import React, { MouseEventHandler } from 'react';
import { ColumnProps, TableProps } from "antd/es/table";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { Button, Input, Table } from "antd";
import {
    SorterResult,
    TableEventListeners,
    TableRowSelection
} from "antd/lib/table/interface";
import { IntlShape } from 'react-intl';
import { SerializedEntity } from '@cuba-platform/rest';

export enum SelectRowType {
    NONE = "none",
    SINGLE = "single",
    MULTI = "multi"
}


type PaginationPosition = 'top' | 'bottom' | 'both';


type PickerTableProps<T> = {
    selectRowType?: SelectRowType,
    columns?: ColumnProps<T>[],
    items: Array<T>,
    paginationPosition?: PaginationPosition,
    onRowClick?: (record: T, index: number, event?: Event) => void,
    onOkClick: (record: T[]) => void,
    onCancelClick: () => void,
    tableProps: TableProps<T>,
    intl?: IntlShape
}

type TableState<T> = {
    loading: boolean,
    pagination?: PageConfig | false,
    selectedRowKeys: number[],
    items: Array<T>,
    sorterColumns: SorterResult<T>[],
}

type PageConfig = {
    position?: PaginationPosition,
    length?: number
}

export type CoursePickerTableData = {
    id: string,
    name: string,
    shortDescription: string,
    party: string,
    category: string,
    targetAudience: string,
    learningType: string,
    activeFlag: string,
    selfEnrollment: string,
    isOnline: string,
}


@observer
export default class CoursePickerTable<T> extends React.Component<PickerTableProps<T>> {

    @observable filteredItems: Array<T>;
    @observable allItems: Array<T>;
    @observable selectedRows: T[] = [];

    @observable tableState: TableState<T> = {
        loading: false,
        selectedRowKeys: [],
        items: [],
        pagination: false,
        sorterColumns: []
    };

    courseColumns: ColumnProps<CoursePickerTableData>[] = [{
        title: this.props.intl!.formatMessage({ id: "name" }),
        dataIndex: 'name',
        key: "name_column",
        sorter: true,
        filtered: true
    },
    {
        title: this.props.intl!.formatMessage({ id: "shortDescription" }),
        dataIndex: 'shortDescription',
        key: "short_description_column",
        width: 400,
        sorter: true,
        filtered: true
    },
    {
        title: this.props.intl!.formatMessage({ id: "party" }),
        dataIndex: 'party',
        key: "party_column",
        sorter: true,
        filtered: true
    },
    {
        title: this.props.intl!.formatMessage({ id: "category" }),
        dataIndex: 'category',
        key: "category_column",
        sorter: true,
        filtered: true
    },
    {
        title: this.props.intl!.formatMessage({ id: "targetAudience" }),
        dataIndex: 'targetAudience',
        key: "targetAudience_column",
        sorter: true,
        filtered: true
    },
    {
        title: this.props.intl!.formatMessage({ id: "learningType" }),
        dataIndex: 'learningType',
        key: "learningType_column",
        sorter: true,
        filtered: true
    },
    {
        title: this.props.intl!.formatMessage({ id: "activeFlag" }),
        dataIndex: 'activeFlag',
        key: "active_column",
        sorter: true,
        filtered: true
    },
    {
        title: this.props.intl!.formatMessage({ id: "selfEnrollment" }),
        dataIndex: 'selfEnrollment',
        key: "self_enrollment_column",
        sorter: true,
        filtered: true,
        render: (text, record) => {
            return <div style={{ width: "250px" }}>{text}</div>
        }
    },
    {
        title: this.props.intl!.formatMessage({ id: "isOnline" }),
        dataIndex: 'isOnline',
        key: "is_online_column",
        sorter: true,
        filtered: true
    }]

    constructor(props: PickerTableProps<T>, context: any) {
        super(props, context);

        if (this.props.paginationPosition) {
            this.tableState.pagination = {
                position: this.props.paginationPosition
            }
        }
    }

    componentDidMount() {
        this.allItems = this.props.items;
        this.filteredItems = this.allItems;
    }

    @action
    setItems = (items: Array<T>) => {
        this.tableState.items = items;
    }

    @action
    setPageCount = (pageCount: number | undefined) => {
        if (this.tableState.pagination) {
            this.tableState.pagination.length = pageCount;
        }
    }

    @action
    setLoading = (loading: boolean) => {
        this.tableState.loading = loading;
    }

    @action
    setSelectedRowKeys = (value: number[]) => {
        this.tableState.selectedRowKeys = value;
    }

    @action
    addSelectedRowKey = (value: any) => {
        this.tableState.selectedRowKeys.push(value);
    }

    @action
    addSortColumn = (value: SorterResult<T>) => {
        this.tableState.sorterColumns.push(value);
    }

    @action
    clearSelectedRowKeys = () => {
        this.tableState.selectedRowKeys = [];
    }


    onSelectedRowKeysChange = (selectedRowKeys: any, selectedRows: T[]) => {
        this.clearSelectedRowKeys();
        if (this.props.selectRowType === SelectRowType.MULTI) {
            this.selectedRows = selectedRows;
            this.setSelectedRowKeys(selectedRowKeys);
        } else {
            this.addSelectedRowKey(selectedRowKeys[0]);
            this.selectedRows[0] = selectedRows[0];
        }

    }

    updateMultiSelectedRows = (record: T, index: number) => {
        if (this.selectedRows.includes(record)) {
            this.selectedRows.splice(this.selectedRows.indexOf(record), 1)
        } else {
            this.selectedRows.push(record);
        }

        const selectedKeys = this.tableState.selectedRowKeys;

        if (selectedKeys.includes(index)) {
            selectedKeys.splice(selectedKeys.indexOf(index), 1)
        } else {
            selectedKeys.push(index);
        }
        this.clearSelectedRowKeys();
        this.setSelectedRowKeys(selectedKeys);
    }

    render() {
        let rowSelection: TableRowSelection<any> | undefined;
        let onRowClick: ((record: T, index: number, event: Event) => void) | undefined;

        if (this.props.selectRowType) {
            switch (this.props.selectRowType) {
                case SelectRowType.MULTI: {
                    rowSelection = {
                        type: 'checkbox',
                        onChange: this.onSelectedRowKeysChange,
                        selectedRowKeys: this.tableState.selectedRowKeys
                    };
                    onRowClick = (record: T, index: number, event: Event) => {
                        this.updateMultiSelectedRows(record, index);

                    }
                    break;
                }
                case SelectRowType.SINGLE: {
                    rowSelection = {
                        type: 'radio',
                        onChange: this.onSelectedRowKeysChange,
                        selectedRowKeys: this.tableState.selectedRowKeys
                    }
                    onRowClick = (record: T, index: number, event: Event) => {
                        this.clearSelectedRowKeys();
                        this.selectedRows[0] = record;
                        this.addSelectedRowKey(index);

                    }
                    break;
                }
                default: {
                    onRowClick = this.props.onRowClick;
                    break;
                }
            }
        } else {
            onRowClick = this.props.onRowClick;
        }

        const tableEventListeners = (record: T, index: number) => {
            return {
                onClick: (arg: React.MouseEvent) => {
                    if (this.props.selectRowType === SelectRowType.MULTI) {
                        this.updateMultiSelectedRows(record, index);

                    } else {
                        this.clearSelectedRowKeys();
                        this.selectedRows[0] = record;
                        this.addSelectedRowKey(index);
                    }

                }

            } as TableEventListeners
        };
        const className = (this.props.selectRowType ? this.props.selectRowType + "-table" : "")
            + (onRowClick ? " actionable" : "");

        return (
            <div className={"table-container"}>
                <div style={{ marginBottom: "5px" }}>
                    <Input onChange={value => { this.filteredItems = this.allItems.filter(item => (item as unknown as SerializedEntity<CoursePickerTableData>).name.includes(value.target.value)) }} />
                </div>
                <Table className={className}
                    pagination={this.tableState.pagination}
                    columns={this.courseColumns}
                    dataSource={this.filteredItems}
                    onRow={tableEventListeners}
                    rowSelection={rowSelection}
                    style={{ height: "40%" }}
                    showHeader={true}
                    scroll={{ x: 1500, y: '20rem' }}

                />
                <div style={{ width: "100%", height: "35px" }}>
                    <div style={{ float: "right" }}>
                        <Button style={{ display: "inline-block", marginRight: "5px" }}
                            onClick={() => {
                                this.props.onOkClick(this.selectedRows);
                                this.clearSelectedRowKeys();
                                this.selectedRows = [];
                            }}>{this.props.intl!.formatMessage({ id: "cubaReact.dataTable.ok" })}
                        </Button>
                        <Button style={{ display: "inline-block" }}
                            onClick={() => {
                                this.props.onCancelClick();
                                this.clearSelectedRowKeys();
                                this.selectedRows = [];
                            }}>{this.props.intl!.formatMessage({ id: "management.browser.exclude.cancel" })}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }


}