import * as React from "react";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

import {getCubaREST, injectMainStore, MainStoreInjected, withLocalizedForm} from "@cuba-platform/react";

import "../../../../app/App.css";

import {AssignedGoal} from "../../../../cuba/entities/base/tsadv$AssignedGoal";
import {observable} from "mobx";
import {Card, Col, Form, Input, InputNumber, Row} from "antd";
import {RootStoreProp} from "../../../store";
import {KpiEmployee} from "../CascadeBtn";
import Page from "../../../hoc/PageContentHoc";
import Section from "../../../hoc/Section";
import Button, {ButtonType} from "../../../components/Button/Button";
import {goBackOrHomePage} from "../../../util/util";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router";
import {FormComponentProps} from "antd/lib/form/Form";
import Notification from "../../../util/Notification/Notification";
import {restServices} from "../../../../cuba/services";

export type CascadePojo = {
  groupId: string,
  name?: string,
  description?: string,
  weight?: number,
}

type Props = GoalHistoryProps;

type GoalHistoryProps = {
  goal: AssignedGoal,
};

@inject('rootStore')
@injectMainStore
@observer
class Cascade extends React.Component<Props & RootStoreProp & WrappedComponentProps & FormComponentProps & MainStoreInjected & RouteComponentProps<any>> {

  @observable
  data?: CascadePojo[] = undefined;
  @observable
  employee?: KpiEmployee[] = undefined;
  @observable
  goal?: AssignedGoal = undefined;

  @observable
  selectedRowKeys: string[] | undefined;

  requiredFields: string[] = [];

  columns = [
    {
      title: this.props.intl.formatMessage({id: 'employee.name'}),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: this.props.intl.formatMessage({id: 'positionGroup'}),
      dataIndex: 'position',
      key: 'position',
    },
  ];

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKeys = selectedRowKeys;
  };

  handleOk = async () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        Notification.error({
          message:
            this.props.intl.formatMessage({
              id: "management.editor.validationError"
            })
        });
        return;
      }

      restServices.kpiService.cascadeAssignedGoal({goalId: this.goal!.id, cascadePojos: this.data!})
        .then(value => Notification.success({
          message: this.props.intl.formatMessage({id: "cascading.was.successful"})
        }))
        .then(value => goBackOrHomePage(this.props.history!))
        .catch(reason => Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.error"})
        }));

    });
  }

  render() {
    if (!this.data || !this.employee || !this.goal) return <></>;

    return (
      <Page pageName={this.props.intl.formatMessage({id: "cascade"})}>
        <Section size="large" className={"narrow-layout card-actions-container"}>
          <Card bordered={false} actions={this.btns()}>
            <div>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Form.Item label={this.props.intl.formatMessage({id: 'cascaded.kpi'})}>
                    <Input value={this.goal.goalString || this.goal.goal && this.goal.goal['_instanceName']} disabled/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={<FormattedMessage id="goalForm.column.kpiDetails"/>}>
                    <Input
                      value={this.goal.successCriteria || (this.goal.goal && (this.goal.goal as any).successCriteriaLang)}
                      disabled/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={<FormattedMessage id="kpi.goals.weight"/>}>
                    <Input value={this.goal.weight} disabled/>
                  </Form.Item>
                </Col>
              </Row>
              {
                this.data.map(this.renderData)
              }
            </div>
          </Card>
        </Section>
      </Page>);
  }

  renderData = (data: CascadePojo) => {

    const employee = this.employee!.find(value => value.groupId === data.groupId)!;

    this.requiredFields.push(data.groupId + "_kpiName", data.groupId + "_kpiDetails", data.groupId + "_weight");

    return (
      <Row gutter={[8, 8]}>
        <Col span={6}>
          <Form.Item label={<FormattedMessage id='employee.name'/>}>
            <Input value={employee.name || ''} disabled/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={<FormattedMessage id="goalForm.column.kpiName"/>}>
            {this.props.form.getFieldDecorator(data.groupId + "_kpiName", {
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.intl.formatMessage({id: 'goalForm.column.kpiName'})})
              }],
              initialValue: data.name,
              getValueFromEvent: args => {
                const value = args.currentTarget.value;
                data.name = value;
                return value;
              }
            })(
              <Input/>
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={<FormattedMessage id="goalForm.column.kpiDetails"/>}>
            {this.props.form.getFieldDecorator(data.groupId + "_kpiDetails", {
              initialValue: data.description,
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.intl.formatMessage({id: 'goalForm.column.kpiDetails'})})
              }],
              getValueFromEvent: args => {
                const value = args.currentTarget.value;
                data.description = value;
                return value;
              }
            })(
              <Input/>
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={<FormattedMessage id="kpi.goals.weight"/>}>
            {this.props.form.getFieldDecorator(data.groupId + "_weight", {
              initialValue: data.weight,
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.intl.formatMessage({id: 'kpi.goals.weight'})})
              }],
              getValueFromEvent: args => {
                const value = args;
                data.weight = value;
                return value;
              }
            })(
              <InputNumber max={100} min={0}/>
            )}
          </Form.Item>
        </Col>
      </Row>
    )
  }

  btns = () => {
    return [<Button buttonType={ButtonType.FOLLOW}
                    htmlType="button"
                    onClick={() => goBackOrHomePage(this.props.history!)}>
      <FormattedMessage id="close"/>
    </Button>,
      <Button buttonType={ButtonType.FOLLOW}
              htmlType="button"
              onClick={this.handleOk}>
        <FormattedMessage id="cascade"/>
      </Button>];
  }

  componentDidMount() {
    (async () => {
      const goalId = this.props.rootStore!.cascadeGoalStore.goalId;
      this.employee = this.props.rootStore!.cascadeGoalStore.personGroups;

      await getCubaREST()!.loadEntity<AssignedGoal>(AssignedGoal.NAME, goalId, {view: 'assignedGoal-portal-kpi-create-default'})
        .then(value => this.goal = value);

      if (this.employee && this.goal) {
        this.data = this.employee.map(value => {
          return {
            groupId: value.groupId,
            description: this.goal!.successCriteria || (this.goal!.goal && (this.goal!.goal as any).successCriteriaLang),
            name: this.goal!.goalString || this.goal!.goal && this.goal!.goal['_instanceName'],
            weight: this.goal!.weight
          } as CascadePojo
        })
      }

    })()
  }
}

export default injectIntl(withLocalizedForm<any>({
  onValuesChange: (props: any, changedValues: any, allValues: any) => {
    // Reset server-side errors when field is edited
    Object.keys(changedValues).forEach((fieldName: string) => {
      props.form.setFields({
        [fieldName]: {
          value: changedValues[fieldName]
        }
      });
    });
  }
})(withRouter(Cascade)));
