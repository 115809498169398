import * as React from "react";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import SurChargeRequestEdit from "./SurChargeRequestEdit";
import SurChargeRequestList from "./SurChargeRequestList";
import Page from "../../hoc/PageContentHoc";
import { FormattedMessage } from "react-intl";
import { Card } from "antd";
import { RootStoreProp } from "../../store";

type Props = RouteComponentProps<{
  entityId?: string
}> & RootStoreProp;

@inject("rootStore")
@observer
export class MySurChargeRequestManagement extends React.Component<Props> {
  static PATH = "/mySurChargeRequest";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId } = this.props.match.params;
    return entityId ? (
      <SurChargeRequestEdit entityId={entityId} />
    ) : (
      <Page pageName={<FormattedMessage id={'menu.surCharge'}/>}>
        <Card
          bordered={false}
          style={{boxShadow:"0 3px 24px rgb(0 0 0 / 10%)",    height: "fit-content", borderRadius:4, padding:"20px "}}
        >
          <SurChargeRequestList
            key="list"
            openCreateTab={()=>this.props.history.push(MySurChargeRequestManagement.PATH + '/' + MySurChargeRequestManagement.NEW_SUBPATH)}
            personGroupId={this.props.rootStore!.userInfo.personGroupId}
          />
        </Card>
      </Page>
    );
  }
}
