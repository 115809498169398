import {AbstractParentEntity} from "./AbstractParentEntity";
import {FileDescriptor} from "./sys$FileDescriptor";
import {DicCompany} from "./base_DicCompany";

export class Timeline extends AbstractParentEntity {
  static NAME = "tsadv_Timeline";

  name: String;
  company: DicCompany;
  isPublished: Boolean;
  imageRu?: FileDescriptor | null;
  urlRu?: String | null;
  newsTopicRu?: String | null;
  newsTextRu?: String | null;
  imageKz?: FileDescriptor | null;
  urlKz?: String | null;
  newsTopicKz?: String | null;
  newsTextKz?: String | null;
  imageEn?: FileDescriptor | null;
  urlEn?: String | null;
  newsTopicEn?: String | null;
  newsTextEn?: String | null;
  dateTo?: any | null;
}
