import * as React from "react"
import DataTableFormat from "../../../components/DataTable/intex";
import {CompetenceElement} from "../../../../cuba/entities/base/tsadv$CompetenceElement";
import {injectMainStore, MainStoreInjected, collection} from "@cuba-platform/react";
import {observer} from "mobx-react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Table} from "antd";

interface Props {
  selectedPosition?: string;
}

@injectMainStore
@observer
class CompetenceTable extends React.Component<Props & MainStoreInjected & WrappedComponentProps> {
  dataCollection = collection(CompetenceElement.NAME, {
    view: "competenceElement-view",
    filter: {
      conditions: [{property: "positionGroup.id", operator: "=", value: this.props.selectedPosition!}]
    }
  })

  render() {
    console.log(this.dataCollection)
    return (
      <Table bordered={true} dataSource={this.dataCollection && this.dataCollection.items}>
        <Table.Column render={(text, record: CompetenceElement) => {
          return <span>{record && record.competenceGroup && record.competenceGroup.competence && record.competenceGroup.competence && record.competenceGroup.competence.competeceType!["_instanceName"]}</span>
        }} title={this.props.intl.formatMessage({id: "keyPosition.modalTable.table.type"})}/>
        <Table.Column render={(text, record: CompetenceElement) => {
          return <span>{record && record.competenceGroup && record.competenceGroup["_instanceName"]}</span>
        }} title={this.props.intl.formatMessage({id: "keyPosition.modalTable.table.nameing"})}/>
        <Table.Column render={(text, record: CompetenceElement) => {
          return <span>{record && record.competenceGroup && record.competenceGroup.competence && record.competenceGroup.competence && record.competenceGroup.competence.scale && record.competenceGroup.competence.scale["_instanceName"]}</span>
        }} title={this.props.intl.formatMessage({id: "keyPosition.modalTable.table.scale"})}/>
        <Table.Column render={(text, record: CompetenceElement) => {
          return <span>{record && record.scaleLevel && record.scaleLevel["_instanceName"]}</span>
        }} title={this.props.intl.formatMessage({id: "keyPosition.modalTable.table.level"})}/>
      </Table>)
  }
}

export default injectIntl(CompetenceTable);