import * as React from "react";
import { RouteComponentProps } from "react-router";
import { inject, observer } from "mobx-react";
import SurChargeRequestList from "./SurChargeRequestList";
import Page from "../../hoc/PageContentHoc";
import { FormattedMessage } from "react-intl";
import { Card } from "antd";
import { RootStoreProp } from "../../store";

type Props = RootStoreProp & {
  personGroupId?: string,
};

@inject("rootStore")
@observer
export class PaidSurChargeListManagment extends React.Component<Props> {
  static PATH = "/surChargeRequest-paid";

  render() {
    return (
      <Page pageName={<FormattedMessage id={'menu.surChargeRequest.paid'}/>}>
        <Card
          bordered={false}
          style={{
            margin: "0 2rem"
          }}
        >
          <SurChargeRequestList
            key="paid"
            onlyPaidRequests
            personGroupId={this.props.personGroupId || this.props.rootStore!.userInfo.personGroupId}
          />
        </Card>
      </Page>
    );
  }
}
