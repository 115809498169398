import { AbstractParentEntity } from "./AbstractParentEntity";
import { PositionGroupExt } from "./base$PositionGroupExt";
import { SuccessionPlanningHistory } from "./tsadv_SuccessionPlanningHistory";
import { Successor } from "./tsadv$Successor";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { TsadvUser } from "./tsadv$UserExt";
import { FileDescriptor } from "./sys$FileDescriptor";
export class SuccessionPlanning extends AbstractParentEntity {
  static NAME = "tsadv$SuccessionPlanning";
  positionGroup?: PositionGroupExt | null;
  successionPlanningHistory?: SuccessionPlanningHistory[] | null;
  currentSuccessionPlanningHistory?: SuccessionPlanningHistory | null;
  startDate?: any | null;
  endDate?: any | null;
  vacancyDate?: any | null;
  description?: string | null;
  successors?: Successor[] | null;
  personGroup?: PersonGroupExt | null;
  reason?: string | null;
  jobResponsibilities?: string | null;
  qualificationRequirements?: string | null;
  generalAndAdditionalRequirements?: string | null;
  positionType?: any | null;
  holders?: TsadvUser | null;
  managers?: TsadvUser | null;
  jobDescription?: FileDescriptor | null;
  positionName?: string | null;
}
export type SuccessionPlanningViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "keyPosition-full"
  | "keyPosition-short"
  | "keyPosition-view"
  | "successionPlanning-SuccessorRequest"
  | "successionPlanning-successor-table-view"
  | "successionPlanning-talentPool"
  | "successionPlanning.browse";
export type SuccessionPlanningView<
  V extends SuccessionPlanningViewName
> = V extends "_base"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "positionName"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "positionName"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_minimal"
  ? Pick<SuccessionPlanning, "id" | "positionName">
  : V extends "keyPosition-full"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "positionName"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "positionGroup"
      | "successors"
      | "personGroup"
      | "successionPlanningHistory"
      | "currentSuccessionPlanningHistory"
      | "holders"
      | "managers"
      | "jobDescription"
    >
  : V extends "keyPosition-short"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "positionName"
      | "positionGroup"
      | "positionType"
      | "jobDescription"
      | "holders"
      | "managers"
      | "successors"
    >
  : V extends "keyPosition-view"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "positionName"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "positionGroup"
      | "successors"
      | "personGroup"
      | "successionPlanningHistory"
      | "currentSuccessionPlanningHistory"
    >
  : V extends "successionPlanning-SuccessorRequest"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "positionName"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "positionGroup"
      | "successors"
    >
  : V extends "successionPlanning-successor-table-view"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "positionName"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "positionGroup"
      | "successors"
      | "personGroup"
      | "positionGroup"
      | "personGroup"
    >
  : V extends "successionPlanning-talentPool"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "positionName"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "positionGroup"
      | "successors"
      | "personGroup"
    >
  : V extends "successionPlanning.browse"
  ? Pick<
      SuccessionPlanning,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "reason"
      | "jobResponsibilities"
      | "qualificationRequirements"
      | "generalAndAdditionalRequirements"
      | "positionType"
      | "positionName"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "positionGroup"
      | "successors"
      | "personGroup"
    >
  : never;
