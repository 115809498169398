import * as React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { observable } from "mobx";

import { Modal, Button } from "antd";

import {
  collection,
  injectMainStore,
  MainStoreInjected,
  DataTable
} from "@cuba-platform/react";

import { AdaptationTask } from "../../../../cuba/entities/base/tsadv_AdaptationTask";
import { SerializedEntity } from "@cuba-platform/rest";
import { AdaptationTaskManagement } from "./AdaptationTaskManagement";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
// import AdaptationTaskEdit from "./AdaptationTaskEdit";
// import AddAdaptationModal from "../Managment/AddAdaptationModal";


type Props = MainStoreInjected & WrappedComponentProps & {
  personGroupId?: string;
};

@injectMainStore
@observer
class AdaptationTaskListComponent extends React.Component<Props> {
  dataCollection = collection<AdaptationTask>(AdaptationTask.NAME, {
    view: "_local",
    sort: "-updateTs"
  });

  fields = [
    "assignmentLang1",

    "assignmentLang2",

    "assignmentLang3",

    "achievedResultsLang1",

    "achievedResultsLang2",

    "achievedResultsLang3",

    "expectedResultsLang1",

    "expectedResultsLang2",

    "expectedResultsLang3"
  ];

  @observable selectedRowKey: string | undefined;

  showDeletionDialog = (e: SerializedEntity<AdaptationTask>) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "management.browser.delete.areYouSure" },
        { instanceName: e._instanceName }
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        this.selectedRowKey = undefined;

        return this.dataCollection.delete(e);
      }
    });
  };

  render() {
    const buttons = [
      
      // <AddAdaptationModal
      //   // title="Hello world"
      //   button={({ onClick }) =>
      //     <Button
      //       htmlType="button"
      //       style={{ margin: "0 12px 12px 0" }}
      //       type="primary"
      //       icon="plus"
      //       onClick={onClick}
      //     >
      //       <span>
      //         <FormattedMessage id="management.browser.create" />
      //       </span>
      //     </Button>
      //   }>
      //   <AdaptationTaskEdit entityId="new" />
      // </AddAdaptationModal>,
      <Link
        to={AdaptationTaskManagement.PATH + "/" + this.selectedRowKey}
        key="edit"
      >
        <Button
          htmlType="button"
          style={{ margin: "0 12px 12px 0" }}
          disabled={!this.selectedRowKey}
          type="default"
        >
          <FormattedMessage id="management.browser.edit" />
        </Button>
      </Link>,
      <Button
        htmlType="button"
        style={{ margin: "0 12px 12px 0" }}
        disabled={!this.selectedRowKey}
        onClick={this.deleteSelectedRow}
        key="remove"
        type="default"
      >
        <FormattedMessage id="management.browser.remove" />
      </Button>
    ];

    return (
      <DataTable
        dataCollection={this.dataCollection}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  getRecordById(id: string): SerializedEntity<AdaptationTask> {
    const record:
      | SerializedEntity<AdaptationTask>
      | undefined = this.dataCollection.items.find(record => record.id === id);

    if (!record) {
      throw new Error("Cannot find entity with id " + id);
    }

    return record;
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

  deleteSelectedRow = () => {
    this.showDeletionDialog(this.getRecordById(this.selectedRowKey!));
  };
}

const AdaptationTaskList = injectIntl(AdaptationTaskListComponent);

export default AdaptationTaskList;
