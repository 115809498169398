import { StandardEntity } from "./sys$StandardEntity";
import { UcoSignDocument } from "./ucosign$Document";
import { User } from "./sec$User";
import { FileDescriptor } from "./sys$FileDescriptor";
export class UcoSignSignature extends StandardEntity {
  static NAME = "ucosign$Signature";
  ucosignDocument?: UcoSignDocument | null;
  sigexSignId?: number | null;
  dataURL: string | null
  sigexSubjectData?: string | null;
  signUser?: User | null;
  signUserIdentificationNumber?: string | null;
  userSignType?: "IIN" | "BIN" | null;
  signUserLogin?: string | null;
  signUserFullName?: string | null;
  signDate?: any | null;
  signatureFile?: FileDescriptor | null;
  signatureData?: string | null;
  signOrder?: number | null;
}
export type UcoSignSignatureViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "ucoSignSignature-registerOrAddSignatureToSigexDocument"
  | "ucoSignSignature-signByQr"
  | "ucoSignSignature.edit";
export type UcoSignSignatureView<
  V extends UcoSignSignatureViewName
> = V extends "_base"
  ? Pick<
      UcoSignSignature,
      | "id"
      | "signUserFullName"
      | "sigexSignId"
      | "sigexSubjectData"
      | "signUserIdentificationNumber"
      | "userSignType"
      | "signUserLogin"
      | "signDate"
      | "signatureData"
    >
  : V extends "_local"
  ? Pick<
      UcoSignSignature,
      | "id"
      | "sigexSignId"
      | "sigexSubjectData"
      | "signUserIdentificationNumber"
      | "userSignType"
      | "signUserLogin"
      | "signUserFullName"
      | "signDate"
      | "signatureData"
      | "signOrder"
    >
  : V extends "_minimal"
  ? Pick<UcoSignSignature, "id" | "signUserFullName">
  : V extends "ucoSignSignature-registerOrAddSignatureToSigexDocument"
  ? Pick<
      UcoSignSignature,
      | "id"
      | "sigexSignId"
      | "sigexSubjectData"
      | "signUserIdentificationNumber"
      | "userSignType"
      | "signUserLogin"
      | "signUserFullName"
      | "signDate"
      | "signatureData"
      | "ucosignDocument"
      | "signUser"
    >
  : V extends "ucoSignSignature-signByQr"
  ? Pick<
      UcoSignSignature,
      | "id"
      | "signUserFullName"
      | "sigexSignId"
      | "sigexSubjectData"
      | "signUserIdentificationNumber"
      | "userSignType"
      | "signUserLogin"
      | "signDate"
      | "signatureData"
      | "ucosignDocument"
      | "signUser"
      | "signatureFile"
    >
  : V extends "ucoSignSignature.edit"
  ? Pick<
      UcoSignSignature,
      | "id"
      | "sigexSignId"
      | "sigexSubjectData"
      | "signUserIdentificationNumber"
      | "userSignType"
      | "signUserLogin"
      | "signUserFullName"
      | "signDate"
      | "signatureData"
      | "ucosignDocument"
      | "signUser"
      | "signatureFile"
      | "createTs"
      | "updateTs"
      | "signOrder"
    >
  : never;
