import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import VacancyBrowse from "./VacancyBrowse";

type Props = RouteComponentProps<{ entityId?: string }>;

@observer
export class VacancyManagement extends React.Component<Props> {
  static PATH = "/manager-vacancies";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId} = this.props.match.params;
    return (
      <>
        <VacancyBrowse/>
      </>
    );
  }
}