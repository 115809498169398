import * as React from "react";
import DataTableFormat from "../../components/DataTable/intex";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import {MyTeamCardProps} from "../MyTeam/MyTeamCard";
import {PunishmentRequest} from "../../../cuba/entities/base/tsadv$PunishmentRequest";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import {Button} from "antd";
import {PunishmentRequestType} from "../../../cuba/enums/enums";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {PositionOverlappingRequestManagement} from "../PositionOverlappingRequest/PositionOverlappingRequestManagement";
import {PositionOverlappingRequest} from "../../../cuba/entities/kzm$PositionOverlappingRequest";
import {RootStoreProp} from "../../store";

@injectMainStore
@observer
@inject("rootStore")
class JobCombineList extends React.Component<MainStoreInjected & WrappedComponentProps & MyTeamCardProps & RootStoreProp> {
  dataCollection = collection<PositionOverlappingRequest>(PositionOverlappingRequest.NAME, {
    view: "positionOverlappingRequest-edit",
    sort: "-updateTs",
    limit: 20,
    filter:{
      conditions:[{
        property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!
      }]
    }
  });

  fields = [
    "requestNumber",
    "requestDate",
    "status",
    "personGroup"
  ];

  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
      <Link
        to={
          PositionOverlappingRequestManagement.PATH + "/" + PositionOverlappingRequestManagement.NEW_SUBPATH
        }
        key="create">
        <Button
          htmlType="button"
          style={{margin: "0 12px 12px 0"}}
          type="primary"
        >
          <span>
            <FormattedMessage id="diCreate"/>
          </span>
        </Button>
      </Link>
    ];

    return <Page pageName={this.props.intl.formatMessage({id:"jobCombReq"})}>
      <Section size={"large"}>
        <DataTableFormat
          dataCollection={this.dataCollection}
          fields={this.fields}
          hideSelectionColumn={true}
          buttons={buttons}
          render={[
            {
              column: 'requestNumber',
              render: (text, record) => (
                <Link to={PositionOverlappingRequestManagement.PATH + "/" + record.id}>{text}</Link>
              )
            }
          ]}
        /></Section></Page>
  }
}

export default injectIntl(JobCombineList)

