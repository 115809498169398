import {Alert, Button, DatePicker, Form, Input, Modal, Select, Spin} from "antd";
import {FormComponentProps} from "antd/es/form";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../store";
import {SerializedEntity} from "@cuba-platform/rest";
import {DicEducationType} from "../../../../cuba/entities/base/tsadv$DicEducationType";
import {DicIdpActionType} from "../../../../cuba/entities/base/tsadv_DicIdpActionType";
import {Course} from "../../../../cuba/entities/base/tsadv$Course";
import {inject, observer} from "mobx-react";
import * as React from "react";
import {action, observable, runInAction} from "mobx";
import {CourseSchedule} from "../../../../cuba/entities/base/tsadv_CourseSchedule";
import {IdpDetailsActionTypeCodes} from "../../../../cuba/enums/enums";
import {PersonGroupExt} from "../../../../cuba/entities/base/base$PersonGroupExt";
import moment, {Moment} from "moment";
import {IndividualDevelopmentPlan} from "../../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";
import SearchCourse from "../../../components/SearchCourse";
import SearchPerson from "../../../components/SearchPerson";
import {PartyExt} from "../../../../cuba/entities/base/base$PartyExt";

type TaskDetailsProps = {
  showTaskDetail:boolean,
  editable:boolean,
  educationTypes:SerializedEntity<DicEducationType>[],
  actionTypes:SerializedEntity<DicIdpActionType>[],
  companyCode:string,
  courses?:Course[]|undefined;
}

@injectMainStore
@inject("rootStore")
@observer
class TaskDetailsComponent extends React.Component<TaskDetailsProps & FormComponentProps&MainStoreInjected & WrappedComponentProps & RootStoreProp>{

  @observable isLoading:boolean=false;

  @observable courseSchedules:SerializedEntity<CourseSchedule>[];

  @observable selectedCourseId:string|undefined

  @observable courseSchedule:SerializedEntity<CourseSchedule>|undefined

  @observable selectedCourse:SerializedEntity<Course>

  @action.bound handleTaskDetailCancel(){
    if( this.props.rootStore!.idpStore){
      this.props.rootStore!.idpStore.showTaskDetails = false
    }
  }

  @observable fields:string[]

  @action.bound handleOkTaskDetail(currentTaskId:string){
    const isTraining = this.actionType&&this.actionType.code === IdpDetailsActionTypeCodes.TRAINING;
    this.props.form.validateFields((err)=>{
      if(!err&&this.props.rootStore!.idpStore){
        const store = this.props.rootStore!.idpStore;
        const detail=store.getIdpDetailById(currentTaskId)
        if(detail){
          if(isTraining){
            detail.course = this.courseSchedule&&this.courseSchedule.course&&this.courseSchedule.course || this.selectedCourse
            detail.priceKzt = this.priceKzt
            detail.travelExpensesKzt = this.travelExpensesKzt
            detail.educationType = this.educationType
            detail.actionType = this.actionType
            detail.description = this.description
            detail.goalName = this.goalName

            detail.targetDate = this.targetDate&&this.targetDate.toISOString()
           // detail.party = this.provider
            detail.courseAddress = this.place
            detail.personHelp = undefined
          }
          else
          {
            detail.personHelp = this.personHelp
            detail.educationType = this.educationType
            detail.actionType = this.actionType
            detail.description = this.description
            detail.goalName = this.goalName
            detail.targetDate = this.targetDate&&this.targetDate.toISOString()
            detail.party = undefined
            detail.course = undefined
            detail.priceKzt = undefined;
            detail.travelExpensesKzt = undefined;
            detail.courseAddress = undefined;
          }
          this.props.rootStore!.idpStore.showTaskDetails = false
        }
      }
    })
  }

  @action getCourseData(value:string){
    this.isLoading = true;
    getCubaREST()!.loadEntity<Course>(Course.NAME,this.selectedCourseId,{view:"course.edit.new"}).then(course=>{
      this.selectedCourse = course;
      this.numberOfHour = this.selectedCourse&&this.selectedCourse.educationDuration?this.selectedCourse.educationDuration:0
      this.numberOfDay = this.numberOfHour/8;
      this.provider = this.selectedCourse&&this.selectedCourse.party?this.selectedCourse.party:{}
    })
    getCubaREST()!.searchEntities<CourseSchedule>(CourseSchedule.NAME,{
      conditions:[{
        property:"course.id",
        operator:"=",
        value:value
      }]
    },{view:"courseScheduleEvent-edit"}).then(schedules=>runInAction(()=>{
      this.isLoading = false;
      this.courseSchedule = undefined
      if(schedules.length>0){
        this.courseSchedule = schedules.reduce((p,c)=>moment(p.endDate).isAfter(moment(c.endDate))?p:c)
        this.place = this.courseSchedule&&this.courseSchedule.address?this.courseSchedule.address:""
      }
      else{
        getCubaREST()!.loadEntity<Course>(Course.NAME,this.selectedCourseId,{view:"_local"}).then(course=>{
          this.selectedCourse = course;
        })
      }
    })).catch(()=>runInAction(()=>this.isLoading = false))
  }

  @observable educationType:DicEducationType

  @observable educationTypeId:string

  @observable actionType:DicIdpActionType

  @observable actionTypeId:string

  @observable priceKzt:number

  @observable travelExpensesKzt:number

  @observable personHelp:null | undefined | PersonGroupExt

  @observable description:string

  @observable goalName:string

  @observable targetDate:Moment| undefined

  @observable provider:PartyExt

  @observable place:string

  @observable numberOfDay:number;

  @observable numberOfHour:number;

  @action onChangeSearchPerson = async (value:string) => {
    return await getCubaREST()!.loadEntity<PersonGroupExt>(PersonGroupExt.NAME, value, {view: "personGroup.personHelp"})
  }

  @observable providers:SerializedEntity<PartyExt>[]

  constructor(props:TaskDetailsProps & RootStoreProp & WrappedComponentProps&FormComponentProps) {
    super(props);
    const currentTaskDetail = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.currentTaskDetail
    const isTraining = this.actionType&&this.actionType.code === IdpDetailsActionTypeCodes.TRAINING;
    this.fields  = isTraining?["targetDate","description","selectedCourseId"]:["targetDate","description","actionTypeId","educationTypeId","goalName","selectedCourseId"]
    if(currentTaskDetail){
      this.educationType = JSON.parse(JSON.stringify(currentTaskDetail.educationType&&currentTaskDetail.educationType))
      this.educationTypeId = this.educationType.id

      this.actionType = currentTaskDetail.actionType
        ? JSON.parse(JSON.stringify(currentTaskDetail.actionType))
        : undefined

      this.actionTypeId = this.actionType && this.actionType.id
      this.priceKzt = currentTaskDetail.priceKzt || 0;
      this.travelExpensesKzt = currentTaskDetail.travelExpensesKzt || 0;
      this.description = currentTaskDetail.description?currentTaskDetail.description.toString():""
      if(currentTaskDetail.personHelp){
        this.personHelp=currentTaskDetail.personHelp
      }
      this.numberOfHour = currentTaskDetail.numberOfHour || 0;
      this.numberOfDay = currentTaskDetail.numberOfDay || 0;
      this.goalName = currentTaskDetail.goalName?currentTaskDetail.goalName.toString():""
      this.place = currentTaskDetail.courseAddress||""
      this.selectedCourseId = currentTaskDetail.course&&currentTaskDetail.course.id || undefined
      if(this.selectedCourseId) this.getCourseData(this.selectedCourseId);
      this.targetDate = currentTaskDetail.targetDate?moment(currentTaskDetail.targetDate):undefined
      this.fields.forEach(field=>this.props.form.getFieldDecorator(field,{initialValue:this[field]}))

    }
  }

  render() {
    const {Option} = Select
    const messages = this.props.mainStore!.messages!;
    const store = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore
    const currentTask = store&&store.currentTaskDetail
    const personHelpName =this.personHelp&&this.personHelp['_instanceName']
    const personHelpCompany = this.personHelp&&this.personHelp.person&&this.personHelp.company&&this.personHelp.company['_instanceName']
    const personHelpEmployeeNumber = this.personHelp&&this.personHelp.person&&this.personHelp.person.employeeNumber
    const personHelpFullName=this.personHelp?personHelpName + ` (${personHelpEmployeeNumber}) (${personHelpCompany})`:""
    const isTraining = this.actionType&&this.actionType.code === IdpDetailsActionTypeCodes.TRAINING;
    const provider = currentTask&&currentTask.party || this.provider
    const duration = this.numberOfHour|| ""
    const durationInDays = this.numberOfDay || ""
    const place = this.place
    const editCourse = isTraining && this.props.editable||false
    return (<Modal
        visible={this.props.rootStore!.idpStore!.showTaskDetails}
        title={<FormattedMessage id={"taskDetails"}/>}
        onCancel={this.handleTaskDetailCancel}
        centered
        bodyStyle={{padding:5}}
        footer={this.props.editable?[
          <Button key="back" onClick={this.handleTaskDetailCancel}>
            <FormattedMessage id={"management.browser.delete.cancel"}/>
          </Button>,
          <Button key="submit" type="primary" onClick={()=>this.handleOkTaskDetail(currentTask?currentTask.id:"")}>
            <FormattedMessage id={"save"}/>
          </Button>,
        ]:[]}
      >
        <Spin spinning={this.isLoading}>
          <Form>
            {!isTraining?
              <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"educationType"}/>}>
                {this.props.form.getFieldDecorator("educationTypeId", {
                  rules: [{
                    required: editCourse,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.educationType']}),
                  }]
                })(
                  <Select disabled={!this.props.editable}
                          onChange={(e: string) => runInAction(() => {
                            this.educationType = this.props.educationTypes.find(type => type.id === e)!
                          })}>
                    {this.props.educationTypes && this.props.educationTypes.map(value => <Option key={value.id}
                                                                                                 value={value.id}>{value._instanceName}</Option>)}
                  </Select>)}
              </Form.Item>
              :
              <></>
            }
            <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"actionType"}/>}>
              {this.props.form.getFieldDecorator("actionTypeId",{
                rules:[{
                  required:false,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.actionType']}),
                }]
              })(
                <Select disabled={!this.props.editable}
                        onChange={(e:string)=>runInAction(()=>{
                          this.actionType = this.props.actionTypes.find(type=>type.id===e)!
                        })}>
                  {this.props.actionTypes && this.props.actionTypes.map(value => <Option key={value.id}
                                                                                         value={value.id}>{value._instanceName}</Option>)}
                </Select>)}
            </Form.Item>
            {isTraining ?
              <>
                <Form.Item labelCol={{span:24}} className="taskDetailModalItem" label={<Alert description={<FormattedMessage id="taskCourseDescription"/>} style={{display:"inline-block",padding:5}} type={"info"} message={this.props.intl.formatMessage({id:"courseScheduleEnrollment.course"})} />}>
                  {this.props.form.getFieldDecorator("selectedCourseId", {
                    rules:[{
                      required:editCourse,
                      message:this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.course']})
                    }]
                  })(
                      <SearchCourse disabled={!editCourse} changeValue={(value: string) => runInAction(() => {
                        this.selectedCourseId = value;
                        this.props.form.setFieldsValue({"selectedCourseId": value})
                        this.getCourseData(value)
                      })} selectValue={this.selectedCourseId}/>
                  )}
                </Form.Item>
             </>:
              <></>}
                <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"planDate"}/>}>
                  {this.props.form.getFieldDecorator("targetDate", {
                    rules: [{
                      required: true,
                      message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.targetDate']}),
                    }]
                  })(
                    <DatePicker disabled={!this.props.editable} locale={'ru'} format={'L'} onChange={(date) => {
                      runInAction(() => {
                        if (date) this.targetDate = date
                      })
                    }
                    }/>)}
                </Form.Item>
                {isTraining ?
                  <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"description"}/>}>
                    {this.props.form.getFieldDecorator("description",{
                      rules:[{
                        required:editCourse,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.description']})
                      }]
                    })(
                      <Input disabled={!editCourse} onChange={(e)=>runInAction(()=>{
                        this.description = e.target.value
                      })
                      }/>)}
                  </Form.Item>
                  :
                  <></>
                }
                {/*{isTraining?*/}
                {/*  <>*/}
                {/*  <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"provider"}/>}>*/}
                {/*    <Select disabled={!editCourse} value={provider && provider.id}*/}
                {/*            onChange={(value) => this.provider = this.providers.find(p => p.id === value)!}>*/}
                {/*      {this.providers && this.providers.map(p => <Option key={p.id} value={p.id}>{p.name}</Option>)}*/}
                {/*    </Select>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"numberOfDays"}/>}>*/}
                {/*  <Input disabled={!editCourse} value={durationInDays||0} onChange={(e)=>*/}
                {/*    runInAction(()=> {*/}
                {/*      if (isNaN(Number(e.target.value))) {*/}
                {/*        e.target.value.slice(0, -1)*/}
                {/*      } else this.numberOfDay = Number(e.target.value);*/}
                {/*    })*/}
                {/*  }/>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"numberOfHours"}/>}>*/}
                {/*  <Input disabled={!editCourse} value={duration||0} onChange={(e)=>*/}
                {/*    runInAction(()=> {*/}
                {/*      if (isNaN(Number(e.target.value))) {*/}
                {/*        e.target.value.slice(0, -1)*/}
                {/*      } else this.numberOfHour = Number(e.target.value);*/}
                {/*    })*/}
                {/*  }/>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"priceKZT"}/>}>*/}
                {/*  <Input inputMode={"numeric"} value={this.priceKzt} disabled={!editCourse} onChange={(e)=>*/}
                {/*  runInAction(()=> {*/}
                {/*  if (isNaN(Number(e.target.value))) {*/}
                {/*  e.target.value.slice(0, -1)*/}
                {/*} else this.priceKzt = Number(e.target.value);*/}
                {/*})*/}
                {/*}/>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"travelPriceKZT"}/>}>*/}
                {/*  <Input inputMode={"numeric"} value={this.travelExpensesKzt} disabled={!editCourse} onChange={(e)=>*/}
                {/*  runInAction(()=>{*/}
                {/*  if(isNaN(Number(e.target.value))){*/}
                {/*  e.target.value.slice(0,-1)*/}
                {/*}*/}
                {/*  else this.travelExpensesKzt = Number(e.target.value);*/}
                {/*})}/>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"trainingPlace"}/>}>*/}
                {/*  <Input disabled={!editCourse} value={place||""} onChange={(e)=>runInAction(()=>this.place = e.target.value)}/>*/}
                {/*  </Form.Item>*/}
                {/*  </>*/}
                {/*  :*/}
                {/*  <></>*/}
            {/*}*/}
            {!isTraining?
              <>
                <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"task"}/>}>
                  {this.props.form.getFieldDecorator("goalName",{
                    rules:[{
                      required:true,
                      message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.goalName']}),
                    }]
                  })(
                    <Input disabled={!this.props.editable} onChange={(e)=>this.goalName = e.target.value}/>)}
                </Form.Item>
                <fieldset style={{
                  border: "1px solid #d8d8d8",
                  padding: "5px",
                  borderRadius: "4px",
                  width: "100%",
                  height: "auto",
                }}>
                  <legend style={{
                    width: "auto",
                    padding: "2px",
                    borderBottom: "none",
                    color: "inherit",
                    fontSize: "1 em",
                    marginBottom: 0
                  }}><FormattedMessage id="idpDetail.personHelp"/>
                  </legend>
                  <Form.Item className={"person-help"}>
                    {!this.props.editable ?
                      <Input disabled value={personHelpFullName}/>
                      :
                      <SearchPerson companyCode={this.props.rootStore!.userInfo.companyCode!}
                                    changeValue={(value: string) => {
                                      this.onChangeSearchPerson(value).then(person =>
                                        runInAction(() => this.personHelp = person))
                                    }}
                                    selectValue={this.personHelp && this.personHelp.id}
                      />
                    }
                  </Form.Item>
                  <Form.Item className={"person-help"} label={<FormattedMessage id={"description"}/>}>
                    {this.props.form.getFieldDecorator("description", {
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.description']}),
                      }]
                    })(
                      <Input disabled={!this.props.editable}
                             onChange={(e) => runInAction(() => this.description = e.target.value)}/>)}
                  </Form.Item>
                </fieldset>
              </>
              :
              <></>
            }
          </Form>
        </Spin>
      </Modal>
    )}
  componentDidMount() {
    if(this.props.courses&&this.props.courses.length===1){
      this.getCourseData(this.props.courses[0].id)
    }
    getCubaREST()!.loadEntities<SerializedEntity<PartyExt>>(PartyExt.NAME,{view:"partyExt.edit"})
      .then(providers=>runInAction(()=>this.providers = providers))
  }
}

export const TaskDetails =  Form.create<TaskDetailsProps & FormComponentProps&MainStoreInjected & WrappedComponentProps & RootStoreProp>()(injectIntl(TaskDetailsComponent))
