import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import QuestionBlock from "../components/Test/QuestionBlock";
import { AnsweredQuestion, TestSectionModel } from "../components/Test/TestComponent";
import { getCubaREST, injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import { RouteComponentProps, withRouter } from "react-router";
import { LearningFeedbackQuestion } from "../../cuba/entities/base/tsadv$LearningFeedbackQuestion";
import { LearningFeedbackTemplateQuestion } from "../../cuba/entities/base/tsadv$LearningFeedbackTemplateQuestion";
import { QuestionModel } from "../components/Test/Question";
import Page from "../hoc/PageContentHoc";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import Section from "../hoc/Section";
import { Card } from "antd";
import Button, { ButtonType } from "../components/Button/Button";
import { RootStoreProp } from "../store";
import { restServices } from "../../cuba/services";
import { AnsweredFeedback } from "../pages/MyCourse/RenderModalBody/Feedback/FeedbackQuestionAnswerComponent";
import Notification from "../util/Notification/Notification";

type RouteProps = RouteComponentProps<{ entityId: string }>;

@inject("rootStore")
@injectMainStore
@observer
class Questionnaire extends Component<RouteProps & WrappedComponentProps & RootStoreProp & MainStoreInjected> {

  @observable
  performingFinishRequest = false;

  @observable questions: TestSectionModel[] = [];

  @observable loading: boolean = true;

  answeredFeedback: AnsweredFeedback = {
    courseId: undefined,
    templateId: this.props.match.params.entityId!,
    questionsAndAnswers: []
  };

  render() {
    return (
      <Page pageName={this.props.intl.formatMessage({id: "homePage.questionnaire"})}>
        <Section size="large">
          <Card className="card-actions-container" bordered={false} actions={[
            <Button htmlType="button" buttonType={ButtonType.FOLLOW} onClick={this.doPass}
                    loading={this.performingFinishRequest}>
              <FormattedMessage id="questionnaire.pass"/>
            </Button>
          ]}>
            <QuestionBlock isSinglePage addRemoveAnswer={this.addRemoveAnswer} testSections={this.questions}/>
          </Card>
        </Section>
      </Page>
    );
  }

  fetchQuestions = () => {
    getCubaREST()!.searchEntities<LearningFeedbackTemplateQuestion>(LearningFeedbackTemplateQuestion.NAME, {
      conditions: [{
        property: "feedbackTemplate.id",
        operator: "=",
        value: this.props.match.params.entityId!
      }]
    }, {
      view: "learningFeedbackTemplateQuestion.edit"
    }).then(learningFeedbackTemplateQuestion => {
      const questionIds = learningFeedbackTemplateQuestion.map(l => {
        return l.feedbackQuestion!.id
      })

      getCubaREST()!.searchEntities<LearningFeedbackQuestion>(LearningFeedbackQuestion.NAME, {
        conditions: [{
          property: "id",
          operator: "in",
          value: questionIds
        }]
      }, {
        view: "question.with.answers"
      }).then(questions => {
        questions.sort((a,b)=>{
          const q1 = learningFeedbackTemplateQuestion.find(i=>(i.feedbackQuestion&&i.feedbackQuestion.id) === a.id)
          const q2 = learningFeedbackTemplateQuestion.find(i=>(i.feedbackQuestion&&i.feedbackQuestion.id) === b.id)
          if(q1 && q2)
          return q1.order! - q2.order!
          return 1
        })
        this.setQuestions([{
          questionsAndAnswers:
            (questions
              .map(q => {
                return {
                  id: q.id,
                  text: q["questionLangValue" + this.getLanguageNumber()],
                  type: q.questionType,
                  answers: q.answers!.map(a => {
                    return {
                      id: a.id,
                      text: a["answerLangValue" + this.getLanguageNumber()]
                    }
                  })
                }
              }) as QuestionModel[])
        }])
      })
      this.setLoading(false);
    }).catch(error => {
      this.setLoading(false);
    });
  }

  componentDidMount(): void {
    this.fetchQuestions()
  }

  @action
  setLoading = (loading: boolean) => {
    this.loading = loading;
  }

  @action
  setQuestions = (questions: TestSectionModel[]) => {
    this.questions = questions;
  }

  @action
  setPerformingFinishRequest = (value: boolean) => {
    this.performingFinishRequest = value;
  };

  addRemoveAnswer = (a: AnsweredQuestion) => {
    for (let i = this.answeredFeedback.questionsAndAnswers!.length - 1; i > -1; i--) {
      if (this.answeredFeedback.questionsAndAnswers![i].questionId === a.questionId) {
        this.answeredFeedback.questionsAndAnswers!.splice(i, 1);
      }
    }
    this.answeredFeedback.questionsAndAnswers!.push(a);
  };

  doPass = () => {
    this.setPerformingFinishRequest(true);
    restServices.lmsService.finishFeedback({
      answeredFeedback: this.answeredFeedback,
      personGroupId: this.props.rootStore!.userInfo.personGroupId!
    }).then(() => {
      Notification.success({
        message: this.props.intl.formatMessage({id: "questionnaire.success"}),
      })
      this.props.history.push("/")
      this.setPerformingFinishRequest(false)
    }).catch((exception) => {
      Notification.error({
        message: this.props.intl.formatMessage({id: "management.editor.error"}),
        description: exception.message
      })
      this.setPerformingFinishRequest(false)
    });
  };

  getLanguageNumber = (): string => {
    switch (this.props.mainStore!.locale!) {
      case "en" : {
        return "3";
      }
      case "kz" : {
        return "2";
      }
      default: {
        return "1";
      }
    }
  }
}

export default withRouter(injectIntl(Questionnaire));