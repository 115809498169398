import * as React from "react";
import {ChangeEvent, FormEvent} from "react";
import {Button, Form, Icon, Input, message} from "antd";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import "./Login.css";
import logo from "../header/logo.png";
import {LanguageSwitcher} from "../../i18n/LanguageSwitcher";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import {RootStoreProp} from "../store";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import Notification from "../util/Notification/Notification";
import {Statistic} from "antd";
import {restServices} from "../../cuba/services";
import {PersonGroupExt} from "../../cuba/entities/base/base$PersonGroupExt";
import {BaseUserExt} from "../../cuba/entities/base/base$UserExt";
import MaskedInput from "antd-mask-input";
import {CUBA_APP_URL} from "../../config";

@injectMainStore
@inject("rootStore")
@observer
class Login extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & RouteComponentProps> {

  @observable performingLoginRequest = false;
  @observable modeMain = 'register';
  @observable mode = 'login';
  @observable iin = '';
  @observable phone = '';
  @observable code = '';
  @observable smsResend = true;
  @observable smsResendTime : number;
  @observable password1 = '';
  @observable password2 = '';
  @observable loginName = '';
  @observable smsTimeout = 300;

  person: PersonGroupExt;
  user: BaseUserExt;

  changeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.rootStore!.login.setLogin(e.target.value);
  };

  changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.rootStore!.login.setPassword(e.target.value);
  };

  changeIin = (e: ChangeEvent<HTMLInputElement>) => {
    this.iin = e.target.value;
  };

  changePhone = (e: ChangeEvent<HTMLInputElement>) => {
    let phone1 = e.target.value.replace(/[^\d;]/g, '');
    if (phone1!='') this.phone = "+"+phone1;
  };

  changeCode = (e: ChangeEvent<HTMLInputElement>) => {
    this.code = e.target.value;
  };

  changePassword1 = (e: ChangeEvent<HTMLInputElement>) => {
    this.password1 = e.target.value;
  };

  changePassword2 = (e: ChangeEvent<HTMLInputElement>) => {
    this.password2 = e.target.value;
  };

  @observable
  changeCodeTime = () => {
    this.smsResend = true;
  };

  @observable
  setSmsCodeTimeoutSec = () => {
    restServices.userRegistrationService.getSmsCodeTimeoutSec()
      .then(value => {
        if (value) this.smsTimeout = value.valueOf()
        else this.smsTimeout = 300})
      .catch(action(() => {this.smsTimeout = 300}));
  }

  getPersonByIin = () => {
    restServices.userRegistrationService.getPersonByIin(this.iin).then(
      value => {
        if (value) {
          this.person = value;
          this.getUserByIin();
        }
      }
    ).catch(
      action(() => {
        Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorIin"})});
      })
    );
  }

  check =():boolean=> {
    return (!this.person!
      || !this.person.person!
      || !this.person.person!.nationalIdentifier!
      || !this.person.mobilePhone!
      || this.person.person!.nationalIdentifier != this.iin
      || this.person.mobilePhone != this.phone);
  }

  getUserByIin = () => {
    restServices.userRegistrationService.getUserByIin(this.iin).then(
      value => {
        if (value) {
          if (this.modeMain=='register') { // новый пользователь
            if (value.active == false) {
              Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorUserIin2"})});
            } else {
              Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorUserIin1"})});
            }
            this.user = value;
          } else { // смена пароля
            if (this.check()) {
              Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorNoUser"})});
            } else {
              this.sendSms()
            }
          }
        }
      }
    ).catch(
      action(() => {
        if (this.modeMain=='register') { // новый пользователь
          if (this.check()) {
            Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorNoUser"})});
          } else {
            this.sendSms()
          }
        } else { // смена пароля
          Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorNoUser2"})});
        }
      })
    );
  }

  sendSms = () => {
    this.smsResend=true;
    restServices.userRegistrationService.sendSms(this.person.id, this.phone).then(
      value => {
        if (!value) {
          Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorSms"})});
        } else {
          this.smsResendTime = Date.now();
          this.mode="sms";
        }
      }
    ).catch(
      action(() => {
        Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorServer"})});
      })
    );
  }

  createUser = () => {
    restServices.userRegistrationService.createUser(this.person.id, this.password1).then(
      value => {
        if (value=="") {
          Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorCreateUser"})});
        } else {
          this.props.rootStore!.login.setLogin(value.valueOf());
          this.props.rootStore!.login.setPassword("");
          this.loginName=value.valueOf();
          this.mode="loginName";
        }
      }
    ).catch(
      action(() => {
        Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorServer"})});
      })
    );
  }

  updatePassword = () => {
    restServices.userRegistrationService.updatePassword(this.person.id, this.password1).then(
      value => {
        if (!value) {
          Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorPassword3"})});
        } else {
          this.mode="login";
        }
      }
    ).catch(
      action(() => {
        Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorServer"})});
      })
    );
  }

  sendSmsClick = () => {
    this.sendSms();
    this.smsResendTime=Date.now();
    this.smsResend=true;
  }

  @action
  doLogin = (e: FormEvent) => {
    e.preventDefault();
    this.performingLoginRequest = true;
    const {login, password} = this.props.rootStore!.login;
    if (!password) {
      Notification.error({
        message: this.props.intl.formatMessage({id: "password.error.empty"})
      });
      this.performingLoginRequest = false;
      return;
    }
    getCubaREST()!.login(login!, password!, {tokenEndpoint: "auth/token"})
      .then(
        action(() => {
          const urlToRedirect = this.props.location.pathname;
          this.props.rootStore!.menu.loadUserMenuCustomization();
          this.props.rootStore!.userInfo.loadUserInfo();
          this.performingLoginRequest = false;
          this.props.mainStore!.userName = login;
          this.props.mainStore!.authenticated = true;
        })
      )
      .catch(
        action(() => {
          this.performingLoginRequest = false;
          message.error(this.props.intl.formatMessage({id: "login.failed"}));
        })
      );
  };

  @action
  doRegister = (e: FormEvent) => {
    e.preventDefault();
    this.props.rootStore!.login.setLogin("");
    this.props.rootStore!.login.setPassword("");
    this.modeMain="register"
    this.getPersonByIin();
  };

  @action
  doSms = (e: FormEvent) => {
    e.preventDefault();
    restServices.userRegistrationService.testSms(this.person.id, this.code).then(
      value => {
        if (!value) {
          Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorSmsCode"})});
        } else {
          this.mode="password";
        }
      }
    ).catch(
      action(() => {
        Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorServer"})});
      })
    );
  };

  @action
  doPassword = (e: FormEvent) => {
    e.preventDefault();
    if (this.password1!=this.password2)
      Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorPassword"})});
    else {
      let regexp = new RegExp(/(?=.*[0-9])(?=.*[#!,%:?*()_+<>/\\])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z#!,%:?*()_+<>/\\]{6,}/g);
      let bb = regexp.test(this.password1);
      if (!bb) {
        Notification.error({message: this.props.intl.formatMessage({id: "login.register.errorPassword2"})});
      } else {
        if (this.modeMain=='register')
          this.createUser()
        else
          this.updatePassword()
      }
    }
  };

  @action
  doLoginName = (e: FormEvent) => {
    e.preventDefault();
    this.mode="login";
  };

  @action
  doRestore = (e: FormEvent) => {
    e.preventDefault();
    this.modeMain="restore"
    this.getPersonByIin()
  };
  render() {
    this.setSmsCodeTimeoutSec()
    if (this.mode=='register') {
      return ( // register
        <div className="login-form">
          <img
            src={logo}
            alt={this.props.intl.formatMessage({id: "common.alt.logo"})}
            className="logo"
          />
          <Form layout="vertical" onSubmit={this.doRegister}>
            <Form.Item>
              <Input
                id="input_iin"
                onChange={this.changeIin}
                value={this.iin}
                placeholder={this.props.intl.formatMessage({id: "login.placeholder.iin"})}
                prefix={<Icon type="user" style={{color: "rgba(0,0,0,.25)"}}/>}
                required={true}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <MaskedInput mask={"+7 (111) 111-1111"}
                id="input_mobile"
                onChange={this.changePhone}
                value={this.phone}
                placeholder={"+7 (___) ___-____"}
                prefix={<Icon type="mobile" style={{color: "rgba(0,0,0,.25)"}}/>}
                required={true}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block={true}
                loading={this.performingLoginRequest}
              >
                <FormattedMessage id="login.continueBtn"/>
              </Button>
            </Form.Item>
            <Form.Item>
              <div style={{textAlign: "center"}}>
                <FormattedMessage id="login.register3"/>
                <Link id="login.register1"
                      onClick={() => this.mode='login'}
                      to={"/"}>
                  {this.props.intl.formatMessage({id: "login.loginBtn"})}
                </Link>
              </div>
            </Form.Item>
          </Form>
        </div>
      )

    } else
      if (this.mode=='sms') {
      const {Countdown} = Statistic;
      return ( // sms code
        <div className="login-form">
          <img
            src={logo}
            alt={this.props.intl.formatMessage({id: "common.alt.logo"})}
            className="logo"
          />
          <Form layout="vertical" onSubmit={this.doSms}>
            <Form.Item>
              <div style={{textAlign: "center"}}>
                <FormattedMessage id="login.register.code1"/>
              </div>
              <div style={{textAlign: "center"}}>
                <FormattedMessage id="login.register.code2"/>
              </div>
            </Form.Item>
            <Form.Item>
              <Input
                id="input_code"
                placeholder={this.props.intl.formatMessage({
                  id: "login.placeholder.code"
                })}
                value={this.code}
                onChange={this.changeCode}
                prefix={<Icon type="font-colors" style={{color: "rgba(0,0,0,.25)"}}/>}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <div style={{textAlign: "center"}}>
                <FormattedMessage id="login.register.code3"/>
                <Countdown value={this.smsResendTime + (this.smsResend ? 1000 * this.smsTimeout : 0)}
                           onFinish={()=>{this.smsResend = false}}/>
                <Button id="login.sendCode"
                        type={"link"}
                        hidden={this.smsResend}
                        onClick={this.sendSmsClick}>
                  <FormattedMessage id="login.sendCode" />
                </Button>
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block={true}
                loading={this.performingLoginRequest}
              >
                <FormattedMessage id="login.continueBtn"/>
              </Button>
            </Form.Item>
          </Form>
        </div>
      )

    } else
      if (this.mode=='password') {
      return ( // password
        <div className="login-form">
          <img
            src={logo}
            alt={this.props.intl.formatMessage({id: "common.alt.logo"})}
            className="logo"
          />
          <Form layout="vertical" onSubmit={this.doPassword}>
            <Form.Item>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.password1"/>
              </div>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.password2"/>
              </div>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.password3"/>
              </div>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.password4"/>
              </div>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.password5"/>
              </div>
            </Form.Item>
            <Form.Item>
              <Input
                id="input_password1"
                placeholder={this.props.intl.formatMessage({id: "login.placeholder.password1"})}
                onChange={this.changePassword1}
                value={this.password1}
                prefix={<Icon type="lock" style={{color: "rgba(0,0,0,.25)"}}/>}
                type="password"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Input
                id="input_password2"
                placeholder={this.props.intl.formatMessage({id: "login.placeholder.password2"})}
                onChange={this.changePassword2}
                value={this.password2}
                prefix={<Icon type="lock" style={{color: "rgba(0,0,0,.25)"}}/>}
                type="password"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block={true}
                loading={this.performingLoginRequest}
              >
                <FormattedMessage id="login.continueBtn"/>
              </Button>
            </Form.Item>
          </Form>
        </div>
      )

    } else
      if (this.mode=='loginName') {
      return ( // login name
        <div className="login-form">
          <img
            src={logo}
            alt={this.props.intl.formatMessage({id: "common.alt.logo"})}
            className="logo"
          />
          <Form layout="vertical" onSubmit={this.doLoginName}>
            <Form.Item>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.loginName1"/>
              </div>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.loginName2"/>
              </div>
              <div style={{textAlign: "left"}}>
                <FormattedMessage id="login.register.loginName3"/>
              </div>
            </Form.Item>
            <Form.Item>
              <Input
                id="input_login"
                readOnly={true}
                placeholder={this.props.intl.formatMessage({id: "login.placeholder.login"})}
                // onChange={this.changeLoginName}
                value={this.loginName}
                prefix={<Icon type="user" style={{color: "rgba(0,0,0,.25)"}}/>}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block={true}
                loading={this.performingLoginRequest}
              >
                <FormattedMessage id="login.startPage"/>
              </Button>
            </Form.Item>
          </Form>
        </div>
      )

    } else
      if (this.mode=='restore') {
        return ( // restore
          <div className="login-form">
            <img
              src={logo}
              alt={this.props.intl.formatMessage({id: "common.alt.logo"})}
              className="logo"
            />
            <Form layout="vertical" onSubmit={this.doRestore}>
              <Form.Item>
                <div style={{textAlign: "left"}}>
                  <FormattedMessage id="login.register.restore1"/>
                </div>
                <div style={{textAlign: "left"}}>
                  <FormattedMessage id="login.register.restore2"/>
                </div>
              </Form.Item>
              <Form.Item>
                <Input
                  id="input_iin"
                  required={true}
                  placeholder={this.props.intl.formatMessage({id: "login.placeholder.iin"})}
                  onChange={this.changeIin}
                  defaultValue={""}
                  value={this.iin}
                  prefix={<Icon type="user" style={{color: "rgba(0,0,0,.25)"}}/>}
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <MaskedInput mask={"+7 (111) 111-1111"}
                  id="input_mobile"
                  required={true}
                  placeholder={"+7 (___) ___-____"}
                  onChange={this.changePhone}
                  defaultValue={""}
                  value={this.phone}
                  prefix={<Icon type="mobile" style={{color: "rgba(0,0,0,.25)"}}/>}
                  size="large"
                />

              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  block={true}
                  loading={this.performingLoginRequest}
                >
                  <FormattedMessage id="login.continueBtn"/>
                </Button>
              </Form.Item>
              <Form.Item>
                <div style={{textAlign: "center"}}>
                  <Link id="login.register1"
                        onClick={() => this.mode='login'}
                        to={"/"}>
                    {this.props.intl.formatMessage({id: "login.register.toMain"})}
                  </Link>
                </div>
              </Form.Item>
            </Form>
          </div>
        )

      } else {
      return ( // login
        <div className="login-form">
          <img
            src={logo}
            alt={this.props.intl.formatMessage({id: "common.alt.logo"})}
            className="logo"
          />
          <Form layout="vertical" onSubmit={this.doLogin}>
            <Form.Item>
              <Input
                id="input_login"
                placeholder={this.props.intl.formatMessage({id: "login.placeholder.login"})}
                onChange={this.changeLogin}
                value={this.props.rootStore!.login.login}
                prefix={<Icon type="user" style={{color: "rgba(0,0,0,.25)"}}/>}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Input
                id="input_password"
                placeholder={this.props.intl.formatMessage({id: "login.placeholder.password"})}
                onChange={this.changePassword}
                value={this.props.rootStore!.login.password}
                type="password"
                prefix={<Icon type="lock" style={{color: "rgba(0,0,0,.25)"}}/>}
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <div style={{float: "right"}}>
                <LanguageSwitcher className="language-switcher"/>
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block={true}
                loading={this.performingLoginRequest}
              >
                <FormattedMessage id="login.loginBtn"/>
              </Button>
            </Form.Item>
            <Form.Item>
              <FormattedMessage id="login.register1"/>
              <Link id="login.register1"
                    onClick={() => this.mode='register'}
                    to={"/"}>
                <div style={{textAlign: "center"}}>
                  {this.props.intl.formatMessage({id: "login.register.link"})}
                </div>
              </Link>
            </Form.Item>
            <Form.Item>
              <div style={{textAlign: "center"}}>
                <FormattedMessage id="login.register2"/>
                <Link id="login.register1"
                      onClick={() => this.mode='restore'}
                      to={"/"}>
                  {this.props.intl.formatMessage({id: "login.register.restore"})}
                </Link>
              </div>
            </Form.Item>
          </Form>
          <div style={{textAlign: "center", paddingBottom: '24px'}}>
            <a href={CUBA_APP_URL + 'sso/sso'}>
              <FormattedMessage id="login.SSO"/>
            </a>
          </div>
        </div>
      );
    }
  }
}

export default injectIntl(withRouter(Login));
