import RootStore from "./RootStore";
import { action, observable } from "mobx";
import { restServices } from "../../cuba/services";
import { BellNotification } from "./BellNotificationStore";
import { getCubaREST } from "@cuba-platform/react";
import { LearningFeedbackTemplate } from "../../cuba/entities/base/tsadv$LearningFeedbackTemplate";
import { LearningFeedbackUsageType } from "../../cuba/enums/enums";
import { SerializedEntity } from "@cuba-platform/rest";
import { PersonProfile } from "../pages/MyTeam/MyTeamCard";


class PersonProfileStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable personProfile:PersonProfile;

  @observable imageUrl:string

 @action.bound loadPersonProfile = (id:string) => {
    restServices.employeeService
    .personProfile(id)
    .then(value => {
      this.personProfile = value;
      if (this.personProfile && this.personProfile.imageId)
        getCubaREST()!
          .getFile(this.personProfile.imageId)
          .then((value: Blob) => (this.imageUrl = URL.createObjectURL(value)));
    })
    .catch((err) => {
      console.log(err)
    });
  };
}

export default PersonProfileStore;