import * as React from "react";
import { createElement } from "react";
import { Card, Col, Form, Input, Row } from "antd";
import { inject, observer } from "mobx-react";
import { FormattedMessage, injectIntl } from "react-intl";

import {
  collection,
  getCubaREST, getEnumCaption, getPropertyInfoNN,
  injectMainStore,
  instance,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../app/App.css";
import { PersonExt } from "../../../cuba/entities/base/base$PersonExt";
import { PersonalDataRequest } from "../../../cuba/entities/base/tsadv$PersonalDataRequest";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import { observable } from "mobx";
import { ReadonlyField } from "../../components/ReadonlyField";
import moment from "moment";
import DefaultDatePicker from "../../components/Datepicker";
import Button, { ButtonType } from "../../components/Button/Button";
import { withRouter } from "react-router";
import Section from "../../hoc/Section";
import Page from "../../hoc/PageContentHoc";
import MsgEntity from "../../components/MsgEntity";
import { DicRequestStatus } from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import { goBackOrHomePage } from "../../util/util";
import { restServices } from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";
import { PersonProfile } from "../MyTeam/MyTeamCard";
import {PersonOtherInfoRequestManagement} from "./PersonOtherInfoRequestManagement";
import {PersonOtherInfoRequest} from "../../../cuba/entities/base/tsadv_PersonOtherInfoRequest";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

type EditorProps = {
  entityId: string;
};

@inject("rootStore")
@injectMainStore
@observer
class PersonOtherInfoRequestEditComponent extends AbstractBprocEdit<
  PersonalDataRequest,
  EditorProps
  > {
  processDefinitionKey = "personOtherInfoRequest";

  dataInstance = instance<PersonOtherInfoRequest>(PersonOtherInfoRequest.NAME, {
    view: "personOtherInfoRequest.edit",
    loadImmediately: false
  });

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  @observable
  person: PersonExt;


  @observable
  changedMap = new Map<string, boolean>();

  isUpdateBeforeOutcome = true;

  fields = [
    "requestNumber",

    "requestDate",

    "status",

    "childUnder18WithoutFatherOrMother",

    "childUnder14WithoutFatherOrMother",

    "attachments"
  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.props.rootStore!.userInfo!.personGroupId,
      ...super.getUpdateEntityData()
    };
  }

  render() {
    const isNotDraft = this.isNotDraft();
    return (
      <div>
        <ScrollToTopOnMount />
        <Page pageName={<MsgEntity entityName={PersonOtherInfoRequest.NAME} />}>
        <Section size="large">
          <Card
            className="narrow-layout card-actions-container"
            bordered={false}
            actions={[
              <Button
                buttonType={ButtonType.FOLLOW}
                onClick={() => goBackOrHomePage(this.props.history!)}
              >
                {this.props.intl.formatMessage({ id: "close" })}
              </Button>,
              this.getOutcomeBtns()
            ]}
          >
            <Form layout="vertical">
                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="requestNumber"
                    form={this.props.form}
                    formItemOpts={{ style: { marginBottom: "12px" } }}
                    disabled={true}
                  />

                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="status"
                    form={this.props.form}
                    formItemOpts={{ style: { marginBottom: "12px" } }}
                    optionsContainer={this.statusesDc}
                    disabled={true}
                  />
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "personGroup"
                })}
              >
                <Input
                  value={this.person ? this.person["_instanceName"] || "" : ""}
                  disabled
                />
              </Form.Item>
              <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="requestDate"
                form={this.props.form}
                disabled={true}
                formItemOpts={{ style: { marginBottom: "12px" } }}
              />


              <Row type={"flex"} className={"data-form"}>
                <Col md={24} sm={24} lg={12}>
                  <div className={"section-header-container"}>
                    <FormattedMessage id={"currentValue"} />
                  </div>

                  <Form.Item
                    label={<FormattedMessage id={"personOtherInfoRequest.childUnder18WithoutFatherOrMother"} />}
                  >
                    <Input
                      value={this.person ? getEnumCaption(this.person.childUnder18WithoutFatherOrMother, getPropertyInfoNN("childUnder18WithoutFatherOrMother", PersonExt.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!) || "" : ""}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item
                    label={<FormattedMessage id={"personOtherInfoRequest.childUnder14WithoutFatherOrMother"} />}
                  >
                    <Input
                      value={this.person ? getEnumCaption(this.person.childUnder14WithoutFatherOrMother, getPropertyInfoNN("childUnder14WithoutFatherOrMother", PersonExt.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!) || "" : ""}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} lg={12}>
                  <div className={"section-header-container"}>
                    <FormattedMessage id={"newValue"} />
                  </div>

                  <ReadonlyField
                    entityName={PersonExt.NAME}
                    propertyName="childUnder18WithoutFatherOrMother"
                    disabled={isNotDraft}
                    getFieldDecoratorOpts={{
                      getValueFromEvent: args => {
                        if (!args) return args;
                        this.changedMap.set(
                          "childUnder18WithoutFatherOrMother",
                          args !== this.person.childUnder18WithoutFatherOrMother
                        );
                        return args;
                      }
                    }}
                    form={this.props.form}
                    formItemOpts={{
                      label:this.props.intl.formatMessage({id:"personOtherInfoRequest.childUnder18WithoutFatherOrMother"}),
                      hasFeedback: this.changedMap.get("childUnder18WithoutFatherOrMother")
                    }}
                  />
                  <ReadonlyField
                    entityName={PersonExt.NAME}
                    propertyName="childUnder14WithoutFatherOrMother"
                    form={this.props.form}
                    getFieldDecoratorOpts={{
                      getValueFromEvent: args => {
                        if (!args) return args;
                        this.changedMap.set(
                          "childUnder14WithoutFatherOrMother",
                          args !== this.person.childUnder14WithoutFatherOrMother
                        );
                        return args;
                      }
                    }}
                    disabled={isNotDraft}
                    formItemOpts={{
                      label:this.props.intl.formatMessage({id:"personOtherInfoRequest.childUnder14WithoutFatherOrMother"}),
                      hasFeedback: this.changedMap.get("childUnder14WithoutFatherOrMother")
                    }}
                  />

                </Col>

                <ReadonlyField
                  entityName={this.dataInstance.entityName}
                  propertyName="attachments"
                  form={this.props.form}
                  disabled={isNotDraft}
                  getFieldDecoratorOpts={{
                    rules: [
                      {
                        required: true,
                        message: this.props.intl.formatMessage(
                          { id: "form.validation.required" },
                          {
                            fieldName: this.mainStore.messages![
                            this.dataInstance.entityName + ".attachments"
                              ]
                          }
                        )
                      }
                    ]
                  }}
                />
              </Row>
            </Form>

            {this.takCard()}
          </Card>
        </Section>
      </Page>
      </div>
    );
  }

  onReactionDisposerEffect = (item: PersonOtherInfoRequest | undefined) => {
    const requestDate =
      item && item.requestDate ? item.requestDate : moment().toISOString();
    getCubaREST()!
      .searchEntities<PersonExt>(
        PersonExt.NAME,
        {
          conditions: [
            {
              property: "group.id",
              operator: "=",
              value:
                item && item.personGroup
                  ? item.personGroup.id!
                  : this.props.rootStore!.userInfo!.personGroupId!
            },
            {
              property: "startDate",
              operator: "<=",
              value: requestDate
            },
            {
              property: "endDate",
              operator: ">=",
              value: requestDate
            }
          ]
        },
        {
          view: "person-edit"
        }
      )
      .then(value => value[0])
      .then(value => (this.person = value))
      .then(value => {
        if (
          value &&
          this.props.entityId === PersonOtherInfoRequestManagement.NEW_SUBPATH
        ) {
          this.props.form.setFieldsValue({
            childUnder18WithoutFatherOrMother:value.childUnder18WithoutFatherOrMother,
            childUnder14WithoutFatherOrMother:value.childUnder14WithoutFatherOrMother
          });
        } else if (item && value) {
          this.changedMap.set("childUnder18WithoutFatherOrMother", item.childUnder18WithoutFatherOrMother !== value.childUnder18WithoutFatherOrMother);
          this.changedMap.set("childUnder14WithoutFatherOrMother", item.childUnder14WithoutFatherOrMother !== value.childUnder14WithoutFatherOrMother);
        }
      });
  };
}

export default injectIntl(
  withLocalizedForm({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(PersonOtherInfoRequestEditComponent))
);
