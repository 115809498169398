import {MyTeamData} from "../pages/MyTeam/MyTeamComponent";
import RootStore from "./RootStore";
import {action} from "mobx";
import { DicCompany } from "../../cuba/entities/base/base_DicCompany";
import { Moment } from "moment";
import { SerializedEntity } from "@cuba-platform/rest";

export default class RecognitionStore{
  rootStore: RootStore;

  selectedCompany?: SerializedEntity<DicCompany> | null;
  selectedYear?: Moment | null;
  selectedMainTab: string;
  selectedMedalTab: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  clearRecognitionStore = () => {
    this.selectedCompany = undefined;
    this.selectedYear = undefined;
  };

  @action
  setCompany = (company: SerializedEntity<DicCompany> | null) => {
    this.selectedCompany = company;
  };

  @action
  setYear = (year: Moment | null) => {
    this.selectedYear = year;
  };

  @action
  setMainTab = (key: string) => {
    this.selectedMainTab = key;
  };

  @action
  setMedalTab = (key: string) => {
    this.selectedMedalTab = key;
  };
}