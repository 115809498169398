import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {observable} from "mobx";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import Button, {ButtonType} from "../../components/Button/Button";
import {RootStoreProp} from "../../store";
import {PersonExperienceRequestManagement} from "./PersonExperienceRequestManagement";
import {PersonExperience} from "../../../cuba/entities/base/tsadv$PersonExperience";
import DataTableFormat from "../../components/DataTable/intex";

interface Props{
  personGroupId:string
}

@injectMainStore
@inject("rootStore")
@observer
class PersonEducationListComponent extends React.Component<Props&MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  dataCollection = collection<PersonExperience>(PersonExperience.NAME, {
    view: "personExperience.full",
    sort: "-updateTs",
    filter: {
      conditions: [{
        property: "personGroup.id",
        operator: "=",
        value: this.props.personGroupId
      }]
    }

  });

  fields = [

    "startMonth",

    "endMonth",

    "job",

    "company"

  ]
  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
      <Link
        to={
          PersonExperienceRequestManagement.PATH +
          "/" +
          PersonExperienceRequestManagement.NEW_SUBPATH
        }
        key="create"
      >
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}
        >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>,
      <Link
        to={PersonExperienceRequestManagement.PATH+ "/" + PersonExperienceRequestManagement.NEW_SUBPATH + "/" + this.selectedRowKey}
        key="edit"
      >
        <Button buttonType={ButtonType.FOLLOW}
                style={{margin: "0 12px 12px 0"}}
                disabled={!this.selectedRowKey}
        >
          <FormattedMessage id="management.browser.edit"/>
        </Button>
      </Link>
    ];

    return (
      <DataTableFormat
        dataCollection={this.dataCollection}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const PersonExperienceList = injectIntl(PersonEducationListComponent);

export default PersonExperienceList;