import { AbstractParentEntity } from "./AbstractParentEntity";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { AssessmentTemplate } from "./tsadv$AssessmentTemplate";
import { DicAssessmentStatus } from "./tsadv$DicAssessmentStatus";
import { PositionGroupExt } from "./base$PositionGroupExt";
export class PersonAssessment extends AbstractParentEntity {
  static NAME = "tsadv_PersonAssessment";
  personGroup?: PersonGroupExt | null;
  assessmentTemplate?: AssessmentTemplate | null;
  assessmentResult?: any | null;
  assessmentStatus?: DicAssessmentStatus | null;
  positionGroup?: PositionGroupExt | null;
}
export type PersonAssessmentViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personAssessment-for-apab"
  | "personAssessment-for-assessment-template-edit"
  | "personAssessment-for-person-card";
export type PersonAssessmentView<
  V extends PersonAssessmentViewName
> = V extends "_base"
  ? Pick<
      PersonAssessment,
      | "id"
      | "personGroup"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      PersonAssessment,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_minimal"
  ? Pick<PersonAssessment, "id" | "personGroup">
  : V extends "personAssessment-for-apab"
  ? Pick<
      PersonAssessment,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "personGroup"
      | "assessmentTemplate"
      | "assessmentStatus"
      | "positionGroup"
    >
  : V extends "personAssessment-for-assessment-template-edit"
  ? Pick<
      PersonAssessment,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "personGroup"
      | "assessmentTemplate"
      | "assessmentStatus"
      | "positionGroup"
    >
  : V extends "personAssessment-for-person-card"
  ? Pick<
      PersonAssessment,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "updateTs"
      | "personGroup"
      | "assessmentTemplate"
      | "assessmentStatus"
    >
  : never;
