import * as React from "react";
import {createElement} from "react";
import {Card, Col, Form, Input, InputNumber, Row, Select, Spin, Tag} from "antd";
import {inject, observer} from "mobx-react";
import {VacancyRequestManagement} from "./VacancyRequestManagement";
import {Redirect} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";

import {
  collection,
  getCubaREST,
  injectMainStore,
  instance,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../App.css";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import LoadingPage from "../LoadingPage";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {downloadFile, goBackOrHomePage} from "../../util/util";
import {VacancyRequest} from "../../../cuba/entities/base/tsadv_VacancyRequest";
import FormContainer from "../../common/FormContainer";
import {PositionGroupExt} from "../../../cuba/entities/base/base$PositionGroupExt";
import {serviceCollection} from "../../util/ServiceDataCollectionStore";
import {restServices} from "../../../cuba/services";
import moment from "moment";
import {observable, runInAction} from "mobx";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import TextArea from "antd/es/input/TextArea";
import {JobDescription} from "../../../cuba/entities/base/tsadv_JobDescription";
import {TsadvUser} from "../../../cuba/entities/base/tsadv$UserExt";
import {SerializedEntity} from "@cuba-platform/rest";
import {Successor} from "../../../cuba/entities/base/tsadv$Successor";
import {UserRole} from "../../../cuba/entities/base/sec$UserRole";
import {SuccessionPlanning} from "../../../cuba/entities/base/tsadv$SuccessionPlanning";
import {AssignmentExt} from "../../../cuba/entities/base/base$AssignmentExt";
import {DataCollectionStore} from "@cuba-platform/react/dist/data/Collection";

export type PositionInfo = {
  positionId: string;
  positionGroupId: string;
  organizationGroupId: string;
  positionName: string;
  organizationName: string;
  company: string;
  departmentName?: string;
  managementName?: string;
  place?: string;
  fte?: number;
  parentPositionName?: string;
  parentPositionGroupId?: string;
}

type EditorProps = {
  entityId: string;
}

@inject("rootStore")
@injectMainStore
@observer
class VacancyRequestEditComponent extends AbstractBprocEdit<VacancyRequest, EditorProps> {

  processDefinitionKey = "vacancyRequest";

  isUpdateBeforeOutcome = true;

  dataInstance = instance<VacancyRequest>(VacancyRequest.NAME, {
    view: "portal.vacancyRequest-edit",
    loadImmediately: false,
  });

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal",
  });

   personGroupDc2 = serviceCollection<PersonGroupExt>(
     Pagination => restServices.employeeService.getEmployees({date: this.getDate(), view: '_minimal'}), PersonGroupExt.NAME)


 @observable positionGroupDc:PositionGroupExt[];

    reservistDc = serviceCollection<PersonGroupExt>(
    async Pagination => restServices.successorService.getSuccessorsByPosition({
      positionGroupId: this.positionGroupId,
      date: this.getDate()
    }),
    PersonGroupExt.NAME);

  @observable usersDc:TsadvUser[]=[];
  @observable
  myArr : any = [];

  @observable
  periodEnable = true;

  @observable
  newPosition:boolean|undefined;

  @observable
  recruiterEnable = false;

  @observable
  jobDescription: JobDescription;
  @observable
  vacancyAmount: number

  positionGroupId: string;

  approverTaskCode: string;

  argData : string;
  @observable
  checked:boolean|undefined
  @observable newPosReq:boolean =true;
  @observable replacementReq:boolean = true;

  fields = [
    "requestNumber",

    "requestDate",

    "status",

    "files",

    "workingStartDate",

    "vacancyResponsibleManager",

    "vacancyAmount",

    "positionGroup",

    "newPosition",

    "crucialPosition",

    "recruiter",

    "replacement",

    "operatingMode",

    "substituter",

    "temporary",

    "permanent",

    "period",

    "vacancyType",

    "justification"
  ];

  @observable
  positionInfo?: PositionInfo;

  @observable
  notMatchAvailableVacancies = false;

  @observable
  reservists: PersonGroupExt[] = [];
  @observable
  filterVal:string = ""
  @observable
  successors =collection<Successor>(Successor.NAME,{
    view:"successor-view",
    filter:{
      conditions:[{
        property: 'personGroup.id',
        operator: '=',
        value: this.filterVal
      }]
    }
  });
  @observable
  successionPlaining : SerializedEntity<SuccessionPlanning>| Promise<void>

  @observable
  assigments: DataCollectionStore<AssignmentExt>

  @observable positionGroupDcId:string;

  getDate = () => this.date.format(JSON_DATE_TIME_FORMAT);
  date = moment();
  getUpdateEntityData = (): any => {
    const data:VacancyRequest = {...this.props.form.getFieldsValue(this.fields)}

    if(this.props.entityId==="new"||!this.isNotDraftVRE()) {
      data.positionGroup = {id:this.positionGroupId}
      if (data.replacement===undefined||data.replacement===null){

        data.replacement = false;
      }
      if (data.newPosition===undefined||data.newPosition===null){
        data.newPosition = false;
      }
      return data
    }
    else {
      data.vacancyResponsibleManager = this.dataInstance.item && this.dataInstance.item.vacancyResponsibleManager
      data.positionGroup = {id:this.positionGroupId}
      data.substituter = this.dataInstance.item&&this.dataInstance.item.substituter;
      return data
    }
  };

  loadPositionInfo = () => {
    restServices.positionService.getPositionInfo(this.positionGroupId, this.getDate()).then(value => {
      this.positionInfo = value;
      const vacancyAmount = this.props.form.getFieldValue('vacancyAmount');
      this.notMatchAvailableVacancies = !!(vacancyAmount && this.positionInfo && this.positionInfo.fte != undefined && parseInt(vacancyAmount) > this.positionInfo.fte);
    })
  }

  loadReservists = () => {
    (async () => {
      await this.reservistDc.load();
      this.reservists = this.reservistDc.items.slice();
   this.myArr = this.reservists.map(value =>
   {
     this.filterVal = value.id;
     return this.successors =collection<Successor>(Successor.NAME,{
       view:"successor-view",
       filter:{
         conditions:[{
           property: 'personGroup.id',
           operator: '=',
           value: this.filterVal
         }]
       }
     });
   })
    })()
  }

  loadSuccessionPlaning = (positionGroupId:string) => {
    this.successionPlaining = getCubaREST()!.searchEntities<SuccessionPlanning>(SuccessionPlanning.NAME, {
          conditions:[{
            property: 'positionGroup.id',
            operator: '=',
            value: positionGroupId
          }]
      },{
      view: "_minimal",
    }).then(value => {
        if(value.length>0){this.props.form.setFieldsValue({'crucialPosition': true})}
    else{this.props.form.setFieldsValue({'crucialPosition': false})}});
    }

  loadAssigments = (positionGroupId:string) => {
    getCubaREST()!.searchEntities<AssignmentExt>(AssignmentExt.NAME, {
        conditions: [{
          property: 'positionGroup.id',
          operator: '=',
          value:positionGroupId
        }, {
          property: "startDate",
          operator: '<',
          value: this.getDate()
        }, {
          property: "endDate",
          operator: '>',
          value: this.getDate()
        }]
      }, {
        view: "assignment.view",
      }).then(value => {
        this.assigments = collection<PersonGroupExt>(PersonGroupExt.NAME,{
          view:"_minimal",
          filter:{
            conditions:[{
              property: 'id',
              operator: 'in',
              value: value.map(item => item.personGroup&&item.personGroup.id)
            }]
          }
        })
    })

  }


  loadJobDescription = () => {
    getCubaREST()!.searchEntities<JobDescription>(JobDescription.NAME, {
      conditions: [{
        property: 'positionGroup.id',
        operator: '=',
        value: this.positionGroupId
      }]
    }, {
      limit: 1,
      view: 'portal-jobDescription'
    }).then(value => value[0])
      .then(value => this.jobDescription = value);
  }

  onChangePositionOrDate = () => {
    this.personGroupDc2.load();
    this.loadReservists();
    this.loadPositionInfo();
    this.loadJobDescription();
    this.loadSuccessionPlaning(this.positionGroupId);
    this.loadAssigments(this.positionGroupId);
  }

  render() {
      if (!this.dataInstance) {
        return <LoadingPage/>
      }
      if (this.updated) {
        return <Redirect to={VacancyRequestManagement.PATH}/>;
      }
      const isNotDraft = this.isNotDraftVRE();
      const messages = this.mainStore.messages!;
      console.log(isNotDraft,!this.checked, this.newPosition)
      if (!messages) return <LoadingPage/>
      return (
        <Page pageName={this.props.intl.formatMessage({id: "vacancyRequest"})}>
          <Spin spinning={this.dataInstance.status!=="DONE"}>
          <div>
            <Card className="narrow-layout large-section section-container"
                  bordered={false}>

              <Form layout="vertical" className="compact-form">
                <FormContainer>
                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="requestNumber"
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        disabled
                      />
                    </Col>
                    <Col md={24} lg={12}>

                        <ReadonlyField
                          entityName={this.dataInstance.entityName}
                          propertyName="status"
                          disabled
                          form={this.props.form}
                          formItemOpts={{style: {marginBottom: "12px"}}}
                          optionsContainer={this.statusesDc}
                        />

                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="requestDate"
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        disabled
                      />
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item style={{marginBottom: "12px"}}
                                 label={<FormattedMessage id={'company'}/>}>
                        <Input disabled value={this.positionInfo ? this.positionInfo.company : ''}/>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="newPosition"
                        disabled={isNotDraft}
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        getFieldDecoratorOpts={{valuePropName: 'checked',
                          rules:[{
                            required: this.newPosReq,
                            message: this.props.intl.formatMessage({id:"vacancyRequestCheckboxError"})
                          }],
                          getValueFromEvent:value=>{
                            this.newPosition = value.target.checked
                            if(value.target.checked === true){
                              this.replacementReq = false
                            }
                            if(value.target.checked === false){
                              if(this.checked !== true){
                                this.replacementReq = true
                              }
                              this.newPosition = undefined
                            }
                            this.props.form.setFieldsValue({"replacement":undefined,"substituter":undefined});
                            this.checked = undefined
                            return this.newPosition
                          }}}
                      />
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="replacement"
                        disabled={isNotDraft||this.newPosition}
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        getFieldDecoratorOpts={{valuePropName: 'checked',
                          rules:[{
                            required: this.replacementReq,
                            message: this.props.intl.formatMessage({id:"vacancyRequestCheckboxError"})
                          }],
                          getValueFromEvent:value => {
                            this.checked = value.target.checked
                            if(value.target.checked===true){
                              this.newPosReq = false
                            }
                            if(value.target.checked===false){
                              if(this.newPosition !== true){
                                this.newPosReq = true
                              }
                              this.checked = undefined
                              this.props.form.setFieldsValue({"substituter":undefined});
                            }
                            return this.checked
                          }}}
                      />
                    </Col>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="crucialPosition"
                        disabled={true}
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        getFieldDecoratorOpts={{valuePropName: 'checked'}}
                      />
                      { this.props.entityId ==="new" || !isNotDraft?
                        <ReadonlyField
                          entityName={this.dataInstance.entityName}
                          propertyName="substituter"
                          disabled={isNotDraft || !this.checked || this.newPosition}
                          form={this.props.form}
                          formItemOpts={{
                            style: {marginBottom: "12px"},
                            label: this.props.intl.formatMessage({id: "substituterFullName"})
                          }}
                          optionsContainer={this.assigments}
                          getFieldDecoratorOpts={{
                            rules: [{
                              required: this.checked,
                              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.substituter']})
                            }],
                          }}/>:
                        <Form.Item style={{marginBottom: "12px"}}
                                   label={<Msg entityName={VacancyRequest.NAME} propertyName={"substituter"}/>}>
                          <Input disabled={isNotDraft || !this.checked || this.newPosition}
                                 value={this.dataInstance&&this.dataInstance.item&&this.dataInstance.item.substituter ?this.dataInstance.item.substituter['_instanceName']  : ''}/>
                        </Form.Item>
                      }
                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      {this.props.entityId==="new" || !isNotDraft?
                        <Form.Item style={{marginBottom: "12px"}}
                                   label={<Msg entityName={VacancyRequest.NAME} propertyName={"positionGroup"}/>}>
                          <Select
                            value={this.positionGroupId}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              (option.props.children as string || '').toLowerCase().indexOf((input || '').toLowerCase()) >= 0
                            }
                            onChange={(value:string )=> runInAction(()=>{
                                if (value) {
                                  this.props.form.setFieldsValue({'substituter': undefined});
                                  this.positionGroupId = value;
                                  this.onChangePositionOrDate();
                                } else this.positionInfo = undefined;
                              return value;
                            })
                            }>
                            {this.positionGroupDc&&this.positionGroupDc&&this.positionGroupDc.map(i=>
                               <Select.Option key={i.id}>{`${i['_instanceName']} (${i.organizationGroup&&i.organizationGroup.organizationName})`}</Select.Option>
                            )}
                          </Select>
                        </Form.Item>:
                        <Form.Item label={<Msg entityName={this.dataInstance.entityName} propertyName="positionGroup"/>}>
                          <Input disabled value={this.dataInstance.item&&this.dataInstance.item.positionGroup&&this.dataInstance.item.positionGroup.organizationGroup&&this.dataInstance.item.positionGroup.organizationGroup.organizationName?
                            `${this.dataInstance.item.positionGroup['_instanceName']} (${this.dataInstance.item.positionGroup.organizationGroup.organizationName})`:""}/>
                        </Form.Item>
                      }
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item style={{marginBottom: "12px"}}
                                 label={<FormattedMessage id={'department'}/>}>
                        <Input disabled value={this.positionInfo ? this.positionInfo.departmentName : ''}/>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <Form.Item style={{marginBottom: "12px"}}
                                 label={<FormattedMessage id={'position.location'}/>}>
                        <Input disabled value={this.positionInfo ? this.positionInfo.place : ''}/>
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item style={{marginBottom: "12px"}}
                                 label={<FormattedMessage id={'control'}/>}>
                        <Input disabled value={this.positionInfo ? this.positionInfo.managementName : ''}/>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <Form.Item style={{marginBottom: "12px"}}
                                 label={<FormattedMessage id="vacancyAmount"/>}>
                        {this.props.form.getFieldDecorator("vacancyAmount",
                          {
                            rules: [{
                              required: true,
                              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.mainStore.messages![this.dataInstance.entityName + '.vacancyAmount']})
                            }],
                            getValueFromEvent: args => {
                              const value = args;
                              this.notMatchAvailableVacancies = !!(value && this.positionInfo && this.positionInfo.fte != undefined && parseInt(value) > this.positionInfo.fte);
                              return value;
                            }
                          })(
                          <InputNumber min={0} disabled={isNotDraft}/>
                        )}
                        <div style={{color: "#deb410", marginBottom: "15px"}}>{this.notMatchAvailableVacancies ?
                          <FormattedMessage id={'position.vacancyAmount.not.match.warning'}/> : <></>}</div>
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item style={{marginBottom: "12px"}}
                                 label={<FormattedMessage id={'organization'}/>}>
                        <Input disabled value={this.positionInfo ? this.positionInfo.organizationName : ''}/>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="operatingMode"
                        disabled={isNotDraft}
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                      />
                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item style={{marginBottom: "12px"}}
                                 label={<FormattedMessage id={'functional.subordination'}/>}>
                        <Input disabled value={this.positionInfo ? this.positionInfo.parentPositionName : ''}/>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="workingStartDate"
                        disabled={isNotDraft}
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        getFieldDecoratorOpts={{
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.workingStartDate']})
                          }],
                          getValueFromEvent: args => {
                            this.date = args ? args : moment();
                            return args;
                          }
                        }}
                      />
                    </Col>

                    <Col md={24} lg={12}>
                      {this.props.entityId === "new" || !isNotDraft?
                        <Spin spinning={this.personGroupDc2.status === "LOADING"}>
                          <ReadonlyField
                            entityName={this.dataInstance.entityName}
                            propertyName="vacancyResponsibleManager"
                            disabled={isNotDraft}
                            form={this.props.form}
                            formItemOpts={{style: {marginBottom: "12px"}}}
                            optionsContainer={this.personGroupDc2}
                            style={{visibility: this.personGroupDc2.status === "LOADING" ? "hidden" : "visible"}}
                            getFieldDecoratorOpts={{
                              rules: [{
                                required: true,
                                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.vacancyResponsibleManager']})
                              }]
                            }}
                          />
                        </Spin>:
                        <Form.Item label={<Msg entityName={this.dataInstance.entityName} propertyName="vacancyResponsibleManager"/>}>
                          <Input disabled value={this.dataInstance.item&&this.dataInstance.item.vacancyResponsibleManager&&this.dataInstance.item.vacancyResponsibleManager['_instanceName']}/>
                        </Form.Item>
                      }
                    </Col>

                  </Row>

                </FormContainer>
              </Form>
            </Card>

            <Card className="narrow-layout large-section section-container">

              <Form layout="vertical" className="compact-form">
                <FormContainer>
                  <Row className={"form-row"}>
                    <Col  md={24} lg={12}>
                        <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="vacancyType"
                        disabled={isNotDraft}
                        form={this.props.form}
                        getFieldDecoratorOpts={{
                        rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.vacancyType']})
                      }]
                      }}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        />

                    </Col>
                    <Col md={24} lg={12}>
                      <Form.Item>
                        <Input.Group compact>
                          <Form.Item style={{marginRight:"12px",marginBottom: "12px", width:"60%"}}
                                     label={<FormattedMessage id={'reservists'}/>}>
                            {this.reservists.length === 0
                              ? <Input disabled/>
                              : this.reservists.map(value => <Input disabled value={value['_instanceName']}/>)
                            }
                          </Form.Item>
                          <Form.Item style={{marginBottom: "12px", width:"30%"}}
                                     label= {<FormattedMessage id={'readinessLevel'}/>}
                          >
                            {this.myArr.length === 0
                              ? <Input disabled/>
                              : this.myArr.map((value:any) => <Input style={{width:"auto"}} disabled value={value.status === "DONE" && value.items[0].readinessLevel &&value.items[0].readinessLevel? value.items[0].readinessLevel['_instanceName'] : ""}/>)
                            }
                          </Form.Item>
                        </Input.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row >
                    <Col md={48} lg={24}>
                    <Input.Group compact={true}>
                    <Form.Item style={{marginRight:"20px"}}
                               label={this.props.intl.formatMessage({id: 'contract.type'}) + ' :'}>


                    </Form.Item>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="permanent"
                        disabled={isNotDraft}
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px",marginRight:"20px"}}}
                        getFieldDecoratorOpts={{
                          getValueFromEvent: args => {
                            const value = args.target.checked === true;
                            this.props.form.setFieldsValue({'temporary': !value});
                            this.periodEnable = value;
                            return value;
                          },
                          valuePropName: 'checked'
                        }}
                      />
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="temporary"
                        disabled={isNotDraft}
                        form={this.props.form}
                        formItemOpts={{style: {marginBottom: "12px"}}}
                        getFieldDecoratorOpts={{
                          getValueFromEvent: args => {
                            const value = args.target.checked === true;
                            this.props.form.setFieldsValue({'permanent': !value});
                            this.periodEnable = !value;
                            return value;
                          },
                          valuePropName: 'checked'
                        }}
                      />

                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="period"
                        disabled={isNotDraft || this.periodEnable}
                        form={this.props.form}
                        formItemOpts={{style: {marginLeft: 20}}}
                        getFieldDecoratorOpts={{
                          rules: [{
                            required: !this.periodEnable,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.period']})
                          }]
                        }}
                      />
                      </Input.Group>
                    </Col>
                  </Row>
                </FormContainer>
              </Form>
            </Card>

            <Card className="narrow-layout large-section section-container card-actions-container"
                  actions={[
                    <Button buttonType={ButtonType.FOLLOW} htmlType="button"
                            onClick={() => goBackOrHomePage(this.props.history!)}>
                      <FormattedMessage id="close"/>
                    </Button>,
                    this.getOutcomeBtns(undefined,this.recruiterEnable)
                  ]}>

              <Form.Item style={{marginBottom: "12px"}}
                         label={messages[JobDescription.NAME + '.basicInteractionsAtWork']}>
                <TextArea disabled
                          rows={5}
                          value={this.jobDescription ? this.jobDescription.basicInteractionsAtWork || '' : ''}/>
              </Form.Item>

              <Form.Item style={{marginBottom: "12px"}}
                         label={messages[JobDescription.NAME + '.positionDuties']}>
                <TextArea disabled
                          rows={5}
                          value={this.jobDescription ? this.jobDescription.positionDuties || '' : ''}/>
              </Form.Item>

              <Form.Item style={{marginBottom: "12px"}}
                         label={messages[JobDescription.NAME + '.compulsoryQualificationRequirements']}>
                <TextArea disabled
                          rows={5}
                          value={this.jobDescription ? this.jobDescription.compulsoryQualificationRequirements || '' : ''}/>
              </Form.Item>

              <Form.Item style={{marginBottom: "12px"}}
                         label={messages[JobDescription.NAME + '.generalAdditionalRequirements']}>
                <TextArea disabled
                          rows={5}
                          value={this.jobDescription ? this.jobDescription.generalAdditionalRequirements || '' : ''}/>
              </Form.Item>

              {this.jobDescription && this.jobDescription.file
                ? <Tag
                  style={{margin: "10px"}}
                  color={"blue"}
                  onClick={() => {
                    downloadFile(this.jobDescription.file!.id,
                      this.jobDescription.file!.name as string,
                      this.jobDescription.file!.extension as string,
                      "");
                  }
                  }> {this.jobDescription.file!.name}</Tag>
                : <></>}

              {/* <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="files"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
              />*/}

              <Form.Item
                label={createElement(Msg, {entityName: this.dataInstance.entityName, propertyName: "justification"})}>
                {this.props.form.getFieldDecorator("justification", {
                  rules: [{
                    required: this.notMatchAvailableVacancies,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.justification']})
                  }]
                })(
                  <TextArea
                    disabled={isNotDraft}
                    rows={4}/>
                )}
              </Form.Item>

                <Form.Item style={this.recruiterEnable ? {display: 'block'} : {display: 'none'}}
                           label={messages[this.dataInstance.entityName + '.recruiter']}>
                  {this.props.form.getFieldDecorator("recruiter", {
                    rules: [{
                      required: this.recruiterEnable,
                      message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.recruiter']})
                    }]
                  })(
                    <Select style={{width: '100%'}} showSearch allowClear
                            filterOption={(input, option) =>
                              (option.props.children as string || '').toLowerCase().indexOf((input || '').toLowerCase()) >= 0
                            }>
                      {this.usersDc.map(i=><Select.Option key={i.id}>{i.personGroup&&i.personGroup['_instanceName']}</Select.Option>)}
                    </Select>
                  )}
                </Form.Item>

                {this.takCard()}

              </Card>
            </div>
          </Spin>
        </Page>
      );
  }

  componentDidMount() {
    super.componentDidMount();
    restServices.positionService.getActiveChildPositions(this.getDate(), 'positionForSelect').then(res=>runInAction(()=>this.positionGroupDc=res))
    getCubaREST()!.searchEntities<UserRole>(UserRole.NAME,{
      conditions:[{
        property:"role.name",
        operator:"=",
        value:"recruter"
      }]
    }, {view:"tmp.user.edit"}).then(i=>{
      getCubaREST()!.searchEntities<TsadvUser>(TsadvUser.NAME,{
        conditions:[{
          property:"id",
          operator:"in",
          value:i.map(v=>v.user&&v.user.id)
        },
          {
           property: 'active',
           operator: '=',
          value: 'TRUE'
        }]
      },{view:"portal-bproc-users"}).then(users=>runInAction(()=>this.usersDc=users))
    })
  }


  onReactionDisposerEffect = (item: VacancyRequest | undefined) => {
    if (item) {
      this.positionGroupId = item&&item.positionGroup?item.positionGroup.id:undefined;
      this.date = moment(item.workingStartDate);
      this.periodEnable = item.permanent === true;
      this.checked = item.replacement?item.replacement:false
      this.onChangePositionOrDate();
    }
  }

  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.taskDefinitionKey) {
      this.approverTaskCode = this.activeUserTask.taskDefinitionKey;
    }
    this.recruiterEnable = this.approverTaskCode === 'mor_task';
    if(this.props.entityId ==="new"){
      this.props.form.setFieldsValue( {'replacement': undefined,newPosition:undefined})
    }
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any, allValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(VacancyRequestEditComponent))
);
