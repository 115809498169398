import { AbstractParentEntity } from "./AbstractParentEntity";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicExperience } from "./tsadv_DicExperience";
import { DicRelationshipToReferrer } from "./tsadv_DicRelationshipToReferrer";
import { DicEducation } from "./tsadv_DicEducation";
import { DicPersonalEvaluation } from "./tsadv_DicPersonalEvaluation";
import { FileDescriptor } from "./sys$FileDescriptor";
import { Requisition } from "./tsadv$Requisition";
export class RefferalCandidate extends AbstractParentEntity {
  static NAME = "tsadv_RefferalCandidate";
  personGroup?: PersonGroupExt | null;
  workExperience?: DicExperience | null;
  cityResidence?: string | null;
  relationshipToReferrer?: DicRelationshipToReferrer | null;
  education?: DicEducation | null;
  personalEvaluation?: DicPersonalEvaluation | null;
  file?: FileDescriptor | null;
  vacancy?: Requisition | null;
  referrer?: PersonGroupExt | null;
}
export type RefferalCandidateViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "refferalCandidate.edit";
export type RefferalCandidateView<
  V extends RefferalCandidateViewName
> = V extends "_base"
  ? Pick<
      RefferalCandidate,
      | "id"
      | "cityResidence"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      RefferalCandidate,
      | "id"
      | "cityResidence"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "refferalCandidate.edit"
  ? Pick<
      RefferalCandidate,
      | "id"
      | "cityResidence"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "personGroup"
      | "workExperience"
      | "relationshipToReferrer"
      | "education"
      | "personalEvaluation"
      | "file"
      | "vacancy"
      | "referrer"
      | "createTs"
      | "createdBy"
    >
  : never;
