import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PositionGroupExt } from "./base$PositionGroupExt";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { FileDescriptor } from "./sys$FileDescriptor";
import {TsadvUser} from "./tsadv$UserExt";
export class VacancyRequest extends AbstractBprocRequest {
  static NAME = "tsadv_VacancyRequest";
  positionGroup?: PositionGroupExt | null;
  vacancyAmount?: number | null;
  replacement?: boolean | null;
  substituter?: PersonGroupExt | null;
  newPosition?: boolean | null;
  operatingMode?: string | null;
  workingStartDate?: any | null;
  vacancyResponsibleManager?: PersonGroupExt | null;
  vacancyType?: any | null;
  permanent?: boolean | null;
  temporary?: boolean | null;
  period?: string | null;
  justification?: string | null;
  files?: FileDescriptor[] | null;
  recruiter?: TsadvUser | null;
  crucialPosition?: boolean | null;
  assistantManager?:PersonGroupExt|null;
}
export type VacancyRequestViewName = "_base" | "_local" | "_minimal";
export type VacancyRequestView<
  V extends VacancyRequestViewName
> = V extends "_base"
  ? Pick<
      VacancyRequest,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "vacancyAmount"
      | "replacement"
      | "newPosition"
      | "operatingMode"
      | "workingStartDate"
      | "vacancyType"
      | "permanent"
      | "temporary"
      | "period"
      | "justification"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      VacancyRequest,
      | "id"
      | "vacancyAmount"
      | "replacement"
      | "newPosition"
      | "operatingMode"
      | "workingStartDate"
      | "vacancyType"
      | "permanent"
      | "temporary"
      | "period"
      | "justification"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<VacancyRequest, "id" | "requestNumber" | "requestDate">
  : never;
