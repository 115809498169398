import * as React from "react";
import {inject, observer} from "mobx-react";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Input, Spin, Typography, Tabs, Select, Button, Row, Card} from 'antd';
import {Link} from "react-router-dom";
import {organizationVacancyInfoPATH} from "./OrganizationVacancyInfo";
import {Requisition} from "../../../cuba/entities/base/tsadv$Requisition";
import moment from 'moment'
import {HierarchyElementExt} from "../../../cuba/entities/base/base$HierarchyElementExt";
import {action, observable, runInAction,} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import {DicCompany} from "../../../cuba/entities/base/base_DicCompany";
import { Pagination } from 'antd';
export const organizationVacancyListPATH = "/OrganizationVacancyList";

interface CollectionExt extends SerializedEntity<Requisition> {
  index:number;
  ParentCompanyName:string;
}

@injectMainStore
@inject("rootStore")
@observer

class organizationVacancy extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  @observable newCollection:CollectionExt[] = [];
  @observable origCollection:CollectionExt[] = [];
  @observable isLoading:boolean = true;
  @action findParent = async (organizationGroupId:string,previousCompanyName?:string):Promise<string> =>{
   return await getCubaREST()!.searchEntities<HierarchyElementExt>(HierarchyElementExt.NAME,{
      conditions:[{
        property:"organizationGroup.id",
        operator:"=",
        value:organizationGroupId
      }]
    },{view:"hierarchyElement.full"}).then(value => {
      if(value[0]&&value[0].parent){
       return this.findParent(value[0].parent!.organizationGroup!.id,value[0].name!)
      }
       return previousCompanyName!
   })
}
  @observable searchValue:string=""
  @observable searchCompany:string=""
  dicCompany = collection<DicCompany>(DicCompany.NAME,{
    view:"_local"
  })
  @observable total:number;
  @action setTotal = (value:number) => {
    this.total = value
  }
  @observable vacancyCount:number;
  @action setVacancyCount = (value:number) => {
    this.vacancyCount = value
  }
  @action loadByCountAndPage = () => {
    this.newCollection = [];
    this.origCollection = [];
    getCubaREST()!.searchEntitiesWithCount<Requisition>(Requisition.NAME,{
      conditions:[{
        property:"requisitionStatus",
        operator:"=",
        value:"OPEN"
      },{group:"OR",conditions:[{
          property:"nameForSiteLang1",
          operator:"contains",
          value:this.searchValue
        },{
          property:"nameForSiteLang2",
          operator:"contains",
          value:this.searchValue
        },{
          property:"nameForSiteLang3",
          operator:"contains",
          value:this.searchValue
        }]},{
        property:"positionGroup.company.id",
        operator:this.searchCompany&&this.searchCompany!=='empty'?"=":"<>",
        value:this.searchCompany&&this.searchCompany!=='empty'?this.searchCompany:""
      },{
        property:"createdBy",
        operator:"<>",
        value:"migration_candidate2"
      }]
    },{view:"_minimal",sort:"-createTs"}).then((res)=>{
      this.total = res.count
    })
    getCubaREST()!.searchEntities<Requisition>(Requisition.NAME,{
      conditions:[{
        property:"requisitionStatus",
        operator:"=",
        value:"OPEN"
      },{group:"OR",conditions:[{
          property:"nameForSiteLang1",
          operator:"contains",
          value:this.searchValue
        },{
          property:"nameForSiteLang2",
          operator:"contains",
          value:this.searchValue
        },{
          property:"nameForSiteLang3",
          operator:"contains",
          value:this.searchValue
        }]},{
        property:"positionGroup.company.id",
        operator:this.searchCompany&&this.searchCompany!=='empty'?"=":"<>",
        value:this.searchCompany&&this.searchCompany!=='empty'?this.searchCompany:""
      },{
        property:"createdBy",
        operator:"<>",
        value:"migration_candidate2"
      }]
    },{view:"requisition.list",sort:"-createTs",limit:this.count,offset:(this.page-1)*this.count}).then(res=>{
      for  (const [i,value] of res.entries()){
        this.findParent(value.positionGroup!.organizationGroup!.id).then(a=>{
          this.newCollection.push({index:i,...value,ParentCompanyName:a});
          this.newCollection =this.newCollection.sort((a, b)=> a.index-b.index)
          this.origCollection.push({index:i,...value,ParentCompanyName:a});
          this.origCollection = this.origCollection.sort((a, b)=> a.index-b.index)
        })
      }
      this.isLoading = false
    })
  }
  @observable count = 10;
  @observable page = 1;
  render() {
    const { TabPane } = Tabs;
    const {Text,Title} = Typography;
    const {Option} = Select;
    const formStyle={ marginBottom: "12px", padding: "0 30px" };
    return (

      <Page pageName={<FormattedMessage id={"organization.vacancies"}/>} >
        <Section size="large">
          {this.isLoading?
            <div className="spin-center">
              <Spin spinning size={"large"}/>
            </div>
            :
            <>
                <Tabs size={"large"} style={{width:"100%"}}>
                  <TabPane tab={<FormattedMessage id={"vacancies"}/>} key={"1"}>
                    <div className={"ant-row ant-form-item"}
                         style={formStyle}>
                      <fieldset style={{
                        border: "1px solid #d8d8d8",
                        padding: "5px",
                        borderRadius: "4px",
                        width: "100%",
                        height: "auto",
                      }}>
                        <legend style={{
                          width: "auto",
                          padding:"2px",
                          borderBottom:"none",
                          color:"inherit",
                          fontSize:"1.5em"
                        }}><FormattedMessage id={"search"}/>
                        </legend>
                      <div style={{display:"inline-flex",justifyContent:"space-around",width:"100%",marginBottom:30}}>
                        <div>
                         <Button onClick={this.loadByCountAndPage}><FormattedMessage id={"search"}/></Button>
                        </div>
                        <div>
                          <Text strong><FormattedMessage id="vacancy.name"/></Text>
                          <Input placeholder={this.props.intl.formatMessage({id:"vacancy.name"})}
                                 onChange={(e)=>runInAction(()=>this.searchValue = e.target.value)}
                                 style={{marginLeft:"20px",width:"auto", minWidth:"250px"}}/>
                        </div>
                        <div>
                          <Text strong><FormattedMessage id="company"/></Text>
                          <Select onChange={(value:string) => runInAction(()=>this.searchCompany=value)} style={{marginLeft:"20px",width:"auto", minWidth:"250px"}}>
                            <Option key={"empty"} value={"empty"}>{"..."}</Option>
                            {this.dicCompany.items.map(company=><Option value={company.id} key={company.id}>{company._instanceName}</Option>)}
                          </Select>
                        </div>
                      </div>
                      </fieldset>
                    </div>

                    <Row style={{marginTop:"20px", marginBottom:"20px"}}>
                      <Title style={{display:"inline-block",marginRight:40}} level={4}>{this.props.intl.formatMessage({id:"openVacancies"}) + " : " + this.vacancyCount}</Title>
                     <span>Показать:
                       <Select defaultValue={10} style={{marginLeft:10,width:80}} onChange={(value:number)=>{runInAction(()=>this.count = value);this.loadByCountAndPage()}}>
                        <Option value={10}>10</Option>
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                       </span>
                    </Row>
                    {this.newCollection.map(item =>
                    {
                     return (<div style={{marginBottom:15}} key={item.id}>
                        <Card title={<Link to={organizationVacancyInfoPATH + "/"+ item.id}><span style={{textDecoration:"underline"}}>{item.nameForSiteLang||""}</span></Link>}
                              style={{ width:"100%" }}>
                          <p style={{marginLeft:"20px", marginTop:"20px"}}><span style={{color:"brown"}}>{item.ParentCompanyName}</span>
                            <span style={{color:"grey"}}>{item.operatingMode?" | "+item.operatingMode:""}</span></p>
                          <p style={{marginLeft:"20px"}}>{item.createTs?moment(item.createTs).locale('ru').format('L'):""}</p>
                        </Card>
                      </div>)
                    }
                    )}
                    <Pagination defaultCurrent={1} total={this.total} pageSize={this.count} onChange={((page) => {
                      this.page = page;
                      this.loadByCountAndPage()
                    })}/>
                  </TabPane>
                </Tabs>
            </>
          }
        </Section>
      </Page>

    );
  }
  componentDidMount() {
    getCubaREST()!.searchEntitiesWithCount<Requisition>(Requisition.NAME,{
      conditions:[{
        property:"requisitionStatus",
        operator:"=",
        value:"OPEN"
      },{
        property:"positionGroup.company.id",
        operator:this.searchCompany&&this.searchCompany!=='empty'?"=":"<>",
        value:this.searchCompany&&this.searchCompany!=='empty'?this.searchCompany:""
      },{
        property:"createdBy",
        operator:"<>",
        value:"migration_candidate2"
      }]
    },{view:"_minimal"}).then(res=>{
      this.setVacancyCount(res.count)
    })
 this.loadByCountAndPage();
  }
}
const organizationVacancyList = injectIntl(organizationVacancy);
export default organizationVacancyList;

