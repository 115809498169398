import './style.css'
import {CSSProperties, default as React} from "react"
import {TooltipProps} from "antd/lib/tooltip";
import {Icon, Tooltip} from "antd";

type Props = {
  labelText? : string
  labelClassName?: string
  labelStyle?: CSSProperties
  containerStyle?: CSSProperties

} & TooltipProps

export default class LabelTooltip extends React.Component<Props> {

  render() {
    return (
      <div className='component-container' style={this.props.containerStyle}>
        <label style={this.props.labelStyle} className={this.props.labelClassName}>{this.props.labelText}</label>
        <Tooltip {...this.props}>
          <Icon type="question-circle" theme="filled" className="tooltip-icon" />
        </Tooltip>
      </div>
    )
  }
}