import React, { Component } from 'react'
import { injectMainStore, MainStoreInjected } from '@cuba-platform/react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
// import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../store';
import { Table } from 'antd';
import { restServices } from '../../../cuba/services';
import { AdaptationServiceGetAssessmentFormResult } from '../../../cuba/entities/base/tsadv_AdaptationPlan';
import { observable } from 'mobx';

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp & {
  personGroupId: string;
};

type CompetenceTableRow = {
  key: string,
  competence_type: string,
  competence_type_id: string,
  children: (AdaptationServiceGetAssessmentFormResult & {key: string})[]
};

@injectMainStore
@inject("rootStore")
@observer
class MyCompetentionTableComponent extends Component<Props> {
  @observable
  data: CompetenceTableRow[] = [];

  componentDidMount = async () => {
    const assessmentForms = await this.getAdaptationAssessmentsForms();
    this.data = this.groupDataByCompetenceType(assessmentForms);
    console.log("myCompetention data:", this.data)
  }

  getAdaptationAssessments = async () => {
    try {
      const assessments = await restServices.adaptationPlanRequestService.getAssessment({
        personGroupId: this.props.personGroupId,
        lang: (this.props.rootStore && this.props.rootStore.userInfo && this.props.rootStore.userInfo.locale) || "ru"
      });
      const filteredAssessments = assessments.filter(assessment => assessment.assessment_type === 'ADAPTATION');
      return filteredAssessments;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  getAdaptationAssessmentsForms = async () => {
    try {
      const assessments = await this.getAdaptationAssessments();
      if (assessments.length > 0 && assessments[0].person_assessment_id) {
        const result = await restServices.learningService.getPersonAssessmentForm({
          personGroupId: assessments[0].person_assessment_id!,
          lang: (this.props.rootStore && this.props.rootStore.userInfo && this.props.rootStore.userInfo.locale) || "ru"
        });
        const parsedData: AdaptationServiceGetAssessmentFormResult[] = JSON.parse(result[0].value);
        return parsedData;
      }
      return []
    } catch (e) {
      return []
    }
  }

  groupDataByCompetenceType = (arr: AdaptationServiceGetAssessmentFormResult[]):CompetenceTableRow[] => {
    const map = arr.reduce((acc, cur, index) => {
      if (cur.competence_type_id && cur.competence_type) {
        if(!acc.has(cur.competence_type_id)){
          acc.set(cur.competence_type_id, {
            competence_type_id: cur.competence_type_id,
            competence_type: cur.competence_type,
            key: cur.competence_type_id,
            children: []
          });
        }
        acc.get(cur.competence_type_id)!.children.push({
          ...cur,
          competence_type: cur.competence_name,
          key: `${cur.competence_group_id || index}-${cur.participant1_entity_id || index}`
        });
      }
      return acc
    }, new Map() as Map<string, CompetenceTableRow>);

    return Array.from(map.values())
  };

  render() {
    return (
      <div>
        <Table
          scroll={{x:true}}
          dataSource={[...this.data]}
          pagination={{
            total: this.data.length,
            pageSize: 3,
          }}
          rowKey="key"
          columns={[
            {
              key: 'competence_type',
              dataIndex: 'competence_type',
              title: () => <FormattedMessage id="myCompetention.competence_name"/>
            },
            // {
            //   key: 'competence_name',
            //   dataIndex: 'competence_name',
            //   title: () => <FormattedMessage id="myCompetention.competence_name"/>
            // },
            {
              key: 'required_scale_level',
              dataIndex: 'required_scale_level',
              title: () => <FormattedMessage id="myCompetention.required_scale_level"/>
            }, {
              key: 'participant1_scale_level',
              dataIndex: 'participant1_scale_level',
              title: () => <FormattedMessage id="myCompetention.participant1_scale_level"/>
            }, {
              key: 'result',
              dataIndex: 'result',
              title: () => <FormattedMessage id="myCompetention.result"/>
            }, {
              key: 'delta',
              dataIndex: 'delta',
              title: () => <FormattedMessage id="myCompetention.delta"/>
            }
          ]}
        />
      </div>
    )
  }
}

const MyCompetentionTable = injectIntl(MyCompetentionTableComponent)

export default MyCompetentionTable
