import {ServiceDataCollectionStore} from "./ServiceDataCollectionStore";
import {restServices} from "../../cuba/services";
import {runInAction} from "mobx";
import {DataCollectionOptions} from "@cuba-platform/react/dist/data/Collection";
import {getCubaREST} from "@cuba-platform/react";
import {ICompanyEntity} from "../../cuba/entities/interface/ICompanyEntity";
import {DicCompany} from "../../cuba/entities/base/base_DicCompany";
import {Condition} from "@cuba-platform/rest/dist-node/filter";

export class DictionaryDataCollectionStore<T extends ICompanyEntity> extends ServiceDataCollectionStore<T> {

  emptyCompanyId = getCubaREST()!.searchEntities<DicCompany>(DicCompany.NAME, {
    conditions: [{
      property: "code",
      operator: "=",
      value: "empty"
    }]
  }).then((result: any) => result[0] ? result[0].id : null);

  constructor(dictionaryName: string, personGroupId: string, opts: DataCollectionOptions) {
    super(restServices.portalHelperService.companiesForLoadDictionary.bind(null, {
      personGroupId: personGroupId,
    }), dictionaryName);

    if (opts.view) {
      this.view = opts.view;
    }
    if (opts.filter) {
      this.filter = opts.filter;
    }
    if (opts.sort) {
      this.sort = opts.sort;
    }
    if (opts.limit !== null && opts.limit !== undefined) {
      this.limit = opts.limit;
    }
    if (opts.offset !== null && opts.offset !== undefined) {
      this.offset = opts.offset;
    }
  }

  loading = async (promise: any) => {
    let emptyDictId = await this.emptyCompanyId;
    promise
      .then((resp: any) => {
          if (resp) {
            if (!this.filter) this.filter = {conditions: []}
            this.filter.conditions.push({property: "company.id", operator: "in", value: resp})
          }
          if (this.filter) {
            this.loadingDictionaries(getCubaREST()!.searchEntitiesWithCount(this.entityName, this.filter, this.loadOptions())).then((e) => {
              if (e.count !== 0) {
                return;
              }
              this.loadWitchCompanyCode(emptyDictId);
            })

          } else {
            this.loadingDictionaries(getCubaREST()!.loadEntitiesWithCount(this.entityName, this.loadOptions()))
          }
        }
      )
      .catch(() => {
        runInAction(() => {
          this.status = 'ERROR';
        });
      });
  }

   loadWitchCompanyCode(emptyDictId: Array<DicCompany>) {
    this.filter!.conditions = [
      ...this.filter!.conditions.filter(e => (e as Condition).property !== "company.id"),
      {property: "company.id", operator: "in", value: [emptyDictId]}
    ] as Array<Condition>;
    this.loadingDictionaries(getCubaREST()!.searchEntitiesWithCount(this.entityName, this.filter!, this.loadOptions()))
  }

  loadOptions = () => {
    const loadOptions = {
      view: this.view,
    };
    if (this.sort) {
      loadOptions['sort'] = this.sort;
    }
    if (this.limit !== null && this.limit !== undefined) {
      loadOptions["limit"] = this.limit;
    }
    if (this.offset !== null && this.offset !== undefined) {
      loadOptions['offset'] = this.offset;
    }
    return loadOptions;
  }

  loadingDictionaries(promise: Promise<any>) {
    return promise
      .then((resp) => {
        runInAction(() => {
          this.items = resp.result;
          this.count = resp.count;
          this.status = 'DONE';

          this.afterLoad();
        });
        return resp;
      })
      .catch(() => {
        runInAction(() => {
          this.status = 'ERROR';
        });
      });
  }

  afterLoad = () => {

  }
}

export function dictionaryCollection<T extends ICompanyEntity>(dictionaryName: string, personGroupId: string, opts?: DataCollectionOptions): DictionaryDataCollectionStore<T> {
  if (!opts) opts = {
    loadImmediately: true
  };
  const dataCollection = new DictionaryDataCollectionStore<T>(dictionaryName, personGroupId, opts);
  if (typeof opts.loadImmediately === 'undefined' || opts.loadImmediately) {
    dataCollection.load();
  }
  return dataCollection;
}