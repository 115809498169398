import * as React from "react";
import { inject, observer } from "mobx-react";

import {
  action,
  IReactionDisposer,
  observable,
  reaction,
  runInAction,
  toJS
} from "mobx";

import {
  Button,
  Form,
  Icon,
  Input,
  InputNumber,
  message,
  Modal,
  Table
} from "antd";

import {
  getCubaREST,
  injectMainStore,
  MainStoreInjected,
  Msg
} from "@cuba-platform/react";

import { AssignedGoal } from "../../../../cuba/entities/base/tsadv$AssignedGoal";
import { SerializedEntity } from "@cuba-platform/rest";
import Column from "antd/es/table/Column";
import { restQueries } from "../../../../cuba/queries";
import { Link } from "react-router-dom";
import { queryInstance } from "../../../util/QueryDataInstanceStore";
import { AssignedPerformancePlan } from "../../../../cuba/entities/base/tsadv$AssignedPerformancePlan";
import Notification from "../../../util/Notification/Notification";
import { RouteComponentProps, withRouter } from "react-router";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import { restServices } from "../../../../cuba/services";
import { RootStoreProp } from "../../../store";
import { DicGoalCategory } from "../../../../cuba/entities/base/tsadv$DicGoalCategory";
import { AssignedGoalTypeEnum } from "../../../../cuba/enums/enums";
import { ReactComponent as CascadeSvg } from "../../../../resources/icons/goal/cascade-goal.svg";
import { ReactComponent as IndividualSvg } from "../../../../resources/icons/goal/individual-goal.svg";
import { ReactComponent as LibrarySvg } from "../../../../resources/icons/goal/library-goal.svg";
import TextArea from "antd/es/input/TextArea";
import { Goal } from "../../../../cuba/entities/base/tsadv$Goal";
import GoalHistory from "../GoalHistory";
import CascadeBtn from "../CascadeBtn";
import { DicNineBoxCategory } from "../../../../cuba/entities/base/tsadv_DicNineBoxCategory";
import { string } from "@amcharts/amcharts4/core";
import { Simulate } from "react-dom/test-utils";
import CBAStore from "../../../store/CBAStore";
import { EmployeeColumns } from "../../Kpi/AssignedPerformancePlanEdit";

type Props = {
  assignedPerformancePlanId: string;
  approverHrRoleCode?: string;
  assessmentStatus: string;
  setTotalWeight?: (totalWeight: number) => void;
  setTotalResult?: (totalResult: number) => void;
  readonly: boolean;
  parentForm: any;
  KPIfinalScore: number | 0;
  RenderCompetencetotalResult: number;
  RendertotalResult: number;
  setAssignedPerformanceState?: (state: {
    update: () => void;
    validate: () => boolean;
  }) => void;
  setEmployeeColumns: (map:Map<string, EmployeeColumns>) => void;
};

@injectMainStore
@inject("rootStore")
@observer
class AssignedGoalList extends React.Component<
  MainStoreInjected &
    WrappedComponentProps &
    Props &
    RouteComponentProps &
    RootStoreProp
> {
  @observable
  dataCollection: any[] = [];

  @observable isUserManager: boolean = false;

  @observable
  kpiDataInstance = queryInstance<AssignedPerformancePlan>(
    AssignedPerformancePlan.NAME,
    "kpiEditPage",
    { appId: this.props.assignedPerformancePlanId },
    async () => {
      await restServices.organizationHrUserService
        .isManagerOrSupManager({
          userId: this.props.rootStore!.userInfo!.id!,
          employeePersonGroupId: this.kpiDataInstance.item!.assignedPerson!.id!
        })
        .then(value => (this.isUserManager = value))
        .then(() => this.recalcTotalResult());

      this.dataCollection.forEach(this.setWeightEditable);
    }
  );

  fields = ["category", "goalString", "weight", "startDate", "endDate"];

  @observable selectedRowKey: string | undefined;
  @observable recordAssessment: number;

  cbaStore = this.props.rootStore && this.props.rootStore!.cbaStore!;

  reactionDisposer: IReactionDisposer;

  form = this.props.parentForm;

  @observable messages = this.props.mainStore!.messages!;

  @observable isWeightEditable = new Map<AssignedGoal, boolean>();
  @observable onClickSave?: string = undefined;

  @observable
  employeeMap = new Map<string, EmployeeColumns>();

  isManager = (): boolean => this.props.approverHrRoleCode === "MANAGER";
  isInitiator = (): boolean => this.props.approverHrRoleCode === "INITIATOR";

  showDeletionDialog = (e: SerializedEntity<AssignedGoal>) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "management.browser.delete.areYouSure" },
        { instanceName: e._instanceName }
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        this.selectedRowKey = undefined;

        return this.deleteGoal(e);
      }
    });
  };

  deleteGoal = (e: SerializedEntity<AssignedGoal>) => {
    if (e == null || e.id == null) {
      throw new Error("Unable to delete entity without ID");
    }
    getCubaREST()!
      .deleteEntity(AssignedGoal.NAME, e.id)
      .then(
        action(() => {
          this.load();
        })
      )
      .catch(action(() => {}));
  };

  getStageCode = () =>
    this.kpiDataInstance.item &&
    this.kpiDataInstance.item.stage &&
    this.kpiDataInstance.item.stage.code;

  recalcTotalResult = () => {
    const isSecondStep = this.getStageCode() === "COMPLETED";
    if (this.props.setTotalResult) {
      if (this.dataCollection.length > 0) {
        this.props.setTotalResult(
          this.dataCollection
            .map(
              (value: AssignedGoal) =>
                ((value.weight || 0) *
                  ((value.managerAssessment != undefined &&
                  (this.isUserManager || !isSecondStep)
                    ? value.managerAssessment
                    : value.assessment) || 0)) /
                100
            )
            .reduce((i1, i2) => i1 + i2, 0)
        );
      } else this.props.setTotalResult(0);
    }
  };
  @observable employeeAssessmentCategoryCode: string;
  @observable employeeAssessmentvalue: number;
  @observable managerAssessmentCategoryCode: string;
  @observable manageAssessmentvalue: number;
  @observable isHasCategoryCodeDelta: boolean = false;
  assessmentValidator = (rule: any, value: any, callback: any) => {
    if (value && (value < 0 || value > 100)) {
      callback(
        this.props.intl.formatMessage({
          id: "assignedGoal.assessment.validation"
        })
      );
    }
    callback();
  };

  onCheckDelta = (weight: number) => {
    getCubaREST()!
      .searchEntities<DicNineBoxCategory>(
        DicNineBoxCategory.NAME,
        {
          conditions: [
            {
              property: "totalScoreKPImin",
              operator: "<=",
              value: this.props.KPIfinalScore
            },
            {
              property: "totalScoreKPImax",
              operator: ">=",
              value: this.props.KPIfinalScore
            }
          ]
        },
        { view: "dicNineBoxCategory-edit" }
      )
      .then(v => {
        this.employeeAssessmentCategoryCode = v && v[0] && v[0].code!;
        getCubaREST()!
          .searchEntities<DicNineBoxCategory>(
            DicNineBoxCategory.NAME,
            {
              conditions: [
                {
                  property: "totalScoreCBAmin",
                  operator: "<=",
                  value:
                    this.props.assessmentStatus! === "SEND"
                      ? Math.floor(this.props.RenderCompetencetotalResult)
                      : Math.floor(
                          this.cbaStore! &&
                            this.cbaStore!.participantTotalResult!
                        )
                },
                {
                  property: "totalScoreCBAmax",
                  operator: ">=",
                  value:
                    this.props.assessmentStatus! === "SEND"
                      ? Math.floor(this.props.RenderCompetencetotalResult)
                      : Math.floor(
                          this.cbaStore! &&
                            this.cbaStore!.participantTotalResult
                        )
                }
              ]
            },
            { view: "dicNineBoxCategory-edit" }
          )
          .then(v => {
            this.managerAssessmentCategoryCode = v && v[0] && v[0].code!;
            if (
              (this.employeeAssessmentCategoryCode === "LOW" &&
                this.managerAssessmentCategoryCode === "HIGH") ||
              (this.employeeAssessmentCategoryCode === "HIGH" &&
                this.managerAssessmentCategoryCode === "LOW")
            ) {
              this.cbaStore! &&
                this.cbaStore!.setCategoryCodeDelta &&
                this.cbaStore!.setCategoryCodeDelta(true);
            } else {
              this.cbaStore! &&
                this.cbaStore!.setCategoryCodeDelta &&
                this.cbaStore!.setCategoryCodeDelta(false);
            }
          });
      });
  };

  managerCommentRender = (text: string, record: any) => {
    const isThirdStep = this.getStageCode() === "ASSESSMENT";
    const disabled = !this.isManager() || !isThirdStep;
    return (
      <div>
        <Form.Item>
          {this.form.getFieldDecorator("managerComment/" + record.id, {
            initialValue: record.managerComment,
            rules: [
              {
                required: true,
                validator: (rule: any, value: any, callback: any) => {
                  if (!value && !disabled)
                    return callback(
                      this.props.intl.formatMessage(
                        { id: "form.validation.required" },
                        {
                          fieldName: this.messages[
                            AssignedGoal.NAME + "." + "managerComment"
                          ]
                        }
                      )
                    );
                  callback();
                }
              }
            ]
          })(
            <TextArea
              disabled={disabled}
              style={{ minWidth: 300 }}
              onChange={event => {
                record.managerComment = event.currentTarget.value;
              }}
            />
          )}
        </Form.Item>
      </div>
    );
  };

  managerAssessmentColumnRender = (text: string, record: any) => {
    const isThirdStep = this.getStageCode() === "ASSESSMENT";
    this.props.rootStore!.cbaStore!.setCBAWeight &&
      this.props.rootStore!.cbaStore!.setCBAWeight(record.weight!);
    let participantTotalResult: number =
      this.cbaStore! && this.cbaStore!.participantTotalResult;
    let renderParticipantTotalResult: number = this.props
      .RenderCompetencetotalResult;
    if (
      participantTotalResult !== renderParticipantTotalResult &&
      participantTotalResult !== undefined &&
      this.isManager() &&
      ((isThirdStep &&
        record.parentAssignedGoal &&
        record.parentAssignedGoal!.goal! &&
        record.parentAssignedGoal!.goal!.useInCBA!) ||
        (record.goal && record.goal!.useInCBA!))
    ) {
      if (participantTotalResult > 100) {
        record.managerAssessment = 100;
      } else {
        record.managerAssessment = participantTotalResult;
      }

      this.onCheckDelta(record.weight);
      record.result = record.managerAssessment || record.assessment;
      this.recalcTotalResult();
    } else if (
      this.isManager() &&
      renderParticipantTotalResult !== 0 &&
      ((record.parentAssignedGoal &&
        record.parentAssignedGoal!.goal! &&
        record.parentAssignedGoal!.goal!.useInCBA!) ||
        (record.goal && record.goal!.useInCBA!))
    ) {
      if (renderParticipantTotalResult > 100) {
        record.managerAssessment = 100;
      } else {
        record.managerAssessment = renderParticipantTotalResult;
      }

      this.onCheckDelta(record.weight);
      record.result = record.managerAssessment || record.assessment;
      this.recalcTotalResult();
    } else {
      record.managerAssessment = record.managerAssessment;
      record.result = record.managerAssessment || record.assessment;
      this.recalcTotalResult();
    }

    return (
      <div>
        {((record.parentAssignedGoal &&
          record.parentAssignedGoal!.goal! &&
          record.parentAssignedGoal!.goal!.useInCBA!) ||
          (record.goal && record.goal.useInCBA)) &&
        this.isManager() ? (
          <Form.Item>
            {this.form.getFieldDecorator("managerAssessment/" + record.id, {
              initialValue: record.managerAssessment,
              rules: [
                {
                  required: true,
                  message: this.props.intl.formatMessage(
                    { id: "form.validation.required" },
                    {
                      fieldName: this.messages[
                        AssignedGoal.NAME + "." + "assessment"
                      ]
                    }
                  )
                },
                {
                  validator: this.assessmentValidator
                }
              ]
            })(<Input disabled={true} />)}
          </Form.Item>
        ) : (
          <Form.Item>
            {this.form.getFieldDecorator("managerAssessment/" + record.id, {
              initialValue: record.managerAssessment,
              rules: [
                {
                  required: true,
                  message: this.props.intl.formatMessage(
                    { id: "form.validation.required" },
                    {
                      fieldName: this.messages[
                        AssignedGoal.NAME + "." + "managerAssessment"
                      ]
                    }
                  )
                },
                {
                  validator: this.assessmentValidator
                }
              ]
            })(
              <InputNumber
                disabled={!this.isManager() || !isThirdStep}
                onChange={value => {
                  record.managerAssessment = value;
                  record.result = record.managerAssessment || record.assessment;
                  this.recalcTotalResult();
                }}
              />
            )}
          </Form.Item>
        )}
      </div>
    );
  };

  employeeCommentRender = (text: string, record: any) => {
    const isThirdStep = this.getStageCode() === "ASSESSMENT";
    const disabled = !this.isInitiator() || !isThirdStep;
    return (
      <div>
        <Form.Item>
          {this.form.getFieldDecorator("employeeComment/" + record.id, {
            initialValue: record.employeeComment,

            rules: [
              {
                required: true,
                validator: (rule: any, value: any, callback: any) => {
                  if (!value && !disabled)
                    return callback(
                      this.props.intl.formatMessage(
                        { id: "form.validation.required" },
                        {
                          fieldName: this.messages[
                            AssignedGoal.NAME + "." + "employeeComment"
                          ]
                        }
                      )
                    );
                  callback();
                }
              }
            ]
          })(
            <Input.TextArea
              disabled={disabled}
              style={{ minWidth: 300 }}
              onChange={event => {
                record.employeeComment = event.currentTarget.value;
                this.employeeMap.set(record.id, {assessment: record.assessment, employeeComment: record.employeeComment})
                this.props.setEmployeeColumns(this.employeeMap);
              }}
            />
          )}
        </Form.Item>
      </div>
    );
  };

  @action
  assessmentColumnRender = (text: string, record: any) => {
    const isThirdStep = this.getStageCode() === "ASSESSMENT";
    let participantTotalResult: number =
      this.cbaStore! && this.cbaStore!.participantTotalResult;
    let renderParticipantTotalResult: number = this.props.RendertotalResult;
    if (
      participantTotalResult !== renderParticipantTotalResult &&
      participantTotalResult !== undefined &&
      this.isInitiator() &&
      ((isThirdStep &&
        record.parentAssignedGoal &&
        record.parentAssignedGoal!.goal! &&
        record.parentAssignedGoal!.goal!.useInCBA!) ||
        (record.goal && record.goal!.useInCBA!))
    ) {
      if (participantTotalResult > 100) {
        record.assessment = 100;
      } else {
        record.assessment = participantTotalResult!;
      }

      record.result = participantTotalResult;
      this.recalcTotalResult();
    } else if (
      (record.parentAssignedGoal &&
        record.parentAssignedGoal!.goal! &&
        record.parentAssignedGoal!.goal!.useInCBA!) ||
      (record.goal && record.goal!.useInCBA!)
    ) {
      if (renderParticipantTotalResult > 100) {
        record.assessment = 100;
      } else {
        record.assessment = renderParticipantTotalResult;
      }
      record.result = renderParticipantTotalResult;
      this.recalcTotalResult();
    }
    return (
      <div>
        {((record.parentAssignedGoal &&
          record.parentAssignedGoal!.goal! &&
          record.parentAssignedGoal!.goal!.useInCBA!) ||
          (record.goal && record.goal.useInCBA)) &&
        this.isInitiator() ? (
          <>
            <Form.Item>
              {this.form.getFieldDecorator("assessment/" + record.id, {
                initialValue: record.assessment,
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage(
                      { id: "form.validation.required" },
                      {
                        fieldName: this.messages[
                          AssignedGoal.NAME + "." + "assessment"
                        ]
                      }
                    )
                  },
                  {
                    validator: this.assessmentValidator
                  }
                ]
              })(<Input disabled={true} />)}
            </Form.Item>
          </>
        ) : (
          <Form.Item>
            {this.form.getFieldDecorator("assessment/" + record.id, {
              initialValue: record.assessment,
              rules: [
                {
                  required: true,
                  message: this.props.intl.formatMessage(
                    { id: "form.validation.required" },
                    {
                      fieldName: this.messages[
                        AssignedGoal.NAME + "." + "assessment"
                      ]
                    }
                  )
                },
                {
                  validator: this.assessmentValidator
                }
              ]
            })(
              <InputNumber
                disabled={!this.isInitiator() || !isThirdStep}
                onChange={value => {
                  record.assessment = value;
                  record.result = record.managerAssessment || record.assessment;
                  this.recalcTotalResult();
                  this.employeeMap.set(record.id, {assessment: record.assessment, employeeComment: record.employeeComment})
                  this.props.setEmployeeColumns(this.employeeMap);
                }}
              />
            )}
          </Form.Item>
        )}
      </div>
    );
  };

  validate = (): boolean => {
    const isThirdStep = this.getStageCode() === "ASSESSMENT";
    let isValidatedSuccess = true;
    if (isThirdStep)
      this.form.validateFields((err: any, values: any) => {
        isValidatedSuccess = !err;
        if (err) {
          message.error(
            this.props.intl.formatMessage({
              id: "management.editor.validationError"
            })
          );
        }
      });
    return isValidatedSuccess;
  };

  update = () => {
    const isThirdStep = this.getStageCode() === "ASSESSMENT";
    if (isThirdStep && this.dataCollection) this.commitDataCollection();
  };

  commitDataCollection = () => {
    this.dataCollection.forEach(this.commitAssignedGoal);
  };

  commitAssignedGoal = (assignedGoal: AssignedGoal) => {
    return getCubaREST()!
      .commitEntity(AssignedGoal.NAME, toJS(assignedGoal))
      .then(value => {
        Notification.success({
          message: this.props.intl.formatMessage({
            id: "management.editor.success"
          })
        });
        return value;
      })
      .catch(reason => {
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "management.editor.error"
          })
        });
      });
  };

  render() {
    const isFirstStep = this.getStageCode() === "DRAFT";
    const isSecondStep = this.getStageCode() === "COMPLETED";
    const isDraft =
      !this.kpiDataInstance.item ||
      !this.kpiDataInstance.item.status ||
      this.kpiDataInstance.item.status.code == "DRAFT" ||
      this.kpiDataInstance.item.status.code == "TO_BE_REVISED";

    const assessmentColumn =
      !isFirstStep && !isSecondStep ? (
        <Column
          title={<FormattedMessage id="goalForm.column.assessment" />}
          dataIndex="assessment"
          key="assessment"
          render={this.assessmentColumnRender}
        />
      ) : null;

    const employeeCommentColumn =
      !isFirstStep && !isSecondStep ? (
        <Column
          title={
            <Msg
              entityName={AssignedGoal.NAME}
              propertyName="employeeComment"
            />
          }
          dataIndex="employeeCommentColumn"
          key="employeeCommentColumn"
          render={this.employeeCommentRender}
        />
      ) : null;

    const managerAssessmentColumn =
      !isFirstStep && !isSecondStep && (!this.isInitiator() || !isDraft) ? (
        <Column
          title={
            <Msg
              entityName={AssignedGoal.NAME}
              propertyName="managerAssessment"
            />
          }
          dataIndex="managerAssessment"
          key="managerAssessment"
          render={this.managerAssessmentColumnRender}
        />
      ) : null;

    const managerComment =
      !isFirstStep && !isSecondStep && (!this.isInitiator() || !isDraft) ? (
        <Column
          title={
            <Msg entityName={AssignedGoal.NAME} propertyName="managerComment" />
          }
          dataIndex="managerComment"
          key="managerComment"
          render={this.managerCommentRender}
        />
      ) : null;

    return (
      <Table
        style={{ overflowX: "auto" }}
        dataSource={
          this.dataCollection.length > 0 ? this.dataCollection.slice() : []
        }
        pagination={false}
        size="default"
        bordered={false}
        rowKey="id"
      >
        <Column
          key="category_icon"
          render={(text, record: AssignedGoal, index) => {
            const GoalTypeIcon = this.getGoalTypeIcon(record.goalType);
            return GoalTypeIcon ? (
              <span
                className={
                  "ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal"
                }
                title={
                  record && record.positionGroup
                    ? record.positionGroup["_instanceName"] || ""
                    : ""
                }
              >
                <GoalTypeIcon style={{ width: "14px" }} />
              </span>
            ) : null;
          }}
        />
        <Column
          title={<Msg entityName={AssignedGoal.NAME} propertyName="category" />}
          dataIndex="category._instanceName"
          key="category"
          sorter={(a: AssignedGoal, b: AssignedGoal) => {
            return (a.category as SerializedEntity<
              DicGoalCategory
            >)._instanceName!.localeCompare(
              (b.category as SerializedEntity<DicGoalCategory>)._instanceName
            );
          }}
        />
        <Column
          title={<FormattedMessage id="goalForm.column.kpiName" />}
          dataIndex="goalString"
          key="goalString"
          sorter={(a: any, b: any) => {
            const renderedText =
              a.goalString && a.goalString.trim().length > 0
                ? a.goalString
                : a.goal
                ? (a.goal as SerializedEntity<Goal>)._instanceName
                : null;

            return (renderedText || "").localeCompare(b.goalString);
          }}
          render={(text, record: AssignedGoal, index) => {
            const renderedText =
              record.goalString && record.goalString.trim().length > 0
                ? record.goalString
                : record.goal
                ? (record.goal as SerializedEntity<Goal>)._instanceName
                : null;

            return this.props.readonly || (record as any).cantDelete ? (
              renderedText
            ) : (
              <Link to={this.getGoalUrl(record)}>{renderedText}</Link>
            );
          }}
        />
        <Column
          title={<FormattedMessage id="goalForm.column.kpiDetails" />}
          dataIndex="successCriteria"
          key="successCriteria"
          sorter={(a: any, b: any) => {
            if (a.key) {
              return a;
            }
            const aSuccessCriteria =
              a.successCriteriaLang ||
              (a.goal ? a.goal.successCriteriaLang : undefined);
            const bSuccessCriteria =
              b.successCriteriaLang ||
              (b.goal ? b.goal.successCriteriaLang : undefined);

            if (aSuccessCriteria && bSuccessCriteria) {
              return aSuccessCriteria.localeCompare(bSuccessCriteria);
            }
            if (aSuccessCriteria) return 1;
            if (bSuccessCriteria) return -1;
            return 0;
          }}
          render={(text, record) => {
            const assignedGoal = record as AssignedGoal;
            return (
              text ||
              (assignedGoal.goal
                ? (assignedGoal.goal as any).successCriteriaLang
                : "")
            );
          }}
        />
        <Column
          title={<FormattedMessage id="kpi.goals.weight" />}
          dataIndex="weight"
          key="weight"
          sorter={(a: any, b: any) => {
            if (a.key) {
              return a;
            }
            return a.weight - b.weight;
          }}
          render={this.weightColumnRender}
        />

        {assessmentColumn}

        {employeeCommentColumn}

        {managerAssessmentColumn}

        {managerComment}

        <Column
          title={this.props.intl.formatMessage({ id: "actions" })}
          key="action"
          render={(ag, record: AssignedGoal) => {
            return (
              <div style={{ display: "grid", justifyItems: "left" }}>
                {[
                  <Button
                    type="link"
                    key={`save_${record.id}`}
                    disabled={this.onClickSave === record.id}
                    style={{
                      padding: 0,
                      color: "green",
                      display: this.isWeightEditable.get(record)
                        ? "block"
                        : "none"
                    }}
                    onClick={event => {
                      this.onClickSave = record.id;
                      this.commitAssignedGoal(record);
                      this.onClickSave = undefined;
                    }}
                  >
                    <span>
                      <FormattedMessage id="save" />
                    </span>
                  </Button>,

                  <GoalHistory goal={record} />,

                  this.props.readonly ? <></> : <CascadeBtn goal={record} />,

                  this.props.readonly ||
                  (record as any).cantDelete ||
                  record.parentAssignedGoal ? (
                    <></>
                  ) : (
                    <Button
                      type="link"
                      style={{ padding: 0, color: "red" }}
                      onClick={() => this.showDeletionDialog(ag)}
                    >
                      <Icon
                        type="delete"
                        style={{ fontSize: "18px", cursor: "pointer" }}
                      />
                    </Button>
                  )
                ]}
              </div>
            );
          }}
        />
      </Table>
    );
  }

  weightColumnRender = (text: string, record: AssignedGoal) => {
    return this.isWeightEditable.get(record) ? (
      <div>
        <Form.Item>
          {this.form.getFieldDecorator("weight/" + record.id, {
            initialValue: record.weight,
            rules: [
              {
                required: true,
                message: this.props.intl.formatMessage(
                  { id: "form.validation.required" },
                  {
                    fieldName: this.messages[AssignedGoal.NAME + "." + "weight"]
                  }
                )
              }
            ]
          })(
            <InputNumber
              onChange={value => {
                record.weight = value;
                // this.changed.set(record, true);
                return value;
              }}
            />
          )}
        </Form.Item>
      </div>
    ) : (
      text
    );
  };

  componentDidMount(): void {
    //this.props.setIsNotCBAForm(false)
    this.load();
    this.kpiDataInstance.load();

    this.reactionDisposer = reaction(
      () => this.dataCollection,
      item => {
        if (this.props.setTotalWeight) {
          if (this.dataCollection.length > 0) {
            const reduce = this.dataCollection
              .map((i: AssignedGoal) => (i.weight ? i.weight : 0))
              .reduce((i1, i2) => i1 + i2, 0);
            this.props.setTotalWeight(reduce);
          } else {
            this.props.setTotalWeight(0);
          }
        }
        this.recalcTotalResult();
      }
    );

    if (this.props.setAssignedPerformanceState)
      this.props.setAssignedPerformanceState({
        update: this.update,
        validate: this.validate
      });
  }

  load = async () => {
    await restQueries
      .kpiAssignedGoals(this.props.assignedPerformancePlanId)
      .then(ag => {
        runInAction(() => {
          this.dataCollection = ag;
        });
      });
  };

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

  getGoalUrl = (assignedGoal: AssignedGoal): string => {
    return `${this.props.match.url}${
      this.props.match.url[this.props.match.url.length - 1] === "/" ? "" : "/"
    }goal/${(assignedGoal.goalType as string).toLowerCase()}/${
      assignedGoal.id
    }`;
  };

  getGoalTypeIcon = (goalType: AssignedGoalTypeEnum | undefined) => {
    switch (goalType) {
      case AssignedGoalTypeEnum.CASCADE: {
        return CascadeSvg;
      }
      case AssignedGoalTypeEnum.LIBRARY: {
        return LibrarySvg;
      }
      case AssignedGoalTypeEnum.INDIVIDUAL: {
        return IndividualSvg;
      }
      default: {
        return null;
      }
    }
  };

  @action
  setWeightEditable = (goal: AssignedGoal): void => {
    this.isWeightEditable.set(
      goal,
      !!(
        this.kpiDataInstance.item &&
        goal.parentAssignedGoal &&
        (this.kpiDataInstance.item.status!.code === "DRAFT" ||
          this.kpiDataInstance.item.status!.code === "TO_BE_REVISED") &&
        (this.getStageCode() === "DRAFT" ||
          this.getStageCode() === "COMPLETED") &&
        this.isUserManager
      )
    );
  };
}

export default injectIntl(withRouter(AssignedGoalList));
