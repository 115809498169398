import * as React from "react";
import {createElement} from "react";
import {Card, Checkbox, Col, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";

import {
  collection,
  DataCollectionStore,
  getCubaREST,
  injectMainStore,
  instance,
  Msg, WithId,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../app/App.css";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {PersonDocument} from "../../../cuba/entities/base/tsadv$PersonDocument";
import moment from "moment";
import {PersonDocumentRequestManagement} from "../PersonDocumentRequest/PersonDocumentRequestManagement";
import {goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import {formatDate} from "../../util/Date/Date";
import {DisabilityRequest} from "../../../cuba/entities/base/tsadv_DisabilityRequest";
import {Disability} from "../../../cuba/entities/base/tsadv$Disability";
import {DicDisabilityType} from "../../../cuba/entities/base/tsadv$DicDisabilityType";
import TextArea from "antd/es/input/TextArea";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

type EditorProps = {
  entityId: string;
  disabilityId?: string;
};

@inject("rootStore")
@injectMainStore
@observer
class DisabilityRequestEditComponent extends AbstractBprocEdit<DisabilityRequest, EditorProps> {
  processDefinitionKey = "disabilityRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<DisabilityRequest>(DisabilityRequest.NAME, {
    view: "disabilityRequest.edit",
    loadImmediately: false
  });

  groupDisabilityDc = collection<DicDisabilityType>(DicDisabilityType.NAME, {
    view: "dicDisabilityType-edit",
    sort: "-updateTs",
    filter: {
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]
      }]
    }
  });


  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  @observable
  editDisability : Disability;

  instanceEditDisability = instance<Disability>(Disability.NAME, {
    view: "portal.my-profile",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  approverHrRoleCode: string;

  @observable
  hasDisability: boolean | undefined = this.editDisability && this.editDisability ? this.editDisability!.hasDisability! : false;


  fields = [

    "requestNumber",

    "requestDate",

    "status",

    "attachments",

    "hasDisability",

    "disabilityType",

    "dateTo",

    "dateFrom"
  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())

      return super.getUpdateEntityData();
    return {
      personGroupExt: this.personGroupId,
      disability: this.editDisability ? this.editDisability.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {

    if (!this.mainStore) return <LoadingPage/>
    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    return (
      <div>
        <ScrollToTopOnMount />
          <Page pageName={<FormattedMessage id={"disabilityRequest.title"}/>}>
        <Section className='large'>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}
              />

              <Form.Item
                label={createElement(Msg, {entityName: entityName, propertyName: "personGroupExt"})}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>

              {
                this.editDisability ?
                  <Row type={"flex"} className={"data-form"}>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderAddress()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderAddressRequest()}
                    </Col>
                  </Row>
                  : this.renderAddressRequest()
              }

              <ReadonlyField
                entityName={entityName}
                propertyName="attachments"
                disabled={isNotDraft}
                form={this.props.form}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.intl.formatMessage({id: "form.validation.disabilityRequest.file"})})
                  }],
                }}
                formItemOpts={{style: {marginBottom: "12px"}, label:this.props.intl.formatMessage({id:"disabilityRequest.file"})}}
              />

            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
      </div>
    );
  }

  renderAddress = () => {

    const entityName = this.dataInstance.entityName;


    return (
     <div>


       <Form.Item
         label={createElement(Msg, {entityName: entityName, propertyName: "hasDisability"})}>
         <Checkbox
           checked={this.editDisability && this.editDisability ? this.editDisability!.hasDisability! : false}
           disabled/>
       </Form.Item>
       <Form.Item
         label={createElement(Msg, {entityName: entityName, propertyName: "disabilityType"})}>
         <Input
           value={this.editDisability && this.editDisability.disabilityType ? this.editDisability.disabilityType['_instanceName'] || '' : ''}
           disabled/>
       </Form.Item>
       <Form.Item
         label={createElement(Msg, {entityName: entityName, propertyName: "dateFrom"})}>
         <Input
           value={formatDate(this.editDisability.dateFrom)}
           disabled/>
       </Form.Item>
       <Form.Item
         label={createElement(Msg, {entityName: entityName, propertyName: "dateTo"})}>
         <Input
           value={formatDate(this.editDisability.dateTo)}
           disabled/>
       </Form.Item>
     </div>
    );
  }

  renderAddressRequest = () => {

    const messages = this.mainStore.messages!;

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    const {getFieldDecorator} = this.props.form;
    return (
      <div>

          <ReadonlyField
            entityName={entityName}
            propertyName="hasDisability"
            form={this.props.form}
            disabled={isNotDraft}
            formItemOpts={{
              style: {marginBottom: "12px"},
            }}
            getFieldDecoratorOpts={{
              valuePropName: "checked",
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.hasDisability']})
              }],
            }}
          />
          {/*//   <Form.Item*/}
          {/*//   style={{marginBottom: "12px"}}*/}
          {/*//   label={<Msg entityName={entityName} propertyName={"hasDisability"}/>}  >*/}
          {/*// {getFieldDecorator("hasDisability", {*/}
          {/*//   rules: [{*/}
          {/*//   required: true,*/}
          {/*//   message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.hasDisability']})*/}
          {/*// }]*/}
          {/*// })(*/}
          {/*//   <Checkbox onChange={(e)=>{*/}
          {/*//   const value = e.target.checked*/}
          {/*//   if(this.editDisability){*/}
          {/*//   this.changedMap.set('hasDisability',value)*/}
          {/*// }*/}
          {/*// }}/>*/}
          {/*//   )}*/}
          {/*//   </Form.Item>*/}

        <ReadonlyField
          entityName={entityName}
          propertyName="disabilityType"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={this.generateOptionItems(this.groupDisabilityDc)}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.disabilityType']})
            }],
            getValueFromEvent: args => {
              if (this.editDisability)
                this.changedMap.set('disabilityType', args !== (this.editDisability.disabilityType ? this.editDisability.disabilityType.id : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('disabilityType'),
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="dateFrom"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.dateFrom']})
            }],
            getValueFromEvent: args => {
              if (this.editDisability)
                this.changedMap.set('dateFrom', this.editDisability.dateFrom ? !moment(this.editDisability.dateFrom).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('dateFrom'),
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="dateTo"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.dateTo']})
            }],
            getValueFromEvent: args => {
              if (this.editDisability)
                this.changedMap.set('dateTo', this.editDisability.dateTo ? !moment(this.editDisability.dateTo).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('dateTo'),
          }}
        />
      </div>

    );
  }

  generateOptionItems = (dataCollection:DataCollectionStore<any>):DataCollectionStore<WithId>=>{
    let newArr:any = {items:[]};
    dataCollection.items.forEach(item=>{
      if(item.company!.code !=="empty"){
        return  newArr.items && newArr.items.push(item)
      }else return newArr.items && newArr.items.push(item)
    })
    return newArr
  }

  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: DisabilityRequest | undefined) => {
    this.personGroupId = item && item.personGroupExt ? item.personGroupExt.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();


    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const disabilityId = this.props.disabilityId || (item && item.disability ? item.disability.id : undefined)

    if (disabilityId)
      getCubaREST()!.loadEntity(Disability.NAME, disabilityId, {view: 'disability.all'})
        .then(value => this.editDisability = value as PersonDocument)
        .then(value => {
          this.instanceEditDisability.setItem(value);
          const properties = [

            "hasDisability",

            "disabilityType",

            "dateTo",

            "dateFrom"];
          if (this.props.entityId === PersonDocumentRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditDisability.getFieldValues(properties));
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(DisabilityRequestEditComponent))
);
