import * as React from "react";
import ApprovmentScreen from "./ApprovmentScreen";
import {SerializedEntity} from "@cuba-platform/rest";
import {DicCurrency} from "../../../../cuba/entities/base/base$DicCurrency";
import {RouteComponentProps} from "react-router";

type Props = RouteComponentProps<{
  learningBudgetId: string
}>

export class ApprovmentManagement extends React.Component<Props> {
  static PATH = "learningBudget/:learningBudgetId/";

  render() {
    const {learningBudgetId} = this.props.match.params
    return <ApprovmentScreen entityId={learningBudgetId} currency={{
      _instanceName: 'USD'
    } as SerializedEntity<DicCurrency>}/>
  }
}
