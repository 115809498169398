import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import JobDescriptionRequestEdit from "./JobDescriptionRequestEdit";
import JobDescriptionRequestList from "./JobDescriptionRequestList";

type Props = RouteComponentProps<{ entityId?: string }>;

@observer
export class JobDescriptionRequestManagement extends React.Component<Props> {
  static PATH = "/jobDescriptionRequest";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId } = this.props.match.params;
    return (
      <>
        {entityId ? (
          <JobDescriptionRequestEdit entityId={entityId} />
        ) : (
          <JobDescriptionRequestList />
        )}
      </>
    );
  }
}
