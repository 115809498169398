import React, { Component } from 'react';
import Search from "antd/es/input/Search";
import {Icon, Spin, Tree} from "antd";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { restServices } from "../../../../cuba/services";
import { OrganizationTree } from "../../../../cuba/entities/base/tsadv$OrganizationTree";
import { AntTreeNode, AntTreeNodeExpandedEvent } from "antd/lib/tree/Tree";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { catchException } from "../../../util/util";
import Notification from "../../../util/Notification/Notification";

const {TreeNode} = Tree;

export type OrganizationNode = {
  id: string,
  name: string,
  hasChild: boolean,
  data: OrganizationTree,
  childes?: OrganizationNode[]
}

type Props = {
  onSelectNode?: (a: OrganizationNode) => void
}

@observer
class TreeOrganization extends Component<WrappedComponentProps & Props> {
  @observable dataList: any
  @observable isDataPosting: boolean = false
  @observable onSearching: boolean = false
  @observable isLoading: boolean = false
  @observable searchValue: any
  @observable selectedOrganizationId?: string | null;
  @observable expandedKeys: string[] = [];
  @observable expandedKeys2: any;
  @observable isSearch = false;
  @observable organizationNodes: OrganizationNode[] = [];

  render() {
    return (
      <div style={{height: "100%", width: "100%", overflow: 'auto'}}>
        <Search style={{padding: '10px 5px 10px 10px'}}
                placeholder={this.props.intl.formatMessage({id: 'search'})}
                onSearch={this.onSearch}/>

        {!(this.isDataPosting && this.onSearching && this.searchValue.length>0) ?
        <Tree
          expandedKeys={[...this.expandedKeys]}
          onExpand={this.onExpand}
          showIcon
          style={{overflowX: 'auto', fontSize: 'small'}}
          onSelect={this.onSelect}
          loadData={this.onLoadData}>
          {this.renderTreeNodes(this.organizationNodes)}
        </Tree>: <div>
            <Spin spinning={this.isLoading}>
              <Tree
              expandedKeys={this.expandedKeys2}
              onExpand={this.onExpand2}
              onSelect={this.onSelect}
            >
              {this.renderTreeNodesOnSearch(this.loop(this.organizationNodes))}
            </Tree>
            </Spin>
          </div>
        }

      </div>

    );
  }

  renderTreeNodesOnSearch = (data: OrganizationNode[]): any => {
    if(!data) return  Notification.error({
      message: this.props.intl.formatMessage({id:"searchvalue.text.error"})
    });
    return data.map(item => {
      return <TreeNode title={item.name}
                       key={item.id}
                       dataRef={item}
                       isLeaf={item.hasChild}>
        {item.childes ? this.renderTreeNodesOnSearch(item.childes) : null}
      </TreeNode>
    });
  }
  onSelect = (keys: string[]): void => {
    if (keys && keys.length > 0 && this.props.onSelectNode) {
      let organizationNode = this.getRecordByKey(keys[0])!;
      this.props.onSelectNode(organizationNode);
    }
  }

  getRecordByKey = (key: string): OrganizationNode | undefined => {
    return this.getRecordByIdFromData(key, this.organizationNodes);
  }

  getRecordByIdFromData = (id: string, dataArray: OrganizationNode[]): OrganizationNode | undefined => {
    if (dataArray) {
      for (let data of dataArray) {
        if (data.id === id) return data;
        if (data.childes) {
          const result = this.getRecordByIdFromData(id, data.childes);
          if (result !== undefined) return result;
        }
      }
    }
    return undefined;
  };


  isUpperCase = (str:string) => {
    return str == str.toUpperCase() && str != str.toLowerCase();
  }
  ucFirst = (str:string) =>{
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  }
  ucAll = (str:string) =>{
    if (!str) return str;
    return str.toUpperCase() ;
  }
  loop = (data:any):any => {
    if(data.length>0 && data[0].name) {
      return data.map((item: any) => {
        let text: string = ""
        if(this.isUpperCase(item!.name!)){
          text = this.ucAll(this.searchValue)
        }else{
          text = this.ucFirst(this.searchValue)
        }

        let index;
        index = item!.name!.toLowerCase()!.indexOf(this.searchValue!.toLowerCase()!)
        const beforeStr = item.name.substr(0, item!.name!.toLowerCase()!.indexOf(this.searchValue!));
        const afterStr = item.name.substr(index + this.searchValue!.length);
        const name =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{color: "orangered"}}>
                {(beforeStr.length>0)?   this.searchValue : text}
              </span>
              {afterStr}
            </span>
          ) : (
            <span>{item.name}</span>
          );
        if (item.childes) {
          return {name, id: item.id, childes: this.loop(item.childes)};
        }
        return {
          name,
          id: item.id,
        };

      })
    }
  }
  onExpand2 = (expandedKeys:any) => {
    this.expandedKeys2 = expandedKeys;
  };
  renderTreeNodes = (data: OrganizationNode[]): any => {
    return data.map(item => {
      return <TreeNode title={item.name}
                       icon={<Icon type="user" style={{color: "black"}}/>}
                       key={item.id}
                       dataRef={item}
                       isLeaf={!item.hasChild}>
        {item.childes && item.childes.length > 0 ? this.renderTreeNodes(item.childes) : null}
      </TreeNode>
    });
  };

  loadOrganizations = () => {
    return restServices.organizationService.loadOrganizationTree({
      parentId: null,
      hierarchyId: null,
      searchText: null
    }).then(async companies => {
      const organizationNodes: OrganizationNode[] = []
      for (let company of companies) {
        organizationNodes.push({
          id: company.id,
          name: company.organizationName!,
          data: company,
          hasChild: company.hasChild!
        })
      }

      this.organizationNodes = organizationNodes;
    });
  }

  onSearch = (searchText: string) => {
    this.isLoading = true
    this.onSearching = true
    this.searchValue = searchText.toLowerCase().trim()
    this.selectedOrganizationId = undefined;
    if (!searchText || searchText === "") {
      if (this.isSearch) this.isSearch = false
      this.expandedKeys = [];
      this.loadOrganizations();
    }

    return catchException(restServices.organizationService.loadOrganizationTree({
      parentId: null,
      hierarchyId: null,
      searchText: searchText.trim()
    }).then(organizationWithoutChildes => organizationWithoutChildes.map(o => {
      return {
        id: o.id,
        name: o.organizationName,
        data: o,
        hasChild: o.hasChild
      } as OrganizationNode
    })).then(value => {

      this.dataList = value
      this.expandedKeys2 = value.map(item=>item.id)
      this.organizationNodes = value;
      let newArr:any[] = []
      newArr = this.organizationNodes.map(item=>({
        id:item.id, name:item.name,
        parentId:item.data && item.data!.hasOwnProperty('parent') ? item.data!.parent!.id!: null,
        childes:[],
        hasChild:item.hasChild,
        data:item.data
      }))
      const arrMap = newArr.reduce((acc, el, i) => {
        acc[el.id] = i;
        return acc;
      }, {});

      let roots:any[] = [];

      newArr.forEach((el:any) => {
        if (el.parentId === null) {
          roots.push(el);
        } else {
          newArr[arrMap[el.parentId]].childes.push(el);
        }
      });
      this.organizationNodes = roots
      this.isDataPosting = true
      this.isLoading = false
    })).catch((reason: Error) => {
      Notification.error({
        message: reason.message
      });
    });


  }

  onExpand = (expandedKeys: string[], info: AntTreeNodeExpandedEvent) => {
    if (info.expanded) {
      this.expandedKeys = [...this.expandedKeys, info.node.props.eventKey!];
    } else {
      this.expandedKeys.splice(this.expandedKeys.indexOf(info.node.props.eventKey!), 1);
    }
    this.expandedKeys = [...this.expandedKeys];
  };

  onLoadData = (treeNode: AntTreeNode): PromiseLike<void> => {
    return new Promise(resolve => {
      const key = treeNode.props.eventKey;
      if (treeNode.props.children || !key) {
        resolve();
        return;
      }

      const parentId = key.substring(key.indexOf("/") + 1);
      return restServices.organizationService.loadOrganizationTree({
        parentId: parentId,
        hierarchyId: null,
        searchText: null
      }).then(organizationWithoutChildes => organizationWithoutChildes.map(o => {
        return {
          id: o.id,
          name: o.organizationName,
          data: o,
          hasChild: o.hasChild
        }
      })).then(organizations => {
        treeNode.props.dataRef.childes = [...organizations];
        this.organizationNodes = [...this.organizationNodes];
        resolve();
      });
    });
  }

  componentDidMount(): void {
    this.loadOrganizations()
  }

}

export default injectIntl(TreeOrganization);