import MenuStore from "./MenuStore";
import LoginStore from "./LoginStore";
import UserSettingsStore from "./UserSettingsStore";
import UserInfoStore from "./UserInfoStore";
import {CubaApp} from "@cuba-platform/rest";
import BellNotificationStore from "./BellNotificationStore";
import ChangePasswordStore from "./ChangePasswordStore";
import KpiStore from "./KpiStore";
import {observable} from "mobx";
import DefaultGoalStore from "./DefaultGoalStore";
import CourseCatalogStore from "./CourseCatalogStore";
import MyTeamStore from "./MyTeamStore";
import AssistantTeamStore from "./AssistantTeamStore";
import PositionStructureStore from "./PositionStructureStore";
import GlobalStore from "./GlobalStore";
import LearningBudgetStore from "./LearningBudgetStore";
import RecognitionStore from "./RecognitionStore";
import VacationRequestStore from "./VacationRequestStore";
import CascadeGoalStore from "./CascadeGoalStore";
import IdpStore from "./IdpStore";
import CBAStore from "./CBAStore";
import PersonProfileStore from "./PersonProfileStore";
import ScheduleStandart from "./SheduleStandartStore";
import {InitIdpStore} from "./InitIdpStore";
import {resetActivityState} from "../pages/Activity/ActivityState";

export default class RootStore {
  cubaRest: CubaApp;
  menu: MenuStore;
  login: LoginStore;
  globalStore: GlobalStore;
  userSettings: UserSettingsStore;
  userInfo: UserInfoStore;
  bellNotification: BellNotificationStore;
  myTeamInfo: MyTeamStore;
  learningBudgetInfo: LearningBudgetStore;
  recognitionStore: RecognitionStore;
  cascadeGoalStore: CascadeGoalStore;
  assistantTeamInfo: AssistantTeamStore;
  changePassword: ChangePasswordStore;
  courseCatalogStore?: CourseCatalogStore;
  @observable idpStore?: IdpStore;
  positionStructureStore: PositionStructureStore;
  @observable kpiEditStore: KpiStore;
  @observable goalStore: DefaultGoalStore;
  @observable cbaStore?: CBAStore;
  @observable scheduleStore?: ScheduleStandart;
  @observable personProfileStore?: PersonProfileStore;
  initIdpStore: InitIdpStore;
  vacationRequestStore: VacationRequestStore;

  constructor(cubaRest: CubaApp, initIdpStore: InitIdpStore) {
    initIdpStore.rootStore = this;
    this.initIdpStore = initIdpStore;
    this.cubaRest = cubaRest;
    this.menu = new MenuStore(this);
    this.userSettings = new UserSettingsStore(this);
    this.login = new LoginStore(this);
    this.userInfo = new UserInfoStore(this);
    this.bellNotification = new BellNotificationStore(this);
    this.myTeamInfo = new MyTeamStore(this);
    this.learningBudgetInfo = new LearningBudgetStore(this);
    this.recognitionStore = new RecognitionStore(this);
    this.assistantTeamInfo = new AssistantTeamStore(this);
    this.positionStructureStore = new PositionStructureStore(this);
    this.globalStore = new GlobalStore(this);
    this.vacationRequestStore = new VacationRequestStore();
    this.cascadeGoalStore = new CascadeGoalStore(this);
    this.globalStore = new GlobalStore(this);
    this.cbaStore = new CBAStore(this);
    this.scheduleStore = new ScheduleStandart(this);
    this.createDefaultGoalStore();
  }

  createChangePasswordStore = () => {
    this.changePassword = new ChangePasswordStore(this);
  };

  createKpiStore = (appId: string) => {
    this.kpiEditStore = new KpiStore(this, appId);
  };

  createDefaultGoalStore = () => {
    this.goalStore = new DefaultGoalStore(this);
  };

  createCourseCatalogStore = () => {
    this.courseCatalogStore = new CourseCatalogStore(this);
  };
  createIdpStore = () => {
    this.idpStore = new IdpStore(this);
  }

  createPersonProfileStore = () => {
    this.personProfileStore = this.personProfileStore = new PersonProfileStore(this);
  }

  clearStores = () => {
    resetActivityState();
    this.courseCatalogStore = undefined;
    this.userInfo.clearUserInfo();
    this.login.clearCredentials();
    this.myTeamInfo.clearMyTeamInfo();
    this.assistantTeamInfo.clearAssistantTeamInfo();
    this.learningBudgetInfo.clearBudgetInfo();
    this.vacationRequestStore.clear();
    this.recognitionStore.clearRecognitionStore();
    this.cascadeGoalStore.clear();
  }
}