import { AbstractParentEntity } from "./AbstractParentEntity";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { SuccessionPlanning } from "./tsadv$SuccessionPlanning";
export class SuccessionPlanningHistory extends AbstractParentEntity {
  static NAME = "tsadv_SuccessionPlanningHistory";
  number?: number | null;
  initiator?: PersonGroupExt | null;
  type?: any | null;
  comments?: string | null;
  successionPlanning?: SuccessionPlanning | null;
  date?: any | null;
}
export type SuccessionPlanningHistoryViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "successionPlanningHistory-short-view";
export type SuccessionPlanningHistoryView<
  V extends SuccessionPlanningHistoryViewName
> = V extends "_base"
  ? Pick<
      SuccessionPlanningHistory,
      | "id"
      | "number"
      | "type"
      | "comments"
      | "date"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      SuccessionPlanningHistory,
      | "id"
      | "number"
      | "type"
      | "comments"
      | "date"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "successionPlanningHistory-short-view"
  ? Pick<
      SuccessionPlanningHistory,
      | "id"
      | "number"
      | "type"
      | "comments"
      | "date"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "initiator"
    >
  : never;
