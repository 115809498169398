import { AbstractParentEntity } from "./AbstractParentEntity";
import { NominationPerson } from "./tsadv_NominationPerson";
export class Nomination extends AbstractParentEntity {
  static NAME = "tsadv_Nomination";
  langName1?: string | null;
  langName2?: string | null;
  langName3?: string | null;
  descriptionLangName1?: string | null;
  descriptionLangName2?: string | null;
  descriptionLangName3?: string | null;
  year?: number | null;
  url?: string | null;
  nominationPersons?: NominationPerson[] | null;
}
export type NominationViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "nomination-with-persons";
export type NominationView<V extends NominationViewName> = V extends "_base"
  ? Pick<
      Nomination,
      | "id"
      | "langName1"
      | "langName3"
      | "langName2"
      | "descriptionLangName1"
      | "descriptionLangName2"
      | "descriptionLangName3"
      | "year"
      | "url"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      Nomination,
      | "id"
      | "langName1"
      | "langName2"
      | "langName3"
      | "descriptionLangName1"
      | "descriptionLangName2"
      | "descriptionLangName3"
      | "year"
      | "url"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_minimal"
  ? Pick<Nomination, "id" | "langName1" | "langName3">
  : V extends "nomination-with-persons"
  ? Pick<
      Nomination,
      | "id"
      | "langName1"
      | "langName2"
      | "langName3"
      | "descriptionLangName1"
      | "descriptionLangName2"
      | "descriptionLangName3"
      | "year"
      | "url"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "nominationPersons"
    >
  : never;
