import React, { Component } from 'react';
import { Card } from "antd";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { getCubaREST, injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import { LearningFeedbackTemplate } from "../../cuba/entities/base/tsadv$LearningFeedbackTemplate";
import { LearningFeedbackUsageType } from "../../cuba/enums/enums";
import { SerializedEntity } from "@cuba-platform/rest";
import { NavLink } from "react-router-dom";
import { restServices } from "../../cuba/services";
import { RootStoreProp } from "../store";
import { getLocaleName } from "../util/util";

type QuestionnaireModel = {
  id: string;
  description?: string | null;
}

@inject("rootStore")
@injectMainStore
@observer
class QuestionnaireSection extends Component<WrappedComponentProps & RootStoreProp & MainStoreInjected> {

  @observable questionnaire: QuestionnaireModel;

  render() {
    return this.questionnaire
      ? <div>
        <Card title={this.props.intl.formatMessage({id: "homePage.questionnaire"})} bordered={false}>
          <p className="questionnaire-text">{this.questionnaire ? this.questionnaire.description : null}</p>
          <div className="centered-flex-container w-100">
            <NavLink to={"/questionnaire/" + (this.questionnaire ? this.questionnaire.id : "")}>
              {this.props.intl.formatMessage({id: "homePage.questionnaire.pass"})}
            </NavLink>
          </div>
        </Card>
      </div>
      : null;
  }

  fetchQuestionnaire = () => {
    restServices.portalHelperService.companiesForLoadDictionary({
      personGroupId: this.props.rootStore!.userInfo.personGroupId!
    }).then(companies => {
      getCubaREST()!.searchEntities(LearningFeedbackTemplate.NAME, {
        conditions: [{
          property: "usageType",
          operator: "=",
          value: LearningFeedbackUsageType.DAILY_SURVEY
        }, {
          property: "company.id",
          operator: "in",
          value: companies
        }]
      }).then((questionnaires: SerializedEntity<LearningFeedbackTemplate>[]) => {
        if (questionnaires.length > 0) {
          console.log('description' + getLocaleName(this.props.mainStore!.locale!));
          this.setQuestionnaire({
            id: questionnaires[0].id,
            description: questionnaires[0]['description' + getLocaleName(this.props.mainStore!.locale!)],
          });
        }
      });
    })
  };

  componentDidMount(): void {
    this.fetchQuestionnaire();
  }

  @action
  setQuestionnaire = (questionnare: QuestionnaireModel) => {
    this.questionnaire = questionnare;
  };
}

export default injectIntl(QuestionnaireSection);