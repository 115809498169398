import * as React from "react";
import { RouteComponentProps } from "react-router";
import {inject, observer} from "mobx-react";
import AnnualSalaryRevisionList from "./AnnualSalaryRevisionList";
import AnnualSalaryRevisionRequestPage from "./AnnualSalaryRevisionRequest";
import {AnnualSalaryRevisionRequest} from "../../../cuba/entities/base/tsadv$AnnualSalaryRevisionRequest"
import {PersonProfile} from "../MyTeam/MyTeamCard";
import {RootStoreProp} from "../../store";
import {instanceStore} from "../../util/InstanceStore";

type Props = RouteComponentProps<{ entityId?: string }>;
export  type  PersonCardProps = {
  person?: PersonProfile
}
@inject("rootStore")
@observer
export class AnnualSalaryRevisionManagement extends React.Component<Props & PersonCardProps & RootStoreProp> {
  static PATH = "/annualSalaryRevisionRequest";

  dataInstance = instanceStore<AnnualSalaryRevisionRequest>(AnnualSalaryRevisionRequest.NAME, {
    view: "annualSalaryRevisionRequest-view",
    loadImmediately: true
  })

  render() {
    const { entityId } = this.props.match.params;
    return (
      <>
        {entityId ? (
          <AnnualSalaryRevisionRequestPage entityId={entityId}/>
        ) : (
          <AnnualSalaryRevisionList />
        )}
      </>
    );
  }
}
