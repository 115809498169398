import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Tabs} from "antd";
import {observer} from "mobx-react";
import PersonMedals from "./PersonMedals";
import RecognitionList from "./RecognitionList";
import {recognitionPersonWallTypeStore} from "../store/RecognitionsStore";
import CoinsHistoryComponent from "./CoinsHistoryComponent";
import Analytics from "./Chart/Analytics";
import {reaction} from "mobx";

type PersonFeedProps = {
  groupId: string;
  isMyProfile: boolean;
};

@observer
class PersonFeed extends React.Component<WrappedComponentProps & PersonFeedProps> {

  componentDidMount() {
    reaction(
      () => this.props.groupId,
      () => {
        recognitionPersonWallTypeStore.personGroup = this.props.groupId;
        recognitionPersonWallTypeStore.reloadRecognitions();
      },
      {fireImmediately: true}
    );

  }


  render() {
    const {groupId, isMyProfile} = this.props;

    return <>
      <PersonMedals personGroupId={groupId}/>

      <Tabs>
        <Tabs.TabPane tab={this.props.intl.messages["recognition.recognition"]} key={"recognition"}>
          <RecognitionList recognitionsStore={recognitionPersonWallTypeStore}/>
        </Tabs.TabPane>
        {isMyProfile &&
          <Tabs.TabPane tab={this.props.intl.messages["coins"]} key={"coins"}>
            <CoinsHistoryComponent/>
          </Tabs.TabPane>}
        {isMyProfile &&
          <Tabs.TabPane tab={this.props.intl.messages["recognition.analytics"]} key={"analytics"}>
            <Analytics/>
          </Tabs.TabPane>}
      </Tabs>
    </>
  }

}

export default injectIntl(PersonFeed);