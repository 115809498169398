import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import SurChargeRequestEdit from "./SurChargeRequestEdit";
import SurChargeRequestList from "./SurChargeRequestList";
import Page from "../../hoc/PageContentHoc";
import { FormattedMessage } from "react-intl";
import { Card } from "antd";
import { MyTeamSurChargeRequestManagement } from "./MyTeamSurChargeManagment";

type Props = RouteComponentProps<{
  entityId?: string
  personGroupId?: string
}>;

@observer
export class SurChargeRequestManagement extends React.Component<Props> {
  static PATH = "/surChargeRequest";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId, personGroupId } = this.props.match.params;
    if(personGroupId){
      if(entityId) return <SurChargeRequestEdit entityId={entityId} personGroupId={personGroupId}/>
      return <MyTeamSurChargeRequestManagement personGroupId={personGroupId}/>
    }
    return entityId ? (
      <SurChargeRequestEdit entityId={entityId} />
    ) : (
      <Page pageName={<FormattedMessage id={'menu.surCharge'}/>}>
        <Card
          bordered={false}
          style={{
            margin: "0 2rem"
          }}
        >
          <SurChargeRequestList
            key="list"
          />
        </Card>
      </Page>
    );
  }
}
