import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PersonMedalRequestEdit from './PersonMedalRequestEdit';

type Props = RouteComponentProps<{ entityId?: string }>;

class PersonMedalRequestManagement extends Component<Props> {
  static PATH_WITH_PARAMS = "personMedalRequest/:entityId/";
  static PATH = "personMedalRequest";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId } = this.props.match.params;
    return <PersonMedalRequestEdit entityId={entityId} />

  }
}

export default PersonMedalRequestManagement;