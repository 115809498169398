import React from "react";
import {Button, Col, Row} from "antd";
import {action, observable} from "mobx";
import {observer} from "mobx-react";
import PersonLink from "./PersonLink";
import {RecognitionCommentPojo} from "../../../../cuba/entities/base/tsadv$RecognitionCommentPojo";
import CommentEnterComponent from "./CommentSendComponent";
import {FormattedMessage} from "react-intl";

type CommentElementProps = {
  onSend: (comment: string) => Promise<void>,
  comment: RecognitionCommentPojo,
  onSelect: (commentId: string) => void
}

@observer
class CommentElement extends React.Component<CommentElementProps> {

  @observable showReply = false;

  @action onShowReply = () => {
    this.showReply = true;
  }

  @action onSend = async (comment: string) => {
    await this.props.onSend(comment);
    this.showReply = false;
  }

  render() {
    const comment = this.props.comment;

    return <>
      <Row style={{paddingTop: '10px'}} key={comment.id}>
        <Row type={"flex"} justify={"space-between"} style={{alignItems: "center"}}>
          <Col>
            <PersonLink person={comment.author!}/>
          </Col>
          <Col>
            <Button type={"link"} onClick={this.onShowReply} disabled={this.showReply}>
              <FormattedMessage id={"recognition.comment.reply"}/>
            </Button>
          </Col>
        </Row>
        <Row>
          {comment.createDate}
        </Row>
        <Row>
          {comment.parentCommentAuthor &&
            <a onClick={() => this.props.onSelect(comment.parentCommentId)}>{`@${comment.parentCommentAuthor.name!}, `}</a>}
          {comment.text}
        </Row>
      </Row>
      {this.showReply && <CommentEnterComponent onSend={this.onSend}/>}
    </>
  }
}

export default CommentElement;
