import React, { Component } from 'react';
import Questionnaire from "./Questionnaire";

class QuestionnaireManagement extends Component {
  static PATH = "/questionnaire/:entityId?";
  render() {
    return (
      <Questionnaire/>
    );
  }
}

export default QuestionnaireManagement;