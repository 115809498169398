import { StandardEntity } from "./sys$StandardEntity";
import { JobRequest } from "./tsadv$JobRequest";
export class JobRequestNote extends StandardEntity {
  static NAME = "tsadv_JobRequestNote";
  text?: string | null;
  jobRequest?: JobRequest | null;
}
export type JobRequestNoteViewName = "_base" | "_local" | "_minimal";
export type JobRequestNoteView<
  V extends JobRequestNoteViewName
> = V extends "_base"
  ? Pick<JobRequestNote, "id" | "text">
  : V extends "_local"
  ? Pick<JobRequestNote, "id" | "text">
  : never;
