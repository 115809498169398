import { Person } from "./base$Person";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { Category } from "./sys$Category";
import { DicNonresidentType } from "./tsadv$DicNonresidentType";
import { DicMaritalStatus } from "./tsadv$DicMaritalStatus";
import { DicPersonType } from "./tsadv$DicPersonType";
import { DicNationality } from "./tsadv$DicNationality";
import { DicCitizenship } from "./tsadv$DicCitizenship";
import { FileDescriptor } from "./sys$FileDescriptor";
import { DicSource } from "./tsadv$DicSource";
export class PersonExt extends Person {
  static NAME = "base$PersonExt";
  children?: PersonGroupExt | null;
  category?: Category | null;
  nonresidentType?: DicNonresidentType | null;
  dateOfDeath?: any | null;
  group?: PersonGroupExt | null;
  maritalStatus?: DicMaritalStatus | null;
  type?: DicPersonType | null;
  nationality?: DicNationality | null;
  citizenship?: DicCitizenship | null;
  fullNameCyrillic?: string | null;
  fullNameNumberCyrillic?: string | null;
  birthPlace?: string | null;
  academicDegree?: string | null;
  scientificWorksIventions?: string | null;
  stateAwards?: string | null;
  haveCrippleChild?: any | null;
  haveChildWithoutParent?: any | null;
  prevJobNDA?: any | null;
  prevJobObligation?: any | null;
  attachments?: FileDescriptor[] | null;
  commitmentsFromPrevJob?: any | null;
  commitmentsAttachments?: FileDescriptor[] | null;
  commitmentsLoan?: boolean | null;
  commitmentsCredit?: boolean | null;
  commitmentsNotSurMatValues?: boolean | null;
  haveNDA?: any | null;
  ndaAttachments?: FileDescriptor[] | null;
  reasonForDismissal?: string | null;
  prevJobHr?: string | null;
  haveConviction?: any | null;
  convictionAttachments?: FileDescriptor[] | null;
  registeredDispensary?: any | null;
  dispensaryAttachments?: FileDescriptor[] | null;
  dispensaryPeriod?: string | null;
  disability?: any | null;
  disabilityAttachments?: FileDescriptor[] | null;
  disabilityGroup?: string | null;
  contraindicationsHealth?: any | null;
  contraindicationsHealthAttachments?: FileDescriptor[] | null;
  contraindicationsHealthText?: string | null;
  childUnder18WithoutFatherOrMother?: any | null;
  childUnder18WithoutFatherOrMotherAttachments?: FileDescriptor[] | null;
  childUnder14WithoutFatherOrMother?: any | null;
  childUnder14WithoutFatherOrMotherAttachments?: FileDescriptor[] | null;
  criminalAdministrativeLiability?: any | null;
  criminalAdministrativeLiabilityAttachments?: FileDescriptor[] | null;
  criminalAdministrativeLiabilityPerioidReason?: string | null;
  personName?: string | null;
  city?: string | null;
  source?: DicSource | null;
  fioWithEmployeeNumberWithSortSupported?: string | null;
}
export type PersonExtViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "assignment.card.person"
  | "person-edit"
  | "person-for-search"
  | "person-view"
  | "person-view_1"
  | "person.browse"
  | "person.candidate"
  | "person.edit"
  | "person.full"
  | "person.info"
  | "person.minimal"
  | "person.teamMember"
  | "person.uploadImage.recognition"
  | "personExt.for.intern.browse"
  | "personExt.for.recognition.image"
  | "personExt.intern.edit"
  | "personExt.view.for.card"
  | "personGroup.browse.person";
export type PersonExtView<V extends PersonExtViewName> = V extends "_base"
  ? Pick<
      PersonExt,
      | "id"
      | "personName"
      | "lastName"
      | "firstName"
      | "middleName"
      | "employeeNumber"
      | "endDate"
      | "startDate"
      | "firstNameLatin"
      | "lastNameLatin"
      | "middleNameLatin"
      | "dateOfDeath"
      | "fullNameCyrillic"
      | "fullNameNumberCyrillic"
      | "birthPlace"
      | "academicDegree"
      | "scientificWorksIventions"
      | "stateAwards"
      | "haveCrippleChild"
      | "haveChildWithoutParent"
      | "prevJobNDA"
      | "prevJobObligation"
      | "commitmentsFromPrevJob"
      | "commitmentsLoan"
      | "commitmentsCredit"
      | "commitmentsNotSurMatValues"
      | "haveNDA"
      | "reasonForDismissal"
      | "prevJobHr"
      | "haveConviction"
      | "registeredDispensary"
      | "dispensaryPeriod"
      | "disability"
      | "disabilityGroup"
      | "contraindicationsHealth"
      | "contraindicationsHealthText"
      | "childUnder18WithoutFatherOrMother"
      | "childUnder14WithoutFatherOrMother"
      | "criminalAdministrativeLiability"
      | "criminalAdministrativeLiabilityPerioidReason"
      | "city"
      | "fioWithEmployeeNumberWithSortSupported"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "writeHistory"
      | "hireDate"
      | "nationalIdentifier"
      | "dateOfBirth"
    >
  : V extends "_local"
  ? Pick<
      PersonExt,
      | "id"
      | "dateOfDeath"
      | "fullNameCyrillic"
      | "fullNameNumberCyrillic"
      | "birthPlace"
      | "academicDegree"
      | "scientificWorksIventions"
      | "stateAwards"
      | "haveCrippleChild"
      | "haveChildWithoutParent"
      | "prevJobNDA"
      | "prevJobObligation"
      | "commitmentsFromPrevJob"
      | "commitmentsLoan"
      | "commitmentsCredit"
      | "commitmentsNotSurMatValues"
      | "haveNDA"
      | "reasonForDismissal"
      | "prevJobHr"
      | "haveConviction"
      | "registeredDispensary"
      | "dispensaryPeriod"
      | "disability"
      | "disabilityGroup"
      | "contraindicationsHealth"
      | "contraindicationsHealthText"
      | "childUnder18WithoutFatherOrMother"
      | "childUnder14WithoutFatherOrMother"
      | "criminalAdministrativeLiability"
      | "criminalAdministrativeLiabilityPerioidReason"
      | "city"
      | "fioWithEmployeeNumberWithSortSupported"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "firstName"
      | "firstNameLatin"
      | "middleNameLatin"
      | "lastName"
      | "lastNameLatin"
      | "middleName"
      | "hireDate"
      | "nationalIdentifier"
      | "dateOfBirth"
      | "employeeNumber"
      >
    : V extends "_minimal"
      ? Pick<
        PersonExt,
        | "id"
        | "personName"
        | "lastName"
        | "firstName"
        | "middleName"
        | "employeeNumber"
        | "endDate"
        | "startDate"
        | "firstNameLatin"
        | "lastNameLatin"
        | "middleNameLatin"
        >
      : V extends "assignment.card.person"
        ? Pick<
          PersonExt,
          | "id"
          | "dateOfDeath"
          | "fullNameCyrillic"
          | "fullNameNumberCyrillic"
          | "birthPlace"
          | "academicDegree"
          | "scientificWorksIventions"
          | "stateAwards"
          | "haveCrippleChild"
          | "haveChildWithoutParent"
          | "prevJobNDA"
          | "prevJobObligation"
          | "commitmentsFromPrevJob"
          | "commitmentsLoan"
          | "commitmentsCredit"
          | "commitmentsNotSurMatValues"
          | "haveNDA"
          | "reasonForDismissal"
          | "prevJobHr"
          | "haveConviction"
          | "registeredDispensary"
          | "dispensaryPeriod"
          | "disability"
          | "disabilityGroup"
          | "contraindicationsHealth"
          | "contraindicationsHealthText"
          | "childUnder18WithoutFatherOrMother"
          | "childUnder14WithoutFatherOrMother"
          | "criminalAdministrativeLiability"
          | "criminalAdministrativeLiabilityPerioidReason"
          | "city"
          | "fioWithEmployeeNumberWithSortSupported"
          | "legacyId"
          | "organizationBin"
          | "integrationUserLogin"
          | "startDate"
          | "endDate"
          | "writeHistory"
          | "firstName"
          | "firstNameLatin"
          | "middleNameLatin"
          | "lastName"
          | "lastNameLatin"
          | "middleName"
          | "hireDate"
          | "nationalIdentifier"
          | "dateOfBirth"
          | "employeeNumber"
          | "maritalStatus"
          | "type"
          | "nationality"
          | "citizenship"
          | "sex"
          | "group"
          | "image"
          | "nonresidentType"
          >
        : V extends "person-edit"
          ? Pick<
            PersonExt,
            | "id"
            | "version"
            | "createTs"
            | "createdBy"
            | "updateTs"
            | "updatedBy"
            | "deleteTs"
            | "deletedBy"
            | "dateOfDeath"
            | "fullNameCyrillic"
            | "fullNameNumberCyrillic"
            | "birthPlace"
            | "academicDegree"
            | "scientificWorksIventions"
            | "stateAwards"
            | "haveCrippleChild"
            | "haveChildWithoutParent"
            | "prevJobNDA"
            | "prevJobObligation"
            | "commitmentsFromPrevJob"
            | "commitmentsLoan"
            | "commitmentsCredit"
            | "commitmentsNotSurMatValues"
            | "haveNDA"
            | "reasonForDismissal"
            | "prevJobHr"
            | "haveConviction"
            | "registeredDispensary"
            | "dispensaryPeriod"
            | "disability"
            | "disabilityGroup"
            | "contraindicationsHealth"
            | "contraindicationsHealthText"
            | "childUnder18WithoutFatherOrMother"
            | "childUnder14WithoutFatherOrMother"
            | "criminalAdministrativeLiability"
            | "criminalAdministrativeLiabilityPerioidReason"
            | "city"
            | "fioWithEmployeeNumberWithSortSupported"
            | "legacyId"
            | "organizationBin"
            | "integrationUserLogin"
            | "startDate"
            | "endDate"
            | "writeHistory"
            | "firstName"
            | "firstNameLatin"
            | "middleNameLatin"
            | "lastName"
            | "lastNameLatin"
            | "middleName"
            | "hireDate"
            | "nationalIdentifier"
            | "dateOfBirth"
            | "employeeNumber"
            | "childUnder14WithoutFatherOrMother"
            | "childUnder18WithoutFatherOrMother"
            | "category"
            | "maritalStatus"
            | "type"
            | "nationality"
            | "citizenship"
            | "group"
            | "nonresidentType"
            | "sex"
            >
          : V extends "person-for-search"
            ? Pick<
              PersonExt,
              | "id"
              | "startDate"
              | "endDate"
              | "group"
              | "firstNameLatin"
              | "lastNameLatin"
              >
            : V extends "person-view"
              ? Pick<
                PersonExt,
                | "id"
                | "firstName"
                | "lastName"
                | "middleName"
                | "maritalStatus"
                | "type"
                | "nationality"
                | "citizenship"
                | "employeeNumber"
                | "group"
                | "legacyId"
                | "image"
                | "nonresidentType"
                >
              : V extends "person-view_1"
                ? Pick<
                  PersonExt,
                  | "id"
                  | "dateOfDeath"
                  | "fullNameCyrillic"
                  | "fullNameNumberCyrillic"
                  | "birthPlace"
                  | "academicDegree"
                  | "scientificWorksIventions"
                  | "stateAwards"
                  | "haveCrippleChild"
                  | "haveChildWithoutParent"
                  | "prevJobNDA"
                  | "prevJobObligation"
                  | "commitmentsFromPrevJob"
                  | "commitmentsLoan"
                  | "commitmentsCredit"
                  | "commitmentsNotSurMatValues"
                  | "haveNDA"
                  | "reasonForDismissal"
                  | "prevJobHr"
                  | "haveConviction"
                  | "registeredDispensary"
                  | "dispensaryPeriod"
                  | "disability"
                  | "disabilityGroup"
                  | "contraindicationsHealth"
                  | "contraindicationsHealthText"
                  | "childUnder18WithoutFatherOrMother"
                  | "childUnder14WithoutFatherOrMother"
                  | "criminalAdministrativeLiability"
                  | "criminalAdministrativeLiabilityPerioidReason"
                  | "city"
                  | "fioWithEmployeeNumberWithSortSupported"
                  | "legacyId"
                  | "organizationBin"
                  | "integrationUserLogin"
                  | "startDate"
                  | "endDate"
                  | "writeHistory"
                  | "firstName"
                  | "firstNameLatin"
                  | "middleNameLatin"
                  | "lastName"
                  | "lastNameLatin"
                  | "middleName"
                  | "hireDate"
                  | "nationalIdentifier"
                  | "dateOfBirth"
                  | "employeeNumber"
                  | "children"
                  | "group"
                  | "maritalStatus"
                  | "type"
                  | "nationality"
                  | "citizenship"
                  | "image"
                  >
                : V extends "person.browse"
                  ? Pick<
                    PersonExt,
                    | "id"
                    | "nonresidentType"
                    | "group"
                    | "maritalStatus"
                    | "type"
                    | "nationality"
                    | "citizenship"
                    | "fioWithEmployeeNumber"
                    | "legacyId"
                    | "startDate"
                    | "endDate"
                    | "image"
                    | "firstName"
                    | "firstNameLatin"
                    | "middleNameLatin"
                    | "lastName"
                    | "lastNameLatin"
                    | "middleName"
                    | "hireDate"
                    | "nationalIdentifier"
                    | "childUnder14WithoutFatherOrMother"
                    | "childUnder18WithoutFatherOrMother"
                    | "dateOfBirth"
                    | "sex"
                    >
                  : V extends "person.candidate"
                    ? Pick<
                      PersonExt,
                      | "id"
                      | "personName"
                      | "lastName"
                      | "firstName"
                      | "middleName"
                      | "employeeNumber"
                      | "endDate"
                      | "startDate"
                      | "firstNameLatin"
                      | "lastNameLatin"
                      | "middleNameLatin"
                      | "group"
                      | "maritalStatus"
                      | "type"
                      | "nationality"
                      | "citizenship"
                      | "nationalIdentifier"
                      | "dateOfBirth"
                      | "sex"
                      | "fullName"
                      | "shortName"
                      | "startDate"
                      | "endDate"
                      | "writeHistory"
                      | "image"
                      >
                    : V extends "person.edit"
                      ? Pick<
                        PersonExt,
                        | "id"
                        | "firstName"
                        | "lastName"
                        | "middleName"
                        | "employeeNumber"
                        | "group"
                        | "maritalStatus"
                        | "type"
                        | "nationality"
                        | "citizenship"
                        | "hireDate"
                        | "image"
                        | "nonresidentType"
                        >
                      : V extends "person.full"
                        ? Pick<
                          PersonExt,
                          | "id"
                          | "version"
                          | "createTs"
                          | "createdBy"
                          | "updateTs"
                          | "updatedBy"
                          | "deleteTs"
                          | "deletedBy"
                          | "dateOfDeath"
                          | "fullNameCyrillic"
                          | "fullNameNumberCyrillic"
                          | "birthPlace"
                          | "academicDegree"
                          | "scientificWorksIventions"
                          | "stateAwards"
                          | "haveCrippleChild"
                          | "haveChildWithoutParent"
                          | "prevJobNDA"
                          | "prevJobObligation"
                          | "commitmentsFromPrevJob"
                          | "commitmentsLoan"
                          | "commitmentsCredit"
                          | "commitmentsNotSurMatValues"
                          | "haveNDA"
                          | "reasonForDismissal"
                          | "prevJobHr"
                          | "haveConviction"
                          | "registeredDispensary"
                          | "dispensaryPeriod"
                          | "disability"
                          | "disabilityGroup"
                          | "contraindicationsHealth"
                          | "contraindicationsHealthText"
                          | "childUnder18WithoutFatherOrMother"
                          | "childUnder14WithoutFatherOrMother"
                          | "criminalAdministrativeLiability"
                          | "criminalAdministrativeLiabilityPerioidReason"
                          | "city"
                          | "fioWithEmployeeNumberWithSortSupported"
                          | "legacyId"
                          | "organizationBin"
                          | "integrationUserLogin"
                          | "startDate"
                          | "endDate"
                          | "writeHistory"
                          | "firstName"
                          | "firstNameLatin"
                          | "middleNameLatin"
                          | "lastName"
                          | "lastNameLatin"
                          | "middleName"
                          | "hireDate"
                          | "nationalIdentifier"
                          | "dateOfBirth"
                          | "employeeNumber"
                          | "group"
                          | "maritalStatus"
                          | "type"
                          | "nationality"
                          | "citizenship"
                          | "image"
                          | "sex"
                          >
                        : V extends "person.info"
                          ? Pick<
                            PersonExt,
                            | "id"
                            | "dateOfDeath"
                            | "fullNameCyrillic"
                            | "fullNameNumberCyrillic"
                            | "birthPlace"
                            | "academicDegree"
                            | "scientificWorksIventions"
                            | "stateAwards"
                            | "haveCrippleChild"
                            | "haveChildWithoutParent"
                            | "prevJobNDA"
                            | "prevJobObligation"
                            | "commitmentsFromPrevJob"
                            | "commitmentsLoan"
                            | "commitmentsCredit"
                            | "commitmentsNotSurMatValues"
                            | "haveNDA"
                            | "reasonForDismissal"
                            | "prevJobHr"
                            | "haveConviction"
                            | "registeredDispensary"
                            | "dispensaryPeriod"
                            | "disability"
                            | "disabilityGroup"
                            | "contraindicationsHealth"
                            | "contraindicationsHealthText"
                            | "childUnder18WithoutFatherOrMother"
                            | "childUnder14WithoutFatherOrMother"
                            | "criminalAdministrativeLiability"
                            | "criminalAdministrativeLiabilityPerioidReason"
                            | "city"
                            | "fioWithEmployeeNumberWithSortSupported"
                            | "legacyId"
                            | "organizationBin"
                            | "integrationUserLogin"
                            | "startDate"
                            | "endDate"
                            | "writeHistory"
                            | "firstName"
                            | "firstNameLatin"
                            | "middleNameLatin"
                            | "lastName"
                            | "lastNameLatin"
                            | "middleName"
                            | "hireDate"
                            | "nationalIdentifier"
                            | "dateOfBirth"
                            | "employeeNumber"
                            | "maritalStatus"
                            | "type"
                            | "nationality"
                            | "citizenship"
                            | "sex"
                            | "group"
                            | "image"
                            >
                          : V extends "person.minimal"
                            ? Pick<
                              PersonExt,
                              | "id"
                              | "firstName"
                              | "lastName"
                              | "middleName"
                              | "image"
                              | "type"
                              | "legacyId"
                              | "startDate"
                              | "endDate"
                              | "firstNameLatin"
                              | "middleNameLatin"
                              | "lastNameLatin"
                              | "hireDate"
                              | "employeeNumber"
                              >
                            : V extends "person.teamMember"
                              ? Pick<
                                PersonExt,
                                | "id"
                                | "dateOfDeath"
                                | "fullNameCyrillic"
                                | "fullNameNumberCyrillic"
                                | "birthPlace"
                                | "academicDegree"
                                | "scientificWorksIventions"
                                | "stateAwards"
                                | "haveCrippleChild"
                                | "haveChildWithoutParent"
                                | "prevJobNDA"
                                | "prevJobObligation"
                                | "commitmentsFromPrevJob"
                                | "commitmentsLoan"
                                | "commitmentsCredit"
                                | "commitmentsNotSurMatValues"
                                | "haveNDA"
                                | "reasonForDismissal"
                                | "prevJobHr"
                                | "haveConviction"
                                | "registeredDispensary"
                                | "dispensaryPeriod"
                                | "disability"
                                | "disabilityGroup"
                                | "contraindicationsHealth"
                                | "contraindicationsHealthText"
                                | "childUnder18WithoutFatherOrMother"
                                | "childUnder14WithoutFatherOrMother"
                                | "criminalAdministrativeLiability"
                                | "criminalAdministrativeLiabilityPerioidReason"
                                | "city"
                                | "fioWithEmployeeNumberWithSortSupported"
                                | "legacyId"
                                | "organizationBin"
                                | "integrationUserLogin"
                                | "startDate"
                                | "endDate"
                                | "writeHistory"
                                | "firstName"
                                | "firstNameLatin"
                                | "middleNameLatin"
                                | "lastName"
                                | "lastNameLatin"
                                | "middleName"
                                | "hireDate"
                                | "nationalIdentifier"
                                | "dateOfBirth"
                                | "employeeNumber"
                                | "group"
                                | "image"
                                >
                              : V extends "person.uploadImage.recognition"
                                ? Pick<
                                  PersonExt,
                                  | "id"
                                  | "dateOfDeath"
                                  | "fullNameCyrillic"
                                  | "fullNameNumberCyrillic"
                                  | "birthPlace"
                                  | "academicDegree"
                                  | "scientificWorksIventions"
                                  | "stateAwards"
                                  | "haveCrippleChild"
                                  | "haveChildWithoutParent"
                                  | "prevJobNDA"
                                  | "prevJobObligation"
                                  | "commitmentsFromPrevJob"
                                  | "commitmentsLoan"
                                  | "commitmentsCredit"
                                  | "commitmentsNotSurMatValues"
                                  | "haveNDA"
                                  | "reasonForDismissal"
                                  | "prevJobHr"
                                  | "haveConviction"
                                  | "registeredDispensary"
                                  | "dispensaryPeriod"
                                  | "disability"
                                  | "disabilityGroup"
                                  | "contraindicationsHealth"
                                  | "contraindicationsHealthText"
                                  | "childUnder18WithoutFatherOrMother"
                                  | "childUnder14WithoutFatherOrMother"
                                  | "criminalAdministrativeLiability"
                                  | "criminalAdministrativeLiabilityPerioidReason"
                                  | "city"
                                  | "fioWithEmployeeNumberWithSortSupported"
                                  | "legacyId"
                                  | "organizationBin"
                                  | "integrationUserLogin"
                                  | "startDate"
                                  | "endDate"
                                  | "writeHistory"
                                  | "firstName"
                                  | "firstNameLatin"
                                  | "middleNameLatin"
                                  | "lastName"
                                  | "lastNameLatin"
                                  | "middleName"
                                  | "hireDate"
                                  | "nationalIdentifier"
                                  | "dateOfBirth"
                                  | "employeeNumber"
                                  | "type"
                                  | "image"
                                  | "group"
                                  >
                                : V extends "personExt.for.intern.browse"
                                  ? Pick<
                                    PersonExt,
                                    | "id"
                                    | "personName"
                                    | "lastName"
                                    | "firstName"
                                    | "middleName"
                                    | "employeeNumber"
                                    | "endDate"
                                    | "startDate"
                                    | "firstNameLatin"
                                    | "lastNameLatin"
                                    | "middleNameLatin"
                                    | "group"
                                    | "startDate"
                                    | "endDate"
                                    | "nationalIdentifier"
                                    >
                                  : V extends "personExt.for.recognition.image"
                                    ? Pick<
                                      PersonExt,
                                      | "id"
                                      | "personName"
                                      | "lastName"
                                      | "firstName"
                                      | "middleName"
                                      | "employeeNumber"
                                      | "endDate"
                                      | "startDate"
                                      | "firstNameLatin"
                                      | "lastNameLatin"
                                      | "middleNameLatin"
                                      | "legacyId"
                                      | "startDate"
                                      | "endDate"
                                      | "image"
                                      | "group"
                                      >
                                    : V extends "personExt.intern.edit"
                                      ? Pick<
                                        PersonExt,
                                        | "id"
                                        | "dateOfDeath"
                                        | "fullNameCyrillic"
                                        | "fullNameNumberCyrillic"
                                        | "birthPlace"
                                        | "academicDegree"
                                        | "scientificWorksIventions"
                                        | "stateAwards"
                                        | "haveCrippleChild"
                                        | "haveChildWithoutParent"
                                        | "prevJobNDA"
                                        | "prevJobObligation"
                                        | "commitmentsFromPrevJob"
                                        | "commitmentsLoan"
                                        | "commitmentsCredit"
                                        | "commitmentsNotSurMatValues"
                                        | "haveNDA"
                                        | "reasonForDismissal"
                                        | "prevJobHr"
                                        | "haveConviction"
                                        | "registeredDispensary"
                                        | "dispensaryPeriod"
                                        | "disability"
                                        | "disabilityGroup"
                                        | "contraindicationsHealth"
                                        | "contraindicationsHealthText"
                                        | "childUnder18WithoutFatherOrMother"
                                        | "childUnder14WithoutFatherOrMother"
                                        | "criminalAdministrativeLiability"
                                        | "criminalAdministrativeLiabilityPerioidReason"
                                        | "city"
                                        | "fioWithEmployeeNumberWithSortSupported"
                                        | "legacyId"
                                        | "organizationBin"
                                        | "integrationUserLogin"
                                        | "startDate"
                                        | "endDate"
                                        | "writeHistory"
                                        | "firstName"
                                        | "firstNameLatin"
                                        | "middleNameLatin"
                                        | "lastName"
                                        | "lastNameLatin"
                                        | "middleName"
                                        | "hireDate"
                                        | "nationalIdentifier"
                                        | "dateOfBirth"
                                        | "employeeNumber"
                                        | "group"
                                        | "maritalStatus"
                                        | "type"
                                        | "sex"
                                        | "nationality"
                                        | "citizenship"
                                        >
                                      : V extends "personExt.view.for.card"
                                        ? Pick<
                                          PersonExt,
                                          | "id"
                                          | "dateOfDeath"
                                          | "fullNameCyrillic"
                                          | "fullNameNumberCyrillic"
                                          | "birthPlace"
                                          | "academicDegree"
                                          | "scientificWorksIventions"
                                          | "stateAwards"
                                          | "haveCrippleChild"
                                          | "haveChildWithoutParent"
                                          | "prevJobNDA"
                                          | "prevJobObligation"
                                          | "commitmentsFromPrevJob"
                                          | "commitmentsLoan"
                                          | "commitmentsCredit"
                                          | "commitmentsNotSurMatValues"
                                          | "haveNDA"
                                          | "reasonForDismissal"
                                          | "prevJobHr"
                                          | "haveConviction"
                                          | "registeredDispensary"
                                          | "dispensaryPeriod"
                                          | "disability"
                                          | "disabilityGroup"
                                          | "contraindicationsHealth"
                                          | "contraindicationsHealthText"
                                          | "childUnder18WithoutFatherOrMother"
                                          | "childUnder14WithoutFatherOrMother"
                                          | "criminalAdministrativeLiability"
                                          | "criminalAdministrativeLiabilityPerioidReason"
                                          | "city"
                                          | "fioWithEmployeeNumberWithSortSupported"
                                          | "legacyId"
                                          | "organizationBin"
                                          | "integrationUserLogin"
                                          | "startDate"
                                          | "endDate"
                                          | "writeHistory"
                                          | "firstName"
                                          | "firstNameLatin"
                                          | "middleNameLatin"
                                          | "lastName"
                                          | "lastNameLatin"
                                          | "middleName"
                                          | "hireDate"
                                          | "nationalIdentifier"
                                          | "dateOfBirth"
                                          | "employeeNumber"
                                          | "type"
                                          >
                                        : V extends "personGroup.browse.person"
                                          ? Pick<
                                            PersonExt,
                                            | "id"
                                            | "dateOfDeath"
                                            | "fullNameCyrillic"
                                            | "fullNameNumberCyrillic"
                                            | "birthPlace"
                                            | "academicDegree"
                                            | "scientificWorksIventions"
                                            | "stateAwards"
                                            | "haveCrippleChild"
                                            | "haveChildWithoutParent"
                                            | "prevJobNDA"
                                            | "prevJobObligation"
                                            | "commitmentsFromPrevJob"
                                            | "commitmentsLoan"
                                            | "commitmentsCredit"
                                            | "commitmentsNotSurMatValues"
                                            | "haveNDA"
                                            | "reasonForDismissal"
                                            | "prevJobHr"
                                            | "haveConviction"
                                            | "registeredDispensary"
                                            | "dispensaryPeriod"
                                            | "disability"
                                            | "disabilityGroup"
                                            | "contraindicationsHealth"
                                            | "contraindicationsHealthText"
                                            | "childUnder18WithoutFatherOrMother"
                                            | "childUnder14WithoutFatherOrMother"
                                            | "criminalAdministrativeLiability"
                                            | "criminalAdministrativeLiabilityPerioidReason"
                                            | "city"
                                            | "fioWithEmployeeNumberWithSortSupported"
                                            | "legacyId"
                                            | "organizationBin"
                                            | "integrationUserLogin"
                                            | "startDate"
                                            | "endDate"
                                            | "writeHistory"
                                            | "firstName"
                                            | "firstNameLatin"
                                            | "middleNameLatin"
                                            | "lastName"
                                            | "lastNameLatin"
                                            | "middleName"
                                            | "hireDate"
                                            | "nationalIdentifier"
                                            | "dateOfBirth"
                                            | "employeeNumber"
                                            | "sex"
                                            | "type"
                                            | "image"
                                            >
                                          : never;
