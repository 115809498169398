import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import PersonOtherInfoRequestEdit from "./PersonOtherInfoRequestEdit";

type Props = RouteComponentProps<{ entityId: string, personOtherInfoId?: string }>;

@observer
export class PersonOtherInfoRequestManagement extends React.Component<Props> {
  static PATH = "/personOtherInfoRequest";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId, personOtherInfoId} = this.props.match.params;
    return (
      <>
        <PersonOtherInfoRequestEdit entityId={entityId} personOtherInfoId={personOtherInfoId}/>
      </>
    );
  }
}
