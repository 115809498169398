import * as React from "react";
import {ChangeEvent, createElement, CSSProperties} from "react";
import {inject, observer} from "mobx-react";

import {
  collection,
  getCubaREST,
  injectMainStore,
  instance,
  MainStoreInjected, Msg,
  MultilineText,
  withLocalizedForm
} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";

import {AnnualSalaryRevisionRequest} from "../../../cuba/entities/base/tsadv$AnnualSalaryRevisionRequest"
import DataTableFormat from "../../components/DataTable/intex";
import {FormComponentProps} from "antd/lib/form";
import {withRouter} from "react-router";
import {AnnualSalaryRevisionPerson} from "../../../cuba/entities/base/tsadv$AnnualSalaryRevisionPerson";
import {Alert, Card, Divider, Form, Input, InputNumber, Radio, Table, Upload, Button as AntdButton} from "antd";

import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {action, observable, reaction, toJS} from "mobx";
import {AnnualSalaryRevisionManagement} from "./AnnualSalaryRevisionManagement";
import Button, {ButtonType} from "../../components/Button/Button";
import {goBackOrHomePage} from "../../util/util";
import {runReport} from "../../util/reportUtil";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import {parseToJsonFromFieldValue} from "../../components/MultiFileUpload";
import {RadioChangeEvent} from "antd/es/radio";
import {ExtTaskData} from "../../../cuba/entities/base/tsadv_ExtTaskData";
import * as XLSX from "xlsx";
import {UploadChangeParam} from "antd/lib/upload";
import Notification from "../../util/Notification/Notification";
import en from "../../../i18n/en.json";
import ru from "../../../i18n/ru.json";
import moment from "moment";
import {Grade} from "../../../cuba/entities/base/tsadv$Grade";
import SearchFilterDropdown from "./SearchFilterDropdown";
import {Organization} from "../../../cuba/entities/base/base$Organization";
import {PositionGroup} from "../../../cuba/entities/base/base$PositionGroup";
import {AnnualSalaryRevisionBaseIncreasePercent} from "../../../cuba/entities/base/tsadv$AnnualSalaryRevisionBaseIncreasePercent";
import {OrganizationGroup} from "../../../cuba/entities/base/base$OrganizationGroup";

type Props = FormComponentProps & EditorProps;

type EditorProps = {
  entityId: string;
};

@injectMainStore
@inject("rootStore")
@observer
class AnnualSalaryRevisionRequestComponent extends AbstractBprocEdit<AnnualSalaryRevisionRequest, EditorProps & Props & MainStoreInjected & WrappedComponentProps> {

  dataInstance = instance<AnnualSalaryRevisionRequest>(AnnualSalaryRevisionRequest.NAME, {
    view: "annualSalaryRevisionRequest-view",
    loadImmediately: true
  });

  @observable
  personDataCollection = collection<AnnualSalaryRevisionPerson>(AnnualSalaryRevisionPerson.NAME, {
    filter: {
      conditions: [{
        property: "limit",
        operator: "=",
        value: 10000
      }]
    },
    view: "annualSalaryRevisionPerson-view",
    loadImmediately: true
  });
  @observable initialModeratedPercent: number;
  @action handlePercentageChange = (record: any, value: number) => {
    if (this.obj[record.id][0] !== value) {
      this.obj[record.id][1] = 1
    } else {
      this.obj[record.id][1] = true
    }
  }
  personDataCollectionInitVersion = collection<AnnualSalaryRevisionPerson>(AnnualSalaryRevisionPerson.NAME, {
    filter: {
      conditions: [{
        property: "limit",
        operator: "=",
        value: 10000
      }]
    },
    view: "annualSalaryRevisionPerson-view",
    loadImmediately: true
  });

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_local"
  });

  personFields1 = [
    "employeeNumber",
    "organisation",
    "position",
    "person",
    "grade",
    "hireDate",
    "baseSalaryBegin",
    "increasePercentPerYear",
    "baseSalaryEnd",
    "increasePercent",
    "increasePercentModerated",
    "newSalaryRecommended",
    "newSalaryModerated",
    "increasePercentFinal",
    "comment",
    "status"
  ];

  @observable
  updated = false;

  processDefinitionKey = "asrRequest";

  @observable
  approverHrRoleCode: string;

  @observable
  isUserInitiator: boolean

  @observable
  activeTask: ExtTaskData | null;

  path = AnnualSalaryRevisionManagement.PATH;

  isUpdateBeforeOutcome = true;
  @observable isModeratedPercentageChanged = false;
  @observable
  reportCode?: string = undefined;

  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    } else if (this.isStartForm) {
      this.approverHrRoleCode = 'INITIATOR';
    }
  }

  @observable
  generalFOTBeforeIncrease = 0

  @observable
  generalFOTAfterIncrease = 0

  report = () => {
    const data = {
      parameters: [{
        name: "req",
        value: this.props.entityId
      }]
    };

    runReport(this.reportCode!, data, this.props.intl);
  }
  @observable filteredData: any = null;
  @action handleChange = (pagination: any, filters: any, sorters: any) => {
    this.filteredData = filters
  }
  @action emptyFilters = (name?: string) => {
    if (name) {
      this.filteredData[name] = null
    } else {
      this.filteredData = null
    }
  }
  getUpdateEntityData = (): any => {
    return {
      ...this.props.form.getFieldsValue(this.fields),
      file: parseToJsonFromFieldValue(this.props.form.getFieldValue('file')),
    }
  };

  actions = () => {
    const {status} = this.dataInstance;

    const actions = [];

    if (this.getStatusCode() === "APPROVED" || this.getStatusCode() === "APPROVING") {
      actions.push(<Button buttonType={ButtonType.FOLLOW}
                           disabled={status !== "DONE" && status !== "ERROR"}
                           loading={status === "LOADING"}
                           onClick={this.saveRequest}>
        <FormattedMessage id="management.editor.submit"/>
      </Button>);
    }

    actions.push(<Button buttonType={ButtonType.FOLLOW}
                         onClick={event => goBackOrHomePage(this.props.history)}>{this.props.intl.formatMessage({id: "close"})}</Button>);

    if (!this.isNewEntity()) {
      if (this.reportCode)
        actions.push(<Button buttonType={ButtonType.FOLLOW}
                             onClick={this.report}>{this.props.intl.formatMessage({id: "report"})}</Button>);

      actions.push(this.getOutcomeBtns());
    }
    return actions;
  }

  getRevisionName = () => {
    return this.dataInstance.item ?
      this.dataInstance.item.annualSalaryRevision ?
        this.dataInstance.item.annualSalaryRevision.nameLang1
        : ""
      : "";
  }

  getInitBeforeFotTotal = () => {
    let afterFotTotal = 0
    for (let item of this.personDataCollectionInitVersion.items) {
      afterFotTotal += item.baseSalaryEnd
    }
    return afterFotTotal
  }

  getInitAfterFotTotal = () => {
    let beforeFotTotal = 0
    for (let item of this.personDataCollectionInitVersion.items) {
      beforeFotTotal += item.newSalaryModerated
    }
    return beforeFotTotal
  }

  getAvgIncreasePercent = () => {
    let avg: any = 0
    avg = (this.getInitAfterFotTotal() - this.getInitBeforeFotTotal()) / (this.getInitBeforeFotTotal() / 100)
    if (avg % 1 != 0) {
      avg = avg.toFixed(2)
    }
    return avg
  }

  getInitPersonData = () => {
    let personFilters = []
    for (let item of this.personDataCollectionInitVersion.items) {
      if (item.increasePercent > item.increasePercentModerated) {
        let personFilterState = {
          [item.id]: {
            filterState: 1,
            increasePercent: item.increasePercent,
            increasePercentModerated: item.increasePercentModerated
          }
        }
        personFilters.push(personFilterState)
      } else {
        if (item.increasePercent < item.increasePercentModerated) {
          let personFilterState = {
            [item.id]: {
              filterState: -1,
              increasePercent: item.increasePercent,
              increasePercentModerated: item.increasePercentModerated
            }
          }
          personFilters.push(personFilterState)
        } else {
          let personFilterState = {
            [item.id]: {
              filterState: 0,
              increasePercent: item.increasePercent,
              increasePercentModerated: item.increasePercentModerated
            }
          }
          personFilters.push(personFilterState)
        }
      }
    }
    return personFilters
  }

  setPersonInitVersion = (id: string, ipm: number, nsm: number, ipf: number, comment: string) => {
    for (let i = 0; i < this.personDataCollectionInitVersion.items.length; i++) {
      if (this.personDataCollectionInitVersion.items[i].id == id) {
        this.personDataCollectionInitVersion.items[i].increasePercentModerated = ipm
        this.personDataCollectionInitVersion.items[i].newSalaryModerated = nsm
        this.personDataCollectionInitVersion.items[i].increasePercentFinal = ipf
        this.personDataCollectionInitVersion.items[i].comment = comment
        getCubaREST()!.commitEntity<AnnualSalaryRevisionPerson>(AnnualSalaryRevisionPerson.NAME, {
          id: this.personDataCollectionInitVersion.items[i].id,
          increasePercentModerated: ipm,
          newSalaryModerated: nsm,
          increasePercentFinal: ipf,
          comment: comment
        })
      }
    }
    this.getInitPersonData()
  }

  syncPersonDataCollection = () => {
    for (let i = 0; i < this.personDataCollection.items.length; i++) {
      for (let j = 0; j < this.personDataCollectionInitVersion.items.length; j++) {
        if (this.personDataCollection.items[i].id == this.personDataCollectionInitVersion.items[j].id) {
          this.personDataCollection.items[i].increasePercentModerated = this.personDataCollectionInitVersion.items[j].increasePercentModerated
          this.personDataCollection.items[i].increasePercentFinal = this.personDataCollectionInitVersion.items[j].increasePercentFinal
          this.personDataCollection.items[i].comment = this.personDataCollectionInitVersion.items[j].comment
          break;
        }
      }
    }
  }
  moderatedCollection = collection(AnnualSalaryRevisionBaseIncreasePercent.NAME, {
    view: "_minimal"
  })
  fields = [
    "requestNumber",
    "requestDate",
    "comment",
    "file",
    "status",
  ];
  filterPersonData = (condition: number) => {
    let personFilterArray = this.getInitPersonData()
    let filteredPersonIds: string[] = []
    for (let item of personFilterArray) {
      for (let id in item) {
        if (item[id].filterState === condition) {
          filteredPersonIds.push(id)
        }
      }
    }
    this.personDataCollection = collection<AnnualSalaryRevisionPerson>(AnnualSalaryRevisionPerson.NAME, {
      filter: {
        conditions: [{
          property: "id",
          operator: "in",
          value: filteredPersonIds
        }]
      },
      view: "annualSalaryRevisionPerson-view",
    });
    this.syncPersonDataCollection()
    return filteredPersonIds
  }

  filterPersonLessThanModerated = () => {
    this.filterPersonData(1)
  }

  filterPersonGreaterThanModerated = () => {
    this.filterPersonData(-1)
  }

  onChangeFilter = (e: RadioChangeEvent | React.FocusEvent<HTMLInputElement>) => {
    switch (e.target.value) {
      case "all":
        this.initPersonMembers()
        break;
      case "less":
        this.filterPersonLessThanModerated()
        break;
      case "greater":
        this.filterPersonGreaterThanModerated()
        break;
    }
  }

  testCB1 = (): boolean => {
    return this.approverHrRoleCode === 'C&B_GROUP'
      || this.approverHrRoleCode === 'C&B_COMPANY'
      || this.approverHrRoleCode === 'INITIATOR'
  }

  testCB2 = (hideSalary: boolean): boolean => {
    console.log(hideSalary)
    if (!hideSalary!) return true;
    return this.approverHrRoleCode === 'C&B_GROUP'
      || this.approverHrRoleCode === 'C&B_COMPANY'
      || this.approverHrRoleCode === 'HR_MANAGER'
      || this.approverHrRoleCode === 'PERSONAL_DIRECTOR_GROUP'
      || this.approverHrRoleCode === 'CMB_GROUP'
  }

  getTableDataForExport = () => {
    let data = [];
    let header = {
      employeeNumber: this.props.intl.formatMessage({id: "export.employeeNumber"}),
      organisation: this.props.intl.formatMessage({id: "export.organisation"}),
      person: this.props.intl.formatMessage({id: "export.person"}),
      grade: this.props.intl.formatMessage({id: "export.grade"}),
      hireDate: this.props.intl.formatMessage({id: "export.hireDate"}),
      baseSalaryBegin: this.props.intl.formatMessage({id: "export.baseSalaryBegin"}),
      increasePercentPerYear: this.props.intl.formatMessage({id: "export.increasePercentPerYear"}),
      baseSalaryEnd: this.props.intl.formatMessage({id: "export.baseSalaryEnd"}),
      increasePercent: this.props.intl.formatMessage({id: "export.increasePercent"}),
      increasePercentModerated: this.props.intl.formatMessage({id: "export.increasePercentModerated"}),
      newSalaryRecommended: this.props.intl.formatMessage({id: "export.newSalaryRecommended"}),
      newSalaryModerated: this.props.intl.formatMessage({id: "export.newSalaryModerated"}),
      increasePercentFinal: this.props.intl.formatMessage({id: "export.increasePercentFinal"}),
      comment: this.props.intl.formatMessage({id: "export.comment"})
    }
    data.push(header);
    for (let entry of this.personDataCollection.items.entries()) {
      console.log(entry[1])
      let ddd = {
        employeeNumber: entry[1].employeeNumber,
        organisation: entry[1].organisation ? entry[1].organisation!['_instanceName'] : "",
        person: entry[1].person ? entry[1].person!['_instanceName'] : "",
        grade: entry[1].grade ? entry[1].grade!['_instanceName'] : "",
        hireDate: entry[1].hireDate,
        baseSalaryBegin: this.testCB2(entry[1].hideSalary!) ? entry[1].baseSalaryBegin : 0,
        increasePercentPerYear: entry[1].increasePercentPerYear,
        baseSalaryEnd: this.testCB2(entry[1].hideSalary!) ? entry[1].baseSalaryEnd : 0,
        increasePercent: entry[1].increasePercent,
        // increasePercentModerated: this.testCB1() ? entry[1].increasePercentModerated : 0,
        increasePercentModerated: entry[1].increasePercentModerated,
        newSalaryRecommended: this.testCB2(entry[1].hideSalary!) ? entry[1].newSalaryRecommended : 0,
        newSalaryModerated: entry[1].newSalaryModerated,
        // increasePercentFinal: this.testCB1() ? entry[1].increasePercentFinal : 0,
        increasePercentFinal: entry[1].increasePercentFinal,
        comment: entry[1].comment,
        // position: entry[1].position ? entry[1].position!["_instanceName"] : "",
        // status: entry[1].status ? entry[1].status["_instanceName"] : ""
      }
      data.push(ddd ? ddd : null);
    }
    return data;
  }
   array:any=[];
  export = () => {
    const data = this.getTableDataForExport();

    let headers = {
      skipHeader: true
    }

    const ws = XLSX.utils.json_to_sheet(data, headers);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "persons");
    XLSX.writeFile(wb, "export.xlsx");
  }
  organisationCollection = collection(OrganizationGroup.NAME, {
    view: "_minimal"
  })
  updatePersonDataCollection = (data: any) => {
    console.log(data)
    console.log(data[0]["Табельный номер"])
    for (let i = 0; i < this.personDataCollectionInitVersion.items.length; i++) {
      for (let j = 0; j < data.length; j++) {
        let employeeNumber = data[j][en["export.employeeNumber"]]
        if (employeeNumber == null) employeeNumber = data[j][ru["export.employeeNumber"]]
        if (employeeNumber == null) break;
        if (this.personDataCollectionInitVersion.items[i].employeeNumber == employeeNumber) {
          let increasePercentModerated = data[j][en["export.increasePercentModerated"]]
          if (increasePercentModerated == null) increasePercentModerated = data[j][ru["export.increasePercentModerated"]]
          if (increasePercentModerated != null)
            this.personDataCollectionInitVersion.items[i].increasePercentModerated = increasePercentModerated

          let comment = data[j][en["export.comment"]]
          if (comment == null) comment = data[j][ru["export.comment"]]
          if (comment != null)
            this.personDataCollectionInitVersion.items[i].comment = comment
          this.calculate(this.personDataCollectionInitVersion.items[i], this.personDataCollectionInitVersion.items[i].increasePercentModerated)
          break
        }
      }
    }
    this.syncPersonDataCollection()
    this.initPersonMembers()
  };

  import = (info: UploadChangeParam) => {
    const reader = new FileReader();
    let f = info.file
    reader.readAsBinaryString(f!.originFileObj!);
    reader.onload = (evt) => {
      const buffer = reader.result;
      const wb = XLSX.read(buffer, {type: 'binary'});
      const sheetName = wb.SheetNames[0];
      const sheet = wb.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);
      this.updatePersonDataCollection(data);
    };
  };

  calculate = (record: AnnualSalaryRevisionPerson, value: number) => {
    record.newSalaryModerated = (record.baseSalaryEnd + (record.baseSalaryEnd * value / 100));
    if (Number.isFinite(record.baseSalaryBegin) && record.baseSalaryBegin != 0)
      record.increasePercentFinal = (record.newSalaryModerated / record.baseSalaryBegin - 1) * 100
    else record.increasePercentFinal = 0;
    if (record.increasePercentFinal % 1 != 0) {
      record.increasePercentFinal = (record.increasePercentFinal).toFixed(2)
    }
    this.setPersonInitVersion(record.id, value!, record.newSalaryModerated, record.increasePercentFinal, record.comment!)
  }

  isPercentModerateDisable = (record: AnnualSalaryRevisionPerson): boolean => {
    if (this.approverHrRoleCode === 'C&B_GROUP' || this.approverHrRoleCode === 'C&B_COMPANY') {
      return false;
    }
    if (record.disableSalary!) {
      return true
    }
    if (this.isUserInitiator && !this.isStartForm) return true;
    let date = this.dataInstance.item ?
      this.dataInstance.item.annualSalaryRevision ?
        this.dataInstance.item.annualSalaryRevision.dateForZeroIncrease
        : ""
      : "";
    if (date != "") {
      return record.hireDate > date
    }
    return false;
  }
  positionCollection = collection(PositionGroup.NAME, {
    view: "_minimal"
  })
  isSalaryVisible = (record: AnnualSalaryRevisionPerson): boolean => {
    if (record.hideSalary == null) return true
    if (record.hideSalary) {
      return this.approverHrRoleCode === 'C&B_GROUP'
        || this.approverHrRoleCode === 'C&B_COMPANY'
        || this.approverHrRoleCode === 'HR_MANAGER'
        || this.approverHrRoleCode === 'PERSONAL_DIRECTOR_GROUP'
        || this.approverHrRoleCode === 'CMB_GROUP'
    } else
      return true;
  }

  checkCommentRequired = (record: AnnualSalaryRevisionPerson): boolean => {
    return (record.increasePercentModerated != record.increasePercent
      && (!record.comment || record.comment == ""))
  }
  requiredFields: string[] = [];
  isCommentDisable = (): boolean => {
    return !(this.approverHrRoleCode === 'C&B_GROUP'
      || this.approverHrRoleCode === 'C&B_COMPANY'
      || this.approverHrRoleCode === 'INITIATOR')
  }

  commentRequiredStyle = (record: AnnualSalaryRevisionPerson): CSSProperties => {
    return this.checkCommentRequired(record) ? {borderColor: "red"} : {}
  }

  saveRequest = (e: React.MouseEvent) => {
    e.preventDefault();

    this.props.form.validateFields(this.array, {force: true}, (err, values) => {
      if (err) {
        Notification.error({
          message:
            this.props.intl.formatMessage({
              id: "management.editor.validationError"
            })
        });
        return;
      }
      for (let i = 0; i < this.personDataCollection.items.length; i++) {
        if (this.checkCommentRequired(this.personDataCollection.items[i])) {
          Notification.error({
            message:
              this.props.intl.formatMessage({
                id: "asr.commentRequired"
              })
          });
          return;
        }
      }
      if (this.approverHrRoleCode === 'C&B_GROUP' || this.approverHrRoleCode === 'C&B_COMPANY') {
        for (let entry of this.personDataCollection.items.entries()) {
          if (entry[1].increasePercentModerated != entry[1].increasePercent) {
            entry[1].changedByCab = true
            getCubaREST()!.commitEntity<AnnualSalaryRevisionPerson>(AnnualSalaryRevisionPerson.NAME, {
              id: entry[1].id,
              changedByCab: true
            }).then((value) => {
              console.log(value)
            })
          }
        }
      }
      this.updateAndCommit().then(value => this.props.history.push(this.path + "/" + this.dataInstance.item!.id));
    });
  }
  collection = collection(Grade.NAME, {
    view: "_minimal"
  })
  initPersonMembers = () => {
    this.personDataCollection.clear();
    // @ts-ignore
    this.personDataCollection.items = Array.from(this.dataInstance.item.persons);
    this.personDataCollectionInitVersion.clear();
    // @ts-ignore
    this.personDataCollectionInitVersion.items = Array.from(this.dataInstance.item.persons);
  }


  componentDidMount() {
    super.componentDidMount()
    if (this.personDataCollection.status === "DONE") this.setInitialValues();
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      (item) => {
        this.initPersonMembers();
      }
    );
  }
  validate = (): Promise<boolean> => {
    console.log(this.array)
    let isValidatedSuccess = true;
    this.props.form.validateFields(this.array, {force: true}, (err, values) => {
      isValidatedSuccess = !err;
      if (err) {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.validationError"})
        });
      }
    });
    return new Promise(resolve => resolve(isValidatedSuccess));
  };
  fields2:any = [];
  @action setInitialValues = () => {
    console.log(this.personDataCollection.items)
    for (let i = 0; i < this.personDataCollection.items.length; i++) {
      if(!this.personDataCollection.items[i]){
        break;
      }
      this.obj[this.personDataCollection.items[i].id] = [this.personDataCollection.items[i].increasePercentModerated, 0]
      this.fields2.push("tableComment"+this.personDataCollection.items[i].id)
    }
    this.array =[...new Set([ ...this.fields, ...this.fields2])]


  }
  @observable obj = {}

  render() {

    if (this.personDataCollection.items) {
      this.setInitialValues()
    }
    let filteredData = this.filteredData || {}
    const messages = this.mainStore.messages!;
    const isNull = Object.values(filteredData).every(value => {
      if (value === null || !value[0]) {
        return true;
      }
      return false;
    })



    return (
      <Page pageName={this.props.intl.formatMessage({id: "menu.annual-salary-revision"})}>
        <Form layout="vertical">

          <Card className="narrow-layout card-actions-container"
                actions={this.actions()}
                bordered={false}>

            <Section size="large" sectionName={this.getRevisionName()}>
              <ReadonlyField
                entityName={AnnualSalaryRevisionRequest.NAME}
                propertyName="requestNumber"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                disabled
              />

              <ReadonlyField
                entityName={AnnualSalaryRevisionRequest.NAME}
                propertyName="requestDate"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                disabled
              />

              <ReadonlyField
                entityName={AnnualSalaryRevisionRequest.NAME}
                propertyName="status"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                optionsContainer={this.statusesDc}
                disabled
                getFieldDecoratorOpts={{
                  rules: [{required: true}]
                }}
              />


              <div style={{margin: "4px 0"}}>
                <div>
                  <strong>{this.props.intl.formatMessage({id: "averageIncreasePercent"})}:</strong> {this.getAvgIncreasePercent()}%
                </div>
                <div>
                  <strong>{this.props.intl.formatMessage({id: "totalFOTbeforeIncrease"})}:</strong> {this.generalFOTBeforeIncrease ? this.generalFOTBeforeIncrease : this.getInitBeforeFotTotal()}
                </div>
                <div>
                  <strong>{this.props.intl.formatMessage({id: "totalFOTafterIncrease"})}:</strong> {this.generalFOTAfterIncrease ? this.generalFOTAfterIncrease : this.getInitAfterFotTotal()}
                </div>
                <Radio.Group onChange={this.onChangeFilter} style={{margin: "20px 0"}} defaultValue={"all"}>
                  <div>
                    <Radio value={"all"}> {this.props.intl.formatMessage({id: "showAll"})} </Radio>
                  </div>
                  <div>
                    <Radio value={"less"}> {this.props.intl.formatMessage({id: "showBelowBasePercent"})}</Radio>
                  </div>
                  <Radio value={"greater"}> {this.props.intl.formatMessage({id: "showAboveBasePercent"})}</Radio>
                </Radio.Group>
              </div>

              <Button
                htmlType="submit"
                style={{margin: "0 2px 2px 0"}}
                onClick={() => this.export()}
                type="primary">
                <FormattedMessage id="asr.export"/>
              </Button>

              <Upload
                onChange={(e) => this.import(e)}
                showUploadList={false}
                openFileDialogOnClick={true}>
                <Button
                  htmlType="submit"
                  style={{margin: "0 2px 2px 0"}}
                  type="primary">
                  <FormattedMessage id="asr.import"/>
                </Button>
              </Upload>
              <div style={{display: "flex", flexDirection: "column"}}>
                {!isNull ? <div style={{display: "flex", justifyContent: "flex-end"}}>
                  <AntdButton type={'link'} onClick={() => this.emptyFilters()}
                              icon={"delete"}>{this.props.intl.formatMessage({id: "cubaReact.dataTable.clearAllFilters"})}</AntdButton>
                </div> : null}
                <Table onChange={this.handleChange} scroll={{x: true}}
                       dataSource={this.personDataCollection.items}
                       rowClassName={(record: any) => {
                         if (record.changedByCab) return "ant-table-row-selected"; else return ""
                       }} bordered={true}>
                  <Table.Column filteredValue={filteredData.employeeNumber || null}
                                onFilter={(value, record) => {
                                  return value === record.employeeNumber
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"has"}
                                          text={"employeeNumber"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>
                  } sorter={(a: any, b: any) => {
                    return a.employeeNumber - b.employeeNumber
                  }} title={this.props.intl.formatMessage({id: "employeeNumber"})}
                                dataIndex={"employeeNumber"}/>
                  <Table.Column
                    filteredValue={this.filteredData && this.filteredData.organisation && this.filteredData.organisation._instanceName}
                    onFilter={(value, record) => {
                      return value === record.organisation.id
                    }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters}
                                          selectItems={this.organisationCollection.items} date={false} type={"equals"}
                                          text={"export.organisation"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>
                  } sorter={(a: any, b: any) => {
                    return a.organisation._instanceName.localeCompare(b.organisation._instanceName)
                  }} dataIndex={"organisation._instanceName"}
                    title={this.props.intl.formatMessage({id: "export.organisation"})}/>
                  <Table.Column
                    filteredValue={this.filteredData && this.filteredData.position && this.filteredData.position._instanceName}
                    onFilter={(value, record) => {
                      return value === record.position.id
                    }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} selectItems={this.positionCollection.items}
                                          date={false} type={"equals"}
                                          text={"postionGroup"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.position._instanceName.localeCompare(b.position._instanceName)
                  }} dataIndex={"position._instanceName"}
                    title={this.props.intl.formatMessage({id: "postionGroup"})}/>
                  <Table.Column
                    filteredValue={this.filteredData && this.filteredData.person && this.filteredData.person._instanceName}
                    onFilter={(value, record) => {
                      return record.person._instanceName.toLowerCase().includes(value.toLowerCase())
                    }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"has"}
                                          text={"export.person"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.person._instanceName.localeCompare(b.person._instanceName)
                  }} dataIndex={"person._instanceName"}
                    title={this.props.intl.formatMessage({id: "export.person"})}/>
                  <Table.Column
                    filteredValue={this.filteredData && this.filteredData.grade && this.filteredData.grade._instanceName}
                    onFilter={(value, record) => {
                      return value === record.grade.id
                    }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} selectItems={this.collection.items}
                                          date={false} type={"equals"}
                                          text={"export.grade"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.grade._instanceName - b.grade._instanceName
                  }} dataIndex={"grade._instanceName"}
                    title={this.props.intl.formatMessage({id: "export.grade"})}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.hireDate}
                                onFilter={(value, record) => {
                                  return value === record.hireDate
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={true} type={"equals"}
                                          text={"employee.hireDate"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return moment(a.hireDate).valueOf() - moment(b.hireDate).valueOf()
                  }} dataIndex={"hireDate"}
                                title={this.props.intl.formatMessage({id: "employee.hireDate"})}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.baseSalaryBegin}
                                onFilter={(value, record) => {
                                  return parseInt(value) === record.baseSalaryBegin
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"baseSalaryBegin"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.baseSalaryBegin - b.baseSalaryBegin
                  }} dataIndex={"baseSalaryBegin"}
                                title={this.props.intl.formatMessage({id: "export.baseSalaryBegin"})}
                                render={(text, record) => {
                                  if (this.isSalaryVisible(record)) {
                                    return (Number.isFinite(text) && (text % 1 != 0)) ? text.toFixed(2) : text
                                  } else
                                    return "-"
                                }}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.increasePercentPerYear}
                                onFilter={(value, record) => {
                                  return parseInt(value) === record.increasePercentPerYear
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"export.increasePercentPerYear"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.increasePercentPerYear - b.increasePercentPerYear
                  }} dataIndex={"increasePercentPerYear"}
                                title={this.props.intl.formatMessage({id: "export.increasePercentPerYear"})}
                                render={(text) => {
                                  return (Number.isFinite(text) && (text % 1 != 0)) ? text.toFixed(2) : text
                                }}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.baseSalaryEnd}
                                onFilter={(value, record) => {
                                  return parseInt(value) === record.baseSalaryEnd
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"export.baseSalaryEnd"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.baseSalaryEnd - b.baseSalaryEnd
                  }} dataIndex={"baseSalaryEnd"}
                                title={this.props.intl.formatMessage({id: "export.baseSalaryEnd"})}
                                render={(text, record) => {
                                  if (this.isSalaryVisible(record)) {
                                    return (Number.isFinite(text) && (text % 1 != 0)) ? text.toFixed(2) : text
                                  } else
                                    return "-"
                                }}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.increasePercent}
                                onFilter={(value, record) => {
                                  return record.increasePercent === parseInt(value)
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"export.increasePercent"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.increasePercent - b.increasePercent
                  }} dataIndex={"increasePercent"}
                                title={this.props.intl.formatMessage({id: "export.increasePercent"})}
                                render={(text) => {
                                  return (Number.isFinite(text) && (text % 1 != 0)) ? text.toFixed(2) : text
                                }}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.increasePercentModerated}
                                onFilter={(value, record) => {
                                  return record.increasePercentModerated === parseInt(value)
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"export.increasePercentModerated"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.increasePercentModerated - b.increasePercentModerated
                  }} dataIndex={"increasePercentModerated"}
                                title={this.props.intl.formatMessage({id: "export.increasePercentModerated"})}
                                render={(text, record: any) => {
                                  return <div className={"asr-table-field"}>
                                    <InputNumber name={"increasePercentModerated-" + record.id}
                                                 disabled={this.isPercentModerateDisable(record)}
                                                 value={record.increasePercentModerated}
                                                 onChange={value => {
                                                   this.calculate(record, value!);
                                                 }}/><span>%</span></div>
                                }}/>

                  <Table.Column filteredValue={this.filteredData && this.filteredData.newSalaryRecommended}
                                onFilter={(value, record) => {
                                  return record.newSalaryRecommended === parseInt(value)
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"export.newSalaryRecommended"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.newSalaryRecommended - b.newSalaryRecommended
                  }} dataIndex={"newSalaryRecommended"}
                                title={this.props.intl.formatMessage({id: "export.newSalaryRecommended"})}
                                render={(text, record) => {
                                  if (this.isSalaryVisible(record)) {
                                    return (Number.isFinite(text) && (text % 1 != 0)) ? text.toFixed(2) : text
                                  } else
                                    return "-"
                                }}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.newSalaryModerated}
                                onFilter={(value, record) => {
                                  return parseInt(value) == record.newSalaryModerated
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"export.newSalaryModerated"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.newSalaryModerated - b.newSalaryModerated
                  }} dataIndex={"newSalaryModerated"}
                                title={this.props.intl.formatMessage({id: "export.newSalaryModerated"})}
                                render={(text, record) => {
                                  if (this.isSalaryVisible(record)) {
                                    return (Number.isFinite(text) && (text % 1 != 0)) ? text.toFixed(2) : text
                                  } else
                                    return "-"
                                }}/>
                  <Table.Column filteredValue={this.filteredData && this.filteredData.increasePercentFinal}
                                onFilter={(value, record) => {
                                  return parseInt(value) === record.increasePercentFinal
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                          text={"export.increasePercentFinal"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.increasePercentFinal - b.increasePercentFinal
                  }} dataIndex={"increasePercentFinal"}
                                title={this.props.intl.formatMessage({id: "export.increasePercentFinal"})}
                                render={(text, record) => {
                                  return (Number.isFinite(text) && (text % 1 != 0)) ? text.toFixed(2) : text
                                }}/>
                  <Table.Column key={"commentTable"} filteredValue={this.filteredData && this.filteredData.comment}
                                onFilter={(value, record) => {
                                  return record.comment && record.comment.toLowerCase().includes(value.toLowerCase())
                                }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"has"}
                                          text={"export.comment"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.comment.localeCompare(b.comment)
                  }} dataIndex={"comment"}
                                title={this.props.intl.formatMessage({id: "export.comment"})}
                                render={(text, record: any) =>
                                  <div className={"asr-table-field"}>
                                    <Form.Item style={{margin:0}}
                                      label={createElement(Msg, {
                                        entityName: AnnualSalaryRevisionPerson.NAME,
                                        propertyName: "tableComment"+record.id})}>
                                      {this.props.form.getFieldDecorator("tableComment" + record.id, {
                                        initialValue: text ? text : undefined,
                                        rules: [
                                          {required: this.checkCommentRequired(record),
                                          message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[AnnualSalaryRevisionPerson.NAME+ '.comment']})
                                        }],
                                        getValueFromEvent: args => {
                                          const value = args.currentTarget.value;
                                          record.comment = value;
                                          this.setPersonInitVersion(record.id, record.increasePercentModerated, record.newSalaryModerated, record.increasePercentFinal, args.target.value)
                                          return value;
                                        }
                                      })(
                                        <Input
                                               style={this.commentRequiredStyle(record)}
                                          // disabled={this.isCommentDisable()}
                                               disabled={this.isPercentModerateDisable(record)}
                                        />)
                                      }</Form.Item>
                                  </div>}/>
                  <Table.Column
                    filteredValue={this.filteredData && this.filteredData.status && this.filteredData.status._instanceName}
                    onFilter={(value, record) => {
                      return value === record.status.id
                    }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
                    <SearchFilterDropdown emptyFilters={this.emptyFilters} selectItems={this.statusesDc.items}
                                          date={false} type={"equals"}
                                          text={"status"} clearFilters={clearFilters}
                                          setSelectedKeys={setSelectedKeys} confirm={confirm}
                                          selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
                    return a.status._instanceName.localeCompare(b.status._instanceName)
                  }} title={this.props.intl.formatMessage({id: "status"})}
                    dataIndex={"status._instanceName"}/>


                </Table>
              </div>

            </Section>

            <Section size="large"
                     sectionName={this.props.intl.formatMessage({id: "asr.Justification"})}>
              <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="comment"
                disabled={this.isCommentDisable()}
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.comment']}),
                  }]
                }}
              />

              <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="file"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}/>

            </Section>

            {
              this.globalErrors.length > 0 && (
                <Alert
                  message={<MultilineText lines={toJS(this.globalErrors)}/>}
                  type="error"
                  style={{marginBottom: "24px"}}
                />
              )
            }

            {
              this.takCard()
            }
          </Card>
        </Form>

      </Page>
    )
      ;

  }

  afterSendOnApprove = () => {
    if (this.isNotDraft()) this.props.history!.goBack();
    else this.props.history!.push(AnnualSalaryRevisionManagement.PATH);
  };
}

const component = injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(AnnualSalaryRevisionRequestComponent))
);
export default component;
