import { StandardEntity } from "./sys$StandardEntity";
import { EmployeeQuestionnaireAnswers } from "./tsadv_EmployeeQuestionnaireAnswers";
import { EmployeeQuestionnaire } from "./tsadv_EmployeeQuestionnaire";
export class EmployeeQuestionnaireQuestions extends StandardEntity {
  static NAME = "tsadv_EmployeeQuestionnaireQuestions";
  questionTextLang1?: string | null;
  questionTextLang2?: string | null;
  questionTextLang3?: string | null;
  questionType?: any | null;
  questionnaireAnswers?: EmployeeQuestionnaireAnswers[] | null;
  employeeQuestionnaire?: EmployeeQuestionnaire | null;
  questionText?: string | null;
}
export type EmployeeQuestionnaireQuestionsViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "employeeQuestionnaireQuestions-view_browse"
  | "employeeQuestionnaireQuestions-view_edit";
export type EmployeeQuestionnaireQuestionsView<
  V extends EmployeeQuestionnaireQuestionsViewName
> = V extends "_base"
  ? Pick<
      EmployeeQuestionnaireQuestions,
      | "id"
      | "questionText"
      | "questionTextLang1"
      | "questionTextLang2"
      | "questionTextLang3"
      | "questionType"
    >
  : V extends "_local"
  ? Pick<
      EmployeeQuestionnaireQuestions,
      | "id"
      | "questionTextLang1"
      | "questionTextLang2"
      | "questionTextLang3"
      | "questionType"
    >
  : V extends "_minimal"
  ? Pick<EmployeeQuestionnaireQuestions, "id" | "questionText">
  : V extends "employeeQuestionnaireQuestions-view_browse"
  ? Pick<
      EmployeeQuestionnaireQuestions,
      | "id"
      | "questionTextLang1"
      | "questionTextLang2"
      | "questionTextLang3"
      | "questionType"
      | "questionnaireAnswers"
      | "employeeQuestionnaire"
    >
  : V extends "employeeQuestionnaireQuestions-view_edit"
  ? Pick<
      EmployeeQuestionnaireQuestions,
      | "id"
      | "questionText"
      | "questionType"
      | "questionnaireAnswers"
      | "questionTextLang1"
      | "questionTextLang2"
      | "questionTextLang3"
      | "employeeQuestionnaire"
    >
  : never;
