import React from "react";
import {Tabs} from "antd";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {RootStoreProp} from "../../../store";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {inject, observer} from "mobx-react";
import Badges from "./Tabs/Badges";
import Nominations from "./Tabs/Nominations";
import {observable} from "mobx";
import {PersonMedal} from "../../../../cuba/entities/base/tsadv$PersonMedal";
import {restServices} from "../../../../cuba/services";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router";
import {PersonMedalRequest} from "../../../../cuba/entities/base/tsadv_PersonMedalRequest";

type Props = {
  personGroupId: string

} & MainStoreInjected & RootStoreProp & WrappedComponentProps & RouteComponentProps


@injectMainStore
@inject("rootStore")
@observer
class MedalTab extends React.Component<Props> {

  public static BADGES_TAB_KEY = 'badges'
  public static NOMINATIONS_TAB_KEY = 'nominations'
  public static REVIEWS_TAB_KEY = 'reviews'

  @observable badgesData: PersonMedal[]
  @observable nominationsData: PersonMedalRequest[]



  render() {
    const messages = this.props.intl.messages
    const activeMedalTab = this.props.rootStore!.recognitionStore.selectedMedalTab ? this.props.rootStore!.recognitionStore.selectedMedalTab : "badges"
    return (
      <Tabs defaultActiveKey={activeMedalTab} onChange={key => {this.props.rootStore!.recognitionStore.setMedalTab(key)}}>
        <Tabs.TabPane key={MedalTab.BADGES_TAB_KEY} tab={messages['medalTab.tabs.badges']}>
          <Badges badges={this.badgesData} personGroupId={this.props.personGroupId}/>
        </Tabs.TabPane>
        <Tabs.TabPane key={MedalTab.NOMINATIONS_TAB_KEY} tab={messages['medalTab.tabs.nominations']}>
          <Nominations data={this.nominationsData} personGroupId={this.props.personGroupId}/>
        </Tabs.TabPane>
      </Tabs>
    )
  }
}

export default withRouter(injectIntl(MedalTab))