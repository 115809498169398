import { Assignment } from "./base$Assignment";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicAssignmentStatus } from "./tsadv$DicAssignmentStatus";
import { DicAssignmentCategory } from "./tsadv$DicAssignmentCategory";
import { DicCostCenter } from "./tsadv$DicCostCenter";
import { JobGroup } from "./tsadv$JobGroup";
import { GradeGroup } from "./tsadv$GradeGroup";
import { OrganizationGroupExt } from "./base$OrganizationGroupExt";
import { AssignmentGroupExt } from "./base$AssignmentGroupExt";
import { PositionGroupExt } from "./base$PositionGroupExt";
export class AssignmentExt extends Assignment {
  static NAME = "base$AssignmentExt";
  personGroup?: PersonGroupExt | null;
  substituteEmployee?: PersonGroupExt | null;
  orderNumber?: string | null;
  orderDate?: any | null;
  durationProbationPeriod?: number | null;
  unit?: any | null;
  probationEndDate?: any | null;
  assignmentStatus?: DicAssignmentStatus | null;
  assignmentCategory?: DicAssignmentCategory | null;
  costCenter?: DicCostCenter | null;
  jobGroup?: JobGroup | null;
  gradeGroup?: GradeGroup | null;
  organizationGroup?: OrganizationGroupExt | null;
  primaryFlag?: boolean | null;
  fte?: any | null;
  group?: AssignmentGroupExt | null;
  positionGroup?: PositionGroupExt | null;
  children?: AssignmentExt | null;
  temporaryEndDate?: any | null;
}
export type AssignmentExtViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "assignedPerformancePlan-assignment"
  | "assignmanetExt.view.for.positionEdit"
  | "assignment-for-talents"
  | "assignment-portal-kpi"
  | "assignment-viewREQS"
  | "assignment-with-groups"
  | "assignment-with-org-calendar"
  | "assignment.card"
  | "assignment.edit"
  | "assignment.full"
  | "assignment.historyEdit"
  | "assignment.isManager"
  | "assignment.match"
  | "assignment.myteam.browse"
  | "assignment.person"
  | "assignment.rcg.org-pos"
  | "assignment.rcg.profile"
  | "assignment.schedule"
  | "assignment.view"
  | "assignmentBrowse.view"
  | "assignmentExt-view"
  | "assignmentExt-with-type"
  | "assignmentExt.bpm.view"
  | "assignmentExtBrowse.view"
  | "assignmentForManger.browse"
  | "assignmentRequisitionEdit"
  | "portal-assignment-group"
  | "portal-assignment-group";
export type AssignmentExtView<
  V extends AssignmentExtViewName
> = V extends "_base"
  ? Pick<
      AssignmentExt,
      | "id"
      | "organizationGroup"
      | "jobGroup"
      | "group"
      | "endDate"
      | "startDate"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "writeHistory"
      | "assignDate"
    >
  : V extends "_local"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
    >
  : V extends "_minimal"
  ? Pick<
      AssignmentExt,
      | "id"
      | "organizationGroup"
      | "jobGroup"
      | "group"
      | "endDate"
      | "startDate"
    >
  : V extends "assignedPerformancePlan-assignment"
  ? Pick<
      AssignmentExt,
      | "id"
      | "organizationGroup"
      | "jobGroup"
      | "group"
      | "endDate"
      | "startDate"
      | "personGroup"
      | "jobGroup"
      | "organizationGroup"
      | "positionGroup"
    >
  : V extends "assignmanetExt.view.for.positionEdit"
  ? Pick<
      AssignmentExt,
      | "id"
      | "organizationGroup"
      | "jobGroup"
      | "group"
      | "endDate"
      | "startDate"
      | "group"
      | "personGroup"
      | "startDate"
      | "endDate"
      | "legacyId"
    >
  : V extends "assignment-for-talents"
  ? Pick<
      AssignmentExt,
      | "id"
      | "organizationGroup"
      | "jobGroup"
      | "group"
      | "endDate"
      | "startDate"
      | "gradeGroup"
      | "positionGroup"
    >
  : V extends "assignment-portal-kpi"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "jobGroup"
      | "organizationGroup"
    >
  : V extends "assignment-viewREQS"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "location"
      | "group"
      | "jobGroup"
      | "gradeGroup"
      | "positionGroup"
      | "organizationGroup"
    >
  : V extends "assignment-with-groups"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "group"
      | "personGroup"
    >
  : V extends "assignment-with-org-calendar"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "group"
    >
  : V extends "assignment.card"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "location"
      | "group"
      | "jobGroup"
      | "gradeGroup"
      | "positionGroup"
      | "organizationGroup"
      | "assignmentStatus"
      | "substituteEmployee"
      | "updatedBy"
    >
  : V extends "assignment.edit"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "group"
      | "location"
      | "positionGroup"
    >
  : V extends "assignment.full"
  ? Pick<
      AssignmentExt,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "jobGroup"
      | "gradeGroup"
      | "organizationGroup"
      | "group"
      | "location"
      | "positionGroup"
      | "assignmentStatus"
    >
  : V extends "assignment.historyEdit"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "assignmentStatus"
      | "updatedBy"
      | "personGroup"
      | "jobGroup"
      | "gradeGroup"
      | "organizationGroup"
      | "group"
      | "location"
      | "positionGroup"
      | "substituteEmployee"
      | "costCenter"
      | "assignmentCategory"
    >
  : V extends "assignment.isManager"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "positionGroup"
    >
  : V extends "assignment.match"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "location"
      | "group"
      | "jobGroup"
      | "gradeGroup"
      | "positionGroup"
      | "organizationGroup"
      | "assignmentStatus"
      | "substituteEmployee"
      | "updatedBy"
      | "personGroup"
      | "jobGroup"
      | "organizationGroup"
      | "positionGroup"
      | "updatedBy"
      | "createTs"
      | "createdBy"
      | "updateTs"
    >
  : V extends "assignment.myteam.browse"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "positionGroup"
      | "gradeGroup"
      | "location"
      | "organizationGroup"
    >
  : V extends "assignment.person"
  ? Pick<AssignmentExt, "id" | "personGroup">
  : V extends "assignment.rcg.org-pos"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "organizationGroup"
      | "positionGroup"
    >
  : V extends "assignment.rcg.profile"
  ? Pick<
      AssignmentExt,
      "id" | "organizationGroup" | "personGroup" | "positionGroup"
    >
  : V extends "assignment.schedule"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "group"
      | "personGroup"
    >
  : V extends "assignment.view"
  ? Pick<
      AssignmentExt,
      | "id"
      | "personGroup"
      | "gradeGroup"
      | "positionGroup"
      | "group"
      | "organizationGroup"
      | "jobGroup"
      | "assignmentStatus"
      | "location"
      | "fte"
      | "substituteEmployee"
      | "primaryFlag"
      | "startDate"
      | "endDate"
      | "updatedBy"
      | "costCenter"
    >
  : V extends "assignmentBrowse.view"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "positionGroup"
      | "group"
      | "organizationGroup"
    >
  : V extends "assignmentExt-view"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "jobGroup"
      | "gradeGroup"
      | "organizationGroup"
      | "group"
      | "positionGroup"
    >
  : V extends "assignmentExt-with-type"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "assignmentStatus"
    >
  : V extends "assignmentExt.bpm.view"
  ? Pick<
      AssignmentExt,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
      | "assignmentStatus"
      | "jobGroup"
      | "gradeGroup"
      | "organizationGroup"
      | "positionGroup"
      | "group"
      | "costCenter"
      | "substituteEmployee"
      | "children"
      | "location"
    >
  : V extends "assignmentExtBrowse.view"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "group"
      | "assignmentCategory"
      | "personGroup"
      | "organizationGroup"
      | "positionGroup"
      | "jobGroup"
      | "gradeGroup"
      | "location"
      | "updatedBy"
      | "costCenter"
      | "assignmentStatus"
    >
  : V extends "assignmentForManger.browse"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
    >
  : V extends "assignmentRequisitionEdit"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "personGroup"
    >
  : V extends "portal-assignment-group"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "group"
      | "personGroup"
    >
  : V extends "portal-assignment-group"
  ? Pick<
      AssignmentExt,
      | "id"
      | "orderNumber"
      | "orderDate"
      | "durationProbationPeriod"
      | "unit"
      | "probationEndDate"
      | "primaryFlag"
      | "fte"
      | "temporaryEndDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "startDate"
      | "endDate"
      | "writeHistory"
      | "assignDate"
      | "group"
      | "personGroup"
    >
  : never;
