import React, { Component } from 'react'
import { injectMainStore, MainStoreInjected } from '@cuba-platform/react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../store';
import { Table } from 'antd';
import { SerializedEntity } from '@cuba-platform/rest';
import { PersonGroupExt } from '../../../cuba/entities/base/base$PersonGroupExt';

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp & {
  data: null | SerializedEntity<PersonGroupExt>[];
  title: string;
};

@injectMainStore
@inject("rootStore")
@observer
class MyAdaptationTeamTableComponent extends Component<Props> {
  render() {
    if(!this.props.data) return null;
    return (
      <div>
        <Table
          dataSource={this.props.data}
          pagination={{
            total: this.props.data.length,
            pageSize: 2,
          }}
          columns={[{
            title: this.props.title,
            key: 'fullName',
            dataIndex: 'fullName',
            render: (text, record)=>{
              return <div>
                <b>{text}</b>
                {record.currentAssignment && record.currentAssignment.positionGroup && record.currentAssignment.positionGroup.fullName && 
                  <div>{record.currentAssignment.positionGroup.fullName}</div>
                }
                {record.currentAssignment && record.currentAssignment.organizationGroup && record.currentAssignment.organizationGroup.organizationName && 
                  <div>{record.currentAssignment.organizationGroup.organizationName}</div>
                }
              </div>
            }
          }]}
        />
      </div>
    )
  }
}

const MyAdaptationTeamTable = injectIntl(MyAdaptationTeamTableComponent)

export default MyAdaptationTeamTable
