import * as React from "react";
import {createElement, ReactNode} from "react";
import {Card, Col, Form, Icon, Input, InputNumber, Modal, Row, Spin, Table, Tooltip} from "antd";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

import {collection, DataCollectionStore, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";

import "../../App.css";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import LoadingPage from "../LoadingPage";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {withRouter} from "react-router";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {goBackOrHomePage} from "../../util/util";
import {LearningRequest} from "../../../cuba/entities/base/tsadv_LearningRequest";
import {ReadonlyField} from "../../components/ReadonlyField";
import {observable} from "mobx";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {SerializedEntity} from "@cuba-platform/rest";
import {getPersonByPersonGroup} from "../../util/personUtils";
import moment, {Moment} from "moment";
import {restServices} from "../../../cuba/services";
import {PersonProfile} from "../MyTeam/MyTeamCard";
import {AssignmentExt} from "../../../cuba/entities/base/base$AssignmentExt";
import {Competence} from "../../../cuba/entities/base/tsadv$Competence";
import TextArea from "antd/es/input/TextArea";
import {DEFAULT_DATE_PATTERN, formatDate} from "../../util/Date/Date";
import {DicTransferKnowledge} from "../../../cuba/entities/base/tsadv_DicTransferKnowledge";
import {DicTypeOfTraining} from "../../../cuba/entities/base/tsadv_DicTypeOfTraining";
import {Successor} from "../../../cuba/entities/base/tsadv$Successor";
import {TsadvUser} from "../../../cuba/entities/base/tsadv$UserExt";
import Column from "antd/es/table/Column";
import ApproveTdWindow from "./ApproveTdModal";
import {DicCategory} from "../../../cuba/entities/base/tsadv$DicCategory";
import Notification from "../../util/Notification/Notification";

type EditorProps = {
  entityId: string;
}

@inject("rootStore")
@injectMainStore
@observer
class LearningRequestEditComponent extends AbstractBprocEdit<LearningRequest, EditorProps> {

  processDefinitionKey = "learningRequest";

  dataInstance = instance<LearningRequest>(LearningRequest.NAME, {
    view: "portal.learningRequest-edit",
    loadImmediately: false
  });

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_local"
  });

  transferKnowledgeDc = collection<DicTransferKnowledge>(DicTransferKnowledge.NAME, {
    view: "_local"
  });

  learningTypeDc  = collection<DicTypeOfTraining>(DicTypeOfTraining.NAME, {
    view: "_local"
  });

  typeOfTrainingDc = collection<DicCategory>("tsadv$DicCategory",{
    view: "_local"
  });

  @observable
  successorDc: DataCollectionStore<Successor>;

  @observable
  competencesDc: DataCollectionStore<Competence>;

  fields = [
    "requestNumber",
    "requestDate",
    "status",
    "comment",
    "competence",
    "transferKnowledge",
    "typeOfTraining",
    "learningType",
    "courseName",
    "numberOfDays",
    "numberOfHours",
    "dateFrom",
    "dateTo",
    "courseDescription",
    "instruction",
    "costOfEducation",
    "livingCosts",
    "dailyLivingCosts",
    "travelCosts",
    "otherCosts",
    "totalCosts",
  ];

  @observable
  person: SerializedEntity<PersonExt>;

  @observable
  personProfile: PersonProfile;

  @observable
  openedApproveWindowTd: boolean = false;
  @observable
  isApproverTd: boolean = false;
  @observable
  isFirstTd: boolean = false;

  defaultFieldProps = {
    entityName: this.dataInstance.entityName,
    form: this.props.form,
    formItemOpts: {style: {margin: "12px"}},
  }

  getUpdateEntityData(): any {
    return {
      personGroup: {
        id: this.personProfile.groupId
      },
      ...this.props.form.getFieldsValue(this.fields)
    }
  };

  validate = (): Promise<boolean> => {
    const {dateTo,dateFrom} = this.props.form.getFieldsValue(['dateTo','dateFrom'])
    if(dateTo&&dateFrom&&(dateTo as Moment).diff(dateFrom,'days')<=0){
    Notification.error({message:this.props.intl.formatMessage({id:"validation.dateFromAndDateTo"})});
    return new Promise(resolve => false )
    }
    let isValidatedSuccess = true;
    this.props.form.validateFields(this.fields, {force: true}, (err, values) => {
      isValidatedSuccess = !err;
      if (err) {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.validationError"})
        });
      }
    });
    return new Promise(resolve => isValidatedSuccess );
  };

  render() {
    if (!this.dataInstance
      || !this.mainStore.messages) {
      return <LoadingPage/>
    }

    return (
      <Page pageName={this.props.intl.formatMessage({id: "page.learningRequest"})}>
        <Card className="narrow-layout card-actions-container"
              actions={[
                <Button buttonType={ButtonType.FOLLOW} htmlType="button"
                        onClick={() => goBackOrHomePage(this.props.history!)}>
                  <FormattedMessage id="close"/>
                </Button>,
                this.getOutcomeBtns()
              ]}
              bordered={false}>
          {[
            this.renderEmployeeInfoSection(),
            this.renderLearningSection(),
            this.renderAdditionalInfoSection(),
            this.renderJustificationSection(),
            this.takCard()
          ]}

          {this.openedApproveWindowTd
            ? <ApproveTdWindow isFirstTd={this.isFirstTd}
                               request={this.dataInstance.item!}
                               task={this.activeUserTask!}
                               afterSendOnApprove={this.afterSendOnApprove}
                               changeVisible={visible => this.openedApproveWindowTd = visible}
                               visible={this.openedApproveWindowTd}/>
            : null}
        </Card>
      </Page>
    );
  }

  showReservistTable = (reservist: TsadvUser[]) => {
    Modal.info({
      width: 700,
      title: this.props.intl.formatMessage({id: "reservist"}),
      content: (
        <Table dataSource={reservist} pagination={false}>
          <Column key={'succession'} dataIndex={'succession.positionGroup'}
                  title={this.props.intl.formatMessage({id: 'key.position'})}
                  render={(text, record: Successor) => record.succession!.positionGroup!["_instanceName"]}/>
          <Column key={'readinessLevel'} dataIndex={'readinessLevel'}
                  title={this.props.mainStore!.messages![`${Successor.NAME}.readinessLevel`]}
                  render={(text, record: Successor) => record.readinessLevel && record.readinessLevel!["_instanceName"]}/>
          <Column key={'startDate'} dataIndex={'startDate'}
                  title={this.props.mainStore!.messages![`${Successor.NAME}.startDate`]}
                  render={(text, record: Successor) => formatDate(record.startDate)}/>
        </Table>
      )
    });
  };

  renderEmployeeInfoSection = () => {
    return <Section size="large"
                    sectionName={this.props.intl.formatMessage({id: "learningRequest.section.employee.info"})}>
      <Form>
        {[
          this.renderRow(this.renderReadonlyField({propertyName: "requestNumber", disabled: true}),
            this.renderReadonlyField({propertyName: "requestDate", disabled: true})),

          this.renderRow(<Form.Item
              label={<Msg entityName={PersonExt.NAME} propertyName={'employeeNumber'}/>}>
              <Input value={this.person && this.person.employeeNumber || ""} disabled/>
            </Form.Item>,
            this.renderReadonlyField({propertyName: "status", optionsContainer: this.statusesDc, disabled: true})),

          this.renderRow(<Form.Item
              label={<Msg entityName={this.dataInstance.entityName} propertyName={'personGroup'}/>}>
              <Input value={this.person && this.person._instanceName} disabled/>
            </Form.Item>,
            //reservist
            <Form.Item
              label={this.props.intl.formatMessage({id: 'reservist'})}>
              {
                !this.successorDc || this.successorDc.status === 'LOADING'
                  ? <Spin/>
                  : <div>
                    {
                      this.successorDc.items.length > 0
                        ? <a onClickCapture={() => this.showReservistTable(this.successorDc.items.slice())} key="modal">
                          {this.props.intl.formatMessage({id: 'cubaReact.dataTable.yes'})}
                        </a>
                        : this.props.intl.formatMessage({id: 'cubaReact.dataTable.no'})
                    }
                  </div>
              }
            </Form.Item>
          ),

          this.renderRow(<Form.Item
              label={<Msg entityName={AssignmentExt.NAME} propertyName={'positionGroup'}/>}>
              <Input value={this.personProfile && this.personProfile.positionName} disabled/>
            </Form.Item>,
            <Form.Item
              label={<Msg entityName={AssignmentExt.NAME} propertyName={'costCenter'}/>}>
              <Input value={this.personProfile && this.personProfile.costCenter} disabled/>
            </Form.Item>),

          this.renderRow(<Form.Item
            label={<Msg entityName={AssignmentExt.NAME} propertyName={'organizationGroup'}/>}>
            <Input value={this.personProfile && this.personProfile.organizationName} disabled/>
          </Form.Item>),
        ]}
      </Form>
    </Section>
  }

  renderLearningSection = () => {
    const isNotDraft = this.isNotDraft();
    return <Section size="large"
                    sectionName={this.props.intl.formatMessage({id: "learningRequest.section.learning"})}>
      <Form>
        {[
          this.renderRow(
            [
              this.renderReadonlyField(
                {
                  propertyName: "competence",
                  optionsContainer: this.competencesDc,
                  disabled: isNotDraft,
                  getFieldDecoratorOpts: {
                    rules: [{
                      required: true,
                      message: this.getRequiredMessage("competence")
                    }]
                  }
                }),
              React.createElement("div", {},
                this.renderRow(
                  <Form.Item
                    label={createElement(Msg, {
                      entityName: this.dataInstance.entityName,
                      propertyName: "numberOfDays"
                    })}>
                    {this.props.form.getFieldDecorator("numberOfDays", {
                      rules: [{
                        required: true,
                        message: this.getRequiredMessage("numberOfDays")
                      }]
                    }
                    )(
                      <InputNumber
                        disabled={isNotDraft} min={0}/>
                    )}
                  </Form.Item>,
                  <Form.Item
                    label={createElement(Msg, {
                      entityName: this.dataInstance.entityName,
                      propertyName: "numberOfHours"
                    })}>
                    {this.props.form.getFieldDecorator("numberOfHours", {
                      rules: [{
                        required: true,
                        message: this.getRequiredMessage("numberOfHours")
                      }]
                    })(
                      <InputNumber
                        disabled={isNotDraft} min={0}/>
                    )}
                  </Form.Item>)),
              React.createElement("div", {},
                this.renderRowWithCheckBox(
                  this.renderReadonlyField({
                    propertyName: "dateFrom",
                    disabled: isNotDraft,
                    format: DEFAULT_DATE_PATTERN,
                    getFieldDecoratorOpts: {
                      rules: [{
                        required: true,
                        message: this.getRequiredMessage("dateFrom"),
                      }]
                    }
                  }),
                  this.renderReadonlyField({
                    propertyName: "dateTo",
                    disabled: isNotDraft,
                    format: DEFAULT_DATE_PATTERN,
                    getFieldDecoratorOpts: {
                      rules: [{
                        required: true,
                        message: this.getRequiredMessage("dateTo")
                      }]
                    }
                  }),
                  this.renderReadonlyField({
                    propertyName: "inTheCountry",
                    disabled: isNotDraft,
                    format: DEFAULT_DATE_PATTERN,
                    getFieldDecoratorOpts: {
                      rules: [{
                        required: true,
                        message: this.getRequiredMessage("inTheCountry")
                      }]
                    }
                  }),
                  this.renderReadonlyField({
                    propertyName: "abroad",
                    disabled: isNotDraft,
                    format: DEFAULT_DATE_PATTERN,
                    getFieldDecoratorOpts: {
                      rules: [{
                        required: true,
                        message: this.getRequiredMessage("abroad")
                      }]
                    }
                  }),
                )),
              this.renderReadonlyField(
                {
                  propertyName: "typeOfTraining",
                  optionsContainer: this.typeOfTrainingDc,
                  disabled: isNotDraft,
                  getFieldDecoratorOpts: {
                    rules: [{
                      required: true,
                      message: this.getRequiredMessage("typeOfTraining")
                    }]
                  }
                }),
              this.renderReadonlyField(
                {
                  propertyName: "learningType",
                  optionsContainer: this.learningTypeDc,
                  disabled: isNotDraft,
                  getFieldDecoratorOpts: {
                    rules: [{
                      required: true,
                      message: this.getRequiredMessage("learningType")
                    }]
                  }
                }),
            ], [
              this.renderReadonlyField({
                propertyName: "courseName",
                disabled: isNotDraft,
                getFieldDecoratorOpts: {
                  rules: [{
                    required: true,
                    message: this.getRequiredMessage("courseName")
                  }]
                }
              }),
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "courseDescription"
                })}>
                {this.props.form.getFieldDecorator("courseDescription", {
                  rules: [{
                    required: true,
                    message: this.getRequiredMessage("courseDescription")
                  }]
                })(
                  <TextArea
                    disabled={isNotDraft}
                    style={{height: 288}}/>
                )}
              </Form.Item>]
          ),
        ]}
      </Form>
    </Section>
  }

  renderAdditionalInfoSection = () => {
    const isNotDraft = this.isNotDraft();
    const instructionCaption = this.props.intl.formatMessage({id: "learningRequest.provider.preference"});
    return <Section size="large"
                    sectionName={this.props.intl.formatMessage({id: "learningRequest.section.additional.info"})}>
      <Form>
        {[
          this.renderRow(
            [
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "costOfEducation"
                })}>
                {this.props.form.getFieldDecorator("costOfEducation", {
                  rules: [{
                    required: true,
                    message: this.getRequiredMessage("costOfEducation")
                  }],
                  getValueFromEvent: args => {
                    this.calcTotalCost({property: "costOfEducation", value: parseInt(args)});
                    return args;
                  }
                })(
                  <InputNumber
                    min={0}
                    disabled={isNotDraft}/>
                )}
              </Form.Item>,
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "livingCosts"
                })}>
                {this.props.form.getFieldDecorator("livingCosts", {
                  rules: [{
                    required: true,
                    message: this.getRequiredMessage("livingCosts")
                  }],
                  getValueFromEvent: args => {
                    this.calcTotalCost({property: "livingCosts", value: parseInt(args)});
                    return args;
                  }
                })(
                  <InputNumber
                    min={0}
                    disabled={isNotDraft}/>
                )}
              </Form.Item>,
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "dailyLivingCosts"
                })}>
                {this.props.form.getFieldDecorator("dailyLivingCosts", {
                  rules: [{
                    required: true,
                    message: this.getRequiredMessage("dailyLivingCosts")
                  }],
                  getValueFromEvent: args => {
                    this.calcTotalCost({property: "dailyLivingCosts", value: parseInt(args)});
                    return args;
                  }
                })(
                  <InputNumber
                    min={0}
                    disabled={isNotDraft}/>
                )}
              </Form.Item>,
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "travelCosts"
                })}>
                {this.props.form.getFieldDecorator("travelCosts", {
                  rules: [{
                    required: true,
                    message: this.getRequiredMessage("travelCosts")
                  }],
                  getValueFromEvent: args => {
                    this.calcTotalCost({property: "travelCosts", value: parseInt(args)});
                    return args;
                  }
                })(
                  <InputNumber
                    min={0}
                    disabled={isNotDraft}/>
                )}
              </Form.Item>,
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "otherCosts"
                })}>
                {this.props.form.getFieldDecorator("otherCosts", {
                  getValueFromEvent: args => {
                    this.calcTotalCost({property: "otherCosts", value: parseInt(args)});
                    return args;
                  }
                })(
                  <InputNumber
                    min={0}
                    disabled={isNotDraft}/>
                )}
              </Form.Item>,
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "totalCosts",
                })}>
                {this.props.form.getFieldDecorator("totalCosts")(<InputNumber disabled={true}/>)}
              </Form.Item>
            ],
            <Form.Item
              label={
                <span>
              {instructionCaption}&nbsp;
                  <Tooltip title={this.props.intl.formatMessage({id: "learningRequest.instruction.filling.field"})}>
                <Icon type="question-circle-o"/>
              </Tooltip>
            </span>}>
              {this.props.form.getFieldDecorator("instruction", {
                rules: [{
                  required: true,
                  message: this.getRequiredMessage("instruction", instructionCaption)
                }]
              })(
                <TextArea
                  disabled={isNotDraft}
                  style={{height: 396}}/>
              )}
            </Form.Item>
          ),
        ]}
      </Form>
    </Section>
  }

  renderJustificationSection = () => {
    const isNotDraft = this.isNotDraft();
    const rationaleLearningOutcomes = this.props.intl.formatMessage({id: "learningRequest.rationale.learning.outcomes"});
    const transferKnowledge = this.props.intl.formatMessage({id: "learningRequest.transferKnowledge"});
    return <Section size="large"
                    sectionName={this.props.intl.formatMessage({id: "learningRequest.section.justification"})}>
      <Form>
        <ReadonlyField
          entityName={this.dataInstance.entityName}
          propertyName="transferKnowledge"
          form={this.props.form}
          disabled={isNotDraft}
          optionsContainer={this.transferKnowledgeDc}
          formItemOpts={{style: {marginBottom: "12px"}, label: <span>{transferKnowledge}</span>}}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.getRequiredMessage(transferKnowledge)
            }]
          }
          }
        />
        <Form.Item
          label={rationaleLearningOutcomes}>
          {this.props.form.getFieldDecorator("comment", {
            rules: [{
              required: true,
              message: this.getRequiredMessage(rationaleLearningOutcomes)
            }]
          })(
            <TextArea
              disabled={isNotDraft}
              rows={10}/>
          )}
        </Form.Item>
      </Form>
    </Section>
  }

  calcTotalCost = (value: { property: string, value: number }) => {
    const costProperties = ["costOfEducation", "livingCosts", "dailyLivingCosts", "travelCosts", "otherCosts"];
    const totalCost = costProperties.map(property => {
      if (value.property === property) return value.value;
      return this.props.form.getFieldValue(property);
    }).map(cost => cost || 0)
      .reduce((a, b) => a + b, 0);
    this.props.form.setFieldsValue({totalCosts: totalCost});
  }

  getRequiredMessage = (property: string, caption?: string) => {
    return this.props.intl.formatMessage({id: "form.validation.required"},
      {fieldName: caption || this.props.mainStore!.messages![`${this.dataInstance.entityName}.${property}`]})
  }

  renderReadonlyField = (props: any) => {
    return React.createElement(ReadonlyField, Object.assign({}, this.defaultFieldProps, props));
  }

  renderRow = (left?: ReactNode | ReactNode[], right?: ReactNode | ReactNode[]) => {
    return React.createElement(Row, {type: "flex", className: "data-form"},
      [
        React.createElement(Col, {md: 24, sm: 24, lg: 12}, left),
        React.createElement(Col, {md: 24, sm: 24, lg: 12}, right),
      ]
    )
  }
  renderRowWithCheckBox = (left?: ReactNode | ReactNode[], right?: ReactNode | ReactNode[], checkbox1?:ReactNode | ReactNode[],checkbox2?:ReactNode|ReactNode[]) => {
    return React.createElement(Row, {type: "flex", className: "data-form"},
      [
        React.createElement(Col, {md: 12, sm: 12, lg: 6}, left),
        React.createElement(Col, {md: 12, sm: 12, lg: 6}, right),
        React.createElement(Col, {md: 12, sm: 12, lg: 6}, checkbox1),
        React.createElement(Col, {md: 12, sm: 12, lg: 6}, checkbox2),
      ]
    )
  }

  onReactionDisposerEffect = (item: LearningRequest | undefined) => {
    const personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo.personGroupId!;
    const date = moment(item && item.requestDate).format("YYYY-MM-DD");

    getPersonByPersonGroup(personGroupId, date, "_base").then(value => this.person = value);
    restServices.employeeService.personProfile(personGroupId).then(value => this.personProfile = value);
    this.initCompetencesDc(item, date);
    this.initSuccessionDc(item, date);
  }

  initSuccessionDc = (item: LearningRequest | undefined, date: string) => {
    this.successorDc = collection<Successor>(Successor.NAME, {
      view: "reservist",
      filter: {
        conditions: [{
          property: "personGroup.id",
          operator: "=",
          value: item && item.personGroup && item.personGroup.id || this.props.rootStore!.userInfo.personGroupId!
        }, {
          property: "endDate",
          operator: ">=",
          value: date
        }, /*{
          property: "startDate",
          operator: "<=",
          value: date
        }*/]
      }
    })
  }

  initCompetencesDc = (item: LearningRequest | undefined, date: string) => {
    const competenceId = item && item.competence && item.competence.id;
    this.competencesDc = collection<Competence>(Competence.NAME, {
      view: "_local",
      filter: {
        conditions: [{
          group: 'OR',
          conditions: [{
            property: "startDate",
            operator: "<=",
            value: date
          }, {
            property: "id",
            operator: competenceId ? "=" : "notEmpty",
            value: competenceId
          }]
        }, {
          group: 'OR',
          conditions: [{
            property: "id",
            operator: competenceId ? "=" : "notEmpty",
            value: competenceId
          }, {
            property: "endDate",
            operator: ">=",
            value: date
          }]
        }]
      }
    })
  }

  initVariablesByBproc = () => {
    this.isApproverTd = !!(this.activeUserTask && ["T&D", "T&D_GROUP"].find(value => value === this.activeUserTask!.hrRole!.code));
    this.isFirstTd = !!(this.tasks && this.activeUserTask
      && (this.tasks.length < 2 || this.tasks[this.tasks.length - 2].taskDefinitionKey !== this.activeUserTask.taskDefinitionKey));
  }

  beforeCompletePredicate = (outcome: string): Promise<boolean> => {
    if (this.isApproverTd && outcome === 'APPROVE') {
      this.openedApproveWindowTd = true;
      return new Promise(resolve => resolve(false));
    }
    return new Promise(resolve => resolve(true));
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any, allValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(LearningRequestEditComponent))
);
