import * as React from "react";
import {observer} from "mobx-react";
import DocumentSignList from "./DocumentSignList";
import {RouteComponentProps} from "react-router";
type Props = RouteComponentProps<{ entityId: string }>;
@observer
export class DocumentSignManagement extends React.Component<Props> {
  static PATH = "/documentSignList";
  static PATH_WITH_PARAMS = "/documentSignList/:entityId?";

  render() {
    const {entityId} = this.props.match.params;
    return (
      <>
        <DocumentSignList entityId={entityId}/>
      </>
    );
  }
}
