import { AbstractDictionary } from "./AbstractDictionary";
export class DicFormStudy extends AbstractDictionary {
  static NAME = "tsadv_DicFormStudy";
}
export type DicFormStudyViewName = "_base" | "_local" | "_minimal";
export type DicFormStudyView<V extends DicFormStudyViewName> = V extends "_base"
  ? Pick<
      DicFormStudy,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "description1"
      | "description2"
      | "description3"
      | "description4"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_local"
  ? Pick<
      DicFormStudy,
      | "id"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_minimal"
  ? Pick<
      DicFormStudy,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
    >
      : V extends "dicFormStudy-view"
        ? Pick<
          DicFormStudy,
          | "id"
          | "legacyId"
          | "organizationBin"
          | "integrationUserLogin"
          | "langValue1"
          | "description1"
          | "langValue2"
          | "description2"
          | "langValue3"
          | "description3"
          | "langValue4"
          | "description4"
          | "langValue5"
          | "description5"
          | "startDate"
          | "endDate"
          | "code"
          | "isSystemRecord"
          | "active"
          | "isDefault"
          | "order"
          | "company"
          >
  : never;
