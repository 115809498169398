import * as React from "react";
import { FormEvent } from "react";
import { Alert, Button, Card, Form, message, Spin, Tabs } from "antd";
import { observer } from "mobx-react";
import { AdaptationTaskManagement } from "./AdaptationTaskManagement";
import { FormComponentProps } from "antd/lib/form";
import { Link, Redirect } from "react-router-dom";
import { IReactionDisposer, observable, reaction, toJS } from "mobx";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  Field,
  instance,
  withLocalizedForm,
  extractServerValidationErrors,
  constructFieldsWithErrors,
  clearFieldErrors,
  MultilineText
} from "@cuba-platform/react";

import "../../../../app/App.css";

import { AdaptationTask } from "../../../../cuba/entities/base/tsadv_AdaptationTask";

type Props = FormComponentProps & EditorProps & WrappedComponentProps;

type EditorProps = {
  entityId: string;
  adaptationPlanId: string;
  onSave?: (task: AdaptationTask) => void;
  onCancel?: () => void;
};

@observer
class AdaptationTaskEditComponent extends React.Component<Props> {
  dataInstance = instance<AdaptationTask>(AdaptationTask.NAME, {
    view: "adaptationTask",
    loadImmediately: false
  });

  @observable
  updated = false;
  reactionDisposer: IReactionDisposer;

  fields = [
    "assignmentLang1",

    "assignmentLang2",

    "assignmentLang3",

    "achievedResultsLang1",

    "achievedResultsLang2",

    "achievedResultsLang3",

    "expectedResultsLang1",

    "expectedResultsLang2",

    "expectedResultsLang3"
  ];

  @observable
  globalErrors: string[] = [];
  @observable
  loaded = false;

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        return;
      }
      const newData = this.props.form.getFieldsValue(this.fields);
      newData.adaptationPlan = this.props.adaptationPlanId
      this.dataInstance
        .update(newData)
        .then(() => {
          message.success(
            this.props.intl.formatMessage({ id: "management.editor.success" })
          );
          this.updated = true;
          if (this.props.onSave) {
            this.props.onSave(this.dataInstance.item!)
          }
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.error"
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({ id: "management.editor.error" })
            );
          }
        });
    });
  };

  renderHiddenFields = () => {
    return (
      <div style={{display: 'none'}}>
        <Field
          entityName={AdaptationTask.NAME}
          propertyName="assignmentLang1"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />

        <Field
          entityName={AdaptationTask.NAME}
          propertyName="expectedResultsLang1"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />

        <Field
          entityName={AdaptationTask.NAME}
          propertyName="achievedResultsLang1"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />

        <Field
          entityName={AdaptationTask.NAME}
          propertyName="assignmentLang2"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />

        <Field
          entityName={AdaptationTask.NAME}
          propertyName="expectedResultsLang2"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />

        <Field
          entityName={AdaptationTask.NAME}
          propertyName="achievedResultsLang2"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />
        <Field
          entityName={AdaptationTask.NAME}
          propertyName="assignmentLang3"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />

        <Field
          entityName={AdaptationTask.NAME}
          propertyName="expectedResultsLang3"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />

        <Field
          entityName={AdaptationTask.NAME}
          propertyName="achievedResultsLang3"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />
      </div>
    )
  }

  render() {
    const { status } = this.dataInstance;

    return (
      <Spin spinning={!this.loaded}>
        <Card onSubmit={e => e.stopPropagation()} className="narrow-layout" bordered={false}>
          <Form onSubmit={this.handleSubmit} layout="vertical">

                <Field
                  entityName={AdaptationTask.NAME}
                  propertyName="assignmentLang1"
                  form={this.props.form}
                  formItemOpts={{ style: { marginBottom: "12px" },
                    label:this.props.intl.formatMessage({id:"assignmentLang1"}) }}
                  getFieldDecoratorOpts={{
                    rules: [{ required: true }]
                  }}
                />

                <Field
                  entityName={AdaptationTask.NAME}
                  propertyName="expectedResultsLang1"
                  form={this.props.form}
                  formItemOpts={{ style: { marginBottom: "12px" } ,
                    label:this.props.intl.formatMessage({id:"expectedResultsLang1"})}}
                  getFieldDecoratorOpts={{
                    rules: [{ required: true }]
                  }}
                />

                <Field
                  entityName={AdaptationTask.NAME}
                  propertyName="achievedResultsLang1"
                  form={this.props.form}
                  formItemOpts={{ style: { marginBottom: "12px" } ,
                    label:this.props.intl.formatMessage({id:"achievedResultsLang1"})}}
                  getFieldDecoratorOpts={{}}
                />



            {this.renderHiddenFields()}

            {this.globalErrors.length > 0 && (
              <Alert
                message={<MultilineText lines={toJS(this.globalErrors)} />}
                type="error"
                style={{ marginBottom: "24px" }}
              />
            )}

            <Form.Item style={{ textAlign: "center" }}>
              <Button htmlType="button" onClick={this.props.onCancel}>
                <FormattedMessage id="management.editor.cancel" />
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={status !== "DONE" && status !== "ERROR"}
                loading={status === "LOADING"}
                style={{ marginLeft: "8px" }}
              >
                <FormattedMessage id="management.editor.submit" />
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Spin>
    );
  }

  componentDidMount() {
    if (this.props.entityId !== AdaptationTaskManagement.NEW_SUBPATH) {
      this.dataInstance.load(this.props.entityId);
    } else {
      this.dataInstance.setItem(new AdaptationTask());
      this.loaded = true;
    }
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      () => {
        this.props.form.setFieldsValue(
          this.dataInstance.getFieldValues(this.fields)
        );
        this.loaded = true;
      }
    );
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(AdaptationTaskEditComponent)
);
