import * as React from "react";
import {
  collection,
  getCubaREST,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";
import {
  Table,
  Breadcrumb,
  Modal,
  Form,
  Button,
  Row,
  Col,
  Input,
  Icon,
  Popover,
  Select,
  Spin
} from "antd";
import {
  WrappedComponentProps,
  injectIntl,
  FormattedMessage
} from "react-intl";
import { Link } from "react-router-dom";
import { Successor } from "../../../cuba/entities/base/tsadv$Successor";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import "./style.css";
import Column from "antd/es/table/Column";
import TalentPoolProgramSuccessorModal from "./Modals/TalentPoolProgramSuccessorModal";
import PopoverS from "../../components/Popover";
import { downloadFile } from "../../util/util";
import { FileDescriptor } from "../../../cuba/entities/base/sys$FileDescriptor";
import { SerializedEntity } from "@cuba-platform/rest";
import { restServices } from "../../../cuba/services";
import { FC } from "react";
import { TsadvUser } from "../../../cuba/entities/base/tsadv$UserExt";
import LoadingPage from "../LoadingPage";
import { SuccessionPlanning } from "../../../cuba/entities/base/tsadv$SuccessionPlanning";
import { SuccessorRequestManagement } from "../SuccessorRequest/SuccessorRequestManagement";
import { RouteComponentProps, withRouter } from "react-router";
import { RootStoreProp } from "../../store";
import { string } from "@amcharts/amcharts4/core";
import { Company } from "../../../cuba/entities/base/base$Company";
import { DicCompany } from "../../../cuba/entities/base/base_DicCompany";

type Employee = {
  person_group_id: string;
  full_name: string;
};

type File = {
  file_id: string;
  file_name: string;
};

type Detail = {
  current_employee: Employee[];
  job_description: File[];
  manager: Employee[];
  organization_name: string;
  position_group_id: string;
  position_name: string;
};

type KeyPositionList = {
  company_code: string;
  company_id: string;
  organization_name: string;
  position_group_id: string;
  position_name: string;
  reservisty: Employee[];
  holdersPOPOVER: string;
  successorsPOPOVER: string;
  managersPOPOVER: string;
};
type Competence = {
  competence_name: string;
  competence_type: string;
  required_level: string;
  scale_name: string;
};
interface RootObject {
  competence_name: string;
  competence_type: string;
  required_level: string;
  scale_name: string;
}
interface RootObjectWithKey extends RootObject {
  key: string;
}
type CompetenceTableRow = {
  key: string;
  competence_type: string;
  competence_type_id: string;
  children: (RootObjectWithKey & { key: string })[];
};
@injectMainStore
@inject("rootStore")
@observer
class TalentPoolProgramList extends React.Component<
  RouteComponentProps &
    MainStoreInjected &
    RootStoreProp &
    WrappedComponentProps
> {
  companyDC = collection<DicCompany>(DicCompany.NAME, {
    filter: {
      conditions: [
        {
          property: "code",
          operator: "<>",
          value: "empty"
        }
      ]
    },
    view: "_minimal"
  });

  @observable successorDataCollection = collection<Successor>(Successor.NAME, {
    view: "reservistUpdated"
  });

  @observable isSuccessorModalVisible = false;
  @observable isPositionModalVisible = false;
  @observable id: string = "";
  @observable
  data2: any;

  @observable
  keyPositionData: any;

  @observable functionalManager: any;

  @observable countOfFunctionalManager: any;

  @observable selectedCompany: string = this.props.rootStore!.userInfo
    .companyCode!;
  @observable pageSized: number = 1;

  @observable visible: boolean = false;

  @observable visiblePopup: boolean = false;

  @observable loading: boolean = false;
  @observable test: any;
  @observable selectedRowKeys: any;

  @observable selectedRowKey: any;

  @observable fileDesc: FileDescriptor | undefined | null;

  @observable fileName: string | null | undefined;

  @observable indexRowKey: any;
  @observable managerDataList: any = [];
  @observable dataList: Array<SerializedEntity<any>> = [];

  RESERVIST: string = "RESERVIST";

  MANAGER: string = "MANAGER";
  HOLDER: string = "HOLDER";
  @observable holderDataList2: any;
  @observable functionalManagers: any;
  @observable loadingModalData: boolean = true;
  @action
  showModal = (positionGroupId: any) => {
    this.visible = true;
    restServices.learningService
      .getSuccessionKeyPositionInfo({
        lang: this.props.rootStore!.userInfo!.locale!,
        positionGroupId: positionGroupId
      })
      .then(r => JSON.parse(r[0] && r[0]!.value))
      .then(detail => {
        this.loadingModalData = false;
        this.modalData = detail[0];
        if (
          detail[0] &&
          detail[0]!.competencies! &&
          detail[0]!.competencies!.length > 0
        ) {
          this.modalDataTable = this.groupDataByCompetenceType(
            detail[0] && detail[0]!.competencies!
          );
        }
      });
  };

  groupDataByCompetenceType = (arr: RootObject[]): CompetenceTableRow[] => {
    const map = arr.reduce(
      (acc, cur, index) => {
        if (cur.competence_type && cur.competence_type) {
          if (!acc.has(cur.competence_type)) {
            acc.set(cur.competence_type, {
              competence_type_id: cur.competence_type,
              competence_type: cur.competence_type,
              key: this.uuid(),
              children: []
            });
          }
          acc.get(cur.competence_type)!.children.push({
            ...cur,
            competence_type: "",
            key: `${this.uuid() || index}-${this.uuid() || index}`
          });
        }
        return acc;
      },
      new Map() as Map<string, CompetenceTableRow>
    );

    return Array.from(map.values());
  };
  uuid = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  content: FC<Array<string>> = (props, value: string) => {
    return (
      <div style={{ padding: "14px" }}>
        <div
          className={"hover-list"}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Icon type="user" />
          <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
            {" "}
            {value === this.RESERVIST ? (
              <FormattedMessage id={"keyPosition.table.popover.reservists"} />
            ) : value === this.MANAGER ? (
              <FormattedMessage id={"keyPosition.table.popover.director"} />
            ) : value === this.HOLDER ? (
              <FormattedMessage id={"keyPosition.table.popover.holder"} />
            ) : (
              ""
            )}{" "}
          </div>
        </div>
        {props
          ? props.map((item, index) => (
              <div
                style={{
                  padding: "12px 5px",
                  background: "#F3F3F3",
                  borderRadius: "3px",
                  margin: "8px 0"
                }}
              >
                {index + 1}) {item}
              </div>
            ))
          : ""}
      </div>
    );
  };

  @observable modalDataTable: CompetenceTableRow[] = [];
  @observable modalData: Detail;
  @observable index = 0;
  @observable keyPositionDataList: any;
  @observable companiesDc = collection(DicCompany.NAME, {
    view: "_minimal"
  });

  @action.bound
  togglePositionModalVisibility() {
    this.isPositionModalVisible = !this.isPositionModalVisible;
  }

  @observable PAGEITEMSCOUNT = 30;

  @action.bound
  toggleProfileModalVisibility() {
    this.isSuccessorModalVisible = !this.isSuccessorModalVisible;
  }
  findManager = (Persons: Employee[]): string => {
    let personName: string = "";
    if (Persons) {
      personName =
        Persons.length === 1
          ? Persons[0]!.full_name!
          : Persons[0]!
          ? `${Persons[0]!.full_name!}+${Persons.length - 1}`
          : "";
    }
    return personName;
  };

  @observable personData: Successor | undefined;
  @observable isLoadedSuccessor: boolean = true;
  showSuccessorProfileModal = (id: string) => {
    console.log("enter");
    getCubaREST()!
      .searchEntities<Successor>(
        Successor.NAME,
        {
          conditions: [
            {
              property: "personGroup.id",
              operator: "=",
              value: id
            }
          ]
        },
        {
          view: "successor-talentPool",
          sort: "-updateTs"
        }
      )
      .then(value => {
        console.log(value[0]);
        this.isLoadedSuccessor = false;
        this.personData = value[0];
      });
  };

  renderSuccessorModal = () => {
    return (
      <TalentPoolProgramSuccessorModal
        toggleVisibility={this.toggleProfileModalVisibility}
        personData={this.personData}
        isSuccessorModalVisible={this.isSuccessorModalVisible}
      />
    );
  };

  @action
  handleCancel = () => {
    this.visible = false;
    for (let member in this.modalData) delete this.modalData[member];
    this.loadingModalData = true;
    this.modalDataTable = [];
  };

  @action setSelectedCompany = () => {
    this.dataList = [];
    this.keyPositionData = undefined;
    getCubaREST()!
      .searchEntities<Successor>(
        Successor.NAME,
        {
          conditions: [
            {
              group: "AND",
              conditions: [
                {
                  property: "succession.positionGroup.company.code",
                  operator: "=",
                  value: this.selectedCompany
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "REJECTED"
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "OUT_OF_LDP"
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "TERMINATED"
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "PROMOTED"
                }
              ]
            }
          ]
        },
        {
          view: "successor-talentPool"
        }
      )
      .then(value => {
        const ids = value.map(i => i.succession!.id);
        var set = new Set(ids);
        restServices.successionPlanningService
          .getSuccessionPlannings({
            successionPlanningList: Array.from(set),
            view: "keyPosition-short"
          })
          .then(value => {
            this.keyPositionData = value;
          })
          .finally(() => {
            if (!this.keyPositionData) {
              this.keyPositionData = [];
            }
          });
      });
  };

  @observable keyPositionList: Array<KeyPositionList>;
  @observable loadingData: boolean = true;

  getSuccessionKeyPosition = (
    changedCode: string = "",
    isChange: boolean = false
  ) => {
    const initialCode = this.props.rootStore!.userInfo!.companyCode!;
    if (changedCode === "empty") {
      isChange = false;
    }
    restServices.learningService
      .getSuccessionKeyPosition({
        lang: this.props.rootStore!.userInfo!.locale!,
        company: isChange
          ? changedCode
          : changedCode === "empty"
          ? ""
          : initialCode === "KMM"
          ? ""
          : initialCode,
        position: "",
        organization: "",
        holder: "",
        manager: "",
        reservist: ""
      })
      .then(r => JSON.parse(r[0] && r[0]!.value!))
      .then(list => {
        this.loadingData = false;
        if(list){
          this.keyPositionList = list
            .map((item: KeyPositionList) => ({
              ...item,
              key: this.uuid(),
              successorsPOPOVER:
                item.reservisty !== null
                  ? Object.values(item.reservisty.map(fil => fil!.full_name!))
                  : ""
            }))
            .filter(
              (successor: KeyPositionList) =>
                successor.reservisty && successor.reservisty.length > 0
            );
        }
        else{
          this.keyPositionList = []
        }
      });
  };

  componentDidMount() {
    this.getSuccessionKeyPosition();

    getCubaREST()!
      .searchEntities<Successor>(
        Successor.NAME,
        {
          conditions: [
            {
              group: "AND",
              conditions: [
                {
                  property: "status",
                  operator: "<>",
                  value: ""
                },
                {
                  property: "succession.positionGroup.company.code",
                  operator: "=",
                  value: this.props.rootStore!.userInfo!.companyCode!
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "REJECTED"
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "OUT_OF_LDP"
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "TERMINATED"
                },
                {
                  property: "status.code",
                  operator: "<>",
                  value: "PROMOTED"
                }
              ]
            }
          ]
        },
        {
          view: "successor-talentPool"
        }
      )
      .then(value => {
        const ids = value.map(i => i.succession!.id);
        var set = new Set(ids);
        restServices.successionPlanningService
          .getSuccessionPlannings({
            successionPlanningList: Array.from(set),
            view: "keyPosition-short"
          })
          .then(value => {
            this.keyPositionData = value;
          })
          .finally(() => {
            if (!this.keyPositionData) {
              this.keyPositionData = [];
            }
          });
      });
  }

  render() {
    let file: FileDescriptor | undefined | null;
    file = this.keyPositionData ? this.keyPositionData!.jobDescription! : null;
    let fileName: string | null | undefined;
    fileName = file ? file.name : null;

    if (this.keyPositionData === undefined) {
      return <LoadingPage />;
    }

    const holderDataList: any = [];
    const successorDataList: any = [];
    const managerDataList: any = [];

    this.keyPositionData.forEach((item: any) =>
      holderDataList.push(item.holders ? item.holders : "")
    );
    this.keyPositionData.forEach((item: any) =>
      successorDataList.push(item.successors ? item.successors : "")
    );
    this.keyPositionData.forEach((item: any) =>
      managerDataList.push(item.managers ? item.managers : "")
    );
    const functionalManagers =
      this.functionalManagers && this.functionalManagers.length === 1
        ? this.functionalManagers && this.functionalManagers[0]!.fullName!
        : this.functionalManagers && this.functionalManagers[0]!
        ? this.functionalManagers[0]!.fullName! +
          "+" +
          (this.functionalManagers && this.functionalManagers.length - 1)
        : "";
    const currentHolders =
      this.holderDataList2 && this.holderDataList2.length === 1
        ? this.holderDataList2 && this.holderDataList2[0]!.fullName!
        : this.holderDataList2 && this.holderDataList2[0]!
        ? this.holderDataList2[0]!.fullName! +
          "+" +
          (this.holderDataList2 && this.holderDataList2.length - 1)
        : "";
    const funcValue = this.functionalManager
      ? (this.functionalManager &&
          this.functionalManager[0]!["_instanceName"]!) +
        "+" +
        (this.functionalManager && this.functionalManager.length - 1)
      : "";

    const columns = [
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.table.company.code"
        }),
        dataIndex: "company_code",
        key: "company_code",
        sorter: (a: KeyPositionList, b: KeyPositionList) =>
          a.company_code.localeCompare(b.company_code)
      },
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.table.reservists"
        }),
        key: "reservists",
        dataIndex: "successorsPOPOVER",
        render: (text: string, record: KeyPositionList) => {
          let successorsArr: any;
          successorsArr = text;
          text = text[0];
          const popList = (list: Array<string>, title: string) => {
            return list && list.length > 0 ? (
              list.length === 1 ? (
                <span
                  onClick={() => {
                    this.id =
                      (record as KeyPositionList).reservisty &&
                      (record as KeyPositionList).reservisty![0]! &&
                      (record as KeyPositionList).reservisty![0]!
                        .person_group_id!;
                    this.showSuccessorProfileModal(this.id);
                    this.toggleProfileModalVisibility();
                  }}
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    textDecoration: "underline",
                    color: "#005487",
                    fontSize: "12px",
                    wordBreak: "break-word"
                  }}
                >
                  {text}
                </span>
              ) : (
                <Popover
                  placement={"bottomLeft"}
                  content={this.content(list, title)}
                >
                  <Button
                    onClick={() => {
                      this.id =
                        (record as KeyPositionList).reservisty &&
                        (record as KeyPositionList).reservisty![0]! &&
                        (record as KeyPositionList).reservisty![0]!
                          .person_group_id!;
                      this.showSuccessorProfileModal(this.id);
                      this.toggleProfileModalVisibility();
                    }}
                    style={{
                      background: "transparent",
                      border: "none",
                      width: "100%",
                      padding: "0",
                      textAlign: "start"
                    }}
                  >
                    <span
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        textDecoration: "underline",
                        color: "#005487",
                        fontSize: "12px",
                        wordBreak: "break-word"
                      }}
                    >
                      {text + "+" + (list.length - 1)}
                    </span>
                  </Button>
                </Popover>
              )
            ) : (
              <></>
            );
          };
          return popList(successorsArr, this.RESERVIST);
        }
      },
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.table.keyPosition"
        }),
        dataIndex: "position_name",
        key: "position_name",
        sorter: (a: KeyPositionList, b: KeyPositionList) =>
          a.position_name.localeCompare(b.position_name),
        render: (text: string, record: KeyPositionList) => {
          return (
            <div style={{ width: "300px" }}>
              {
                <Button
                  style={{ background: "transparent", border: "none" }}
                  onClick={() => {
                    this.showModal(record && record.position_group_id);
                  }}
                >
                  <span
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      textDecoration: "underline",
                      color: "#005487"
                    }}
                  >
                    {record.position_name}
                  </span>
                </Button>
              }
            </div>
          );
        }
      },
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.table.organization"
        }),
        dataIndex: "organization_name",
        key: "organization_name",
        sorter: (a: KeyPositionList, b: KeyPositionList) =>
          a.organization_name.localeCompare(b.organization_name)
      }
    ];

    return (
      <>
        <Breadcrumb style={{ margin: 20 }}>
          <Breadcrumb.Item>
            <Link to="/talentPoolProgram">
              {this.props.intl.formatMessage({ id: "talentPoolProgramText" })}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.props.intl.formatMessage({ id: "reservistTabText" })}
          </Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ display: "flex", margin: 20, alignItems: "center" }}>
          <h1 style={{ fontSize: 45, margin: 0 }}>
            {this.props.intl.formatMessage({ id: "talentPoolProgramHeading" })}
          </h1>
          {this.props.rootStore!.userInfo.companyCode! == "KMM" ? (
            <>
              <h2
                style={{
                  fontSize: 30,
                  margin: 0,
                  marginLeft: 80,
                  color: "black"
                }}
              >
                {this.props.intl.formatMessage({ id: "company" })}
              </h2>
              <Select
                  placeholder={this.props.intl.formatMessage({id:"talentPoll.select"})}
                  style={{ width: 250, marginLeft: 20 }}
                  onChange={(value: string) => {
                    this.selectedCompany = value;
                    this.getSuccessionKeyPosition(value, true);
                  }}
                >
                  {this.companiesDc.items.map((item: any) => {
                    return (
                      <Select.Option  value={item.code}>
                        {item._instanceName}
                      </Select.Option>
                    );
                  })}
                </Select>


            </>
          ) : null}
        </div>

        <Table
          dataSource={this.keyPositionList}
          size={"default"}
          bordered={true}
          key={this.indexRowKey}
          rowKey="id"
          loading={this.loadingData}
          className={"keyPosition-table"}
          columns={columns}
          pagination={{
            defaultPageSize: 20
          }}
        />

        {this.isSuccessorModalVisible ? this.renderSuccessorModal() : <></>}
        {this.renderModalPage()}
        {/* <Column
            title={this.props.intl.formatMessage({id: "keyPosition.table.company.code"})}
            dataIndex={"companyCode"}
            key={"companyCode"}
            render={(text, record) => {
              return <div>
                {(record as SuccessionPlanning).positionGroup ? (record as SuccessionPlanning).positionGroup! && (record as SuccessionPlanning).positionGroup!.company! && (record as SuccessionPlanning).positionGroup!.company!.code : " "}
              </div>

            }}
          />
          <Column
            title={this.props.intl.formatMessage({id: "keyPosition.table.reservists"})}
            key={"reservists"}
            render={(text, record, index) => {
              index = (this.pageSized - 1) * 30 + index
              return (successorDataList[index].length > 0
                  ? (((successorDataList[index].length - 1) !== 0)
                    ? <Popover placement={"bottomLeft"}
                               content={this.content((successorDataList.length > 0 && successorDataList[index]), this.RESERVIST)}>
                      <Button onClick={() => {
                        this.id = (record as SuccessionPlanning).successors && (record as SuccessionPlanning).successors![0]! && (record as SuccessionPlanning).successors![0]!.personGroup! && (record as SuccessionPlanning).successors![0]!.id!;
                        this.toggleProfileModalVisibility();
                      }} style={{
                        background: "transparent",
                        textAlign: "left",
                        border: "none",
                        width: "100%",
                        padding: "0"
                      }}>
                            <span
                              style={{
                                whiteSpace: "normal", textAlign: "left",
                                wordWrap: "break-word", textDecoration: "underline", color: "#005487"
                              }}>
                               {(record as SuccessionPlanning).successors ? (record as SuccessionPlanning).successors && (record as SuccessionPlanning).successors![0]! && (record as SuccessionPlanning).successors![0]!.personGroup! && (record as SuccessionPlanning).successors![0]!.personGroup!.fullName! + "+" + (successorDataList[index].length - 1) : ""}
                            </span>
                      </Button>
                    </Popover>
                    : <span onClick={() => {
                      this.id = (record as SuccessionPlanning).successors && (record as SuccessionPlanning).successors![0]! && (record as SuccessionPlanning).successors![0]!.personGroup! && (record as SuccessionPlanning).successors![0]!.id!;
                      this.toggleProfileModalVisibility();
                    }} style={{
                      whiteSpace: "normal", cursor: "pointer", fontSize: "12.5px",
                      wordWrap: "break-word", textDecoration: "underline", color: "#005487"
                    }}>
                           {(record as SuccessionPlanning).successors ? (record as SuccessionPlanning).successors && (record as SuccessionPlanning).successors![0]! && (record as SuccessionPlanning).successors![0]!.personGroup! && (record as SuccessionPlanning).successors![0]!.personGroup!.fullName! : ""}
                           </span>)
                  : <></>
              )
            }}
          />


          <Column
            title={this.props.intl.formatMessage({id: "keyPosition.table.keyPosition"})}
            dataIndex={"positionName"}
            key={"keyPosition"}
            render={(text, record: SuccessionPlanning, index) => {
              index = (this.pageSized - 1) * 30 + index
              return <div style={{width: "300px"}}>
                {!(record as SuccessionPlanning).positionGroup ? ""
                  : <div>
                    <Button style={{background: "transparent", border: "none"}} onClick={() => {
                      this.showModal(record.id)
                    }}>
                          <span style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word", textDecoration: "underline", color: "#005487"
                          }}>
                             {(record as SuccessionPlanning).positionGroup!["_instanceName"]}
                          </span>
                    </Button>
                  </div>
                }
              </div>
            }}
          />
          <Column
            title={this.props.intl.formatMessage({id: "keyPosition.table.organization"})}
            key={"organization"}
            render={(text, record) => {
              return <div>
                {(record as SuccessionPlanning).positionGroup ? (record as SuccessionPlanning).positionGroup! && (record as SuccessionPlanning).positionGroup!.organizationGroup! && (record as SuccessionPlanning).positionGroup!.organizationGroup!.organizationName : "no data"}
              </div>
            }}
          /> */}
        {/* </Table> */}
        {/* {this.id !== "" ? this.showSuccessorProfileModal(this.id) : null} */}

        {/* <Modal
          className={"keyPosition-modal"}
          visible={this.visible}
          title={this.props.intl.formatMessage({
            id: "keyPosition.modalTable.title"
          })}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              <FormattedMessage id={"keyPosition.modalTable.cancel"} />
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                this.props.history.push({
                  pathname:
                    SuccessorRequestManagement.PATH +
                    "/" +
                    SuccessorRequestManagement.NEW_SUBPATH,
                  state: { positionGroupId: this.data2.positionGroup.id }
                });
              }}
              loading={this.loading}
            >
              <FormattedMessage id={"keyPosition.modalTable.submit"} />
            </Button>
          ]}
        >
          <div>
            <Form layout="vertical">
              <Row>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 2.5% 0 0" }}>
                  <Form.Item
                    label={
                      <FormattedMessage id={"keyPosition.table.keyPosition"} />
                    }
                  >
                    <Input
                      value={this.data2 && this.data2!["_instanceName"]}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 0 0 2.5%" }}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id={"keyPosition.modalTable.structure"}
                      />
                    }
                  >
                    <Input
                      value={
                        this.data2
                          ? this.data2.positionGroup!.organizationGroup &&
                            this.data2.positionGroup!.organizationGroup![
                              "_instanceName"
                            ]
                          : ""
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 2.5% 0 0" }}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id={"keyPosition.modalTable.currentWorker"}
                      />
                    }
                  >
                    {this.holderDataList2 && this.holderDataList2.length > 1 ? (
                      <PopoverS
                        popoverTitle={
                          <FormattedMessage
                            id={"keyPosition.modalTable.currentEmployee"}
                          />
                        }
                        popoverData={this.holderDataList2}
                      >
                        <Input
                          className={"individual-input"}
                          value={currentHolders}
                          disabled
                        />
                      </PopoverS>
                    ) : (
                      <Input
                        className={"individual-input"}
                        value={
                          this.holderDataList2 &&
                          this.holderDataList2.length !== 0
                            ? this.holderDataList2[0]!.fullName!
                            : ""
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 0 0 2.5%" }}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id={"keyPosition.modalTable.ModalDirector"}
                      />
                    }
                  >
                    {functionalManagers &&
                    this.functionalManagers.length > 1 ? (
                      <PopoverS
                        popoverTitle={
                          <FormattedMessage
                            id={"keyPosition.modalTable.ModalDirector"}
                          />
                        }
                        popoverData={this.functionalManagers}
                      >
                        <Input
                          className={"individual-input"}
                          value={functionalManagers}
                          disabled
                        />
                      </PopoverS>
                    ) : (
                      <Input
                        className={"individual-input"}
                        value={functionalManagers}
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  marginBottom: "12px",
                  display: "flex",
                  textAlign: "center"
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormattedMessage id={"keyPosition.modalTable.manual"} />
                </div>
                <div style={{ marginLeft: "12px" }}>
                  {this.fileDesc ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ padding: "10px", color: "#005487" }}>
                        {this.fileName}
                      </span>
                      <Button
                        onClick={() =>
                          downloadFile(
                            this.fileDesc ? this.fileDesc.id : null,
                            this.fileDesc
                              ? this.fileDesc!.name!.substring(
                                  0,
                                  this.fileDesc &&
                                    this.fileDesc!.name!.length - 5
                                )
                              : "",
                            this.fileDesc ? this.fileDesc!.extension! : "",
                            "not found"
                          )
                        }
                        type="primary"
                      >
                        <Icon type="download" />
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>

              <div style={{ marginBottom: "12px" }}>
                <FormattedMessage id={"keyPosition.modalTable.tableTitle"} />
              </div>
              <Table
                dataSource={
                  this.data2 &&
                  this.data2.positionGroup &&
                  this.data2.positionGroup.competenceElements
                }
                size="default"
                bordered={true}
                rowKey="id"
                footer={() => ""}
              >
                <Column
                  title={this.props.intl.formatMessage({
                    id: "keyPosition.modalTable.table.type"
                  })}
                  dataIndex={
                    "competenceGroup.competence.competeceType._instanceName"
                  }
                  key={"type"}
                />
                <Column
                  title={this.props.intl.formatMessage({
                    id: "keyPosition.modalTable.table.nameing"
                  })}
                  dataIndex={"competenceGroup._instanceName"}
                  key={"nameing"}
                />
                <Column
                  title={this.props.intl.formatMessage({
                    id: "keyPosition.modalTable.table.scale"
                  })}
                  dataIndex={"competenceGroup.competence.scale._instanceName"}
                  key={"scale"}
                />
                <Column
                  title={this.props.intl.formatMessage({
                    id: "keyPosition.modalTable.table.level"
                  })}
                  dataIndex={"scaleLevel._instanceName"}
                  key={"level"}
                />
              </Table>
            </Form>
          </div>
        </Modal> */}
      </>
    );
  }
  renderModalPage = () => {
    const modalColumns = [
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.modalTable.table.type"
        }),
        dataIndex: "competence_type",
        key: "competence_type",
        render: (text: any, record: any) => {
          return record.hasOwnProperty("competence_type")
            ? record!.competence_type!
            : "";
        }
      },
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.modalTable.table.nameing"
        }),
        dataIndex: "competence_name",
        key: "nameing"
      },
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.modalTable.table.scale"
        }),
        dataIndex: "scale_name",
        key: "scale"
      },
      {
        title: this.props.intl.formatMessage({
          id: "keyPosition.modalTable.table.level"
        }),
        dataIndex: "required_level",
        key: "level"
      }
    ];
    return (
      <Modal
        className={"keyPosition-modal--reservist"}
        visible={this.visible}
        title={this.props.intl.formatMessage({
          id: "keyPosition.modalTable.title"
        })}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            <FormattedMessage id={"keyPosition.modalTable.cancel"} />
          </Button>,

          <Button
            key="submit"
            type="primary"
            loading={this.loading}
            onClick={() => {
              this.props.history.push({
                pathname:
                  SuccessorRequestManagement.PATH +
                  "/" +
                  SuccessorRequestManagement.NEW_SUBPATH,
                state: { positionGroupId: this.modalData.position_group_id }
              });
            }}
          >
            <FormattedMessage id={"keyPosition.modalTable.submit"} />
          </Button>
        ]}
      >
        <Spin spinning={this.loadingModalData}>
          <div>
            <Form layout="vertical">
              <Row>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 2.5% 0 0" }}>
                  <Form.Item
                    label={
                      <FormattedMessage id={"keyPosition.table.keyPosition"} />
                    }
                  >
                    <Input
                      value={this.modalData && this.modalData!.position_name!}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 0 0 2.5%" }}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id={"keyPosition.modalTable.structure"}
                      />
                    }
                  >
                    <Input
                      value={
                        this.modalData && this.modalData!.organization_name!
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 2.5% 0 0" }}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id={"keyPosition.modalTable.currentWorker"}
                      />
                    }
                  >
                    {this.modalData &&
                    this.modalData!.current_employee &&
                    this.modalData!.current_employee!.length > 1 ? (
                      <PopoverS
                        popoverTitle={
                          <FormattedMessage
                            id={"keyPosition.modalTable.currentEmployee"}
                          />
                        }
                        popoverData={
                          this.modalData && this.modalData!.current_employee!
                        }
                        useInKeyPosition={true}
                      >
                        <Input
                          className={"individual-input"}
                          value={this.findManager(
                            this.modalData && this.modalData!.current_employee!
                          )}
                          disabled
                        />
                      </PopoverS>
                    ) : (
                      <Input
                        className={"individual-input"}
                        value={
                          this.modalData &&
                          this.modalData!.current_employee! &&
                          this.modalData!.current_employee![0]!.full_name!.toString()!
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col md={24} sm={24} lg={12} style={{ padding: "0 0 0 2.5%" }}>
                  <Form.Item
                    label={
                      <FormattedMessage
                        id={"keyPosition.modalTable.ModalDirector"}
                      />
                    }
                  >
                    {this.modalData &&
                    this.modalData!.manager &&
                    this.modalData!.manager!.length > 1 ? (
                      <PopoverS
                        popoverTitle={
                          <FormattedMessage
                            id={"keyPosition.modalTable.ModalDirector"}
                          />
                        }
                        popoverData={this.modalData && this.modalData!.manager!}
                        useInKeyPosition={true}
                      >
                        <Input
                          className={"individual-input"}
                          value={this.findManager(
                            this.modalData && this.modalData!.manager!
                          )}
                          disabled
                        />
                      </PopoverS>
                    ) : (
                      <Input
                        className={"individual-input"}
                        value={
                          this.modalData &&
                          this.modalData!.manager! &&
                          this.modalData!.manager![0]!.full_name!.toString()!
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormattedMessage id={"keyPosition.modalTable.manual"} />
                </div>
                <div>
                  {this.modalData && this.modalData.job_description ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px"
                      }}
                    >
                      <span
                        style={{ padding: "0 10px 0 0 ", color: "#005487" }}
                      >
                        {this.modalData &&
                          this.modalData.job_description[0].file_name}
                      </span>
                      <Button
                        onClick={() => {
                          const extension =
                            this.modalData &&
                            this.modalData.job_description[0].file_name
                              .split(".")
                              .slice(-1)[0]
                              .toString();
                          return downloadFile(
                            this.modalData &&
                              this.modalData.job_description[0].file_id,
                            this.modalData &&
                              this.modalData.job_description[0].file_name,
                            extension,
                            "not found"
                          );
                        }}
                        type="primary"
                      >
                        <Icon type="download" />
                      </Button>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              </div>

              <div style={{ marginBottom: "12px" }}>
                <FormattedMessage id={"keyPosition.modalTable.tableTitle"} />
              </div>
              <Table
                dataSource={this.modalDataTable}
                size="default"
                bordered={true}
                rowKey={() => this.uuid()}
                columns={modalColumns}
              />
            </Form>
          </div>
        </Spin>
      </Modal>
    );
  };
}

export default withRouter(injectIntl(TalentPoolProgramList));
