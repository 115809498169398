import * as React from "react";
import {inject, observer} from "mobx-react";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Tabs} from "antd";
import {RootStoreProp} from "../../store";
import {withRouter} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import MedalTab from "./Medals/MedalTab";
import BestEmployeesTab from "./BestEmployeesTab";

const { TabPane } = Tabs;

export type RecognitionProps = {
    personGroupId: string
};

@injectMainStore
@inject("rootStore")
@observer
class RecognitionComponent extends React.Component<RecognitionProps & MainStoreInjected & RootStoreProp & WrappedComponentProps & RouteComponentProps<any>> {

    render() {
        const activeMainTab = this.props.rootStore!.recognitionStore.selectedMainTab ? this.props.rootStore!.recognitionStore.selectedMainTab : "1"
        return (

            <div style={{ height: "100%", position: 'relative' }}>
                <Tabs style={{ width: "100%" }} defaultActiveKey={activeMainTab}
                    onChange={activeKey => {
                        this.props.rootStore!.recognitionStore.setMainTab(activeKey)
                    }}>
                    <TabPane style={{ width: "100%" }} tab={this.props.intl.formatMessage({ id: "bestEmployees" })} key="1">
                        <BestEmployeesTab personGroupId={this.props.personGroupId}/>
                    </TabPane>
                    
                    <TabPane tab={this.props.intl.formatMessage({ id: "myRecognitionProfile" })} key="3">
                      <MedalTab personGroupId={this.props.personGroupId}/>
                    </TabPane>

                </Tabs>
            </div>
        );
    }


    componentDidMount() {
        
    }
}

const recognitionComponent = injectIntl(withRouter(RecognitionComponent));
export default recognitionComponent;