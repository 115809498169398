import {action, computed, observable} from "mobx";
import {restServices} from "../../../../cuba/services";
import {RecognitionPojo} from "../../../../cuba/entities/base/tsadv$RecognitionPojo";

enum WallType {
  ALL = -1,
  PERSON = 1
}

export class RecognitionsStore {
  @observable recognitions: RecognitionPojo[] = [];
  @observable page: number = 0;
  @observable currentFilter: string = "";
  @observable lastCount: number = 0;
  @observable pageCount: number = 0;
  @observable isLoading = false;

  wallType: WallType;
  personGroup?: string;

  constructor(wallType: WallType) {
    this.wallType = wallType;
  }

  @action loadNextRecognitionsPage = async () => {
    this.page++;
    this.isLoading = true;

    const response = await restServices.recognitionService.loadRecognitions(this.page, this.lastCount, this.wallType, this.personGroup!, "-1", this.currentFilter)
      .finally(() => this.isLoading = false);

    if (this.page === 1) {
      this.lastCount = response.pageInfo!.totalRowsCount!;
    }

    this.pageCount = response.pageInfo!.pagesCount!;

    this.recognitions = [
      ...this.recognitions,
      ...response.recognitions!
    ];

  }

  @computed get hasMore() {
    return this.page <= this.pageCount;
  }

  @action setCurrentFilter = async (filter: string) => {
    this.currentFilter = filter;
    await this.reloadRecognitions();
  }

  @action reloadRecognitions = async () => {
    this.page = 0;
    this.recognitions = [];
    this.lastCount = 0;
    await this.loadNextRecognitionsPage();
  }


}

export const recognitionAllWallTypeStore = new RecognitionsStore(WallType.ALL);

export const recognitionPersonWallTypeStore = new RecognitionsStore(WallType.PERSON);