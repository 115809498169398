import * as React from "react";
import {inject, observer} from "mobx-react";
import {getCubaREST, injectMainStore, instance, MainStoreInjected, Msg} from "@cuba-platform/react";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Button, Col, Row, Spin, Input, Typography, Alert} from 'antd';
import {Link} from "react-router-dom";
import {organizationVacancyRecommendPATH} from "./OrganizationVacancyRecommend";
import {organizationVacancyRespondPATH} from "./OrganizationVacancyRespond";
import {RouteComponentProps} from "react-router";
import {Requisition} from "../../../cuba/entities/base/tsadv$Requisition";
import moment from 'moment';
import {organizationVacancyListPATH} from "./OgranizationVacancyList";
import {action, observable, runInAction} from "mobx";
import {HierarchyElementExt} from "../../../cuba/entities/base/base$HierarchyElementExt";

export const organizationVacancyInfoPATH = "/OrganizationVacancyInfo";

type Props = RouteComponentProps<{ entityId: string}>;

@injectMainStore
@inject("rootStore")
@observer
class OrganizationVacancyInfoClass extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp&Props> {

  @observable dataInstance = instance<Requisition>(Requisition.NAME,{
    view: "requisition.info",
  });
  @action findParent = async (organizationGroupId:string,previousCompanyName?:string):Promise<string> =>{
    return await getCubaREST()!.searchEntities<HierarchyElementExt>(HierarchyElementExt.NAME,{
      conditions:[{
        property:"organizationGroup.id",
        operator:"=",
        value:organizationGroupId
      }]
    },{view:"hierarchyElement.full"}).then(value => {
      if(value[0]&&value[0].parent){
        return this.findParent(value[0].parent!.organizationGroup!.id,value[0].name!)
      }
      return previousCompanyName!
    })
  }
  @observable companyName:string;

  render() {
    const {TextArea} = Input;
    const {Text} = Typography;
    const {entityId}=this.props.match.params;
    const entityName = this.dataInstance.entityName;
    const item = this.dataInstance.item;
    const positionName = item && item.positionGroup? item.positionGroup['_instanceName'] : ""
    const operationMode = item && item.operatingMode?item.operatingMode:""
    const startDate = item&&item.createTs? moment(item.createTs).locale('ru').format('L'):"";
    const jobResponsibilities = (locale:string):string => {
      switch (locale){
        case 'ru':
          return (item&&item.jobResponsibilitiesLang1?item.jobResponsibilitiesLang1:" ")
        case 'kz':
          return (item&&item.jobResponsibilitiesLang2?item.jobResponsibilitiesLang2:" ")
        case 'en':
          return (item&&item.jobResponsibilitiesLang3?item.jobResponsibilitiesLang3:" ")
        default :
          return " "
      }
    }
    const desirableRequirementsAndAdditionalComments = (locale:string):string => {
      switch (locale) {
        case 'ru':
          return (item && item.desirableRequirementsAndAdditionalCommentsLang1 ? item.desirableRequirementsAndAdditionalCommentsLang1 : " ")
        case 'kz':
          return (item && item.desirableRequirementsAndAdditionalCommentsLang2 ? item.desirableRequirementsAndAdditionalCommentsLang2 : " ")
        case 'en':
          return (item && item.desirableRequirementsAndAdditionalCommentsLang3 ? item.desirableRequirementsAndAdditionalCommentsLang3 : " ")
        default :
          return " "
      }
    }
    const generalAndAdditionalRequirements = (locale:string):string => {
      switch (locale) {
        case 'ru':
          return (item && item.generalAndAdditionalRequirementsLang1 ? item.generalAndAdditionalRequirementsLang1 : " ")
        case 'kz':
          return (item && item.generalAndAdditionalRequirementsLang2 ? item.generalAndAdditionalRequirementsLang2 : " ")
        case 'en':
          return (item && item.generalAndAdditionalRequirementsLang3 ? item.generalAndAdditionalRequirementsLang3 : " ")
        default :
          return " "
      }
    }
      const mandatoryQualifications = (locale:string):string => {
        switch (locale) {
          case 'ru':
            return (item && item.mandatoryQualificationsLang1 ? item.mandatoryQualificationsLang1 : " ")
          case 'kz':
            return (item && item.mandatoryQualificationsLang2 ? item.mandatoryQualificationsLang2 : " ")
          case 'en':
            return (item && item.mandatoryQualificationsLang3 ? item.mandatoryQualificationsLang3 : " ")
          default :
            return " "
        }
    }
    return (
      <Page>
        {this.dataInstance.status!=="DONE"?
          <div className="spin-center">
            <Spin spinning size={"large"}/>
          </div>
          :
            <>
                  <Section size={"large"} style={{width:"95%"}}>
                   <span style={{display:"inline-flex",width:"100%", justifyContent:"space-between"}}>
                     <h2 className={"orgVacancy"} style={{marginTop:"auto",marginBottom:"auto"}}><strong>{positionName}</strong></h2>
                     <h3 style={{marginTop:"auto",marginBottom:"auto"}}><strong>{this.props.intl.formatMessage({id:"vacancy.Created"}) + " : " +startDate}</strong></h3>
                   </span>
                    <p className={"orgVacancy"}><span style={{color:"brown"}}>{this.companyName?<strong>{this.companyName}</strong>:""} </span></p>
                    <p className={"orgVacancy"}><span style={{color:"grey"}}><strong>{operationMode}</strong></span></p>
                    <Row style={{marginTop:"40px"}}>
                      <Col style={{marginLeft:"20px",marginRight:20}} xxl={2} xl={3} lg={3} xs={3} >
                        <Link to={organizationVacancyRecommendPATH+ "/" + entityId}>
                          <Button type={"primary"}>
                          <FormattedMessage id={"recommend"}/>
                          </Button>
                        </Link>
                      </Col>
                      <Col style={{marginLeft:"20px"}} xxl={4} xl={4} lg={5} xs={5}>
                        <Link to={organizationVacancyRespondPATH + "/" + entityId}>
                          <Button type={"primary"}>
                          <FormattedMessage id={"respond"}/>
                        </Button>
                        </Link>
                      </Col>
                      <Col xxl={14} xl={14} lg={10} xs={10}>
                        <Alert style={{display:"inline-block"}} message={this.props.intl.formatMessage({id:"organization.vacancy.alert"})} type="error" />
                      </Col>
                    </Row>
                    <div className={"orgVacancy"}>
                      <Text strong><Msg entityName={entityName} propertyName={"jobResponsibilitiesLang1"}/></Text>
                      <TextArea autoSize={{minRows:4}} disabled value={jobResponsibilities(this.props.rootStore!.userInfo.locale!)}/>
                    </div>
                    <div className={"orgVacancy"}>
                      <Text strong><Msg entityName={entityName} propertyName={"mandatoryQualificationsLang1"}/></Text>
                      <TextArea autoSize={{minRows:4}} disabled value={mandatoryQualifications(this.props.rootStore!.userInfo.locale!)}/>
                    </div>
                    <div className={"orgVacancy"}>
                      <Text strong><Msg entityName={entityName} propertyName={"generalAndAdditionalRequirementsLang1"}/></Text>
                      <TextArea autoSize={{minRows:4}} disabled value={generalAndAdditionalRequirements(this.props.rootStore!.userInfo.locale!)}/>
                    </div>
                    <div className={"orgVacancy"}>
                      <Text strong><Msg entityName={entityName} propertyName={"desirableRequirementsAndAdditionalCommentsLang1"}/></Text>
                      <TextArea autoSize={{minRows:4}} disabled value={desirableRequirementsAndAdditionalComments(this.props.rootStore!.userInfo.locale!)}/>
                    </div>
                    <Link to={organizationVacancyListPATH}><Button style={{marginLeft:"20px",marginTop:"40px"}}>{this.props.intl.formatMessage({id:"back"})}</Button></Link>
                  </Section>
            </>
          }
      </Page>

    );
  }
  componentDidMount() {
   const {entityId}=this.props.match.params;
   getCubaREST()!.loadEntity<Requisition>(Requisition.NAME,entityId,{view:"requisition.info"})
     .then(res=>{
       this.dataInstance.setItem(res)
       const {item} = this.dataInstance
       if(item&&item.positionGroup&&item.positionGroup.organizationGroup){
         this.findParent(item.positionGroup.organizationGroup.id).then(a => runInAction(()=>this.companyName = a))
       }

     })
  }
}
const organizationVacancyInfo = injectIntl(OrganizationVacancyInfoClass);
export default organizationVacancyInfo;

