import RootStore from "./RootStore";
import {observable} from "mobx";

export default class PositionStructureStore {

  root: RootStore;

  selectedPositionGroupId: string;

  selectedPositionGroupName: string;

  selectedStructuralOrganizationsTree: string;

  selectedFunctionalManagerName: string;

  selectedFunctionalManagerId: string;

  selectedAdministrativeManagerName: string;

  selectedAdministrativeManagerId: string;


  constructor(root: RootStore) {
    this.root = root;
  }

  setSelectedPositionGroupId(value: string) {
    this.selectedPositionGroupId = value;
  }

  setSelectedAdministrativeManagerName(value: string) {
    this.selectedAdministrativeManagerName = value;
  }

  setSelectedFunctionalManagerName(value: string) {
    this.selectedFunctionalManagerName = value;
  }

  setSelectedAdministrativeManagerId(value: string) {
    this.selectedAdministrativeManagerId = value;
  }

  setSelectedFunctionalManagerId(value: string) {
    this.selectedFunctionalManagerId = value;
  }

  setSelectedStructuralOrganizationsTree(value: string) {
    this.selectedStructuralOrganizationsTree = value;
  }

  setSelectedPositionGroupName(value: string) {
    this.selectedPositionGroupName = value;
  }

  clearPositionStore(){
    this.selectedPositionGroupId = ''
    this.selectedPositionGroupName = ''
    this.selectedFunctionalManagerName = ''
    this.selectedFunctionalManagerId = ''
    this.selectedAdministrativeManagerName = ''
    this.selectedAdministrativeManagerId = ''
    this.selectedStructuralOrganizationsTree = ''
  }

}