import {getCubaREST} from "@cuba-platform/react";
import {SerializedEntity} from "@cuba-platform/rest";
import {PersonExt} from "../../cuba/entities/base/base$PersonExt";
import moment from "moment";

export const getPersonByPersonGroup = async (personGroupId: string,
                                             date: string = moment().format("YYYY-MM-DD"),
                                             view: string = "_minimal"): Promise<SerializedEntity<PersonExt>> => {
  return await getCubaREST()!.searchEntities<SerializedEntity<PersonExt>>(
    PersonExt.NAME,
    {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: date,
      }, {
        property: 'endDate',
        operator: '>=',
        value: date,
      }]
    }, {
      limit: 1,
      view: view
    }
  ).then(value => value[0]);
};