import * as React from "react";
import {Button as AntBtn, Button, Icon, Modal, Spin, Table} from "antd";
import {SuccessionPlanning} from "../../../../cuba/entities/base/tsadv$SuccessionPlanning";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {action, observable} from "mobx";
import SuccessorProfileModal from "./SuccessorProfileModal";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {observer} from "mobx-react";
import {collection} from "@cuba-platform/react";
import {ReactNode} from "react";
import {Successor} from "../../../../cuba/entities/base/tsadv$Successor";
import Column from "antd/es/table/Column";
import moment from "moment";
import {RouteComponentProps, withRouter} from "react-router";
import {SuccessorRequestManagement} from "../../SuccessorRequest/SuccessorRequestManagement";
import {SuccessorHistory} from "../../../../cuba/entities/base/tsadv_SuccessorHistory";
import SuccessorHistoryModal from "./SuccessorHistoryModal";
import {restServices} from "../../../../cuba/services";
import {runReport} from "../../../util/reportUtil";
import Notification from "../../../util/Notification/Notification";
import {Person} from "../../../../cuba/entities/base/base$Person";
import {PersonExt} from "../../../../cuba/entities/base/base$PersonExt";
import {getPersonByPersonGroup} from "../../../util/personUtils";

type Props = {
  selectedPosition: string | undefined,
  succesors?: Successor[]
}

@injectMainStore
@observer
class ReservistsList extends React.Component<RouteComponentProps & Props & MainStoreInjected & WrappedComponentProps> {
  @observable mainStore = this.props.mainStore!;
  @observable data: any
  @observable isVisible = false;
  @observable id: string = "";
  @action.bound toggleVisibility = () => {
    this.isVisible = !this.isVisible
  }
  @action setHistoryModalVisible = () => {
    this.data=null;
    this.historyVisible=false;
  }
  @observable historyVisible = false
  @action showHistory = (personGroupId: string) => {
    getCubaREST()!.searchEntities(SuccessorHistory.NAME, {
      conditions: [{property: "successor.personGroup.id", operator: "=", value: personGroupId}]
    }, {
      view:"successorHistory-view",
      sort:"+date"
    }).then(res => { console.log(res); this.data = res})
    this.historyVisible = true
  }
  showModal = () => {
    const personData = this.dataCollection.items[0].successors!.find(item => {
      return item.personGroup!.id === this.id
    }) ? this.dataCollection.items[0].successors!.find(item => {
      return item.personGroup!.id === this.id
    }) : undefined;
    const org = this.dataCollection.items[0].positionGroup!.organizationGroup ? this.dataCollection!.items![0].positionGroup!.organizationGroup["_instanceName"] : ""
    return <SuccessorProfileModal organizationGroup={org} toggleVisibility={this.toggleVisibility}
                                  isVisible={this.isVisible} personData={personData}/>
  }
  @observable dataCollection = collection<SuccessionPlanning>(SuccessionPlanning.NAME, {
    view: "successionPlanning.browse",
    filter: {
      conditions: [{
        property: "positionGroup.id",
        operator: '=',
        value: this.props.selectedPosition ? this.props.selectedPosition : null
      }]
    }

  });


  @observable successorList:any
  @observable isLoadingReporteEn:boolean = false
  @observable isLoadingReportRu:boolean = false
  @observable isVisibleReportModal:boolean = false
  loadPersonInfo = async (localeProp: string, e: any) => {
    console.log(this.successorList && this.successorList.id!)
    try {
      localeProp === "en" ?  this.isLoadingReporteEn = true : this.isLoadingReportRu = true
        getCubaREST()!.searchEntities<Successor>(Successor.NAME,{
          conditions: [{
            property: "id",
            operator: '=',
            value: this.recordId
          }]
        },{
          view:"_base"
        }).then(value=>{
          getPersonByPersonGroup(value[0] && value[0]!.personGroup! && value[0].personGroup!.id!).then(res=>{
            const data = {
              parameters: [{
                name: "person",
                value:res && res!.id!
              }]
            };
            let locale: string = localeProp === "en" ? "PROFILE_TD_EN" : "PROFILE_TD_RU"

            runReport(locale, data, this.props.intl).then(()=> {
              localeProp === "en" ?  this.isLoadingReporteEn = false : this.isLoadingReportRu = false
            });
          })

        })

    } catch (e) {
      localeProp === "en" ?  this.isLoadingReporteEn = false : this.isLoadingReportRu = false
      Notification.error({
        message: this.props.intl.formatMessage({
          id: 'adaptationPlan.employee_profile.NotFound',
          defaultMessage: 'Employee Profile Not Found!',
        }),
      });
    }
  }
  @observable
  recordId:string
  @action
  showReportModal = (recordId:string) => {
    this.isVisibleReportModal= true
    this.recordId = recordId
  };

  @action
  handleOk = () => {
    this.isVisibleReportModal= false
  };

  @action
  handleCancel = () => {
    this.isVisibleReportModal= false
  };

  componentDidMount() {

    getCubaREST()!.searchEntities<SuccessionPlanning>(SuccessionPlanning.NAME,{
        conditions: [{
          property: "positionGroup.id",
          operator: '=',
          value: this.props.selectedPosition ? this.props.selectedPosition : null
        }]
    },{view: "successionPlanning.browse"}).then((value)=>{
      this.successorList = value[0].successors!.map(item=>item)
      console.log(this.successorList)
    })
  }

  render(): ReactNode {
    return (
      <div style={{width: "1fr"}}>
        {(
          (this.dataCollection.status) !== "DONE" && this.dataCollection.items.length < 1 ? <Spin spinning={true}/> :
            this.dataCollection.status === "DONE" && this.dataCollection.count === 0 ?
              <Table dataSource={[]}/> :
              <>
                {/* <Button type={"primary"} style={{margin: "20px"}} onClick={() => {
                  this.props.history.push({
                    pathname: SuccessorRequestManagement.PATH + "/" + SuccessorRequestManagement.NEW_SUBPATH,
                    state: {reservistId: "new", positionGroupId: this.props.selectedPosition}
                  })
                }}>{this.props.intl.formatMessage({id: "addReservist"})}</Button> */}
                <Table pagination={{pageSize: 8}} bordered={true}
                       scroll={{x:true}}
                       dataSource={this.successorList}>
                  <Column key="name" dataIndex={"successors"}
                          title={this.props.intl.formatMessage({id: "reservist.fullName"})}
                          render={((text, record: Successor) => record.personGroup ? <a onClick={() => {
                            this.id = record.personGroup!.id;
                            this.toggleVisibility()
                          }}>{record.personGroup!["_instanceName"] + " " + "(" + record.personGroup!.list![0].employeeNumber + ")"}</a> : "")}/>
                  <Column key="dateOfUpdate" dataIndex={"successors"}
                          title={this.props.intl.formatMessage({id: "reservistUpdateDate"})}
                          render={((text, record: Successor) => record.startDate? moment((record.startDate)).format("DD.MM.YYYY") : "")}/>
                  <Column key="readinessLevel" dataIndex={"successors"}
                          title={this.props.intl.formatMessage({id: "reservistReadiness"})}
                          render={((text, record: Successor) => record.readinessLevel ? record.readinessLevel["_instanceName"] : "")}/>
                  <Column key="status" dataIndex={"successor"}
                          title={this.props.intl.formatMessage({id: "reservistStatus"})}
                          render={((text, record: Successor) => record.status ? record.status["_instanceName"] : "")}/>
                  <Column key="history"
                          title={this.props.intl.formatMessage({id:"history"})}
                          render={((text, record: Successor) => <a onClick={(e) => {
                            e.preventDefault();
                            this.showHistory(record.personGroup && record.personGroup.id)
                          }}>{this.props.intl.formatMessage({id:"history"})}</a>)}/>
                  <Column
                          title=""
                          render={((text, record: Successor) => {
                                return <Button
                                  onClick={()=>this.showReportModal(record.id)}
                                  style={{background:"green",color:"#fff",float:"right", marginRight:40}}>
                                  <FormattedMessage id={"myTeam-personData.profile"}/>
                                </Button>

                            }
                          )}/>
                </Table>
                {this.id !== "" ? this.showModal() : null}
                <Modal
                  title={this.props.intl.formatMessage({id:"myTeam-personData.choosen"})}
                  visible={this.isVisibleReportModal}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                >
                  <div>

                    <Button loading={this.isLoadingReportRu} style={{marginRight:"14px"}}  shape="round" icon="download" onClick={(e)=>this.loadPersonInfo("ru",e)}>
                      Ru
                    </Button>
                    <Button loading={this.isLoadingReporteEn}  shape="round" icon="download" onClick={(e)=>this.loadPersonInfo("en",e)}>
                      En
                    </Button>

                  </div>
                </Modal>
                <SuccessorHistoryModal setVisible={this.setHistoryModalVisible} visible={this.historyVisible} data={this.data}/>
              </>

        )}

      </div>

    )
  }
}

export default withRouter(injectIntl(ReservistsList));
