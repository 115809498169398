import React, {Component} from 'react';
import {Icon, List} from "antd";
import {Meta} from "antd/es/list/Item";
import {CourseSection} from "../../../cuba/entities/base/tsadv$CourseSection";
import {CourseSectionRenderType} from "../../pages/MyCourse/RenderModalBody/RenderModalBody";

export type ListItem = {
  id: string,
  succeedFinished: boolean,
  text: string,
  type: CourseSectionRenderType,
  styleClass?: string
}

type Props<T> = {
  dataInstance?: ListItem[] | null,
  selectedItem?: string | null;
  clickItemHandler?: (e:ListItem) => void
}

class CourseSectionList extends Component<Props<CourseSection>> {
  render() {
    return (
      <List
        grid={{ column: 1 }}
        locale={{ emptyText: <></> }}
        dataSource={this.props.dataInstance || []}
        renderItem={item => (
          <List.Item onClick={this.props.clickItemHandler?()=>this.props.clickItemHandler!(item):()=>{}}>
            <Meta
              key={item.id}
              className={"course-section-item" + (this.props.selectedItem === item.id ? " selected" : "") + (item.styleClass ? " " + item.styleClass : "")}
              title={<>
                {item.succeedFinished &&
                  <Icon type="check-circle" className={"done"} theme="twoTone" twoToneColor="#12BF66"/> }
                <div>{item.text}</div>
              </>}
            />
          </List.Item>
        )}
      />
    );
  }
}

export default CourseSectionList;