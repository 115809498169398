import * as React from "react";
import {Card, Icon} from "antd";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {PositionDetailsProps} from "./PositionDetails";
import {observable} from "mobx";
import {JobDescription} from "../../../../cuba/entities/base/tsadv_JobDescription";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import LoadingPage from "../../LoadingPage";
import {inject, observer} from "mobx-react";
import {downloadFile} from "../../../util/util";
import {FileDescriptor} from "../../../../cuba/entities/base/sys$FileDescriptor";
import {RootStoreProp} from "../../../store";
import {withRouter} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {JobDescriptionRequestManagement} from "../../JobDescriptionRequest/JobDescriptionRequestManagement";
import {restQueries} from "../../../../cuba/queries";
import Notification from "../../../util/Notification/Notification";
import Button, {ButtonType} from "../../../components/Button/Button";
import TextArea from "antd/es/input/TextArea";


@inject("rootStore")
@injectMainStore
@observer
class JobDescriptionForm extends React.Component<WrappedComponentProps & PositionDetailsProps & MainStoreInjected & RootStoreProp & RouteComponentProps<any>> {

  @observable
  jobDescription?: JobDescription | null
  @observable
  mainStore = this.props.mainStore!;

  messages = this.mainStore.messages!;


  componentDidMount(): void {
    if (this.props.selectedPosition && this.props.selectedPosition.length > 0) {
      if (!this.jobDescription || (this.jobDescription.id && this.jobDescription.positionGroup
        && this.jobDescription.positionGroup.id && this.jobDescription.positionGroup.id !== this.props.selectedPosition)) {
        getCubaREST()!.searchEntities<JobDescription>(JobDescription.NAME, {
          conditions: [{
            property: "positionGroup.id",
            operator: '=',
            value: this.props.selectedPosition ? this.props.selectedPosition : null
          }]
        }, {
          view: 'jobDescription-for-position-edit'
        }).then(values => {
          if (values && values[0]) {
            this.jobDescription = values[0]
          } else {
            this.jobDescription = null
          }
        });
      }
    }
  }

  render(): React.ReactNode {
    const messages = this.mainStore.messages!;
    if (!messages) return <LoadingPage/>
    let file: FileDescriptor | null | undefined;
    file = this.jobDescription ? this.jobDescription.file : null;
    let fileName: string | null | undefined;
    fileName = file ? file.name : null;
    return <div style={{overflow: "auto"}}>
      <Button style={{
        margin: "10px"
      }} buttonType={ButtonType.PRIMARY} onClick={(event) => {
        restQueries.getPositionsJobDescriptionRequestsCount(this.props.selectedPosition ? this.props.selectedPosition : '')
          .then(value => {
            if (value > 0) {
              Notification.info({
                message: this.props.intl.formatMessage({id: "alreadyExistsRequests"})
              });
            } else {
              if (this.props.selectedPosition) {
                this.props.rootStore!.positionStructureStore.selectedPositionGroupId = this.props.selectedPosition
              }
              if (this.props.rootStore) {
                this.props.history!.push(JobDescriptionRequestManagement.PATH + '/' + JobDescriptionRequestManagement.NEW_SUBPATH);
              }
            }
          })
      }}>{this.props.intl.formatMessage({
        id: "newJobDescriptionRequest"
      })}</Button>
      <Card title={this.props.intl.formatMessage({
        id: "formJD"
      })} style={{
        padding: "10px",
        width: "100%",
      }} type="inner">
        <div style={{padding: "10px"}}>
          <span style={{padding: "10px"}}>{fileName}</span>
          {file ? <Button
            onClick={() => downloadFile(file ? file.id : null, file ? file.name ? file.name : "" : "",
              file ? file.extension ? file.extension : "" : ""
              , "")}
            type="primary">
            <Icon type="download"/>
          </Button> : <div/>}
        </div>
        <Card title={messages[JobDescription.NAME + '.positionDuties']}
              style={{
                padding: "10px",
                width: "100%",
                height: "auto"
              }} type="inner">
          <TextArea disabled={true} autoSize={{minRows: 1}}
                    value={this.jobDescription ?
                      this.jobDescription.positionDuties ?
                        this.jobDescription.positionDuties : undefined : undefined}/>
        </Card>
        <Card title={messages[JobDescription.NAME + '.basicInteractionsAtWork']} style={{
          padding: "10px",
          width: "100%",
        }} type="inner">
          <TextArea disabled={true} autoSize={{minRows: 1}}
                    value={this.jobDescription ?
                      this.jobDescription.basicInteractionsAtWork ?
                        this.jobDescription.basicInteractionsAtWork : undefined : undefined}/>
        </Card>
        <Card title={messages[JobDescription.NAME + '.compulsoryQualificationRequirements']} style={{
          padding: "10px",
          width: "100%",
        }} type="inner">
          <TextArea disabled={true} autoSize={{minRows: 1}}
                    value={this.jobDescription ?
                      this.jobDescription.compulsoryQualificationRequirements ?
                        this.jobDescription.compulsoryQualificationRequirements : undefined : undefined}/>
        </Card>
        <Card title={messages[JobDescription.NAME + '.generalAdditionalRequirements']} style={{
          padding: "10px",
          width: "100%",
        }} type="inner">
          <TextArea disabled={true} autoSize={{minRows: 1}}
                    value={this.jobDescription ?
                      this.jobDescription.generalAdditionalRequirements ?
                        this.jobDescription.generalAdditionalRequirements : undefined : undefined}/>
        </Card>
      </Card>

    </div>
  }
}

export default injectIntl(withRouter(JobDescriptionForm));