import * as React from "react";
import {action, observable} from "mobx";
import {inject, observer} from "mobx-react";
import {
  collection,
  getCubaREST, getEnumCaption, getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected,
  Msg,
} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {PositionDetailsProps} from "./PositionDetails";
import {RootStoreProp} from "../../../store";
import {RouteComponentProps} from "react-router-dom";
import {PositionExt} from "../../../../cuba/entities/base/base$PositionExt";
import LoadingPage from "../../LoadingPage";
import {withRouter} from "react-router";
import {Checkbox, Col, Input, Row, Button, Modal, Icon, Spin, Table} from "antd";
import {SuccessionPlanning} from "../../../../cuba/entities/base/tsadv$SuccessionPlanning";
import {SuccessionPlanningHistory} from "../../../../cuba/entities/base/tsadv_SuccessionPlanningHistory";
import "./TabSheets.css";
import {restServices} from "../../../../cuba/services";
import Column from "antd/es/table/Column";
import {SerializedEntity} from "@cuba-platform/rest";
import {SuccessionPlanningRequestManagement} from "../../SuccessionPlanningRequest/SuccessionPlanningRequestManagement";
import {SuccessionPlanningRequest} from "../../../../cuba/entities/base/tsadv_SuccessionPlanningRequest";

@inject("rootStore")
@injectMainStore
@observer
class MainDataComponent extends React.Component<WrappedComponentProps & PositionDetailsProps & MainStoreInjected & RootStoreProp & RouteComponentProps<any>> {


  @observable
  positionExt?: any;

  @observable
  loading: boolean = false

  @observable
  visible: boolean = false

  @observable
  visibleTree: boolean = false

  @observable
  mainStore = this.props.mainStore!;

  messages = this.mainStore.messages!;

  @observable
  isKeyPosition: boolean = false

  @observable
  requestStatus: string | null | undefined = ""
  @observable historyNotEmpty = false;
  @action setHistoryNotEmpty = () => {
    this.historyNotEmpty = true
  };
  @observable
  array: any

  dataCollection = collection<SuccessionPlanningHistory>(SuccessionPlanningHistory.NAME, {
    view: 'successionPlanningHistory-short-view',
    sort: "+updateTs",
    filter: {
      conditions: [{
        property: "successionPlanning.positionGroup.id",
        operator: "=",
        value: this.props.selectedPosition ? this.props.selectedPosition! : null
      }]
    }
  })
  fields = [
    "number",

    "date",

    "type",

    "comments",
    'initiator'
  ]
  showModal = () => {
    this.visible = true
  };
  showModalTree = () => {
    this.visibleTree = true
  }

  handleOk = () => {
    this.loading = true
  };

  handleCancel = () => {
    this.visible = false
  };
  handleCancelTree = () => {
    this.visibleTree = false
  };

  loadData = (id: any) => {
    restServices.positionHierarchyService.getPositionHierarchyPath({
      positionGroupId: id,
      lang: this.props!.rootStore!.userInfo!.locale!,
      date: null
    })
      .then(r => {
        this.array = r
      })
  }

  componentDidMount(): void {
    getCubaREST()!.searchEntities<SuccessionPlanningRequest>(SuccessionPlanningRequest.NAME, {
      conditions: [{
        property: "personGroup.id",
        operator: "=",
        value: this.props.rootStore!.userInfo!.personGroupId!
      }, {
        property: "positionGroup.id",
        operator: "=",
        value: this.props.selectedPosition ? this.props.selectedPosition! : null
      }]
    }, {view: "successionPlanningRequest-edit", sort: "updateTs"}).then(value => {
      this.requestStatus = value[0] && value[0].status && value[0].status!.code!
    })
    if (this.props.selectedPosition && this.props.selectedPosition.length > 0) {

      getCubaREST()!.searchEntities<SuccessionPlanning>(SuccessionPlanning.NAME, {
        conditions: [{
          property: "positionGroup.id",
          operator: "=",
          value: this.props.selectedPosition ? this.props.selectedPosition! : null
        },
          {
            property: "positionType",
            operator: "=",
            value: "INCLUDE"
          }
        ]
      }, {
        view: 'keyPosition-view'
      }).then(values => {
        if (values.length > 0) {
          console.log(values)
          this.isKeyPosition = true
        }
      })

      if (!this.positionExt || (this.positionExt.id && this.positionExt.group &&
        this.positionExt.group.id && this.positionExt.id !== this.props.selectedPosition
      )) {
        getCubaREST()!.searchEntities<PositionExt>(PositionExt.NAME, {
          conditions: [{
            property: "group.id",
            operator: '=',
            value: this.props.selectedPosition ? this.props.selectedPosition : null
          }]
        }, {
          view: 'position.edit'
        }).then(values => {
          if (values && values[0]) {
            this.positionExt = values[0]
            this.loadData(values[0]!.group!.id!)
          }
        })

      }
    }
  }

  Modalwarning() {
    Modal.warning({
      title: this.props.intl.formatMessage({id: "positionHierarchy.maindData.modal.warning.title"}),
      content: this.props.intl.formatMessage({id: "positionHierarchy.maindData.modal.warning.text"}),
    });
  }

  @action setIsKeyPosition = () => {
    this.isKeyPosition = true
  }

  render(): React.ReactNode {
    if (this.dataCollection.status === "DONE" && this.dataCollection.items.length > 0 || this.requestStatus === "APPROVING" ) {
      this.setHistoryNotEmpty();
    }
    console.log(this.dataCollection)
    console.log(this.requestStatus)
    const messages = this.mainStore.messages!;
    if (!messages) return <LoadingPage/>
    const visible = this.visible;
    const visibleTree = this.visibleTree
    if (!this.positionExt) {
      return <Spin style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}/>
    }

    const lastSuccessors = this.dataCollection && this.dataCollection.items[this.dataCollection.items.length - 1] && this.dataCollection.items[this.dataCollection.items.length - 1].type!
    let isTypeIncludeOnLastSuccessors: boolean = false
    if (lastSuccessors === "INCLUDE") {
      isTypeIncludeOnLastSuccessors = true
    }


    return (
      <div style={{height: 'calc(100% - 58px)', overflow: "auto", padding: "0 12px"}}>
        <Row className={"form-row"} style={{marginBottom: "10px"}}>

          <Col md={24} lg={12}>
            <div>
              <FormattedMessage id={"tree.positionHieararchy.name"}/>
              <div style={{display: "flex"}}>
                <Input style={{marginTop: "8px"}}
                       value={this.positionExt ? this.positionExt.group!['_instanceName'] || '' : ''}
                       disabled={true}
                />
                <Button style={{marginTop: "8px"}} onClick={this.showModalTree}>
                  <Icon type="apartment"/>
                </Button>
                <Modal
                  visible={visibleTree}
                  onOk={this.handleOk}
                  onCancel={this.handleCancelTree}
                  footer={[
                    <Button key="back" onClick={this.handleCancelTree}>
                      <FormattedMessage id={"tree.positionHieararchy.backButton"}/>
                    </Button>,
                  ]}>
                  <div>
                    {this.array !== undefined ?
                      this.array!.split("/")!.map((item: any, index: any) => {
                        const a = ".";
                        return <div key={index}
                                    style={index === 1 ? {color: "gray"} : {color: "black"}}> {a.repeat(index) + item} </div>
                      })
                      : ""}
                  </div>
                </Modal>
              </div>
            </div>
          </Col>
          <Col md={24} lg={12}>
            <div>
              <FormattedMessage id={"tree.positionHieararchy.division"}/>
              <Input style={{marginTop: "8px"}}
                     value={this.positionExt ? this.positionExt.organizationGroupExt!['_instanceName'] || '' : ''}
                     disabled={true}
              />
            </div>
          </Col>
        </Row>
        <Row className={"form-row"} style={{marginBottom: "10px"}}>
          <Col md={24} lg={12}>
            <div>
              <FormattedMessage id={"tree.positionHieararchy.functionalGuide"}/>
              <Input style={{marginTop: "8px"}}
                     value={this.positionExt && this.positionExt ? this.positionExt.hasOwnProperty("functionalManagerPositionGroup") ? this.positionExt.functionalManagerPositionGroup!['_instanceName'] : "" || "" : ""}
                     disabled={true}
              />
            </div>
          </Col>
          <Col md={24} lg={12}>
            <div>
              <FormattedMessage id={"tree.positionHieararchy.grade"}/>
              <Input style={{marginTop: "8px"}}
                     value={this.positionExt && this.positionExt && this.positionExt.gradeGroup ? this.positionExt.gradeGroup['_instanceName'] : ''}
                     disabled={true}
              />
            </div>
          </Col>
        </Row>
        <Row className={"form-row"} style={{marginBottom: "10px"}}>

          <Col md={24} lg={12}>
            <div>
              <div>
                <Checkbox checked={this.isKeyPosition && isTypeIncludeOnLastSuccessors} disabled
                          style={{background: "#fff",marginRight:"12px"}}
                          className={"tree_chekbox"}/><FormattedMessage id={"tree.positionHieararchy.keyPosition"}/>
                <div style={{display: "flex", marginTop: "10px"}}>
                  {(this.historyNotEmpty) ? <button style={{
                      color: "blue",
                      textDecoration: "underline",
                      border: "none",
                      background: "transparent",
                      marginLeft: "12px"
                    }}
                                                    onClick={this.showModal}>
                      <FormattedMessage id={"tree.positionHieararchy.history"}/>
                    </button>
                    :
                    <div style={{
                      textDecoration: "underline",
                      border: "none",
                      background: "transparent",
                      marginLeft: "12px"
                    }}>
                      <FormattedMessage id={"tree.positionHieararchy.history"}/>
                    </div>}
                </div>
              </div>
              <Modal
                className={'mainData-model'}
                visible={visible}
                title={this.props.intl.formatMessage({id: "tree.positionHieararchy.dateTableTitle"})}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                  <Button key="back" onClick={this.handleCancel}>
                    <FormattedMessage id={"tree.positionHieararchy.backButton"}/>
                  </Button>,
                ]}>
                <div>
                  <Table
                    dataSource={this.dataCollection.items}
                    size="default"
                    bordered={false}
                    rowKey="id">
                    <Column title={<Msg entityName={SuccessionPlanningHistory.NAME} propertyName={"number"}/>}
                            dataIndex={"number"}
                            key={"number"} render={(text, record: SerializedEntity<SuccessionPlanningHistory>) => {
                      return <span>
                                  {(record.number)}
                                </span>
                    }}/>
                    <Column title={<Msg entityName={SuccessionPlanningHistory.NAME} propertyName={"date"}/>}
                            dataIndex={"date"}
                            key={"date"} render={(text, record: SerializedEntity<SuccessionPlanningHistory>) => {
                      return <span>
                                    {(record.date)!.substr(0, 10).split('-').reverse().join('.')}
                                </span>
                    }}/>
                    <Column title={<Msg entityName={SuccessionPlanningHistory.NAME} propertyName={"type"}/>}
                            dataIndex={"type"}
                            key={"type"} render={(text, record: SerializedEntity<SuccessionPlanningHistory>) => {
                      return <span>
                        {getEnumCaption(record.type, getPropertyInfoNN("type", SuccessionPlanningHistory.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!)}
                                </span>
                    }}/>
                    <Column title={<Msg entityName={SuccessionPlanningHistory.NAME} propertyName={"initiator"}/>}
                            dataIndex={"initiator"}
                            key={"initiator"} render={(text, record: SerializedEntity<SuccessionPlanningHistory>) => {
                      return <span>
                                  {(record.initiator && record.initiator['_instanceName']!)}
                                </span>
                    }}/>
                    <Column title={<Msg entityName={SuccessionPlanningHistory.NAME} propertyName={"comments"}/>}
                            dataIndex={"comments"}
                            key={"comments"} render={(text, record: SerializedEntity<SuccessionPlanningHistory>) => {
                      return <span>
                                  {(record.comments)}

                                </span>
                    }}/>

                  </Table>
                </div>
              </Modal>
            </div>
          </Col>
        </Row>
        {!(this.isKeyPosition && isTypeIncludeOnLastSuccessors) ?
          <Button onClick={() => {
            this.requestStatus === "APPROVING" ? this.Modalwarning()
              :
              this.props.history.push({
                pathname: SuccessionPlanningRequestManagement.PATH + "/" + SuccessionPlanningRequestManagement.NEW_SUBPATH,
                state: {
                  isKeyPosition: (this.isKeyPosition && isTypeIncludeOnLastSuccessors),
                  positionGroupId: (this.positionExt && this.positionExt.group && this.positionExt.group.id)
                }
              })
          }} style={{background: "green", color: "#fff", marginTop: "20px"}}>
            <FormattedMessage id={"tree.positionHieararchy.turnOf"}/>
          </Button>
          :
          <Button onClick={() => {
            this.props.history.push({
              pathname: SuccessionPlanningRequestManagement.PATH + "/" + SuccessionPlanningRequestManagement.NEW_SUBPATH,
              state: {
                isKeyPosition: (this.isKeyPosition && isTypeIncludeOnLastSuccessors),
                positionGroupId: (this.positionExt && this.positionExt.group && this.positionExt.group.id)
              }
            })
          }} style={{background: "#0e5987", color: "#fff", marginTop: "20px"}}>
            <FormattedMessage id={"tree.positionHieararchy.exept"}/>
          </Button>
        }
        {this.historyNotEmpty ? <button style={{
          color: "blue",
          textDecoration: "underline",
          border: "none",
          background: "transparent",
          marginLeft: "12px"
        }}
                                        onClick={() => {
                                          this.props.history.push({
                                            pathname: SuccessionPlanningRequestManagement.PATH + "/",
                                            state: {
                                              isKeyPosition: this.isKeyPosition,
                                              positionGroupId: (this.positionExt && this.positionExt.group && this.positionExt.group.id)
                                            }
                                          })
                                        }}>
          <FormattedMessage id={"allKeyPositionRequests"}/>
        </button> : <button style={{
          textDecoration: "underline",
          border: "none",
          background: "transparent",
          marginLeft: "12px"
        }}>
          <FormattedMessage id={"allKeyPositionRequests"}/>
        </button>}
      </div>
    )
  }


}

export default injectIntl(withRouter(MainDataComponent));