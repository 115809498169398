import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {observer} from "mobx-react";
type Props = {
  loadPersonInfo:(el:string)=>void,
  dataObj:any,
  numberOfEmployees:number,
  showInfo:(item:any)=>void
}
@injectMainStore
@observer
class NineBoxItem extends React.Component <WrappedComponentProps&MainStoreInjected&Props>{
  render() {
    let {dataObj, numberOfEmployees, loadPersonInfo, showInfo} = this.props;
    console.log(numberOfEmployees)
    return (
      <div className={"ninebox-container-item"} style={{border: "1px solid black"}}>
        {dataObj.length>0?<span style={{
          marginBottom: 5,
          textAlign: "center",
          fontSize: "115%",
          display:"block"
        }}>{ `${dataObj.length} (${(dataObj.length / numberOfEmployees * 100).toFixed(2)}%)`}</span>:null}
        {dataObj.slice(0, 5).map((el:any) => {
          return <a className={"ninebox-span"} onClick={(e) => {
            e.preventDefault();
            loadPersonInfo(el.personGroupId);
          }}>{el.employeeFullName}</a>
        })}
        {dataObj.length > 5 ? <a className={"ninebox-span"} onClick={(e) => {
          e.preventDefault();
          showInfo(dataObj)
        }}>{this.props.intl.formatMessage({id: "seeAll"})}</a> : ""}
      </div>
    );
  }
}


export default injectIntl(NineBoxItem)