import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { Competence } from "./tsadv$Competence";
import { DicTransferKnowledge } from "./tsadv_DicTransferKnowledge";
import { DicTypeOfTraining } from "./tsadv_DicTypeOfTraining";
import { DicLearningType } from "./tsadv$DicLearningType";
export class LearningRequest extends AbstractBprocRequest {
  static NAME = "tsadv_LearningRequest";
  personGroup?: PersonGroupExt | null;
  competence?: Competence | null;
  transferKnowledge?: DicTransferKnowledge | null;
  typeOfTraining?: DicTypeOfTraining | null;
  learningType?: DicLearningType | null;
  courseName?: string | null;
  numberOfDays?: number | null;
  numberOfHours?: number | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  courseDescription?: string | null;
  instruction?: string | null;
  costOfEducation?: any | null;
  livingCosts?: any | null;
  dailyLivingCosts?: any | null;
  travelCosts?: any | null;
  otherCosts?: any | null;
  totalCosts?: any | null;
  inTheCountry?: boolean | null;
  abroad?: boolean | null;
}
export type LearningRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "portal.learningRequest-edit";
export type LearningRequestView<
  V extends LearningRequestViewName
> = V extends "_base"
  ? Pick<
      LearningRequest,
      | "id"
      | "requestNumber"
      | "courseName"
      | "numberOfDays"
      | "numberOfHours"
      | "dateFrom"
      | "dateTo"
      | "courseDescription"
      | "instruction"
      | "costOfEducation"
      | "livingCosts"
      | "dailyLivingCosts"
      | "travelCosts"
      | "otherCosts"
      | "totalCosts"
      | "inTheCountry"
      | "abroad"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestDate"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      LearningRequest,
      | "id"
      | "courseName"
      | "numberOfDays"
      | "numberOfHours"
      | "dateFrom"
      | "dateTo"
      | "courseDescription"
      | "instruction"
      | "costOfEducation"
      | "livingCosts"
      | "dailyLivingCosts"
      | "travelCosts"
      | "otherCosts"
      | "totalCosts"
      | "inTheCountry"
      | "abroad"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<LearningRequest, "id" | "requestNumber">
  : V extends "portal.learningRequest-edit"
  ? Pick<
      LearningRequest,
      | "id"
      | "courseName"
      | "numberOfDays"
      | "numberOfHours"
      | "dateFrom"
      | "dateTo"
      | "courseDescription"
      | "instruction"
      | "costOfEducation"
      | "livingCosts"
      | "dailyLivingCosts"
      | "travelCosts"
      | "otherCosts"
      | "totalCosts"
      | "inTheCountry"
      | "abroad"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "personGroup"
      | "status"
      | "competence"
      | "learningType"
      | "transferKnowledge"
      | "typeOfTraining"
    >
  : never;
