import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import PersonEducationRequestEdit from "./PersonEducationRequestEdit";
import {observable} from "mobx";

type Props = RouteComponentProps<{entityId: string,addressId?:string}>;
interface defaultProps{
  isRequest?:boolean
}

@observer
export class PersonEducationRequestManagement extends React.Component<Props,defaultProps> {
  static PATH = "/PersonEducationRequest";
  static NEW_SUBPATH = "new";
  @observable defaultProps:defaultProps
  constructor(props:Props,defaultProps:defaultProps) {
    super(props,defaultProps);
    this.defaultProps = defaultProps
  }
  render() {
    const {entityId,addressId} = this.props.match.params;
    if(entityId!=="new"&&!addressId){
      this.defaultProps.isRequest = true;
    }
    return (
      <>
        <PersonEducationRequestEdit entityId={entityId} addressId={addressId} isRequest={this.defaultProps.isRequest}/>
    </>
  );
  }
}
