import React, {CSSProperties} from "react";
import {Button, Col, Row} from "antd";
import {CommentHierarchy, restServices} from "../../../../cuba/services";
import {action, observable} from "mobx";
import {observer} from "mobx-react";
import {RecognitionCommentPojo} from "../../../../cuba/entities/base/tsadv$RecognitionCommentPojo";
import CommentEnterComponent from "./CommentSendComponent";
import CommentElement from "./CommentElement";
import {FormattedMessage} from "react-intl";

type CommentComponentProps = {
  recognitionId: string
}


@observer
class CommentComponent extends React.Component<CommentComponentProps> {
  readonly FIRST_PAGE_SIZE = 2;
  readonly OTHER_PAGE_SIZE = 10;

  @observable comments: CommentHierarchy[] = [];
  @observable selectedCommentId?: string;
  @observable hasMore = false;
  @observable hasMoreChild = new Map<string, boolean>()

  componentDidMount() {
    this.loadParents();
  }

  @action loadParents = async () => {
    const limit = this.comments.length === 0
      ? this.FIRST_PAGE_SIZE
      : this.OTHER_PAGE_SIZE;

    const offset = this.comments.length;

    const result = await restServices.recognitionService.getRecognitionComments(this.props.recognitionId, limit, offset);
    this.hasMore = result.hasMore;

    this.comments = [
      ...this.comments,
      ...result.comments
    ];

    this.hasMoreChild = new Map([
      ...this.hasMoreChild.entries(),
      ...result.hasMoreChildren.entries()
    ]);
  }

  renderHighlightableComment = (comment: RecognitionCommentPojo, parentId: string|null) => {
    const style: CSSProperties = {
      backgroundColor: this.selectedCommentId === comment.id ? "#f0f0f0" : "",
    };

    const elementId = this.getDivIdByCommentId(comment.id!);

    return <div style={style} id={elementId}>
      <CommentElement
        onSelect={this.onSelect}
        onSend={(text) => this.sendComment(text, comment.id, parentId)}
        comment={comment}/>
    </div>
  }

  @action loadAllChild = async (parentId: string) => {
    const parent = this.comments.find(hierarchy => hierarchy.comment.id === parentId);

    if (!parent) {
      return;
    }

    parent.children = await restServices.recognitionService.getAllForParent(parentId);
    this.hasMoreChild.set(parentId, false);
  }

  renderHierarchy = (hierarchy: CommentHierarchy) => {
    const parentCommentId = hierarchy.comment.id;
    const hasMoreHidden: boolean = !this.hasMoreChild.get(parentCommentId);
    const renderHighlightableComment = (comment: RecognitionCommentPojo) => this.renderHighlightableComment(comment, parentCommentId);

    return <>
      {this.renderHighlightableComment(hierarchy.comment, null)}
      <Row type={"flex"} justify={"end"}>
        <Col xs={23}>
          {hierarchy.children && hierarchy.children.map(renderHighlightableComment)}
          <Row type={"flex"} justify={"center"}>
            <Button type={"link"}
                    hidden={hasMoreHidden}
                    onClick={() => this.loadAllChild(parentCommentId!)}
            >
              <FormattedMessage id={"recognition.comment.loadAll"}/>
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  }

  @action onSelect = (commentId: string) => {
    this.selectedCommentId = commentId;
    const commentDiv = document.getElementById(this.getDivIdByCommentId(commentId));

    if (commentDiv) {
      commentDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }

  getDivIdByCommentId(commentId: string) {
    return "comment-" + commentId;
  }

  @action sendComment = async (commentText: string, parentCommentId: string|null = null, parentInHierarchy: string|null = null) => {
    const newComment = await restServices.recognitionService.sendComment(this.props.recognitionId, commentText, parentCommentId);
    this.addNewCommentInRequiredPosition(parentCommentId, newComment, parentInHierarchy);
  }

  addNewCommentInRequiredPosition(parentCommentId: string | null, newComment: RecognitionCommentPojo, parentInHierarchy: string | null) {
    if (parentCommentId) {
      this.ifHasParent(parentCommentId, newComment, parentInHierarchy);
    } else {
      this.ifHasNotParent(newComment);
    }
  }

  ifHasNotParent = (newComment: RecognitionCommentPojo) => {
    const hierarchy: CommentHierarchy = {
      comment: newComment,
      children: []
    }

    this.comments = [hierarchy, ...this.comments];
  }

  ifHasParent = (parentCommentId: string, newComment: RecognitionCommentPojo, parentInHierarchy: string | null)=> {
    const parent = this.comments.find(hierarchy => hierarchy.comment.id === parentCommentId);

    if (parent) {
      parent.children = [newComment, ...parent.children!];
    } else {
      const root = this.comments.find(hierarchy => hierarchy.comment.id === parentInHierarchy)!;
      root.children = [newComment, ...root.children!];
    }
  }

  render() {
    return <>
      {this.comments.map(this.renderHierarchy)}
      <Row type={"flex"} justify={"center"}>
        <Button type={"link"} hidden={!this.hasMore} onClick={this.loadParents}>
          <FormattedMessage id={"recognition.comment.loadMore"}/>
        </Button>
      </Row>
      <div style={{padding: '10px', backgroundColor: '#eeeeee', marginTop: "10px"}}>
        <CommentEnterComponent onSend={this.sendComment}/>
      </div>
    </>
  }

}

export default CommentComponent;