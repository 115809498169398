import { AbstractParentEntity } from "./AbstractParentEntity";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { LearningExpense } from "./tsadv$LearningExpense";
import { CourseScheduleEnrollment } from "./tsadv_CourseScheduleEnrollment";
export class PersonLearningContract extends AbstractParentEntity {
  static NAME = "tsadv$PersonLearningContract";
  personGroup?: PersonGroupExt | null;
  learningExpense?: LearningExpense[] | null;
  contractNumber?: string | null;
  contractDate?: any | null;
  contractEndDate?: any | null;
  termOfService?: any | null;
  other?: string | null;
  courseScheduleEnrollment?: CourseScheduleEnrollment | null;
}
export type PersonLearningContractViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personLearningContract-for-learninexpense"
  | "personLearningContract.edit";
export type PersonLearningContractView<
  V extends PersonLearningContractViewName
  > = V extends "_base"
  ? Pick<
    PersonLearningContract,
    | "id"
    | "contractNumber"
    | "contractDate"
    | "contractEndDate"
    | "termOfService"
    | "other"
    | "legacyId"
    | "organizationBin"
    | "integrationUserLogin"
    >
  : V extends "_local"
    ? Pick<
      PersonLearningContract,
      | "id"
      | "contractNumber"
      | "contractDate"
      | "contractEndDate"
      | "termOfService"
      | "other"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      >
    : V extends "personLearningContract-for-learninexpense"
      ? Pick<
        PersonLearningContract,
        "id" | "personGroup" | "contractNumber" | "contractDate" | "termOfService"
        >
      : V extends "personLearningContract.edit"
        ? Pick<
          PersonLearningContract,
          | "id"
          | "contractNumber"
          | "contractDate"
          | "contractEndDate"
          | "termOfService"
          | "other"
          | "legacyId"
          | "organizationBin"
          | "integrationUserLogin"
          | "personGroup"
          | "courseScheduleEnrollment"
          >
        : never