import * as React from "react";
import { FormEvent, FunctionComponent } from "react";
import {
  Button,
  Card,
  Form,
  message,
  Select,
  Checkbox,
  Spin
} from "antd";

import { inject, observer } from "mobx-react";

import { FormComponentProps } from "antd/lib/form";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { IReactionDisposer, observable } from "mobx";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  instance,
  withLocalizedForm,
  extractServerValidationErrors,
  constructFieldsWithErrors,
  clearFieldErrors,
  collection,
  injectMainStore,
  MainStoreInjected,
  Msg,
  Instance, getCubaREST
} from "@cuba-platform/react";

import "../../../app/App.css";

import { Concourse } from "../../../cuba/entities/base/tsadv_Concourse";
import { RootStoreProp } from "../../store";

import { MarkCriteria } from "../../../cuba/entities/base/tsadv_MarkCriteria";
import TextArea from "antd/es/input/TextArea";
import { GradeDetail } from "../../../cuba/entities/base/tsadv_GradeDetail";
import { DataInstanceStore } from "@cuba-platform/react/dist/data/Instance";


import moment from "moment";
import { ConcourseRequest } from "../../../cuba/entities/base/tsadv_ConcourseRequest";

import {Levels} from "../../../cuba/entities/base/tsadv_Levels";
import Section from "../../hoc/Section";
import Page from "../../hoc/PageContentHoc";
import {GradeDetailAnswer} from "../../../cuba/entities/base/tsadv_GradeDetailAnswer";

const { Option } = Select;

type Props = FormComponentProps & EditorProps;

type EditorProps = {
  markCriteria: MarkCriteria[] | null | undefined;
  setTotalGrade: Function;
  dataInstance: DataInstanceStore<ConcourseRequest>;
  personGroupId?: any;
  updated: boolean;
};

type ActiveTabProps = RouteComponentProps<{ activeTab?: string }>;

interface IState {
  data: number;
}

@injectMainStore
@inject("rootStore")
@observer
class GradeFormComponent extends React.Component<
  Props &
    WrappedComponentProps &
    ActiveTabProps &
    MainStoreInjected &
    RootStoreProp &
    RouteComponentProps<any>,
  IState
> {
  dataInstance = this.props.dataInstance;

  gradeInstance = instance<GradeDetail>(GradeDetail.NAME, {
    view: "gradeDetail-view",
    loadImmediately: false
  });


  concoursesDsc = collection<Concourse>(Concourse.NAME, {
    view: "concourse-view",
    filter: {
      conditions: [
        {
          value: this.dataInstance.item && this.dataInstance.item!.concourse! && this.dataInstance.item!.concourse!.id,
          operator: "=",
          property: "id"
        }
      ]
    }
  });

  gradeDataCollection = collection<GradeDetail>(GradeDetail.NAME, {
    view: "gradeDetail-view",
    filter: {
      conditions: [
        {
          value: this.props.personGroupId,
          operator: "=",
          property: "personGroup.id"
        },
        {
          value: this.dataInstance.item && this.dataInstance.item!.id,
          operator: "=",
          property: "concourseRequest.id"
        }
      ]
    }
  });



  @observable
  updated = this.props.updated;
  reactionDisposer: IReactionDisposer;

  @observable
  goBack = false;

  createElement = React.createElement;

  @observable
  globalErrors: string[] = [];

  pageName: string = "concourseManagement";

  optionValue: object = {};

  checkboxValue: object = {};

  checkBx: object = {};

  @observable
  personGroupId: any = this.props.personGroupId;

  @observable
  comment: string = "";
  isDisabled: boolean = false;

  @observable
  mainStore = this.props.mainStore!

  // handleChangeOption = (name: string, val: any) => {
  //   let [item, value] = val.split("$");
  //   console.log("FROM SCALE CHANGE", name)
  //   this.optionValue[name] = {
  //     [item]: value
  //   };
  //   console.log("SCALE CHANGE: ",this.optionValue[name])
  // };

  handleChangeOption = (item: string, name:string, val: any) => {
    // let [name, value] = val.split("$");
    console.log("FROM SCALE CHANGE", name)
    this.optionValue[item] = [name, val]
    console.log("SCALE CHANGE: ",this.optionValue)
  };

  handleChangeCheckbox = (item: string, name: string, value: boolean, scale:any,id:string) => {

    this.checkboxValue[item] = {
      ...this.checkboxValue[item],
      [name]: [value?value:false, value ? scale : 0]
    };
    if(value){
      this.changedRequired[id] = {
        ...this.changedRequired[id],
      }
    }
    else{
      Object.keys(this.changedRequired).forEach((item:any)=>{
        if(item===id){
          delete this.changedRequired[id]
        }
      })
    }
  };

  handleChangeComment = (e: any) => {
    this.comment = e.target.value;
  };
  @observable changedRequired = {};
  totalGradeHandler = (localSum: number) => {
    let sum = 0;
    sum += localSum;
    this.props.setTotalGrade(sum);
  };

  @observable
  innerHtml: string = "";

  @observable
  sumOfTotalGrade:number = 0;

  @observable gradeId:string
  submitForm(values1: object, values2: object) {

    let sum = 0;
    if (values1) {
      for (const property in values1) {
        if (values1.hasOwnProperty(property)) {

          if (values1[property] && values1[property].length) {
            console.log("scale", values1[property][0])
            sum += +(values1[property][1]);
          }

        }
      }
      console.log("Scale", sum)
    }
    if (values2) {
      for (const property in values2) {
        if (values2.hasOwnProperty(property)) {
          for (const val in values2[property]) {
            if (values2[property].hasOwnProperty(val))
              if (values2[property][val] && values2[property][val].length){
                console.log("check",values2[property][val])
                sum += +(values2[property][val][1]);}
          }
        }
      }
      console.log("Check", sum)
    }

    let grade = this.gradeDataCollection.items;
    const indicators = (checkboxValue: any, optionValue: any) => {
      let html = `
          <form class="ant-form ant-form-horizontal" style="width: 100%;">
                    <div class="ant-card generalInfo ant-card-bordered ant-card-small" style="padding: 5px 20px 20px;">
                        <div class="ant-card-body">
      `;
      if (checkboxValue)
        for (let key in checkboxValue) {
          if (checkboxValue.hasOwnProperty(key)) {

            html += `
              <div class="ant-row ant-form-item" style="margin-top: 24px;">
                  <div class="ant-col ant-form-item-label">
                      <label class="ant-form-item-required" title="">${key}</label>
                  </div>
              `;
            for (let values in checkboxValue[key])
              if (checkboxValue[key].hasOwnProperty(values))
                html += `
                  <span class="ant-form-item-children">
                      <div class="ant-row ant-form-item default-form-item " style="display: flex; align-items: center; margin: 0px 0px 0px 0px; width: 80%; ">
                          <div class="ant-col ant-form-item-control-wrapper">
                              <div class="ant-form-item-control">
                                  <span class="ant-form-item-children">
                                      <label class="ant-checkbox-wrapper">
                                          <span  class="ant-checkbox ${
                                  checkboxValue[key][values.toString()] && checkboxValue[key][values.toString()][0]
                                              ? "ant-checkbox-checked"
                                              : ""
                                          }">
                                              <input disabled type="checkbox" class="ant-checkbox-input" value="">
                                              <span class="ant-checkbox-inner"></span>
                                          </span>
                                      </label>
                                  </span>
                              </div>
                          </div>
                          <div class="ant-col ant-form-item-label" >
                              <label class="" title="">${values}</label>
                          </div>
                      </div>
                  </span>`;
          }
          html += `</div>`;
        }

      if (optionValue) {
        for (let key in optionValue) {
          if (optionValue.hasOwnProperty(key)) {
            html += `
              <div class="ant-row ant-form-item" style="margin-top: 24px;">
                  <div class="ant-col ant-form-item-label" >
                      <label class="ant-form-item-required" title="">${key}</label>
                  </div>
              `;


                html += `
                        <div class="ant-col ant-form-item-control-wrapper">
                            <div class="ant-form-item-control">
                                <span class="ant-form-item-children">
                                    <div class="ant-select ant-select-enabled ant-select-allow-clear" style="width: 80%;">
                                        <div class="ant-select-selection ant-select-selection--single" role="combobox" aria-autocomplete="list" aria-haspopup="true" aria-controls="80c4bf7f-823b-44a5-93fe-83b3d92a612b" aria-expanded="false" tabindex="0">
                                          <div class="ant-select-selection__rendered">
                                              <div disabled class="ant-select-selection-selected-value" title="${optionValue[key] && optionValue[key][0]}" style="display: block; opacity: 1;">
                                                ${optionValue[key][0]}
                                              </div>
                                          </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>`;

          }
          html += `</div>`;
        }
      }

      html += `
      <div class="ant-row ant-form-item" style="width: 80%;">
          <div class="ant-col ant-form-item-label">
              <label for="Comments" class="ant-form-item-required" title="">
                  ${
                    this.props.mainStore!.locale === "ru"
                      ? "Комментарии"
                      : "Comment"
                  }
              </label>
          </div>
          <div class="ant-col ant-form-item-control-wrapper">
              <div class="ant-form-item-control">
                  <span class="ant-form-item-children">
                      <textarea disabled rows="6" id="Comments" data-__meta="[object Object]" data-__field="[object Object]" class="ant-input">${
                        this.comment
                      }</textarea>
                  </span>
              </div>
          </div>
      </div>
      
      `;

      html += `</div> </div> </form> `;

      return html;
    };

    if (grade.length) {

      Object.keys(this.changedRequired).forEach((item:any)=>{
        getCubaREST()!.commitEntity<GradeDetailAnswer>(GradeDetailAnswer.NAME,{
          gradeDetail:{
            id:this.gradeId!
          },
          indicator:{
            id:item
          }
        })
      })

      console.log("if", this.sumOfTotalGrade+sum)
      this.gradeInstance
        .update({
          id: grade[0].id,
          personGroup: this.props.personGroupId,
          concourseRequest: {
            id: this.dataInstance.item!.id,
            totalGrade:(sum + this.sumOfTotalGrade),
          },
          comment: this.comment,
          grade: sum,
          indicatorsList: indicators(this.checkBx, this.optionValue)
        })
        .then(data => {
          message.success(
            this.props.intl.formatMessage({ id: "management.editor.success" })
          );
          this.totalGradeHandler((sum + this.sumOfTotalGrade))
          // this.updated = true
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.error"
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({ id: "management.editor.error" })
            );
          }
        });
    } else {
      this.innerHtml = indicators(this.checkboxValue, this.optionValue);

      Object.keys(this.changedRequired).forEach((item:any)=>{
        getCubaREST()!.commitEntity<GradeDetailAnswer>(GradeDetailAnswer.NAME,{
          gradeDetail:{
            id:this.gradeId!
          },
          indicator:{
            id:item
          }
        })
      })
      this.gradeInstance
        .update({
          personGroup: this.props.personGroupId,
          concourseRequest: {
            id: this.dataInstance.item!.id,
            totalGrade:(sum + this.sumOfTotalGrade),

          },
          comment: this.comment,
          grade: sum,
          indicatorsList: indicators(this.checkBx, this.optionValue)
        })
        .then((data:GradeDetail) => {
          Object.keys(this.changedRequired).forEach((item:any)=>{
            console.log(item)
            getCubaREST()!.commitEntity<GradeDetailAnswer>(GradeDetailAnswer.NAME,{
              gradeDetail:{
                id:data && data.id
              },
              indicator:{
                id:item
              }

            })
          })
          message.success(
            this.props.intl.formatMessage({ id: "management.editor.success" })
          );
          console.log("SUM", sum)
          console.log("SUM",)
          console.log(indicators(this.checkboxValue, this.optionValue))

          this.totalGradeHandler((sum + this.sumOfTotalGrade));
          // this.updated = true
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.error"
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({ id: "management.editor.error" })
            );
          }
        });
    }
  }

  handleSubmit = (e: FormEvent) => {
    this.checkBx= {...this.checkboxValue}
    e.preventDefault();
    this.dataInstance.status = "LOADING";
    this.submitForm(this.optionValue, this.checkBx);
  };

  checkDates = () => {
    if (this.concoursesDsc.items[0]) {
      let dateNow = moment(Date.now());
      let requestDate = moment(this.concoursesDsc.items[0]!.endVoting);

      if (dateNow.isAfter(requestDate)) {
        this.isDisabled = true;
      }
    }
  };

  checkGradeExists = () => {
    let grade = this.gradeDataCollection.items;
    if (grade.length) {
      this.isDisabled = true;
    }
  };

  render() {
    if (this.updated) {
      return <Redirect exact={true} to={"/concourse/4"} />;
    }

    if (this.goBack) {
      return <Redirect exact={true} to={"/concourse/4"} />;
    }

    let sumOfTotalGrade = 0
    console.log(this.dataInstance)
    console.log(this.gradeInstance)

    console.log(sumOfTotalGrade)
    const activeTab = "1";
    const defaultActiveKey = activeTab ? activeTab : "1";
    const messages = this.mainStore.messages!;

    const isRu = this.props.mainStore!.locale === "ru";

    const { status } = this.dataInstance;

    this.checkDates();
    this.checkGradeExists();
    console.log(this.checkboxValue)
    return this.isDisabled && this.gradeDataCollection.items[0] ? (
      [
        <Page>
          <Section size={"large"}>
        <span
          dangerouslySetInnerHTML={{
            __html: this.gradeDataCollection.items![0].indicatorsList!
          }}
        />
          </Section>
        </Page>,
        <Button
          htmlType="button"
          onClick={() => (this.goBack = true)}
          style={{ marginTop: 24, textTransform: "capitalize" }}
        >
          <FormattedMessage id="back" />
        </Button>
      ]
    ) : (
      <Page>
      <Form layout="vertical" className={"concoureRequest_form"} onSubmit={this.handleSubmit}>
        <Spin spinning={status == "LOADING"}>
          <Section size={"large"}>
          <Card
            size="small"
            className="generalInfo"
            style={{ padding: "5px 20px 20px" }}
          >
            {this.props.markCriteria &&
              this.props.markCriteria.map((el: MarkCriteria, markIndex) => {
                if (el.indicator && el.indicator_relation) return (
                  <Form.Item
                    key={isRu ? el.name_ru : el.name_en}
                    style={{marginTop: "24px", width: "80%"}}
                    required={true}
                    label={this.createElement(Msg, {
                      entityName: "tsadv_markCriteria",
                      propertyName: isRu ? el.name_ru : el.name_en
                    })}

                  >
                    {el.indicator_relation!.map(chk =>{
                      if (chk && isRu && this.checkboxValue[el.name_ru!] && this.checkboxValue[el.name_ru!][chk.name_ru!] && !this.checkboxValue[el.name_ru!][chk.name_ru!][0])
                        this.checkboxValue[el.name_ru!] = {

                          [chk.name_ru!]:[false, 0]
                        }
                      else if (chk && !isRu && this.checkboxValue[el.name_en!] && this.checkboxValue[el.name_en!][chk.name_en!] && !this.checkboxValue[el.name_en!][chk.name_en!][0] )
                        this.checkboxValue[el.name_en!] = {

                          [chk.name_en!]:[false, 0]
                        }
                      return (chk &&
                      <Form.Item
                        key={chk.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "0",
                          marginLeft: "8px",
                          flexDirection:"row-reverse"
                        }}
                        className={"default-form-item"}
                        label={this.createElement(Msg, {
                          entityName: "tsadv_markCriteria",
                          propertyName: isRu ? chk.name_ru : chk.name_en
                        })}
                      >
                        <Checkbox
                          disabled={this.isDisabled}
                          onChange={value => {
                            if (value) {

                              this.handleChangeCheckbox(
                                isRu ? el.name_ru! : el.name_en!,
                                isRu ? chk.name_ru! : chk.name_en!,
                                value.target.checked,
                                chk.value,
                                chk.id
                              )
                            }
                          }
                          }
                        />
                      </Form.Item>
                    )})}
                  </Form.Item>
                )

                return !el.indicator && el.ratingScale && (
                  <Form.Item
                    key={isRu ? el.name_ru! : el.name_en!}
                    style={{marginTop: "24px"}}
                    label={this.createElement(Msg, {
                      entityName: "tsadv_markCriteria",
                      propertyName: isRu ? el.name_ru : el.name_en
                    })}
                  >{this.props.form.getFieldDecorator(el.name_en!, {
                    rules: [
                      {
                        required: true,
                        message: this.props.intl.formatMessage(
                          {id: "form.validation.required"},
                          {
                            fieldName:
                              messages["tsadv_markCriteria" + el.name_en!]
                          }
                        )
                      }
                    ],
                  })
                  (
                    <Select
                      disabled={this.isDisabled}
                      id={el.ratingScale!.id}
                      // value={this.optionValue[el.ratingScale!.id]}
                      onSelect={ value => {
                        if (value) {
                          console.log("SELECTED", value)
                          const lvl = el.ratingScale!.level_relation!.filter((el) => el.id === value)[0]
                          const lvlName = isRu ? lvl.name_ru! : lvl.name_en!
                          const scaleName = isRu ?el.name_ru:el.name_en
                          // console.log(lvlName)
                          if (scaleName)
                          this.handleChangeOption(scaleName,
                            lvlName,
                            lvl.number
                          )
                        }
                      }}
                      allowClear={true}
                      placeholder={"....."}
                      style={{width: "80%"}}
                    >
                      {/*TODO set value as ID of level and use it to search in array*/}
                      {el.ratingScale!.level_relation!.map((lvl: Levels, idx) => (
                          <Option
                            key={
                              lvl.id}
                            value={lvl.id}
                          >
                            {isRu ? lvl.name_ru : lvl.name_en!}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                  </Form.Item>
                )
              })}
            <Form.Item
              style={{ width: "80%" }}
              label={this.createElement(Msg, {
                entityName: "tsadv_markCriteria",
                propertyName: isRu ? "Комментарии" : "Comments"
              })}
              required={true}
            >
              {this.props.form.getFieldDecorator("comments", {
                rules: [
                  {
                    required: true,
                    message: this.props.intl.formatMessage(
                      { id: "form.validation.required" },
                      {
                        fieldName:
                          messages["tsadv_markCriteria" + ".comments"]
                      }
                    )
                  }
                ],
              })(
                <TextArea
                  disabled={this.isDisabled}
                  value={this.comment}
                  onChange={this.handleChangeComment}
                  required={true}
                  rows={6}
                />
              )}
            </Form.Item>
            <Form.Item style={{ textAlign: "left", marginTop: "36px" }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.isDisabled}
                style={{ marginRight: "8px" }}
              >
                <FormattedMessage id="management.editor.submit" />
              </Button>

              <Button
                htmlType="button"
                onClick={() => (this.goBack = true)}
                style={{ textTransform: "capitalize" }}
              >
                <FormattedMessage id="management.editor.cancel" />
              </Button>
            </Form.Item>
            {/*{*/}
            {/*  this.innerHtml.length &&  <span dangerouslySetInnerHTML={{__html:this.innerHtml}}>*/}
            {/*  </span>*/}
            {/*}*/}
          </Card>
          </Section>
        </Spin>
      </Form>
      </Page>
    );
  }

  componentDidMount() {
    this.gradeInstance.setItem(new GradeDetail());
    getCubaREST()!.searchEntities<GradeDetail>(GradeDetail.NAME,{
      conditions: [
        {
          value: this.dataInstance.item && this.dataInstance.item!.id,
          operator: "=",
          property: "concourseRequest.id"
        }
      ]
    },{
      view: "gradeDetail-view"
    }).then(value => {
      value.forEach(item=>{
        this.gradeId = item.id!
        this.sumOfTotalGrade += item.grade!
      })
    })

  }

  componentWillUnmount() {}
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
      if (changedValues["comments"]!==null) props.form.validateFields(['comments'], {force: true});
    }
  })(GradeFormComponent)
);
