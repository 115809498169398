import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Page from "../../../hoc/PageContentHoc";
import RecognitionPage from "./RecognitionPage";


class RecognitionPageManager extends React.Component<WrappedComponentProps> {

  render() {
    return <Page pageName={this.props.intl.formatMessage({id: "recognition.recognitions.name"})}>
        <RecognitionPage/>
    </Page>
  }
}

export default injectIntl(RecognitionPageManager);