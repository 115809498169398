import { AbstractDictionary } from "./AbstractDictionary";
import { DicCompetenceAssessmentType } from "./tsadv_DicCompetenceAssessmentType";
import { CompetenceTemplateDetail } from "./tsadv$CompetenceTemplateDetail";
import { CompetenceTemplateParticipant } from "./tsadv_CompetenceTemplateParticipant";
export class CompetenceTemplate extends AbstractDictionary {
  static NAME = "tsadv$CompetenceTemplate";
  usePositionCompetence?: boolean | null;
  assessmentType?: DicCompetenceAssessmentType | null;
  positionCompetenceWeight?: number | null;
  competenceTemplateDetail?: CompetenceTemplateDetail[] | null;
  competenceTemplateName?: string | null;
  participants?: CompetenceTemplateParticipant[] | null;
  useOrganizationProfile?: boolean | null;
}
export type CompetenceTemplateViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "competenceTemplate-view";
export type CompetenceTemplateView<
  V extends CompetenceTemplateViewName
> = V extends "_base"
  ? Pick<
      CompetenceTemplate,
      | "id"
      | "langValue"
      | "usePositionCompetence"
      | "positionCompetenceWeight"
      | "competenceTemplateName"
      | "useOrganizationProfile"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_local"
  ? Pick<
      CompetenceTemplate,
      | "id"
      | "usePositionCompetence"
      | "positionCompetenceWeight"
      | "competenceTemplateName"
      | "useOrganizationProfile"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_minimal"
  ? Pick<CompetenceTemplate, "id" | "langValue">
  : V extends "competenceTemplate-view"
  ? Pick<
      CompetenceTemplate,
      | "id"
      | "usePositionCompetence"
      | "positionCompetenceWeight"
      | "competenceTemplateName"
      | "useOrganizationProfile"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
      | "assessmentType"
      | "competenceTemplateDetail"
      | "participants"
      | "company"
    >
  : never;
