import * as React from "react";
import {createElement} from "react";
import {inject, observer} from "mobx-react";
import {FormComponentProps} from "antd/lib/form";
import {Link, RouteComponentProps} from "react-router-dom";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

import {
  collection,
  getCubaREST,
  injectMainStore,
  instance,
  MainStoreInjected,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../app/App.css";
import { ReadonlyField } from "../../components/ReadonlyField";
import Section from "../../hoc/Section";
import Page from "../../hoc/PageContentHoc";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import { AssignmentSalaryRequest } from "../../../cuba/entities/base/tsadv$AssignmentSalaryRequest";
import { DicRequestStatus } from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import { DicWCCFType } from "../../../cuba/entities/base/tsadv_DicWCCFType";
import FormContainer from "../../common/FormContainer";
import {action, observable, when} from "mobx";
import {restServices} from "../../../cuba/services";
import moment from "moment";
import DefaultDatePicker from "../../components/Datepicker";
import { PersonExt } from "../../../cuba/entities/base/base$PersonExt";
import TextArea from "antd/es/input/TextArea";
import { OrganizationGroup } from "../../../cuba/entities/base/base$OrganizationGroup";
import { GradeGroup } from "../../../cuba/entities/base/tsadv$GradeGroup";
import Button, { ButtonType } from "../../components/Button/Button";
import { runReport } from "../../util/reportUtil";
import { WccfManagement } from "./WccfManagement";
import { JSON_DATE_TIME_FORMAT } from "../../util/Date/Date";
import { withRouter } from "react-router";
import { RootStoreProp } from "../../store";
import { parseToJsonFromFieldValue } from "../../components/MultiFileUpload";
import { HrRequestManagerManagement } from "../HrRequestManager/HrRequestManagerManagement";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import { PositionGroupExt } from "../../../cuba/entities/base/base$PositionGroupExt";
import { JobDescription } from "../../../cuba/entities/base/tsadv_JobDescription";
import { downloadFile } from "../../util/util";
import Notification from "../../util/Notification/Notification";
import WccfMenuPage from "./WccfMenuPage";
import {serviceCollection} from "../../util/ServiceDataCollectionStore";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {instanceStore} from "../../util/InstanceStore";
import {DataCollectionStore} from "@cuba-platform/react/dist/data/Collection";
import {OrganizationGroupExt} from "../../../cuba/entities/base/base$OrganizationGroupExt";
import {getPersonByPersonGroup} from "../../util/personUtils";
import {Card, Col, Form, Icon, Input, Modal, Row, Spin} from "antd";

type Props = FormComponentProps & EditorProps;

type EditorProps = {
  entityId: string;
};

export class AssignmentSalaryPojo {
  hireDate: any;
  positionName: string;
  organizationName: string;
  gradeName: string;
  positionGroupId: string;
  organizationGroupId: string;
  gradeGroupId: string;
  salary: number;
  currencyId?: string;
}

@injectMainStore
@inject("rootStore")
@observer
class WccfEdit extends AbstractBprocEdit<AssignmentSalaryRequest, Props> {
  dataInstance = instanceStore<AssignmentSalaryRequest>(
    AssignmentSalaryRequest.NAME,
    {
      view: "portal-assignmentSalaryRequest-edit",
      loadImmediately: false
    }
  );

  processDefinitionKey = "assignmentSalaryRequest";

  path = WccfManagement.PATH;

  isUpdateBeforeOutcome = true;

  personGroupId: string;

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  dateDc = collection<AssignmentSalaryRequest>(AssignmentSalaryRequest.NAME, {
    view: "portal-assignmentSalaryRequest-edit",
    filter: {
      conditions: [
        {
          property: "personGroup.id",
          operator: "=",
          value: this.props.rootStore!.userInfo!.personGroupId!
        }
      ]
    }
  });

  wccfTypesDc = collection<DicRequestStatus>(DicWCCFType.NAME, {
    view: "_local"
  });

  positionGroupDc = collection<PositionGroupExt>(PositionGroupExt.NAME, {
    view: "positionGroup.list",
    loadImmediately: false
  });

  @observable
  organizationGroupDc: DataCollectionStore<OrganizationGroup>;

  organInstance = instance<OrganizationGroup>(OrganizationGroup.NAME, {
    view: "_minimal",
    loadImmediately: false
  });
  gradeGroupDc = collection<GradeGroup>(GradeGroup.NAME, { view: "_minimal" });

  @observable
  personsDc: DataCollectionStore<PersonGroupExt>;

  salaryVisibleRoles = [
    "c_and_b_company_task",
    "c_and_b_group_task",
    "super_c_and_b_group_task",
    "super_c_and_b_group_task",
    "hra_task",
    "hr_manager_task",
    "cmb_group_task",
    "personal_director_group_task"
  ];

  @observable
  assignmentSalaryOldValue: AssignmentSalaryPojo;

  @observable
  hasVacation = true;

  fields = [
    "requestNumber",

    "requestDate",

    "status",

    "wccfType",

    "planDate",

    "dateTo",

    "actualDate",

    "personGroup",

    "positionGroup",

    "jobGroup",

    "organizationGroup",

    "gradeGroup",

    "amount",

    "changePercent",

    "currency",

    "salaryComment",

    "comment",

    "files"
  ];

  @observable
  isPositionChange = false;

  @observable
  isSalaryChange = false;

  @observable
  isActaualDateChange = false;

  @observable
  loadingReportJobInstruction = false;

  @observable
  loadingReportEmployeeProfile = false;

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  isCbCompany: boolean = false;

  @observable
  isCbGroup: boolean = false;

  @observable
  isCbGroupManager: boolean = false;

  @observable
  isCmBGroupManager: boolean = false;

  @observable
  isHR: boolean = false;

  @observable
  isHrManager: boolean = false;

  @observable
  isPersonalDirectorGroup: boolean = false;

  @observable
  wccfTypeCodeValue?: string | null = "";

  @observable newPosFTE:number|undefined;

  @action setNewPosFTE = (value:number) => this.newPosFTE = value

  onChangeWccfSelectType = (e: any) => {
    this.wccfTypesDc!.items!.filter(item =>
      item.id === e ? (this.wccfTypeCodeValue = item.code) : false
    );
  };

  getUpdateEntityData = (): any => {
    const visibleSalary =
      this.isSalaryChange &&
      (this.isCbCompany ||
        this.isCbGroup ||
        this.isCbGroupManager ||
        this.isCmBGroupManager);

    let fieldNames = [
      "requestNumber",
      "requestDate",
      "status",
      "wccfType",
      "planDate",
      "dateTo",
      "personGroup",
      "positionGroup",
      "organizationGroup",
      "gradeGroup",
      "currency",
      "comment",
      "actualDate",
      "files"
    ];

    if (visibleSalary)
      fieldNames.push("amount", "changePercent", "salaryComment", "currency");

    const obj = {
      ...this.props.form.getFieldsValue(fieldNames)
    };

    const metaClass = this.props.mainStore!.metadata!.find(
      mci => mci.entityName === this.dataInstance.entityName
    );
    if (metaClass) {
      metaClass.properties
        .filter(value => value.type === "sys$FileDescriptor")
        .filter(
          value =>
            value.cardinality === "ONE_TO_MANY" ||
            value.cardinality === "MANY_TO_MANY"
        )
        .filter(value => fieldNames.find(field => field === value.name))
        .forEach(value => {
          const files = obj[value.name];
          if (files) obj[value.name] = parseToJsonFromFieldValue(files);
        });
    }

    if (this.isNewEntity() && this.assignmentSalaryOldValue) {
      obj["positionGroup"] = this.assignmentSalaryOldValue.positionGroupId;
      obj[
        "organizationGroup"
      ] = this.assignmentSalaryOldValue.organizationGroupId;
      obj["gradeGroup"] = this.assignmentSalaryOldValue.gradeGroupId;
    }
    obj["currency"] = this.assignmentSalaryOldValue.currencyId;

    return obj;
  };

  getBtns = () => {
    const { status } = this.dataInstance;
    const isDisabledFields = this.isNotDraft();

    const bprocButtons = [];
    if (!isDisabledFields)
      bprocButtons.push(
        <Button
          buttonType={ButtonType.PRIMARY}
          disabled={status !== "DONE" && status !== "ERROR"}
          loading={status === "LOADING"}
          onClick={this.saveRequest}
        >
          <Icon type="check" />
          <FormattedMessage id="management.editor.submit" />
        </Button>
      );

    bprocButtons.push(
      <Link to={HrRequestManagerManagement.PATH}>
        <Button buttonType={ButtonType.FOLLOW}>
          <Icon type="close" />
          <FormattedMessage id="management.editor.cancel" />
        </Button>
      </Link>
    );

    if (!this.isNewEntity()) bprocButtons.push(this.getOutcomeBtnsWccf(true));

    return bprocButtons;
  };
  @observable
  selectedOrganizationGroup =
    this.assignmentSalaryOldValue &&
    this.assignmentSalaryOldValue.organizationGroupId;
  @observable isLoadingReporteEn:boolean = false
  @observable isLoadingReportRu:boolean = false
  loadPersonInfo = (localeProp:string) => {
    try {
      localeProp === "en" ?  this.isLoadingReporteEn = true : this.isLoadingReportRu = true
      console.log(this.isLoadingReportRu)
      let data = {}
      let locale:string = localeProp === "en"?"PROFILE_TD_EN":"PROFILE_TD_RU"
      getPersonByPersonGroup(this.dataInstance.item!.personGroup!.id).then((res: any) => data = {
        parameters: [{
          name: "person",
          value: res.id
        }]
      }).then(() =>
        runReport(locale, data, this.props.intl).then(()=>{
          localeProp === "en" ?  this.isLoadingReporteEn = false : this.isLoadingReportRu = false
          console.log(this.isLoadingReportRu)
        }))
    }
    catch (e) {
      localeProp === "en" ?  this.isLoadingReporteEn = false : this.isLoadingReportRu = false
      Notification.error({
        message: this.props.intl.formatMessage({
          id: 'adaptationPlan.employee_profile.NotFound',
          defaultMessage: 'Employee Profile Not Found!',
        }),
      });
    }
  }



  info = ()=> {

    Modal.info({
      title: this.props.intl.formatMessage({id:"myTeam-personData.choosen"}),
      content: (
        <div>
          {this.isLoadingReportRu}
          <Button className={"buttonWccfReport"} loading={this.isLoadingReportRu}  style={{marginRight:"14px",color:"black"}}  shape="round" icon="download" onClick={()=>this.loadPersonInfo("ru")}>
            Ru
          </Button>
          <Button className={"buttonWccfReport"} loading={this.isLoadingReporteEn}   shape="round" icon="download" onClick={()=>this.loadPersonInfo("en")}>
            En
          </Button>
        </div>
      ),
      onOk() {},
    });
  }
  onChangeOrganization = (e: any) => {
    this.selectedOrganizationGroup = e;
    this.positionGroupDc.filter = {
      conditions: [
        {
          property: "organizationGroup.id",
          operator: "=",
          value: this.selectedOrganizationGroup!
        }
      ]
    };

    this.positionGroupDc.load();

    when(()=>this.positionGroupDc.status==="DONE"&&this.positionGroupDc.items.length>0,()=>this.positionGroupDc.items = this.positionGroupDc.items.filter(i=>i.position&&i.position.positionStatus&&i.position.positionStatus.code==="ACTIVE"))

    this.props.form.setFieldsValue({positionGroup: undefined});
  }

  validatorFields = (rule: any, value: any, callback: any) => {
    if (this.isNewEntity()) return callback();

    if (!value) {
      return callback(
        this.props.intl.formatMessage({ id: "form.validation.required" })
      );
    }

    if (
      this.isPositionChange &&
      !this.isNotDraft() &&
      !this.changedMap.get("organizationGroup") &&
      !this.changedMap.get("positionGroup") &&
      !this.changedMap.get("gradeGroup")
    )
      return callback(
        this.props.intl.formatMessage({
          id: "validation.equal.organizationGroup"
        })
      );
    return callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const messages = this.props.mainStore!.messages!;

    const isNewEntity = this.isNewEntity();

    const isNotDraft = this.isNotDraft();

    const isOnApprove = this.isOnApproving();

    const visibleSalary: boolean =
      this.isSalaryChange &&
      isOnApprove &&
      !!this.activeUserTask &&
      !!this.salaryVisibleRoles.find(
        name => name === this.activeUserTask!.name
      );

    const readonlyPosition = !this.isPositionChange || isNotDraft;
    const readonlyAmountSalary = !(
      this.isCbGroupManager ||
      this.isCbCompany ||
      this.isCbGroup
    );

    this.props.form.getFieldDecorator("dateTo", {});

    return (
      <Page pageName={this.props.intl.formatMessage({ id: "wccf.request" })}>
        <Card
          className="narrow-layout card-actions-container"
          actions={this.getBtns()}
          bordered={false}
        >
          <Section
            size="large"
            sectionName={this.props.intl.formatMessage({ id: "request.info" })}
          >
            <Spin spinning={this.dataInstance.status === "LOADING"}>
              <Form layout="vertical" className="compact-form">
                <FormContainer>
                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="requestNumber"
                        form={this.props.form}
                        formItemOpts={{ style: { marginBottom: "12px" } }}
                        disabled
                      />
                    </Col>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="requestDate"
                        form={this.props.form}
                        formItemOpts={{ style: { marginBottom: "12px" } }}
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="status"
                        form={this.props.form}
                        optionsContainer={this.statusesDc}
                        formItemOpts={{ style: { marginBottom: "12px" } }}
                        disabled
                      />
                    </Col>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="wccfType"
                        form={this.props.form}
                        optionsContainer={this.wccfTypesDc}
                        disabled={!isNewEntity}
                        getFieldDecoratorOpts={{
                          getValueFromEvent: args => {
                            this.onChangeWccfSelectType(args);
                            return args;
                          },
                          rules: [
                            {
                              required: true,
                              message: this.props.intl.formatMessage(
                                { id: "form.validation.required" },
                                {
                                  fieldName:
                                    messages[
                                      this.dataInstance.entityName + ".wccfType"
                                    ]
                                }
                              )
                            }
                          ]
                        }}
                        formItemOpts={{ style: { marginBottom: "12px" } }}
                      />
                    </Col>
                  </Row>
                </FormContainer>
              </Form>
            </Spin>
          </Section>

          <Section
            size="large"
            sectionName={this.props.intl.formatMessage({
              id: "general.information"
            })}
          >
            <Spin spinning={this.dataInstance.status === "LOADING"}>
              <Form layout="vertical" className="compact-form">
                <FormContainer>
                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <ReadonlyField
                        entityName={this.dataInstance.entityName}
                        propertyName="personGroup"
                        optionsContainer={this.personsDc}
                        form={this.props.form}
                        disabled={!isNewEntity}
                        getFieldDecoratorOpts={{
                          rules: [
                            {
                              required: true,
                              message: this.props.intl.formatMessage(
                                { id: "form.validation.required" },
                                {
                                  fieldName:
                                    messages[
                                      this.dataInstance.entityName +
                                        ".personGroup"
                                    ]
                                }
                              )
                            }
                          ],
                          getValueFromEvent: args => {
                            this.personGroupId = args;
                            this.loadAssignmentSalaryOldValue();
                            return args;
                          }
                        }}
                      />
                    </Col>
                    <Col md={24} lg={12}>
                      <div style={{ display: "flex" }}>
                        <Form.Item
                          label={messages[PersonExt.NAME + ".hireDate"]}
                        >
                          <DefaultDatePicker
                            value={
                              this.assignmentSalaryOldValue &&
                              this.assignmentSalaryOldValue.hireDate &&
                              moment(this.assignmentSalaryOldValue.hireDate)
                            }
                            disabled={true}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row className={"form-row"}>
                    {!isNewEntity ? (
                      <Col md={24} lg={12}>
                        {this.dataInstance.status === "DONE" &&
                        this.dataInstance!.item!.wccfType!.code! !==
                          "TEMPORARY_TRANSFER" ? (
                          <div>
                            <ReadonlyField
                              entityName={this.dataInstance.entityName}
                              propertyName="planDate"
                              form={this.props.form}
                              formItemOpts={{
                                style: { marginBottom: "12px" },
                                label: this.props.intl.formatMessage({
                                  id: "wccf.planDate"
                                })
                              }}
                              disabled={isNotDraft}
                              getFieldDecoratorOpts={{
                                getValueFromEvent: args => {
                                  this.loadAssignmentSalaryOldValue(args);
                                  return args;
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                marginBottom: "12px",
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <ReadonlyField
                                entityName={this.dataInstance.entityName}
                                propertyName="planDate"
                                form={this.props.form}
                                formItemOpts={{
                                  style: { marginBottom: "12px" },
                                  label: this.props.intl.formatMessage({
                                    id: "wccf.planDate"
                                  })
                                }}
                                disabled={isNotDraft}
                                getFieldDecoratorOpts={{
                                  getValueFromEvent: args => {
                                    this.loadAssignmentSalaryOldValue(args);
                                    return args;
                                  }
                                }}
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "12px",
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "10px"
                              }}
                            >
                              <ReadonlyField
                                entityName={this.dataInstance.entityName}
                                propertyName="dateTo"
                                form={this.props.form}
                                formItemOpts={{
                                  style: { marginBottom: "12px" },
                                  label: this.props.intl.formatMessage({
                                    id: "wccf.planDateTo"
                                  })
                                }}
                                disabled={isNotDraft}
                                getFieldDecoratorOpts={{
                                  getValueFromEvent: args => {
                                    this.loadAssignmentSalaryOldValue(args);
                                    return args;
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Col>
                    ) : (
                      <Col md={24} lg={12}>
                        {this.wccfTypeCodeValue !== "TEMPORARY_TRANSFER" ? (
                          <ReadonlyField
                            entityName={this.dataInstance.entityName}
                            propertyName="planDate"
                            form={this.props.form}
                            formItemOpts={{
                              style: { marginBottom: "12px" },
                              label: this.props.intl.formatMessage({
                                id: "wccf.planDate"
                              })
                            }}
                            disabled={isNotDraft}
                            getFieldDecoratorOpts={{
                              getValueFromEvent: args => {
                                this.loadAssignmentSalaryOldValue(args);
                                return args;
                              }
                            }}
                          />
                        ) : (
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                marginBottom: "12px",
                                display: "flex",
                                flexDirection: "column"
                              }}
                            >
                              <ReadonlyField
                                entityName={this.dataInstance.entityName}
                                propertyName="planDate"
                                form={this.props.form}
                                formItemOpts={{
                                  style: { marginBottom: "12px" },
                                  label: this.props.intl.formatMessage({
                                    id: "wccf.planDate"
                                  })
                                }}
                                disabled={isNotDraft}
                                getFieldDecoratorOpts={{
                                  getValueFromEvent: args => {
                                    this.loadAssignmentSalaryOldValue(args);
                                    return args;
                                  }
                                }}
                              />
                            </div>
                            <div
                              style={{
                                marginBottom: "12px",
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "10px"
                              }}
                            >
                              <ReadonlyField
                                entityName={this.dataInstance.entityName}
                                propertyName="dateTo"
                                form={this.props.form}
                                formItemOpts={{
                                  style: { marginBottom: "12px" },
                                  label: this.props.intl.formatMessage({
                                    id: "wccf.planDateTo"
                                  })
                                }}
                                disabled={isNotDraft}
                                getFieldDecoratorOpts={{
                                  getValueFromEvent: args => {
                                    this.loadAssignmentSalaryOldValue(args);
                                    return args;
                                  }
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Col>
                    )}

                    <Col md={24} lg={12}>
                      <div style={isNewEntity ? { display: "none" } : {}}>
                        <Button
                          style={{
                            background: "#3ab5d6",
                            color: "black !important"
                          }}
                          disabled={this.loadingReportJobInstruction}
                          onClick={this.reportJobInstruction}
                        >
                          <FormattedMessage id={"wccf.job.instructions"} />
                        </Button>
                        <Button
                          onClick={this.info}
                          style={{background:"green",color:"#fff",marginLeft: 10}}>
                          <FormattedMessage id={"myTeam-personData.profile"}/>
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Form.Item
                    style={{ width: "100%" }}
                    label={createElement(Msg, {
                      entityName: this.dataInstance.entityName,
                      propertyName: "salaryComment"
                    })}
                  >
                    {getFieldDecorator("comment", {
                      rules: [
                        {
                          required: true,
                          message: this.props.intl.formatMessage(
                            { id: "form.validation.required" },
                            {
                              fieldName:
                                messages[
                                  this.dataInstance.entityName +
                                    ".salaryComment"
                                ]
                            }
                          )
                        }
                      ]
                    })(<TextArea disabled={isNotDraft} rows={4} />)}
                  </Form.Item>
                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="files"
                    form={this.props.form}
                    formItemOpts={{ style: { marginBottom: "12px" } }}
                  />

                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="actualDate"
                    disabled={!this.isHR}
                    form={this.props.form}
                    getFieldDecoratorOpts={{
                      rules: [
                        {
                          required: this.isHR,
                          message: this.props.intl.formatMessage(
                            { id: "form.validation.required" },
                            {
                              fieldName:
                                messages[
                                  this.dataInstance.entityName + ".actualDate"
                                ]
                            }
                          )
                        }
                      ]
                    }}
                    formItemOpts={{
                      style: this.isHR
                        ? { marginBottom: "12px" }
                        : { display: "none" }
                    }}
                  />
                </FormContainer>
              </Form>
            </Spin>
          </Section>

          <div style={isNewEntity ? { display: "none" } : {}}>
            <Section
              size="large"
              sectionName={this.props.intl.formatMessage({
                id: "currentData.and.proposedChanges"
              })}
            >
              <Spin spinning={this.dataInstance.status === "LOADING"}>
                <Form layout="vertical" className="compact-form">
                  <FormContainer>
                    <Row className={"form-row"}>
                      <Col md={24} lg={12}>
                        <div className={"section-header-container"}>
                          <FormattedMessage id={"wccf.currentValue"} />
                        </div>
                      </Col>
                      <Col md={24} lg={12}>
                        <div className={"section-header-container"}>
                          <FormattedMessage id={"newValue"} />
                        </div>
                        {this.hasVacation ? (
                          <></>
                        ) : (
                          <div style={{ marginLeft: 10 }}>
                            <span style={{ color: "red" }}>
                              <FormattedMessage id={"wccf.no.vacation"} />
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className={"form-row"}>
                      <Col md={24} lg={12}>
                        <Form.Item
                          label={createElement(Msg, {
                            entityName: this.dataInstance.entityName,
                            propertyName: "organizationGroup"
                          })}
                        >
                          <Input
                            value={
                              this.assignmentSalaryOldValue &&
                              this.assignmentSalaryOldValue.organizationName
                            }
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24} lg={12}>
                        <ReadonlyField
                          entityName={this.dataInstance.entityName}
                          propertyName="organizationGroup"
                          form={this.props.form}
                          optionsContainer={this.organizationGroupDc}
                          disabled={readonlyPosition}
                          getFieldDecoratorOpts={{
                            rules: [
                              {
                                required: !isNewEntity,
                                validator: !isOnApprove
                                  ? this.validatorFields
                                  : (rule: any, value: any, callback: any) => {
                                      return callback();
                                    }
                              }
                            ],
                            getValueFromEvent: args => {
                              if (!args) return args;
                              this.changedMap.set(
                                "organizationGroup",
                                args !==
                                  this.assignmentSalaryOldValue
                                    .organizationGroupId
                              );
                              this.checkHasVacation(null, args);
                              this.onChangeOrganization(args);
                              return args;
                            }
                          }}
                          formItemOpts={{
                            hasFeedback: this.changedMap.get(
                              "organizationGroup"
                            )
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className={"form-row"}>
                      <Col md={24} lg={12}>
                        <Form.Item
                          label={createElement(Msg, {
                            entityName: this.dataInstance.entityName,
                            propertyName: "positionGroup"
                          })}
                        >
                          <Input
                            value={
                              this.assignmentSalaryOldValue &&
                              this.assignmentSalaryOldValue.positionName
                            }
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24} lg={12}>
                        <ReadonlyField
                          entityName={this.dataInstance.entityName}
                          propertyName="positionGroup"
                          optionsContainer={this.positionGroupDc}
                          disabled={readonlyPosition}
                          getFieldDecoratorOpts={{
                            rules: [
                              {
                                required: !isNewEntity,
                                validator: !isOnApprove
                                  ? this.validatorFields
                                  : (rule: any, value: any, callback: any) => {
                                      return callback();
                                    }
                              }
                            ],
                            getValueFromEvent: args => {
                              if (!args) return args;
                              this.changedMap.set('positionGroup', args !== this.assignmentSalaryOldValue.positionGroupId);
                              getCubaREST()!.searchEntities<PositionGroupExt>(PositionGroupExt.NAME, {
                                conditions: [{property: "id", operator: "=", value: args}]
                              }, {view: "positionGroup.change.request"}).then(value1 => {
                                if (value1.length == 1) {
                                  const gradeId = (value1 as PositionGroupExt[])[0].position!.gradeGroup!.id;
                                  if (gradeId) {
                                    this.props.form.setFieldsValue({
                                      gradeGroup: gradeId
                                    });
                                  }
                                  this.setNewPosFTE(value1[0]!.position!.fte);
                                  this.checkHasVacation(args);
                                }
                              });
                              return args;
                            }
                          }}
                          form={this.props.form}
                          formItemOpts={{
                            hasFeedback: this.changedMap.get("positionGroup")
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className={"form-row"}>
                      <Col md={24} lg={12}>
                        <Form.Item
                          label={createElement(Msg, {
                            entityName: this.dataInstance.entityName,
                            propertyName: "gradeGroup"
                          })}
                        >
                          <Input
                            value={
                              this.assignmentSalaryOldValue &&
                              this.assignmentSalaryOldValue.gradeName
                            }
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24} lg={12}>
                        <ReadonlyField
                          entityName={this.dataInstance.entityName}
                          propertyName="gradeGroup"
                          form={this.props.form}
                          optionsContainer={this.gradeGroupDc}
                          disabled={readonlyPosition}
                          getFieldDecoratorOpts={{
                            rules: [
                              {
                                required: !isNewEntity,
                                validator: !isOnApprove
                                  ? this.validatorFields
                                  : (rule: any, value: any, callback: any) => {
                                      return callback();
                                    }
                                // message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.gradeGroup']})
                              }
                            ],
                            getValueFromEvent: args => {
                              if (!args) return args;
                              this.changedMap.set(
                                "gradeGroup",
                                args !==
                                  this.assignmentSalaryOldValue
                                    .organizationGroupId
                              );
                              return args;
                            }
                          }}
                          formItemOpts={{
                            hasFeedback: this.changedMap.get("gradeGroup")
                          }}
                        />
                      </Col>
                    </Row>
                  </FormContainer>
                </Form>
              </Spin>

              <Form
                layout="vertical"
                className="compact-form"
                style={visibleSalary ? {} : { display: "none" }}
              >
                <FormContainer>
                  <Row className={"form-row"}>
                    <Col md={24} lg={12}>
                      <Form.Item
                        label={createElement(Msg, {
                          entityName: this.dataInstance.entityName,
                          propertyName: "amount"
                        })}
                      >
                        <Input
                          value={
                            this.assignmentSalaryOldValue &&
                            this.assignmentSalaryOldValue.salary
                          }
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                      <div style={{ display: "flex" }}>
                        <ReadonlyField
                          entityName={this.dataInstance.entityName}
                          propertyName="amount"
                          form={this.props.form}
                          disabled={readonlyAmountSalary}
                          getFieldDecoratorOpts={{
                            rules: [
                              {
                                required: visibleSalary,
                                message: this.props.intl.formatMessage(
                                  { id: "form.validation.required" },
                                  {
                                    fieldName: this.mainStore.messages![
                                      this.dataInstance.entityName + ".amount"
                                    ]
                                  }
                                )
                              }
                            ],
                            getValueFromEvent: args => {
                              if (!args) return args;
                              const value = args.currentTarget.value;
                              this.changedMap.set(
                                "salary",
                                (value && parseInt(value)) !==
                                  this.assignmentSalaryOldValue.salary
                              );
                              if (this.assignmentSalaryOldValue.salary) {
                                this.props.form.setFieldsValue({
                                  changePercent:
                                    Math.round(
                                      ((value -
                                        this.assignmentSalaryOldValue.salary) *
                                        10000) /
                                        this.assignmentSalaryOldValue.salary
                                    ) / 100
                                });
                              }
                              return value;
                            }
                          }}
                          formItemOpts={{
                            hasFeedback: this.changedMap.get("salary"),
                            style: { width: "80%" }
                          }}
                        />

                        <Form.Item
                          style={{ marginLeft: 10 }}
                          label={createElement(Msg, {
                            entityName: this.dataInstance.entityName,
                            propertyName: "changePercent"
                          })}
                        >
                          {getFieldDecorator("changePercent")(
                            <Input type={"number"} disabled={true} />
                          )}
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Form.Item
                    style={{ width: "100%" }}
                    label={createElement(Msg, {
                      entityName: this.dataInstance.entityName,
                      propertyName: "salaryComment"
                    })}
                  >
                    {getFieldDecorator("salaryComment", {
                      rules: [
                        {
                          required: visibleSalary,
                          message: this.props.intl.formatMessage(
                            { id: "form.validation.required" },
                            {
                              fieldName:
                                messages[
                                  this.dataInstance.entityName +
                                    ".salaryComment"
                                ]
                            }
                          )
                        }
                      ]
                    })(<TextArea disabled={readonlyAmountSalary} rows={4} />)}
                  </Form.Item>
                </FormContainer>
              </Form>
            </Section>
            {this.takCard()}
          </div>
        </Card>
      </Page>
    );
  }

  checkHasVacation = (
    position: string | null,
    org: string = this.props.form.getFieldValue("organizationGroup")
  ) => {
    if (!position) position = this.props.form.getFieldValue("positionGroup");
    if (position && org) {
      getCubaREST()!.searchEntities<AssignmentExt>(AssignmentExt.NAME, {
        conditions: [
          {
          property: 'positionGroup.id',
          operator: '=',
          value: position
        },
          {
          property: 'organizationGroup.id',
          operator: '=',
          value: org
        },
          {
          property: 'assignmentStatus.code',
          operator: '=',
          value: 'ACTIVE'
        },
          {
          property: 'primaryFlag',
          operator: '=',
          value: 'TRUE'
        },
          {
          property: 'startDate',
          operator: '<=',
          value: moment().format('YYYY-MM-DD')
        }, {
          property: 'endDate',
          operator: '>=',
          value: moment().format('YYYY-MM-DD')
        }
        ]
      }).then(value =>{
        if(this.newPosFTE){
          this.hasVacation = (this.newPosFTE - value.length)>0
        }
      });
    }
  };

  loadAssignmentSalaryOldValue = async (
    date: any = moment(),
    personGroupId: string = this.personGroupId
  ) => {
    if (date && personGroupId)
      await restServices.assignmentSalaryRequestService
        .getAssignmentSalaryInfo({
          date: moment(date)
            .subtract(1, "day")
            .format(JSON_DATE_TIME_FORMAT),
          personGroupId: personGroupId
        })
        .then(value => (this.assignmentSalaryOldValue = value));
  };

  loadData = async () => {
    if (!this.isNewEntity()) {
      await this.dataInstance.load(this.props.entityId);
    } else {
      const entityName = this.dataInstance.entityName;
      this.initItem(
        await restServices.portalHelperService.newEntity({
          entityName: entityName
        })
      );
    }
  };

  initChangeType = (wccfType?: DicWCCFType | null) => {
    this.isPositionChange = !!(wccfType && wccfType.changePosition);
    this.isSalaryChange = !!(wccfType && wccfType.changeSalary);
  };

  onReactionDisposerEffect = (item: AssignmentSalaryRequest | undefined) => {
    (async () => {
      this.personGroupId = item && item.personGroup && item.personGroup.id;
      if (this.personGroupId && item && (item.actualDate || item.planDate || item.requestDate || item.dateTo))
        await this.loadAssignmentSalaryOldValue((item.actualDate || item.planDate || item.requestDate || item.dateTo), this.personGroupId);
      if (this.assignmentSalaryOldValue && item) {
        this.changedMap.set(
          "positionGroup",
          (item.positionGroup && item.positionGroup.id) !==
            this.assignmentSalaryOldValue.positionGroupId
        );
        this.changedMap.set(
          "organizationGroup",
          (item.organizationGroup && item.organizationGroup.id) !==
            this.assignmentSalaryOldValue.organizationGroupId
        );
        this.changedMap.set(
          "gradeGroup",
          (item.gradeGroup && item.gradeGroup.id) !==
            this.assignmentSalaryOldValue.gradeGroupId
        );
        this.changedMap.set(
          "salary",
          item.amount !== this.assignmentSalaryOldValue.salary
        );
        this.changedMap.set(
          "currency",
          (item.currency && item.currency.id) !==
            this.assignmentSalaryOldValue.currencyId
        );
      }

      this.initChangeType(item && item.wccfType);

      if (item && item.positionGroup && item.organizationGroup)
        this.checkHasVacation(item.positionGroup.id, item.organizationGroup.id);
      if (this.personGroupId) this.setEmployee(this.personGroupId);

      this.organizationGroupDc = !this.isNotDraft() ? serviceCollection<OrganizationGroup>((pagination) =>
          restServices.assignmentSalaryRequestService.getMyOrgAndChilds()
            .then(value => {
              return getCubaREST()!.searchEntities<OrganizationGroupExt>(OrganizationGroupExt.NAME,{
                conditions:[{
                  property:"id",
                  operator:'=',
                  value:this.assignmentSalaryOldValue.organizationGroupId
                }
                ]},{view:"organizationGroupExt-receptionAssignment"}).then(orgValue=>{
                let date = moment(orgValue[0] && orgValue[0].company! && orgValue[0].company!.endDate!)
                let now = moment()
                if(date >= now  && this.assignmentSalaryOldValue){
                  if(value.some((item)=>item.id===this.assignmentSalaryOldValue.organizationGroupId)){
                    return [...value]
                  }
                  else{
                    return  [...value, {
                      id: this.assignmentSalaryOldValue.organizationGroupId,
                      '_instanceName': this.assignmentSalaryOldValue.organizationName
                    }]
                  }
                }
                else {
                  this.props.form.setFieldsValue({"organizationGroup":this.organizationGroupDc && this.organizationGroupDc!.items!.some(item=>item.id === (this.assignmentSalaryOldValue && this.assignmentSalaryOldValue!.organizationGroupId!))? (this.assignmentSalaryOldValue && this.assignmentSalaryOldValue!.organizationGroupId! ): ""})
                  return value
                }
              })
            })
        )
        : collection<OrganizationGroup>(OrganizationGroup.NAME, {
            filter: {
              conditions: [
                {
                  property: "id",
                  operator: "=",
                  value:
                    this.dataInstance.item &&
                    this.dataInstance.item.organizationGroup &&
                    this.dataInstance.item.organizationGroup.id!
                }
              ]
            }
          });

      if (this.dataInstance.item && this.dataInstance.item.organizationGroup)
        this.positionGroupDc.filter = {
          conditions: [
            {
              property: "organizationGroup.id",
              operator: "=",
              value: this.dataInstance.item.organizationGroup.id
            }
          ]
        };

      this.personsDc = this.personGroupId
        ? collection<PersonGroupExt>(PersonGroupExt.NAME, {
            filter: {
              conditions: [
                {
                  property: "id",
                  operator: "=",
                  value: this.personGroupId
                }
              ]
            }
          })
        : serviceCollection<PersonGroupExt>(pagination =>
            restServices.employeeService.getEmployees({
              date: moment().format("YYYY-MM-DD"),
              view: "_minimal"
            })
          );



      this.personsDc.load();
      this.positionGroupDc.load();
      when(()=>this.positionGroupDc.status==="DONE"&&this.positionGroupDc.items.length>0,()=>this.positionGroupDc.items = this.positionGroupDc.items.filter(i=>i.position&&i.position.positionStatus&&i.position.positionStatus.code==="ACTIVE"))
      this.organizationGroupDc.load();
    })();
  };

  reportJobInstruction = async () => {
    this.loadingReportJobInstruction = true;
    const positionGroupId = this.dataInstance.item!.positionGroup!.id;
    getCubaREST()!
      .searchEntities(
        JobDescription.NAME,
        {
          conditions: [
            {
              property: "positionGroup.id",
              operator: "=",
              value: positionGroupId
            }
          ]
        },
        {
          view: "jobDescription-for-position-edit",
          limit: 1
        }
      )
      .then(jd => {
        if (jd && jd.length > 0 && jd[0]) {
          const jdElement = jd[0] as JobDescription;
          if (jdElement.file && jdElement.file.id) {
            downloadFile(
              jdElement.file.id,
              jdElement.file.name ? jdElement.file.name : "JD",
              jdElement.file.extension ? jdElement.file.extension : "",
              this.props.intl.formatMessage({ id: "jobDescriptionFileNotFind" })
            )
              .then(value1 => {
                this.loadingReportJobInstruction = false;
              })
              .catch(reason => {
                this.loadingReportJobInstruction = false;
              });
          } else {
            Notification.error({
              message: this.props.intl.formatMessage({
                id: "jobDescriptionFileNotFind"
              })
            });
            this.loadingReportJobInstruction = false;
          }
        } else {
          Notification.error({
            message: this.props.intl.formatMessage({
              id: "jobDescriptionFileNotFind"
            })
          });
          this.loadingReportJobInstruction = false;
        }
      })
      .catch(reason => {
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "jobDescriptionFileNotFind"
          })
        });
        this.loadingReportJobInstruction = false;
      });
  };

  reportEmployeeProfile = () => {
    this.loadingReportEmployeeProfile = true;
    const locale = this.props.rootStore!.userInfo.locale!.toLowerCase();
    let reportCode = "employee_profile_" + locale;
    if (
      this.activeUserTask &&
      this.activeUserTask.hrRole &&
      this.activeUserTask.hrRole.code &&
      (this.activeUserTask!.hrRole!.code === "C&B_COMPANY" ||
        this.activeUserTask!.hrRole!.code === "C&B_GROUP" ||
        this.activeUserTask!.hrRole!.code === "C&B_GROUP_MANAGER" ||
        this.activeUserTask!.hrRole!.code === "HR_MANAGER" ||
        this.activeUserTask!.hrRole!.code === "PERSONAL_DIRECTOR_GROUP" ||
        this.activeUserTask!.hrRole!.code === "HRA")
    ) {
      reportCode = "profile_cb_" + locale;
    }
    const personGroupId = this.dataInstance.item!.personGroup!.id;
    getCubaREST()!
      .searchEntities(
        PersonExt.NAME,
        {
          conditions: [
            {
              property: "group.id",
              operator: "=",
              value: personGroupId
            },
            {
              property: "startDate",
              operator: "<",
              value: moment().format("YYYY-MM-DD")
            },
            {
              property: "endDate",
              operator: ">",
              value: moment().format("YYYY-MM-DD")
            }
          ]
        },
        {
          view: "_local"
        }
      )
      .then(persons => {
        if (persons && persons.length > 0 && persons[0]) {
          const person = persons[0] as PersonExt;
          if (person) {
            const data = {
              parameters: [
                {
                  name: "person",
                  value: person.id
                }
              ]
            };
            runReport(reportCode, data, this.props.intl)
              .then(value1 => {
                this.loadingReportEmployeeProfile = false;
              })
              .catch(reason => {
                Notification.error({
                  message: this.props.intl.formatMessage({
                    id: "employeeProfileNotFound"
                  })
                });
                this.loadingReportEmployeeProfile = false;
              });
          } else {
            Notification.error({
              message: this.props.intl.formatMessage({
                id: "employeeProfileNotFound"
              })
            });
            this.loadingReportEmployeeProfile = false;
          }
        } else {
          Notification.error({
            message: this.props.intl.formatMessage({
              id: "employeeProfileNotFound"
            })
          });
          this.loadingReportEmployeeProfile = false;
        }
      })
      .catch(reason => {
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "employeeProfileNotFound"
          })
        });
        this.loadingReportEmployeeProfile = false;
      });
  };

  afterSendOnApprove = () => {
    if (this.activeTask) this.props.history!.goBack();
    else this.props.history!.push(WccfMenuPage.PATH);
  };

  initVariablesByBproc = async () => {
    if (this.activeUserTask) {
      const taskName = this.activeUserTask.name;

      this.isCbGroup = taskName === "c_and_b_group_task";
      this.isCbCompany = this.isCbGroup || taskName === "c_and_b_company_task";
      this.isCbGroupManager = taskName === "super_c_and_b_group_task";
      this.isCmBGroupManager = taskName === "cmb_group_task";
      this.isHR = taskName === "hra_task";
      this.isHrManager = taskName === "hr_manager_task";
      this.isPersonalDirectorGroup =
        taskName === "personal_director_group_task";
    }
  };

  componentDidMount() {
    super.componentDidMount();

    this.wccfTypeCodeValue =
      this.dataInstance.item &&
      this.dataInstance.item.wccfType &&
      this.dataInstance.item!.wccfType.code;
  }
}

const component = injectIntl(
  withLocalizedForm<
    EditorProps &
      WrappedComponentProps &
      RootStoreProp &
      MainStoreInjected &
      RouteComponentProps<any>
  >({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(WccfEdit)
);

export default withRouter(component);
