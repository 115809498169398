import { ILearningBudgetRequestCost } from "./tsadv_ILearningBudgetRequestCost";
import { LearningBudgetRequestCostPrev } from "./tsadv_LearningBudgetRequestCostPrev";
export class LearningBudgetRequestCost extends ILearningBudgetRequestCost {
  static NAME = "tsadv_LearningBudgetRequestCost";
  learningBudgetRequestCostPrev?: LearningBudgetRequestCostPrev | null;
}
export type LearningBudgetRequestCostViewName = "_base" | "_local" | "_minimal";
export type LearningBudgetRequestCostView<
  V extends LearningBudgetRequestCostViewName
> = V extends "_base"
  ? Pick<
      LearningBudgetRequestCost,
      "id" | "trainingCost" | "travel" | "residence" | "dailyAllowance"
    >
  : V extends "_local"
  ? Pick<
      LearningBudgetRequestCost,
      "id" | "trainingCost" | "travel" | "residence" | "dailyAllowance"
    >
  : never;
