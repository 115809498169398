import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {PositionDetailsProps} from "./PositionDetails";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router";
import {restServices} from "../../../../cuba/services";
import Column from "antd/es/table/Column";
import {Button, Icon, Table} from "antd";
import {JobDescriptionRequestJson} from "../../../../cuba/entities/base/tsadv_JobDescriptionRequestJson";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {JobDescriptionRequestManagement} from "../../JobDescriptionRequest/JobDescriptionRequestManagement";
import {JobDescriptionRequest} from "../../../../cuba/entities/base/tsadv_JobDescriptionRequest";

@injectMainStore
@observer
class JobDescriptionRequestTable extends React.Component<WrappedComponentProps & PositionDetailsProps & MainStoreInjected
  & RouteComponentProps> {

  @observable
  jobDescriptionRequests?: JobDescriptionRequestJson[] = []
  @observable
  selectedPosition: string | undefined


  componentDidMount(): void {
    if (this.selectedPosition !== this.props.selectedPosition) {
      restServices.jobDescriptionService.getJobDescriptionRequests({
        positionId: this.props.selectedPosition!
      }).then(value => {
        this.jobDescriptionRequests = value
        this.selectedPosition = this.props.selectedPosition
      })
    }
  }

  @observable
  mainStore = this.props.mainStore!;

  render(): React.ReactNode {
    const messages = this.mainStore.messages!;

    return <Table dataSource={this.jobDescriptionRequests!.slice()}
                  size="default"
                  bordered={false}
                  rowKey="id" style={{width: '100%'}}>
      <Column title={messages[JobDescriptionRequestJson.NAME + '.requestNumber']}
              dataIndex="requestNumber"
              key="requestNumber"
              render={(text, record, index) => {
                return <Button type="link" onClick={(event) => {
                  const id = (record as JobDescriptionRequestJson).id;
                  this.props.history!.push(JobDescriptionRequestManagement.PATH + '/' + id);
                }}>
                  {text}
                </Button>
              }}
      />
      <Column title={messages[JobDescriptionRequestJson.NAME + '.initiator']}
              dataIndex="initiator"
              key="initiator"/>
      <Column title={messages[JobDescriptionRequestJson.NAME + '.requestDate']}
              dataIndex="requestDate"
              key="requestDate"/>
      <Column title={messages[JobDescriptionRequestJson.NAME + '.requestStatus']}
              dataIndex="requestStatus"
              key="requestStatus"/>
      <Column title={''}
              dataIndex="statusCode"
              key="statusCode"
              render={(text, record) => {
                if (text === 'DRAFT') {
                  return <Button onClick={() => {
                    getCubaREST()!.deleteEntity(JobDescriptionRequest.NAME, (record as JobDescriptionRequestJson).id).then(value => {
                      restServices.jobDescriptionService.getJobDescriptionRequests({
                        positionId: this.props.selectedPosition!
                      }).then(value => {
                        this.jobDescriptionRequests = value
                      })
                    })
                  }
                  }><Icon type='delete'/></Button>
                }
                return <Button disabled={true}><Icon type='delete'/></Button>
              }}/>

    </Table>
  }
}

const JobDescriptionRequestTables = injectIntl(withRouter(JobDescriptionRequestTable));
export default JobDescriptionRequestTables