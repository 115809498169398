 import * as React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {PositionDetailsProps} from "./PositionDetails";
import {observable, runInAction} from "mobx";
import {JobDescription} from "../../../../cuba/entities/base/tsadv_JobDescription";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {observer} from "mobx-react";
import {AssignmentExt} from "../../../../cuba/entities/base/base$AssignmentExt";
import Column from "antd/es/table/Column";
import {SerializedEntity} from "@cuba-platform/rest";
import {Radio, Table} from 'antd';
import {RadioChangeEvent} from "antd/es/radio";
import moment from 'moment'
 import {PositionExt} from "../../../../cuba/entities/base/base$PositionExt";
 import MsgEntity from "../../../components/MsgEntity";


@injectMainStore
@observer
class JobDescriptionForm extends React.Component<WrappedComponentProps & PositionDetailsProps & MainStoreInjected> {

  @observable
  jobDescription?: JobDescription
  @observable
  mainStore = this.props.mainStore!
  @observable
  dataCollection?:Array<SerializedEntity<AssignmentExt>>
  @observable
  dataCollectionFiltered?:Array<SerializedEntity<AssignmentExt>>
  @observable
  btnValue: number = 2
  messages = this.mainStore.messages!;

  @observable positionInfo:SerializedEntity<PositionExt>

  componentDidMount(): void {
    if (this.props.selectedPosition && this.props.selectedPosition.length > 0) {
      getCubaREST()!.searchEntities<AssignmentExt>(AssignmentExt.NAME, {
        conditions: [{
          property: "positionGroup.id",
          operator: '=',
          value: this.props.selectedPosition ? this.props.selectedPosition : null
        }]
      }, {
        view: 'assignment.for.destinations'
      }).then(values => {
       this.dataCollection = values
        this.dataCollectionFiltered = this.dataCollection!.filter(value => moment().isBetween(moment(value.startDate), moment(value.endDate)) && (value.assignmentStatus!.code === "ACTIVE" || value.assignmentStatus!.code === "SUSPENDED") )
      });
      getCubaREST()!.searchEntities<PositionExt>(PositionExt.NAME,{conditions:[
          {
            property:"group.id",
            operator:"=",
            value:this.props.selectedPosition
          }]},{view:"_local"}).then(res=>runInAction(()=>this.positionInfo = res[0]))
    }
  }

  render(): React.ReactNode {
    const onChange = (event:RadioChangeEvent) => {
      this.btnValue = event.target.value
    }

    return <div>
      <Radio.Group style={{margin:"20px"}} onChange={onChange} value={this.btnValue}>
        <Radio key={"1"} value={1}><FormattedMessage id={"fullStory"}/></Radio>
        <Radio key={"2"} value={2}><FormattedMessage id={"currentAppointments"}/></Radio>
      </Radio.Group>
      {this.positionInfo&&this.positionInfo.fte?
      <span>
        <strong><FormattedMessage id="planValue"/>: {this.positionInfo.fte}</strong>
      </span>:
        <></>}
      <Table dataSource={this.btnValue === 2? this.dataCollectionFiltered: this.dataCollection }
                  loading={!this.dataCollection}
                  size="default"
                  bordered={false}
                  rowKey="id" style={{width: '100%'}}
                  pagination={{pageSize:15}}
      > 
      <Column title={this.props.intl.formatMessage({id:"FIO"})}
              dataIndex="personGroup"
              key="personGroup"
              render={(text, record:AssignmentExt) => record.personGroup ? record.personGroup['_instanceName']+(record.personGroup.person?" ("+record.personGroup.person.employeeNumber+")":""):""}
      />
        <Column title={this.props.intl.formatMessage({id:"employee.hireDate"})}
                dataIndex="hireDate"
                key="hireDate"
                render={(text, record:AssignmentExt) => record.personGroup && record.personGroup.person&&record.personGroup.person.hireDate? moment(record.personGroup.person.hireDate).locale('ru').format('L'):""}
        />
      <Column title={this.props.intl.formatMessage({id:"assignment.startDate"})}
              dataIndex="startDate"
              key="startDate"
              render={(text, record:AssignmentExt) => record.startDate ? moment(record.startDate).locale('ru').format('L'):""}
      />
        <Column title={this.props.intl.formatMessage({id:"endDate"})}
                dataIndex="endDate"
                key="endDate"
                render={(text, record:AssignmentExt) => record.endDate ? moment(record.endDate).locale('ru').format('L'):""}
        />
        <Column title={<MsgEntity entityName={AssignmentExt.NAME} propertyName={"assignmentStatus"}/>}
                dataIndex="assignmentStatus"
                key="assignmentStatus"
                render={(text, record:AssignmentExt) => record.assignmentStatus? record.assignmentStatus['_instanceName']:""}
        />
    </Table>
    </div>
  }
}

export default injectIntl(JobDescriptionForm);