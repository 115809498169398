import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {observer} from "mobx-react";
import RecognitionCountChart from "./RecognitionCountChart";
import RecognitionQualityChart from "./RecognitionQualityChart";
import RecognitionBuyChart from "./RecognitionBuyChart";
import RecognitionQualityRadarChart from "./RecognitionQualityRadarChart";
import {Icon, Modal, Tabs} from "antd";
import {action, observable, runInAction} from "mobx";
import {rootStore} from "../../../../store";

@observer
class Analytics extends React.Component<WrappedComponentProps> {
  @observable modalVisible: boolean = false;
  @observable currentComponent?: JSX.Element;
  @observable currentHeader?: string;

  personGroupId = rootStore!.userInfo.personGroupId!;

  makeTab = (tabName: string, component: JSX.Element) => {

    const onModalOpen = () => runInAction(() => {
      this.currentComponent = component;
      this.currentHeader = tabName;
      this.modalVisibleChange(true);
    });

    const expandIcon = <div style={{display: "flex", justifyContent: "end", width: "100%"}}>
      <Icon type="fullscreen" style={{cursor: "pointer"}} onClick={onModalOpen}/>
    </div>

    return <Tabs.TabPane key={tabName} tab={tabName}>
      {expandIcon}
      {component}
    </Tabs.TabPane>
  }

  @action modalVisibleChange = (visible: boolean) => {
    this.modalVisible = visible;
  }


  render() {
    const messages = this.props.intl.messages as Record<string, string>

    return <>
      <Modal centered
             visible={this.modalVisible}
             width={"90vw"}
             onOk={() => this.modalVisibleChange(false)}
             onCancel={() => this.modalVisibleChange(false)}
             cancelButtonProps={{ style: { display: 'none' } }}
      >
        <h2>{this.currentHeader}</h2>
        {this.currentComponent}
      </Modal>

      <Tabs>
        {this.makeTab(messages["recognition.analytics.recognitions"], <RecognitionCountChart personGroupId={this.personGroupId}/>)}
        {this.makeTab(messages["recognition.analytics.qualities"], <RecognitionQualityChart personGroupId={this.personGroupId}/>)}
        {this.makeTab(messages["recognition.analytics.qualitiesRadar"], <RecognitionQualityRadarChart personGroupId={this.personGroupId}/>)}
        {this.makeTab(messages["recognition.analytics.orders"], <RecognitionBuyChart personGroupId={this.personGroupId}/>)}
      </Tabs>
    </>
  }
}

export default injectIntl(Analytics);