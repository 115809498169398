import * as React from "react";
import {createElement, Fragment} from "react";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

import {collection, injectMainStore, withLocalizedForm} from "@cuba-platform/react";

import "../../App.css";
import {observable} from "mobx";
import {Button, Form, Icon, Modal, Row, Select, Table} from "antd";
import TextArea from "antd/es/input/TextArea";
import {SerializedEntity} from "@cuba-platform/rest";
import {TsadvUser} from "../../../cuba/entities/base/tsadv$UserExt";
import Notification from "../../util/Notification/Notification";
import Column from "antd/lib/table/Column";
import {FormComponentProps} from "antd/lib/form";
import {restServices} from "../../../cuba/services";
import {AbstractBprocRequest} from "../../../cuba/entities/base/AbstractBprocRequest";
import {ExtTaskData} from "../../../cuba/entities/base/tsadv_ExtTaskData";
import {RootStoreProp} from "../../store";

export type User = {
  id: string,
  name: string
}

export type ApproveTd = {
  users: string[],
  comment: string,
  isFirstTd: boolean
}

export type EditorProps = {
  isFirstTd: boolean,
  visible: boolean,
  changeVisible: (visible: boolean) => void,
  afterSendOnApprove: () => void,
  request: AbstractBprocRequest,
  task: ExtTaskData
}

@inject("rootStore")
@injectMainStore
@observer
class ApproveTdComponent extends React.Component<EditorProps & RootStoreProp & WrappedComponentProps & FormComponentProps> {

  @observable users: User[] = [];
  @observable selectedUser?: User;

  userCollection = collection<TsadvUser>(TsadvUser.NAME, {
    view: 'portal-bproc-users',
    filter: {
      conditions: [{
        property: 'active',
        operator: '=',
        value: 'TRUE'
      }, {
        property: 'id',
        operator: '<>',
        value: this.props.rootStore!.userInfo!.id!
      }]
    }
  });

  render() {

    return <>
      <Modal visible={this.props.visible}
             onOk={this.approve}
             onCancel={() => this.props.changeVisible(false)}>
        {this.props.isFirstTd ? this.renderTable() : null}

        <Form.Item
          label={createElement(Fragment, null, this.props.intl.formatMessage({id: 'comment'}))}>
          {this.props.form.getFieldDecorator("bproc-comment")(
            <TextArea
              rows={4}/>
          )}
        </Form.Item>
      </Modal>
    </>;
  }

  renderTable = () => {
    return <div>
      <Row>
        <Form.Item style={{margin: 0}} label={<FormattedMessage id="bproc.startBproc.modal.user"/>}>
          <Select style={{width: '100%'}} showSearch allowClear
                  filterOption={(input, option) =>
                    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  loading={this.userCollection.status === 'LOADING'}
                  onChange={this.onChangeUser}>
            {this.userCollection.items.length > 0 ? this.userCollection.items.map(l =>
                <Select.Option title={(l as SerializedEntity<TsadvUser>).fullNameWithLogin!}
                               key={l.id}>{(l as SerializedEntity<TsadvUser>).fullNameWithLogin!}</Select.Option>) :
              <Select.Option key="empty"/>
            }
          </Select>
        </Form.Item>
        <Button type={"primary"}
                disabled={!(this.selectedUser)}
                onClick={this.addUser}><FormattedMessage id="bproc.startBproc.modal.add"/></Button>
      </Row>

      <Table dataSource={Array.from(this.users || [])}
             pagination={false}
             showHeader={true}
             loading={this.userCollection.status === 'LOADING'}
             rowKey={record => record.id}>
        <Column key='name'
                title={<FormattedMessage id="bproc.startBproc.modal.users"/>}
                render={(text, record: User) => record.name}/>
        <Column
          key="action"
          render={(text, record: User) => {
            return <Button type="link"
                           style={{padding: 0}}
                           onClick={() => this.showDeletionDialog(record)}>
              <Icon type="delete" style={{fontSize: '18px', cursor: 'pointer'}}/>
            </Button>
          }}
        />
      </Table>
    </div>;
  }

  showDeletionDialog = (e: User) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        {id: "management.browser.delete.areYouSure"},
        {instanceName: e.name}
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        return this.deleteRow(e);
      }
    });
  };

  deleteRow = (row: User) => {
    this.users = this.users.filter(r => r.id !== row.id);
  };

  onChangeUser = (value: string, option: React.ReactElement<HTMLLIElement>) => {
    this.selectedUser = value ? {id: value, name: (option.props['children'] as any),} : undefined;
  };

  addUser = () => {
    if (this.users.find(u => u.id === this.selectedUser!.id) !== undefined) {
      Notification.error({
        message: this.props.intl.formatMessage({id: "bproc.startBproc.modal.error"})
      });
      return;
    }
    this.users.unshift(this.selectedUser!);
    this.selectedUser = undefined;
  };

  approve = async () => {

    await restServices.learningRequestService.approveTd({
      approvePojo: {
        users: this.users.map(value => value.id),
        isFirstTd: this.props.isFirstTd,
        comment: this.props.form.getFieldValue('bproc-comment')
      },
      request: this.props.request,
      taskData: this.props.task
    })

    Notification.success({
      message: this.props.intl.formatMessage({id: "bproc.APPROVE.success"})
    });
    this.props.afterSendOnApprove();
    // this.props.changeVisible(false);
  }

}

const approveTdWindow = injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any, allValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(ApproveTdComponent)
);
export default approveTdWindow;
