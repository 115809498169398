import * as React from "react";
import {createElement} from "react";
import {Card, Col, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";

import {collection, getCubaREST, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";

import "../../../app/App.css";

import {AddressRequest} from "../../../cuba/entities/base/tsadv$AddressRequest";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import moment from "moment";
import {goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import {DEFAULT_DATE_PATTERN, formatDate} from "../../util/Date/Date";
import {PersonContact} from "../../../cuba/entities/base/tsadv$PersonContact";
import {PersonContactRequestManagement} from "./PersonContactRequestManagement";
import {PersonContactRequest} from "../../../cuba/entities/base/tsadv_PersonContactRequest";
import {DicPhoneType} from "../../../cuba/entities/base/tsadv$DicPhoneType";
import {SearchSelect} from "../../components/SearchSelect";
import DefaultDatePicker from "../../components/Datepicker";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

type EditorProps = {
  entityId: string;
  addressId?: string;
  isRequest?:boolean;
}

@inject("rootStore")
@injectMainStore
@observer
class AddressRequestEditComponent extends AbstractBprocEdit<AddressRequest, EditorProps> {
  processDefinitionKey = "personContactRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<PersonContactRequest>(PersonContactRequest.NAME, {
    view: "personContactRequest-for-integration",
    loadImmediately: false
  });


  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  DicPhone = (this.isUserInitiator || this.props.entityId === 'new') ?  collection<DicPhoneType>(DicPhoneType.NAME, {
    view: "dicPhoneType-edit",
    filter:{
      conditions:[{
        property:'company.code',
        operator:"=",
        value:this.props.rootStore!.userInfo!.companyCode!
      }]
    }
  }):collection<DicPhoneType>(DicPhoneType.NAME, {
    view: "_minimal",

  });
  DicEmotyPhone = (this.isUserInitiator || this.props.entityId === 'new') ?  collection<DicPhoneType>(DicPhoneType.NAME, {
    view: "dicPhoneType-edit",
    filter:{
      conditions:[{
        property:'company.code',
        operator:"=",
        value: "empty"
      }]
    }
  }):collection<DicPhoneType>(DicPhoneType.NAME, {
    view: "_minimal",

  });
  @observable
  editAddress: PersonContact;

  instanceEditAddress = instance<PersonContact>(PersonContact.NAME, {
    view: "portal.my-profile",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  approverHrRoleCode: string;

  isUpdateBeforeOutcome = true;

  fields = [

    "requestNumber",

    "requestDate",

    "status",

    "startDate",

    "endDate",

    "contactValue",

    "type"

  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.personGroupId,
      personContact: this.editAddress ? this.editAddress.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {

    if (!this.mainStore) return <LoadingPage/>

    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>

    const entityName = this.dataInstance.entityName;

    return (
      <div>
        <ScrollToTopOnMount />
        <Page pageName={this.props.isRequest?this.props.intl.formatMessage({id:"personEducationRequest"}):<FormattedMessage id={"personContact"}/>}>
        <Section size={"large"}>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}
              />

              <Form.Item
                label={<FormattedMessage id={"personGroup"}/>}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>

              {
                this.editAddress ?
                  <Row type={"flex"} className={"data-form"}>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderAddress()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderAddressRequest()}
                    </Col>
                  </Row>
                  : this.renderAddressRequest()
              }
            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
      </div>
        );
  }

  renderAddress = () => {

    const entityName = this.dataInstance.entityName;
    

    return (
      <div>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "startDate"})}>
          <Input
            value={formatDate(this.editAddress.startDate)}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "endDate"})}>
          <Input
            value={formatDate(this.editAddress.endDate)}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "type"})}>
          <Input
            value={this.editAddress && this.editAddress.type ? this.editAddress.type['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "contactValue"})}>
          <Input
            value={this.editAddress ? this.editAddress.contactValue || '' : ''}
            disabled/>
        </Form.Item>

      </div>
    );
  }

  renderAddressRequest = () => {

    const messages = this.mainStore.messages!;

    console.log(this.changedMap)
    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();


    return (
      <div>

        <ReadonlyField
          entityName={entityName}
          propertyName="startDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.startDate']})
            }],
            getValueFromEvent: args => {
              if (this.editAddress)
                this.changedMap.set('startDate', this.editAddress.startDate ? !moment(this.editAddress.startDate).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            label:this.props.intl.formatMessage({id:"Contact.startDate"}),
            hasFeedback: this.changedMap.get('startDate'),
          }}
        />

        <Form.Item
          hasFeedback={this.changedMap.get("endDate")}
          label={<FormattedMessage id={"Contact.endDate"} />}
        >{this.props.form.getFieldDecorator("endDate", {
          initialValue:moment("31.12.9999","DD.MM.YYYY"),
          getValueFromEvent: args => {
            if (this.editAddress)
              this.changedMap.set('endDate', this.editAddress.endDate ? !moment(this.editAddress.endDate).isSame(args, 'day') : !!args)
            return args;
          }
        })
        (<DefaultDatePicker disabled={isNotDraft}  />)}

        </Form.Item>

        {/*<ReadonlyField*/}
        {/*  entityName={entityName}*/}
        {/*  propertyName="endDate"*/}
        {/*  disabled={isNotDraft}*/}
        {/*  form={this.props.form}*/}
        {/*  getFieldDecoratorOpts={{*/}
        {/*    initialValue:moment('31.12.9999', DEFAULT_DATE_PATTERN),*/}
        {/*    getValueFromEvent: args => {*/}
        {/*      if (this.editAddress)*/}
        {/*        this.changedMap.set('endDate', this.editAddress.endDate ? !moment(this.editAddress.endDate).isSame(args, 'day') : !!args);*/}
        {/*      return args;*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  formItemOpts={{*/}
        {/*    label:this.props.intl.formatMessage({id:"Contact.endDate"}),*/}
        {/*    hasFeedback: this.changedMap.get('endDate'),*/}
        {/*  }}*/}
        {/*/>*/}

        <ReadonlyField
          entityName={entityName}
          propertyName="type"
          disabled={isNotDraft}
          optionsContainer={(this.DicPhone && this.DicPhone.items && this.DicPhone.items!.length > 0) ?   this.DicPhone : this.DicEmotyPhone }
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.type']})
            }],
            getValueFromEvent: args => {
              const value = args;
              if (this.editAddress)
                this.changedMap.set('type', value !== this.editAddress.type);
              return value;
            }
          }}
          formItemOpts={{
            label:this.props.intl.formatMessage({id:"Contact.type"}),
            hasFeedback: this.changedMap.get('type'),
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="contactValue"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.contactValue']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('contactValue', value !== this.editAddress.contactValue);
              return value;
            }
          }}
          formItemOpts={{
            label:this.props.intl.formatMessage({id:"Value"}),
            hasFeedback: this.changedMap.get('contactValue'),
          }}
        />


      </div>
    );
  }
componentDidMount() {
  super.componentDidMount();
  this.DicPhone.load()
  if( this.DicPhone && this.DicPhone.items && this.DicPhone.items!.length<0){
    this.DicEmotyPhone.load()
  }
}

  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: AddressRequest | undefined) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();

    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const addressId = this.props.addressId || (item && item.baseAddress ? item.baseAddress.id : undefined)

    if (addressId)
      getCubaREST()!.loadEntity(PersonContact.NAME, addressId, {view: 'portal.my-profile'})
        .then(value => this.editAddress = value as PersonContact)
        .then(value => {
          this.instanceEditAddress.setItem(value);
          const properties = ["contactValue", "type"];
          if (this.props.entityId === PersonContactRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditAddress.getFieldValues(properties));
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

  protected initItem(request: AddressRequest) {
    this.dataInstance.setItem(request);

    const fieldValues = this.dataInstance.getFieldValues(this.fields);
    this.props.form.setFieldsValue(fieldValues);

    this.props.form.setFieldsValue({
      endDate:moment("31.12.9999","DD.MM.YYYY")
    })

  }


}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(AddressRequestEditComponent))
);
