import { SerializedEntity } from "@cuba-platform/rest";
import moment from "moment";
import { StandardEntity } from "../../../../cuba/entities/base/sys$StandardEntity";
import { JSON_DATE_TIME_FORMAT } from "../../../util/Date/Date";
import {DicCompany} from "../../../../cuba/entities/base/base_DicCompany";

const formatAmount = (value: string): string => {
  const split = value.split('.')
  return value ? split[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (split[1] ? '.' + split[1].substr(0, 2) : '') : value;
}

const parseStringToFloat = (value: string): number => {
  let res = (value + '').replace(/ /g, '');
  return parseFloat(res);
}


function wrapToSerialized<T extends StandardEntity>(entity?: T | null): SerializedEntity<T> {
  return entity as SerializedEntity<T>
}

const getValidGreaterZero = (msg: string) => [
  {
    transform: (value: string) => parseInt(value),
    min: 1,
    type: 'integer',
    message: msg
  }
]

const getFieldValue = (prop: string[], obj: any): any => {
  if (obj) {
    const curr = obj[prop[0]]
    if (prop.length === 1)
      return curr
    else
      return getFieldValue(prop.slice(1, prop.length), curr)
  } else {
    return ''
  }
}

const getAssignmentCondition = (nameValue?: string, ids?: string, organizationValue?: string): any[] => {
  const conditions = [
    {
      property: 'endDate',
      operator: ">=",
      value: moment().format(JSON_DATE_TIME_FORMAT)
    },
    {
      property: 'startDate',
      operator: "<=",
      value: moment().format(JSON_DATE_TIME_FORMAT)
    },
    {
      property: 'assignmentStatus.code',
      operator: "=",
      value: 'ACTIVE'
    },
    {
      property: 'primaryFlag',
      operator: "=",
      value: 'TRUE'
    },
    {
      group: 'OR', conditions: [
        {
          property: 'personGroup.relevantPerson.firstName',
          operator: "contains",
          value: nameValue
        },
        {
          property: 'personGroup.relevantPerson.middleName',
          operator: "contains",
          value: nameValue
        },
        {
          property: 'personGroup.relevantPerson.lastName',
          operator: "contains",
          value: nameValue
        },
        {
          property: 'personGroup.relevantPerson.firstNameLatin',
          operator: "contains",
          value: nameValue
        },
        {
          property: 'personGroup.relevantPerson.lastNameLatin',
          operator: "contains",
          value: nameValue
        },
        {
          property: 'personGroup.relevantPerson.middleNameLatin',
          operator: "contains",
          value: nameValue
        }
      ]
    },

  ]

  if (ids) {
    conditions.push({
      property: 'id',
      operator: "in",
      value: ids
    })
  }

  if (organizationValue) {
    conditions.push({
      property: 'organizationGroup.id',
      operator: "=",
      value: organizationValue
    })
  }

  return conditions;
}

const getOrganizationCondition = (organizationNameValue?: string, organizationCompanyValue?: DicCompany): any[] => {
  const condition = [
    {
      group: 'OR', conditions: [
        {
          property: 'organizationNameLang1',
          operator: "contains",
          value: organizationNameValue
        },
        {
          property: 'organizationNameLang2',
          operator: "contains",
          value: organizationNameValue
        },
        {
          property: 'organizationNameLang3',
          operator: "contains",
          value: organizationNameValue
        },
        {
          property: 'organizationNameLang4',
          operator: "contains",
          value: organizationNameValue
        },
        {
          property: 'organizationNameLang5',
          operator: "contains",
          value: organizationNameValue
        },
      ],
    },
  ]
  if (organizationCompanyValue){
    condition.push({
      group: 'AND', conditions: [
        {
          property: 'company.id',
          operator: "=",
          value: organizationCompanyValue.id
        }
      ]
    })
  }
  return condition;
}

export {
  formatAmount,
  parseStringToFloat,
  wrapToSerialized,
  getValidGreaterZero,
  getFieldValue,
  getAssignmentCondition,
  getOrganizationCondition
}