import React from "react";
import {restServices} from "../../../../cuba/services";
import {action, computed, observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {Form, Modal, Row} from "antd";
import TextArea from "antd/es/input/TextArea";
import {PersonGroupExt} from "../../../../cuba/entities/base/base$PersonGroupExt";
import {FormComponentProps} from "antd/es/form";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {rootStore} from "../../../store";
import {getCubaREST} from "@cuba-platform/react";
import {PersonAward} from "../../../../cuba/entities/base/tsadv$PersonAward";
import moment from "moment";
import {AwardProgram} from "../../../../cuba/entities/base/tsadv$AwardProgram";
import {DicPersonAwardType} from "../../../../cuba/entities/base/tsadv$DicPersonAwardType";
import {getPersonByPersonGroup} from "../../../util/personUtils";
import PersonFinder from "./PersonFinder";

type PersonAwardCreateModalProps = {
  personGroupId?: string;
  visible: boolean;
  onClose: () => void;
}


@observer
class PersonAwardCreateModal extends React.Component<PersonAwardCreateModalProps & FormComponentProps & WrappedComponentProps> {

  messages = this.props.intl.messages  as Record<string, string>;

  @observable selectedPerson?: PersonGroupExt;
  @observable history: string = "";
  @observable cause: string = "";

  @observable isSending = false;
  @observable title = this.messages["recognition.nominate.title"];

  activeAwardsProgram?: AwardProgram;
  type?: DicPersonAwardType;

  componentDidMount() {
    this.load();
    this.transformModalIfPersonGroupPassed();
  }

  @action transformModalIfPersonGroupPassed = async () => {
    const {personGroupId} = this.props;

    if (!personGroupId) {
      return;
    }

    const now = moment().format("YYYY-MM-DD");
    const personGroupEntity = await getPersonByPersonGroup(personGroupId, now, "_local");
    const personName = personGroupEntity["_instanceName"];

    this.selectedPerson = {id: personGroupId};
    this.title =  this.messages["recognition.nominate.title"] + " " + personName;
  }

  load = async () => {
    restServices.recognitionService.getActiveAwardProgram()
      .then((activeAwardsProgram) => runInAction(() => this.activeAwardsProgram = activeAwardsProgram))

    getCubaREST()!.searchEntities<DicPersonAwardType>(DicPersonAwardType.NAME, {
      conditions: [
        {
          property: "code",
          operator: "=",
          value: "HEART_AWARD"
        }
      ]
    }, {view: "_minimal"})
      .then(resp => resp[0] || {})
      .then((type) => runInAction(() => this.type = type))
  }

  save = () => {
    this.props.form.validateFields(async err => {
      if (err) {
        return;
      }

      runInAction(() => this.isSending = true);

      const author = {id: rootStore.personProfileStore!.personProfile!.groupId};
      const receiver = {id: this.selectedPerson!.id}

      const personAward: PersonAward = {
        author,
        receiver,
        history: this.history,
        why: this.cause,
        status: "NOMINATED",
        date: moment().format("YYYY-MM-DD"),
        awardProgram: this.activeAwardsProgram,
        type: this.type
      }

      await getCubaREST()!.commitEntity(PersonAward.NAME, personAward)
        .finally(() => runInAction(() => this.isSending = false));

      this.props.onClose();
    });
  }

  @action onChangePerson = (value: string) => {
    this.selectedPerson = {id: value} as PersonGroupExt;
  }

  @computed get historyLabel() {
    return this.history.length === 0
      ? this.messages["recognition.nominate.history.min"]
      : this.messages["recognition.nominate.history.current"] + this.history.length + this.messages["recognition.nominate.history.current.postfix"];
  }

  @computed get causeLabel() {
    return this.cause.length === 0
      ? this.messages["recognition.nominate.cause.min"]
      : this.messages["recognition.nominate.cause.current"] + this.cause.length + this.messages["recognition.nominate.cause.current.postfix"];
  }

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <Modal visible={this.props.visible}
             width={800}
             title={this.title}
             onOk={this.save}
             onCancel={this.props.onClose}>
        <Form>
          {!this.props.personGroupId &&
            <Form.Item label={this.messages["recognition.nominate.nominee"]} required={false}>
              {getFieldDecorator('nominee', {
                rules: [{required: true, message: this.messages["recognition.nominate.selectNominee"]}]
              })(<PersonFinder onChange={this.onChangePerson}/>)
              }
            </Form.Item>
          }

          <Row type={"flex"} justify={"space-between"}>
            <div>
              <FormattedMessage id={"recognition.nominate.history"}/>
            </div>
            <div>{this.historyLabel}</div>
          </Row>
          <Form.Item>
            {getFieldDecorator('history', {
              validateTrigger: 'onBlur',
              rules: [{required: true, message: this.messages["recognition.nominate.historyWalid"], min: 300}]
            })(<TextArea rows={4} onChange={event => runInAction(() => this.history = event.target.value)}/>)
            }
          </Form.Item>

          <Row type={"flex"} justify={"space-between"}>
            <div>
              <FormattedMessage id={"recognition.nominate.causeTitle"}/>
            </div>
            <div>{this.causeLabel}</div>
          </Row>
          <Form.Item>
            {getFieldDecorator('cause', {
              validateTrigger: 'onBlur',
              rules: [{required: true, message: this.messages["recognition.nominate.causeWalid"], min: 100}]
            })(<TextArea rows={4} onChange={event => runInAction(() => this.cause = event.target.value)}/>)
            }
          </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default injectIntl(Form.create<FormComponentProps & PersonAwardCreateModalProps & WrappedComponentProps>()(PersonAwardCreateModal));