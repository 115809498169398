import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";

import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {VacancyManagement} from "./VacancyManagement";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import Button, {ButtonType} from "../../components/Button/Button";
import {Table} from "antd";
import Page from "../../hoc/PageContentHoc";
import {Requisition} from "../../../cuba/entities/base/tsadv$Requisition";
import {observable} from "mobx";
import Notification from "../../util/Notification/Notification";
import {restServices} from "../../../cuba/services";
import {ColumnProps} from "antd/es/table";
import {CandidateJobRequestManagement} from "./CandidatejobRequestManagement";
import {JobRequestStatus} from "../../../cuba/enums/enums";
import {JobRequest} from "../../../cuba/entities/base/tsadv$JobRequest";
import moment from "moment";
import {DEFAULT_DATE_PATTERN, DEFAULT_DATE_TIME_PATTERN} from "../../util/Date/Date";

export type RequisitionDataParent = RequisitionDataBase & {
  organizationGroup: string,
  children?: RequisitionDataChild[],
  countOfJobRequestInCustomer: number,
  countOfAllJobRequest: number
}

export type RequisitionDataChild = RequisitionDataBase & {
  expenditureHeading: string
}

type RequisitionDataBase = {
  rId: string,
  vacancyId: string,
  positionGroupExt: string,
  lineManager: string,
  recruiter: string,
  dateOfRAF: string,
  numberOfRAF: string,
  nameRu: string,
  nameEn: string
}

@injectMainStore
@inject("rootStore")
@observer
class VacancyBrowseComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & RouteComponentProps<any>> {

  @observable
  treeData: RequisitionDataParent[];
  @observable
  treeLoading: boolean = true;
  @observable
  columnsOptions = {
    'current': true,
    'changes': true,
    'difference': true,
  };

  constructor(props: any) {
    super(props);
    this.reloadTreeData();
  }

  dataCollection = collection<Requisition>(Requisition.NAME, {
    view: "requisition.view",
    sort: "-code",
    filter: {
      conditions: [{property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!}]
    }
  });

  locale = this.props.mainStore!.locale!;


  fetchDataService = restServices.requisitionService.getRequisitionTreeData.bind(null, {});

  reloadTreeData = () => {
    this.treeLoading = true;
    this.fillTreeData(this.fetchDataService());
  };

  fillTreeData = (fetchServiceResponse: Promise<Array<RequisitionDataParent>>) => {
    fetchServiceResponse.then(loadedData => {
      this.treeData = loadedData;
    })
      .catch(async (response: any) => {
        Notification.error({message: this.props.intl.formatMessage({id: "management.editor.error"})});
      })
      .finally(() => {
        this.treeLoading = false;
      })
  }

  renderCVInCustomerCountColumn = (text: any, row: RequisitionDataParent, index: number) => {
    return (
      <Link
        to={
          CandidateJobRequestManagement.PATH + "/" + row.vacancyId + "/" + JobRequestStatus.CV_IN_CUSTOMER.toLowerCase()
        }
        key="create" >
        <label style={{cursor:'pointer'}}>{row.countOfJobRequestInCustomer}</label>
      </Link>
    )
  }

  renderAllJobRequestsColumn = (text: any, row: RequisitionDataParent, index: number) => {
    return (
      <Link
        to={
          CandidateJobRequestManagement.PATH + "/" + row.vacancyId + "/all"
        }
        key="create" >
        <label style={{cursor:'pointer'}}>{row.countOfAllJobRequest}</label>
      </Link>
    )
  }

  renderDateColumn = (text: any, row: RequisitionDataParent, index: number) => {
    return (
      <label>{moment(row.dateOfRAF).format(DEFAULT_DATE_PATTERN)}</label>
    )
  }

  render() {
    const messages = this.props.mainStore!.messages!;
    const locale = this.locale;

    const buttons = [
      <Link
        to={
          VacancyManagement.PATH + "/" + VacancyManagement.NEW_SUBPATH
        }
        key="create">
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}>
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>
    ];

    let columns = [
      {
        title: this.props.intl.formatMessage({id: 'organizationGroup'}),
        dataIndex: 'organizationGroup',
        key: 'organizationGroup'
      },
      {
        title: this.props.intl.formatMessage({id: 'vacancy.position'}),
        dataIndex: 'positionGroupExt',
        key: 'positionGroupExt'
      }, {
        title: this.props.intl.formatMessage({id: 'lineManager'}),
        dataIndex: 'lineManager',
        key: 'lineManager',
      }, {
        title: this.props.intl.formatMessage({id: 'dateOfRaf'}),
        dataIndex: 'dateOfRAF',
        key: 'dateOfRAF',
        render: this.renderDateColumn
      }, {
        title: this.props.intl.formatMessage({id: 'numberOfRaf'}),
        dataIndex: 'numberOfRAF',
        key: 'numberOfRAF',
      }, {
        title: this.props.intl.formatMessage({id: 'recruiter'}),
        dataIndex: 'recruiter',
        key: 'recruiter',
      }, {
        title: this.props.intl.formatMessage({id: 'countOfJobRequestInCustomer'}),
        dataIndex: 'countOfJobRequestInCustomer',
        key: 'countOfJobRequestInCustomer',
        render: this.renderCVInCustomerCountColumn
      }, {
        title: this.props.intl.formatMessage({id: 'countOfAllJobRequest'}),
        dataIndex: 'countOfAllJobRequest',
        key: 'countOfAllJobRequest',
        render: this.renderAllJobRequestsColumn
      }
    ] as ColumnProps<RequisitionDataParent>[]

    return (
      <Page pageName={this.props.intl.formatMessage({id: "menu.vacancies"})}>
        <div style={{boxShadow:"0 3px 24px rgb(0 0 0 / 10%)",borderRadius:4,padding:10, height:"fit-content"}}>
        <Table columns={columns}
               loading={this.treeLoading}
               dataSource={Array.from(this.treeData || '')}
               rowKey={(r: RequisitionDataParent) => r.vacancyId}
               bordered={false}

        />
        </div>
      </Page>
    );
  }

}

const VacancyBrowse = injectIntl(withRouter(VacancyBrowseComponent));

export default VacancyBrowse;