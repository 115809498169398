import React from "react";
import {inject, observer} from "mobx-react";
import {collection, injectMainStore, MainStoreInjected, withLocalizedForm} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Button as AntdButton, Spin, Table} from "antd";
import Column from "antd/es/table/Column";
import {action, observable, runInAction} from "mobx";
import {FormComponentProps} from "antd/lib/form";
import {DicAssessmentStatus} from "../../../cuba/entities/base/tsadv$DicAssessmentStatus";
import {restServices} from "../../../cuba/services";
import {RootStoreProp} from "../../store";
import moment from "moment";
import SearchFilterDropdown from "../AnnualSalaryRevision/SearchFilterDropdown";
import {DicParticipantType} from "../../../cuba/entities/base/tsadv$DicParticipantType";
import {Link} from "react-router-dom";

interface ScaleLevel {
  scale_level_id: string;
  lang1: string;
  lang2: string;
  lang3: string;
  level_score: number;
}

interface Participant {
  participant_id: string | undefined;
  row_id: string;
  entity_id: string;
  entity_name: string;
  person_group_id: string;
  employee_name: string;
  participant_role_code: string;
  role_name: string;
  participant_order: number;
  scale_level_id: string;
  scale_level: string;
  comments: string;
  required_to_train?: any;
  scale_levels: ScaleLevel[];
  has_comments: boolean;
  participant_status_code: string;
}

interface RootObject {
  children: any;
  person_assessment_id: string;
  row_id: string;
  assessment_competence_id: string;
  competence_source: string;
  competence_type_id: string;
  competence_type: string;
  competence_group_id: string;
  competence_name: string;
  required_scale_level_id: string;
  required_scale_level: string;
  scale_levels: ScaleLevel[];
  entityName: string;
  result_percent: number;
  result_id?: any;
  result?: any;
  required_to_train: boolean;
  delta: number;
  has_comments: boolean;
  participants: Participant[];
};

type MainData = {
  date_from: string | null;
  date_to: string | null;
  is_edit: boolean | null;
  employeeFullName: string | null;
  participant_person_group_id: string | null;
  participant_type_code: string | null;
  person_group_id: string | null;
  session_name: string | null;
  status_code: string | null;
  total_result: string | null;
  person_assessment_id: string | null;
  instruction: string | null;
  participant_status_id: string | null;
  participant_status_code: string | null;
  participant_result: number | null | undefined;
};

interface RootObjectWithKey extends RootObject {
  key: string;
}

type Props = {
  assignedPerformancePlanId: string;
  personInitiatorId: string;
  initiatorId: string | undefined;
  setCompetenceTotalResult: (totalResult: number) => void;
  setAssessmentStatus: (assessmentStatus: string) => void;
};

@inject("rootStore")
@injectMainStore
@observer
class MenuCbaList extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & Props & FormComponentProps> {
  @observable mainTableData: Array<MainData>;
  @observable expanded = false;
  @observable toDeleteArray: any = [];
  @observable participant_type_code: string;
  @observable participant_status_code: string;
  @observable assessmentSessionId: string;
  @observable totalResult: string | null;
  @observable competeceTotalResult: number | null;
  @observable assessmentStatus: string | null;
  @observable instruction: string | null;
  @observable result: undefined | string | null;
  @observable changedRequired = {};
  @observable groupScale: any;
  @observable filteredData: any = null;
  @observable date_from: string;
  @observable date_to: string;
  @observable session_name: string;
  @observable participantTypeCode: string;
  @observable assessmentId: string;
  @observable loading: boolean = false;

  personGroup = this.props.rootStore!.userInfo.personGroupId;

  @action handleChange = (pagination: any, filters: any, sorters: any) => {
    this.filteredData = filters;
  };

  @action emptyFilters = (name?: string) => {
    if (name) {
      this.filteredData[name] = null;
    } else {
      this.filteredData = null;
    }
  };

  @observable statusesDc = collection<DicAssessmentStatus>(DicAssessmentStatus.NAME, {view: "_local"});
  @observable assessorTypeDc = collection(DicParticipantType.NAME, {view: "dicParticipantType-browse"});

  componentDidMount() {
    runInAction(() => this.loading = true);

    restServices.learningService
      .getPersonAssessments({
        personGroupId: this.personGroup!,
        lang: this.props.rootStore!.userInfo!.locale!
      })
      .then(value => {
        this.mainTableData = JSON.parse(value && value[0] && value[0]!.value!);
      })
      .finally(() => runInAction(() => this.loading = false));
  }

  render() {
    let filteredData = this.filteredData || {};

    const isNull = Object.values(filteredData).every(value => {
      return value === null || !value[0];
    });

    return (
      <Spin spinning={this.loading}>
        {!isNull ? (
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <AntdButton
              type={"link"}
              onClick={() => this.emptyFilters()}
              icon={"delete"}
            >
              {this.props.intl.formatMessage({
                id: "cubaReact.dataTable.clearAllFilters"
              })}
            </AntdButton>
          </div>
        ) : null}
        <Table
          onChange={this.handleChange}
          dataSource={this.mainTableData}
          bordered={false}
          rowKey={record => record.person_assessment_id!}
        >
          <Column
            filteredValue={filteredData.employeeFullName || null}
            onFilter={(value, record) => {
              return record.employeeFullName
                .toLowerCase()
                .includes(value.toString().toLowerCase());
            }}
            filterDropdown={({
                               confirm,
                               selectedKeys,
                               setSelectedKeys,
                               clearFilters
                             }) => (
              <SearchFilterDropdown
                emptyFilters={this.emptyFilters}
                date={false}
                type={"has"}
                text={"employee"}
                clearFilters={clearFilters}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                selectedKeys={selectedKeys}
              />
            )}
            sorter={(a: any, b: any) => {
              return a.employeeFullName.localeCompare(b.employeeFullName);
            }}
            title={<>{this.props.intl.formatMessage({id: "employee"})}</>}
            dataIndex={"employeeFullName"}
            key={"employeeFullName"}
          />
          <Column
            title={this.props.intl.formatMessage({id: "cbaForm"})}
            render={(text, record: MainData) => (
              <Link to={`/menuCba/${record!.person_assessment_id!}`}>
                {this.props.intl.formatMessage({id: "cbaForm"})}
              </Link>
            )}
            key={"cbaform"}
          />
          <Column
            filteredValue={filteredData.template || null}
            onFilter={(value, record) => {
              return record.session_name
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase());
            }}
            filterDropdown={({
                               confirm,
                               selectedKeys,
                               setSelectedKeys,
                               clearFilters
                             }) => (
              <SearchFilterDropdown
                emptyFilters={this.emptyFilters}
                date={false}
                type={"has"}
                text={"cba.FormTemplateName"}
                clearFilters={clearFilters}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                selectedKeys={selectedKeys}
              />
            )}
            sorter={(a: any, b: any) => {
              return a.session_name.localeCompare(b.session_name);
            }}
            title={this.props.intl.formatMessage({
              id: "cba.FormTemplateNameTable"
            })}
            dataIndex={"session_name"}
            key={"template"}
          />
          <Column
            filteredValue={filteredData.role || null}
            onFilter={(value, record) => {
              return record.participant_type_code === value;
            }}
            filterDropdown={({
                               confirm,
                               selectedKeys,
                               setSelectedKeys,
                               clearFilters
                             }) => (
              <SearchFilterDropdown
                code={true}
                selectItems={this.assessorTypeDc.items}
                emptyFilters={this.emptyFilters}
                date={false}
                type={"equals"}
                text={"assessor"}
                clearFilters={clearFilters}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                selectedKeys={selectedKeys}
              />
            )}
            sorter={(a: any, b: any) => {
              return a.participant_type_code.localeCompare(
                b.participant_type_code
              );
            }}
            title={this.props.intl.formatMessage({id: "assessor"})}
            dataIndex={"participant_type_code"}
            render={text => {
              let data = this.assessorTypeDc.items.find(
                (item: any) => item.code === text
              );
              return data && data._instanceName;
            }}
            key={"role"}
          />
          <Column
            filteredValue={filteredData.total_result || null}
            onFilter={(value, record) => {
              return parseInt(value) === parseInt(record.total_result);
            }}
            filterDropdown={({
                               confirm,
                               selectedKeys,
                               setSelectedKeys,
                               clearFilters
                             }) => (
              <SearchFilterDropdown
                emptyFilters={this.emptyFilters}
                date={false}
                type={"equals"}
                text={"cba.totalResult"}
                clearFilters={clearFilters}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                selectedKeys={selectedKeys}
              />
            )}
            sorter={(a: any, b: any) => {
              return a.total_result - b.total_result;
            }}
            title={this.props.intl.formatMessage({id: "cba.totalResult"})}
            dataIndex={"total_result"}
            key={"total_result"}
          />
          <Column
            filteredValue={filteredData.status || null}
            onFilter={(value, record) => {
              return value === record.participant_status_code;
            }}
            filterDropdown={({
                               confirm,
                               selectedKeys,
                               setSelectedKeys,
                               clearFilters
                             }) => (
              <SearchFilterDropdown
                selectItems={this.statusesDc.items}
                code
                emptyFilters={this.emptyFilters}
                date={false}
                type={"equals"}
                text={"status"}
                clearFilters={clearFilters}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                selectedKeys={selectedKeys}
              />
            )}
            sorter={(a: any, b: any) => {
              let aData = this.statusesDc.items.find(
                (item: any) => item.code === a.participant_status_code
              );
              let bData = this.statusesDc.items.find(
                (item: any) => item.code === b.participant_status_code
              );
              return aData!.code!.localeCompare(bData!.code!);
            }}
            title={this.props.intl.formatMessage({id: "status"})}
            dataIndex={"participant_status_code"}
            render={text => {
              let data = this.statusesDc.items.find(
                (item: any) => item.code === text
              )!;
              return data && data._instanceName;
            }}
            key={"status"}
          />

          <Column
            dataIndex={"overalRating"}
            key={"sendForm"}
            render={(text, record: MainData, index) =>
              record.is_edit &&
              record.participant_status_code !== "SEND" &&
              record.status_code !== "COMPLETED" &&
              moment().isBetween(
                record.date_from,
                record.date_to,
                "days",
                "[]"
              ) ? (
                <Link to={`/menuCba/${record!.person_assessment_id!}`}>
                  {this.props.intl.formatMessage({id: "cbaComplete"})}
                </Link>
              ) : (
                ""
              )
            }
          />
        </Table>
      </Spin>
    );
  }

}

export default injectIntl(
  withLocalizedForm({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(MenuCbaList)
);
