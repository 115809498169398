import React, { Component } from 'react';
import { Card, List } from "antd";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { restServices } from "../../../cuba/services";
import { inject, observer } from "mobx-react";
import { action, observable } from "mobx";
import { RootStoreProp } from "../../store";
import { getCubaREST } from "@cuba-platform/react";
import { PersonExt } from "../../../cuba/entities/base/base$PersonExt";
import { DicCompany } from "../../../cuba/entities/base/base_DicCompany";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import { SerializedEntity } from "@cuba-platform/rest";
import { PositionGroupExt } from "../../../cuba/entities/base/base$PositionGroupExt";
import {getBlobUrl, getFileUrl} from "../../util/util";
import Img from "../../components/Img";
import { NotFoundImage } from "../../components/NoImage";
import {BirthdayDto} from "../../../cuba/entities/dto/BirthdayDto";


@inject("rootStore")
@observer
class BirthdayPeople extends Component<WrappedComponentProps & RootStoreProp> {

  @observable birthdayPeople: BirthdayDto[] = [];

  render() {
    return (
      <Card title={this.props.intl.formatMessage({id: "homePage.birthdayPeople"})} bordered={false}>
        <List
          itemLayout="horizontal"
          dataSource={this.birthdayPeople}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                className="centered-flex-container birthday-man"
                avatar={<Img type="src" src={item.img != null ? getFileUrl(item.img!) : null} className="avatar" noFoundImage={NotFoundImage.EMPLOYEE}/>}
                title={<span>{item.name}</span>}
                description={<>
                  <div>{item.position}</div>
                  <div>{item.localizedDate}</div>
                </>}
              />
            </List.Item>
          )}
        />
      </Card>
    );
  }

  fetchBirthdayPeople = async () => {
    let birthdayDto = await restServices.employeeService.getBirthdayPeopleForCurrentMonthList();
    this.setBirthdayPeople(birthdayDto);
  }

  @action
  setBirthdayPeople = (birthdayPeople: BirthdayDto[]) => {
    this.birthdayPeople = birthdayPeople;
  }

  componentDidMount(): void {
    this.fetchBirthdayPeople();
  }
}

export default injectIntl(BirthdayPeople);