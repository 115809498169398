import * as React from "react";
import {observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";
import {observable} from "mobx";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {MartialStatusRequestManagement} from "../MartialStatusRequest/MartialStatusRequestManagement"
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Beneficiary} from "../../../cuba/entities/base/tsadv$Beneficiary"
import DataTableFormat, { ColumnRender } from "../../components/DataTable/intex";
import {withRouter} from "react-router";
import Button, {ButtonType} from "../../components/Button/Button";
import {BeneficiaryRequest} from "../../../cuba/entities/base/tsadv_BeneficiaryRequest";

export type MartialStatusListProps ={
  personGroupId: string;
}

@injectMainStore
@observer
class MartialStatusListComponent extends React.Component<MartialStatusListProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps>{
  dataCollection = collection<Beneficiary>(Beneficiary.NAME,{
    view: "beneficiaryView",
    sort: "-updateTs",
    filter: {
      conditions: [{
        property: 'personGroupParent.id',
        operator: '=',
        value: this.props.personGroupId
      }]
    }
  });
  
  fields=[
    "relationshipType",
    "lastName",
    "firstName",
    "middleName",
    "birthDate",
    "addressType",
    "postalCode",
    "country",
    "addressKATOCode",
    "streetType",
    "streetName",
    "building",
    "block",
    "flat",
    "addressForExpats",
    "additionalContact",
  ]

  @observable selectedRowKey: string | undefined;

  render () {

    const buttons =[
      <Link to={
        MartialStatusRequestManagement.PATH +
          "/" +
        MartialStatusRequestManagement.NEW_SUBPATH
      }
      key="create">
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}
                key="createBtn"
        >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>,
      <Button
        buttonType={ButtonType.FOLLOW}
        style={{margin: "0 12px 12px 0"}}
        disabled={!this.selectedRowKey}
        onClick={this.openRequest}
        key="editBtn"
      >
        <FormattedMessage id="management.browser.edit"/>
      </Button>
    ];

    // Переопределяем отображение колонок,
    // все остальные колонки будут отображаться корректно
    const customRenderOfColumns: ColumnRender<Beneficiary>[] | undefined = [
      {
        column: "firstNameLatin",
        render: (text, record) => record.firstNameLatin
      },
      {
        column: "lastNameLatin",
        render: (text, record) => record.lastNameLatin
      },
    ]

    return(
      <DataTableFormat
        dataCollection={this.dataCollection}
        enableFiltersOnColumns={[]}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
        render={customRenderOfColumns}
      />
    )
  }

  openRequest = () => {
    this.getRequestId()
      .then(value => this.props.history!.push(MartialStatusRequestManagement.PATH + '/' + value));
  }

  getRequestId = (): Promise<string> => {
    if (!this.selectedRowKey) return new Promise<string>(resolve => resolve(MartialStatusRequestManagement.NEW_SUBPATH));
    return getCubaREST()!.searchEntities<BeneficiaryRequest>(BeneficiaryRequest.NAME, {
      conditions: [{
        property: 'beneficiary.id',
        operator: '=',
        value: this.selectedRowKey!
      }, {
        property: 'status.code',
        operator: 'in',
        value: ['DRAFT', 'TO_BE_REVISED', 'APPROVING']
      }]
    }, {
      view: 'beneficiaryRequest-for-integration'
    }).then(values => {
      if (!values || values.length === 0) {
        return MartialStatusRequestManagement.NEW_SUBPATH + '/' + this.selectedRowKey!;
      } else {
        const approvingRequest = values.find(value => value!.status!.code === 'APPROVING');
        return approvingRequest ? approvingRequest.id : values[0].id;
      }
    });
  }

  handleRowSelectionChange = (selectedRowKeys: string[])=>{
    this.selectedRowKey = selectedRowKeys[0];
  }
}

const MartialStatusList = injectIntl(withRouter(MartialStatusListComponent));

export default MartialStatusList;