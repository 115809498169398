import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import {RootStoreProp} from "../../../store";
import RecognitionComponent from "./RecognitionComponent";
import {Col, Row, Spin} from "antd";
import {action, computed} from "mobx";
import Search from "antd/es/input/Search";
import {RecognitionsStore} from "../store/RecognitionsStore";
import InfiniteScroll from 'react-infinite-scroller';

type RecognitionListProps = {
  recognitionsStore: RecognitionsStore
}

@inject("rootStore")
@injectMainStore
@observer
class RecognitionList extends React.Component<RootStoreProp & WrappedComponentProps & RecognitionListProps> {
  wasError = false;

  @computed get hasMore() {
    return this.props.recognitionsStore.hasMore
    && !!this.props.recognitionsStore.personGroup
    && !this.wasError
  }

  render() {
    const spin = <Row type={"flex"} justify={"center"} style={{height: "40px"}}>
      <Spin/>
    </Row>

    return <>
      <Row gutter={40}>
        <Col xs={24}>
          <Search onSearch={this.onSearch} loading={this.props.recognitionsStore.isLoading}/>
        </Col>
      </Row>

      <InfiniteScroll hasMore={this.hasMore} loadMore={this.loadMore} loader={spin}>
        {this.props.recognitionsStore.recognitions.map(recognition => <RecognitionComponent recognition={recognition}/>)}
      </InfiniteScroll>
    </>
  }

  loadMore = async () => {
    await this.props.recognitionsStore.loadNextRecognitionsPage().catch(e => {
      this.wasError = true;
      throw e;
    })
  }

  @action onSearch = async (value: string) => {
    await this.props.recognitionsStore.setCurrentFilter(value)
  }
}

export default injectIntl(RecognitionList);