import React, {Component} from 'react';
import {Carousel, Icon} from "antd";
import {wrapSrcBase64} from "../../util/util";
import {observer} from "mobx-react";

type ImageType = "base64" | "src"

export type SliderFile = {
  type: ImageType,
  src: string,
  url?: string
}

type CarouselProps = {
  images?: SliderFile[]
}

@observer
export default class extends Component<CarouselProps> {

  imagesCarousel: Carousel | null;

  render() {
    const imagesCarouselElements = this.props.images
      ? this.props.images.map((image) => {
        const imageContainer =
          <img alt={"no image"} style={{width:"100%",height:"auto"}}
               src={image.type === "base64" ? wrapSrcBase64(image.src) : image.src}/>
        return image.url ? (<a href={image.url}>{imageContainer}</a>) : imageContainer
      })
      : null;

    const carouselNextArrow = (
      <Icon
        type="right"
        className="main-carousel-bottom-menu-arrow carousel-next-arrow"
        onClick={() => this.imagesCarousel!.next()}
      />
    );

    const carouselPreviousArrow = (
      <Icon
        type="left"
        className="main-carousel-bottom-menu-arrow carousel-prev-arrow"
        onClick={() => this.imagesCarousel!.prev()}
      />);

    return (
      <div className="home-page-carousel">
      <Carousel dots={false} ref={prop => (this.imagesCarousel = prop)}  autoplay >
        {imagesCarouselElements}
      </Carousel>
        {carouselPreviousArrow}
        {carouselNextArrow}
      </div>
    );
  }
}