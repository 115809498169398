import * as React from "react";
import {observer} from "mobx-react";

import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import WccfList from "./WccfList";

@injectMainStore
@observer
class WccfManagementPageComponent extends React.Component<MainStoreInjected & WrappedComponentProps> {
  render() {
    return (
      <Page pageName={this.props.intl.formatMessage({id: "menu.hr-request-manager"})}>
        <Section size="large">
          <WccfList/>
        </Section>
      </Page>
    );
  }
}

const WccfManagerPage = injectIntl(WccfManagementPageComponent);

export default WccfManagerPage;
