import { AbstractDictionary } from "./AbstractDictionary";
import { DicPromotionType } from "./tsadv$DicPromotionType";
export class DicKindOfAward extends AbstractDictionary {
  static NAME = "tsadv_DicKindOfAward";
  promotionType?: DicPromotionType | null;
}
export type DicKindOfAwardViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "dicKindOfAward.edit";
export type DicKindOfAwardView<
  V extends DicKindOfAwardViewName
  > = V extends "_base"
  ? Pick<
    DicKindOfAward,
    | "id"
    | "langValue"
    | "langValue1"
    | "langValue2"
    | "langValue3"
    | "langValue4"
    | "langValue5"
    | "legacyId"
    | "organizationBin"
    | "integrationUserLogin"
    | "description1"
    | "description2"
    | "description3"
    | "description4"
    | "description5"
    | "startDate"
    | "endDate"
    | "code"
    | "isSystemRecord"
    | "active"
    | "isDefault"
    | "order"
    >
  : V extends "_local"
    ? Pick<
      DicKindOfAward,
      | "id"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
      >
    : V extends "_minimal"
      ? Pick<
        DicKindOfAward,
        | "id"
        | "langValue"
        | "langValue1"
        | "langValue2"
        | "langValue3"
        | "langValue4"
        | "langValue5"
        >
      : V extends "dicKindOfAward.edit"
        ? Pick<
          DicKindOfAward,
          | "id"
          | "legacyId"
          | "organizationBin"
          | "integrationUserLogin"
          | "langValue1"
          | "description1"
          | "langValue2"
          | "description2"
          | "langValue3"
          | "description3"
          | "langValue4"
          | "description4"
          | "langValue5"
          | "description5"
          | "startDate"
          | "endDate"
          | "code"
          | "isSystemRecord"
          | "active"
          | "isDefault"
          | "order"
          | "promotionType"
          | "company"
          >
        : never;
