import { StandardEntity } from "./sys$StandardEntity";
import { PersonExt } from "./base$PersonExt";
import { DicQuestionnaireCategory } from "./tsadv_DicQuestionnaireCategory";
export class EmployeeQuestionnaire extends StandardEntity {
  static NAME = "tsadv_EmployeeQuestionnaire";
  nameLang1?: string | null;
  nameLang2?: string | null;
  nameLang3?: string | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  description?: string | null;
  questionnaireAdministrator?: PersonExt | null;
  category?: DicQuestionnaireCategory | null;
  name?: string | null;
}
export type EmployeeQuestionnaireViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "employeeQuestionnaire-view_edit"
  | "employeeQuestionnaire-view_new";
export type EmployeeQuestionnaireView<
  V extends EmployeeQuestionnaireViewName
> = V extends "_base"
  ? Pick<
      EmployeeQuestionnaire,
      | "id"
      | "name"
      | "nameLang1"
      | "nameLang2"
      | "nameLang3"
      | "dateFrom"
      | "dateTo"
      | "description"
    >
  : V extends "_local"
  ? Pick<
      EmployeeQuestionnaire,
      | "id"
      | "nameLang1"
      | "nameLang2"
      | "nameLang3"
      | "dateFrom"
      | "dateTo"
      | "description"
      | "name"
    >
  : V extends "_minimal"
  ? Pick<EmployeeQuestionnaire, "id" | "name">
  : V extends "employeeQuestionnaire-view_edit"
  ? Pick<
      EmployeeQuestionnaire,
      | "id"
      | "dateFrom"
      | "dateTo"
      | "nameLang1"
      | "nameLang2"
      | "nameLang3"
      | "name"
      | "description"
      | "questionnaireAdministrator"
      | "category"
    >
  : V extends "employeeQuestionnaire-view_new"
  ? Pick<
      EmployeeQuestionnaire,
      | "id"
      | "nameLang1"
      | "nameLang2"
      | "nameLang3"
      | "dateFrom"
      | "dateTo"
      | "description"
      | "questionnaireAdministrator"
      | "name"
    >
  : never;
