import * as React from "react";
import TabSheets from "./TabSheets";

export type PositionDetailsProps = {
  selectedPosition?: string
  defaulId?:string
}

export class PositionDetails extends React.Component<PositionDetailsProps> {

  render(): React.ReactNode {
    if (this.props.selectedPosition && this.props.selectedPosition.length > 0) {
      return <TabSheets selectedPosition={this.props.selectedPosition}/>
    } else
      return <TabSheets selectedPosition={this.props.defaulId}/>
  }
}
