import * as React from "react";
import {Card, Col, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {observable, runInAction} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";
import {
  collection,
  DataCollectionStore,
  getCubaREST,
  injectMainStore,
  instance,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";
import "../../../app/App.css";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {PersonDocument} from "../../../cuba/entities/base/tsadv$PersonDocument";
import moment from "moment";
import {PersonDocumentRequestManagement} from "../PersonDocumentRequest/PersonDocumentRequestManagement";
import {goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import {PersonAwardsDegrees} from "../../../cuba/entities/base/tsadv_PersonAwardsDegrees";
import {PersonAwardsDegreesRequest} from "../../../cuba/entities/base/tsadv_PersonAwardsDegreesRequest";
import {DicPromotionType} from "../../../cuba/entities/base/tsadv$DicPromotionType";
import {DicKindOfAward} from "../../../cuba/entities/base/tsadv_DicKindOfAward";
import {SerializedEntity} from "@cuba-platform/rest";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

type EditorProps = {
  entityId: string;
  addressId?: string;
};

@inject("rootStore")
@injectMainStore
@observer
class PersonAwardsDegreesRequestEditComponent extends AbstractBprocEdit<PersonAwardsDegreesRequest, EditorProps> {
  processDefinitionKey = "personAwardsDegreesRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<PersonAwardsDegreesRequest>(PersonAwardsDegreesRequest.NAME, {
    view: "personAwardsDegreesRequest.edit",
    loadImmediately: false
  });

  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  @observable
  editPersonAwardsDegrees: PersonAwardsDegrees;

  instanceEditPersonAwardsDegrees = instance<PersonAwardsDegrees>(PersonAwardsDegrees.NAME, {
    view: "personAwardsDegrees.edit",
    loadImmediately: false
  });

  typeDc = collection<DicPromotionType>(DicPromotionType.NAME,{
    view: "_minimal",
    sort: this.dicLangValue,
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  })

  @observable
  kindDc: DataCollectionStore<DicKindOfAward> = collection<DicKindOfAward>(DicKindOfAward.NAME,{
    view: "dicKindOfAward.edit",
    sort: this.dicLangValue,
    filter:{
      conditions:[{
        property:"company.code",
        operator:"=",
        value:this.props.rootStore!.userInfo!.companyCode!
      }]
    }
  })

  @observable promotionTypeId:string
  getDicKindOfAward =(promotionTypeId:string)=>{
    getCubaREST()!.searchEntities<DicKindOfAward>(DicKindOfAward.NAME,{
      conditions:[{
        property:"promotionType.id",
        operator:"=",
        value:promotionTypeId!
      }]
    },{
      view: "dicKindOfAward.edit",
      sort: this.dicLangValue,
    }).then((value=> {
      runInAction(()=>{
        this.kindDc.items = value
      })
    }))
  }


  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  approverHrRoleCode: string;

  isUpdateBeforeOutcome = true;

  fields = [
    "requestNumber",
    "requestDate",
    "status",
    "type",
    "kind",
    "startDate",
    "endDate",
    "description",
    "file"
  ];


  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.personGroupId,
      ...super.getUpdateEntityData(),
      employee:this.person && this.person!.group! && this.person.group!.id!,
    };
  }

  render() {
    if (!this.mainStore) return <LoadingPage/>
    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    return (
      <div>
        <ScrollToTopOnMount />
      <Page pageName={<FormattedMessage id={"personAwardsDegrees.title"}/>}>
        <Section className='large'>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}
              />
              <Form.Item
                label={this.props.intl.formatMessage({id:"employee.personName"})}
              >
                <Input
                  value={this.person ? this.person["_instanceName"] || "" : ""}
                  disabled
                />
              </Form.Item>
              {
               this.renderPersonAwardsDegreesRequest()
              }



              <ReadonlyField
                entityName={entityName}
                propertyName="file"
                disabled={isNotDraft}
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"},label:this.props.intl.formatMessage({id:"personAwardsDegrees.file"})}}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.file']})
                  }],
                }}
              />

            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
      </div>
    );
  }



  renderPersonAwardsDegreesRequest = () => {

    const messages = this.mainStore.messages!;

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    const isHr = this.approverHrRoleCode === 'HR';
    let arrayWithCode:any = {items:[]};
    let arrayWithDefaultCode:any = {items:[]};
    this.typeDc.items.forEach(item=>{
      if(item && item.company! && item.company!.code !=="empty"){
        arrayWithCode.items && arrayWithCode.items.push(item)
      }else arrayWithDefaultCode.items && arrayWithDefaultCode.items.push(item)
    })
    return (
      <div>

        <ReadonlyField
          entityName={entityName}
          propertyName="type"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={arrayWithCode.items && arrayWithCode!.items!.length === 0 ? arrayWithDefaultCode : arrayWithCode}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.type']})
            }],
            getValueFromEvent: args => {
              console.log(args)
              this.getDicKindOfAward(args)
              if (this.editPersonAwardsDegrees)
                this.changedMap.set('type', args !== (this.editPersonAwardsDegrees.type ? this.editPersonAwardsDegrees.type.id : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('type'),
            label:this.props.intl.formatMessage({id:"personAwardsDegrees.type"})
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="kind"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={this.kindDc}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.kind']})
            }],
            getValueFromEvent: args => {
              if (this.editPersonAwardsDegrees)
                this.changedMap.set('kind', args !== (this.editPersonAwardsDegrees.kind ? this.editPersonAwardsDegrees.kind.id : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('kind'),
            label:this.props.intl.formatMessage({id:"personAwardsDegrees.kind"})
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="startDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.startDate']})
            }],
            getValueFromEvent: args => {
              if (this.editPersonAwardsDegrees)
                this.changedMap.set('startDate', this.editPersonAwardsDegrees.startDate ? !moment(this.editPersonAwardsDegrees.startDate).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('startDate'),
            label:this.props.intl.formatMessage({id:"personAwardsDegrees.startDate"})
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="endDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.endDate']})
            }],
            getValueFromEvent: args => {
              if (this.editPersonAwardsDegrees)
                this.changedMap.set('endDate', this.editPersonAwardsDegrees.endDate ? !moment(this.editPersonAwardsDegrees.endDate).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('endDate'),
            label:this.props.intl.formatMessage({id:"personAwardsDegrees.endDate"})
          }}
        />
        <Form.Item
          hasFeedback={this.changedMap.get('description')}
          label={this.props.intl.formatMessage({id:"personAwardsDegrees.description"})}
        >
          {this.props.form.getFieldDecorator("description",{
            rules: [{
              required: false,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.description']})
            }]
          })(
            <TextArea rows={4}/>
          )
          }
        </Form.Item>

      </div>
    );
  }



  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: PersonAwardsDegreesRequest | undefined) => {
    this.personGroupId = item && item.employee ? item.employee.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();


    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const addressId = this.props.addressId
      // || (item && item.baseAddress ? item.baseAddress.id : undefined)


    if (addressId)
      getCubaREST()!.loadEntity(PersonAwardsDegrees.NAME, addressId, {view: 'personAwardsDegrees.edit'})
        .then(value => this.editPersonAwardsDegrees = value as PersonDocument)
        .then(value => {
          this.instanceEditPersonAwardsDegrees.setItem(value);
          const properties = [
            "type",
            "kind",
            "startDate",
            "endDate",
            "description"];
          if (this.props.entityId === PersonDocumentRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditPersonAwardsDegrees.getFieldValues(properties));
          } else if (item) {

            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(PersonAwardsDegreesRequestEditComponent))
);
