import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Col, Row, Select, Table} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import {action, observable} from "mobx";
import {restServices} from "../../../../cuba/services";
import {NomineePojo} from "../../../../cuba/entities/base/tsadv$NomineePojo";
import PersonLink from "./PersonLink";
import {PersonPojo} from "../../../../cuba/entities/base/tsadv$PersonPojo";


@inject("rootStore")
@injectMainStore
@observer
class MyNominee extends React.Component<RootStoreProp & WrappedComponentProps> {

  @observable years?: number[];
  @observable nominees?: NomineePojo[];
  @observable selectedYear?: number;
  @observable isLoading = false;

  page = 1;
  lastCount = 0;

  componentDidMount() {
    this.load();
  }

  @action load = async () => {
    this.years = await restServices.recognitionService.loadNomineeYears();
    this.selectedYear = this.years[0]!;
    await this.loadNominees();
  }


  @action loadNominees = async () => {
    this.isLoading = true;

    const nomineePageInfo = await restServices.recognitionService.loadMyNominees(this.page, this.lastCount, this.selectedYear!, -1)
      .finally(() => this.isLoading = false);

    this.lastCount = nomineePageInfo.pageInfo!.totalRowsCount;
    this.nominees = nomineePageInfo.nominees!;
  }

  fullNameRender = (value: any, record: NomineePojo) => {
    const person: PersonPojo = {
      personGroupId: record.pgId,
      name: record.fullName
    }

    return <PersonLink person={person}/>
  }

  onPageChange = (pageNumber: number) => {
    this.page = pageNumber;
    this.loadNominees();
  }

  onChangeYear = (year: number) => {
    this.selectedYear  = year;
    this.loadNominees();
  }

  render() {
    const messages = this.props.intl.messages;

    return <>
      <Row type={"flex"} justify={"space-between"}>
        <Col>
          <h2>
            <FormattedMessage id={"recognition.myNominee.title"}/>
          </h2>
        </Col>
        <Col>
          <Select style={{width: "200px", marginRight: "20px"}}
                  loading={this.years === undefined}
                  value={this.selectedYear}
                  onChange={this.onChangeYear}
          >
            {this.years && this.years.map(year => <Select.Option key={year} value={year}>{year}</Select.Option>)}
          </Select>
        </Col>
      </Row>

      <Table dataSource={this.nominees}
             loading={this.isLoading}
             pagination={{defaultPageSize: 20, total: this.lastCount, onChange: this.onPageChange}}>
        <Table.Column title={messages["recognition.myNominee.fullName"]} render={this.fullNameRender} />
        <Table.Column title={messages["recognition.myNominee.position"]} dataIndex={"position"}/>
        <Table.Column title={messages["recognition.myNominee.organization"]} dataIndex={"organization"}/>
        <Table.Column title={messages["recognition.myNominee.year"]} dataIndex={"year"}/>
      </Table>
    </>
  }

}

export default injectIntl(MyNominee);