import RootStore from "./RootStore";
import {action} from "mobx";
import {KpiEmployee} from "../pages/AssignedGoals/CascadeBtn";

export default class {
  rootStore: RootStore;

  goalId?: string;
  personGroups?: KpiEmployee[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  clear = () => {
    this.goalId = undefined;
    this.personGroups = undefined;
  };

  @action
  setData = (goalId: string, personGroups: KpiEmployee[]) => {
    this.goalId = goalId;
    this.personGroups = personGroups;
  };
}