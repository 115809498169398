import { AbstractDictionary } from "./AbstractDictionary";
export class Bank extends AbstractDictionary {
  static NAME = "base$Bank";
  bankIdentificationCode?: string | null;
  businessIdentificationNumber?: string | null;
  beneficiaryCode?: string | null;
  addressLang1?: string | null;
  addressLang2?: string | null;
  addressLang3?: string | null;
  addressLang4?: string | null;
  addressLang5?: string | null;
  address?: string | null;
  name?: string | null;
  fullName?: string | null;
}
export type BankViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "bank.browse"
  | "bank.edit";
export type BankView<V extends BankViewName> = V extends "_base"
  ? Pick<
      Bank,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "bankIdentificationCode"
      | "businessIdentificationNumber"
      | "beneficiaryCode"
      | "addressLang1"
      | "addressLang2"
      | "addressLang3"
      | "addressLang4"
      | "addressLang5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "description1"
      | "description2"
      | "description3"
      | "description4"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_local"
  ? Pick<
      Bank,
      | "id"
      | "bankIdentificationCode"
      | "businessIdentificationNumber"
      | "beneficiaryCode"
      | "addressLang1"
      | "addressLang2"
      | "addressLang3"
      | "addressLang4"
      | "addressLang5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_minimal"
  ? Pick<
      Bank,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
    >
  : V extends "bank.browse"
  ? Pick<
      Bank,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "bankIdentificationCode"
      | "businessIdentificationNumber"
      | "beneficiaryCode"
      | "addressLang1"
      | "addressLang2"
      | "addressLang3"
      | "addressLang4"
      | "addressLang5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "description1"
      | "description2"
      | "description3"
      | "description4"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
      | "company"
    >
  : V extends "bank.edit"
  ? Pick<
      Bank,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "bankIdentificationCode"
      | "businessIdentificationNumber"
      | "beneficiaryCode"
      | "addressLang1"
      | "addressLang2"
      | "addressLang3"
      | "addressLang4"
      | "addressLang5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "description1"
      | "description2"
      | "description3"
      | "description4"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
      | "company"
    >
  : never;
