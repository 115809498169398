import React from "react";
import {inject, observer} from "mobx-react";
import {
  getEnumCaption,
  getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected,
} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../store";
import {Checkbox, Input, Modal, Spin, Table} from "antd";
import {Assessment} from "../../../../cuba/entities/base/tsadv$Assessment";
import Column from "antd/es/table/Column";
import {MyTeamCardProps} from "../MyTeamCard";
import {action, observable, runInAction} from "mobx";
import Button, {ButtonType} from "../../../components/Button/Button";
import {restServices} from "../../../../cuba/services";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots} from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";
import {AssessmentDetail} from "../../../../cuba/entities/base/tsadv_AssessmentDetail";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type MainData = {
  date_from: string | null,
  date_to: string | null,
  employeeFullName: string | null,
  participant_person_group_id: string | null,
  participant_type_code: string | null,
  person_group_id: string | null,
  session_name: string | null,
  status_code: string | null,
  total_result: string | null,
  person_assessment_id: string | null,
  instruction: string | null,
  participant_status_id: string | null,
  participant_status_code: string | null,
  partcicipant_result: number | null | undefined
}

interface ScaleLevel {
  scale_level_id: string;
  lang1: string;
  lang2: string;
  lang3: string;
  level_score: number;
}

interface Participant {
  row_id: string;
  entity_id: string;
  entity_name: string;
  person_group_id: string;
  employee_name: string;
  participant_role_code: string;
  role_name: string;
  participant_order: number;
  scale_level_id: string;
  scale_level: string;
  comments: string;
  required_to_train?: any;
  scale_levels: ScaleLevel[];
  has_comments: boolean;
}

interface RootObject {
  person_assessment_id: string | null;
  row_id: string | null;
  assessment_competence_id: string | null;
  competence_source: string | null;
  competence_type_id: string | null
  competence_name?: string | null
  competence_type?: string | null
  competence_group_id: string | null
  required_scale_level_id: string | null;
  required_scale_level: string | null;
  scale_levels: ScaleLevel[];
  entityName: string | null;
  result_percent: number;
  result_id?: any;
  result?: any;
  required_to_train: boolean;
  delta: number;
  has_comments: boolean;
  participants: Participant[];

}

interface RootObjectWithKey extends RootObject {
  key: string;
}

type CompetenceTableRow = {
  key: string,
  competence_type: string,
  competence_type_id: string,
  children: (RootObjectWithKey & {key: string})[]
};

@inject("rootStore")
@injectMainStore
@observer
class CompetencyBasedAssessmentt extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & MyTeamCardProps> {

  @observable isCBADetail: boolean = false

  @observable mainTableData: Array<MainData>

  @observable detailTableData: any

  @observable personAssessmentId: string

  @observable isLoadedMainData: boolean = true

  @observable isLoadedDetailData: boolean = true

  @observable participants: Participant[]

  @observable modalData: any

  @observable commentModalVisible = false

  @observable hasComment: boolean = false

  componentDidMount() {
    restServices.learningService.getPersonAssessments({
      personGroupId: this.props.personGroupId!,
      lang: this.props.rootStore!.userInfo!.locale!
    }).then(value => {
      this.isLoadedMainData = false
      this.mainTableData = (JSON.parse(value && value[0] && value[0]!.value!))
    })
    restServices.learningService.getMyAssessments({
      personGroupId: this.props.personGroupId!,
      lang: this.props.rootStore!.userInfo!.locale!
    }).then(value => {
      console.log(JSON.parse(value && value[0] && value[0]!.value!))
    })
  }

  groupDataByCompetenceType = (arr: RootObject[]):CompetenceTableRow[] => {
    const map = arr.reduce((acc, cur, index) => {
      if (cur.competence_type_id && cur.competence_type) {
        if(!acc.has(cur.competence_type_id)){
          acc.set(cur.competence_type_id, {
            competence_type_id: cur.competence_type_id,
            competence_type: cur.competence_type,
            key: cur.competence_type_id,
            children: []
          });
        }
        acc.get(cur.competence_type_id)!.children.push({
          ...cur,
          competence_type: cur && cur.competence_name,
          key: `${cur.competence_group_id || index}-${cur.row_id || index}`
        });
      }
      return acc
    }, new Map() as Map<string, CompetenceTableRow>);

    return Array.from(map.values())
  };

  loadDetailCBACard = (personAssesmentId: string) => {
    restServices.learningService.getPersonAssessmentForm({
      personGroupId: personAssesmentId,
      lang: this.props.rootStore!.userInfo!.locale!
    }).then(value => {
      this.isLoadedDetailData = false
      console.log(JSON.parse(value && value[0] && value[0]!.value!))
      runInAction(() => {
        let arr: RootObject[] = (JSON.parse(value && value[0] && value[0]!.value!))
        this.participants = arr[0].participants
        this.isLoadedDetailData = false
        this.detailTableData = this.groupDataByCompetenceType(JSON.parse(value && value[0] && value[0]!.value!))
      })

    })
  }


  @action
  onChangeIsCBADetail = () => {
    if (this.isCBADetail) {
      this.isCBADetail = false
    } else {
      this.isCBADetail = true
    }
    return this.isCBADetail
  }


  @action openCommentModal = (record: any, edit: boolean) => {
    this.modalData = record
    this.commentModalVisible = true
  }


  render() {
    return (
      <div>
        {
          this.isCBADetail
            ? this.renderDetailTable(this.mainTableData)
            : this.renderMainTable()
        }
      </div>
    )
  }

  renderMainTable = () => {
    return <Spin spinning={this.isLoadedMainData}>
      <Table
        dataSource={this.mainTableData}

      >
        <Column
          title={this.props.intl.formatMessage({id: "cba.dateFrom"})}
          dataIndex={"startDate"}
          key={"startDate"}
          render={
            (text, record) => {
              return (record as MainData).date_from ? (record as MainData).date_from && ((record as MainData).date_from)!.substr(0, 10).split('-').reverse().join('.') : ""
            }
          }
        />
        <Column
          title={this.props.intl.formatMessage({id: "cba.dateTo"})}
          dataIndex={"endDate"}
          key={"endDate"}
          render={

            (text, record) => {
              return (record as MainData).date_to ? (record as MainData).date_to && ((record as MainData).date_to)!.substr(0, 10).split('-').reverse().join('.') : ""
            }
          }
        />
        <Column
          title={this.props.intl.formatMessage({id: "cba.FormTemplateName"})}
          dataIndex={"template"}
          key={"template"}
          render={
            (text, record) => {
              return <button
                style={{color: "#005781", textDecoration: "underline", background: "transparent", border: "none"}}
                onClick={() => {
                  this.onChangeIsCBADetail()
                  this.personAssessmentId = (record as MainData)!.person_assessment_id!
                  this.loadDetailCBACard((record as MainData)!.person_assessment_id!)
                }}
              >
                {(record as MainData).session_name}
              </button>
            }
          }
        />
        <Column
          title={this.props.intl.formatMessage({id: "cba.totalResult"})}
          dataIndex={"overalRating"}
          key={"competenceTemplateName"}
          render={
            (text, record) => (record as MainData)!.total_result!
          }
        />
      </Table>
    </Spin>
  }

  renderDetailTable = (data: Array<MainData>) => {

    const {
      session_name,
      date_to,
      date_from,
      total_result
    } = (data.filter(item => item.person_assessment_id === this.personAssessmentId))[0]
    const faCommentDotss = faCommentDots as IconProp;
    return <>
      <Modal
        className={"cba-detail--modal"}
        visible={this.isCBADetail}
        onOk={this.onChangeIsCBADetail}
        onCancel={this.onChangeIsCBADetail}
      >
        <div style={{display: "flex", marginBottom: "20px"}}>
          <div className={"competencyBasedAssessment_detail__items"}>
            <FormattedMessage id={"cba.detail.FormTemplateName"}/>
            <Input
              value={session_name ? session_name : ""}
              disabled/>
          </div>
          <div className={"competencyBasedAssessment_detail__items"}>
            <FormattedMessage id={"cba.dateFrom"}/>
            <Input
              value={date_from ? date_from.substr(0, 10).split('-').reverse().join('.') : ""}
              disabled/>
          </div>
          <div className={"competencyBasedAssessment_detail__items"}>
            <FormattedMessage id={"cba.dateTo"}/>
            <Input
              value={date_to ? date_to.substr(0, 10).split('-').reverse().join('.') : ""}
              disabled/>
          </div>
          <div className={"competencyBasedAssessment_detail__items"}>
            <FormattedMessage id={"cba.totalResult"}/>
            <Input
              value={total_result ? total_result : ""}
              disabled/>
          </div>
        </div>
        <Spin spinning={this.isLoadedDetailData}>
          <Table dataSource={this.detailTableData}
                 scroll={{x: true}}
                 tableLayout={'fixed'}
                 className={"cba-detail"}
          >
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.source"})}
              dataIndex={"source"}
              key={"source"}
              render={
                (text, record: RootObject) => record.entityName !== AssessmentDetail.NAME && getEnumCaption((record as RootObject)!.competence_source!, getPropertyInfoNN("competenceSource", Assessment.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!)
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.competency"})}
              dataIndex={"competency"}
              key={"competency"}
              render={
                (text, record: RootObject) => record.hasOwnProperty("competence_name") ? (record as RootObject)!.competence_name! : (record as RootObject)!.competence_type!
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.requiredlevel"})}
              dataIndex={"requiredlevel"}
              key={"requiredlevel"}
              render={
                (text, record) => (record as RootObject).required_scale_level
              }
            />
            {this.participants && this.participants.map((employee, empoyeeIndex) =>
              <Column
                title={() => <>{employee.role_name}<br/><span>{employee.employee_name}</span></>}
                dataIndex={"participants"}
                key={employee.entity_id}
                render={
                  (text, record: RootObject, index) => record.participants && record.participants[empoyeeIndex].scale_level
                }
              />
            )}
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.totalResult"})}
              dataIndex={"totalResult"}
              key={"totalResult"}
              render={
                (text, record) => (record as RootObject).result_percent
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.result"})}
              dataIndex={"result"}
              key={"result"}
              render={
                (text, record) => (record as RootObject).result
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.requiredToTrain"})}
              dataIndex={"requiredToTrain"}
              key={"requiredToTrain"}
              render={
                (text, record: RootObject) => {
                  if (record.entityName !== AssessmentDetail.NAME) {
                    return <Checkbox disabled defaultChecked={(record as RootObject).required_to_train}/>
                  } else {
                    return ""
                  }
                }}

            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.comments"})}
              dataIndex={"comments"}
              key={"comments"}
              align={"center"}
              render={
                (text, record: RootObject) => {

                  if (record.entityName && record.has_comments) {
                    return <><a onClick={(e) => {
                      e.preventDefault();
                      this.openCommentModal(record, false);
                    }}>{<FontAwesomeIcon style={{marginRight: 5}} size={"2x"} icon={faCommentDotss}/>
                    }</a>
                    </>
                  } else {
                    return ""
                  }
                }
              }
            />
          </Table>
        </Spin>
      </Modal>
      <Modal maskClosable={false}
             centered
             onOk={() => {
               runInAction(() => {
                 this.commentModalVisible = false
               })}}
             destroyOnClose
             title={this.props.intl.formatMessage({id: "comment"})}
             onCancel={() => {
               runInAction(() => {
                 this.commentModalVisible = false
                 this.modalData = undefined
               })}}
             visible={this.commentModalVisible}>
        <>
          {
            this.participants && this.participants.map((employee, empInd) => {
              return <>
                <label>{employee.employee_name}</label>
                <TextArea
                  defaultValue={(this.modalData && this.modalData.participants[empInd].comments)}
                  autoSize={{minRows: 5, maxRows: 15}}
                  disabled
                />
              </>
            })
          }
          </>

      </Modal>
      <Button buttonType={ButtonType.PRIMARY}
              onClick={this.onChangeIsCBADetail}>
        <FormattedMessage id={"close"}/>
      </Button>
    </>
  }
}

export default injectIntl(CompetencyBasedAssessmentt)