import React from "react";
import {Tag, Tooltip} from "antd";
import {observer} from "mobx-react";
import {action, observable, runInAction} from "mobx";
import {DicQuality} from "../../../../cuba/entities/base/tsadv$DicQuality";
import {getCubaREST} from "@cuba-platform/react";
import {Condition, ConditionsGroup} from "@cuba-platform/rest/dist-node/filter";
import moment from "moment/moment";
import {JSON_DATE_TIME_FORMAT} from "../../../util/Date/Date";

type QuantitySelectorProps = {
  onChange?: (selected: DicQuality[]) => void
}

type ChangeType = "remove" | "add";


@observer
class QuantitySelector extends React.Component<QuantitySelectorProps> {
  readonly ACTUAL_FILTER: Array<Condition | ConditionsGroup> = [
    {
      property: "startDate",
      operator: '<',
      value: moment().format(JSON_DATE_TIME_FORMAT)
    }, {
      property: "endDate",
      operator: '>',
      value: moment().format(JSON_DATE_TIME_FORMAT)
    }
  ]

  @observable dicQualities: DicQuality[] = [];
  @observable selectedQuality: DicQuality[] = [];


  componentDidMount() {
    getCubaREST()!.searchEntities(DicQuality.NAME, {conditions: this.ACTUAL_FILTER})
      .then(result => runInAction(() => this.dicQualities = result as DicQuality[]));
  }

  @action changeQualities = (quality: DicQuality, type: ChangeType) => {
    type === "add"
      ? this.selectedQuality.push(quality)
      : this.selectedQuality = this.selectedQuality.filter((q) => q.id !== quality.id);

    this.props.onChange && this.props.onChange(this.selectedQuality);
  }

  makeQuality = (quality: DicQuality) => {
    const isSelected = this.selectedQuality.find((q) => q.id === quality.id) !== undefined;
    const changeType: ChangeType = isSelected ? "remove" : "add";

    return (
      <Tooltip placement="top" title={quality.description}>
        <Tag onClick={() => this.changeQualities(quality, changeType)}
             color={isSelected ? "blue" : "gray"}>{quality.langValue}</Tag>
      </Tooltip>
    )
  }


  render() {
    return (
      this.dicQualities.map(this.makeQuality)
    )
  }
}

export default QuantitySelector;