import {collection, getCubaREST, injectMainStore, MainStoreInjected, Msg, MultilineText} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import React from "react";
import {IReactionDisposer, observable, toJS} from "mobx";
import {Alert, Form, Input, InputNumber, message, Modal, Select} from "antd";
import {RootStoreProp} from "../../store";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RouteComponentProps} from "react-router-dom";
import {FormComponentProps} from "antd/lib/form";
import {OrgRequestGrade, OrgRequestRow} from "./OrgStructureRequestEdit";
import {restServices} from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";
import {OrgStructureRequestDetail} from "../../../cuba/entities/base/tsadv_OrgStructureRequestDetail";
import {withRouter} from "react-router";
import {GradeRuleValue} from "../../../cuba/entities/base/tsadv$GradeRuleValue";
import moment from "moment";
import PickerTable from "../../components/PickerField/PickerTable";
import Button from "../../components/Button/Button";
import {JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import {PositionExt} from "../../../cuba/entities/base/base$PositionExt";
import {Grade} from "../../../cuba/entities/base/tsadv$Grade";
import FormItem from "antd/es/form/FormItem";
import {ReadonlyField} from "../../components/ReadonlyField";
import {PositionGroupExt} from "../../../cuba/entities/base/base$PositionGroupExt";
import {GradeGroup} from "../../../cuba/entities/base/tsadv$GradeGroup";
import {Condition, ConditionsGroup} from "@cuba-platform/rest/dist-node/filter";
import {JobGroup} from "../../../cuba/entities/base/tsadv$JobGroup";
import {Job} from "../../../cuba/entities/base/tsadv$Job";
import {DicCompany} from "../../../cuba/entities/base/base_DicCompany";

export class PositionSaveModel {
  rId: string;
  rdId: string | null;
  nameRu: string;
  nameEn: string;
  positionGroupId: string | null;
  parentOrganizationGroupId: string | null;
  gradeGroupId: string | null;
  headCount: number | 0;
  baseSalary?: number;
  maxSalary?: number;
  minSalary?: number;
  parentRdId: string | null;
  nameFromPosition?: string | null;
  nameFromJob?: string | null;
}

export interface EditorProps {
  row: OrgRequestRow | null,
  availableSalary: boolean,
  requestId: string,
  treeData: OrgRequestRow[],
  isNew: boolean,
  closeModal: () => void,
  onSave: (rdId: string) => void,
  isDisabledFields: boolean,
}

type Props = FormComponentProps & EditorProps;

@inject("rootStore")
@injectMainStore
@observer
class PositionEditor extends React.Component<Props & MainStoreInjected & RootStoreProp & WrappedComponentProps & RouteComponentProps> {

  @observable
  globalErrors: string[] = [];

  organizations: any[] = [];

  pickedPositions: PositionExt[] = [];

  @observable
  pickedJob?: Job | null = undefined;

  @observable
  grades: OrgRequestGrade[] = [];

  @observable
  mainStore = this.props.mainStore!;

  reactionDisposer: IReactionDisposer;

  fields = ["id", "rId", "gradeGroupId", "positionGroupId", "parentId", "nameRu", "nameEn", "headCount", "baseSalary", "mtPayrollPer"];

  locale = this.props.mainStore!.locale!;

  @observable
  availableSalary: boolean = false;

  @observable
  openedPositionSelect: boolean = false;

  gradeDc = collection<PositionExt>(GradeGroup.NAME, {
    view: '_minimal'
  });
  companyCodeDc = collection<DicCompany>(DicCompany.NAME,{
    // filter:{
    //   conditions:[{
    //     property:"code",
    //     operator:"<>",
    //     value:"empty"
    //   }]
    // },
    view:"_minimal"
  })

  @observable positionCollection = collection<Job>(Job.NAME, {
      view: 'job.edit',
      limit: 10,
      filter: {
        conditions: [
          {
            property: 'endDate',
            operator: ">=",
            value: moment().format(JSON_DATE_TIME_FORMAT)
          },
          {
            property: 'startDate',
            operator: "<=",
            value: moment().format(JSON_DATE_TIME_FORMAT)
          }
        ]
      },
      loadImmediately: false
    }
  )

  save = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.form.validateFields(this.fields, {force: true}, (err, values) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        return;
      }

      let formData = this.props.form.getFieldsValue(this.fields);

      let posSaveModel = new PositionSaveModel();
      posSaveModel.rId = formData.rId;
      posSaveModel.rdId = formData.id;
      posSaveModel.nameRu = formData.nameRu;
      posSaveModel.nameEn = formData.nameEn;
      posSaveModel.positionGroupId = formData.positionGroupId;
      posSaveModel.gradeGroupId = formData.gradeGroupId;
      posSaveModel.headCount = formData.headCount;
      posSaveModel.nameFromJob = this.pickedJob && this.pickedJob.group && this.pickedJob.group.id;

      if (this.availableSalary) {
        posSaveModel.maxSalary = formData.baseSalary;
        posSaveModel.minSalary = formData.mtPayrollPer;
      }

      let pId = formData.parentId;
      if (pId !== undefined && pId !== null) {
        let foundOrg = this.organizations.find(o => o.id === pId);

        if (foundOrg !== undefined) {
          posSaveModel.parentOrganizationGroupId = foundOrg.orgGroupId;
          posSaveModel.parentRdId = foundOrg.rdId;
        }
      }

      restServices.orgStructureService.savePosition({
        positionRequestSaveModel: posSaveModel
      }).then(rdId => {
        this.props.onSave(rdId);
      }).catch(async (response: any) => {
        const reader = response.response.body.getReader();
        let receivedLength = 0;
        let chunks = [];
        while (true) {
          const {done, value} = await reader.read();
          if (done) break;
          chunks.push(value);
          receivedLength += value.length;
        }

        let chunksAll = new Uint8Array(receivedLength);
        let position = 0;
        for (let chunk of chunks) {
          chunksAll.set(chunk, position);
          position += chunk.length;
        }

        let result = new TextDecoder("utf-8").decode(chunksAll);
        const parse = JSON.parse(result);
        Notification.error({message: parse.message});
      });
    });
  }

  close = (e: React.MouseEvent) => {
    this.props.closeModal();
  }

  fillOrganizations = (tree: OrgRequestRow[]) => {
    tree.forEach(v => {
      if (v.elementType == 1) {
        this.organizations.push({
          id: v.rdId || v.orgGroupId,
          rdId: v.rdId,
          orgGroupId: v.orgGroupId,
          name: (this.locale === 'ru') ? v.nameRu[0] : v.nameEn[0]
        });
        if (v.children && v.children.length > 0) {
          this.fillOrganizations(v.children);
        }
      }
    });
  }

  fillGrades = async () => {
    if (this.pickedJob) {
      await getCubaREST()!.searchEntities<Grade>(Grade.NAME, {
        conditions: [{
          property: 'group.company.id',
          operator: '=',
          value: this.pickedJob.group!.company!.id!
        }, {
          property: 'endDate',
          operator: ">=",
          value: moment().format(JSON_DATE_TIME_FORMAT)
        },
          {
            property: 'startDate',
            operator: "<=",
            value: moment().format(JSON_DATE_TIME_FORMAT)
          }]
      }, {
        view: 'grade.edit'
      }).then(value => value.map(grade => {
        return {
          id: grade.id,
          groupId: grade.group!.id,
          name: grade.gradeName
        } as OrgRequestGrade
      })).then(value => this.grades = value);
    } else
      await restServices.orgStructureService.getGrades().then(grades => {
        this.grades = grades;
      });
  }

  onNameFilterChange = () => {

    const conditions: Array<Condition | ConditionsGroup> = [
      {
        property: 'endDate',
        operator: ">=",
        value: moment().format(JSON_DATE_TIME_FORMAT)
      },
      {
        property: 'startDate',
        operator: "<=",
        value: moment().format(JSON_DATE_TIME_FORMAT)
      }
    ];

    if (this.filterPositionNameValue && this.filterPositionNameValue.trim().length !== 0) {
      conditions.push({
        group: 'OR', conditions: [
          {
            property: 'jobNameLang1',
            operator: "contains",
            value: this.filterPositionNameValue
          },
          {
            property: 'jobNameLang2',
            operator: "contains",
            value: this.filterPositionNameValue
          },
          {
            property: 'jobNameLang3',
            operator: "contains",
            value: this.filterPositionNameValue
          }
        ]
      });
    }

    if (this.filterGradeValue && this.filterGradeValue.trim().length !== 0) {
      conditions.push({
        property: 'gradeGroup.id',
        operator: "=",
        value: this.filterGradeValue
      });
    }

    if (this.filterCompanyValue && this.filterCompanyValue.trim().length !== 0) {
      conditions.push({
        property: 'group.company.id',
        operator: "=",
        value: this.filterCompanyValue
      });
    }

    this.positionCollection.filter = {conditions: conditions};

    this.positionCollection.load();
  }

  clearPickerPosition = async () => {
    this.pickedJob = undefined;
    await this.fillGrades();
  }

  handleModalOk = async () => {
    this.pickedJob = this.pickedPositions.length > 0 ? this.pickedPositions[0] : undefined;
    if (this.pickedJob) {
      await this.fillGrades();

      this.props.form.setFieldsValue({
        nameRu: this.pickedJob.jobNameLang1,
        nameEn: this.pickedJob.jobNameLang3,
      });
    }

    this.openedPositionSelect = false;
  }

  handleModalCancel = () => {
    this.openedPositionSelect = false;
  }

  positionTableFields = [
    {
      caption: this.props.intl!.formatMessage({id: "position.name"}),
      field: '_instanceName'
    },
    {
      caption: this.props.intl!.formatMessage({id: "company"}),
      field: 'group.company._instanceName'
    },
  ]

  filterPositionNameValue!: string;
  filterGradeValue!: string;
  filterCompanyValue!: string;

  getPositionFilter = () => {
    return (<div style={{display: 'flex'}}>
      <FormItem style={{width: '40%', marginRight: 5}}
                label={this.props.intl.formatMessage({id: "position.name"})}>
        <Input onChange={(event) => this.filterPositionNameValue = event.target.value}
               onPressEnter={(event) => this.onNameFilterChange()}/>
      </FormItem>

      <ReadonlyField
        entityName={PositionGroupExt.NAME}
        propertyName="company"
        disabled={false}
        form={this.props.form}
        style={{width: 100}}
        getFieldDecoratorOpts={{
          getValueFromEvent: args => {
            this.filterCompanyValue = args;
            this.onNameFilterChange();
            return args
          }
        }}
        formItemOpts={{
          style: {marginRight: 5}
        }}
        optionsContainer={this.companyCodeDc}/>

    </div>);
  }

  positionPicker = () => {
    return <Modal
      title={this.props.intl.formatMessage({id: "position.name"})}
      width={"1200px"}
      style={{height: "770px"}}
      footer={null}
      visible={true}
      closable={false}>
      <PickerTable tableData={this.positionCollection}
                   columns={this.positionTableFields}
                   selectionMode={'single'}
                   onSelect={(rows: PositionExt[]) => this.pickedPositions = rows}
                   filter={this.getPositionFilter}/>
      <Button onClick={this.handleModalOk} type={'primary'}>
        {this.props.intl!.formatMessage({id: "cubaReact.dataTable.ok"})}
      </Button>
      <Button onClick={this.handleModalCancel} type={'danger'} style={{marginLeft: '1%'}}>
        {this.props.intl!.formatMessage({id: "management.browser.exclude.cancel"})}
      </Button>
    </Modal>
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    console.log(getFieldDecorator)
    const messages = this.mainStore.messages!;

    return (
      <div>
        <Modal
          title={this.props.intl.formatMessage({id: "org.request.pos.edit"})}
          visible={true}
          onOk={this.save}
          onCancel={this.close}>
          <Form>
            {getFieldDecorator('id')(<Input type="hidden"/>)}
            {getFieldDecorator('rId')(<Input type="hidden"/>)}
            {getFieldDecorator('positionGroupId')(<Input type="hidden"/>)}

            <Form.Item label={<Msg entityName={OrgStructureRequestDetail.NAME} propertyName='parentOrganizationGroup'/>}
                       key="parentId">
              {getFieldDecorator('parentId', {
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[OrgStructureRequestDetail.NAME + '.' + 'parentOrganizationGroup']})
                }]
              })(
                <Select style={{width: '100%'}} disabled={this.props.isDisabledFields}>
                  {this.organizations && this.organizations.length > 0 ? this.organizations.map((o: any) =>
                      <Select.Option key={o.id}>{o.name}</Select.Option>)
                    : <Select.Option key="empty"/>
                  }
                </Select>
              )}
            </Form.Item>

            <Form.Item label={this.props.intl.formatMessage({id: "nameFromPosition"})}
                       key="nameFromPosition">
              <div style={{display: 'flex', width: '100%'}}>
                <input disabled value={(this.pickedJob && this.pickedJob['_instanceName']) || ''}
                       style={{width: '100%', marginRight: 10}}/>
                <button style={{width: 40, marginRight: 10}}
                        disabled={this.props.isDisabledFields}
                        onClick={event => this.openedPositionSelect = true}
                >. . .
                </button>
                <button style={{width: 30}}
                        disabled={this.props.isDisabledFields}
                        onClick={this.clearPickerPosition}>X
                </button>
              </div>
              {this.openedPositionSelect ? this.positionPicker() : <></>}
            </Form.Item>

            <Form.Item label={<Msg entityName={OrgStructureRequestDetail.NAME} propertyName='positionNameRu'/>}
                       key="nameRu">
              {getFieldDecorator('nameRu', {
                rules: [{
                  required: !this.pickedJob,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[OrgStructureRequestDetail.NAME + '.' + 'positionNameRu']})
                }]
              })(
                <Input disabled={this.props.isDisabledFields || !!this.pickedJob}/>
              )}
            </Form.Item>
            <Form.Item label={<Msg entityName={OrgStructureRequestDetail.NAME} propertyName='positionNameEn'/>}
                       key="nameEn">
              {getFieldDecorator('nameEn', {
                rules: [{
                  required: !this.pickedJob,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[OrgStructureRequestDetail.NAME + '.' + 'positionNameEn']})
                }]
              })(
                <Input disabled={this.props.isDisabledFields || !!this.pickedJob}/>
              )}
            </Form.Item>
            <Form.Item label={<Msg entityName={OrgStructureRequestDetail.NAME} propertyName='gradeGroup'/>}
                       key="gradeGroupId">
              {getFieldDecorator('gradeGroupId', {
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[OrgStructureRequestDetail.NAME + '.' + 'gradeGroup']})
                }],
                getValueFromEvent: args => {
                  this.onChangeGrade(args);
                  return args;
                }
              })(
                <Select style={{width: '100%'}} disabled={this.props.isDisabledFields}>
                  {this.grades && this.grades.length > 0 ? this.grades.map((o: any) =>
                      <Select.Option key={o.groupId}>{o.name}</Select.Option>)
                    : <Select.Option key="empty"/>
                  }
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<Msg entityName={OrgStructureRequestDetail.NAME} propertyName='headCount'/>}
                       key="headCount">
              {getFieldDecorator('headCount', {
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[OrgStructureRequestDetail.NAME + '.' + 'headCount']})
                }]
              })(
                <InputNumber min={0} disabled={this.props.isDisabledFields}/>
              )}
            </Form.Item>

            <Form.Item label={<Msg entityName={OrgStructureRequestDetail.NAME} propertyName='minSalary'/>}
                       style={this.availableSalary ? {} : {display: 'none'}}
                       key="baseSalary">
              {getFieldDecorator('baseSalary', {
                rules: [{
                  required: this.availableSalary,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[OrgStructureRequestDetail.NAME + '.' + 'minSalary']})
                }]
              })(
                <InputNumber min={0}/>
              )}
            </Form.Item>

            <Form.Item label={<Msg entityName={OrgStructureRequestDetail.NAME} propertyName='maxSalary'/>}
                       style={this.availableSalary ? {} : {display: 'none'}}
                       key="mtPayrollPer">
              {getFieldDecorator('mtPayrollPer', {
                rules: [{
                  required: this.availableSalary,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[OrgStructureRequestDetail.NAME + '.' + 'maxSalary']})
                }]
              })(
                <InputNumber min={0}/>
              )}
            </Form.Item>

            {this.globalErrors.length > 0 && (
              <Alert
                message={<MultilineText lines={toJS(this.globalErrors)}/>}
                type="error"
                style={{marginBottom: "24px"}}
              />
            )}
          </Form>
        </Modal>
      </div>
    )
  }

  onChangeGrade = (gradeGroupId?: string) => {
    if (this.availableSalary && gradeGroupId) {
      (async () => {
        let gradeRuleValue = {min: 0, max: 0} as GradeRuleValue;
        await getCubaREST()!.searchEntities<GradeRuleValue>(GradeRuleValue.NAME, {
          conditions: [{
            value: gradeGroupId,
            operator: '=',
            property: 'gradeGroup.id'
          }, {
            property: 'startDate',
            operator: '<=',
            value: moment().format('yyyy-MM-DD'),
          }, {
            property: 'endDate',
            operator: '>=',
            value: moment().format('yyyy-MM-DD'),
          }]
        }, {
          view: '_local',
          limit: 1
        }).then(value => {
          if (value.length > 0) gradeRuleValue = value[0] as GradeRuleValue;
        })
        this.props.form.setFieldsValue({baseSalary: gradeRuleValue.min, mtPayrollPer: gradeRuleValue.max})
      })()
    }
  }

  componentDidMount() {
    (async () => {
      let row = this.props.row, isNew = this.props.isNew, model = {};

      this.fillOrganizations(this.props.treeData)

      if (row !== undefined && row !== null) {
        model['rId'] = this.props.requestId;

        if (isNew) {
          model['parentId'] = row.elementType === 1 ? (row.rdId || row.orgGroupId) : (row.pRdId || row.orgGroupId);
        } else {
          model['id'] = row.rdId;
          model['nameRu'] = row.nameRu[1];
          model['nameEn'] = row.nameEn[1];

          model['parentId'] = row.pRdId || row.pOrgGroupId;

          model['positionGroupId'] = row.posGroupId;
          model['parentOrganizationGroupId'] = row.pOrgGroupId;
          model['gradeGroupId'] = row.gradeGroupId;
          model['headCount'] = row.headCount[1];
          model['baseSalary'] = row.baseSalary && row.baseSalary[1];
          model['mtPayrollPer'] = row.mtPayrollPer && row.mtPayrollPer[1];

          if (row.rdId)
            await getCubaREST()!.loadEntity<OrgStructureRequestDetail>(OrgStructureRequestDetail.NAME,
              row.rdId,
              {view: 'org.request.details'}
            ).then(value => value.nameFromPositionGroup)
              .then(value => {
                if (value && value.position)
                  return {
                    ...value.position,
                    group: value
                  };
                return undefined;
              })
              .then(value => this.pickedJob = value);
        }
      }
      this.fillGrades();
      this.props.form.setFieldsValue(model);
      this.availableSalary = this.props.availableSalary;
      this.positionCollection.load();
    })()
  }
}

const positionEditor = withRouter(injectIntl(PositionEditor));
export default positionEditor;