import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RecognitionProfileTypes} from "./RecognitionProfileTypes";

type PersonAwardsProps = {
  personGroupId: string
}

class PersonAwards extends React.Component<WrappedComponentProps & PersonAwardsProps> {

  render() {
    return <RecognitionProfileTypes personGroupId={this.props.personGroupId}/>
  }
}

export default injectIntl(PersonAwards);