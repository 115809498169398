import React from "react";
import {getCubaREST, injectMainStore, MainStoreInjected,} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Breadcrumb, Table} from "antd";
import {RouteComponentProps} from "react-router";
import Column from "antd/es/table/Column";
import Button, {ButtonType} from "../../components/Button/Button";
import {Link, withRouter} from "react-router-dom";
import {IndividualDevelopmentPlan} from "../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";
import {RootStoreProp} from "../../store";
import {action, observable, runInAction} from "mobx";
import {IndividualDevolopmentPlanManagement} from "./IndividualDevolopmentPlanManagement";
import {SerializedEntity} from "@cuba-platform/rest";

type  Props = {
  onVisible:any,
  collection?:any
}

@injectMainStore
@inject("rootStore")
@observer
class IndividualDevolopmentPlanListComponent  extends React.Component<WrappedComponentProps& MainStoreInjected & Props &  RootStoreProp & RouteComponentProps> {

  @observable data:SerializedEntity<IndividualDevelopmentPlan>[];

  @observable limit = 30;

  @observable offset = 0;

  @action loadData = () => {
    getCubaREST()!.searchEntities<IndividualDevelopmentPlan>(IndividualDevelopmentPlan.NAME,{
      conditions:[{
        property:"personGroup.id",
        operator:"=",
        value:this.props!.rootStore!.userInfo!.personGroupId!,
      }
      ]
    },{view:"individualDevelopmentPlanNew-list",sort:"-createTs",limit:this.limit,offset:this.offset}).then(value=>{
      runInAction(()=>this.data = value)
    })
  }

  render() {
    const locale = this.props.rootStore!.userInfo.locale!
    return (
      <div style={{display:"flex", flexDirection:"column"}}>
        <Breadcrumb separator="" style={{marginBottom:"20px", display:"flex"}}>
          <Breadcrumb.Item onClick={()=>{this.props.onVisible(false)}} >
            <div style={{color:"blue" , textDecoration:"underline"}}>
              <FormattedMessage  id={"individualPlan.list.path.last"} />
            </div>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <div style={{margin:"0 2px"}}>
              >
            </div>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <FormattedMessage id={"individualPlan.list.path.current"} />
          </Breadcrumb.Item>
        </Breadcrumb>
          <Table
            rowKey={(record => record.id)}
                 dataSource={this.data}
                 pagination={false}>
            <Column title={this.props.intl.formatMessage( {id:"individualPlan.list.IPR"}) }
                    dataIndex="IPR"
                    key="IPR"
                    ellipsis
                    width={"10%"}
                    render={(text, record:IndividualDevelopmentPlan) => {
                      return <div >
                        {
                          <Link to={IndividualDevolopmentPlanManagement.PATH +"/" + record.id} >
                            <p style={{color:"#005487", textDecoration:"underline"}}>
                              {locale==='ru'?record.planName:record.planNameEn}
                            </p>
                          </Link>
                        }
                      </div>;
                    }}/>
            <Column title={this.props.intl.formatMessage( {id:"individualPlan.edit.positionGroup"})}
                    dataIndex="positionGroup"
                    key="positionGroup"
                    ellipsis
                    render={(text, record:IndividualDevelopmentPlan) => {
                      return <div >
                        {record.positionGroup&&record.positionGroup.positionName||""}
                      </div>;
                    }}/>
            <Column title={this.props.intl.formatMessage( {id:"company"})}
                    dataIndex="company"
                    key="company"
                    ellipsis
                    width={"9%"}
                    render={(text, record:IndividualDevelopmentPlan) => {
                      return <div >
                        {record.positionGroup&&record.positionGroup.company&&record.positionGroup.company.code||""}
                      </div>;
                    }}/>
            <Column title={this.props.intl.formatMessage( {id:"individualPlan.list.StartDate"})}
                    dataIndex="startDate"
                    key="startDate"
                    ellipsis
                    width={"10%"}
                    render={(text, record:IndividualDevelopmentPlan) => {
                      return <div >
                        {record.startDate.substr(0,10).split('-').reverse().join('.')}
                      </div>;
                    }}/>
            <Column title={this.props.intl.formatMessage( {id:"individualPlan.list.EndDate"})}
                    dataIndex="endDate"
                    key="endDate"
                    ellipsis
                    width={"10%"}
                    render={(text, record:IndividualDevelopmentPlan) => {
                      return <div >
                        {record.endDate.substr(0,10).split('-').reverse().join('.')}
                      </div>;
                    }}/>
            <Column title={this.props.intl.formatMessage( {id:"individualPlan.list.status"})}
                    dataIndex="status"
                    key="status"
                    width={"12%"}
                    ellipsis
                    render={(text, record:IndividualDevelopmentPlan) => {
                      return <div >
                        {record.status&&record.status['_instanceName']}
                      </div>;
                    }}/>
            <Column title={this.props.intl.formatMessage( {id:"individualPlan.list.percent"})}
                    dataIndex="percent"
                    key="percent"
                    ellipsis
                    width={"10%"}
                    render={(text, record:IndividualDevelopmentPlan) => {
                      return <>
                        {record['planPercent'] ? record['planPercent'] : 0}
                      </>;
                    }}/>
          </Table>
        <Button style={{marginTop:"40px", alignSelf:"flex-end"}}
                buttonType={ButtonType.PRIMARY}
                onClick={()=>{this.props.onVisible(false)}}
        >
          <FormattedMessage id={"individualPlan.list.button.close"} />
        </Button>
      </div>
    );
  }

  componentDidMount() {
    this.loadData();
  }


}

const IndividualDevolopmentPlanList = injectIntl(withRouter(IndividualDevolopmentPlanListComponent));

export default IndividualDevolopmentPlanList;