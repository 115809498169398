import * as React from "react";
import {inject, observer} from "mobx-react";
import {action, observable} from "mobx";
import {restServices} from "../../../cuba/services";
import {RootStoreProp} from "../../store";
import {PersonProfile} from "../MyTeam/MyTeamCard";
import {Button, Select, Modal, DatePicker, Form, message} from "antd";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Moment} from "moment";
import {runExpandableReport} from "../../util/reportUtil";
import {FormComponentProps} from "antd/es/form";
import Notification from "../../util/Notification/Notification";

export type AssistantSelectorProps = {
  render: (selectedPerson: PersonProfile) => React.ReactNode,
  onChange?: (selectedPerson?: PersonProfile) => void,
}

@inject("rootStore")
@observer
class Component extends React.Component<RootStoreProp & AssistantSelectorProps & WrappedComponentProps & FormComponentProps> {

  @observable
  managers: PersonProfile[] = [];

  @observable
  selectedPerson?: PersonProfile;

  @observable modalVisible = false;

  @action showModal = () => this.modalVisible = true;

  @action hideModal = () => {
    this.modalVisible = false;
    this.validationStarted = false;
    this.dateFrom = undefined;
    this.dateTo = undefined;
  }

  @observable dateFrom:Moment | undefined;

  @action setDateFrom = (value:Moment) => this.dateFrom = value

  @observable dateTo:Moment | undefined;

  @action setDateTo = (value:Moment) => this.dateTo = value

  @observable printLoading:boolean = false;

  @action setPrintLoading = (value:boolean) => this.printLoading = value

  @observable validationStarted = false;

  @action onOk = () => {
    this.validationStarted = true;
      if(!this.dateFrom || !this.dateTo){
        return Notification.error({message:this.props.intl.formatMessage({id:"management.editor.validationError"})})
      }
    this.setPrintLoading(true);
    message.loading({content:this.props.intl.formatMessage({id:"generatingReport"}),key:"message",duration:0});
    const data = {
      parameters: [{
        name: "start_date",
        value: this.dateFrom?this.dateFrom.toISOString():null
      },{
        name: "end_Date",
        value: this.dateTo?this.dateTo.toISOString():null
      },{
        name: "PersonGroupID",
        value: this.selectedPerson!.groupId
      }]
    };
    runExpandableReport("REQUEST_LIST", data).then(()=>
      message.success({content: this.props.intl.formatMessage({id: "ready"}), key: "message", duration: 3})
    ).catch(()=>
      message.error({
        content: this.props.intl.formatMessage({id: "management.editor.error"}),
        key: "message",
        duration: 3
      })
    ).finally(()=>this.setPrintLoading(false))
  }

  helpValidation = (fieldName:string) => {
   return this.validationStarted?this[fieldName]?undefined:this.props.intl.formatMessage({id:"cubaReact.dataTable.requiredField"}):undefined
  }

  validateStatus = (fieldName:string) => {
    return this.validationStarted?this[fieldName]?"success":"error":undefined
  }


  render() {
    return (
      <div style={{height: '100%', width: '100%'}}>
        <div style={{width:'100%'}}>
        <Select style={{height: '5%', width: '60%', padding: 10}}
                showSearch
                allowClear
                autoFocus={true}
                value={this.props.rootStore!.assistantTeamInfo.selectedManager
                  ? this.props.rootStore!.assistantTeamInfo.selectedManager.id
                  : undefined}
                placeholder={<FormattedMessage id={'select.manager'}/>}
                filterOption={(input, option) =>
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={this.onChangeManager}>
          {this.managers.length > 0 ? this.managers.map(l =>
              <Select.Option title={(l as PersonProfile).fullName!}
                             key={l.id}>{(l as PersonProfile).fullName}</Select.Option>) :
            <Select.Option key="empty"/>
          }
        </Select>
          <Button type="primary" onClick={()=>this.showModal()} disabled={!this.selectedPerson} style={{marginLeft:40,padding:"8px 20px",height: '5%'}}>
            <FormattedMessage id="requestRegistry"/>
          </Button>
        </div>
        <div style={{height: '95%', width: '100%'}}>
          {
            this.selectedPerson
              ? this.props.render(this.selectedPerson)
              : <></>
          }
        </div>
        <Modal
          centered
          closable
          visible={this.modalVisible}
          title={<FormattedMessage id="reportOptions"/>}
          onOk={this.onOk}
          onCancel={()=>this.hideModal()}>
          <Form layout={"inline"} name={"request_modal"}>
              <Form.Item help={this.helpValidation('dateFrom')} validateStatus={this.validateStatus('dateFrom')} required style={{width:"45%"}} label={<FormattedMessage id="dateFrom"/>}>
                <DatePicker locale="ru" format={'L'} value={this.dateFrom} onChange={this.setDateFrom}/>
              </Form.Item>
            <Form.Item help={this.helpValidation('dateTo')} validateStatus={this.validateStatus('dateTo')} required style={{width:"45%"}} label={<FormattedMessage id="dateTo"/>}>
               <DatePicker locale="ru" format={'L'} value={this.dateTo} onChange={this.setDateTo}/>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }

  onChangeManager = (value: string) => {
    this.selectedPerson = this.managers.find(manager => manager.id === value);
    this.props.rootStore!.assistantTeamInfo.selectedManager = this.selectedPerson;
    if (this.props.onChange) {
      this.props.onChange(this.selectedPerson);
    }
  };

  componentDidMount() {
    this.props.rootStore!.assistantTeamInfo.active = true;

    restServices.executiveAssistantService.getManagerList(this.props.rootStore!.userInfo!.positionGroupId!)
      .then(value => this.managers = value)
      .then(value => {
        let selectedManager = this.props.rootStore!.assistantTeamInfo.selectedManager;
        if (value && value.length === 1) selectedManager = value[0];
        else if (!value || value.length === 0 || (selectedManager && !value.find(item => item.id === selectedManager!.id)))
          this.props.rootStore!.assistantTeamInfo.selectedManager = undefined;
        if (selectedManager) this.onChangeManager(selectedManager.id)
        return value;
      });
  }
}
export const AssistantSelector = injectIntl(Form.create<RootStoreProp & AssistantSelectorProps & WrappedComponentProps & FormComponentProps>({name:'request_modal'})(Component))