import React, { Component } from 'react'
import { injectMainStore, MainStoreInjected } from '@cuba-platform/react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../store';
import { Table } from 'antd';
import { restServices } from '../../../cuba/services';
import { Link } from 'react-router-dom';
import { observable } from 'mobx';
import { SerializedEntity } from '@cuba-platform/rest';
import { PositionCourse } from '../../../cuba/entities/base/tsadv_PositionCourse';
import { Course } from '../../../cuba/entities/base/tsadv$Course';

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp & {
  adaptationPlanId: string;
};

@injectMainStore
@inject("rootStore")
@observer
class MyAdaptationCoursesComponent extends Component<Props> {

  @observable
  courses: Course[] = [];
  
  @observable
  isLocaleRu = this.props.rootStore!.userInfo.locale === 'ru';

  @observable
  isLocaleKz = this.props.rootStore!.userInfo.locale === 'kz';

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  componentDidMount = async ()=>{
    const positionCourse = await restServices.adaptationPlanRequestService
      .getPositionCourse(this.props.adaptationPlanId);
    
    this.courses = positionCourse.map(item=>item.course!);
  }

  render() {
    return (
      <div>
        <Table
          dataSource={this.courses}
          columns={[{
            title: this.props.intl.formatMessage({id:"myAdaptationCourses.courseTitle"}),
            key: 'name',
            dataIndex: 'name',
            render: (text, course)=>{
              return <Link to={"/course/" + course.id}>
                {text}
              </Link>
            }
          }]}
        />
        <br/>
        <br/>
        <Link style={{float: "right"}} to="/trainingPlan"><FormattedMessage id="adaptationPlan.goToMyCourses"/></Link>
      </div>
    )
  }
}

const MyAdaptationCourses = injectIntl(MyAdaptationCoursesComponent)

export default MyAdaptationCourses
