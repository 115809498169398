import * as React from "react";
import Page from "../hoc/PageContentHoc";
import Section from "../hoc/Section";
import { Card, Col, Row } from "antd";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TimelineList from "./timeline/TimelineList";
import BirthdayPeople from "./timeline/BirthdayPeople";
import Questionnaire from "./Questionnaire";
import { NavLink } from "react-router-dom";
import { DictionaryEmployeeManagement } from "../pages/DictionaryEmployee/DictionaryEmployeeManagement";
import { collection } from "@cuba-platform/react";
import { Config } from "../../cuba/entities/base/sys$Config";
import { inject, observer } from "mobx-react";
import { RootStoreProp } from "../store";

@inject('rootStore')
@observer
class HomePage extends React.Component<WrappedComponentProps & RootStoreProp> {

  static CODE: string = "home-page";

  kazminUrl = collection<Config>(Config.NAME, {
    filter: {
      conditions: [{
        property: 'name',
        operator: '=',
        value: 'tal.front.homePage.kazminUrl'
      }]
    },
    view: '_local',
    limit: 1
  })

  render() {
    return (
      this.props.rootStore!.menu.menuCustomization.find(item => item.menuItem === 'main') ?
        <Page>
          <Row gutter={[10, 10]}>
            <Col xs={11}>
              <Section padding="none" visible={false}
                sectionName={this.props.intl.formatMessage({ id: "homePage.news" })}>
                <TimelineList />
              </Section>
            </Col>
            <Col xs={6}>
              <Section padding="none" visible={false}>
                <BirthdayPeople />
              </Section>
            </Col>
            <Col xs={7}>
              <Section padding="none" visible={false}>
                <Card title={this.props.intl.formatMessage({ id: "homePage.quickAccess" })} bordered={false}>
                  <div className="between-flex-container quick-access-service-container">
                    <NavLink to={DictionaryEmployeeManagement.PATH}>
                      <div className="quick-access-service">
                        <div className="quick-access-service-icon">
                          <img className="quick-access-service-img" src={require("../../resources/icons/home-page/dictionary-employees.svg")} />
                        </div>
                        <div className="quick-access-service-name">
                          {this.props.intl.formatMessage({ id: "menu.dictionaryEmployees" })}
                        </div>
                      </div>
                    </NavLink>
                    <NavLink to="/absence">
                      <div className="quick-access-service">
                        <div className="quick-access-service-icon">
                          <img className="quick-access-service-img" src={require("../../resources/icons/home-page/vacation-request.svg")} />
                        </div>
                        <div className="quick-access-service-name">
                          {this.props.intl.formatMessage({ id: "vacationRequest" })}
                        </div>
                      </div>
                    </NavLink>
                  </div>
                </Card>
              </Section>
            </Col>
          </Row>

          <Row>
            <Col span={8} lg={8} xs={24}>
              <Section size="large" padding="none" visible={false}>
                <Questionnaire />
              </Section>
            </Col>
          </Row>
        </Page>
        :
        <Page />
    );
  }
}

export default injectIntl(HomePage);
