import * as React from "react";
import {inject, observer} from "mobx-react";

import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";

import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";

import {EmployeeQuestionnaireParticipants} from "../../../cuba/entities/base/tsadv_EmployeeQuestionnaireParticipants";
import {Icon, Spin, Table} from "antd";
import {Link} from "react-router-dom";
import moment from "moment";

export const employeeQuestionnaireTablePATH = "/employeeQuestionnaire"


@injectMainStore
@inject("rootStore")
@observer
class EmployeeQuestionnaireTable extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  dataCollection = collection<EmployeeQuestionnaireParticipants>(EmployeeQuestionnaireParticipants.NAME, {
    filter: {
      conditions: [{
        property: 'personGroup.id',
        operator: '=',
        value: this.props.rootStore!.userInfo!.personGroupId!
      }]
    },
    view: "employeeQuestionnaireParticipants-browseView",
    // sort: "-period"
  });


  render() {

    if (!this.dataCollection || this.dataCollection.status === "LOADING") {
      return <Icon type="spin"/>;
    }

    // const cur = moment();
    // const dateTo = moment(this.dataCollection.items[0].employeeQuestionnaire!.dateTo);

    return (

      <Page pageName={this.props.intl.formatMessage({id: "menu.questionnaire"})}>
        <Section size="large">
          {/*<Spin spinning={this.dataCollection.status==="LOADING"}>*/}
          <Table dataSource={this.dataCollection.items.filter(value => moment(value.employeeQuestionnaire!.dateFrom).isSameOrBefore(moment()) && moment(value.employeeQuestionnaire!.dateTo).isSameOrAfter(moment()))}
                 columns={
                   [
                     {
                       title: this.props.intl.formatMessage({ id: "questionnaireName" }), render: ((text, record) => {
                         return (
                           <Link to={"/test?" + (record!.employeeQuestionnaire && record.employeeQuestionnaire!.id)}>
                             {record.employeeQuestionnaire! && record.employeeQuestionnaire!['_instanceName']}
                           </Link>
                         )
                       })
                     },
                     {
                       title: this.props.intl.formatMessage({ id: "questionnaireDateFrom" }), render: ((text, record) => record.employeeQuestionnaire && moment(record.employeeQuestionnaire.dateFrom).format('L'))
                     },
                     {
                       title: this.props.intl.formatMessage({ id: "questionnaireDateTo" }), render: ((text, record) => record.employeeQuestionnaire && moment(record.employeeQuestionnaire.dateTo).format('L'))
                     },
                     {
                       title: this.props.intl.formatMessage({ id: "questionnaireStatus" }),
                       render: ((text, record) => record && record.status
                         ? record.status === true
                           ? this.props.intl.formatMessage({ id: "employeeQuestionnaireTable.statusIsCompleted" })
                           : this.props.intl.formatMessage({ id: "employeeQuestionnaireTable.statusIsNotCompleted" })
                         : ""
                       )
                     }
                   ]
                 }
                  />
          {/*</Spin>*/}
        </Section>
      </Page>
    );

  }

}

const EmployeeQuestionnaireList = injectIntl(EmployeeQuestionnaireTable);

export default EmployeeQuestionnaireList;
