import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { Bank } from "./base$Bank";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicRequestStatus } from "./tsadv$DicRequestStatus";
import { FileDescriptor } from "./sys$FileDescriptor";
import { PersonBankDetails } from "./tsadv_PersonBankDetails";
export class PersonBankdetailsRequest extends AbstractBprocRequest {
  static NAME = "tsadv_PersonBankdetailsRequest";
  bank?: Bank | null;
  startDate?: any | null;
  endDate?: any | null;
  startDateHistory?: any | null;
  endDateHistory?: any | null;
  fullNameBankCard?: string | null;
  iban?: string | null;
  bicBank?: string | null;
  personGroup?: PersonGroupExt | null;
  requestStatus?: DicRequestStatus | null;
  attachments?: FileDescriptor[] | null;
  bankDetails?: PersonBankDetails | null;
}
export type PersonBankdetailsRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personBankDetailsRequest.full"
  | "personBankdetailsRequest-for-integration";
export type PersonBankdetailsRequestView<
  V extends PersonBankdetailsRequestViewName
> = V extends "_base"
  ? Pick<
      PersonBankdetailsRequest,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "startDate"
      | "endDate"
      | "startDateHistory"
      | "endDateHistory"
      | "fullNameBankCard"
      | "iban"
      | "bicBank"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      PersonBankdetailsRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "startDateHistory"
      | "endDateHistory"
      | "fullNameBankCard"
      | "iban"
      | "bicBank"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<PersonBankdetailsRequest, "id" | "requestNumber" | "requestDate">
  : V extends "personBankDetailsRequest.full"
  ? Pick<
      PersonBankdetailsRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "startDateHistory"
      | "endDateHistory"
      | "fullNameBankCard"
      | "iban"
      | "bicBank"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "bank"
      | "personGroup"
      | "attachments"
      | "bankDetails"
    >
  : V extends "personBankdetailsRequest-for-integration"
  ? Pick<
      PersonBankdetailsRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "startDateHistory"
      | "endDateHistory"
      | "fullNameBankCard"
      | "iban"
      | "bicBank"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "bank"
      | "personGroup"
      | "bankDetails"
      | "status"
    >
  : never;
