import React from "react";
import {restServices} from "../../../../cuba/services";
import {action, computed, observable, reaction, runInAction} from "mobx";
import {observer} from "mobx-react";
import {PreferencePojo} from "../../../../cuba/entities/base/tsadv$PreferencePojo";
import {Button, Row} from "antd";
import {rootStore} from "../../../store";
import {FormattedMessage} from "react-intl";
import PersonPreferencesEditModal from "./PersonPreferencesEditModal";

type PersonPreferencesProps = {
  personGroupId: string;
}

@observer
export class PersonPreferences extends React.Component<PersonPreferencesProps> {

  @observable personPreferences: PreferencePojo[] = [];
  @observable visiblePersonPreferencesEditModal = false;

  componentDidMount() {
    reaction(
      () => this.props.personGroupId,
      () => this.reload(),
      {fireImmediately: true}
    );
  }

  @action reload = () => {
    restServices.recognitionService.loadPersonPreferences(this.props.personGroupId)
      .then(preferences => runInAction(() => this.personPreferences = preferences));
  }

  @action openPersonPreferencesEditModal = () => {
    this.visiblePersonPreferencesEditModal = true;
  }

  @computed get isMyProfile() {
    return this.props.personGroupId === rootStore.userInfo.personGroupId;
  }

  renderPreference = (preference: PreferencePojo) => {
    return (
      <div key={preference.id}>
        <h1>{preference.typeName}</h1>
        <p>{preference.description}</p>
      </div>
    )
  }

  render() {
    return <>
      {this.visiblePersonPreferencesEditModal &&
        <PersonPreferencesEditModal
          visible={this.visiblePersonPreferencesEditModal}
          onClose={() => runInAction(() => this.visiblePersonPreferencesEditModal = false)}
          onSaved={this.reload}
          personGroupId={this.props.personGroupId}/>
      }

      <div style={{textAlign: "left", width: "100%", paddingLeft: "3%"}} className={"person-preferences"}>
        <Row type={"flex"} justify={"space-between"} style={{marginBottom: "10px", alignItems: "baseline"}}>
          <span style={{fontWeight: "bold"}}>
            <FormattedMessage id={"recognition.personPreference.title"}/>
          </span>
          <Button type={"link"} hidden={!this.isMyProfile} onClick={this.openPersonPreferencesEditModal}>
            <FormattedMessage id={"recognition.personPreference.edit"}/>
          </Button>
        </Row>
        {this.personPreferences.map(this.renderPreference)}
      </div>
    </>
  }
}