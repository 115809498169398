import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Card, Col, Row, Spin, Tabs} from "antd";
import Img from "../../../components/Img";
import {restServices} from "../../../../cuba/services";
import {action, observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {TopTenPojo} from "../../../../cuba/entities/base/tsadv$TopTenPojo";
import {NotFoundImage} from "../../../components/NoImage";
import {Link} from "react-router-dom";
import {getPersonPageLink} from "../recognitionUtils";


function resolveMedalImage(type: string) {
  switch (type) {
    case "g":
      return require("../../../../resources/icons/recognition/medal-g.png");
    case "s":
      return require("../../../../resources/icons/recognition/medal-s.png");
    case "br":
      return require("../../../../resources/icons/recognition/medal-br.png");
    case null:
    case undefined:
      return "";
    default:
      return require("../../../../resources/icons/recognition/medal-bl.png");
  }
}

function PersonCard(props: { person: TopTenPojo }) {
  const {person} = props;
  const link = getPersonPageLink(person.pgId);
  const personImage = restServices.employeeService.getPersonImage(person.pgId!);
  const medalImage = resolveMedalImage(person.medal!);

  return <>
    <Row type={"flex"} align={"middle"}>
      <Col xs={3}>
        <Img src={personImage}
             type={"base64Promise"}
             style={{width: "30px", borderRadius: "100%"}}
             image={NotFoundImage.EMPLOYEE}
        />
      </Col>
      <Col xs={16}>
        <Row>
          <Link to={link}>{person.fullName}</Link>
        </Row>
        <Row>{person.position}</Row>
      </Col>
      <Col xs={2}>
        <Img src={medalImage}/>
      </Col>
      <Col xs={3}>
        {person.count}
      </Col>
    </Row>
  </>
}

@observer
class RecognitionTop extends React.Component<WrappedComponentProps> {
  readonly monthCountTabs: Map<string, number | undefined> = new Map([
    ["one", 1],
    ["three", 3],
    ["six", 6],
    ["twelve", 12],
    ["all", undefined]
  ])

  selectedMonthCount: number | undefined = 1;

  @observable topAwarded: TopTenPojo[] = [];
  @observable topSender: TopTenPojo[] = [];
  @observable isLoading = false;

  @action onTapSwitch = (key: string) => {
    this.selectedMonthCount = this.monthCountTabs.get(key);
    this.load(this.selectedMonthCount);
  }

  async componentDidMount() {
    this.load(this.selectedMonthCount);
  }

  load = (monthCount: number | undefined) => {
    runInAction(() => this.isLoading = true);

    Promise.all([
      restServices.recognitionService.loadTopAwarded(monthCount).then((topAwarded) => {
        runInAction(() => this.topAwarded = topAwarded);
      }),

      restServices.recognitionService.loadTopSender(monthCount).then((topSender) => {
        runInAction(() => this.topSender = topSender);
      })
    ]).finally(() => runInAction(() => this.isLoading = false));
  }

  renderMonthTab = (tab: string, list: TopTenPojo[]) => {
    const tabTitle = this.props.intl.messages[`recognition.top.monthTabs.${tab}`];

    return <Tabs.TabPane tab={tabTitle} key={tab}>
      {list.map((person) => <PersonCard person={person}/>)}
    </Tabs.TabPane>
  }

  renderTab = (list: TopTenPojo[]) => {
    return <Spin spinning={this.isLoading}>
      <Tabs className={"recognition-top-months-tabs"} onChange={this.onTapSwitch} type={"card"} animated={false}>
        {Array.from(this.monthCountTabs.keys()).map(key => this.renderMonthTab(key, list))}
      </Tabs>
    </Spin>
  }

  render() {
    return <>
      <Card className="narrow-layout large-section section-container">
        <Tabs animated={false}>
          <Tabs.TabPane key={"1"} tab={this.props.intl.messages["recognition.topTen.awarded"]}>
            {this.renderTab(this.topAwarded)}
          </Tabs.TabPane>
          <Tabs.TabPane key={"2"} tab={this.props.intl.messages["recognition.topTen.sender"]}>
            {this.renderTab(this.topSender)}
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  }
}

export default injectIntl(RecognitionTop);