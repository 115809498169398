import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { SuccessorStatus } from "./tsadv_SuccessorStatus";
import { PositionGroupExt } from "./base$PositionGroupExt";
import { DicReadinessLevel } from "./tsadv$DicReadinessLevel";
import { PersonAssessment } from "./tsadv_PersonAssessment";
export class SuccessorRequest extends AbstractBprocRequest {
  static NAME = "tsadv_SuccessorRequest";
  initiator?: PersonGroupExt | null;
  successorStatus?: SuccessorStatus | null;
  personGroup?: PersonGroupExt | null;
  positionGroup?: PositionGroupExt | null;
  description?: string | null;
  readinessLevel?: DicReadinessLevel | null;
  assessment?: PersonAssessment | null;
}
export type SuccessorRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "successorRequest-browse"
  | "successorRequest-edit"
  | "successorRequestList-view";
export type SuccessorRequestView<
  V extends SuccessorRequestViewName
  > = V extends "_base"
  ? Pick<
    SuccessorRequest,
    | "id"
    | "requestNumber"
    | "description"
    | "legacyId"
    | "organizationBin"
    | "integrationUserLogin"
    | "requestDate"
    | "comment"
    >
  : V extends "_local"
    ? Pick<
      SuccessorRequest,
      | "id"
      | "description"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      >
    : V extends "_minimal"
      ? Pick<SuccessorRequest, "id" | "requestNumber">
      : V extends "successorRequest-browse"
        ? Pick<
          SuccessorRequest,
          | "id"
          | "assessment"
          | "description"
          | "legacyId"
          | "organizationBin"
          | "integrationUserLogin"
          | "requestNumber"
          | "requestDate"
          | "comment"
          | "initiator"
          | "personGroup"
          | "positionGroup"
          | "readinessLevel"
          | "status"
          >
        : V extends "successorRequest-edit"
          ? Pick<
            SuccessorRequest,
            | "id"
            | "description"
            | "assessment"
            | "legacyId"
            | "organizationBin"
            | "integrationUserLogin"
            | "requestNumber"
            | "requestDate"
            | "comment"
            | "createTs"
            | "initiator"
            | "personGroup"
            | "positionGroup"
            | "readinessLevel"
            | "status"
            | "successorStatus"
            >
          : V extends "successorRequestList-view"
            ? Pick<
              SuccessorRequest,
              | "id"
              | "description"
              | "legacyId"
              | "organizationBin"
              | "integrationUserLogin"
              | "requestNumber"
              | "requestDate"
              | "comment"
              | "positionGroup"
              | "status"
              >
            : never;