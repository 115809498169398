import React, {createElement, FormEvent} from "react";
import {inject, observer} from "mobx-react";
import {
  collection, getCubaREST,
  getEnumCaption,
  getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected,
  Msg, withLocalizedForm
} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Card, Checkbox, Form, Input, Modal, Select, Spin, Table} from "antd";
import Column from "antd/es/table/Column";
import {action, observable, runInAction} from "mobx";
import Button, {ButtonType} from "../../components/Button/Button";
import {restServices} from "../../../cuba/services";
import {PersonAssessment} from "../../../cuba/entities/base/tsadv_PersonAssessment";
import {PerformancePlan} from "../../../cuba/entities/base/tsadv$PerformancePlan";
import {AssignedPerformancePlan} from "../../../cuba/entities/base/tsadv$AssignedPerformancePlan";
import {DicAssessmentStatus} from "../../../cuba/entities/base/tsadv$DicAssessmentStatus";
import {AssessmentParticipant} from "../../../cuba/entities/base/tsadv$AssessmentParticipant";
import {AssessmentDetail} from "../../../cuba/entities/base/tsadv_AssessmentDetail";
import {Assessment} from "../../../cuba/entities/base/tsadv$Assessment";
import {AssessmentCompetence} from "../../../cuba/entities/base/tsadv$AssessmentCompetence";
import Notification from "../../util/Notification/Notification";
import TextArea from "antd/es/input/TextArea";
import {Job} from "../../../cuba/entities/base/tsadv$Job";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots, faEdit} from "@fortawesome/free-solid-svg-icons";
import {FormComponentProps} from "antd/lib/form";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {ScaleLevel as tsadvScaleLevel} from "../../../cuba/entities/base/tsadv$ScaleLevel"

type MainData = {
  date_from: string | null,
  date_to: string | null,
  employeeFullName: string | null,
  participant_person_group_id: string | null,
  participant_type_code: string | null,
  person_group_id: string | null,
  session_name: string | null,
  status_code: string | null,
  total_result: string | null,
  person_assessment_id: string | null,
  instruction: string | null,
  participant_status_id: string | null,
  participant_status_code: string | null,
  partcicipant_result: number | null | undefined
}

interface ScaleLevel {
  scale_level_id: string;
  lang1: string;
  lang2: string;
  lang3: string;
  level_score: number;
}

interface Participant {
  row_id: string;
  entity_id: string;
  entity_name: string;
  person_group_id: string;
  assessment_id: string;
  employee_name: string;
  participant_role_code: string;
  role_name: string;
  participant_order: number;
  scale_level_id: string;
  instruction: string
  scale_level: string;
  comments: string;
  required_to_train?: any;
  scale_levels: ScaleLevel[];
  has_comments: boolean;
  assessment_result: number
}

interface RootObject {
  person_assessment_id: string;
  row_id: string;
  assessment_competence_id: string;
  competence_source: string;
  competence_type_id: string;
  competence_type: string;
  competence_group_id: string;
  competence_name: string;
  required_scale_level_id: string;
  required_scale_level: string;
  scale_levels: ScaleLevel[];
  entityName: string;
  result_percent: number;
  result_id?: any;
  result?: any;
  required_to_train: boolean;
  delta: number;
  has_comments: boolean;
  participants: Participant[];

}

interface RootObjectWithKey extends RootObject {
  key: string;
}

type Props = {
  keyPosHoldersPersonGroups?:PersonGroupExt[];
  assignedPerformancePlanId: string;
  personInitiatorId: string;
  initiatorId: string | undefined;
  setActiveKey: (isActiveKey: boolean) => void
  setAssessmentStatus: (assessmentStatus: string) => void
  personGroupId:string,
  personAssessmentId:string,
  positionGroupName:string,
}

type CompetenceType = {
  competence_type: string;
  children: RootObjectWithKey[]
}
type CompetenceTableRow = {
  key: string,
  competence_type: string,
  competence_type_id: string,
  children: (RootObjectWithKey & { key: string })[]
};

const faCommentDotss = faCommentDots as IconProp;
const faEditt = faEdit as IconProp;

@inject("rootStore")
@injectMainStore
@observer
class CBAGoal extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & Props & FormComponentProps> {

  @observable mainTableData: Array<MainData>

  @observable detailTableData: Array<CompetenceTableRow>

  @observable dataOriginal: RootObject[]

  @observable GroupScale: any
  @observable personOrder: any

  @observable modalData: any

  @observable personAssessmentId: string

  @observable assessmentSessionId: string;

  @observable totalResult: number

  @observable competeceTotalResult: number | null

  @observable assessmentStatus: string | null

  @observable instruction: string | null

  @observable assessmentCompetenceId: string | null

  @observable isLoadedMainData: boolean = true

  @observable isLoadedDetailData: boolean = true

  @observable isSendStatusToKPI: boolean = false

  @observable isSendStatusToKPIOnRender: boolean = false

  @observable visible: boolean = false;

  @observable commentModalVisible: boolean = false

  @observable isCommentModalEdit: boolean = false;

  @observable changedRequired = {};

  @observable changedAssessment = {};

  @observable changed = {};

  @observable personInfo: any

  @observable commentInputValue: string = ""

  @observable participants: Participant[]

  @observable participantTypeCode: string

  @observable assessmentId: string
  
  @observable dictScaleLvl: tsadvScaleLevel[] | undefined

  @action.bound setDictScaleLevel = (levels:tsadvScaleLevel[]) => this.dictScaleLvl = levels 

  personGroup = this.props.rootStore!.userInfo.personGroupId;

  visibleAssessmentParticipantGroupIds: string[] = []


  @observable statusesDc = collection<DicAssessmentStatus>(DicAssessmentStatus.NAME, {
    view: "_local"
  })

  @action openCommentModal = (record: any, edit: boolean) => {
    this.modalData = record
    this.isCommentModalEdit = edit;
    this.commentModalVisible = true
  }

  getPersonAssessmentForm = (personAssessmentId: string) => {
    restServices.learningService.getPersonAssessmentForm({
      personGroupId: personAssessmentId,
      lang: this.props.rootStore!.userInfo.locale!
    }).then(v => {
      runInAction(() => {
        let arr: RootObject[] = (JSON.parse(v && v[0] && v[0]!.value!))
        this.totalResult = arr[0].participants[0].assessment_result
        this.dataOriginal = (JSON.parse(v && v[0] && v[0]!.value!)).filter((item: RootObject) => item.entityName === AssessmentDetail.NAME).map((i: RootObject) => i.participants&&i.participants.length>0&&  i.participants.filter((v: Participant) => v.person_group_id === this.props.rootStore!.userInfo!.personGroupId!))
        this.isLoadedDetailData = false
        this.detailTableData = this.groupDataByCompetenceType(JSON.parse(v && v[0] && v[0]!.value!))
        this.participants = arr[0].participants;
        this.assessmentCompetenceId = (JSON.parse(v && v[0] && v[0]!.value!))[0].assessment_competence_id
        this.personOrder = (this.dataOriginal.map(item => item && item[0].participant_order))
        this.instruction = (this.dataOriginal.map(item => item && item[0].instruction)[0])
        this.GroupScale = (this.dataOriginal.map(item => item && item[0].scale_level).filter((item) => item !== null)).length
      })


    })
  }
  groupDataByCompetenceType = (arr: RootObject[]): CompetenceTableRow[] => {
    const map = arr.reduce((acc, cur, index) => {
      if (cur.competence_type_id && cur.competence_type) {
        if (!acc.has(cur.competence_type_id)) {
          acc.set(cur.competence_type_id, {
            competence_type_id: cur.competence_type_id,
            competence_type: cur.competence_type,
            key: cur.competence_type_id,
            children: []
          });
        }
        acc.get(cur.competence_type_id)!.children.push({
          ...cur,
          competence_type: cur && cur.competence_name,
          key: `${cur.competence_group_id || index}-${cur.row_id || index}`
        });
      }
      return acc
    }, new Map() as Map<string, CompetenceTableRow>);

    return Array.from(map.values())
  };

  renderColumnIfVisible = (employee: Participant, column: JSX.Element) => {
    return this.visibleAssessmentParticipantGroupIds.includes(employee["person_group_id"])
      ? column
      : undefined;
  }

  async componentDidMount() {
    this.visibleAssessmentParticipantGroupIds = await restServices.competenceTemplateParticipantService.getVisibleAssessmentParticipantGroupIds(this.props.personAssessmentId)
    getCubaREST()!.loadEntities<tsadvScaleLevel>(tsadvScaleLevel.NAME,{view:"_minimal"})
    .then((res)=> this.setDictScaleLevel(res))
    this.personAssessmentId = this.props.personAssessmentId
    this.loadDetailCBACard(this.props.personAssessmentId,this.props.personGroupId)
    getCubaREST()!.searchEntities<AssignedPerformancePlan>(AssignedPerformancePlan.NAME, {
      conditions: [{
        property: "id",
        operator: "=",
        value: this.props.assignedPerformancePlanId
      }]
    }, {view: "assignedPerformancePlan-myKpi"}).then(v => {
      getCubaREST()!.searchEntities<PerformancePlan>(PerformancePlan.NAME, {
        conditions: [{
          property: "id",
          operator: "=",
          value: v[0].performancePlan!.id!
        }]
      }, {view: "performancePlan.edit"}).then(v => {
        runInAction(() => {
          this.assessmentSessionId = v[0].assessmentSession!.id!
        })

      })
    })
  }

  render() {
    return this.renderDetailTable()
  }

  loadDetailCBACard = (personAssesmentId: string, personGroupId: string) => {
    restServices.employeeService.personProfile(personGroupId).then(res => {
      runInAction(() => {
        this.personInfo = res
      })
    })
    this.getPersonAssessmentForm(personAssesmentId)
  }

  renderCommentTextAreas = (GroupObj: any) => {
    return <div style={{display: "flex", flexDirection: "column-reverse", gap: 10}}>
      {this.participants && this.participants.map((employee, empInd) => {
        return <div>
          <label>{employee.employee_name}</label>
          <TextArea
            defaultValue={
              (this.modalData && this.modalData.participants && this.modalData.participants[empInd] && this.modalData.participants[empInd].person_group_id === this.props.rootStore!.userInfo!.personGroupId! &&
                GroupObj[this.modalData && this.modalData.participants[empInd] && this.modalData.participants[empInd].entity_id] &&
                GroupObj[this.modalData.participants[empInd].entity_id].hasOwnProperty("comment") && GroupObj[this.modalData.participants[empInd].entity_id].comment
              )
                ? GroupObj[this.modalData && this.modalData.participants[empInd] && this.modalData.participants[empInd].entity_id] && GroupObj[this.modalData.participants[empInd].entity_id].hasOwnProperty("comment") && GroupObj[this.modalData.participants[empInd].entity_id].comment
                : (this.modalData && this.modalData.participants[empInd].comments)
            }
            autoSize={{minRows: 5, maxRows: 15}}
            disabled={!this.isCommentModalEdit || this.modalData && this.modalData.participants && this.modalData.participants[empInd].person_group_id !== this.props.rootStore!.userInfo!.personGroupId!}
            onChange={(e) => {
              if ((GroupObj[this.modalData && this.modalData.participants[empInd] && this.modalData.participants[empInd].entity_id] && GroupObj[this.modalData.participants[empInd].entity_id].hasOwnProperty("comment") && GroupObj[this.modalData.participants[empInd].entity_id].comment)) {
                this.commentInputValue = GroupObj[this.modalData && this.modalData.participants[empInd] && this.modalData.participants[empInd].entity_id] && GroupObj[this.modalData.participants[empInd].entity_id].hasOwnProperty("comment") && GroupObj[this.modalData.participants[empInd].entity_id].comment
                this.commentInputValue += e.target.value
              }
              this.commentInputValue = e.target.value
            }}
          />
        </div>
      })}</div>
  }
  @observable updateLoading: true
  renderDetailTable = () => {
    let statusSEND = this.statusesDc.items.find((item: any) => {
      return item.code === "SEND";
    });
    let statusSAVE = this.statusesDc.items.find((item: any) => {
      return item.code === "SAVE";
    });

    let openModal = () => {
      runInAction(() => {
        this.visible = true
      })

    }
    const SendedStatusinfoOnRender = () => {
      Modal.info({
        title: 'Вы уже успешно сохранили статус',
        content: (
          <div>
            <p>Вы уже успешно сохранили статус</p>
          </div>
        ),
        onOk() {
        },
      });
    }
    const SendedStatusinfo = () => {
      Modal.success({
        content: 'Переходите на вкладку КПИ форма',
      });
    }
    // if(this.isSendStatusToKPIOnRender){
    //   SendedStatusinfoOnRender()
    // }
    if (this.isSendStatusToKPI) {
      SendedStatusinfo()
    }

    const commentBasedOnRole = (record: any, value: string) => {
      if (value.length > 2500) {
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "value.exceeds.length"
          }, {length: 2500})
        })
        return
      }
      this.participants && this.participants.map((employee, empInd) => {
        if (record.participants[empInd].person_group_id === this.props.rootStore!.userInfo!.personGroupId!) {
          this.changedAssessment[record.participants[empInd].entity_id]
            ? this.changedAssessment[record.participants[empInd].entity_id].comment = value
            : this.changedAssessment[record.participants[empInd].entity_id] =
              {
                id: record.participants[empInd].entity_id,
                comment: value
              }
        }
      })
      new Promise((resolve => {
        Object.keys(this.changedAssessment).forEach((item) => {
          getCubaREST()!.commitEntity<Assessment>(Assessment.NAME, {
            id: item,
            ...(this.changedAssessment[item].hasOwnProperty("comment")) && {comment: this.changedAssessment[item].comment},
          }).then(() => {
            this.changedAssessment = {}
          }).catch((a: any) =>
            Notification.error({
              message: this.props.intl.formatMessage({
                id: "management.editor.error"
              })
            }))
        });
        resolve()
      })).then(() => {
        this.getPersonAssessmentForm(this.personAssessmentId)
      })
    }
    const commentBasedOnRoleDetail = (record: any, value: string) => {
      if (value.length > 2500) {
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "value.exceeds.length"
          }, {length: 2500})
        })
        return
      }
      this.participants && this.participants.map((employee, empInd) => {
        if (record.participants[empInd].person_group_id === this.props.rootStore!.userInfo!.personGroupId!) {
          this.changed[record.participants[empInd].entity_id]
            ? this.changed[record.participants[empInd].entity_id].comment = value
            : this.changed[record.participants[empInd].entity_id] = {
              id: record.participants[empInd].entity_id,
              comment: value
            }
        }
      })
      new Promise((resolve => {
        Object.keys(this.changed).forEach((item) => {
          getCubaREST()!.commitEntity<AssessmentDetail>(AssessmentDetail.NAME, {
            id: item,
            ...(this.changed[item].hasOwnProperty("comment")) && {comments: this.changed[item].comment},
          }).then(() => {
            this.changed = {}
          }).catch((a: any) =>
            Notification.error({
              message: this.props.intl.formatMessage({
                id: "management.editor.error"
              })
            }))
        });
        resolve()
      })).then(() => {
        this.getPersonAssessmentForm(this.personAssessmentId)
      })

    }

    let sendEntity = (e: FormEvent) => {
      this.props.form.validateFields({force: true}, (err, values) => {
        if (err || this.GroupScale !== this.dataOriginal.length) {
          Notification.error({
            message: this.props.intl.formatMessage({
              id: "management.editor.validationError.cba"
            })
          });
          return;
        }

        let dataParticipant: PersonAssessment = {
          id: this.assessmentId,
          assessmentStatus: {id: statusSEND!.id}
        }
        getCubaREST()!.commitEntity<AssessmentParticipant>(AssessmentParticipant.NAME, dataParticipant).then(() => {
          Notification.success({
            message: this.props.intl.formatMessage({id: "management.editor.submit"})
          });
          this.props.setAssessmentStatus("SEND")
          getCubaREST()!.searchEntities<AssessmentParticipant>(AssessmentParticipant.NAME, {
            conditions: [{
              property: "participantPersonGroup.id",
              operator: "=",
              value: this.personGroup!
            },
              {
                property: "personAssessment.assessmentTemplate.id",
                operator: "=",
                value: this.assessmentSessionId!
              }, {
                property: "personAssessment.id",
                operator: "=",
                value: this.personAssessmentId!
              }]
          }, {view: "assessmentParticipant-for-asd"}).then(v => {
            runInAction(() => {
              this.assessmentStatus = v[0].assessmentStatus!.code!
              if (this.assessmentStatus === "SEND") {
                this.isSendStatusToKPI = true
              }
              this.props.setAssessmentStatus(this.assessmentStatus)
            })
          }).catch(() => {
            Notification.error({
              message: "Please try again"
            });
          })
        })

      })

    }
    return <>
      <Card className={"narrow-layout card-actions-container"}
            actions={this.isSendStatusToKPI ? [
              <Button buttonType={ButtonType.PRIMARY}
                      onClick={openModal}>
                <FormattedMessage id={"cbaSend"}/>
              </Button>
            ] : [<></>]}>
        <Spin spinning={this.isLoadedDetailData}>
          <div style={{width:"100%"}}>
            <div style={{display: "inline-block",width:"50%"}}>
          {createElement(Msg, {entityName: Job.NAME, propertyName: "instruction"})}
          <TextArea value={this.instruction ? this.instruction : ""}
                    style={{width: "90%",display:"block"}}
                    defaultValue={this.instruction ? this.instruction : ""}
                    autosize={{minRows: 2, maxRows: 15}} rows={4}
                    disabled/>
            </div>
            <div style={{display: "inline-block",width: "50%",height:"100%"}}>
            {<FormattedMessage id="positionGroup"/>}
              <TextArea value={this.props.positionGroupName?this.props.positionGroupName:""}
                        style={{width: "90%",display:"block"}}
                        autosize={{minRows: 2, maxRows: 15}} rows={4}
                        disabled/>
            </div>
          </div>
          <div style={{display: "flex", margin: "20px 0"}}>
            <div style={{marginRight: "40px", display: "flex", flexDirection: "column"}}>
              <FormattedMessage id={"cba.detail.totalResult"}/>
              <Input
                style={{maxWidth: "200px", marginTop: "8px"}}
                value={this.totalResult ? this.totalResult : 0}
                disabled/>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
              <FormattedMessage id={"CompetenceTotalResult"}/>
              <Input
                style={{maxWidth: "200px", marginTop: "8px"}}
                value={this.participantTypeCode === "MANAGER" ? this.totalResult : 0}
                disabled/>
            </div>
          </div>
          <Table dataSource={this.detailTableData}
                 scroll={{x: true}}
                 bordered={true}
                 tableLayout={'fixed'}
                 className={"cba-detail"}
                 rowKey={(record: CompetenceTableRow) => record.key}
          >
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.source"})}
              dataIndex={"source"}
              key={"source"}
              render={
                (text, record: RootObject, index) => record.entityName !== AssessmentDetail.NAME && getEnumCaption((record as RootObject)!.competence_source!, getPropertyInfoNN("competenceSource", "tsadv$Assessment", this.props.mainStore!.metadata!), this.props.mainStore!.enums!)

              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.competency"})}
              dataIndex={"competency"}
              key={"competency"}
              render={
                (text, record: RootObject, index) => record.hasOwnProperty("competence_name") ? (record as RootObject)!.competence_name! : (record as RootObject)!.competence_type!
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.requiredlevel"})}
              dataIndex={"requiredlevel"}
              key={"requiredlevel"}
              render={
                (text, record, index) => (record as RootObject).required_scale_level
              }
            />
            {this.participants && this.participants.map((employee, empoyeeIndex) =>
              this.renderColumnIfVisible(employee, (
                <Column
                  title={() => <>{employee.role_name}<br/><span>{employee.employee_name}</span></>}
                  dataIndex={"participants"}
                  key={employee.entity_id}
                  render={
                    (text, record: RootObject, index) => record.entityName === AssessmentDetail.NAME && record.participants && record.participants[empoyeeIndex].person_group_id === this.props.rootStore!.userInfo!.personGroupId! ?
                      <div className={"cba-form-item"}>
                        <Form.Item
                          help={record.participants[empoyeeIndex].scale_level && record.participants[empoyeeIndex].scale_level.length > 0 && record.participants[empoyeeIndex].scale_level && record.participants[empoyeeIndex].scale_level != "" ? undefined : this.props.intl.formatMessage({id: "indicatorError"})}
                          validateStatus={record.participants[empoyeeIndex].scale_level && record.participants[empoyeeIndex].scale_level.length > 0 && record.participants[empoyeeIndex].scale_level && record.participants[empoyeeIndex].scale_level != "" ? "success" : "error"}
                          style={{marginBottom: 0, display: "flex", justifyContent: "center", alignItems: "center"}}
                          label={createElement(Msg, {
                            propertyName: record.competence_name
                          })}>{
                          this.props.form.getFieldDecorator(`empoyee-${empoyeeIndex}` + record.row_id, {
                            initialValue: record.participants && record.participants[empoyeeIndex].scale_level,
                            rules: [

                              {
                                validator: (rule: any, value: any, callback: any) => {
                                  if (!value) {
                                    callback(this.props.intl.formatMessage({id: 'cubaReact.dataTable.requiredField'}));
                                    return;
                                  }
                                  callback();
                                }
                              }],
                            getValueFromEvent: (args => {
                              getCubaREST()!.commitEntity<AssessmentDetail>(AssessmentDetail.NAME, {
                                id: record.participants && record.participants[empoyeeIndex]!.entity_id,
                                scaleLevel: {
                                  id: args
                                }
                              }).then(() => {
                                this.isLoadedDetailData = true
                                restServices.learningService.updateAssessment({assessmentId: record.participants[empoyeeIndex].assessment_id}).then((v) => {
                                  if (v) {
                                    this.getPersonAssessmentForm(this.personAssessmentId)
                                  }
                                })
                              })
                              return args
                            })
                          })(
                            <Select
                              disabled={this.assessmentStatus === "SEND"}
                              style={{width: 175}}>{this.dictScaleLvl && this.dictScaleLvl.map((item) =>
                              <Select.Option key={item.id} value={item.id}>{item['_instanceName']}</Select.Option>)}
                            </Select>)}
                        </Form.Item>
                      </div> : record.participants && record.participants[empoyeeIndex].scale_level
                  }
                />
              )))}
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.total"})}
              dataIndex={"total"}
              key={"total"}
              render={
                (text, record, index) => (record as RootObject).result_percent
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.result"})}
              dataIndex={"result"}
              key={"result"}
              render={
                (text, record, index) => (record as RootObject).result
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.requiredToTrain"})}
              dataIndex={"requiredToTrain"}
              key={"requiredToTrain"}
              render={
                (text, record: RootObject, index) => {
                  if (record.entityName !== AssessmentDetail.NAME) {
                    return (
                      <Checkbox disabled={!(this.props.keyPosHoldersPersonGroups&&this.props.keyPosHoldersPersonGroups.map(holder=>holder.id).includes(this.props.rootStore!.userInfo.personGroupId!))} onChange={(e) => runInAction(() => {
                        getCubaREST()!.commitEntity<AssessmentCompetence>(AssessmentCompetence.NAME, {
                          id: record.assessment_competence_id,
                          requiredDevelopmet: e.target.checked
                        }).catch((a: any) =>
                          Notification.error({
                            message: this.props.intl.formatMessage({
                              id: "management.editor.error"
                            })
                          }))

                      })}
                                defaultChecked={(record as RootObject).required_to_train}/>)
                  } else {
                    return ""
                  }
                }
              }
            />
            <Column
              title={this.props.intl.formatMessage({id: "cba.detail.comments"})}
              dataIndex={"comments"}
              key={"comments"}
              align={"center"}
              render={
                (text, record: RootObject) => {
                  if (record.entityName) {
                    return <> {this.assessmentStatus === "SEND" ?
                      <>{record.has_comments ?
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            this.openCommentModal(record, false);
                          }}><FontAwesomeIcon
                          size={"2x"} icon={faCommentDotss}/></a>
                        : null
                      }
                      </>
                      :
                      <>
                        <a
                          onClick={(e) => {
                            this.openCommentModal(record, true);
                          }}>
                          {<><FontAwesomeIcon
                            color={(this.changed[record.participants && record.participants[this.personOrder && this.personOrder[0] - 1] && record.participants[this.personOrder && this.personOrder[0] - 1]["entity_id"]] && this.changed[record.participants && record.participants[this.personOrder && this.personOrder[0] - 1] && record.participants[this.personOrder && this.personOrder[0] - 1]["entity_id"]]!.hasOwnProperty("comment")! ||
                              (record && record.participants && record.participants[this.personOrder && this.personOrder[0] - 1] && record.participants[this.personOrder && this.personOrder[0] - 1].comments)
                            ) ?
                              "green" : "#005487"
                            } style={{marginRight: 5}} size={"2x"} icon={
                            faEditt}/></>}
                        </a>
                        {
                          record.has_comments ? <a
                            onClick={(e) => {
                              e.preventDefault();
                              this.openCommentModal(record, false);
                            }}><FontAwesomeIcon
                            size={"2x"} icon={faCommentDotss}/></a> : null
                        }
                      </>
                    }
                    </>
                  } else {
                    return ""
                  }
                }
              }
            />
          </Table>
          <Modal centered children={<span>{this.props.intl.formatMessage({id: "cbaSure"})}</span>}
                 visible={this.visible}
                 onCancel={() => {
                   runInAction(() => {
                     this.visible = false
                   })
                 }} onOk={(e) => {
            sendEntity(e);
            runInAction(() => {
              this.visible = false
            })
          }}/>
          <Modal
            maskClosable={false}
            centered
            onOk={() => {
              (this.modalData && this.modalData.entityName === AssessmentDetail.NAME)
                ? commentBasedOnRoleDetail(this.modalData, this.commentInputValue)
                : commentBasedOnRole(this.modalData, this.commentInputValue)
              runInAction(() => {
                this.commentModalVisible = false
              })
            }
            }
            destroyOnClose
            title={this.props.intl.formatMessage({id: "comment"})}
            onCancel={() => {
              runInAction(() => {
                this.commentModalVisible = false
                this.modalData = undefined
              })
            }}
            visible={this.commentModalVisible}>
            <>
              {
                this.modalData && this.modalData.entityName === AssessmentDetail.NAME
                  ? this.renderCommentTextAreas(this.changed)
                  : this.renderCommentTextAreas(this.changedAssessment)
              }
            </>
          </Modal>
        </Spin>

      </Card>
    </>
  }
}

export default injectIntl(
  withLocalizedForm
  ({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
          props.form.setFields({
            [fieldName]: {
              value: changedValues[fieldName]
            }
          });
        }
      );
    }
  })
  (CBAGoal)
);