import React, { HTMLAttributes } from 'react';
import NoImage, {NoImageProps, NotFoundImage} from "../NoImage";
import { observer } from "mobx-react";
import { observable } from "mobx";

export type ImageType = "base64" | "src" | "promise" | "base64Promise"

type Props = {
  type?: ImageType,
  src?: any,
  alt?: string,
  noFoundImage?: NotFoundImage
}

@observer
class Img extends React.Component<Props & HTMLAttributes<HTMLImageElement> & NoImageProps> {

  @observable imageSrc: string;

  render() {
    const {type, style} = this.props;
    return this.imageSrc
      ? <img {...this.props}
             src={type && type === "base64" ? ("data:image/png;base64, " + this.imageSrc) : this.imageSrc}
             style={Object.assign({maxWidth: "250px", maxHeight: "250px"}, style)}/>
      : <NoImage {...this.props} className={this.props.className}/>;
  }
  
  componentDidUpdate(prevProps: Readonly<Props & React.HTMLAttributes<HTMLImageElement> & NoImageProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.src !== prevProps.src) {
      this.init();
    }
  }


  componentDidMount(): void {
    this.init();
  }

  init() {
    if (this.props.type === 'promise' && this.props.src) {
      this.props.src.then((response: string) => {
        this.imageSrc = response
      }).catch(() => {
      })

      return;
    }

    if (this.props.type === 'base64Promise' && this.props.src) {
      this.props.src.then((response: string) => {
        this.imageSrc = response
      }).catch(() => {
      })

      return;
    }

    this.imageSrc = this.props.src
  }
}

export default Img;