import React from "react";
import {restServices} from "../../../../cuba/services";
import {observable, reaction, runInAction} from "mobx";
import {observer} from "mobx-react";
import {RecognitionTypePojo} from "../../../../cuba/entities/base/tsadv$RecognitionTypePojo";
import {getFileUrl} from "../../../util/util";

type RecognitionProfileTypesProps = {
  personGroupId: string;
}

@observer
export class RecognitionProfileTypes extends React.Component<RecognitionProfileTypesProps> {

  @observable recognitionTypes: RecognitionTypePojo[] = [];

  componentDidMount() {
    reaction(
      () => this.props.personGroupId,
      () => this.reload(),
      {fireImmediately: true}
    );
  }

  reload = () => {
    restServices.recognitionService.loadRecognitionTypes(this.props.personGroupId)
      .then(recognitionTypes => runInAction(() => this.recognitionTypes = recognitionTypes));
  }

  renderRecognitionType = (recognitionType: RecognitionTypePojo) => {
    const typeImage = getFileUrl(recognitionType.image!);

    return <div style={{textAlign: "center", margin: '10px'}}>
      <img style={{width: '50px'}} src={typeImage}/>
      <h1 style={{fontWeight: "bold"}}>{recognitionType.count}</h1>
      <p>{recognitionType.name}</p>
    </div>
  }

  render() {
    return <div style={{width: '100%', display: "flex", justifyContent: "center"}}>
      {this.recognitionTypes.map(this.renderRecognitionType)}
    </div>
  }
}