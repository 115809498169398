import React from "react";
import {Button, Col, Icon, Row} from "antd";
import Img from "../../../components/Img";
import {GoodsPojo} from "../../../../cuba/entities/base/tsadv$GoodsPojo";
import {shopStore} from "../store/ShopStore";
import {observer} from "mobx-react";
import CoinsComponent from "./CoinsComponent";
import {getFileUrlOpt} from "../../../util/util";
import {injectIntl, WrappedComponentProps} from "react-intl";

type StoreElementProps = {
  item: GoodsPojo
}

@observer
class ShopElement extends React.Component<StoreElementProps & WrappedComponentProps> {

  addToCart = () => {
    shopStore.addToCart(this.props.item.id);
  }

  addToWishList = () => {
    shopStore.addToWishList(this.props.item.id);
  }

  render() {
    const item = this.props.item;

    return <Row type={"flex"} gutter={[20, 20]} style={{border: "solid 1px", borderRadius: "6px", margin: '10px', height: "190px"}}>
      <Col xs={3} style={{borderRight: 'solid 1px black'}}>
        <Row type={"flex"} style={{alignItems: "center", height: "100%"}}>
          <Img src={getFileUrlOpt(item.image!)} style={{width: "100%"}} />
        </Row>
      </Col>
      <Col xs={16}>
        <h2>{item.name}</h2>
        <p>{item.description}</p>
      </Col>
      <Col xs={5} style={{display: "flex", alignItems: "center"}}>
        <Row type={"flex"} justify={"center"} style={{paddingBottom: "30px"}}>
          <Col xs={24}>
            <CoinsComponent value={item.price}/>
          </Col>
          <Col xs={24}>
            <Col xs={5}>
              <Button block onClick={this.addToWishList}>
                <Icon theme={item.inWishList === 1 ? "filled" : "outlined"} type="heart"/>
              </Button>
            </Col>
            <Col xs={19}>
              <Button block onClick={this.addToCart} disabled={item.inCart !== 0}>
                {
                  item.inCart === 0
                    ? this.props.intl.messages["recognition.shopElement.addToCart"]
                    : this.props.intl.messages["recognition.shopElement.inCart"]
                }
              </Button>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  }
}

export default injectIntl(ShopElement);