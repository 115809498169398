import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import {action, observable, runInAction} from "mobx";
import {restServices} from "../../../../cuba/services";
import {GoodsOrderPojo} from "../../../../cuba/entities/base/tsadv$GoodsOrderPojo";
import {Table} from "antd/es";
import {ColumnProps} from "antd/es/table/interface";
import CoinsComponent from "../Components/CoinsComponent";
import {PaginationConfig} from "antd/es/pagination";
import {GoodsOrderPageInfo} from "../../../../cuba/entities/base/tsadv$GoodsOrderPageInfo";


@inject("rootStore")
@injectMainStore
@observer
class OrdersPage extends React.Component<RootStoreProp & WrappedComponentProps> {

  @observable goodsOrderPojo: GoodsOrderPojo[] = [];
  @observable isLoading: boolean = false;
  @observable lastCount: number = 0;

  messages = this.props.intl.messages;

  columns: ColumnProps<GoodsOrderPojo>[] = [
    {
      title: this.messages["recognition.shop.order"],
      dataIndex: 'orderNumber',
    },
    {
      title: this.messages["recognition.shop.date"],
      dataIndex: 'dateTime',
    },
    {
      title: this.messages["recognition.shop.sum"],
      dataIndex: 'sum',
      render: (sum) => <CoinsComponent value={sum}/>
    },
    {
      title: this.messages["recognition.shop.status"],
      dataIndex: 'status',
    },
    {
      title: this.messages["recognition.shop.voucherUsed"],
      dataIndex: 'voucherUsed',
      render: (voucherUsed) => voucherUsed
        ? this.messages["recognition.shop.yes"]
        : this.messages["recognition.shop.no"]
    }
  ];

  componentDidMount() {
    this.loadPage(1);
  }

  @action handleResult = (result: GoodsOrderPageInfo) => {
    this.goodsOrderPojo = result.goodsOrders!;
    this.lastCount = result.pageInfo!.totalRowsCount;
  }

  loadPage(page: number) {
    this.isLoading = true;
    restServices.recognitionService.loadGoodsOrders(page, this.lastCount, "", "")
      .finally(() => runInAction(() => this.isLoading = false))
      .then(this.handleResult)
  }

  onChange = (pagination: PaginationConfig) => {
    this.loadPage(pagination.current!);
  }

  render() {
    return (
      <Table dataSource={this.goodsOrderPojo}
             columns={this.columns}
             loading={this.isLoading}
             pagination={{total: this.lastCount, pageSize: 20, position: "top"}}
             onChange={this.onChange}
      />
    )
  }
}

export default injectIntl(OrdersPage);