import * as React from "react";
import { inject, observer } from "mobx-react";
import { collection, injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import DataTableFormat from "../../components/DataTable/intex";
import { AbsenceBalance } from "../../../cuba/entities/base/tsadv$AbsenceBalance";
import { restServices } from "../../../cuba/services";
//@ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import moment from "moment";
import { action, observable } from "mobx";

type Props = { personGroupId?: string | undefined };

@injectMainStore
@inject("rootStore")
@observer
class AbsenceBalanceComponent extends React.Component<MainStoreInjected & WrappedComponentProps & Props> {

    @observable
    absenceBalance: number | undefined = 0;

    @action
    setAbsenceBalance = (num: number): void => {
        this.absenceBalance = num;
    }

    dataCollectionAbsenceBalance = collection<AbsenceBalance>(AbsenceBalance.NAME, {
        view: "_local",
        sort: "-dateFrom",
        filter: {
            conditions: [{ property: "personGroup.id", operator: "=", value: this.props.personGroupId! }]
        }
    });

    AbsenceBalanceFields = [
        "dateFrom", // Дата с
        "dateTo", // Дата по
        "balanceDays", // Положено дней
        "daysSpent", // Использовано дней
        "daysLeft", // Остаток
    ];

    componentDidMount(): void {
        restServices.absenceBalanceService.getNewPersonBalance({
            personGroupId: this.props.personGroupId ? this.props.personGroupId : "",
        }).then(result => {
            if (result[0]) {
                this.dataCollectionAbsenceBalance.items = JSON.parse((result)[0].value);
            }
        }).then(() => {
            restServices.absenceBalanceService.getAbsenceBalance({
                absenceDate: new Date(),
                personGroupId: this.props.personGroupId ? this.props.personGroupId : "",
            }).then(result => {
                if (typeof result === "number") {
                    this.setAbsenceBalance(result);
                }
            })
        })
    }

    render() {
        return (
            <div>
                <h2 style={{ color: "deepskyblue" }}>
                    {this.props.intl.formatMessage({ id: "currentAbsenceBalance" }) + " " + this.absenceBalance}
                </h2>
                <div>
                    <ReactHTMLTableToExcel id="test-table-xls-button"
                        className="ant-btn ant-btn-lg"
                        table="table-to-xls"
                        filename={new Date().toDateString()}
                        sheet="tablexls"
                        buttonText="Excel"
                    >
                    </ReactHTMLTableToExcel>
                </div>
                <table id={"table-to-xls"}>
                    <DataTableFormat
                        dataCollection={this.dataCollectionAbsenceBalance}
                        fields={this.AbsenceBalanceFields}
                        hideSelectionColumn={true}
                        enableFiltersOnColumns={[]}
                        render={
                            [
                                {
                                    column: "dateFrom",
                                    render: (text, record) => moment(record.dateFrom).format('DD.MM.YYYY')
                                },
                                {
                                    column: "dateTo",
                                    render: (text, record) => moment(record.dateTo).format('DD.MM.YYYY')
                                },
                                {
                                    column: "balanceDays",
                                    render: (text, record) => record.balanceDays
                                },
                                {
                                    column: "daysSpent",
                                    render: (text, record) => record.daysSpent
                                },
                                {
                                    column: "daysLeft",
                                    render: (text, record) => record.daysLeft
                                }
                            ]
                        }
                    />
                </table>
            </div>
        )
    }
}

const AbsenceBalanceList = injectIntl(AbsenceBalanceComponent);

export default AbsenceBalanceList;