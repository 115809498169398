import * as React from "react";
import MyProfileCba from "./MyProfileCBA";
import {MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
type Props={
  personGroupId:string
}
class MyProfileCbaManagement extends React.Component<Props&MainStoreInjected&WrappedComponentProps> {
  render() {
    return (
      <>
      <MyProfileCba personGroupId={this.props.personGroupId}/>
      </>
    );
  }
}
export default injectIntl(MyProfileCbaManagement)
