import {action, computed, observable} from "mobx";
import {restServices} from "../../../../cuba/services";
import {cartStore} from "./CartStore";
import {GoodsPojo} from "../../../../cuba/entities/base/tsadv$GoodsPojo";


class ShopStore {
  @observable goods: GoodsPojo[] = [];
  @observable categoryId = "-1";
  @observable sort = "0";
  @observable page: number = 0;
  @observable totalPageCount: number = 0;
  @observable isLoading = false;

  lastCount: number = 0;

  @computed get isLastPage(): boolean {
    return this.totalPageCount === this.page;
  }

  @action loadNextGoodsPage = async () => {
    this.page++;
    this.isLoading = true;

    const resp = await restServices.recognitionService.loadGoods(this.page, this.lastCount, this.categoryId, this.sort)
      .finally(() => this.isLoading = false);

    this.goods = [...this.goods!, ...resp.goods!];

    this.totalPageCount = resp.pageInfo!.pagesCount;
    this.lastCount = resp.pageInfo!.totalRowsCount;
  }

  @action reloadGoodsPage = async () => {
    this.page = 0;
    this.lastCount = 0;
    this.totalPageCount = 0;
    this.goods = [];
    await this.loadNextGoodsPage();
  }

  @action setCategoryId = async (categoryId: string) => {
    this.categoryId = categoryId;
    await this.reloadGoodsPage();
  }

  @action setSort = async (sort: string) => {
    this.sort = sort;
    await this.reloadGoodsPage();
  }

  @action addToWishList = async (id: string) => {
    await restServices.recognitionService.addToWishList(id);
    const goodsPojo = this.goods.find(e => e.id === id)!;
    goodsPojo.inWishList = goodsPojo.inWishList === 0 ? 1 : 0;
  }

  @action addToCart = async (id: string) => {
    await restServices.recognitionService.addGoodsToCart(id);
    const goodsPojo = this.goods.find(e => e.id === id)!;
    cartStore.loadGoodsCartCount();
    goodsPojo.inCart = 1;
  }
}

export const shopStore = new ShopStore();