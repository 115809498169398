import { StandardEntity } from "./sys$StandardEntity";
import { DicRegion } from "./tsadv_DicRegion";
import { DicCurrency } from "./base$DicCurrency";
import { DicCompany } from "./base_DicCompany";
export class PerdiemsSetting extends StandardEntity {
  static NAME = "tsadv_PerdiemsSetting";
  region?: DicRegion | null;
  mci?: any | null;
  amount?: any | null;
  currency?: DicCurrency | null;
  companyCode?: DicCompany | null;
  livingExpenses?: any | null;
  travelExpenses?: any | null;
}
export type PerdiemsSettingViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "perdiems-front-view";
export type PerdiemsSettingView<
  V extends PerdiemsSettingViewName
> = V extends "_base"
  ? Pick<
      PerdiemsSetting,
      "id" | "mci" | "amount" | "livingExpenses" | "travelExpenses"
    >
  : V extends "_local"
  ? Pick<
      PerdiemsSetting,
      "id" | "mci" | "amount" | "livingExpenses" | "travelExpenses"
    >
  : V extends "perdiems-front-view"
  ? Pick<
      PerdiemsSetting,
      | "id"
      | "mci"
      | "amount"
      | "livingExpenses"
      | "travelExpenses"
      | "companyCode"
      | "region"
      | "currency"
    >
  : never;
