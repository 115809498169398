import * as React from "react";
import { inject, observer } from "mobx-react";
import {
  collection,
  injectMainStore,
  MainStoreInjected,
  getCubaREST
} from "@cuba-platform/react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
  injectIntl,
  WrappedComponentProps,
  FormattedMessage
} from "react-intl";
import { RootStoreProp } from "../../store";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import DataTable from "../../components/DataTable/intex";
import NcaLayer from "./ncalayer";
import { restServices } from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";
import { Button, Col, Row, Table, Modal, Typography } from "antd";
import { UcoSignSignature } from "../../../cuba/entities/base/ucosign$Signature";
import {downloadFile, goBackOrHomePage, parseUrlForUserCode} from "../../util/util";
import { FileDescriptor } from "../../../cuba/entities/base/sys$FileDescriptor";
import { observable, action } from "mobx";
import Column from "antd/es/table/Column";
import { UcoSignDocument } from "../../../cuba/entities/base/ucosign$Document";
import {collectionWithAfterLoad} from "../../util/DataCollectionStoreWithAfterLoad";
import moment from "moment";
import QRCode from "react-qr-code";

type EditorProps = {
  entityId: string;
};

@injectMainStore
@inject("rootStore")
@observer
class DocumentSignListComponent extends React.Component<
  MainStoreInjected &
    WrappedComponentProps &
    RootStoreProp &
    EditorProps &
    RouteComponentProps
> {
  @observable
  dataCollection = collection<UcoSignDocument>(UcoSignDocument.NAME, {
    filter: {
      conditions: [
        {
          property: "ucosignRegistration.id",
          operator: "=",
          value: this.props.entityId
        }
      ]
    },
    view: "ucoSignDocument-buildDdc-kzm"
  });
  @observable
  selectedRowKey: string;

  @observable
  ncLayerBtnDisabled: boolean = false;

  @observable
  ncLayerBtnLoading: boolean = false;

  @observable
  ncaLayer = new NcaLayer();

  @observable
  countNumber = 0;

  @observable
  countNumberParticipans = 0;

  @observable
  modalIsVisible = false;

  @observable
  imageData: any = null;

  @observable
  isDocumentSign = true;

  @observable
  objectsCount = {};

  @observable
  objectsParticipansCount = {};

  @observable
  controller: AbortController;

  @observable
  approversForDocumentList: [];

  @observable
  isLoadApprovers = false

  @observable
  firstUpdate = true;

  @observable
  signature: UcoSignSignature[];

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
    if (this.selectedRowKey) {
      this.isLoadApprovers = true;
      restServices.digitalSignatureSignService
        .getApproversForDocument({
          documentId: this.selectedRowKey
        })
        .then((value: any) => {
          value = JSON.parse(value);
          this.approversForDocumentList = value.approversForSignJsons;
        }).finally(()=>{
          this.isLoadApprovers = false;
        });
    } else {
      if(this.dataCollection.items)
        this.selectedRowKey = this.dataCollection.items[0].id
      this.approversForDocumentList = [];
      this.countNumberParticipans = 0;
      this.objectsParticipansCount = {};
      this.isLoadApprovers = false;
    }
  };

  @action
  setModalIsVisible = (bool: boolean) => {
    this.modalIsVisible = bool;
  };
  isCurrentDateAfterStringDate = () => {  
    if (this.dataCollection.items && this.dataCollection.items[0] && this.dataCollection.items[0].ucosignRegistration) {
      const currentDate = moment();
      const stringDate = moment(this.dataCollection.items[0].ucosignRegistration!.dateTo);
      return currentDate.isAfter(stringDate);
    }
    return true
  };
  handleClickNCLayer = () => {
    if (this.isCurrentDateAfterStringDate()) {
      Notification.error({message: this.props.intl.formatMessage({id: "documentSitn.error.dateTo"})})
      return
    }
    this.ncLayerBtnLoading = true;
    this.ncaLayer.connect();
    this.isDocumentSign = false;
    let base64String: string;
    getCubaREST()!
      .getFile(this.dataCollection.items[0].originalFile!.id)
      .then(value => {
        const reader = new FileReader();
        reader.onload = () => {
          base64String = reader.result as string;
          base64String = base64String.split(",")[1];
          if (this.ncaLayer.getConnectionState() === "OPEN") {
             restServices.digitalSignatureSignService.getAvailabilitySign(
              {
                userId: this.props.rootStore!.userInfo!.id!,
                registrationId: this.props.entityId
              }
            ).then(async (val) => {
              if(val){
            this.ncaLayer.createCMSSignatureFromBase64(
              "PKCS12",
              "SIGNATURE",
              base64String,
              false,
              async value => {
                if (value.code == 500) {
                  Notification.error({
                    message: this.props.intl.formatMessage({
                      id: `ucoSign_${value.message}`
                    })
                  });
                } else if (value.code == 200) {
                  const controller = new AbortController();
                  const signal = controller.signal;

                  await restServices.ucoSign
                    .postFetch(
                      {
                        usDocumentId: this.selectedRowKey,
                        signUserId: this.props.rootStore!.userInfo!.id!,
                        signatureData: value.responseObject,
                      },
                      "add-signature",
                      signal
                    )
                    .then(value => {
                      if (!value.errorMessage) {
                        Notification.success({
                          message: this.props.intl.formatMessage({
                            id: `ucoSign_success`
                          })
                        });
                        this.isDocumentSign = true;
                        goBackOrHomePage(this.props.history!);
                      }else{
                        let message = ""
                        try {
                          message = JSON.parse(value.error.cause.message).message
                        } catch (e) {
                          message = value.error.cause.message
                        }
                        Notification.error({message: message})
                      }
                    });
                }
                this.ncLayerBtnLoading = false;
              }
            );
              }
              else{
                this.ncLayerBtnLoading = false;
                Notification.error({message:
                  this.props.intl.formatMessage({
                    id: "documentSign.validate.order"
                  })
                })
              }
            })
          } else if (this.ncaLayer.getConnectionState() === "CLOSED") {
            setTimeout(() => {
              if (this.ncaLayer.getConnectionState() === "CONNECTING")
                this.handleClickNCLayer();
              else {
                this.ncLayerBtnLoading = false;
                return;
              }
            }, 200);
          }
        };
        reader.readAsDataURL(value);
      }).finally(  () =>  setTimeout(() => {this.ncLayerBtnLoading = false}, 300));
  };

  handleClickQr = async () => {
    if (this.isCurrentDateAfterStringDate()) {
      Notification.error({message: this.props.intl.formatMessage({id: "documentSitn.error.dateTo"})})
      return
    }
    await restServices.digitalSignatureSignService.getAvailabilitySign(
      {
        userId: this.props.rootStore!.userInfo!.id!,
        registrationId: this.props.entityId
      }
    ).then(async (value) => {
      if(value){
      try {
        let response = await restServices.ucoSign.getfetch(
          "description=Пакет документов на подписание",
          "get-qr-code"
        );
        this.imageData = {
          qrCode: parseUrlForUserCode(response.data),
          bin: response.data.eGovBusinessLaunchLink,
          iin: response.data.eGovMobileLaunchLink
        };
        this.modalIsVisible = true;
        Notification.info({
          message: this.props.intl.messages["documentSitn.info.qr"]
        });
        try {
          this.controller = new AbortController();
          const signal = this.controller.signal;
          await restServices.ucoSign
            .postFetch(
              {
                dataURL: response.data.dataURL,
                expiredAt: response.data.expireAt,
                registrationIds: [this.props.entityId]
              },
              "send-documents",
              signal
            )
            .then(() => {
              console.log(123)
              //todo Loader
            })

          await restServices.ucoSign
            .postFetch(
              {
                signURL: response.data.signURL,
                expiredAt: response.data.expireAt,
                signUserId: this.props.rootStore!.userInfo!.id!,
                registrationIds: [this.props.entityId]
              },
              "sign-documents",
              signal
            )
            .then(value => {
              if (!value.errorMessage) {
                restServices.reports.refreshBell({userId: this.props.rootStore!.userInfo.id || ''})
                this.isDocumentSign = true;
                Notification.success({
                  message: this.props.intl.formatMessage({
                    id: `ucoSign_success`
                  })
                });
                goBackOrHomePage(this.props.history!);
              }else{
                let message = ""
                try {
                  message = JSON.parse(value.error.cause.message).message
                } catch (e) {
                  message = value.error.cause.message
                }
                Notification.error({message: message})
              }
              this.modalIsVisible = false;
            })
        } catch (error) {
        }
      } catch (error) {
      }
    }
    else{
      Notification.error({message:
        this.props.intl.formatMessage({
          id: "documentSign.validate.order"
        })
      })
    }
  })
  };
  fieldSignature = ["id", "originalFile", "finalFile"];
  // @observable
  // ucoSignDocument: UcoSignSignature | null = null
  componentDidMount() {
    getCubaREST()!
      .searchEntities<UcoSignSignature>(
        UcoSignSignature.NAME,
        {
          conditions: [
            {
              property: "signUser.id",
              operator: "=",
              value: this.props.rootStore!.userInfo!.id!
            },
            {
              property: "ucosignDocument.ucosignRegistration.id",
              operator: "=",
              value: this.props.entityId
            },
            {
              property: "signDate",
              operator: "=",
              value: ""
            }
          ]
        },
        { view: "ucoSignSignature.edit", sort: "signOrder"}
      )
      .then(value => {
        this.signature = value as UcoSignSignature[];
        this.isDocumentSign =
          value.filter(val => val.signatureData == null).length == 0;
      });
    this.dataCollection.load();
    console.log(this.ncaLayer.getConnectionState());
  }
  componentDidUpdate(prevState: any) {
    if(this.firstUpdate && this.dataCollection.items[0]){
      let selectedRow = [];
      selectedRow.push(this.dataCollection.items[0].id)
      this.handleRowSelectionChange(selectedRow);
      this.firstUpdate = false;
    }
    if (this.props.entityId !== prevState.entityId) {
      getCubaREST()!
        .searchEntities<UcoSignSignature>(
          UcoSignSignature.NAME,
          {
            conditions: [
              {
                property: "signUser.id",
                operator: "=",
                value: this.props.rootStore!.userInfo!.id!
              },
              {
                property: "ucosignDocument.ucosignRegistration.id",
                operator: "=",
                value: this.props.entityId
              },
              {
                property: "signDate",
                operator: "=",
                value: ""
              }
            ]
          },
          { view: "ucoSignSignature.edit", sort: "signOrder" }
        )
        .then(value => {
          this.signature = value as UcoSignSignature[];
          this.isDocumentSign =
            value.filter(val => val.signatureData == null).length == 0;
        });
      this.dataCollection = collectionWithAfterLoad<UcoSignDocument>(UcoSignDocument.NAME, ()=>{
        this.objectsCount = {}
        this.countNumber = 0
        this.objectsParticipansCount = {}
        this.countNumberParticipans = 0;
        let selectedRow = [];
        selectedRow.push(this.dataCollection.items[0].id)
        this.handleRowSelectionChange(selectedRow);
      },{
        filter: {
          conditions: [
            {
              property: "ucosignRegistration.id",
              operator: "=",
              value: this.props.entityId
            }
          ]
        },
        view: "ucoSignDocument-buildDdc-kzm"
      });
      this.dataCollection.load();
      // this.handleRowSelectionChange([this.dataCollection.items[0].id]);
    }
  }
  changeNextCount(id: string): number {
    if (id in this.objectsCount) {
      return this.objectsCount[id];
    } else {
      this.objectsCount[id] = this.countNumber + 1;
      this.countNumber++;
      return this.objectsCount[id];
    }
  }
  changeNextCountParticipant(id: string): number {
    if (id in this.objectsParticipansCount) {
      return this.objectsParticipansCount[id];
    } else {
      this.objectsParticipansCount[id] = this.countNumberParticipans + 1;
      this.countNumberParticipans++;
      return this.objectsParticipansCount[id];
    }
  }
  render() {
    if (!this.signature) return <></>

    return (
      <Page pageName={this.props.intl.formatMessage({ id: "menu.advHr" })}>
        <Section size="large" visible={true}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Title level={3}>
                <FormattedMessage
                  id={"ucoSignDocuments_documents_title"}
                  defaultMessage={this.props.intl.formatMessage({
                    id: "ucoSignDocuments"
                  })}
                />
              </Typography.Title>

              <style>
                {`
           
            .documentSign_id{
              width: 10%
            }
            .documentSign_originalFile{
              width: 45%
            }
            .documentSign_finalFile{
              width: 45%
            }
          `}
              </style>
              <DataTable
                enableFiltersOnColumns={[]}
                hideSelectionColumn
                dataCollection={this.dataCollection}
                fields={this.fieldSignature}
                onRowSelectionChange={this.handleRowSelectionChange}
                tableProps={{
                  columns: [
                    {
                      className: 'documentSign_id',
                      title: this.props.intl.formatMessage({
                        id: "documentSign.number"
                      }),
                      align: 'center',
                      sorter: false,
                      render: (text, record) => {
                        if(this.firstUpdate)
                          this.componentDidUpdate(this.props)
                        return this.changeNextCount(record.id);
                      },
                    },
                    {
                      className: 'documentSign_originalFile',
                      key: "originalFile",
                      title: this.props.intl.formatMessage({
                        id: "documentSign.originalFile"
                      }),
                      align: 'center',
                      sorter: false,
                      render: (text, record) => {
                        const file = (record as UcoSignDocument).originalFile;
                        if (file)
                          return (
                            <a
                              onClick={() => {
                                downloadFile(
                                  (file as FileDescriptor).id,
                                  (file as FileDescriptor).name as string,
                                  "",
                                  this.props.intl.formatMessage({
                                    id: "file.download.error"
                                  })
                                );
                              }}
                            >
                              {(file as FileDescriptor).name}
                            </a>
                          );
                        return <span />;
                      },
                    },
                    {
                      className: 'documentSign_finalFile',
                      key: "finalFile",
                      title: this.props.intl.formatMessage({
                        id: "documentSign.finalFile"
                      }),
                      align: 'center',
                      sorter: false,
                      render: (text, record) => {
                        const file = (record as UcoSignDocument).finalFile;
                        if (file)
                          return (
                            <a
                              onClick={() => {
                                downloadFile(
                                  (file as FileDescriptor).id,
                                  (file as FileDescriptor).name as string,
                                  "",
                                  this.props.intl.formatMessage({
                                    id: "file.download.error"
                                  })
                                );
                              }}
                            >
                              {(file as FileDescriptor).name}
                            </a>
                          );
                        return <span />;
                      },
                    },
                  ],
                  pagination: false,
                }}
              ></DataTable>
            </Col>
            <Col span={12}>
              <Typography.Title level={3}>
                <FormattedMessage
                  id={"ucoSignDocuments_participant_title"}
                  defaultMessage={this.props.intl.formatMessage({
                    id: "ucoSignDocuments.participans"
                  })}
                />
              </Typography.Title>
              <Table
                loading={this.isLoadApprovers}
                pagination={false}
                dataSource={
                  this.approversForDocumentList
                    ? this.approversForDocumentList
                    : []
                }
                tableLayout={"fixed"}
              >
                <Column
                  width={80}
                  title={this.props.intl.formatMessage({
                    id: "documentSign.number"
                  })}
                  dataIndex={"id"}
                  key={"id"}
                  render={(text, record: any, index) =>
                    this.changeNextCountParticipant(record.id)
                  }
                />
                <Column
                  title={this.props.intl.formatMessage({
                    id: "role"
                  })}
                  dataIndex={"role"}
                  key={"role"}
                  render={(text, record: any, index) => record.role}
                />
                <Column
                  title={this.props.intl.formatMessage({
                    id: "FIO"
                  })}
                  dataIndex={"fullName"}
                  key={"fullName"}
                  render={(text, record: any, index) => record.fullName}
                />
                <Column
                  title={this.props.intl.formatMessage({
                    id: "status"
                  })}
                  dataIndex={"status"}
                  key={"status"}
                  render={(text, record: any, index) => record.status}
                />
                <Column
                  title={this.props.intl.formatMessage({
                    id: "documentsSign.signDate"
                  })}
                  dataIndex={"signDate"}
                  key={"signDate"}
                  render={(text, record: any, index) => record.signDate}
                />
              </Table>
            </Col>
          </Row>
          <div style={{ marginTop: 15 }}>
            <Button
              style={{ marginRight: 15 }}
              key="qrBtn"
              onClick={this.handleClickQr}
              loading={false}
              disabled={this.isDocumentSign}
            >
              {this.props.intl.formatMessage({ id: "documentSign.btn.QR" })}
            </Button>
            <Button
              style={{ marginRight: 15 }}
              key="nclayerBtn"
              loading={this.ncLayerBtnLoading}
              disabled={
                this.ncLayerBtnDisabled ||
                this.isDocumentSign ||
                !(this.dataCollection && this.selectedRowKey)
              }
              onClick={this.handleClickNCLayer}
            >
              {this.props.intl.formatMessage({
                id: "documentSign.btn.NCLayer"
              })}
            </Button>
            <Button
              key="cancel"
              onClick={() => {
                this.props.history.push("/activity/documentsSign");
              }}
            >
              {this.props.intl.formatMessage({
                id: "management.browser.exclude.cancel"
              })}
            </Button>
          </div>
          <Modal
            centered
            visible={this.modalIsVisible}
            onCancel={() => {
              this.setModalIsVisible(false);
            }}
            footer={[
              <Button
                key="cancel"
                onClick={() => {
                  this.setModalIsVisible(false);
                  this.controller.abort();
                }}
              >
                {this.props.intl.formatMessage({
                  id: "management.browser.exclude.cancel"
                })}
              </Button>
            ]}
          >
            <div>
              <Typography.Text style={{display: "block", marginBottom: '15px'}}>
                <span> {this.props.intl.formatMessage({id: "documentSitn.infoText.qr1"})}</span>
                <span
                  style={{color: '#1ac404'}}> {this.props.intl.formatMessage({id: "documentSitn.infoText.qr2"})}</span>
              </Typography.Text>
              <div style={{height: "auto", margin: "0 auto", maxWidth: 256, width: "100%"}}>
                <div style={{height: "auto", margin: "0 auto", maxWidth: 256, width: "100%"}}>
                  {this.signature[0] && this.signature[0].userSignType && this.imageData &&
                    <QRCode
                      size={256}
                      style={{height: "auto", maxWidth: "100%", width: "100%"}}
                      value={`mobileSign:https://sigex.kz/api/egovQr/egov/${this.imageData.qrCode}`}
                      viewBox={`0 0 256 256`}

                    />
                  }
                </div>
              </div>
              {/*<img*/}
              {/*  width={250}*/}
              {/*  height={250}*/}
              {/*  src={`data:image/png;base64, ${this.imageData}`}*/}
              {/*  alt="Изображение"*/}
              {/*/>*/}
            </div>
          </Modal>
        </Section>
      </Page>
    );
  }
}

const DocumentSignList = injectIntl(withRouter(DocumentSignListComponent));

export default DocumentSignList;
