import React, { Component } from 'react'
import { collection, injectMainStore, MainStoreInjected, Msg } from '@cuba-platform/react';
import { AdaptationTask } from '../../../../cuba/entities/base/tsadv_AdaptationTask';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../store';
import { Table, Typography } from 'antd';
import { observable } from 'mobx';

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp & {
    personGroupId: string;
};

@injectMainStore
@inject("rootStore")
@observer
class AllAdaptationTasksTableComponent extends Component<Props> {
  dataCollection = collection<AdaptationTask>(AdaptationTask.NAME, {
    view: "adaptationTask",
    sort: "-updateTs",
    loadImmediately: true,
    filter: {
      conditions: [
        {
          property: "adaptationPlan.personGroup",
          operator: "=",
          value: this.props.personGroupId,
        },
        {
          property: "adaptationPlan.needless",
          operator: "=",
          value: "false",
        }
        // {
        //   property: "adaptationPlan.adaptationStatus",
        //   operator: "doesNotContain",
        //   value: "FULL_APPROVED"
        // }
      ]
    }
  })

  @observable
  isLocaleRu = this.props.rootStore!.userInfo.locale === 'ru';

  @observable
  isLocaleKz = this.props.rootStore!.userInfo.locale === 'kz';

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  renderTaskField = (
    fieldName: string, 
    ruSuffix:string = 'Lang1', 
    kzSuffix:string = 'Lang2', 
    enSuffix:string = 'Lang3'
  ) => (text: string, record: AdaptationTask) => {
    const value = text 
    || (this.isLocaleRu && record[fieldName + ruSuffix])
    || (this.isLocaleKz && record[fieldName + kzSuffix])
    || record[fieldName+enSuffix] || record[fieldName+ruSuffix] || record[fieldName+kzSuffix];
    
    return (
      <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
        {value}
      </Typography.Text>
    )
  }

  render() {
    return (
      <div>
        <Table
          dataSource={this.dataCollection.items}
          style={{ tableLayout: 'fixed' }}
          rowKey={'id'}
          columns={[
            {
              key: 'assignment',
              title: <Msg entityName={AdaptationTask.NAME} propertyName={"assignment"} mainStore={this.props.mainStore} />,
              dataIndex: 'assignment',
              render: this.renderTaskField('assignment')
            }, {
              key: 'expectedResults',
              title: <Msg entityName={AdaptationTask.NAME} propertyName={"expectedResults"} mainStore={this.props.mainStore} />,
              dataIndex: 'expectedResults',
              render: this.renderTaskField('expectedResults')
            }, {
              key: 'achievedResults',
              title: <Msg entityName={AdaptationTask.NAME} propertyName={"achievedResults"} mainStore={this.props.mainStore} />,
              dataIndex: 'achievedResults',
              render: this.renderTaskField('achievedResults')
            },
          ]}
        />
      </div>
    )
  }
}

const AdaptationTasksTable = injectIntl(AllAdaptationTasksTableComponent)

export default AdaptationTasksTable
