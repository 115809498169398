import React from 'react'
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import { injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import { RootStoreProp } from "../../../../store";
import { Button, Table } from "antd";
import { ColumnProps } from "antd/es/table";
import { formatDate } from "../../../../util/Date/Date";
import { PersonMedalRequest } from "../../../../../cuba/entities/base/tsadv_PersonMedalRequest";
import { IReactionDisposer, observable, reaction } from "mobx";
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import PersonMedalRequestManagement from '../../PersonMedal/PersonMedalRequestManagement';
import { restServices } from '../../../../../cuba/services';
import { SerializedEntity } from '@cuba-platform/rest';
import { PersonGroupExt } from '../../../../../cuba/entities/base/base$PersonGroupExt';
import moment from "moment";

type Props = {
  data?: PersonMedalRequest[],
  personGroupId: string
} & WrappedComponentProps & MainStoreInjected & RootStoreProp

type TableData = {
  id: string
  order: number
  date: number
  employeeName: string
  category: string,
  status: string
  link: string
}

@observer
class Nominations extends React.Component<Props> {
  dataWatcher: IReactionDisposer
  messages = this.props.intl.messages
  @observable data?: TableData[] = []
  @observable selectedRow: TableData | undefined;

  constructor(props: Props) {
    super(props);
    restServices.recognitionPortalService.loadUserNominations(this.props.personGroupId).then(value => {
      this.data = this.mapData(value).sort((a, b) => {
        if (a.order > b.order) {
          return -1;
        }
        if (a.order < b.order) {
          return 1;
        }
        return 0;
      })
    })
  }

  mapData = (data: PersonMedalRequest[]): TableData[] => {
    return data.map(value => ({
      id: value.id,
      order: value.requestNumber,
      date: value.requestDate,
      employeeName: value.personGroup ? value.personGroup.map(it => (it as SerializedEntity<PersonGroupExt>)._instanceName).join(', ') : '',
      category: value.awardType ? value.awardType.langValue : '',
      status: value.status ? value.status.langValue : '',
      link: PersonMedalRequestManagement.PATH + "/" + value.id,
    } as TableData)
    )
  }

  columns = [
    {
      key: 'order',
      dataIndex: 'order',
      title: this.messages['medalTab.tabs.nominations.table.order'],
      sorter: (a:any, b:any) => a.order - b.order,
      render: (text: any, record: TableData) => {
        const link = record.link
        return (
          <Link
            to={
              link
            }
            key="edit">
            <a
              key={"editPersonMedalRequest"}>
              {record.order}
            </a>
          </Link>
        )
      }
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: this.messages['medalTab.tabs.nominations.table.date'],
      sorter: (a, b) => {
        const aDate = moment(a.date!)
        const bDate = moment(b.date!)
        return bDate.diff(aDate)
      },
      render: (text, record) => {
        const date = record.date
        return (
          <label>
            {formatDate(date, 'DD.MM.YYYY')}
          </label>
        )
      }

    } as ColumnProps<TableData>,
    {
      key: 'employeeName',
      dataIndex: 'employeeName',
      title: this.messages['medalTab.tabs.nominations.table.employeeName'],
      sorter:(a:any,b:any)=>{
        return  a.employeeName && a.employeeName!.localeCompare(b.employeeName!)
      }
    },
    {
      key: 'category',
      dataIndex: 'category',
      title: this.messages['medalTab.tabs.nominations.table.category'],
      sorter:(a:any,b:any)=>{
        return  a.category && a.category!.localeCompare(b.category!)
      }
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: this.messages['medalTab.tabs.nominations.table.status'],
      filters: [
        {
          text: <FormattedMessage id={"status.Draft"}/>,
          value: this.props.intl.formatMessage({id: "status.Draft"})
        },
        {
          text: <FormattedMessage id={"status.OnApproval"}/>,
          value: this.props.intl.formatMessage({id: "status.OnApproval"})
        },
        {
          text: <FormattedMessage id={"status.Approved"}/>,
          value: this.props.intl.formatMessage({id: "status.Approved"})
        },
        {
          text: <FormattedMessage id={"status.ToBeRevised"}/>,
          value: this.props.intl.formatMessage({id: "status.ToBeRevised"})
        },
        {
          text: <FormattedMessage id={"status.Rejected"}/>,
          value: this.props.intl.formatMessage({id: "status.Rejected"})
        }],

        onFilter: (value:any, record:any) => record.status!.startsWith(value)
    },
  ]

  onRowSelect = (record: TableData) => {
    this.selectedRow = record;
  }


  render() {
    return (
      <div style={{ margin: '2%' }}>
        <div className={'nominations-layout'}>
          <Link
            to={
              PersonMedalRequestManagement.PATH + "/" + PersonMedalRequestManagement.NEW_SUBPATH
            }
            key="create">
            <Button type={'primary'} style={{ width: '20%' }}>
              {this.messages['medalTab.tabs.nominations.nominate']}
            </Button>
          </Link>
          <Table dataSource={this.data} columns={this.columns} rowKey={record => record.id} onRowClick={this.onRowSelect}>

          </Table>
        </div>
      </div>
    )
  }

}

export default injectIntl(Nominations)