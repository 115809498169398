import React from "react";
import {Button, Row} from "antd";
import {observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import {FormattedMessage} from "react-intl";

type CountPickerProps = {
  min?: number;
  max?: number;
  defaultValue?: number;
  onChange?: (value: number) => void;
}

@observer
class CountPicker extends React.Component<CountPickerProps> {

  @observable value: number = this.props.defaultValue || 0;

  @action add = () => {
    if (this.props.max !== undefined && this.value >= this.props.max) {
      return;
    }

    this.value++;
    this.props.onChange && this.props.onChange(this.value);
  }

  @action subtract = () => {
    if (this.props.min !== undefined && this.value <= this.props.min) {
      return;
    }

    this.value--;
    this.props.onChange && this.props.onChange(this.value);
  }

  @computed get isMax() {
    return (this.props.max !== undefined && this.value >= this.props.max);
  }

  @computed get isMin() {
    return (this.props.min !== undefined && this.value <= this.props.min);
  }

  render() {
    return <Row type={"flex"} justify={"center"} style={{paddingBottom: "30px", alignItems: "baseline"}}>
      <Button shape="circle" size={"small"} onClick={this.subtract} disabled={this.isMin}>-</Button>
      <p style={{padding: "0 10px"}}>
        {this.value}
        <FormattedMessage id={"recognition.countPicker.postfix"}/>
      </p>
      <Button shape="circle" size={"small"} onClick={this.add} disabled={this.isMax}>+</Button>
    </Row>
  }
}

export default CountPicker;