import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import SurChargeRequestEdit from "./SurChargeRequestEdit";
import SurChargeRequestList from "./SurChargeRequestList";
import Page from "../../hoc/PageContentHoc";
import { FormattedMessage } from "react-intl";
import { Card } from "antd";
import { SurChargeRequestManagement } from "./SurChargeRequestManagement";
import Section from "../../hoc/Section";

interface Props{ 
    entityId?: string;
    personGroupId: string;
    isUsedMyteam?:boolean
}

@observer
export class MyTeamSurChargeRequestManagement extends React.Component<Props> {
  static PATH = SurChargeRequestManagement.PATH;
  static NEW_SUBPATH = SurChargeRequestManagement.NEW_SUBPATH;
  
  state = {
    entityId: ""
  }

  openCreateTab=()=>{
    this.setState({ entityId: SurChargeRequestManagement.NEW_SUBPATH })
  }
  openEditTab=(entityId: string)=>{
    this.setState({ entityId })
  }
  closeCreateTab=()=>{
    this.setState({ entityId: "" })
  }

  render() {
    const { entityId } = this.state;
    return entityId ? (
      <SurChargeRequestEdit
        personGroupId={this.props.personGroupId}
        closeCreateTab={this.closeCreateTab}
        entityId={entityId}
      />
    ) : (
      <SurChargeRequestList
          openCreateTab={this.openCreateTab}
          openEditTab={this.openEditTab}
          personGroupId={this.props.personGroupId}
        />
    );
  }
}
