import { AbstractParentEntity } from "./AbstractParentEntity";
import { Assessment } from "./tsadv$Assessment";
import { DicAssessmentStatus } from "./tsadv$DicAssessmentStatus";
import { PersonAssessment } from "./tsadv_PersonAssessment";
import { CompetenceTemplateParticipant } from "./tsadv_CompetenceTemplateParticipant";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { AssessmentCompetence } from "./tsadv$AssessmentCompetence";
import { AssessmentGoal } from "./tsadv$AssessmentGoal";
export class AssessmentParticipant extends AbstractParentEntity {
  static NAME = "tsadv$AssessmentParticipant";
  assessments?: Assessment[] | null;
  assessmentResult?: any | null;
  assessmentStatus?: DicAssessmentStatus | null;
  personAssessment?: PersonAssessment | null;
  participantType?: CompetenceTemplateParticipant | null;
  participantPersonGroup?: PersonGroupExt | null;
  assessmentCompetence?: AssessmentCompetence[] | null;
  assessmentGoal?: AssessmentGoal[] | null;
}
export type AssessmentParticipantViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "assessmentParticipant-for-apab"
  | "assessmentParticipant-for-apab-tab"
  | "assessmentParticipant-for-asd"
  | "assessmentParticipant-for-ate"
  | "assessmentParticipant.browse"
  | "assessmentParticipant.edit"
  | "assessmentParticipant.full"
  | "assessmentParticipantNew-edit";
export type AssessmentParticipantView<
  V extends AssessmentParticipantViewName
> = V extends "_base"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "assessmentParticipant-for-apab"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "personAssessment"
      | "participantPersonGroup"
    >
  : V extends "assessmentParticipant-for-apab-tab"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "assessmentStatus"
      | "personAssessment"
      | "participantPersonGroup"
      | "assessmentCompetence"
      | "participantType"
    >
  : V extends "assessmentParticipant-for-asd"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "updateTs"
      | "assessmentStatus"
      | "personAssessment"
      | "participantType"
      | "participantPersonGroup"
    >
  : V extends "assessmentParticipant-for-ate"
  ? Pick<AssessmentParticipant, "id" | "assessmentStatus" | "personAssessment">
  : V extends "assessmentParticipant.browse"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "assessmentStatus"
      | "participantPersonGroup"
      | "participantType"
      | "assessmentCompetence"
    >
  : V extends "assessmentParticipant.edit"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "assessments"
      | "assessmentStatus"
      | "participantPersonGroup"
      | "personAssessment"
    >
  : V extends "assessmentParticipant.full"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "assessments"
      | "assessmentStatus"
      | "participantPersonGroup"
    >
  : V extends "assessmentParticipantNew-edit"
  ? Pick<
      AssessmentParticipant,
      | "id"
      | "assessmentResult"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "participantType"
      | "participantPersonGroup"
      | "personAssessment"
    >
  : never;
