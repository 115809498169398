import React from 'react';
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BudgetHierarchyComponent from './BudgetHierarchyComponent';
import { RootStoreProp } from '../../store';
import { MainStoreInjected } from "@cuba-platform/react";

export type LearningBudgetProps = {
  budgetRequestId?: string;
};

@inject("rootStore")
@observer
class LearningBudget extends React.Component<LearningBudgetProps & MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  render() {
    return (
      <BudgetHierarchyComponent
        selectedTab={() => this.props.rootStore!.learningBudgetInfo.selectedTab}
        selectedLeftMenu={() => this.props.rootStore!.learningBudgetInfo.selectedMenu}
        selectedData={this.props.rootStore!.learningBudgetInfo.selectedHierarchyData}
        onChangeSelectedInfo={this.props.rootStore!.learningBudgetInfo.setBudgetInfo}
        positionGroupId={this.props.rootStore!.userInfo!.positionGroupId!}
        personGroupId={this.props.rootStore!.userInfo!.personGroupId!}
        hierarchyId='337c7844-6a2c-77b7-0b24-c22273623bd5'
        selectedBudget={undefined}
      />
    );
  }
}

export default injectIntl(LearningBudget);