import React from 'react';
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { RootStoreProp } from '../../store';
import { MainStoreInjected } from "@cuba-platform/react";
import { restServices } from '../../../cuba/services';
import Button from "../../components/Button/Button";

export type BudgetDetailProps = {
    budgetId: string;
};

@inject("rootStore")
@observer
class ExportBudgetDetailReport extends React.Component<BudgetDetailProps & MainStoreInjected & WrappedComponentProps & RootStoreProp> {


    exportReport = () => {
        restServices.learningBudgetService.createBudgetDetailReport({ learningBudgetId: this.props.budgetId })
            .then(response => {
                const binaryString = window.atob(response); // Comment this if not using base64
                const bytes = new Uint8Array(binaryString.length);
                const body = bytes.map((byte, i) => binaryString.charCodeAt(i));
                const blob = new Blob([body]);
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', 'Budget_detail_report.xlsx');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
    }

    render() {
        return (
            <Button
                className={"export-detail-btn"}
                onClick={() => this.exportReport()}
                key="modal">
                {this.props.intl.formatMessage({ id: "exportBudgetDetail" })}
            </Button>
        );
    }
}

export default injectIntl(ExportBudgetDetailReport);