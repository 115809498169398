import * as React from "react";
import {observer} from "mobx-react";
import RecognitionStructure from "./RecognitionStructure";

@observer
export class RecognitionManagement extends React.Component {
  static PATH = "/employee-recognition";

  render() {
    return (
      <RecognitionStructure/>
    );
  }

}
