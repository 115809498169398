import { LearningBudgetRequestCost } from "../../../cuba/entities/base/tsadv_LearningBudgetRequestCost";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { StandardEntity } from "../../../cuba/entities/base/sys$StandardEntity";
import { SerializedEntity } from "@cuba-platform/rest";

const formatAmount = (value: string): string => {
  const split = value.split('.')
  return value ? split[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (split[1] ? '.' + split[1].substr(0, 2) : '') : value;
}

const parseStringToFloat = (value: string): number => {
  let res = (value + '').replace(/ /g, '');
  return parseFloat(res);
}

export type FillDataProps = {
  trainingCostString: string
  travelCostString: string
  residenceString: string
  dailyAllowanceString: string
  dayCount: number
  employeeCount: number
}

const fillData = ({
  trainingCostString,
  travelCostString,
  residenceString,
  dailyAllowanceString,
  dayCount,
  employeeCount
}: FillDataProps) => {
  const trainingCostValue = parseStringToFloat(trainingCostString);
  const travelValue = parseStringToFloat(travelCostString);
  const residenceValue = parseStringToFloat(residenceString);
  const dailyAllowanceValue = parseStringToFloat(dailyAllowanceString);
  const trainingCost = isNaN(trainingCostValue) ? 0 : trainingCostValue;
  const travel = isNaN(travelValue) ? 0 : travelValue;
  const residence = isNaN(residenceValue) ? 0 : residenceValue;
  const dailyAllowance = isNaN(dailyAllowanceValue) ? 0 : dailyAllowanceValue;
  const residenceWhole = residence * employeeCount;
  const dailyAllowanceWhole = dailyAllowance * employeeCount;
  return {
    trainingCost: trainingCost,
    travel: travel,
    residence: residence,
    dailyAllowance: dailyAllowance,
    residenceWhole: residenceWhole,
    dailyAllowanceWhole: dailyAllowanceWhole
  }
}

const updateCost = (obj: LearningBudgetRequestCost, form: WrappedFormUtils) => {
  obj['trainingCost'] = parseStringToFloat(form.getFieldValue('trainingCost'))
  obj['travel'] = parseStringToFloat(form.getFieldValue('travel'))
}


function wrapToSerialized<T extends StandardEntity>(entity?: T | null): SerializedEntity<T> {
  return entity as SerializedEntity<T>
}

const getValidGreaterZero = (msg: string) => [
  {
    transform: (value: string) => parseInt(value),
    min: 1,
    type: 'integer',
    message: msg
  }
]

const getFieldValue = (prop: string[], obj: any): any => {
  if (obj) {
    const curr = obj[prop[0]]
    if (prop.length === 1)
      return curr
    else
      return getFieldValue(prop.slice(1, prop.length), curr)
  } else {
    return ''
  }
}

export {
  formatAmount,
  parseStringToFloat,
  fillData,
  updateCost,
  wrapToSerialized,
  getValidGreaterZero,
  getFieldValue
}