import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Icon, Row} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import CoinsComponent from "./CoinsComponent";
import {Link} from "react-router-dom";
import {cartStore} from "../store/CartStore";

type MenuItems = {
  name: string;
  path: string;
}

type RecognitionTopBarProps = {
  href: string;
}

@inject("rootStore")
@injectMainStore
@observer
class RecognitionTopBar extends React.Component<RootStoreProp & WrappedComponentProps & RecognitionTopBarProps> {

  menuItems: MenuItems[] = [
    {name: this.props.intl.messages["recognition.topBar.menus.store"] as string, path: "items"},
    {name: this.props.intl.messages["recognition.topBar.menus.orders"] as string, path: "orders"},
    {name: this.props.intl.messages["recognition.topBar.menus.cart"] as string, path: "cart"}
  ]

  renderMenuItem = (menuItem: MenuItems) => {
    const fullPath =  "/recognition/store/" + menuItem.path;

    const classes = ["store-menu-wrapper"]
    if (this.props.href.endsWith(menuItem.path)) {
      classes.push("store-menu-wrapper-selected")
    }

    const className = classes.join(" ");

    return <Link to={fullPath} key={menuItem.name} className={className}>
      <h2 className={"store-menu"}>{menuItem.name}</h2>
    </Link>
  }

  componentDidMount() {
    cartStore.loadBalance();
    cartStore.loadGoodsCartCount();
  }

  render() {
    return <>
      <Row type={"flex"} justify={"space-between"} style={{alignItems: "center", height: "50px"}}>
        <Row type={"flex"}>
          {this.menuItems.map(this.renderMenuItem)}
        </Row>
        <Row type={"flex"} style={{alignItems: "center"}}>
          <Row type={"flex"} style={{alignItems: "center", marginRight: "10px"}}>
            <span style={{paddingRight: "10px"}}>
              <FormattedMessage id={"recognition.topBar.balance"}/>
            </span>
            <CoinsComponent value={cartStore.balance}/>
          </Row>
          <div style={{marginRight: "10px"}}>
            <FormattedMessage id={"recognition.topBar.items"}/>
             <strong>{cartStore.countItemsInCart}</strong> <Icon type="shopping-cart"/>
          </div>
        </Row>
      </Row>
      <hr style={{margin: 0, marginBottom: "30px"}}/>
    </>
  }
}

export default injectIntl(RecognitionTopBar);