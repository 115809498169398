import * as React from "react";
import {inject, observer} from "mobx-react";

import {observable, reaction} from "mobx";

import {collection, DataCollectionStore, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Icon, Tree} from "antd";
import {AntTreeNode, AntTreeNodeExpandedEvent} from "antd/lib/tree/Tree";
import {restServices} from "../../../cuba/services";
import LoadingPage from "../LoadingPage";
import {RootStoreProp} from "../../store";
import LearningBudgetCard from "./LearningBudgetCard";
import {SplitPane} from "react-multi-split-pane";
import {withRouter} from "react-router";
import {RouteComponentProps} from "react-router-dom";
import {HierarchyElementExt} from "../../../cuba/entities/base/base$HierarchyElementExt";
import {LearningBudget} from "../../../cuba/entities/base/tsadv_LearningBudget";
import DefaultDropdown, {MenuRaw} from "../../components/Dropdown/DefaultDropdown";
import {SerializedEntity} from "@cuba-platform/rest";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {OrganizationGroupExt} from "../../../cuba/entities/base/base$OrganizationGroupExt";
import Notification from "../../util/Notification/Notification";
import {BudgetForecast} from "../../../cuba/entities/base/tsadv_BudgetForecast";
import {BudgetHeaderStatus} from "../../../cuba/enums/enums";
import moment from "moment";
import {LearningBudgetRequestHeader} from "../../../cuba/entities/base/tsadv_LearningBudgetRequestHeader";
import './hierarchy.css'
import {wrapToSerialized} from "./utils";

const { TreeNode } = Tree;

export type HierarchyData = {
  id: string,
  parentId?: string,
  personGroup: SerializedEntity<PersonGroupExt>,
  positionGroupId: string,
  fullName?: string,
  organizationGroup: OrganizationGroupExt,
  parentOrganization: OrganizationGroupExt,
  hasChild?: boolean,
  children?: HierarchyData[],
};

export type BudgetStructureProps =
  MainStoreInjected
  & RootStoreProp
  & WrappedComponentProps
  & RouteComponentProps<any>
  & {
    searchVisible?: boolean,
    personCard?: (personGroupId: string) => React.ReactElement;
    positionGroupId: string,
    personGroupId: string,
    onChangeSelectedInfo?: (selectedData?: HierarchyData, selectedBudget?: SerializedEntity<LearningBudget>, selectedTab?: string, selectedLeftMenu?: string) => void;
    selectedData?: HierarchyData;
    selectedTab?: () => string | undefined;
    selectedLeftMenu?: () => string | undefined;
    selectedBudget: SerializedEntity<LearningBudget> | undefined;
    hierarchyId: string;
  };


@injectMainStore
@inject("rootStore")
@observer
class BudgetHierarchyComponent extends React.Component<BudgetStructureProps> {

  @observable hierarchyElementData: HierarchyData[] = [];
  @observable isSearch = false;
  @observable expandedKeys: string[] = [];
  @observable mainSplitPaneDefaultSizes?: number[];
  @observable selectedData?: HierarchyData = this.props.selectedData;
  @observable selectedBudget: SerializedEntity<LearningBudget> | undefined = this.props.selectedBudget;
  @observable isTreeLoading = false;
  @observable isApprover: boolean;
  @observable learningBudgetRequestHeader: LearningBudgetRequestHeader;
  @observable hierarchyHeaders = new Map<string, LearningBudgetRequestHeader>()
  @observable isLoading: boolean = false;
  learningBudgetRequestHeaders: LearningBudgetRequestHeader[] = [];


  budgetDataCollection = collection<LearningBudget>(LearningBudget.NAME, {
    view: "learningBudget-front-view",
    sort: "-updateTs",
    loadImmediately: false
  });

  @observable budgetMenu: MenuRaw[] = []

  constructor(props: BudgetStructureProps) {
    super(props);

    if (this.props.rootStore!.learningBudgetInfo.expandedKeys.length) {
      this.expandedKeys = this.props.rootStore!.learningBudgetInfo.expandedKeys;
    }
    if (this.props.rootStore!.learningBudgetInfo.selectedHierarchyData)
      this.selectedData = this.props.rootStore!.learningBudgetInfo.selectedHierarchyData
    if (this.props.rootStore!.learningBudgetInfo.selectedBudget)
      this.selectedBudget = this.props.rootStore!.learningBudgetInfo.selectedBudget

    addCollectionLoadedCallback(this.budgetDataCollection, async items => {
      if (items.length > 0) {
        this.budgetMenu.push(...items.map(entry => ({
          id: entry.id,
          value: entry._instanceName
        })))
        if (!this.selectedBudget){
          this.selectedBudget = items[0]
        }
        this.learningBudgetRequestHeaders = await restServices.hierarchyService.getHeadersByBudget({
          personGroupId: this.props.personGroupId,
          budgetId: this.selectedBudget!.id
        })
        this.learningBudgetRequestHeaders.forEach(item => {
          this.hierarchyHeaders.set(item.organizationGroup!.id + this.selectedBudget!.id, item);
        })
        this.learningBudgetRequestHeader = this.hierarchyHeaders.get(this.selectedData!.organizationGroup.id + this.selectedBudget!.id)!
        this.props.rootStore!.learningBudgetInfo.setLearningBudgetRequestHeader(this.learningBudgetRequestHeader)
        this.props.rootStore!.learningBudgetInfo.setBudget(this.selectedBudget)
        await this.setIsForecast()
      } else {
        Notification.error({
          message: this.props.intl.formatMessage({ id: "noBudgets" })
        });
      }
    })
  }

  setIsForecast = async () => {
    const forecasts = await getCubaREST()!.searchEntities<BudgetForecast>(BudgetForecast.NAME, {
      conditions: [{
        property: 'budget.id',
        operator: '=',
        value: this.selectedBudget!.id
      }, {
        property: 'startDate',
        operator: '<=',
        value: moment().format('YYYY-MM-DD'),
      }, {
        property: 'endDate',
        operator: '>=',
        value: moment().format('YYYY-MM-DD'),

      }]
    }, {
      view: '_local'
    })
    this.props.rootStore!.learningBudgetInfo.setIsForecast(forecasts.length > 0 && this.selectedBudget!.status!.code === 'APPROVED');
  }

  onApprovingBudgetControlClick = async (status: BudgetHeaderStatus) => {
    const serialized = wrapToSerialized(this.learningBudgetRequestHeader)
    try {
      serialized.status = status
      const persisted = await getCubaREST()!.commitEntity<LearningBudgetRequestHeader>(LearningBudgetRequestHeader.NAME, this.learningBudgetRequestHeader)
      const res = await getCubaREST()!.loadEntity(LearningBudgetRequestHeader.NAME, persisted.id, {view: 'learning-budget-request-header-front'})
      this.hierarchyHeaders.set((res as LearningBudgetRequestHeader).organizationGroup!.id + this.selectedBudget!.id, res as LearningBudgetRequestHeader);
    } catch (err) {
      console.log(err)
      Notification.error({
        message: this.props.intl.formatMessage({ id: "management.editor.error" })
      });
    }
  };

  onApproveBudgetForecastClick = async () => {
    this.learningBudgetRequestHeader.isForecastEditable = false
  }

  onLoadData = (treeNode: AntTreeNode): PromiseLike<void> => {
    return new Promise(resolve => {
      const key = treeNode.props.eventKey;
      if (treeNode.props.children || !key) {
        resolve();
        return;
      }
      const id = key.substring(0, key.indexOf("/"));
      return restServices.hierarchyService.getOrganizationChildHierarchyElement({
        parentId: id,
        personGroupId: null
      })
        .then(value => value.map(this.parseToHierarchyData))
        .then(value => {
          treeNode.props.dataRef.children = [...value]
          this.hierarchyElementData = [...this.hierarchyElementData]
          resolve();
        })

    });
  }

  renderTreeNodes = (data: HierarchyData[]): any =>
    data.map(item => {
      const header = this.hierarchyHeaders.get(item.organizationGroup.id + this.selectedBudget!.id)

      return (
        <TreeNode
          title={item.fullName}
          icon={header!.status === 'APPROVED' ? <Icon type="lock" style={{ color: "red" }} /> :
            <Icon type="unlock" style={{ color: "green" }} />}
          key={this.getKey(item)}
          dataRef={item}
          isLeaf={!item.hasChild}>
          {item.children ? this.renderTreeNodes(item.children) : null}
        </TreeNode>)
    }
    )

  initDefaultExpandedKeys = (data: HierarchyData): string[] => {
    if (!data) return [];
    const keys = [data.id + "/" + data.parentId];
    if (data.children) {
      const childKeys = data.children
        .map(this.initDefaultExpandedKeys)
        .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], []);
      return [...keys, ...childKeys];
    }
    return keys;
  }

  getDefaultExpandedKeys = (): string[] => {
    if (!this.isSearch || !this.hierarchyElementData) return [];
    return this.hierarchyElementData
      .map(this.initDefaultExpandedKeys)
      .reduce((previousValue, currentValue) => [...previousValue, ...currentValue], []);
  }


  onExpand = (expandedKeys: string[], info: AntTreeNodeExpandedEvent) => {
    if (info.expanded) {
      this.expandedKeys = [...this.expandedKeys, info.node.props.eventKey!];
    } else {
      this.expandedKeys.splice(this.expandedKeys.indexOf(info.node.props.eventKey!), 1);
    }
    this.expandedKeys = [...this.expandedKeys];
    this.props.rootStore!.learningBudgetInfo.setExpandedKeys(this.expandedKeys);
  }

  onSelect = async (keys: string[]): Promise<void> => {
    if (keys.length) {
      const data = this.getRecordByKey(keys[0])
      this.selectedData = data
      if (data) {
        const selectedOrg = wrapToSerialized(data.organizationGroup)
        const selectedHeader = this.hierarchyHeaders.get(data.organizationGroup.id + this.selectedBudget!.id)!
        this.props.rootStore!.learningBudgetInfo.setOrganization(selectedOrg)
        this.props.rootStore!.learningBudgetInfo.setLearningBudgetRequestHeader(selectedHeader);
        this.learningBudgetRequestHeader = selectedHeader;
        if (selectedHeader.organizationManager && this.selectedData!.personGroup && selectedHeader.organizationManager!.id !== this.selectedData!.personGroup!.id) {
          selectedHeader.organizationManager = data.personGroup;
          try {
            const persisted = await getCubaREST()!.commitEntity<LearningBudgetRequestHeader>(LearningBudgetRequestHeader.NAME, selectedHeader)
            const res = await getCubaREST()!.loadEntity(LearningBudgetRequestHeader.NAME, persisted.id, {view: 'learning-budget-request-header-front'})
            this.hierarchyHeaders.set(data.organizationGroup.id + this.selectedBudget!.id, res as LearningBudgetRequestHeader)
          } catch (err) {
            console.log(err)
            Notification.error({
              message: this.props.intl.formatMessage({ id: "management.editor.error" })
            })
          }
        }
        this.isApprover = !!this.expandedKeys.length && !this.expandedKeys[0].includes(data.id)
        if (typeof this.props.onChangeSelectedInfo === 'function') {
          this.props.onChangeSelectedInfo(this.selectedData, this.selectedBudget);
        }
      }

    }
  }



  render() {
    const loading = this.props.intl.messages['page.loading']

    if (this.budgetDataCollection.status === "LOADING" || this.isTreeLoading || !this.learningBudgetRequestHeader
      || this.isLoading)
      return loading;

    if (!this.hierarchyElementData)
      return <LoadingPage />
    if (this.hierarchyElementData.length === 0)
      return this.props.intl.formatMessage({ id: "noBudgetData" })

    return (
      <div style={{ height: "100%", position: 'relative' }}>
        <div style={{
          padding: "5px 10px",
        }}>
          <label style={{
            display: "inline-block",
            marginRight: "5px"
          }}>{this.props.intl.formatMessage({ id: "budget" })}</label>
          <div style={{
            width: "350px",
            display: "inline-block",
          }}>
            <DefaultDropdown menu={this.budgetMenu}
              selected={this.selectedBudget!._instanceName!}
              handleMenuClick={(value) => this.updateBudget(this.budgetDataCollection.items.find(val => val.id === value))} />
          </div>

        </div>
        <SplitPane key={'mainSplitPane'}
          split="vertical"
          defaultSizes={this.mainSplitPaneDefaultSizes}
          onDragFinished={sizes => this.saveDefaultSettings(sizes)}>
          <div style={{
            height: '100%',
            overflowX: "auto",
            width: '100%',
          }}>

            <Tree
              className={"budget-tree"}
              expandedKeys={[...this.expandedKeys]}
              defaultSelectedKeys={this.selectedData ? [this.getKey(this.selectedData)] : []}
              onExpand={this.onExpand}
              showIcon
              multiple={false}
              style={{ overflowX: 'auto', fontSize: 'small' }}
              onSelect={this.onSelect}
              loadData={this.onLoadData}>
              {this.renderTreeNodes(this.hierarchyElementData)}
            </Tree>

          </div>

          <div className={'without-scrollbar'} style={{
            height: '100%',
            overflowX: "auto",
            width: '100%',
          }}>
            <LearningBudgetCard
              selectedBudget={this.selectedBudget}
              selectedData={this.selectedData}
              currentPerson={this.props.personGroupId}
              learningBudgetRequestHeader={this.learningBudgetRequestHeader}
              isApprover={this.isApprover}
              selectedOrganization={this.selectedData ? this.selectedData.organizationGroup : null}
              onApprovingBudgetControlClick={this.onApprovingBudgetControlClick}
              onApproveBudgetForecastClick={this.onApproveBudgetForecastClick}
            />
          </div>
        </SplitPane>

      </div>
    );
  }

  getKey = (data: HierarchyData): string => data.id + "/" + data.parentId;

  updateBudget = async (value?: LearningBudget): Promise<void> => {
    this.isLoading = true;
    this.learningBudgetRequestHeaders = await restServices.hierarchyService.getHeadersByBudget({
      personGroupId: this.props.personGroupId,
      budgetId: wrapToSerialized(value)!.id
    })
    this.selectedBudget = wrapToSerialized(value)
    this.learningBudgetRequestHeaders.forEach(item => {
      this.hierarchyHeaders.set(item.organizationGroup!.id + this.selectedBudget!.id, item);
    })
    this.isLoading = false;
    const selected = this.selectedData || this.hierarchyElementData[0]
    const defaultOrganization = selected.organizationGroup
    this.props.rootStore!.learningBudgetInfo.setOrganization(wrapToSerialized(defaultOrganization))

    this.learningBudgetRequestHeader = this.hierarchyHeaders.get(selected.organizationGroup.id + this.selectedBudget!.id)!
    this.props.rootStore!.learningBudgetInfo.setLearningBudgetRequestHeader(this.learningBudgetRequestHeader)

    await this.setIsForecast()

    if (typeof this.props.onChangeSelectedInfo === 'function')
      this.props.onChangeSelectedInfo(this.selectedData, this.selectedBudget)
  }

  getRecordByKey = (key: string): HierarchyData | undefined => {
    return this.getRecordByIdFromData(key.substring(0, key.indexOf("/")), this.hierarchyElementData);
  }

  getRecordByIdFromData = (id: string, dataArray: HierarchyData[]): HierarchyData | undefined => {
    if (dataArray) {
      for (let data of dataArray) {
        if (data.id === id) return data;
        if (data.children) {
          const result = this.getRecordByIdFromData(id, data.children);
          if (result !== undefined) return result;
        }
      }
    }
    return undefined;
  }

  parseToHierarchyData = (hierarchyElementData: HierarchyElementExt): HierarchyData | undefined => {
    return {
      id: hierarchyElementData.id,
      parentId: hierarchyElementData.parent ? hierarchyElementData.parent.id : undefined,
      fullName: hierarchyElementData.name,
      positionGroupId: hierarchyElementData.positionGroup ? hierarchyElementData.positionGroup.id : null,
      organizationGroup: hierarchyElementData.organizationGroup,
      parentOrganization: hierarchyElementData.parent ? hierarchyElementData.parent.organizationGroup : undefined,
      personGroup: hierarchyElementData.personGroup,
      hasChild: hierarchyElementData.hasChild,
    } as HierarchyData
  }

  loadData = (): Promise<HierarchyData[]> => {
    return restServices.hierarchyService.getOrganizationChildHierarchyElement({
      parentId: null,
      personGroupId: this.props.personGroupId
    })
      .then(value => value.map(this.parseToHierarchyData).filter(v => v !== undefined) as HierarchyData[]);
  }

  loadDefaultSettings = () => {
    restServices.userSettingService.loadSetting<number[]>('BudgetHierarchyComponent.mainSplitPane')
      .then(value => {
        if (value) this.mainSplitPaneDefaultSizes = value;
      });
  }

  saveDefaultSettings = (sizes: number[]) => {
    restServices.userSettingService.saveSetting('BudgetHierarchyComponent.mainSplitPane', '[' + sizes + ']');
  }

  async initState() {
    this.isTreeLoading = true
    const data = await this.loadData()
    this.hierarchyElementData.push(...data)

    if (!this.hierarchyElementData || this.hierarchyElementData.length === 0) {
      Notification.error({
        message: this.props.intl.formatMessage({ id: "noBudgetData" })
      });
      return
    }
    const selectedOrganization = data[0].organizationGroup || this.props.rootStore!.learningBudgetInfo.selectedOrganization

    if (this.props.rootStore!.learningBudgetInfo.expandedKeys.length) {
      this.expandedKeys = this.props.rootStore!.learningBudgetInfo.expandedKeys;
    }
    if (this.props.rootStore!.learningBudgetInfo.selectedHierarchyData) {
      this.selectedData = this.props.rootStore!.learningBudgetInfo.selectedHierarchyData
    }
    else {
      this.selectedData = this.hierarchyElementData[0]
    }

    if (!this.props.rootStore!.learningBudgetInfo.selectedOrganization) {
      const selectedOrg = wrapToSerialized(selectedOrganization)
      this.props.rootStore!.learningBudgetInfo.setOrganization(selectedOrg)
    }

    if (selectedOrganization.company)
      this.budgetDataCollection.filter = {
        conditions: [{
          property: "company.id",
          operator: "=",
          value: selectedOrganization!.company!.id
        }]
      };

    this.budgetDataCollection.load();
    this.isTreeLoading = false;
    this.loadDefaultSettings()
  }

  componentDidMount() {
    this.initState()
  }

}

const budgetHierarchyComponent = injectIntl(withRouter(BudgetHierarchyComponent));

export default budgetHierarchyComponent;

function addCollectionLoadedCallback<T>(collection: DataCollectionStore<T>, callback: (items: Array<SerializedEntity<T>>) => void) {
  reaction(() => collection.status === 'DONE', (arg, r) => {
    callback(collection.items)
    r.dispose()
  }, {
    fireImmediately: false
  })
}
