import React, {ReactElement} from "react";
import {Card, Checkbox, Input, Radio} from "antd";
import {RadioChangeEvent} from "antd/es/radio";
import CheckboxGroup, {CheckboxValueType} from "antd/es/checkbox/Group";
import {AnswerModel} from "./Question";
import {observable} from "mobx";

// import {AnsweredQuestion} from "./TestComponent";

export interface AnswerComponentProps {
  // answers?: AnswerModel[],
  id?: string,
  text?: string,

  answers?: Array<any>,

  questionId: string
  type: string,
  isReadOnly?: boolean,

  questionnaireId?: any,
  indexOfQuestion?: any,

  checkedAnswers?: any,

  handleCheckboxClick: (event: any) => void,
}

// export interface AnswerComponentHandlers {
//   addRemoveAnswer: (a: AnsweredQuestion) => void
// }

class Answer extends React.Component<AnswerComponentProps> {

  // @observable checkedAnswers: any = []
  @observable inputValue: string
  @observable inputNum: string

  getAnswerComponentByType: React.FC<any> = (type: string): ReactElement => {
    switch (type) {
      case "ONE": {

        return <Radio.Group disabled={this.props.isReadOnly} value={this.props.checkedAnswers} style={{width: "100%", marginBottom: "20px"}}
                            className={"answer-block"}>
          <Card style={{
            width: "100%",
            padding: "5px",
          }} className="card-test-answer-item">

            {this.props.answers!.map((answer) => {
                return <Radio
                  onChange={(event) => this.props.handleCheckboxClick(event)}
                  value={answer.id}
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    border: "1px solid #E0E0E0",
                    borderRadius: "5px",
                    padding: "5px",
                    display: "block",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    marginLeft: "10px"
                  }}>{answer.answerText}
                </Radio>
              }
            )}

          </Card>
        </Radio.Group>;
      }
      case "MANY": {
        return <CheckboxGroup value={this.props.checkedAnswers} disabled={this.props.isReadOnly} style={{width: "100%", marginBottom: "20px"}}
                              className={"answer-block"}>
          <Card style={{
            width: "100%",
            padding: "5px",
          }} className="card-test-answer-item">
            {this.props.answers!.map(answer =>{
            return <Checkbox
                className={"emplyee_checkbox"}
                onClick={(event) => this.props.handleCheckboxClick(event)}
                value={answer.id}
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "5px",
                  padding: "5px",
                  display: "block",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                }}>{answer.answerText}</Checkbox>})}
          </Card>
        </CheckboxGroup>
      }
      case "TEXT": {
        this.inputValue  = this.props.checkedAnswers
        return <Card style={{marginTop: "40px", marginBottom: "20px", width: "100%"}} className="card-test-answer-item">
          <Input  className={"answer-block"} type={"text"}
                 onChange={(event) => { this.props.handleCheckboxClick(event); this.inputValue = event.target.value}}
                value={this.inputValue} disabled={this.props.isReadOnly}/>
        </Card>
      }
      case "NUM": {
        this.inputNum = this.props.checkedAnswers
        return <Card style={{marginTop: "40px", marginBottom: "20px", width: "100%"}} className="card-test-answer-item">
          <Input disabled={this.props.isReadOnly} type={"number"} className={"answer-block"} onChange={(event) => {
            this.props.handleCheckboxClick(event); this.inputNum = event.target.value
          }} value={this.inputNum}/>
        </Card>
      }
    }
    return <></>
  };

  render() {
    const {type} = this.props
    return this.getAnswerComponentByType(type);
  }

  //componentDidMount() {
  // if (this.props.localStorageValues !== null) {
  //   for (const element of this.props.localStorageValues) {
  //     document.getElementById(element)!.parentElement!.classList.add("ant-checkbox-wrapper-checked")
  //   }
  // }
  //}
}

export default Answer;