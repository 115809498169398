import './style.css'
import {default as React} from 'react'
import {Form, Input, Row} from "antd";
import {getEnumCaption, getPropertyInfoNN, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {LearningBudget} from "../../../cuba/entities/base/tsadv_LearningBudget";
import moment from "moment";
import DefaultDatePicker from "../../components/Datepicker";
import {FormComponentProps} from "antd/lib/form";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {inject} from "mobx-react";
import {IReactionDisposer, observable, reaction} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import {HierarchyData} from "./BudgetHierarchyComponent";
import {LearningBudgetRequestHeader} from "../../../cuba/entities/base/tsadv_LearningBudgetRequestHeader";
import {wrapToSerialized} from "./utils";

export type Props = {
  budget?: LearningBudget
  hierarchy?: HierarchyData
  requestHeader?: LearningBudgetRequestHeader

} & FormComponentProps & MainStoreInjected & WrappedComponentProps & RootStoreProp

@injectMainStore
@inject("rootStore")
class BudgetInfoComponent extends React.Component<Props> {
  budgetWatcher: IReactionDisposer
  hierarchyWatcher: IReactionDisposer
  headerWatcher: IReactionDisposer

  @observable budget?: SerializedEntity<LearningBudget> = wrapToSerialized(this.props.budget)
  @observable hierarchy?: HierarchyData = this.props.hierarchy
  @observable requestHeader?: LearningBudgetRequestHeader = this.props.requestHeader

  constructor(props: Props) {
    super(props);
    this.budgetWatcher = reaction(() => this.props.budget,
      arg => this.budget = wrapToSerialized(arg))
    this.hierarchyWatcher = reaction(() => this.props.hierarchy, arg => this.hierarchy = arg)
    this.headerWatcher = reaction(() => this.props.requestHeader, arg => this.requestHeader = arg)
  }

  render() {
    return (
      <fieldset style={{
        border: "1px solid silver",
        borderRadius: "1rem",
        marginTop: "5%",
        width: "100%"
      }}>
        <legend style={{
          width: "fit-content",
          margin: '0 1%'
        }}>{this.props.intl.formatMessage({id: "budgetInfo"})}</legend>
        <Form className='form-grid'>
          <Row style={{gridColumn: 1}}>
            <Form.Item>
              <div className="budget-request-form">
                <label style={{width: '100%'}}>{this.props.intl.formatMessage({id: "budget"})}</label>
                <div className={'budget-request-form-input'}>
                  {this.props.form.getFieldDecorator("name",
                    {
                      initialValue: this.budget ? this.budget._instanceName : null
                    })(
                    <Input disabled={true}/>
                  )}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="budget-request-form">
                <label style={{width: '100%'}}>{this.props.mainStore!.messages![`${LearningBudget.NAME}.budgetStartDate`]}</label>
                <div className={'budget-request-form-input'}>
                  {this.props.form.getFieldDecorator("budgetStartDate",
                    {
                      initialValue: this.budget ? moment(this.budget.budgetStartDate) : null
                    })(
                    <DefaultDatePicker style={{width: "100%"}} disabled={true}/>
                  )}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="budget-request-form">
                <label style={{width: '100%'}}>{this.props.mainStore!.messages![`${LearningBudget.NAME}.budgetEndDate`]}</label>
                <div className={'budget-request-form-input'}>
                  {this.props.form.getFieldDecorator("budgetEndDate",
                    {
                      initialValue: this.budget ? moment(this.budget.budgetEndDate) : null
                    })(
                    <DefaultDatePicker style={{width: "100%"}} disabled={true}/>
                  )}
                </div>
              </div>
            </Form.Item>
          </Row>
          <Row style={{gridColumn: 2}}>
            <Form.Item>
              <div className="budget-request-form">
                <label style={{width: '100%'}}>{this.props.intl.formatMessage({id: "manager"})}</label>
                <div className={'budget-request-form-input'}>
                  {this.props.form.getFieldDecorator("manager",
                    {
                      initialValue: this.hierarchy && this.hierarchy.personGroup ? this.hierarchy.personGroup._instanceName : null
                    })(
                    <Input disabled={true}/>
                  )}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="budget-request-form">
                <label style={{width: '100%'}}>{this.props.mainStore!.messages![`${LearningBudget.NAME}.status`]}</label>
                <div className={'budget-request-form-input'}>
                  {this.props.form.getFieldDecorator("status",
                    {
                      initialValue: this.requestHeader ? getEnumCaption(this.requestHeader.status, getPropertyInfoNN("status", LearningBudgetRequestHeader.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!) : null
                    })(
                    <Input disabled={true}/>
                  )}
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <div className="budget-request-form">
                <label style={{width: '100%'}}>{this.props.intl.formatMessage({id: "organizationGroup"})}</label>
                <div className={'budget-request-form-input'}>
                  {this.props.form.getFieldDecorator("fullName",
                    {
                      initialValue: this.hierarchy ? this.hierarchy.fullName : null
                    })(
                    <Input disabled={true}/>
                  )}
                </div>
              </div>
            </Form.Item>
          </Row>
        </Form>
      </fieldset>
    )
  }

  componentWillUnmount() {
    this.budgetWatcher()
    this.hierarchyWatcher()
    this.headerWatcher()
  }
}

export default injectIntl(BudgetInfoComponent)