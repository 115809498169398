import * as React from "react";
import {
  Alert,
  Button,
  Card, Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input,
  List,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
  Typography
} from "antd";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import moment, {Moment} from "moment";
import {getCubaREST, getEnumCaption, getPropertyInfoNN, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {IdpDetail} from "../../../../cuba/entities/base/tsadv$IdpDetail";
import {action, autorun, observable, runInAction, when} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import {inject, observer} from "mobx-react";
import {DicIdpActionType} from "../../../../cuba/entities/base/tsadv_DicIdpActionType";
import {DicEducationType} from "../../../../cuba/entities/base/tsadv$DicEducationType";
import {Competence} from "../../../../cuba/entities/base/tsadv$Competence";
import {ColumnProps} from "antd/es/table";
import {rootStore, RootStoreProp} from "../../../store";
import {IdpDetailsActionTypeCodes, IdpDetailsEducationTypeCodes, IdpStatus} from "../../../../cuba/enums/enums";
import {CompetenceIndicator} from "../../../../cuba/entities/base/tsadv_CompetenceIndicator";
import {Course} from "../../../../cuba/entities/base/tsadv$Course";
import {FormComponentProps} from "antd/es/form";
import Notification from "../../../util/Notification/Notification";
import {AddTaskModal} from "./AddTaskModal";
import {TaskDetails} from "./TaskDetails";
import {AccessSettingForQuarterlyMeetings} from "../../../../cuba/entities/base/tsadv_AccessSettingForQuarterlyMeetings";



type CompetenceType = {
  id: string,
  name: string,
  tasks: IdpDetail[],
  courses?:Course[]|undefined | null
}

type Props = {
  entityId: string,
}

type CompetenceCourse = {
  competenceId:string,
  courses:Course[]
}


@inject("rootStore")
@injectMainStore
@observer
class IndividualDevPlanTasks extends React.Component<Props & RootStoreProp & WrappedComponentProps & FormComponentProps & MainStoreInjected> {
  @observable isLoading: boolean = true;

  @observable competence: Competence

  @observable unabledToEditPage:boolean

  isUnableToEdit = ():boolean =>{
    if(this.unabledToEditPage&&!(rootStore!.idpStore&&rootStore!.idpStore.isReservist)){
      Modal.confirm({
        title: this.props.intl.formatMessage({id:"idp.edit.error"}),
        okText: 'ОК',
        cancelButtonProps:{style:{display:"none"}},
        centered:true
      });
      return true;
    }
    return false;
  }

  @action changeCompetence(value: Competence) {
   const competence = this.tasks.map(t=>t.competence).find(c=>c!.id === value.id)
  if(competence) this.competence = competence;
  }
  @observable competenceCourses:CompetenceCourse[] = []

  uuidv4 = () => {
    return 'new' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  @observable taskPlanDate: Moment;

  @action.bound changeTaskPlanDate(date: Moment | null) {
    if (date) {
      this.taskPlanDate = date
    }
  }

  @action.bound handleOkCompetence() {
    const c = this.tasks.find(task=> (task.competence&&task.competence.competence&&task.competence.competence.id) === this.selectedCompetenceId)
    if(c){
      return Notification.error({message:this.props.intl.formatMessage({id:"alreadyHaveCompetence.error"},{competence:c.competence&&c.competence['_instanceName'],number:rootStore!.idpStore&&rootStore!.idpStore.selectedIdp.planName})});
    }
   const competence = this.competencies.find((competence)=>competence.id === this.selectedCompetenceId)
    getCubaREST()!.searchEntities<CompetenceIndicator>(CompetenceIndicator.NAME,{
      conditions:[{
        property:"competenceGroup.id",
        operator:"=",
        value:competence&&competence.group?competence.group.id:""
      }]
    },{view:"competenceIndicator-browse"}).then(indicators=>{
      const courses = indicators&&indicators.length>0?indicators!.filter(ind=>ind.training).map(i=>i.training!):undefined
      if(competence&&courses){
          this.competenceCourses.push({
            competenceId:competence.group&&competence.group.id,
            courses:courses
          })
      }
      let data: SerializedEntity<IdpDetail>;
      const item = this.competencies.find(value => value.id === this.selectedCompetenceId)
      if(item) {
        item.group!.competence = {
          id: item.id
        }

        data = {
          id: item.group && item.group.id,
          competence: item.group,
          _instanceName: item['_instanceName'],
          _entityName: IdpDetail.NAME
        }
        let result = this.tasks.find(i => i.id === data.id)
        if (result) return
        const initialTasks: IdpDetail[] = [
          {
            id: this.uuidv4(),
            competence: data.competence,
            educationType: this.educationTypes.find((type) => type.code === IdpDetailsEducationTypeCodes.THEO),
            actionType: this.actionTypes.find((type)=>type.code===IdpDetailsActionTypeCodes.TRAINING),
            goalName: "Пройти тренинг",
            done: false,
            course:courses&&courses.length===1?courses[0]:undefined,
            description:courses&&courses.length===1?courses[0]!.name:""
          },
          {
            id: this.uuidv4(),
            competence: data.competence,
            educationType: this.educationTypes.find((type) => type.code === IdpDetailsEducationTypeCodes.EXCHANGE),
            actionType: this.actionTypes.find((type)=>type.code===IdpDetailsActionTypeCodes.FEEDBACK),
            goalName: "Получить отзыв от руководителя",
            done: false
          },
          {
            id: this.uuidv4(),
            competence: data.competence,
            educationType: this.educationTypes.find((type) => type.code === IdpDetailsEducationTypeCodes.WORKPLACE),
            actionType: this.actionTypes.find((type)=>type.code===IdpDetailsActionTypeCodes.INTERNET),
            goalName: "Самостоятельно изучить интернет источники",
            done: false
          }
        ]
        if(this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdpDetails){
          this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.setSelectedIdpDetails([...this.props.rootStore!.idpStore.selectedIdpDetails,...initialTasks as SerializedEntity<IdpDetail>[]])
        }
        else{
          this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.setSelectedIdpDetails((initialTasks as SerializedEntity<IdpDetail>[]))
        }
        if(this.props.rootStore!.idpStore!) this.props.rootStore!.idpStore!.calculatePercent();
      }
      this.showCompetenceModal = false;
    })
  }

  @action.bound handleCancelCompetence() {
    this.changeShowCompetenceModal(false)
  }

  @observable actionTypes: SerializedEntity<DicIdpActionType>[]

  @action changeActionTypes(value: SerializedEntity<DicIdpActionType>[]) {
    this.actionTypes = value
  }

  @observable educationTypes: SerializedEntity<DicEducationType>[]

  @action changeEducationTypes(value: SerializedEntity<DicEducationType>[]) {
    this.educationTypes = value
  }

  @observable showCompetenceModal = false;
  @observable competencies: SerializedEntity<Competence>[]

  @observable positionCompetencies: SerializedEntity<Competence>[]

  @action.bound changeCompetencies(value: SerializedEntity<Competence>[]) {
    this.originalCompetencies = value
    this.competencies = value
  }

  @action.bound changeShowCompetenceModal(value: boolean) {
    if(this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp&&this.props.rootStore!.idpStore.selectedIdp.positionGroup)
      this.showCompetenceModal = value
    else {
     return Notification.error({message:this.props.intl.formatMessage({id:"notification.positionGroup"}),duration:3})
    }
  }

  @observable selectedCompetenceId: string

  @action.bound changeSelectedCompetence(value: string) {
    this.selectedCompetenceId = value
  }

  @observable uniqueData: CompetenceType[];

  @action.bound changeUniqueData(value: CompetenceType[]) {
    this.uniqueData = value
  }

  @action.bound pushUniqueDate(value: CompetenceType) {
    this.uniqueData.push(value)
  }

  @observable data: CompetenceType[]

  @action.bound changeData(value: CompetenceType[]) {
    this.data = value
  }

  @action.bound pushData(value: CompetenceType) {
    this.data.push(value)
  }

  @observable tasks: IdpDetail[] = [];

  @action.bound changeTasks(value: SerializedEntity<IdpDetail>[]) {
     this.tasks = value.filter((value) => value.deleteTs===undefined).sort((a, b) => Number(a.actionType&&a.actionType.code)-Number(b.actionType&&b.actionType.code))
    this.isLoading = false
    this.onMutationTasks()
  }

  @action.bound pushTasks(value: IdpDetail) {
    this.tasks.push(value)
  }

  @action.bound onMutationTasks() {
    this.changeData(this.tasks&&this.tasks.filter(i => (i.competence && i.competence)).map(value => {
      const competenceCourse = this.competenceCourses.find(c=>c.competenceId===value.id)
      return{
      id: value.competence && value.competence && value.competence.id,
      name: value.competence&& value.competence["_instanceName"],
      tasks: this.tasks.filter(item => item.competence&&item.competence.id === (value.competence && value.competence && value.competence.id)),
      courses:competenceCourse?competenceCourse.courses:undefined
    }}))
    this.changeUniqueData(this.data && [...new Map(this.data.map(item =>
      [item["id"], item])).values()])
  }

  @action.bound onDeleteTask() {
    const store = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore
    if(this.isUnableToEdit()) return
      const currentTaskId = this.props.rootStore!.idpStore&& this.props.rootStore!.idpStore.currentTaskDetail&&this.props.rootStore!.idpStore.currentTaskDetail.id
      const item = rootStore.idpStore!.getIdpDetailById(currentTaskId)
      if (item&&store) {
        item.deleteTs = moment().toISOString()
        this.changeTasks(store.selectedIdpDetails)
        store.calculatePercent()
      }

  }

  @action.bound markAsDone() {
    this.showFactDateModal = true;
  }

  @observable showFactDateModal: boolean = false;
  @observable factDate: Date;
  @observable currentIdpDetail:IdpDetail
  @observable competenceTablePage=1;
  @action.bound handleOkFactDate() {
    const store = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore;
    const currentTaskId = this.props.rootStore!.idpStore&& this.props.rootStore!.idpStore.currentTaskDetail&&this.props.rootStore!.idpStore.currentTaskDetail.id
    let result = this.tasks.find(task => task.id === currentTaskId)
    if (result && this.factDate) {
      result.actualDate = this.factDate;
      result.done = true
      this.showFactDateModal = false;
      const item = rootStore.idpStore!.getIdpDetailById(result.id)
      if (item) {
        item.actualDate = result.actualDate
        item.done = true;
        if(store){
          store.calculatePercent()
        }
      }
    }
  }
  @action.bound onDeleteCompetence = (record:CompetenceType) => {
   if(this.isUnableToEdit()) return
    const store = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore
    const details = record.tasks.map(t => store && store.getIdpDetailById(t.id))
    const deleteTasks = () => {
      if (details && details.length > 0) {
        details.forEach(d => d!.deleteTs = new Date())
        if(store) {
          this.changeTasks(store.selectedIdpDetails)
          store.calculatePercent()
        }
        }
    }
    if(record.tasks&&record.tasks.length>0){
     const doneTasks = record.tasks.filter(t=>t.done)
      if(doneTasks.length>0){
        Modal.confirm({
          title: this.props.intl.formatMessage({id:"notification.deleteCompetence"}),
          onOk() {
            deleteTasks()
          },
          onCancel() {},
        });
      }
    else {
        deleteTasks()
      }
     }
  }

  @observable editableTaskDetails:boolean

  @observable disabledPage:boolean

  @action changeCreateTaskVisible(value: boolean) {
    if(this.props.rootStore!.idpStore) this.props.rootStore!.idpStore.createTaskVisible = value
  }

  @action validateTasks  = ():boolean =>{
  const noDescription = this.tasks.filter(t=>!t.description||t.description.length === 0)
    const noCourse = this.tasks.filter(t=>t.actionType&&t.actionType.code === IdpDetailsActionTypeCodes.TRAINING&&t.course===undefined)
    const noPlanDate = this.tasks.filter(t=>!t.targetDate)
    if(noDescription.length>0||noCourse.length>0||noPlanDate.length>0){
      Notification.error({
        message: this.props.intl.formatMessage({id: "idp.tasksValidation.error"})
      });
      return false
    }
    return true
  }

  @observable accessSettingForQuarterlyMeetings:AccessSettingForQuarterlyMeetings;

  @observable byCompany:boolean = false;

  @observable originalCompetencies:SerializedEntity<Competence>[];

  @observable searchInput:string = "";

 @action changeSearchInput = (value:string) =>{
   this.searchInput = value;
 }

  @action handleSearch = (value:string) => {
    if(value) this.competencies = this.originalCompetencies.filter(c=>c._instanceName.toLocaleLowerCase().includes(value))
    else this.competencies = this.originalCompetencies;
  }

@action resetSearch = () => {
   this.competencies = this.originalCompetencies;
   this.searchInput = "";
   this.props.form.setFieldsValue({'searchInput':""})
  }

  render(): React.ReactNode {
    const currentTask = this.props.rootStore!.idpStore&& this.props.rootStore!.idpStore.currentTaskDetail&&this.props.rootStore!.idpStore.currentTaskDetail
    const courses = this.competenceCourses.find((cc)=> cc.competenceId ===(currentTask&&currentTask.competence&&currentTask.competence.id))
    const competenceCourses = courses&&courses.courses
    const {TabPane} = Tabs
    const {Text} = Typography
    const {Option} = Select
    const currentCompetence =this.competenceCourses.find(cc=>cc.competenceId===(this.competence&&this.competence.id))
    const taskCourses = currentCompetence&&currentCompetence.courses
    const columns:ColumnProps<CompetenceType>[] = [
      {title: this.props.intl.formatMessage({id:"competenceTab"}), dataIndex: 'name', key: 'competenceName', width:"90%"},
      {key:'deleteBtn',render:((text, record) => <Button style={this.disabledPage?{display:"none"}:{}} onClick={()=>this.onDeleteCompetence(record)}><Icon type="delete"/></Button>)}
    ];
    const statusBackColor = (status: boolean): string => {
      if (status)
        return 'green'
      else
        return '#fffb8f'
    }
    const statusTextColor = (status: boolean): string => {
      if (status)
        return 'white'
      else
        return 'black'
    }
    const menu = (task:IdpDetail) => (
      <Menu>
        <Menu.Item>
          <a onClick={this.onDeleteTask}>
            <FormattedMessage id={"management.browser.delete.ok"}/>
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => runInAction(() => {
            if (this.isUnableToEdit()) return
              this.editableTaskDetails = true
              this.props.rootStore!.idpStore!.showTaskDetails = true
          })}>
          <FormattedMessage id={"change"}/>
          </a>
        </Menu.Item>
        <Menu.Item disabled>
          <a onClick={() => this.markAsDone()} style={task&&task.description&&task.targetDate?{}:{pointerEvents:"none"}}>
            <FormattedMessage id={"markAsDone"}/>
          </a>
        </Menu.Item>
      </Menu>
    );
    const competenceColumns: ColumnProps<Competence>[] = [
      {
        title: this.props.intl.formatMessage({id:"type"}),
        dataIndex: "competenceType",
        key: 'competenceType',
        render: (text, record) => record.competeceType && record.competeceType['_instanceName']
      },
      {
        title: this.props.intl.formatMessage({id:"nameOf"}),
        dataIndex: '_instanceName',
        key: '_instanceName',
        filterIcon: () => (
          <Icon type="search" style={{color:'white', backgroundColor: '#005487',fontSize:16,width:40}} />
        ),
        filterDropdown:(() => {
         return (<div style={{ padding: 8 }}>
             <Form.Item style={{marginBottom:5}}>
               {this.props.form.getFieldDecorator('searchInput')(
                 <Input
                   onChange={e => this.changeSearchInput(e.target.value.toLocaleLowerCase())}
                   onPressEnter={() => this.handleSearch(this.searchInput)}
                   style={{ width: 188, marginBottom: 8, display: 'block' }}
                 />
               )}
             </Form.Item>
            <Button
              type="primary"
              onClick={() => this.handleSearch(this.searchInput)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.resetSearch()} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        )
        })
      },
      {
        title: this.props.intl.formatMessage({id:"keyPosition.modalTable.table.scale"}),
        dataIndex: 'scale',
        key: 'scale',
        render: (text, record) => record.scale && record.scale['_instanceName']
      },
    ]
    const companyCode = this.props.rootStore!.userInfo.companyCode!
    const store = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore
    const positionGroup = store&&store.selectedIdp&&store.selectedIdp.positionGroup
    const positionName = positionGroup&&positionGroup['_instanceName']
    const orgName = positionGroup&&positionGroup.organizationGroup&&positionGroup.organizationGroup['_instanceName']
    const positionCompetenciesIds =store&&store.positionCompetencies&&store.positionCompetencies.map(pc=>pc.competence&&pc.competence.id)

    return <>
      <AddTaskModal key={currentCompetence&&currentCompetence.competenceId} intl={this.props.intl} competence={this.competence} educationTypes={this.educationTypes} actionTypes={this.actionTypes} competenceCourses={competenceCourses?competenceCourses:[]} taskCourses={taskCourses?taskCourses:[]}/>
      <Modal
        visible={this.showCompetenceModal}
        title={this.props.intl.formatMessage({id:"modal.addCompetence"})}
        onOk={this.handleOkCompetence}
        onCancel={this.handleCancelCompetence}
        width={1000}
        centered
        bodyStyle={{paddingBottom:0}}
        footer={[
          <Button key="back" onClick={this.handleCancelCompetence}>
            <FormattedMessage id={"management.browser.exclude.cancel"}/>
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOkCompetence}>
           <FormattedMessage id={"bproc.startBproc.modal.add"}/>
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab={
            <FormattedMessage id={"currentPositionCompetencies"}/>
          } key={"1"}>
            <Row>
              <Col span={11} offset={1}>
                <Form.Item label={<FormattedMessage id={"individualPlan.edit.positionGroup"}/>}>
                  <Input value={positionName||""}/>
                </Form.Item>
              </Col>
              <Col span={11} offset={1}>
                <Form.Item label={<FormattedMessage id={"individualPlan.edit.organization"}/>}>
                  <Input value={orgName||""}/>
                </Form.Item>
              </Col>
            </Row>
            <Table
              dataSource={this.competencies&&this.competencies.filter(c => positionCompetenciesIds&&positionCompetenciesIds.includes(c.id))}
              columns={competenceColumns.map((c,index)=>{
                if(index===1){
                  return {...c,filterDropdown:undefined}
                }
                return c
              })}
              key={this.selectedCompetenceId}
              pagination={{pageSize: 30,onChange:page =>{runInAction(()=>this.competenceTablePage = page)},current:this.competenceTablePage}}
              rowKey={record => this.selectedCompetenceId&&this.selectedCompetenceId===record.id?record.id+"selected":record.id}
              onRow={(record) => {return {onClick:(()=>this.changeSelectedCompetence(record.id))}}}
              rowClassName={(record =>this.selectedCompetenceId===record.id?"ant-table-row ant-table-row-level-0 ant-table-row-selected":"ant-table-row ant-table-row-level-0")}
            />
          </TabPane>
          <TabPane tab={<FormattedMessage id={"catalog"}/>} key="2">
            <div>
              <div style={{display:"inline-block",width:"80%"}}>
                <Alert style={{width:"fit-content",margin:"auto",paddingLeft:"50px",paddingRight:"50px"}} message={<FormattedMessage id="competenceNotFound"/>} type="warning"/>
              </div>
              <div style={{textAlign:"right",fontWeight:"bold",display:"inline-block", width:"20%"}}>
              <Checkbox value={this.byCompany} onChange={()=>{
               runInAction(()=>this.byCompany = !this.byCompany)}}><FormattedMessage id="byCompany"/></Checkbox>
              </div>
            </div>
            <Table
              dataSource={this.byCompany?this.competencies.filter(c=>c.company&&c.company.code===((rootStore!.idpStore!.selectedIdp!.positionGroup&&rootStore!.idpStore!.selectedIdp!.positionGroup.company&&rootStore!.idpStore!.selectedIdp!.positionGroup.company.code)||this.props.rootStore!.userInfo.companyCode)):this.competencies }
              columns={competenceColumns}
              key={this.selectedCompetenceId}
              pagination={{pageSize: 30,onChange:page =>{runInAction(()=>this.competenceTablePage = page)},current:this.competenceTablePage}}
              rowKey={record => this.selectedCompetenceId&&this.selectedCompetenceId===record.id?record.id+"selected":record.id}
              onRow={(record) => {return {onClick:(()=>this.changeSelectedCompetence(record.id))}}}
              rowClassName={(record =>this.selectedCompetenceId===record.id?"ant-table-row ant-table-row-level-0 ant-table-row-selected":"ant-table-row ant-table-row-level-0")}
            />
          </TabPane>
        </Tabs>
      </Modal>
      <Modal
        visible={this.showFactDateModal}
        title={<FormattedMessage id={"chooseFactDate"}/>}
        key={"addFactDate"}
        centered
        footer={[
          <Button key="back" onClick={() => runInAction(() => this.showFactDateModal = false)}>
            <FormattedMessage id={"form.cancel"}/>
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleOkFactDate}>
            <FormattedMessage id={"bproc.startBproc.modal.add"}/>
          </Button>,
        ]}
      >
        <DatePicker locale={'ru'} format={'L'} onChange={(date) => {
          if (date)
            this.factDate = date.toDate()
        }}/>
      </Modal>

      <TaskDetails showTaskDetail={this.props.rootStore!.idpStore!.showTaskDetails}
                   educationTypes={this.educationTypes}
                   actionTypes={this.actionTypes} companyCode={companyCode}
                   courses={competenceCourses}
                   key={currentTask&&currentTask.id}
                   editable={this.editableTaskDetails}
                   intl={this.props.intl}

      />
      <Card
        className="narrow-layout card-actions-container"
        bordered={false}
      >
        <Button style={this.disabledPage?{display:"none"}:{marginLeft: 20}} onClick={() => {if(this.isUnableToEdit()) return;this.changeShowCompetenceModal(true)}} type={"primary"}><FormattedMessage id={"addCompetence"}/></Button>
        <Table
          columns={columns}
          dataSource={this.uniqueData || []}
          rowKey={(record => record.id)}
          loading={this.isLoading}
          expandedRowRender={(record: CompetenceType) => (
            <>
              <Button style={this.disabledPage?{display:"none"}:{marginBottom: "20px"}} type={"primary"} onClick={() => {
                if(this.isUnableToEdit()) return
                this.changeCompetence(record);
                this.changeCreateTaskVisible(true)
              }}><FormattedMessage id={"addTask"}/></Button>
              <List
                size="small"
                bordered
                dataSource={record.tasks}
                rowKey={item => item.id}
                renderItem={(item: IdpDetail) =>
                  <List.Item>
                    <div className={"task-list-item-container"}>
                      <div className={"task-list-item"}>
                        <div className={"task-status"} style={{backgroundColor: statusBackColor(item && item.done!)}}>
                          <Text
                            style={{color: statusTextColor(item && item.done!)}}>{item.done ? this.props.intl.formatMessage({id:"finished"}) : this.props.intl.formatMessage({id:"notFinished"})}</Text>
                        </div>
                          <Form.Item style={{marginBottom: 0}} label={<FormattedMessage id={"planDate"}/>}
                                     help={item.targetDate?undefined:<FormattedMessage id={"addPlanDateMsg"}/>}
                                     validateStatus={item.targetDate?"":"error"}
                          >
                            <Input className={"taskDate"}
                                   onClick={()=>runInAction(()=> {
                                     if(this.props.rootStore!.idpStore){
                                       this.props.rootStore!.idpStore.showTaskDetails = true;
                                       this.props.rootStore!.idpStore.setCurrentTaskDetail(item);
                                       this.props.rootStore!.idpStore.showTaskDetails = true
                                       this.editableTaskDetails = true;
                                     }
                                   })}
                                   readOnly={!item.id.startsWith('new')} disabled={!item.id.startsWith('new')}
                                   value={item.targetDate ? moment(item.targetDate).locale('ru').format('L') : ""}/>
                          </Form.Item>
                        <Form.Item style={{marginBottom: 0}} label={<FormattedMessage id={"factDate"}/>}>
                          <Input className={"taskDate"}
                                 disabled
                                 value={item.actualDate ? moment(item.actualDate).locale('ru').format('L') : ""}/>
                        </Form.Item>
                      </div>
                      <div className={"task-main"}>
                        <Input disabled
                               value={item.goalName ? item.goalName : ""}
                               onChange={(e) => {
                                 item.description = e.target.value;
                               }}/>
                        <div className={"task-types"}>
                          <Form.Item className={"taskSelect"} label={<FormattedMessage id={"educationType"}/>}>
                            <Select style={{width: "100%"}} disabled
                                    onChange={(value) => {
                                      item.educationType = this.educationTypes.find((type) => type.id === value);
                                    }} value={item.educationType && item.educationType.id}>
                              {this.educationTypes.map(e => <Option value={e.id} key={e.id}>{e._instanceName}</Option>)}
                            </Select>
                          </Form.Item>
                          <Form.Item  className={"taskSelect"} label={<FormattedMessage id={"actionType"}/>}>
                            <Select style={{width: "100%"}}
                                    disabled
                                    onChange={(value) => {
                                      item.actionType = this.actionTypes.find((type) => type.id === value);
                                    }}
                                    value={item.actionType && item.actionType.id}>
                              {this.actionTypes.map(e => <Option value={e.id} key={e.id}>{e._instanceName}</Option>)}
                            </Select>
                          </Form.Item>
                        </div>
                        {item.actionType&&item.actionType.code === IdpDetailsActionTypeCodes.TRAINING?
                        <Form.Item label={<FormattedMessage id={"course"}/>} help={item.course?undefined:this.props.intl.formatMessage({id:"help.chooseCourse"})} style={{marginBottom:0}} validateStatus={item.course?"":"error"}>
                          <Input readOnly={!item.id.startsWith('new')} disabled={!item.id.startsWith('new')}
                                 onClick={()=>runInAction(()=> {
                            if(this.props.rootStore!.idpStore){
                              this.props.rootStore!.idpStore.showTaskDetails = true;
                              this.props.rootStore!.idpStore.setCurrentTaskDetail(item);
                              this.props.rootStore!.idpStore.showTaskDetails = true
                              this.editableTaskDetails = true;
                            }
                          })} value={item.course&&item.course.name?item.course.name:""}/>
                        </Form.Item>
                          :
                          <></>
                        }
                        <Form.Item help={item.description&&item.description.length>0?undefined:this.props.intl.formatMessage({id:"help.taskDescription"})} validateStatus={item.description&&item.description.length>0?"":"error"} style={{marginBottom: 0}} label={<FormattedMessage id={"description"}/>}>
                          <Input readOnly={!item.id.startsWith('new')} disabled={!item.id.startsWith('new')}
                                    onClick={()=>runInAction(()=> {
                                   if(this.props.rootStore!.idpStore){
                                     this.props.rootStore!.idpStore.showTaskDetails = true;
                                     this.props.rootStore!.idpStore.setCurrentTaskDetail(item);
                                     this.props.rootStore!.idpStore.showTaskDetails = true
                                     this.editableTaskDetails = true;
                                   }
                                 })}
                                 value={item.description ? item.description : ""}/>
                        </Form.Item>
                      </div>
                      <div className={"task-options"}>
                        <Dropdown onVisibleChange={() => runInAction(() => {
                          this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.setCurrentTaskDetail(item)})}
                                  overlay={menu(item)} placement="bottomCenter" disabled={this.disabledPage}>
                          <Button type="primary"><FormattedMessage id="management.browser.edit"/><Icon type="edit" style={{margin: "auto"}}/></Button>
                        </Dropdown>
                        <a style={{marginBottom:10}} onClick={()=>{runInAction(()=>{
                          if(this.props.rootStore!.idpStore){
                            this.props.rootStore!.idpStore.setCurrentTaskDetail(item)
                            this.props.rootStore!.idpStore.showTaskDetails = true
                            this.editableTaskDetails = false;
                          }})}}>
                          <FormattedMessage id={"taskDetails"}/>
                        </a>
                      </div>
                    </div>
                  </List.Item>}
              />
            </>)}
        />
      </Card>
    </>
  }

  constructor(props:Props & RootStoreProp & WrappedComponentProps&MainStoreInjected&FormComponentProps) {
    super(props);
    const store = this.props.rootStore!.idpStore
    if(store){
      store.validateFields = this.validateTasks
    }
   autorun(()=>{
      if(this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdpDetails){
        this.changeTasks(this.props.rootStore!.idpStore.selectedIdpDetails)
      }
    })
  }


  componentDidMount() {

    if (this.props.entityId === "new") {
      this.changeTasks([])
    }
    else
    {
      when(() => !!(this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.selectedIdpDetails && this.props.rootStore!.idpStore.selectedIdpDetails.length > 0), () => {
        const idp = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp
        this.changeTasks(this.props.rootStore!.idpStore!.selectedIdpDetails);
        if(idp&&idp.status&&idp.status.code === IdpStatus.APPROVING){
          this.disabledPage = true;
        }
        if(this.props.rootStore!.userInfo.personGroupId !== this.props.rootStore!.idpStore!.selectedIdp.personGroup!.id){
          this.disabledPage = true;
        }
        const idpArray = this.props.rootStore!.idpStore!.selectedIdpDetails!.map(i=>i.competence&&i.competence.id)
        const idpSet = new Set(idpArray)
        idpSet.forEach(competence=>{
          getCubaREST()!.searchEntities<CompetenceIndicator>(CompetenceIndicator.NAME,{
            conditions:[{
              property:"competenceGroup.id",
              operator:"=",
              value:competence
            }]
          },{view:"competenceIndicator-browse"}).then(indicators=> {
            const courses = indicators && indicators.length > 0 ? indicators!.filter(ind => ind.training).map(i => i.training!) : undefined
            if (courses && competence) {
              this.competenceCourses.push({
                competenceId: competence,
                courses: courses
              })
            }
          })
        })
      })
    }
    getCubaREST()!.loadEntities<DicEducationType>(DicEducationType.NAME, {view: "_local"}).then(value => this.changeEducationTypes(value))
    getCubaREST()!.loadEntities<DicIdpActionType>(DicIdpActionType.NAME, {view: "_local"}).then(value => this.changeActionTypes(value))
    getCubaREST()!.searchEntities<Competence>(Competence.NAME, {
      conditions: [
        {
          property: "startDate",
          operator: "<=",
          value: moment().toISOString()
        },
        {
          property: "endDate",
          operator: ">=",
          value: moment().toISOString()
        }
      ]
    }, {view: "competence.edit"}).then(value => this.changeCompetencies(value))
    getCubaREST()!.searchEntities<AccessSettingForQuarterlyMeetings>(AccessSettingForQuarterlyMeetings.NAME,{
      conditions:[{
        property:"year",
        operator:"=",
        value:moment().year()
      },{
        property:"quarter",
        operator:"=",
        value:getEnumCaption("Q"+moment().utc().quarter(), getPropertyInfoNN("quarter", AccessSettingForQuarterlyMeetings.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!)||""
      }]
    },{view:"_local"}).then(res=>runInAction(()=>{
      this.accessSettingForQuarterlyMeetings = res[0];
      when(()=>!!(this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp),()=>{
        const idp = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp;
        if(this.accessSettingForQuarterlyMeetings&&idp&&idp.status&&idp.status.code===IdpStatus.APPROVED){
          this.unabledToEditPage = !moment().isBetween(moment(this.accessSettingForQuarterlyMeetings.startDate), moment(this.accessSettingForQuarterlyMeetings.endDate));
        }
      })
    }))
  }
}

export default  Form.create<FormComponentProps&Props>()(injectIntl(IndividualDevPlanTasks));