import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { Requisition } from "./tsadv$Requisition";
import { FileDescriptor } from "./sys$FileDescriptor";
export class JobOfferRequest extends AbstractBprocRequest {
  static NAME = "tsadv_JobOfferRequest";
  candidate?: PersonGroupExt | null;
  requisition?: Requisition | null;
  positionSalaryLevelMin?: string | null;
  expectedSalary?: any | null;
  startDate?: any | null;
  allowance?: any | null;
  justification?: string | null;
  candidateFiles?: FileDescriptor[] | null;
}
