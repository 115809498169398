import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { PersonMedal } from "./tsadv$PersonMedal";
import { DicAwardType } from "./tsadv$DicAwardType";
import { DicAwardLevel } from "./tsadv_DicAwardLevel";
import { DicAwardMeasure } from "./tsadv_DicAwardMeasure";
import { DicAward } from "./tsadv_DicAward";
import { Medal } from "./tsadv$Medal";
import { DicCompany } from "./base_DicCompany";
import { OrganizationGroupExt } from "./base$OrganizationGroupExt";
import { PersonMedalRequestAttachment } from "./tsadv_PersonMedalRequestAttachment";
export class PersonMedalRequest extends AbstractBprocRequest {
  static NAME = "tsadv_PersonMedalRequest";
  initiatorGroup?: PersonGroupExt | null;
  deliveryDate?: any | null;
  awardedBy?: PersonGroupExt | null;
  delivered?: boolean | null;
  personGroup?: PersonGroupExt[] | null;
  personMedal?: PersonMedal[] | null;
  awardType?: DicAwardType | null;
  awardLevel?: DicAwardLevel | null;
  awardMeasure?: DicAwardMeasure | null;
  award?: DicAward | null;
  description?: string | null;
  payout?: boolean | null;
  hasBonus?: boolean | null;
  medal?: Medal | null;
  countMedal?: number | null;
  company?: DicCompany | null;
  unit?: OrganizationGroupExt | null;
  attachments?: PersonMedalRequestAttachment[] | null;
  amountKzt?: number | null;
  onCommission?: boolean | null;
  bonusMCI?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  approveDate?: any | null;
}
export type PersonMedalRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "my-nominations-view"
  | "person-medal-request-edit-view"
  | "personMedalRequest.edit";
export type PersonMedalRequestView<
  V extends PersonMedalRequestViewName
> = V extends "_base"
  ? Pick<
      PersonMedalRequest,
      | "id"
      | "deliveryDate"
      | "delivered"
      | "description"
      | "payout"
      | "hasBonus"
      | "countMedal"
      | "amountKzt"
      | "onCommission"
      | "bonusMCI"
      | "startDate"
      | "endDate"
      | "approveDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      PersonMedalRequest,
      | "id"
      | "deliveryDate"
      | "delivered"
      | "description"
      | "payout"
      | "hasBonus"
      | "countMedal"
      | "amountKzt"
      | "onCommission"
      | "bonusMCI"
      | "startDate"
      | "endDate"
      | "approveDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<PersonMedalRequest, "id">
  : V extends "my-nominations-view"
  ? Pick<
      PersonMedalRequest,
      | "id"
      | "status"
      | "requestNumber"
      | "requestDate"
      | "awardType"
      | "personGroup"
    >
  : V extends "person-medal-request-edit-view"
  ? Pick<
      PersonMedalRequest,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "deliveryDate"
      | "delivered"
      | "description"
      | "payout"
      | "hasBonus"
      | "countMedal"
      | "amountKzt"
      | "onCommission"
      | "bonusMCI"
      | "startDate"
      | "endDate"
      | "approveDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "status"
      | "initiatorGroup"
      | "awardType"
      | "awardLevel"
      | "awardMeasure"
      | "medal"
      | "award"
      | "personGroup"
      | "attachments"
      | "personMedal"
      | "company"
      | "unit"
    >
  : V extends "personMedalRequest.edit"
  ? Pick<
      PersonMedalRequest,
      | "id"
      | "deliveryDate"
      | "delivered"
      | "description"
      | "payout"
      | "hasBonus"
      | "countMedal"
      | "amountKzt"
      | "onCommission"
      | "bonusMCI"
      | "startDate"
      | "endDate"
      | "approveDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "initiatorGroup"
      | "awardType"
      | "awardLevel"
      | "awardMeasure"
      | "award"
      | "personGroup"
      | "medal"
      | "company"
      | "unit"
      | "attachments"
      | "status"
    >
  : never;
