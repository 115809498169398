import {Moment} from "moment/moment";


export type ActivityState = {
  sortBy?: string;
  searchByName: string;
  dateFrom: Moment | null;
  dateTo: Moment | null;
  isStatusInProgress: boolean;
  isStatusCompleted: boolean;
  pageIndex: number;
}


const defaultState = {
  sortBy: "-updateTs",
  searchByName: "",
  dateFrom: null,
  dateTo: null,
  isStatusInProgress: false,
  isStatusCompleted: false,
  pageIndex: 1
};

const activityState: ActivityState = Object.assign({}, defaultState);

export function resetActivityState() {
  Object.assign(activityState, defaultState);
}
export default activityState;