import * as React from "react";
import { inject, observer } from "mobx-react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from 'react-router-dom'
import { observable } from "mobx";

import {Modal, Table, Button as AntBtn, Icon} from "antd";

import {
  collection,
  injectMainStore,
  MainStoreInjected,
  Msg,
  DataCollectionStore
} from "@cuba-platform/react";

import { SurChargeRequest } from "../../../cuba/entities/base/tsadv_SurChargeRequest";
import { Condition, SerializedEntity } from "@cuba-platform/rest";
import { SurChargeRequestManagement } from "./SurChargeRequestManagement";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import { RootStoreProp } from "../../store";
import { restServices } from "../../../cuba/services";
import moment from "moment";
import Button,{ButtonType} from "../../components/Button/Button";
import DataTableFormat from "../../components/DataTable/intex";

interface Props{
  personGroupId?: string;
  openCreateTab?():void;
  openEditTab?(entityId: string):void;
  onlyPaidRequests?: boolean;
}

type ListProps = MainStoreInjected & WrappedComponentProps & Props & RootStoreProp & RouteComponentProps;

@inject("rootStore")
@injectMainStore
@observer
class SurChargeRequestListComponent extends React.Component<ListProps> {
  dataCollection: DataCollectionStore<SurChargeRequest>;
  personGroupId: string;
  constructor(props: ListProps){
    super(props);
    this.personGroupId = props.personGroupId || props.rootStore!.userInfo!.personGroupId!;
   
    const conditions: Condition[] = [];
    if(props.personGroupId) conditions.push({
      property: "employeeName.id",
      operator: "=",
      value: this.personGroupId
    });
    if(props.onlyPaidRequests) conditions.push({
      property: "status.code",
      operator: "=",
      value: "APPROVED"
    });

    this.dataCollection = collection<SurChargeRequest>(SurChargeRequest.NAME, {
      view: "surchargerequest.view",
      sort: "-requestNumber",
      filter: conditions.length ? { conditions } : undefined
    })
  }

  @observable selectedRowKey: any;

  fields = this.props.onlyPaidRequests ? [
    "requestDate",
    "aidType",
    "aidSum"
  ] : [
    "requestNumber",
    "requestDate",
    "status",
    "aidType",
    "aidSum"
  ];

  showDeletionDialog = (e: SerializedEntity<SurChargeRequest>) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "management.browser.delete.areYouSure" },
        { instanceName: e.aidType && e.aidType["_instanceName"] }
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        this.selectedRowKey = undefined;

        return this.dataCollection.delete(e);
      }
    });
  };

  render() {

    const columns = [

    ]

    const buttons = [
      !this.props.onlyPaidRequests?
      <Link
        to={
          this.props.personGroupId ?
          SurChargeRequestManagement.PATH + "/" + SurChargeRequestManagement.NEW_SUBPATH + "/" + this.props.personGroupId :
          SurChargeRequestManagement.PATH + "/" + SurChargeRequestManagement.NEW_SUBPATH
        }
        key="create">
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}>
          <span>
            <FormattedMessage id="createRequest"/>
          </span>
        </Button>
      </Link>:
      <Link
        to={SurChargeRequestManagement.PATH + "/" + this.selectedRowKey}
        key="edit"
      >
        <Button
          buttonType={ButtonType.PRIMARY}
          style={{ margin: "0 12px 12px 0" }}
          disabled={!this.selectedRowKey}
          type="default"
        >
          <FormattedMessage id="open" />
        </Button>
      </Link>
    ];

    const selectRow = (record:any) => {
      this.selectedRowKey = record.id;
    }
    return (
      <div>
        {/*<DataTableFormat*/}
        {/*  dataCollection={this.dataCollection}*/}
        {/*  fields={this.fields}*/}
        {/*  onRowSelectionChange={this.handleRowSelectionChange}*/}
        {/*  hideSelectionColumn={true}*/}
        {/*/>*/}
        <div >
          {buttons}
        </div>
        { this.props.onlyPaidRequests?
          <Table
            scroll={{x: true}}
            bordered={true}
            className={"tableSelectCheckboxOverride"}
            onRow={(record) => ({
              onClick: () => {
                selectRow(record);
              },
            })}
            rowSelection={{
              selectedRowKeys:this.selectedRowKey,

            }
            }
            //onRowSelectionChange={this.handleRowSelectionChange}
            dataSource={this.dataCollection.items}
            rowKey={record => record.id}
            loading={this.dataCollection.status === "LOADING"}
            columns={[
              {
                title: this.props.intl.formatMessage({id: "requestDate"}),
                dataIndex: "requestDate",
                render: (text, record) => moment(record.requestDate!).format('L'),
                sorter: (a, b) => {
                  const aDate = moment(a.requestDate!)
                  const bDate = moment(b.requestDate!)
                  return bDate.diff(aDate)
                }
              },
              {
                title: <Msg entityName={SurChargeRequest.NAME} propertyName={"aidType"}/>,
                dataIndex: "aidType",
                render: (text, record: SurChargeRequest) =>record.aidType && record.aidType['_instanceName'],
                sorter:(a,b)=>{
                 return  a.aidType && a.aidType['_instanceName']!.localeCompare(b.aidType && b.aidType['_instanceName']!)
                }
              },
              {
                title: <Msg entityName={SurChargeRequest.NAME} propertyName={"aidSum"}/>,
                dataIndex: "aidSum",
                sorter: (a, b) => a.aidSum - b.aidSum
              }

            ]}
          />:
          <Table
            scroll={{x: true}}
            dataSource={this.dataCollection.items}
            rowKey={record => record.id}
            loading={this.dataCollection.status === "LOADING"}
            columns={[
              {
                title: this.props.intl.formatMessage({id: "requestNumber"}),
                dataIndex: "requestNumber",
                render: (text, record: SurChargeRequest) => <Link
                  to={SurChargeRequestManagement.PATH + "/" + record.id}
                >{text}
                </Link>,
                sorter: (a, b) => a.requestNumber - b.requestNumber
              },
              {
                title: this.props.intl.formatMessage({id: "requestDate"}),
                dataIndex: "requestDate",
                render: (text, record) => moment(record.requestDate!).format('L'),
                sorter: (a, b) => {
                  const aDate = moment(a.requestDate!)
                  const bDate = moment(b.requestDate!)
                  return bDate.diff(aDate)
                }
              },
              {
                title: this.props.intl.formatMessage({id: "status"}),
                dataIndex: "status",
                render: (text, record) => record.status ? record.status!['_instanceName'] : "",
                filters: [
                  {
                    text: <FormattedMessage id={"status.Draft"}/>,
                    value: this.props.intl.formatMessage({id: "status.Draft"})
                  },
                  {
                    text: <FormattedMessage id={"status.OnApproval"}/>,
                    value: this.props.intl.formatMessage({id: "status.OnApproval"})
                  },
                  {
                    text: <FormattedMessage id={"status.Approved"}/>,
                    value: this.props.intl.formatMessage({id: "status.Approved"})
                  },
                  {
                    text: <FormattedMessage id={"status.ToBeRevised"}/>,
                    value: this.props.intl.formatMessage({id: "status.ToBeRevised"})
                  },
                  {
                    text: <FormattedMessage id={"status.Rejected"}/>,
                    value: this.props.intl.formatMessage({id: "status.Rejected"})
                  }],

                onFilter: (value, record) => record.status!["_instanceName"].startsWith(value)
              },
              {
                title: <Msg entityName={SurChargeRequest.NAME} propertyName={"aidType"}/>,
                dataIndex: "aidType",
                render: (text, record: SurChargeRequest) =>record.aidType && record.aidType['_instanceName'],
                sorter:(a,b)=>{
                  return  a.aidType && a.aidType['_instanceName']!.localeCompare(b.aidType && b.aidType['_instanceName']!)
                }
              },
              {
                title: <Msg entityName={SurChargeRequest.NAME} propertyName={"aidSum"}/>,
                dataIndex: "aidSum",
                sorter: (a, b) => a.aidSum - b.aidSum
              },
              {
                title: "",
                render: (text, record) => {
                  if (record.status!.code === "DRAFT") {
                    return <AntBtn onClick={() => {
                      this.showDeletionDialog(record)
                    }
                    }><Icon type='delete'/></AntBtn>
                  }
                  return <AntBtn disabled={true}><Icon type='delete'/></AntBtn>
                }
              }

            ]}
          />
        }


      </div>
    );
  }


  
  // setToRevision = () =>{
  //   if(!this.selectedRowKey) return;
  //   restServices.surChargeRequestService.revision( { entityId: this.getRecordById( this.selectedRowKey ).id } );
  // }



  getRecordById(id: string): SerializedEntity<SurChargeRequest> {
    const record:
      | SerializedEntity<SurChargeRequest>
      | undefined = this.dataCollection.items.find(record => record.id === id);

    if (!record) {
      throw new Error("Cannot find entity with id " + id);
    }

    return record;
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const SurChargeRequestList = withRouter(injectIntl(SurChargeRequestListComponent));

export default SurChargeRequestList;
