import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { DicDisabilityType } from "./tsadv$DicDisabilityType";
import { DicDuration } from "./tsadv$DicDuration";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { FileDescriptor } from "./sys$FileDescriptor";
import { Disability } from "./tsadv$Disability";
export class DisabilityRequest extends AbstractBprocRequest {
  static NAME = "tsadv_DisabilityRequest";
  disabilityType?: DicDisabilityType | null;
  attachmentName?: string | null;
  attachment?: any | null;
  duration?: DicDuration | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  personGroupExt?: PersonGroupExt | null;
  group?: string | null;
  file?: FileDescriptor | null;
  disability?: Disability | null;
  attachments?: FileDescriptor[] | null;
  hasDisability?: boolean | null;
}
export type DisabilityRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "disabilityRequest.edit";
export type DisabilityRequestView<
  V extends DisabilityRequestViewName
> = V extends "_base"
  ? Pick<
      DisabilityRequest,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "attachmentName"
      | "attachment"
      | "dateFrom"
      | "dateTo"
      | "group"
      | "hasDisability"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      DisabilityRequest,
      | "id"
      | "attachmentName"
      | "attachment"
      | "dateFrom"
      | "dateTo"
      | "group"
      | "hasDisability"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<DisabilityRequest, "id" | "requestNumber" | "requestDate">
  : V extends "disabilityRequest.edit"
  ? Pick<
      DisabilityRequest,
      | "id"
      | "attachmentName"
      | "attachment"
      | "dateFrom"
      | "dateTo"
      | "group"
      | "hasDisability"
      | "legacyId"
      | "attachments"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "disabilityType"
      | "personGroupExt"
      | "file"
      | "disability"
      | "status"

    >
  : never;
