import React from "react";
import {Button, List, Popover} from "antd";
import {observable} from "mobx";
import {restServices} from "../../../../cuba/services";
import {observer} from "mobx-react";
import PersonLink from "./PersonLink";
import {PersonPojo} from "../../../../cuba/entities/base/tsadv$PersonPojo";
import {PersonGroupExt} from "../../../../cuba/entities/base/base$PersonGroupExt";
import {injectIntl, WrappedComponentProps} from "react-intl";

type ListLikedPopOverProps = {
  likeCount: number,
  recognitionId: string
}

@observer
class ListLikedPopOver extends React.Component<ListLikedPopOverProps & WrappedComponentProps> {
  @observable likedPersons?: PersonGroupExt[];

  loadContent = async (visible: boolean) => {
    if (visible && this.likedPersons === undefined) {
      this.likedPersons = await restServices.recognitionService.getLikedPersons(this.props.recognitionId, "personGroup.person.info");
    }
  }

  renderItem = (personGroup: PersonGroupExt) => {
    const person: PersonPojo = {
      personGroupId: personGroup.id,
      name: personGroup.fullName
    }

    return <List.Item>
      <PersonLink person={person} />
    </List.Item>
  }

  renderContent = () => {
    return <List dataSource={this.likedPersons}
                 loading={this.likedPersons === undefined}
                 renderItem={this.renderItem}/>
  }

  render() {
    const content = this.renderContent();

    return <>
      <Popover content={content} title={this.props.intl.messages["recognition.like"]} trigger={"click"} onVisibleChange={this.loadContent}>
        <Button type={"link"}>{this.props.likeCount}</Button>
      </Popover>
    </>
  }
}

export default injectIntl(ListLikedPopOver);
