import * as React from "react";
import { FormEvent } from "react";
import { Alert, Card, Col, Form, Icon, Input, message, Modal, Row, Select, Spin, Tabs } from "antd";
import { inject, observer } from "mobx-react";
import { FormComponentProps } from "antd/lib/form";
import {action, IReactionDisposer, observable, reaction, toJS, when} from "mobx";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  instance,
  withLocalizedForm,
  extractServerValidationErrors,
  constructFieldsWithErrors,
  clearFieldErrors,
  MultilineText,
  injectMainStore,
  MainStoreInjected,
  Msg,
} from "@cuba-platform/react";

import "../../../../app/App.css";

import { AdaptationPlan } from "../../../../cuba/entities/base/tsadv_AdaptationPlan";
import AbstractBprocEdit from "../../Bproc/abstract/AbstractBprocEdit";
import LoadingPage from "../../LoadingPage";
import Button, { ButtonType } from "../../../components/Button/Button";
import { ReadonlyField } from "../../../components/ReadonlyField";
import AdaptationTasksTable from "../AdaptationTask/AdaptationTasksTable";
import { goBackOrHomePage, saveFile } from "../../../util/util";
import { parseToJsonFromFieldValue } from "../../../components/MultiFileUpload";
import { withRouter } from "react-router-dom";
import { restServices } from '../../../../cuba/services';
import { runReport } from "../../../util/reportUtil";
import Notification from "../../../util/Notification/Notification";
import MyCompetentionTable from "../MyCompetentionTable";
import {IndividualDevelopmentPlan} from "../../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";

const { Option } = Select;

type Props = FormComponentProps & EditorProps;

type EditorProps = WrappedComponentProps & MainStoreInjected & {
  entityId: string;
  setEntityId?: (entityId: string) => void;
  showCompetentionTable?: boolean;
};

@inject("rootStore")
@injectMainStore
@observer
class AdaptationPlanEditComponent extends AbstractBprocEdit<AdaptationPlan, Props> {
  processDefinitionKey = "AdaptationPlanRequest";
  dataInstance = instance<AdaptationPlan>(AdaptationPlan.NAME, {
    view: "adaptationPlan",
    loadImmediately: false
  });

  reactionDisposer: IReactionDisposer;

  fields = [
    "planNameLangRu",
    "planNameLangEn",
    "planNameLangKz",
    "adaptationStatusRef",
    "dateFrom",
    "dateTo",
    "employeePassedProbationaryEventsRef",
    "employeePassedProbationPeriodRef",
    "comment",
  ];

  @observable
  globalErrors: string[] = [];

  @observable
  loaded = false;

  isUpdateBeforeOutcome = true;

  @observable
  isLocaleRu = this.props.rootStore!.userInfo.locale === 'ru';

  @observable
  isLocaleKz = this.props.rootStore!.userInfo.locale === 'kz';

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  planInstructions: string = "";

  @observable
  isOuctomeButtonsVisible: boolean = true;

  @observable
  daysBeforeDateTo: number = 0;

  @observable
  isModalOpen = false;

  @observable
  showCheckboxes: boolean = !!(this.dataInstance.item && this.dataInstance.item.isApprovedPlan) && this.daysBeforeDateTo < 14;

  @action handleSave = () => {
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        return;
      }

      const newData = this.props.form.getFieldsValue();
      if(this.showCheckboxes){
      newData.employeePassedProbationaryEvents = !!newData.employeePassedProbationaryEventsRef
      newData.employeePassedProbationPeriod = !!newData.employeePassedProbationPeriodRef
      }
      else{
        newData.employeePassedProbationaryEvents = null
        newData.employeePassedProbationPeriod = null
      }
      this.dataInstance
        .update(newData)
        .then(() => {
          message.success(
            this.props.intl.formatMessage({ id: "management.editor.success" })
          );
          const oneDayInMilliseconds = 86400000;
          const dateTo = new Date(this.dataInstance.item!.dateTo);
          this.daysBeforeDateTo = (dateTo.getTime() - Date.now())/oneDayInMilliseconds;
          this.showCheckboxes = !!(this.dataInstance.item && this.dataInstance.item.isApprovedPlan) && this.daysBeforeDateTo < 14;
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.error"
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({ id: "management.editor.error" })
            );
          }
        });
    });
  }
  handleEnd = (e?: any) => {
    if(e) e.stopPropagation();
    if (this.props.setEntityId) this.props.setEntityId("")
    else goBackOrHomePage(this.props.history);
  }


  getUpdateEntityData(): any {
    const obj = {
      ...this.props.form.getFieldsValue(this.fields),
    };

    const metaClass = this.props.mainStore!.metadata!.find(mci => mci.entityName === this.dataInstance.entityName);
    if (metaClass) {
      metaClass.properties
        .filter(value => value.type === 'sys$FileDescriptor')
        .filter(value => value.cardinality === "ONE_TO_MANY" || value.cardinality === "MANY_TO_MANY")
        .filter(value => this.fields.find(field => field === value.name))
        .forEach(value => {
          const files = obj[value.name];
          if (files)
            obj[value.name] = parseToJsonFromFieldValue(files);
        })
    }
      if(this.showCheckboxes) {
        obj.employeePassedProbationaryEvents = !!obj.employeePassedProbationaryEventsRef
        obj.employeePassedProbationPeriod = !!obj.employeePassedProbationPeriodRef
      }
      else{
        obj.employeePassedProbationaryEvents = null
        obj.employeePassedProbationPeriod = null
      }

    return obj;
  };

  validate = (): Promise<boolean> => {
    let isValidatedSuccess = true;
    this.props.form.validateFields(this.fields, {force: true}, (err, values) => {
      isValidatedSuccess = !err;
      if (err) {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.validationError"})
        });
      }
    });
    this.handleSave()
    return new Promise(resolve => resolve(isValidatedSuccess));
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.handleSave();
  };

  renderEditNameModal = ()=>{
    return (
      <Modal
        title={"Редактировать название адаптации"}
        visible={this.isModalOpen}
        onOk={()=>this.isModalOpen = false}
        onCancel={()=>this.isModalOpen = false}
        footer={[]}
      >
        <Card onSubmit={e=>e.stopPropagation()} className="narrow-layout" bordered={false}>
          <ReadonlyField
            entityName={AdaptationPlan.NAME}
            propertyName="planNameLangRu"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <span><Msg entityName={AdaptationPlan.NAME} propertyName="planName"/> Ru</span>
            }}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />
          <ReadonlyField
            entityName={AdaptationPlan.NAME}
            propertyName="planNameLangKz"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <span><Msg entityName={AdaptationPlan.NAME} propertyName="planName"/> Kz</span>
            }}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />
          <ReadonlyField
            entityName={AdaptationPlan.NAME}
            propertyName="planNameLangEn"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <span><Msg entityName={AdaptationPlan.NAME} propertyName="planName"/> En</span>
            }}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />
          <Row justify="center">
            <Button onClick={()=>this.isModalOpen=false} htmlType="button" type="primary">
              <FormattedMessage id="save"/>
            </Button>
          </Row>
        </Card>
      </Modal>
    )
  }

  renderFormFields = () => {
    const messages = this.props.mainStore!.messages!;
    return (
      <>
        <ReadonlyField
          disabled
          entityName={AdaptationPlan.NAME}
          propertyName="status"
          fieldDecoratorId="adaptationStatusRef"
          form={this.props.form}
          formItemOpts={{
            style: { marginBottom: "12px" },
          }}
          // optionsContainer={this.statussDc}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
            }]
          }}
        />
        <Form.Item
          style={this.isLocaleRu ? { marginBottom: "12px" } : {display: 'none'}}
          label={<Msg entityName={AdaptationPlan.NAME} propertyName="planName"/>}
        >
          {
            this.props.form.getFieldDecorator(
              'planNameLangRu',
              {}
            )(
              <Input
                disabled
                addonAfter={ !this.isNotDraft() &&
                <Icon
                  onClick={()=>this.isModalOpen=true}
                  type="edit"
                  style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                />
                }
              />
            )
          }
        </Form.Item>
        <Form.Item
          style={this.isLocaleEn ? { marginBottom: "12px" } : {display: 'none'}}
          label={<Msg entityName={AdaptationPlan.NAME} propertyName="planName"/>}
        >
          {
            this.props.form.getFieldDecorator(
              'planNameLangEn',
              {}
            )(
              <Input
                disabled
                addonAfter={ !this.isNotDraft() &&
                <Icon
                  onClick={()=>this.isModalOpen=true}
                  type="edit"
                  style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                />
                }
              />
            )
          }
        </Form.Item>
        <Form.Item
          style={this.isLocaleKz ? { marginBottom: "12px" } : {display: 'none'}}
          label={<Msg entityName={AdaptationPlan.NAME} propertyName="planName"/>}
        >
          {
            this.props.form.getFieldDecorator(
              'planNameLangKz',
              {}
            )(
              <Input
                disabled
                addonAfter={ !this.isNotDraft() &&
                <Icon
                  onClick={()=>this.isModalOpen=true}
                  type="edit"
                  style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer' }}
                />
                }
              />
            )
          }
        </Form.Item>

        <ReadonlyField
          disabled={this.isNotDraft()}
          entityName={AdaptationPlan.NAME}
          propertyName="dateFrom"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{
            rules: [{ required: true }]
          }}
        />
        <ReadonlyField
          disabled={this.isNotDraft()}
          entityName={AdaptationPlan.NAME}
          propertyName="dateTo"
          form={this.props.form}
          formItemOpts={{ style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{
            rules: [{ required: true }]
          }}
        />
        {this.renderDownloadDocsButtons()}
        <br/>
        <br/>
        {this.renderAdaptationTasks()}
        {this.showCheckboxes ?
          <>
          <Form.Item
            labelCol={{span: 12}}
            wrapperCol={{span: 12}}
            style={{marginBottom: "12px"}}
            label={<Msg entityName={AdaptationPlan.NAME} propertyName="employeePassedProbationaryEvents"/>}
          >
            {this.props.form.getFieldDecorator('employeePassedProbationaryEventsRef', {
              initialValue: undefined,
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[AdaptationPlan.NAME + '.' + '.employeePassedProbationaryEventsRef']})
              }]
            })(
              <Select disabled={this.isNotDraft()} style={{width: 70}}>
                <Option value={1}>{this.isLocaleEn ? "Yes" : "Да"}</Option>
                <Option value={0}>{this.isLocaleEn ? "No" : "Нет"}</Option>
              </Select>
            )}
          </Form.Item>

        <Form.Item
          labelCol={{span: 12}}
          wrapperCol={{span: 12}}
          style={{ marginBottom: "12px"}}
          label={<Msg entityName={AdaptationPlan.NAME} propertyName="employeePassedProbationPeriod"/>}
        >
          {this.props.form.getFieldDecorator('employeePassedProbationPeriodRef', {
            initialValue: undefined,
            rules:[{
              required:true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[AdaptationPlan.NAME + '.' + '.employeePassedProbationPeriodRef']})
            }]
          })(
            <Select disabled={this.isNotDraft()} style={{ width: 70 }}>
              <Option value={1}>{this.isLocaleEn ? "Yes" : "Да"}</Option>
              <Option value={0}>{this.isLocaleEn ? "No" : "Нет"}</Option>
            </Select>
          )}
        </Form.Item>
          </>
          :
          <></>
        }

        <Form.Item
          style={{ marginBottom: "12px" }}
          label={<Msg entityName={AdaptationPlan.NAME} propertyName="comment"/>}
        >
          {this.props.form.getFieldDecorator('comment', {
          })(<Input.TextArea disabled={this.isNotDraft()} />)}
        </Form.Item>
      </>
    )
  }

  handleDownloadJobInstructions = async () => {
    try{
      const jobInstructions = await restServices.adaptationPlanRequestService
        .getJobInstructions(this.dataInstance.item!.personGroup!.id!);

      if(jobInstructions){
        const blob = await restServices.fileDownload.download(jobInstructions.id);
        saveFile(blob, jobInstructions.name || "jobInstructions.txt");
      }
    }catch(e){
      Notification.error({
        message: this.props.intl.formatMessage({
          id: 'adaptationPlan.jobInstructions.NotFound',
          defaultMessage: 'File JobInstructions Not Found!',
        }),
      });
    }
  }

  reportEmployeeProfile = async () => {
    try{
      const personProfile = await restServices.employeeService.personProfile(this.dataInstance.item!.personGroup!.id);
      const data = {
        parameters: [{
          name: "person",
          value: personProfile.id
        }]
      };
      runReport('employee_profile_ru', data, this.props.intl);
    }catch(e){
      Notification.error({
        message: this.props.intl.formatMessage({
          id: 'adaptationPlan.employee_profile.NotFound',
          defaultMessage: 'Employee Profile Not Found!',
        }),
      });
    }
  }

  reportProbationTasksPlan = () => {
    try{
      const data = {
        parameters: [{
          name: "id",
          value: this.dataInstance.item!.id
        }]
      };
      runReport('ADAPTATION_PLAN', data, this.props.intl);
    }catch(e){
      Notification.error({
        message: this.props.intl.formatMessage({
          id: 'adaptationPlan.downloadProbationTasksPlan.NotFound',
          defaultMessage: 'Probationary task plan not found!',
        }),
      });
    }
  }

  renderDownloadDocsButtons = ()=>{
    return (
      <Row gutter={20} type="flex" justify="start">
        <Col>
          <Button onClick={this.reportProbationTasksPlan} type={ButtonType.PRIMARY}>
            <FormattedMessage id="adaptationPlan.downloadProbationTasksPlan" defaultMessage="Download probationary task plan"/>
          </Button>
        </Col>
        <Col>
          <Button onClick={this.reportEmployeeProfile} type={ButtonType.PRIMARY}>
            <FormattedMessage id="adaptationPlan.employeeProfile" defaultMessage="Employee profile"/>
          </Button>
        </Col>
        <Col>
          <Button onClick={this.handleDownloadJobInstructions} type={ButtonType.PRIMARY}>
            <FormattedMessage id="adaptationPlan.jobDescriptions" defaultMessage="Job descriptions" />
          </Button>
        </Col>
      </Row>
    )
  }

  renderAdaptationTasks = () => {
    return (
      <div style={{
        border: "1px solid #adadad",
        padding: "1rem",
        position: "relative",
        marginBottom: "2rem",
      }}>
        <p style={{
          position: "absolute",
          top: 0,
          transform: "translateY(-50%)",
          background: "#FFF",
          padding: "0 1rem",
        }}>
          <FormattedMessage id="adaptationPlan.tasks" defaultMessage="Adaptation tasks" />
        </p>
        {!this.isNotDraft() &&
        <p style={{ color: '#a7a700', textAlign: 'end', whiteSpace: 'normal' }}>
          {this.planInstructions}
        </p>
        }
        <AdaptationTasksTable readonly={this.isNotDraft()} adaptationPlanId={this.props.entityId} />
      </div>
    )
  }

  renderActions = () => {
    const actions = [];

    actions.push(
      <Button
        buttonType={ButtonType.FOLLOW}
        onClick={this.handleEnd}
      >
        {this.props.intl.formatMessage({ id: "close" })}
      </Button>
    );

    if (!this.isNotDraft()) {
      actions.push(
        <Button
          buttonType={ButtonType.FOLLOW}
          htmlType="submit"
        >
          {this.props.intl.formatMessage({ id: "save" })}
        </Button>
      );
    }

    if(this.isOuctomeButtonsVisible){
      actions.push(this.getOutcomeBtns());
    }
    return actions;
  }

  renderEditAdaptationPlanForm = () => (
    <Form onSubmit={this.handleSubmit} layout="vertical">
      <Card
        className="narrow-layout card-actions-container"
        bordered={false}
        actions={this.renderActions()}
        style={{
          padding: '16px',
        }}
      >
        <Spin spinning={!this.loaded}>
          {this.renderEditNameModal()}
          {this.renderFormFields()}
          {this.getStatusCode() !== 'DRAFT' && this.takCard()}
          {this.globalErrors.length > 0 && (
            <Alert
              message={<MultilineText lines={toJS(this.globalErrors)} />}
              type="error"
              style={{ marginBottom: "24px" }}
            />
          )}
        </Spin>
      </Card>
    </Form>
  )

  render() {
    if (!this.mainStore) {
      return <LoadingPage />
    }

    const messages = this.mainStore.messages!;
    if (!messages && this.dataInstance.status !== 'DONE') {
      return <LoadingPage />
    }

    if(this.props.showCompetentionTable && this.isNotDraft() && this.dataInstance.item && this.dataInstance.item.isApprovedPlan) return (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<FormattedMessage id="tsadv_AdaptationPlan"/>} key="1">
          {this.renderEditAdaptationPlanForm()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={<FormattedMessage id="myСompetention.competence-assessment"/>} key="2">
          <MyCompetentionTable personGroupId={this.dataInstance.item!.personGroup!.id!}/>
        </Tabs.TabPane>
      </Tabs>
    );

    return this.renderEditAdaptationPlanForm();
  }
  afterSendOnApprove = () => this.handleEnd()

  onReactionDisposerEffect = (item:AdaptationPlan|undefined) => {
    console.log(item);
    
  }

  componentDidMount(){
    super.componentDidMount();
    when(()=>!!this.dataInstance.item&&this.showCheckboxes&&!!this.props.form.getFieldDecorator('employeePassedProbationaryEventsRef')&&!!this.props.form.getFieldDecorator('employeePassedProbationPeriodRef'),()=>{
      if(this.showCheckboxes){
        this.props.form.setFieldsValue({
          employeePassedProbationaryEventsRef: this.dataInstance.item!.employeePassedProbationaryEvents===true ? 1 : this.dataInstance.item!.employeePassedProbationaryEvents===false ? 0 : undefined,
          employeePassedProbationPeriodRef: this.dataInstance.item!.employeePassedProbationPeriod===true ? 1 : this.dataInstance.item!.employeePassedProbationPeriod===false ? 0 : undefined,
        })
      }
    })  
  
  }

  setReactionDisposer = () => {
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
       (item: AdaptationPlan) => {
        this.onReactionDisposerEffect(item);
        this.planInstructions = item.adaptationSetting ?
          this.isLocaleRu ?
            item.adaptationSetting.instructionLang1 || ""
            : this.isLocaleKz ?
              item.adaptationSetting.instructionLang2 || ""
              : item.adaptationSetting.instructionLang3 || ""
          : "";

        const statusCode = item.adaptationStatus && (item.adaptationStatus ==="FULL_APPROVED" || (item.status && item.status.code === "DRAFT")) ?
          this.props.intl.formatMessage({ id: `statusCode.${item.adaptationStatus}` })
          : this.isLocaleEn ?
            item.status!.langValue3
            : item.status!.langValue1;


        
        const oneDayInMilliseconds = 86400000;
        const dateTo = new Date(item.dateTo);

        this.daysBeforeDateTo = (dateTo.getTime() - Date.now())/oneDayInMilliseconds;
        this.isOuctomeButtonsVisible = !!this.isNotDraft() || !item.isApprovedPlan || this.daysBeforeDateTo < 14;
        this.showCheckboxes = !!(this.dataInstance.item && this.dataInstance.item.isApprovedPlan) && this.daysBeforeDateTo < 14;

        this.loaded = true;
        this.props.form.setFieldsValue({
          ...this.onReactionFieldsValue(item),
          adaptationStatusRef: statusCode
        })
     
      }
    );
  };
}



export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(AdaptationPlanEditComponent))
);
