import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {observer} from "mobx-react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {restServices} from "../../../../../cuba/services";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {generateDivId} from "../../chartUtils";

type ChartPageProps = {
  personGroupId: string
}

@observer
class RecognitionQualityChart extends React.Component<ChartPageProps & WrappedComponentProps> {

  chartDivId = generateDivId();
  chart: am4charts.XYChart;

  loadData = async () => {
    return await restServices.recognitionChartService.loadQualities(this.props.personGroupId);

  }

  componentDidMount() {
    this.init();
  }

  init = async () => {
    this.chart = this.makeChart();
    this.chart.data = await this.loadData();
  }

  makeChart = () => {
    am4core.useTheme(am4themes_animated);

    const chart = am4core.create(this.chartDivId, am4charts.XYChart);

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "qualityName";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    const series = chart.series.push(new am4charts.ColumnSeries());

    series.dataFields.valueY = "qualityCount";
    series.dataFields.categoryX = "qualityName";
    series.name = "qualityCount";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = .8;

    const columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;

    return chart;
  }

  componentWillUnmount() {
    this.chart && this.chart.dispose();
  }

  render() {

    return <>
      <div id={this.chartDivId} style={{height: 300}}/>
    </>
  }
}

export default injectIntl(RecognitionQualityChart);