import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Col, Row} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import ShopCategories from "../Components/ShopCategories";
import RecognitionElementList from "../Components/RecognitionElementList";


@inject("rootStore")
@injectMainStore
@observer
class ItemsPage extends React.Component<RootStoreProp & WrappedComponentProps> {

  render() {
    return (
      <Row>
        <Col xs={5}>
          <ShopCategories/>
        </Col>
        <Col xs={19}>
          <RecognitionElementList/>
        </Col>
      </Row>
    )
  }
}

export default injectIntl(ItemsPage);