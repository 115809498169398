import { AbstractParentEntity } from "./AbstractParentEntity";
import { IndividualDevelopmentPlan } from "./tsadv$IndividualDevelopmentPlan";
import { CompetenceGroup } from "./tsadv$CompetenceGroup";
import { ScaleLevel } from "./tsadv$ScaleLevel";
import { Course } from "./tsadv$Course";
import { DicEducationType } from "./tsadv$DicEducationType";
import { Goal } from "./tsadv$Goal";
import { DicIdpActionType } from "./tsadv_DicIdpActionType";
import { PersonGroupExt } from "./base$PersonGroupExt";
import {PartyExt} from "./base$PartyExt";
export class IdpDetail extends AbstractParentEntity {
  static NAME = "tsadv$IdpDetail";
  idp?: IndividualDevelopmentPlan | null;
  competence?: CompetenceGroup | null;
  scaleLevel?: ScaleLevel | null;
  course?: Course | null;
  educationType?: DicEducationType | null;
  description?: string | null;
  targetDate?: any | null;
  done?: boolean | null;
  comment?: string | null;
  reason?: string | null;
  goal?: Goal | null;
  actionType?: DicIdpActionType | null;
  actualDate?: any | null;
  goalName?: string | null;
  personHelp?: PersonGroupExt | null;
  priceKzt?: any | null;
  travelExpensesKzt?: any | null;
  party?: PartyExt | null;
  courseAddress?: string | null;
  numberOfDay?: any | null;
  numberOfHour?: any | null;
}
export type IdpDetailViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "idpDetail-full"
  | "idpDetail.edit";
export type IdpDetailView<V extends IdpDetailViewName> = V extends "_base"
  ? Pick<
      IdpDetail,
      | "id"
      | "description"
      | "targetDate"
      | "done"
      | "comment"
      | "reason"
      | "actualDate"
      | "goalName"
      | "priceKzt"
      | "travelExpensesKzt"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      IdpDetail,
      | "id"
      | "description"
      | "targetDate"
      | "done"
      | "comment"
      | "reason"
      | "actualDate"
      | "goalName"
      | "priceKzt"
      | "travelExpensesKzt"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "idpDetail-full"
  ? Pick<
      IdpDetail,
      | "id"
      | "description"
      | "targetDate"
      | "done"
      | "comment"
      | "reason"
      | "actualDate"
      | "goalName"
      | "priceKzt"
      | "travelExpensesKzt"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "idp"
      | "competence"
      | "scaleLevel"
      | "course"
      | "educationType"
      | "goal"
      | "actionType"
    >
  : V extends "idpDetail.edit"
  ? Pick<
      IdpDetail,
      | "id"
      | "description"
      | "targetDate"
      | "done"
      | "comment"
      | "reason"
      | "actualDate"
      | "goalName"
      | "priceKzt"
      | "travelExpensesKzt"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "competence"
      | "scaleLevel"
      | "course"
      | "educationType"
    >
  : never;
