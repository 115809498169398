import React from 'react';
import {getCubaREST, injectMainStore} from "@cuba-platform/react";
import {action, observable, runInAction} from "mobx";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import moment from "moment";
import {Select, Spin} from "antd";
import {observer} from "mobx-react";

type Props = {
  changeValue:(value:string) => void,
  companyCode:string,
  selectValue?:string
}

@injectMainStore
@observer
export default class SearchPerson extends React.Component<Props> {

  @observable loading:boolean = false;

  @observable persons:PersonExt[]

  @action changePersons (value:PersonExt[]){
    this.persons = value
  }

  @action unitePersons (value:PersonExt[]){
    if(this.persons)
    this.persons = [...this.persons,...value]
    else {
      this.persons = [];
      this.changePersons(value)
    }
  }

  @action changeLoading(value:boolean){
    this.loading = value
  }

  @observable personId: string;

  @action changePersonId (value:string){
    this.personId = value
  }

  @observable timer:NodeJS.Timeout;

  @action changeSearchValue (value:string) {
    clearTimeout(this.timer);
    value = value.replace(/\s+/g, " ");
    const splitVal = value.split(" ");
    const lastName = splitVal[0];
    const firstName = splitVal&&splitVal[1]?splitVal[1]:""
    const middleName = splitVal&&splitVal[2]?splitVal[2]:""
    const isCyrillic = /[а-яА-ЯЁё]/.test(value);
    this.changeLoading(true)
    if(value.length<1){
      this.changePersons([])
      this.changeLoading(false)
      return
    }
    const search = () => {
      const lastNameFirst = getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
          conditions: [
            {group:"AND",
              conditions:[
                {
                  property: isCyrillic?"lastName":"lastNameLatin",
                  operator: "contains",
                  value: lastName,
                },
                {
                  property: isCyrillic?"firstName":"firstNameLatin",
                  operator: "contains",
                  value: firstName,
                },

                {
                  property: isCyrillic?"middleName":"middleNameLatin",
                  operator: "contains",
                  value: middleName,
                }
              ]
            },
            {
              property: "startDate",
              operator: "<=",
              value: moment().toISOString()
            },
            {
              property: "endDate",
              operator: ">=",
              value: moment().toISOString()
            }
          ]
        },
        {
          view: "person.forSearch",
          limit: 15
        })
      const firstNameFirst = getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
          conditions: [
            {group:"AND",
              conditions:[
                {
                  property: isCyrillic?"lastName":"lastNameLatin",
                  operator: "contains",
                  value: firstName,
                },
                {
                  property: isCyrillic?"firstName":"firstNameLatin",
                  operator: "contains",
                  value: lastName,
                },

                {
                  property: isCyrillic?"middleName":"middleNameLatin",
                  operator: "contains",
                  value: middleName,
                }
              ]
            },
            {
              property: "startDate",
              operator: "<=",
              value: moment().toISOString()
            },
            {
              property: "endDate",
              operator: ">=",
              value: moment().toISOString()
            }
          ]
        },
        {
          view: "person.forSearch",
          limit: 15
        })
      Promise.all([lastNameFirst,firstNameFirst]).then(items => {
        this.changePersons([])
        const result = [...items[0],...items[1]]
        this.changePersons(result)
        this.changeLoading(false);
      })
    }
    const newTimer = setTimeout(()=>runInAction(()=>{
      search();
    }),500)
    runInAction(()=>this.timer = newTimer)
  }
  render() {
  const  {Option} = Select
    return (
      <Select onChange={(e: string) =>this.props.changeValue(e)} filterOption={false}
              notFoundContent={this.loading ? <Spin size="small"/> : null} showSearch
              value={this.props.selectValue?this.props.selectValue:undefined}
              onSearch={(e) => {
                this.changeSearchValue(e);
              }}>
        {
          this.persons && this.persons.length > 0 && this.persons.map(value =>
            <Option
              key={value.id}
              value={value.group && value.group.id}>{value['_instanceName'] + ` (${value.employeeNumber}) (${value.group&&value.group.company&&value.group.company['_instanceName']})`}
            </Option>)
        }
      </Select>
    );
  }
  componentDidMount() {
      getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
          conditions: [
            {
              property: "startDate",
              operator: "<=",
              value: moment().toISOString()
            },
            {
              property: "endDate",
              operator: ">=",
              value: moment().toISOString()
            },
            {
              property: "group.company.code",
              operator: "=",
              value: this.props.companyCode
            },
            {
              property:"group.id",
              operator:"<>",
              value:this.props.selectValue?this.props.selectValue:""
            }
          ]
        },
        {
          view: "person.forSearch",
          limit: 30
        }).then(items => {
        this.unitePersons(items);
      })
    if(this.props.selectValue){
      getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME,{
          conditions:[{
            property:"group.id",
            operator:"=",
            value:this.props.selectValue
          }]
        },
        {view:"person.forSearch"}).then(items=>{
        runInAction(()=>{this.unitePersons(items)})
      })}
  }

}