import React, {createElement} from "react";
import {inject, observer} from "mobx-react";
import {
  collection,
  FileUpload,
  getCubaREST,
  getEnumCaption,
  getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Button as AntdButton, Card, Checkbox, Form, Input, Modal, Row, Select, Spin, Table, Tooltip} from "antd";
import Column from "antd/es/table/Column";
import {action, observable, runInAction} from "mobx";
import TextArea from "antd/es/input/TextArea";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentDots, faEdit} from "@fortawesome/free-solid-svg-icons";
import {FormComponentProps} from "antd/lib/form";
import {DicAssessmentStatus} from "../../../cuba/entities/base/tsadv$DicAssessmentStatus";
import {restServices} from "../../../cuba/services";
import {AssessmentParticipant} from "../../../cuba/entities/base/tsadv$AssessmentParticipant";
import {Assessment} from "../../../cuba/entities/base/tsadv$Assessment";
import {RootStoreProp} from "../../store";
import {AssessmentDetail} from "../../../cuba/entities/base/tsadv_AssessmentDetail";
import {AssessmentCompetence} from "../../../cuba/entities/base/tsadv$AssessmentCompetence";
import Button, {ButtonType} from "../../components/Button/Button";
import Notification from "../../util/Notification/Notification";
import {DicParticipantType} from "../../../cuba/entities/base/tsadv$DicParticipantType";
import moment from "moment";
import Section from "../../hoc/Section";
import {DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS} from "../../util/Date/Date";
import {FileDescriptor} from "../../../cuba/entities/base/sys$FileDescriptor";
import {FileInfo} from "@cuba-platform/react/dist/ui/FileUpload";
import {RowSelectionType} from "antd/es/table";
import {downloadFile, goBackOrHomePage} from "../../util/util";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {RouteComponentProps, withRouter} from "react-router";
import {PersonAssessment} from "../../../cuba/entities/base/tsadv_PersonAssessment";

type MenuCbaEditProps = {
  personAssesmentId: string;
}

type CompetenceTableRow = {
  key: string;
  competence_type: string;
  competence_type_id: string;
  children: (RootObjectWithKey & { key: string })[];
};

interface ScaleLevel {
  scale_level_id: string;
  lang1: string;
  lang2: string;
  lang3: string;
  level_score: number;
}

interface Participant {
  participant_id: string | undefined;
  row_id: string;
  entity_id: string;
  entity_name: string;
  person_group_id: string;
  employee_name: string;
  participant_role_code: string;
  role_name: string;
  participant_order: number;
  scale_level_id: string;
  scale_level: string;
  comments: string;
  required_to_train?: any;
  scale_levels: ScaleLevel[];
  has_comments: boolean;
  participant_status_code: string;
}

type MainData = {
  date_from: string | null;
  date_to: string | null;
  is_edit: boolean | null;
  employeeFullName: string | null;
  participant_person_group_id: string | null;
  participant_type_code: string | null;
  person_group_id: string | null;
  session_name: string | null;
  status_code: string | null;
  total_result: string | null;
  person_assessment_id: string | null;
  instruction: string | null;
  participant_status_id: string | null;
  participant_status_code: string | null;
  participant_result: number | null | undefined;
};

interface RootObject {
  children: any;
  person_assessment_id: string;
  row_id: string;
  assessment_competence_id: string;
  competence_source: string;
  competence_type_id: string;
  competence_type: string;
  competence_group_id: string;
  competence_name: string;
  required_scale_level_id: string;
  required_scale_level: string;
  scale_levels: ScaleLevel[];
  entityName: string;
  result_percent: number;
  result_id?: any;
  result?: any;
  required_to_train: boolean;
  delta: number;
  has_comments: boolean;
  participants: Participant[];
}

interface RootObjectWithKey extends RootObject {
  key: string;
}

@inject("rootStore")
@injectMainStore
@observer
class MenuCbaEdit extends React.Component<MenuCbaEditProps & WrappedComponentProps & RootStoreProp & FormComponentProps & MainStoreInjected & RouteComponentProps> {

  @observable visible: boolean = false;
  @observable participants: Participant[];
  @observable fields: string[] = [];
  @observable instruction: string | null;
  @observable dataOriginal2: RootObject[];
  @observable personAssessmentId2: string;
  @observable isLoadedDetailData: boolean = true;
  @observable dataOriginal: any[];
  @observable detailTableData: any;
  @observable indicatorsFull: boolean;
  @observable isLoadedMainData: boolean = true;
  @observable mainTableData: Array<MainData>;
  @observable fileModalVisible = false;
  @observable changedFilesArray: any = [];
  @observable personInfo: any;
  @observable modalData: any;
  @observable commentInputValue: string = "";
  @observable commentModalVisible: boolean = false;
  @observable isCommentModalEdit: boolean = false;
  @observable changed = {};
  @observable changedAssessment = {};
  @observable fileInput = "";
  @observable visibleAssessmentParticipantGroupIds: string[] = [];

  personGroupId: string;

  assessorTypeDc = collection(DicParticipantType.NAME, {view: "dicParticipantType-browse"});

  personGroup = this.props.rootStore!.userInfo.personGroupId;

  @observable selectedRowKey: any = undefined;
  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

  @observable statusesDc = collection<DicAssessmentStatus>(DicAssessmentStatus.NAME, {view: "_local"});

  @action onDelete = () => {
    getCubaREST()!
      .deleteEntity(
        "tsadv_PersonAssessmentAttachment",
        this.changedFilesArray[this.selectedRowKey].id
      )
      .then(() => {
        this.searchFiles();
        return;
      })
      .catch(() => {
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "management.editor.error"
          })
        });
      });

    this.changedFilesArray.splice(this.selectedRowKey, 1);
    this.selectedRowKey = undefined;
    return;
  };

  @action onAttachmentModalOk = () => {
    const attachment: any = {};
    if (this.props.form.getFieldValue("attachedfile") as FileInfo)
      getCubaREST()!
        .loadEntity(
          FileDescriptor.NAME,
          (this.props.form.getFieldValue("attachedfile") as FileInfo).id,
          {view: "browse"}
        )
        .then(fileDescriptor => {
          attachment.file = fileDescriptor as FileDescriptor;
          attachment.description = this.fileInput;
          attachment.personAssessment = {id: this.props.personAssesmentId};
          attachment.personGroup = {
            id: this.personGroup,
            _instanceName: this.props
              .rootStore!.userInfo.firstLastName!.split(" ")
              .reverse()
              .join(" ")!
          };
          getCubaREST()!
            .commitEntity<any>("tsadv_PersonAssessmentAttachment", {
              file: attachment.file,
              personAssessment: {
                id: this.props.personAssesmentId
              },
              personGroup: {
                id: this.personGroup
              },
              description: attachment.description
            })
            .then(() => {
              this.searchFiles();
            })
            .catch((a: any) =>
              Notification.error({
                message: this.props.intl.formatMessage({
                  id: "management.editor.error"
                })
              })
            );

          this.props.form.setFieldsValue({
            attachedfile: null,
            attachedComment: null
          });
          this.fileModalVisible = false;
        })
        .then(() => (this.fileInput = ""));
    else {
      Notification.error({
        message: this.props.intl.formatMessage(
          {id: "form.validation.required"},
          {
            fieldName: this.props.mainStore!.messages![
            "tsadv_PersonAssessmentAttachment" + ".file"
              ]
          }
        )
      });
    }
  };

  @action searchFiles = () => {
    getCubaREST()!
      .searchEntities(
        "tsadv_PersonAssessmentAttachment",
        {
          conditions: [
            {
              property: "personAssessment.id",
              operator: "=",
              value: this.props.personAssesmentId
            }
          ]
        },
        {
          view: "personAssessmentAttachmentForAPAB-edit"
        }
      )
      .then(res => {
        this.changedFilesArray = res;
      });
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    const personAssessment = await getCubaREST()!.loadEntity<PersonAssessment>(PersonAssessment.NAME,
      this.props.personAssesmentId,
      {view: "personAssessment-for-apab"}
    );
    this.personGroupId = personAssessment.personGroup!.id;
    this.visibleAssessmentParticipantGroupIds = await restServices.competenceTemplateParticipantService.getVisibleAssessmentParticipantGroupIds(this.props.personAssesmentId);

    this.selectedRowKey = undefined;
    this.searchFiles();
    this.getPersonAssessmentForm(this.props.personAssesmentId);
    this.getPersonAssessments();
    restServices.employeeService.personProfile(this.personGroupId).then(res => {
      runInAction(() => {
        this.personInfo = res;
      });
    });
  }

  renderCommentTextAreas = (GroupObj: any) => {
    return (
      <div
        style={{display: "flex", flexDirection: "column-reverse", gap: 10}}
      >
        {this.participants &&
          this.participants.map((employee, empInd) => {
            return (
              <div>
                <label>{employee.employee_name}</label>
                <TextArea
                  defaultValue={
                    this.modalData &&
                    this.modalData.participants &&
                    this.modalData.participants[empInd] &&
                    this.modalData.participants[empInd].comments
                  }
                  autoSize={{minRows: 5, maxRows: 15}}
                  disabled={
                    !this.isCommentModalEdit ||
                    (this.modalData &&
                      this.modalData.participants &&
                      this.modalData.participants[empInd] &&
                      this.modalData.participants[empInd].person_group_id !==
                      this.personGroup)
                  }
                  onChange={e => {
                    this.commentInputValue = e.target.value;
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  };

  commitAssessment = (entityId: string, value: string) => {
    getCubaREST()!
      .commitEntity(Assessment.NAME, {
        id: entityId,
        comment: value
      })
      .then(() => {
        this.getPersonAssessmentForm(this.props.personAssesmentId);
      })
      .catch((a: any) =>
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "management.editor.error"
          })
        })
      );
  };

  @action openCommentModal = (record: any, edit: boolean) => {
    this.modalData = record;
    this.isCommentModalEdit = edit;
    this.commentModalVisible = true;
  };

  commitDevelopment = (entityId: string, value: boolean) => {
    getCubaREST()!
      .commitEntity<AssessmentCompetence>(AssessmentCompetence.NAME, {
        id: entityId,
        requiredDevelopmet: value
      })
      .then(() => {
        this.getPersonAssessmentForm(this.props.personAssesmentId);
      })
      .catch((a: any) =>
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "management.editor.error"
          })
        })
      );
  };

  validate = (): Promise<boolean> => {
    let obj = this.fields && this.props.form.getFieldsValue([...this.fields]);
    let isValidatedSuccess =
      Object.keys(obj).every((item: any) => {
        return obj[item];
      }) || this.indicatorsFull;
    if (!isValidatedSuccess)
      Notification.error({
        message: this.props.intl.formatMessage({
          id: "management.editor.validationError.cba"
        })
      });
    return new Promise(resolve => resolve(isValidatedSuccess));
  };

  goBack = () => {
    goBackOrHomePage(this.props.history)
  };

  groupDataByCompetenceType = (arr: RootObject[]): CompetenceTableRow[] => {
    const map = arr.reduce(
      (acc, cur, index) => {
        if (cur.competence_type_id && cur.competence_type) {
          if (!acc.has(cur.competence_type_id)) {
            acc.set(cur.competence_type_id, {
              competence_type_id: cur.competence_type_id,
              competence_type: cur.competence_type,
              key: cur.competence_type_id,
              children: []
            });
          }
          acc.get(cur.competence_type_id)!.children.push({
            ...cur,
            competence_type: cur && cur.competence_name,
            key: `${cur.competence_group_id || index}-${cur.row_id || index}`
          });
        }
        return acc;
      },
      new Map() as Map<string, CompetenceTableRow>
    );

    return Array.from(map.values());
  };

  getPersonAssessmentForm = (personAssessmentId: string) => {
    restServices.learningService
      .getPersonAssessmentForm({
        personGroupId: personAssessmentId,
        lang: this.props.rootStore!.userInfo!.locale!
      })
      .then((v: any) => {
        runInAction(() => {
          let arr: RootObject[] = JSON.parse(v && v[0] && v[0]!.value!);
          this.isLoadedDetailData = false;
          this.dataOriginal2 = JSON.parse(v && v[0] && v[0]!.value!)
            .filter(
              (item: RootObject) => item.entityName === AssessmentDetail.NAME
            )
            .map((i: RootObject) =>
              i.participants.filter(
                (v: Participant) =>
                  v.person_group_id ===
                  this.props.rootStore!.userInfo!.personGroupId!
              )
            );
          this.instruction = this.dataOriginal2.map(
            item => item && item[0] && item[0].instruction
          )[0] || " "
          this.personAssessmentId2 = this.dataOriginal2.map(
            item => item && item[0] && item[0]!.person_assessment_id!
          )![0]! || " ";
          this.dataOriginal = arr;
          this.detailTableData = this.groupDataByCompetenceType(arr);
          this.participants = arr[0].participants;
          this.indicatorsFull = this.dataOriginal
            .filter((item: any) => {
              return item.entityName === "tsadv_AssessmentDetail";
            })
            .every((item: any) => {
              let found =
                item &&
                item.participants &&
                item.participants.find((item: any) => {
                  return item.person_group_id === this.personGroup;
                });
              return found && !!found.scale_level;
            });
        });
      })
      .then(() => {
        for (let i of this.dataOriginal) {
          if (i.entityName === "tsadv_AssessmentDetail") {
            i &&
            i.participants &&
            i.participants.forEach((item: any) => {
              if (
                item.entity_name === "tsadv_AssessmentDetail" &&
                item.person_group_id ===
                this.props.rootStore!.userInfo.personGroupId!
              )
                this.fields.push(item.entity_id);
            });
          }
        }
      });
  };

  getPersonAssessments = () => {
    restServices.learningService
      .getPersonAssessments({
        personGroupId: this.props.rootStore!.userInfo.personGroupId!,
        lang: this.props.rootStore!.userInfo!.locale!
      })
      .then((value: any) => {
        runInAction(() => {
          this.isLoadedMainData = false;
          this.mainTableData = JSON.parse(
            value && value[0] && value[0]!.value!
          );
        });
      });
  };

  commitDetail = (
    entityId: string,
    field: string,
    value: any,
    record: any,
    index: number | string
  ) => {
    switch (field) {
      case "comment":
        getCubaREST()!
          .commitEntity(AssessmentDetail.NAME, {
            id: entityId,
            comments: value
          })
          .then(() => {
            this.getPersonAssessmentForm(this.props.personAssesmentId);
          })
          .catch((a: any) =>
            Notification.error({
              message: this.props.intl.formatMessage({
                id: "management.editor.error"
              })
            })
          );
        break;
      case "scaleLevel":
        getCubaREST()!
          .commitEntity<AssessmentDetail>(AssessmentDetail.NAME, {
            id: entityId,
            scaleLevel: {id: value}
          })
          .then(() => {
            restServices.learningService
              .updateAssessment({
                assessmentId:
                  record &&
                  record.participants &&
                  record.participants[index] &&
                  record.participants[index].assessment_id
              })
              .then(v => {
              })
              .then(() => {
                this.getPersonAssessments();
              })
              .then(() => {
                this.getPersonAssessmentForm(this.props.personAssesmentId);
              })
              .catch((a: any) =>
                Notification.error({
                  message: this.props.intl.formatMessage({
                    id: "management.editor.error"
                  })
                })
              );
          });
        break;
    }
  };

  renderColumnIfVisible = (employee: Participant, column: JSX.Element) => {
    return this.visibleAssessmentParticipantGroupIds.includes(employee["person_group_id"])
      ? column
      : undefined;
  }

  render() {
    if (!this.mainTableData) {
      return <Row type={"flex"} justify={"center"}>
        <Spin/>
      </Row>
    }

    const {
      session_name,
      date_to,
      date_from,
      total_result,
      status_code,
      participant_type_code,
      person_assessment_id,
      instruction,
      participant_status_code,
      is_edit
    } = this.mainTableData.filter(
      item => item.person_assessment_id === this.props.personAssesmentId
    )[0];
    let statusSEND = this.statusesDc.items.find((item: any) => {
      return item.code === "SEND";
    });
    let statusSAVE = this.statusesDc.items.find((item: any) => {
      return item.code === "SAVE";
    });
    const isEditable =
      moment().isBetween(date_from, date_to, "days", "[]") &&
      status_code !== "COMPLETED" &&
      participant_status_code !== "SEND";
    let openModal = () => {
      runInAction(() => {
        this.visible = true;
      });
    };

    const rowSelection = {
      selectedRowKeys: [this.selectedRowKey],
      type: "radio" as RowSelectionType,
      onChange: this.handleRowSelectionChange,
      getCheckboxProps: (record: any) => ({
        disabled:
          !is_edit ||
          !isEditable ||
          (record.personGroup && record.personGroup.id !== this.personGroup), // Column configuration not to be checked
        name: record.name
      })
    };
    const commentBasedOnRoleDetail = (record: any, value: string) => {
      if (value.length > 2500) {
        Notification.error({
          message: this.props.intl.formatMessage(
            {
              id: "value.exceeds.length"
            },
            {length: 2500}
          )
        });
        return;
      }
      this.participants &&
      this.participants.map((employee, empInd) => {
        if (
          record.participants[empInd].person_group_id ===
          this.props.rootStore!.userInfo!.personGroupId!
        ) {
          this.commitDetail(
            record.participants[empInd].entity_id,
            "comment",
            value,
            record,
            empInd
          );
        }
      });
    };
    const commentBasedOnRole = (record: any, value: string) => {
      if (value.length > 2500) {
        Notification.error({
          message: this.props.intl.formatMessage(
            {
              id: "value.exceeds.length"
            },
            {length: 2500}
          )
        });
        return;
      }
      this.participants &&
      this.participants.map((employee, empInd) => {
        if (
          record.participants[empInd].person_group_id ===
          this.props.rootStore!.userInfo!.personGroupId!
        ) {
          this.commitAssessment(record.participants[empInd].entity_id, value);
        }
      });
    };
    const getParticipantId = () => {
      let find =
        this.participants &&
        this.participants.find((item: any) => {
          return (
            item.person_group_id ===
            this.props.rootStore!.userInfo.personGroupId!
          );
        });
      if (find) {
        return find.participant_id;
      } else {
        return;
      }
    };
    let sendEntity = () => {

      let dataForSend: AssessmentParticipant = {
        id: getParticipantId(),
        assessmentStatus: {id: statusSEND!.id}
      };
      getCubaREST()!
        .commitEntity<AssessmentParticipant>(
          AssessmentParticipant.NAME,
          dataForSend
        )
        .then(() => {
          restServices.notificationsService.sendNotificationNextParticipant({
            personAssessmentId: person_assessment_id!
          }).then(value => {
            restServices.learningService
              .getPersonAssessments({
                personGroupId: this.personGroup!,
                lang: this.props.rootStore!.userInfo!.locale!
              })
              .then(value => {
                this.isLoadedMainData = false;
                this.mainTableData = JSON.parse(
                  value && value[0] && value[0]!.value!
                );
              })
              .then(() => {
                Notification.success({
                  message: this.props.intl.formatMessage({id: "cbaSent"})
                });
                this.goBack();
              });
          });

        });
    };

    const faCommentDotss = faCommentDots as IconProp;
    const faEditt = faEdit as IconProp;
    return (
      <>
        <div
          style={{
            display: "flex",
            marginBottom: "20px",
            width: "75%",
            marginTop: 20,
            gap: 15
          }}
        >
          <div style={{width: "50%"}}>
            <FormattedMessage id={"employee"}/>
            <Input
              width={"100%"}
              value={this.personInfo && this.personInfo.fullName}
              disabled
            />
          </div>
          <div style={{width: "50%"}}>
            <FormattedMessage id={"position"}/>
            <Input
              value={this.personInfo && this.personInfo.positionName}
              disabled
            />
          </div>
          <div style={{width: "50%"}}>
            <FormattedMessage id={"organizationGroup"}/>
            <Input
              value={this.personInfo && this.personInfo.organizationName}
              disabled
            />
          </div>
        </div>
        <div
          style={{display: "flex", marginBottom: 20, width: "75%", gap: 15}}
        >
          <div style={{width: "25%"}}>
            <FormattedMessage id={"cba.dateFrom"}/>
            <Input
              value={date_from ? moment(date_from).format("DD.MM.YYYY") : ""}
              disabled
            />
          </div>
          <div style={{width: "25%"}}>
            <FormattedMessage id={"cba.FormTemplateName"}/>
            <Input value={session_name ? session_name : ""} disabled/>
          </div>
          <div style={{width: "25%"}}>
            <FormattedMessage id={"assessor"}/>
            <Input
              value={
                participant_type_code
                  ? this.assessorTypeDc.items.find(
                    (item: any) => item.code === participant_type_code
                  ) &&
                  this.assessorTypeDc.items.find(
                    (item: any) => item.code === participant_type_code
                  )!._instanceName
                  : ""
              }
              disabled
            />
          </div>
          <div style={{width: "25%"}}>
            <FormattedMessage id={"status"}/>
            <Input
              value={
                participant_status_code
                  ? this.statusesDc.items.find(
                    (item: any) => item.code === participant_status_code
                  ) &&
                  this.statusesDc.items.find(
                    (item: any) => item.code === participant_status_code
                  )!._instanceName
                  : ""
              }
              disabled
            />
          </div>
        </div>
        <Card
          className={"narrow-layout card-actions-container"}
          actions={
            is_edit && isEditable
              ? [
                <Button
                  buttonType={ButtonType.PRIMARY}
                  onClick={() => {
                    this.goBack();
                  }}
                >
                  <FormattedMessage id={"close"}/>
                </Button>,
                <Button
                  buttonType={ButtonType.PRIMARY}
                  onClick={() => {
                    this.validate()
                      .then(isValid => {
                        if (isValid) openModal();
                      })
                      .catch(reason => {
                        Notification.error({
                          message: this.props.intl.formatMessage({
                            id: "management.editor.validationError"
                          })
                        });
                      });
                  }}
                >
                  <FormattedMessage id={"cbaSend"}/>
                </Button>
              ]
              : [
                <Button
                  buttonType={ButtonType.PRIMARY}
                  onClick={() => {
                    this.goBack();
                  }}
                >
                  <FormattedMessage id={"close"}/>
                </Button>
              ]
          }
        >
          <Spin spinning={this.isLoadedDetailData}>
            <label style={{marginTop: 10, marginLeft: 10, display: "block"}}>
              <FormattedMessage id={"instruction"}/>
              <TextArea
                placeholder={this.props.intl.formatMessage({
                  id: "instruction"
                })}
                style={{width: "50%", display: "block"}}
                value={this.instruction ? this.instruction : ""}
                autosize={{minRows: 2, maxRows: 15}}
                disabled
              />
            </label>
            <label
              style={{
                marginTop: 10,
                marginLeft: 10,
                display: "block",
                marginBottom: 10
              }}
            >
              {" "}
              {this.props.intl.formatMessage({id: "myCbaResult"})}
              <Input
                value={total_result ? total_result : ""}
                style={{width: 200, display: "block"}}
                disabled
              />
            </label>
            <Table
              key={"workgreat"}
              dataSource={this.detailTableData}
              scroll={{x: true}}
              bordered={true}
              tableLayout={"fixed"}
              className={"cba-detail"}
            >
              <Column
                title={this.props.intl.formatMessage({
                  id: "cba.detail.source"
                })}
                dataIndex={"source"}
                key={"source"}
                render={(text, record: RootObject, index) =>
                  record.entityName !== AssessmentDetail.NAME &&
                  getEnumCaption(
                    (record as RootObject)!.competence_source!,
                    getPropertyInfoNN(
                      "competenceSource",
                      "tsadv$Assessment",
                      this.props.mainStore!.metadata!
                    ),
                    this.props.mainStore!.enums!
                  )
                }
              />
              <Column
                title={this.props.intl.formatMessage({
                  id: "cba.detail.competency"
                })}
                dataIndex={"competency"}
                key={"competency"}
                render={(text, record: RootObject, index) =>
                  record.hasOwnProperty("competence_name")
                    ? (record as RootObject)!.competence_name!
                    : (record as RootObject)!.competence_type!
                }
              />
              <Column
                title={this.props.intl.formatMessage({
                  id: "cba.detail.requiredlevel"
                })}
                dataIndex={"requiredlevel"}
                key={"requiredlevel"}
                render={(text, record, index) =>
                  (record as RootObject).required_scale_level
                }
              />

              {this.participants &&
                this.participants.map((employee, empoyeeIndex) =>
                  this.renderColumnIfVisible(employee, (
                    <Column
                      title={() => (
                        <>
                          {employee.role_name}
                          <br/>
                          <span>{employee.employee_name}</span>
                        </>
                      )}
                      dataIndex={"participants"}
                      key={employee.row_id + employee.entity_id}
                      render={(text, record: RootObject, index) => {
                        let id =
                          record.participants &&
                          record.participants[empoyeeIndex];
                        return is_edit &&
                        isEditable &&
                        this.props.rootStore!.userInfo.personGroupId! ===
                        (record.participants &&
                          record.participants[empoyeeIndex] &&
                          record.participants[empoyeeIndex]
                            .person_group_id) &&
                        record.entityName === AssessmentDetail.NAME ? (
                          <div className={"hey"}>
                            <Form.Item
                              className={"cba-form-item"}
                              required={true}
                              style={{
                                marginBottom: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                              label={createElement(Msg, {
                                propertyName: id && id!.entity_id!
                              })}
                            >
                              {this.props.form.getFieldDecorator(
                                id.entity_id as never,
                                {
                                  initialValue:
                                    record.participants &&
                                    record.participants[empoyeeIndex].scale_level,
                                  rules: [
                                    {
                                      validator: (
                                        rule: any,
                                        value: any,
                                        callback: any
                                      ) => {
                                        if (!value) {
                                          callback(
                                            this.props.intl.formatMessage({
                                              id:
                                                "cubaReact.dataTable.requiredField"
                                            })
                                          );
                                        }
                                        callback();
                                      }
                                    }
                                  ],
                                  getValueFromEvent: args => {
                                    this.isLoadedDetailData = true;
                                    this.commitDetail(
                                      record.participants &&
                                      record.participants[empoyeeIndex]!
                                        .entity_id!,
                                      "scaleLevel",
                                      args,
                                      record,
                                      empoyeeIndex
                                    );
                                    return args;
                                  }
                                }
                              )(
                                <Select style={{width: 175}}>
                                  {record.scale_levels &&
                                    record.scale_levels &&
                                    record.scale_levels.map((item: any) => {
                                      return (
                                        <Select.Option
                                          key={item.scale_level_id}
                                          value={item.scale_level_id}
                                        >
                                          <Tooltip placement="bottom" title={this.props.rootStore!.userInfo
                                            .locale === "ru"
                                            ? item.lang1
                                            : item.lang3}>
                                            {this.props.rootStore!.userInfo
                                              .locale === "ru"
                                              ? item.lang1
                                              : item.lang3}
                                          </Tooltip>

                                        </Select.Option>
                                      );
                                    })}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                        ) : (
                          record.participants &&
                          record.participants[empoyeeIndex].scale_level
                        );
                      }}
                    />
                  )))}
              <Column
                title={this.props.intl.formatMessage({
                  id: "cba.detail.total"
                })}
                dataIndex={"total"}
                key={"total"}
                render={(text, record, index) =>
                  (record as RootObject).result_percent
                }
              />
              <Column
                title={this.props.intl.formatMessage({
                  id: "cba.detail.result"
                })}
                dataIndex={"result"}
                key={"result"}
                render={(text, record, index) => (record as RootObject).result}
              />
              <Column
                title={this.props.intl.formatMessage({
                  id: "cba.detail.requiredToTrain"
                })}
                dataIndex={"requiredToTrain"}
                key={"requiredToTrain"}
                render={(text, record: RootObject, index) => {
                  if (record.entityName !== AssessmentDetail.NAME) {
                    return (
                      <Checkbox
                        disabled={!is_edit || !isEditable}
                        onChange={e =>
                          runInAction(() => {
                            this.commitDevelopment(
                              record.assessment_competence_id!,
                              e.target.checked
                            );
                          })
                        }
                        defaultChecked={
                          (record as RootObject).required_to_train
                        }
                      />
                    );
                  } else {
                    return "";
                  }
                }}
              />
              <Column
                title={this.props.intl.formatMessage({
                  id: "cba.detail.comments"
                })}
                dataIndex={"comments"}
                key={"comments"}
                render={(text, record: RootObject) => {
                  if (record.entityName) {
                    return (
                      <>
                        {isEditable && is_edit ? (
                          <a
                            onClick={e => {
                              e.preventDefault();
                              this.openCommentModal(record, true);
                            }}
                          >
                            {
                              <>
                                <FontAwesomeIcon
                                  style={{marginRight: 5}}
                                  size={"2x"}
                                  icon={faEditt}
                                />
                              </>
                            }
                          </a>
                        ) : null}
                        {record.has_comments ? (
                          <a
                            onClick={e => {
                              e.preventDefault();
                              this.openCommentModal(record, false);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"2x"}
                              icon={faCommentDotss}
                            />
                          </a>
                        ) : null}
                      </>
                    );
                  } else {
                    return "";
                  }
                }}
              />
            </Table>
            <Section
              sectionName={this.props.intl.formatMessage({id: "attachments"})}
              size={"large"}
            >
              {isEditable && is_edit ? (
                <div style={{display: "flex", gap: 10, marginBottom: 10}}>
                  <AntdButton
                    type={"primary"}
                    icon={"plus"}
                    onClick={() => (this.fileModalVisible = true)}
                  ></AntdButton>
                  <AntdButton
                    disabled={this.selectedRowKey === undefined}
                    type={"danger"}
                    icon={"delete"}
                    onClick={this.onDelete}
                  ></AntdButton>
                </div>
              ) : null}

              <Table
                rowSelection={isEditable && is_edit ? rowSelection : undefined}
                bordered
                dataSource={this.changedFilesArray}
              >
                <Column
                  render={(text, record: any) => (
                    <a
                      onClick={e => {
                        e.preventDefault();
                        downloadFile(
                          record && record.file && record.file.id,
                          record && record.file && record.file.name,
                          "",
                          this.props.intl.formatMessage({
                            id: "file.download.error"
                          })
                        );
                      }}
                    >
                      {text}
                    </a>
                  )}
                  key={"nameFile"}
                  dataIndex={"file._instanceName"}
                  title={this.props.intl!.formatMessage({id: "file"})}
                />
                <Column
                  key={"desc"}
                  dataIndex={"description"}
                  title={this.props.intl!.formatMessage({id: "description"})}
                />
                <Column
                  key={"createTs"}
                  dataIndex={"file.createTs"}
                  render={text =>
                    moment(text).format(
                      DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS
                    )
                  }
                  title={this.props.intl!.formatMessage({
                    id: "cba.scoreCardMain.history.createTs"
                  })}
                />
                <Column
                  key={"author"}
                  dataIndex={"personGroup._instanceName"}
                  title={this.props.intl!.formatMessage({id: "author"})}
                />
              </Table>
            </Section>
            <Modal
              centered
              onOk={this.onAttachmentModalOk}
              onCancel={() => (this.fileModalVisible = false)}
              visible={this.fileModalVisible}
            >
              <div>
                {this.fileModalVisible ? (
                  <>
                    <label>
                      {this.props.intl!.formatMessage({id: "file"})}
                    </label>
                    {this.props.form.getFieldDecorator("attachedfile", {
                      initialValue: undefined
                    })(<FileUpload/>)}
                  </>
                ) : null}
              </div>

              <div style={{marginBottom: "20px"}}>
                <label>
                  {this.props.intl!.formatMessage({id: "concourseDesc"})}
                </label>
                <Input
                  style={{display: "inline-block"}}
                  value={this.fileInput}
                  onChange={event => {
                    this.fileInput = event.target.value;
                  }}
                />
              </div>
            </Modal>
            <Modal
              centered
              children={
                <span>{this.props.intl.formatMessage({id: "cbaSure"})}</span>
              }
              visible={this.visible}
              onCancel={() => {
                runInAction(() => {
                  this.visible = false;
                });
              }}
              onOk={e => {
                sendEntity();
                runInAction(() => {
                  this.visible = false;
                });
              }}
            />
            <Modal
              maskClosable={false}
              centered
              onOk={() => {
                this.modalData &&
                this.modalData.entityName === AssessmentDetail.NAME
                  ? commentBasedOnRoleDetail(
                    this.modalData,
                    this.commentInputValue
                  )
                  : commentBasedOnRole(this.modalData, this.commentInputValue);
                runInAction(() => {
                  this.commentModalVisible = false;
                });
              }}
              destroyOnClose
              title={this.props.intl.formatMessage({id: "comment"})}
              onCancel={() => {
                runInAction(() => {
                  this.commentModalVisible = false;
                  this.modalData = undefined;
                });
              }}
              visible={this.commentModalVisible}
            >
              <>
                {this.modalData &&
                this.modalData.entityName === AssessmentDetail.NAME
                  ? this.renderCommentTextAreas(this.changed)
                  : this.renderCommentTextAreas(this.changedAssessment)}
              </>
            </Modal>
          </Spin>
        </Card>
      </>
    );
  }
}

export default injectIntl(
  withLocalizedForm({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(MenuCbaEdit))
);
