import React from "react";
import {Button, Col, Row} from "antd";
import Img from "../../../components/Img";
import {GoodsPojo} from "../../../../cuba/entities/base/tsadv$GoodsPojo";
import {observer} from "mobx-react";
import CountPicker from "./CountPicker";
import CoinsComponent from "./CoinsComponent";
import {cartStore} from "../store/CartStore";
import {getFileUrlOpt} from "../../../util/util";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

type CartElementProps = {
  item: GoodsPojo
}

@observer
class CartElement extends React.Component<CartElementProps & WrappedComponentProps> {

  updateCount = (value: number) => {
    cartStore.updateCount(this.props.item, value);
  }

  remove = async () => {
    if (window.confirm(this.props.intl.messages["recognition.cart.deleteConFirm"] as string)) {
      const item = this.props.item;
      await cartStore.deleteFromCart(item.cartId);
    }
  }

  render() {
    const item = this.props.item;

    return <Row type={"flex"}
                gutter={[20, 20]}
                style={{border: "solid 1px", borderRadius: "6px", margin: '0 10px 10px 10px'}}
                key={item.id}
    >
      <Col xs={3} style={{borderRight: 'solid 1px black'}}>
        <Row type={"flex"} style={{alignItems: "center", height: "100%"}}>
          <Img src={getFileUrlOpt(item.image!)} style={{width: "100%"}} />
        </Row>
      </Col>
      <Col xs={21}>
        <Row type={"flex"} justify={"space-between"} style={{alignItems: "center"}}>
          <Col>
            <h2>{item.name}</h2>
          </Col>
          <Col>
            <CoinsComponent value={item.price}/>
          </Col>
        </Row>
        <Row type={"flex"} justify={"space-between"}>
          <Col>
            <CountPicker min={1} defaultValue={item.quantity} onChange={this.updateCount}/>
          </Col>
          <Col>
            <Button type={"link"} onClick={this.remove}>
              <FormattedMessage id={"delete"}/>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  }
}

export default injectIntl(CartElement);