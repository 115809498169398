import * as React from "react";
import {createElement, FormEvent} from "react";
import {Alert, Card, Col, Form, message, Row} from "antd";
import {inject, observer} from "mobx-react";
import {VacationScheduleRequestManagement} from "./VacationScheduleRequestManagement";
import {FormComponentProps} from "antd/lib/form";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {IReactionDisposer, observable, reaction, toJS} from "mobx";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

import {
  clearFieldErrors,
  collection,
  constructFieldsWithErrors,
  extractServerValidationErrors,
  getCubaREST,
  injectMainStore,
  MainStoreInjected,
  Msg,
  MultilineText,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../app/App.css";

import {VacationScheduleRequest} from "../../../cuba/entities/base/tsadv_VacationScheduleRequest";
import {RootStoreProp} from "../../store";
import {restServices} from "../../../cuba/services";
import {ReadonlyField} from "../../components/ReadonlyField";
import Button, {ButtonType} from "../../components/Button/Button";
import TextArea from "antd/es/input/TextArea";
import {withRouter} from "react-router";
import {DicAbsenceType} from "../../../cuba/entities/base/tsadv$DicAbsenceType";
import Section from "../../hoc/Section";
import Page from "../../hoc/PageContentHoc";
import moment from "moment/moment";
import {isNumber} from "../../util/util";
import {VacationGanttChart} from "../../components/VacationGanttChart";
import {PersonGroup} from "../../../cuba/entities/base/base$PersonGroup";
import {DataCollectionStore} from "@cuba-platform/react/dist/data/Collection";
import {AssignmentSchedule} from "../../../cuba/entities/base/tsadv$AssignmentSchedule";
import {serviceCollection} from "../../util/ServiceDataCollectionStore";
import {JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import {instanceStore} from "../../util/InstanceStore";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {SerializedEntity} from "@cuba-platform/rest";
import {Absence} from "../../../cuba/entities/base/tsadv$Absence";

export type VacationPojo = {
  id?: string,
  personGroupId: string,
  startDate: string
}

type Props = FormComponentProps & EditorProps;

type EditorProps = {
  entityId: string;
  ganttChartVisible?: boolean;
};

@injectMainStore
@inject("rootStore")
@observer
class VacationScheduleRequestEditComponent extends React.Component<Props & WrappedComponentProps & RootStoreProp & MainStoreInjected & RouteComponentProps<any>> {
  dataInstance = instanceStore<VacationScheduleRequest>(
    VacationScheduleRequest.NAME,
    {view: "vacationScheduleRequest-edit"}
  );

  @observable
  personGroupDc: DataCollectionStore<PersonGroup>;

  @observable
  assignmentSchedule?: SerializedEntity<AssignmentSchedule>;

  @observable
  updated = false;

  @observable
  isNew = true;

  @observable
  isMy = true;

  reactionDisposer: IReactionDisposer;

  fields = [
    "requestNumber",

    "requestDate",

    "personGroup",

    "startDate",

    "endDate",

    "absenceDays",

    "balance",

    "comment",

    "approved",

    "sentToOracle",

    "attachment"
  ];

  @observable
  globalErrors: string[] = [];

  personGroupId: string;

  laborLeave: DicAbsenceType;

  hasMinDayVacation = false;

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        return;
      }
      this.dataInstance
        .update({
          assignmentSchedule: this.assignmentSchedule,
          ...this.props.form.getFieldsValue(this.fields)
        })
        .then(() => {
          message.success(
            this.props.intl.formatMessage({id: "management.editor.success"})
          );
          this.updated = true;
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.error"
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({id: "management.editor.error"})
            );
          }
        });
    });
  };

  render() {
    if (this.updated) {
      return <Redirect to={"/vacationSchedule/" + this.props.rootStore!.vacationRequestStore.type}/>;
    }

    const {getFieldDecorator} = this.props.form;
    const messages = this.props.mainStore!.messages!;

    const {status, item} = this.dataInstance;

    const readonly = !!(item && item.approved && !item.sentToOracle);

    return (
      <Page pageName={this.props.intl.formatMessage({id: "vacationScheduleRequest"})}>
        <Section size="large">
          <div>
            <Card className="narrow-layout card-actions-container" actions={[
              <Button
                buttonType={ButtonType.PRIMARY}
                disabled={status !== "DONE" && status !== "ERROR" || readonly}
                loading={status === "LOADING"}
                onClick={this.handleSubmit}
                style={{marginLeft: "8px"}}>
                <FormattedMessage id="management.editor.submit"/>
              </Button>,
              <Button buttonType={ButtonType.FOLLOW} htmlType="button" onClick={() => this.props.history!.goBack()}>
                <FormattedMessage id="management.editor.cancel"/>
              </Button>]}
                  bordered={false}>
              <Form layout="vertical">
                <Row gutter={24}>
                  <Col xl={11}>
                    <ReadonlyField
                      entityName={VacationScheduleRequest.NAME}
                      propertyName="requestNumber"
                      form={this.props.form}
                      formItemOpts={{style: {marginBottom: "12px"}}}
                      disabled={true}
                    />
                  </Col>

                  <Col xl={10}>
                    <ReadonlyField
                      entityName={VacationScheduleRequest.NAME}
                      propertyName="personGroup"
                      form={this.props.form}
                      optionsContainer={this.personGroupDc}
                      formItemOpts={{style: {marginBottom: "12px"}}}
                      getFieldDecoratorOpts={{
                        getValueFromEvent: args => {
                          this.onChangePersonGroupOrDate(args);
                          return args;
                        }
                      }}
                      disabled={!this.isNew || this.isMy}
                    />
                  </Col>

                  <Col xl={3}>
                    <ReadonlyField
                      entityName={VacationScheduleRequest.NAME}
                      propertyName="requestDate"
                      form={this.props.form}
                      formItemOpts={{style: {marginBottom: "12px"}}}
                      disabled={true}
                    />
                  </Col>

                </Row>
                <Row gutter={24}>
                  <Col xl={3}>
                    <ReadonlyField
                      entityName={VacationScheduleRequest.NAME}
                      propertyName="startDate"
                      form={this.props.form}
                      formItemOpts={{style: {marginBottom: "12px"}}}
                      getFieldDecoratorOpts={{
                        getValueFromEvent: args => {
                          this.getAssignmentSchedule(args);
                          this.getAbsenceBalance(args);
                          this.getAbsenceDays(args);
                          this.checkMinDayVacation(args);

                          return args
                        },
                        rules: [{
                          required: true,
                          validator: (rule, value, callback) => {
                            if (readonly) return callback();
                            if (!value) return callback(this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.startDate']}));

                            const requestDate = this.props.form.getFieldValue('requestDate');

                            if (requestDate && requestDate > value) {
                              return callback(this.props.intl.formatMessage({id: 'validation.vacationScheduleRequest.startDate.start'}));
                            }

                            this.props.form.validateFields(['endDate'], {force: true});

                            return callback();
                          }
                        }]
                      }}
                      disabled={readonly}
                    />
                  </Col>
                  <Col xl={3}>
                    <ReadonlyField
                      entityName={VacationScheduleRequest.NAME}
                      propertyName="endDate"
                      form={this.props.form}
                      formItemOpts={{style: {marginBottom: "12px"}}}
                      disabled={readonly}
                      getFieldDecoratorOpts={{
                        rules: [{
                          required: true,
                          validator: (rule, value, callback) => {
                            if (!value) return callback(this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.endDate']}));
                            const startDate = this.props.form.getFieldValue('startDate');
                            if (startDate && startDate.clone().startOf('day') > value.clone().startOf('day'))
                              return callback(this.props.intl.formatMessage({id: 'validation.compare.date'}, {
                                startDate: messages[this.dataInstance.entityName + '.startDate'],
                                endDate: messages[this.dataInstance.entityName + '.endDate']
                              }));
                            return callback();
                          }
                        }],
                        getValueFromEvent: args => {
                          this.getAbsenceDays(undefined, args);
                          return args;
                        }
                      }}
                    />
                  </Col>
                  <Col xl={9}>
                    <ReadonlyField
                      entityName={VacationScheduleRequest.NAME}
                      propertyName="absenceDays"
                      form={this.props.form}
                      formItemOpts={{style: {marginBottom: "12px"}}}
                      getFieldDecoratorOpts={{
                        rules: [
                          {
                            validator: (rule, value, callback) => {
                              if (readonly) return callback();
                              const balance = this.props.form.getFieldValue('balance');
                              if (!isNumber(value) || !isNumber(balance)) return callback();
                              const absenceDays = parseInt(value);
                              if (balance < absenceDays) {
                                return callback(this.props.intl.formatMessage({id: 'validation.balance'}));
                              } else if (this.laborLeave && this.laborLeave.minDay) {
                                const minDay = this.laborLeave.minDay;
                                if (minDay > absenceDays && !this.hasMinDayVacation) {
                                  return callback(
                                    this.props.intl.formatMessage({id: 'vacationRequest.validation.minDay'},
                                      {days: this.laborLeave.minDay})
                                  );
                                } /*else if ( minDay >= absenceDays && this.hasMinDayVacation) {

                            }*/
                              }
                              return callback();
                            }
                          }
                        ]
                      }}
                      disabled={true}
                    />
                  </Col>
                  <Col xl={9}>
                    <ReadonlyField
                      entityName={VacationScheduleRequest.NAME}
                      propertyName="balance"
                      form={this.props.form}
                      formItemOpts={{style: {marginBottom: "12px"}}}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <div className={"ant-row ant-form-item"} style={{marginBottom: "12px"}}>
                  {createElement(Msg, {entityName: this.dataInstance.entityName, propertyName: "comment"})}
                  <Form.Item>
                    {getFieldDecorator("comment")(
                      <TextArea
                        disabled={readonly}
                        rows={4}/>
                    )}
                  </Form.Item>
                </div>

                <ReadonlyField
                  entityName={VacationScheduleRequest.NAME}
                  propertyName="attachment"
                  disabled={readonly}
                  form={this.props.form}
                  formItemOpts={{style: {marginBottom: "12px"}}}
                />

                <ReadonlyField
                  entityName={VacationScheduleRequest.NAME}
                  propertyName="approved"
                  form={this.props.form}
                  formItemOpts={{style: {marginBottom: "12px"}}}
                  disabled={this.props.rootStore!.vacationRequestStore.type === 'my'}
                />

                {this.globalErrors.length > 0 && (
                  <Alert
                    message={<MultilineText lines={toJS(this.globalErrors)}/>}
                    type="error"
                    style={{marginBottom: "24px"}}
                  />
                )}
              </Form>
            </Card>
          </div>
        </Section>

        {
          this.props.ganttChartVisible && status === 'DONE' && this.props.entityId !== VacationScheduleRequestManagement.NEW_SUBPATH
            ? <Card className="narrow-layout card-actions-container large-section section-container ">
              <VacationGanttChart
                starDate={moment(this.dataInstance.item!.startDate).startOf("year").format('YYYY-MM-DD')}
                endDate={moment(this.dataInstance.item!.startDate).endOf("year").format('YYYY-MM-DD')}/>
            </Card>
            : <></>
        }
      </Page>
    );
  }

  getAbsenceDays = (startDate?: any, endDate?: any) => {
    if (this.personGroupId) {

      startDate = startDate || this.props.form.getFieldValue(`startDate`);
      endDate = endDate || this.props.form.getFieldValue(`endDate`);

      const personGroupId = this.personGroupId;

      if (endDate && startDate && personGroupId) {
        restServices.absenceService.countDays({
          dateFrom: startDate.format('YYYY-MM-DD'),
          dateTo: endDate.format('YYYY-MM-DD'),
          absenceTypeId: this.laborLeave.id,
          personGroupId: personGroupId
        }).then(value => {
          this.props.form.setFields({"absenceDays": {value: value}});
          this.callForceAbsenceDayValidator();
        })
      }
    }
  }

  getAbsenceBalance = (dateFrom?: moment.Moment) => {
    dateFrom = dateFrom || this.props.form.getFieldValue("dateFrom");

    if (dateFrom && this.personGroupId) {
      restServices.vacationScheduleRequestService.getVacationScheduleBalanceDays({
        vacation: {
          id: this.props.entityId !== VacationScheduleRequestManagement.NEW_SUBPATH ? this.props.entityId : undefined,
          personGroupId: this.personGroupId,
          startDate: dateFrom.format(JSON_DATE_TIME_FORMAT)
        }
      })
        .then(value => Math.floor(value))
        .then(value => {
          this.props.form.setFieldsValue({"balance": value});
          this.callForceAbsenceDayValidator();
        })
    }
  }

  callForceAbsenceDayValidator = () => this.props.form.validateFields(['absenceDays'], {
    force: true
  });

  onChangePersonGroupOrDate = (personGroupId: string | undefined = this.props.form.getFieldValue('personGroup'),
                               date: string = this.getDate()) => {
    this.personGroupId = personGroupId;

    this.getAssignmentSchedule();
    this.getAbsenceBalance(undefined);
    this.getAbsenceDays(undefined, undefined);
  }

  getAssignmentSchedule = (date: string = this.getDate()) => {
    restServices.assignmentScheduleService.getAssignmentSchedule({
      personGroupId: this.personGroupId,
      date: date,
      view: '_minimal'
    }).then(value => this.assignmentSchedule = value);
  };

  checkMinDayVacation = async (startDate: moment.Moment = moment(this.dataInstance.item!.startDate)) => {
    if (this.laborLeave && this.laborLeave.minDay && startDate) {
      const year = parseInt(startDate.format('YYYY'));

      let hasMinDayVacation = false;

      await getCubaREST()!.searchEntitiesWithCount(Absence.NAME, {
        conditions: [{
          property: 'personGroup.id',
          operator: '=',
          value: this.personGroupId!
        }, {
          property: 'type.id',
          operator: '=',
          value: this.laborLeave.id
        }, {
          property: 'absenceDays',
          operator: '>=',
          value: this.laborLeave.minDay
        }, {
          property: 'dateFrom',
          operator: '>',
          value: (year - 1) + '-12-31'
        }, {
          property: 'dateFrom',
          operator: '<',
          value: (year + 1) + '-01-01'
        }]
      }).then(value => hasMinDayVacation = (value.count > 0));

      if (!hasMinDayVacation)
        await getCubaREST()!.searchEntitiesWithCount(VacationScheduleRequest.NAME, {
          conditions: [{
            property: 'personGroup.id',
            operator: '=',
            value: this.personGroupId
          }, {
            property: 'absenceDays',
            operator: '>=',
            value: this.laborLeave.minDay
          }, {
            property: 'startDate',
            operator: '>',
            value: (year - 1) + '-12-31'
          }, {
            property: 'endDate',
            operator: '<',
            value: (year + 1) + '-01-01'
          }]
        }, {
          limit: 1
        }).then(value => hasMinDayVacation = (value.count > 0));
      this.hasMinDayVacation = hasMinDayVacation;
      this.callForceAbsenceDayValidator();
    }
  }

  componentDidMount() {
    (async () => {
      restServices.absenceService.getLaborLeave().then(value => this.laborLeave = value);
      this.isNew = this.props.entityId === VacationScheduleRequestManagement.NEW_SUBPATH;
      this.isMy = !this.props.rootStore!.vacationRequestStore.type
        || this.props.rootStore!.vacationRequestStore.type === 'my';

      if (!this.isNew) {
        await this.dataInstance.load(this.props.entityId);
      } else {
        await restServices.portalHelperService.newEntity({entityName: this.dataInstance.entityName})
          .then((response: VacationScheduleRequest) => this.dataInstance.setItem(response));
      }

      this.initPersonGroupDc();

      this.checkMinDayVacation();

    })()

    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      (item) => {

        this.props.form.setFieldsValue(
          {
            ...this.dataInstance.getFieldValues(this.fields),
          }
        );
      }
    );
  }

  initPersonGroupDc = async () => {
    if (!this.isNew || this.isMy) {
      const item = this.dataInstance.item!;

      this.personGroupId = item.personGroup ? item.personGroup.id : this.props.rootStore!.userInfo.personGroupId!;

      this.personGroupDc = collection(PersonGroup.NAME, {
        view: '_minimal',
        filter: {
          conditions: [{
            property: 'id',
            operator: '=',
            value: this.personGroupId
          }]
        },
      })

      if (this.isNew) this.props.form.setFieldsValue({personGroup: this.personGroupId});

      this.onChangePersonGroupOrDate(this.personGroupId, moment().format(JSON_DATE_TIME_FORMAT));

    } else {
      let isHr = false;
      await restServices.hrService.isHr().then(value => isHr = value);

      if (isHr) {
        this.personGroupDc = serviceCollection<SerializedEntity<PersonGroupExt>>(
          Pagination => restServices.hrService.getEmployers(),
          PersonGroupExt.NAME);
      } else {
        const isManager = this.props.rootStore!.vacationRequestStore.type === 'manager';
        const positionGroupId = isManager
          ? this.props.rootStore!.userInfo.positionGroupId!
          : this.props.rootStore!.assistantTeamInfo!.selectedManager!.positionGroupId!;
        this.personGroupDc = serviceCollection<SerializedEntity<PersonGroupExt>>(
          Pagination => restServices.employeeHierarchyService.getChildEmployees({
            positionGroupId: positionGroupId,
            date: moment().format(JSON_DATE_TIME_FORMAT),
            view: '_minimal'
          }),
          PersonGroupExt.NAME);
      }
    }

    this.personGroupDc.load();
  }

  getDate = () => {
    const startDate = this.props.form.getFieldValue('startDate');
    return (startDate ? moment(startDate) : moment()).format(JSON_DATE_TIME_FORMAT);
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }
}

const component = injectIntl(
  withLocalizedForm
  < EditorProps & WrappedComponentProps & RootStoreProp & MainStoreInjected & RouteComponentProps < any >> ({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(VacationScheduleRequestEditComponent)
);
export default withRouter(component);
