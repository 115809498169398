import * as React from "react";
import {inject, observer} from "mobx-react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {observable} from "mobx";
import Section from "../../hoc/Section";
import Page from "../../hoc/PageContentHoc";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'moment/locale/ru'
import {RootStoreProp} from "../../store";
import {collection} from "@cuba-platform/react";
import {CourseScheduleEnrollment} from "../../../cuba/entities/base/tsadv_CourseScheduleEnrollment";
import {Spin, Tooltip} from "antd";

@inject("rootStore")
@observer
class MyCalendar extends React.Component<WrappedComponentProps & RootStoreProp> {
  @observable
  status = "LOADING";
  @observable
  dataCollection = collection<CourseScheduleEnrollment>(CourseScheduleEnrollment.NAME, {
    view: "CourseScheduleEnrollment-view",
    filter: {
      conditions: [{property: "personGroup.id", operator: "=", value: this.props.rootStore!.userInfo.personGroupId!}]
    }
  });
  allEvents: Array<any>


  render() {

    if (this.dataCollection.status === "LOADING") {
      return <Spin spinning={true}/>
    }
    const eventStyleGetter = (event: any, start: Date, end: Date, isSelected: boolean) => {
      let backgroundColor = event.color;
      let style = {
        backgroundColor: backgroundColor,
        color: 'black',
      };
      return {
        style: style,
      };
    }

    const eventComponent = (e: any) => {
      return (
        <span>{e.title}</span>
      )
    }
    const eventWrapperComponent = (props: any) => {
      return (
        <Tooltip overlayStyle={{maxWidth: "500px"}} title={tooltipAccessor(props.event)}>
          {props.children}
        </Tooltip>
      )
    }

    const tooltipAccessor = (event: any) => {
      moment.locale('ru')
      console.log(event.start, event.end)
      let result: number = (moment(event.end).diff(moment(event.start), "days") === 0 ? 1 : moment(event.end).diff(moment(event.start), 'days'))
      return ("Наименование : " + event.title
        + "\nНачало обучения: " + moment(event.start).format('LL')
        + "\nОкончание обучения: " + moment(event.end).format('LL')
        + "\nПродолжительность: " + ((moment(event.end).diff(moment(event.start), "days") === 0 ? 1 : moment(event.end).diff(moment(event.start),
            'days')) +
          ((result % 100 > 10 && result % 100 < 20) || result % 10 === 0 ? " дней" : result % 10 === 1 ? " день" : result % 10 < 5 ? " дня" : " дней"
          )))

    }

    const events = this.dataCollection.items.map((value, index) => {
      const startDate = new Date(value.courseSchedule!.startDate)
      const endDate = new Date(value.courseSchedule!.endDate)
      const colors = [
        'lightgreen',
        'yellow',
        'geekblue',
        'cyan',
        'magenta',
        'orange',
        'red'
      ];
      return ({title: value.courseSchedule!.course!.name, start: startDate, end: endDate, color: colors[index]})
    })
    this.allEvents = events

    const localizer = momentLocalizer(moment)
    return (
      <Page>
        <Section size={"large"}>
          <div>
            <Calendar components={{event: eventComponent, eventWrapper: props => eventWrapperComponent(props)}}
                      tooltipAccessor={""} messages={{
              next: this.props.intl.formatMessage({id: "calendar.next"}),
              month: this.props.intl.formatMessage({id: "calendar.month"}),
              day: this.props.intl.formatMessage({id: "calendar.day"}),
              previous: this.props.intl.formatMessage({id: "calendar.previous"}),
              agenda: this.props.intl.formatMessage({id: "calendar.agenda"}),
              week: this.props.intl.formatMessage({id: "calendar.week"}),
              today: this.props.intl.formatMessage({id: "calendar.today"})
            }}
                      eventPropGetter={(eventStyleGetter)}
                      localizer={localizer}
                      events={this.allEvents}
                      startAccessor="start"
                      endAccessor="end"
                      style={{height: 800}}
                      views={['month', 'week', 'day']}
            />
          </div>
        </Section>
      </Page>

    )
  }
}


export default injectIntl(MyCalendar);