import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { AdaptationTask } from "./tsadv_AdaptationTask";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { AdaptationSetting } from "./tsadv_AdaptationSetting";
import { FileDescriptor } from "./sys$FileDescriptor";
import { ScaleLevel } from "./tsadv$ScaleLevel";
export class AdaptationPlan extends AbstractBprocRequest {
  static NAME = "tsadv_AdaptationPlan";
  planName?: string | null;
  planNameLangRu?: string | null; 
  planNameLangEn?: string | null; 
  planNameLangKz?: string | null; 
  tasks?: AdaptationTask[] | null;
  personGroup?: PersonGroupExt | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  employeePassedProbationaryEvents?: boolean | null;
  employeePassedProbationPeriod?: boolean | null;
  isApprovedPlan?: boolean | null;
  adaptationSetting?: AdaptationSetting | null;
  adaptationStatus?: string | null;
}
export type AdaptationPlanViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "adaptationPlan"
  | "adaptationPlanForAdaptationSetting"
  | "adaptationPlanForApprove";
export type AdaptationPlanView<
  V extends AdaptationPlanViewName
> = V extends "_base"
  ? Pick<
      AdaptationPlan,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "planName"
      | "dateFrom"
      | "dateTo"
      | "employeePassedProbationaryEvents"
      | "employeePassedProbationPeriod"
      | "isApprovedPlan"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      AdaptationPlan,
      | "id"
      | "planName"
      | "dateFrom"
      | "dateTo"
      | "employeePassedProbationaryEvents"
      | "employeePassedProbationPeriod"
      | "isApprovedPlan"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<AdaptationPlan, "id" | "requestNumber" | "requestDate">
  : V extends "adaptationPlan"
  ? Pick<
      AdaptationPlan,
      | "id"
      | "planName"
      | "dateFrom"
      | "dateTo"
      | "employeePassedProbationaryEvents"
      | "employeePassedProbationPeriod"
      | "isApprovedPlan"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "status"
      | "personGroup"
      | "tasks"
    >
  : V extends "adaptationPlanForAdaptationSetting"
  ? Pick<
      AdaptationPlan,
      | "id"
      | "planName"
      | "dateFrom"
      | "dateTo"
      | "employeePassedProbationaryEvents"
      | "employeePassedProbationPeriod"
      | "isApprovedPlan"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "personGroup"
    >
  : V extends "adaptationPlanForApprove"
  ? Pick<
      AdaptationPlan,
      | "id"
      | "planName"
      | "dateFrom"
      | "dateTo"
      | "employeePassedProbationaryEvents"
      | "employeePassedProbationPeriod"
      | "isApprovedPlan"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "personGroup"
    >
  : never;

export class DocumentsFamiliarization extends AbstractBprocRequest{
  static NAME = "tsadv_DocumentsFamiliarization";
  dateFrom?: any | null
  dateTo?: any | null
  description?: string | null
  descriptionEn?: string | null
  descriptionKz?: string | null
  file?: FileDescriptor | null
  name?: string | null
  nameEn?: string | null
  nameKz?: string | null
  type?: DicDocumentsFamiliarizationType | null
  version?: number
}

export class DicDocumentsFamiliarizationType extends AbstractBprocRequest{
  static NAME: string = "tsadv_DicDocumentsFamiliarizationType"
  active?: boolean | null
  description?: string | null
  description1?: string | null
  description2?: string | null
  description3?: string | null
  isDefault?: boolean | null
  isSystemRecord?: boolean | null
  langValue?: string | null
  langValue1?: string | null
  langValue2?: string | null
  langValue3?: string | null
  order?: number | null
  version?: number | null
}

export class AdaptationServiceGetAssessmentResult{
  assessment_type?: string | null 
  date_from?: string | null
  date_to?: string | null
  employeeFullName?: string | null
  person_assessment_id?: string | null
  person_group_id?: string | null
  session_name?: string | null
  status_code?: string | null
  total_result?: number | null
}

export class AdaptationServiceGetAssessmentFormResult{
  entityName: "tsadv$Assessment"
  assessment_competence_id?: string | null
  competence_group_id?: string | null
  competence_name?: string | null
  competence_source?: string | null
  competence_type?: string | null
  competence_type_id?: string | null
  delta?: number | null;
  has_comments?: boolean | null
  participant1_entity_id?: string | null
  participant1_id?: string | null
  participant1_scale_level?: string | null
  participant1_scale_level_id?: string | null
  participant2_entity_id?: string | null
  participant2_id?: string | null
  participant2_scale_level?: string | null
  participant2_scale_level_id?: string | null
  participant3_entity_id?: string | null
  participant3_id?: string | null
  participant3_scale_level?: string | null
  participant3_scale_level_id?: string | null
  person_assessment_id?: string | null
  required_scale_level?: string | null
  required_scale_level_id?: string | null
  required_to_train?: boolean | null
  result?: null
  result_id?: null
  result_percent?: 0
  scale_levels?: string | null | ScaleLevel[]
}