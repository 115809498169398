import React from 'react';
import {getCubaREST, injectMainStore} from "@cuba-platform/react";
import {action, observable, runInAction} from "mobx";
import {Select, Spin} from "antd";
import {observer} from "mobx-react";
import {Course} from "../../../cuba/entities/base/tsadv$Course";
import {SerializedEntity} from "@cuba-platform/rest";

type Props = {
  changeValue:(value:string) => void,
  selectValue?:string,
  disabled?:boolean,
  entityValue?:(course:Course)=>void
}

@injectMainStore
@observer
export default class SearchCourse extends React.Component<Props> {

  @observable loading:boolean = false;

  @observable courses:Course[]

  @action changeCourses (value:Course[]){
    this.courses = value
  }

  @action uniteCourses (value:SerializedEntity<Course>[]){
    if(this.courses)
      this.courses = [...this.courses,...value]
    else {
      this.courses = [];
      this.changeCourses(value)
    }
  }

  @action changeLoading(value:boolean){
    this.loading = value
  }

  @observable courseId: string;

  @action changeCourseId (value:string){
    this.courseId = value
  }

  @observable timer:NodeJS.Timeout;

  @action changeSearchValue (value:string) {
    clearTimeout(this.timer);
    const search = () => {
      getCubaREST()!.searchEntities<Course>(Course.NAME,{
        conditions:[{
          property:"name",
          operator:"contains",
          value:value
        }]
      },{view:"_local",limit:30}).then(courses=>{
        this.changeCourses([])
        this.changeCourses(courses)
        this.changeLoading(false);
      })
    }
    const newTimer = setTimeout(()=>runInAction(()=>{
      search();
    }),500)
    runInAction(()=>this.timer = newTimer)
  }
  render() {
    const  {Option} = Select
    return (
      <Select onChange={(e: string) =>{this.props.changeValue(e);this.props.entityValue&&this.props.entityValue(this.courses!.find(c=>c.id === e)!)}} filterOption={false}
              notFoundContent={this.loading ? <Spin size="small"/> : null} showSearch
              value={this.props.selectValue?this.props.selectValue:undefined}
              disabled={this.props.disabled}
              onSearch={(e) => {
                this.changeSearchValue(e);
              }}>

        {
          this.courses && this.courses.length > 0 && this.courses.map(value =>
            <Option
              key={value.id}
              value={value.id}>{value.name}
            </Option>)
        }
      </Select>
    );
  }
  componentDidMount() {
    if(this.props.selectValue){
      getCubaREST()!.searchEntities<Course>(Course.NAME,{
          conditions:[{
            property:"id",
            operator:"=",
            value:this.props.selectValue
          }]
        },
        {view:"_local"}).then(items=>{
        runInAction(()=>{this.uniteCourses(items)})
      })}
    getCubaREST()!.searchEntities<Course>(Course.NAME,{
      conditions:[{
        property:"id",
        operator:"<>",
        value:this.props.selectValue?this.props.selectValue:""
      }]
    },
      {
        view: "_minimal",
        limit: 30
      }).then(items => {
      this.uniteCourses(items);
    })
  }
}