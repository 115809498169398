import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";

import {Icon, Modal, Table} from "antd";

import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";

import {PositionOverlappingRequest} from "../../../cuba/entities/kzm$PositionOverlappingRequest";
import {SerializedEntity} from "@cuba-platform/rest";
import {PositionOverlappingRequestManagement} from "./PositionOverlappingRequestManagement";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import Button, {ButtonType} from "../../components/Button/Button";
import DataTableFormat from "../../components/DataTable/intex";
import {RootStoreProp} from "../../store";
import {PersonProfile} from "../MyTeam/MyTeamCard";
import {observable} from "mobx";
import {withRouter} from "react-router";
import Column from "antd/es/table/Column";
import {formatDate} from "../../util/Date/Date";
import {ColumnProps} from "antd/es/table";
import {PersonMedalRequest} from "../../../cuba/entities/base/tsadv_PersonMedalRequest";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import PersonMedalRequestManagement from "../Recognition/PersonMedal/PersonMedalRequestManagement";
import {restServices} from "../../../cuba/services";
import moment from "moment";
import {DicPositionsOverlappingType} from "../../../cuba/entities/base/tsadv_DicPositionsOverlappingType";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";

export  type  PersonCardProps = {
  person?: PersonProfile
}
type Props = {
  data?: PositionOverlappingRequest[],
}
type TableData = {
  id: string
  order: number
  date: number
  type:string
  status: string
  link: string
}
@injectMainStore
@inject("rootStore")
@observer
class PositionOverlappingRequestListComponent extends React.Component< PersonCardProps & MainStoreInjected & WrappedComponentProps & RootStoreProp & RouteComponentProps>{

  person = this.props.person;

  dataCollection = collection<PositionOverlappingRequest>(
    PositionOverlappingRequest.NAME,
    { view: "positionOverlappingRequest-edit",
      sort: "-updateTs",
      filter: {
        conditions: [{property: "personGroup.id", operator: "=", value: this.person!.groupId}]
      }
  }
  );

  fields = [
    "requestNumber",

    "requestDate",

    "type",

    "status",
  ];

  @observable selectedRowKey: string | undefined;
  @observable activeClassName: string | undefined;
  @observable selectedData?: SerializedEntity<PositionOverlappingRequest> | undefined;
  @observable selectedRow: string | undefined;
  @observable data?: TableData[] = []
  @observable dicPositionsOverlappingType: Array<any> = []
  @observable dicStatusType: Array<any> = []
  messages = this.props.intl.messages
  handleRowSelectionChange = (selectedRowKeys: string[]) => {

    this.selectedRowKey = selectedRowKeys[0];
     this.selectedData = this.getRequestById(this.selectedRowKey);
  };
  componentDidMount() {
    getCubaREST()!.searchEntities<PositionOverlappingRequest>(PositionOverlappingRequest.NAME,{
      conditions: [{property: "personGroup.id", operator: "=", value: this.person!.groupId}]
    },{view: "positionOverlappingRequest-edit"}).then(value => {
      this.data = this.mapData(value).sort((a, b) => {
        if (a.order > b.order) {
          return -1;
        }
        if (a.order < b.order) {
          return 1;
        }
        return 0;
      })
    })
    getCubaREST()!.loadEntities<DicPositionsOverlappingType>(DicPositionsOverlappingType.NAME,{view:"_local"}).then(value => {
      value.forEach(item=>{
        this.dicPositionsOverlappingType && this.dicPositionsOverlappingType.push(item._instanceName)
      })
    })
    getCubaREST()!.loadEntities<DicRequestStatus>(DicRequestStatus.NAME,{view:"_minimal"}).then(value => {
      value.forEach(item=>{
        this.dicStatusType && this.dicStatusType.push(item._instanceName)
      })
    })
  }




  showDeletionDialog = (e: SerializedEntity<PositionOverlappingRequest>) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "management.browser.delete.areYouSure" },
        { instanceName: e._instanceName }
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        return this.dataCollection.delete(e);
      }
    });
  };

  mapData = (data: PositionOverlappingRequest[]): TableData[] => {
    return data.map(value => ({
        id: value.id,
        order: value.requestNumber,
        date: value.requestDate,
        type:value.type ? value.type['_instanceName'] : 'nn',
        status: value.status ? value.status['_instanceName'] : 'ddd',
        link: PositionOverlappingRequestManagement.PATH + "/" + value.id,
      } as TableData)
    )
  }

  deleteSelectedRow = () => {
    this.showDeletionDialog(this.getRequestById(this.selectedRowKey!));

  };
  onRowSelect = (record: TableData) => {
    this.selectedRowKey = record.id;
    this.selectedData = this.getRequestById(this.selectedRowKey);
  }
  setRowClassName = (record: any) => {
    return this.selectedRowKey !== undefined
    && this.selectedRowKey !== null
    && record.id === this.selectedRowKey ? 'ant-table-row-selected' : '';
  }
  render() {
    if (!this.dataCollection.items)
      return <Icon type="spin"/>;

    let newStatus:Array<any>= []
    let newType:Array<any> = []
    newStatus = this.dicStatusType && this.dicStatusType.map(item=>({text:item, value:item}))
    newType = this.dicPositionsOverlappingType && this.dicPositionsOverlappingType.map(item=>({text:item, value:item}))

    console.log(this.selectedRow)
    console.log(newStatus)
    console.log(newType)

    console.log(this.selectedRowKey)
    console.log(this.dicStatusType)
    console.log(this.dicPositionsOverlappingType)
    const columns = [
      {
        key: 'order',
        dataIndex: 'order',
        title: this.messages['positionOverlapingRequest.table.order'],
        render: (text: any, record: TableData) => {
          const link = record.link
          return (
            <Link
              to={
                link
              }
              key="edit">
              <a
                key={"editPositionOverlapingRequest"}>
                {record.order}
              </a>
            </Link>
          )
        },
        sorter:(a:TableData, b:TableData) => a.order - b.order
      },
      {
        key: 'date',
        dataIndex: 'date',
        title: this.messages['positionOverlapingRequest.table.date'],
        render: (text, record) => {
          const date = record.date
          return (
            <label>
              {formatDate(date, 'DD.MM.YYYY')}
            </label>
          )
        },
        sorter:(a, b) => {
          const aDate = moment(a.date!)
          const bDate = moment(b.date!)
          return bDate.diff(aDate)
        }

      } as ColumnProps<TableData>,
      {
        key: 'type',
        dataIndex: 'type',
        title: this.messages['positionOverlapingRequest.table.type'],
        render:(text, record) => {
          return <div>{record.type} </div>
        },
        filters:newType,
        onFilter:(value, record) => record.type.startsWith(value),
        sorter:(a,b)=>{
          return  a.type.localeCompare(b.type)
        }
      }as ColumnProps<TableData>,
      {
        key: 'status',
        dataIndex: 'status',
        title: this.messages['positionOverlapingRequest.table.status'],
        render:(text, record) => {
          return <div>{record.status} </div>
        },
         filters:newStatus,
        onFilter:(value, record) => record.status.startsWith(value),
        sorter:(a,b)=>{
          return  a.status.localeCompare(b.status)
        }
      }as ColumnProps<TableData>,
    ]
    const buttons = [
      <Link
        to={
          PositionOverlappingRequestManagement.PATH + "/"
          + PositionOverlappingRequestManagement.NEW_SUBPATH + "/"+ this.person!.groupId
        }
        key="create" >
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}>
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>,
      <Link
        to={
          PositionOverlappingRequestManagement.PATH + "/"
          + this.selectedRowKey
        }
        key="edit" >
        <Button buttonType={ButtonType.FOLLOW}
                style={{margin: "0 12px 12px 0"}}
                key="edit"
                disabled={!(this.selectedData && this.selectedData.status && this.selectedData.status.langValue3 === 'Draft')}
        >
          <FormattedMessage id="management.browser.edit"/>
        </Button>
      </Link>,
      <Button
        buttonType={ButtonType.FOLLOW}
        style={{margin: "0 12px 12px 0"}}
        disabled={!(this.selectedData && this.selectedData.status && this.selectedData.status.langValue3 === 'Draft')}
       // disabled={false}
        onClick={this.deleteSelectedRow}
        key="remove"
      >
        <FormattedMessage id="management.browser.remove"/>
      </Button>

    ];



    return (

          <div>
            <div style={{marginBottom: 16}}>
              {buttons}
            </div>

              <Table
                dataSource={this.data}
                rowKey={record => record.id}
                columns={columns}
                rowClassName={this.setRowClassName}
                onRowClick={this.onRowSelect}
                loading={!this.data}
              />



            {/*<DataTableFormat dataCollection={this.dataCollection}*/}
            {/*                 hideSelectionColumn*/}
            {/*                 enableFiltersOnColumns={this.fields}*/}
            {/*                 onRowSelectionChange={this.handleRowSelectionChange}*/}
            {/*                 render={[{*/}
            {/*                   column: this.fields[0],*/}
            {/*                   render: (text, record) => <Link*/}
            {/*                     to={PositionOverlappingRequestManagement.PATH + "/" + (record as PositionOverlappingRequest).id}*/}
            {/*                   >{text}</Link>*/}
            {/*                 }]}*/}
            {/*                 fields={this.fields}/>*/}
          </div>

    );
  }

  getRequestById(id: string): SerializedEntity<PositionOverlappingRequest> {
    const request:
      | SerializedEntity<PositionOverlappingRequest>
      | undefined = this.dataCollection.items.find(request => request.id === id);

    if (!request) {
      throw new Error("Cannot find entity with id " + id);
    }
    return request;
  }

}

const PositionOverlappingRequestList = injectIntl(withRouter(
  PositionOverlappingRequestListComponent)
);

export default PositionOverlappingRequestList;
