import { AbstractParentEntity } from "./AbstractParentEntity";
import { CourseSchedule } from "./tsadv_CourseSchedule";
import { PersonGroupExt } from "./base$PersonGroupExt";
export class CourseScheduleEnrollment extends AbstractParentEntity {
  static NAME = "tsadv_CourseScheduleEnrollment";
  courseSchedule?: CourseSchedule | null;
  personGroup?: PersonGroupExt | null;
  status?: any | null;
  learningCost?: any | null;
  livingCost?: any | null;
  tripCost?: any | null;
  dailyAllowance?: any | null;
  totalCost?: any | null;
}
export type CourseScheduleEnrollmentViewName =
  | "CourseScheduleEnrollment-view"
  | "_base"
  | "_local"
  | "_minimal"
  | "courseScheduleEnrollment-Event.browse";
export type CourseScheduleEnrollmentView<
  V extends CourseScheduleEnrollmentViewName
> = V extends "CourseScheduleEnrollment-view"
  ? Pick<
      CourseScheduleEnrollment,
      | "id"
      | "status"
      | "learningCost"
      | "livingCost"
      | "tripCost"
      | "dailyAllowance"
      | "totalCost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "courseSchedule"
    >
  : V extends "_base"
  ? Pick<
      CourseScheduleEnrollment,
      | "id"
      | "status"
      | "learningCost"
      | "livingCost"
      | "tripCost"
      | "dailyAllowance"
      | "totalCost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      CourseScheduleEnrollment,
      | "id"
      | "status"
      | "learningCost"
      | "livingCost"
      | "tripCost"
      | "dailyAllowance"
      | "totalCost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "courseScheduleEnrollment-Event.browse"
  ? Pick<
      CourseScheduleEnrollment,
      | "id"
      | "status"
      | "learningCost"
      | "livingCost"
      | "tripCost"
      | "dailyAllowance"
      | "totalCost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "courseSchedule"
      | "personGroup"
    >
  : never;
