import React from "react";
import {inject, observer} from "mobx-react";
import {
  collection,
  getCubaREST,
  getEnumCaption,
  getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../../store";
import {FormComponentProps} from "antd/lib/form";
import {MyProfileProps} from "../../../MyProfile/MyProfile";
import {MyTeamCardProps} from "../../MyTeamCard";
import {restServices} from "../../../../../cuba/services";
import Column from "antd/es/table/Column";
import {Assessment} from "../../../../../cuba/entities/base/tsadv$Assessment";
import {Modal, Table} from "antd";
import {PersonCompetence} from "../../../../../cuba/entities/base/tsadv_PersonCompetence";
import {action, observable} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import {PersonCompetenceData} from "../../../../../cuba/entities/base/tsadv_PersonCompetenceData";
import {PersonCompetenceHistory} from "../../../../../cuba/entities/base/tsadv_PersonCompetenceHistory";
import DataTableFormat from "../../../../components/DataTable/intex";
import {LearningBudgetRequestHeader} from "../../../../../cuba/entities/base/tsadv_LearningBudgetRequestHeader";
import {AssignmentExt} from "../../../../../cuba/entities/base/base$AssignmentExt";
import {PersonGroup} from "../../../../../cuba/entities/base/base$PersonGroup";
import {PersonGroupExt} from "../../../../../cuba/entities/base/base$PersonGroupExt";
import {MyTeamData} from "../../MyTeamComponent";
import moment from "moment";

type ScoredData = {
  competenceGroupId?:string,
  hasHistory?:boolean,
  id?:string,
  key?:string,
  parent?:PersonCompetenceData,
  requiredLvl?:string,
  requiredLvlId?:string,
  positionGroupId?:string,
  parentId?:string,
  scaleId?:string,
  name?:string,
  type?:string,
  children?:any,
  courseName:string,
  currentLvl:string,
  personCompetenceId?:string,
}
type Props = {
  currentAssignmentId: string,
};
@inject("rootStore")
@injectMainStore
@observer
class ScoreCard extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & MyTeamCardProps & Props>{



  @observable scoredData:Array<ScoredData>
  @observable competenceGroupId:string
  @observable historyId:string
  @observable visibleModal:boolean = false
  @observable isLoading:boolean = true

  @observable
  historyDataCollection:any

  indicatorCollection = collection<PersonCompetence>(PersonCompetenceHistory.NAME,{
    view:"personCompetenceHistory-browse",
    filter:{
      conditions:[{
        property:"competence.id",
        operator:"=",
        value:"c7610544-2fdc-77b5-f12b-59217d3f4312"
      }]
    }
  })

  render() {

    console.log(this.historyDataCollection)

    const rowSelection = {
      onChange: (selectedRowKeys:any, selectedRows:any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      onSelect: (record:any, selected:any, selectedRows:any) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected:any, selectedRows:any, changeRows:any) => {
        console.log(selected, selectedRows, changeRows);
      },
    };

    return <>
      <div>
        <Table<ScoredData>
          dataSource={this.scoredData}
          scroll={{x:true}}
          bordered={true}
          rowSelection={rowSelection}
          className={"competence_table"}
          tableLayout={"auto"}
          size={"small"}
          loading={this.isLoading}
          columns={[
            {
              title: this.props.intl.formatMessage({id: "cba.scoreCardMain.competency"}),
              dataIndex: 'competenceName',
              key: 'competenceName',
            },{
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.requiredLevel"}),
              dataIndex: 'scaleName',
              key: 'scaleName',
            },
            {
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.currentLevel"}),
              dataIndex: 'currentScaleLevelName',
              key: 'currentScaleLevelName',
            },
            {
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.history"}),
              dataIndex: 'hasHistory',
              key: 'history',
              render: (_, record) => record.hasHistory &&(
                <button
                  style={{
                    color:"#005487",
                    border:"none",
                    background:"transparent",
                    fontWeight:500
                  }}
                  onClick={()=> this.showModal(record.personCompetenceId!)}
                >
                  <div style={{cursor:"pointer"}}>
                    {this.props.intl.formatMessage({id:"cba.scoreCardMain.detail"})}
                  </div>
                </button>
              )
            }
            ,{
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.training"}),
              dataIndex: "courseName",
              key: "courseName"
            }
          ]}
        />
        <Modal
          title={this.props.intl.formatMessage({id:"cba.scoreCardMain.HIstoryModal"})}
          visible={this.visibleModal}
          onOk={this.handleOk}
          className={"cba_modal"}
          onCancel={this.handleOk}
        >
          <Table
            dataSource={this.historyDataCollection && this.historyDataCollection.items!}
            scroll={{x:true}}
            bordered={true}
          >
            <Column<PersonCompetenceHistory>
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.source"})}
              dataIndex={"source"}
              key={"source"}
              render={
                (_, record)=>{
                  return getEnumCaption(record.personCompetenceType, getPropertyInfoNN("personCompetenceType", PersonCompetenceHistory.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!)
                }
              }
            />
            <Column<PersonCompetenceHistory>
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.competency"})}
              dataIndex={"competence"}
              key={"competency"}
              render={
                (text, record)=>{
                  return record.competence && record.competence!['_instanceName']!
                }
              }
            />
            <Column<PersonCompetenceHistory>
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.level"})}
              dataIndex={"level"}
              key={"level"}
              render={
                (text, record)=>{
                  return record.scaleLevel && record.scaleLevel!['_instanceName']
                }
              }
            />
            <Column
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.history.createTs"})}
              dataIndex={"createTs"}
              key={"createTs"}
              render={
                (text , record:PersonCompetenceHistory)=>{
                  const hour = moment(record.createTs).format("HH:mm")
                  const date = moment(record.createTs).format("DD.MM.YYYY")
                  return <>{date+" "+ hour}</>
                }
              }
            />
            <Column
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.history.createdBy"})}
              dataIndex={"createdBy"}
              key={"createdBy"}
              render={
                (text , record:PersonCompetenceHistory)=>{
                  return record.createdBy
                }
              }

            />

          </Table>
        </Modal>
      </div>
    </>;

  }

  @action
  showModal = (id:string) => {
    this.visibleModal = true
    this.historyDataCollection = collection<PersonCompetenceHistory>(PersonCompetenceHistory.NAME,{
      view:"personCompetenceHistory-browse",
      filter:{
        conditions:[{
          property:'personCompetence.id',
          operator:"=",
          value:id
        }]
      }
    })
  };

  @action
  handleOk = () => {
    this.visibleModal = false
  };


  renderModal = () =>{
    const fields = [
      "personCompetenceType",
      "competence",
      "scaleLevel",
    ]
    return <Modal
      title={this.props.intl.formatMessage({id:"cba.scoreCardMain.HIstoryModal"})}
      visible={this.visibleModal}
      onOk={this.handleOk}
    >
      <DataTableFormat
        dataCollection={this.historyDataCollection}
        fields={fields}
      />
    </Modal>
  }

   uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  componentDidMount() {
    getCubaREST()!.searchEntities<PersonGroupExt>(PersonGroupExt.NAME,{
      conditions:[{
        property:"id",
        operator:"=",
        value:this.props!.personGroupId!
      }]
    },{
      view:"personGroup-with-position",
      sort:"updateTs"
    }).then(v=>{
      restServices.scoredCompetencyService.getScorecardData({assignmentId:v[0].currentAssignment!.id})
        .then((value)=>{
          console.log(value)
          this.competenceGroupId = value.competenceGroupId
          this.historyId = value.id
          let newArr:any[] = []
          newArr = value.map((item:ScoredData)=>({
            key:this.uuid(),
            id:item.id,
            personCompetenceId:item.personCompetenceId,
            competenceName:item.name ? item.name : "",
            parentId:item.parent && item.parent.id! ? item.parent.id : null,
            children:[],
            scaleName:item.requiredLvl ? item.requiredLvl : "",
            hasHistory: item.hasHistory ? item.hasHistory : "",
            courseName:item.courseName ? item.courseName : "",
            currentScaleLevelName:item.currentLvl ? item.currentLvl : ""
          }))
          const arrMap = newArr.reduce((acc, el, i) => {
            acc[el.id] = i;
            return acc;
          }, {});
          let roots:any[] = [];
          newArr.forEach((el:any) => {
            if (el.parentId === null) {
              roots.push(el);
            } else {
              newArr[arrMap[el.parentId]].children.push(el);
            }
          });
          console.log("roots = ", roots)
          this.scoredData = roots
          this.isLoading = false
        })
    })



  }
}
export default injectIntl(ScoreCard)