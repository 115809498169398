import * as React from "react";
import {inject, observer} from "mobx-react";
import {injectMainStore, MainStoreInjected, withLocalizedForm} from "@cuba-platform/react";
import {FormComponentProps} from "antd/lib/form";
import {PersonProfile} from "../../MyTeam/MyTeamCard";
import {injectIntl, WrappedComponentProps} from "react-intl";
import PersonInfo from "./PersonInfo";
import {PersonOtherInfoRequest} from "../../../../cuba/entities/base/tsadv_PersonOtherInfoRequest";
import {computed} from "mobx";
import {RootStoreProp} from "../../../store";
import MilitaryFormRequestList from "../../MilitaryFormRequest/MilitaryFormRequestList";
import {MyProfileProps} from "../MyProfile";
import PersonDocumentList from "../../PersonDocument/PersonDocumentList";
import AddressList from "../../Address/AddressList";
import PersonContactList from "../../Contact/PersonContactList";
import {Contact} from "../../../../cuba/entities/base/base$Contact";
import {PersonEducation} from "../../../../cuba/entities/base/tsadv$PersonEducation";
import BankDetailsList from "../../BankDetails/BankDetailsList";
import PersonExperienceList from "../../PersonExpirienceRequest/PersonExperienceList";
import {PersonExperience} from "../../../../cuba/entities/base/tsadv$PersonExperience";
import PersonQualificationList from "../../PersonQualification/PersonQualificationList";
import PersonAwardsDegreesList from "../../PersonAwardsDegrees/PersonAwardsDegreesList";
import DisabilityList from "../../Disability/DisabilityList";
import PersonOtherInfoList from "../../PersonOtherInfo/PersonOtherInfoList";
import MartialStatusList from "../../MartailStatus/MartialStatusList";
import PersonEducationList from "../../PersonEducationRequest/PersonEducationList";
import {Address} from "../../../../cuba/entities/base/tsadv$Address";
import {PersonDocument} from "../../../../cuba/entities/base/tsadv$PersonDocument";
import MilitaryFormList from "../../MilitaryForm/MilitaryFormList";

export  type  PersonCardProps = {
  person?: PersonProfile
}

@injectMainStore
@inject("rootStore")
@observer
class PersonalData extends React.Component<
  MyProfileProps &
  PersonCardProps &
  WrappedComponentProps &
  RootStoreProp &
  FormComponentProps &
  MainStoreInjected
> {

  messages = this.props.mainStore!.messages!;

  @computed get personProfile() {
    return this.props.rootStore!.personProfileStore && this.props.rootStore!.personProfileStore.personProfile
  }

  personDataMenus = [
    {
      id: "personDocumentList",
      component: () => <PersonDocumentList/>,
      header: this.messages[PersonDocument.NAME],
    },
    {
      id: "addressList",
      component: () => <AddressList personGroupId={this.props.person!.groupId}/>,
      header: this.messages[Address.NAME],
    },
    {
      id: "contactList",
      component: () => <PersonContactList personGroupId={this.props.person!.groupId}/>,
      header: this.props.intl.formatMessage({id: "personContact"}),
    },
    {
      id: "personEducationList",
      component: () => <PersonEducationList personGroupId={this.props.person!.groupId}/>,
      header: this.messages[PersonEducation.NAME],
    },
    {
      id: "bankDetailsList",
      component: () => <BankDetailsList personGroupId={this.props.person!.groupId}/>,
      header: this.props.intl.formatMessage({id: "personBankdetails.title"}),
    },
    {
      id: "personExperienceList",
      component: () => <PersonExperienceList personGroupId={this.props.person!.groupId}/>,
      header: this.messages[PersonExperience.NAME]
    },
    {
      id: "personQualitiesList",
      component: () => <PersonQualificationList personGroupId={this.props.person!.groupId}/>,
      header: this.props.intl.formatMessage({id: "personQualification.titile"})
    },
    {
      id: "personAwardsDegreeList",
      component: () => <PersonAwardsDegreesList personGroupId={this.props.person!.groupId}/>,
      header: this.props.intl.formatMessage({id: "personAwardsDegrees.title"})
    },
    {
      id: "militaryFormList",
      component: () => <MilitaryFormList personGroupId={this.props.person!.groupId}/>,
      header:  this.props.intl.formatMessage({id:"militaryFormRequestTitle"})
    },
    {
      id: "disabilityList",
      component: () => <DisabilityList personGroupId={this.props.person!.groupId}/>,
      header: this.props.intl.formatMessage({id:"disabilityRequest.title"})
    },
    {
      id: "personOtherInfoList",
      component: () => <PersonOtherInfoList personGroupId={this.props.person!.groupId}/>,
      header: this.messages[PersonOtherInfoRequest.NAME]
    },
    {
      id: "martialStatusList",
      component: () => <MartialStatusList personGroupId={this.props.person!.groupId}/>,
      header:this.props.intl.formatMessage({id:"beneficiary.title"})
    },
  ];

  @computed get actualDataMenus() {
    let allowedMenuItems = this.props.rootStore!.menu.menuCustomization.map(e => e.menuItem)
    return this.personDataMenus.filter(e => allowedMenuItems.includes(e.id));
  }

  render() {
    return (
      <div>
        <PersonInfo person={this.props.person}/>

        {this.actualDataMenus.map(e => {
          return <>
            <div className={"section-header-container"}>
              {e.header}
            </div>
            {e.component()}
          </>
        })}
      </div>
    )
  }
}

export default injectIntl(
  withLocalizedForm({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(PersonalData)
);