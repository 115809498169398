import { AbstractDictionary } from "./AbstractDictionary";
import { DicAwardMeasure } from "./tsadv_DicAwardMeasure";
export class DicAward extends AbstractDictionary {
  static NAME = "tsadv_DicAward";
  bonusMci?: number | null;
  countMedalMax?: number | null;
  dicAwardMeasure?: DicAwardMeasure | null;
}
export type DicAwardViewName = "_base" | "_local" | "_minimal";
export type DicAwardView<V extends DicAwardViewName> = V extends "_base"
  ? Pick<
      DicAward,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "bonusMci"
      | "countMedalMax"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "description1"
      | "description2"
      | "description3"
      | "description4"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_local"
  ? Pick<
      DicAward,
      | "id"
      | "bonusMci"
      | "countMedalMax"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_minimal"
  ? Pick<
      DicAward,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
    >
  : never;
