import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Button, Col, Row} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

type HeaderProps = {
  create: () => void,
  createPersonAward: () => void
}

@inject("rootStore")
@observer
class Header extends React.Component<HeaderProps & WrappedComponentProps & RootStoreProp> {

  render() {
    return <>
    <Row type={"flex"} justify={"space-between"} style={{alignItems: "center"}}>
      <Row type={"flex"}>
        <h1 style={{margin: 0, marginRight: "10px"}}>
          <FormattedMessage id={"recognition.header.title"}/>
        </h1>
        <Link to={"/recognition/faq"}>
          <a>
            <FormattedMessage id={"recognition.header.readMore"}/>
          </a>
        </Link>
      </Row>
      <Col>
        <Row style={{marginRight: "40px"}}>
          <Button type={"primary"} onClick={this.props.create} style={{marginRight: "15px"}}>
            <FormattedMessage id={"recognition.thanks"}/>
          </Button>
          <Button type={"primary"} onClick={this.props.createPersonAward}>
            <FormattedMessage id={"recognition.nominate"}/>
          </Button>
        </Row>
      </Col>
    </Row>
    <hr/>
  </>
  }
}

export default injectIntl(Header);