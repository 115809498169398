import * as React from "react";
import Button from "../../components/Button/Button";
import { Modal } from "antd";
import { BudgetDelegation } from "../../../cuba/entities/base/tsadv_BudgetDelegation";
import { SerializedEntity } from "@cuba-platform/rest";
import { OrganizationGroupExt } from "../../../cuba/entities/base/base$OrganizationGroupExt";
import {
  collection,
  DataCollectionStore,
  DataTable,
  getCubaREST,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";
import EmployeePickerTable, { SelectRowType } from "./EmployeePickerTable";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { RootStoreProp } from "../../store";
import { action, observable, toJS } from "mobx";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import Notification from "../../util/Notification/Notification";
import { inject, observer } from "mobx-react";
import { Assignment } from "../../../cuba/entities/base/base$Assignment";
import { JSON_DATE_TIME_FORMAT } from "../../util/Date/Date";
import moment from "moment";
import { restServices } from "../../../cuba/services";
import { serviceCollection } from "../../util/ServiceDataCollectionStore";
import PickerTable from "../../components/PickerField/PickerTable";

export type DelegationTableProps = {
  items: DataCollectionStore<BudgetDelegation>
  currentPerson: string
  learningBudgetHeaderId: string
  selectedOrganization: SerializedEntity<OrganizationGroupExt>
}

@injectMainStore
@inject("rootStore")
@observer
class DelegationTable extends React.Component<DelegationTableProps & MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  messages = this.props.mainStore!.messages!
  modalSelectedTemp: AssignmentExt[] = []
  @observable isEmployeeModalVisible: boolean = false
  @observable selectedRow?: AssignmentExt
  @observable assignmentsList = collection<AssignmentExt>(AssignmentExt.NAME, {
    view: 'assignment-view-for-front',
    filter: {
      conditions: [
        {
          property: 'endDate',
          operator: ">=",
          value: moment().format(JSON_DATE_TIME_FORMAT)
        }
      ]
    },
    loadImmediately: false
  }
  )

  assignmentTableFields = [
    {
      caption: this.props.intl!.formatMessage({ id: "personName" }),
      field: 'personGroup._instanceName'
    },
    {
      caption: this.props.intl!.formatMessage({ id: "employeeNumber" }),
      field: 'personGroup.person.employeeNumber'
    },
    {
      caption: this.props.intl!.formatMessage({ id: "organizationGroup" }),
      field: 'organizationGroup._instanceName'
    },
    {
      caption: this.props.intl!.formatMessage({ id: "postionGroup" }),
      field: 'positionGroup._instanceName'
    },
  ]

  assignmentsFields = [
    'personTo',
    'organization'
  ]

  @action
  showPersonModal = () => {
    this.isEmployeeModalVisible = true;
  };

  constructor(props: DelegationTableProps & MainStoreInjected & WrappedComponentProps & RootStoreProp) {
    super(props);
    this.assignmentsList.limit = 10
    this.assignmentsList.sort = 'personGroup.relevantPerson.lastName'
    this.assignmentsList.load()
  }

  onNameFilterChange = (value: string) => {
    if (value || value.trim().length == 0) {
      this.assignmentsList.filter = {
        conditions: [
          {
            property: 'endDate',
            operator: ">=",
            value: moment().format(JSON_DATE_TIME_FORMAT)
          },
          { group: 'OR', conditions : [
            {
              property: 'personGroup.relevantPerson.firstName',
              operator: "contains",
              value: value
            },
            {
              property: 'personGroup.relevantPerson.middleName',
              operator: "contains",
              value: value
            },
            {
              property: 'personGroup.relevantPerson.lastName',
              operator: "contains",
              value: value
            },
            {
              property: 'personGroup.relevantPerson.firstNameLatin',
              operator: "contains",
              value: value
            },
            {
              property: 'personGroup.relevantPerson.lastNameLatin',
              operator: "contains",
              value: value
            },
            {
              property: 'personGroup.relevantPerson.middleNameLatin',
              operator: "contains",
              value: value
            }
          ]},
          
        ]
      }
    } else {
      this.assignmentsList.filter = {
        conditions: [
          {
            property: 'endDate',
            operator: ">=",
            value: moment().format(JSON_DATE_TIME_FORMAT)
          }
        ]
      }
    }

    //"lastName", "firstName", "middleName", "firstNameLatin", "lastNameLatin", "middleNameLatin"
    this.assignmentsList.load();
  }

  onPersonModalOkClick = (persons: AssignmentExt[]) => {
    if (persons[0]) {
      const budgetDelegation = new BudgetDelegation();
      budgetDelegation.fromPerson = { id: this.props.currentPerson };
      budgetDelegation.organization = this.props.selectedOrganization;
      budgetDelegation.personTo = { id: persons[0].personGroup!.id };
      getCubaREST()!.commitEntity(BudgetDelegation.NAME, toJS(budgetDelegation))
        .then(() => this.props.items.load())
        .then(() => {
          if (this.props.learningBudgetHeaderId) {
            this.props.rootStore!.learningBudgetInfo.isForecast ?
              restServices.learningBudgetService.sendNotifyAboutDelegatingBudgetForecast({
                userId: budgetDelegation.personTo!.id,
                responsibleUserId: this.props.currentPerson,
                learningBudgetHeaderId: this.props.learningBudgetHeaderId,
                url: window.location.href
              }) :
              restServices.learningBudgetService.sendNotifyAboutDelegatingBudget({
                userId: budgetDelegation.personTo!.id,
                responsibleUserId: this.props.currentPerson,
                learningBudgetHeaderId: this.props.learningBudgetHeaderId,
                url: window.location.href
              });
          }
        })
        .catch(reason => {
          Notification.error({
            message: this.props.intl.formatMessage({ id: "management.editor.error" })
          });
        });
    }

    this.isEmployeeModalVisible = false;
  }

  onPersonModalCancelClick = () => {
    this.isEmployeeModalVisible = false;
  }

  getRecordById(id: string): SerializedEntity<AssignmentExt> {
    const record:
      | SerializedEntity<AssignmentExt>
      | undefined = this.props.items.items.find(record => record.id === id);

    if (!record) {
      throw new Error("Cannot find entity with id " + id);
    }

    return record;
  }

  handleModalOk = () => {
    this.onPersonModalOkClick(this.modalSelectedTemp)
    this.modalSelectedTemp = []
  }

  handleModalCancel = () => {
    this.onPersonModalCancelClick();
  }

  getModal = () => {
    return <Modal
      title={this.props.intl.formatMessage({ id: "persons" })}
      width={"1200px"}
      style={{ height: "770px" }}
      footer={null}
      visible={true}
      closable={false}>
      <PickerTable tableData={this.assignmentsList} columns={this.assignmentTableFields} selectionMode={'single'}
        onSelect={(rows: AssignmentExt[]) => this.modalSelectedTemp = rows}
        onNameFilterChange={this.onNameFilterChange} />
      <Button onClick={this.handleModalOk} type={'primary'}>
        {this.props.intl!.formatMessage({ id: "cubaReact.dataTable.ok" })}
      </Button>
      <Button onClick={this.handleModalCancel} type={'danger'} style={{ marginLeft: '1%' }}>
        {this.props.intl!.formatMessage({ id: "management.browser.exclude.cancel" })}
      </Button>
    </Modal>
  }

  render() {
    if (this.props.items.status === 'LOADING')
      return this.props.intl.messages['cubaReact.dataTable.loading']
    return <div>
      <DataTable fields={this.assignmentsFields} dataCollection={this.props.items} buttons={[
        <Button
          style={{ margin: '1%' }}
          icon="plus"
          className="add-group-persons-btn"
          onClick={this.showPersonModal}
          key="modal">
        </Button>,
        <Button type="danger"
          className="delete-row-btn"
          style={{ margin: '1%' }}
          disabled={!this.selectedRow}
          icon={'delete'}
          onClick={async () => this.selectedRow ? await this.props.items.delete(this.selectedRow) : undefined}>
        </Button>
      ]}
        onRowSelectionChange={selectedRowKeys => this.selectedRow = selectedRowKeys.length ? this.getRecordById(selectedRowKeys[0]) : undefined} />
      {this.isEmployeeModalVisible ? this.getModal() : null}
    </div>
  }
}

export default injectIntl(DelegationTable)
