import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { collection, injectMainStore, MainStoreInjected } from '@cuba-platform/react';
import { RootStoreProp } from '../../../store';
import welcomeJpg from './welcome.jpg';
import { restServices } from '../../../../cuba/services';
import { SerializedEntity } from '@cuba-platform/rest';
import AllAdaptationTasksTable from '../AdaptationTask/AllAdaptationTasksTable';
import {Card, Col, Row, Spin, Typography} from 'antd';
import MyAdaptationTeamTable from '../MyAdaptationTeamTable';
import MyAdaptationCourses from '../MyCoursesTable';
import MyAdaptationDocs from '../MyAdaptationDocsTable';
import MyCompetentionTable from '../MyCompetentionTable';
import { observable } from 'mobx';
import { PersonGroupExt } from '../../../../cuba/entities/base/base$PersonGroupExt';
import { AdaptationPlan } from '../../../../cuba/entities/base/tsadv_AdaptationPlan';
import { PositionCourse } from '../../../../cuba/entities/base/tsadv_PositionCourse';
import {queryCollection} from "../../../util/QueryDataCollectionStore";
import {LmsSliderImage} from "../../../../cuba/entities/base/tsadv$LmsSliderImage";
import {getBlobUrl, wrapSrcBase64} from "../../../util/util";

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp;

type ImageType = "base64" | "src"

interface SlideFile {
  type: ImageType,
  src: string,
  url?: string
}

@injectMainStore
@inject("rootStore")
@observer
class ForNewEmployeeComponent extends Component<Props> {
  dataCollection = collection<AdaptationPlan>(AdaptationPlan.NAME, {
    view: "adaptationPlan",
    sort: "-updateTs",
    filter: {
      conditions: [
        {
          property: "personGroup.id",
          operator: "=",
          value: this.props.rootStore!.userInfo.personGroupId!,
        },
      ]
    },
    loadImmediately: true,
  });

  PATH = "/for-new-employee"
  
  @observable
  myTeamData: SerializedEntity<PersonGroupExt>[] = [];
  
  @observable
  myTeamHrData: SerializedEntity<PersonGroupExt>[] = [];

  coursesData: SerializedEntity<PositionCourse>[]= [];

  @observable adaptationImage:SlideFile[] = []
  @observable isImgLoaded:boolean = false
  componentDidMount(){
    this.fetchHrs();
    this.fetchManagers();
    const sliderImages = queryCollection<LmsSliderImage>(LmsSliderImage.NAME, "sliderImages", {
      code: "ADAPTATION_PIC"
    });
    console.log(sliderImages)
    sliderImages.afterLoad = () => {
      sliderImages.items.forEach(si => {
        getBlobUrl(si.image!.id).then(fileUrl => {
          this.adaptationImage.push({
            url: si.url ? si.url : undefined,
            type: "src",
            src: fileUrl
          });
        })
      });
    };
    console.log(this.adaptationImage)
  }

  fetchHrs = async () => {
    // const hrs = await restServices.adaptationPlanRequestService.getHrs();
    const hrs = await restServices.adaptationPlanRequestService.getEmployeeCompanyHrs(this.props.rootStore!.userInfo.personGroupId!);
    const HRsMapById = new Map<string, SerializedEntity<PersonGroupExt>>();
    hrs.forEach(hr=>{
      const personGroupId = hr.person!.group!.id;
      if(!HRsMapById.has(personGroupId)){
        HRsMapById.set(personGroupId, hr);
      }
    });
    this.myTeamHrData = Array.from(HRsMapById.values());
  }

  fetchManagers = async () => {
    const managers = await restServices.adaptationPlanRequestService
      .getManagers(this.props.rootStore!.userInfo!.personGroupId!);
    const managersMapById = new Map<string, SerializedEntity<PersonGroupExt>>();
    managers.forEach(manager=>{
      const personGroupId = manager.person!.group!.id;
      if(!managersMapById.has(personGroupId)){
        managersMapById.set(personGroupId, manager);
      }
    });
    this.myTeamData = Array.from(managersMapById.values());
  }
  
  render() {
    const initialAdaptationPlan: SerializedEntity<AdaptationPlan> | null = this.dataCollection.items[0] || null;
    return (
      <Card style={{padding: '16px'}}>

        {this.adaptationImage.length === 0
          ? <> </>
          : <img src={this.adaptationImage && this.adaptationImage[0] && this.adaptationImage[0]!.type! === "base64"
              ? wrapSrcBase64(this.adaptationImage && this.adaptationImage[0] && this.adaptationImage[0]!.src!)
              : this.adaptationImage && this.adaptationImage[0] && this.adaptationImage[0]!.src!}
              alt="welcome" width="100%"/>}

        <h1 style={{
          fontSize: '4vw',
          textAlign: 'center',
        }}>
          <FormattedMessage id={'adaptationPlan.welcome'} defaultMessage='Добро пожаловать в команду!!!'/>
        </h1>
        <Typography.Title level={3}>
          <FormattedMessage id={'adaptationPlan.myAdaptationTeam'} defaultMessage='Моя команда на период адаптации'/>
        </Typography.Title>
        <Row gutter={16}>
          <Col xs={24} style={{marginBottom: '20px'}}>
            <Row gutter={16}>
              <Col md={12}>
                <MyAdaptationTeamTable data={this.myTeamHrData} title={this.props.intl.formatMessage({id:"myAdaptationTeamTable.hr"})}/>
              </Col>
              <Col md={12}>
                <MyAdaptationTeamTable data={this.myTeamData} title={this.props.intl.formatMessage({id:"myAdaptationTeamTable.manager"})}/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} style={{marginBottom: '20px'}}>
            <Typography.Title level={3}>
              <FormattedMessage id={'adaptationPlan.myTasks'} defaultMessage='Мои задачи'/>
            </Typography.Title>
            <AllAdaptationTasksTable personGroupId={this.props.rootStore!.userInfo!.personGroupId!}/>
          </Col>
          {initialAdaptationPlan && 
            <Col xs={24} style={{marginBottom: '20px'}}>
              <Typography.Title level={3}>
                <FormattedMessage id={'adaptationPlan.myCourses'} defaultMessage='Обязательные к прохождению курсы'/>
              </Typography.Title>
              <MyAdaptationCourses adaptationPlanId={initialAdaptationPlan.id!}/>
            </Col>
          }
          {initialAdaptationPlan &&
            <Col xs={24} style={{marginBottom: '20px'}}>
              <Typography.Title level={3}>
                <FormattedMessage id={'adaptationPlan.myDocs'} defaultMessage='Документы для ознакомления'/>
              </Typography.Title>
              <MyAdaptationDocs adaptationPlanId={initialAdaptationPlan.id!}/>
            </Col>
          }
          <Col xs={24} style={{marginBottom: '20px'}}>
            <Typography.Title level={3}>
              <FormattedMessage id={'myСompetention.competence-assessment'} defaultMessage='Оценка компетенции'/>
            </Typography.Title>
            <MyCompetentionTable personGroupId={this.props.rootStore!.userInfo.personGroupId!} />
          </Col>
        </Row>
      </Card>
    )
  }

}

const ForNewEmployee = injectIntl(ForNewEmployeeComponent);

export default ForNewEmployee
