import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Header from "../Components/Header";
import {Card, Col, Row} from "antd";
import RecognitionTop from "../Components/RecognitionTop";
import CreateRecognition from "../Components/CreateRecognition";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import RecognitionList from "../Components/RecognitionList";
import {observable, runInAction} from "mobx";
import {recognitionAllWallTypeStore} from "../store/RecognitionsStore";
import PersonAwardCreateModal from "../Components/PersonAwardCreateModal";


@inject("rootStore")
@injectMainStore
@observer
class RecognitionPage extends React.Component<RootStoreProp & WrappedComponentProps> {

  @observable showCreate: boolean = false;
  @observable showPersonAwardCreateModal: boolean = false;

  render() {
    return <>
      {this.showCreate && <CreateRecognition visible={this.showCreate}
                                             onClose={() => runInAction(() => this.showCreate = false)}
                                             onCreated={() => recognitionAllWallTypeStore.reloadRecognitions()}/>}

      {this.showPersonAwardCreateModal && <PersonAwardCreateModal visible={this.showPersonAwardCreateModal}
                                                                  onClose={() => runInAction(() => this.showPersonAwardCreateModal = false)}/>}

      <Header create={() => runInAction(() => this.showCreate = true)}
              createPersonAward={() => runInAction(() => this.showPersonAwardCreateModal = true)}/>

      <Row>
        <Col xs={14}>
          <Card className="narrow-layout large-section section-container">
            <RecognitionList recognitionsStore={recognitionAllWallTypeStore}/>
          </Card>
        </Col>
        <Col xs={10}>
          <RecognitionTop/>
        </Col>
      </Row>
    </>
  }

  componentDidMount() {
    recognitionAllWallTypeStore.personGroup = this.props.rootStore!.userInfo!.personGroupId!;
    if (recognitionAllWallTypeStore.personGroup) {
      recognitionAllWallTypeStore.reloadRecognitions();
    }
  }
}

export default injectIntl(RecognitionPage);