import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import KeyPositionList from "./KeyPositionList";

type Props = RouteComponentProps<{ entityId: string, addressId?: string }>;

@observer
export class KeyPositionManagement extends React.Component<Props> {
  static PATH = "/keyPosition";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId, addressId} = this.props.match.params;
    return (
      <>
        <KeyPositionList  />
      </>
    );
  }
}