import * as React from "react";
import { Form, Modal, Button, Input, Spin } from "antd";
import { Successor } from "../../../../cuba/entities/base/tsadv$Successor";
import { injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import { observer } from "mobx-react";
import Img from "../../../components/Img";
import { getBlobUrl } from "../../../util/util";
import { injectIntl, WrappedComponentProps } from "react-intl";
import moment from "moment";
import { observable } from "mobx";
interface IProps {
  personData: Successor | undefined;
  isSuccessorModalVisible: boolean;
  toggleVisibility: () => void;
}
@injectMainStore
@observer
class TalentPoolProgramSuccessorModal extends React.Component<
  IProps & WrappedComponentProps & MainStoreInjected
> {
  @observable imgUrl: string;
  componentDidMount() {
    if (this.props.personData !== undefined) {
      let personData = this.props.personData;
      console.log(this.props.personData);
      getBlobUrl(
        personData &&
          personData!.personGroup &&
          personData!.personGroup.list &&
          personData!.personGroup.list[0].image &&
          personData!.personGroup.list[0].image.id
      ).then(response => (this.imgUrl = response));
    }
  }

  render() {
    const {
      personData,
      isSuccessorModalVisible,
      toggleVisibility
    } = this.props;

    if (
      (personData &&
        Object.keys(personData).length === 0 &&
        Object.getPrototypeOf(personData) === Object.prototype) ||
      personData === undefined
    ) {
      return <Spin spinning={true} />;
    }

    return (
      <Modal
        centered={true}
        bodyStyle={{ display: "flex", justifyContent: "center" }}
        width={1000}
        closable={false}
        style={{ width: "auto", height: "auto" }}
        title={this.props.intl.formatMessage({
          id: "reservist.profileModalName"
        })}
        footer={[
          <Button
            style={{ width: 150 }}
            type={"primary"}
            onClick={() => {
              for (let member in personData) delete personData[member];
              toggleVisibility();
            }}
          >
            {this.props.intl.formatMessage({ id: "reservistOk" })}
          </Button>
        ]}
        visible={isSuccessorModalVisible}
      >
        <Spin
          spinning={
            personData &&
            Object.keys(personData).length === 0 &&
            Object.getPrototypeOf(personData) === Object.prototype &&
            personData !== undefined
          }
        >
          <Form
            layout={"horizontal"}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div className={"photoContainer"}>
              <Form.Item>
                <div style={{ height: "100%", width: "300px" }}>
                  <Img
                    style={{ height: "100%", width: "300px" }}
                    type="promise"
                    src={getBlobUrl(
                      personData &&
                        personData!.personGroup &&
                        personData!.personGroup.list &&
                        personData!.personGroup.list[0].image &&
                        personData!.personGroup.list[0].image.id
                    )}
                  />
                </div>
              </Form.Item>
            </div>
            <div
              className={"elseContainer"}
              style={{ width: "65%", height: "300px" }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr",
                  alignItems: "center",
                  marginBottom: 24
                }}
              >
                {this.props.intl.formatMessage({ id: "reservist.fullName" })}{" "}
                <Input
                  style={{ marginLeft: 10 }}
                  disabled={true}
                  value={
                    personData &&
                    personData!.personGroup &&
                    personData!.personGroup!["_instanceName"]
                  }
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr",
                  alignItems: "center",
                  marginBottom: 24
                }}
              >
                {this.props.intl.formatMessage({
                  id: "reservist.keyPosition"
                })}{" "}
                <Input
                  style={{ marginLeft: 10 }}
                  disabled={true}
                  value={
                    personData &&
                    personData!.succession &&
                    personData!.succession!["_instanceName"]
                  }
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr",
                  alignItems: "center",
                  marginBottom: 24
                }}
              >
                {this.props.intl.formatMessage({
                  id: "reservistOrganizationGroup"
                })}{" "}
                <Input
                  style={{ marginLeft: 10 }}
                  disabled={true}
                  value={
                    personData &&
                    personData.succession! &&
                    personData.succession!.positionGroup! &&
                    personData.succession!.positionGroup!.organizationGroup!
                      ? personData!.succession!.positionGroup!
                          .organizationGroup!["_instanceName"]!
                      : ""
                  }
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr",
                  alignItems: "center",
                  marginBottom: 24
                }}
              >
                {this.props.intl.formatMessage({ id: "employee.hireDate" })}{" "}
                <Input
                  style={{ marginLeft: 10 }}
                  disabled={true}
                  value={moment(
                    personData &&
                      personData.personGroup &&
                      personData.personGroup.person!.hireDate
                  ).format("DD.MM.YYYY")}
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 4fr",
                  alignItems: "center",
                  marginBottom: 0
                }}
              >
                {this.props.intl.formatMessage({
                  id: "reservist.dateStatus"
                })}{" "}
                <Input
                  style={{ marginLeft: 10 }}
                  disabled={true}
                  value={moment(personData && personData.startDate!).format(
                    "DD.MM.YYYY"
                  )}
                />
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
    );
  }
}
export default injectIntl(TalentPoolProgramSuccessorModal);
