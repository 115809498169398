import * as React from "react";
import {observer} from "mobx-react";

import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import WccfList from "../Wccf/WccfList";
import RvdList from "../HrManagerRequestRvd/RvdList";

@injectMainStore
@observer
class HrRequestManagerPageComponent extends React.Component<MainStoreInjected & WrappedComponentProps> {

  render() {

    return (
      <Page pageName={this.props.intl.formatMessage({id: "menu.hr-request-manager"})}>
        <Section size="large">
          <WccfList/>
        </Section>
        <Section size="large">
          <RvdList/>
        </Section>
      </Page>
    );
  }
}

const HrRequestManagerPage = injectIntl(HrRequestManagerPageComponent);

export default HrRequestManagerPage;
