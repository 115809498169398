import * as React from "react";
import "./style.css"
import {inject, observer} from "mobx-react";
import {RouteComponentProps} from "react-router";

import {
  collection,
  DataCollectionStore, getCubaREST,
  getEnumCaption,
  getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import Button, {ButtonType} from "../../components/Button/Button";
import {Icon, Modal, Table} from "antd";
import Page from "../../hoc/PageContentHoc";
import moment, {Moment} from "moment";
import {observable, reaction} from "mobx";
import {ColumnProps} from "antd/es/table";
import {JobRequest} from "../../../cuba/entities/base/tsadv$JobRequest";
import {DEFAULT_DATE_TIME_PATTERN} from "../../util/Date/Date";
import {SerializedEntity} from "@cuba-platform/rest";
import {PersonAttachment} from "../../../cuba/entities/base/tsadv$PersonAttachment";
import {downloadFile} from "../../util/util";
import {JobRequestStatus} from "../../../cuba/enums/enums";
import TextArea from "antd/es/input/TextArea";
import {JobRequestNote} from "../../../cuba/entities/base/tsadv_JobRequestNote";
import {withRouter} from "react-router-dom";
import Notification from "../../util/Notification/Notification";

type JobRequestData = {
  jobRequestId: string;
  attachment: PersonAttachment;
}

type JobRequestProps = {
  entityId: string;
  status: string;
}

@injectMainStore
@inject("rootStore")
@observer
class CandidateJobRequestBrowseComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & JobRequestProps & RouteComponentProps> {

  @observable
  columnsOptions = {
    'current': true,
    'changes': true,
    'difference': true,
  };
  @observable
  listOfAttachment: JobRequestData[] = [];
  @observable
  isCommentModalVisible: boolean = false
  @observable
  commentValue: string = "";
  @observable
  isRejectCandidateAction: boolean
  @observable
  actionConfirmCaption: string

  currentJobRequest?: JobRequest;
  selectedStatus?: JobRequestStatus;

  jobRequestsDc = collection<JobRequest>(JobRequest.NAME, {
    view: "job-request-front-view",
    loadImmediately: false
  });

  constructor(props: any) {
    super(props);
    addCollectionLoadedCallback(this.jobRequestsDc, async items => {
      if (items.length > 0) {
        let promises: any = []
        items.forEach(it => {
          promises.push(new Promise((resolve, reject) => {
            getCubaREST()!.searchEntities<PersonAttachment>(PersonAttachment.NAME, {
              conditions: [{
                property: 'personGroup.id',
                operator: '=',
                value: it.candidatePersonGroup!.id
              }, {
                property: 'category.code',
                operator: '=',
                value: 'RESUME'
              }]
            }, {
              limit: 1,
              view: 'personAttachment.view'
            })
              .then(values => {
                resolve({jobRequestId: it.id, attachment: values[0]} as JobRequestData)
              });
          }));
        })
        await this.fillMap(promises)
      }
    })
  }

  locale = this.props.mainStore!.locale!;

  fillMap = async (promises: JobRequestData[]) => {
    Promise.all(promises).then(values => {
      this.listOfAttachment = values
    })
  }

  approveRequest = (request: JobRequest) => {
    this.isRejectCandidateAction = false
    this.actionConfirmCaption = this.props.intl.formatMessage({id: "jobRequestApproveCaption"})
    this.currentJobRequest = request;
    this.selectedStatus = JobRequestStatus.MANAGER_APPROVE
    this.isCommentModalVisible = true
  };

  rejectRequest = (request: JobRequest) => {
    this.isRejectCandidateAction = true
    this.actionConfirmCaption = this.props.intl.formatMessage({id: "jobRequestRejectCaption"})
    this.currentJobRequest = request;
    this.selectedStatus = JobRequestStatus.MANAGER_REJECT
    this.isCommentModalVisible = true
  };

  downLoadFile = (attachment: PersonAttachment) => {
    const file = attachment.attachment
    downloadFile(file ? file.id : null, file ? file.name ? file.name : "" : "",
      file ? file.extension ? file.extension : "" : ""
      , this.props.intl.formatMessage({id: "fileNotFound"}))
  };

  onCommentOkClick = () => {
    if (this.isRejectCandidateAction && this.commentValue.trim() === ""){
      Notification.error({message: this.props.intl.formatMessage({id: "jobRequestRejectValidationError"})});
    } else {
      const jobRequestNote = new JobRequestNote();
      jobRequestNote.jobRequest = this.currentJobRequest
      jobRequestNote.text = this.commentValue;
      getCubaREST()!.commitEntity(JobRequestNote.NAME, jobRequestNote)
      this.currentJobRequest!.requestStatus = this.selectedStatus
      getCubaREST()!.commitEntity(JobRequest.NAME, this.currentJobRequest!)
        .then(val => {
          this.commentValue = ""
          this.isCommentModalVisible = false
          this.currentJobRequest = undefined
          this.selectedStatus = undefined
          this.jobRequestsDc.load()
        })
    }

  };

  renderCVColumn = (text: any, row: JobRequest, index: number) => {
    const personAttachment = this.listOfAttachment.find(it => it.jobRequestId === row.id)
    if (!personAttachment) {
      return <div/>
    } else {
      return (
        <label
          style={{color: '#005487', cursor: 'pointer'}}
          onClickCapture={() => {
            this.downLoadFile(personAttachment.attachment)
          }}>
          {
            personAttachment.attachment ? personAttachment.attachment.filename : ""
          }
        </label>
      )
    }
  }

  renderStatusColumn = (text: any, row: JobRequest, index: number) => {
    return (
      <label>
        {getEnumCaption(row.requestStatus, getPropertyInfoNN("requestStatus", JobRequest.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!)}
      </label>
    )
  }

  renderActionsColumn = (text: any, row: JobRequest, index: number) => {
    if (row.requestStatus !== JobRequestStatus.CV_IN_CUSTOMER) {
      return <div/>
    } else {
      return (
        <div>
          <label className={'approve-btn'}
                 onClickCapture={() => {
                   this.approveRequest(row)
                 }}>
            <Icon type={'check'}/>
          </label>
          <label className={'reject-btn'}
                 onClickCapture={() => {
                   this.rejectRequest(row)
                 }}>
            <Icon type={'cross'}/>
          </label>
        </div>
      )
    }
  }

  renderDateColumn = (text: any, row: JobRequest, index: number) => {
    return (
      <label>{moment(row.updateTs).format(DEFAULT_DATE_TIME_PATTERN)}</label>
    )
  }


  render() {
    if (this.jobRequestsDc.status === 'LOADING' || !this.listOfAttachment) {
      return <Icon type="spin"/>;
    }
    const messages = this.props.mainStore!.messages!;
    const locale = this.locale;

    let columns = [
      {
        title: this.props.intl.formatMessage({id: 'candidatePersonGroupName'}),
        dataIndex: 'candidatePersonGroup._instanceName',
        key: 'candidatePersonGroup',
      },
      {
        title: this.props.intl.formatMessage({id: 'cvCaption'}),
        key: 'candidatePersonGroup',
        render: this.renderCVColumn
      },
      {
        title: this.props.intl.formatMessage({id: 'lastUpdateDate'}),
        dataIndex: 'updateTs',
        key: 'lastUpdateDate',
        render: this.renderDateColumn
      },
      {
        title: this.props.intl.formatMessage({id: 'jobRequestStatus'}),
        dataIndex: 'requestStatus',
        key: 'jobRequestStatus',
        render: this.renderStatusColumn
      },
      {
        key: 'actions',
        render: this.renderActionsColumn
      }
    ] as ColumnProps<JobRequest>[]

    return (
      <Page pageName={this.props.intl.formatMessage({id: "jobRequestBrowse"})}>
        <Table columns={columns}
               dataSource={this.jobRequestsDc.items}
               rowKey={(r: JobRequest) => r.id}
               className="kzm-tree-table"
               indentSize={10}
               size={'small'} bordered={true}
               tableLayout={"auto"}
        />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '10px'}}>
          <Button
            className={"default-btn-with-border"}
                  onClick={() => {
                    this.props.history!.goBack();
                  }}>
            {this.props.intl.formatMessage({id: "close"})}
          </Button>
        </div>
        <Modal
          title={this.actionConfirmCaption}
          width={"1200px"}
          style={{height: "770px"}}
          footer={null}
          closable={false}
          visible={this.isCommentModalVisible}>
          <div>
            <label style={{color:'black', fontSize:'medium'}}>{this.props.intl.formatMessage({id: "export.comment"})}</label>
            <div style={{height:'10px'}}/>
            <TextArea autoSize={false} rows={10} value={this.commentValue}
                      required={true}
                      onChange={val => this.commentValue = val.target.value}/>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '10px'}}>
              <Button className={"default-btn"}
                      onClick={this.onCommentOkClick}>
                {"OK"}
              </Button>
              <Button className={"default-btn"}
                      onClick={() => {
                        this.commentValue = "";
                        this.currentJobRequest = undefined
                        this.selectedStatus = undefined
                        this.isCommentModalVisible = false
                      }}>
                {this.props.intl.formatMessage({id: "close"})}
              </Button>
            </div>
          </div>
        </Modal>
      </Page>
    );
  }

  componentDidMount() {
    this.jobRequestsDc.filter = {
      conditions: [{
        property: "requisition.id",
        operator: "=",
        value: this.props.entityId!
      }]
    };
    if (this.props.status !== 'all') {
      this.jobRequestsDc.filter.conditions.push(
        {
          property: "requestStatus",
          operator: "=",
          value: this.props.status!.toUpperCase()
        }
      )
    }
    this.jobRequestsDc.load();

  }

}

const CandidateJobRequestBrowse = injectIntl(withRouter(CandidateJobRequestBrowseComponent));

export default CandidateJobRequestBrowse;

function addCollectionLoadedCallback<T>(collection: DataCollectionStore<T>, callback: (items: Array<SerializedEntity<T>>) => void) {
  reaction(() => collection.status === 'DONE', (arg, r) => {
    callback(collection.items)
    r.dispose()
  }, {
    fireImmediately: false
  })
}