import * as React from "react";
import {observer} from "mobx-react";
import {injectIntl, WrappedComponentProps} from "react-intl";

import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";

import "../../../../app/App.css";

import {AssignedGoal} from "../../../../cuba/entities/base/tsadv$AssignedGoal";
import {observable} from "mobx";
import {Button, Modal, Table} from "antd";
import {restServices} from "../../../../cuba/services";

type GoalHistoryData = {
  id: string,
  from?: string | null,
  to?: string | null,
  kpiName?: string | null,
  description?: string | null,
  weight?: any | null,
  result?: any | null,
  children?: GoalHistoryData[] | null
}

type Props = GoalHistoryProps;

type GoalHistoryProps = {
  goal: AssignedGoal,
};

@injectMainStore
@observer
class GoalHistory extends React.Component<Props & WrappedComponentProps & MainStoreInjected> {

  @observable
  data: GoalHistoryData[] = [];

  columns = [
    {
      title: this.props.intl.formatMessage({id: 'goalHistory.from'}),
      dataIndex: 'from',
      key: 'from',
    },
    {
      title: this.props.intl.formatMessage({id: 'goalHistory.to'}),
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: this.props.intl.formatMessage({id: 'goalHistory.kpiName'}),
      dataIndex: 'kpiName',
      key: 'kpiName',
    },
    {
      title: this.props.intl.formatMessage({id: 'goalHistory.description'}),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: this.props.intl.formatMessage({id: 'goalHistory.weight'}),
      dataIndex: 'weight',
      key: 'weight',
    },
    {
      title: this.props.intl.formatMessage({id: 'goalHistory.result'}),
      dataIndex: 'result',
      key: 'result',
    },
  ];

  showGoalHistory = () => {
    Modal.info({
      width: 900,
      title: this.props.intl.formatMessage({id: "history"}),
      content: (<Table dataSource={this.data.slice()}
                       defaultExpandedRowKeys={this.getAllKeys()}
                       rowKey={'id'}
                       key={'table'}
                       pagination={false}
                       columns={this.columns}/>)
    });
  };

  getAllKeys = () => {
    const keys: string[] = [];
    if (this.data)
      this.data.forEach(value => keys.push(...this.getKeys(value)))
    return keys;
  }

  getKeys = (data: GoalHistoryData) => {
    const strings = [data.id];
    if (data.children)
      data.children!.forEach(v => strings.push(...this.getKeys(v)));
    return strings
  }

  render() {
    return <Button type="link"
                   style={{padding: 0}}
                   onClick={this.showGoalHistory}>
      {this.props.intl.formatMessage({id: "history"})}
    </Button>;
  }

  parseToGoalHistoryData = (goal: AssignedGoal): GoalHistoryData => {
    console.log(goal);
    return {
      id: goal.id,
      from: goal.assignedByPersonGroup && goal.assignedByPersonGroup["_instanceName"],
      to: goal.personGroup && goal.personGroup["_instanceName"]
        || goal.assignedPerformancePlan && goal.assignedPerformancePlan.assignedPerson && goal.assignedPerformancePlan.assignedPerson["_instanceName"],
      kpiName: goal.goalString || goal.goal && goal.goal["_instanceName"],
      description: goal.successCriteria || goal.goal && goal.goal["successCriteriaLang"],
      weight: goal.weight,
      result: goal.result
    }
  }

  fillHierarchy = (historyData: GoalHistoryData, goals: Array<AssignedGoal>) => {
    historyData.children = goals.filter(value => (value.parentAssignedGoal && value.parentAssignedGoal.id) === historyData.id)
      .filter(value => !!value)
      .map(this.parseToGoalHistoryData);
    historyData.children.filter(value => !!value).forEach(value => this.fillHierarchy(value, goals));
  }

  componentDidMount() {

    (async () => {

      let fullData: GoalHistoryData | undefined = undefined;

      await restServices!.assignedGoalService.getAssignedGoalFullHierarchy({assignedGoalId: this.props.goal.id})
        .then(value => {
          if (value) {
            let parent = value.find(data => !data.parentAssignedGoal);
            if (!parent){
              parent = value.find(data => !value.find(value1 => data.parentAssignedGoal!.id === value1.id))!;
            }
            fullData = this.parseToGoalHistoryData(parent);
            this.fillHierarchy(fullData, value);
          }
        })

      this.data = [fullData!];
    })()
  }
}

export default injectIntl(GoalHistory);
