import * as React from "react";
import {observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";

import {observable} from "mobx";

import {
  collection,
  getCubaREST,
  getEnumCaption,
  getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";
import {AddressRequestManagement} from "../AddressRequest/AddressRequestManagement";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Address} from "../../../cuba/entities/base/tsadv$Address";
import DataTableFormat from "../../components/DataTable/intex";
import {withRouter} from "react-router";
import {AddressRequest} from "../../../cuba/entities/base/tsadv$AddressRequest";
import Button, {ButtonType} from "../../components/Button/Button";
import {PersonOtherInfoRequest} from "../../../cuba/entities/base/tsadv_PersonOtherInfoRequest";
import {PersonOtherInfoRequestManagement} from "../PersonOtherInfoRequest/PersonOtherInfoRequestManagement";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {IndividualDevelopmentPlan} from "../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";

export type AddressListProps = {
  personGroupId: string
}

@injectMainStore
@observer
class PersonOtherInfoListComponent extends React.Component<AddressListProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps> {
  dataCollection = collection<PersonExt>(PersonExt.NAME, {
    view: "person.browse",
    sort: "-updateTs",
    limit:1,
    filter: {
      conditions: [{
        property: 'group.id',
        operator: '=',
        value: this.props.personGroupId
      }]
    }
  });

  fields = [
    "childUnder18WithoutFatherOrMother",

    "childUnder14WithoutFatherOrMother"
  ];

  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
      <Button
        buttonType={ButtonType.FOLLOW}
        style={{margin: "0 12px 12px 0"}}
        disabled={!this.selectedRowKey}
        onClick={this.openRequest}
        key="editBtn"
      >
        <FormattedMessage id="management.browser.edit"/>
      </Button>
    ];

    return (
      <div className={"personOtherInfo_table"}>
        <DataTableFormat
          dataCollection={this.dataCollection}
          enableFiltersOnColumns={[]}
          fields={this.fields}
          onRowSelectionChange={this.handleRowSelectionChange}
          hideSelectionColumn={true}
          buttons={buttons}
          render={[
            {
              column:this.fields[0],
              render:((text, record) =>  getEnumCaption((record as PersonOtherInfoRequest).childUnder18WithoutFatherOrMother, getPropertyInfoNN("childUnder18WithoutFatherOrMother", PersonOtherInfoRequest.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!))
            },
            {
              column:this.fields[1],
              render:((text, record) =>  getEnumCaption((record as PersonOtherInfoRequest).childUnder14WithoutFatherOrMother, getPropertyInfoNN("childUnder14WithoutFatherOrMother", PersonOtherInfoRequest.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!))
            }
          ]}
        />
      </div>
    );
  }

  openRequest = () => {
    this.getRequestId()
      .then(value => {
        this.props.history!.push(PersonOtherInfoRequestManagement.PATH + '/' + value)
      });
  }

  getRequestId = (): Promise<string> => {
    return getCubaREST()!.searchEntities<PersonOtherInfoRequest>(PersonOtherInfoRequest.NAME, {
      conditions: [{
        property: 'personGroup.id',
        operator: '=',
        value: this.props.personGroupId!
      }, {
        property: 'status.code',
        operator: 'in',
        value: ['DRAFT', 'TO_BE_REVISED', 'APPROVING']
      }]
    }, {
      view: 'personOtherInfoRequest.edit',
      sort:"-updateTs",
    }).then(values => {
      if (!values || values.length === 0) {
        return PersonOtherInfoRequestManagement.NEW_SUBPATH + '/' + this.selectedRowKey!;
      } else {
        const approvingRequest = values.find(value => value!.status!.code === 'APPROVING');
        return approvingRequest ? approvingRequest.id : values[0].id;
      }
    });
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const PersonOtherInfoList = injectIntl(withRouter(PersonOtherInfoListComponent));

export default PersonOtherInfoList;
