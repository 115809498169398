import React from "react";
import {Button, Col, Input, Row} from "antd";
import {action, observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

type CommentEnterComponentProps = {
  onSend: (comment: string) => Promise<void>
}

@observer
class CommentEnterComponent extends React.Component<CommentEnterComponentProps & WrappedComponentProps> {
  @observable wasPressedCommentField = false;
  @observable commentText = '';
  @observable sending = false;

  @action onSend = () => {
    this.sending = true;
    this.props.onSend(this.commentText)
      .then(this.reset)
      .finally(() => runInAction(() => this.sending = false))
  }

  @action reset = () => {
    this.commentText = '';
    this.wasPressedCommentField = false;
  }

  render() {
    return <>
      <Row>
        <Col xs={this.wasPressedCommentField ? 20 : 24}>
          <Input multiple placeholder={this.props.intl.messages["recognition.comment.placeholder"] as string} value={this.commentText}
                 onFocus={() => runInAction(() => this.wasPressedCommentField = true)}
                 onChange={e => runInAction(() => this.commentText = e.target.value)}/>
        </Col>
        {this.wasPressedCommentField &&
          <Col xs={4}>
            <Button loading={this.sending} block disabled={this.commentText === ''} onClick={this.onSend}>
              <FormattedMessage id={"recognition.comment.send"}/>
              <img height={20}
                   style={{marginLeft: 10}}
                   src={require('../../../../resources/icons/contact/telegram-solid.svg')}/>
            </Button>
          </Col>
        }
      </Row>
    </>
  }
}

export default injectIntl(CommentEnterComponent);
