import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Tabs} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import {RouteComponentProps, withRouter} from "react-router";
import MyNominee from "../Components/MyNominee";
import PersonAwardCards from "../Components/RecognitionWinners";


type RecognitionProgramProps = RouteComponentProps<{ activeTab: string }>;

@inject("rootStore")
@injectMainStore
@observer
export class RecognitionProgramComponent extends React.Component<RootStoreProp & WrappedComponentProps & RecognitionProgramProps & RouteComponentProps> {
  static ROOT_PATH = "/recognition/recognitionProgram/"
  static PATH = "/recognition/recognitionProgram/:activeTab"

  onChange = (key: string) => {
    this.props.history.push(RecognitionProgramComponent.ROOT_PATH + key)
  }

  render() {
    const activeTab = this.props.match.params.activeTab;
    const messages = this.props.intl.messages;

    return <>
      <Tabs animated={false} type={"card"} defaultActiveKey={activeTab} onChange={this.onChange}>
        <Tabs.TabPane tab={messages["recognition.recognitionProgram.winners"]} key={"winners"}>
          <PersonAwardCards/>
        </Tabs.TabPane>
        <Tabs.TabPane tab={messages["recognition.recognitionProgram.myNominee"]} key={"myNominee"}>
          <MyNominee/>
        </Tabs.TabPane>
      </Tabs>
     </>
  }

}

export default withRouter(injectIntl(RecognitionProgramComponent));