import * as React from "react";
import {observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import DataTableFormat from "../../components/DataTable/intex";
import {withRouter} from "react-router";
import {PersonContactRequestManagement} from "../PersonContactRequest/PersonContactRequestManagement";
import {PersonContact} from "../../../cuba/entities/base/tsadv$PersonContact";
import {observable} from "mobx";
import Button, {ButtonType} from "../../components/Button/Button";


export type RequiredPersonGroupProps = {
  personGroupId: string
}

@injectMainStore
@observer
class PersonContactRequestListComponent extends React.Component<RequiredPersonGroupProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps> {
  dataCollection = collection<PersonContact>(PersonContact.NAME, {
    view: "portal.my-profile",
    sort: "-updateTs",
    filter: {
      conditions: [{
        property: 'personGroup.id',
        operator: '=',
        value: this.props.personGroupId
      }]
    }
  });

  fields = [
    "type",
    "contactValue",
    "startDate",
    "endDate"
  ];

  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
      <Link
        to={
          PersonContactRequestManagement.PATH +
          "/" +
          PersonContactRequestManagement.NEW_SUBPATH
        }
        key="create"
      >
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}
                key="createBtn"
        >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>,
      <Button buttonType={ButtonType.FOLLOW}
              style={{margin: "0 12px 12px 0"}}
              key="edit"
              onClick={this.openEditRequest}
              disabled={!this.selectedRowKey}
      >
        <FormattedMessage id="management.browser.edit"/>
      </Button>
    ];

    return (
      <DataTableFormat
        enableFiltersOnColumns={[]}
        dataCollection={this.dataCollection}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  openEditRequest = () => {
    this.getRequestId()
      .then(value =>{console.log(value);return this.props.history!.push(PersonContactRequestManagement.PATH + '/new/' + value)});
  }
  getRequestId = ()=> {
    return getCubaREST()!.searchEntities<PersonContact>(PersonContact.NAME, {
      conditions: [{
        property: 'id',
        operator: '=',
        value: this.selectedRowKey!
      }]
    }, {
      view: 'portal.my-profile'
    }).then(value => value[0].id)
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
    console.log(this.selectedRowKey)
  };

}

const PersonContactRequestList  = injectIntl(withRouter(PersonContactRequestListComponent));

export default PersonContactRequestList;
