import React from 'react'
import {Carousel, Icon} from "antd";
import './styles.css'
import {injectIntl, WrappedComponentProps} from "react-intl";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {RootStoreProp} from "../../../../store";
import {inject, observer} from "mobx-react";
import MedalComponent from "../MedalComponent";
import {PersonMedal} from "../../../../../cuba/entities/base/tsadv$PersonMedal";
import {Medal} from "../../../../../cuba/entities/base/tsadv$Medal";
import {getFileUrl} from "../../../../util/util";
import {IReactionDisposer, observable} from "mobx";
import {restServices} from "../../../../../cuba/services";
import {SerializedEntity} from "@cuba-platform/rest";
import {MedalType} from "../../../../../cuba/enums/enums";
import {JSON_DATE_TIME_FORMAT} from "../../../../util/Date/Date";
import moment from "moment";

type Props = {
  badges?: PersonMedal[],
  personGroupId: string
} & WrappedComponentProps & MainStoreInjected & RootStoreProp


type MedalDescription = {
  link?: string
  total: number
  active: number
}

@injectMainStore
@inject("rootStore")
@observer
class Badges extends React.Component<Props> {

  notSeniorityMedalType: MedalType[] = [MedalType.COPPER, MedalType.SILVER, MedalType.GOLD]
  seniorityMedalType: MedalType[] = [MedalType.SENIORITY]
  dataWatcher: IReactionDisposer
  messages = this.props.intl.messages
  badgesCarousel?: Carousel | null
  seniorityCarousel?: Carousel | null
  @observable data: MedalDescription[] = []
  @observable dataSeniority: MedalDescription[] = []
  @observable badgesData: PersonMedal[]


  groupData = (medals: Medal[], isForSeniority: boolean, badges?: PersonMedal[]) => {
    medals.sort()
    const map = new Map<string, MedalDescription>()
    for (const medal of medals) {
      if ((!isForSeniority && this.notSeniorityMedalType.includes(medal.type) ) || (isForSeniority
        && this.seniorityMedalType.includes(medal.type) )) {
        const description = {
          link: medal.icon ? getFileUrl(medal.icon.id) : undefined,
          active: 0,
          total: 0
        }
        map.set(medal.id, description)
      }
    }
    if (badges) {
      for (const badge of badges) {
        const medal = badge.medal
        if (medal && ((!isForSeniority && this.notSeniorityMedalType.includes(medal.type)) || (isForSeniority
          && this.seniorityMedalType.includes(medal.type)))) {
          const description = map.get(medal.id)

          description!.total = description!.total + badge.medalCount!
          if (badge.endDate >= moment().format(JSON_DATE_TIME_FORMAT))
            description!.active = description!.active + badge.medalCount!
          map.set(medal.id, description!)
        }
      }
    }
    return Array.from(map.values())
  }

  mapDataToElem = (data: MedalDescription[]) => {
    const result = []
    if (data.length) {
      for (let i = 0; i < data.length; i++) {
        const first = data[i]
        result.push(wrapCarouselItem([
          <MedalComponent totalQuantity={first.total} activeQuantity={first.active} image={first.link}/>,
        ]))
      }
    }
    return result
  }

  render() {
    if (!this.data.length)
      return <label style={{marginLeft:"28px", fontSize:"28px"}}>
        {this.messages['medalTab.tabs.badges.notFound']}
    </label>
    const badgesCarouselNextArrow = (
      <Icon
        type="right"
        className=""
        onClick={() => this.badgesCarousel ? this.badgesCarousel.next() : undefined}
      />
    );
    const badgesCarouselPreviousArrow = (
      <Icon
        type="left"
        className=""
        onClick={() => this.badgesCarousel ? this.badgesCarousel.prev() : undefined}
      />);

    const seniorityCarouselNextArrow = (
      <Icon
        type="right"
        className=""
        onClick={() => this.seniorityCarousel ? this.seniorityCarousel.next() : undefined}
      />
    );
    const seniorityPreviousArrow = (
      <Icon
        type="left"
        className=""
        onClick={() => this.seniorityCarousel ? this.seniorityCarousel.prev() : undefined}
      />);

    return (
      <div>
        <div className={'badges-layout'}>
          <div className={'badges-captions-container'} style={{gridColumn: 1}}>
            <label  className={'badges-list.caption'} style={{fontSize:"24px"}}>{this.messages['medalTab.tabs.badges.badges']}</label>
            <label className={'badges-list.caption'} style={{fontSize:"24px"}}>{this.messages['medalTab.tabs.badges.seniorityBadges']}</label>
          </div>
          <div style={{gridColumn: 2}}>
            <div className={'badges-layout-container'}>
              <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                {this.mapDataToElem(this.data)}
              </div>
            </div>
            <div className={'badges-layout-container'}>
              <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                <div style={{display: "flex", flexDirection: 'row', alignItems: 'center'}}>
                  {this.mapDataToElem(this.dataSeniority)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    getCubaREST()!.loadEntities<Medal>(Medal.NAME,  {
      view: 'badge-browseView'
    })
      .then(medals => {
        medals.sort((a, b)=>{
          if(a.sort&&b.sort)
          return a.sort - b.sort;
          else return  1
        })
        restServices.recognitionPortalService.loadUserMedals(this.props.personGroupId).then(value => this.badgesData = value)
          .then(values => {
            this.data = this.groupData(medals as SerializedEntity<Medal>[], false, values)
            this.dataSeniority = this.groupData(medals as SerializedEntity<Medal>[], true, values)
          })
      });

  }

}

function

wrapCarouselItem(items: JSX.Element[]) {
  return (
    <div>
      <div>
        {items}
      </div>
    </div>
  )
}

export default injectIntl(Badges)