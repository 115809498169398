import * as React from "react";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Button, Card, Divider, Icon, List, Modal, Tabs} from "antd";
import {FileInfo, getCubaREST, injectMainStore, MainStoreInjected, withLocalizedForm} from "@cuba-platform/react";
import {action, observable} from "mobx";
import {FormComponentProps} from "antd/lib/form";
import {RootStoreProp} from "../../store";
import {Menu, PersonProfile} from "../MyTeam/MyTeamCard";
import Notification from "../../util/Notification/Notification";
import PersonalData from "./PersonalData";
import PersonLearningContractList from "../PersonLearningContract/PersonLearningContractList";
import MyProfileCbaManagement from "../MyProfileCBA/MyProfileCBAManagement";
import PersonAssignmentList from "../PersonAssignment/PersonAssignmentList";
import PunishmentList from "../MyTeam/assignment/punishment/PunishmentList";
import {faCamera} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import Upload, {RcFile} from "antd/lib/upload";
import {getBlobUrl} from "../../util/util";
import {UploadChangeParam, UploadFile} from "antd/lib/upload/interface";
import AddTelegramModal from "./AddTelegramModal";
import AddWhatsAppModal from "./AddWhatsAppModal";
import PersonFeed from "../RecognitionProgram/Components/PersonFeed";
import {RecognitionProfileTypes} from "../RecognitionProgram/Components/RecognitionProfileTypes";
import RecognitionProfilePointsAndCoins from "../RecognitionProgram/Components/RecognitionProfilePointsAndCoins";
import {PersonPreferences} from "../RecognitionProgram/Components/PersonPreferences";


const {TabPane} = Tabs;

export type MyProfileProps = {
  personGroupId: string;
};

@inject("rootStore")
@injectMainStore
@observer
class MyProfile extends React.Component<
  MyProfileProps &
  WrappedComponentProps &
  RootStoreProp &
  FormComponentProps &
  MainStoreInjected
> {
  readonly RECOGNITION_MENU = "recognition";

  @observable person?: PersonProfile;
  @observable urlImg?: string;

  @observable selectedTab: string = "personalData";
  @observable selectedLeftMenu: string = "myProfile.mainData";

  @observable
  mainStore = this.props.mainStore;

  getTabs = (): Menu[] => {
    return [
      {
        id: "personalData"
      }, {
        id: "education"
      },
      {
        id: 'competencyBasedAssessment'
      },
      {
        id: 'personprofileAssignment'
      },
      {
        id: this.RECOGNITION_MENU
      }
    ]
  }

  getLeftMenu = (): Menu[] => {
    const messages = this.mainStore!.messages!;
    switch (this.selectedTab) {
      case 'personalData':
        return [{
          id: 'myProfile.mainData',
          caption: 'myProfile.mainData',
        },

          {id: 'punishment', caption: 'punishment'},

        ]
      case 'timeManagement':
        return [{
          id: 'absence'
        }, {
          id: 'absenceRequest'
        }, {
          id: 'workOnWeekend'
        }, {
          id: 'workOnWeekendRequest'
        }, {
          id: 'scheduleStandard'
        }, {
          id: 'scheduleOffsetRequest'
        }]
      case 'competencyBasedAssessment':
        return [{
          id: 'CBA',
          caption: "cba"
        }]
      case 'personprofileAssignment':
        return [
          {
            id: 'personprofileAssignment.mainData',
            caption: "personprofileAssignment"
          }
        ]
      case this.RECOGNITION_MENU:
        return [
          {
            id: this.RECOGNITION_MENU,
            caption: this.RECOGNITION_MENU
          }
        ]
    }
    return [
      {
        id: "personalData"
      }
    ];
  };

  @observable showModal = false;

  @action changeShowModal = (value: boolean) => this.showModal = value;

  @observable image: FileInfo | undefined;

  @action changeImage = (file: FileInfo) => this.image = file;

  @action handleOk = () => {
    if (this.image && this.image.id) {
      getCubaREST()!.commitEntity<PersonGroupExt>(PersonGroupExt.NAME, {
        id: this.props.rootStore!.userInfo.personGroupId!,
        image: {id: this.image.id}
      })
        .then(() => {
          if (this.props.rootStore!.personProfileStore) this.props.rootStore!.personProfileStore.loadPersonProfile(this.props.rootStore!.userInfo.personGroupId!);
        }).catch((error) => Notification.error({
        message: this.props.intl.formatMessage({id: "management.editor.error"}) + error,
        duration: 3
      }))
    }
    this.changeShowModal(false);
  }

  @action handleCancel = () => {
    this.image = undefined;
    this.changeShowModal(false);
  }

  @observable isImage: boolean;

  @action changeIsImage = (value: boolean) => this.isImage = value;

  @observable imageUrl: string;

  @action changeImageUrl = (value: string) => this.imageUrl = value

  @observable loadingImg: boolean;

  @action changeLoading = (value: boolean) => this.loadingImg = value

  @action handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      this.changeLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      console.log(info.file.response);
      this.changeImage(info.file.response);
      if (this.image)
        getBlobUrl(this.image.id).then(res => this.changeImageUrl(res))
      this.changeLoading(false);
    }
  }
  @action beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      Notification.error({message: this.props.intl.formatMessage({id: "profileImageModal.format.error"})});
    }
    return isJpgOrPng;
  }

  renderRecognitionBlock = () => {
    if (this.RECOGNITION_MENU !== this.selectedLeftMenu) {
      return null;
    }

    const personGroupId = this.props.personGroupId;

    return <>
      <Card className={"narrow-layout large-section section-container myProfile-card"}>
        <RecognitionProfileTypes personGroupId={personGroupId}/>
        <Divider/>

        <div style={{width: '100%'}}>
          <RecognitionProfilePointsAndCoins personGroupId={personGroupId}/>
          <Divider/>
        </div>

        <PersonPreferences personGroupId={personGroupId}/>
      </Card>
    </>
  }

  render() {
    const personProfile = this.props.rootStore!.personProfileStore && this.props.rootStore!.personProfileStore.personProfile
    const urlImage = this.props.rootStore!.personProfileStore && this.props.rootStore!.personProfileStore.imageUrl
    const hasEmail = personProfile && personProfile.email;
    const hasPhone = personProfile && personProfile.phone;
    const hasTelegram = personProfile && personProfile.telegram;
    const hasWhatsApp = personProfile && personProfile.whatsApp;
    const faCameraIcon = faCamera as IconProp;
    return (
      <div style={{ height: "100%", display: "flex" }}>
        <div
          style={{
            width: 330
          }}
          className={"myProfile-card__left"}
        >
          <Card
            className="narrow-layout large-section section-container myProfile-card"
            bodyStyle={{textAlign: "center"}}
          >
            <div style={{position: "relative"}}>
              <img
                alt={personProfile ? personProfile.fullName || "" : ""}
                src={
                  urlImage
                    ? urlImage
                    : require("../../../resources/img/default-avatar.svg")
                }
                style={{height: 200, width: 200, borderRadius: "50%"}}
              />
              <Button
                style={{position: "absolute", bottom: 0, right: 0, backgroundColor: "transparent", border: "none"}}
                onClick={() => this.changeShowModal(true)}><FontAwesomeIcon size="2x" icon={faCameraIcon}/></Button>
            </div>
            <h3>{personProfile ? personProfile.fullName || "" : ""}</h3>
            <p className="title">
              {personProfile ? personProfile.positionName || "" : ""}
            </p>
            <p>{personProfile ? personProfile.organizationName || "" : ""}</p>
          </Card>
          <Modal
            title={<FormattedMessage id="profileImageModal.title"/>}
            visible={this.showModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            centered
          >
            <div style={{display: "flex", justifyContent: "center"}}>
              <div>
                <Upload
                  listType="picture-card"
                  beforeUpload={this.beforeUpload}
                  showUploadList={false}
                  style={{display: "inline-block"}} action={getCubaREST()!.getFileUploadURL()}
                  headers={{'Authorization': 'Bearer ' + getCubaREST()!.restApiToken}}
                  onChange={this.handleChange}>
                  {
                    this.image ? <img src={this.imageUrl} alt="avatar" style={{width: '100%'}}/> :
                      <div>
                        <Icon type={this.loadingImg ? 'loading' : 'plus'}/>
                        <div className="ant-upload-text"><FormattedMessage id="upload"/></div>
                      </div>
                  }
                </Upload>
              </div>
            </div>
          </Modal>
          <Card
            className="narrow-layout large-section section-container myProfile-card"
            style={hasEmail || hasPhone ? {} : {display: "none"}}
          >
            <h1 style={{fontWeight: "bold"}}>
              <FormattedMessage id={"myProfile.contactInformation"}/>
            </h1>
            <div style={hasPhone ? {} : {display: "none"}}>
              <img
                alt={""}
                width={15}
                src={require("../../../resources/icons/contact/phone-alt-solid.svg")}
              />
              <span style={{paddingLeft: 10}}>
                {personProfile ? personProfile.phone || "" : ""}
              </span>
            </div>
            <div style={hasEmail ? {} : {display: "none"}}>
              <img
                alt={""}
                width={15}
                src={require("../../../resources/icons/contact/envelope-solid.svg")}
              />
              {personProfile && personProfile.email ? (
                <a
                  href={"mailto:" + personProfile.email}
                  style={{paddingLeft: 10}}
                >
                  {personProfile.email}
                </a>
              ) : (
                <></>
              )}
            </div>
            <div>
              <img
                alt={""}
                width={15}
                src={require("../../../resources/icons/contact/telegram-solid.svg")}
              />
              <span style={{paddingLeft: 10}}>
                {hasTelegram
                  ? <a href={`https://t.me/${personProfile!.telegram}`} target="_blank"> {personProfile!.telegram} </a>
                  : <AddTelegramModal
                    onAdd={() => this.props.rootStore!.personProfileStore!.loadPersonProfile(this.props.rootStore!.userInfo.personGroupId!)}
                  />
                }
              </span>
            </div>
            <div>
              <img
                alt={""}
                width={15}
                src={require("../../../resources/icons/contact/whatsapp-solid.svg")}
              />
              <span style={{paddingLeft: 10}}>
                {hasWhatsApp
                  ? <a href={`https://wa.me/${personProfile!.whatsApp}`} target="_blank"> {personProfile!.whatsApp} </a>
                  : <AddWhatsAppModal
                     onAdd={() => this.props.rootStore!.personProfileStore!.loadPersonProfile(this.props.rootStore!.userInfo.personGroupId!)}
                  />
                }
              </span>
            </div>
          </Card>
          <Card
            className="narrow-layout large-section section-container myProfile-card"
            style={this.getLeftMenu().length > 0 && this.selectedLeftMenu !== this.RECOGNITION_MENU ? {} : {display: "none"}}
          >
            <List>
              {this.getLeftMenu().map((menu: Menu) => (
                <List.Item
                  style={
                    this.selectedLeftMenu === menu.id
                      ? {
                        backgroundColor: "#bae7ff",
                        marginTop: "10px",
                        padding: "10px",
                        cursor: "pointer"
                      }
                      : {
                        marginTop: "10px",
                        padding: "10px",
                        cursor: "pointer"
                      }
                  }
                  key={menu.id}
                  onClick={() => {
                    this.selectedLeftMenu = menu.id;
                  }}
                >
                  <FormattedMessage id={menu.caption || menu.id}/>
                </List.Item>
              ))}
            </List>
          </Card>
          {this.renderRecognitionBlock()}
        </div>

        <div
          style={{
            display: "block",
            height: "100%",
            width: "100%",
            overflow: "auto"
          }}
        >
          <div style={{height: "100%", display: "flex", justifyContent: "center"}}>
            <Card className="narrow-layout large-section section-container myProfile-card"
                  style={{overflowX: 'scroll'}}>
              <Tabs
                defaultActiveKey={this.selectedTab}
                onChange={activeKey => {
                  this.selectedTab = activeKey;
                  this.selectedLeftMenu = this.getLeftMenu()[0].id;
                  // this.callSetSelectedTabOrLeftMenu();
                }}
              >
                {this.getTabs().map(tabInfo => (
                  <TabPane
                    tab={<FormattedMessage id={tabInfo.id}/>}
                    key={tabInfo.id}
                  ></TabPane>
                ))}
              </Tabs>
              {this.renderContent()}
            </Card>
          </div>
        </div>
      </div>
    );
  }

  renderContent = () => {
    const personProfile = this.props.rootStore!.personProfileStore && this.props.rootStore!.personProfileStore.personProfile
    if (!personProfile) return <></>;
    switch (this.selectedLeftMenu) {
      case 'punishment':
        return <PunishmentList personGroupId={personProfile!.groupId}/>
      case 'myProfile.mainData':
        return <PersonalData person={personProfile}/>;
      case 'personalData':
        return <PersonLearningContractList/>;
      case "CBA":
        return <MyProfileCbaManagement personGroupId={this.props.personGroupId}/>
      case 'personprofileAssignment.mainData':
        return <PersonAssignmentList/>
      case this.RECOGNITION_MENU:
        return <PersonFeed isMyProfile={true} groupId={this.props.personGroupId}/>;
    }
    return (
      <div>
        This is {this.selectedLeftMenu}
      </div>
    );
  }

}

export default injectIntl(
  withLocalizedForm({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(MyProfile)
);
