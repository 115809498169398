import { AbstractParentEntity } from "./AbstractParentEntity";
export class AccessSettingForQuarterlyMeetings extends AbstractParentEntity {
  static NAME = "tsadv_AccessSettingForQuarterlyMeetings";
  quarter?: any | null;
  year?: number | null;
  startDate?: any | null;
  endDate?: any | null;
}
export type AccessSettingForQuarterlyMeetingsViewName =
  | "_base"
  | "_local"
  | "_minimal";
export type AccessSettingForQuarterlyMeetingsView<
  V extends AccessSettingForQuarterlyMeetingsViewName
> = V extends "_base"
  ? Pick<
      AccessSettingForQuarterlyMeetings,
      | "id"
      | "quarter"
      | "year"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      AccessSettingForQuarterlyMeetings,
      | "id"
      | "quarter"
      | "year"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : never;
