import { AbstractParentEntity } from "./AbstractParentEntity";
import { AnnualSalaryRevision } from "./tsadv$AnnualSalaryRevision";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicCompany } from "./base_DicCompany";
import { OrganizationGroupExt } from "./base$OrganizationGroupExt";
import { PositionGroupExt } from "./base$PositionGroupExt";
import { GradeGroup } from "./tsadv$GradeGroup";
import { DicRequestStatus } from "./tsadv$DicRequestStatus";
import { AnnualSalaryRevisionRequest } from "./tsadv$AnnualSalaryRevisionRequest";
export class AnnualSalaryRevisionPerson extends AbstractParentEntity {
  static NAME = "tsadv$AnnualSalaryRevisionPerson";
  annualSalaryRevision?: AnnualSalaryRevision | null;
  person?: PersonGroupExt | null;
  company?: DicCompany | null;
  organisation?: OrganizationGroupExt | null;
  position?: PositionGroupExt | null;
  employeeNumber?: string | null;
  hireDate?: any | null;
  grade?: GradeGroup | null;
  increasePercentPerYear?: any | null;
  increasePercent?: any | null;
  increasePercentModerated?: any | null;
  baseSalaryBegin?: any | null;
  baseSalaryEnd?: any | null;
  newSalaryRecommended?: any | null;
  newSalaryModerated?: any | null;
  increasePercentFinal?: any | null;
  comment?: string | null;
  disableSalary?: boolean | null;
  hideSalary?: boolean | null;
  changedByCab?: boolean | null;
  status?: DicRequestStatus | null;
  annualSalaryRevisionRequests?: AnnualSalaryRevisionRequest[] | null;
}
export type AnnualSalaryRevisionPersonViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "annualSalaryRevisionPerson-view";
export type AnnualSalaryRevisionPersonView<
  V extends AnnualSalaryRevisionPersonViewName
> = V extends "_base"
  ? Pick<
      AnnualSalaryRevisionPerson,
      | "id"
      | "person"
      | "employeeNumber"
      | "hireDate"
      | "increasePercentPerYear"
      | "increasePercent"
      | "increasePercentModerated"
      | "baseSalaryBegin"
      | "baseSalaryEnd"
      | "newSalaryRecommended"
      | "newSalaryModerated"
      | "increasePercentFinal"
      | "comment"
      | "hideSalary"
      | "disableSalary"
      | "changedByCab"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      AnnualSalaryRevisionPerson,
      | "id"
      | "employeeNumber"
      | "hireDate"
      | "increasePercentPerYear"
      | "increasePercent"
      | "increasePercentModerated"
      | "baseSalaryBegin"
      | "baseSalaryEnd"
      | "newSalaryRecommended"
      | "newSalaryModerated"
      | "increasePercentFinal"
      | "comment"
      | "hideSalary"
      | "disableSalary"
      | "changedByCab"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_minimal"
  ? Pick<AnnualSalaryRevisionPerson, "id" | "person">
  : V extends "annualSalaryRevisionPerson-view"
  ? Pick<
      AnnualSalaryRevisionPerson,
      | "id"
      | "employeeNumber"
      | "hireDate"
      | "increasePercentPerYear"
      | "increasePercent"
      | "increasePercentModerated"
      | "baseSalaryBegin"
      | "baseSalaryEnd"
      | "newSalaryRecommended"
      | "newSalaryModerated"
      | "increasePercentFinal"
      | "comment"
      | "hideSalary"
      | "disableSalary"
      | "changedByCab"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "person"
      | "annualSalaryRevisionRequests"
      | "company"
      | "organisation"
      | "position"
      | "grade"
      | "status"
    >
  : never;
