import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicCompany } from "./base_DicCompany";
import { OrganizationGroupExt } from "./base$OrganizationGroupExt";
import { PositionGroupExt } from "./base$PositionGroupExt";
import { AnnualSalaryRevision } from "./tsadv$AnnualSalaryRevision";
import { FileDescriptor } from "./sys$FileDescriptor";
import { AnnualSalaryRevisionPerson } from "./tsadv$AnnualSalaryRevisionPerson";
export class AnnualSalaryRevisionRequest extends AbstractBprocRequest {
  static NAME = "tsadv$AnnualSalaryRevisionRequest";
  person?: PersonGroupExt | null;
  company?: DicCompany | null;
  organisation?: OrganizationGroupExt | null;
  position?: PositionGroupExt | null;
  annualSalaryRevision?: AnnualSalaryRevision | null;
  file?: FileDescriptor[] | null;
  persons?: AnnualSalaryRevisionPerson[] | null;
  averagePercent?: any | null;
  totalPayrollFundBefore?: any | null;
  totalPayrollFundAfter?: any | null;
}
export type AnnualSalaryRevisionRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "annualSalaryRevisionRequest-view";
export type AnnualSalaryRevisionRequestView<
  V extends AnnualSalaryRevisionRequestViewName
  > = V extends "_base"
  ? Pick<
    AnnualSalaryRevisionRequest,
    | "id"
    | "requestNumber"
    | "requestDate"
    | "legacyId"
    | "organizationBin"
    | "integrationUserLogin"
    | "comment"
    >
  : V extends "_local"
    ? Pick<
      AnnualSalaryRevisionRequest,
      | "id"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      >
    : V extends "_minimal"
      ? Pick<AnnualSalaryRevisionRequest, "id" | "requestNumber" | "requestDate">
      : V extends "annualSalaryRevisionRequest-view"
        ? Pick<
          AnnualSalaryRevisionRequest,
          | "id"
          | "legacyId"
          | "organizationBin"
          | "integrationUserLogin"
          | "requestNumber"
          | "requestDate"
          | "comment"
          | "annualSalaryRevision"
          | "person"
          | "file"
          | "persons"
          | "averagePercent"
          | "totalPayrollFundBefore"
          | "totalPayrollFundAfter"
          | "status"
          >
        : never;
