import {action, computed, observable, runInAction} from "mobx";
import {restServices} from "../../../../cuba/services";
import {GoodsPojo} from "../../../../cuba/entities/base/tsadv$GoodsPojo";
import {rootStore} from "../../../store";
import {getCubaREST} from "@cuba-platform/react";
import {GoodsCart} from "../../../../cuba/entities/base/tsadv$GoodsCart";


class CardStore {
  @observable cardItems: GoodsPojo[] = [];
  @observable countItemsInCart = 0;
  @observable balance = 0;
  @observable totalSum = 0;
  @observable pageCounts = 0;
  @observable page = 0;
  @observable isLoading = false;

  lastCount = 0;

  @computed get isLastPage() {
    return this.pageCounts === this.page;
  }

  @action loadCartItems = async () => {
    this.isLoading = true;
    this.page++;

    const resp = await restServices.recognitionService.loadCart(this.page, this.lastCount)
      .finally(() => this.isLoading = false)

    this.cardItems = [...this.cardItems, ...resp.goods!]

    this.lastCount = resp.pageInfo!.totalRowsCount;
    this.pageCounts = resp.pageInfo!.pagesCount;
  }

  @action reloadCartItems = async () => {
    this.page = 0;
    this.lastCount = 0;
    this.pageCounts = 0;
    this.cardItems = [];
    await this.loadCartItems();
    await this.loadGoodsCartCount();
  }

  @action loadBalance = () => {
    restServices.recognitionService.getPersonBalance()
      .then(resp => runInAction(() => this.balance = resp));
  }

  @action loadGoodsCartCount = () => {
    restServices.recognitionService.goodsCartCount()
      .then(resp => runInAction(() => this.countItemsInCart = resp));
  }

  @action loadTotalSum = () => {
    const groupId = rootStore!.userInfo.personGroupId!;
    restServices.recognitionService.getTotalSum(groupId)
      .then(resp => runInAction(() => this.totalSum = resp));
  }

  @action deleteFromCart = async (id: string) => {
    await getCubaREST()!.deleteEntity(GoodsCart.NAME, id);
    this.cardItems = this.cardItems.filter(item => item.cartId !== id);
    this.loadGoodsCartCount();
    this.loadTotalSum();
  }

  @action updateCount = async (item: GoodsPojo, count: number) => {
    await restServices.recognitionService.updateGoodsQuantity(item.cartId, item.id, count)
      .then(() => runInAction(() => item.quantity = count))
    this.loadTotalSum();
  }
}

export const cartStore = new CardStore();