import React from 'react';
import PanelCard from "../../components/CourseCard";
import {Select, Spin, Tabs} from "antd";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import {observable, runInAction} from "mobx";
import Meta from "antd/es/card/Meta";
import ImageLogo from "../../components/ImageLogo";
import {restServices} from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Rate from "../../components/Rate/Rate";
import {getFileUrl, langValue} from "../../util/util";
import CardIconFactory from "../CourseCatalog/CardIconFactory";
import {RootStoreProp} from "../../store";
import InfiniteScroll from 'react-infinite-scroller';

type Rating = {
  key: number
  value: number
}
type CourseItem = {
  enrollmentId: string
  enrollmentStatus: any
  createTs: string
  id: string
  isOnline: boolean
  logo: string
  name: string
  rating: Rating[]
  commentCount: number
  category:Catalog
}
type Catalog = {
  id: string,
  langValue: string
}
type AllCourse = {
  id: string,
  langValue: string,
  courses: CourseItem[]
}

@inject("rootStore")
@observer
class CourseList<T> extends React.Component<WrappedComponentProps & RootStoreProp> {

  @observable dataCollectionCategory: Catalog[]

  @observable currentOffset: number = 0

  @observable fetching: boolean = false

  @observable isTabClicked: boolean = false

  @observable isDataLoading: boolean = true

  @observable dataCollectionSupCategory: Array<AllCourse>
  @observable dataCollectionSupCategoryOriginal: Array<AllCourse>

  @observable dataCollectionSupCategoryonScroll: Array<AllCourse>

  @observable activeKey: string = this.props.rootStore!.courseCatalogStore ? this.props.rootStore!.courseCatalogStore.selectedCategoryId : this.dataCollectionSupCategory && this.dataCollectionSupCategory[0] && this.dataCollectionSupCategory[0]!.id;

  @observable filterValue: string = ""

  @observable searchValue: string = ""

  LIMIT = 8

  OFFSET = 8

  getCourse = async (): Promise<Catalog[]> => {
    return await restServices.courseService.getCourseSubCategoriesWithCourseSearchAndPagination({
      personGroupId: this.props.rootStore!.userInfo.personGroupId!,
      searchString: "",
      limit: null,

      offset: 0
    })
  }

  getSubCourse = async (categoryId: string, searchText: string = ""): Promise<CourseItem[]> => {
    return await restServices.courseService.getSubCategoryCoursesWithSearchAndPagination({
      subCategoryId: categoryId,
      personGroupId: this.props.rootStore!.userInfo.personGroupId!,
      searchString: searchText,
      limit: this.LIMIT,
      offset: this.currentOffset
    })
  }

  loadDataCollectionOnSearching =  (searchText: string = "") => {

    this.getCourse().then((catalog)=>{
      this.dataCollectionCategory = catalog
      console.log(this.dataCollectionCategory)

      Promise.all(catalog.map((items) => {
        return this.getSubCourse(items.id!, searchText)
      })).then((courses)=>{
        this.isDataLoading = false
        // if(courses.length === 0){
        //   this.fetching = false
        // }
        // this.fetching = true
        this.dataCollectionSupCategory = catalog.map((e,i) => {
          return (
            {
              langValue: e.langValue,
              id: e.id,
              courses: courses[i]
            })
        })
        this.dataCollectionSupCategory = this.dataCollectionSupCategory.filter((ec)=>ec.courses.length>0)
        const ErrorItem = this.dataCollectionSupCategory.filter(course => course.id === (this.activeKey !== undefined ? this.activeKey : this.dataCollectionSupCategory && this.dataCollectionSupCategory[0] && this.dataCollectionSupCategory[0]!.id))
        if (ErrorItem && ErrorItem[0] && ErrorItem[0].courses! && ErrorItem[0].courses!.length! === 0) {
          Notification.info({
            message: this.props.intl.formatMessage({id: "courses.search.noFound"})
          });
          return;
        }
      })


    })
  }

  loadDataCollection =  (searchText: string = "") => {
    this.getCourse()
      .then( (catalog) => {
        this.dataCollectionCategory = catalog
        Promise.all(catalog.map((items) => {
          return this.getSubCourse(items.id!, searchText)
        }))
          .then(courses => {
            this.isDataLoading = false
            this.dataCollectionSupCategoryOriginal = catalog.map((e, i) => {
              return (
                {
                  langValue: e.langValue,
                  id: e.id,
                  courses: courses[i]
                })
            })

            this.dataCollectionSupCategory = this.dataCollectionSupCategoryOriginal
          })


      })
  }

  groupDataByCompetenceType = (arr: CourseItem[]): AllCourse[] => {
    const map = arr.reduce((acc, cur, index:number) => {
      if (cur.category.id ) {
        if (!acc.has(cur.category.id)) {
          acc.set(cur.category.id, {
            id: cur.category.id,
            langValue: cur.category.langValue,
            courses: []
          });
        }
        acc.get(cur.category.id)!.courses.push({
          ...cur
        });
      }
      return acc
    }, new Map() as Map<string, AllCourse>);

    return Array.from(map.values())
  };
  loadCategoryDataCollection = (isLoadingInfinity: boolean = false) => {
    this.getSubCourse(this.activeKey !== undefined ? this.activeKey : this.dataCollectionCategory[0].id, this.searchValue)
      .then((courses) => {
        console.log(courses)
        this.isDataLoading = false
        this.LIMIT === courses.length ? this.fetching = true : this.fetching = false
       
          this.dataCollectionSupCategory = this.dataCollectionCategory.map((e: AllCourse, i: number) => {
            return (
              {
                langValue: e.langValue,
                id: e.id,
                courses: courses.find((a,ind)=>a.category.id === e.id )  ? [...this.dataCollectionSupCategory[i]!.courses!, ...courses] : this.dataCollectionSupCategory[i]!.courses!
              })
          })

      })
  }

  handleChange = (name: string, value: string) => {
    this.filterValue = value
  }
  onSearch = (value: string) => {
    if (value && value.length > 0) {
      runInAction(() => {
        this.currentOffset = 0
        this.searchValue = value
      })
      this.loadDataCollectionOnSearching(this.searchValue)
    } else {
      this.loadDataCollectionOnSearching()
    }
  };

  render() {

    const {TabPane} = Tabs;
    const {Option} = Select;
    const defaultTabKey = this.props.rootStore!.courseCatalogStore ? this.props.rootStore!.courseCatalogStore.selectedCategoryId : this.dataCollectionSupCategory && this.dataCollectionSupCategory[0] && this.dataCollectionSupCategory[0]!.id;

    return (
      <>
        <Spin spinning={this.isDataLoading}>
          <div style={{display: "flex"}}>
            <div style={{width: "70%"}}>
              <SearchInput  onSearch={this.onSearch}/>
            </div>
            <div style={{width: "20%", marginLeft: "30px"}}>
              <Select allowClear={true} placeholder={"Выберите..."}
                      onChange={value => this.handleChange("name", value as string)} style={{width: "100%"}}>
                <Option value={"1"}>{this.props.intl.formatMessage({id: "filter.AtoZ"})}</Option>
                <Option value={"2"}>{this.props.intl.formatMessage({id: "filter.ZtoA"})}</Option>
                <Option value={"3"}>{this.props.intl.formatMessage({id: "filter.Newest"})}</Option>
                <Option value={"4"}>{this.props.intl.formatMessage({id: "filter.Oldest"})}</Option>
              </Select>
            </div>
          </div>
          {this.dataCollectionSupCategory && this.dataCollectionSupCategory!.length > 0 ? <Tabs
            defaultActiveKey={defaultTabKey}
            onChange={this.tabOnChange}
            onTabClick={(activeKey: string) => this.activeKey = activeKey}
          >
            {this.dataCollectionSupCategory && this.dataCollectionSupCategory!.map((category: any) => <TabPane
              tab={category.langValue} key={category.id}>
              <Spin spinning={this.isDataLoading}>
                <div key={category.id} className={"courses-cards-wrapper"}>
                  <InfiniteScroll
                    pageStart={0}
                    initialLoad={false}
                    loadMore={() => {
                      console.log("loadmore")
                      this.currentOffset += this.OFFSET
                      this.loadCategoryDataCollection(true)
                    }}
                    hasMore={this.fetching}
                    loader={<div style={{width:"100%",display:"flex", alignItems:"center", justifyContent:"center"}} className="loader" key={0}>< Spin spinning={true}/></div>}
                  >
                    <div className={"courses-cards"}>
                      {category.courses!.sort((a: any, b: any) => {
                        return this.filterValue === '1' ?
                          a.name.localeCompare(b.name, 'en', {numeric: true})
                          : this.filterValue === "" ? 0
                            : this.filterValue === "2" ? b.name.localeCompare(a.name, 'en', {numeric: true})
                              : this.filterValue === "3" ? new Date(b.createTs).valueOf() - new Date(a.createTs).valueOf()
                                : this.filterValue === "4" ? new Date(a.createTs).valueOf() - new Date(b.createTs).valueOf()
                                  : ""

                      }).map((course: CourseItem) => <Link to={"/course/" + course.id}>
                        <PanelCard key={course.id}
                                   loading={false} {...course}
                                   name={course.name!}
                                   header={(<>
                                       {
                                         course.enrollmentId && (CardIconFactory.getIcon(course.enrollmentStatus) != null)
                                           ? React.createElement(CardIconFactory.getIcon(course.enrollmentStatus)!, {className: "course-icon left-icon"})
                                           : null
                                       }
                                       {
                                         course.isOnline ?
                                           <img
                                             src={require("../../../resources/icons/online.png")}
                                             alt="online"
                                             className="course-icon right-icon"/> :
                                           null}
                                       <ImageLogo
                                         type="src"
                                         imgSrc={course.logo ? getFileUrl(course.logo) : undefined}
                                         name={course.name!}/>
                                     </>
                                   )}>
                          <Meta title={course.name}
                                description={<><Rate disabled
                                                     defaultValue={course.rating.length > 0 ? Math.ceil((course.rating.map(value => value.key * value.value).reduce((previousValue, currentValue) => previousValue + currentValue) / course.rating.map(value => value.value).reduce((previousValue, currentValue) => previousValue + currentValue)) * 2) / 2 : 0}
                                                     allowHalf/>({course.rating.length > 0 ? (course.rating.map(value => value.value).reduce((previousValue, currentValue) => previousValue + currentValue)) : 0})</>}/>
                        </PanelCard></Link>)}
                    </div>
                  </InfiniteScroll>
                </div>
              </Spin>
            </TabPane>)}
          </Tabs> : <></>}
        </Spin>
      </>
    );
  }

  constructor(props: WrappedComponentProps, props2: RootStoreProp) {
    super(props, props2);
  }

  componentDidMount(): void {
    const {courseCatalogStore} = this.props.rootStore!;
    this.loadDataCollection()

    if (!courseCatalogStore) {
      this.props.rootStore!.createCourseCatalogStore();
    }
  }

  tabOnChange = (activeKey: string) => {
    //this.searchValue = ""
    this.isTabClicked = true
    this.fetching = true
    this.currentOffset = 0
    // this.dataCollectionSupCategory.forEach((item:any)=>item.courses!=[])
    // console.log( this.dataCollectionSupCategory)
    //  this.dataCollectionCategory = this.dataCollectionSupCategory
    //this.loadCategoryDataCollection()
    this.dataCollectionSupCategory = this.dataCollectionSupCategoryOriginal
    this.props.rootStore!.courseCatalogStore!.setSelectedCategoryId(activeKey);
  }

}

export default injectIntl(CourseList);