import * as React from "react";
import {RouteComponentProps} from "react-router";
import {inject, observer} from "mobx-react";
import {getCubaREST, injectMainStore} from "@cuba-platform/react";
import IndividualDevolopmentPlanEdit from "./IndividualDevolopmentPlanEdit";
import {IdpMeeting} from "../../../cuba/entities/base/tsadv_IdpMeeting";
import { Spin } from "antd";
import { action, observable } from "mobx";

type Props = RouteComponentProps<{ entityId?: string}>;

@inject("rootStore")
@injectMainStore
@observer
export class IdpMeetingManagement extends React.Component< Props> {

  static PATH = "/idpMeeting";
  static NEW_SUBPATH = "new";

  @observable id:string;

  @action setId = (id:string) => this.id = id

  render() {
    const {entityId} = this.props.match.params

    return (
      <>
        { this.id?
          <IndividualDevolopmentPlanEdit entityId={entityId}/>
          :  <Spin spinning size="large"/> 
        }
        </>

    );
  }
  componentDidMount() {
    const {entityId} = this.props.match.params
    if(entityId&&entityId!=="new"){
      getCubaREST()!.loadEntity<IdpMeeting>(IdpMeeting.NAME,entityId,{view:"idpMeeting-edit"}).then(meeting=>{
        this.setId(meeting.individualDevelopmentPlan&&meeting.individualDevelopmentPlan.id)
          this.props.history.push({pathname:"/individualDevelopmentPlan/"+this.id,state:{idpMeetingId:entityId}})
      })
    }
  }
}