import React, {Component, FormEvent} from 'react';
import {Card, Col, Form, List, Row, Spin} from "antd";
import moment from "moment";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Button, {ButtonType} from "../../components/Button/Button";
import {FormComponentProps} from "antd/es/form";
import TextArea from "antd/es/input/TextArea";
import {injectMainStore, MainStoreInjected, withLocalizedForm} from "@cuba-platform/react";
import Notification from "../../util/Notification/Notification";
import Rate from "../../components/Rate/Rate";
import {action, observable, reaction, runInAction} from "mobx";
import {restServices} from "../../../cuba/services";
import {inject, observer} from "mobx-react";
import {RootStoreProp} from "../../store";
import "./style.less"

export type Comment = {
  id?: string;
  user?: string;
  date?: moment.Moment;
  comment?: string;
  rating?: number
}

export type RateRenderMeta = {
  rate: React.ReactNode,
  index: number,
  finished: number
}

type MaterialReviewsProps = {
  avgRate?: number
  courseId: string
}

type MaterialReviewsHandlers = {
  sendComment?: (rate: number, text: string) => Promise<void>
}

@inject("rootStore")
@injectMainStore
@observer
class MaterialReviewsPageable extends Component<WrappedComponentProps & MaterialReviewsProps & MaterialReviewsHandlers & FormComponentProps & MainStoreInjected & RootStoreProp> {
  readonly PAGE_SIZE = 10;

  @observable comments: Comment[] = [];
  @observable offset = 0;

  @observable rateList: RateRenderMeta[] = [];

  @observable hasNext = true;

  @observable selectedRating?: number | null = null;
  oldRating: number | null = null;

  submitCommentForm = (e: FormEvent) => {
    e.preventDefault();
    if (this.props.sendComment) {
      this.props.form.validateFields(async (err) => {
        if (err) {
          Object.keys(err).forEach(key => {
            err[key].errors!.forEach((error: any) => {
              Notification.error({
                message: error.message
              })
            })
          });

          return;
        }

        await this.props.sendComment!(this.props.form.getFieldValue("rate"), this.props.form.getFieldValue("text"));
        this.props.form.resetFields();
        this.props.form.setFieldsValue({
          rate: null
        });
        this.loadComments(true);
      })
    }
  };

  componentDidMount() {
    this.load();
    reaction(() => [this.offset, this.selectedRating], this.onChange, {fireImmediately: true});
  }

  @action onChange = () => {
    let needClear = false;
    if (this.oldRating !== this.selectedRating) {
      this.oldRating = this.selectedRating || null;
      needClear = true;
    }

    this.loadComments(needClear);
  }

  loadComments(needClear: boolean) {
    restServices.learningService.getCourseRatings(this.props.courseId, this.PAGE_SIZE, this.offset * this.PAGE_SIZE, this.selectedRating || null)
      .then((response) => {
        let comments: Comment[] = needClear ? [] : this.comments;
        runInAction(() => {
          this.comments = [...comments, ...response];
          this.hasNext = response.length !== 0;
        })
      })
  }

  load = async () => {
    let courseInfo = await restServices.courseService.courseInfo({
      courseId: this.props.courseId,
      personGroupId: this.props.rootStore!.userInfo.personGroupId!
    })

    let rateList: RateRenderMeta[] = [];

    for (let i = 5; i > 0; i--) {
      const rateCount = courseInfo.rating.find(r => r.key === i);
      rateList.push({
        rate:<Rate disabled  value={i}/>,
        finished: rateCount ? rateCount.value : 0,
        index: i
      })
    }

    runInAction(() => {
      this.rateList = rateList;
    })
  };

  render() {
    const {avgRate = 0} = this.props;
    return (
      <>
        <Row type={"flex"}>
          <Col span={6} className={"centered-flex-container"}>
            <div className={"large-rate centered-flex-container"}>
              <span className={"large-text"}>{avgRate.toFixed(1)}</span>
              <Rate allowHalf disabled value={avgRate}/>
              <span className="default-font">{this.props.intl.formatMessage({id: "rating"})}</span>
            </div>
          </Col>
          <Col span={18}>
            <List className={"rates-list"}
                  itemLayout="horizontal"
                  dataSource={this.rateList}
                  renderItem={item => (
                    <List.Item
                      style={{}}>
                      <List.Item.Meta
                        title={item.rate}
                      />
                      <Button type={"link"}
                              onClick={() =>  runInAction(() => this.selectedRating = this.selectedRating === item.index ? null : item.index)}
                      >
                        <div style={{color: this.selectedRating === item.index ? "#0d4e8a" : "#1890ff"}}>{item.finished} {this.props.intl.formatMessage({id: "course.students"})}</div>
                      </Button>
                    </List.Item>
                  )}
            />
          </Col>
        </Row>
        <Row>
          <List itemLayout="horizontal"
                dataSource={this.comments}
                renderItem={item => (
                  <List.Item className={"comment-element"}>
                    <List.Item.Meta
                      key={item.id}
                      style={{margin: '0 5px'}}
                      className="comment-block"
                      title={<div className={"comment-header comment-title"}>
                        <div className="comment-name default-font">
                          <span>{item.user}</span>
                          {item.rating ?
                            <Rate allowHalf disabled defaultValue={item.rating} style={{fontSize: '15px'}}/> : <></>}
                        </div>
                        <div className="comment-date">{moment(item.date).format('DD.MM.yyyy')}</div>
                      </div>}
                      description={<span className="default-font">{item.comment}</span>}
                    />
                  </List.Item>
                )}
          />
          <Row type={"flex"} justify={"center"}>
            <Button type={"primary"} hidden={!this.hasNext}
                    onClick={() => runInAction(() => this.offset++)}>{this.props.intl.messages["load.more"]}</Button>
          </Row>
        </Row>
        <Row>
          <Col>
            <Spin tip={this.props.intl.formatMessage({id: "course.sendingReview"})}
                  spinning={false}>
              <Card bordered={false} style={{marginTop: '20px'}}>
                <Form onSubmit={this.submitCommentForm}>
                  {this.props.form.getFieldDecorator("rate", {
                    rules: [{
                      required: true,
                      message: this.props.intl.formatMessage({id: 'comment.rate.error.required'})
                    }]
                  })(
                    <Rate/>
                  )}
                  {this.props.form.getFieldDecorator("text", {
                    rules: [{
                      required: true,
                      message: this.props.intl.formatMessage({id: 'comment.error.required'})
                    }]
                  })(
                    <TextArea rows={5} maxLength={2000}/>
                  )}
                  <Row type="flex" justify="end">
                    <Col>
                      <Button buttonType={ButtonType.FOLLOW} style={{marginTop: '10px'}} htmlType={"submit"}>
                        {this.props.intl.formatMessage({id: "course.sendComment"})}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Spin>
          </Col>
        </Row>
      </>
    );
  }
}

export default injectIntl(withLocalizedForm<MaterialReviewsProps & MaterialReviewsHandlers>({
  onValuesChange: (props: any, changedValues: any) => {
    // Reset server-side errors when field is edited
    Object.keys(changedValues).forEach((fieldName: string) => {
      props.form.setFields({
        [fieldName]: {
          value: changedValues[fieldName]
        }
      });
    });
  }
})(MaterialReviewsPageable));