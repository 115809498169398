import * as React from "react";
import {Card, Form, Input, Select, Spin} from "antd";
import {inject, observer} from "mobx-react";
import {action, IReactionDisposer, observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";

import {
  collection,
  DataCollectionStore,
  getCubaREST,
  injectMainStore,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../app/App.css";

import {ScheduleOffsetsRequest} from "../../../cuba/entities/base/tsadv_ScheduleOffsetsRequest";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {StandardSchedule} from "../../../cuba/entities/base/tsadv$StandardSchedule";
import {ReadonlyField} from "../../components/ReadonlyField";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import LoadingPage from "../LoadingPage";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import AbstractAgreedBprocEdit from "../Bproc/abstract/AbstractAgreedBprocEdit";
import Notification from "../../util/Notification/Notification";
import {instanceStore} from "../../util/InstanceStore";
import {restServices} from "../../../cuba/services";
import {DEFAULT_DATE_PATTERN, JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import {queryCollection, QueryDataCollectionStore} from "../../util/QueryDataCollectionStore";
import {DicSchedulePurpose} from "../../../cuba/entities/base/tsadv_DicSchedulePurpose";
import TextArea from "antd/es/input/TextArea";
import MsgEntity from '../../components/MsgEntity';
import {dictionaryCollection, DictionaryDataCollectionStore} from "../../util/DictionaryDataCollectionStore";
import {DicEarningPolicy} from "../../../cuba/entities/base/tsadv_DicEarningPolicy";
import moment, {Moment} from "moment";
import {ScheduleOffsetsRequestManagement} from "./ScheduleOffsetsRequestManagement";
import {runReport} from "../../util/reportUtil";
import {goBackOrHomePage} from "../../util/util";
import {MyTeamNew} from "../../../cuba/entities/base/tsadv$MyTeamNew";
import {createElement} from "react";
import {AssignmentSchedule} from "../../../cuba/entities/base/tsadv$AssignmentSchedule";

type EditorProps = {
  entityId: string;
  personGroupId?: string;
};

@injectMainStore
@inject("rootStore")
@observer
class ScheduleOffsetsRequestEditComponent extends AbstractAgreedBprocEdit<ScheduleOffsetsRequest, EditorProps> {

  dataInstance = instanceStore<ScheduleOffsetsRequest>(ScheduleOffsetsRequest.NAME, {
    view: "scheduleOffsetsRequest-for-my-team",
    loadImmediately: false
  });


  @observable teamMembers:MyTeamNew[];
  @observable selectedMember:string="";
  @observable
  loaded: boolean = false;

  newSchedulesDc: DictionaryDataCollectionStore<StandardSchedule>;

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  purposesDc: DictionaryDataCollectionStore<DicSchedulePurpose>;

  standardScheduleDc: any;
  standardScheduleAssignmentDc: AssignmentSchedule;

  personGroupDc: DataCollectionStore<PersonGroupExt>;

  earningPolicyDc: DictionaryDataCollectionStore<DicEarningPolicy>;

  processDefinitionKey = "scheduleOffsetsRequest";

  path = ScheduleOffsetsRequestManagement.PATH;

  @observable
  updated = false;
  @observable
  isVisiblePurposeText = false;

  @observable
  isDisabledFields = true;
  @observable inputsVisible=false;
  @observable
  daysBeforeAbsence: number;

  reactionDisposer: IReactionDisposer;

  fields = [
    "purposeText",

    "dateOfNewSchedule",

    "dateOfStartNewSchedule",

    "detailsOfActualWork",

    "agree",

    "acquainted",

    "legacyId",

    "organizationBin",

    "integrationUserLogin",

    "requestNumber",

    "requestDate",

    "comment",

    "personGroup",

    "purpose",

    "currentSchedule",

    "newSchedule",

    "status",

    "earningPolicy",

    "files"
  ];

  @observable
  globalErrors: string[] = [];

  beforeCompletePredicate = (outcome: string): Promise<boolean> => {
    if (outcome == 'APPROVE' && this.approverHrRoleCode === 'EMPLOYEE') {
      const isAgree = this.props.form.getFieldValue('agree');
      const isFamiliarization = this.props.form.getFieldValue('acquainted');

      if (!isAgree) {
        Notification.info({
            message: this.props.intl.formatMessage({id: "for.approving.must.to.check.field"},
              {fieldName: this.mainStore.messages![this.dataInstance.entityName + '.agree']})
          }
        )
      }

      if (!isFamiliarization) {
        Notification.info({
            message: this.props.intl.formatMessage({id: "for.approving.must.to.check.field"},
              {fieldName: this.mainStore.messages![this.dataInstance.entityName + '.acquainted']})
          }
        )
      }

      if (!isAgree || !isFamiliarization)
        return new Promise(resolve => resolve(false));
    }
    return new Promise(resolve => resolve(true));
  };

  actions = () => {
    const {status} = this.dataInstance;

    const actions = [];

    if (this.isNewEntity())
      actions.push(<Button buttonType={ButtonType.PRIMARY}
                           disabled={status !== "DONE" && status !== "ERROR"}
                           loading={status === "LOADING"}
                           onClick={this.saveRequest}>
        <FormattedMessage id="management.editor.submit"/>
      </Button>);

    actions.push(<Button buttonType={ButtonType.FOLLOW}
                         onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>);

    if (!this.isNewEntity()) {
      actions.push(<Button buttonType={ButtonType.FOLLOW}
                           onClick={this.report}>{this.props.intl.formatMessage({id: "report"})}</Button>);

      actions.push(this.getOutcomeBtns());
    }

    return actions;
  }
  @action setTeamMember=(value:string)=>{
    this.selectedMember=value;
    const scheduleOffsetRequest = this.dataInstance.item!;
    this.standardScheduleDc = queryCollection<StandardSchedule>(StandardSchedule.NAME, "currentStandardSchedule", {
      personGroupId: this.selectedMember
    });

    const requestDate: moment.Moment = this.isNewEntity() ? moment() : moment(scheduleOffsetRequest.requestDate);
    this.newSchedulesDc = dictionaryCollection<StandardSchedule>(StandardSchedule.NAME, this.selectedMember, {
      view: "_minimal",
      sort: 'scheduleName',
      loadImmediately: true,
      filter: {
        conditions: [{
          property: 'startDate',
          operator: '<=',
          value: requestDate.format('YYYY-MM-DD')
        }, {
          property: 'endDate',
          operator: '>=',
          value: requestDate.format('YYYY-MM-DD')
        }]
      }
    });

    this.standardScheduleDc.afterLoad = () => {
      this.dataInstance.item!.currentSchedule = this.standardScheduleDc.items![0];

      if (this.dataInstance.item!.currentSchedule)
        this.props.form.setFieldsValue({'currentSchedule': this.dataInstance.item!.currentSchedule.id});
      this.setIsDisabledFields(scheduleOffsetRequest.status!.code!.toLowerCase() != 'draft' || scheduleOffsetRequest.status!.code! !== "TO_BE_REVISED");
      this.loadPerson(this.selectedMember);

      this.loadEarningPolicyDc(this.selectedMember);

      this.loadPurposesDc(this.selectedMember);

     this.setEmployee(this.selectedMember);
      restServices.absenceService.scheduleOffsetDaysBeforeAbsence().then(response => {
        if (response) {
          this.daysBeforeAbsence = Number(response);
        }
      });
      this.inputsVisible=true;


    }
    }
    render() {

    if (!this.dataInstance) {
      return <LoadingPage/>
    }

    const messages = this.props.mainStore!.messages!;

    const {Option} = Select;

    return (
      <Page pageName={<MsgEntity entityName={ScheduleOffsetsRequest.NAME}/>}>
        <Spin spinning={!this.loaded}>
          <Section size="large">
            <div>
              <Card className="narrow-layout card-actions-container" actions={this.actions()}
                    bordered={false}>
                <Form onSubmit={this.validate} layout="vertical">

                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="requestNumber"
                    form={this.props.form}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    disabled
                    getFieldDecoratorOpts={{
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'requestNumber']})
                      }]
                    }}
                  />

                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="status"
                    disabled
                    form={this.props.form}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    optionsContainer={this.statusesDc}
                    getFieldDecoratorOpts={{
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'status']})
                      }]
                    }}
                  />

                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="requestDate"
                    disabled
                    form={this.props.form}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    optionsContainer={this.statusesDc}
                    format={DEFAULT_DATE_PATTERN}
                    getFieldDecoratorOpts={{
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'requestDate']})
                      }]
                    }}
                  />

                  {this.props.personGroupId&&this.props.personGroupId!=="new"||this.props.entityId&&this.props.entityId!=="new"?<ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="personGroup"
                    optionsContainer={this.personGroupDc}
                    form={this.props.form}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    disabled={true}
                  />:<Form.Item label={createElement(Msg, {entityName: this.dataInstance.entityName, propertyName: "personGroup"})}>{this.props.form.getFieldDecorator("personGroup")(<Select placeholder={this.props.intl.formatMessage({id:"chooseEmployee"})} value={this.inputsVisible?this.selectedMember:undefined}  onChange={(value)=>this.setTeamMember(value)}>{this.teamMembers&&this.teamMembers.map((item:MyTeamNew)=><Select.Option value={item.personGroupId}>{item.fullName}</Select.Option>)}</Select>)}</Form.Item>}


                  <ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="currentSchedule"
                    optionsContainer={this.standardScheduleDc}
                    form={this.props.form}
                    disabled
                    formItemOpts={{style: {marginBottom: "12px"}}}
                  />

                  {/*<Form.Item>*/}
                  {/*  {this.props.form.getFieldDecorator('currentSchedule')*/}
                  {/*  (<Input disabled={true} value={this.standardScheduleAssignmentDc.schedule! && this.standardScheduleAssignmentDc!.schedule!.scheduleName!}/>)*/}
                  {/*  }*/}
                  {/*</Form.Item>*/}

                  <ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="newSchedule"
                    form={this.props.form}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    optionsContainer={this.newSchedulesDc}
                    disabled={this.isNotDraft()}
                    getFieldDecoratorOpts={{
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'newSchedule']})
                      }]
                    }}
                  />

                  <ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="earningPolicy"
                    form={this.props.form}
                    disabled={this.isNotDraft()}
                    optionsContainer={this.earningPolicyDc}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    getFieldDecoratorOpts={{
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'earningPolicy']})
                      }]
                    }}
                  />

                  <Form.Item
                    label={<Msg entityName={ScheduleOffsetsRequest.NAME} propertyName={"purpose"}/>}
                    key='purpose'
                    style={{marginBottom: '12px'}}
                  >
                    {this.props.form.getFieldDecorator('purpose', {
                      validateTrigger: ["onChange", "onBlur"],
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'purpose']})
                      }]
                    })(
                      <Select onChange={this.changePurpose} disabled={this.isNotDraft()}>
                        {this.purposesDc && this.purposesDc.items &&  this.purposesDc.items.map(p => (
                          //@ts-ignore
                          <Option value={p.id}
                            key={p.id}
                            //@ts-ignore
                            code={p.code}>
                              {p._instanceName}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item
                    label={<Msg entityName={ScheduleOffsetsRequest.NAME} propertyName={"purposeText"}/>}
                    key='purposeText'
                    style={{marginBottom: '12px', display: this.isVisiblePurposeText ? 'block' : 'none'}}
                  >
                    {this.props.form.getFieldDecorator('purposeText', {})(
                      <Input maxLength={50} disabled={this.isNotDraft()}/>
                    )}
                  </Form.Item>

                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="dateOfNewSchedule"
                    form={this.props.form}
                    disabled={this.isNotDraft()}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    optionsContainer={this.statusesDc}
                    format={DEFAULT_DATE_PATTERN}
                    getFieldDecoratorOpts={{
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'dateOfNewSchedule']})
                      }]
                    }}
                  />

                  <ReadonlyField
                    entityName={this.dataInstance.entityName}
                    propertyName="dateOfStartNewSchedule"
                    form={this.props.form}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    optionsContainer={this.statusesDc}
                    format={DEFAULT_DATE_PATTERN}
                    disabled={this.isNotDraft()}
                    getFieldDecoratorOpts={{
                      rules: [{
                        required: true,
                        validator: (rule, value, callback) => {
                          if (!value) return callback(this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[ScheduleOffsetsRequest.NAME + '.' + 'dateOfNewSchedule']}));

                          if (this.daysBeforeAbsence) {
                            const {
                              dateOfStartNewSchedule,
                              requestDate
                            } = this.props.form.getFieldsValue(["dateOfStartNewSchedule", "requestDate"]);
                            const numberOfDays = (dateOfStartNewSchedule as Moment).diff((requestDate as Moment).clone().add(1, 'days'), 'days');
                            if (numberOfDays < this.daysBeforeAbsence) {
                              return callback(this.props.intl.formatMessage({id: 'scheduleOffsetRequest.validate.daysBeforeAbsence'}, {numberOfDays: this.daysBeforeAbsence}))
                            }
                          }

                          return callback();
                        }
                      }]
                    }}
                  />

                  <Form.Item
                    label={<Msg entityName={ScheduleOffsetsRequest.NAME} propertyName={"detailsOfActualWork"}/>}
                    key='detailsOfActualWork'
                    style={{marginBottom: '12px'}}>
                    {this.props.form.getFieldDecorator('detailsOfActualWork', {})(
                      <TextArea
                        disabled={this.isNotDraft()}
                        rows={4} maxLength={2000}/>
                    )}
                  </Form.Item>

                  <ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="agree"
                    form={this.props.form}
                    disabled={this.approverHrRoleCode !== 'EMPLOYEE'}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    getFieldDecoratorOpts={{
                      valuePropName: "checked"
                    }}
                  />

                  <ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="acquainted"
                    form={this.props.form}
                    disabled={this.approverHrRoleCode !== 'EMPLOYEE'}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                    getFieldDecoratorOpts={{
                      valuePropName: "checked"
                    }}
                  />

                  <ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="comment"
                    form={this.props.form}
                    disabled={this.isNotDraft()}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                  />

                  <ReadonlyField
                    entityName={ScheduleOffsetsRequest.NAME}
                    propertyName="files"
                    form={this.props.form}
                    disabled={this.isNotDraft()}
                    formItemOpts={{style: {marginBottom: "12px"}}}
                  />

                  {this.takCard()}

                </Form>
              </Card>
            </div>
          </Section>
        </Spin>
      </Page>
    );
  }

  report = () => {

    const data = {
      parameters: [{
        name: "req",
        value: this.props.entityId&&this.props.entityId!=="new"?this.props.entityId:this.selectedMember
      }]
    };

    const reportCode = "REP_SHEDULE_REQUEST";

    runReport(reportCode, data, this.props.intl);
  }

  changePurpose = (value: string, option: React.ReactElement<HTMLLIElement>) => {
    const purposeCode = option!.props["code"];
    this.setIsVisiblePurposeText(purposeCode);

    this.props.form.setFieldsValue({
      purposeText: undefined
    });
  };

  @action
  setIsVisiblePurposeText = (purposeCode?: string | null) => {
    this.isVisiblePurposeText = purposeCode == undefined ? false : purposeCode.toLowerCase() === 'other';
  };

  validate = (): Promise<boolean> => {
    let isValidatedSuccess = true;
    this.props.form.validateFields(this.fields, {force: true}, (err, values) => {
      isValidatedSuccess = !err;
      if (err) {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.validationError"})
        });
      }
    });
    if (isValidatedSuccess) {
    }
    return new Promise(resolve => resolve(isValidatedSuccess));
  };

  loadData = async () => {
    if (!this.isNewEntity()) {
      await this.dataInstance.load(this.props.entityId&&this.props.entityId!=="new"?this.props.entityId:this.selectedMember);
    } else {
      const entityName = this.dataInstance.entityName;
      this.initItem(await restServices.portalHelperService.newEntity({entityName: entityName}));
    }
  };

  componentDidMount() {

    (async () => {
      this.setReactionDisposer();
     await this.loadData();
     await this.loadBpmProcessData();

      if ((!this.props.personGroupId!)&&(!this.selectedMember)){
        await restServices.myTeamService.getChildren({parentPositionGroupId: this.props.rootStore!.userInfo.positionGroupId!}).then(res => {
          if(res.length > 0){
            this.teamMembers = res;
            this.selectedMember=this.teamMembers && this.teamMembers[0].personGroupId!;
          }
        });
      }


      if(this.props.rootStore!.scheduleStore!.currentSchedule){
        this.standardScheduleDc = this.props.rootStore!.scheduleStore!.currentSchedule!.items!.filter(item=>item.startDate <= moment().format(JSON_DATE_TIME_FORMAT) && item.endDate >= moment().format(JSON_DATE_TIME_FORMAT)).map(item=>({items:[item.schedule]}))[0]
      }

      const scheduleOffsetRequest = this.dataInstance.item!;
      const personGroupId: string = this.props.personGroupId ? this.props.personGroupId : scheduleOffsetRequest && scheduleOffsetRequest.personGroup && scheduleOffsetRequest.personGroup.id ? scheduleOffsetRequest.personGroup.id : this.selectedMember;


      const requestDate: moment.Moment = this.isNewEntity() ? moment() : moment(scheduleOffsetRequest.requestDate);

      if(personGroupId){
        if (this.dataInstance
          && this.dataInstance.item
          && this.dataInstance.item.currentSchedule
          && this.dataInstance.item.currentSchedule.id
        ) {
          this.standardScheduleDc = collection<StandardSchedule>(StandardSchedule.NAME, {
            view: "_minimal",
            loadImmediately: true,
            filter: {
              conditions: [{
                property: 'id',
                operator: '=',
                value:  this.dataInstance!.item!.currentSchedule!.id
              }]
            },
          });
        }

        this.newSchedulesDc = dictionaryCollection<StandardSchedule>(StandardSchedule.NAME, personGroupId, {
        view: "_minimal",
        sort: 'scheduleName',
        loadImmediately: true,
        filter: {
          conditions: [{
            property: 'startDate',
            operator: '<=',
            value: requestDate.format('YYYY-MM-DD')
          }, {
            property: 'endDate',
            operator: '>=',
            value: requestDate.format('YYYY-MM-DD')
          }]
        }
      })}

      if (this.isNewEntity()) {
        this.loaded = true;

        if (this.standardScheduleDc) {
          this.dataInstance.item!.currentSchedule = this.standardScheduleDc.items![0];
          if (this.dataInstance.item!.currentSchedule && this.props.personGroupId || this.inputsVisible)
            this.props.form.setFieldsValue({'currentSchedule': this.dataInstance.item!.currentSchedule!.id!});

          this.loaded = true;

        } else {
          this.setIsVisiblePurposeText(scheduleOffsetRequest.purpose! ? scheduleOffsetRequest.purpose!.code : undefined)
        }
      }

      this.setIsDisabledFields(scheduleOffsetRequest.status!.code!.toLowerCase() != 'draft' || scheduleOffsetRequest.status!.code! !== "TO_BE_REVISED" );

      this.loadPersonGroupDc(personGroupId);

     await this.loadPerson(personGroupId);

      if(personGroupId)this.loadEarningPolicyDc(personGroupId);

    if(personGroupId)this.loadPurposesDc(personGroupId);

      this.setEmployee(personGroupId);

      if (!this.isNewEntity()) {
        this.loaded = true;
      }

      restServices.absenceService.scheduleOffsetDaysBeforeAbsence().then(response => {
        if (response) {
          this.daysBeforeAbsence = Number(response);
        }
      })
    })()
  }
  loadEarningPolicyDc = (personGroupId: string) => {
    this.earningPolicyDc = dictionaryCollection<DicEarningPolicy>(DicEarningPolicy.NAME, personGroupId, {
      filter: {
        conditions: [{
          property: 'active',
          operator: '=',
          value: 'TRUE'
        }]
      },
      loadImmediately: true
    });
  };

  loadPurposesDc = (personGroupId: string) => {
    this.purposesDc = dictionaryCollection<DicSchedulePurpose>(DicSchedulePurpose.NAME, personGroupId, {
      loadImmediately: true
    });
  };

  loadPersonGroupDc = (personGroupId: string) => {
    this.personGroupDc = collection<PersonGroupExt>(PersonGroupExt.NAME, {
      view: "_minimal",
      filter: {
        conditions: [{
          property: "id",
          operator: "=",
          value: personGroupId
        }]
      },
      limit: 10
    });
    this.personGroupDc.load();
  };

  componentWillUnmount() {
    this.reactionDisposer();
  }

  afterSendOnApprove = () => {
    if (this.isNotDraft()) this.props.history!.goBack();
    else this.props.history!.push(ScheduleOffsetsRequestManagement.PATH);
  };

  loadPerson = async (personGroupId: string): Promise<void> => {
    const response = await getCubaREST()!.searchEntities<PersonGroupExt>(PersonGroupExt.NAME, {
      conditions: [{
        property: 'id',
        operator: '=',
        value: personGroupId,
      }]
    }, {
      view: 'personGroupExt-absenceEdit'
    });
    this.dataInstance.item!.personGroup = response[0];
    if(this.props.personGroupId)this.props.form.setFieldsValue({'personGroup': personGroupId});
  };

  @action
  setIsDisabledFields = (value: boolean): void => {
    this.isDisabledFields = value;
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(ScheduleOffsetsRequestEditComponent))
);

