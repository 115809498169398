import * as React from "react";
import {inject, observer} from "mobx-react";

import {collection, getCubaREST, injectMainStore, instance, MainStoreInjected} from "@cuba-platform/react";

import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";

import Question from "../../components/Questionnaire/Question";
import {EmployeeQuestionnaireQuestions} from "../../../cuba/entities/base/tsadv_EmployeeQuestionnaireQuestions";
import {Button, Icon, Spin} from "antd";
import {action, isObservableArray, observable, toJS, values} from "mobx";
import "../../App.css"
import Answer from "../../components/Questionnaire/Answer";
import {RadioChangeEvent} from "antd/es/radio";
import {is} from "@amcharts/amcharts4/core";
import {EmployeeQuestionnaireResults} from "../../../cuba/entities/base/tsadv_EmployeeQuestionnaireResults";
import {userInfo} from "os";
import {EmployeeQuestionnaireParticipants} from "../../../cuba/entities/base/tsadv_EmployeeQuestionnaireParticipants";
import {EmployeeQuestionnaire} from "../../../cuba/entities/base/tsadv_EmployeeQuestionnaire";
import {EmployeeQuestionnaireAnswers} from "../../../cuba/entities/base/tsadv_EmployeeQuestionnaireAnswers";
import {StudentHomework} from "../../../cuba/entities/base/tsadv_StudentHomework";
import Notification from "../../util/Notification/Notification";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {Link} from "react-router-dom";
import {employeeQuestionnaireTablePATH} from "./employeeQuestionnaireTable";
import {FormattedMessage} from "react-intl";

export const EmployeeQuestionnaireExecutionListPath = "/test"


@injectMainStore
@inject("rootStore")
@observer
class QuestionnaireExecutionList1 extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  @observable index: number = 0;

  @observable answers = {
    questionIndex: 0,
    answer: {}
  }
  @observable checkedAnswers: any = []
  checkedAnswersForSizeOfLocalstorage: Array<any>;

  @observable valueOfInput: object

  @observable questionnaireId: any = window.location.href.split("?")[1]

  @observable result = new EmployeeQuestionnaireResults();

  checkedAnswersToProps: any = [];

  sizeOfLocalstorage: number = 0;
  count: number = 0;

  dataCollection = collection<EmployeeQuestionnaire>(EmployeeQuestionnaire.NAME, {
    filter: {
      conditions: [{
        property: 'id',
        operator: '=',
        value: this.questionnaireId
      }]
    },
    view: "employeeQuestionnaire-view_edit"
    // sort: "-period"
  })

  employeeQuestionnaireParticipantsDc = collection<EmployeeQuestionnaireParticipants>(EmployeeQuestionnaireParticipants.NAME,{
    filter: {
      conditions: [{
        property: 'employeeQuestionnaire.id',
        operator: '=',
        value: this.questionnaireId
      },
        {
          property: 'personGroup.id',
          operator: '=',
          value: this.props.rootStore!.userInfo!.personGroupId!
        }
      ]
    },
    view: "employeeQuestionnaireParticipants-browseView"
  })


  componentDidMount() {
    this.calculateSizeOfLocalStorage();
    getCubaREST()!.searchEntities<EmployeeQuestionnaireQuestions>(EmployeeQuestionnaireQuestions.NAME,{
      conditions: [{
        property: 'employeeQuestionnaire.id',
        operator: '=',
        value: this.questionnaireId
      }]
    },{view:"employeeQuestionnaireQuestions-view_edit"}).then(value=>{
      this.questions = value
    })

      getCubaREST()!.searchEntities<EmployeeQuestionnaireResults>(EmployeeQuestionnaireResults.NAME, {
        conditions: [{
          property: 'question.employeeQuestionnaire.id',
          operator: '=',
          value: this.questionnaireId
        }, {
          property: 'employeeQuestionnaireParticipants.personGroup.id',
          operator: '=',
          value: this.props.rootStore!.userInfo!.personGroupId!
        }]
      }, {view: "employeeQuestionnaireResults-browseView"}).then(value => {
        this.array = value
      })

  }


  @observable dataInstance = instance<EmployeeQuestionnaireParticipants>(EmployeeQuestionnaireParticipants.NAME, {
    view: "employeeQuestionnaireParticipants-browseView"
  })

  @observable dataCollectionStatus: string

  @observable
  resultDataCollectionONE: any

  @observable
  resultDataCollectionMANY: any

  @observable resu: any
  participantStatus: boolean

  @observable
  questions:any;

  @observable
  array:any

  arrayToAnswears:any
  participantsId: string;
  personGroup = new PersonGroupExt();


  lengthOfQuestions: number = 0;
  answersToProps: Array<EmployeeQuestionnaireQuestions>;

  indexIncreaseHandler = () => {
    this.index++;
  }

  indexDecreaseHandler = () => {
    this.index--;
  }

  handleCheckboxClick = (e: any) => {
    const isExist = localStorage.getItem(this.questionnaireId + "?" + this.index.toString());
    if (isExist) {
      this.checkedAnswers = JSON.parse(localStorage.getItem(this.questionnaireId + "?" + this.index.toString())!);
    } else {
      this.checkedAnswers = [];
    }
    if (typeof this.checkedAnswers === "string") {
      this.checkedAnswers = [...this.checkedAnswers]
    }
    if (this.checkedAnswers.length === 0)
      this.sizeOfLocalstorage--;
    if (this.checkedAnswers.indexOf(e.target.value!) === -1) {
      this.checkedAnswers.push(e.target.value!);
    } else {
      for (let i = 0; i < this.checkedAnswers.length; i++) {
        if (this.checkedAnswers[i].localeCompare(e.target.value) === 0) {
          this.checkedAnswers.splice(i, 1);
          break;
        }
      }
    }
    localStorage.setItem(this.questionnaireId + "?" + this.index.toString(), JSON.stringify(this.checkedAnswers));
    this.forceUpdate();
  }

  generetaNewArray = (arr:any, type:string ) =>{
    if(arr && arr[0]  && arr[0]!.question! && arr[0]!.question!.questionType! === type){
      let text =  arr && arr[0]!.answerText!
      const spitted = text.split(',')
      const updated = spitted.map((item:any)=>({"answerText":item}))
      const arr1Map = new Map(this.questions![this.index].questionnaireAnswers!.map((o:any) => [o.answerText, o]));
      const emptyArray = updated.map((o:any) => arr1Map.get(o.answerText) || o);
      if(type === "ONE"){
        this.resultDataCollectionONE = emptyArray.map((item:any)=>item.id)
      }
      else if(type === "MANY"){
        this.resultDataCollectionMANY = emptyArray.map((item:any)=>item.id)
      }
    }

  }
  handleRadioClick = (e: any) => {
    localStorage.setItem(this.questionnaireId + "?" + this.index.toString(), JSON.stringify(e.target.value));
    this.forceUpdate();
  }

  handleTextInput = (e: any) => {
    this.valueOfInput = e.target.value;
    localStorage.setItem(this.questionnaireId + "?" + this.index.toString(), JSON.stringify(this.valueOfInput));
    this.forceUpdate();
  }

  handleFinishButton = () => {

    for (let questionIndex = 0; questionIndex < this.lengthOfQuestions; questionIndex++) {
      this.checkedAnswers = JSON.parse(localStorage.getItem(this.questionnaireId + "?" + questionIndex)!)
      let checkedAnswersTemp = []
      if (this.questions[questionIndex].questionType === "MANY" || this.questions[questionIndex].questionType === "ONE") {
        if (typeof (this.checkedAnswers) === 'string') {
          for (let j = 0; j < this.questions[questionIndex].questionnaireAnswers.length; j++) {
            if (this.questions[questionIndex].questionnaireAnswers[j].id === this.checkedAnswers) {
              checkedAnswersTemp.push(this.questions[questionIndex].questionnaireAnswers[j].answerText);
            }
          }
        } else {
          for (let i = 0; i < this.checkedAnswers.length; i++) {
            for (let j = 0; j < this.questions[questionIndex].questionnaireAnswers.length; j++) {
              if (this.questions[questionIndex].questionnaireAnswers[j].id === this.checkedAnswers[i]) {
                checkedAnswersTemp.push(this.questions[questionIndex].questionnaireAnswers[j].answerText);
              }
            }
          }
        }
        this.checkedAnswers = checkedAnswersTemp;
      }


      this.result = {
        question: this.questions![questionIndex]!,
        employeeQuestionnaireParticipants: {id: this.participantsId!},
        answerText: this.checkedAnswers!.toString()
      }

      getCubaREST()!.commitEntity(EmployeeQuestionnaireResults.NAME, toJS(this.result))
        .then(value => {
          // console.log(value)
        })
        .catch((e: any) => {
          // console.log(e)
        });
    }

    if (this.dataCollection.status === "DONE") {
      console.log(this.dataInstance)
      const participant = this.dataInstance.item!
      participant.employeeQuestionnaire = this.dataCollection.items[0]!;
      participant.status = true;
      participant.personGroup = this.personGroup!;
      this.dataInstance.update(participant).then(value => {
        Notification.success({message: this.props.intl.formatMessage({id: "management.editor.success.questionaire"})});
      }).catch((e: any) => {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.error"})
        });
      });
    }
    for (let questionIndex = 0; questionIndex < this.lengthOfQuestions; questionIndex++) {
      localStorage.removeItem(this.questionnaireId + "?" + questionIndex)
    }

  }

  calculateSizeOfLocalStorage = () => {
    this.count = 0;
    for (let i = 0; i < this.lengthOfQuestions; i++) {
      if (localStorage.getItem(this.questionnaireId + "?" + i)!)
        this.checkedAnswersForSizeOfLocalstorage = JSON.parse(localStorage.getItem(this.questionnaireId + "?" + i)!);
      if (localStorage.getItem(this.questionnaireId + "?" + i) && this.checkedAnswersForSizeOfLocalstorage.length !== 0)
        this.count++;
    }
    this.sizeOfLocalstorage = this.count;
  }



  render() {
    if (!this.dataCollection || this.dataCollection.status === "LOADING") {
      return <Icon type="spin"/>;
    }

    //this.questions = this!.dataCollection! && this!.dataCollection!.items && this!.dataCollection!.items[0]!.employeeQuestionnaireQuestions!;
    // if(this.employeeQuestionnaireQuestionsDc.items && this.employeeQuestionnaireQuestionsDc.items.length>=0){
    //   this.questions = this.employeeQuestionnaireQuestionsDc.items;
    // }


    // console.log(this.resultDataCollection)
    console.log(this.dataCollection)
    console.log(this.questions)
    console.log(this.index)
    // this.dataCollection.items[0] && this.dataCollection.items[0].employeeQuestionnaireParticipants && this.dataCollection.items[0].employeeQuestionnaireParticipants!.map(participant => {
    //   if (participant.personGroup!.id === this.props.rootStore!.userInfo.personGroupId) {
    //     this.participantsId = participant.id;
    //     this.personGroup = participant.personGroup!;
    //     this.dataInstance.load(this.participantsId);
    //     this.participantStatus = participant.status!;
    //
    //   }
    // })
    this.employeeQuestionnaireParticipantsDc.items!.map(participant => {
      if (participant.personGroup!.id === this.props.rootStore!.userInfo.personGroupId) {
        this.participantsId = participant.id;
        this.personGroup = participant.personGroup!;
        this.dataInstance.load(this.participantsId);
        this.participantStatus = participant.status!;

      }
    })

   // this.array = this.resultDataCollection && this.resultDataCollection.items && this.resultDataCollection.items!.filter((item:any)=>item.question!.id! === this.questions[this.index]!.id! )

    this.arrayToAnswears = this.array && this.array.filter((item: any) => item.question!.id! === this.questions[this.index]!.id!)


    this.generetaNewArray(this.arrayToAnswears, "ONE")
    this.generetaNewArray(this.arrayToAnswears, "MANY")

   // this.dataCollection.items!.map(x => this.lengthOfQuestions = ( x.employeeQuestionnaireQuestions!.length))
    this.lengthOfQuestions = this.questions && this.questions.length
    this.calculateSizeOfLocalStorage();

    return (<>{this.questions?
      <Page pageName={this.props.intl.formatMessage({id: "menu.questionnaire"})}>
        <h2 style={{margin:"0 40px"}}> {this.dataCollection && this.dataCollection.items[0]!.description!} </h2>
        <Section size="large">

          <Question question={{
            id: this.questions && this.questions![this.index]!.id!,
            text: this.questions && this.questions![this.index]!.questionText!,
            type: this.questions && this.questions![this.index]!.questionType!
          }}
          />
          {
            (this.questions![this.index].questionType! === "MANY") ?
              (this.answersToProps = [],
                  this.checkedAnswersToProps = localStorage.getItem(this.questionnaireId + "?" + this.index.toString()),
                  this.checkedAnswersToProps !== null ? console.log('//', this.checkedAnswersToProps) : this.checkedAnswersToProps = [],
                  this.questions![this.index].questionnaireAnswers!.map((value: EmployeeQuestionnaireAnswers) =>
                    this.answersToProps.push(value)),
              this.participantStatus?<Answer type={this.questions![this.index].questionType!}
                                             isReadOnly={(this.participantStatus)}
                          answers={this.answersToProps}
                          questionId={this.questions![this.index].id!}
                          handleCheckboxClick={this.handleCheckboxClick}
                          checkedAnswers={this.resultDataCollectionMANY}
                  />:<Answer type={this.questions![this.index].questionType!}
                             answers={this.answersToProps}
                             questionId={this.questions![this.index].id!}
                             handleCheckboxClick={this.handleCheckboxClick}
                             checkedAnswers={this.checkedAnswersToProps}
              />
              ) :
              (this.questions![this.index].questionType! === "ONE") ?
                (this.answersToProps = [],
                    this.checkedAnswersToProps = localStorage.getItem(this.questionnaireId + "?" + this.index.toString()),
                    this.checkedAnswersToProps !== null ? this.checkedAnswersToProps = this.checkedAnswersToProps.substr(1, this.checkedAnswersToProps.length - 2)
                      : this.checkedAnswersToProps = [],
                    this.questions![this.index].questionnaireAnswers!.map((value: EmployeeQuestionnaireAnswers, index1: number) =>
                      this.answersToProps.push(value)),
                   this.participantStatus?
                     <Answer
                       type={this.questions![this.index].questionType!}
                       isReadOnly={(this.participantStatus)}
                            questionId={this.questions![this.index].id!}
                            questionnaireId={this.questionnaireId}
                            indexOfQuestion={this.index}
                            handleCheckboxClick={this.handleRadioClick}
                            answers={this.answersToProps}
                            checkedAnswers={this.resultDataCollectionONE && this.resultDataCollectionONE[0]}

                    />:<Answer type={this.questions![this.index].questionType!}
                               questionId={this.questions![this.index].id!}
                               questionnaireId={this.questionnaireId}
                               indexOfQuestion={this.index}
                               handleCheckboxClick={this.handleRadioClick}
                               answers={this.answersToProps}
                               checkedAnswers={this.checkedAnswersToProps}

                   />
                ) :
                (this.questions![this.index].questionType! === "TEXT") ?
                  (
                    this.checkedAnswersToProps = [],
                      this.checkedAnswersToProps = localStorage.getItem(this.questionnaireId + "?" + this.index.toString()),
                      this.checkedAnswersToProps !== null ? this.checkedAnswersToProps = this.checkedAnswersToProps.substr(1, this.checkedAnswersToProps.length - 2)
                        : this.checkedAnswersToProps = [],
                     this.participantStatus? <Answer type={this.questions![this.index].questionType!}
                                                     isReadOnly={(this.participantStatus)}
                              questionId={this.questions![this.index].id!}
                              handleCheckboxClick={this.handleTextInput}
                              checkedAnswers={this.arrayToAnswears && this.arrayToAnswears[0] && this.arrayToAnswears[0].answerText!}
                      />:
                       <Answer type={this.questions![this.index].questionType!}
                               questionId={this.questions![this.index].id!}
                               handleCheckboxClick={this.handleTextInput}
                               checkedAnswers={this.checkedAnswersToProps}
                       />
                  ) :
                  (this.questions![this.index].questionType! === "NUM") ?
                    (
                      this.checkedAnswersToProps = [],
                        this.checkedAnswersToProps = localStorage.getItem(this.questionnaireId + "?" + this.index.toString()),
                        this.checkedAnswersToProps !== null ? this.checkedAnswersToProps = this.checkedAnswersToProps.substr(1, this.checkedAnswersToProps.length - 2)
                          : this.checkedAnswersToProps = [],
                       this.participantStatus? <Answer type={this.questions![this.index].questionType!}
                                                       isReadOnly={(this.participantStatus)}
                                questionId={this.questions![this.index].id!}
                                handleCheckboxClick={this.handleTextInput}
                                checkedAnswers={parseInt(this.arrayToAnswears && this.arrayToAnswears[0] && this.arrayToAnswears[0].answerText!, 10)}
                        />:<Answer type={this.questions![this.index].questionType!}
                                   questionId={this.questions![this.index].id!}
                                   handleCheckboxClick={this.handleTextInput}
                                   checkedAnswers={this.checkedAnswersToProps}
                       />
                    ) :
                    <></>

          }
          <div>
            <Button style={{borderStyle: "solid", marginRight: "8px"}} shape={"round"} disabled={this.index === 0}
                    icon={"left"} onClick={this.indexDecreaseHandler}/>
            <Button shape={"round"} disabled={this.index === this.lengthOfQuestions - 1} icon={"right"}
                    onClick={this.indexIncreaseHandler}/>
            <Link to={"/employeeQuestionnaire"}>
              {!this.participantStatus ? <Button shape={"round"} onClick={this.handleFinishButton}
                      disabled={this.lengthOfQuestions !== this.sizeOfLocalstorage || this.participantStatus}
                      style={{float: "right",}}>{this.props.intl.formatMessage({id: "questionnaireFinish"})}</Button>:
               <Link to={
                 employeeQuestionnaireTablePATH
               }>
                 <Button style={{background:"#005781", color:"white",float: "right"}}>
                   <FormattedMessage id={"question.back"} />
                 </Button>
               </Link>
              }
            </Link>
          </div>
        </Section>
      </Page>:<Spin spinning={true}/>}</>
    );


  }






}

const QuestionnaireExecutionList = injectIntl(QuestionnaireExecutionList1);

export default QuestionnaireExecutionList;
