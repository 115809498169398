import { PersonGroupExt } from "./base$PersonGroupExt";
import { IdpDetail } from "./tsadv$IdpDetail";
import { IdpMeeting } from "./tsadv_IdpMeeting";
import { PositionGroupExt } from "./base$PositionGroupExt";
import { Course } from "./tsadv$Course";
import {AbstractBprocRequest} from "./AbstractBprocRequest";
import {PersonAssessment} from "./tsadv_PersonAssessment";

export class IndividualDevelopmentPlan extends AbstractBprocRequest {
  static NAME = "tsadv$IndividualDevelopmentPlan";
  personGroup?: PersonGroupExt | null;
  planName?: string | null;
  planNameEn?:string | null;
  status?: any | null;
  startDate?: any | null;
  endDate?: any | null;
  idpDetail?: IdpDetail[] | null;
  idpMeeting?: IdpMeeting[] | null;
  keyPosition?: PositionGroupExt | null;
  planPercent?: any | null;
  personHelp?: PersonGroupExt | null;
  course?: Course | null;
  complete?: boolean | null;
  positionGroup?: PositionGroupExt | null;
  personAssessment?:PersonAssessment | null;
}
export type IndividualDevelopmentPlanViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "individualDevelopmentPlan-for-personcardnew"
  | "individualDevelopmentPlan.edit"
  | "individualDevelopmentPlanNew-edit";
export type IndividualDevelopmentPlanView<
  V extends IndividualDevelopmentPlanViewName
  > = V extends "_base"
  ? Pick<
    IndividualDevelopmentPlan,
    | "id"
    | "planName"
    | "status"
    | "startDate"
    | "endDate"
    | "planPercent"
    | "complete"
    | "legacyId"
    | "organizationBin"
    | "integrationUserLogin"
    >
  : V extends "_local"
    ? Pick<
      IndividualDevelopmentPlan,
      | "id"
      | "planName"
      | "status"
      | "startDate"
      | "endDate"
      | "planPercent"
      | "complete"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      >
    : V extends "_minimal"
      ? Pick<IndividualDevelopmentPlan, "id" | "planName">
      : V extends "individualDevelopmentPlan-for-personcardnew"
        ? Pick<
          IndividualDevelopmentPlan,
          | "id"
          | "planName"
          | "status"
          | "startDate"
          | "endDate"
          | "planPercent"
          | "complete"
          | "legacyId"
          | "organizationBin"
          | "integrationUserLogin"
          | "personGroup"
          | "idpDetail"
          >
        : V extends "individualDevelopmentPlan.edit"
          ? Pick<
            IndividualDevelopmentPlan,
            | "id"
            | "planName"
            | "status"
            | "startDate"
            | "endDate"
            | "planPercent"
            | "complete"
            | "legacyId"
            | "organizationBin"
            | "integrationUserLogin"
            | "requestNumber"
            | "requestDate"
            | "comment"
            | "personGroup"
            | "idpDetail"
            >
          : V extends "individualDevelopmentPlanNew-edit"
            ? Pick<
              IndividualDevelopmentPlan,
              | "id"
              | "planName"
              | "status"
              | "startDate"
              | "endDate"
              | "planPercent"
              | "complete"
              | "legacyId"
              | "organizationBin"
              | "integrationUserLogin"
              | "personGroup"
              | "idpDetail"
              | "idpMeeting"
              | "positionGroup"
              >
            : never;
