import * as React from "react";
import {inject, observer} from "mobx-react";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Button, Col, Row, Input, Typography, Tabs, message, Upload, Form,Modal} from 'antd';
import {Link} from "react-router-dom";
import {organizationVacancyInfoPATH} from "./OrganizationVacancyInfo";
import {RouteComponentProps, withRouter} from "react-router";
import {action, observable, runInAction} from "mobx";
import {JobRequest} from "../../../cuba/entities/base/tsadv$JobRequest";
import moment from "moment";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {DicSource} from "../../../cuba/entities/base/tsadv$DicSource";
import {PersonAttachment} from "../../../cuba/entities/base/tsadv$PersonAttachment";
import {DicAttachmentCategory} from "../../../cuba/entities/base/tsadv$DicAttachmentCategory";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {organizationVacancyListPATH} from "./OgranizationVacancyList";
import {FormComponentProps} from "antd/es/form";
import {FormEvent} from "react";
import {RefferalCandidate} from "../../../cuba/entities/base/tsadv_RefferalCandidate";
import {restServices} from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";

export const organizationVacancyRespondPATH = "/OrganizationVacancyRespond";

type Props = RouteComponentProps<{ entityId: string}>;

@injectMainStore
@inject("rootStore")
@observer
class organizationVacancyRespondClass extends React.Component<FormComponentProps&Props&MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  DicSource = collection<DicSource>(DicSource.NAME,{
    view:"_minimal",
    filter:{
      conditions:[{
        property:"code",
        operator:"=",
        value:"INTERNAL_CANDIDATE"
      }]
    }
  })
  DicAttachmentCategory = collection<DicAttachmentCategory>(DicAttachmentCategory.NAME,{
    view:'_minimal',
    filter:{
      conditions:[{
        property:"code",
        operator:"=",
        value:"RESUME"
      }]
    }
  })
  @observable person:PersonExt;
  @observable fileId:string;
  @observable fileName:string;
  @observable saving:boolean = false;
  @action respond = (e:FormEvent<HTMLFormElement>) => {
   e.preventDefault();
   Modal.confirm({
     title: this.props.intl.formatMessage({id: "organizationVacancy.modal.respond.title"}),
     okText: this.props.intl.formatMessage({id: "cubaReact.dataTable.yes"}),
     cancelText: this.props.intl.formatMessage({id: "cubaReact.dataTable.no"}),
     onOk: () => {
       if(!this.isHaveReq){
        this.props.form.validateFieldsAndScroll((error)=>{
          if(!error) {
            message.loading({content: this.props.intl.formatMessage({id: "message.saving"}), key: "updatatable",duration:0})
            this.saving = true;
            const savePerson = getCubaREST()!.commitEntity<PersonExt>(PersonExt.NAME, {
              id: this.person.id,
              source: {id: this.DicSource.items[0].id}
            })
            const saveAttachment = getCubaREST()!.commitEntity<PersonAttachment>(PersonAttachment.NAME, {
              attachment: {id: this.fileId},
              personGroup: {id: this.props.rootStore!.userInfo.personGroupId!},
              category: {id: this.DicAttachmentCategory.items[0].id},
              filename: this.fileName
            })
            const saveJobReq = restServices.learningService.createJobRequestForRecommendation({requisition:this.props.match.params.entityId,requestDate:moment().toISOString(),candidatePersonGroup:this.props.rootStore!.userInfo.personGroupId!})
            Promise.all([savePerson, saveAttachment, saveJobReq])
              .then(() => {
                message.success({
                  content: this.props.intl.formatMessage({id: "organizationVacancy.respond.message.success"}),
                  key: "updatatable"
                })
                this.props.history.push(organizationVacancyListPATH)
              })
              .catch(() => message.error({
                content: this.props.intl.formatMessage({id: "management.editor.error"}),
                key: "updatatable",
                duration: 2
              })).finally(()=>this.saving = false)
          }})
          return
       }
       Notification.error({message:this.props.intl.formatMessage({id:"alreadyResponded.error"})})
     }
   });
  }
  @observable isHaveReq:boolean;
  render() {
    const { TabPane } = Tabs;
    const {Text} = Typography;
    const entityId = this.props.match.params.entityId;
    return (
      <Page pageName={<FormattedMessage id={"organization.vacancies"}/>} >
        <Section size="large">
              <Tabs size={"large"} style={{width:"100%"}}>
                <TabPane tab={<FormattedMessage id={"main.data"}/>} key={"1"}>
                  <Section sectionName={<FormattedMessage id={"main.data"}/>} size={"large"} style={{width:"95%"}}>
                    <Form onSubmit={(e)=>this.respond(e)}>
                    <Row>
                      <Col span={10}><Text strong>{this.props.intl.formatMessage({id:"enterYourName"})}</Text></Col>
                      <Col span={14}><Input readOnly type={'text'} value={this.props.rootStore!.userInfo!.firstLastName}/></Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col span={10}><Text strong>{this.props.intl.formatMessage({id:"attachYourResume"})}</Text></Col>
                      <Col span={14}>
                        <Form.Item style={{marginBottom:0}}>
                          {this.props.form.getFieldDecorator('file',{
                            valuePropName:'fileList',
                            getValueFromEvent: e => {
                              if (Array.isArray(e)) {
                                return e;
                              }
                              return e && e.fileList;
                            },
                            rules:[{
                              required:true,
                              message:this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![RefferalCandidate.NAME + '.file']})
                            }]
                          })
                          (<Upload
                          style={{display:"inline-block"}} action={getCubaREST()!.getFileUploadURL()}
                          headers={{'Authorization': 'Bearer ' + getCubaREST()!.restApiToken}}
                          onChange={(info)=>runInAction(()=> {
                            if(info.file && info.file.response){
                              this.fileId = info.file.response.id
                              this.fileName = info.file.name;
                            }
                          })}>
                          <Button icon={"upload"} type={"default"}>
                            {this.props.intl.formatMessage({id:"file.Upload"})}
                          </Button>
                        </Upload>)}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{marginTop:"20px"}}>
                      <Col span={3}><Button loading={this.saving} type={"primary"} htmlType={"submit"} style={{marginLeft:"20px"}}><FormattedMessage id={"respond"}/></Button></Col>
                      <Col span={3}><Link to={organizationVacancyInfoPATH+"/"+entityId}><Button style={{marginLeft:"20px"}}><FormattedMessage id={"form.cancel"}/></Button></Link></Col>
                    </Row>
                    </Form>
                  </Section>
                </TabPane>
              </Tabs>
        </Section>
      </Page>
    );
  }
  componentDidMount() {
    getCubaREST()!.searchEntities<JobRequest>(JobRequest.NAME,{conditions:[{
      group:"AND",
      conditions:[{
      property:"requisition.id",
      operator:"=",
      value:this.props.match.params.entityId
    },{
      property:"candidatePersonGroup.id",
      operator:"=",
      value:this.props.rootStore!.userInfo.personGroupId!
    }]}]},{view:"jobRequest.full"}).then(res=>runInAction(()=>this.isHaveReq = res.length>0))
   getCubaREST()!.loadEntity<PersonGroupExt>(PersonGroupExt.NAME,this.props.rootStore!.userInfo.personGroupId,{view:"personGroup-view"})
     .then(value => runInAction(()=>{
       if(value.person)
       this.person = value.person}))
  }
}
const organizationVacancyRespond = Form.create()(withRouter(injectIntl(organizationVacancyRespondClass)));
export default organizationVacancyRespond;

