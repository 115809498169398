import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {observer} from "mobx-react";
import {action, observable, reaction} from "mobx";
import {restServices} from "../../../../cuba/services";
import {Card, Divider, Tooltip} from "antd";
import {PersonMedalPojo} from "../../../../cuba/entities/base/tsadv$PersonMedalPojo";
import Img from "../../../components/Img";
import {getFileUrlOpt} from "../../../util/util";

type PersonMedalsProps = {
  personGroupId: string;
};

@observer
class PersonMedals extends React.Component<WrappedComponentProps & PersonMedalsProps> {

  @observable personMedals?: PersonMedalPojo[];

  componentDidMount() {
    reaction(
      () => this.props.personGroupId,
      () => this.reload(),
      {fireImmediately: true}
    );
  }

  @action reload = async () => {
    this.personMedals = await restServices.recognitionService.loadPersonMedals(this.props.personGroupId);
  }

  renderMedal = (medal: PersonMedalPojo) => {
    return <Tooltip title={medal.medalName}>
      <Img style={{width: "100px"}} src={getFileUrlOpt(medal.medalImage)}/>
    </Tooltip>
  }

  render() {
    if (!this.personMedals || this.personMedals.length === 0) {
      return null;
    }

    return <div style={{marginBottom: 30}}>
      <h2>
        <FormattedMessage id="recognition.personMedals.title"/>
      </h2>
      <Divider style={{marginTop: 0}}/>
      {this.personMedals && this.personMedals.map(this.renderMedal)}
    </div>
  }
}

export default injectIntl(PersonMedals);