import React from "react";
import {FileDescriptor} from "../../../../cuba/entities/base/sys$FileDescriptor";
import {getFileUrl} from "../../../util/util";
import './styles.css'

type Props = {
  image?: string
  totalQuantity: number
  activeQuantity: number
}

export default class MedalComponent extends React.Component<Props> {

  render() {
    return (
      <div className={'medal-container'}>
        <div className={'medal-container__img'} style={{minHeight:"300px", display:"flex",flexDirection:"column", justifyContent:"center"}}>
          <img className={'medal-image'}  src={this.props.image}/>
        </div>
        <div className={'medal-quantity'}  style={{fontSize:"20px"}}>
          {constructMedalDescription(this.props.totalQuantity, this.props.activeQuantity)}
        </div>
      </div>
    )
  }
}

function constructMedalDescription(total: number, active: number) {
  if (total || active)
    return <a>{total}/{active}</a>
  else
    return <label>{total}/{active}</label>
}