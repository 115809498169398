import RootStore from "./RootStore";
import {action, observable} from "mobx";
import {getCubaREST, RouteItem, SubMenu} from "@cuba-platform/react";
import {PortalMenuCustomization} from "../../cuba/entities/base/tsadv_PortalMenuCustomization";
import {Config} from "../../cuba/entities/base/sys$Config";

export interface MenuRouteItem extends RouteItem {
  id: string,
}

export interface MenuSubMenu extends SubMenu {
  id: string,
  items: (MenuRouteItem | MenuSubMenu)[]
}

export default class MenuStore {
  root: RootStore;

  @observable menuList: Array<MenuRouteItem | MenuSubMenu> = [];

  @observable menuCustomization: Array<PortalMenuCustomization> = [];

  @observable ucoPortalUrl:string | null | undefined;

  constructor(root: RootStore) {
    this.root = root;
    this.loadUserMenuList();
  }

  @action
  loadUserMenuCustomization = () => {
    return this.root.cubaRest!.invokeService<string>(
      "tsadv_PortalHelperService",
      "getPortalMenu",
      {menuType: 'P'}
    ).then(value => JSON.parse(value))
      .then(value => this.menuCustomization = value)
  }

  @action
  loadConf = () => {
    getCubaREST()!.searchEntities<Config>(Config.NAME, {
        conditions: [{
          property: "name",
          operator: "=",
          value: "uco.portal.url"
        }]
      },
      {view: "_local"}
    ).then(e => this.ucoPortalUrl = e[0] ? e[0].value : null)
  }

  @action
  loadUserMenuList = () => {
    this.menuList = [
      {id: "main", caption: "Главная", menuLink: "/", pathPattern: "/", component: null},
      {
        id: "recognition",
        caption: "Лента благодарностей",
        menuLink: "/recognition",
        pathPattern: "/my-profile",
        component: null,
      },
      {
        id: "documents-sign",
        caption: "Документы на подписание",
        menuLink: "/activity/documentsSign",
        pathPattern: "/activity/documentsSign",
        component: null,
      },
      {
        id: "awards",
        caption: "HEART awards",
        menuLink: "/recognition/recognitionProgram/myNominee",
        pathPattern: "/recognition/recognitionProgram/myNominee",
        component: null,
      },
      {
        id: "my-profile",
        caption: "Мой профиль",
        menuLink: "/my-profile",
        pathPattern: "/my-profile",
        component: null
      },
      {
        id: "my-team",
        caption: "Моя команда",
        menuLink: "/my-team",
        pathPattern: "/my-team",
        component: null
      },
      {
        id: "allRequests",
        caption: "HR Заявки",
        menuLink: "/allRequestsPage",
        pathPattern: "/allRequestsPage",
        component: null,
        items: [
          {
            id: "allRequests-absence",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-manager-vacation-schedule",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-worker-vacation-schedule",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-certificate",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-payslip",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-dismissal",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-rvd",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-job-description",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-incentive",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-recognition",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-schedule-offset",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-punishment",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-punishment-dismissal",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-wccf",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-manager-material-help",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-worker-material-help",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-raf",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-job-combine",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-structure",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },
          {
            id: "allRequests-annual-salary-revision",
            caption: "Отсутствие",
            menuLink: "",
            pathPattern: "",
            component: null
          },

        ]
      },

      {
        id: "assistant-team",
        caption: "Assistant team",
        menuLink: "/assistant-team",
        pathPattern: "/assistant-team",
        component: null
      },
      {
        id: "surCharge",
        caption: "surCharge",
        items: [
          {
            id: "surChargeRequest",
            caption: "surChargeRequest",
            menuLink: "/mySurChargeRequest",
            pathPattern: "/mySurChargeRequest",
            component: null,
          }
        ]
      },
      {
        id: "vacationScheduleMenu",
        caption: "vacationScheduleMenu",
        items: [
          {
            id: "vacationSchedule.my",
            caption: "vacationSchedule.my",
            menuLink: "/vacationSchedule/my",
            pathPattern: "/vacationSchedule/my",
            component: null
          },
          {
            id: "vacationSchedule.manager",
            caption: "vacationSchedule.manager",
            menuLink: "/vacationSchedule/manager",
            pathPattern: "/vacationSchedule/manager",
            component: null
          },
          {
            id: "vacationSchedule.assistant",
            caption: "vacationSchedule.assistant",
            menuLink: "/vacationSchedule/assistant",
            pathPattern: "/vacationSchedule/assistant",
            component: null
          },
        ],
      },
      {
        id: "dmc-my",
        caption: "Мои ДМС",
        menuLink: "/my-dmc",
        pathPattern: "/my-dmc",
        component: null,
      },
      {
        id: "personPayslip",
        caption: "personPayslip",
        menuLink: "/personPayslip",
        pathPattern: "/personPayslip",
        component: null,
      },
      {
        id: "my-education", caption: "Обучение",
        menuLink: "/my-education",
        pathPattern: "/my-education",
        component: null
      },
      {
        id: "learning-budget", caption: "Бюджет",
        menuLink: "/learning-budget",
        pathPattern: "/learning-budget",
        component: null
      },
      {
        id: "employee-recognition", caption: "Признание сотрудников",
        menuLink: "/employee-recognition",
        pathPattern: "/employee-recognition",
        component: null
      },
      {
        id: "manager-vacancies", caption: "Вакансии",
        menuLink: "/manager-vacancies",
        pathPattern: "/manager-vacancies",
        component: null
      },
      {
        id: "menu-nine-box", caption: "9Box",
        menuLink: "/ninebox/menu",
        pathPattern: "/ninebox/menu",
        component: null
      },
      {
        id: "kpi",
        caption: "Отпуска",
        items:
          [{id: "my-kpi",
            caption: "Мой KPI",
            menuLink: "/kpi",
            pathPattern: "/kpi",
            component: null},
            {
            id: "team-kpi",
            caption: "KPI команды",
            menuLink: "/kpi-team",
            pathPattern: "/kpi-team",
            component: null,
          },
            {
              id: "nine-box", caption: "9Box",
              menuLink: "/ninebox",
              pathPattern: "/ninebox",
              component: null
            },],
      },

      {id: "concourse", caption: "Конкурс проектов", menuLink: "/concourse", pathPattern: "/concourse", component: null},

      {
        id: 'adaptationPlan.forNewEmployee',
        caption: 'Новым сотрудникам',
        menuLink: '/for-new-employee',
        pathPattern: '/for-new-employee',
        component: null,
      },
      {
        id: "position-hierarchy",
        caption: "Штатное расписание",
        menuLink: "/position-hierarchy",
        pathPattern: "/position-hierarchy",
        component: null
      },
      // {
      //   id: "my-rating",
      //   caption: "Моя оценка",
      //   menuLink: "/my-rating",
      //   pathPattern: "/my-rating",
      //   component: null,
      // },
      // {
      //   id: "schedule-offsets",
      //   caption: "График сменности",
      //   menuLink: "/schedule-offsets",
      //   pathPattern: "/schedule-offsets",
      //   component: ScheduleOffsetsRequestManagement,
      // },
      // {
      //   id: "team-rating",
      //   caption: "Оценка команды",
      //   menuLink: "/team-rating",
      //   pathPattern: "/team-rating",
      //   component: null,
      // },
      // {
      //   id: "my-app-vacation",
      //   caption: "Мои заявки на вакансию",
      //   menuLink: "/my-app-vacation",
      //   pathPattern: "/my-app-vacation",
      //   component: null,
      // },
      // {
      //   id: "assigment-interview",
      //   caption: "Назначенные интервью",
      //   menuLink: "/assigment-interview",
      //   pathPattern: "/assigment-interview",
      //   component: null,
      // },
      // {
      //   id: "job-offers",
      //   caption: "Job Offers",
      //   menuLink: "/job-offers",
      //   pathPattern: "/job-offers",
      //   component: null,
      // },
      {
        id: "orgStructureRequest",
        caption: "Заявка по Орг.структуре",
        menuLink: "/orgStructureRequest",
        pathPattern: "/orgStructureRequest",
        component: null,
      },
      {
        id: "incentive",
        caption: "Incentive",
        menuLink: "/incentive",
        pathPattern: "/incentive",
        component: null,
      },
      // {
      //   id: "learning-budget-approvment",
      //   caption: "learning-budget-approvment",
      //   menuLink: "/learning-budget-approvment",
      //   pathPattern: "/learning-budget-approvment",
      //   component: null,
      // },
      {
        id: "talentPool",
        caption: "Talent pool",
        menuLink: "/talentPoolProgram",
        pathPattern: "/talentPoolProgram",
        component: null,
      },
      {
        id: "vacancyRequest",
        caption: "vacancyRequest",
        menuLink: "/vacancyRequest",
        pathPattern: "/vacancyRequest",
        component: null,
      },
      {
        id: "vacancyRequestAssistant",
        caption: "vacancyRequestAssistant",
        menuLink: "/vacancyRequestAssistant",
        pathPattern: "/vacancyRequestAssistant",
        component: null,
      },
      // {
      //   id: "learning-budget-approvment",
      //   caption: "learning-budget-approvment",
      //   menuLink: "/learning-budget-approvment",
      //   pathPattern: "/learning-budget-approvment",
      //   component: null,
      // },
      {
        id: "documentsFamiliarization",
        caption: "DocumentsFamiliarization",
        menuLink: "/documents-familiarization",
        pathPattern: "/documents-familiarization",
        component: null,
      },
      {
        id: "dictionaryEmployees",
        caption: "DictionaryEmployees",
        menuLink: "/dictionaries/employees",
        pathPattern: "/dictionaries/employees",
        component: null,
      },
      {
        id: "annual-salary-revision",
        caption: "Annual salary revision",
        menuLink: "/annualSalaryRevisionRequest",
        pathPattern: "/annualSalaryRevisionRequest",
        component: null,
      },
      {
        id: "organization.vacancies",
        caption: "Organization Vacancies",
        menuLink: "/OrganizationVacancyList",
        pathPattern: "/OrganizationVacancyList",
        component: null,
      },
      {
        id: "questionnaire",
        caption: "Questionnaire",
        menuLink: "/employeeQuestionnaire",
        pathPattern: "/employeeQuestionnaire",
        component: null,
      },
      {
        id: "menuCba", caption: "Menu CBA",
        menuLink: "/menuCba",
        pathPattern: "/menuCba",
        component: null
      },
      {
        id: "store",
        caption: "Store",
        menuLink: "/recognition/store/items",
        pathPattern: "/recognition/store/items",
        component: null
      },
    ]
  }
}