import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Button, Card, Col, Icon, Row, Tag, Tooltip} from "antd";
import {action, computed, observable, runInAction} from "mobx";
import {inject, observer} from "mobx-react";
import {restServices} from "../../../../cuba/services";
import {injectMainStore} from "@cuba-platform/react";
import {RootStoreProp} from "../../../store";
import {RecognitionPojo} from "../../../../cuba/entities/base/tsadv$RecognitionPojo";
import {getFileUrl} from "../../../util/util";
import "../style.less";
import PersonLink from "./PersonLink";
import CommentComponent from "./CommentComponent";
import ListLikedPopOver from "./ListLikedPopOver";


type RecognitionComponentProps = {
  recognition: RecognitionPojo
}

@inject("rootStore")
@injectMainStore
@observer
class RecognitionComponent extends React.Component<RootStoreProp & WrappedComponentProps & RecognitionComponentProps> {
  @observable sendingLike = false;
  @observable isShowFullText = false;

  renderRecognitionText = () => {
    const needCollapse = !!(
      !this.isShowFullText
      && this.props.recognition.text
      && this.props.recognition.text.length > 200
    );

    return needCollapse
      ? <>
        <span style={{paddingRight: "5px"}}>{this.props.recognition.text!.substring(0, 200) + "..."}</span>
        <a onClick={() => runInAction(() => this.isShowFullText = true)}>
          <FormattedMessage id={"recognition.recognitionComponent.showFully"}/>
        </a>
      </>
      : <span>{this.props.recognition.text!}</span>
}

  render() {
    const recognition: RecognitionPojo = this.props.recognition;
    return <Card style={{marginTop: '20px', marginBottom: '20px'}}>
      <div style={{padding: '10px'}}>
        <Row type={"flex"} style={{alignItems: "center"}}>
          <Col xs={3}/>
          <Col xs={21}>
            <Row type={"flex"} justify={"space-between"}>
              <Col>
                <PersonLink person={recognition.sender!}/> {recognition.say} <PersonLink person={recognition.receiver!}/>
              </Col>
              <Col>
                {recognition.createDate}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <img width={"90%"}
                 src={(recognition.type && recognition.type!.image && getFileUrl(recognition.type.image)) || undefined}/>
            <p style={{textAlign: "center", width: "90%", fontSize: "12px"}}>{recognition.type && recognition.type!.name}</p>
          </Col>
          <Col xs={21}>
            <div style={{wordBreak: 'break-all'}}>{this.renderRecognitionText()}</div>
            {this.renderQualities()}
          </Col>
        </Row>
        <Row justify={"end"} type={"flex"}>
          <ListLikedPopOver likeCount={recognition.likeCount} recognitionId={recognition.id}/>

          <Button className={this.hasMyLike ? "button-send-like" : "button-non-send-like"}
                  loading={this.sendingLike}
                  onClick={this.sendLike}
          >
            <FormattedMessage id={"recognition.recognitionComponent.like"}/>
            <Icon type="like"/>
          </Button>

        </Row>
        <CommentComponent recognitionId={recognition.id}/>
      </div>
    </Card>
  }

  renderQualities = () => {
    return this.props.recognition.qualities!.length !== 0
      && <div style={{margin: '10px 0'}}>
        {this.props.recognition.qualities!.map(quality =>
          <Tooltip placement="top" title={quality.description}>
            <Tag color={"blue"} style={{marginBottom: "5px"}}>{quality.name}</Tag>
          </Tooltip>)
        }
      </div>;
  }

  @computed get hasMyLike(): boolean {
    return this.props.recognition!.currentLike !== 0;
  }

  @action sendLike = async () => {
    this.sendingLike = true;

    await restServices.recognitionService.sendLike(this.props.recognition!.id!)
      .finally(() => this.sendingLike = false);

    if (!this.hasMyLike) {
      this.props.recognition!.currentLike = 1;
      this.props.recognition!.likeCount += 1;
    } else {
      this.props.recognition!.currentLike = 0;
      this.props.recognition!.likeCount -= 1;
    }
  }
}

export default injectIntl(RecognitionComponent);