import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {observer} from "mobx-react";
import {action, observable} from "mobx";
import {NomineePojo} from "../../../../cuba/entities/base/tsadv$NomineePojo";
import {restServices} from "../../../../cuba/services";
import {Row, Select, Spin, Table} from "antd";
import {PersonPojo} from "../../../../cuba/entities/base/tsadv$PersonPojo";
import PersonLink from "./PersonLink";
import InfiniteScroll from "react-infinite-scroller";

@observer
class AllNominees extends React.Component<WrappedComponentProps> {

  @observable nominees: NomineePojo[] = [];
  @observable years?: number[];
  @observable selectedYear: number;

  lastCount = 0;
  wasError = false;
  page = 0;

  async componentDidMount() {
    this.years = await restServices.recognitionService.loadNomineeYearsNominations(1);
    this.selectedYear = this.years[0];
    await this.loadNominees();
  }

  @action loadNominees = async () => {
    try {
      this.page++;

      const resp = await restServices.recognitionService.loadAllNomineeWithDefault(this.page, this.lastCount, this.selectedYear, "-1", this.selectedYear, true);

      this.lastCount = resp.pageInfo!.totalRowsCount!;

      this.nominees = [...this.nominees!, ...resp.nominees]
    } catch (e) {
      this.wasError = true;
      throw e;
    }
  }

  onChangeYear = (year: number) => {
    this.selectedYear = year;
    this.loadNominees();
  }

  fullNameRender = (value: any, record: NomineePojo) => {
    const person: PersonPojo = {
      personGroupId: record.pgId,
      name: record.fullName
    }

    return <PersonLink person={person}/>
  }

  render() {
    const messages = this.props.intl.messages;

    const spin = <Row type={"flex"} justify={"center"} style={{height: "40px"}}>
      <Spin/>
    </Row>

    return <>
      <Row type={"flex"} justify={"space-between"} style={{padding: "0 20px", alignItems: "center"}}>
        <h2 style={{margin: 0}}>
          <FormattedMessage id={"recognition.recognitionProgram.winners.allNominee"}/>
        </h2>
        <Select style={{width: "300px"}} value={this.selectedYear} onChange={this.onChangeYear}>
          {this.years && this.years.map(year => <Select.Option value={year}>{year}</Select.Option>)}
        </Select>
      </Row>

      <InfiniteScroll loadMore={this.loadNominees} hasMore={!this.wasError && this.lastCount > this.nominees.length} loader={spin}>
        <Table dataSource={this.nominees}
               pagination={false}>
          <Table.Column title={messages["recognition.myNominee.fullName"]} render={this.fullNameRender}/>
          <Table.Column title={messages["recognition.myNominee.position"]} dataIndex={"position"}/>
          <Table.Column title={messages["recognition.myNominee.organization"]} dataIndex={"organization"}/>
          <Table.Column title={messages["recognition.myNominee.year"]} dataIndex={"year"}/>
        </Table>
      </InfiniteScroll>
    </>
  }
}

export default injectIntl(AllNominees);