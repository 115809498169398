import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import SuccessionPlanningRequestEdit from "./SuccessionPlanningRequestEdit";
import SuccessionPlanningRequestList from "./SuccessionPlanningRequestList";

type Props = RouteComponentProps<{ entityId: string, addressId?: string }>;
interface defaultProps{
  isKeyPosition?:boolean
}

@observer
export class SuccessionPlanningRequestManagement extends React.Component<Props,defaultProps> {
  static PATH = "/successionPlanningRequest";
  static NEW_SUBPATH = "new";

  render() {
    const state:any = this.props.location.state
    const isKeyPosition = state && state.isKeyPosition;
    const positionGroupId = state && state.positionGroupId;
    const {entityId,addressId} = this.props.match.params;
    return (
      <>
        {entityId ?
          <SuccessionPlanningRequestEdit addressId={addressId} entityId={entityId} isKeyPosition={isKeyPosition}
                                         positionGroupId={positionGroupId}/> :
          <SuccessionPlanningRequestList positionGroupId={positionGroupId} isKeyPosition={isKeyPosition}/>
        }
      </>
    );
  }
}