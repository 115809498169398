import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicRelationshipType } from "./tsadv$DicRelationshipType";
import { Beneficiary } from "./tsadv$Beneficiary";
import { RelationshipTypeBeneficiary } from "./tsadv$RelationshipTypeBeneficiary";
import { DicAddressType } from "./tsadv$DicAddressType";
import { DicCountry } from "./base$DicCountry";
import { DicKato } from "./tsadv_DicKato";
import { DicStreetType } from "./tsadv_DicStreetType";
import { FileDescriptor } from "./sys$FileDescriptor";
export class BeneficiaryRequest extends AbstractBprocRequest {
  static NAME = "tsadv_BeneficiaryRequest";
  personGroupParent?: PersonGroupExt | null;
  personGroupChild?: PersonGroupExt | null;
  dateFrom?: any | null;
  dateTo?: any | null;
  getAlimony?: boolean | null;
  relationshipType?: DicRelationshipType | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  birthDate?: any | null;
  workLocation?: string | null;
  homeAddress?: string | null;
  additionalContact?: string | null;
  personGroup?: PersonGroupExt | null;
  beneficiary?: Beneficiary | null;
  relatedPersonGroup?: PersonGroupExt | null;
  relationDegree?: RelationshipTypeBeneficiary | null;
  addressType?: DicAddressType | null;
  postal?: string | null;
  country?: DicCountry | null;
  addressKatoCode?: DicKato | null;
  streetType?: DicStreetType | null;
  streetName?: string | null;
  building?: string | null;
  block?: string | null;
  flat?: string | null;
  addressForExpats?: string | null;
  lastNameLatin?: string | null;
  firstNameLatin?: string | null;
  files?: FileDescriptor[] | null;
}
export type BeneficiaryRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "beneficiaryRequest-for-integration";
export type BeneficiaryRequestView<
  V extends BeneficiaryRequestViewName
> = V extends "_base"
  ? Pick<
      BeneficiaryRequest,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "dateFrom"
      | "dateTo"
      | "getAlimony"
      | "lastName"
      | "firstName"
      | "middleName"
      | "birthDate"
      | "workLocation"
      | "homeAddress"
      | "additionalContact"
      | "postal"
      | "streetName"
      | "building"
      | "block"
      | "flat"
      | "addressForExpats"
      | "lastNameLatin"
      | "firstNameLatin"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      BeneficiaryRequest,
      | "id"
      | "dateFrom"
      | "dateTo"
      | "getAlimony"
      | "lastName"
      | "firstName"
      | "middleName"
      | "birthDate"
      | "workLocation"
      | "homeAddress"
      | "additionalContact"
      | "postal"
      | "streetName"
      | "building"
      | "block"
      | "flat"
      | "addressForExpats"
      | "lastNameLatin"
      | "firstNameLatin"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<BeneficiaryRequest, "id" | "requestNumber" | "requestDate">
  : V extends "beneficiaryRequest-for-integration"
  ? Pick<
      BeneficiaryRequest,
      | "id"
      | "dateFrom"
      | "dateTo"
      | "getAlimony"
      | "lastName"
      | "firstName"
      | "middleName"
      | "birthDate"
      | "workLocation"
      | "homeAddress"
      | "additionalContact"
      | "postal"
      | "streetName"
      | "building"
      | "block"
      | "flat"
      | "addressForExpats"
      | "lastNameLatin"
      | "firstNameLatin"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "personGroupParent"
      | "personGroupChild"
      | "relationshipType"
      | "personGroup"
      | "beneficiary"
      | "relatedPersonGroup"
      | "relationDegree"
      | "addressType"
      | "country"
      | "addressKatoCode"
      | "streetType"
      | "files"
      | "status"
    >
  : never;