
import * as React from "react";
import { inject, observer } from "mobx-react";
import {
  collection,
  getCubaREST,
  getEnumCaption,
  getPropertyInfoNN,
  handleTableChange,
  injectMainStore,
  MainStoreInjected,
  withLocalizedForm
} from "@cuba-platform/react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Modal, Tabs } from "antd";
import { RootStoreProp } from "../../store";
import { Link } from "react-router-dom";
import Page from "../../hoc/PageContentHoc";
import { action, IReactionDisposer, observable, reaction, toJS } from "mobx";
import Button from "../../components/Button/Button";
import { FormComponentProps } from "antd/lib/form";
import DataTableFormat from "../../components/DataTable/intex";
import { SerializedEntity } from "@cuba-platform/rest";
import { LearningBudget } from "../../../cuba/entities/base/tsadv_LearningBudget";
import { LearningBudgetRequest } from "../../../cuba/entities/base/tsadv_LearningBudgetRequest";
import moment from "moment";
import { HierarchyData } from "./BudgetHierarchyComponent";
import { restServices } from "../../../cuba/services";
import LearningBudgetRequestManagement from "./LearningBudgetRequestManagement";
import { PersonProfile } from "../MyTeam/MyTeamCard";
import Notification from "../../util/Notification/Notification";
import { OrganizationGroupExt } from "../../../cuba/entities/base/base$OrganizationGroupExt";
import { BudgetDelegation } from "../../../cuba/entities/base/tsadv_BudgetDelegation";
import { SorterResult } from "antd/lib/table";
import TextArea from "antd/lib/input/TextArea";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import { formatDate, JSON_DATE_TIME_FORMAT } from "../../util/Date/Date";
import { PaginationConfig } from "antd/es/pagination";
import { BudgetHeaderStatus } from "../../../cuba/enums/enums";
import { LearningBudgetRequestHeader } from "../../../cuba/entities/base/tsadv_LearningBudgetRequestHeader";
import { BudgetHistoryApprovement } from "../../../cuba/entities/base/tsadv_BudgetHistoryApprovement";
import DelegationTable from "./DelegationTable";
import BudgetInfoComponent from "./BudgetInfoComponent";
import { wrapToSerialized } from "./utils";
import LoadingPage from "../LoadingPage";
import * as XLSX from 'xlsx';
import Input from "../../components/Input/Input";

const { TabPane } = Tabs;


export type Menu = {
  id: string,
  caption?: string,
};


export type BudgetStructureProps = {
  selectedBudget: SerializedEntity<LearningBudget>;
  selectedData?: HierarchyData;
  currentPerson: string;
  selectedOrganization: SerializedEntity<OrganizationGroupExt>;
  isApprover: boolean;
  learningBudgetRequestHeader: LearningBudgetRequestHeader;
  onChangeSelectedInfo?: (selectedData?: HierarchyData, selectedBudget?: SerializedEntity<LearningBudget>, selectedTab?: string, selectedLeftMenu?: string) => void;
  onApprovingBudgetControlClick: (status: BudgetHeaderStatus) => void;
  onApproveBudgetForecastClick: () => void;
} & FormComponentProps & MainStoreInjected & WrappedComponentProps & RootStoreProp;


@injectMainStore
@inject("rootStore")
@observer
class LearningBudgetCard extends React.Component<BudgetStructureProps> {

  @observable person?: PersonProfile;
  @observable selectedBudgetRequest?: LearningBudgetRequest;
  @observable isEmployeeModalVisible: boolean;
  @observable isDelegationHelpVisible: boolean = false;
  @observable editLink: string;
  @observable assignmentsList: SerializedEntity<AssignmentExt>[];
  @observable isCommentModalVisible: boolean;
  @observable isInitiator: boolean = false;
  @observable totalSum: number = 0;
  @observable courseCount: number = 0;


  comment: string;

  reactionDisposers: IReactionDisposer[] = [];

  dataCollectionBudgetRequests = collection<LearningBudgetRequest>(LearningBudgetRequest.NAME, {
    view: "learningBudgetRequest-front-view",
    sort: "-updateTs",
    loadImmediately: false
  }
  );

  approvementHistoryDc = collection<BudgetHistoryApprovement>(BudgetHistoryApprovement.NAME, {
    view: "budgetHistoryApprovement-front-view",
    sort: "-createTs",
    loadImmediately: false
  }
  );

  budgetDelegationDc = collection<BudgetDelegation>(BudgetDelegation.NAME, {
    view: "budget-delegation-view",
    sort: "-updateTs",
    loadImmediately: false
  }
  );

  @action
  handleChange = (pagination: PaginationConfig, tableFilters: Record<string, string[]>, sorter: SorterResult<any>): void => {
    this.props.rootStore!.learningBudgetInfo.setBudgetRequestConfig(pagination);
    handleTableChange({
      pagination: pagination,
      filters: tableFilters,
      sorter: sorter,
      defaultSort: '-updateTs',
      fields: this.budgetRequestsFields,
      mainStore: this.props.mainStore!,
      dataCollection: this.dataCollectionBudgetRequests
    });
  };


  budgetRequestsFields = [
    "employee",

    "employeeCount",

    "courseName",

    "learningType",

    "typeOfTraining",

    "region",

    "month",

    "city",

    "costByPerson",

    "costSum"
  ];

  approvementHistoryFields = [
    "approver",

    "role",

    "action",

    "comment",

    "date"

  ];

  budgetRequestsRender = [
    {

      column: 'employee',
      render: (text: any, record: LearningBudgetRequest) => {
        return <Link
          to={
            LearningBudgetRequestManagement.PATH + "/single/" + record.id + "/" + this.props.selectedBudget.id + "/1"
          }
          key="edit">
          <label style={{ cursor: 'pointer' }}>{text || ""}</label>
        </Link>
      }

    },
    {
      column: 'costSum',
      render: (text: any, record: LearningBudgetRequest) => {
        return <Link
          to={
            LearningBudgetRequestManagement.PATH + "/single/" + record.id + "/" + this.props.selectedBudget.id + "/1"
          }
          key="edit">
          <label style={{ cursor: 'pointer' }}>{record.costSum / 1000}</label>
        </Link>
      }

    },
    {
      column: 'costByPerson',
      render: (text: any, record: LearningBudgetRequest) =>
        <Link
          to={
            LearningBudgetRequestManagement.PATH + "/single/" + record.id + "/" + this.props.selectedBudget.id + "/1"
          }
          key="edit">
          <label style={{ cursor: 'pointer' }}>{record.costByPerson / 1000}</label>
        </Link>
    },
    {
      column: 'month',
      render: (text: any, record: LearningBudgetRequest) =>
        <label>{formatDate(new Date(record.month), 'MM')}</label>
    }
  ]


  @observable selectedRowKey: string | undefined;

  @observable
  pageName = "myLearningBudget";

  isCreateLeavingVacationRequestDisabled = false;

  constructor(props: BudgetStructureProps) {
    super(props);
    reaction(r => this.budgetDelegationDc.status, (arg, r) => {
      if (arg === 'DONE') {
        r.dispose()
      }
    })
  }

  deleteBudgetRequest = () => {
    this.dataCollectionBudgetRequests.delete(this.selectedBudgetRequest!);
  }

  exportExcel = () => {
    const messages = this.props.mainStore!.messages!;
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("Export");
    var ws_data = [[messages[LearningBudgetRequest.NAME + '.' + 'employee'], messages[LearningBudgetRequest.NAME + '.' + 'employeeCount'],
    messages[LearningBudgetRequest.NAME + '.' + 'courseName'], messages[LearningBudgetRequest.NAME + '.' + 'learningType'],
    messages[LearningBudgetRequest.NAME + '.' + 'typeOfTraining'], messages[LearningBudgetRequest.NAME + '.' + 'region'],
    messages[LearningBudgetRequest.NAME + '.' + 'month'], messages[LearningBudgetRequest.NAME + '.' + 'city'],
    messages[LearningBudgetRequest.NAME + '.' + 'costByPerson'], messages[LearningBudgetRequest.NAME + '.' + 'costSum']]];

    this.dataCollectionBudgetRequests.items.forEach(budgetRequest => {
      const costByPerson: any = ((budgetRequest.costByPerson as number) / 1000)
      const costSum: any = ((budgetRequest.costSum as number) / 1000)
      const employee = budgetRequest.employee ? wrapToSerialized(budgetRequest.employee)._instanceName : ""
      const learningType = budgetRequest.learningType ? wrapToSerialized(budgetRequest.learningType)._instanceName : ""
      const typeOfTraining = budgetRequest.typeOfTraining ? wrapToSerialized(budgetRequest.typeOfTraining)._instanceName : ""
      const region = budgetRequest.region ? wrapToSerialized(budgetRequest.region)._instanceName : ""
      let row = [employee, budgetRequest.employeeCount, budgetRequest.courseName, learningType,
        typeOfTraining, region, moment(budgetRequest.month).format('MM-yyyy'), budgetRequest.city, costByPerson, costSum];
      ws_data.push(row)
    })

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Export"] = ws;
    var wbout = XLSX.writeFile(wb, "Budget_requests.xlsx");
  }

  onRowSelect = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
    this.selectedBudgetRequest = this.dataCollectionBudgetRequests.items.find(item => item.id === this.selectedRowKey);
    this.editLink = LearningBudgetRequestManagement.PATH + "/single/" + this.selectedBudgetRequest!.id + "/" + this.props.selectedBudget.id
  }

  showPersonModal = () => {
    this.isEmployeeModalVisible = true;
  };

  showCommentModal = () => {
    this.isCommentModalVisible = true;
  }

  closeCommentModal = () => {
    this.isCommentModalVisible = false;
  }

  sendForRevision = (comment?: string) => {
    if (!comment || comment.length === 0) {
      Notification.error({
        message: this.props.intl.formatMessage({ id: "commentRequredError" })
      });
    } else {
      this.isCommentModalVisible = false;
      this.createApproveHistoryItem(BudgetHeaderStatus.SEND_FOR_REVISION, comment);
      this.props.onApprovingBudgetControlClick(BudgetHeaderStatus.SEND_FOR_REVISION);
      this.props.rootStore!.learningBudgetInfo.isForecast ? restServices.learningBudgetService.sendNotifyAboutSendForRevisionForecast({
        userId: this.props.learningBudgetRequestHeader.organizationManager!.id,
        responsibleUserId: this.props.currentPerson,
        url: window.location.href
      }) :
        restServices.learningBudgetService.sendNotifyAboutSendForRevision({
          userId: this.props.learningBudgetRequestHeader.organizationManager!.id,
          responsibleUserId: this.props.currentPerson,
          url: window.location.href
        });
    }

  };

  approveBudget = () => {
    this.createApproveHistoryItem(BudgetHeaderStatus.APPROVED);
    this.props.onApproveBudgetForecastClick();
    this.props.onApprovingBudgetControlClick(BudgetHeaderStatus.APPROVED);
  };

  sendToApprove = () => {
    this.createApproveHistoryItem(BudgetHeaderStatus.ON_APPROVAL);
    this.props.onApprovingBudgetControlClick(BudgetHeaderStatus.ON_APPROVAL);
    if (this.props.selectedData!.parentOrganization) {
      restServices.organizationHrUserService.getManagerByOrganizationGroupId({ organizationGroupId: this.props.selectedData!.parentOrganization.id, roleCode: 'BUDGET_T&D' })
        .then(personGroup => {
          this.props.rootStore!.learningBudgetInfo.isForecast ? restServices.learningBudgetService.sendNotifyAboutApproveBudgetForecast({
            userId: personGroup.id,
            responsibleUserId: this.props.currentPerson,
            learningBudgetHeaderId: this.props.learningBudgetRequestHeader.id,
            url: window.location.href
          }) :
            restServices.learningBudgetService.sendNotifyAboutApproveBudget({
              userId: personGroup.id,
              responsibleUserId: this.props.currentPerson,
              learningBudgetHeaderId: this.props.learningBudgetRequestHeader.id,
              url: window.location.href
            });
        })
    }

  };

  createApproveHistoryItem = (status: BudgetHeaderStatus, comment?: string) => {

    getCubaREST()!.searchEntities<AssignmentExt>(AssignmentExt.NAME, {
      conditions: [{
        property: 'personGroup',
        operator: '=',
        value: this.props.currentPerson
      }, {
        property: 'startDate',
        operator: '<=',
        value: moment().format(JSON_DATE_TIME_FORMAT)
      }, {
        property: 'endDate',
        operator: '>=',
        value: moment().format(JSON_DATE_TIME_FORMAT)
      }]
    }, {
      limit: 1,
      view: 'assignment-view-for-front'
    }).then(assignments => {
      const approveHistoryItem = new BudgetHistoryApprovement();
      approveHistoryItem.learningBudgetHeader = this.props.learningBudgetRequestHeader;
      approveHistoryItem.approver = { id: this.props.currentPerson };
      if (assignments && assignments.length > 0 && assignments[0].positionGroup) {
        approveHistoryItem.role = wrapToSerialized(assignments[0].positionGroup)._instanceName;
      }
      approveHistoryItem.action = getEnumCaption(status, getPropertyInfoNN("status", LearningBudgetRequestHeader.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!);
      approveHistoryItem.date = moment().format(JSON_DATE_TIME_FORMAT);
      approveHistoryItem.comment = comment;
      getCubaREST()!.commitEntity(BudgetHistoryApprovement.NAME, toJS(approveHistoryItem))
        .then(() => {
          this.updateApproveHistory();
        })
        .catch(reason => {
          Notification.error({
            message: this.props.intl.formatMessage({ id: "management.editor.error" })
          });
        });
    }).catch(reason => {
      Notification.error({
        message: this.props.intl.formatMessage({ id: "management.editor.error" })
      });
    })

  }

  updateApproveHistory = () => {
    this.approvementHistoryDc.filter = {
      conditions: [{
        property: "learningBudgetHeader.id",
        operator: "=",
        value: this.props.learningBudgetRequestHeader.id
      }]
    };
    this.approvementHistoryDc.load();
  }

  render() {
    if (this.dataCollectionBudgetRequests.status === 'LOADING') return <LoadingPage />;

    const infoView = !this.isDelegationHelpVisible ?
      <BudgetInfoComponent form={this.props.form} budget={this.props.selectedBudget} hierarchy={this.props.selectedData}
        requestHeader={this.props.learningBudgetRequestHeader} /> :
      <fieldset style={{
        border: "1px solid #d8d8d8",
        padding: "5px",
        borderRadius: "4px",
        width: "60%",
        height: "400px",
      }}>
        <legend style={{
          width: "auto",
          paddingRight: "10px"
        }}>{this.props.intl.formatMessage({ id: "help" })}</legend>
        <div style={{ width: "100%", height: "100%" }}>
          <TextArea autoSize={true} rows={5} value={this.props.intl.formatMessage({ id: "delegationHelp" })} />
        </div>

      </fieldset>

    const isForecast = this.props.rootStore!.learningBudgetInfo.isForecast;

    const labelForecast = isForecast ?
      <label className="forecast-label">{this.props.intl.formatMessage({ id: "forecastActions" })}</label> : "";
    const newLink = LearningBudgetRequestManagement.PATH + "/single/" + LearningBudgetRequestManagement.NEW_SUBPATH + "/" + this.props.selectedBudget.id;
    const isForecastNotApproved = this.props.learningBudgetRequestHeader.status === BudgetHeaderStatus.APPROVED && isForecast
      && this.props.learningBudgetRequestHeader.isForecastEditable;
    const approveBtns = this.props.isApprover && this.props.learningBudgetRequestHeader.status === BudgetHeaderStatus.ON_APPROVAL ? [
      <Button
        className={"send-for-revision-btn"}
        onClick={() => this.showCommentModal()}
        key="modal">
        {this.props.intl.formatMessage({ id: "sendForRevision" })}
      </Button>,
      <Button
        style={{ marginLeft: "10px" }}
        type='primary'
        onClick={() => this.approveBudget()}
        key="modal">
        {this.props.intl.formatMessage({ id: "approveBudget" })}
      </Button>
    ] : (this.props.learningBudgetRequestHeader.status === BudgetHeaderStatus.DRAFT || this.props.learningBudgetRequestHeader.status === BudgetHeaderStatus.SEND_FOR_REVISION
      || isForecastNotApproved) ? [
      <Button
        style={{ marginLeft: "10px" }}
        type='primary'
        onClick={() => this.sendToApprove()}
        key="modal">
        {isForecast ? this.props.intl.formatMessage({ id: "sendToApprovementForecast" }) : this.props.intl.formatMessage({ id: "sendToApprovement" })}
      </Button>
    ] : []

    const btns = this.props.learningBudgetRequestHeader.status === BudgetHeaderStatus.DRAFT ||
      this.props.learningBudgetRequestHeader.status === BudgetHeaderStatus.SEND_FOR_REVISION || isForecastNotApproved ? [
      moment().isBefore(this.props.selectedBudget.budgetEndDate) ? <Link
        to={
          newLink
        }
        key="create">
        <Button
          type="default"
          disabled={this.selectedRowKey === 'undefined' || !this.props.selectedData!.personGroup}
          key={"createLearningBudgetRequest"}
          style={{ margin: "0 12px 12px 0" }}>
          <img alt={this.props.intl.formatMessage({ id: "management.browser.edit" })}
            width={25}
            src={require("../../../resources/icons/user/user-add.svg")} />
        </Button>
      </Link> : <Button
        type="default"
        disabled={true}
        key={"createLearningBudgetRequest"}
        style={{ margin: "0 12px 12px 0" }}>
        <img alt={this.props.intl.formatMessage({ id: "management.browser.edit" })}
          width={25}
          src={require("../../../resources/icons/user/user-add.svg")} />
      </Button>,

      moment().isBefore(this.props.selectedBudget.budgetEndDate) ? <Link
        to={
          LearningBudgetRequestManagement.PATH + "/group/" + LearningBudgetRequestManagement.NEW_SUBPATH + "/" + this.props.selectedBudget.id
        }
        key="createGroup">
        <Button
          type="default"
          disabled={this.selectedRowKey === 'undefined' || !this.props.selectedData!.personGroup || this.props.selectedBudget.budgetEndDate < moment()}
          key={"createGroupLearningBudgetRequest"}
          style={{ margin: "0 12px 12px 0" }}>
          <img alt={this.props.intl.formatMessage({ id: "management.browser.edit" })}
            width={25}
            src={require("../../../resources/icons/user/user-group-add.svg")} />
        </Button>
      </Link> : <Button
        type="default"
        disabled={true}
        key={"createGroupLearningBudgetRequest"}
        style={{ margin: "0 12px 12px 0" }}>
        <img alt={this.props.intl.formatMessage({ id: "management.browser.edit" })}
          width={25}
          src={require("../../../resources/icons/user/user-group-add.svg")} />
      </Button>,

      this.selectedRowKey ? <Link
        to={
          this.editLink
        }
        key="edit">
        <Button
          icon="edit"
          type="default"
          className="edit-budget-request-btn"
          disabled={this.selectedRowKey === 'undefined' || !this.props.selectedData!.personGroup}
          key={"createLearningBudgetRequest"}
          style={{ margin: "0 12px 12px 0" }}>

        </Button>
      </Link> :
        <Button
          icon="edit"
          type="default"
          className="edit-budget-request-btn"
          disabled={true}
          key={"createLearningBudgetRequest"}
          style={{ margin: "0 12px 12px 0" }}></Button>,

      <Button
        icon="delete"
        type="danger"
        className={(this.selectedBudgetRequest === 'undefined' || this.selectedBudgetRequest == null) ? "delete-budget-request-disabled" : "delete-budget-request"}
        key={"deleteLearningBudgetRequest"}
        disabled={this.selectedBudgetRequest === 'undefined' || this.selectedBudgetRequest == null || !this.props.selectedData!.personGroup}
        onClick={() => {
          this.deleteBudgetRequest();
        }}
        style={{ margin: "0 12px 12px 0" }}>
      </Button>,
      <Button
        icon="file-excel"
        className="export-budget-requests-btn"
        key={"exportLearningBudgetRequests"}
        onClick={() => {
          this.exportExcel();
        }}
        style={{ margin: "0 12px 12px 0" }}>
      </Button>
    ] : [
      <Button
        icon="file-excel"
        className="export-budget-requests-btn"
        key={"exportLearningBudgetRequests"}
        onClick={() => {
          this.exportExcel();
        }}
        style={{ margin: "0 12px 12px 0" }}>
      </Button>
    ];

    const defaultActiveKey = "1";

    return (
      <Page>
        <div style={{ width: '98%', margin: '1%' }}>
          {infoView}
          <Tabs style={{ width: "100%" }} defaultActiveKey={defaultActiveKey}
            onChange={activeKey => {
              this.pageName = "myLearningBudget" + (activeKey === "1" ? "" : "Request");
              if (activeKey === "3") {
                this.isDelegationHelpVisible = true;
                if (this.props.rootStore!.learningBudgetInfo.allAssignments) {
                  this.assignmentsList = this.props.rootStore!.learningBudgetInfo.allAssignments!;
                } else if (!this.assignmentsList) {

                }

              } else {
                this.isDelegationHelpVisible = false;
              }
            }}>
            <TabPane style={{ width: "100%" }} tab={this.props.intl.formatMessage({ id: "budgetRows" })} key="1">
              <div style={{ width: "100%" }}>
                <div style={{width: "100%"}}>
                  <div style={{float:'right'}}>
                    <div style={{display:'inline-block'}}>
                      <label>{'Total'}</label>
                      <Input disabled={true} value={this.totalSum} style={{width:"150px", display:'inline-block', marginLeft:'5px'}}/></div>
                    <div style={{display:'inline-block', marginLeft:'10px'}}>
                      <label>{'Кол-во курсов'}</label>
                      <Input disabled={true} value={this.courseCount} style={{width:"150px", display:'inline-block', marginLeft:'5px'}}/>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  {labelForecast}
                  {btns}
                </div>
                <DataTableFormat
                  dataCollection={this.dataCollectionBudgetRequests}
                  onRowSelectionChange={this.onRowSelect}
                  fields={this.budgetRequestsFields}
                  render={this.budgetRequestsRender}
                  hideSelectionColumn={false}
                  tableProps={{
                    useFixedHeader:true,
                    bodyStyle: {overflowY:'scroll', height:'320px'},
                    rowKey: "id",
                    onChange: this.handleChange,
                    pagination: this.props.rootStore!.learningBudgetInfo.budgetRequestPagingConfig,
                  }} />
                <div style={{ float: "right", marginBottom: "20px" }}>
                  {approveBtns}
                </div>
                <Modal
                  title={this.props.intl.formatMessage({ id: "comment" })}
                  width={"60%"}
                  footer={null}
                  closable={false}
                  visible={this.isCommentModalVisible}>
                  <div style={{ height: "18rem" }}>
                    <div style={{ width: "100%" }}>
                      <TextArea rows={15}
                        onChange={(value => this.comment = value.target.value)}
                        style={{ resize: 'none' }}
                        defaultValue={this.comment} />
                      <div style={{ width: "100%", height: "35px", marginTop: "5px", float: "right" }}>
                        <div style={{ float: 'right' }}>
                          <Button
                            type="primary"
                            style={{ display: "inline-block", marginRight: "5px" }}
                            onClick={() => {
                              this.sendForRevision(this.comment);
                            }}>{this.props.intl!.formatMessage({ id: "cubaReact.dataTable.ok" })}
                          </Button>
                          <Button
                            className="edit-budget-request-btn"
                            style={{ display: "inline-block" }}
                            onClick={() => {
                              this.closeCommentModal();
                            }}>{this.props.intl!.formatMessage({ id: "management.browser.exclude.cancel" })}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </TabPane>
            <TabPane tab={this.props.intl.formatMessage({ id: "approvementHistory" })} key="2">
              <div style={{ width: "100%" }}>
                <DataTableFormat
                  dataCollection={this.approvementHistoryDc}
                  fields={this.approvementHistoryFields}
                  hideSelectionColumn={true}
                  tableProps={{
                    rowKey: "id",
                    pagination: this.props.rootStore!.learningBudgetInfo.budgetRequestPagingConfig
                  }} />
              </div>
            </TabPane>

            <TabPane tab={this.props.intl.formatMessage({ id: "myDelegation" })} key="3">
              <DelegationTable items={this.budgetDelegationDc}
                currentPerson={this.props.currentPerson}
                learningBudgetHeaderId={this.props.learningBudgetRequestHeader.id}
                selectedOrganization={this.props.selectedOrganization} />
            </TabPane>

          </Tabs>
        </div>
      </Page>
    );
  }

  componentDidMount() {
    if (this.props.selectedOrganization && this.props.selectedBudget) {
      this.dataCollectionBudgetRequests.filter = {
        conditions: [{
          property: "organizationGroup.id",
          operator: "=",
          value: this.props.selectedOrganization!.id
        },
        {
          property: "budget.id",
          operator: "=",
          value: this.props.selectedBudget!.id
        }]
      };
      this.dataCollectionBudgetRequests.load();

      this.budgetDelegationDc.filter = {
        conditions: [{
          property: "organization.id",
          operator: "=",
          value: this.props.selectedOrganization!.id
        }]
      };
      this.budgetDelegationDc.load();

      this.updateApproveHistory();
      getCubaREST()!.loadEntities<AssignmentExt>(AssignmentExt.NAME, {
        view: 'assignment-view-for-front'
      }).then(values => {
        this.assignmentsList = (values as SerializedEntity<AssignmentExt>[]).sort((a, b) => {
          if (a._instanceName < b._instanceName) {
            return -1;
          }
          if (a._instanceName > b._instanceName) {
            return 1;
          }
          return 0;
        });
        this.props.rootStore!.learningBudgetInfo.setAssignments(this.assignmentsList);
      });

    }
    if (this.props.selectedData && this.props.selectedData.personGroup) {
      restServices.employeeService.personProfile(this.props.selectedData.personGroup.id)
        .then(value => {
          this.person = value;
        })
        .catch(() => {
          Notification.error({
            message: this.props.intl.formatMessage({ id: "management.editor.error" })
          });
        }
        )
    }

    this.reactionDisposers.push(
      reaction(
        () => this.dataCollectionBudgetRequests.status === 'DONE',
        (isDataLoaded, permsReaction) => {
          if (isDataLoaded === true) {
            this.props.rootStore!.learningBudgetInfo.setBudgetRequestConfig({
              showSizeChanger: false,
              total: this.dataCollectionBudgetRequests.count,
              current: 1,
              pageSize: 10
            } as PaginationConfig);
            let sum = 0;
            for(const request of this.dataCollectionBudgetRequests.items) {
              sum = sum + request.costSum
            }
            this.totalSum = sum / 1000
            this.courseCount = this.dataCollectionBudgetRequests.items.length

            permsReaction.dispose();
          }
        },
        { fireImmediately: true }
      )
    )

  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(dispose => dispose());
  }

}

export default injectIntl(withLocalizedForm({
  onValuesChange: (props: any, changedValues: any) => {
    // Reset server-side errors when field is edited
    Object.keys(changedValues).forEach((fieldName: string) => {
      props.form.setFields({
        [fieldName]: {
          value: changedValues[fieldName]
        }
      });
    });
  }
})(LearningBudgetCard));
