import { AbstractDictionary } from "./AbstractDictionary";
import { DicAwardType } from "./tsadv$DicAwardType";
import { DicAward } from "./tsadv_DicAward";
export class DicAwardMeasure extends AbstractDictionary {
  static NAME = "tsadv_DicAwardMeasure";
  awardType?: DicAwardType | null;
  award?: DicAward[] | null;
  payout?: boolean | null;
  hasBonus?: boolean | null;
  amountKzt?: number | null;
  countMedalMax?: number | null;
  overLimit?: number | null;
}
export type DicAwardMeasureViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "dicAwardMeasure.edit";
export type DicAwardMeasureView<
  V extends DicAwardMeasureViewName
> = V extends "_base"
  ? Pick<
      DicAwardMeasure,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "payout"
      | "hasBonus"
      | "amountKzt"
      | "countMedalMax"
      | "overLimit"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "description1"
      | "description2"
      | "description3"
      | "description4"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_local"
  ? Pick<
      DicAwardMeasure,
      | "id"
      | "payout"
      | "hasBonus"
      | "amountKzt"
      | "countMedalMax"
      | "overLimit"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_minimal"
  ? Pick<
      DicAwardMeasure,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
    >
  : V extends "dicAwardMeasure.edit"
  ? Pick<
      DicAwardMeasure,
      | "id"
      | "payout"
      | "hasBonus"
      | "amountKzt"
      | "countMedalMax"
      | "overLimit"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
      | "awardType"
      | "award"
      | "company"
      | "amountKzt"
    >
  : never;
