import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Col, List, Row, Spin} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import {action, computed, observable} from "mobx";
import {restServices} from "../../../../cuba/services";
import {CoinLogPojo} from "../../../../cuba/entities/base/tsadv$CoinLogPojo";
import InfiniteScroll from 'react-infinite-scroller';
import {Link} from "react-router-dom";
import {getPersonPageLink} from "../recognitionUtils";


@inject("rootStore")
@injectMainStore
@observer
class CoinsHistoryComponent extends React.Component<RootStoreProp & WrappedComponentProps> {

  @observable coinsLog: CoinLogPojo[] = [];
  @observable page = 0;
  @observable totalPages = 0;
  @observable isLoading = false;
  lastCount = 0;
  wasError = false;

  componentDidMount() {
    this.loadMore();
  }

  @computed get hasMore(): boolean {
    return this.page < this.totalPages;
  }

  @action loadMore = async () => {
    this.isLoading = true;
    this.page++;

    const coinLogPageInfo = await restServices.recognitionService.loadCoinLogs(this.page, this.lastCount)
      .catch(e => {
        this.wasError = true;
        throw e;
      })
      .finally(() => this.isLoading = false)

    this.coinsLog = [
      ...this.coinsLog,
      ...coinLogPageInfo.coinLogs
    ]

    this.lastCount = coinLogPageInfo.pageInfo!.totalRowsCount!;
    this.totalPages = coinLogPageInfo.pageInfo!.pagesCount!;
  }

  resolveName = (name: string) => {
    switch (name) {
      case "Points": return this.props.intl.messages["points"];
      case "HC": return this.props.intl.messages["coins"];
      default: return "";
    }
  }

  renderItem = (coinsLog: CoinLogPojo) => {
    const link = getPersonPageLink(coinsLog.targetId);

    return <List.Item>
      <Row type={"flex"} justify={"center"} style={{width: "100%", alignItems: "center"}}>
        <Col xs={5}>
          {`${coinsLog.coins} ${this.resolveName(coinsLog.coinType!)}`}
        </Col>
        <Col xs={10}>
          <Row>
            {coinsLog.actionType}
          </Row>
          <Row>
            <FormattedMessage id={"recognition.coinsHistory.from"}/>
            <Link to={link}>{coinsLog.target}</Link>
          </Row>
        </Col>
        <Col xs={9}>
          {coinsLog.date}
        </Col>
      </Row>
    </List.Item>
  }

  render() {
    const spin = <Row type={"flex"} justify={"center"} style={{height: "40px"}}>
      <Spin/>
    </Row>
    
    return <>
      <InfiniteScroll hasMore={this.hasMore && !this.wasError} loadMore={this.loadMore} loader={spin}>
        <List dataSource={this.coinsLog} renderItem={this.renderItem}/>
      </InfiniteScroll>
    </>
  }
}

export default injectIntl(CoinsHistoryComponent);