import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import TrainingPlanTabs from "./TrainingPlanTabs";

type Props = RouteComponentProps<{ entityId: string }>;

@observer
export class TrainingPlanManagement extends React.Component< Props> {
  static PATH = "/trainingPlan";
  static NEW_SUBPATH = "new";

  render() {

    const {entityId} = this.props.match.params;
    return (
      <>
        <TrainingPlanTabs  entityId={entityId}/>
      </>
    );
  }
}