import * as Cookies from "es-cookie";
import { CubaApp } from "@cuba-platform/rest";

const TOKEN_KEY = "token"

export function readCookie(cubaREST: CubaApp) {
  const cookieToken = Cookies.get(TOKEN_KEY);

  if (cookieToken !== undefined && cookieToken !== null) {
    cubaREST.restApiToken = cookieToken;
    removeCookie();
  }
}

export function removeCookie() {
  // NOTE: https://github.com/theodorejb/es-cookie#remove
  Cookies.remove(TOKEN_KEY);
  Cookies.remove(TOKEN_KEY, { path: '' });
}

export default {
  readCookie,
  removeCookie,
};
