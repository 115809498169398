import * as React from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

import { Table } from "antd";

import { injectMainStore, MainStoreInjected, Msg } from "@cuba-platform/react";

import { DocumentsFamiliarization } from "../../../cuba/entities/base/DocumentsFamiliarization";
import { SerializedEntity } from "@cuba-platform/rest";
import { DocumentsFamiliarizationManagement } from "./DocumentsFamiliarizationManagement";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import Column from "antd/es/table/Column";
import moment from "moment";
import { restServices } from "../../../cuba/services";
import { RootStoreProp } from "../../store";
import { collectionWithAfterLoad } from "../../util/DataCollectionStoreWithAfterLoad";
import { observable } from "mobx";
import { getLocaleName, langValue } from "../../util/util";

@inject("rootStore")
@injectMainStore
@observer
class DocumentsFamiliarizationListComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  @observable
  dataCollection: SerializedEntity<DocumentsFamiliarization>[];

  render() {
    return (
      <Page>
        <Section visible={false} size={"large"}>
          <Table dataSource={this.dataCollection} pagination={false}
                 size="default" bordered={false} rowKey="id">
            <Column title={<Msg entityName={DocumentsFamiliarization.NAME} propertyName='name'/>}
                    dataIndex={"name" + getLocaleName(this.props.mainStore!.locale!)}
                    key={"name" + getLocaleName(this.props.mainStore!.locale!)} render={(text, record: SerializedEntity<DocumentsFamiliarization>) => {
              return <Link
                to={DocumentsFamiliarizationManagement.PATH + "/" + record.id}
                key="edit">
                {record['name' + getLocaleName(this.props.mainStore!.locale!)]}
              </Link>
            }}/>
            <Column title={<Msg entityName={DocumentsFamiliarization.NAME} propertyName='type'/>}
                    dataIndex={"type"}
                    key={"type"} render={(text, record: SerializedEntity<DocumentsFamiliarization>, index) => {
              return record.type[langValue(this.props.mainStore!.locale!)];
            }}/>
            <Column title={<Msg entityName={DocumentsFamiliarization.NAME} propertyName='file'/>}
                    dataIndex={"file"}
                    key={"file"} render={(text, record: SerializedEntity<DocumentsFamiliarization>, index) => {
              return record.file.name;
            }}/>
            <Column title={<Msg entityName={DocumentsFamiliarization.NAME} propertyName='description'/>}
                    dataIndex={"description" + getLocaleName(this.props.mainStore!.locale!)}
                    key={"description" + getLocaleName(this.props.mainStore!.locale!)} render={(text, record: SerializedEntity<DocumentsFamiliarization>, index) => {
              return record['description' + getLocaleName(this.props.mainStore!.locale!)];
            }}/>
          </Table>
        </Section>
      </Page>
    );
  }


  componentDidMount(): void {
    restServices.portalHelperService.companiesForLoadDictionary({
      personGroupId: this.props.rootStore!.userInfo.personGroupId!
    }).then(companies => {
      const store = collectionWithAfterLoad<DocumentsFamiliarization>(
        DocumentsFamiliarization.NAME,
        () => {
          this.dataCollection = store.items;
        },{
          view: "documentsFamiliarization.edit", sort: "-updateTs", filter: {
            conditions: [{
              property: 'dateFrom',
              operator: '<=',
              value: moment().format('YYYY-MM-DD'),
            }, {
              property: 'dateTo',
              operator: '>=',
              value: moment().format('YYYY-MM-DD'),
            }, {
              property: "company.id",
              operator: "in",
              value: companies
            }]
          }
        }
      );
    });
  }
}

const DocumentsFamiliarizationList = injectIntl(
  DocumentsFamiliarizationListComponent
);

export default DocumentsFamiliarizationList;
