import * as React from "react";
import {Alert, Button, Checkbox, Form, Icon, Input, Modal, Select} from "antd";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {
  getCubaREST,
  injectMainStore,
  instance,
  withLocalizedForm
} from "@cuba-platform/react";
import "../../../app/App.css";
import {withRouter} from "react-router-dom";
import {IndividualDevelopmentPlan} from "../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";
import './IndividualDevolopmentPlan.css'
import IndividualDevPlanTabs from "./IndividualDevPlanTabs/IndividualDevPlanTabs";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {AssignmentExt} from "../../../cuba/entities/base/base$AssignmentExt";
import {action, observable, reaction, runInAction, when} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {IdpDetail} from "../../../cuba/entities/base/tsadv$IdpDetail";
import moment, { Moment } from "moment";
import {restServices} from "../../../cuba/services";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {Successor} from "../../../cuba/entities/base/tsadv$Successor";
import {FC} from "react";
import {PositionGroupExt} from "../../../cuba/entities/base/base$PositionGroupExt";
import {CompetenceElement} from "../../../cuba/entities/base/tsadv$CompetenceElement";
import {PersonAssessment} from "../../../cuba/entities/base/tsadv_PersonAssessment";
import EditableAssessmentForm from "../../components/EditableAssessmentForm/EditableAssessmentForm";
import Notification from "../../util/Notification/Notification";
import {CompetenceTemplate} from "../../../cuba/entities/base/tsadv$CompetenceTemplate";
import {DicAssessmentStatus} from "../../../cuba/entities/base/tsadv$DicAssessmentStatus";
import {AssessmentTemplate} from "../../../cuba/entities/base/tsadv$AssessmentTemplate";
import {CompetenceTemplateParticipant} from "../../../cuba/entities/base/tsadv_CompetenceTemplateParticipant";
import {rootStore} from "../../store";
import {AssessmentParticipant} from "../../../cuba/entities/base/tsadv$AssessmentParticipant";
import IdpCandidateModal from "./IdpCandidateModal/IdpCandidateModal";
import {IdpStatus} from "../../../cuba/enums/enums";
import {runReport} from "../../util/reportUtil";
import { AssessmentDetail } from "../../../cuba/entities/base/tsadv_AssessmentDetail";


export interface PersonAssessmentRes {
  person_assessment_id: string;
  person_group_id: string;
  date_from: string;
  date_to: string;
  status_code: string;
  session_name: string;
  employeeFullName: string;
  participant_person_group_id: string;
  participant_type_code: string;
  total_result: number;
  instruction: string;
  assessment_type: string;
  participant_status_id: string;
  partcicipant_result: number;
  participant_status_code: string;
  is_edit: boolean;
  position_group_id:string;
  create_ts:string;
  update_ts:string;
}


type EditorProps = {
  entityId: string | undefined;
  addressId?: string;
};


const ModalFC:FC<any> = (array: any, text:any):any =>{
  return <>
    <div>
      <FormattedMessage id={"history"}/> <FormattedMessage id={`${text && text.title}`}/>
    </div>
    <div>
    {array !== undefined ?
      array!.split("`")!.map((item: any, index: any) => {
        const a = ".";
        return <div key={index}
                    style={
                      item.trim().toLowerCase() === text && text!.value!.trim().toLowerCase() || item.includes(text && text!.value!)
                        ? {color: "black"}
                        : {color: "gray"} && index === 0 ? {color:"#403F4C"} : {color: "gray"}
                    }> {a.repeat(index) + item} </div>
      })
      : ""}
  </div>
  </>
}
@inject("rootStore")
@injectMainStore
@observer
class IndividualDevolopmentPlanEditComponent extends AbstractBprocEdit<IndividualDevelopmentPlan, EditorProps> {

  processDefinitionKey = "individualDevelopmentPlanRequest"

  fields = []

  dataInstance = instance<IndividualDevelopmentPlan>(IndividualDevelopmentPlan.NAME, {
    view: "individualDevelopmentPlanNew-edit"
  })

  @observable personGroupDc: SerializedEntity<PersonGroupExt>[] = []

  @observable assignment: SerializedEntity<AssignmentExt>[] = []

  @observable idpDetails: SerializedEntity<IdpDetail>[];

  @observable currentUser: SerializedEntity<PersonGroupExt>;

  @observable personData : SerializedEntity<Successor>

  @observable successorList : Array<SerializedEntity<Successor>>

  @observable positionHierarchy:string

  @observable positionGroupvalue:any|null

  @observable organizationHierarchy:string

  @observable
  isReservist: boolean = false

  @observable
  personGroupIdOnRequest: string

  @observable id: string = "";

  @observable isSuccessorModalVisible = false;

  @observable lengthOfRequest:number

  @observable disabledPage:boolean

  @observable personAssessment:PersonAssessment

  @observable alreadyCreated:boolean = false;

  isUpdateBeforeOutcome = true;

  @action.bound
  toggleProfileModalVisibility() {
    this.isSuccessorModalVisible = !this.isSuccessorModalVisible;
  }

    getUpdateEntityData(): IndividualDevelopmentPlan {
      delete this.props.rootStore!.idpStore!.selectedIdp!.idpMeeting
      if (this.props.entityId === 'new' && this.props.rootStore!.idpStore!.selectedIdp!.id==="") {
      delete this.props.rootStore!.idpStore!.selectedIdp!.id
      return {...super.getUpdateEntityData(),...this.props.rootStore!.idpStore!.selectedIdp!,personAssessment:{id:rootStore!.idpStore!.actualAssessment!.person_assessment_id}}
    }
    else{
      return {...this.props.rootStore!.idpStore!.selectedIdp!,personAssessment:{id:rootStore!.idpStore!.actualAssessment!.person_assessment_id}}
    }
  }

  @action  isKeyPos = (posG:PositionGroupExt) =>{
    return !!this.successorList.find((s) => (s.succession && s.succession.positionGroup && s.succession.positionGroup.id) === (posG && posG.id));
  }

@action showSuccessorProfileModal = (id?:string) => {
    const store = this.props.rootStore!.idpStore
    if(id){
      getCubaREST()!.loadEntity<Successor>(Successor.NAME,id,{view:"successor-talentPool"}).then(data=>this.personData=data).catch(()=>{
        getCubaREST()!.searchEntities<Successor>(Successor.NAME,{
          conditions:[{
            property:"personGroup.id",
            operator:"=",
            value:this.props.rootStore!.idpStore!.selectedIdp!.personGroup!.id
          },{
            property:"succession.positionType",
            operator:"=",
            value:"INCLUDE"
          },{
            property:"succession.positionGroup.id",
            operator:"=",
            value:id
          }]
        },{
          view: "successor-talentPool",
          sort:"updateTs"
        }).then((item)=>{
          this.personData = item[0]
          if(store&&this.personData&&this.personData.readinessLevel) store.readinessLevelFromCard = this.personData.readinessLevel
          if(item.length > 0){
            this.isReservist = true;
            if(rootStore.idpStore) rootStore.idpStore.isReservist = true
          }
        })
      })
    }
    else {
      getCubaREST()!.searchEntities<Successor>(Successor.NAME, {
        conditions: [{
          property: "personGroup.id",
          operator: "=",
          value: this.props.rootStore!.idpStore!.selectedIdp!.personGroup!.id
        }, {
          property: "succession.positionType",
          operator: "=",
          value: "INCLUDE"
        }]
      }, {
        view: "successor-talentPool",
        sort: "updateTs"
      }).then((item) => {
        this.personData = item[0]
        if(store&&this.personData&&this.personData.readinessLevel) store.readinessLevelFromCard = this.personData.readinessLevel
        if (item.length > 0) {
          this.isReservist = true;
          if(rootStore.idpStore) rootStore.idpStore.isReservist = true
        }
      })
    }
  }

  @observable allAssessment:PersonAssessmentRes[]

  @observable notEmployee:boolean = false;

 @action createNewSessionAlert = () =>{
   Modal.info({title:this.props.intl.formatMessage({id:"fillEmployeeCardMsg"}),centered:true})
    this.assessmentFormVisible = false;
  }

    @action createNewSession = () =>{
      const store = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.selectedIdp && this.props.rootStore!.idpStore
      if (store) {
        const pg = store&&store.selectedIdp.positionGroup

        if(!pg){
          Notification.error({message:this.props.intl.formatMessage({id:"notification.positionGroup"})})
          return
        }
        const personGr = store&&store.selectedIdp.personGroup
        getCubaREST()!.searchEntities<CompetenceTemplate>(CompetenceTemplate.NAME, {
          conditions: [{
            property: "code",
            operator: "=",
            value: this.isKeyPos(pg)?"LDP":"ИПР_CBA"
          }]
        }).then((ct) => {
          Promise.all([getCubaREST()!.searchEntities<DicAssessmentStatus>(DicAssessmentStatus.NAME, {
            conditions: [{
              property: "code",
              operator: "=",
              value: "DRAFT"
            }]
          }).then(as => as[0]),
            getCubaREST()!.searchEntities<AssessmentTemplate>(AssessmentTemplate.NAME, {
              conditions: [{
                property: "competenceTemplate.id",
                operator: "=",
                value: ct[0].id
              }]
            }).then(at => at[0]),
            getCubaREST()!.searchEntities<CompetenceTemplateParticipant>(CompetenceTemplateParticipant.NAME,{
              conditions:[{
                property:"competenceTemplate.id",
                operator:"=",
                value:ct[0].id
              }]
            },{view:"competenceTemplateParticipant-for-assessmentTemplate-edit"}).then(res=>res),
          ]).then(data => {
            const personAssessment: PersonAssessment = {
               assessmentTemplate: data[1],
               personGroup: store && store.selectedIdp && store.selectedIdp.personGroup,
               assessmentStatus: data[0],
               positionGroup: this.props.rootStore!.idpStore!.selectedIdp! && this.props.rootStore!.idpStore!.selectedIdp.positionGroup,
             }
             getCubaREST()!.commitEntity<PersonAssessment>(PersonAssessment.NAME, {
               ...personAssessment,
               id: ""
             }).then(pa => {
               this.personAssessment = {
                 id: pa.id,
                 ...personAssessment
               }
               this.newPersonAssessmentId = pa.id
               store.selectedIdp.personAssessment = {id:this.newPersonAssessmentId};
               this.dataInstance.item!.personAssessment = {id:this.newPersonAssessmentId};
               restServices.learningService.createAssessment({personAssessmentId:pa.id}).then(()=>{
               const participant = {...(data[2][0] as CompetenceTemplateParticipant),personGroupId:personGr!.id};
                 restServices.learningService.createAssessmentParticipant({personAssessmentId:pa.id,personGroupId:participant.personGroupId,code:"EMPLOYEE"})
                   .then(()=>{
                     this.editableFormVisible = true;
                     this.alreadyCreated = true;
                   })
               })
             })
          })
        })
      }

  }

afterSendOnApprove = () =>{
   const idpStore = this.props.rootStore!.idpStore
   if(idpStore){
     idpStore.saveIdpDetails()
   }
   this.props.history.push("/trainingPlan")
}

@observable validationStarted:boolean;
  @observable addMeButtonLoading:boolean = false;

  @action addMeToSession = ():void => {
    this.addMeButtonLoading = true;
    const store = this.props.rootStore!.idpStore
    const pg = this.props.rootStore!.userInfo.personGroupId!
    if(store&&store.selectedIdp.personGroup&&store.selectedIdp.personGroup.id!==pg){
      restServices.learningService.createAssessmentParticipant({personAssessmentId:store.actualAssessment!.person_assessment_id!,personGroupId:pg,code:this.isKeyPos(store.selectedIdp.positionGroup!)?"LDP":"MANAGER"})
        .then(()=>{
          this.addMeButtonLoading = false;
          this.tableKey = this.tableKey +1;
          this.getParticipantsCount(store!.actualAssessment!.person_assessment_id)
        });
    }
  }
  @observable tableKey=0;

  @observable participantsCount = 0;

  @observable assessmentForm:Array<any>;

  @observable newPersonAssessmentId:string;

  @action checkForSelfAssessmentValidation = () => {
   const levels = this.assessmentForm.filter(a=>(a.participants[0]&&a.participants[0].scale_level&&a.participants[0].scale_level!==""))
   rootStore!.idpStore!.selfAssessmentValidation = levels.length >= this.assessmentForm.filter(a=>(a.entityName === AssessmentDetail.NAME)).length;     
    }
  @action checkForManagerAssessmentValidation = () => {
    const levels = this.assessmentForm.filter(a=>(a.participants[1]&&a.participants[1].scale_level&&a.participants[1].scale_level!==""))
    rootStore!.idpStore!.managerValidation = levels.length >= this.assessmentForm.length;
    rootStore!.idpStore!.selfAssessmentValidation = levels.length >= this.assessmentForm.filter(a=>(a.entityName === AssessmentDetail.NAME)).length;     
  }
  @action getParticipantsCount = (id:string,onFirstLoad?:boolean) => {    
    restServices.learningService.getPersonAssessmentForm({personGroupId:id,lang:this.props.rootStore!.userInfo.locale!}).then(res=> {
      this.assessmentForm = JSON.parse(res[0]['value'])
      this.participantsCount = JSON.parse(res[0]['value'])[0].participants.length;
      this.checkForSelfAssessmentValidation();
      this.checkForManagerAssessmentValidation();
      if(this.assessmentForm&&this.assessmentForm[0]&&this.assessmentForm[0].participants&&this.assessmentForm[0].participants[1]&&this.assessmentForm[0].participants[1].result_percent){
       Promise.all([getCubaREST()!.loadEntities<DicAssessmentStatus>(DicAssessmentStatus.NAME,{view:"_local"})
         .then(statuses=>statuses),
        getCubaREST()!.searchEntities<AssessmentParticipant>(AssessmentParticipant.NAME,{
          conditions:[{
            property:"personAssessment.id",
            operator:"=",
            value:id
          }]
        },{view:"_local"}).then(par =>par)]).then(res=>{
        const status =  res[0].find(s=>s.code === "SEND")
        const participants = res[1];
         getCubaREST()!.commitEntity<AssessmentParticipant>(AssessmentParticipant.NAME,{...participants[0],assessmentStatus:status}).then(()=>{
           getCubaREST()!.commitEntity<AssessmentParticipant>(AssessmentParticipant.NAME,{...participants[1],assessmentStatus:status})
         })
       })
      }
    })
    if(onFirstLoad) return;
    restServices.learningService.getPersonAssessments({personGroupId:rootStore!.idpStore!.selectedIdp.personGroup!.id,lang:this.props.rootStore!.userInfo.language!})
      .then(res=>{
        if(res[0]&&res[0].value) {
          this.allAssessment = JSON.parse(res[0].value);
          const filtered = this.allAssessment.filter(i=>i.person_assessment_id === rootStore!.idpStore!.actualAssessment!.person_assessment_id)
          if (this.allAssessment.length > 0)
            rootStore!.idpStore!.actualAssessment = filtered[0]
        }
      })
  }

  @observable competenceLen:number

  @observable canCreateAssessment:boolean;

  @observable isLoadingReporteEn:boolean = false
  @observable isLoadingReportRu:boolean = false
  @observable isVisibleReportModal:boolean = false
  loadPersonInfo = async (localeProp: string, e: any) => {
    try {
      const personProfile = await restServices.employeeService.personProfile(this.props.rootStore!.idpStore!.selectedIdp.personGroup!.id);
      const data = {
        parameters: [{
          name: "person",
          value: personProfile.id
        }]
      };
      let locale: string = localeProp === "en" ? "PROFILE_TD_EN" : "PROFILE_TD_RU"
      runReport(locale, data, this.props.intl);
    } catch (e) {
      Notification.error({
        message: this.props.intl.formatMessage({
          id: 'adaptationPlan.employee_profile.NotFound',
          defaultMessage: 'Employee Profile Not Found!',
        }),
      });
    }
  }
  @action
  showReportModal = () => {
    this.isVisibleReportModal= true
  };

  @action
  handleOk = () => {
    this.isVisibleReportModal= false
  };

  @action
  handleCancel = () => {
    this.isVisibleReportModal= false
  };

  @observable haveOld:boolean;

  @action setHaveOld = (value:boolean) => {
    this.haveOld =  value
  }


  render() {
    const store = this.props.rootStore!.idpStore
    const item = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.selectedIdp;
    const currentAssignment = this.currentUser && this.currentUser.currentAssignment
    const personName = item && item.personGroup && item.personGroup.fullName || this.currentUser && this.currentUser.person&&this.currentUser.person['_instanceName']
    const newStatus = item && item.status && item.status['_instanceName'] || ""
    const IPRTitle = this.props.rootStore!.userInfo.locale==='ru'? item && item!.planName! : item &&item.planNameEn
    const startDate = item && item.startDate&&moment(item.startDate).locale('ru').format('L') || moment().locale('ru').format('L')
    const endDate = item && item.endDate&&moment(item.endDate).locale('ru').format('L')  || moment().endOf('year').locale('ru').format('L')
    const positionName = currentAssignment && currentAssignment.positionGroup && currentAssignment.positionGroup['_instanceName']
    const isComplete = item && item.complete!
    const positionGroup = currentAssignment && currentAssignment.positionGroup && currentAssignment.positionGroup
    const organizationGroup = currentAssignment && currentAssignment.organizationGroup && currentAssignment!.organizationGroup
    const planPercent = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.planPercent&&this.props.rootStore!.idpStore.planPercent.toFixed(0) || 0
    const company = item&&item.positionGroup&&item.positionGroup.company&&item.positionGroup.company.code || this.positionGroupvalue&&this.currentUser&& this.currentUser.company&&this.currentUser.company.code||""
    const info = (data:any, equal:string, title:any) => {
      Modal.info({
        className:"individualPlane_modal",
        title:this.props.intl.formatMessage({id:'individualPlan.list.hierarchy'}) +  this.props.intl.formatMessage({id:`${title}`}),
        content: (
          <div>{
            data !== undefined ?
              data.split("`")!.map((item: any, index: any) => {
                const a = ".";
                return <div key={index}
                            style={
                              item.trim().toLowerCase() === equal.trim().toLowerCase() || item.includes(equal)
                              ? {color: "black",width:"max-content"}
                              : {color: "gray",width:"max-content"} && index === 0 ? {color:"#403F4C",width:"max-content"} : {color: "gray",width:"max-content"}
                            }>
                  {a.repeat(index) + item}
                </div>
              })
              :""
          }
          </div>
        ),
        onOk() {},
      });
    }
    const { Option, OptGroup } = Select;
    const personGroup =this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp&&this.props.rootStore!.idpStore.selectedIdp.personGroup&&this.props.rootStore!.idpStore.selectedIdp.personGroup
    const selectedPG = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp&&this.props.rootStore!.idpStore.selectedIdp.positionGroup;
    const isInitiator = personGroup&&personGroup.id === this.props.rootStore!.userInfo.personGroupId!;
    return (
      <>
      <div className={"individualPlane"}>
      <Page pageName={IPRTitle?IPRTitle: ""}>
        <Section style={{width:"100%"}}>
            <div className={"individualPlane_top"}>
              <div className={"individualPlane_top__left"}>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.edit.name"}/></p>
                  <Form.Item style={{marginBottom:10}}>
                    <Input
                      value={personName || ""}
                      disabled/>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.edit.position"}/></p>
                  <Form.Item style={{marginBottom:10}}>
                    <Input
                      value={positionName || ""}
                      disabled/>
                    <Button
                      onClick={()=>{
                        info(this.positionHierarchy, positionName || "", "individualPlan.edit.position.title")
                      }}
                      style={{background: "transparent", padding: "1px 5px"}}>
                      <Icon type="apartment"/>
                    </Button>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.edit.organization"}/></p>
                  <Form.Item style={{marginBottom:10}}>
                    <Input
                      value={organizationGroup && organizationGroup['_instanceName'] || ""}
                      disabled/>
                    <Button onClick={()=>{

                      info(this.organizationHierarchy,organizationGroup && organizationGroup.organizationName|| '', 'individualPlan.edit.organization.title' )
                    }} style={{background: "transparent", padding: "1px 5px"}}>
                      <Icon type="apartment" style={{width: "2em", height: "2em"}}/>
                    </Button>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.list.IPR"}/> <FormattedMessage id={"positionGroup"}/></p>
                  <Form.Item validateStatus={!selectedPG&&store&&store.validationStarted?"error":""} help={!selectedPG&&store&&store.validationStarted?this.props.intl.formatMessage({id:"notification.positionGroup"}):undefined} style={{marginBottom:10}}>
                    <Select value={this.successorList&&this.successorList.length>0||positionGroup?item&&item.positionGroup&&item.positionGroup.id||"":""} disabled={this.isNotDraft()} style={{ width: "100%" }} onChange={this.handleChange}>
                      <OptGroup label={this.props.intl.formatMessage({id:"individualPlan.edit.select.currentPosition"})}>
                        {
                          <Option key={positionGroup&&positionGroup.id} value={positionGroup&&positionGroup.id}>{positionName}</Option>
                        }
                      </OptGroup>
                      <OptGroup label={this.props.intl.formatMessage({id:"individualPlan.edit.select.keyposition"})}>
                        {
                          this.successorList && this.successorList.map(item=>{
                            const successionPositionName = item&&item.succession&&item.succession.positionGroup&&item.succession.positionGroup["_instanceName"]|| ""
                            return <Option key={item.succession && item.succession.positionGroup && item.succession.positionGroup!.id!} value={item.succession && item.succession.positionGroup && item.succession.positionGroup!.id!}>{successionPositionName}</Option>
                          })
                        }
                      </OptGroup>
                    </Select>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"company"}/></p>
                  <Form.Item style={{marginBottom:10}} labelCol={{style:{marginBottom:0}}}>
                    <Input disabled value={company||""}/>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.edit.reservist"}/></p>
                  <Form.Item style={{marginBottom:0}} wrapperCol={{span:24}}>
                    <div className={"individualPlane_top-items--chek"}>
                      <Checkbox  checked={this.isReservist}/>
                      { this.isReservist ?
                        <button style={{background: "transparent", border: "none", cursor: "pointer"}} onClick={() => {
                          this.toggleProfileModalVisibility();
                        }}>
                          <div style={{color:"blue",textDecoration: "underline",marginLeft:30}}>
                            <FormattedMessage id={"individualPlan.edit.link"}/>
                          </div>
                        </button> :
                        <button  style={{background: "transparent", border: "none", cursor: "not-allowed"}}>
                          <div style={{marginLeft:30}}>
                            <FormattedMessage id={"individualPlan.edit.link"}/>
                          </div>
                        </button>
                      }
                    </div>
                    <span style={{marginBottom:0}}/>
                    <FormattedMessage id={"individualPlan.edit.complete"}/>
                    <Checkbox style={{marginLeft:10}} checked={isComplete}/>
                  </Form.Item>
                </div>
              </div>
              <div className={"individualPlane_top__right"}>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0,marginTop:0}}><FormattedMessage id={"individualPlan.edit.persent"}/></p>
                  <Form.Item style={{marginBottom:10}}>
                    <Input
                      value={planPercent}
                      disabled/>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.edit.plan.startDate"}/></p>
                  <Form.Item style={{marginBottom:10}}>
                    <Input
                      value={startDate.substr(0,10).split('-').reverse().join('.')}
                      disabled/>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.edit.plan.endDate"}/></p>
                  <Form.Item style={{marginBottom:10}}>
                    <Input
                      value={endDate.substr(0,10).split('-').reverse().join('.')}
                      disabled/>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"individualPlan.edit.status"}/></p>
                  <Form.Item style={{marginBottom:10}} labelCol={{style:{marginBottom:0}}}>
                    <Input
                      value={newStatus}
                      disabled/>
                  </Form.Item>
                </div>
                <div className={"individualPlane_top-items"}>
                  <p style={{marginBottom:0}}><FormattedMessage id={"keyPosPercent"}/></p>
                  <Form.Item style={{marginBottom:10}} labelCol={{style:{marginBottom:0}}}>
                    <a style={{textDecoration:"underline",marginRight:10}} onClick={()=>runInAction(()=>store&&store.actualAssessment?this.assessmentFormVisible = true:this.createNewSessionAlert())}>
                      {store&&store.actualAssessment&&store.actualAssessment.total_result||0}%
                    </a>
                    {!this.isCreatable|| this.haveOld ||(this.alreadyCreated || store&&store.selectedIdp&&store.selectedIdp.personGroup&&store.selectedIdp.personGroup.id!==this.props.rootStore!.userInfo.personGroupId)?
                      this.haveOld?<Alert message={this.props.intl.formatMessage({id:"haveOldSession.error"},{dateFrom:store&&store.actualAssessment&&store.actualAssessment.date_from&&moment(store.actualAssessment.date_from).locale('ru').format('L'),dateTo:store&&store.actualAssessment&&store.actualAssessment.date_to&&moment(store.actualAssessment.date_to).locale('ru').format('L')})} type="error" />:<></>:
                    <a style={this.competenceLen>0?{textDecoration:"underline"}:{pointerEvents:"none",cursor:"not-allowed"}} onClick={()=>this.createNewSession()}><FormattedMessage id={isInitiator?"createNewEstimation":"showNewEstimation"}/></a>}
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className={"individualPlane_tabs"}>
              <Button
                onClick={this.showReportModal}
                style={{background:"green",color:"#fff",float:"right", marginRight:40}}>
                <FormattedMessage id={"myTeam-personData.profile"}/>
              </Button>
              <Modal
                title={this.props.intl.formatMessage({id:"myTeam-personData.choosen"})}
                visible={this.isVisibleReportModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                <div>

                  <Button loading={this.isLoadingReportRu} style={{marginRight:"14px"}}  shape="round" icon="download" onClick={(e)=>this.loadPersonInfo("ru",e)}>
                    Ru
                  </Button>
                  <Button loading={this.isLoadingReporteEn}  shape="round" icon="download" onClick={(e)=>this.loadPersonInfo("en",e)}>
                    En
                  </Button>

                </div>
              </Modal>
              <IndividualDevPlanTabs organizationName={organizationGroup&&organizationGroup.organizationName || "" }
                                     positionName={positionName || ""}
                                     disabledPage={this.disabledPage}
                                     takCard={this.takCard()} getOutcomeBtns={this.getOutcomeBtns()}
                                     entityId={this.props.entityId!}/>
            </div>

        </Section>

        <IdpCandidateModal toggleVisibility={this.toggleProfileModalVisibility} personData={this.personData}
                                         isSuccessorModalVisible={this.isSuccessorModalVisible}/>
        {store&&store.actualAssessment?
        <Modal onCancel={(e)=>runInAction(()=>{
          e.preventDefault()
            this.assessmentFormVisible=false;
          if(store&&store.actualAssessment&&store.actualAssessment.person_assessment_id) this.getParticipantsCount(store&&store.actualAssessment&&store.actualAssessment.person_assessment_id);
          restServices.learningService.getPersonAssessments({personGroupId:personGroup!.id,lang:this.props.rootStore!.userInfo!.locale!}).then((res)=>{
            if(this.newPersonAssessmentId) {
              const data = JSON.parse(res[0]['value']);
              store.actualAssessment = data.filter((d: PersonAssessmentRes) => d.person_assessment_id === this.newPersonAssessmentId)[0];
            }})
        })}
               visible={this.assessmentFormVisible} centered width={"80%"}
               footer={[
                 <Button loading={this.addMeButtonLoading} style={((item&&item.personGroup&&item.personGroup.id!==this.props.rootStore!.userInfo.personGroupId) && this.participantsCount < 2) ?{backgroundColor:"green",color:"white"}:{display:"none"}}
                 onClick={()=>{
                   this.addMeToSession();
                 }}>
                   <FormattedMessage id="addMeToSession"/></Button>,
                 <Button type={"primary"} style={{width:150}}  onClick={(e)=>{
                   e.preventDefault();
                   runInAction(()=>{
                     this.assessmentFormVisible=false;
                     if(store&&store.actualAssessment&&store.actualAssessment.person_assessment_id)
                     this.getParticipantsCount(store!.actualAssessment!.person_assessment_id!);
                     store!.selectedIdp.personAssessment = {id:store!.actualAssessment!.person_assessment_id!}
                   });
                 }}>OK</Button>]}>
          <EditableAssessmentForm
            key={this.tableKey}
            positionGroupName={store&&store.selectedIdp.positionGroup&&store.selectedIdp.positionGroup['_instanceName']}
            personGroupId={store&&store.selectedIdp.personGroup!.id}
            personAssessmentId={store&&store.actualAssessment.person_assessment_id}/>
        </Modal>
          :<></>
        }
          {this.personAssessment ?
            <Modal onCancel={()=>runInAction(()=>{
              this.editableFormVisible = false;
              if(store&&store.actualAssessment&&store.actualAssessment.person_assessment_id)
              this.getParticipantsCount(store!.actualAssessment!.person_assessment_id!);
              if(this.newPersonAssessmentId) {
                restServices.learningService.getPersonAssessments({
                  personGroupId: personGroup!.id,
                  lang: this.props.rootStore!.userInfo!.locale!
                }).then((res) => {
                  const data = JSON.parse(res[0]['value']);
                  store!.actualAssessment = data.filter((d: PersonAssessmentRes) => d.person_assessment_id === this.newPersonAssessmentId)[0];
                })
              }})}
                   footer={[<Button type={"default"} style={{width:150}} onClick={()=>runInAction(()=>{
                     this.editableFormVisible = false;
                     if(store&&store.actualAssessment&&store.actualAssessment.person_assessment_id)
                       this.getParticipantsCount(store!.actualAssessment!.person_assessment_id!);
                     if(this.newPersonAssessmentId) {
                       restServices.learningService.getPersonAssessments({
                         personGroupId: personGroup!.id,
                         lang: this.props.rootStore!.userInfo!.locale!
                       }).then((res) => {
                         const data = JSON.parse(res[0]['value']);
                         store!.actualAssessment = data.filter((d: PersonAssessmentRes) => d.person_assessment_id === this.newPersonAssessmentId)[0];
                         this.dataInstance.item!.personAssessment = { id:this.newPersonAssessmentId }
                       })
                     }})}><FormattedMessage id="keyPosition.modalTable.cancel"/></Button>,
                            <Button type={"primary"} onClick={(e)=>{
                              e.preventDefault();
                              runInAction(()=>{
                                this.editableFormVisible=false;
                                if(store&&store.actualAssessment&&store.actualAssessment.person_assessment_id)
                                  this.getParticipantsCount(store!.actualAssessment!.person_assessment_id!);
                                if(this.newPersonAssessmentId) {
                                  this.getParticipantsCount(this.newPersonAssessmentId);
                                  restServices.learningService.getPersonAssessments({
                                    personGroupId: personGroup!.id,
                                    lang: this.props.rootStore!.userInfo!.locale!
                                  }).then((res) => {
                                      const data = JSON.parse(res[0]['value']);
                                      store!.actualAssessment = data.filter((d: PersonAssessmentRes) => d.person_assessment_id === this.newPersonAssessmentId)[0];
                                      this.dataInstance.item!.personAssessment = {id: this.newPersonAssessmentId}
                                    })
                                if(store&&store.selectedIdp&&store.selectedIdp.status&&store.selectedIdp.status.code===IdpStatus.APPROVED){
                                  store.saveRequest();
                                }}
                              })
                            }} style={{width:150}}>OK</Button> ]}
              visible={this.editableFormVisible} width={"80%"} centered
            >
            <EditableAssessmentForm
              personGroupId={rootStore!.idpStore!.selectedIdp.personGroup!.id}
              personAssessmentId={this.personAssessment.id}/>
            </Modal>:
            <></>
          }
      </Page>
      </div>
      </>
    )
  }

  @observable isCreatable:boolean;

  @action
  handleChange = async (value:string)=> {
    let competenceLen: number;
    let positionGroup: PositionGroupExt;
    competenceLen = await getCubaREST()!.searchEntities<CompetenceElement>(CompetenceElement.NAME, {
      conditions: [{
        property: "positionGroup.id",
        operator: "=",
        value: value
      }]
    }, {view: "competenceElement.full"}).then(elements => {
      if (positionGroup) competenceNullMsg(positionGroup['_instanceName'])
      if (this.props.rootStore!.idpStore)
        this.props.rootStore!.idpStore.positionCompetencies = elements.map(e => e.competenceGroup!)
      return elements.length
    })
    const competenceNullMsg = (positionGroupName: string) => {
      if (competenceLen === 0) {
        this.competenceLen = 0;
        Notification.error({
          message: this.props.intl.formatMessage({id: "noCompetenceMsg"}, {positionGroupName: positionGroupName}),
          duration: 6
        });
      } else this.competenceLen = competenceLen
    }
    this.positionGroupvalue = value;
    const idpStore = this.props.rootStore!.idpStore;
    if (value === (this.currentUser.currentAssignment && this.currentUser.currentAssignment.positionGroup && this.currentUser.currentAssignment.positionGroup.id)) {
      positionGroup = this.currentUser!.currentAssignment!.positionGroup!
      rootStore!.idpStore!.selectedIdp.positionGroup = positionGroup
      this.showSuccessorProfileModal()
    } else {
      const result = this.successorList.find(s => (s.succession && s.succession.positionGroup && s.succession.positionGroup.id) === value)
      positionGroup = result!.succession!.positionGroup!
      rootStore!.idpStore!.selectedIdp.positionGroup = positionGroup
      this.showSuccessorProfileModal(result!.id)
    }
    competenceNullMsg(positionGroup['_instanceName'])
    if (idpStore && idpStore.selectedIdp) {
        idpStore.selectedIdp = {...idpStore.selectedIdp, positionGroup: positionGroup}
      if (this.allAssessment && this.allAssessment.length > 0) {
        const filtered = this.allAssessment!.filter(aa => aa.position_group_id === value)
        if (filtered && filtered.length > 0 && idpStore) {
          idpStore!.actualAssessment = this.allAssessment.filter((c)=>c.person_assessment_id === (idpStore.selectedIdp&&idpStore.selectedIdp.personAssessment&&idpStore.selectedIdp.personAssessment.id) && c.person_group_id === (idpStore.selectedIdp&&idpStore.selectedIdp.personGroup&&idpStore.selectedIdp.personGroup.id))[0]
          if(idpStore.actualAssessment&&idpStore.actualAssessment.person_assessment_id)
          idpStore.selectedIdp.personAssessment = {id: idpStore.actualAssessment.person_assessment_id}
          else  idpStore!.actualAssessment = this.allAssessment.filter(aa => aa.position_group_id === value && aa.person_group_id === idpStore!.selectedIdp.personGroup!.id).reduce((prev, curr) => moment(prev.create_ts).isAfter(moment(curr.create_ts))?prev:curr)
        } else idpStore.actualAssessment = undefined;
        if(idpStore&&idpStore.actualAssessment){
          idpStore.selectedIdp.personAssessment = { id: idpStore.actualAssessment.person_assessment_id }
          this.dataInstance.item!.personAssessment = { id: idpStore.actualAssessment.person_assessment_id }
          const inRange = (date:Moment):boolean => {
            return date.isBetween(moment(idpStore!.actualAssessment!.date_from),moment(idpStore!.actualAssessment!.date_to).add(1,'day'))
          }
          this.setHaveOld(inRange(moment())&&idpStore.actualAssessment.status_code === 'CLOSED'&&inRange(moment(idpStore.actualAssessment.update_ts)))
          this.getParticipantsCount(idpStore.actualAssessment.person_assessment_id);
        }
      }
    }
    getCubaREST()!.searchEntities<CompetenceTemplate>(CompetenceTemplate.NAME, {
      conditions: [{
        property: "code",
        operator: "=",
        value: this.isKeyPos(positionGroup) ? "LDP" : "ИПР_CBA"
      }]
    }).then((ct) => {
      getCubaREST()!.searchEntities<AssessmentTemplate>(AssessmentTemplate.NAME, {
        conditions: [{
          property: "competenceTemplate.id",
          operator: "=",
          value: ct[0].id
        }]
      }).then(at => {
        this.isCreatable = moment().isBetween(moment(at[0].dateFrom),moment(at[0].dateTo).add(1,'day'))
      })
    })
  }
  @observable assessmentFormVisible:boolean=false

  @observable editableFormVisible:boolean=false;

  constructor(props: any) {
    super(props);
    this.props.rootStore!.createIdpStore();
    const idpStore = this.props.rootStore!.idpStore;
    const startDate = new Date(Date.UTC(new Date().getFullYear(), 0, 1))
    const endDate = new Date(Date.UTC(new Date().getFullYear(), 11, 31))
    const error:Promise<boolean> = new Promise(resolve => resolve(false));
    this.validate = ():Promise<boolean> => {
      const store = this.props.rootStore!.idpStore;
      if(store) store.changeValidationStarted(true)
      const errorMessagePG = () => Notification.error({message:this.props.intl.formatMessage({id:"notification.positionGroup"}),duration:3})
      const errorMessage = () =>Notification.error({message:this.props.intl.formatMessage({id:"notification.addCompetence"}),duration:3})
      if(!(store&&store.selectedIdp&&store.selectedIdp.positionGroup)){
        errorMessagePG();
        return error
      }
      if(store&&!store.selectedIdpDetails){
        errorMessage();
        return error
      }
      if(store&&store.selectedIdpDetails&&store.selectedIdpDetails.filter(det=>!det.deleteTs).length===0){
        errorMessage()
        return error
      }
      if(!(rootStore.idpStore!.actualAssessment||this.personAssessment)){
        Notification.error({message:this.props.intl.formatMessage({id:"createNewAssessment.error"}),duration:3})
        return new Promise(resolve => resolve(false));
      }
      if(this.props.rootStore!.userInfo!.personGroupId===this.props.rootStore!.idpStore!.selectedIdp!.personGroup!.id!){
        this.checkForSelfAssessmentValidation()
        if(!rootStore!.idpStore!.selfAssessmentValidation && ((store!.selectedIdp.personGroup&&store!.selectedIdp.personGroup.id)===this.props.rootStore!.userInfo.personGroupId)){
          Notification.error({message:this.props.intl.formatMessage({id:"selfAssessmentValidation.error"}),duration:3})
          runInAction(()=>this.assessmentFormVisible = true)
          return error
        }
      }
      if(!rootStore!.idpStore!.managerValidation && ((store!.selectedIdp.personGroup&&store!.selectedIdp.personGroup.id)!==this.props.rootStore!.userInfo.personGroupId)){
        Notification.error({message:this.props.intl.formatMessage({id:"createNewAssessment.error"}),duration:3})
        runInAction(()=>this.assessmentFormVisible = true)
        return error
      }
      return new Promise(resolve => resolve(idpStore&&idpStore.validateFields()));
    };
      idpStore!.validateIdp = this.validate
    this.updateAndCommit =  (): Promise<void>=> {
        this.updateItemValue();
        this.dataInstance.item = this.props.rootStore!.idpStore!.selectedIdp
        return this.dataInstance.commit();
    }

    getCubaREST()!.searchEntities<IndividualDevelopmentPlan>(IndividualDevelopmentPlan.NAME,{
      conditions:[
        {
          property: "personGroup.id",
          operator: "=",
          value: this.props.rootStore!.userInfo!.personGroupId!
        },{
          property: "startDate",
          operator: ">=",
          value: startDate.toISOString().substr(0,10)
        },{
          property: "endDate",
          operator: "<=",
          value: endDate.toISOString().substr(0,10)
        }]
    },{
      view:"_minimal"
    }).then(value=> value.length).then(count=>{
      if (this.props.entityId === 'new') {
        when(() => !!this.dataInstance.item, () => {
          const planNumber =  moment().year() + "-" + (count+1)
          const data: IndividualDevelopmentPlan = {
            personGroup: {id: this.props.rootStore!.userInfo!.personGroupId!},
            planName: "ИПР " + planNumber,
            planNameEn:"IDP " + planNumber,
            startDate: new Date(),
            endDate: new Date(Date.UTC(new Date().getFullYear(), 11, 31)),
          }
          idpStore!.setSelectedIdp({...this.dataInstance.item!,...data})
          this.showSuccessorProfileModal()
          this.getSuccessorEmoloyee(this.props!.rootStore!.userInfo!.personGroupId!)
        })
      } else {
        getCubaREST()!.searchEntities<IdpDetail>(IdpDetail.NAME, {
          conditions: [{
            property: "idp.id",
            operator: "=",
            value: this.props.entityId
          }]
        }, {view: "idpDetail-full"}).then(res => this.idpDetails = res)
        when(() => !!(this.dataInstance.item && this.idpDetails), () => {
          if(this.props.location.state) {
             const state:any = this.props.location.state
             const {idpMeetingId} = state
            idpStore!.approvingIdpMeetingId = idpMeetingId;
          }
          if(this.dataInstance.item!.idpMeeting&&this.dataInstance.item!.idpMeeting.length===0){
            delete this.dataInstance.item!.idpMeeting;
          }
          idpStore!.setSelectedIdp({...this.dataInstance.item!})
          idpStore!.setSelectedIdpDetails(this.idpDetails)
          const idp = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp
          const personGroupId = idp&&idp.personGroup&&idp.personGroup.id
          if(personGroupId!==this.props.rootStore!.userInfo.personGroupId){
            this.disabledPage = true;
          }
          this.showSuccessorProfileModal(this.dataInstance.item&&this.dataInstance.item.positionGroup&&this.dataInstance.item.positionGroup.id)
          this.getSuccessorEmoloyee(this.dataInstance.item&&this.dataInstance.item.personGroup&&this.dataInstance.item.personGroup.id)
          if(idpStore!.selectedIdp&&idpStore!.selectedIdp.positionGroup){
            getCubaREST()!.searchEntities<CompetenceElement>(CompetenceElement.NAME,{
              conditions:[{
                property:"positionGroup.id",
                operator:"=",
                value:idpStore!.selectedIdp.positionGroup.id!
              }]
            },{view:"competenceElement.full"}).then(elements=>{
              if(this.props.rootStore!.idpStore)
                this.props.rootStore!.idpStore.positionCompetencies = elements.map(e=>e.competenceGroup!)
            })
          }
        })
      }
    })
  }

  getSuccessorEmoloyee = (id:string) => {
    getCubaREST()!.searchEntities<Successor>(Successor.NAME,{
      conditions:[{
        property:"personGroup.id",
        operator:"=",
        value:id
      }, {
        property: "succession.positionType",
        operator: "=",
        value: "INCLUDE"
      },{
        group:"AND",
        conditions:[
          {
            property: "status.code",
            operator: "<>",
            value: "TERMINATED"
          },
          {
            property: "status.code",
            operator: "<>",
            value: "REJECTED"
          },
          {
            property: "status.code",
            operator: "<>",
            value: "OUT_OF_LDP"
          }]}
       ]
    },{
      view: "successor-talentPool",
      sort:"updateTs"
    }).then((item)=>{
      runInAction(()=>{
        const idpStore = this.props.rootStore!.idpStore;
        const idp = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp
        this.successorList = item;
        if(this.props.entityId!=='new'){
          this.handleChange(this.dataInstance.item!.positionGroup!.id)
        }
        restServices.learningService.getPersonAssessments({personGroupId:idpStore!.selectedIdp.personGroup!.id,lang:this.props.rootStore!.userInfo.language!}).then(res=>{
          this.allAssessment = JSON.parse(res[0].value);
          if(res[0]&&res[0].value&&this.props.entityId !== 'new') {
            if (this.allAssessment.length > 0){
              idpStore!.actualAssessment = this.allAssessment.filter((c)=>c.person_assessment_id === (idp&&idp.personAssessment&&idp.personAssessment.id) && c.person_group_id === (idp&&idp.personGroup&&idp.personGroup.id))[0]
            }
         else idpStore!.actualAssessment = this.allAssessment[0];
            if(idpStore!.actualAssessment&&idpStore!.actualAssessment.person_assessment_id)
              this.getParticipantsCount(idpStore!.actualAssessment.person_assessment_id,true)
          }
          if(idpStore&&idpStore.actualAssessment){
            const inRange = (date:Moment):boolean => {
              return date.isBetween(moment(idpStore!.actualAssessment!.date_from),moment(idpStore!.actualAssessment!.date_to).add(1,'day'))
            }
            this.setHaveOld(inRange(moment())&&idpStore.actualAssessment.status_code === 'CLOSED'&&inRange(moment(idpStore.actualAssessment.update_ts)))
          }
        })
      })
      if(item.length > 0){
        runInAction(()=>{
          this.isReservist = true
        })
      }
      getCubaREST()!.searchEntities<CompetenceTemplate>(CompetenceTemplate.NAME, {
        conditions: [{
          property: "code",
          operator: "=",
          value: this.isKeyPos(this.dataInstance.item!.positionGroup!) ? "LDP" : "ИПР_CBA"
        }]
      }).then((ct) => {
        if (ct[0]) {
          getCubaREST()!.searchEntities<AssessmentTemplate>(AssessmentTemplate.NAME, {
            conditions: [{
              property: "competenceTemplate.id",
              operator: "=",
              value: ct[0].id
            }]
          }).then(at => {
            this.isCreatable = moment().isBetween(moment(at[0].dateFrom), moment(at[0].dateTo).add(1, 'day'));
          })
        }
      })
    })
  }

  getHierarchyPositionData = (user:any):Promise<any> =>{
   return  restServices.learningService.getOrganizationHierarchy({
      organizationId:
        user.currentAssignment &&
        user.currentAssignment!.organizationGroup! &&
        user.currentAssignment!.organizationGroup!.id!
    }).then(item=>{
      return this.positionHierarchy = item
   })
  }

  componentDidMount() {
    super.componentDidMount();
    const loadUser = async (id:string) => {
      await getCubaREST()!.loadEntity<PersonGroupExt>(PersonGroupExt.NAME, id, {view: "personGroup-with-position"}).then(user => runInAction(() => {
        this.currentUser = user
        restServices.learningService.getOrganizationHierarchy({
          organizationId:
            user.currentAssignment &&
            user.currentAssignment!.organizationGroup! &&
            user.currentAssignment!.organizationGroup!.id!
        }).then(item=>this.organizationHierarchy = item)
        restServices.learningService.getPositionHierarchy({
          positionId:
            user.currentAssignment &&
            user.currentAssignment!.positionGroup! &&
            user.currentAssignment!.positionGroup!.id!
        }).then(item=>{this.positionHierarchy = item;})
      }))
    }
    if(this.props.entityId==='new'){
      loadUser(this.props.rootStore!.userInfo.personGroupId!)
    }
    else{
      when(()=>!!this.dataInstance.item&&!!this.dataInstance.item.personGroup,() =>{
        loadUser(this.dataInstance.item&&this.dataInstance.item.personGroup&&this.dataInstance.item.personGroup.id)
      } )
    }

  }

  setReactionDisposer = () => {
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      (item) => {
        this.onReactionDisposerEffect(item);
      }
    );
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>()(withRouter(IndividualDevolopmentPlanEditComponent))
);