import * as React from "react";
import Answer from "./Answer";
import {TestComponentProps} from "../Questionnaire/Question";
import {AnsweredQuestion} from "../Test/TestComponent";
import {observable} from "mobx";

export type QuestionModel = {
  id: string,
  text: string,
  type: string,
  // answers?: AnswerModel[]
}

export type AnswerModel = {
  id: string,
  text: string,
  questionId: string
}

export interface TestComponentProps {
  question: QuestionModel

  // checkedAnswers?: []
  // handleCheckboxClick?: (event:any)=>void
  // localStorageValues?:any
}

// export interface TestComponentHandlers {
//   addRemoveAnswer: (a: AnsweredQuestion) => void
// }

class Question extends React.Component<TestComponentProps> {

  @observable checkedAnswers : any = []


  constructor(props:TestComponentProps) {
    super(props);

  }

  handleCheckboxClick = (event: any) => {
    // console.log(event);
    this.checkedAnswers.push(event.target.value);
    // this.props.returnAnswers!(this.checkedAnswers);
  }

  render() {
    return (
      <div className={"question-block"}>
        <div style={{border:"1px solid #E0E0E0", padding:"10px", borderRadius: "5px", marginBottom: "25px"}} className={"question-title"}>{this.props.question.text}</div>
        {/*<div className={"question-options"}>*/}
        {/*  <Answer localStorageValues={this.props.localStorageValues}*/}
        {/*          returnAnswers={(value:any)=>this.props.returnAnswers!(value)}*/}
        {/*          checkedAnswers={this.checkedAnswers}*/}
        {/*          answers={this.props.question.answers}*/}
        {/*          type={this.props.question.type}*/}
        {/*          questionId={this.props.question.id} />*/}
        {/*</div>*/}
      </div>);
  }
  componentDidMount() {
  }
}

export default Question;