import * as React from "react";
import {createElement} from "react";
import {Card, Col, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";

import {collection, getCubaREST, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";
import "../../../app/App.css";

import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {PersonDocument} from "../../../cuba/entities/base/tsadv$PersonDocument";
import moment from "moment";
import {PersonDocumentRequestManagement} from "../PersonDocumentRequest/PersonDocumentRequestManagement";
import {goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import {PersonBankdetailsRequest} from "../../../cuba/entities/base/tsadv_PersonBankdetailsRequest";
import {PersonBankDetails} from "../../../cuba/entities/base/tsadv_PersonBankDetails";
import {Bank} from "../../../cuba/entities/base/base$Bank";

type EditorProps = {
  entityId: string;
  bankDetailsId?: string;
};

@inject("rootStore")
@injectMainStore
@observer
class BankDetailsRequestEditComponent extends AbstractBprocEdit<PersonBankdetailsRequest, EditorProps> {
  processDefinitionKey = "personBankdetailsRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<PersonBankdetailsRequest>(PersonBankdetailsRequest.NAME, {
    view: "personBankDetailsRequest.full",
    loadImmediately: false
  });



  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });
  bankDc = collection<Bank>(Bank.NAME, {
    view: "_minimal"
  });


  @observable
  editBankDetails: PersonBankDetails;

  instanceEditBankDetails = instance<PersonBankDetails>(PersonBankDetails.NAME, {
    view: "personBankDetails.edit",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  approverHrRoleCode: string;

  isUpdateBeforeOutcome = true;

  fields = [

    "requestNumber",

    "requestDate",

    "status",

    "bank",

    "fullNameBankCard",

    "iban",

    "startDateHistory",

    "endDateHistory",

    "attachments"
  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.personGroupId,
      bankDetails: this.editBankDetails ? this.editBankDetails.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {
    if (!this.mainStore) return <LoadingPage/>
    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>
    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();
    return (
      <Page pageName={<FormattedMessage id={"personBankdetails.title"}/> }>
        <Section className='large'>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true
                  }]
                }}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true
                  }]
                }}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true
                  }]
                }}
              />

              <Form.Item
                required={true}
                label={createElement(Msg, {entityName: entityName, propertyName: "personGroup"})}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>

              {
                this.editBankDetails ?
                  <Row type={"flex"} className={"data-form"}>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderBankDetails()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderBankDetailsRequest()}
                    </Col>
                  </Row>
                  : this.renderBankDetailsRequest()
              }

              <ReadonlyField
                entityName={entityName}
                propertyName="attachments"
                disabled={isNotDraft}
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true
                  }]
                }}
              />

            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
    );
  }

  renderBankDetails = () => {

    const entityName = this.dataInstance.entityName;

    const isHr = this.approverHrRoleCode === 'HR';

    return (
      <div>
        <Form.Item
          required={true}
          label={createElement(Msg, {entityName: entityName, propertyName: "bank"})}>
          <Input
            value={this.editBankDetails && this.editBankDetails.bank ? this.editBankDetails.bank['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          required={true}
          label={createElement(Msg, {entityName: entityName, propertyName: "fullNameBankCard"})}>
          <Input
            value={this.editBankDetails ? this.editBankDetails.fullNameBankCard || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          required={true}
          label={createElement(Msg, {entityName: entityName, propertyName: "iban"})}>
          <Input
            value={this.editBankDetails ? this.editBankDetails.iban || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          required={true}
          label={createElement(Msg, {entityName: entityName, propertyName: "startDateHistory"})}>
          <Input
            value={this.editBankDetails ? this.editBankDetails.startDateHistory || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          required={true}
          label={createElement(Msg, {entityName: entityName, propertyName: "endDateHistory"})}>
          <Input
            value={this.editBankDetails ? this.editBankDetails.endDateHistory || '' : ''}
            disabled/>
        </Form.Item>
      </div>
    );
  }

  renderBankDetailsRequest = () => {

    const messages = this.mainStore.messages!;

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    const isHr = this.approverHrRoleCode === 'HR';

    return (
      <div>
        <ReadonlyField
          entityName={entityName}
          propertyName="bank"
          disabled={isNotDraft}
          optionsContainer={this.bankDc}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.bank']})
            }],
            getValueFromEvent: args => {
              if (this.editBankDetails)
                this.changedMap.set('bank', args !== (this.editBankDetails.bank ? this.editBankDetails.bank.id : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('bank'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="fullNameBankCard"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.fullNameBankCard']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editBankDetails)
                this.changedMap.set('fullNameBankCard', value !== this.editBankDetails.fullNameBankCard);
              return value;
            }
          }}

          formItemOpts={{
            hasFeedback: this.changedMap.get('fullNameBankCard'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="iban"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.iban']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editBankDetails)
                this.changedMap.set('iban', value !== this.editBankDetails.iban);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('iban'),
            validateStatus: "success",
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="startDateHistory"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.startDateHistory']})
            }],
            getValueFromEvent: args => {
              if (this.editBankDetails)
                this.changedMap.set('startDateHistory', this.editBankDetails.startDateHistory ? !moment(this.editBankDetails.startDateHistory).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('startDateHistory'),
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="endDateHistory"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.endDateHistory']})
            }],
            getValueFromEvent: args => {
              if (this.editBankDetails)
                this.changedMap.set('endDateHistory', this.editBankDetails.endDateHistory ? !moment(this.editBankDetails.endDateHistory).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('endDateHistory'),
          }}
        />
      </div>
    );
  }

  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: PersonBankdetailsRequest | undefined) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();

    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const bankDetailsId = this.props.bankDetailsId || (item && item.bankDetails ? item.bankDetails.id : undefined)

    if (bankDetailsId)
      getCubaREST()!.loadEntity(PersonBankDetails.NAME, bankDetailsId, {view: 'personBankDetails.edit'})
        .then(value => this.editBankDetails = value as PersonDocument)
        .then(value => {
          this.instanceEditBankDetails.setItem(value);
          const properties = [
            "bank",

            "fullNameBankCard",

            "iban",

            "startDateHistory",

            "endDateHistory",

            "attachments"];
          if (this.props.entityId === PersonDocumentRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditBankDetails.getFieldValues(properties));

          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(BankDetailsRequestEditComponent))
);
