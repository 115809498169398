import React, { Component } from 'react'
import { injectMainStore, MainStoreInjected } from '@cuba-platform/react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
// import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../store';
import { Table } from 'antd';
import { restServices } from '../../../cuba/services';
import { observable } from 'mobx';
import { SerializedEntity } from '@cuba-platform/rest';
import { DocumentsFamiliarization } from '../../../cuba/entities/base/tsadv_AdaptationPlan';
import { saveFile } from '../../util/util';

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp & {
  adaptationPlanId: string;
};

@injectMainStore
@inject("rootStore")
@observer
class MyAdaptationDocsTableComponent extends Component<Props> {

  @observable
  documents: SerializedEntity<DocumentsFamiliarization>[] = [];
  
  @observable
  isLocaleRu = this.props.rootStore!.userInfo.locale === 'ru';

  @observable
  isLocaleKz = this.props.rootStore!.userInfo.locale === 'kz';

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  componentDidMount = async () => {
    const data = await restServices.adaptationPlanRequestService.getDocumentsForReview(this.props.adaptationPlanId!);
    this.documents = data;
  }

  render() {
    return (
      <div>
        <Table
          dataSource={this.documents}
          columns={[{
            title: this.props.intl.formatMessage({id:"myAdaptationDocsTable.name"}),
            key: 'name',
            dataIndex: 'name',
            render: (text: string, record: SerializedEntity<DocumentsFamiliarization>)=>{
              return <a
              href="#"
              target='_blank'
              onClick={async (e) => {
                e.preventDefault();
                const blob = await restServices.fileDownload.download(record.file!.id);
                saveFile(blob, record.file!.name || "docs.txt");
              }}
              download
            >{this.isLocaleRu ? text : this.isLocaleEn ? record.nameEn : record.nameKz}</a>
            }
          },{
            title: this.props.intl.formatMessage({id:"myAdaptationDocsTable.version"}),
            key: 'version',
            dataIndex: 'version',
          },{
            title: this.props.intl.formatMessage({id:"myAdaptationDocsTable.size"}),
            key: 'size',
            dataIndex: 'file.size',
          },{
            title: '',
            key: 'link',
            dataIndex: 'link',
            render: (text: string, record: SerializedEntity<DocumentsFamiliarization>) => {
              return <a
                href="#"
                target='_blank'
                onClick={async (e) => {
                  e.preventDefault();
                  const blob = await restServices.fileDownload.download(record.file!.id);
                  saveFile(blob, record.file!.name || "docs.txt");
                }}
                download
              >Скачать</a>
            }
          },]}
        />
      </div>
    )
  }
}

const MyAdaptationDocsTable = injectIntl(MyAdaptationDocsTableComponent)

export default MyAdaptationDocsTable
