import * as React from "react";
import Section from "../../hoc/Section";
import {Tabs} from "antd";
import Page from "../../hoc/PageContentHoc";
import {MySurChargeRequestManagement} from "../SurChargeRequest/MySurChargeRequestManagement";
import SurChargeRequestList from "../SurChargeRequest/SurChargeRequestList";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
@injectMainStore
@inject("rootStore")
@observer
class MaterialHelpList extends React.Component <MainStoreInjected&WrappedComponentProps&RootStoreProp>{
  render() {
    return (
      <Page pageName={this.props.intl.formatMessage({id:"menu.surCharge"})}>
        <Section size={"large"}>
          <Tabs defaultActiveKey={"Hello"}>
            <Tabs.TabPane tab={this.props.intl.formatMessage({id:"menu.surChargeRequest"})} key={"Hello"}>
              <SurChargeRequestList
                key="list"
                personGroupId={this.props.rootStore!.userInfo.personGroupId!}
              />
            </Tabs.TabPane>
            {/*<Tabs.TabPane tab={this.props.intl.formatMessage({id:"menu.surChargeRequest.paid"})} key={"Second"}>*/}
            {/*  <SurChargeRequestList*/}
            {/*    key="paid"*/}
            {/*    onlyPaidRequests*/}
            {/*    personGroupId={this.props.rootStore!.userInfo.personGroupId!}*/}
            {/*  />*/}
            {/*</Tabs.TabPane>*/}
          </Tabs>
        </Section>
      </Page>
    );
  }
}
export default injectIntl(MaterialHelpList);
