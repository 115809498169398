import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import LearningRequestEdit from "./LearningRequestEdit";

type Props = RouteComponentProps<{ entityId: string }>;

@observer
export class LearningRequestManagement extends React.Component<Props> {
  static PATH = "/learningRequest";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId} = this.props.match.params;
    return (
      <>
        <LearningRequestEdit entityId={entityId}/>
      </>
    );
  }
}
