import {getBasicAuthHeaders} from "@cuba-platform/rest";
import {action, observable} from "mobx";
import {CUBA_APP_URL} from "../../config";
import RootStore from "./RootStore";

export class InitIdpStore {

  @observable
  rootStore?: RootStore;

  @observable
  isSsoLoading: boolean = true;

  @observable
  isSso: boolean = false;


  @action setRootStore = (rootStore: RootStore) => {
    this.rootStore = rootStore
  };

  @action setSso = (value: boolean) => {
    this.isSso = value
  };

  @action
  setSsoLoading = (value: boolean) => {
    this.isSsoLoading = value
  };

  tryToLoginWithIdp = () => {
    return fetch(CUBA_APP_URL.replace('rest/','')+'/dispatch/idp/status/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(value => {
        return value.json()
      }).then(data => {
        if (data.webIdpEnabled && data.resIdpEnabled) {
          this.setSso(true);
          let urlHash = window.location.hash;
          let idpTicketParameter = 'idp_ticket=';
          if (urlHash && urlHash.indexOf(idpTicketParameter) >= 0) {
            // Try to login to CUBA REST-API!
            let idpTicket = urlHash.substring(
              urlHash.indexOf(idpTicketParameter) + idpTicketParameter.length
            );
            return this.loginWithIdp(idpTicket);
          } else {
            // check IDP status
            return this.checkIdpStatus();
          }
        } else {
          return new Promise(resolve => {
            resolve()
          })
        }
      });

  }

  checkIdpStatus = () => {
    // Check if we already logsged in IDP

    const promise = fetch(CUBA_APP_URL + "v2/idp/status", {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      mode: "no-cors"
    }).then((data: any): Promise<any> | void => {
      if (data!.idpTicket) {
        return this.loginWithIdp(data.idpTicket);
      } else {
        window.location.href = CUBA_APP_URL + "v2/idp/login?redirectUrl=" + window.location.href;
      }
    });
    return promise
  }

  loginWithIdp = (idpTicket: string) => {
    // IDP ticket:
    window.history.pushState("", document.title, window.location.pathname);
    let fetchOptions = {
      method: "POST",
      headers: {
        ...getBasicAuthHeaders(
          this.rootStore!.cubaRest.restClientId,
          this.rootStore!.cubaRest.restClientSecret
        ),
        "Content-type": "application/x-www-form-urlencoded"
      },
      body: "idp_ticket=" + idpTicket
    };
    return fetch(
      CUBA_APP_URL + "v2/idp/token",
      fetchOptions
    ).then(value => value.json()).then(e => {
      localStorage.setItem("kzm_cubaAccessToken", e.access_token);
      localStorage.setItem("idp_ticket", idpTicket);
      localStorage.setItem("kzm_cubaLocale", e.locale);
      this.setSsoLoading(false);
      window.location.href = window.location.pathname + "#/"
    });
  }

  logout = () => {
    let fetchOptions = {
      method: "POST",
      headers: {
        ...getBasicAuthHeaders(
          this.rootStore!.cubaRest.restClientId,
          this.rootStore!.cubaRest.restClientSecret
        ),
        "Content-type": "application/x-www-form-urlencoded"
      },
      body: "token=" + localStorage.getItem("kzm_cubaAccessToken")
    };
    return fetch(CUBA_APP_URL + "v2/oauth/revoke", fetchOptions)
      .then(value => value.json()).then(data => {
        if (data.location) {
          localStorage.removeItem("kzm_cubaAccessToken");
          localStorage.removeItem("idp_ticket");
          this.setSsoLoading(true);
          window.location.href = data.location;
        }
      });
  }

}

export const initIdpStore = new InitIdpStore();