import * as React from "react";
import {SplitPane} from "react-multi-split-pane/dist";
import Search from "antd/es/input/Search";
import {PositionDetails} from "./PositionDetails/PositionDetails";
import {observable} from "mobx";
import {observer} from "mobx-react";
import PositionHierarchyTree from "./PositionHierarchyTreeNew";
import {restServices} from "../../../cuba/services";

@observer
export class PositionHierarchyComponent extends React.Component {

  @observable
  selectedPosition: string

  @observable mainSplitPaneDefaultSizes?: number[];

  @observable
  defaultId: string
  changeSelectedPosition = (positionId: string): void => {
    this.selectedPosition = positionId
  }

  getDefaultId = (defaultId:any): void  =>{
    this.defaultId = defaultId
  }


  render() {
    return <div style={{height: "100%", position: 'relative'}}>
      <SplitPane split="vertical" className={"myTeam-vertical"}
                 defaultSizes={this.mainSplitPaneDefaultSizes}
                 onDragFinished={sizes => this.saveDefaultSettings(sizes)}>
        <div style={{height: "100%", width: "100%"}}>

            <PositionHierarchyTree getDefaultId={this.getDefaultId} changeSelectedPosition={this.changeSelectedPosition}/>


        </div>
        <div style={{height: '100%', width: '100%',}} className={"myTeam-detail"}>
          <PositionDetails defaulId={this.defaultId} selectedPosition={this.selectedPosition}/>
        </div>
      </SplitPane>
    </div>;
  }
  loadDefaultSettings = () => {
    restServices.userSettingService.loadSetting<number[]>('PositionHierarchyComponent.mainSplitPane')
      .then(value => {
        if (value) this.mainSplitPaneDefaultSizes = value;
      });
  }

  saveDefaultSettings = (sizes: number[]) => {
    restServices.userSettingService.saveSetting('PositionHierarchyComponent.mainSplitPane', '[' + sizes + ']');
  }
  componentDidMount() {
    this.loadDefaultSettings();
  }
}
