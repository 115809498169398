import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import BankDetailsRequestEdit from "./BankDetailsRequestEdit";

type Props = RouteComponentProps<{ entityId: string, bankDetailsId?: string }>;

@observer
export class BankDetailsRequestManagement extends React.Component<Props> {

  static PATH = "/personBankDetailsRequest";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId, bankDetailsId} = this.props.match.params;
    return (
      <>
        <BankDetailsRequestEdit entityId={entityId} bankDetailsId={bankDetailsId}/>
      </>
    );
  }
}
