import * as React from "react";
import {observer} from "mobx-react";


import WccfManagerPage from "./WccfManagementPage";


@observer
class WccfMenuPage extends React.Component {
  static PATH = "/wccf-request-page"
  render() {
    return (
      <>
        <WccfManagerPage/>
      </>
    );
  }
}



export default WccfMenuPage;