import React from "react";
import {Col, Row} from "antd";
import {injectIntl, WrappedComponentProps} from "react-intl";

type CoinsComponentProps = {
  value: number
}

class CoinsComponent extends React.Component<CoinsComponentProps & WrappedComponentProps> {

  render() {
    return <Row type={"flex"}>
      <Col style={{width: "20px"}}>
        <img src={require("../../../../resources/icons/recognition/coins.png")} style={{width: "20px", height: "20px"}}/>
      </Col>
      <Col style={{paddingLeft: "5px"}}>
        <div style={{textAlign: "center"}}>
          <strong style={{fontSize: "18px"}}>{this.props.value + " "}</strong>
          {this.props.intl.messages["coins"]}
        </div>
      </Col>
    </Row>
  }
}

export const CoinsComponentWithoutInjectIntl = CoinsComponent;

export default injectIntl(CoinsComponent);