import {AbstractParentEntity} from "./AbstractParentEntity";
import {DicRequestStatus} from "./tsadv$DicRequestStatus";
import {DicDocumentsFamiliarizationType} from "./DicDocumentsFamiliarizationType";
import {FileDescriptor} from "./sys$FileDescriptor";
import {DicCompany} from "./base_DicCompany";

export class DocumentsFamiliarization extends AbstractParentEntity {

  static NAME = "tsadv_DocumentsFamiliarization";

  name: string;
  type: DicDocumentsFamiliarizationType;
  file: FileDescriptor;
  company: DicCompany;
  dateFrom: any;
  dateTo: any;
  description?: string;

}
