import * as React from "react";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {CourseScheduleEnrollment} from "../../../cuba/entities/base/tsadv_CourseScheduleEnrollment";
import DataTableFormat from "../../components/DataTable/intex";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {Modal, Table} from "antd";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {restServices} from "../../../cuba/services";
import {inject, observer} from "mobx-react";
import {RootStoreProp} from "../../store";
import {action, observable} from "mobx";
import moment from "moment";
import CourseScheduleMonthTable from "./CourseScheduleMonth";

@inject("rootStore")
@injectMainStore
@observer
class CourseScheduleList extends React.Component<MainStoreInjected & RootStoreProp & WrappedComponentProps> {
  @observable data: any;
  @observable isVisible = false;
  @observable selectedCourse: string;
  @action setSelectedCourse = (id: string) => {
    this.selectedCourse = id;
  }
  @action toggleVisibility = () => {
    this.isVisible = !this.isVisible
  }
  makeRequest = async (scheduleId: string) => {
    await restServices.learningService.createScheduleEnrollment({
      personGroupId: this.props.rootStore!.userInfo.personGroupId!,
      scheduleId: scheduleId,
      status: 1,
      user: this.props.rootStore!.userInfo.login!
    })
    await restServices.learningService.getPersonCourseSchedule({personGroupId: this.props.rootStore!.userInfo.personGroupId!}).then(res => {
      this.dataObj = {};
      this.data = res.filter(item=>moment(item.start_date).isSameOrAfter(moment(), "month")).sort((a, b) => moment(a.start_date).valueOf() - moment(b.start_date).valueOf());

    })
  }

  componentDidMount() {
    restServices.learningService.getPersonCourseSchedule({personGroupId: this.props.rootStore!.userInfo.personGroupId!}).then(res => {
      this.dataObj = {};
      this.data = res.filter(item=>moment(item.start_date).isSameOrAfter(moment(), "month")).sort((a, b) => moment(a.start_date).valueOf() - moment(b.start_date).valueOf());

    })
  }


  @observable dataObj: any = {};

  render() {
    const newData={}
    this.data && this.data.forEach((item: any) => {
      const month = (moment(item.start_date).format("M"))+" "+(moment(item.start_date).format("YYYY"));
      if (!newData[month]) {
        newData[month] = [item];
      } else {
        newData[month].push(item);
      }
    console.log(newData)
    })
    console.log(this.dataObj)
    return (
      <Page pageName={this.props.intl.formatMessage({id: "menu.courseSchedule"})}>
        <Section size={"large"}>
          {Object.keys(newData).map((item: any) => {
            return <CourseScheduleMonthTable month={item}  key={Math.random()}  setSelectedCourse={this.setSelectedCourse}
                                             toggleVisibility={this.toggleVisibility} data={newData[item]}/>
          })}
          <Modal centered={true} onCancel={() => {
            this.isVisible = false;

          }} maskClosable={true}
                 children={<span style={{fontSize:18}}>{this.props.intl.formatMessage({id: "sendRequestSure"})}</span>} onOk={() => {

            this.makeRequest(this.selectedCourse);
            this.isVisible = false
          }} visible={this.isVisible}/>
        </Section>
      </Page>
    );
  }
}

export default injectIntl(CourseScheduleList);