import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { DicCompany } from "./base_DicCompany";
import { DicCurrency } from "./base$DicCurrency";
import { LearningBudgetRequest } from "./tsadv_LearningBudgetRequest";
import { LearningBudgetAttachment } from "./tsadv_LearningBudgetAttachment";
import { LearningBudgetApprover } from "./tsadv_LearningBudgetApprover";
export class LearningBudget extends AbstractBprocRequest {
  static NAME = "tsadv_LearningBudget";
  nameRus?: string | null;
  nameKz?: string | null;
  nameEng?: string | null;
  company?: DicCompany | null;
  currency?: DicCurrency | null;
  currencyExchangeRate?: any | null;
  budgetRequests?: LearningBudgetRequest[] | null;
  budgetStartDate?: any | null;
  budgetEndDate?: any | null;
  cost?: any | null;
  attachments?: LearningBudgetAttachment[] | null;
  budgetApprovers?: LearningBudgetApprover[] | null;
}
export type LearningBudgetViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "learningBudget-approvment-front-view"
  | "learningBudget-approvment-view"
  | "learningBudget-detail-report-view"
  | "learningBudget-front-view";
export type LearningBudgetView<
  V extends LearningBudgetViewName
> = V extends "_base"
  ? Pick<
      LearningBudget,
      | "id"
      | "nameRus"
      | "nameKz"
      | "nameEng"
      | "currencyExchangeRate"
      | "budgetStartDate"
      | "budgetEndDate"
      | "cost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      LearningBudget,
      | "id"
      | "nameRus"
      | "nameKz"
      | "nameEng"
      | "currencyExchangeRate"
      | "budgetStartDate"
      | "budgetEndDate"
      | "cost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<LearningBudget, "id" | "nameRus">
  : V extends "learningBudget-approvment-front-view"
  ? Pick<
      LearningBudget,
      | "id"
      | "nameRus"
      | "nameKz"
      | "nameEng"
      | "currencyExchangeRate"
      | "budgetStartDate"
      | "budgetEndDate"
      | "cost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "currency"
      | "budgetRequests"
      | "attachments"
      | "status"
    >
  : V extends "learningBudget-approvment-view"
  ? Pick<
      LearningBudget,
      | "id"
      | "nameRus"
      | "nameKz"
      | "nameEng"
      | "currencyExchangeRate"
      | "budgetStartDate"
      | "budgetEndDate"
      | "cost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "currency"
      | "budgetRequests"
    >
  : V extends "learningBudget-detail-report-view"
  ? Pick<
      LearningBudget,
      | "id"
      | "nameRus"
      | "nameKz"
      | "nameEng"
      | "currencyExchangeRate"
      | "budgetStartDate"
      | "budgetEndDate"
      | "cost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "company"
      | "currency"
      | "status"
      | "budgetRequests"
    >
  : V extends "learningBudget-front-view"
  ? Pick<
      LearningBudget,
      | "id"
      | "nameRus"
      | "nameKz"
      | "nameEng"
      | "currencyExchangeRate"
      | "budgetStartDate"
      | "budgetEndDate"
      | "cost"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "company"
      | "currency"
      | "status"
    >
  : never;
