import * as React from "react";
import {createElement} from "react";
import {inject, observer} from "mobx-react";

import {collection, injectMainStore, MainStoreInjected, Msg, withLocalizedForm} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {PersonProfile} from "../../MyTeamCard";
import {Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row} from "antd";
import {PersonExt} from "../../../../../cuba/entities/base/base$PersonExt";
import {FormComponentProps} from "antd/lib/form";
import moment from "moment";
import {DEFAULT_DATE_PATTERN} from "../../../../util/Date/Date";
import {Successor} from "../../../../../cuba/entities/base/tsadv$Successor";
import {SuccessionPlanning} from "../../../../../cuba/entities/base/tsadv$SuccessionPlanning";
import {getPersonByPersonGroup} from "../../../../util/personUtils";
import {runReport} from "../../../../util/reportUtil";
import {observable} from "mobx";

export  type  PersonCardProps = {
  person?: PersonProfile
}

@injectMainStore
@inject("rootStore")
@observer
class MyTeamPersonCard extends React.Component<PersonCardProps & MainStoreInjected & WrappedComponentProps & FormComponentProps> {

  person = this.props.person;

  successorDc = collection<Successor>(Successor.NAME,{
    view:"_minimal",
    filter:{
      conditions:[{
        property:"personGroup.id",
        operator:"=",
        value:this.props.person! && this.props.person!.groupId
      }]
    },
    sort:"-updateTs"
  })


  successionPlaningDc = collection<SuccessionPlanning>(SuccessionPlanning.NAME,{
    view:"keyPosition-short",
    filter:{
      conditions:[{
        property:"positionGroup.id",
        operator:"=",
        value:this.props.person! && this.props.person!.positionGroupId
      }]
    },
    sort:"-updateTs"
  })

  loadPersonInfo = (localeProp:string) => {

    let data = {}
    let locale:string = localeProp === "en"?"PROFILE_TD_EN":"PROFILE_TD_RU"
    data = {
      parameters: [{
        name: "person",
        value: this.props.person! && this.props.person!.id
      }]
    }
    runReport(locale, data, this.props.intl).then(()=>{

    })
  }
  info = ()=> {
    Modal.info({
      title: this.props.intl.formatMessage({id:"myTeam-personData.choosen"}),
      content: (
        <div>
         <Button style={{marginRight:"14px"}}  shape="round" icon="download" onClick={()=>this.loadPersonInfo("ru")}>
           Ru
         </Button>
          <Button  shape="round" icon="download" onClick={()=>this.loadPersonInfo("en")}>
            En
          </Button>
        </div>
      ),
      onOk() {},
    });
  }

  render() {
    const isReservist:boolean = this.successorDc.items && this.successorDc.items.length > 0

    const isHolder:boolean = this.successionPlaningDc.items && this.successionPlaningDc.items.length > 0


    return (
      <div>
        <Form>
          <Row type={"flex"} className={"data-form"}>
            <Col md={18} lg={9} style={{marginRight: '30px'}}>
              <Form.Item style={{marginBottom: "12px"}}
                         label={createElement(Msg, {entityName: PersonExt.NAME, propertyName: "fullName"})}>
                {this.props.form.getFieldDecorator("fullName",
                  {
                    initialValue: this.person ? this.person.fullName : ""
                  })(
                  <Input disabled/>
                )}
              </Form.Item>

              <Form.Item style={{marginBottom: "12px"}}
                         label={createElement(Msg, {entityName: PersonExt.NAME, propertyName: "dateOfBirth"})}>
                {this.props.form.getFieldDecorator("birthDate",
                  {
                    initialValue: this.person ? moment(this.person.birthDate) : null
                  })(
                  <DatePicker format={DEFAULT_DATE_PATTERN} disabled/>
                )}
              </Form.Item>

              <Form.Item style={{marginBottom: "12px"}}
                         label={createElement(Msg, {entityName: PersonExt.NAME, propertyName: "sex"})}>
                {this.props.form.getFieldDecorator("sex",
                  {
                    initialValue: this.person ? this.person.sex : null
                  })(
                  <Input disabled/>
                )}
              </Form.Item>

            </Col>
            <Col md={18} lg={9}>
              <Form.Item style={{marginBottom: "12px"}}
                         label={createElement(Msg, {entityName: PersonExt.NAME, propertyName: "citizenship"})}>
                {this.props.form.getFieldDecorator("citizenship",
                  {
                    initialValue: this.person ? this.person.citizenship : ""
                  })(
                  <Input disabled/>
                )}
              </Form.Item>

              <Form.Item style={{marginBottom: "12px"}}
                         label={<FormattedMessage id={'cityOfResidence'}/>}>
                {this.props.form.getFieldDecorator("cityOfResidence",
                  {
                    initialValue: this.person ? this.person.cityOfResidence : null
                  })(
                  <Input disabled/>
                )}
              </Form.Item>

              <Form.Item style={{marginBottom: "12px"}}
                         label={createElement(Msg, {entityName: PersonExt.NAME, propertyName: "hireDate"})}>
                {this.props.form.getFieldDecorator("hireDate",
                  {
                    initialValue: this.person ? moment(this.person.hireDate) : null
                  })(
                  <DatePicker format={DEFAULT_DATE_PATTERN} disabled/>
                )}
              </Form.Item>

            </Col>
            <Col  md={18} lg={9} style={{marginRight: '30px'}}>
              <Form.Item >
              <div style={{display:"flex"}}>
                <span style={{marginRight:"12px"}}>
                   <FormattedMessage  id={"myTeam-personData.successor"}/>
                </span>

                <Checkbox  className={isReservist?"myTeam-personData__checkbox":""} disabled={true} checked={isReservist}  />
              </div>
              </Form.Item>
              <Form.Item >
              <div style={{display:"flex"}}>
                <span style={{marginRight:"12px"}}>
                <FormattedMessage id={"myTeam-personData.holder"}/>
                </span>
                <Checkbox className={isHolder?"myTeam-personData__checkbox":""} disabled={true} checked={isHolder}   />
              </div>
              </Form.Item>
            </Col>
            <Col md={18} lg={9}>
              <Form.Item >
                <Button
                  onClick={this.info}
                  style={{background:"green",color:"#fff"}}>
                  <FormattedMessage id={"myTeam-personData.profile"}/>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}



export default injectIntl(
  withLocalizedForm({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(MyTeamPersonCard)
);