import * as React from "react";
import DataTableFormat from "../../components/DataTable/intex";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import {MyTeamCardProps} from "../MyTeam/MyTeamCard";
import {PunishmentRequest} from "../../../cuba/entities/base/tsadv$PunishmentRequest";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import {PunishmentAssignmentRequestManagement} from "../MyTeam/assignment/punishmentAssignmentRequest/PunishmentAssignmentRequestManagement";
import {Button} from "antd";
import {PunishmentRemovalRequestManagement} from "../MyTeam/assignment/punishmentRemovalRequest/punishmentRemovalRequestManagement";
import {PunishmentRequestType} from "../../../cuba/enums/enums";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {RootStoreProp} from "../../store";
import {ExtTaskData} from "../../../cuba/entities/base/tsadv_ExtTaskData";
import {ProcessInstanceData} from "../../../cuba/entities/base/bproc_ProcessInstanceData";

@injectMainStore
@inject("rootStore")
@observer
class PunishmentRequestListAll extends React.Component<RootStoreProp & MainStoreInjected & WrappedComponentProps & MyTeamCardProps> {
  dataCollection = collection<PunishmentRequest>(PunishmentRequest.NAME, {
    view: "punishmentRequest-view",
    filter: {
      conditions: [{property: "requestType", operator: "=", value: PunishmentRequestType.ASSIGNMENT},
        {property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!}      ]
    },
    sort: "-updateTs",
    limit: 20
  });


  fields = [
    "requestNumber",
    "requestDate",
    "status",
    "personGroup"
  ];

  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
      <Link
        to={
          PunishmentAssignmentRequestManagement.PATH + "/" + PunishmentAssignmentRequestManagement.NEW_SUBPATH
        }
        key="create">
        <Button
          htmlType="button"
          style={{margin: "0 12px 12px 0"}}
          type="primary"
        >
          <span>
            <FormattedMessage id="diCreate"/>
          </span>
        </Button>
      </Link>
    ];

    return <Page pageName={this.props.intl.formatMessage({id:"punishCreateTitle"})}><Section size={"large"}><DataTableFormat
      dataCollection={this.dataCollection}
      fields={this.fields}
      hideSelectionColumn={true}
      buttons={buttons}
      render={[
        {
          column: 'requestNumber',
          render: (text, record) => (
              <Link to={PunishmentAssignmentRequestManagement.PATH + "/" + record.id}>{text}</Link>
            )
        }
      ]}
    /></Section></Page>
  }
}

export default injectIntl(PunishmentRequestListAll)
