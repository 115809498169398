import { AbstractParentEntity } from "./AbstractParentEntity";
import { Assessment } from "./tsadv$Assessment";
import { CompetenceElementIndicator } from "./tsadv_CompetenceElementIndicator";
import { ScaleLevel } from "./tsadv$ScaleLevel";
export class AssessmentDetail extends AbstractParentEntity {
  static NAME = "tsadv_AssessmentDetail";
  assessmentCompetence?: Assessment | null;
  indicator?: CompetenceElementIndicator | null;
  scaleLevel?: ScaleLevel | null;
}
export type AssessmentDetailViewName = "_base" | "_local" | "_minimal";
export type AssessmentDetailView<
  V extends AssessmentDetailViewName
> = V extends "_base"
  ? Pick<
      AssessmentDetail,
      "id" | "legacyId" | "organizationBin" | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      AssessmentDetail,
      "id" | "legacyId" | "organizationBin" | "integrationUserLogin"
    >
  : never;
