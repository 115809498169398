import * as React from "react";
import { inject, observer } from "mobx-react";

import {
  getCubaREST,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import { Card, List, Tabs } from "antd";
import Meta from "antd/lib/card/Meta";
import { observable } from "mobx";
import { restServices } from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";
import MyTeamPersonCard from "./personalData/MyTeamPersonCard/MyTeamPersonCard";
import MyTeamAbsence from "./timeManagement/MyTeamAbsence/MyTeamAbsence";
import MyTeamPersonRvd from "./timeManagement/rvd/MyTeamPersonRvd/MyTeamPersonRvd";
import PositionOverlappingRequestListComponent from "../PositionOverlappingRequest/PositionOverlappingRequestList";
// import CurrentSchedule from "./shiftSchedules/MyTeamCurrentSchedule/CurrentSchedule";
import AbsenceRvdRequestList from "./timeManagement/rvd/MyTeamPersonRvdRequest/AbsenceRvdRequestList";
import { rootStore, RootStoreProp } from "../../store";
import AssignmentScheduleStandard from "./AssignmentScheduleStandard";
import MyTeamScheduleOffsetRequestList from "./MyTeamScheduleOffsetRequestList";
import MyTeamAbsenceRequest from "./timeManagement/MyTeamAbsenceRequest/MyTeamAbsenceRequest";
import { MyTeamData } from "./MyTeamComponent";
import PunishmentRequestList from "./assignment/PunishmentRequestList";
import PunishmentList from "./assignment/punishment/PunishmentList";
import { PersonContact } from "../../../cuba/entities/base/tsadv$PersonContact";
import { MyTeamSurChargeRequestManagement } from "../SurChargeRequest/MyTeamSurChargeManagment";
import { PaidSurChargeListManagment } from "../SurChargeRequest/PaidSurChargeList";
import { AdaptationPlanManagement } from "../AdaptationPlan/Managment/AdaptationPlanManagement";
import CompetencyBasedAssessment from "./CompetencyBasedAssessment/CompetencyBasedAssessment";
import ScoreCardTabs from "./CompetencyBasedAssessment/ScoreCard/ScoreCardTabs";
import RequestHistoryPage from "../AllRequests/RequestHistoryPage";
import AbsenceBalanceList from "../AbsenceBalanceList/AbsenceBalanceList";
import PersonAwards from "../RecognitionProgram/Components/PersonAwards";

const { TabPane } = Tabs;

export type PersonProfile = {
  id: string;
  groupId: string;
  positionGroupId: string;
  organizationGroupId: string;
  assignmentGroupId: string;
  positionId: string;
  fullName: string;
  firstLastName: string;
  hireDate?: any;
  birthDate?: any;
  sex?: string;
  cityOfResidence?: string;
  citizenship?: string;
  nationality?: string;
  imageId?: string;
  organizationName?: string;
  positionName?: string;
  email?: string;
  telegram?: string;
  whatsApp?: string;
  phone?: string;
  companyCode?: string;
  managerList?: Array<{
    fullName: string;
  }>;
  costCenter?: string;
};
export type MyTeamCardProps = {
  personGroupId: string;
  selectedData?: MyTeamData;
  selectedTab?: string;
  selectedLeftMenu?: string;
  onChangeSelectedInfo?: (
    selectedData?: MyTeamData,
    selectedTab?: string,
    selectedLeftMenu?: string
  ) => void;
};

export type Menu = {
  id: string;
  caption?: string;
};

@injectMainStore
@inject("rootStore")
@observer
class MyTeamCard extends React.Component<
  MyTeamCardProps & MainStoreInjected & WrappedComponentProps & RootStoreProp
> {
  @observable person?: PersonProfile;
  @observable urlImg?: string;
  @observable type: Array<PersonContact>;
  @observable selectedTab: string = this.props.selectedTab || "personalData";
  @observable selectedLeftMenu: string =
    this.props.selectedLeftMenu || "personalData";

  callSetSelectedTabOrLeftMenu = () => {
    if (this.props.onChangeSelectedInfo)
      this.props.onChangeSelectedInfo(
        this.props.selectedData,
        this.selectedTab,
        this.selectedLeftMenu
      );
  };

  renderContent = (): React.ReactNode => {
    if (!this.person) return <></>;
    switch (this.selectedLeftMenu) {
      case "personalData":
        return <MyTeamPersonCard person={this.person} />;
      case "menu.surCharge":
        return (
          <MyTeamSurChargeRequestManagement
            personGroupId={this.person!.groupId}
          />
        );
      case "menu.surChargeRequest.paid":
        return (
          <PaidSurChargeListManagment personGroupId={this.person!.groupId} />
        );
      case "absence":
        return <MyTeamAbsence personGroupId={this.person!.groupId} />;
      // case 'absenceRequest':
      //   return <MyTeamAbsenceRequest personGroupId={this.person!.groupId}/>
      case "workOnWeekend":
        return <MyTeamPersonRvd personGroupId={this.person!.groupId} />;
      // case 'workOnWeekendRequest':
      //   return <AbsenceRvdRequestList personGroupId={this.person!.groupId}/>
      case "scheduleStandard":
        return (
          <AssignmentScheduleStandard personGroupId={this.person!.groupId} />
        );
      // case 'scheduleOffsetRequest':
      //   return <MyTeamScheduleOffsetRequestList personGroupId={this.person!.groupId}/>
      case "positionOverlappingRequest":
        return <PositionOverlappingRequestListComponent person={this.person} />;
      case "punishmentRequest":
        return <PunishmentRequestList personGroupId={this.person!.groupId} />;
      case "punishment":
        return <PunishmentList personGroupId={this.person!.groupId} />;
      case "cba":
        return (
          <CompetencyBasedAssessment personGroupId={this.person!.groupId} />
        );
      case "scoreCard":
        return <ScoreCardTabs personGroupId={this.person!.groupId} />;
      case "tsadv_AdaptationPlan":
        return (
          <AdaptationPlanManagement personGroupId={this.props.personGroupId} />
        );
      case 'requestHistory':
        return <RequestHistoryPage personId={this.props.personGroupId} />
      case 'absenceBalance':
        return <AbsenceBalanceList personGroupId={this.person!.groupId} />
      case 'awards':
        return <PersonAwards personGroupId={this.person!.groupId}/>
    }
    return <div>Here is {this.selectedLeftMenu}</div>;
  };

  getTabs = (): Menu[] => {
    return [
      {
        id: 'personalData'
      },
      {
        id: 'timeManagement'
      },
      {
        id: 'positionOverlappingRequest'
      },
      {
        id: "talentProfile"
      },
      {
        id: "recognition"
      }
    ];
  };

  getLeftMenu = (): Menu[] => {
    switch (this.selectedTab) {
      case 'personalData':
        return[
          { id: 'personalData' },
          { id: 'tsadv_AdaptationPlan' },
          { id: 'punishment'},
          { id: 'menu.surCharge'},
          { id: 'requestHistory'},
        ]
      case 'timeManagement':
        return [
          { id: 'absence' },
          { id: "workOnWeekend" },
          { id: "scheduleStandard" },
          { id: 'absenceBalance'}
        ];
      case 'positionOverlappingRequest':
        return [{id: 'positionOverlappingRequest'}]

      case 'talentProfile':
        return [{
          id:'cba'
        },{
          id:"scoreCard"
        }]
      case 'competencyBasedAssessment':
        return [{
          id:'cba'
        },{
          id:"scoreCard"
        }]
      case 'recognition':
        return [{
          id: 'awards'
        }]
    }
    return [
      { id: 'personalData' },
    ];
  }

  render() {
    const arr = this.type && this.type!.map(item => item);
    if (!this.person) return <></>;

    return (
      <div style={{ height: "100%", overflowY: "auto", display: "flex"}}>
        <div
          style={{

            marginTop: "40px",
            marginLeft:"16px",
            marginRight: "10px",
            width: "200px"
          }}
        >
          <Card

            hoverable
            style={{boxShadow:"0 3px 24px rgb(0 0 0 / 10%)",    height: "fit-content", borderRadius:4, padding:5}}
            cover={
              <img
                style={{borderRadius:"50%"}}
                alt="example"
                src={
                  this.urlImg
                    ? this.urlImg
                    : require("../../../resources/img/my-team-person-logo.jpg")
                }
              />
            }
          >
            <span
              className={
                "ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal"
              }
              title={this.person.fullName}
            >
              <Meta
                title={
                  <div style={{ fontSize: 10, marginTop: "10px" }}>
                    {this.person.firstLastName}
                  </div>
                }
              />
            </span>
            <span
              className={
                "ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal"
              }
              title={this.person.organizationName || ""}
            >
              <Meta
                title={
                  <span style={{ fontSize: 10 , color:"rgba(0,0,0,.65)"}}>
                    {this.person.organizationName || ""}
                  </span>
                }
              />
            </span>
            <span
              className={
                "ant-tree-node-content-wrapper ant-tree-node-content-wrapper-normal"
              }
              title={this.person.positionName || ""}
            >
              <Meta
                title={
                  <span style={{ fontSize: 10,color:"rgba(0,0,0,.65)" }}>
                    {this.person.positionName || ""}
                  </span>
                }
              />
            </span>
            {typeof arr != "undefined" && arr != null && arr.length > 0 ? (
              arr &&
              arr
                .filter(
                  item =>
                    item!.type!.code === "email" ||
                    item!.type!.code === "person email"
                )
                .map(item => (
                  <Meta
                    title={
                      <span style={{ fontSize: 10 }}>
                        <span>e-mail: </span>
                        <a style={{color:"rgba(0,0,0,.65)"}} href={"mailto:" + item.contactValue}>
                          {item.contactValue}
                        </a>
                      </span>
                    }
                  />
                ))
            ) : (
              <Meta
                title={
                  <span style={{ fontSize: 10 }}>
                    <span>e-mail: </span>
                    <a style={{color:"rgba(0,0,0,.65)"}} href={"mailto:"}></a>
                  </span>
                }
              />
            )}
            {typeof arr != "undefined" && arr != null && arr.length > 0 ? (
              arr &&
              arr
                .filter(item => {
                  return (
                    item!.type!.code === "M" ||
                    item!.type!.code === "W1" ||
                    item!.type!.code === "H1" ||
                    item!.type!.code === "mobile"
                  );
                })
                .map(item => (
                  <Meta
                    title={
                      <span style={{ fontSize: 9 }}>
                        {" "}
                        <FormattedMessage id={"personInfo.phone"} />{" "}
                        {": " + item.contactValue}
                      </span>
                    }
                  />
                ))
            ) : (
              <Meta title={<span style={{ fontSize: 9 }}>{"тел: "}</span>} />
            )}
          </Card>
          <List style={{ fontSize: "smaller" , boxShadow:"0 3px 24px rgb(0 0 0 / 10%)",    height: "fit-content", borderRadius:4 }}>
            {this.getLeftMenu().map((menu: Menu) => (
              <List.Item
                style={
                  this.selectedLeftMenu === menu.id
                    ? {
                        backgroundColor: "#bae7ff",
                        marginTop: "10px",
                        padding: "10px"
                      }
                    : { marginTop: "10px", padding: "10px" }
                }
                key={menu.id}
                onClick={() => {
                  this.selectedLeftMenu = menu.id;
                  this.callSetSelectedTabOrLeftMenu();
                }}
              >
                <FormattedMessage id={menu.id} />
              </List.Item>
            ))}
          </List>
        </div>
        <div  style={{ whiteSpace: "nowrap",overflowX: "auto" , boxShadow:"0 3px 24px rgb(0 0 0 / 10%)",    height: "fit-content",
          marginTop: 40, borderRadius:4,padding: "15px 15px 15px 15px" ,marginRight:15}}>
          <Tabs
            defaultActiveKey={this.selectedTab}
            onChange={activeKey => {
              this.selectedTab = activeKey;
              this.selectedLeftMenu = this.getLeftMenu()[0].id;
              this.callSetSelectedTabOrLeftMenu();
            }}
          >
            {this.getTabs().map(tabInfo => (
             <TabPane
                tab={<FormattedMessage id={tabInfo.id} />}
                key={tabInfo.id}
              ></TabPane>
            ))}
          </Tabs>
          <div style={{ display: "flow-root" }}>{this.renderContent()}</div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    restServices.employeeService
      .personProfile(this.props.personGroupId)
      .then(value => {
        this.person = value;
        getCubaREST()!
          .searchEntities<PersonContact>(
            PersonContact.NAME,
            {
              conditions: [
                {
                  property: "personGroup.id",
                  operator: "=",
                  value: this.person.groupId
                }
              ]
            },
            { view: "personContact.full" }
          )
          .then(value => (this.type = value))
          .catch(() => {});
        if (this.person && this.person.imageId)
          getCubaREST()!
            .getFile(this.person.imageId)
            .then((value: Blob) => (this.urlImg = URL.createObjectURL(value)));
      })
      .catch(() => {
        Notification.error({
          message: this.props.intl.formatMessage({
            id: "management.editor.error"
          })
        });
      });
  }
}

export default injectIntl(MyTeamCard);
