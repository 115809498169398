import * as React from "react";
import {inject, observer} from "mobx-react";

import {observable, reaction} from "mobx";
import Notification from "../../util/Notification/Notification";
import {collection, DataCollectionStore, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Card} from "antd";
import Button from "../../components/Button/Button";
import {RootStoreProp} from "../../store";
import {withRouter} from "react-router";
import {Link, RouteComponentProps} from "react-router-dom";
import DefaultDropdown, {MenuRaw} from "../../components/Dropdown/DefaultDropdown";
import {SerializedEntity} from "@cuba-platform/rest";
import {DicCompany} from "../../../cuba/entities/base/base_DicCompany";
import DefaultDatePicker from "../../components/Datepicker";
import {NominationPerson} from "../../../cuba/entities/base/tsadv_NominationPerson";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {PositionGroupExt} from "../../../cuba/entities/base/base$PositionGroupExt";
import moment, {Moment} from "moment";
import {Nomination} from "../../../cuba/entities/base/tsadv_Nomination";
import {PersonMedal} from "../../../cuba/entities/base/tsadv$PersonMedal";
import {Medal} from "../../../cuba/entities/base/tsadv$Medal";
import {getFileUrl} from "../../util/util";
import PersonMedalRequestManagement from "./PersonMedal/PersonMedalRequestManagement";
import {JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";

export type NominationPersonData = {
  nominationPerson: SerializedEntity<NominationPerson>,
  imageUrl: any
  medals: {
    link: string
    count: number
  }[]
};

export type BestEmployeesTabProps = MainStoreInjected
  & RootStoreProp
  & WrappedComponentProps
  & RouteComponentProps<any>
  & {
  personGroupId: string
};

@injectMainStore
@inject("rootStore")
@observer
class BestEmployeesTab extends React.Component<BestEmployeesTabProps> {

  @observable companyMenu: MenuRaw[] = [];
  @observable selectedCompany: SerializedEntity<DicCompany> | undefined;
  @observable nominationPersonsData: NominationPersonData[];
  @observable nomination?: SerializedEntity<Nomination>;
  @observable selectedYear?: Moment | null

  companyDataCollection = collection<DicCompany>(DicCompany.NAME, {
    view: "_minimal",
    sort: "-updateTs",
    loadImmediately: false
  });

  constructor(props: BestEmployeesTabProps) {
    super(props);
    addCompanyCollectionLoadedCallback(this.companyDataCollection, async items => {
      if (items.length > 0) {
        this.companyMenu.push(...items.map(entry => ({
          id: entry.id,
          value: entry._instanceName
        })))
        if (this.props.rootStore!.recognitionStore.selectedCompany) {
          this.selectedCompany = this.props.rootStore!.recognitionStore.selectedCompany;
        } else {
          this.selectedCompany = items[0];
        }
        this.updateBestEmployees(this.selectedCompany!.id);
      } else {
        Notification.error({
          message: this.props.intl.formatMessage({id: "noCompanies"})
        });
      }
    })
  }

  parseMedalsData = (personMedals: PersonMedal[]) => {
    const map = new Map<string, number>()
    personMedals
      .forEach(v => {
        const value = v.medal as Medal
        if (value) {
          const count = map.get(value.id) || 0
          map.set(value.id, count + (v.medalCount ? v.medalCount : 0))
        }
      })
    return Array.from(map.entries()).map(value => {
      const personMedal = personMedals.find(item => item.medal!.id === value[0])
      return {
        link: personMedal!.medal!.icon ? getFileUrl(personMedal!.medal!.icon.id) : '',
        count: value[1]
      }
    })
  }

  loadPersonMedals = async (nominationPerson: NominationPerson) => await getCubaREST()!.searchEntities<PersonMedal>(PersonMedal.NAME, {
    conditions: [{
      property: "personGroup.id",
      operator: '=',
      value: nominationPerson.personGroup!.id
    }, {
      property: "endDate",
      operator: '>=',
      value: moment().format(JSON_DATE_TIME_FORMAT)
    },{
      property: "hasUpgraded",
      operator: '<>',
      value: 'TRUE'
    }]
  }, {
    view: 'personMedal.edit',sort:'medal.sort'
  })


  getPersonImageLink = async (personGroup: PersonGroupExt):Promise<string> => {
    try {
       return await getCubaREST()!.invokeService<string>(
         "tsadv_EmployeeService",
         "personProfile",
         {personGroupId: personGroup.id}
       ).then(r => JSON.parse(r)).then(value=> {
         if(value && value.imageId){
           return getFileUrl(value.imageId)
         }
         return ''
       })
    } catch (err) {
      Notification.error({
        message: this.props.intl.formatMessage({id: "cubaReact.fileUpload.uploadFailed"})
      });
    }
    return ''
  }

  parseToNominationPersonData = async (nominationPerson: NominationPerson): Promise<NominationPersonData | undefined> => {
    try {
      const personMedal = await this.loadPersonMedals(nominationPerson)
      const medals = this.parseMedalsData(personMedal)
      const urlImg = await this.getPersonImageLink(nominationPerson.personGroup!)
      return {
        nominationPerson: nominationPerson,
        imageUrl: urlImg,
        medals: medals
      } as NominationPersonData

    } catch (err) {
      console.log(err)
      Notification.error({
        message: this.props.intl.messages['person.notFound']
      })
      return undefined
    }
  }

  updateBestEmployees = async (companyId: string) => {
    this.selectedCompany = this.companyDataCollection.items.find(item => item.id === companyId);
    this.props.rootStore!.recognitionStore.setCompany(this.selectedCompany as SerializedEntity<DicCompany>)

    const values = await getCubaREST()!.searchEntities<Nomination>(Nomination.NAME, {
      conditions: [{
        property: "year",
        operator: '=',
        value: this.selectedYear!.year()
      }]
    }, {
      view: 'nomination-with-persons'
    })
    const allNominationPersons: NominationPerson[] = []
    if (!values || values.length === 0) {
      this.nomination = undefined
    }
    values.forEach(value => {
      if (value.nominationPersons) {
        this.nomination = value
        const persons: NominationPerson[] = value.nominationPersons
          .filter(it => (this.selectedCompany!.code === "empty" || it.company!.id === this.selectedCompany!.id) && it.isPublished)
        allNominationPersons.push(...persons)
      }
    })
    const persons = await Promise.all(allNominationPersons.map(this.parseToNominationPersonData))
    this.nominationPersonsData = persons.filter(value => !!value) as NominationPersonData[]
  }

  onYearChanged = (date: Moment | null) => {
    this.selectedYear = date
    this.props.rootStore!.recognitionStore.setYear(date)
  }

  onOpenYearChanged = (status: boolean) => {
    if (!status) {
      this.updateBestEmployees(this.selectedCompany!.id)
    }
  }

  render() {
    if (this.companyDataCollection.status === 'LOADING') {
      return "Loading..."
    }
    let gridColumn = 0;

    return (
      <div style={{height: "100%", width: "100%"}}>
        <div style={{width: "80%", marginBottom: '20px', padding:"20px 0"}}>
          <div style={{margin:"0px 20px 20px 20px"}}>
            <Link
              to={
                PersonMedalRequestManagement.PATH + "/" + PersonMedalRequestManagement.NEW_SUBPATH
              }
              key="create">
              <Button
                style={{display: 'inline-block'}}
                type='primary'
                key="modal">
                {this.props.intl.formatMessage({id: "nominateEmployee"})}
              </Button>
            </Link>
          </div>
          <div style={{display: 'inline-block'}}>
            <label style={{marginLeft: '20px'}}>{this.props.intl.formatMessage({id: "company"})}</label>
            <div style={{display: 'inline-block', width: '15rem', marginLeft: '5px'}}>
              <DefaultDropdown menu={this.companyMenu}
                               selected={this.selectedCompany ? this.selectedCompany._instanceName! : ""}
                               handleMenuClick={(value) => this.updateBestEmployees(value)}/>
            </div>
          </div>
          <div style={{display: 'inline-block', marginLeft: '5rem'}}>
            <label>{this.props.intl.formatMessage({id: "year"})}</label>
            <DefaultDatePicker style={{marginLeft: '5px'}} mode={'year'} format="yyyy"
                               onChange={this.onYearChanged} onOpenChange={this.onOpenYearChanged}
                               value={this.selectedYear}
                               onPanelChange={(val) => {
                                 this.selectedYear = val
                               }}/>
          </div>
          <div style={{paddingLeft: '15px', marginTop: '15px'}}>
            <div style={{fontSize: 'large', color: '#005487', marginBottom: '15px'}}>
              <label>{this.props.intl.formatMessage({id: "bestEmployeeYear"}) + (this.selectedYear ? this.selectedYear.year() : "")}</label>
            </div>
            <div style={{marginBottom: '10px'}}>
              <label>{(this.nomination ? this.props.rootStore!.userInfo.locale === 'en' ? this.nomination.descriptionLangName3 : this.nomination.descriptionLangName1 : "")}</label>
            </div>
            {
              this.nomination && this.nomination.url ? <div style={{marginBottom: '15px'}}>
              <label>{this.props.intl.formatMessage({id: "nominationVideoUrl"})}</label>
              <a href={this.nomination.url}
                 target={'_blank'}>{this.props.intl.formatMessage({id: "nominationVideoUrlLink"})}</a>
              </div>
              : <div/>
            }
          </div>
        </div>
        <div style={{width:'100%', display:'flex', flexWrap: 'wrap'}}>
          {this.nominationPersonsData && this.nominationPersonsData.map((nominationPersonData, index) => {
              gridColumn++
              if (gridColumn > 4){
                gridColumn = 1
              }
              const position = nominationPersonData.nominationPerson.position
              const orgGroupName = position!.organizationGroup ? (position!.organizationGroup as SerializedEntity<PositionGroupExt>)._instanceName : ''
              return <div style={{width: '21rem',  marginTop: '20px'}} key={index}>
                <Card key={nominationPersonData.nominationPerson.id} className="narrow-layout section-container"
                      style={{marginLeft: '2rem', height:'550px', display:'flex'}} bodyStyle={{textAlign: 'center',height:"100%",position:"relative"}
                }>
                  <img
                    src={nominationPersonData.imageUrl ? nominationPersonData.imageUrl : require('../../../resources/img/default-avatar.svg')}
                    style={{height: 200, width: 200, borderRadius: '50%'}}/>
                  <h3>{nominationPersonData.nominationPerson ? (nominationPersonData.nominationPerson.personGroup as SerializedEntity<PersonGroupExt>)._instanceName || '' : ''}</h3>
                  <p style={{fontSize:12}}
                    className="title">{nominationPersonData.nominationPerson ? (nominationPersonData.nominationPerson.position! as SerializedEntity<PositionGroupExt>)._instanceName || '' : ''}</p>
                  <p style={{fontSize:12}}>{orgGroupName}</p>
                  <div style={{bottom:24,position:"absolute",height:"50px",width:"100%"}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    {
                      nominationPersonData.medals.map((value, index) => (
                        <div style={{display: 'flex', flexDirection: 'column'}} key={index}>
                          <img src={value.link} style={{height: '2rem'}}/>
                          <label>{value.count}</label>
                        </div>
                      ))
                    }
                  </div>
                  </div>
                </Card>
              </div>
            }
          )
          }
        </div>
      </div>
    );
  }

  initState = async () => {
    this.selectedYear = moment().subtract(1, "year");
    this.companyDataCollection.load();
    if (this.props.rootStore!.recognitionStore.selectedYear) {
      this.selectedYear = this.props.rootStore!.recognitionStore.selectedYear
    }
  }

  componentDidMount() {
    this.initState()
  }
}

const bestEmployeesTab = injectIntl(withRouter(BestEmployeesTab));
export default bestEmployeesTab;

function addCompanyCollectionLoadedCallback<T>(collection: DataCollectionStore<T>, callback: (items: Array<SerializedEntity<T>>) => void) {
  reaction(() => collection.status === 'DONE', (arg, r) => {
    callback(collection.items)
    r.dispose()
  }, {
    fireImmediately: false
  })
}