import * as React from "react";
import {createElement} from "react";
import {Card, Form, Input, Select} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";

import {collection, getCubaREST, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";

import "../../../app/App.css";
import {restQueries} from "../../../cuba/queries";
import {PositionOverlappingRequest} from "../../../cuba/entities/kzm$PositionOverlappingRequest";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {DicPositionsOverlappingType} from "../../../cuba/entities/base/tsadv_DicPositionsOverlappingType";
import LoadingPage from "../LoadingPage";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import {goBackOrHomePage} from "../../util/util";
import {ReadonlyField} from "../../components/ReadonlyField";
import {DEFAULT_DATE_PATTERN} from "../../util/Date/Date";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import moment from "moment";
import {OrganizationGroup} from "../../../cuba/entities/base/base$OrganizationGroup";
import TextArea from "antd/es/input/TextArea";
import {Redirect} from "react-router-dom";
import {PositionOverlappingRequestManagement} from "./PositionOverlappingRequestManagement";
import {withRouter} from "react-router";
import {PositionGroupExt} from "../../../cuba/entities/base/base$PositionGroupExt";
import {collectionWithAfterLoadFilter} from "../../util/DataCollectionStoreWithAfterLoadFilter";
import {SerializedEntity} from "@cuba-platform/rest/dist-node/model";
import {runReport} from "../../util/reportUtil";
import {restServices} from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";

type EditorProps = {
  entityId: string,
  personGroupId: any;
};

@inject("rootStore")
@injectMainStore
@observer
class PositionOverlappingRequestEditComponent extends AbstractBprocEdit<PositionOverlappingRequest, EditorProps> {
  processDefinitionKey = "positionOverlappingRequest";

  dataInstance = instance<PositionOverlappingRequest>(
    PositionOverlappingRequest.NAME,
    {view: "positionOverlappingRequest-edit", loadImmediately: false}
  );

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  positionOverlappingTypeDc = collection<DicPositionsOverlappingType>(
    DicPositionsOverlappingType.NAME,
    {view: "_minimal"}
  )

  afterLoadFilter = (result: Array<SerializedEntity<PositionGroupExt>>) => {
    return result.filter(value =>
      value.position && value.position.positionStatus && value.position.positionStatus.code
      && value.position.positionStatus.code === 'ACTIVE'
    )
  }

  @observable
  positionGroupDc = collectionWithAfterLoadFilter<PositionGroupExt>(
    PositionGroupExt.NAME,
    this.afterLoadFilter,
    {
      view: "positionGroupExt-with-status",
      filter: {
        conditions: [
          {
            property: "company.code", operator: "=", value: this.props.rootStore!.userInfo.companyCode!
          }
        ]
      }
    }
  )

  organizationGroupDc = collection<OrganizationGroup>(
    OrganizationGroup.NAME,
    {
      view: "_minimal",
      filter: {
        conditions: [
          {
            property: "company.code", operator: "=", value: this.props.rootStore!.userInfo.companyCode!
          }
        ]
      }
    }
  )

  @observable
  editPositionOverlappingRequest: PositionOverlappingRequest;
  validate = (): Promise<boolean> => {
    let isValidatedSuccess = true;
    this.props.form.validateFields([...this.fields, "personGroup"], {force: true}, (err, values) => {
      isValidatedSuccess = !err;
      if (err) {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.validationError"})
        });
      }
    });
    return new Promise(resolve => resolve(isValidatedSuccess));
  };

  fields = [
    "requestNumber",

    "requestDate",

    "dateFrom",

    "dateTo",

    "positionGroup",

    "department",

    "justification",

    "status",

    "type",

    "attachments"
  ];


  @observable
  person: PersonExt;
  @observable teamMembers: any;
  organization: any;

  editedPositionOverlappingRequest: PositionOverlappingRequest;

  @observable
  mainStore = this.props.mainStore!;
  @observable selectedPerson: any;

  @observable
  approverHrRoleCode: string = "";

  @observable
  approverHrRoleCodeHR: boolean = false;


  @observable
  loadingReportEmployeeProfile = false

  isUpdateBeforeOutcome = true;

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.dataInstance.item!.personGroup && this.dataInstance.item!.personGroup.id || (this.props.personGroupId?this.props.personGroupId:this.selectedPerson),
      ...super.getUpdateEntityData()
    };
  }

  isDraft = () => {
    return this.dataInstance.item && this.dataInstance.item.status ? this.dataInstance.item.status.langValue3 === "Draft"
      && true
      : false;
  };

  actions = () => {
    return [
      <Button buttonType={ButtonType.FOLLOW}
              onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
      this.getOutcomeBtns()
    ]
  }
  actionsHr = () => {
    return [
      <Button buttonType={ButtonType.FOLLOW}
              onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
      <Button style={{background: 'green', color: 'black !important'}}
              disabled={this.loadingReportEmployeeProfile}
              onClick={() => {
                const data = {
                  parameters: [{
                    name: "req",
                    value: this.props.entityId!
                  }]
                }
                runReport("overlapping_request", data, this.props.intl)
              }}
      >{this.props.intl.formatMessage({id: "download.excel"})}</Button>,
      this.getOutcomeBtns()
    ]
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this.props.personGroupId) {
      restServices.myTeamService
        .getChildren({parentPositionGroupId: this.props.rootStore!.userInfo.positionGroupId!})
        .then((res) => {
          console.log(res);
          this.teamMembers = res
        })
    }
  }

  constructor(props: any) {
    super(props);
  }

  render() {
    console.log(this.teamMembers)
    if (!this.dataInstance) {
      return <LoadingPage/>
    }


    if (this.updated) {
      return <Redirect to={PositionOverlappingRequestManagement.PATH}/>;
    }


    const messages = this.mainStore.messages!;
    const entityName = this.dataInstance.entityName;


    return <Page pageName={<FormattedMessage id={"positionOverlappingRequest"}/>}>
      <Section size="large">
        <Card className="narrow-layout card-actions-container"
              bordered={false}
              actions={this.approverHrRoleCodeHR ? this.actionsHr() : this.actions()}
        >
          <Form onSubmit={this.validate} layout="vertical">

            <ReadonlyField
              entityName={PositionOverlappingRequest.NAME}
              propertyName="requestNumber"
              form={this.props.form}
              formItemOpts={{style: {marginBottom: "12px"}}}
              disabled={true}
              getFieldDecoratorOpts={{
                rules: [{required: true,}]
              }}
            />

            <ReadonlyField
              entityName={PositionOverlappingRequest.NAME}
              propertyName="status"
              disabled={true}
              form={this.props.form}
              formItemOpts={{style: {marginBottom: "12px"}}}
              optionsContainer={this.statusesDc}
              getFieldDecoratorOpts={{
                rules: [{required: true,}],
              }}
            />

            <ReadonlyField
              entityName={PositionOverlappingRequest.NAME}
              propertyName="requestDate"
              form={this.props.form}
              disabled={true}
              formItemOpts={{style: {marginBottom: "12px"}}}
              format={DEFAULT_DATE_PATTERN}
              getFieldDecoratorOpts={{
                rules: [{required: true,}]
              }}
            />

            <Form.Item
              label={createElement(Msg, {entityName: entityName, propertyName: "personGroup"})}>
              {this.props.personGroupId||(this.props.entityId&&this.props.entityId!=="new") ? <Input
                value={this.person ? this.person['_instanceName'] || '' : ''}
                disabled/> : this.props.form.getFieldDecorator("personGroup", {
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage({id: "form.validation.required"},
                    {fieldName: messages[PositionOverlappingRequest.NAME + '.' + 'personGroup']})
                }]
              })(<Select disabled={!this.isDraft()}
                         placeholder={this.props.intl.formatMessage({id: "chooseEmployee"})}
                         onChange={(value:string) => {this.selectedPerson = value;this.setEmployee(value);}}>
                {this.teamMembers && this.teamMembers.map((item: any) => {
                  return <Select.Option value={item.personGroupId} key={item.personGroupId}>
                    {item.fullName}
                  </Select.Option>
                })}
              </Select>)}

            </Form.Item>

            <ReadonlyField
              entityName={PositionOverlappingRequest.NAME}
              propertyName="type"
              form={this.props.form}
              formItemOpts={{style: {marginBottom: "12px"}}}
              optionsContainer={this.positionOverlappingTypeDc}
              disabled={this.isNotDraft()!}
              getFieldDecoratorOpts={{
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage(
                    {id: "form.validation.required"},
                    {fieldName: messages[PositionOverlappingRequest.NAME + '.type']})
                }]
              }}
            />

            <ReadonlyField
              entityName={PositionOverlappingRequest.NAME}
              propertyName="department"
              form={this.props.form}
              formItemOpts={{style: {marginBottom: "12px"}}}
              optionsContainer={this.organizationGroupDc}
              disabled={this.isNotDraft()!}
              getFieldDecoratorOpts={{
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage(
                    {id: "form.validation.required"},
                    {fieldName: messages[PositionOverlappingRequest.NAME + '.department']})
                }],
                getValueFromEvent: organizationId => {
                  if (organizationId) {
                    this.positionGroupDc.filter = {
                      conditions: [
                        {
                          property: "company.code", operator: "=", value: this.props.rootStore!.userInfo.companyCode!
                        },
                        {
                          property: "organizationGroup.id",
                          operator: "=",
                          value: organizationId
                        }
                      ]
                    };
                  } else {
                    this.positionGroupDc.filter = {
                      conditions: [
                        {
                          property: "company.code", operator: "=", value: this.props.rootStore!.userInfo.companyCode!
                        }
                      ]
                    };
                  }
                  this.positionGroupDc.load();
                  return organizationId;
                }
              }}
            />

            <ReadonlyField
              entityName={PositionOverlappingRequest.NAME}
              propertyName="positionGroup"
              form={this.props.form}
              disabled={this.isNotDraft()!}
              formItemOpts={{style: {marginBottom: "12px"}}}
              optionsContainer={this.positionGroupDc}
              getFieldDecoratorOpts={{
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage(
                    {id: "form.validation.required"},
                    {fieldName: messages[PositionOverlappingRequest.NAME + '.positionGroup']})
                }],
                getValueFromEvent: positionId => {
                  this.fillOrganization(positionId);
                  return positionId;
                }
              }}
            />


            <Form onSubmit={this.validate} layout="vertical">
              <ReadonlyField
                entityName={PositionOverlappingRequest.NAME}
                propertyName="dateFrom"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                disabled={this.isNotDraft()!}
                getFieldDecoratorOpts={{
                  rules: [{required: true,}]
                }}
              />
            </Form>
            <Form onSubmit={this.validate} layout="vertical">
              <ReadonlyField
                entityName={PositionOverlappingRequest.NAME}
                propertyName="dateTo"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                disabled={this.isNotDraft()!}
                getFieldDecoratorOpts={{
                  rules: [{required: true,}]
                }}
              />
            </Form>
            <Form.Item
              label={createElement(Msg, {entityName: this.dataInstance.entityName, propertyName: "justification"})}>
              {this.props.form.getFieldDecorator("justification")(
                <TextArea
                  disabled={this.isNotDraft()!}
                  rows={4}/>
              )}
            </Form.Item>
            <ReadonlyField
              entityName={entityName}
              propertyName="attachments"
              form={this.props.form}
              disabled={this.isNotDraft()!}
              formItemOpts={{style: {marginBottom: "12px"}}}
            />
          </Form>
          {this.takCard()}
        </Card>
      </Section>
    </Page>;

  }

  initVariablesByBproc = () => {
    if (
      this.activeUserTask &&
      this.activeUserTask.hrRole &&
      this.activeUserTask.hrRole.code
    ) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
      this.approverHrRoleCodeHR = this.activeUserTask.taskDefinitionKey === "hr_task"
    }
  };
  fillOrganization = (positionId: string) => {
    restQueries.organizationGroupByPositionId(positionId).then(values => {
      const organId = values.length < 1 ? "" : values[0].id;
      this.props.form.setFields({
        department: {
          value: organId
        }
      })
    });
  }


  onReactionDisposerEffect = (item: PositionOverlappingRequest | undefined) => {
    const personGroupId = item && item.personGroup ? item.personGroup.id! : this.props!.personGroupId?this.props.personGroupId:this.selectedPerson;
    this.setEmployee(personGroupId);
    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();
    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const organizationId = item!.department!.id;
    if (organizationId) {
      this.positionGroupDc = collectionWithAfterLoadFilter<PositionGroupExt>(
        PositionGroupExt.NAME,
        this.afterLoadFilter,
        {
          view: "positionGroupExt-with-status",
          filter: {
            conditions: [
              {
                property: "company.code", operator: "=", value: this.props.rootStore!.userInfo.companyCode!
              },
              {
                property: "organizationGroup.id",
                operator: "=",
                value: organizationId
              }
            ]
          }
        }
      );
    }

  }
}


export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(PositionOverlappingRequestEditComponent))
);
