import './style.css'
import { createElement, default as React } from "react";
import {
  collection,
  getCubaREST,
  injectMainStore,
  instance,
  MainStoreInjected,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";
import Page from "../../hoc/PageContentHoc";
import { RootStoreProp } from "../../store";
import { inject, observer } from "mobx-react";
import { Card, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import { RouteComponentProps } from "react-router-dom";
import Button, { ButtonType } from "../../components/Button/Button";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { withRouter } from "react-router";
import { FormComponentProps } from "antd/lib/form";
import Notification from "../../util/Notification/Notification";
import { LearningBudgetRequest } from "../../../cuba/entities/base/tsadv_LearningBudgetRequest";
import { LearningBudget } from "../../../cuba/entities/base/tsadv_LearningBudget";
import { parseToJsonFromFieldValue } from "../../components/MultiFileUpload";
import { OrganizationGroupExt } from "../../../cuba/entities/base/base$OrganizationGroupExt";
import { PersonGroupExt } from "../../../cuba/entities/base/base$PersonGroupExt";
import { observable, toJS } from "mobx";
import { Course } from "../../../cuba/entities/base/tsadv$Course";
import { DicRegion } from "../../../cuba/entities/base/tsadv_DicRegion";
import TextArea from "antd/lib/input/TextArea";
import { DicCostCenter } from "../../../cuba/entities/base/tsadv$DicCostCenter";
import { DicLearningType } from "../../../cuba/entities/base/tsadv$DicLearningType";
import { LearningBudgetRequestCost } from "../../../cuba/entities/base/tsadv_LearningBudgetRequestCost";
import { restServices } from "../../../cuba/services";
import Table from "antd/lib/table";
import EmployeePickerTable, { SelectRowType } from "./EmployeePickerTable";
import { SerializedEntity } from "@cuba-platform/rest";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import Column from "antd/es/table/Column";
import { PositionGroupExt } from "../../../cuba/entities/base/base$PositionGroupExt";
import CoursePickerTable, { CoursePickerTableData } from "./CoursePickerTable";
import { PartyExt } from "../../../cuba/entities/base/base$PartyExt";
import { DicCategory } from "../../../cuba/entities/base/tsadv$DicCategory";
import moment from "moment";
import { PerdiemsSetting } from "../../../cuba/entities/base/tsadv_PerdiemsSetting";
import { LearningBudgetRequestHeader } from "../../../cuba/entities/base/tsadv_LearningBudgetRequestHeader";
import { getValidGreaterZero, parseStringToFloat, updateCost, wrapToSerialized } from "./utils";
import { CostsComponent } from "./CostsComponent";
import LabelTooltip from "./LabelTooltip/LabelTooltip";
import LoadingPage from "../LoadingPage";
import { OrganizationExt } from '../../../cuba/entities/base/base$OrganizationExt';

type EditorProps = {
  entityId: string;
  budgetId: string;
};

@inject("rootStore")
@injectMainStore
@observer
class LearningBudgetRequestGroupCreate extends React.Component<EditorProps & WrappedComponentProps & FormComponentProps & MainStoreInjected & RootStoreProp & RouteComponentProps> {

  @observable learningBudget: LearningBudget;
  @observable selectedPersons: AssignmentExt[];
  @observable selectedAssignmentKey: string;
  @observable selectedCourse?: CoursePickerTableData;
  @observable selectedLearningType?: SerializedEntity<DicLearningType>;
  @observable selectedTypeOfTraining?: SerializedEntity<DicCategory>;
  @observable currentUser: SerializedEntity<PersonGroupExt>;
  @observable pickerUsersDataItems: Array<AssignmentExt>;
  @observable pickerCoursesDataItems: Array<CoursePickerTableData>;
  @observable isCourseOnline: boolean;
  @observable organizationGroupList: SerializedEntity<OrganizationGroupExt>[];
  @observable selectedOrganization: SerializedEntity<OrganizationGroupExt>;
  @observable selectedBaseOrganization: SerializedEntity<OrganizationExt>;
  @observable assignmentsList: SerializedEntity<AssignmentExt>[];
  @observable isEmployeeModalVisible: boolean;
  @observable isCourseModalVisible: boolean;
  @observable perdiemsSettings?: PerdiemsSetting;
  @observable reportGroup?: SerializedEntity<OrganizationGroupExt>
  @observable isCourseRequred: boolean = true
  @observable isCourseNameRequred: boolean = true

  learningBudgetRequestHeader: LearningBudgetRequestHeader;
  @observable daysCount = {
    value: 1
  }

  @observable employeeCount = {
    value: 0,
  }


  costDataInstance = instance<LearningBudgetRequestCost>(LearningBudgetRequestCost.NAME, {
    view: "_local",
    loadImmediately: false
  });

  dataCollectionAssignment = collection<AssignmentExt>(AssignmentExt.NAME, {
    sort: "-updateTs",
    loadImmediately: false
  });


  learningTypesDc = collection<DicLearningType>(DicLearningType.NAME, {
    sort: "-updateTs",
  });

  typeOfTrainingDc = collection<DicCategory>(DicCategory.NAME, {
    sort: "-updateTs",
  });

  coursesDc = collection<Course>(Course.NAME, {
    view: "course-front-view",
    sort: "-updateTs",
  });

  costCentersDc = collection<DicCostCenter>(DicCostCenter.NAME, {
    sort: "-updateTs",
  });

  regionsDc = collection<DicRegion>(DicRegion.NAME, {
    sort: "-updateTs",
  });

  reportGroupDc = collection<OrganizationGroupExt>(OrganizationGroupExt.NAME, {
    sort: "-updateTs",
  })

  assignmentFields = [
    "personGroup",

    "organizationGroup",

    "positionGroup"
  ]


  fields = [
    "organizationGroup",

    "employee",

    "costCenter",

    "positionName",

    "responsiblePerson",

    "employeeCount",

    "course",

    "typeOfTraining",

    "courseName",

    "learningType",

    "city",

    "month",

    "region",

    "dayCount",

    "reason",

  ];

  costFields = [
    "trainingCost",

    "travel",

  ];

  getUpdateEntityData = (fields: string[]): any => {
    const obj = {
      ...this.props.form.getFieldsValue(fields),
    };

    const metaClass = this.props.mainStore!.metadata!.find(mci => mci.entityName === this.costDataInstance.entityName);
    if (metaClass) {
      metaClass.properties
        .filter(value => value.cardinality === "ONE_TO_MANY" || value.cardinality === "MANY_TO_MANY")
        .filter(value => fields.find(field => field === value.name))
        .forEach(value => {
          const files = obj[value.name];
          if (files)
            obj[value.name] = parseToJsonFromFieldValue(files);
        })
    }

    return obj;
  };

  updateCost = () => {
    const obj = this.getUpdateEntityData(this.costFields);
    updateCost(obj, this.props.form)
    return obj;
  }

  close = () => {
    this.props.history!.goBack();
  }

  updateAndClose = async () => {
    if (!this.selectedPersons || !this.selectedPersons.length) {
      Notification.error({
        message: this.props.intl.messages['employeeCount.invalid']
      })
      return
    }
    try {
      await (this.props.form.validateFields() as unknown as Promise<any>)
      this.employeeCount.value = 1
      try {
        const objCost = await this.updateCost()
        for (const assignment of this.dataCollectionAssignment.items) {
          const learningBudgetRequest = new LearningBudgetRequest();
          learningBudgetRequest.employeeCount = 1;
          learningBudgetRequest.budget = this.learningBudget;
          learningBudgetRequest.employee = assignment.personGroup;
          learningBudgetRequest.positionName = wrapToSerialized(assignment.positionGroup)._instanceName;
          learningBudgetRequest.responsiblePerson = this.currentUser;
          learningBudgetRequest.organizationGroup = assignment.organizationGroup;
          const costCenter = this.selectedBaseOrganization.costCenter ? this.selectedBaseOrganization.costCenter
            : assignment.costCenter ? assignment.costCenter : this.selectedOrganization.costCenter
          learningBudgetRequest.costCenter = costCenter;

          let learningBudgetRequestCost = new LearningBudgetRequestCost()
          learningBudgetRequestCost.trainingCost = objCost['trainingCost'];
          learningBudgetRequestCost.travel = objCost['travel'];
          learningBudgetRequestCost.residence = this.costDataInstance.item!.residence;
          learningBudgetRequestCost.dailyAllowance = this.costDataInstance.item!.dailyAllowance;
          learningBudgetRequest.cost = await getCubaREST()!.commitEntity(LearningBudgetRequestCost.NAME, learningBudgetRequestCost)

          learningBudgetRequest.reportGroup = {id: this.props.form.getFieldValue("reportGroup")};
          if (this.selectedCourse) {
            learningBudgetRequest.course = this.coursesDc.items.find(item => item.id === this.selectedCourse!.id);
          }
          learningBudgetRequest.courseName = this.props.form.getFieldValue("courseName");
          const learningTypeId = this.props.form.getFieldValue("learningType");
          learningBudgetRequest.learningType = learningTypeId ? { id: learningTypeId } : undefined;
          const typeOfTrainingId = this.props.form.getFieldValue("typeOfTraining");
          learningBudgetRequest.typeOfTraining = typeOfTrainingId ? { id: typeOfTrainingId } : undefined;
          if (this.props.form.getFieldValue('city')) {
            learningBudgetRequest.city = this.props.form.getFieldValue("city");
          }
          const regionId = this.props.form.getFieldValue("region");
          learningBudgetRequest.region = regionId ? { id: regionId } : undefined;
          learningBudgetRequest.month = this.props.form.getFieldValue("month");
          learningBudgetRequest.dayCount = this.props.form.getFieldValue("dayCount");
          learningBudgetRequest.reason = this.props.form.getFieldValue("reason");
          learningBudgetRequest.costByPerson = parseStringToFloat(this.props.form.getFieldValue('totalByOne'));
          learningBudgetRequest.costSum = parseStringToFloat(this.props.form.getFieldValue('totalByOne'));
          learningBudgetRequest.learningBudgetRequestHeader = this.learningBudgetRequestHeader;
          await getCubaREST()!.commitEntity(LearningBudgetRequest.NAME, toJS(learningBudgetRequest))
        }
      } catch (err) {
        Notification.error({
          message: this.props.intl.formatMessage({ id: "management.editor.error" })
        });
      }
      this.close();
    } catch (reason) {
      const errors = reason.errors
      for (let field in errors) {
        errors[field].errors.forEach((val: {
          message: string
          field: string
        }) => {
          Notification.error({
            message: val.message
          })
        })
      }
    }
  }

  onCourseChange = (courseId: string) => {
    this.isCourseNameRequred = false
    this.selectedCourse = this.pickerCoursesDataItems.find(item => item.id === courseId)!;
    const course = this.coursesDc.items.find(item => item.id === courseId);
    this.selectedLearningType = course!.learningType as SerializedEntity<DicLearningType>;
    this.selectedTypeOfTraining = course!.category as SerializedEntity<DicCategory>;
    this.props.form.setFieldsValue({
      course: this.selectedCourse,
      learningType: this.selectedLearningType.id,
      typeOfTraining: this.selectedTypeOfTraining.id
    });
    getCubaREST()!.loadEntity(Course.NAME, courseId, { view: '_local' })
      .then(value => {
        this.isCourseOnline = (value as Course).isOnline!;
        this.props.form.setFieldsValue({
          courseName: value._instanceName
        });
        if (this.isCourseOnline) {
          this.props.form.setFieldsValue({
            city: "ONLINE"
          });
        }
      })
      .catch(() => {
        Notification.error({
          message: this.props.intl.formatMessage({ id: "management.editor.error" })
        });
      }
      )
  }

  onRegionChange = async (regionId: string) => {
    const perdiemsResult = await getCubaREST()!.searchEntities<PerdiemsSetting>(PerdiemsSetting.NAME, {
      conditions: [{
        property: 'companyCode.id',
        operator: '=',
        value: this.selectedOrganization!.company!.id
      },
      {
        property: 'region.id',
        operator: '=',
        value: regionId
      }]
    }, {
      sort: '-createTs',
      view: 'perdiems-front-view',
      limit: 1
    })

    if (perdiemsResult.length)
      this.perdiemsSettings = perdiemsResult[0] as PerdiemsSetting;
    else {
      this.perdiemsSettings = undefined
      Notification.info({
        message: this.props.intl.messages['dailyAllowance.empty']
      })
    }
  }


  parseToCourseData = (course: SerializedEntity<Course>): CoursePickerTableData => {
    return {
      id: course.id,
      name: course._instanceName,
      shortDescription: course.shortDescription,
      party: course.party ? ((course.party) as SerializedEntity<PartyExt>)._instanceName : "",
      category: course.category ? ((course.category) as SerializedEntity<DicCategory>)._instanceName : "",
      targetAudience: course.targetAudience,
      learningType: course.learningType ? ((course.learningType) as SerializedEntity<DicLearningType>)._instanceName : "",
      activeFlag: course.activeFlag ? this.props.intl.formatMessage({ id: "cubaReact.dataTable.yes" }) : this.props.intl.formatMessage({ id: "cubaReact.dataTable.no" }),
      selfEnrollment: course.selfEnrollment ? this.props.intl.formatMessage({ id: "cubaReact.dataTable.yes" }) : this.props.intl.formatMessage({ id: "cubaReact.dataTable.no" }),
      isOnline: course.isOnline ? this.props.intl.formatMessage({ id: "cubaReact.dataTable.yes" }) : this.props.intl.formatMessage({ id: "cubaReact.dataTable.no" }),
      sorterColumns: []
    } as CoursePickerTableData
  }


  onEmployeeModalOkClick = (persons: AssignmentExt[]) => {
    this.selectedPersons = persons;
    this.employeeCount = {
      value: persons.length,
    };
    persons.forEach(assignment => {
      //var assignment = this.assignmentsList.find(item => item.id === it.id);
      if (!this.dataCollectionAssignment.items.includes(assignment as SerializedEntity<AssignmentExt>)) {
        this.dataCollectionAssignment.items.push(assignment as SerializedEntity<AssignmentExt>);
      }
    });
    this.isEmployeeModalVisible = false;
  }

  onEmployeeModalCancelClick = () => {
    this.isEmployeeModalVisible = false;
  }

  onCourseModalOkClick = (course: CoursePickerTableData[]) => {
    this.selectedCourse = course[0];
    this.onCourseChange(this.selectedCourse.id);
    this.isCourseModalVisible = false;
  }

  onCourseModalCancelClick = () => {
    this.isCourseModalVisible = false;
  }

  showEmployeeModal = () => {
    this.isEmployeeModalVisible = true;
  };

  showCourseModal = () => {
    this.isCourseModalVisible = true;
  };

  clearCourseFields = () => {
    this.isCourseNameRequred = true
    this.selectedCourse = undefined;
    this.selectedLearningType = undefined;
    this.selectedTypeOfTraining = undefined;
    this.isCourseOnline = false
    this.props.form.setFieldsValue({
      course: this.selectedCourse,
      learningType: this.selectedLearningType,
      typeOfTraining: this.selectedTypeOfTraining,
      courseName: "",
      courseNameEng: "",
      city: ""
    });
  };


  render() {

    if (!this.currentUser || !this.assignmentsList || this.coursesDc.status === 'LOADING' || !this.costDataInstance.item) return <LoadingPage />

    if (!this.pickerCoursesDataItems) {
      this.pickerCoursesDataItems = this.coursesDc.items.sort((a, b) => {
        if (a._instanceName < b._instanceName) {
          return -1;
        }
        if (a._instanceName > b._instanceName) {
          return 1;
        }
        return 0;
      }).map(item => this.parseToCourseData(item));
    }

    const actions = [];
    const messages = this.props.mainStore!.messages!;

    let courseOption;
    let typeOFTrainingOption;
    let learningTypeOption;

    if (this.selectedTypeOfTraining) {
      typeOFTrainingOption = { value: this.selectedTypeOfTraining.id, label: this.selectedTypeOfTraining._instanceName };
    }

    if (this.selectedLearningType) {
      learningTypeOption = { value: this.selectedLearningType.id, label: this.selectedLearningType._instanceName };
    }

    if (this.selectedCourse) {
      courseOption = { value: this.selectedCourse.id, label: this.selectedCourse.name };
    }

    actions.push(<Button
      onClick={this.updateAndClose}
      buttonType={ButtonType.PRIMARY}
      style={{ marginLeft: "8px" }}>
      <FormattedMessage id="management.editor.submit" />
    </Button>);
    actions.push(<Button htmlType="button"
      buttonType={ButtonType.FOLLOW}
      onClick={() => this.props.history!.goBack()}>
      <FormattedMessage id="management.editor.cancel" />
    </Button>);

    return (
      <Page pageName={this.props.intl.formatMessage({ id: "budgetRequestEdit" })}>
        <div>
          <Card className="narrow-layout card-actions-container"
            bordered={false} actions={actions}>
            <div className={"ant-row ant-form-item"}
              style={{ marginBottom: "12px", padding: "0 30px" }}>
              <fieldset style={{
                border: "1px solid silver",
                padding: "5px",
                borderRadius: "4px"
              }}>
                <legend style={{
                  width: "auto",
                  paddingRight: "10px"
                }}>{this.props.intl.formatMessage({ id: "listOfPersons" })}</legend>
                <div>
                  <Button
                    icon="plus"
                    className="add-group-persons-btn"
                    onClick={() => this.showEmployeeModal()}
                    key="modal">
                  </Button>

                  <Table dataSource={Array.from(this.dataCollectionAssignment.items || [])}
                    pagination={false} showHeader={true}
                    rowKey={record => record.id}>
                    <Column key='personGroup' title={messages[AssignmentExt.NAME + '.' + 'personGroup']}
                      render={(text, record: AssignmentExt) => (record.personGroup as SerializedEntity<PersonGroupExt>)._instanceName} />
                    <Column key='organizationGroup' title={this.props.intl.formatMessage({ id: "organizationGroupLabel" })}
                      render={(text, record: AssignmentExt) => (record.organizationGroup as SerializedEntity<OrganizationGroupExt>)._instanceName} />
                    <Column key='positionGroup' title={messages[AssignmentExt.NAME + '.' + 'positionGroup']}
                      render={(text, record: AssignmentExt) => (record.positionGroup as SerializedEntity<PositionGroupExt>)._instanceName} />
                    <Column
                      key="action"
                      render={(text, record: SerializedEntity<AssignmentExt>) => {
                        return <Button type="link"
                          className="delete-row-btn"
                          style={{ padding: 0 }}
                          onClick={() => {
                            const idx = this.dataCollectionAssignment.items.indexOf(record);
                            if (idx !== -1) {
                              this.dataCollectionAssignment.items.splice(idx, 1);
                            }

                          }}>
                          {this.props.intl.formatMessage({ id: "delete" })}
                        </Button>
                      }}
                    />
                  </Table>

                  <Modal
                    title={this.props.intl.formatMessage({ id: "persons" })}
                    width={"1200px"}
                    style={{ height: "770px" }}
                    footer={null}
                    closable={false}
                    visible={this.isEmployeeModalVisible}>
                    <div>
                      <EmployeePickerTable
                        items={this.assignmentsList}
                        intl={this.props.intl}
                        onOkClick={this.onEmployeeModalOkClick}
                        onCancelClick={this.onEmployeeModalCancelClick}
                        paginationPosition={"bottom"}
                        tableProps={{ rowKey: "id" }}
                        selectRowType={SelectRowType.MULTI} />
                    </div>
                  </Modal>
                </div>
              </fieldset>
              <fieldset style={{
                border: "1px solid silver",
                padding: "5px",
                borderRadius: "4px",
                marginTop: "25px"
              }}>
                <legend style={{
                  width: "auto",
                  paddingRight: "10px"
                }}>{this.props.intl.formatMessage({ id: "course.information" })}</legend>
                <Form style={{ padding: "10px" }}>
                  <Row>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'course']}
                          trigger={'click'}
                          title={this.props.intl.formatMessage({ id: "courseHint" })} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("course",
                            {
                              rules: [{
                                required: this.isCourseRequred,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[`${LearningBudgetRequest.NAME}.course`] })
                              }],
                              valuePropName: 'id',
                              initialValue: this.selectedCourse ? this.selectedCourse!.id : undefined
                            })(
                              <Select showSearch
                                style={{ width: "96%" }}
                                filterOption={(input, option) => {
                                  return option.props.children!.toLocaleString().toLowerCase().includes(input.toLowerCase());
                                }
                                }
                                value={this.selectedCourse ? this.selectedCourse!.id : undefined}
                                onChange={this.onCourseChange}>
                                {this.pickerCoursesDataItems && this.pickerCoursesDataItems.map(value =>
                                  <Select.Option key={value.id}>
                                    {value.name}</Select.Option>)}
                              </Select>
                            )}
                          <Button
                            className="picker-btn"
                            icon="close"
                            onClick={() => this.clearCourseFields()}
                            key="clear"/>
                          <Button
                            className="picker-btn"
                            icon="ellipsis"
                            onClick={() => this.showCourseModal()}
                            key="modal">

                          </Button>
                          <Modal
                            title={this.props.intl.formatMessage({ id: "courses" })}
                            width={"60%"}
                            style={{ height: "90%" }}
                            footer={null}
                            closable={false}
                            visible={this.isCourseModalVisible}>
                            <div>
                              <CoursePickerTable
                                items={this.pickerCoursesDataItems}
                                intl={this.props.intl}
                                onOkClick={this.onCourseModalOkClick}
                                onCancelClick={this.onCourseModalCancelClick}
                                paginationPosition={"bottom"}
                                tableProps={{ rowKey: "id" }}
                                selectRowType={SelectRowType.SINGLE} />
                            </div>
                          </Modal>
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'courseName']}
                          trigger={'click'}
                          title={this.props.intl.formatMessage({ id: "courseTextHint" })} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("courseName",
                            {
                              rules: [{
                                required: this.isCourseNameRequred,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'courseName'] })
                              }],
                              initialValue: ""
                            })(
                              <Input onChange={value => {
                                this.isCourseRequred = !(value.target.value && value.target.value !== '');
                              }} disabled={this.props.form.getFieldValue('course')} />
                            )}
                        </div>
                      </div>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'learningType']}
                          trigger={'click'}
                          title={messages[LearningBudgetRequest.NAME + '.' + 'learningType']} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("learningType",
                            {
                              rules: [{
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'learningType'] })
                              }],
                              initialValue: learningTypeOption ? learningTypeOption.value : undefined
                            })(
                              <Select
                                disabled={this.props.form.getFieldValue('course')}
                                showSearch
                                value={learningTypeOption ? learningTypeOption.value : undefined}>
                                {this.learningTypesDc && this.learningTypesDc.items.map(value =>
                                  <Select.Option key={value.id}>
                                    {value._instanceName}
                                  </Select.Option>)}
                              </Select>
                            )}
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'typeOfTraining']}
                          trigger={'click'}
                          title={messages[LearningBudgetRequest.NAME + '.' + 'typeOfTraining']} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("typeOfTraining",
                            {
                              rules: [{
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'typeOfTraining'] })
                              }],
                              initialValue: typeOFTrainingOption ? typeOFTrainingOption.value : undefined
                            })(
                              <Select showSearch
                                disabled={this.props.form.getFieldValue('course')}
                                value={typeOFTrainingOption ? typeOFTrainingOption.value : undefined}>
                                {this.typeOfTrainingDc && this.typeOfTrainingDc.items.map(value =>
                                  <Select.Option key={value.id}>
                                    {value._instanceName}
                                  </Select.Option>)}
                              </Select>
                            )}
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'reportGroup']}
                          trigger={'click'}
                          title={messages[LearningBudgetRequest.NAME + '.' + 'reportGroup']} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("reportGroup",
                            {
                              rules: [
                                {
                                  required: true,
                                  message: this.props.intl.messages['reportGroup.required']
                                }
                              ],
                              initialValue: this.reportGroup ? this.reportGroup.id : undefined
                            })(
                              <Select showSearch
                                value={this.reportGroup ? this.reportGroup.id : undefined}>
                                {this.reportGroupDc && this.reportGroupDc.items.map(value =>
                                  <Select.Option key={value.id}>
                                    {value._instanceName}
                                  </Select.Option>)}
                              </Select>
                            )}
                        </div>
                      </div>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'city']} trigger={'click'}
                          title={messages[LearningBudgetRequest.NAME + '.' + 'city']} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("city",
                            {
                              rules: [{
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'city'] })
                              }],
                              initialValue: this.isCourseOnline ? 'ONLINE' : ''
                            })(
                              <Input disabled={this.isCourseOnline} style={{ width: '100%' }} />
                            )}
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'region']}
                          trigger={'click'}
                          title={messages[LearningBudgetRequest.NAME + '.' + 'region']} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("region",
                            {
                              rules: [{
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'region'] })
                              }],
                              initialValue: null
                            })(
                              <Select showSearch
                                onChange={this.onRegionChange}>
                                {this.regionsDc && this.regionsDc.items.map(value =>
                                  <Select.Option key={value.id}>
                                    {value._instanceName}</Select.Option>)}
                              </Select>
                            )}
                        </div>
                      </div>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'month']} trigger={'click'}
                          title={messages[LearningBudgetRequest.NAME + '.' + 'month']} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("month",
                            {
                              rules: [{
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![LearningBudgetRequest.NAME + '.month'] })
                              }],
                              initialValue: moment()
                            })(
                              <DatePicker.MonthPicker style={{ width: '100%' }} />
                            )}
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'dayCount']}
                          trigger={'click'}
                          title={messages[LearningBudgetRequest.NAME + '.' + 'dayCount']} />
                        <div className={'budget-request-form-input'}>
                          {this.props.form.getFieldDecorator("dayCount",
                            {
                              initialValue: this.daysCount.value,
                              rules: [
                                {
                                  transform: (value: string) => parseInt(value),
                                  min: 1,
                                  type: 'integer',
                                  message: this.props.intl.messages['daysCount.invalid']
                                },
                                {
                                  required: true,
                                  message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'dayCount'] })
                                }
                              ]
                            })(
                              <Input
                                onChange={(ev) => this.daysCount.value = parseInt(ev.target.value) || 1} />
                            )}
                        </div>
                      </div>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item>
                      <div className="budget-request-form">
                        <LabelTooltip labelText={this.props.intl.formatMessage({ id: "reasonCaption" })} trigger={'click'}
                          title={this.props.intl.formatMessage({ id: "reasonCaption" })}
                          containerStyle={{ flex: 2 }} />
                        <div className={'budget-request-form-input'} style={{ flex: 5 }}>
                          {this.props.form.getFieldDecorator("reason",
                            {
                              rules: [{
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.intl.formatMessage({ id: "reasonCaption" }) })
                              }],
                              initialValue: null
                            })(
                              <TextArea rows={5} style={{ width: "100%" }} />
                            )}
                        </div>
                      </div>
                    </Form.Item>
                  </Row>
                </Form>
              </fieldset>
              <CostsComponent assignmentsList={this.dataCollectionAssignment.items} learningBudget={this.learningBudget}
                form={this.props.form} perdiemsSettings={this.perdiemsSettings} intl={this.props.intl} costs={this.costDataInstance.item}
                daysCount={this.daysCount} employeeCount={this.employeeCount} />
            </div>
          </Card>
        </div>
      </Page>
    );
  }

  componentDidMount() {
    getCubaREST()!.loadEntity(LearningBudget.NAME, this.props.budgetId, { view: 'learningBudget-front-view' })
      .then(value => {
        this.learningBudget = value as LearningBudget;

        getCubaREST()!.loadEntity(PersonGroupExt.NAME, this.props.rootStore!.userInfo!.personGroupId!, { view: '_minimal' })
          .then(value => {
            this.currentUser = value;
            this.selectedOrganization = this.props.rootStore!.learningBudgetInfo.selectedOrganization;
            if (this.selectedOrganization) {
              getCubaREST()!.loadEntity(OrganizationExt.NAME, this.selectedOrganization.organization!.id, { view: 'organization-for-budget-request-view' })
                .then(val => {
                  this.selectedBaseOrganization = val;
                })
            }
            this.organizationGroupList = Array.of(this.selectedOrganization);
            this.learningBudgetRequestHeader = this.props.rootStore!.learningBudgetInfo.currentLearningBudgetRequestHeader;

            this.costDataInstance.setItem(new LearningBudgetRequestCost());

            if (this.selectedOrganization) {
              restServices.learningBudgetService.findReportGroupByOrganizationGroupId({ organizationGroupId: this.selectedOrganization.id })
                .then(val => {
                  getCubaREST()!.loadEntity(OrganizationGroupExt.NAME, val, { view: '_minimal' })
                    .then(reportGroupRes => {
                      this.reportGroup = reportGroupRes;
                    })
                })
            }

            restServices.assignmentService.getAssignmentsByListOfOrganization({ organizationGroupExtList: this.organizationGroupList })
              .then(values => {
                this.assignmentsList = (values as SerializedEntity<AssignmentExt>[]).sort((a, b) => {
                  if ((a.personGroup! as SerializedEntity<PersonGroupExt>)._instanceName < (b.personGroup! as SerializedEntity<PersonGroupExt>)._instanceName) {
                    return -1;
                  }
                  if ((a.personGroup! as SerializedEntity<PersonGroupExt>)._instanceName > (b.personGroup! as SerializedEntity<PersonGroupExt>)._instanceName) {
                    return 1;
                  }
                  return 0;
                });
              })
          });
      });

  }


}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(LearningBudgetRequestGroupCreate)));

