import { StandardEntity } from "./sys$StandardEntity";
import { JobGroup } from "./tsadv$JobGroup";
import { AdaptationPlan } from "./tsadv_AdaptationPlan";
export class AdaptationTask extends StandardEntity {
  static NAME = "tsadv_AdaptationTask";
  assignment?: string | null;
  assignmentLang1?: string | null;
  assignmentLang2?: string | null;
  assignmentLang3?: string | null;
  achievedResults?: string | null;
  achievedResultsLang1?: string | null;
  achievedResultsLang2?: string | null;
  achievedResultsLang3?: string | null;
  expectedResults?: string | null;
  expectedResultsLang1?: string | null;
  expectedResultsLang2?: string | null;
  expectedResultsLang3?: string | null;
  jobGroup?: JobGroup | null;
  adaptationPlan?: AdaptationPlan | null;
}
export type AdaptationTaskViewName = "_base" | "_local" | "_minimal";
export type AdaptationTaskView<
  V extends AdaptationTaskViewName
> = V extends "_base"
  ? Pick<
      AdaptationTask,
      | "id"
      | "assignmentLang1"
      | "assignmentLang2"
      | "assignmentLang3"
      | "achievedResultsLang1"
      | "achievedResultsLang2"
      | "achievedResultsLang3"
      | "expectedResultsLang1"
      | "expectedResultsLang2"
      | "expectedResultsLang3"
    >
  : V extends "_local"
  ? Pick<
      AdaptationTask,
      | "id"
      | "assignmentLang1"
      | "assignmentLang2"
      | "assignmentLang3"
      | "achievedResultsLang1"
      | "achievedResultsLang2"
      | "achievedResultsLang3"
      | "expectedResultsLang1"
      | "expectedResultsLang2"
      | "expectedResultsLang3"
    >
  : V extends "_minimal"
  ? Pick<AdaptationTask, "id">
  : never;
