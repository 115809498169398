import { StandardEntity } from "./sys$StandardEntity";
import { EmployeeQuestionnaireResults } from "./tsadv_EmployeeQuestionnaireResults";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { EmployeeQuestionnaire } from "./tsadv_EmployeeQuestionnaire";
export class EmployeeQuestionnaireParticipants extends StandardEntity {
  static NAME = "tsadv_EmployeeQuestionnaireParticipants";
  results?: EmployeeQuestionnaireResults[] | null;
  personGroup?: PersonGroupExt | null;
  status?: boolean | null;
  employeeQuestionnaire?: EmployeeQuestionnaire | null;
}
export type EmployeeQuestionnaireParticipantsViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "employeeQuestionnaireParticipants-browseView";
export type EmployeeQuestionnaireParticipantsView<
  V extends EmployeeQuestionnaireParticipantsViewName
> = V extends "_base"
  ? Pick<EmployeeQuestionnaireParticipants, "id" | "status">
  : V extends "_local"
  ? Pick<EmployeeQuestionnaireParticipants, "id" | "status">
  : V extends "employeeQuestionnaireParticipants-browseView"
  ? Pick<
      EmployeeQuestionnaireParticipants,
      "id" | "status" | "results" | "personGroup" | "employeeQuestionnaire"
    >
  : never;
