import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import VacancyBrowse from "./VacancyBrowse";
import CandidateJobRequestBrowse from "./CandidateJobRequestBrowse";

type Props = RouteComponentProps<{ entityId?: string, status?: string}>;

@observer
export class CandidateJobRequestManagement extends React.Component<Props> {
  static PATH = "/job-requests";

  render() {
    const {entityId, status} = this.props.match.params;
    return (
      <>
        <CandidateJobRequestBrowse entityId={entityId} status={status}/>
      </>
    );
  }
}