import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Alert, Checkbox, List, Typography} from "antd"
import { Card } from 'antd';
import {observable, runInAction} from "mobx";
import {Successor} from "../../../cuba/entities/base/tsadv$Successor";
import Img from "../../components/Img";
import {getBlobUrl} from "../../util/util";
import moment from "moment"
import {CSSProperties} from "react";
import {SuccessionPlanning} from "../../../cuba/entities/base/tsadv$SuccessionPlanning";
import {SerializedEntity} from "@cuba-platform/rest";
import {KeyPositionManagement} from "./KeyPosition/KeyPositionManagement";
import {SuccessorRequestManagement} from "../SuccessorRequest/SuccessorRequestManagement";
import {LmsSliderImage} from "../../../cuba/entities/base/tsadv$LmsSliderImage";

const counterStyle:CSSProperties = {marginLeft:"auto",marginRight:"auto",width:"60%",height:"auto", textAlign:"center", verticalAlign:"middle", borderColor:"black", borderStyle:"solid", padding:"10px",marginTop:"30px" }


@injectMainStore
@inject("rootStore")
@observer
export class TalentPoolProgramComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & RouteComponentProps<any>> {
static PATH = "/talentPoolProgram";
  @observable loading:boolean = true
  dataCollection = collection<Successor>(Successor.NAME,{
    view:"successor-talentPool",
    loadImmediately:true,
    filter:{
      conditions:[{
        property:"succession.positionGroup.company.code",
        operator:"=",
        value:this.props.rootStore!.userInfo!.companyCode!
      } ]
    }
  })
  @observable data:Array<SerializedEntity<Successor>>

  successionPlanning = collection<SuccessionPlanning>(SuccessionPlanning.NAME,{
    view:"_local",
    filter:{
      conditions:[{
        property:"positionGroup.company.code",
        operator:"=",
        value:this.props.rootStore!.userInfo!.companyCode!
      }]
    }
  })

   reservistCount:number = 0;
   successionPlanningCount:number = 0;
   @observable currentDataSource:Array<SerializedEntity<Successor>>
  @observable randomData:Array<SerializedEntity<Successor>>
  @observable slicedData:Array<SerializedEntity<Successor>>
  @observable isLoading:boolean = true
  @observable imageUrl:string;
   @observable checked:boolean = false;

  render() {
    if(this.successionPlanning.status==="DONE"){
      this.successionPlanningCount = this.successionPlanning.items.length
    }
  return (
    <div style={{height:"auto"}}>
    <div style={{height:200}}>
      {this.imageUrl? <img style={{height:150,marginRight:"auto",marginLeft:"auto",display:"block"}} src={this.imageUrl}/>:<></>}
    </div>
      <Typography.Title style={{margin:"20px"}} level={2}>{this.props.intl.formatMessage({id:this.checked?"bestReservists":"LDPCandidates"})}</Typography.Title>
      <div id={"card"} style={{width:"auto",display:"flex"}}>
        <Card style={{marginLeft:"30px", width:"70%"}} bordered={false}>
          <List
            itemLayout="horizontal"
            dataSource={this.checked?this.data&&this.data.length>0&&this.data.filter(d=>d.bestReservist).slice(0,5)||[]:this.slicedData||[]}
            size={"large"}
            loading={this.isLoading}
            style={{height: "auto", overflow: 'auto', width:"100%"}}
            loadMore={<div style={{marginTop:"20px",width:"100%",textAlign:"center",fontSize:20}}><Link to={"/talentPoolProgram-list"}><u>{this.props.intl.formatMessage({id:"showFullList"})}</u></Link></div>}
            renderItem={item =>(
              <List.Item>
                <List.Item.Meta
                  avatar={<div style={{height:"60px",width:"auto"}}><Img style={{height:"60px",width:"auto"}} type="promise" src={getBlobUrl(item.personGroup&&item.personGroup.list&&item.personGroup.list.length>0&&item.personGroup.list[0].image&&item.personGroup.list[0].image.id)}/></div>}
                  title={item.personGroup!['_instanceName']!}
                  description={
                      <div style={{width:"100%",display:"inline-flex"}}>
                      <div style={{width:"65%"}}>
                    <div>{item.succession&&item.succession['_instanceName']?item.succession['_instanceName']:""}</div>
                    <div>{item.succession&&item.succession.positionGroup&&item.succession.positionGroup.organizationGroup?item.succession!.positionGroup!.organizationGroup!['_instanceName']:""}</div>
                        </div>
                      <div style={{width:"auto", marginLeft:"20px"}}>
                    <div>{this.props.intl.formatMessage({id:"inProgramFrom"}) +(item.startDate? moment(item.startDate).format('L'):"")}</div>
                        <div>{this.props.intl.formatMessage({id:"inCompanyFrom"})+(item.personGroup&& item.personGroup&& item.personGroup.list&&item.personGroup.list.length>0 &&item.personGroup.list[0].startDate? moment(item.personGroup.list[0].startDate).format('L'):"")}</div>
                      </div>
                      </div>
                  }
                />
              </List.Item>
            )}
          />
        </Card>
        <div style={{width:"30%"}}>
          <div style={{marginLeft:"auto",marginRight:"auto",width:"60%",textAlign:"center"}}>
            <Typography.Title level={4}><FormattedMessage id="showBest"/><Checkbox className="successors-checkbox" onChange={(e)=>runInAction(()=>this.checked = e.target.checked)}/></Typography.Title>
          </div>
          <div style={counterStyle}>
            <Typography.Text>{this.props.intl.formatMessage({id:"positionCount"})}</Typography.Text>
            <Typography.Title level={4} style={{marginTop:10}}>{this.successionPlanningCount}</Typography.Title>

          </div>
          <div style={counterStyle  }>
            <Typography.Text>{this.props.intl.formatMessage({id:"reservistCount"})}</Typography.Text>
            <Typography.Title level={4} style={{marginTop:10}}>{this.reservistCount}</Typography.Title>

          </div>
          <Alert
              message={this.props.intl.formatMessage({id:"wantToBeReservist"})}
              description={<Link to={KeyPositionManagement.PATH} > <u>{this.props.intl.formatMessage({id:"clickHere"})}</u></Link>}
              type="info"
              style={{width:"60%",height:"auto", marginTop:"30px",marginLeft:"auto",marginRight:"auto"}}
              showIcon
          />
          <div style={{width:"60%", marginTop:"30px",marginLeft:"auto",marginRight:"auto", textAlign:"center"}}><Link to={SuccessorRequestManagement.PATH}><u>{this.props.intl.formatMessage({id:"mySuccessorRequests"})}</u></Link></div>
        </div>
      </div>
    </div>
  )
}
componentDidMount() {
     getCubaREST()!.searchEntities(Successor.NAME,{
       conditions:[{
         property:"succession.positionGroup.company.code",
         operator:"=",
         value:this.props.rootStore!.userInfo!.companyCode!
       },
         {
           group: "AND",
           conditions: [
             {
               property: "status",
               operator: "<>",
               value:""
             },
             {
               property: "succession.positionGroup.company.code",
               operator: "=",
               value: this.props.rootStore!.userInfo!.companyCode!
             },
             {
               property: "status.code",
               operator: "<>",
               value: "REJECTED"
             },
             {
               property: "status.code",
               operator: "<>",
               value: "OUT_OF_LDP"
             },
             {
               property: "status.code",
               operator: "<>",
               value: "TERMINATED"
             },
             {
               property: "status.code",
               operator: "<>",
               value: "PROMOTED"
             }
           ]
         }
         ,
         {
           property:"succession.positionType",
           operator:"=",
           value:"INCLUDE"
         }
       ]
     },{view:"successor-talentPool"}).then(value =>{this.data = value; this.reservistCount = this.data.length; this.randomData = this.data.sort(() => 0.5 - Math.random());this.slicedData = this.randomData.slice(0,5);}).catch().finally(()=>runInAction(()=>this.isLoading = false))
    getCubaREST()!.searchEntities<LmsSliderImage>(LmsSliderImage.NAME,{
      conditions:[{
        property:"slider.position.code",
        operator:"=",
        value:"LDP"
      }]
    },{sort:"-updateTs",view:"lmsSliderImage.with.image"}).then(res=>{getBlobUrl(res.length>0&&res[0]!.image!.id).then(url=>runInAction(()=>this.imageUrl = url))})

  }
}

const TalentPoolProgram = React.memo(injectIntl(TalentPoolProgramComponent));

export default TalentPoolProgram;