import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter} from "react-router-dom";

import {action, observable, runInAction} from "mobx";

import {Button, Card, Dropdown, Empty, Menu, Spin} from "antd";

import {getCubaREST, injectMainStore, MainStoreInjected, Msg} from "@cuba-platform/react";

import {InsuredPerson} from "../../../cuba/entities/base/tsadv$InsuredPerson";
import {InsuredPersonManagement} from "./InsuredPersonManagement";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {RouteComponentProps} from "react-router";
import {Table} from "antd/es";
import Column from "antd/lib/table/Column";
import {DEFAULT_DATE_PARSE_FORMAT, restServices} from "../../../cuba/services";
import {InsuranceContract} from "../../../cuba/entities/base/tsadv$InsuranceContract";
import moment from "moment";
import {DEFAULT_DATE_PATTERN} from "../../util/Date/Date";
import {SerializedEntity} from "@cuba-platform/rest";

@injectMainStore
@inject("rootStore")
@observer
class InsuredPersonListComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & RouteComponentProps<any>> {
  // dataCollection = collection<InsuredPerson>(InsuredPerson.NAME, {
  //   view: "insuredPerson-browseView",
  //   sort: "-updateTs",
  //   filter: {
  //     conditions: [
  //       {
  //         property: "employee.id",
  //         operator: "=",
  //         value: this.props.rootStore!.userInfo.personGroupId!
  //       },
  //       {
  //         property: "type",
  //         operator: "=",
  //         value: "EMPLOYEE"
  //       }
  //     ]
  //   }
  // });

  @observable items: InsuranceContract[];

  fields = [
    "documentNumber",
    "insuranceContract",
    "attachDate",
    "statusRequest",
    "totalAmount",
  ];

  @observable selectedRowKey: string | undefined;
  @observable disabled:boolean = true;
  @observable selectedRow:InsuredPerson;
  @action changeSelectedRow = (value:InsuredPerson) =>{
    this.selectedRow = value;
    this.disabled = !(this.selectedRow.insuranceContract && moment().isBetween(moment(this.selectedRow.insuranceContract.availabilityPeriodFrom), moment(this.selectedRow.insuranceContract.availabilityPeriodTo), 'days'));
  }
  @observable menuList: SerializedEntity<InsuranceContract>[];


  render() {
    const menu = (
      <Menu>

        {this.menuList&&this.menuList.length>0?this.menuList.map(i=><Menu.Item><Link to={InsuredPersonManagement.PATH + "/new"+"/" +i.id}><strong>{i.contract}</strong></Link></Menu.Item>):
          !this.menuList?<div style={{width:"100%",textAlign:"center"}}><Spin spinning/></div>:<Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
      </Menu>
    )
    const buttons = [
      <span style={{margin:12}}>
      <Dropdown trigger={['click']} overlay={menu} placement={"bottomLeft"}>
        <Button
          htmlType="button"
          type="primary"
          key={'insurance'}
        >
          <FormattedMessage id="join.health.insurance"/>
        </Button>
      </Dropdown>
      </span>,
      <Button
        htmlType="button"
        style={{margin: "12px"}}
        type="primary"
        key={'members'}
        disabled={this.disabled}
        onClick={this.subscribeFamilyMemberToMIC}
      >
        <FormattedMessage id="attach.family.members"/>
      </Button>
    ];

    return (
      <Card style={{margin: "10px"}}>
        <Spin spinning={this.items === undefined}>
          {buttons}
          <Table
            dataSource={this.items}
            rowKey={record => record.id}
            onRowClick={record => this.changeSelectedRow(record)}
            rowClassName={(record) => record.id===(this.selectedRow&&this.selectedRow.id)?"ant-table-row ant-table-row-level-0 ant-table-row-selected":"ant-table-row ant-table-row-level-0"}
          >
            <Column
              title={<Msg entityName={InsuranceContract.NAME} propertyName='contract'/>}
              dataIndex="insuranceContract.contract"
              render={(text, record: InsuredPerson) => (
                <Link to={InsuredPersonManagement.PATH + "/" + record.id}>{record.insuranceContract!.contract!}</Link>
              )}
            />

            <Column
              title={<Msg entityName={InsuranceContract.NAME} propertyName='startDate'/>}
              dataIndex="insuranceContract.startDate"
              render={(text, record: InsuredPerson) => (
                (React.createElement("div", null, moment(record.insuranceContract!.startDate!, DEFAULT_DATE_PARSE_FORMAT).format(DEFAULT_DATE_PATTERN)))
              )}
            />

            <Column
              title={<Msg entityName={InsuranceContract.NAME} propertyName='expirationDate'/>}
              dataIndex="insuranceContract.expirationDate"
              render={(text, record: InsuredPerson) => (
                (React.createElement("div", null, moment(record.insuranceContract!.expirationDate!, DEFAULT_DATE_PARSE_FORMAT).format(DEFAULT_DATE_PATTERN)))
              )}
            />

            <Column
              title={<Msg entityName={InsuredPerson.NAME} propertyName='attachDate'/>}
              dataIndex="attachDate"
              render={(text, record: InsuredPerson) => (
                (React.createElement("div", null, moment(record.attachDate!, DEFAULT_DATE_PARSE_FORMAT).format(DEFAULT_DATE_PATTERN)))
              )}
            />

            <Column
              title={<Msg entityName={InsuredPerson.NAME} propertyName='statusRequest'/>}
              dataIndex="statusRequest"
              render={(text, record: InsuredPerson) => (
                (React.createElement("div", null, record.statusRequest!.langValue!))
              )}
            />

            <Column
              title={<Msg entityName={InsuredPerson.NAME} propertyName='totalAmount'/>}
              dataIndex="totalAmount"
              render={(text, record: InsuredPerson) => (
                (React.createElement("div", null, record.totalAmount!))
              )}
            />

          </Table>
        </Spin>
      </Card>
    );
  }


  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };


  subscribeToMIC = () => {
    this.props.history.push(InsuredPersonManagement.PATH + "/" + InsuredPersonManagement.NEW_SUBPATH);
  };

  subscribeFamilyMemberToMIC = () => {
    this.props.history.push(InsuredPersonManagement.PATH + "/" + this.selectedRow.id);
  };

  componentDidMount(): void {
    restServices.documentService.getMyInsuraces().then(val => {
      this.items =val.sort((a, b) => Date.parse((b.insuranceContract&&b.insuranceContract.startDate)) - Date.parse((a.insuranceContract&&a.insuranceContract.startDate)))
      getCubaREST()!.searchEntities<InsuranceContract>(InsuranceContract.NAME,{
        conditions:[{
          property:"company.code",
          operator:"=",
          value:this.props.rootStore!.userInfo.companyCode!
        },
          {
            property:"id",
            operator:"notIn",
            value:val.map(a=>(a.insuranceContract&&a.insuranceContract.id))
          },
          {
            property:"availabilityPeriodFrom",
            operator:"<=",
            value:moment().toISOString()
          },
          {
            property:"availabilityPeriodTo",
            operator:">=",
            value:moment().toISOString()
          }
        ]
      }).then(res=>runInAction(()=>this.menuList=res))
    })

  }
}

const InsuredPersonList = withRouter(injectIntl(InsuredPersonListComponent));

export default InsuredPersonList;
