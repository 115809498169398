import React, { createElement} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {collection, getCubaREST, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";
import {withRouter} from "react-router-dom";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {BeneficiaryRequest} from "../../../cuba/entities/base/tsadv_BeneficiaryRequest";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import LoadingPage from "../LoadingPage";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {Card, Col, Form, Input, Row, Select, Spin} from "antd";
import Button, {ButtonType} from "../../components/Button/Button";
import {goBackOrHomePage, isEquals} from "../../util/util";
import {ReadonlyField} from "../../components/ReadonlyField";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {Beneficiary} from "../../../cuba/entities/base/tsadv$Beneficiary";
import moment from "moment";
import {PersonDocument} from "../../../cuba/entities/base/tsadv$PersonDocument";
import {PersonDocumentRequestManagement} from "../PersonDocumentRequest/PersonDocumentRequestManagement";
import {formatDate} from "../../util/Date/Date";
import {DicRelationshipType} from "../../../cuba/entities/base/tsadv$DicRelationshipType";
import {DicAddressType} from "../../../cuba/entities/base/tsadv$DicAddressType";
import {DicCountry} from "../../../cuba/entities/base/base$DicCountry";
import {DicStreetType} from "../../../cuba/entities/base/tsadv_DicStreetType";
import {SearchSelect} from "../../components/SearchSelect";
import {DicKato} from "../../../cuba/entities/base/tsadv_DicKato";
import {SerializedEntity} from "@cuba-platform/rest/dist-node/model";
import {DicEducationDocumentType} from "../../../cuba/entities/base/tsadv_DicEducationDocumentType";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

type EditorProps = {
  entityId: string;
  statusId?:string;
};

@inject("rootStore")
@injectMainStore
@observer
class MartialStatusRequestEditComponent extends AbstractBprocEdit<BeneficiaryRequest,EditorProps> {
  processDefinitionKey = "beneficiaryRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<BeneficiaryRequest>(BeneficiaryRequest.NAME, {
    view: "beneficiaryRequest-for-integration",
    loadImmediately: false
  });

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {view: "_minimal"});

  relationshipTypeDc = (this.isUserInitiator || this.props.entityId === 'new') ?
    collection<DicRelationshipType>(DicRelationshipType.NAME,{
      view:"dicRelationshipType-browse",
      filter:{
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        }]
      }
    }):
    collection<DicRelationshipType>(DicRelationshipType.NAME,{view:"_local"})

  relationshipTypeEmptyDc = (this.isUserInitiator || this.props.entityId === 'new') ?
    collection<DicRelationshipType>(DicRelationshipType.NAME,{
      view:"dicRelationshipType-browse",
      filter:{
        conditions:[{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]
      }
    }):
    collection<DicRelationshipType>(DicRelationshipType.NAME,{view:"_local"})

  addressTypesDc  = (this.isUserInitiator || this.props.entityId === 'new') ?  collection<DicAddressType>(DicAddressType.NAME, {
  view: "dicAddressType-edit",
  sort: this.dicLangValue,
  filter:{
    conditions:[{
      group:"OR",
      conditions:[{
        property:'company.code',
        operator:"=",
        value:this.props.rootStore!.userInfo!.companyCode!
      },{
      property:'company.code',
      operator:"=",
      value: "empty"
    }]

  }]
}
}): collection<DicEducationDocumentType>(DicEducationDocumentType.NAME, {
  view: "_minimal"
});

  countrysDc = collection<DicCountry>(DicCountry.NAME, {
    view: "_local",
    sort: this.dicLangValue,
    filter: {
      conditions: [{
        value: 'TRUE',
        operator: '=',
        property: 'active'
      }]
    }
  });
  katosDc = collection<DicKato>(DicKato.NAME,
    {view: "_minimal", loadImmediately: false, sort: this.dicLangValue});
  streetTypesDc = collection<DicStreetType>(DicStreetType.NAME, {
    view: "_minimal",
    sort: this.dicLangValue
  });

  @observable
  editMartialStatus: Beneficiary;

  @observable
  isKatoRequired = false;

  instanceEditMartialStatus = instance<Beneficiary>(Beneficiary.NAME, {
    view: "beneficiaryView",
    loadImmediately: false
  });


  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;


  @observable
  approverHrRoleCode: string;

  personGroupId: string;

  @observable
  mainStore = this.props.mainStore!;

  isUpdateBeforeOutcome = true;

  fields = [
    "requestNumber",

    "requestDate",

    "status",

    "firstName",

    "lastName",

    "middleName",

    "lastNameLatin",

    "firstNameLatin",

    "birthDate",

    "addressKatoCode",

    "workLocation",

    "building",

    "relationshipType",

    "block",

    "flat",

    "addressForExpats",

    "additionalContact",

    "addressType",

    "postalCode",

    "country",

    "addressKATOCode",

    "streetType",

    "streetName",

    "files"
  ];

  @observable
  filterdPositionGroup:string = this.editMartialStatus && this.editMartialStatus.addressType ? this.editMartialStatus.addressType['_instanceName'] || '' : '';


  onChangePosition=(e:any)=>{
    this.filterdPositionGroup = e;

  }



  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.personGroupId,
      beneficiary: this.editMartialStatus ? this.editMartialStatus.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {
    if (!this.mainStore) {return <LoadingPage/>}

    const messages = this.mainStore.messages!;
    if (!messages) return <LoadingPage/>

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    return (
      <div>
        <ScrollToTopOnMount />
        <Page pageName={<FormattedMessage id={"beneficiary.title"}/> }>

        <Section className='large'>
          <Card
            className="narrow-layout card-actions-container"
            bordered={false}
            actions={[
              <Button buttonType={ButtonType.FOLLOW}
                      onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
              this.getOutcomeBtns()
            ]}
          >
            <Spin spinning={this.dataInstance.status === 'LOADING' }>

            <Form layout="vertical">
              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
                getFieldDecoratorOpts={{
                  rules:[{
                    required: !isNotDraft
                  }]
                }}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
                getFieldDecoratorOpts={{
                  rules:[{
                    required: !isNotDraft
                  }]
                }}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statusesDc}
                getFieldDecoratorOpts={{
                  rules:[{
                    required: !isNotDraft
                  }]
                }}
              />

              <Form.Item
                required={true}
                label={createElement(Msg, {entityName: entityName, propertyName: "personGroup"})}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>
              {
                this.editMartialStatus?
                  <Row type={"flex"} className={"data-form"}>
                    <Col  md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderMartialStatus()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderMartialStatusRequest()}
                    </Col>
                  </Row> :this.renderMartialStatusRequest()

              }
              <ReadonlyField
                entityName={entityName}
                propertyName="files"
                disabled={isNotDraft}
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                getFieldDecoratorOpts={{
                  rules:[{
                    required: !isNotDraft
                  }]
                }}
              />
            </Form>
            {this.takCard()}
        </Spin>
          </Card>
        </Section>

      </Page>
      </div>

    );
  }
  renderMartialStatus = ()=>{
    const entityName = this.dataInstance.entityName;
    return(
      <div>
        <Form.Item
        label={createElement(Msg, {entityName: entityName, propertyName: "relationshipType"})}>
          <Input
            value={this.editMartialStatus && this.editMartialStatus.relationshipType ? this.editMartialStatus.relationshipType['_instanceName']|| "": ""}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "lastName"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.lastName || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "firstName"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.firstName || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"beneficiary.middleName"}/>}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.middleName || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "lastNameLatin"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.middleName || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "lastNameLatin"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.middleName || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "birthDate"})}>
          <Input
            value={formatDate(this.editMartialStatus.birthDate)}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "workLocation"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.workLocation || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "addressType"})}>
          <Input
            value={this.editMartialStatus && this.editMartialStatus.addressType ? this.editMartialStatus.addressType['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "postal"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.postalCode || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "country"})}>
          <Input
            value={this.editMartialStatus && this.editMartialStatus.country ? this.editMartialStatus.country['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={ <FormattedMessage id={"beneficiary.addressKato"} /> }>
          <Input value={this.editMartialStatus && this.editMartialStatus.addressKATOCode ? this.editMartialStatus.addressKATOCode['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "streetType"})}>
          <Input
            value={this.editMartialStatus && this.editMartialStatus.streetType ? this.editMartialStatus.streetType['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "streetName"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.streetName || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "building"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.building || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "block"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.block || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "flat"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.flat || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "addressForExpats"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.addressForExpats || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "additionalContact"})}>
          <Input
            value={this.editMartialStatus ? this.editMartialStatus.additionalContact || '' : ''}
            disabled/>
        </Form.Item>
      </div>
    )
  }
  renderMartialStatusRequest = ()=>{
    const messages = this.mainStore.messages!;
    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();
    let arrayWithDefaultCode:any = {items:[]};
    let arrayWithCode:any = {items:[]};
    this.addressTypesDc.items.forEach(item=>{
      if(item && item.company! && item.company!.code !=="empty"){
        arrayWithCode.items && arrayWithCode.items.push(item)
      }else arrayWithDefaultCode.items && arrayWithDefaultCode.items.push(item)
    })
    return(
      <div>
        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"relationshipType"}
          optionsContainer={(this.relationshipTypeDc && this.relationshipTypeDc.items &&  this.relationshipTypeDc.items.length>0) ? this.relationshipTypeDc : this.relationshipTypeEmptyDc}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules:[{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.relationshipType']})
            }],
            getValueFromEvent:args=>{

              if(this.editMartialStatus)
                this.changedMap.set('relationshipType', args !== (this.editMartialStatus!.relationshipType ? this.editMartialStatus!.relationshipType!.id : undefined))
              return args
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('relationshipType'),
            validateStatus: "success",
          }}
        />

        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"lastName"}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules:[{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.firstName']})
            }],
            getValueFromEvent:args=>{
              const value = args.currentTarget.value;
              if(this.editMartialStatus)
                this.changedMap.set('lastName', value !== this.editMartialStatus.firstName)
              return value
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('lastName'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"firstName"}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules:[{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.firstName']})
            }],
            getValueFromEvent:args=>{
              const value = args.currentTarget.value;
              if(this.editMartialStatus)
                this.changedMap.set('firstName', value !== this.editMartialStatus.firstName)
              return value
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('firstName'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"middleName"}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            getValueFromEvent:args=>{
              const value = args.currentTarget.value;
              if(this.editMartialStatus)
                this.changedMap.set('middleName', value !== this.editMartialStatus.middleName)
              return value
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('middleName'),
            validateStatus: "success",
            label:this.props.intl.formatMessage({id:"beneficiary.middleName"})
          }}
        />
        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"lastNameLatin"}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules:[{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.lastNameLatin']})
            }],
            getValueFromEvent:args=>{
              const value = args.currentTarget.value;
              if(this.editMartialStatus)
                this.changedMap.set('lastNameLatin', value !== this.editMartialStatus.lastNameLatin)
              return value
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('lastNameLatin'),
            validateStatus: "success",
            label: this.props.intl.formatMessage({id: "beneficiary.lastName.latin"})
          }}
        />
        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"firstNameLatin"}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules:[{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.firstNameLatin']})
            }],
            getValueFromEvent:args=>{
              const value = args.currentTarget.value;
              if(this.editMartialStatus)
                this.changedMap.set('firstNameLatin', value !== this.editMartialStatus.firstNameLatin)
              return value
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('firstNameLatin'),
            validateStatus: "success",
            label: this.props.intl.formatMessage({id: "beneficiary.firstName.latin"})
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="birthDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.birthDate']})
            }],
            getValueFromEvent: args => {
              if (this.editMartialStatus)
                this.changedMap.set('birthDate', this.editMartialStatus.birthDate ? !moment(this.editMartialStatus.birthDate).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('birthDate'),
          }}
        />
        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"workLocation"}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            getValueFromEvent:args=>{
              const value = args.currentTarget.value;
              if(this.editMartialStatus)
                this.changedMap.set('workLocation', value !== this.editMartialStatus.workLocation)
              return value
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('workLocation'),
            validateStatus: "success"
          }}
        />

        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"addressType"}
          optionsContainer={arrayWithCode.items && arrayWithCode!.items!.length === 0 ? arrayWithDefaultCode : arrayWithCode}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules:[{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.addressType']})
            }],
            getValueFromEvent:args=>{
              if (this.editMartialStatus)
                this.changedMap.set('addressType', args !== (this.editMartialStatus.addressType ? this.editMartialStatus.addressType.id : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('addressType'),
            label: this.props.intl.formatMessage({id: "beneficiary.address.type"}),

          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="postalCode"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editMartialStatus)
                this.changedMap.set('postalCode', value !== this.editMartialStatus.postalCode);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('postalCode'),
            validateStatus: "success",
            label: this.props.intl.formatMessage({id: "beneficiary.postal"})
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="country"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={this.countrysDc}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.country']})
            }],
            getValueFromEvent: args => {
              const country = this.countrysDc.items.find(value => value.id === args);
              this.isKatoRequired = !!(country && country.code === 'KZ');
              if (this.editMartialStatus)
                this.changedMap.set('country', args !== (this.editMartialStatus.country ? this.editMartialStatus.country.id  : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('country'),
            label: this.props.intl.formatMessage({id: "beneficiary.country"})
          }}
        />
        <Form.Item
          hasFeedback={this.changedMap.get('addressKATOCode')}
          label={<FormattedMessage id={"beneficiary.addressKato"}/>}>
          {this.props.form.getFieldDecorator("addressKATOCode", {
            getValueFromEvent: args => {
              if (this.editMartialStatus)
                this.changedMap.set('addressKATOCode', args !== (this.editMartialStatus.addressKATOCode ? this.editMartialStatus.addressKATOCode.id : undefined));
              return args;
            }
          })(<SearchSelect onSearch={this.onSearchKato}
                           disabled={isNotDraft}
                           placeholder={this.props.intl.formatMessage({id: 'search'})}
                           loading={this.katosDc.status === 'LOADING'}
                           options={this.katosDc && this.katosDc.items.map(d => <Select.Option
                             key={d.id!}>{d._instanceName}</Select.Option>)}/>)
          }
        </Form.Item>

        <ReadonlyField
          entityName={entityName}
          propertyName="streetType"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={this.streetTypesDc}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.streetType']})
            }],
            getValueFromEvent: args => {
              if (this.editMartialStatus)
                this.changedMap.set('streetType', args !== (this.editMartialStatus.streetType ? this.editMartialStatus.streetType.id : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('streetType'),
            label: this.props.intl.formatMessage({id: "beneficiary.street.type"})
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="streetName"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={this.streetTypesDc}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.streetName']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value
              if (this.editMartialStatus)
                this.changedMap.set('streetName', value !== (this.editMartialStatus.streetName ));
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('streetName'),
            label: this.props.intl.formatMessage({id: "beneficiary.street.name"})
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName='building'
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editMartialStatus)
                this.changedMap.set('building', value !== this.editMartialStatus.building);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('building'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName='block'
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editMartialStatus)
                this.changedMap.set('block', value !== this.editMartialStatus.block);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('block'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName='flat'
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editMartialStatus)
                this.changedMap.set('flat', value !== this.editMartialStatus.flat);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('flat'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          disabled={isNotDraft}
          propertyName='addressForExpats'
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editMartialStatus)
                this.changedMap.set('addressForExpats', value !== this.editMartialStatus.addressForExpats);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('addressForExpats'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          form={this.props.form}
          entityName={entityName}
          propertyName={"additionalContact"}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            getValueFromEvent:args=>{
              const value = args.currentTarget.value;
              if(this.editMartialStatus)
                this.changedMap.set('additionalContact', value !== this.editMartialStatus.additionalContact)
              return value
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('additionalContact'),
            validateStatus: "success"
          }}
        />
      </div>
    )
  }
  onSearchKato = (value: string) => {
    this.katosDc.items = [];
    if (value && value.length >= 4) {
      this.katosDc.filter = {
        conditions: [
          {
            property: this.dicLangValue,
            operator: 'contains',
            value: value
          }
        ]
      }

      this.katosDc.load();
    }
  };

  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: BeneficiaryRequest | undefined) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();
    if (item && item.addressKatoCode) {
      this.katosDc.items = [item.addressKatoCode as SerializedEntity<DicKato>];
      this.katosDc.status = 'DONE';
    }

    this.isKatoRequired = !!(item && item.country && item.country.code === 'KZ');
    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]

    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const statusId = this.props.statusId || (item && item.beneficiary ? item.beneficiary.id : undefined)

    if (statusId)
      getCubaREST()!.loadEntity(Beneficiary.NAME, statusId, {view: 'beneficiaryView'})
        .then(value => this.editMartialStatus = value as PersonDocument)
        .then(value => {
          this.instanceEditMartialStatus.setItem(value);
          const properties = [

            "firstName",

            "lastName",

            "middleName",

            "lastNameLatin",

            "firstNameLatin",

            "birthDate",

            "addressKatoCode",

            "workLocation",

            "building",

            "relationshipType",

            "block",

            "flat",

            "additionalContact",

            "addressForExpats",

            "addressType",

            "postalCode",

            "country",

            "addressKATOCode",

            "streetType",

            "streetName",

            "files"
          ];
          if (this.props.entityId === PersonDocumentRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditMartialStatus.getFieldValues(properties));
            if (this.instanceEditMartialStatus.item && this.instanceEditMartialStatus.item.addressKATOCode)
              this.katosDc.items = [this.instanceEditMartialStatus.item.addressKATOCode as SerializedEntity<DicKato>]
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }
  componentDidMount() {
    super.componentDidMount();
    this.relationshipTypeDc.load()
    if( this.relationshipTypeDc && this.relationshipTypeDc.items && this.relationshipTypeDc.items!.length<0){
      this.relationshipTypeEmptyDc.load()
    }
  }

}


export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(MartialStatusRequestEditComponent))
);