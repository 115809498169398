import * as React from "react";
import {RouteComponentProps} from "react-router";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import IndividualDevolopmentPlanEdit from "./IndividualDevolopmentPlanEdit";

type Props = RouteComponentProps<{ entityId?: string}>;

@inject("rootStore")
@injectMainStore
@observer
export class IndividualDevolopmentPlanManagement extends React.Component< Props> {

  static PATH = "/individualDevelopmentPlan";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId} = this.props.match.params
    return (

        <>
          <IndividualDevolopmentPlanEdit entityId={entityId}/>
        </>

    );
  }
}