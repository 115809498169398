import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import PersonExperienceRequestEdit from "./PersonExperienceRequestEdit";
import {observable} from "mobx";

type Props = RouteComponentProps<{ entityId: string, addressId?: string }>;
interface defaultProps{
  isRequest?:boolean
}

@observer
export class PersonExperienceRequestManagement extends React.Component<Props,defaultProps> {
  static PATH = "/personExperienceRequest";
  static NEW_SUBPATH = "new";
  @observable defaultProps:defaultProps
  constructor(props:Props,defaultProps:defaultProps) {
    super(props,defaultProps);
    this.defaultProps = defaultProps
  }

  render() {

    const {entityId,addressId} = this.props.match.params;
    if(entityId!=="new"&&!addressId){
      this.defaultProps.isRequest = true;
    }
    return (
      <>
        <PersonExperienceRequestEdit entityId={entityId} addressId={addressId} isRequest={this.defaultProps.isRequest}/>
      </>
    );
  }
}