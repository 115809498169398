import React, { Component } from 'react';
import LearningBudget from "./LearningBudget";

class LearningBudgetManagement extends Component {
  static PATH = "learning-budget";

  render() {
    return (
      <LearningBudget />
    );
  }
}

export default LearningBudgetManagement;