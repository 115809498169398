import "./styles.css"
import { ComponentProps, createElement, default as React } from "react";
import {
    collection,
    DataTable,
    FileInfo,
    FileUpload,
    FormField,
    getCubaREST,
    injectMainStore,
    instance,
    MainStoreInjected,
    Msg,
    withLocalizedForm
} from "@cuba-platform/react";
import Page from "../../../hoc/PageContentHoc";
import { RootStoreProp } from "../../../store";
import { inject, observer } from "mobx-react";
import { Card, Checkbox, DatePicker, Form, Icon, Input, Modal, Row, Select, Table, Tooltip } from "antd";
import { RouteComponentProps } from "react-router-dom";
import Button, { ButtonType } from "../../../components/Button/Button";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { withRouter } from "react-router";
import { FormComponentProps } from "antd/lib/form";
import Notification from "../../../util/Notification/Notification";
import {action, IReactionDisposer, observable, observe, reaction, values} from "mobx";
import PersonMedalRequestManagement from './PersonMedalRequestManagement';
import { PersonMedalRequest } from '../../../../cuba/entities/base/tsadv_PersonMedalRequest';
import { DicRequestStatus } from '../../../../cuba/entities/base/tsadv$DicRequestStatus';
import { PersonGroupExt } from '../../../../cuba/entities/base/base$PersonGroupExt';
import { DicAwardType } from '../../../../cuba/entities/base/tsadv$DicAwardType';
import { DicAwardLevel } from '../../../../cuba/entities/base/tsadv_DicAwardLevel';
import { DicAwardMeasure } from '../../../../cuba/entities/base/tsadv_DicAwardMeasure';
import moment, { Moment } from 'moment';
import DefaultDatePicker from '../../../components/Datepicker';
import { SerializedEntity } from "@cuba-platform/rest";
import { AssignmentExt } from "../../../../cuba/entities/base/base$AssignmentExt";
import Column from "antd/es/table/Column";
import { OrganizationGroupExt } from "../../../../cuba/entities/base/base$OrganizationGroupExt";
import {
  DEFAULT_DATE_PATTERN,
  DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS,
  formatDate,
  JSON_DATE_TIME_FORMAT
} from "../../../util/Date/Date";
import PickerTable from "../PickerComponent/PickerTable";
import { PersonMedalRequestAttachment } from "../../../../cuba/entities/base/tsadv_PersonMedalRequestAttachment";
import TextArea from "antd/lib/input/TextArea";
import { parseToJsonFromFieldValue } from "../../../components/MultiFileUpload";
import { Medal } from "../../../../cuba/entities/base/tsadv$Medal";
import { getValidGreaterZero, getAssignmentCondition, getOrganizationCondition, getFieldValue } from "./utils";
import { downloadFile, setItemPropertiesValue } from "../../../util/util";
import { FileDescriptor } from "../../../../cuba/entities/base/sys$FileDescriptor";
import DataTableFormat from "../../../components/DataTable/intex";
import { restServices } from "../../../../cuba/services";
import { DicAward } from "../../../../cuba/entities/base/tsadv_DicAward";
import AbstractAgreedBprocEdit from "../../Bproc/abstract/AbstractAgreedBprocEdit";
import { ExtTaskData } from "../../../../cuba/entities/base/tsadv_ExtTaskData";
import { MedalType } from "../../../../cuba/enums/enums";
import {DicHrRole} from "../../../../cuba/entities/base/tsadv$DicHrRole";
import Candidate from "../../Bproc/component/Candidate";
import {TsadvUser} from "../../../../cuba/entities/base/tsadv$UserExt";
import {PersonMedal} from "../../../../cuba/entities/base/tsadv$PersonMedal";
import {DicCompany} from "../../../../cuba/entities/base/base_DicCompany";
import LabelTooltip from "../../LearningBudget/LabelTooltip/LabelTooltip";

type EditorProps =
    & {
        entityId?: string;
    };

@inject("rootStore")
@injectMainStore
@observer
class PersonMedalRequestEdit extends AbstractAgreedBprocEdit<PersonMedalRequest, EditorProps & FormComponentProps
& RouteComponentProps & WrappedComponentProps & MainStoreInjected & RootStoreProp> {

    ADMIN_RECOGNITION_TASK_CODE: string = "admin_recognition_task";
    C_AND_B_COMPANY_TASK_CODE: string = "c_and_b_company_task";
    T_AND_D_COMPANY_ROLE: string = "T&D";
    APPROVED_CODE: string = "APPROVED";
    REJECTED_CODE: string = "REJECTED";
    REJECT_CODE: string = "REJECT";

    @observable initiator: PersonGroupExt;
    @observable selectedPersons: AssignmentExt[];
    @observable isEmployeeModalVisible: boolean;
    @observable isOrganizationModalVisible: boolean;
    @observable isAttachmentModalVisible: boolean;
    @observable selectedRow?: AssignmentExt;
    @observable selectedAttachmentRow?: PersonMedalRequestAttachment;
    @observable draftStatus: DicRequestStatus;
    @observable selectedOrganizationId: string;
    @observable selectedOrganizationName: string;
    @observable requestDate: Moment;
    @observable bronzeMedal?: Medal;
    @observable readOnly: boolean = false;
    @observable sumReadOnly: boolean = false;
    @observable currentUserIsAdminRecognition: boolean = false;
    @observable currentUserIsTandD: boolean = false;
    @observable onCommission: boolean = false;
    @observable attentionMsgs: Map<string, string> = new Map();
    @observable isAdditionalFieldsVisible: boolean = false;
    @observable isAwardRequired: boolean = false;

    attachedComment?: string;
    nameFilterValue?: string = '';
    positionFilterValue?: string;
    organizationnameFilterValue?: string = '';
    organizationCompanyFilterValue?: DicCompany;
    messages = this.props.mainStore!.messages!;
    modalSelectedTemp: AssignmentExt[] = [];
    modalSelectedOrgTemp: OrganizationGroupExt[] = [];
    bprocComment: string;
    intervalErrorAssignments: AssignmentExt[] = [];
    overlimitErrorAssignments: AssignmentExt[] = [];

    processDefinitionKey = "personMedalRequest"


    reactionDisposers: IReactionDisposer[] = [];

    dataInstance = instance<PersonMedalRequest>(PersonMedalRequest.NAME, {
        view: "person-medal-request-edit-view",
        loadImmediately: false
    });

    companiesDc = collection<DicCompany>(DicCompany.NAME, {
      sort: "-updateTs",
    });

    requestStatusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
        sort: "-updateTs",
    });

    personGroupDc = collection<PersonGroupExt>(PersonGroupExt.NAME, {
        sort: "-updateTs",
    });

    awardTypesDc = collection<DicAwardType>(DicAwardType.NAME, {
        sort: "-updateTs",
        view: "_local"
    });

    awardLevelsDc = collection<DicAwardLevel>(DicAwardLevel.NAME, {
        sort: "-updateTs",
    });

    awardMeasuresDc = collection<DicAwardMeasure>(DicAwardMeasure.NAME, {
        view: "_local",
        sort: "-updateTs",
    });

    medalsDc = collection<Medal>(Medal.NAME, {
        sort: "-updateTs",
    });

    awardsDc = collection<DicAward>(DicAward.NAME, {
        view: "_local",
        sort: "-updateTs",
    });

    attachmentsDc = collection<PersonMedalRequestAttachment>(PersonMedalRequestAttachment.NAME, {
        sort: "-updateTs",
        view: "person-medal-request-attachment-front-edit",
        loadImmediately: false
    });

    organizationDc = collection<OrganizationGroupExt>(OrganizationGroupExt.NAME, {
        view: "organizationGroup.browse"
    });

    dataCollectionAssignment = collection<AssignmentExt>(AssignmentExt.NAME, {
        sort: "personGroup.relevantPerson.lastName",
        view: "assignmentExt-view",
        loadImmediately: false,
        limit: 10,
        filter: {
            conditions: [
                {
                    property: 'endDate',
                    operator: ">=",
                    value: moment().format(JSON_DATE_TIME_FORMAT)
                },
                {
                    property: 'startDate',
                    operator: "<=",
                    value: moment().format(JSON_DATE_TIME_FORMAT)
                },
                {
                    property: 'assignmentStatus.code',
                    operator: "=",
                    value: 'ACTIVE'
                },
                {
                    property: 'primaryFlag',
                    operator: "=",
                    value: 'TRUE'
                }
            ]
        },
    });

    selectedAssignment = collection<AssignmentExt>(AssignmentExt.NAME, {
        sort: "personGroup.relevantPerson.lastName",
        view: "assignmentExt-view",
        loadImmediately: false
    });

    selectedAttachments = collection<PersonMedalRequestAttachment>(PersonMedalRequestAttachment.NAME, {
        view: "person-medal-request-attachment-front-edit",
        loadImmediately: false
    });

    fields = [
        "requestNumber",

        "requestDate",

        "status",

        "initiatorGroup",

        "awardType",

        "awardLevel",

        "awardMeasure",

        "award",

        "description",

        "countMedal",

        "onCommission",

        "hasBonus",

        "medal",

        "payout",

        "amountKzt",

        "bonusMCI"

    ]

    assignmentFields = [
        "personGroup",

        "organizationGroup",

        "positionGroup"
    ]

    attachmentFields = [
        "file",

        "date",

        "comment"
    ]

    assignmentTableColumns = [
        {
            caption: this.messages[AssignmentExt.NAME + '.' + 'personGroup'],
            field: 'personGroup._instanceName'
        },
        {
            caption: this.props.intl!.formatMessage({ id: "organization" }),
            field: 'organizationGroup._instanceName'
        },
        {
            caption: this.messages[AssignmentExt.NAME + '.' + 'positionGroup'],
            field: 'positionGroup._instanceName'
        },
    ]

    organizationTableColumns = [
        {
            caption: this.props.intl!.formatMessage({ id: "organization" }),
            field: '_instanceName'
        },
        {
            caption: this.props.intl!.formatMessage({ id: "company" }),
            field: 'company._instanceName'
        },
    ]

    attachmentColumnRender = [
        {
            title: this.props.intl.formatMessage({id: 'file'}),
            column: 'file',
            sorter: false,
            render: (text: any, record: PersonMedalRequestAttachment) => {
                const filename = record.file!.name!.replace(/\.[^/.]+$/, "")
                return <a onClick={() => {
                    downloadFile(record.file!.id,
                        filename as string,
                        record.file!.extension as string,
                        "");
                }}>
                    {record.file!.name}
                </a>
            }

        },
        {
            title: this.props.intl.formatMessage({id: 'attachmentDate'}),
            column: 'date',
            sorter: false,
            render: (text: any, record: PersonMedalRequestAttachment) => {
                return <label>{moment(record.date).format(DEFAULT_DATE_PATTERN)}</label>
            }

        },
        {
            title: this.props.intl.formatMessage({id: 'comment'}),
            column: 'attachedComment',
            sorter: false,
            render: (text: any, record: PersonMedalRequestAttachment) =>
                <label>{record.comment}</label>
        }
    ]


    initVariablesByBproc = () => {
        if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
            this.approverHrRoleCode = this.activeUserTask.hrRole.code;
        }
    }

    takCard = () => {
      if (!this.tasks) return <div/>;
      const tasks = Array.from(this.tasks);
      return <Table
        dataSource={tasks}
        pagination={false}
        key="key"
        bordered
        rowKey="id">
        <Column title={<Msg entityName={ExtTaskData.NAME} propertyName='hrRole'/>}
                dataIndex="hrRole"
                ellipsis
                key="hrRole"
                render={(text, record) => {
                  return <div
                    className="break-words">{(record as ExtTaskData).hrRole ? ((record as ExtTaskData).hrRole as DicHrRole).langValue : ""}</div>;
                }}/>
        <Column title={this.props.intl.formatMessage({id: "bproc.startBproc.modal.user"})}
                dataIndex="assigneeOrCandidates"
                key="assigneeOrCandidates"
                ellipsis
                render={(text, record) =>
                  (<Candidate candidates={((record as ExtTaskData).assigneeOrCandidates as TsadvUser[] | null)}/>)
                }/>
        <Column title={<FormattedMessage id="createDate"/>}
                dataIndex="createTime"
                key="createTime"
                ellipsis
                render={(text, record, index) => {
                  return <div
                    className="break-words">{formatDate((record as ExtTaskData).createTime!, DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS)}</div>;
                }}/>
        <Column title={<FormattedMessage id="endDate"/>}
                dataIndex="endTime"
                ellipsis
                key="endTime"
                render={(text, record, index) => {
                  return <div
                    className="break-words">{formatDate((record as ExtTaskData).endTime, DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS)}</div>;
                }}/>
        <Column title={<Msg entityName={ExtTaskData.NAME} propertyName='outcome'/>}
                dataIndex="outcome"
                key="outcome"
                ellipsis
                render={(text, record, index) => {
                  const outcome = (record as ExtTaskData).outcome;
                  return outcome ?
                    <div className="break-words">{this.props.intl.formatMessage({id: 'result.' + outcome!})}</div> : "";
                }}/>
        <Column title={<Msg entityName={ExtTaskData.NAME} propertyName='comment'/>}
                dataIndex="comment"
                key="comment"
                ellipsis
                render={(text, record, index) => {
                  return <div className="break-words">{(record as ExtTaskData).comment}</div>;
                }}/>
      </Table>
    }

    checkInterval = async (assignments: AssignmentExt[], awardTypeId?: string):Promise<boolean> => {
      return new Promise(resolve => {
        let isAllowToCreate = true;
        if (!awardTypeId) {
          awardTypeId = this.props.form.getFieldValue("awardType")
        }
        if (awardTypeId) {
          const awardType = this.awardTypesDc.items.find((item:SerializedEntity<DicAwardType>) => item.id === awardTypeId)!
          getCubaREST()!.searchEntities<PersonMedalRequest>(PersonMedalRequest.NAME, {
            conditions: [
              {
                property: 'awardType.id',
                operator: '=',
                value: awardTypeId!
              },
              {
                property: 'status.code',
                operator: '=',
                value: this.APPROVED_CODE
              }
            ]
          }, {
            view: 'person-medal-request-edit-view'
          }).then(personMedalRequests => {
            let overlimitPromises: Promise<AssignmentExt>[] = []
            assignments.forEach(assignment => {
              const filteredPersonMedalRequests = personMedalRequests.filter(el => el.personGroup!.find(item => item.id === assignment.personGroup!.id))
              if (filteredPersonMedalRequests.length > 0) {
                filteredPersonMedalRequests.forEach(personMedalRequest => {
                    let duration = moment.duration(moment().diff(moment(personMedalRequest.approveDate)));
                    let years = duration.asYears();
                    if (awardType.awardingInterval) {
                      if (years <= awardType.awardingInterval) {
                        this.intervalErrorAssignments.push(assignment)
                        isAllowToCreate = false
                        this.attentionMsgs.set(assignment.id, this.props.intl.formatMessage({id: "intervalError" },
                        {name:(assignment.personGroup as SerializedEntity<PersonGroupExt>)._instanceName,
                           years: awardType.awardingInterval}))
                      }
                    }
                  })
              }
              if (isAllowToCreate && awardType) {
                overlimitPromises.push(this.checkLimit(assignment, awardType))
              }
            })
            if (overlimitPromises.length > 0) {
              Promise.all(overlimitPromises).then(values => {
                const results = values.filter(it => !!it)
                this.overlimitErrorAssignments = results
                this.overlimitErrorAssignments.forEach(errorAssignment => {
                  this.attentionMsgs.set(errorAssignment.id, this.props.intl.formatMessage({id: "overLimitAttention"},
                  {name: (errorAssignment.personGroup as SerializedEntity<PersonGroupExt>)._instanceName}))
                })
                isAllowToCreate = results.length === 0
                resolve(isAllowToCreate)
              })
            } else {
              resolve(isAllowToCreate)
            }
          });
        }
      });
    }

    checkLimit = async (assignment: AssignmentExt, awardType: DicAwardType): Promise<AssignmentExt> => {
      return new Promise(resolve => {
        getCubaREST()!.searchEntities<PersonMedal>(PersonMedal.NAME, {
          conditions: [{
            property: 'personGroup.id',
            operator: '=',
            value: assignment.personGroup!.id
          },{
            property: 'awardType.id',
            operator: '=',
            value: awardType.id
          }]
        }, {
          view: '_local'
        }).then(personMedals => {
          let medalCount = 0
          const currentDate = moment()
          personMedals.forEach(personMedal => {
            if (moment(personMedal.endDate) > currentDate && personMedal.medalCount) {
              medalCount = medalCount + personMedal.medalCount
            }
          })
          const isIncorrectLimit = !!(awardType!.overLimit && (medalCount >= awardType!.overLimit))
          if (isIncorrectLimit) {
            resolve(assignment)
          } else {
            resolve(undefined)
          }
        });
      })
    }


    updateAndClose = () => {
        // @ts-ignore
        const r = this.props.form.validateFields() as Promise<any>
        if (this.selectedAssignment.items && this.selectedAssignment.items.length === 0) {
            Notification.error({
                message: this.props.intl.formatMessage({
                    id: "employeesIsEmpty"
                })
            })
        } {
            r.then(value => {
              this.update()
                    .then(value => {
                      this.selectedAttachments.items.forEach(item => {
                        getCubaREST()!.commitEntity(PersonMedalRequestAttachment.NAME, item)
                          .catch((a: any) =>
                            Notification.error({
                              message: this.props.intl.formatMessage({
                                id: "management.editor.error"
                              })
                            })
                          )
                      })
                      this.close()
                    })
                    .catch((a: any) =>
                      Notification.error({
                        message: this.props.intl.formatMessage({
                          id: "management.editor.error"
                        })
                      })
                    )
                    .catch((a: any) =>
                      Notification.error({
                        message: this.props.intl.formatMessage({
                          id: "management.editor.error"
                        })
                      })
                    )
            }).catch(reason => {
                const errors = reason.errors
                for (let field in errors) {
                    errors[field].errors.forEach((val: {
                        message: string
                        field: string
                    }) => {
                        Notification.error({
                            message: val.message
                        })
                    })
                }
            })
        }
    }

    closeScreen = () => {
      // @ts-ignore
      if (this.props.history.action === 'POP')
        this.props.history.push("/employee-recognition");
      else this.props.history.goBack();
    }

    updateItemValue = (): void => {
        const awardMeasure = this.props.form.getFieldValue("awardMeasure")
          ? this.awardMeasuresDc.items.find(item => item.id === this.props.form.getFieldValue("awardMeasure")) : undefined
        const countMedal = awardMeasure
          ? awardMeasure.countMedalMax : 0
        const amountKzt = this.props.form.getFieldValue("amountKzt") ? this.props.form.getFieldValue("amountKzt")
          : awardMeasure ? awardMeasure.amountKzt : 0
        const award = this.props.form.getFieldValue("award")
          ? this.awardsDc.items.find(item => item.id === this.props.form.getFieldValue("award")) : undefined
        const bonusMCI = this.props.form.getFieldValue("bonusMCI") ? this.props.form.getFieldValue("bonusMCI")
          : award ? award.bonusMci : 0
        this.dataInstance.item!.requestNumber = this.props.form.getFieldValue("requestNumber")
        this.dataInstance.item!.requestDate = this.props.form.getFieldValue("requestDate")
        this.dataInstance.item!.status = { id: this.props.form.getFieldValue("status") }
        this.dataInstance.item!.initiatorGroup = this.props.form.getFieldValue("initiatorGroup") ? {id: this.props.form.getFieldValue("initiatorGroup")} : { id: this.initiator.id }
        this.dataInstance.item!.awardType = this.awardTypesDc.items.find((item:SerializedEntity<DicAwardType>) => item.id === this.props.form.getFieldValue("awardType"))
        this.dataInstance.item!.awardLevel = { id: this.props.form.getFieldValue("awardLevel") }
        this.dataInstance.item!.awardMeasure = { id: this.props.form.getFieldValue("awardMeasure") }
        this.dataInstance.item!.award = { id: this.props.form.getFieldValue("award") }
        this.dataInstance.item!.description = this.props.form.getFieldValue("description")
        this.dataInstance.item!.medal = this.bronzeMedal
        this.dataInstance.item!.amountKzt = amountKzt
        this.dataInstance.item!.bonusMCI = bonusMCI

        this.dataInstance.item!.company = this.selectedAssignment.items[0].organizationGroup!.company;
        this.dataInstance.item!.payout = awardMeasure ? awardMeasure.payout : false;
        this.dataInstance.item!.onCommission = this.onCommission;
        this.dataInstance.item!.hasBonus = awardMeasure ? awardMeasure.hasBonus : false;
        this.dataInstance.item!.countMedal = this.props.form.getFieldValue("countMedal") ? this.props.form.getFieldValue("countMedal")
          : countMedal ? countMedal : 0
        this.dataInstance.item!.unit = this.selectedAssignment.items[0].organizationGroup;
        this.dataInstance.item!.personGroup = this.selectedAssignment.items.map(item => { return { id: item.personGroup!.id } });
    }

    updateAndCommit = (): Promise<void> => {
        return this.update()
            .then(value => {
                this.props.form.setFieldsValue({
                  "bproc-comment" : this.bprocComment
                })
                this.selectedAttachments.items.forEach(item => {
                    getCubaREST()!.commitEntity(PersonMedalRequestAttachment.NAME, item)
                        .catch((a: any) =>
                            Notification.error({
                                message: this.props.intl.formatMessage({
                                    id: "management.editor.error"
                                })
                            })
                        )
                })
            })
    };

    validate = async (): Promise<boolean> => {
      let isValidatedSuccess = true;
      this.props.form.validateFields(this.fields, {force: true}, (err, values) => {
        isValidatedSuccess = !err;
        if (err) {
          Notification.error({
            message: this.props.intl.formatMessage({id: "management.editor.validationError"})
          });
        }
      });

      return new Promise(resolve => resolve(isValidatedSuccess));
    };

    update = () => {
        let obj = this.getUpdateEntityObj(this.fields);
        return this.configureFieldsAndUpdate(obj);
    }

    configureFieldsAndUpdate = (obj: any) => {
        const awardMeasure = this.props.form.getFieldValue("awardMeasure")
          ? this.awardMeasuresDc.items.find(item => item.id === this.props.form.getFieldValue("awardMeasure")) : undefined
        const countMedal = awardMeasure
          ? awardMeasure.countMedalMax : 0
        const amountKzt = this.props.form.getFieldValue("amountKzt") ? this.props.form.getFieldValue("amountKzt")
          : awardMeasure ? awardMeasure.amountKzt : 0
        const award = this.props.form.getFieldValue("award")
          ? this.awardsDc.items.find(item => item.id === this.props.form.getFieldValue("award")) : undefined
        const bonusMCI = this.props.form.getFieldValue("bonusMCI") ? this.props.form.getFieldValue("bonusMCI")
        : award ? award.bonusMci : 0
        obj['company'] = this.selectedAssignment.items[0].organizationGroup!.company;
        obj['payout'] = awardMeasure ? awardMeasure.payout : false;
        obj['onCommission'] = this.onCommission;
        obj['hasBonus'] = awardMeasure ? awardMeasure.hasBonus : false;
        obj['bonusMCI'] = bonusMCI;
        obj['amountKzt'] = amountKzt;
        obj['medal'] = this.bronzeMedal;
        obj['countMedal'] = this.props.form.getFieldValue("countMedal") ? this.props.form.getFieldValue("countMedal")
          : countMedal ? countMedal : 0
        obj['unit'] = this.selectedAssignment.items[0].organizationGroup;
        obj['personGroup'] = parseToJsonFromFieldValue(this.selectedAssignment.items.map(item => item.personGroup));
        this.bprocComment = this.props.form.getFieldValue("bproc-comment")
        return this.dataInstance.update(obj);
    }

    getUpdateEntityObj = (fields: string[]): any => {
        const obj = {
            ...this.props.form.getFieldsValue(fields),
        };

        const metaClass = this.props.mainStore!.metadata!.find(mci => mci.entityName === this.dataInstance.entityName);
        if (metaClass) {
            metaClass.properties
                .filter(value => value.cardinality === "ONE_TO_MANY" || value.cardinality === "MANY_TO_MANY")
                .filter(value => fields.find(field => field === value.name))
                .forEach(value => {
                    const files = obj[value.name];
                    if (files)
                        obj[value.name] = parseToJsonFromFieldValue(files);
                })
        }
        return obj;
    };


    isNewEntity = () => {
        return this.props.entityId === PersonMedalRequestManagement.NEW_SUBPATH;
    }


    close = () => {
        this.props.history!.goBack();
    }


    showEmployeeModal = () => {
        if (!this.selectedOrganizationId) {
          Notification.info({
            message: this.props.intl.formatMessage({
              id: "organizationGroupIsRequired"
            })
          })
        } else {
          this.isEmployeeModalVisible = true;
        }
    };

    showOrganizationModal = () => {
        this.isOrganizationModalVisible = true;
    };

    showAttachmentModal = () => {
        this.isAttachmentModalVisible = true;
    };

    onEmployeeModalOkClick = (persons: AssignmentExt[]) => {
        this.selectedPersons = persons;
        persons.forEach(assignment => {
            let existingAssignment = this.selectedAssignment.items.find(item => item.id === assignment.id);
            if (!existingAssignment) {
                this.selectedAssignment.items.push(assignment as SerializedEntity<AssignmentExt>);
            }
        });
        this.isEmployeeModalVisible = false;
    }

    onEmployeeModalCancelClick = () => {
        this.isEmployeeModalVisible = false;
        this.nameFilterValue = ''
        //this.positionFilterValue = ''
    }

    onAttachmentModalOkClick = () => {
        const attachment = new PersonMedalRequestAttachment();
        getCubaREST()!.loadEntity(FileDescriptor.NAME, (this.props.form.getFieldValue('attachedfile') as FileInfo).id, { view: "browse" })
            .then(fileDescriptor => {
                attachment.file = fileDescriptor as FileDescriptor
                attachment.comment = this.attachedComment
                attachment.medalRequest = this.dataInstance.item
                attachment.date = moment();
                this.selectedAttachments.items.push(attachment as SerializedEntity<PersonMedalRequestAttachment>);
                this.props.form.setFieldsValue({
                    attachedfile: undefined,
                    attachedComment: undefined
                })
            })

        this.isAttachmentModalVisible = false;
    }

    onAttachmentModalCancelClick = () => {
        this.isAttachmentModalVisible = false;
    }

    onNameFilterChange = (value: string) => {
        this.nameFilterValue = value.trim();
        this.dataCollectionAssignment.filter = {
            conditions: getAssignmentCondition(this.nameFilterValue, this.positionFilterValue, this.selectedOrganizationId)
        }

        this.dataCollectionAssignment.load();
    }

    onPositionFilterChange = (value: string) => {
        this.positionFilterValue = value.trim();
        this.dataCollectionAssignment.filter = {
            conditions: getAssignmentCondition(this.nameFilterValue, this.positionFilterValue, this.selectedOrganizationId)
        }

        this.dataCollectionAssignment.load();
    }

    onOrganizationNameFilterChange = (value: string) => {
        this.organizationnameFilterValue = value.trim();
        this.organizationDc.filter = {
            conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
        }

        this.organizationDc.load();
    }

    onCompanyFilterChange = (value: DicCompany) => {
      this.organizationCompanyFilterValue = value;
      this.organizationDc.filter = {
        conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
      }

      this.organizationDc.load();
    }

    onOrganizationChange = (organizationId: string) => {
        this.selectedOrganizationId = organizationId;

        this.dataCollectionAssignment.filter = {
            conditions: [
                {
                    property: 'endDate',
                    operator: ">=",
                    value: moment().format(JSON_DATE_TIME_FORMAT)
                },
                {
                    property: 'startDate',
                    operator: "<=",
                    value: moment().format(JSON_DATE_TIME_FORMAT)
                },
                {
                    property: 'assignmentStatus.code',
                    operator: "=",
                    value: 'ACTIVE'
                },
                {
                    property: 'primaryFlag',
                    operator: "=",
                    value: 'TRUE'
                },
                {
                    property: "organizationGroup.id",
                    operator: '=',
                    value: organizationId
                }
            ]
        }
        this.dataCollectionAssignment.load();
    }

    handleModalOk = () => {
        this.onEmployeeModalOkClick(this.modalSelectedTemp)
        this.checkInterval(this.selectedAssignment.items)
        this.modalSelectedTemp = []
        this.nameFilterValue = ''
        this.positionFilterValue = undefined

    }

    handleOrganizationModalOk = () => {
        if (this.modalSelectedOrgTemp.length > 0) {
          this.selectedOrganizationName = (this.modalSelectedOrgTemp[0] as SerializedEntity<OrganizationGroupExt>)._instanceName
          this.onOrganizationChange(this.modalSelectedOrgTemp[0].id);
        }
        this.organizationnameFilterValue = ''
        this.organizationCompanyFilterValue = undefined;
        this.modalSelectedOrgTemp = []
        this.organizationDc.filter = {
          conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
        }
        this.organizationDc.load();
        this.isOrganizationModalVisible = false
    }

    handleOrganizationModalCancel = () => {
        this.organizationnameFilterValue = ''
        this.organizationCompanyFilterValue = undefined;
        this.organizationDc.filter = {
          conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
        }
        this.organizationDc.load();
        this.isOrganizationModalVisible = false
    }


    getAssignmentModal = () => {
        return <Modal
            title={this.props.intl.formatMessage({ id: "persons" })}
            width={"1200px"}
            style={{ height: "770px" }}
            footer={null}
            visible={true}
            closable={false}>
            <PickerTable intl={this.props.intl} tableData={this.dataCollectionAssignment} columns={this.assignmentTableColumns} selectionMode={'multi'}
                showPositionFilter={true} onSelect={(rows: AssignmentExt[]) => this.modalSelectedTemp = rows}
                onNameFilterChange={this.onNameFilterChange} onPositionFilterChange={this.onPositionFilterChange} />
            <Button onClick={this.handleModalOk} type={'primary'}>
                {this.props.intl!.formatMessage({ id: "cubaReact.dataTable.ok" })}
            </Button>
            <Button onClick={this.onEmployeeModalCancelClick} className="cancel-btn" style={{ marginLeft: '1%' }}>
                {this.props.intl!.formatMessage({ id: "management.browser.exclude.cancel" })}
            </Button>
        </Modal>
    }

    getOrganizationModal = () => {
        return <Modal
            title={this.props.intl.formatMessage({ id: "organizations" })}
            width={"1200px"}
            style={{ height: "770px" }}
            footer={null}
            closable={false}
            visible={this.isOrganizationModalVisible}>
            <div>
                <PickerTable intl={this.props.intl} tableData={this.organizationDc} columns={this.organizationTableColumns} selectionMode={'single'}
                    showPositionFilter={false} onSelect={(rows: OrganizationGroupExt[]) => this.modalSelectedOrgTemp = rows}
                    onNameFilterChange={this.onOrganizationNameFilterChange} onCompanyFilterChange={this.onCompanyFilterChange} companiesDc={this.companiesDc} />
                <Button onClick={this.handleOrganizationModalOk} type={'primary'}>
                    {this.props.intl!.formatMessage({ id: "cubaReact.dataTable.ok" })}
                </Button>
                <Button onClick={this.handleOrganizationModalCancel} className="cancel-btn" style={{ marginLeft: '1%' }}>
                    {this.props.intl!.formatMessage({ id: "management.browser.exclude.cancel" })}
                </Button>
            </div>
        </Modal>
    }

    getAttachmentModal = () => {
        return <Modal
            title={this.props.intl.formatMessage({ id: "attachmentModal" })}
            width={"800px"}
            style={{ height: "700px" }}
            footer={null}
            visible={true}
            closable={false}>
            <div>
                <label>{this.props.intl!.formatMessage({ id: "file" })}</label>
                {this.props.form.getFieldDecorator("attachedfile",
                    {
                        initialValue: undefined
                    })(
                        <FileUpload />
                    )}
            </div>
            <div style={{ marginBottom: '20px' }}>
                <label>{this.props.intl!.formatMessage({ id: "comment" })}</label>
                {this.props.form.getFieldDecorator("attachedComment",
                    {
                        initialValue: undefined
                    })(
                        <Input style={{ display: 'inline-block' }}
                            onChange={event => { this.attachedComment = event.target.value }}
                            value={this.attachedComment} />
                    )}
            </div>
            <Button onClick={this.onAttachmentModalOkClick} type={'primary'}>
                {this.props.intl!.formatMessage({ id: "cubaReact.dataTable.ok" })}
            </Button>
            <Button onClick={this.onAttachmentModalCancelClick} className="cancel-btn" style={{ marginLeft: '1%' }}>
                {this.props.intl!.formatMessage({ id: "management.browser.exclude.cancel" })}
            </Button>
        </Modal>
    }

    getRecordById(id: string): SerializedEntity<AssignmentExt> {
        const record:
            | SerializedEntity<AssignmentExt>
            | undefined = this.selectedAssignment.items.find(record => record.id === id);

        if (!record) {
            throw new Error("Cannot find entity with id " + id);
        }

        return record;
    }

    getAttachmentRecordById(id: string): SerializedEntity<PersonMedalRequestAttachment> {
        const record:
            | SerializedEntity<PersonMedalRequestAttachment>
            | undefined = this.selectedAttachments.items.find(record => record.id === id);

        if (!record) {
            throw new Error("Cannot find entity with id " + id);
        }

        return record;
    }

    onAwardTypeChange = (awardTypeId: any) => {
        const awardType = this.awardTypesDc.items.find((item:SerializedEntity<DicAwardType>) => item.id === awardTypeId)
        this.isAwardRequired = !!(awardType && awardType.code && awardType.code === 'stateAward')
        this.checkInterval(this.selectedAssignment.items, awardTypeId)
        this.onCommission = awardType!.commissionApprove ? awardType!.commissionApprove : false;
        this.awardMeasuresDc.filter = {
            conditions: [
                {
                    property: 'awardType.id',
                    operator: '=',
                    value: awardTypeId
                }
            ]
        }
        this.awardMeasuresDc.load();
        this.props.form.setFieldsValue({
          awardMeasure: undefined
        })
    }

    onAwardMeasureChange = (awardMeasureId: any) => {
      this.awardsDc.filter = {
        conditions: [
          {
            property: 'dicAwardMeasure.id',
            operator: '=',
            value: awardMeasureId
          }
        ]
      }
      this.awardsDc.load();
      this.props.form.setFieldsValue({
        award: undefined
      })
    }


    render() {

        if (this.dataInstance.status === 'LOADING'
            || !this.initiator
            || this.awardTypesDc.status === 'LOADING'
            || this.awardLevelsDc.status === 'LOADING'
            || this.selectedAttachments.status === 'LOADING') {
            return 'Loading';
        }

        const actions = [];
        const messages = this.props.mainStore!.messages!;

        actions.push(<div id="outComeButtonsContainer">
            {this.getOutcomeBtns()}
        </div>);
        if (this.dataInstance.item!.status!.code === this.REJECT_CODE || this.dataInstance.item!.status!.code === this.APPROVED_CODE) {
          actions.push(<Button
            onClick={this.closeScreen}
            buttonType={ButtonType.FOLLOW}
            style={{ marginLeft: "8px" }}>
            <FormattedMessage id="close" />
          </Button>);
        } else {
          actions.push(<Button
            onClick={this.updateAndClose}
            buttonType={ButtonType.PRIMARY}
            style={{ marginLeft: "8px" }}>
            <FormattedMessage id="management.editor.submit.award" />
          </Button>);

          actions.push(<Button htmlType="button"
                               buttonType={ButtonType.FOLLOW}
                               onClick={() => this.props.history!.goBack()}>
            <FormattedMessage id="management.editor.cancel" />
          </Button>);
        }


        let editFields = undefined
        if(!this.isNewEntity() && this.isAdditionalFieldsVisible) {
          editFields = [
            <Form.Item className="person-medal-form-item form-item-without-bottom-margin" style={{marginTop:'10px'}}>
              <div className="form-item-box">
                <label>{messages[PersonMedalRequest.NAME + '.' + 'amountKzt']}</label>
                <div className="person-medal-form-field">
                  {this.props.form.getFieldDecorator("amountKzt",
                    {

                      initialValue: this.dataInstance.item && this.dataInstance.item.amountKzt
                        ? this.dataInstance.item.amountKzt
                        : 0
                    })(
                    <Input disabled={this.sumReadOnly} style={{marginLeft: '10px'}}/>
                  )}
                </div>
              </div>
            </Form.Item>,
            <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
              <div className="form-item-box">
                <label>{messages[PersonMedalRequest.NAME + '.' + 'bonusMCI']}</label>
                <div className="person-medal-form-field">
                  {this.props.form.getFieldDecorator("bonusMCI",
                    {

                      initialValue: this.dataInstance.item && this.dataInstance.item.bonusMCI
                        ? this.dataInstance.item.bonusMCI
                        : 0
                    })(
                    <Input disabled={this.sumReadOnly} style={{marginLeft: '10px'}}/>
                  )}
                </div>
              </div>
            </Form.Item>,

            <Form.Item className="person-medal-form-item" >
              <div className="form-item-box">
                <label>{messages[PersonMedalRequest.NAME + '.' + 'countMedal']}</label>
                <div className="person-medal-form-field">
                  {this.props.form.getFieldDecorator("countMedal",
                    {

                      initialValue: this.dataInstance.item && this.dataInstance.item.countMedal
                        ? this.dataInstance.item.countMedal
                        : 0
                    })(
                    <Input disabled={!this.currentUserIsAdminRecognition} style={{marginLeft: '10px'}}/>
                  )}
                </div>
              </div>
            </Form.Item>
          ]
        }

        let approvingRoute = <div/>
        if (this.tasks) {
          approvingRoute = <fieldset style={{
            border: "1px solid #d8d8d8",
            padding: "5px",
            borderRadius: "4px",
          }}>
            <legend style={{
              width: "auto",
              paddingRight: "10px",
              borderBottom: 'none',
              marginBottom: '10px'
            }}>{this.props.intl.formatMessage({ id: "approvingRoute" })}</legend>
            {this.takCard()}
          </fieldset>
        }
        const attentionBox = this.attentionMsgs.size > 0
          ? <div className="attention-block">
          {Array.from(this.attentionMsgs.values()).map(item => <p>{item}</p>)}
          </div>
          : undefined
        const pageName = this.isNewEntity() ? this.props.intl.formatMessage({ id: "personalMedalRequestCreate" })
          : this.props.intl.formatMessage({ id: "personalMedalRequestEdit" })

        return (
            <Page pageName={pageName}>
                {attentionBox}
                <Card className="narrow-layout card-actions-container"
                    bordered={false} actions={actions}>
                    <Form style={{ width: '100%', paddingLeft: '15px' }}>

                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'requestNumber']}</label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("requestNumber",
                                        {
                                            initialValue: this.dataInstance.item && this.dataInstance.item.requestNumber
                                                ? this.dataInstance.item.requestNumber : undefined
                                        })(
                                            <Input disabled={true} />
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'requestDate']}</label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("requestDate",
                                        {
                                            initialValue: this.requestDate
                                        })(
                                            <DefaultDatePicker disabled={true} style={{ width: '100%' }} />
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'status']}</label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("status",
                                        {
                                            initialValue: this.dataInstance.item && this.dataInstance.item.status
                                                ? this.dataInstance.item.status.id : this.draftStatus ? this.draftStatus.id : undefined
                                        })(
                                            <Select disabled={true}>
                                                {this.requestStatusesDc && this.requestStatusesDc.items.map(value =>
                                                    <Select.Option key={value.id}>
                                                        {value._instanceName}</Select.Option>)}
                                            </Select>
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'initiatorGroup']}</label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("initiatorGroup",
                                        {
                                            valuePropName: 'id',
                                            initialValue: this.initiator.id
                                        })(
                                            <Select disabled={true}
                                                defaultValue={this.initiator.id}>
                                                <Select.Option key={this.initiator.id}>
                                                    {(this.initiator as SerializedEntity<PersonGroupExt>)._instanceName}</Select.Option>
                                            </Select>
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{this.props.intl.formatMessage({ id: "organization" })}</label>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    {this.props.form.getFieldDecorator("organizationGroup",
                                        {
                                            rules: [{
                                              required: true,
                                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.intl.formatMessage({ id: "organization" }) })
                                            }],
                                            initialValue: this.selectedOrganizationName
                                        })(
                                            <Input disabled={this.readOnly} readOnly={true}
                                                   style={{ display: 'inline-block' }}
                                                   value={this.selectedOrganizationName}/>
                                        )}
                                    <Button
                                        className="picker-btn"
                                        disabled={this.readOnly}
                                        icon="ellipsis"
                                        onClick={() => this.showOrganizationModal()}
                                        key="modal">

                                    </Button>
                                </div>

                                {this.isOrganizationModalVisible ? this.getOrganizationModal() : null}
                            </div>
                        </Form.Item>
                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'awardType']}</label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("awardType",
                                        {
                                            rules: [{
                                                required: true,
                                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![PersonMedalRequest.NAME + '.awardType'] })
                                            }],
                                            initialValue: this.dataInstance.item && this.dataInstance.item.awardType
                                                ? this.dataInstance.item.awardType.id
                                                : undefined
                                        })(
                                            <Select showSearch
                                                disabled={this.readOnly}
                                                onChange={this.onAwardTypeChange}>
                                                {this.awardTypesDc && this.awardTypesDc.items.filter((value:SerializedEntity<DicAwardType>) =>value.code !=="WORK_EXPERIENCE").map(value=>
                                                  <Select.Option key={value.id}>
                                                    {value._instanceName}</Select.Option>
                                                )
                                                }
                                            </Select>
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'awardLevel']}</label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("awardLevel",
                                        {
                                            rules: [{
                                                required: true,
                                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![PersonMedalRequest.NAME + '.awardLevel'] })
                                            }],
                                            initialValue: this.dataInstance.item && this.dataInstance.item.awardLevel
                                                ? this.dataInstance.item.awardLevel.id
                                                : undefined
                                        })(
                                            <Select showSearch disabled={this.readOnly}>
                                                {this.awardLevelsDc && this.awardLevelsDc.items.map(value =>
                                                    <Select.Option key={value.id}>
                                                        {value._instanceName}</Select.Option>)}
                                            </Select>
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label><FormattedMessage id="criterion"/></label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("awardMeasure",
                                        {
                                            rules: [{
                                                required: true,
                                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![PersonMedalRequest.NAME + '.awardMeasure'] })
                                            }],
                                            initialValue: this.dataInstance.item && this.dataInstance.item.awardMeasure
                                                ? this.dataInstance.item.awardMeasure.id
                                                : undefined
                                        })(
                                            <Select showSearch disabled={this.readOnly} onChange={this.onAwardMeasureChange}>
                                                {this.awardMeasuresDc && this.awardMeasuresDc.items.map(value =>
                                                    <Select.Option title={value._instanceName} key={value.id}>
                                                        {value._instanceName}</Select.Option>)}
                                            </Select>
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        {/*<Form.Item className="person-medal-form-item form-item-without-bottom-margin">
                            <div className="form-item-box">
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'medal']}</label>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("medal",
                                        {
                                            rules: [{
                                                required: true,
                                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![PersonMedalRequest.NAME + '.medal'] })
                                            }],
                                            initialValue: this.dataInstance.item && this.dataInstance.item.medal
                                                ? this.dataInstance.item.medal.id
                                                : undefined
                                        })(
                                            <Select showSearch>
                                                {this.medalsDc && this.medalsDc.items.map(value =>
                                                    <Select.Option key={value.id}>
                                                        {value._instanceName}</Select.Option>)}
                                            </Select>
                                        )}
                                </div>
                            </div>
                        </Form.Item>*/}

                        <Form.Item className="person-medal-form-item">
                            <div className="form-item-box">
                                <LabelTooltip trigger={'click'}
                                              title={this.props.intl.formatMessage({ id: "governmentalAwardTip" })}
                                              labelText={this.props.intl.formatMessage({id:"personMedalForm.award"})}/>
                                <div className="person-medal-form-field">
                                    {this.props.form.getFieldDecorator("award",
                                        {
                                            rules: [{
                                              required: this.isAwardRequired,
                                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![PersonMedalRequest.NAME + '.award'] })
                                            }],
                                            initialValue: this.dataInstance.item && this.dataInstance.item.award
                                                ? this.dataInstance.item.award.id
                                                : undefined
                                        })(
                                            <Select showSearch disabled={this.readOnly}>
                                                {this.awardsDc && this.awardsDc.items.map(value =>
                                                    <Select.Option key={value.id}>
                                                        {value._instanceName}</Select.Option>)}
                                            </Select>
                                        )}
                                </div>
                            </div>
                        </Form.Item>
                        <div>
                            <fieldset style={{
                                border: "1px solid #d8d8d8",
                                padding: "5px",
                                borderRadius: "4px",
                            }}>
                                <legend style={{
                                    width: "auto",
                                    paddingRight: "10px",
                                    borderBottom: 'none',
                                    marginBottom: '0px'
                                }}>{this.props.intl.formatMessage({ id: "persons" })}</legend>
                                <div style={{ width: "100%", height: "100%" }}>
                                    <DataTable fields={this.assignmentFields} tableProps={{
                                        columns: this.assignmentTableColumns.map(col => ({
                                            title: col.caption,
                                            render: (text: any, record: AssignmentExt) => (
                                                <label>
                                                    {getFieldValue(col.field.split('.'), record)}
                                                </label>
                                            )
                                        }))
                                    }} dataCollection={this.selectedAssignment}
                                        buttons={[
                                            <Button
                                                className="request-table-control-btn"
                                                icon="plus"
                                                disabled={this.readOnly}
                                                type="primary"
                                                onClick={this.showEmployeeModal}
                                                key="modal">
                                            </Button>,
                                            <Button
                                                type="danger"
                                                className="request-table-control-btn"
                                                disabled={!this.selectedRow || this.readOnly}
                                                icon={'delete'}
                                                key={"deleteItem"}
                                                onClick={() => {
                                                    this.attentionMsgs.delete(this.selectedRow!.id)
                                                    const idx = this.selectedAssignment.items.indexOf(this.selectedRow! as SerializedEntity<AssignmentExt>);
                                                    if (idx !== -1) {
                                                        this.selectedAssignment.items.splice(idx, 1);
                                                    }
                                                }
                                                }>
                                            </Button>
                                        ]}
                                        onRowSelectionChange={selectedRowKeys => this.selectedRow = selectedRowKeys.length ? this.getRecordById(selectedRowKeys[0]) : undefined} />
                                </div>

                            </fieldset>


                            {this.isEmployeeModalVisible ? this.getAssignmentModal() : null}
                        </div>

                        <Form.Item className="person-medal-form-item">
                            <div>
                                <label>{messages[PersonMedalRequest.NAME + '.' + 'description']}</label>
                                <div>
                                    {this.props.form.getFieldDecorator("description",
                                        {
                                            rules: [{
                                              required: true,
                                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![PersonMedalRequest.NAME + '.description'] })
                                            }],
                                            initialValue: this.dataInstance.item && this.dataInstance.item.description
                                                ? this.dataInstance.item.description
                                                : undefined
                                        })(
                                            <TextArea disabled={this.readOnly} style={{ width: "100%" }} rows={5} />
                                        )}
                                </div>
                            </div>
                        </Form.Item>

                        <div>
                            <fieldset style={{
                                border: "1px solid #d8d8d8",
                                padding: "5px",
                                borderRadius: "4px",
                            }}>
                                <legend style={{
                                    width: "auto",
                                    paddingRight: "10px",
                                    borderBottom: 'none',
                                    marginBottom: '0px'
                                }}>{this.props.intl.formatMessage({ id: "attachments" })}</legend>
                                <DataTableFormat
                                    buttons={[
                                        <Button
                                            className="request-table-control-btn"
                                            icon="plus"
                                            disabled={this.readOnly}
                                            type="primary"
                                            onClick={this.showAttachmentModal}
                                            key="modal">
                                        </Button>,
                                        <Button
                                            type="danger"
                                            className="request-table-control-btn"
                                            disabled={!this.selectedAttachmentRow || this.readOnly}
                                            icon={'delete'}
                                            key={"deleteItem"}
                                            onClick={() => {
                                                getCubaREST()!.deleteEntity(PersonMedalRequestAttachment.NAME, this.selectedAttachmentRow!.id)
                                                const idx = this.selectedAttachments.items.indexOf(this.selectedAttachmentRow! as SerializedEntity<PersonMedalRequestAttachment>);
                                                if (idx !== -1) {
                                                    this.selectedAttachments.items.splice(idx, 1);
                                                }
                                            }
                                            }>
                                        </Button>
                                    ]}
                                    enableFiltersOnColumns={[]}
                                    dataCollection={this.selectedAttachments}
                                    onRowSelectionChange={selectedRowKeys => this.selectedAttachmentRow = selectedRowKeys.length ? this.getAttachmentRecordById(selectedRowKeys[0]) : undefined}
                                    fields={this.attachmentFields}
                                    tableProps={{columns:this.attachmentColumnRender}}
                                    hideSelectionColumn={true}
                                />
                            </fieldset>

                            {this.isAttachmentModalVisible ? this.getAttachmentModal() : null}
                        </div>
                        {editFields}

                        {approvingRoute}

                    </Form>
                </Card>

            </Page>
        );
    }

    loadBpmProcessData = () => {
        this.sumReadOnly = true
        const processDefinitionKey = this.processDefinitionKey;
        if (this.props.entityId !== "new") {

          restServices.bprocService.processInstanceData({
            processInstanceBusinessKey: this.processInstanceBusinessKey(),
            processDefinitionKey: processDefinitionKey
          }).then(value => {
            this.isCalledProcessInstanceData = true;
            this.processInstanceData = value;
            if (value) {
              this.isUserInitiator = value.startUserId === this.props.rootStore!.userInfo!.id;
              restServices.bprocService.tasks({processInstanceData: value})
                .then(tasks => {
                  this.tasks = tasks;
                  this.activeTask = tasks.find(task => !task.endTime
                  ) as ExtTaskData;

                  this.activeUserTask = this.activeTask
                  && this.activeTask.assigneeOrCandidates
                  && this.activeTask.assigneeOrCandidates.some(user => user.id === this.props.rootStore!.userInfo.id)
                    ? this.activeTask : null;

                  if (this.activeUserTask) {
                    this.isAdditionalFieldsVisible = true
                    restServices.organizationHrUserService.getDicHrRoles({userId: this.props.rootStore!.userInfo.id!})
                      .then(roles => {
                        roles.forEach(role => {
                          if(role.code === this.T_AND_D_COMPANY_ROLE){
                            this.currentUserIsTandD = true
                          }
                        })
                      })
                    if (this.ADMIN_RECOGNITION_TASK_CODE === this.activeUserTask!.taskDefinitionKey) {
                      this.readOnly = true
                      this.sumReadOnly = true
                      this.currentUserIsAdminRecognition = true
                    } else if (this.C_AND_B_COMPANY_TASK_CODE === this.activeUserTask!.taskDefinitionKey) {
                      this.readOnly = true
                      this.sumReadOnly = false
                    } else {
                      this.readOnly = (this.dataInstance.item!.status && this.dataInstance.item!.status!.code !== 'TO_BE_REVISED')!
                      this.sumReadOnly = true
                    }
                    restServices.bprocFormService.getTaskFormData({taskId: this.activeUserTask.id!})
                      .then(formData => {
                        this.formData = formData;
                        this.isStartForm = false;
                        this.initVariablesByBproc();
                      });
                  }
                  else {
                    if (this.isUserInitiator && this.activeTask) {
                      /*this.formData = {
                        outcomes: [{id: 'REVISION'}]
                      };*/
                      this.isStartForm = false;
                    }
                    this.readOnly = true
                    this.sumReadOnly = true
                    this.initVariablesByBproc();
                  }
                })
            } else {
              restServices.bprocService.getStartFormData({processDefinitionKey: processDefinitionKey})
                .then(formData => {
                  this.formData = formData;
                  this.isStartForm = true;
                  this.initVariablesByBproc();
                });
            }
          })
        } else {
          const processDefinitionKey = this.processDefinitionKey;

          restServices.bprocService.getStartFormData({processDefinitionKey: processDefinitionKey})
            .then(formData => {
              this.formData = formData;
              this.isStartForm = true;
              this.initVariablesByBproc();
            });
        }
      }

    componentDidMount() {
        super.componentDidMount();

        getCubaREST()!.searchEntities<DicRequestStatus>(DicRequestStatus.NAME, {
            conditions: [{
                property: "code",
                operator: '=',
                value: 'DRAFT'
            }]
        }, {
            view: '_minimal'
        }).then(value => this.draftStatus = value[0])

        getCubaREST()!.searchEntities<Medal>(Medal.NAME, {
            conditions: [{
                property: "type",
                operator: '=',
                value: MedalType.COPPER
            }]
        }, {
            view: '_minimal'
        }).then(value => this.bronzeMedal = value[0])

        if (!this.isNewEntity()) {
            this.dataInstance.load(this.props.entityId!);
        } else {
            this.dataCollectionAssignment.load();
            restServices.employeeNumberService.generateNextRequestNumber()
                .then(val => {
                    const entityName = this.dataInstance.entityName;
                    restServices.portalHelperService.newEntity({ entityName: entityName }).then((personMedalRequest: PersonMedalRequest) => {
                        personMedalRequest.requestNumber = val;
                        this.requestDate = moment()
                        this.dataInstance.setItem(personMedalRequest);
                        getCubaREST()!.loadEntity(PersonGroupExt.NAME, this.props.rootStore!.userInfo.personGroupId, { view: '_minimal' })
                          .then(value => this.initiator = value as PersonGroupExt);
                    });

                })

        }

        this.reactionDisposers.push(
            reaction(
                () => this.dataInstance.status === 'DONE',
                (isDataLoaded, permsReaction) => {
                    if (isDataLoaded === true && this.dataInstance.item!.id) {
                        this.requestDate = this.dataInstance.item && this.dataInstance.item.requestDate
                            ? moment(this.dataInstance.item.requestDate) : moment()
                        this.initiator = this.dataInstance.item!.initiatorGroup!

                        this.attachmentsDc.filter = {
                            conditions: [
                                {
                                    property: 'medalRequest.id',
                                    operator: "=",
                                    value: this.dataInstance.item!.id
                                }
                            ]
                        }
                        this.attachmentsDc.load();

                        if (this.dataInstance.item!.personGroup) {
                            this.dataInstance.item!.personGroup.forEach(personGroup => {
                                if (personGroup.assignments) {
                                    this.selectedAssignment.items.push(...personGroup.assignments
                                      .filter(it => moment(it.endDate).isAfter(moment()) && moment(it.startDate).isBefore(moment())
                                        && it.assignmentStatus!.code === 'ACTIVE' && it.primaryFlag === true) as Array<SerializedEntity<AssignmentExt>>)
                                    this.checkInterval(this.selectedAssignment.items, this.dataInstance.item!.awardType!.id)
                                }
                            })
                            if (this.selectedAssignment.items && this.selectedAssignment.items.length > 0) {
                                this.selectedOrganizationId = this.selectedAssignment.items[0].organizationGroup!.id
                                this.selectedOrganizationName = (this.selectedAssignment.items[0].organizationGroup! as SerializedEntity<OrganizationGroupExt>)._instanceName
                                this.dataCollectionAssignment.filter = {
                                  conditions: getAssignmentCondition(this.nameFilterValue, this.positionFilterValue, this.selectedOrganizationId)
                                }
                                this.dataCollectionAssignment.load();
                            }
                        }

                        permsReaction.dispose();
                    }
                },
                { fireImmediately: true }
            )
        );
        this.reactionDisposers.push(
            reaction(
                () => this.attachmentsDc.status === 'DONE',
                (isDataLoaded, permsReaction) => {
                    if (isDataLoaded === true) {
                        this.selectedAttachments.items = this.attachmentsDc.items;
                        permsReaction.dispose();
                    }
                },
                { fireImmediately: true }
            )
        );

    }

    componentWillUnmount() {
        this.reactionDisposers.forEach(dispose => dispose());
    }

    onReactionFieldsValue = (item: PersonMedalRequest | undefined) => {
        return {};
    }


}

export default injectIntl(
    withLocalizedForm
        <EditorProps>({
            onValuesChange: (props: any, changedValues: any) => {
                // Reset server-side errors when field is edited
                Object.keys(changedValues).forEach((fieldName: string) => {
                    props.form.setFields({
                        [fieldName]: {
                            value: changedValues[fieldName]
                        }
                    });
                });
            }
        })(withRouter(PersonMedalRequestEdit)));