
import React, {Component} from 'react';
import StudentsRatingList from "./StudentsRatingList";

class StudentsRatingManagment extends Component {

  static PATH = "rating-students";

  render() {
    return <StudentsRatingList/>;
  }
}

export default StudentsRatingManagment;
