import * as React from "react";
import {inject, observer} from "mobx-react";

import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {AssignmentSalaryRequest} from "../../../cuba/entities/base/tsadv$AssignmentSalaryRequest";
import {WccfManagement} from "./WccfManagement";
import {Link} from "react-router-dom";
import DataTableFormat from "../../components/DataTable/intex";
import {observable} from "mobx";
import {SerializedEntity} from "@cuba-platform/rest";
import {Button, Modal} from "antd";

@injectMainStore
@inject("rootStore")
@observer
class WccfListComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  dataCollection = collection<AssignmentSalaryRequest>(AssignmentSalaryRequest.NAME, {
    view: "portal-assignmentSalaryRequest-edit",
    sort: "-updateTs",
    loadImmediately: true,
    filter: {
      conditions: [
        {
          property: "createdBy",
          operator: "=",
          value: this.props.rootStore!.userInfo.login!
        }
      ],
    },
  });

  fields = [

    "requestNumber",

    "personGroup",

    "wccfType",

    "requestDate",

    "status",

  ];

  @observable selectedRowKey: string | undefined;

  showDeletionDialog = (e: SerializedEntity<AssignmentSalaryRequest>) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        {id: "management.browser.delete.areYouSure"},
        {instanceName: e._instanceName}
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        this.selectedRowKey = undefined;

        return this.dataCollection.delete(e);
      }
    });
  };

  buttons = () => {
    return [<Link
      to={WccfManagement.PATH + "/" + WccfManagement.NEW_SUBPATH}
      key="create">
      <Button
        htmlType="button"
        style={{margin: "0 12px 12px 0"}}
        type="primary"
        icon="plus"
      >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
      </Button>
    </Link>,
      <Button
        htmlType="button"
        style={{margin: "0 12px 12px 0"}}
        disabled={!this.selectedRowKey || this.getRecordById(this.selectedRowKey).status!.code !== 'DRAFT'}
        onClick={this.deleteSelectedRow}
        key="remove"
        type="default"
      >
        <FormattedMessage id="management.browser.remove"/>
      </Button>]
  }

  render() {
    return <DataTableFormat
      dataCollection={this.dataCollection}
      fields={this.fields}
      hideSelectionColumn={true}
      buttons={this.buttons()}
      onRowSelectionChange={this.handleRowSelectionChange}
      render={[{
        column: 'requestNumber',
        render: (text, record) => <Link to={WccfManagement.PATH + '/' + record.id}>{text}</Link>
      },]}
    />
  }


  getRecordById(id: string): SerializedEntity<AssignmentSalaryRequest> {
    const record:
      | SerializedEntity<AssignmentSalaryRequest>
      | undefined = this.dataCollection.items.find(record => record.id === id);

    if (!record) {
      throw new Error("Cannot find entity with id " + id);
    }

    return record;
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

  deleteSelectedRow = () => {
    this.showDeletionDialog(this.getRecordById(this.selectedRowKey!));
  };

}

const WccfList = injectIntl(WccfListComponent);

export default WccfList;
