import { AbstractParentEntity } from "./AbstractParentEntity";
import { DicPromotionType } from "./tsadv$DicPromotionType";
import { DicAward } from "./tsadv_DicAward";
import { PersonGroupExt } from "./base$PersonGroupExt";
export class PersonAwardsDegrees extends AbstractParentEntity {
  static NAME = "tsadv_PersonAwardsDegrees";
  type?: DicPromotionType | null;
  kind?: DicAward | null;
  startDate?: any | null;
  endDate?: any | null;
  description?: string | null;
  employee?: PersonGroupExt | null;
}
export type PersonAwardsDegreesViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personAwardsDegrees.edit";
export type PersonAwardsDegreesView<
  V extends PersonAwardsDegreesViewName
> = V extends "_base"
  ? Pick<
      PersonAwardsDegrees,
      | "id"
      | "description"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      PersonAwardsDegrees,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_minimal"
  ? Pick<PersonAwardsDegrees, "id" | "description">
  : V extends "personAwardsDegrees.edit"
  ? Pick<
      PersonAwardsDegrees,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "type"
      | "kind"
      | "employee"
    >
  : never;
