import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { IndividualDevelopmentPlan } from "./tsadv$IndividualDevelopmentPlan";
import { DicMeetingType } from "./tsadv_DicMeetingType";
import { DicReadinessLevel } from "./tsadv$DicReadinessLevel";
export class IdpMeeting extends AbstractBprocRequest {
  static NAME = "tsadv_IdpMeeting";
  individualDevelopmentPlan?: IndividualDevelopmentPlan | null;
  meetingType?: DicMeetingType | null;
  meetingDate?: any | null;
  readinessLevel?: DicReadinessLevel | null;
  planPercent?: number | null;
}
export type IdpMeetingViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "idpMeeting-edit";
export type IdpMeetingView<V extends IdpMeetingViewName> = V extends "_base"
  ? Pick<
      IdpMeeting,
      | "id"
      | "individualDevelopmentPlan"
      | "meetingDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      IdpMeeting,
      | "id"
      | "meetingDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<IdpMeeting, "id" | "individualDevelopmentPlan" | "meetingDate">
  : V extends "idpMeeting-edit"
  ? Pick<
      IdpMeeting,
      | "id"
      | "meetingDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "individualDevelopmentPlan"
      | "meetingType"
      | "status"
      | "readinessLevel"
    >
  : never;
