import { observer } from "mobx-react";
import '../PersonMedal/styles.css'
import React from "react";
import { DataCollectionStore } from "@cuba-platform/react/dist/data/Collection";
import { DataTable } from "@cuba-platform/react";
import { ColumnProps } from "antd/es/table";
import {Input, Select} from "antd";
import { BaseUuidEntity } from "../../../../cuba/entities/base/sys$BaseUuidEntity";
import { getFieldValue } from "../PersonMedal/utils";
import { IntlShape } from "react-intl";
import {DicCompany} from "../../../../cuba/entities/base/base_DicCompany";

export type ColumnSettings = {
  caption: string
  field: string
}

type PickerTableProps<E> = {
  intl: IntlShape
  tableData: DataCollectionStore<E & BaseUuidEntity>
  columns: ColumnSettings[]
  selectionMode: 'single' | 'multi' | 'none',
  showPositionFilter?: boolean,
  onSelect: (rows: E[]) => void;
  onNameFilterChange?: (value: string) => void;
  onPositionFilterChange?: (value: string) => void;
  onCompanyFilterChange?: (value: DicCompany) => void;
  companiesDc?:DataCollectionStore<DicCompany>
}

@observer
export default class PickerTable<E> extends React.Component<PickerTableProps<E>> {

  nameFilterValue = ''
  positionFilterValue = ''
  companyFilterValue: DicCompany

  columns = this.props.columns.map(col => ({
    title: col.caption,
    render: (text: any, record: (E & BaseUuidEntity)) => (
      <label>
        {getFieldValue(col.field.split('.'), record)}
      </label>
    )
  }))

  onCompanyValueChange = (companyId: any) => {
    const company = this.props.companiesDc!.items.find(item => item.id === companyId)
    this.companyFilterValue = company!
    this.props.onCompanyFilterChange!(company!)
  }

  handleSelectionChange = (selectedRowKeys: string[]) => {
    if (selectedRowKeys.length) {
      const rows = this.props.tableData.items.filter(value => selectedRowKeys.includes(value.id))
      this.props.onSelect(rows)
    }
  }

  render() {

    const filter = this.props.showPositionFilter ? [
      <div style={{display:'inline-block'}}>
        <label className="filter-field">{this.props.intl.formatMessage({ id: "filterName" })}</label>
        <Input className="filter-field" onChange={(event) => this.nameFilterValue = event.target.value}
          onPressEnter={(event) => this.props.onNameFilterChange!(this.nameFilterValue)} />
      </div>,
      //<div style={{display:'inline-block', marginLeft:'20px'}}>
      //  <label className="filter-field">{this.props.intl.formatMessage({ id: "filterPosition" })}</label>
      //  <Input className="filter-field" onChange={(event) => this.positionFilterValue = event.target.value}
      //    onPressEnter={(event) => this.props.onPositionFilterChange!(this.positionFilterValue)} />
      //</div>
    ] :
      [
        <div style={{display:'inline-block', marginLeft:'20px'}}>
          <label className="filter-field">{this.props.intl.formatMessage({ id: "export.organisation" })}</label>
          <Input className="filter-field" onChange={(event) => this.nameFilterValue = event.target.value}
            onPressEnter={(event) => this.props.onNameFilterChange!(this.nameFilterValue)} />
        </div>,
        <div style={{display:'inline-block', marginLeft:'20px'}}>
          <label style={{display:'block'}}>{this.props.intl.formatMessage({ id: "company" })}</label>
          <Select showSearch
                  style={{width:'200px'}}

                  onChange={this.onCompanyValueChange}>
            {this.props.companiesDc && this.props.companiesDc.items.map(value =>
              <Select.Option key={value.id}>
                {value._instanceName}</Select.Option>)}
          </Select>
        </div>
      ]

    return (

      <div>
        <div style={{ marginBottom: "5px" }}>
          {filter}
        </div>
        <DataTable fields={[]} dataCollection={this.props.tableData}
          rowSelectionMode={this.props.selectionMode} onRowSelectionChange={this.handleSelectionChange}
          tableProps={{
            columns: this.columns
          }}
        />
      </div>
    )
  }
}