import * as React from "react";
import {inject, observer} from "mobx-react";
import {injectIntl, WrappedComponentProps} from "react-intl";

import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";

import "../../../../app/App.css";

import {AssignedGoal} from "../../../../cuba/entities/base/tsadv$AssignedGoal";
import {observable} from "mobx";
import {Button, Modal, Table} from "antd";
import {restServices} from "../../../../cuba/services";
import {RootStoreProp} from "../../../store";
import {RouteComponentProps} from "react-router-dom";
import {withRouter} from "react-router";

export type KpiEmployee = {
  groupId: string,
  name?: string | null,
  position?: string | null,
}

type Props = GoalHistoryProps;

type GoalHistoryProps = {
  goal: AssignedGoal,
};

@inject('rootStore')
@injectMainStore
@observer
class CascadeBtn extends React.Component<Props & RootStoreProp & WrappedComponentProps & MainStoreInjected & RouteComponentProps<any>> {

  @observable
  data: KpiEmployee[] = [];
  @observable
  visible = false;

  @observable
  selectedRowKeys: string[] | undefined;

  columns = [
    {
      title: this.props.intl.formatMessage({id: 'employee.name'}),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: this.props.intl.formatMessage({id: 'positionGroup'}),
      dataIndex: 'position',
      key: 'position',
    },
  ];

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKeys = selectedRowKeys;
  };

  handleOk = async () => {
    this.props.rootStore!.cascadeGoalStore.setData(this.props.goal.id, (this.selectedRowKeys || []).map(value => this.data.find(d => d.groupId === value)!));
    this.visible = false;
    this.props.history!.push("/goal/cascade");
  }

  handleCancel = () => {
    this.visible = false;
  }

  render() {
    return <Button type="link"
                   style={{padding: 0}}
                   onClickCapture={event => this.visible = true}>
      {this.props.intl.formatMessage({id: "cascade"})}
      <Modal
        title={this.props.intl.formatMessage({id: "cascade"})}
        width={900}
        okButtonProps={{disabled: this.selectedRowKeys === undefined || this.selectedRowKeys.length === 0}}
        visible={this.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <Table dataSource={this.data.slice()}
               rowSelection={{
                 type: 'checkbox',
                 onChange: this.handleRowSelectionChange
               }}
               key={'groupId'}
               rowKey={'groupId'}
               pagination={false}
               columns={this.columns}/>
      </Modal>
    </Button>;
  }

  componentDidMount() {
    (async () => {
      this.props.rootStore!.cascadeGoalStore.clear();
      await restServices.kpiService.getKpiSubordinateEmployees({goalId: this.props.goal.id}).then(value => this.data = value);
    })()
  }
}

export default injectIntl(withRouter(CascadeBtn));
