import * as React from "react";
import {createElement} from "react";
import {Alert, Button as AntButton, Card, Form, Input} from "antd";
import {inject, observer} from "mobx-react";
import {JobDescriptionRequestManagement} from "./JobDescriptionRequestManagement";
import {FormComponentProps} from "antd/lib/form";
import {Redirect} from "react-router-dom";
import {IReactionDisposer, observable, toJS} from "mobx";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

import {
  collection,
  DataCollectionStore,
  DataInstanceStore,
  getCubaREST,
  injectMainStore,
  instance,
  MainStoreInjected,
  Msg,
  MultilineText,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../app/App.css";

import {JobDescriptionRequest} from "../../../cuba/entities/base/tsadv_JobDescriptionRequest";
import Section from "../../hoc/Section";
import Page from "../../hoc/PageContentHoc";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import LoadingPage from "../LoadingPage";
import {ReadonlyField} from "../../components/ReadonlyField";
import TextArea from "antd/es/input/TextArea";
import Button, {ButtonType} from "../../components/Button/Button";
import {goBackOrHomePage} from "../../util/util";
import {withRouter} from "react-router";
import {PositionGroupExt} from "../../../cuba/entities/base/base$PositionGroupExt";
import {PositionGroupDetails} from "../../../cuba/entities/base/tsadv_PositionGroupDetails";
import {restServices} from "../../../cuba/services";
import {runReport} from "../../util/reportUtil";
import {rootStore} from "../../store";
import {SerializedEntity} from "@cuba-platform/rest";
import PositionHierarchyTreeNew from "../PositionHierarchy/PositionHierarchyTreeNew";

type Props = FormComponentProps & EditorProps;

type EditorProps = {
  entityId: string;
};

@inject("rootStore")
@injectMainStore
@observer
class JobDescriptionRequestEditComponent extends AbstractBprocEdit<JobDescriptionRequest, EditorProps & Props & WrappedComponentProps & MainStoreInjected> {

  dataInstance: DataInstanceStore<JobDescriptionRequest> = instance<JobDescriptionRequest>(JobDescriptionRequest.NAME, {
    view: "jobDescriptionRequest-edit",
    loadImmediately: false
  });


  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal",
  });

  @observable
  positionGroupDc: DataCollectionStore<PositionGroupExt> | undefined

  @observable
  positionGroupDetails: PositionGroupDetails | undefined

  processDefinitionKey = "jobDescriptionRequest";

  @observable
  updated = false;

  reactionDisposer: IReactionDisposer;


  fields = [
    "requestNumber",

    "requestDate",

    "status",

    "positionGroup",

    "basicInteractionsAtWork",

    "positionDuties",

    "generalAdditionalRequirements",

    "compulsoryQualificationRequirements",

    "comment",

    "file"
  ];
  @observable fieldKey = "Hello";
  @observable selectedPosition: string;
  changeSelectedPosition = (positionId: string): void => {
    this.selectedPosition = positionId;
    this.positionGroupDc = collection<PositionGroupExt>(PositionGroupExt.NAME, {
      view: "_minimal",
      filter: {
        conditions: [
          {
            property: 'id',
            operator: '=',
            value: this.selectedPosition
          }
        ]
      }
    });

    restServices.positionStructureService.getPositionDetails({
      positionGroupId: this.selectedPosition
    }).then(value => {
      this.positionGroupDetails = value
    })
    this.fieldKey = Math.random().toString();
    const fieldValues = this.dataInstance.getFieldValues(this.fields);
    fieldValues.positionGroup = positionId;
    this.props.form.setFieldsValue(fieldValues)
  }
  @observable newDc = this.positionGroupDc;
  @observable dutiesValue = "";
  @observable requirementsValue = "";
  @observable interactionsValue = "";
  @observable generalRequirementsValue = "";
  @observable

  globalErrors: string[] = [];
  @observable treeData: any;
  @observable fileId: string

  initItem(request: JobDescriptionRequest): void {
    const id = this.props.rootStore!.positionStructureStore.selectedPositionGroupId;
    restServices.positionStructureService.getPositionDetails({
      positionGroupId: id
    }).then(value => {
      this.positionGroupDetails = value
    });
    getCubaREST()!.loadEntity(PositionGroupExt.NAME,
      this.props.rootStore!.positionStructureStore.selectedPositionGroupId,
      {view: "positionGroupExt.for.requisition"},
    ).then(value => {
      const positionGroup = value as PositionGroupExt
      request.positionGroup = positionGroup
      if (positionGroup.jobDescription) {
        request.basicInteractionsAtWork = positionGroup.jobDescription.basicInteractionsAtWork
          ? positionGroup.jobDescription.basicInteractionsAtWork : null
        this.interactionsValue = request.basicInteractionsAtWork as string
        request.compulsoryQualificationRequirements = positionGroup.jobDescription.compulsoryQualificationRequirements
          ? positionGroup.jobDescription.compulsoryQualificationRequirements : null
        this.requirementsValue = request.compulsoryQualificationRequirements as string
        request.generalAdditionalRequirements = positionGroup.jobDescription.generalAdditionalRequirements
          ? positionGroup.jobDescription.generalAdditionalRequirements : null
        this.generalRequirementsValue = request.generalAdditionalRequirements as string
        request.positionDuties = positionGroup.jobDescription.positionDuties
          ? positionGroup.jobDescription.positionDuties : null
        this.dutiesValue = request.positionDuties as string

      }
      this.dataInstance.setItem(request);
      const fieldValues = this.dataInstance.getFieldValues(this.fields);
      this.props.form.setFieldsValue(fieldValues);
    })
    this.dataInstance.setItem(request);
    const fieldValues = this.dataInstance.getFieldValues(this.fields);
    this.props.form.setFieldsValue(fieldValues);

  }

  isDisabled(): boolean {
    if (this.props.entityId === 'new') {
      return false
    }
    if (this.dataInstance && this.dataInstance.item && this.dataInstance.item.status && this.dataInstance.item.status.code === 'DRAFT') {
      return false
    }
    if (this.dataInstance && this.dataInstance.item && this.dataInstance.item.status
      && this.dataInstance.item.status.code === 'TO_BE_REVISED'
      && this.activeTask && this.activeTask.name === "initiator_task"
      && this.props.rootStore!.userInfo.id === this.activeTask.assignee) {
      return false
    }
    return true
  }

  onReactionDisposerEffect = (item: JobDescriptionRequest | undefined) => {
    restServices.positionStructureService.getPositionDetails({
      positionGroupId: item && item.positionGroup && item.positionGroup.id ? item.positionGroup.id : this.selectedPosition
    }).then(value => {
      this.positionGroupDetails = value
    });
    this.positionGroupDc = collection<PositionGroupExt>(PositionGroupExt.NAME, {
      view: "_minimal",
      filter: {
        conditions: [
          {
            property: 'id',
            operator: '=',
            value: item && item.positionGroup && item.positionGroup.id ? item.positionGroup.id : this.selectedPosition
          }
        ]
      }
    })
    const fieldValues = this.dataInstance.getFieldValues(this.fields);
    this.selectedPosition = fieldValues.positionGroup;
    this.dutiesValue = fieldValues.positionDuties;
    this.requirementsValue = fieldValues.compulsoryQualificationRequirements;
    this.generalRequirementsValue = fieldValues.generalAdditionalRequirements;
    this.interactionsValue = fieldValues.basicInteractionsAtWork;
  }

  displayComponent(): boolean {
    if (this.isUserInitiator
      && this.dataInstance && this.dataInstance.item && this.dataInstance.item.status
      && this.dataInstance.item.status.code && this.dataInstance.item.status.code === 'APPROVED') {
      return false
    } else if (this.activeUserTask && this.activeUserTask.taskDefinitionKey
      && this.activeUserTask.taskDefinitionKey === 'c_and_b_task2'
      && this.dataInstance && this.dataInstance.item && this.dataInstance.item.status
      && this.dataInstance.item.status.code && this.dataInstance.item.status.code === 'APPROVING') {
      return false
    }
    return true
  }

  getUpdateEntityData(): any {
    return {
      positionGroup: this.selectedPosition,
      positionDuties: this.dutiesValue,
      generalAdditionalRequirements: this.generalRequirementsValue,
      basicInteractionsAtWork: this.interactionsValue,
      compulsoryQualificationRequirements: this.requirementsValue,
      file: this.fileId ? {id: this.fileId} : null
    }
  }

  shouldComponentUpdate(prev: any, next: any): boolean {
    return prev !== next;
  }


  render() {
    if (!this.dataInstance) {
      return <LoadingPage/>
    }
    console.log("rerender")
    const {getFieldDecorator} = this.props.form;
    const messages = this.mainStore.messages!;
    if (this.updated) {
      return <Redirect to={JobDescriptionRequestManagement.PATH}/>;
    }
    console.log(this.isUserInitiator)
    console.log(this.displayComponent())

    return (
      <Page pageName={this.props.intl.formatMessage({id: "jobDescriptionRequestEdit.form.caption"})}>
        <Section size="large">
          <Card className="narrow-layout card-actions-container"
                actions={[
                  <Button buttonType={ButtonType.FOLLOW} htmlType="button"
                          onClick={() => goBackOrHomePage(this.props.history!)}>
                    <FormattedMessage id="close"/>
                  </Button>,
                  this.getOutcomeBtns()
                ]}
                bordered={false}>
            <Form onSubmit={this.validate} layout="vertical">


              <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="requestNumber"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                disabled={true}
              />

              <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="requestDate"
                form={this.props.form}
                disabled={true}
                formItemOpts={{style: {marginBottom: "12px"}}}
              />
              <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="status"
                disabled={true}
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                optionsContainer={this.statusesDc}
              />
              {this.props.rootStore!.positionStructureStore.selectedPositionGroupId || this.props.entityId !== "new" ? null :
                <PositionHierarchyTreeNew changeSelectedPosition={this.changeSelectedPosition}/>}
              <div className={"ant-row ant-form-item"} style={{marginBottom: "12px"}}>

                {createElement(Msg, {entityName: this.dataInstance.entityName, propertyName: "positionGroup"})}

                <Input id={"positionGroup"} disabled={true}
                       value={this.positionGroupDc && this.positionGroupDc.items && this.positionGroupDc.items[0]
                         && (this.positionGroupDc.items[0] as SerializedEntity<PositionGroupExt>)._instanceName}/>

              </div>
              <Form.Item
                label={this.props.intl.formatMessage({id: "jobDescriptionRequest.structure"})}
              >
                <TextArea disabled
                          value={this.positionGroupDetails ? this.positionGroupDetails.structuralOrganizationsTree
                            ? this.positionGroupDetails.structuralOrganizationsTree : '' : ''}
                          rows={4}/>
              </Form.Item>
              <Form.Item
                label={createElement(Msg, {
                  entityName: PositionGroupDetails.NAME,
                  propertyName: "functionalManagerName"
                })}>
                <Input
                  value={this.positionGroupDetails ? this.positionGroupDetails.functionalManagerName
                    ? this.positionGroupDetails.functionalManagerName : '' : ''}
                  disabled/>
              </Form.Item>
              <Form.Item
                label={createElement(Msg, {
                  entityName: PositionGroupDetails.NAME,
                  propertyName: "administrativeManagerName"
                })}>
                <Input
                  value={this.positionGroupDetails ? this.positionGroupDetails.administrativeManagerName
                    ? this.positionGroupDetails.administrativeManagerName : '' : ''}
                  disabled/>
              </Form.Item>
              <Form.Item
                label={createElement(Msg, {entityName: this.dataInstance.entityName, propertyName: "positionDuties"})}
                required={true}
              >
                {getFieldDecorator("positionDuties", {
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"},
                      {fieldName: messages[JobDescriptionRequest.NAME + '.' + 'positionDuties']})
                  }]
                })(
                  <TextArea
                    value={this.dutiesValue}
                    disabled={this.isDisabled()}
                    rows={4}
                    onChange={(e) => {
                      this.dutiesValue = e.currentTarget.value;
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "basicInteractionsAtWork"
                })}>
                {getFieldDecorator("basicInteractionsAtWork")(
                  <TextArea
                    disabled={this.isDisabled()}
                    rows={4}
                    value={this.interactionsValue}
                    onChange={(e) => {
                      this.interactionsValue = e.currentTarget.value;
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "compulsoryQualificationRequirements"
                })}>
                {getFieldDecorator("compulsoryQualificationRequirements")(
                  <TextArea
                    disabled={this.isDisabled()}
                    rows={4}
                    value={this.requirementsValue}
                    onChange={(e) => {
                      this.requirementsValue = e.currentTarget.value;
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={createElement(Msg, {
                  entityName: this.dataInstance.entityName,
                  propertyName: "generalAdditionalRequirements"
                })}>
                {getFieldDecorator("generalAdditionalRequirements")(
                  <TextArea
                    value={this.generalRequirementsValue}
                    disabled={this.isDisabled()}
                    rows={4}
                    onChange={(e) => {
                      this.generalRequirementsValue = e.currentTarget.value;
                    }}/>
                )}
              </Form.Item>
              {this.getReport()}
              <ReadonlyField
                entityName={JobDescriptionRequest.NAME}
                propertyName="file"
                form={this.props.form}
                disabled={this.isUserInitiator && this.dataInstance && this.dataInstance.item! && this.dataInstance!.item!.status! && this.dataInstance.item!.status!.code! === "APPROVED"}
                formItemOpts={this.displayComponent() ? {style: {marginBottom: "12px", display: "none"}}
                  : {style: {marginBottom: "12px"}}}
                getFieldDecoratorOpts={this.displayComponent() ? {rules: []} :
                  {
                    rules: [{
                      required: true,
                      message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[JobDescriptionRequest.NAME + '.file']})
                    }],
                    getValueFromEvent: args => {

                      this.fileId = (args && args!.id!)
                      console.log(args)
                      if (!this.isUpdateBeforeOutcome) {
                        this.isUpdateBeforeOutcome = true
                      }
                      return args
                    }
                  }
                }/>
              {this.globalErrors.length > 0 && (
                <Alert
                  message={<MultilineText lines={toJS(this.globalErrors)}/>}
                  type="error"
                  style={{marginBottom: "24px"}}
                />
              )}

              {this.takCard()}

            </Form>
          </Card>
        </Section>
      </Page>
    );
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }

  getReport = () => {
    return (
      <AntButton type="link" onClick={event => {
        this.dataInstance.update(this.getUpdateEntityData()).then(value => {
          let locale = rootStore.userInfo.locale;
          if (this.dataInstance.item && locale) {
            runReport('jobDescriptionRequestReport', {
              template: locale.toUpperCase(),
              parameters: [{
                name: "req",
                value: this.dataInstance.item.id
              }, {
                name: "pLanguage",
                value: locale.toUpperCase()
              }]
            }, this.props.intl)
          }
        })
      }
      }>
        <span>{this.props.intl.formatMessage({id: "downloadJDTemplate"})}</span>
      </AntButton>)
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(JobDescriptionRequestEditComponent))
);
