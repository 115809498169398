import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import SuccessorRequestEdit from "./SuccessorRequestEdit";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {RootStoreProp} from "../../store";
import SuccessorRequestList from "./SuccessorRequestList";

type Props = RouteComponentProps<{ entityId: string }>;

@injectMainStore
@inject("rootStore")
@observer
export class SuccessorRequestManagement extends React.Component<Props&RootStoreProp&MainStoreInjected> {
  static PATH = "/successorRequest";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId} = this.props.match.params;
    const state:any = this.props.location.state
    const positionGroupId:string|undefined = state&&state.positionGroupId|| undefined
    const reservistId = state&& state.reservistId||this.props.rootStore!.userInfo!.personGroupId
    return (
      <>
        {entityId==="new"?<SuccessorRequestEdit entityId={entityId} initiatorId={this.props.rootStore!.userInfo!.personGroupId!}
                                        positionGroupId={positionGroupId} reservistId={reservistId}/>
                    : entityId ?<SuccessorRequestEdit entityId={entityId}/>
          :<SuccessorRequestList/>}
    </>
  );
  }
}
export default withRouter(SuccessorRequestManagement)