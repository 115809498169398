import * as React from 'react';
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import MartialStatusRequestEdit from "./MartialStatusRequestEdit";

type Props = RouteComponentProps<{ entityId: string, statusId?:string }>;

@observer
export class MartialStatusRequestManagement extends React.Component<Props> {
  static PATH = "/beneficiaryRequest";
  static NEW_SUBPATH = "new";

  render() {

    const {entityId, statusId} = this.props.match.params;
    return <MartialStatusRequestEdit entityId={entityId} statusId={statusId}/>
  }
}

