import { StandardEntity } from "./sys$StandardEntity";
import { LearningBudget } from "./tsadv_LearningBudget";
import { Course } from "./tsadv$Course";
import { DicLearningType } from "./tsadv$DicLearningType";
import { DicCategory } from "./tsadv$DicCategory";
import { DicRegion } from "./tsadv_DicRegion";
import { OrganizationGroupExt } from "./base$OrganizationGroupExt";
import { DicCostCenter } from "./tsadv$DicCostCenter";
import { PersonGroupExt } from "./base$PersonGroupExt";
export class ILearningBudgetRequest extends StandardEntity {
  static NAME = "tsadv_ILearningBudgetRequest";
  budget?: LearningBudget | null;
  course?: Course | null;
  courseName?: string | null;
  courseNameEng?: string | null;
  learningType?: DicLearningType | null;
  typeOfTraining?: DicCategory | null;
  city?: string | null;
  region?: DicRegion | null;
  month?: any | null;
  organizationGroup?: OrganizationGroupExt | null;
  costCenter?: DicCostCenter | null;
  responsiblePerson?: PersonGroupExt | null;
  employee?: PersonGroupExt | null;
  positionName?: string | null;
  employeeCount?: number | null;
  reason?: string | null;
  reportGroup?: OrganizationGroupExt | null;
  costByPerson?: any | null;
  costSum?: any | null;
  dayCount?: number | null;
}
export type ILearningBudgetRequestViewName = "_base" | "_local" | "_minimal";
export type ILearningBudgetRequestView<
  V extends ILearningBudgetRequestViewName
> = V extends "_base"
  ? Pick<
      ILearningBudgetRequest,
      | "id"
      | "courseName"
      | "courseNameEng"
      | "city"
      | "month"
      | "positionName"
      | "employeeCount"
      | "reason"
      | "costByPerson"
      | "costSum"
      | "dayCount"
    >
  : V extends "_local"
  ? Pick<
      ILearningBudgetRequest,
      | "id"
      | "courseName"
      | "courseNameEng"
      | "city"
      | "month"
      | "positionName"
      | "employeeCount"
      | "reason"
      | "costByPerson"
      | "costSum"
      | "dayCount"
    >
  : never;
