import { StandardEntity } from "./sys$StandardEntity";
import { LearningBudget } from "./tsadv_LearningBudget";
import { OrganizationGroupExt } from "./base$OrganizationGroupExt";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { LearningBudgetRequest } from "./tsadv_LearningBudgetRequest";
export class LearningBudgetRequestHeader extends StandardEntity {
  static NAME = "tsadv_LearningBudgetRequestHeader";
  budget?: LearningBudget | null;
  organizationGroup?: OrganizationGroupExt | null;
  organizationManager?: PersonGroupExt | null;
  budgetRequests?: LearningBudgetRequest[] | null;
  status?: any | null;
  isForecastEditable?: boolean | null;
  comment?: string | null;
}
export type LearningBudgetRequestHeaderViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "learning-budget-request-header-front";
export type LearningBudgetRequestHeaderView<
  V extends LearningBudgetRequestHeaderViewName
> = V extends "_base"
  ? Pick<
      LearningBudgetRequestHeader,
      "id" | "budget" | "status" | "isForecastEditable" | "comment"
    >
  : V extends "_local"
  ? Pick<
      LearningBudgetRequestHeader,
      "id" | "status" | "isForecastEditable" | "comment"
    >
  : V extends "_minimal"
  ? Pick<LearningBudgetRequestHeader, "id" | "budget">
  : V extends "learning-budget-request-header-front"
  ? Pick<
      LearningBudgetRequestHeader,
      | "id"
      | "budget"
      | "budget"
      | "organizationGroup"
      | "organizationManager"
      | "budgetRequests"
      | "comment"
      | "status"
    >
  : never;
