import React, {Component} from 'react';
import MyEducation from "./MyEducation";

class MyEducationManagement extends Component {
  static PATH = "my-education";

  render() {
    return (
      <MyEducation/>
    );
  }
}

export default MyEducationManagement;