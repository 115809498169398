import * as React from "react";
import {observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";

import {observable} from "mobx";

import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {AddressRequestManagement} from "../AddressRequest/AddressRequestManagement";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Address} from "../../../cuba/entities/base/tsadv$Address";
import DataTableFormat from "../../components/DataTable/intex";
import {withRouter} from "react-router";
import {AddressRequest} from "../../../cuba/entities/base/tsadv$AddressRequest";
import Button, {ButtonType} from "../../components/Button/Button";
import {MilitaryForm} from "../../../cuba/entities/base/tsadv$MilitaryForm";
import {MilitaryFormRequest} from "../../../cuba/entities/base/tsadv_MilitaryFormRequest";
import {MilitaryFormRequestManagement} from "../MilitaryFormRequest/MilitaryFormRequestManagement";

export type MilitaryFormProps = {
  personGroupId: string
}

@injectMainStore
@observer
class MilitaryFormListComponent extends React.Component<MilitaryFormProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps> {
  dataCollection = collection<MilitaryForm>(MilitaryForm.NAME, {
    view: "militaryForm-view",
    sort: "-updateTs",
    filter: {
      conditions: [{
        property: 'personGroup.id',
        operator: '=',
        value: this.props.personGroupId
      }]
    }
  });

  fields = [
    "attitude_to_military",
    "military_document_type",
    "document_number",
    "military_type",
    "suitability_to_military",
    "udo",
    "military_rank",
    "officer_type",
    "date_from",
    "date_to"
  ];

  @observable selectedRowKey: string | undefined;

  render() {

    const buttons = this.dataCollection.items && this.dataCollection.items.length > 0 ?
      [
      <Button
        buttonType={ButtonType.FOLLOW}
        style={{margin: "0 12px 12px 0"}}
        disabled={!this.selectedRowKey}
        onClick={this.openRequest}
        key="editBtn"
      >
        <FormattedMessage id="management.browser.edit"/>
      </Button>
    ]
      : [
        <Link
          to={
            MilitaryFormRequestManagement.PATH +
            "/" +
            MilitaryFormRequestManagement.NEW_SUBPATH
          }
          key="create"
        >
          <Button buttonType={ButtonType.PRIMARY}
                  style={{margin: "0 12px 12px 0"}}
                  key="createBtn"
          >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
          </Button>
        </Link>,
        <Button
          buttonType={ButtonType.FOLLOW}
          style={{margin: "0 12px 12px 0"}}
          disabled={!this.selectedRowKey}
          onClick={this.openRequest}
          key="editBtn"
        >
          <FormattedMessage id="management.browser.edit"/>
        </Button>
      ];

    return (
      <DataTableFormat
        dataCollection={this.dataCollection}
        enableFiltersOnColumns={[]}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  openRequest = () => {
    this.getRequestId()
      .then(value => this.props.history!.push(MilitaryFormRequestManagement.PATH + '/' + value));
  }

  getRequestId = (): Promise<string> => {
    if (!this.selectedRowKey) return new Promise<string>(resolve => resolve(MilitaryFormRequestManagement.NEW_SUBPATH));
    return getCubaREST()!.searchEntities<MilitaryFormRequest>(MilitaryFormRequest.NAME, {
      conditions: [{
        property: 'militaryForm.id',
        operator: '=',
        value: this.selectedRowKey!
      }, {
        property: 'status.code',
        operator: 'in',
        value: ['DRAFT', 'TO_BE_REVISED', 'APPROVING']
      }]
    }, {
      view: 'militaryFormRequest-view'
    }).then(values => {
      if (!values || values.length === 0) {
        return MilitaryFormRequestManagement.NEW_SUBPATH + '/' + this.selectedRowKey!;
      } else {
        const approvingRequest = values.find(value => value!.status!.code === 'APPROVING');
        return approvingRequest ? approvingRequest.id : values[0].id;
      }
    });
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const MilitaryFormList = injectIntl(withRouter(MilitaryFormListComponent));

export default MilitaryFormList;
