import * as React from "react";
import { Alert, Card, Form, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { JobOfferRequestManagement } from "./JobOfferRequestManagement";
import { FormComponentProps } from "antd/lib/form";
import { Redirect, withRouter } from "react-router-dom";
import { IReactionDisposer, observable, reaction, toJS } from "mobx";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  collection,
  instance,
  withLocalizedForm,
  MultilineText,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";

import "../../../app/App.css";

import { JobOfferRequest } from "../../../cuba/entities/base/tsadv_JobOfferRequest";
import { PersonGroupExt } from "../../../cuba/entities/base/base$PersonGroupExt";
import { GradeRuleValue } from "../../../cuba/entities/base/tsadv$GradeRuleValue";
import { FileDescriptor } from "../../../cuba/entities/base/sys$FileDescriptor";
import { DicRequestStatus } from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import { Requisition } from "../../../cuba/entities/base/tsadv$Requisition";
import { PersonProfile } from "../MyTeam/MyTeamCard";
import { RootStoreProp } from "../../store";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import LoadingPage from "../LoadingPage";
import Page from "../../hoc/PageContentHoc";
import MsgEntity from "../../components/MsgEntity";
import Section from "../../hoc/Section";
import Button, { ButtonType } from "../../components/Button/Button";
import { goBackOrHomePage } from "../../util/util";
import { restServices } from "../../../cuba/services";
import { ReadonlyField } from "../../components/ReadonlyField";
import ErrorBoundary from "./ErrorBoundary";

type EditorProps = {
  entityId: string;
};

type Props = FormComponentProps & EditorProps & WrappedComponentProps & RootStoreProp & MainStoreInjected;

// enum TaskDefinitionKeys {
//   ier_task = 'ier_task',
//   cb_company_task = 'cb_company_task',
//   hr_manager_task = 'hr_manager_task',
//   ceo_task = 'ceo_task',
//   cb_group_task = 'cb_group_task',
// }

// enum AprooverRoles {
//   'MANAGER',
//   'C&B_COMPANY',
//   'HR',
//   'GENERAL_DIRECTOR_COMPANY',
// }

@inject("rootStore")
@injectMainStore
@observer
class JobOfferRequestEditComponent extends AbstractBprocEdit<JobOfferRequest, Props> {
  dataInstance = instance<JobOfferRequest>(JobOfferRequest.NAME, {
    view: "jobOfferRequest.edit",
    loadImmediately: false
  });

  processDefinitionKey = "jobOffer-request";

  @observable
  loaded: boolean = false;

  path = JobOfferRequestManagement.PATH;

  // candidatesDc = collection<PersonGroupExt>(PersonGroupExt.NAME, {
  //   view: "_minimal"
  // });

  // positionSalaryLevelMinsDc = collection<GradeRuleValue>(GradeRuleValue.NAME, {
  //   view: "_minimal"
  // });

  candidateFilessDc = collection<FileDescriptor>(FileDescriptor.NAME, {
    view: "_minimal"
  });

  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  // requisitionsDc = collection<Requisition>(Requisition.NAME, {
  //   view: "_minimal"
  // });

  @observable
  updated = false;
  reactionDisposer: IReactionDisposer;

  @observable
  person: PersonProfile;

  fields = [
    "expectedSalary",
    "allowance",
    "requestDate",
    "requestNumber",
    "justification",
    "positionSalaryLevelMin",
    "candidateFiles",

    "candidate",
    "candidateRef.fullName",
    
    "status",
    "statusRef",
    
    "requisition",
    "requisitionRef.positionGroup.gradeGroup.grade.gradeName",
    "requisitionRef.positionGroup.fullName",
    "requisitionRef.positionGroup.company.langValue",
    "requisitionRef.organizationGroup.organizationName",
    // "requisitionRef.managerPersonGroup.fullName",
    // "requisitionRef.startDate",
    "requisitionRef.location.langValue",
    "requisitionRef.operatingMode",

    "linearManager",

    "startDate",
    "requestDate",
  ];

  @observable
  globalErrors: string[] = [];
  isUpdateBeforeOutcome = true;

  render() {
    if (this.updated) {
      return <Redirect to={JobOfferRequestManagement.PATH} />;
    }

    if (!this.mainStore) {
      return <LoadingPage />
    }

    const messages = this.mainStore.messages!;
    
    if (!messages) {
      return <LoadingPage />
    }

    return (
      <Page pageName={<MsgEntity entityName={JobOfferRequest.NAME} />}>
        <Spin spinning={!this.loaded}>
          <Section size="large">
            <div>
              <Card
                className="narrow-layout card-actions-container"
                actions={this.renderActions()}
                bordered={false}
              >
                {this.renderForm()}
              </Card>
            </div>
          </Section>
        </Spin>
      </Page>
    );
  }

  afterSendOnApprove = () => this.closeOrGoBack();

  closeOrGoBack = () => {
    goBackOrHomePage(this.props.history!)
  }

  renderActions = () => {
    const actions = [];
    actions.push(
      <Button
        buttonType={ButtonType.FOLLOW}
        onClick={this.closeOrGoBack}
      >
        {this.props.intl.formatMessage({ id: "close" })}
      </Button>
    );

    actions.push(this.getOutcomeBtns());
    return actions;
  }

  renderForm() {
    return (
      <Card bordered={false} className="narrow-layout">
        <Form layout="horizontal">
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requestNumber"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            getFieldDecoratorOpts={{}}
          />

          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="status"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" }
            }}
            optionsContainer={this.statussDc}
            getFieldDecoratorOpts={{}}
          />

          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="candidateRef.fullName"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.candidate.fullName" />
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requisitionRef.positionGroup.fullName"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.positionGroup.fullName" />
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requisitionRef.organizationGroup.organizationName"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.organizationGroup.organizationName" />
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requisitionRef.positionGroup.company.langValue"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.positionGroup.company.langValue" />
            }}
            getFieldDecoratorOpts={{}}
          />

          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requisitionRef.positionGroup.gradeGroup.grade.gradeName"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.positionGroup.gradeGroup.grade.gradeName" />
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="linearManager"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.managerPersonGroup.fullName" />
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requisitionRef.location.langValue"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.location.langValue" />
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requisitionRef.operatingMode"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.operation_mode" />
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="startDate"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
              label: <FormattedMessage id="tsadv_JobOfferRequest.requisition.startDate" />
            }}
            getFieldDecoratorOpts={{}}
          />


          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="expectedSalary"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" },
            }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="positionSalaryLevelMin"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="allowance"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            getFieldDecoratorOpts={{}}
          />

          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="justification"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            getFieldDecoratorOpts={{}}
          />

          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="candidate"
            form={this.props.form}
            formItemOpts={{ style: { display: 'none', transform: 'scale(0)' } }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requestDate"
            form={this.props.form}
            formItemOpts={{ style: { display: 'none', transform: 'scale(0)' } }}
            getFieldDecoratorOpts={{}}
          />
          <ReadonlyField
            disabled
            entityName={JobOfferRequest.NAME}
            propertyName="requisition"
            form={this.props.form}
            formItemOpts={{ style: { display: 'none', transform: 'scale(0)' } }}
            getFieldDecoratorOpts={{}}
          />

          <ErrorBoundary>

            <ReadonlyField
              disabled
              entityName={JobOfferRequest.NAME}
              propertyName="candidateFiles"
              form={this.props.form}
              formItemOpts={{
                style: {
                  marginBottom: "12px"
                },
              }}
              optionsContainer={this.candidateFilessDc}
            />
          </ErrorBoundary>


          {this.globalErrors.length > 0 && (
            <Alert
              message={<MultilineText lines={toJS(this.globalErrors)} />}
              type="error"
              style={{ marginBottom: "24px" }}
            />
          )}

          {this.takCard()}
        </Form>
      </Card>
    );
  }

  loadData = async () => {
    if (!this.isNewEntity()) {
      await this.dataInstance.load(this.props.entityId);
    } else {
      const entityName = this.dataInstance.entityName;
      this.initItem(await restServices.portalHelperService.newEntity({ entityName: entityName }));
    }
  }

  setReactionDisposer = () => {
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      async (item: JobOfferRequest) => {
        this.onReactionDisposerEffect(item);

        console.log("Test 1:", item)

        this.props.form.setFieldsValue(this.onReactionFieldsValue(item));
        let linearManager = "";
        try{
          const response = await restServices.JobOfferRequestService.getManagers({ id: item!.requisition!.positionGroup!.id })
          const data = JSON.parse(response);
          console.log("Test", data);
          linearManager = data.fullName;
        }catch(e){
          linearManager = "NOT FOUND";
        }


        this.props.form.setFieldsValue({
          candidate: item.candidate && item.candidate.id,
          candidateRef: item.candidate,
          requisition: item.requisition && item.requisition.id,
          requisitionRef: item.requisition,
          status: item.status && item.status.id,
          statusRef: item.status,
          justification: item.justification,
          linearManager,
          // requestDate: item.requestDate
        });

        console.log("Test 2:", this.props.form.getFieldsValue(this.fields), this.onReactionFieldsValue(item))

        this.loaded = true;
      }
    );
  };

  componentDidMount() {
    (async () => {
      this.setReactionDisposer();
      await this.loadData();
      await this.loadBpmProcessData();
      // const JobOfferRequest = this.dataInstance.item!;

    })()
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(JobOfferRequestEditComponent))
);
