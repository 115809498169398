import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Button, Col, Row, Spin} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import CartElement from "../Components/CartElement";
import CartTotalElement from "../Components/CartTotalElement";
import {cartStore} from "../store/CartStore";


@inject("rootStore")
@injectMainStore
@observer
class CartPage extends React.Component<RootStoreProp & WrappedComponentProps> {

  componentDidMount() {
    cartStore.reloadCartItems();
  }

  renderLeftCol = () => {
    if (cartStore.isLoading && cartStore.cardItems.length === 0) {
      return <Row type={"flex"} justify={"center"}>
        <Spin size={"large"}/>
      </Row>
    }

    return <>
      {cartStore.cardItems.map(item => <CartElement key={item.id} item={item}/>)}
      <Row type={"flex"} justify={"center"} style={{height: "40px"}}>
        <Button hidden={cartStore.isLastPage || cartStore.cardItems.length === 0}
                loading={cartStore.isLoading && cartStore.cardItems.length !== 0}
                type={"primary"}
                onClick={() => cartStore.loadCartItems()}
        >
          <FormattedMessage id={"load.more"}/>
        </Button>
      </Row>
    </>
  }

  render() {
    return <>
      <Row>
        <Col xs={18}>
          {this.renderLeftCol()}
        </Col>
        <Col xs={6}>
          <CartTotalElement total={0}/>
        </Col>
      </Row>
    </>
  }
}

export default injectIntl(CartPage);