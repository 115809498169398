import { StandardEntity } from "./sys$StandardEntity";
import { DicPersonType } from "./tsadv$DicPersonType";
import { AnnualSalaryRevision } from "./tsadv$AnnualSalaryRevision";
export class AnnualSalaryRevisionBaseIncreasePercent extends StandardEntity {
  static NAME = "tsadv$AnnualSalaryRevisionBaseIncreasePercent";
  personType?: DicPersonType | null;
  baseIncreasePercent?: any | null;
  hideSalary?: boolean | null;
  annualSalaryRevision?: AnnualSalaryRevision | null;
}
export type AnnualSalaryRevisionBaseIncreasePercentViewName =
  | "_base"
  | "_local"
  | "_minimal";
export type AnnualSalaryRevisionBaseIncreasePercentView<
  V extends AnnualSalaryRevisionBaseIncreasePercentViewName
  > = V extends "_base"
  ? Pick<
    AnnualSalaryRevisionBaseIncreasePercent,
    "id" | "personType" | "baseIncreasePercent" | "hideSalary"
    >
  : V extends "_local"
    ? Pick<
      AnnualSalaryRevisionBaseIncreasePercent,
      "id" | "baseIncreasePercent" | "hideSalary"
      >
    : V extends "_minimal"
      ? Pick<AnnualSalaryRevisionBaseIncreasePercent, "id" | "personType">
      : never;
