import React from "react";
import {injectIntl, WrappedComponentProps, FormattedMessage} from "react-intl";
import {Col, Divider, Modal} from "antd";
import Img from "../../../components/Img";
import {NomineePojo} from "../../../../cuba/entities/base/tsadv$NomineePojo";
import {restServices} from "../../../../cuba/services";
import {NotFoundImage} from "../../../components/NoImage";

type PersonAwardCardProps = {
  nomineePojo: NomineePojo
}

class PersonAwardCard extends React.Component<WrappedComponentProps & PersonAwardCardProps> {
  personImage: Promise<string>

  componentDidMount() {
    const {pgId} = this.props.nomineePojo;
    this.personImage = restServices.employeeService.getPersonImage(pgId!);
  }

  renderFrontSide = () => {
    const {nomineePojo} = this.props;

    return <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      <div>
        <Img style={{height: "150px", paddingRight: "5px"}}
             type={"base64Promise"}
             src={this.personImage}
             image={NotFoundImage.EMPLOYEE}
        />
      </div>
      <div>
        <h2>{nomineePojo.fullName}</h2>
      </div>
      <div>
        <h3>{nomineePojo.position}</h3>
      </div>
      <div style={{height: "100%"}}/>
      <Divider style={{marginBottom: "0.5em"}}/>
      <div>
        <p style={{marginBottom: 0}}>
          <FormattedMessage id={"recognition.recognitionProgram.personAwardCard.winner"}/>
        </p>
        <h2>{nomineePojo.program}</h2>
      </div>
    </div>
  }

  showFullText = () => {
    const {messages} = this.props.intl;
    const {nomineePojo} = this.props;

    Modal.info({
      title: messages["recognition.recognitionProgram.personAwardCard.winner"] + " " + nomineePojo.program,
      width: "80%",
      icon: null,
      content: <p>
        {nomineePojo.description}
      </p>
    })
  }

  renderBackSide = () => {
    const {nomineePojo} = this.props;
    const {messages} = this.props.intl;

    return <div style={{display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-around"}}>
      <div className={"back-text"}>
        {nomineePojo.description}
      </div>
      <h2 style={{cursor: "pointer"}} onClick={this.showFullText}>
        <FormattedMessage id={"recognition.recognitionProgram.personAwardCard.readMore"}/>
      </h2>
    </div>
  }

  render() {
    const {nomineePojo} = this.props;

    return (
      <div className="deck" key={nomineePojo.id}>
        <div className="card hovercard">
          <div className="front face">
            {this.renderFrontSide()}
          </div>
          <div className="back face">
            {this.renderBackSide()}
          </div>
        </div>
      </div>
    )
  }

}

export default injectIntl(PersonAwardCard);