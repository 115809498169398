import * as React from "react";
import {FileDescriptor} from "../../../../cuba/entities/base/sys$FileDescriptor";
import {Table} from "antd";
import {IReactionDisposer, observable, reaction} from "mobx";
import {observer} from "mobx-react";
import {getFileUrl} from "../../../util/util";
import {injectIntl, WrappedComponentProps} from "react-intl";

export type AttachmentFileData = {
  file: FileDescriptor
  comment: string,
  author: string,
  date?: string
}

type Props = WrappedComponentProps & {
  items?: AttachmentFileData[]
}
@observer
class AttachmentsComponent extends React.Component<Props> {
  @observable data = this.props.items
  dataWatcher: IReactionDisposer

  messages = this.props.intl.messages

  constructor(props: Props) {
    super(props);
    this.dataWatcher = reaction(r => this.props.items, (arg, r) => {
      this.data = arg
    })
  }

  renderFileLink = (text: any, row: AttachmentFileData, index: number) => {
    const fileUrl = getFileUrl(row.file.id);
    return (
      <a href={fileUrl} target='_blank'>
        {row.file.name}
      </a>
    )
  }

  columns = [
    {
      title: this.messages['file'],
      dataIndex: 'file',
      render: this.renderFileLink
    },
    {
      title: this.messages['author'],
      dataIndex: 'author',
      key: 'author'
    },
    {
      title: this.messages['comment'],
      dataIndex: 'comment',
      key: 'comment'
    },
    {
      title: this.messages['date'],
      dataIndex: 'date',
      key: 'date'
    }
  ]

  render() {
    return (
      <div id={'container'} style={{
        width: '100%',
        height: '100%',
      }}>
        <label htmlFor={'container'}>{this.messages['attachments']}</label>
        <Table bordered dataSource={this.data} columns={this.columns} rowKey={record => record.file.id}/>
      </div>
    )
  }

  componentWillUnmount() {
    this.dataWatcher()
  }
}

export default injectIntl(AttachmentsComponent)
