import * as React from "react"
import {Button} from "antd";
import {
  faBell,
  faBusinessTime,
  faCalendarAlt, faCalendarCheck,
  faCalendarWeek, faEdit, faFileAlt, faHandHoldingUsd, faMedal,
  faMoneyCheck,
  faMoneyCheckAlt,
  faPlane, faProjectDiagram,
  faStamp, faUserClock, faUserEdit, faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Section from "../../hoc/Section";
import "./style.css"
import {Link} from "react-router-dom";
import imageLink from "../../../resources/icons/menu/absence.svg";
import {LeavingVacationRequestManagement} from "../LeavingVacationRequest/LeavingVacationRequestManagement";
import {PersonalDataRequest} from "../../../cuba/entities/base/tsadv$PersonalDataRequest";
import {PersonDocumentRequestManagement} from "../PersonDocumentRequest/PersonDocumentRequestManagement";
import {AddressRequestManagement} from "../AddressRequest/AddressRequestManagement";
import {PersonEducationRequest} from "../../../cuba/entities/base/tsadv_PersonEducationRequest";

import {PersonalDataRequestManagement} from "../PersonalDataRequest/PersonalDataRequestManagement";
import {CertificateRequestManagement} from "../CertificateRequest/CertificateRequestManagement";
import {PersonPayslipManagement} from "../PersonPayslip/PersonPayslipManagement";
import {AbsenceRvdRequestManagement} from "../MyTeam/timeManagement/rvd/MyTeamPersonRvdRequest/AbsenceRvdRequestManagement";
import {JobDescriptionRequestManagement} from "../JobDescriptionRequest/JobDescriptionRequestManagement";
import {ScheduleOffsetsRequestManagement} from "../ScheduleOffsetsRequest/ScheduleOffsetsRequestManagement";
import {AbsenceRequestManagement} from "../AbsenceRequest/AbsenceRequestManagement";
import {PunishmentAssignmentRequest} from "../../../cuba/entities/base/tsadv$PunishmentAssignmentRequest";
import {PunishmentAssignmentRequestManagement} from "../MyTeam/assignment/punishmentAssignmentRequest/PunishmentAssignmentRequestManagement";
import {WccfManagement} from "../Wccf/WccfManagement";
import {VacancyRequestManagement} from "../VacancyRequest/VacancyRequestManagement";
import {AnnualSalaryRevisionManagement} from "../AnnualSalaryRevision/AnnualSalaryRevisionManagement";
import {VacationScheduleManagement} from "../VacationSchedule/VacationScheduleManagement";
import {MyProfileManagement} from "../MyProfile/MyProfileManagement";
import {observable} from "mobx";
import {collection, getMenuItems, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {inject, observer} from "mobx-react";
import {restServices} from "../../../cuba/services";
import {RootStoreProp} from "../../store";
import {MySurChargeRequestManagement} from "../SurChargeRequest/MySurChargeRequestManagement";
import {JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import moment from "moment";
import {SurChargeRequestManagement} from "../SurChargeRequest/SurChargeRequestManagement";
import {ProcInstance} from "../../../cuba/entities/base/bpm$ProcInstance";
import {ProcInstanceV} from "../../../cuba/entities/base/tsadv$ProcInstanceV";
import {OrgStructureRequestManagement} from "../orgStructureRequest/OrgStructureRequestManagement";
import {DisabilityRequestManagement} from "../DisabilityRequest/DisabilityRequestManagement";
import {Dismissal} from "../../../cuba/entities/base/tsadv$Dismissal";
import {DismissalRequestManagement} from "../DismissalRequest/DismissalRequestManagement";
import LoadingPage from "../LoadingPage";
import {IncentiveManagement} from "../Incentive/IncentiveManagement";

@injectMainStore
@inject("rootStore")
@observer
class AllRequests extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  @observable isManager: any;
  @observable history: any;
  @observable data: any
  date = moment();
  getDate = () => this.date.format(JSON_DATE_TIME_FORMAT);
  @observable menuItems: any

  componentDidMount() {
    (async () =>
      this.props.rootStore!.menu.loadUserMenuCustomization().then(res => {
        this.menuItems = res
        this.isManager = res.find((item: any) => item.menuItem === "my-team")
      }))();
    console.log(this.isManager)
  }

  render() {
    console.log(this.menuItems)
    const workerItems = [
      {
        requestType: "menu.absence",
        icon: faUserClock,
        link: "/absence",
        enabled: this.menuItems && this.menuItems.find((item: any) => item.menuItem === "allRequests-absence")
      },
      {
        requestType: "Заявка на изменение личных данных",
        icon: faMoneyCheck,
        link: MyProfileManagement.PATH,
        enabled: false
      },
      {
        requestType: "Заявка на изменение документов",
        icon: faMoneyCheck,
        link: MyProfileManagement.PATH,
        enabled: false
      },
      {requestType: "Заявка на изменение адреса", icon: faMoneyCheck, link: MyProfileManagement.PATH, enabled: false},
      {requestType: "Заявка на образование", icon: faMoneyCheck, link: MyProfileManagement.PATH, enabled: false},
      {
        requestType: "menu.vacationSchedule.my",
        icon: faBusinessTime,
        link: "/vacationSchedule/my",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-worker-vacation-schedule"
        })
      },
      {
        requestType: "menu.job-confirmation-letter",
        icon: faStamp,
        link: CertificateRequestManagement.PATH,
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-certificate"
        })
      },
      {
        requestType: "menu.personPayslip",
        icon: faMoneyCheckAlt,
        link: PersonPayslipManagement.PATH + "/",
        enabled: this.menuItems && this.menuItems.find((item: any) => item.menuItem === "allRequests-payslip")
      },
      {
        requestType: "menu.surCharge",
        icon: faHandHoldingUsd,
        link: "/materialhelppage",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-worker-material-help"
        })
      },
      {
        requestType: "menu.dismissal",
        icon: faEdit,
        link: DismissalRequestManagement.PATH + "/" + DismissalRequestManagement.NEW_SUBPATH,
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-dismissal"
        })
      }

    ]
    const managerItems = [
      {
        requestType: "menu.rvd-request-manager",
        icon: faBusinessTime,
        link: "/rvdList",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-rvd"
        })
      },
      {
        requestType: "menu.jobDescription-request-manager",
        icon: faFileAlt,
        link: "/jobDescriptionList",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-job-description"
        })
      },
      {
        requestType: "menu.vacationSchedule.manager",
        icon: faCalendarAlt,
        link: "/vacationSchedule/manager",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === this.menuItems && this.menuItems.find((item: any) => {
            return item.menuItem === "allRequests-manager-vacation-schedule"
          })
        })
      },
      {
        requestType: "menu.incentive",
        icon: faMoneyCheckAlt,
        link: IncentiveManagement.PATH,
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-incentive"
        })
      },
      {
        requestType: "menu.employeeRecognition-request-manager",
        icon: faMedal,
        link: "employeeRecognitionPage",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-recognition"
        })
      },
      {
        requestType: "scheduleOffsetRequest",
        icon: faCalendarWeek,
        link: "/scheduleOffsetList",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-schedule-offset"
        })
      },
      {
        requestType: "punishment",
        icon: faBell,
        link: "/punishmentRequestList",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-punishment"
        })
      },
      {requestType: "punishmentRemoval", icon: faCalendarCheck, link: "/punishmentRemovalList", enabled: true},
      {
        requestType: "wccf.request",
        icon: faUserEdit,
        link: "/wccf-request-page",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-wccf"
        })
      },
      {
        requestType: "menu.surCharge",
        icon: faHandHoldingUsd,
        link: SurChargeRequestManagement.PATH,
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === this.menuItems && this.menuItems.find((item: any) => {
            return item.menuItem === "allRequests-manager-material-help"
          })
        })
      },
      {
        requestType: "menu.vacancyRequest",
        icon: faUserPlus,
        link: VacancyRequestManagement.PATH,
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-raf"
        })
      },
      {
        requestType: "positionOverlappingRequest",
        icon: faUserEdit,
        link: "/jobCombineList",
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-job-combine"
        })
      },
      {
        requestType: "menu.orgStructureRequest",
        icon: faProjectDiagram,
        link: OrgStructureRequestManagement.PATH,
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-structure"
        })
      },
      {
        requestType: "menu.annual-salary-revision",
        icon: faMoneyCheckAlt,
        link: AnnualSalaryRevisionManagement.PATH,
        enabled: this.menuItems && this.menuItems.find((item: any) => {
          return item.menuItem === "allRequests-annual-salary-revision"
        })
      },

    ]
    if (this.menuItems)
      return (
        <div className={"wrapper"} style={{width: "80%", margin: "0 auto", marginLeft: 40}}>

          <div className={"header"} style={{
            display: "flex",
            marginTop: 20,
            alignItems: "center",
            marginBottom: 20,
            justifyContent: "space-between"
          }}><h1
            style={{fontSize: "200%", marginBottom: 0}}>{this.props.intl.formatMessage({id: "menu.allRequests"})}</h1>
            <Link to={"/requestHistoryPage"}><Button
              type={"primary"}>{this.props.intl.formatMessage({id: "requestHistory"})}</Button></Link></div>
          <h2 style={{marginBottom: 20}}>{this.props.intl.formatMessage({id: "workerRequests"})}</h2>
          <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "20px 20px", gridAutoRows: "1fr"}}>
            {workerItems.map((item: any) => {
              if (item.enabled && !item.iconLink) {
                return <Section className={"allRequests-request"}
                                style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <div style={{width: 140, height: 140}}>
                    <FontAwesomeIcon style={{fontSize: "800%"}} icon={item.icon}/></div>
                  <Link style={{fontSize: "105%"}}
                        to={item.link}>{this.props.intl.formatMessage({id: item.requestType})}</Link>
                </Section>
              } else if (item.enabled && item.iconLink) {
                return <Section className={"allRequests-request"}
                                style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <div style={{width: 140, height: 140}}>
                    <img style={{height: 112, width: 140}} src={item.iconLink}/></div>
                  <Link style={{fontSize: "105%"}} to={item.link}>{item.requestType}</Link>
                </Section>
              }
              return null
            })}
          </div>
          {this.isManager ? <>
            <h2 style={{marginBottom: 20, marginTop: 20}}>{this.props.intl.formatMessage({id: "managerRequests"})}</h2>
            <div
              style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gap: "20px 20px", gridAutoRows: "1fr"}}>
              {managerItems.map((item: any) => {
                if (item.enabled && !item.iconLink) {
                  return <Section className={"allRequests-request"}
                                  style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{width: 140, height: 140}}>
                      <FontAwesomeIcon style={{fontSize: "800%"}} icon={item.icon}/></div>
                    <Link style={{fontSize: "105%"}}
                          to={item.link}>{this.props.intl.formatMessage({id: item.requestType})}</Link>
                  </Section>
                } else if (item.enabled && item.iconLink) {
                  return <Section className={"allRequests-request"}
                                  style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <img src={item.iconLink}/><Link style={{fontSize: "105%"}} to={item.link}>{item.requestType}</Link>
                  </Section>
                }
                return null
              })}
            </div>
          </> : null}
        </div>
      );
    else {
      return <LoadingPage/>
    }
  }
}

export default injectIntl(AllRequests);
