import * as React from "react"
import {Table} from "antd";
import moment from "moment";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import {RootStoreProp} from "../../store";
import {observable} from "mobx";
import {Link} from "react-router-dom";
import {CourseManagement} from "../Course/CourseManagement";

interface IProps {
  data: any,
  toggleVisibility: () => void,
  setSelectedCourse: (id: string) => void,
  month: string
}

@injectMainStore
@inject("rootStore")
@observer
class CourseScheduleMonth extends React.Component<IProps & WrappedComponentProps & RootStoreProp & MainStoreInjected> {
  @observable data = this.props.data;
  lang = this.props.rootStore!.userInfo.locale!

  shouldComponentUpdate(prev: any, next: any) {
    return prev !== next
  }

  render() {
    const months = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    let selectedMonthName = months[parseInt(this.props.month.split(" ")[0]) - 1];
    console.log(this.props.data)
    return (
      <div>
        <h1 style={{fontSize: 26, color:"#005487"}}>{this.props.intl.formatMessage({id: selectedMonthName})+" "+this.props.month.split(" ")[1]}</h1>
        <Table style={{marginBottom: 30}} pagination={{
          pageSize: 10,
          hideOnSinglePage: true
        }} bordered={true} dataSource={this.data}>
          <Table.Column width={"12.5%"} title={this.props.intl.formatMessage({id: "event"})}
                        render={(text, record: any) => {
                          return <Link to={CourseManagement.PATH+"/"+record.course_id}>{this.lang === "en" ? record.name_lang3 : record.name_lang1}</Link>
                        }}/>
          <Table.Column width={"12.5%"} title={this.props.intl.formatMessage({id: "courseScheduleEnrollment.trainer"})}
                        render={(text, record: any) => {
                          return this.lang === "en" ? record.full_name_number_latin : record.full_name_number_cyrillic
                        }}/>
          <Table.Column width={"12.5%"} title={this.props.intl.formatMessage({id: "course.duration"})}
                        dataIndex={"duration"}/>
          <Table.Column width={"12.5%"}
                        title={this.props.intl.formatMessage({id: "courseScheduleEnrollment.startDate"})}
                        render={(text, record: any) => {
                          return moment(record.start_date).format("L")
                        }}/>
          <Table.Column width={"12.5%"} title={this.props.intl.formatMessage({id: "courseScheduleEnrollment.endDate"})}
                        render={(text, record: any) => {
                          return moment(record.end_date).format("L")
                        }}/>
          <Table.Column width={"12.5%"} title={this.props.intl.formatMessage({id: "course.placesLeft"})}
                        dataIndex={"places_left"}/>
          }}/>
          <Table.Column width={"12.5%"} title={this.props.intl.formatMessage({id: "address"})}
                        render={(text, record: any) => {
                          return this.lang === "en" ? record.address_en : record.address_ru
                        }}/>
          <Table.Column width={"12.5%"} title={this.props.intl.formatMessage({id: "courseScheduleEnrollment.status"})}
                        render={(text, record: any) => {
                          if (this.lang === "en") {
                            if (record.status_en === "Request") {
                              return <a onClick={() => {
                                this.props.setSelectedCourse(record.id);
                                this.props.toggleVisibility()
                              }}>{record.status_en}
                              </a>
                            } else {
                              return record.status_en
                            }
                          } else {
                            if (record.status_en === "Request") {
                              return <a onClick={() => {
                                this.props.setSelectedCourse(record.id);
                                this.props.toggleVisibility()
                              }}>{record.status_ru}
                              </a>
                            } else {
                              return record.status_ru
                            }
                          }
                        }}/>
        </Table>
      </div>
    );
  }
}

export default injectIntl(CourseScheduleMonth)
