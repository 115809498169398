import * as React from "react";
import {inject, observer} from "mobx-react";

import { collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";

import DataTableFormat from "../../components/DataTable/intex";
import {Link} from "react-router-dom";

import {AnnualSalaryRevisionManagement} from "./AnnualSalaryRevisionManagement"
import {AnnualSalaryRevisionRequest} from "../../../cuba/entities/base/tsadv$AnnualSalaryRevisionRequest";
import SearchInput from "../../components/SearchInput";

@injectMainStore
@inject("rootStore")
@observer
class AnnualSalaryRevisionListComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  dataCollection = collection<AnnualSalaryRevisionRequest>(AnnualSalaryRevisionRequest.NAME, {
    view: "annualSalaryRevisionRequest-view",
    filter: {
      conditions: [

        {
          property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!
        }
      ]
    },
  })

  fields = [
    "annualSalaryRevision",
  ];

  dicLangValue = 'nameLang' + this.props.rootStore!.userInfo!.localeIndex;

  onSearch = (value: string) => {
    this.dataCollection.items = [];
    if (value && value.length >= 1) {

      this.dataCollection.filter = {
        conditions: [

          {
            property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!
          },
          {
            property: "annualSalaryRevision."+this.dicLangValue,
            operator: 'contains',
            value: value
          }
        ]
      }

      this.dataCollection.load();
    } else {
      this.dataCollection.filter = {
        conditions: [
          {
            property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!
          }
        ]
      }

      this.dataCollection.load();
    }
  };

  render() {
    return (
      <Page pageName={this.props.intl.formatMessage({id: "menu.annual-salary-revision"})}>
        <Section size="large">
          <SearchInput onSearch={this.onSearch}/>
          <DataTableFormat
            dataCollection={this.dataCollection}
            fields={this.fields}
            hideSelectionColumn={true}
            render={[{
              column: this.fields[0],
              render: (text, record) => <Link to={ AnnualSalaryRevisionManagement.PATH + "/" +record.id}>{text}</Link>
            }]}
          />
        </Section>
      </Page>
    );
  }
}

const AnnualSalaryRevisionList = injectIntl(AnnualSalaryRevisionListComponent);

export default AnnualSalaryRevisionList;
