import {Link, withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import React from "react";
import {PersonPojo} from "../../../../cuba/entities/base/tsadv$PersonPojo";
import Img from "../../../components/Img";
import {NotFoundImage} from "../../../components/NoImage";
import {restServices} from "../../../../cuba/services";
import {getPersonPageLink} from "../recognitionUtils";

type PersonLinkProps = {
  person: PersonPojo
}

class PersonLink extends React.Component<PersonLinkProps & RouteComponentProps> {
  personImage: Promise<string>

  componentDidMount() {
    const {personGroupId} = this.props.person;
    this.personImage = restServices.employeeService.getPersonImage(personGroupId!);
  }

  render() {
    const {name, personGroupId} = this.props.person;
    const link = getPersonPageLink(personGroupId);

    return <>
      <Img image={NotFoundImage.EMPLOYEE} src={this.personImage} type={"base64Promise"} style={{width: "25px", borderRadius: "100%", paddingRight: "5px"}}/>
      <Link to={link} className={"person-link"}>
        <span style={{color: '#40a9ff', fontWeight: 'bold', cursor: "pointer"}}>{name}</span>
      </Link>
    </>
  }
}

export default withRouter(PersonLink);
