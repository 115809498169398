import * as React from "react";
import DataTableFormat from "../../components/DataTable/intex";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {JobDescriptionRequest} from "../../../cuba/entities/base/tsadv_JobDescriptionRequest";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {JobDescriptionRequestManagement} from "../JobDescriptionRequest/JobDescriptionRequestManagement";
import {rootStore, RootStoreProp} from "../../store";
import {inject, observer} from "mobx-react";
import Section from "../../hoc/Section";
import Page from "../../hoc/PageContentHoc";
@injectMainStore
@inject("rootStore")
@observer
class JobDescriptionList extends React.Component<MainStoreInjected&WrappedComponentProps& RootStoreProp>{
  dataCollection = collection<JobDescriptionRequest>(
    JobDescriptionRequest.NAME,

    {filter:{
        conditions:[{
          property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!
        }]
      }, view: "jobDescriptionRequest-browse", sort: "-updateTs" },
  );

  fields = [

    "requestNumber",

    "requestDate",
    "status",
    "positionGroup"
  ];
    render(){
      this.props.rootStore!.positionStructureStore.clearPositionStore();
      return(
        <Page>
          <Section size={"large"}>
          {this.dataCollection.status=="DONE"?<Link to={JobDescriptionRequestManagement.PATH+"/"+JobDescriptionRequestManagement.NEW_SUBPATH}><Button type={"primary"}>{this.props.intl.formatMessage({id:"diCreate"})}</Button></Link>:null}
          <DataTableFormat canSelectRowByClick={false} hideSelectionColumn={true} dataCollection={this.dataCollection} fields={this.fields} render={[{column:"requestNumber", render:((text, record) => <Link to={JobDescriptionRequestManagement.PATH+"/"+record.id}>{text}</Link>)}]}/>
          </Section>
        </Page>
      )
    }
}
export default injectIntl(JobDescriptionList);