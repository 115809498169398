import { AbstractParentEntity } from "./AbstractParentEntity";
import { TsadvUser } from "./tsadv$UserExt";
import { DocumentsFamiliarization } from "./DocumentsFamiliarization";

export class ListOfAcknowledgement extends AbstractParentEntity {

  static NAME = "tsadv_ListOfAcknowledgement";

  documentFamiliarization: DocumentsFamiliarization;
  user: TsadvUser;
  acknowledgement: boolean;

}
