import * as React from "react";
import {Button, Modal, Table} from "antd";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {observable} from "mobx";
import {observer} from "mobx-react";
import moment from "moment";

type Props = {
  data: any,
  visible: boolean,
  setVisible: () => void
}

@injectMainStore
@observer
class SuccessorHistoryModal extends React.Component<MainStoreInjected & WrappedComponentProps & Props> {
  render() {
    console.log(this.props.data)
    return (
      <Modal centered  cancelButtonProps={{style: {display: "none"}}} onOk={this.props.setVisible} okType={"default"}
             maskClosable={false} closable={false} title={this.props.intl.formatMessage({id: "successorHistory"})}
             visible={this.props.visible} width={850}>
        <Table bordered dataSource={this.props.data && this.props.data}>
          <Table.Column title={this.props.intl.formatMessage({id: "reservistUpdatets"})} render={(text) => {
            return moment(text).format("DD.MM.YYYY")
          }} dataIndex={"date"}/>
          <Table.Column title={this.props.intl.formatMessage({id: "vacancyRequest.keyPosition"})}
                        dataIndex={"succession.positionGroup._instanceName"}/>
          <Table.Column title={this.props.intl.formatMessage({id: "readinessLevel"})}
                        dataIndex={"readinessLevel._instanceName"}/>
          <Table.Column title={this.props.intl.formatMessage({id: "readinessDate"})} render={(text) => {
            return moment(text).format("DD.MM.YYYY")
          }} dataIndex={"readinessLevelCulcDate"}/>
          <Table.Column title={this.props.intl.formatMessage({id: "status"})} dataIndex={"status._instanceName"}/>
          <Table.Column title={this.props.intl.formatMessage({id: "comment"})} dataIndex={"comment"}/>
        </Table>
      </Modal>
    );
  }
}

export default injectIntl(SuccessorHistoryModal)
