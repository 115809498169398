import React, { Component } from 'react';
import Img from "../../components/Img";
import { Col, Row } from "antd";
import {RouteComponentProps, withRouter} from "react-router";
import {TimeLineItemFull} from "./TimeLineItemFull";

export type FeedItemModel = {
  id: string,
  url: string,
  img?: Promise<string>,
  date: string
  title: string,
  text: string
}

class TimeLineItem extends Component<FeedItemModel&RouteComponentProps> {
  render() {
    const {id, title, text, date, img, url} = this.props;

    return (
      <Row className="news-feed" key={id}>
        <Col span={10} style={{height: '100%'}} lg={12} xs={24}>
          <Img className="news-image" type="promise" src={img}/>
        </Col>
        <Col span={14} style={{height: '100%'}} lg={12} xs={24}>
          <div className="news-title-container">
            <Row>
              <div className="news-title">
                <span>{date}</span>
                <span>
                  {url?
                    <a className="underline" href={url}>{title}</a>:
                    <a className="underline" onClick={()=>this.props.history.push({pathname:TimeLineItemFull.PATH+"/"+id,
                                                          state:{id:id,title:title,text:text,date:date,img:img,url:url}})}>{title}
                    </a>
                  }

              </span>
              </div>
            </Row>
            <Row className="news-text">
             <div dangerouslySetInnerHTML={{__html:text}}/>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default withRouter(TimeLineItem);