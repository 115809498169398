import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { TsadvUser } from "./tsadv$UserExt";
import {PersonGroupExt} from "./base$PersonGroupExt";
export class ProcInstanceMyTeamV extends AbstractBprocRequest {
  static NAME = "tsadv$ProcInstanceMyTeamV";
  process?: string | null;
  processRu?: string | null;
  processKz?: string | null;
  processEn?: string | null;
  entityName?: string | null;
  businessKey?: string | null;
  entityId?: any | null;
  active?: boolean | null;
  startUser?: TsadvUser | null;
  startTime?: any | null;
  endTime?: any | null;
  entity?: AbstractBprocRequest | null;
  personGroup?: PersonGroupExt | null;
}
export type ProcInstanceMyTeamVViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "procInstanceMyTeamV-view";
export type ProcInstanceMyTeamVView<
  V extends ProcInstanceMyTeamVViewName
> = V extends "_base"
  ? Pick<
      ProcInstanceMyTeamV,
      | "id"
      | "requestNumber"
      | "processRu"
      | "processKz"
      | "processEn"
      | "entityName"
      | "businessKey"
      | "entityId"
      | "active"
      | "startTime"
      | "endTime"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestDate"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      ProcInstanceMyTeamV,
      | "id"
      | "processRu"
      | "processKz"
      | "processEn"
      | "entityName"
      | "businessKey"
      | "entityId"
      | "active"
      | "startTime"
      | "endTime"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<ProcInstanceMyTeamV, "id" | "requestNumber">
  : V extends "procInstanceMyTeamV-view"
  ? Pick<
      ProcInstanceMyTeamV,
      | "id"
      | "requestNumber"
      | "processRu"
      | "processKz"
      | "processEn"
      | "entityName"
      | "businessKey"
      | "entityId"
      | "active"
      | "startTime"
      | "endTime"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestDate"
      | "comment"
      | "startUser"
    >
  : never;
