import { AbstractParentEntity } from "./AbstractParentEntity";
import { CompetenceGroup } from "./tsadv$CompetenceGroup";
import { Course } from "./tsadv$Course";
export class CompetenceIndicator extends AbstractParentEntity {
  static NAME = "tsadv_CompetenceIndicator";
  competenceGroup?: CompetenceGroup | null;
  langValue?: string | null;
  langValue1?: string | null;
  langValue2?: string | null;
  langValue3?: string | null;
  langValue4?: string | null;
  langValue5?: string | null;
  training?: Course | null;
}
export type CompetenceIndicatorViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "competenceIndicator-browse"
  | "competenceIndicator-browse"
  | "competenceIndicator-edit"
  | "competenceIndicator-edit";
export type CompetenceIndicatorView<
  V extends CompetenceIndicatorViewName
> = V extends "_base"
  ? Pick<
      CompetenceIndicator,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      CompetenceIndicator,
      | "id"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_minimal"
  ? Pick<
      CompetenceIndicator,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
    >
  : V extends "competenceIndicator-browse"
  ? Pick<
      CompetenceIndicator,
      | "id"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "competenceGroup"
      | "langValue"
      | "training"
    >
  : V extends "competenceIndicator-browse"
  ? Pick<
      CompetenceIndicator,
      | "id"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "competenceGroup"
      | "langValue"
      | "training"
    >
  : V extends "competenceIndicator-edit"
  ? Pick<
      CompetenceIndicator,
      | "id"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "competenceGroup"
      | "langValue"
      | "training"
    >
  : V extends "competenceIndicator-edit"
  ? Pick<
      CompetenceIndicator,
      | "id"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "competenceGroup"
      | "langValue"
      | "training"
    >
  : never;
