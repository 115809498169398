import React from "react";
import {inject, observer} from "mobx-react";
import {collection, getEnumCaption, getPropertyInfoNN, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../../store";
import {FormComponentProps} from "antd/lib/form";
import {MyProfileProps} from "../../../MyProfile/MyProfile";
import {MyTeamCardProps} from "../../MyTeamCard";
import Column from "antd/es/table/Column";
import {Modal, Table} from "antd";
import {restServices} from "../../../../../cuba/services";
import {PersonCompetenceHistory} from "../../../../../cuba/entities/base/tsadv_PersonCompetenceHistory";
import {action, observable} from "mobx";
import moment from "moment";
import {PersonCompetenceData} from "../../../../../cuba/entities/base/tsadv_PersonCompetenceData";

type Props = {
  currentAssignmentId: string,
};
type ScoredData = {
  competenceGroupId?:string,
  hasHistory?:boolean,
  id?:string,
  key?:string,
  parent?:PersonCompetenceData,
  requiredLvl?:string,
  requiredLvlId?:string,
  positionGroupId?:string,
  personCompetenceId?:string,
  parentId?:string,
  scaleId?:string,
  name?:string,
  type?:string,
  children?:any,
  courseName:string,
  currentLvl:string
}
@inject("rootStore")
@injectMainStore
@observer
class ScoreCardHistory extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp & MyTeamCardProps & Props>{
  @observable scoredData:any
  @observable competenceGroupId:string
  @observable historyId:string
  @observable visibleModal:boolean = false
  @observable
  historyDataCollection:any
  @observable isLoading:boolean = true
  render() {


    const rowSelection = {
      onChange: (selectedRowKeys:any, selectedRows:any) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      onSelect: (record:any, selected:any, selectedRows:any) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected:any, selectedRows:any, changeRows:any) => {
        console.log(selected, selectedRows, changeRows);
      },
    };

    return <>
      <div>
        <Table<ScoredData>
          dataSource={this.scoredData}
          scroll={{x:true}}
          bordered={true}
          rowSelection={rowSelection}
          className={"competence_table"}
          tableLayout={"auto"}
          size={"small"}
          columns={[
            {
              title: this.props.intl.formatMessage({id: "cba.scoreCardMain.competency"}),
              dataIndex: 'competenceName',
              key: 'competenceName',
            },{
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.requiredLevel"}),
              dataIndex: 'scaleName',
              key: 'scaleName',
            },{
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.currentLevel"}),
              dataIndex: 'currentScaleLevelName',
              key: 'currentScaleLevelName',
            },{
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.history"}),
              dataIndex: 'hasHistory',
              key: 'history',
              render: (_, record) => record.hasHistory &&(
                <button
                  style={{
                    color:"#005487",
                    border:"none",
                    background:"transparent",
                    fontWeight:500
                  }}
                  onClick={()=> this.showModal(record.personCompetenceId!)}
                >
                  <div style={{cursor:"pointer"}}>
                    {this.props.intl.formatMessage({id:"cba.scoreCardMain.detail"})}
                  </div>
                </button>
              )
            },{
              title: this.props.intl.formatMessage({id:"cba.scoreCardMain.training"}),
              dataIndex: "courseName",
              key: "courseName"
            }
          ]}
        />
        <Modal
          title={this.props.intl.formatMessage({id:"cba.scoreCardMain.HIstoryModal"})}
          visible={this.visibleModal}
          onOk={this.handleOk}
          className={"cba_modal"}
          onCancel={this.handleOk}
        >
          <Table
            dataSource={this.historyDataCollection && this.historyDataCollection.items!}
            scroll={{x:true}}
            bordered={true}
          >
            <Column<PersonCompetenceHistory>
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.source"})}
              dataIndex={"source"}
              key={"source"}
              render={
                (_, record)=>{
                  return getEnumCaption(record.personCompetenceType, getPropertyInfoNN("personCompetenceType", PersonCompetenceHistory.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!)
                }
              }
            />
            <Column<PersonCompetenceHistory>
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.competency"})}
              dataIndex={"competence"}
              key={"competency"}
              render={
                (text, record)=>{
                  return record.competence && record.competence!['_instanceName']!
                }
              }
            />
            <Column<PersonCompetenceHistory>
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.level"})}
              dataIndex={"level"}
              key={"level"}
              render={
                (text, record)=>{
                  return record.scaleLevel && record.scaleLevel!['_instanceName']
                }
              }
            />
            <Column
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.history.createTs"})}
              dataIndex={"createTs"}
              key={"createTs"}
              render={
                (text , record:PersonCompetenceHistory)=>{
                  const hour = moment(record.createTs).format("HH:mm")
                  const date = moment(record.createTs).format("DD.MM.YYYY")
                  return <>{date+" "+ hour}</>
                }
              }
            />
            <Column
              title={this.props.intl.formatMessage({id:"cba.scoreCardMain.history.createdBy"})}
              dataIndex={"createdBy"}
              key={"createdBy"}
              render={
                (text , record:PersonCompetenceHistory)=>{
                  return record.createdBy
                }
              }

            />

          </Table>
        </Modal>
      </div>
    </>;

  }
  @action
  showModal = (id:string) => {
    this.visibleModal = true
    this.historyDataCollection = collection<PersonCompetenceHistory>(PersonCompetenceHistory.NAME,{
      view:"personCompetenceHistory-browse",
      filter:{
        conditions:[{
          property:'personCompetence.id',
          operator:"=",
          value:id
        }]
      }
    })
  };

  @action
  handleOk = () => {
    this.visibleModal = false
  };

  uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  componentDidMount() {
    restServices.scoredCompetencyService.getScorecardHistoryData({assignmentId:this.props.currentAssignmentId}).then(value=>{
      this.competenceGroupId = value.competenceGroupId
      console.log(value)

      let newArr:any[] = []
      newArr = value.map((item:ScoredData)=>({
        id:item.id,
        key:this.uuid(),
        competenceName:item.name ? item.name : "",
        parentId:item.parent && item.parent.id! ? item.parent.id : null,
        children:[],
        personCompetenceId:item.personCompetenceId,
        scaleName:item.requiredLvl ? item.requiredLvl : "",
        hasHistory: item.hasHistory ? item.hasHistory : "",
        courseName:item.courseName ? item.courseName : "",
        currentScaleLevelName:item.currentLvl ? item.currentLvl : ""
      }))
      const arrMap = newArr.reduce((acc, el, i) => {
        acc[el.id] = i;
        return acc;
      }, {});
      let roots:any[] = [];
      newArr.forEach((el:any) => {
        if (el.parentId === null) {
          roots.push(el);
        } else {
          newArr[arrMap[el.parentId]].children.push(el);
        }
      });
      this.scoredData = roots
      this.isLoading = false
    })
  }
}
export default injectIntl(ScoreCardHistory)