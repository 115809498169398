import * as React from "react";
import {createElement} from "react";
import {Card, Col, Form, Input, Row, Select} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";

import {
  collection,
  DataCollectionStore,
  getCubaREST,
  injectMainStore,
  instance,
  Msg, WithId,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../app/App.css";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import moment from "moment";
import {PersonDocumentRequestManagement} from "../PersonDocumentRequest/PersonDocumentRequestManagement";
import {goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import MsgEntity from "../../components/MsgEntity";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import {SerializedEntity} from "@cuba-platform/rest/dist-node/model";
import {formatDate} from "../../util/Date/Date";
import {MilitaryFormRequest} from "../../../cuba/entities/base/tsadv_MilitaryFormRequest";
import {MilitaryForm} from "../../../cuba/entities/base/tsadv$MilitaryForm";
import {DicAttitudeToMilitary} from "../../../cuba/entities/base/tsadv$DicAttitudeToMilitary";
import {DicMilitaryDocumentType} from "../../../cuba/entities/base/tsadv$DicMilitaryDocumentType";
import {DicMilitaryType} from "../../../cuba/entities/base/tsadv$DicMilitaryType";
import {DicSuitabilityToMilitary} from "../../../cuba/entities/base/tsadv$DicSuitabilityToMilitary";
import {DicUdo} from "../../../cuba/entities/base/tsadv$DicUdo";
import {DicMilitaryRank} from "../../../cuba/entities/base/tsadv$DicMilitaryRank";
import {DicOfficerType} from "../../../cuba/entities/base/tsadv$DicOfficerType";
import {PersonDocument} from "../../../cuba/entities/base/tsadv$PersonDocument";
import {DicTroopsStructure} from "../../../cuba/entities/base/tsadv$DicTroopsStructure";

type EditorProps = {
  entityId: string;
  militaryFormId?: string;
};

@inject("rootStore")
@injectMainStore
@observer
class MilitaryFormRequestEditComponent extends AbstractBprocEdit<MilitaryFormRequest, EditorProps> {
  processDefinitionKey = "militaryFormRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<MilitaryFormRequest>(MilitaryFormRequest.NAME, {
    view: "militaryFormRequest-view",
    loadImmediately: false
  });

  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  attitudeDc = (this.isUserInitiator || this.props.entityId === 'new')?collection<DicAttitudeToMilitary>(DicAttitudeToMilitary.NAME,{
    view:"dicAttitudeToMilitary-edit",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }) : collection<DicAttitudeToMilitary>(DicAttitudeToMilitary.NAME,{view:"dicAttitudeToMilitary-edit"})

  militaryDocumentTDc = (this.isUserInitiator || this.props.entityId === 'new')? collection<DicMilitaryDocumentType>(DicMilitaryDocumentType.NAME,{
    view:"dicMilitaryDocumentType-edit",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }) : collection<DicMilitaryDocumentType>(DicMilitaryDocumentType.NAME,{view:"dicMilitaryDocumentType-edit"})

  militaryServiceTypeDc =(this.isUserInitiator || this.props.entityId === 'new')? collection<DicMilitaryType>(DicMilitaryType.NAME,{
    view:"dicMilitaryType-edit",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }):collection<DicMilitaryType>(DicMilitaryType.NAME,{
    view:"dicMilitaryType-edit",

  })
  militarySustainabilityDc =(this.isUserInitiator || this.props.entityId === 'new')? collection<DicSuitabilityToMilitary>(DicSuitabilityToMilitary.NAME,{
    view:"dicSuitabilityToMilitary-edit",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }):collection<DicSuitabilityToMilitary>(DicSuitabilityToMilitary.NAME,{
    view:"dicSuitabilityToMilitary-edit",
  })
  militaryProfileDc =(this.isUserInitiator || this.props.entityId === 'new')? collection<DicTroopsStructure>(DicTroopsStructure.NAME,{
    view:"dicTroopsStructure-edit",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }):collection<DicTroopsStructure>(DicTroopsStructure.NAME,{
    view:"dicTroopsStructure-edit",
  })
  militaryRankDc =(this.isUserInitiator || this.props.entityId === 'new')? collection<DicMilitaryRank>(DicMilitaryRank.NAME,{
    view:"dicMilitaryRank-view",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }):collection<DicMilitaryRank>(DicMilitaryRank.NAME,{
    view:"dicMilitaryRank-view"
  })

  officerTypeDc =(this.isUserInitiator || this.props.entityId === 'new')? collection<DicOfficerType>(DicOfficerType.NAME,{
    view:"dicOfficerType-edit",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }):collection<DicOfficerType>(DicOfficerType.NAME,{
    view:"dicOfficerType-edit"
  })

  @observable
  editMilitaryForm: SerializedEntity<MilitaryForm>;

  instanceEditMilitaryForm = instance<MilitaryForm>(MilitaryForm.NAME, {
    view: "militaryForm-view",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';



  @observable
  approverHrRoleCode: string;

  isUpdateBeforeOutcome = true;

  fields = [

    "requestNumber",

    "requestDate",

    "status",

    "attitude_to_military",

    "specialization",

    "military_document_type",

    "document_number",

    "military_type",

    "suitability_to_military",

    "troops_structure",

    "military_rank",

    "officer_type",

    "date_from",

    "date_to",

    "attachments",
  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return {...super.getUpdateEntityData()};
    return {
      personGroup: this.personGroupId,
      militaryForm: this.editMilitaryForm ? this.editMilitaryForm.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {

    if (!this.mainStore) return <LoadingPage/>

    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    return (
      <Page pageName={<FormattedMessage id={"militaryFormRequestTitle"}/>}>
        <Section className='large'>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}
              />

              <Form.Item
                label={createElement(Msg, {entityName: entityName, propertyName: "personGroup"})}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>

              {
                this.editMilitaryForm ?
                  <Row type={"flex"} className={"data-form"}>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderMilitaryForm()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderMilitaryFormRequest()}
                    </Col>
                  </Row>
                  : this.renderMilitaryFormRequest()
              }


              <ReadonlyField
                entityName={entityName}
                propertyName="attachments"
                disabled={isNotDraft}
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}

              />

            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
    );
  }

  generateOptionItems = (dataCollection:DataCollectionStore<any>):DataCollectionStore<WithId>=>{
    let newArr:any = {items:[]};
    dataCollection.items.forEach(item=>{
      if(item.company!.code !=="empty"){
       return  newArr.items && newArr.items.push(item)
      }else return newArr.items && newArr.items.push(item)
    })
    return newArr
  }

  renderMilitaryForm = () => {

    const entityName = this.dataInstance.entityName;

    const isHr = this.approverHrRoleCode === 'HR';

    return (
      <div>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.attitude_to_military"}/>}>
          <Input
            value={this.editMilitaryForm && this.editMilitaryForm.attitude_to_military ? this.editMilitaryForm.attitude_to_military['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={<FormattedMessage id={"militaryRequest.military_document_type"}/>}>
          <Input
            value={this.editMilitaryForm && this.editMilitaryForm.military_document_type ? this.editMilitaryForm.military_document_type['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.document_number"}/>}>
          <Input
            value={this.editMilitaryForm ? this.editMilitaryForm.document_number || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.military_type"}/>}>
          <Input
            value={this.editMilitaryForm && this.editMilitaryForm.military_type ? this.editMilitaryForm.military_type['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.suitability_to_military"}/>}>
          <Input
            value={this.editMilitaryForm && this.editMilitaryForm.suitability_to_military ? this.editMilitaryForm.suitability_to_military['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.troops_structure"}/>}>
          <Input
            value={this.editMilitaryForm && this.editMilitaryForm.troops_structure ? this.editMilitaryForm.troops_structure['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.military_rank"}/>}>
          <Input
            value={this.editMilitaryForm && this.editMilitaryForm.military_rank ? this.editMilitaryForm.military_rank['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.officer_type"}/>}>
          <Input
            value={this.editMilitaryForm && this.editMilitaryForm.officer_type ? this.editMilitaryForm.officer_type['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.specialization"}/>}>
          <Input
            value={this.editMilitaryForm ? this.editMilitaryForm.specialization || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.date_from"}/>}>
          <Input
            value={formatDate(this.editMilitaryForm.date_from)}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"militaryRequest.date_to"}/>}>
          <Input
            value={formatDate(this.editMilitaryForm.date_to)}
            disabled/>
        </Form.Item>
      </div>
    );
  }

  renderMilitaryFormRequest = () => {

    const messages = this.mainStore.messages!;

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    const isHr = this.approverHrRoleCode === 'HR';

    return (
     <div>
       <ReadonlyField
         entityName={entityName}
         propertyName="attitude_to_military"
         disabled={isNotDraft}
         form={this.props.form}
         optionsContainer={this.generateOptionItems(this.attitudeDc)}
         getFieldDecoratorOpts={{
           rules: [{
             required: true,
             message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.attitude_to_military']})
           }],
           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('attitude_to_military', args !== (this.editMilitaryForm.attitude_to_military ? this.editMilitaryForm.attitude_to_military.id : undefined));
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('attitude_to_military'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.attitude_to_military"})
         }}
       />

       <ReadonlyField
         entityName={entityName}
         propertyName="military_document_type"
         disabled={isNotDraft}
         form={this.props.form}
         optionsContainer={this.generateOptionItems(this.militaryDocumentTDc)}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('military_document_type', args !== (this.editMilitaryForm.military_document_type ? this.editMilitaryForm.military_document_type.id : undefined));
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('military_document_type'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.military_document_type"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="document_number"
         disabled={isNotDraft}
         form={this.props.form}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             const value = args.currentTarget.value;
             if (this.editMilitaryForm)
               this.changedMap.set('document_number', value !== this.editMilitaryForm.document_number);
             return value;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('document_number'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.document_number"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="military_type"
         disabled={isNotDraft}
         form={this.props.form}
         optionsContainer={this.generateOptionItems(this.militaryServiceTypeDc)}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('military_type', args !== (this.editMilitaryForm.military_type ? this.editMilitaryForm.military_type.id : undefined));
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('military_type'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.military_type"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="suitability_to_military"
         disabled={isNotDraft}
         form={this.props.form}
         optionsContainer={this.generateOptionItems(this.militarySustainabilityDc)}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('suitability_to_military', args !== (this.editMilitaryForm.suitability_to_military ? this.editMilitaryForm.suitability_to_military.id : undefined));
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('suitability_to_military'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.suitability_to_military"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="troops_structure"
         disabled={isNotDraft}
         form={this.props.form}
         optionsContainer={this.generateOptionItems(this.militaryProfileDc)}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('troops_structure', args !== (this.editMilitaryForm.troops_structure ? this.editMilitaryForm.troops_structure.id : undefined));
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('troops_structure'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.troops_structure"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="military_rank"
         disabled={isNotDraft}
         form={this.props.form}
         optionsContainer={this.generateOptionItems(this.militaryRankDc)}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('military_rank', args !== (this.editMilitaryForm.military_rank ? this.editMilitaryForm.military_rank.id : undefined));
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('military_rank'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.military_rank"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="officer_type"
         disabled={isNotDraft}
         form={this.props.form}
         optionsContainer={this.generateOptionItems(this.officerTypeDc)}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('officer_type', args !== (this.editMilitaryForm.officer_type ? this.editMilitaryForm.officer_type.id : undefined));
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('officer_type'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.officer_type"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="specialization"
         disabled={isNotDraft}
         form={this.props.form}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             const value = args.currentTarget.value;
             if (this.editMilitaryForm)
               this.changedMap.set('specialization', value !== this.editMilitaryForm.specialization);
             return value;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('specialization'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.specialization"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="date_from"
         disabled={isNotDraft}
         form={this.props.form}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('date_from', this.editMilitaryForm.date_from ? !moment(this.editMilitaryForm.date_from).isSame(args, 'day') : !!args);
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('date_from'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.date_from"})
         }}
       />
       <ReadonlyField
         entityName={entityName}
         propertyName="date_to"
         disabled={isNotDraft}
         form={this.props.form}
         getFieldDecoratorOpts={{

           getValueFromEvent: args => {
             if (this.editMilitaryForm)
               this.changedMap.set('date_to', this.editMilitaryForm.date_to ? !moment(this.editMilitaryForm.date_to).isSame(args, 'day') : !!args);
             return args;
           }
         }}
         formItemOpts={{
           hasFeedback: this.changedMap.get('date_to'),
           validateStatus: "success",
           label:this.props.intl.formatMessage({id:"militaryRequest.date_to"})
         }}
       />
     </div>
    );
  }



  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: MilitaryFormRequest | undefined) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();

    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const militaryFormId = this.props.militaryFormId || (item && item.militaryForm ? item.militaryForm.id : undefined)

    if (militaryFormId)
      getCubaREST()!.loadEntity<MilitaryForm>(MilitaryForm.NAME, militaryFormId, {view: 'militaryForm-view'})
        .then(value => this.editMilitaryForm = value)
        .then(value => {
          this.instanceEditMilitaryForm.setItem(value);
          const properties = ["attitude_to_military", "specialization", "military_document_type", "document_number", "military_type", "suitability_to_military", "troops_structure", "military_rank", "officer_type", "date_from", "date_to"];
          if (this.props.entityId === PersonDocumentRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditMilitaryForm.getFieldValues(properties));
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(MilitaryFormRequestEditComponent))
);
