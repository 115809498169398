import React from "react";
import {Radio} from "antd";
import {observer} from "mobx-react";
import {action, observable, runInAction} from "mobx";
import {getCubaREST} from "@cuba-platform/react";
import {Condition, ConditionsGroup} from "@cuba-platform/rest/dist-node/filter";
import moment from "moment/moment";
import {JSON_DATE_TIME_FORMAT} from "../../../util/Date/Date";
import {DicRecognitionType} from "../../../../cuba/entities/base/tsadv$DicRecognitionType";
import {getFileUrl} from "../../../util/util";
import {RadioChangeEvent} from "antd/lib/radio/interface";

type RecognitionTypeSelectorProps = {
  onChange?: (selected: DicRecognitionType) => void
}

@observer
class RecognitionTypeSelector extends React.Component<RecognitionTypeSelectorProps> {
  readonly ACTUAL_FILTER: Array<Condition | ConditionsGroup> = [
    {
      property: "startDate",
      operator: '<',
      value: moment().format(JSON_DATE_TIME_FORMAT)
    }, {
      property: "endDate",
      operator: '>',
      value: moment().format(JSON_DATE_TIME_FORMAT)
    }
  ]

  @observable selectedRecognitionType: DicRecognitionType | null = null;
  @observable recognitionTypes: DicRecognitionType[] = [];

  componentDidMount() {
    getCubaREST()!.searchEntities(DicRecognitionType.NAME, {conditions: this.ACTUAL_FILTER}, {view: "dicRecognitionType.edit", sort: "order"})
      .then(result => runInAction(() => this.recognitionTypes = result as DicRecognitionType[]));
  }

  renderRadio = (recognitionType: DicRecognitionType) => {
    const stickerUrl = getFileUrl(recognitionType.sticker && recognitionType.sticker.id);

    return (
      <Radio value={recognitionType}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <img width={65} height={"auto"} src={stickerUrl} alt={"sticker"}/>
          <div style={{textAlign: "center"}}>{recognitionType["_instanceName"]}</div>
        </div>
      </Radio>
    )
  }

  @action onChange = (e: RadioChangeEvent) => {
    this.selectedRecognitionType = e.target.value as DicRecognitionType;
    this.props.onChange && this.props.onChange(this.selectedRecognitionType);
  }

  render() {
    return <>
      <Radio.Group
        className="radio-custom"
        onChange={this.onChange}>
        {this.recognitionTypes.map(this.renderRadio)}
      </Radio.Group>
    </>
  }
}

export default RecognitionTypeSelector;