import * as React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {
  getCubaREST,
  injectMainStore,
  instance, MainStoreInjected,
  withLocalizedForm
} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import {rootStore, RootStoreProp} from "../../../store";
import {IdpMeeting} from "../../../../cuba/entities/base/tsadv_IdpMeeting";
import {ColumnProps} from "antd/es/table";
import {Card, Col, DatePicker, Form, Row, Select, Table} from "antd";
import AbstractBprocEdit from "../../Bproc/abstract/AbstractBprocEdit";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {action, observable, reaction, runInAction, when} from "mobx";
import {DicMeetingType} from "../../../../cuba/entities/base/tsadv_DicMeetingType";
import TextArea from "antd/es/input/TextArea";
import Button, {ButtonType} from "../../../components/Button/Button";
import {DicReadinessLevel} from "../../../../cuba/entities/base/tsadv$DicReadinessLevel";
import {SerializedEntity} from "@cuba-platform/rest";
import moment, {Moment} from "moment";
import Notification from "../../../util/Notification/Notification";
import {IdpStatus, IndividualDevelopmentPlanStatus} from "../../../../cuba/enums/enums";
import {DicRequestStatus} from "../../../../cuba/entities/base/tsadv$DicRequestStatus";
import {goBackOrHomePage} from "../../../util/util";
import MsgEntity from "../../../components/MsgEntity";
import {
  AccessSettingForQuarterlyMeetings
} from "../../../../cuba/entities/base/tsadv_AccessSettingForQuarterlyMeetings";
import {FormComponentProps} from "antd/es/form";

type Props = {
  idpMeetings: IdpMeeting[]
}
type CardProps = {
  entityId: string;
  idpMeeting: IdpMeeting;
  dicMeetingType: SerializedEntity<DicMeetingType>[];
  dicReadinessLevel: SerializedEntity<DicReadinessLevel>[];
  closeCard: (key: string) => void;
  componentKey: string;
  dicRequestStatus: SerializedEntity<DicRequestStatus>[]
  editablePeriods: SerializedEntity<AccessSettingForQuarterlyMeetings>[]
}

@inject("rootStore")
@injectMainStore
@observer
class IdpMeetingsList extends React.Component<RootStoreProp & WrappedComponentProps & Props> {
  @observable idpMeetings: IdpMeeting[] = [];

  @observable dicMeetingType: SerializedEntity<DicMeetingType>[] = [];

  @observable dicReadinessLevel: SerializedEntity<DicReadinessLevel>[] = [];

  @observable dicRequestStatus: SerializedEntity<DicRequestStatus>[]

  @observable expandedRowKeys: string[] = [];

  @observable editablePeriods: SerializedEntity<AccessSettingForQuarterlyMeetings>[] = [];

  render(): React.ReactNode {
    const columns: ColumnProps<IdpMeeting>[] = [
      {key: 'qname', render: (text, record, index) =>
          <div style={{display:"flex",justifyContent:"space-between"}}><strong>{`${this.props.intl.formatMessage({id: "quarterRow"}, {index: index+1})}`}</strong> <span>{this.props.intl.formatMessage({id: "accessPeriod"})}: {this.editablePeriods&&this.editablePeriods[index]&&this.editablePeriods[index].startDate?moment(this.editablePeriods[index].startDate).locale('ru').format('L'):""} - {this.editablePeriods&&this.editablePeriods[index]&&this.editablePeriods[index].endDate?moment(this.editablePeriods[index].endDate).locale('ru').format('L'):""}</span></div>} ]
    return <>
      <Table
        columns={columns}
        dataSource={this.idpMeetings}
        rowKey={(record) => record.id}
        expandedRowKeys={this.expandedRowKeys}
        onExpandedRowsChange={(key: string[]) => {
          this.expandedRowKeys = [...key];
        }}
        expandedRowRender={(record: IdpMeeting) => (
          <IdpMeetingCard closeCard={(key: string) => {
            runInAction(() => this.expandedRowKeys = this.expandedRowKeys.filter(e => e !== key))
          }}
                          key={record.id} componentKey={record.id}
                          dicMeetingType={this.dicMeetingType} dicReadinessLevel={this.dicReadinessLevel}
                          entityId={record.id.toString().startsWith('new') ? record.id.toString().slice(0, -1) : record.id}
                          idpMeeting={record}
                          dicRequestStatus={this.dicRequestStatus}
                          editablePeriods={this.editablePeriods}
          />
        )}
      />
    </>
  }

  componentDidMount() {
    Promise.all([
      getCubaREST()!.loadEntities<DicRequestStatus>(DicRequestStatus.NAME, {view: "_local"}).then(status => runInAction(() => this.dicRequestStatus = status)),
      getCubaREST()!.loadEntities<DicMeetingType>(DicMeetingType.NAME, {view: "dicMeetingType-edit"}).then(types => {
        runInAction(() => {
          this.idpMeetings = [...this.idpMeetings, ...types
            .map((type) => ({
              id: "new" + type.code,
              meetingType: type
            }))]
          this.dicMeetingType = types
          if (this.props.idpMeetings && this.props.idpMeetings.length === 4) this.idpMeetings = JSON.parse(JSON.stringify(this.props.idpMeetings))
          this.dicMeetingType.sort((a, b) => a.code!.localeCompare(b.code!, undefined, {numeric: true}))
          const meetings:IdpMeeting[] = JSON.parse(JSON.stringify(this.props.idpMeetings.sort((a, b) => Number(a.meetingType && a.meetingType.code) - Number(b.meetingType && b.meetingType.code))))
          meetings.forEach((i) => this.idpMeetings[Number(i.meetingType&&i.meetingType.code)-1] = i)
        })
      }),
      getCubaREST()!.loadEntities<DicReadinessLevel>(DicReadinessLevel.NAME, {view: "dicReadinessLevel-edit"}).then(levels => {
        runInAction(() => {
          this.dicReadinessLevel = levels
        })
      })]).then(() => when(() => !!(this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.approvingIdpMeetingId),
      () => this.expandedRowKeys = [this.props.rootStore!.idpStore!.approvingIdpMeetingId]))
    getCubaREST()!.searchEntities<AccessSettingForQuarterlyMeetings>(AccessSettingForQuarterlyMeetings.NAME, {
      conditions: [{
        property: "year",
        operator: "=",
        value: moment().year()
      }]
    }, {view: "_local"}).then((res)=> this.editablePeriods = res.sort((a,b)=>a.quarter.toString().localeCompare(b.quarter.toString(),undefined,{numeric:true})))
  }
}

type fieldsData = {
  meetingDate: Moment,
  readinessLevel: string,
  comment: string,
  status: DicRequestStatus
}

@inject("rootStore")
@injectMainStore
@observer
class IdpMeetingCardComponent extends AbstractBprocEdit<IdpMeeting, CardProps> {
  processDefinitionKey = "idpMeetingRequest";

  dataInstance = instance<IdpMeeting>(IdpMeeting.NAME, {
    view: "idpMeeting-edit"
  })

  fields = ["meetingDate", "readinessLevel", "comment", "status"]

  getUpdateEntityData(): IdpMeeting {
    const meeting = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.getIdpMeetingByCode(this.props.idpMeeting.meetingType!.code!)
    return {...super.getUpdateEntityData(), ...meeting, meetingType:this.props.idpMeeting.meetingType,planPercent:this.props.rootStore!.idpStore!.planPercent};
  }

  @observable savingBtn: boolean = false;

  @observable isLoading:boolean = false

  validate = (): Promise<boolean> => {
    let isValidatedSuccess = true;
    if (this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.selectedIdp && this.props.rootStore!.idpStore.selectedIdp.status && this.props.rootStore!.idpStore.selectedIdp.status.code !== IndividualDevelopmentPlanStatus.APPROVED) {
      Notification.error({
        message: "ИПР еще не утвержден"
      })
      isValidatedSuccess = false;
      return new Promise(resolve => resolve(isValidatedSuccess));
    }
    this.props.form.validateFields(this.fields, {force: true}, (err) => {
      isValidatedSuccess = !err;
      if (err) {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.validationError"})
        });
      }
    });
    return new Promise(resolve => resolve(isValidatedSuccess));
  };

  @action saveIdpMeetings = () => {
    this.props.form.validateFields((err) => {
      if (!err) {
        this.isLoading = true;
        const data: fieldsData = (this.props.form.getFieldsValue(this.fields) as fieldsData);
        const idp = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.selectedIdp

        if (this.props.rootStore!.idpStore) {
          const idpMeeting: IdpMeeting = {
            id: this.props.idpMeeting.id.toString().startsWith('new') ? "" : this.props.idpMeeting.id,
            meetingDate: data.meetingDate.toISOString(),
            readinessLevel: {id: data.readinessLevel},
            comment: data.comment,
            individualDevelopmentPlan: {id: idp && idp.id},
            meetingType: this.props.idpMeeting.meetingType
          }
          this.savingBtn = true;
          const findItem = this.props.rootStore!.idpStore.getIdpMeetingByCode(idpMeeting.meetingType!.code!)
          if (findItem) {
            idpMeeting.id = findItem.id
          }
          const index = Number(idpMeeting.meetingType!.code!)
          getCubaREST()!.commitEntity<IdpMeeting>(IdpMeeting.NAME, idpMeeting).then((res) => {
            Notification.success({message: this.props.intl.formatMessage({id:"quarterSaved"},{quarter:this.props.intl.formatMessage({id:"quarterRow"},{index:index})})})
            this.savingBtn = false;
            this.props.rootStore!.idpStore!.pushIdpMeeting({...idpMeeting, id: res.id})
          })
            .catch(() => {
              Notification.error({message: `${this.props.intl.formatMessage({id: "errorOnSaving"})} ${this.props.intl.formatMessage({id: "quarterRow"}, {index: index})}`})
              this.savingBtn = false;
            }).finally(()=>this.isLoading = false)
        }
      }
    })
  }

  @observable accessSettingForQuarterlyMeetings:AccessSettingForQuarterlyMeetings;

  @observable unabledToEditPage:boolean

  constructor(props:CardProps&WrappedComponentProps & RootStoreProp & MainStoreInjected & RouteComponentProps<any>&FormComponentProps) {
    super(props);
    reaction(()=>this.props.rootStore!.idpStore!.actualAssessment!.total_result,(value)=>{
      console.log(value)
      if (this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore&&(this.props.idpMeeting.status&&this.props.idpMeeting.status.code!=="APPROVED")) {
        const readiness = this.props.dicReadinessLevel.find(rl => ((value?value:0) >= rl['min'] &&  (value?value:0) <= rl['max']))
        if(readiness) {
          if(this.props.form.getFieldValue('readinessLevel')!==undefined){
            this.props.form.setFieldsValue({readinessLevel:readiness.id})
          }
          else{
            this.props.form.getFieldDecorator("readinessLevel",{initialValue:readiness.id})
          }
          const result = this.props.rootStore!.idpStore!.getIdpMeetingByCode(this.props.idpMeeting.meetingType!.code!)
         if(result){
           result.readinessLevel = {id:this.props.form.getFieldValue("readinessLevel")}
         }
        }
        else{
          const max = this.props.dicReadinessLevel.reduce((prev, cur) => prev['max'] > cur['max']?prev:cur)
          const min = this.props.dicReadinessLevel.reduce((prev, cur) => prev['min'] < cur['min']?prev:cur)
          if( this.props.rootStore!.idpStore.actualAssessment!.total_result>max['max']) this.props.form.setFieldsValue({"readinessLevel":max.id})
          if( this.props.rootStore!.idpStore.actualAssessment!.total_result<min['min']) this.props.form.setFieldsValue({"readinessLevel":min.id})
        }
      }
    })
  }


  beforeCompletePredicate = (outcome:string):Promise<boolean> => {
    if(this.props.idpMeeting.id.toString().startsWith('new')) {
      return new Promise(resolve => resolve(true));
    }
    rootStore!.idpStore!.validateIdp();
    return new Promise(resolve => resolve(this.props.rootStore!.idpStore!.managerValidation!));
  }

  render(): React.ReactNode {
    const {Option} = Select
    const messages = this.props.mainStore!.messages!;
    const idp = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp
    const personGroupId = idp&&idp.personGroup&&idp.personGroup.id
    const disabledPage = personGroupId !== this.props.rootStore!.userInfo.personGroupId
    const translatedProperty = (propertyName:string):React.ReactNode => <MsgEntity entityName={IdpMeeting.NAME} mainStore={this.props.mainStore} propertyName={propertyName}/>
    const disabled = !!(this.props.idpMeeting.status && this.props.idpMeeting.status.code === "APPROVED") || !!(rootStore!.idpStore&&rootStore!.idpStore.selectedIdp.status.code !== "APPROVED")
    return <>
      <Card style={{padding: 10}} bordered={true}
            actions={disabledPage?
              [
                <Button buttonType={ButtonType.FOLLOW} htmlType="button"
                        onClick={() => goBackOrHomePage(this.props.history!)}>
                  <FormattedMessage id="close"/>
                </Button>,
                this.getOutcomeBtns()
              ]:
              this.unabledToEditPage?
                [<Button buttonType={ButtonType.FOLLOW}
                         onClick={() => {
                           this.props.closeCard(this.props.componentKey);}}>
                  <FormattedMessage id="close"/>
                </Button>]:
                [
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => {
                            this.props.closeCard(this.props.componentKey);}}>
                    <FormattedMessage id="close"/>
                  </Button>,
                  <Button loading={this.savingBtn} onClick={() => this.saveIdpMeetings()} key={"saveBtn"} style={{backgroundColor: "green"}}><span
                    style={{color: "white"}}>Сохранить</span></Button>,
                  this.getOutcomeBtns()
                ]
            }>
        <Form>
          <Row>
              <Col span={10} xl={10} lg={{span: 22, offset: 0}}>
              <Form.Item label={translatedProperty("meetingDate")}
                         labelCol={{xl: 8, style: {marginTop: 9}, lg: 9}}
                         wrapperCol={{xl: {span: 10, offset: 1}, lg: 15}}
                         style={{marginBottom: 0}}
              >
                {this.props.form.getFieldDecorator('meetingDate', {
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IdpMeeting.NAME + '.' + '.meetingDate']}),
                  }]
                })
                (<DatePicker disabled={disabledPage||disabled || this.unabledToEditPage} locale={'ru'} format={'L'} style={{width: "100%"}}/>)}
              </Form.Item>
              <Form.Item label={translatedProperty('readinessLevel')}
                         labelCol={{xl: 8, lg: 9, style: {marginTop: 9}}}
                         wrapperCol={{xl: {span: 10, offset: 1}, lg: 15, xs: {span: 24}, sm: {span: 16}}}
                         style={{marginBottom: 0}}
              >
                {this.props.form.getFieldDecorator('readinessLevel', {
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IdpMeeting.NAME + '.' + '.readinessLevel']}),
                  }]
                })
                (<Select disabled>
                  {this.props.dicReadinessLevel.map(l => <Option key={l.id} value={l.id}>{l._instanceName}</Option>)}
                </Select>)}
              </Form.Item>
              <Form.Item label={translatedProperty('status')}
                         labelCol={{xl: 8, lg: 9, style: {marginTop: 9}}}
                         wrapperCol={{xl: {span: 10, offset: 1}, lg: 15, xs: {span: 24}, sm: {span: 16}}}
                         style={{marginBottom: 0}}
              >
                {this.props.form.getFieldDecorator('status', {
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IdpMeeting.NAME + '.' + '.status']}),
                  }]
                })
                (<Select disabled>
                  {this.props.dicRequestStatus.map(s => <Option key={s.id} value={s.id}>{s._instanceName}</Option>)}
                </Select>)}
              </Form.Item>
            </Col>
            <Col span={10} xl={13} lg={{span: 22, offset: 0}}>
              <Form.Item label={<FormattedMessage id={'cba.detail.comments'}/>}
                         labelCol={{xl: 6, lg: 9, style: {marginTop: 9}}}
                         wrapperCol={{xl: {span: 17, offset: 1}, lg: 15, xs: {span: 24}, sm: {span: 16}}}>
                {this.props.form.getFieldDecorator('comment', {
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IdpMeeting.NAME + '.' + '.comment']}),
                  }]
                })
                (<TextArea disabled={disabledPage ||disabled || this.unabledToEditPage} rows={6}/>)}
              </Form.Item>
            </Col>
          </Row>
          {this.takCard()}
        </Form>
      </Card>
    </>
  }

  setReactionDisposer = () => {
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      (item) => {
        this.onReactionDisposerEffect(item);
      }
    );
  }

  protected initItem(request: IdpMeeting) {
    this.dataInstance.setItem(request);
  }

  afterSendOnApprove = () =>  {
    this.props.history.push("/trainingPlan");
    this.props.rootStore!.idpStore!.saveRequest();
  }

  componentDidMount() {
    super.componentDidMount()
    const store = this.props.rootStore!.idpStore
    when(() => !!(this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.selectedIdp && this.dataInstance.item), () => {
      if (this.dataInstance.item){
        this.dataInstance.item.individualDevelopmentPlan = {id: this.props.rootStore!.idpStore!.selectedIdp && this.props.rootStore!.idpStore!.selectedIdp.id}
      }
    })
    if (!this.props.idpMeeting.id.toString().startsWith('new')) {
      this.props.form.getFieldDecorator("meetingDate", {initialValue: this.props.idpMeeting.meetingDate ? moment(this.props.idpMeeting.meetingDate) : undefined})
      this.props.form.getFieldDecorator("readinessLevel", {initialValue: this.props.idpMeeting.readinessLevel ? this.props.idpMeeting.readinessLevel.id : undefined})
      this.props.form.getFieldDecorator("comment", {initialValue: this.props.idpMeeting.comment ? this.props.idpMeeting.comment : undefined})
      this.props.form.getFieldDecorator("status", {initialValue: this.props.idpMeeting.status ? this.props.idpMeeting.status.id : undefined})
    }
    else {
      this.props.form.getFieldDecorator("status", {initialValue: this.props.dicRequestStatus.find((status) => status.code === "DRAFT")!.id!})
      if(this.props.idpMeeting.meetingType)
      this.props.form.getFieldDecorator("comment", {initialValue: this.props.idpMeeting.comment ? this.props.idpMeeting.comment : `Q${this.props.idpMeeting.meetingType.code}`})
      if (rootStore!.idpStore&&rootStore!.idpStore.actualAssessment&&rootStore!.idpStore.actualAssessment.total_result) {
        const readiness = this.props.dicReadinessLevel.find(rl => (rootStore!.idpStore!.actualAssessment!.total_result! >= rl['min'] && rootStore!.idpStore!.actualAssessment!.total_result! <= rl['max']))
        if(readiness) this.props.form.getFieldDecorator("readinessLevel", {initialValue:readiness.id})
        else{
          const max = this.props.dicReadinessLevel.reduce((prev, cur) => prev['max'] > cur['max']?prev:cur)
          const min = this.props.dicReadinessLevel.reduce((prev, cur) => prev['min'] < cur['min']?prev:cur)
          if(rootStore!.idpStore&&rootStore!.idpStore.actualAssessment&&rootStore!.idpStore.actualAssessment.total_result>max['max']) this.props.form.getFieldDecorator("readinessLevel", {initialValue:max.id})
          if(rootStore!.idpStore&&rootStore!.idpStore.actualAssessment&&rootStore!.idpStore.actualAssessment.total_result<min['min']) this.props.form.getFieldDecorator("readinessLevel", {initialValue:min.id})
        }
      }
      else {
        this.props.form.getFieldDecorator("readinessLevel", {initialValue:store&&store.readinessLevelFromCard&&store.readinessLevelFromCard.id})
      }
    }
    if(this.props.idpMeeting&&this.props.idpMeeting.meetingType&&this.props.idpMeeting.meetingType.code){
      const period = this.props.editablePeriods.find((period)=>period.quarter === "Q"+this.props.idpMeeting!.meetingType!.code)
      if(period){
        const idp = this.props.rootStore!.idpStore && this.props.rootStore!.idpStore.selectedIdp;
        if ( idp && idp.status && idp.status.code === IdpStatus.APPROVED) {
          this.unabledToEditPage = !moment().isBetween(moment(period.startDate), moment(period.endDate).add(1,'day'));
        }
      }
    }
  }
}

const IdpMeetingCard = React.memo(injectIntl(withLocalizedForm<CardProps>()(withRouter(IdpMeetingCardComponent))),
  (prevProps, nextProps) => {
    return (prevProps.idpMeeting.meetingType && prevProps.idpMeeting.meetingType.code) === (nextProps.idpMeeting.meetingType && nextProps.idpMeeting.meetingType.code)
  })
export default injectIntl(IdpMeetingsList);