import * as React from "react";
import { Alert, Card, Form, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { SurChargeRequestManagement } from "./SurChargeRequestManagement";
import { FormComponentProps } from "antd/lib/form";
import { Redirect, withRouter } from "react-router-dom";
import { action, IReactionDisposer, observable, reaction, toJS } from "mobx";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  collection,
  instance,
  withLocalizedForm,
  MultilineText,
  injectMainStore,
  MainStoreInjected,
  DataCollectionStore,
  getCubaREST,
  Msg
} from "@cuba-platform/react";

import "../../../app/App.css";

import { SurChargeRequest } from "../../../cuba/entities/base/tsadv_SurChargeRequest";
import { PersonGroupExt } from "../../../cuba/entities/base/base$PersonGroupExt";
import { SurChargeName } from "../../../cuba/entities/base/tsadv$SurChargeName";
import { FileDescriptor } from "../../../cuba/entities/base/sys$FileDescriptor";
import { DicRequestStatus } from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import ErrorBoundary from "./ErrorBoundary";
import { ReadonlyField } from "../../components/ReadonlyField";
import { PersonProfile } from "../MyTeam/MyTeamCard";
import { RootStoreProp } from "../../store";
import Page from "../../hoc/PageContentHoc";
import MsgEntity from "../../components/MsgEntity";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import { restServices } from "../../../cuba/services";
import { runReport } from "../../util/reportUtil";
import LoadingPage from "../LoadingPage";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import { MySurChargeRequestManagement } from "./MySurChargeRequestManagement";
import { goBackOrHomePage } from "../../util/util";

type EditorProps = {
  entityId: string;
  personGroupId?: string;
  closeCreateTab?():void;
};

type Props = FormComponentProps & EditorProps & WrappedComponentProps & RootStoreProp & MainStoreInjected;

enum TaskDefinitionKeys{
  ier_task='ier_task',
  cb_company_task='cb_company_task',
  hr_manager_task='hr_manager_task',
  ceo_task='ceo_task',
  cb_group_task='cb_group_task',
}

enum AprooverRoles{
  'MANAGER',
  'C&B_COMPANY',
  'HR',
  'GENERAL_DIRECTOR_COMPANY',
}

@inject("rootStore")
@injectMainStore
@observer
class SurChargeRequestEditComponent extends AbstractBprocEdit<SurChargeRequest, Props> {
  dataInstance = instance<SurChargeRequest>(SurChargeRequest.NAME, {
    view: "surchargerequest.view",
    loadImmediately: false
  });
  processDefinitionKey = "surChargeRequest";
  @observable
  loaded: boolean = false;

  path = MySurChargeRequestManagement.PATH;

  // employeeNamesDc = collection<PersonGroupExt>(PersonGroupExt.NAME, {
  //   view: "_minimal"
  // });

  aidTypesDc = collection<SurChargeName>(SurChargeName.NAME, {
    view: "_minimal"
  });

  filesDc = collection<FileDescriptor>(FileDescriptor.NAME, {
    view: "_minimal"
  });

  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  @observable
  updated = false;

  reactionDisposer: IReactionDisposer;

  @observable
  person: PersonProfile;

  personGroupDc: DataCollectionStore<PersonGroupExt>

  personGroupId: string;

  @observable
  isDisabledFields = true;

  @action
  setIsDisabledFields = (value: boolean): void => {
    this.isDisabledFields = value;
  }

  fields = [
    "justification",

    "aidSum",

    // "legacyId",

    // "organizationBin",

    // "integrationUserLogin",

    "requestNumber",

    "requestDate",

    // "comment",

    "employeeName",

    "aidType",

    "file",

    "status"
  ];

  @observable
  globalErrors: string[] = [];
  isUpdateBeforeOutcome=true;

  render() {

    // console.log(this.dataInstance.item)
    if (this.updated) {
      return <Redirect to={MySurChargeRequestManagement.PATH} />;
    }

    if (!this.mainStore) {
      return <LoadingPage/>
    }

    const messages = this.mainStore.messages!;
    if (!messages) {
      return <LoadingPage/>
    }
    return (

      <Page pageName={<FormattedMessage id={"surChargeRequest"} />}>
        <Spin spinning={!this.loaded}>
          <Section size="large">
            <div>
              <Card
                className="narrow-layout card-actions-container"
                actions={this.renderActions()}
                bordered={false}
              >
                {this.renderForm()}
              </Card>
            </div>
          </Section>
        </Spin>
      </Page>
    );
  }

  afterSendOnApprove = () => this.closeOrGoBack();

  closeOrGoBack = () => {
    if(this.props.personGroupId && this.props.closeCreateTab){
      this.props.closeCreateTab();
    }else if(this.personGroupId !== this.props.rootStore!.userInfo.personGroupId){
      // this.props.history.push(`${SurChargeRequestManagement.PATH}/user/${this.personGroupId}`)
      goBackOrHomePage(this.props.history!)
    }else{
      this.props.history.push(MySurChargeRequestManagement.PATH)
    }
  }

  renderActions = () => {
    const actions = [];
    actions.push(
      <Button
        buttonType={ButtonType.FOLLOW}
        onClick={this.closeOrGoBack}
      >
        {this.props.intl.formatMessage({id: "close"})}
      </Button>
    );

    actions.push(this.getOutcomeBtns());
    return actions;
  }

  renderForm() {
    const messages = this.mainStore.messages!;
    const isCbCompany = (
      this.activeUserTask
      && this.activeUserTask.taskDefinitionKey === TaskDefinitionKeys.cb_company_task
    )
    return (
      <Card bordered={false} className="narrow-layout">
        <Form layout="vertical">

          <ReadonlyField
            disabled
            entityName={SurChargeRequest.NAME}
            propertyName="requestNumber"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />

          <ReadonlyField
            disabled
            entityName={SurChargeRequest.NAME}
            propertyName="requestDate"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />

          <ReadonlyField
            disabled
            entityName={SurChargeRequest.NAME}
            propertyName="status"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            optionsContainer={this.statussDc}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />

          <ReadonlyField
            disabled
            entityName={SurChargeRequest.NAME}
            propertyName="employeeName"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            optionsContainer={this.personGroupDc}
            getFieldDecoratorOpts={{}}
          />
          {this.renderCoordinatorFields()}

          <ReadonlyField
            disabled={this.isDisabledFields}
            entityName={SurChargeRequest.NAME}
            propertyName="aidType"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            optionsContainer={this.aidTypesDc}
            getFieldDecoratorOpts={{
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: '"' + messages[SurChargeRequest.NAME + '.aidType'] + '"'})
              }]
            }}
          />

          <ReadonlyField
            disabled={this.isDisabledFields}
            entityName={SurChargeRequest.NAME}
            propertyName="justification"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            getFieldDecoratorOpts={{
              rules: [{
                required: true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: '"' + messages[SurChargeRequest.NAME + '.justification'] + '"'})
              }]
            }}
          />

          {this.renderCoordinatorFields2()}

          <ErrorBoundary>

            <ReadonlyField
              disabled={this.isDisabledFields && !isCbCompany}
              entityName={SurChargeRequest.NAME}
              propertyName="file"
              form={this.props.form}
              formItemOpts={{
                style: {
                  marginBottom: "12px"
                },
              }}
              extraProps={{
                isCanDeleteFile: (file: any) => {
                  return !file.createdBy || file.createdBy === this.props.rootStore!.userInfo.login
                }
              }}
              optionsContainer={this.filesDc}
              getFieldDecoratorOpts={{
                rules: [{
                  required: true,
                  message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: '"' + messages[SurChargeRequest.NAME + '.file'] + '"'})
                }]
              }}
            />
          </ErrorBoundary>

          {this.globalErrors.length > 0 && (
            <Alert
              message={<MultilineText lines={toJS(this.globalErrors)} />}
              type="error"
              style={{ marginBottom: "24px" }}
            />
          )}

          {this.takCard()}

          {/* <Form.Item style={{ textAlign: "center" }}>
            <Link to={SurChargeRequestManagement.PATH}>
              <Button htmlType="button">
                <FormattedMessage id="management.editor.cancel" />
              </Button>
            </Link>
            <Button
              type="primary"
              htmlType="submit"
              disabled={status !== "DONE" && status !== "ERROR"}
              loading={status === "LOADING"}
              style={{ marginLeft: "8px" }}
            >
              <FormattedMessage id="management.editor.submit" />
            </Button>
          </Form.Item> */}
        </Form>
      </Card>
    )
  }

  renderCoordinatorFields(){
    if(this.isNewEntity() || this.dataInstance.item && this.dataInstance.item.status!.code === "DRAFT") return null;
    const isCbCompany = (
      this.activeUserTask 
      && this.activeUserTask.taskDefinitionKey === TaskDefinitionKeys.cb_company_task
    )
    return (
      <>
        <ReadonlyField
          disabled
          entityName={SurChargeRequest.NAME}
          propertyName="position"
          form={this.props.form}
          formItemOpts={{
            label: <FormattedMessage id="surChargeRequest.position" />,
            style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />
        <ReadonlyField
          disabled
          entityName={SurChargeRequest.NAME}
          propertyName="subdivision"
          form={this.props.form}
          formItemOpts={{
            label: <FormattedMessage id="surChargeRequest.subdivision" />,
            style: { marginBottom: "12px" } }}
          getFieldDecoratorOpts={{}}
        />
      </>
    )
  }

  renderCoordinatorFields2(){
      if(this.isNewEntity() || this.dataInstance.item && this.dataInstance.item.status!.code === "DRAFT") return null;
      const isCbCompany = (
        this.activeUserTask
        && this.activeUserTask.taskDefinitionKey === TaskDefinitionKeys.cb_company_task
      )
      const messages = this.mainStore.messages!;
      return (
        <>
          <ReadonlyField
            disabled={!isCbCompany}
            entityName={SurChargeRequest.NAME}
            propertyName="aidSum"
            form={this.props.form}
            formItemOpts={{
              style: { marginBottom: "12px" }
            }}
            getFieldDecoratorOpts={{
              rules: [{
                required: !!isCbCompany,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: '"' + messages[SurChargeRequest.NAME + '.aidSum'] + '"'})
              }]
            }}
          />
        </>
      )
    }


  // onReactionDisposerEffect = (item: SurChargeRequest | undefined) => {
  //   this.personGroupId = this.props.rootStore!.userInfo!.personGroupId!;
  //   this.props.form.setFieldsValue(
  //     {
  //       ...this.dataInstance.getFieldValues(this.fields),
  //       dateOfReceipt: { value: moment(new Date()) },
  //     }
  //   );
  //   restServices.employeeService.personProfile(this.personGroupId)
  //     .then(value => {
  //       this.person = value;
  //       // console.log(this.personGroupId, this.person, value, this.dataInstance.getFieldValues(this.fields))
  //       this.props.form.setFieldsValue(
  //         {
  //           employeeName: value.groupId,
  //         }
  //       );
  //     });
  // }


  // initItem(request: SurChargeRequest): void {
  //   this.dataInstance.setItem(request);
  //   const fieldValues = this.dataInstance.getFieldValues(this.fields.filter(field => field !== "file"));
  //   this.props.form.setFieldsValue(fieldValues);
  // }
  
  loadData = async () => {
    if (!this.isNewEntity()) {
      await this.dataInstance.load(this.props.entityId);
    } else {
      const entityName = this.dataInstance.entityName;
      this.initItem(await restServices.portalHelperService.newEntity({entityName: entityName}));
    }
  }

  
  report = () => {

    const data = {
      parameters: [{
        name: "entity",
        value: this.props.entityId
      }]
    };

    const reportCode = "SURCHARGEREQUEST";

    runReport(reportCode, data, this.props.intl);
  }

  setReactionDisposer = () => {
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      async (item: SurChargeRequest) => {
        this.onReactionDisposerEffect(item);
        this.personGroupId = item.employeeName && item.employeeName.id || this.props.personGroupId || this.props.rootStore!.userInfo!.personGroupId!;
        this.loadPersonGroupDc(this.personGroupId);
        await this.loadPerson(this.personGroupId);
        
        this.setIsDisabledFields(this.isOnApproving() || this.dataInstance.item!.status!.code!.toUpperCase() === 'APPROVED');
        this.props.form.setFieldsValue(this.onReactionFieldsValue(item));
    
        this.loaded = true;
      }
    );
  };

  componentDidMount() {
    (async () => {
      this.setReactionDisposer();
      await this.loadData();
      await this.loadBpmProcessData();
      // const surChargeRequest = this.dataInstance.item!;
      
    })()
    // if (this.props.entityId !== SurChargeRequestManagement.NEW_SUBPATH) {
    //   this.dataInstance.load(this.props.entityId);
    // } else {
    //   restServices.employeeService.personProfile(this.personGroupId)
    //     .then(value=>{
    //       this.person = value;
    //       const entity = new SurChargeRequest();
    //       entity.employeeName = value.groupId as PersonGroupExt;
    //       this.dataInstance.setItem(entity);
    //     })
    // }
    // this.reactionDisposer = reaction(
    //   () => {
    //     console.log(this.dataInstance.item)
    //     return this.dataInstance.item;
    //   },
    //   () => {
    //     this.props.form.setFieldsValue(
    //       this.dataInstance.getFieldValues(this.fields)
    //     );
    //   }
    // );
  }

  loadPerson = async (personGroupId: string): Promise<void> => {
    const response = await getCubaREST()!.searchEntities<PersonGroupExt>(PersonGroupExt.NAME, {
      conditions: [{
        property: 'id',
        operator: '=',
        value: personGroupId,
      }]
    }, {
      view: '_minimal'//TODO ?? maybe "_local"
    });
    const personProfile = await restServices.employeeService.personProfile(personGroupId)
    this.dataInstance.item!.employeeName = response[0];
    this.props.form.setFieldsValue({
      'employeeName': this.employeePersonGroupId,
      'position': personProfile.positionName,
      'subdivision': personProfile.organizationName
    });
  };

  loadPersonGroupDc = (personGroupId: string) => {
    this.personGroupDc = collection<PersonGroupExt>(PersonGroupExt.NAME, {
      view: "_minimal",
      filter: {
        conditions: [{
          property: "id",
          operator: "=",
          value: personGroupId
        }]
      },
      limit: 10
    });
    this.personGroupDc.load();
  };

  componentWillUnmount() {
    this.reactionDisposer();
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName] 
          }
        });
      });
    }
  })(withRouter(SurChargeRequestEditComponent))
);
