import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LearningBudgetRequestEdit from './LearningBudgetRequestEdit';
import LearningBudgetRequestGroupCreate from './LearningBudgetRequestGroupCreate';

type Props = RouteComponentProps<{ entityId?: string, budgetId?: string, readOnly?: string }>;

class LearningBudgetRequestManagement extends Component<Props> {
  static PATH_WITH_PARAMS = "learning-budget-request/single/:entityId/:budgetId?/:readOnly?";
  static PATH_WITH_PARAMS_GROUP = "learning-budget-request/group/:entityId/:budgetId?";
  static PATH = "learning-budget-request";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId, budgetId, readOnly } = this.props.match.params;
    if (this.props.location!.pathname.includes("group")) {
      return (
        <LearningBudgetRequestGroupCreate entityId={entityId!} budgetId={budgetId!} />
      );
    } else {
      return (
        <LearningBudgetRequestEdit entityId={entityId!} budgetId={budgetId!} readOnly={readOnly} />
      );
    }

  }
}

export default LearningBudgetRequestManagement;