import * as React from "react";
import {createElement} from "react";
import {Card, Col, Form, Input, Row} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";
import {collection, getCubaREST, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";
import "../../../app/App.css";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {PersonDocument} from "../../../cuba/entities/base/tsadv$PersonDocument";
import moment from "moment";
import {PersonDocumentRequestManagement} from "../PersonDocumentRequest/PersonDocumentRequestManagement";
import {goBackOrHomePage, isEquals} from "../../util/util";
import LoadingPage from "../LoadingPage";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import {formatDate} from "../../util/Date/Date";
import {PersonQualification} from "../../../cuba/entities/base/tsadv$PersonQualification";
import {PersonQualificationRequest} from "../../../cuba/entities/base/tsadv_PersonQualificationRequest";
import {DicEducationDocumentType} from "../../../cuba/entities/base/tsadv_DicEducationDocumentType";
import {SerializedEntity} from "@cuba-platform/rest";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

type EditorProps = {
  entityId: string;
  addressId?: string;
};

@inject("rootStore")
@injectMainStore
@observer
class PersonQualificationRequestEditComponent extends AbstractBprocEdit<PersonQualificationRequest, EditorProps> {
  processDefinitionKey = "personQualificationRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<PersonQualificationRequest>(PersonQualificationRequest.NAME, {
    view: "personQualificationRequest.edit",
    loadImmediately: false
  });



  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  isIdOrPassport:boolean = false


  documentTypeDc  = (this.isUserInitiator || this.props.entityId === 'new') ?  collection<DicEducationDocumentType>(DicEducationDocumentType.NAME, {
    view: "dicEducationDocumentType-edit",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]

      }]
    }
  }): collection<DicEducationDocumentType>(DicEducationDocumentType.NAME, {
    view: "_minimal"
  });

 example =  collection<DicEducationDocumentType>(DicEducationDocumentType.NAME, {
  view: "dicEducationDocumentType-edit"
});
  @observable
  editPersonQualification: PersonQualification;

  instanceEditAddress = instance<PersonQualification>(PersonQualification.NAME, {
    view: "personQualification-view",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  isKatoRequired = false;

  @observable
  approverHrRoleCode: string;

  isUpdateBeforeOutcome = true;

  fields = [

    "requestNumber",

    "requestDate",

    "status",

    "educationalInstitutionName",
    "startDate",
    "endDate",
    "diploma",
    "issuedDate",
    "attachments",
    "qualification",
    "profession",
    "educationDocumentType",
    "courseName",
    "expiryDate"

  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.personGroupId,
      personQualification: this.editPersonQualification ? this.editPersonQualification.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {

    if (!this.mainStore) return <LoadingPage/>

    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    return (
      <div>
        <ScrollToTopOnMount />
        <Page pageName={<FormattedMessage id={"personQualification.titile"}/>}>
        <Section className='large'>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}

              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}

              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}

              />

              <Form.Item

                label={createElement(Msg, {entityName: entityName, propertyName: "personGroup"})}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>

              {
                this.editPersonQualification ?
                  <Row type={"flex"} className={"data-form"}>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderPersonQualification()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderPersonQualificationRequest()}
                    </Col>
                  </Row>
                  : this.renderPersonQualificationRequest()
              }



              <ReadonlyField
                entityName={entityName}
                propertyName="attachments"
                disabled={isNotDraft}
                form={this.props.form}

                formItemOpts={{style: {marginBottom: "12px"}}}
              />

            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
      </div>
    );
  }

  renderPersonQualification = () => {

    const entityName = this.dataInstance.entityName;

    const isHr = this.approverHrRoleCode === 'HR';

    return (
      <div>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.educationDocumentType"} /> }>
          <Input
            value={this.editPersonQualification && this.editPersonQualification.educationDocumentType ? this.editPersonQualification.educationDocumentType['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.profession"} /> }>
          <Input
            value={this.editPersonQualification ? this.editPersonQualification.profession|| '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.qualification"} /> }>
          <Input
            value={this.editPersonQualification ? this.editPersonQualification.qualification|| '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.startDate"} /> }>
          <Input
            value={formatDate(this.editPersonQualification.startDate)}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.endDate"} /> }>
          <Input
            value={formatDate(this.editPersonQualification.endDate)}
            disabled/>
        </Form.Item>
        <Form.Item
          label={ <FormattedMessage id={"personQualificationRequest.educationalInstitutionName"} />  }>
          <Input
            value={this.editPersonQualification ? this.editPersonQualification.educationalInstitutionName|| '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.courseName"} /> }>
          <Input
            value={this.editPersonQualification ? this.editPersonQualification.courseName|| '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.issuedDate"} /> }>
          <Input
            value={formatDate(this.editPersonQualification.issuedDate)}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.diploma"} /> }>
          <Input
            value={this.editPersonQualification ? this.editPersonQualification.diploma|| '' : ''}
            disabled/>
        </Form.Item>
        <Form.Item
          label={<FormattedMessage id={"personQualificationRequest.expiryDate"} /> }>
          <Input
            value={formatDate(this.editPersonQualification.expiryDate)}
            disabled/>
        </Form.Item>
      </div>
    );
  }

  renderPersonQualificationRequest = () => {

    const messages = this.mainStore.messages!;

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    const isHr = this.approverHrRoleCode === 'HR';
    let arrayWithCode:any = {items:[]};
    let arrayWithDefaultCode:any = {items:[]};
    this.documentTypeDc.items.forEach(item=>{
      if(item && item.company! && item.company!.code !=="empty"){
        arrayWithCode.items && arrayWithCode.items.push(item)
      }else arrayWithDefaultCode.items && arrayWithDefaultCode.items.push(item)
    })
    return (
      <div>
        <ReadonlyField
          entityName={entityName}
          propertyName="educationDocumentType"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={arrayWithCode.items && arrayWithCode!.items!.length === 0 ? arrayWithDefaultCode : arrayWithCode}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.educationDocumentType']})
            }],
            getValueFromEvent: args => {
              if (this.editPersonQualification)
                this.changedMap.set('educationDocumentType', args !== (this.editPersonQualification.educationDocumentType ? this.editPersonQualification.educationDocumentType.id : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('educationDocumentType'),
            label:this.props.intl.formatMessage({id:"personQualificationRequest.educationDocumentType"})

          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="profession"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editPersonQualification)
                this.changedMap.set('profession', value !== this.editPersonQualification.profession);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('profession'),
            validateStatus: "success",
            label:this.props.intl.formatMessage({id:"personQualificationRequest.profession"})

          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="qualification"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{

            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editPersonQualification)
                this.changedMap.set('qualification', value !== this.editPersonQualification.qualification);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('qualification'),
            validateStatus: "success",
            label:this.props.intl.formatMessage({id:"personQualificationRequest.qualification"})

          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="startDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              if (this.editPersonQualification)
                this.changedMap.set('startDate', this.editPersonQualification.startDate ? !moment(this.editPersonQualification.startDate).isSame(args, 'day') : !!args);
              return args;
            },
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('startDate'),
            label:this.props.intl.formatMessage({id:"personQualificationRequest.startDate"})

          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="endDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              if (this.editPersonQualification)
                this.changedMap.set('endDate', this.editPersonQualification.endDate ? !moment(this.editPersonQualification.endDate).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('endDate'),
            label:this.props.intl.formatMessage({id:"personQualificationRequest.endDate"})

          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="educationalInstitutionName"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editPersonQualification)
                this.changedMap.set('educationalInstitutionName', value !== this.editPersonQualification.educationalInstitutionName);
              return value;
            },

          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('educationalInstitutionName'),
            validateStatus: "success",
            label:this.props.intl.formatMessage({id:"personQualificationRequest.educationalInstitutionName"})
          }}
          />

        <ReadonlyField
          entityName={entityName}
          propertyName="courseName"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editPersonQualification)
                this.changedMap.set('courseName', value !== this.editPersonQualification.courseName);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('courseName'),
            validateStatus: "success",
            label:this.props.intl.formatMessage({id:"personQualificationRequest.courseName"})

          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="issuedDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              if (this.editPersonQualification)
                this.changedMap.set('issuedDate', this.editPersonQualification.issuedDate ? !moment(this.editPersonQualification.issuedDate).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('issuedDate'),
            label:this.props.intl.formatMessage({id:"personQualificationRequest.issuedDate"})

          }}
        />


        <ReadonlyField
          entityName={entityName}
          propertyName="diploma"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editPersonQualification)
                this.changedMap.set('diploma', value !== this.editPersonQualification.diploma);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('diploma'),
            validateStatus: "success",
            label:this.props.intl.formatMessage({id:"personQualificationRequest.diploma"})

          }}
        />






        <ReadonlyField
          entityName={entityName}
          propertyName="expiryDate"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{

            getValueFromEvent: args => {
              if (this.editPersonQualification)
                this.changedMap.set('expiryDate', this.editPersonQualification.expiryDate ? !moment(this.editPersonQualification.expiryDate).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('expiryDate'),
            label:this.props.intl.formatMessage({id:"personQualificationRequest.expiryDate"})

          }}
        />
      </div>
    );
  }



  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: PersonQualificationRequest | undefined) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();


    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

     const addressId = this.props.addressId || (item && item.personQualification ? item.personQualification.id : undefined)

    if (addressId)
      getCubaREST()!.loadEntity(PersonQualification.NAME, addressId, {view: 'personQualification-view'})
        .then(value => this.editPersonQualification = value as PersonDocument)
        .then(value => {
          this.instanceEditAddress.setItem(value);
          const properties = [
            "educationalInstitutionName",
            "startDate",
            "endDate",
            "diploma",
            "issuedDate",
            "attachments",
            "qualification",
            "profession",
            "documentType",
            "courseName",
            "expiryDate"];
          if (this.props.entityId === PersonDocumentRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditAddress.getFieldValues(properties));
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(PersonQualificationRequestEditComponent))
);
