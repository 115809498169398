import * as React from "react";
import {Card, Tabs} from "antd";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {inject, observer} from "mobx-react";
import IndividualDevPlanTasks from "./IndividualDevPlanTasks";
import {IndividualDevelopmentPlan} from "../../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {withRouter} from "react-router";
import Button, {ButtonType} from "../../../components/Button/Button";
import {goBackOrHomePage} from "../../../util/util";
import {rootStore, RootStoreProp} from "../../../store";
import {IdpDetail} from "../../../../cuba/entities/base/tsadv$IdpDetail";
import {RouteComponentProps} from "react-router-dom";
import IdpMeetings from "./IdpMeetings";
import {action, observable, runInAction, when} from "mobx";
import Notification from "../../../util/Notification/Notification";

type EditorProps = {
  entityId: string;
  positionName:string,
  organizationName:string,
  getOutcomeBtns?: JSX.Element | null;
  takCard?: JSX.Element | null;
  disabledPage:boolean;
}

@inject('rootStore')
@injectMainStore
@observer
class IndividualDevPlanTabs extends React.Component<RouteComponentProps&WrappedComponentProps & RootStoreProp & MainStoreInjected & EditorProps> {

  @observable isLoading:boolean = false

  @observable activeKey:string

  @action saveDetails = async () =>{
    let result:boolean = false;
    const idp = rootStore.idpStore!.selectedIdp;
    const idpDetails = rootStore.idpStore!.selectedIdpDetails;
    if (this.props.entityId === 'new') {
      const pushEntities = idpDetails.filter(value => !value.deleteTs && !value.id.startsWith('new')).map(item => getCubaREST()!.commitEntity<IdpDetail>(IdpDetail.NAME, {...item,competence:{id:item.competence&&item.competence.id}}))
      const newEntities = idpDetails.filter(value => !value.deleteTs && value.id.startsWith('new')).map(value => getCubaREST()!.commitEntity<IdpDetail>(IdpDetail.NAME, {
        ...value,
        id: "",
        idp:{id:idp.id},
        competence:{id:value.competence&&value.competence.id},
      }).then((detail) => {
      value.id  = detail.id;
      value._instanceName = detail['_instanceName'];
      value._entityName = detail['_entityName'];
      }))
      await Promise.all([pushEntities,newEntities]).then(()=>result = true).finally(()=>this.isLoading = false)
      return result
    }else{
      const deleteEntities = idpDetails.filter(value => value.deleteTs).map(item => getCubaREST()!.deleteEntity(IdpDetail.NAME, item.id))
      const pushEntities = idpDetails.filter(value => !value.deleteTs && !value.id.startsWith('new')).map(item => getCubaREST()!.commitEntity<IdpDetail>(IdpDetail.NAME, item))
      const newEntities = idpDetails.filter(value => !value.deleteTs && value.id.startsWith('new')).map(value => getCubaREST()!.commitEntity<IdpDetail>(IdpDetail.NAME, {
        ...value,
        id: "",
        idp:{id:idp.id},
        competence:{id:value.competence&&value.competence.id},
      }).then(detail=>{
        value.id = detail.id;
        value._instanceName = detail['_instanceName'];
        value._entityName = detail['_entityName'];
      }))
      Promise.all([deleteEntities, pushEntities, newEntities]).then(()=>result = true).finally(()=>this.isLoading = false)
     return result
    }
    }
  @action saveIdpDetails = async () => {
    let result = false;
    const store = this.props.rootStore!.idpStore
    const validation = store && store.validateFields()
    if(store&&store.selectedIdp){
      store.changeValidationStarted(true)
      if(!store.selectedIdp.positionGroup){
        Notification.error({message:"Выберите Штатную единицу ИПР",duration:3})
        this.isLoading = false;
        return
      }
    }
    if (validation) {
      this.isLoading = true;
      const idp = rootStore.idpStore!.selectedIdp
      if(idp.idpMeeting){
        delete idp.idpMeeting
      }
      if (this.props.entityId === 'new') {
      getCubaREST()!.commitEntity<IndividualDevelopmentPlan>(IndividualDevelopmentPlan.NAME, idp).then(async res => {
            idp.id = res.id
          await  this.saveDetails()
              .then(idpDetails => {
                  result = true
                Notification.success({message: this.props.intl.formatMessage({id:"idp.saved"}), duration: 3});
                return idpDetails
              }).catch(() => Notification.error({message: this.props.intl.formatMessage({id:"notification.IDP.error"}), duration: 3})).finally(()=>this.isLoading = false)
      })
        return result
      } else {
        this.isLoading = true
        const idpPlan = getCubaREST()!.commitEntity<IndividualDevelopmentPlan>(IndividualDevelopmentPlan.NAME, idp)
        const details =  this.saveDetails()
       await Promise.all([idpPlan,details]).then(() => {
          result = true;
          Notification.success({message: this.props.intl.formatMessage({id:"idp.saved"}), duration: 3})
        }).catch(() => Notification.error({message: this.props.intl.formatMessage({id:"notification.IDP.error"}), duration: 3})).finally(()=>this.isLoading = false)
        return result
      }
    }
    return result
  }
  render(): React.ReactNode {
    const {TabPane} = Tabs;
    const idpMeeting = this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.selectedIdp&&
                        this.props.rootStore!.idpStore.idpMeetings ||[]

    return <>
      <Card className="narrow-layout large-section section-container card-actions-container"
            actions={!this.props.disabledPage?[
              <Button  buttonType={ButtonType.FOLLOW} htmlType="button"
                      onClick={() => goBackOrHomePage(this.props.history!)}>
                <FormattedMessage id="close"/>
              </Button>,
              <Button onClick={() => this.saveIdpDetails()} key={"saveBtn"} loading={this.isLoading} style={{backgroundColor: "green"}}><span
                style={{color: "white"}}><FormattedMessage id={"save"}/></span></Button>,
              this.props.getOutcomeBtns
            ]:
              [
              <Button buttonType={ButtonType.FOLLOW} htmlType="button"
                      onClick={() => goBackOrHomePage(this.props.history!)}>
                <FormattedMessage id="close"/>
              </Button>,
              this.props.getOutcomeBtns
              ]
            }>
      <Tabs activeKey={this.activeKey} onTabClick={(key:string)=>{
        runInAction(()=>this.activeKey = key)
      }}>
        <TabPane tab={this.props.intl.formatMessage({
          id: "individualPlan.tabs.task"
        })} key="1">
          <IndividualDevPlanTasks entityId={this.props.entityId}/>
        </TabPane>
        <TabPane tab={this.props.intl.formatMessage({
          id: "individualPlan.tabs.history"
        })} key="2">
          {this.props.takCard}
        </TabPane>
        {rootStore.idpStore!.isReservist?<TabPane tab={this.props.intl.formatMessage({
          id: "individualPlan.tabs.meet"
        })} key="3">
          <IdpMeetings idpMeetings={idpMeeting}/>
        </TabPane>:undefined}
      </Tabs>
      </Card>
    </>
  }
  constructor(props:RouteComponentProps&WrappedComponentProps & RootStoreProp & MainStoreInjected & EditorProps) {
    super(props);
    if(this.props.rootStore!.idpStore){
      this.props.rootStore!.idpStore.saveIdpDetails = () =>this.saveDetails();
      this.props.rootStore!.idpStore.saveRequest = () => this.saveIdpDetails();
    }
    this.activeKey = "1"
    when(()=>!!(this.props.rootStore!.idpStore&&this.props.rootStore!.idpStore.approvingIdpMeetingId),()=>this.activeKey = "3")
  }
}

export default injectIntl(withRouter(IndividualDevPlanTabs));