import React from "react";
import {inject, observer} from "mobx-react";
import Page from "../../hoc/PageContentHoc";
import {getCubaREST, injectMainStore} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import SearchInput from "../../components/SearchInput";
import {Empty, Select, Spin} from "antd";
import {action, observable} from "mobx";
import {restServices} from "../../../cuba/services";
import moment, {Moment} from "moment";
import {RootStoreProp} from "../../store";
import InfiniteScroll from 'react-infinite-scroller';
import {PersonProfile} from "../MyTeam/MyTeamCard";
import DefaultDatePicker from "../../components/Datepicker";
import debounce from 'lodash.debounce'


interface StudentList {
  fullName:string,
  clock:string,
  imgId:any,
  personGroupid:string,
  postionName:string
}


@inject("rootStore")
@injectMainStore
@observer
class StudentsRatingList extends  React.Component<WrappedComponentProps & RootStoreProp>{


  @observable byDateType:string
  @observable Year:any = parseInt(moment().format("YYYY"))
  @observable Month:string = moment().format("MMMM")
  @observable MonthNumber:null | number = null
  @observable Limit:number = 10
  @observable studentList:Array<any> = []
  @observable originalStudentList:Array<any> = []
  @observable isLoading:boolean = false
  @observable isLoadingData:boolean = false
  @observable currentOffset:number = 0
  @observable OFFSET:number = 10
  @observable searchText:string = ""
  @observable personProfile:PersonProfile
  @observable imageUrl:string
  @observable requiredType:string = 'all'
  @observable selectedYear?: Moment | null = moment().subtract(0, "year");

  @action
  onSearch = (searchText:string) => {
      this.searchText = searchText
      this.currentOffset = 0
      this.getStudentList(false)
  }

  onSearchDebounce = (searchText:string) =>{
    this.onSearch(searchText)
  }
  @action
  onSearchSelect = (searchText:string) => {
    if(searchText){

    }
  }

  @action
  handleChangeType = (filteredValue:string) => {
    this.requiredType = filteredValue
    this.getStudentList(false)
  }
  @action
  handleChangeDateType = (filteredValue:string) => {
    this.byDateType = filteredValue
    if(filteredValue === "BYMONTH"){
     this.MonthNumber = parseInt(moment().format("MM"))
      this.getStudentList(false)
    }
    else{
      this.MonthNumber = null
      this.getStudentList(false)
    }
  }

  @action
  handleChangeYear = (year:Moment) => {
    this.selectedYear = year
    this.Year = parseInt(year.format('YYYY'))
    this.getStudentList(false)
  }

  @action
  handleChangeMonth = (month:string) => {
    this.Month = month
    this.MonthNumber = parseInt(month)
    this.getStudentList(false)
  }

  @action
  handleChangeLimit = (limit:number) => {
    this.Limit = limit
    this.OFFSET = limit
    this.getStudentList(false)
  }

  onOpenYearChanged = (status: boolean) => {

  }
  getMonths = (lang:string):Array<{monthName:string,monthQueue:number}> =>{
    if(lang === 'ru'){
      return [
        {monthName:"Январь",monthQueue:1},
        {monthName:"Февраль",monthQueue:2},
        {monthName:"Март",monthQueue:3},
        {monthName:"Апрель",monthQueue:4},
        {monthName:"Май",monthQueue:5},
        {monthName:"Июнь",monthQueue:6},
        {monthName:"Июль",monthQueue:7},
        {monthName:"Август",monthQueue:8},
        {monthName:"Сентябрь",monthQueue:9},
        {monthName:"Октябрь",monthQueue:10},
        {monthName:"Ноябрь",monthQueue:11},
        {monthName:"Декабрь",monthQueue:12},]
    }
    else return [
      {monthName:"January",monthQueue:1},
      {monthName:"February",monthQueue:2},
      {monthName:"March",monthQueue:3},
      {monthName:"April",monthQueue:4},
      {monthName:"May",monthQueue:5},
      {monthName:"June",monthQueue:6},
      {monthName:"Jule",monthQueue:7},
      {monthName:"August",monthQueue:8},
      {monthName:"September",monthQueue:9},
      {monthName:"Oktober",monthQueue:10},
      {monthName:"November",monthQueue:11},
      {monthName:"December",monthQueue:12},]
  }

  getStudentList =  (isUsedScroll?:boolean) =>{
    isUsedScroll ? this.isLoading = true : this.isLoading = false
    isUsedScroll ?  this.isLoadingData = false : this.isLoadingData = true

    restServices.learningService.getStudentRating(
      { lang:"ru",
        company:this.props.rootStore!.userInfo!.companyCode!,
        searchText:this.searchText,
        type:this.requiredType === "all" ? null : this.requiredType,
        month:this.MonthNumber,
        year:this.Year,
        limit:this.Limit,
        nextOffset:isUsedScroll ? this.currentOffset : 0
      }).then(value => {
        return JSON.parse(value[0] && value[0].value)
    }).then( async student=> {


      let result:Array<any> = []
      if (student !== null) {

      const loadImages = async () => {
        return await Promise.all(student.map((item:any) =>
          this.getImageUrl(item!.person_group_id!)
        )).then(images => {
          return student.map((item:any, index:number) => ({
            ...item,
            imageUrl: images[index]
          }))
        })
      }
         result = await loadImages()
    }else{
        this.isLoadingData = false
        this.isLoading = false
      }
      isUsedScroll ? this.originalStudentList = [...this.originalStudentList, ...result] : this.originalStudentList = result
    })
  }

  getImageUrl = async (id:string) =>{
    return await restServices.employeeService
      .personProfile(id).then(value => {
        this.personProfile = value;
        if (this.personProfile && this.personProfile.imageId)
          return  getCubaREST()!
            .getFile(this.personProfile.imageId)
            .then((value: Blob) =>{
              this.isLoadingData = false
              this.isLoading = false
              return URL.createObjectURL(value)
            } )
        return 'no'
      }).catch(()=>{
        this.isLoadingData = false
        return ''
      })
  }

  componentDidMount() {
    this.getStudentList()
    console.log(moment().subtract(0, "year"))
  }

  render(){
    const { Option } = Select;

    // const limit:Array<number> = [10,20,50,70]

    const options = [];

    const minOffset = 2015;

    const maxOffset = 2050;

    for (let i = minOffset; i <= maxOffset; i++) {
      options.push(<Option value={i}>{i}</Option>);
    }

    return(
      <div>
        <Page pageName={this.props.intl.formatMessage({id:"rating-students.title"})}>
          <div style={{marginBottom:50}}>
            <SearchInput onSearch={debounce(this.onSearch, 1000)} />
          </div>
          <div style={{marginBottom:20,display:"flex", alignItems:"center"}}>
            <Select defaultValue={this.props.intl.formatMessage({id: "rating-students.filter.all"}) } style={{width:"300px"}} onChange={value => this.handleChangeType(value as string)}>
              <Option value={"all"}>
                {this.props.intl.formatMessage({id: "rating-students.filter.all"})}
              </Option>
              <Option value={"REQUIRED"}>
                {this.props.intl.formatMessage({id: "rating-students.filter.with-mandatory"})}
              </Option>
              <Option value={"NOT_REQUIRED"}>
                {this.props.intl.formatMessage({id: "rating-students.filter.without-mandatory"})}
              </Option>
            </Select>

            <Select defaultValue={'BYYEAR'} style={{width:200, marginLeft:10}} onChange={value => this.handleChangeDateType(value as string)}>
              <Option value={'BYYEAR'}>
                {this.props.intl.formatMessage({id: "rating-students.filter.by-year"})}
              </Option>
              <Option value={"BYMONTH"}>
                {this.props.intl.formatMessage({id: "rating-students.filter.by-month"})}
              </Option>
            </Select>

            <DefaultDatePicker style={{marginLeft: 10}}
                               mode={'year'}
                               format="yyyy"
                               onChange={this.handleChangeYear}
                               onOpenChange={this.onOpenYearChanged}
                               value={this.selectedYear}
                               defaultValue={this.selectedYear}
                               onPanelChange={(val) => {
                                 this.selectedYear =  val
                                 this.Year = parseInt(val!.format('YYYY'))
                                 this.getStudentList(false)
                               }}/>

            {/*<Select*/}
            {/*  optionFilterProp="children"*/}
            {/*  showSearch*/}
            {/*  defaultValue={this.Year}*/}
            {/*  style={{width:100, marginLeft:10}}*/}
            {/*  onChange={value => this.handleChangeYear(value)}*/}
            {/*  onSearch={this.onSearchSelect}*/}
            {/*  filterOption={(input, option) =>*/}
            {/*    (option.props.children)!.toString()!.indexOf(input) >= 0*/}
            {/*  }*/}
            {/*>*/}
            {/*  {options}*/}
            {/*</Select>*/}
            { this.byDateType === "BYMONTH" ?
              <Select
                optionFilterProp="children"
                showSearch
                defaultValue={this.Month}
                style={{width:200, marginLeft:10}}
                onChange={value => this.handleChangeMonth(value as string)}
                onSearch={this.onSearchSelect}
                filterOption={(input, option) =>
                  (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  this.getMonths(this.props.rootStore!.userInfo!.locale!)!.map(item => {
                    return <Option key={item.monthQueue} value={item.monthQueue}>
                      {item.monthName}
                    </Option>
                  })
                }
              </Select>:<></>
            }
            {/*<Select*/}
            {/*  defaultValue={this.Limit}*/}
            {/*  style={{width:300, marginLeft:10}}*/}
            {/*  onChange={this.handleChangeLimit}*/}
            {/*  onSearch={this.onSearchSelect}*/}
            {/*  filterOption={(input, option) =>*/}
            {/*    (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0*/}
            {/*  }*/}
            {/*>*/}
            {/*  {*/}
            {/*    limit!.map(item=>{*/}
            {/*      return <Option value={item}>*/}
            {/*        {this.props.intl.formatMessage({id: "rating-students.filter.view"})} {item}*/}
            {/*      </Option>*/}
            {/*    })*/}
            {/*  }*/}
            {/*</Select>*/}
          </div>

          { this.originalStudentList && this.originalStudentList.length > 0 ?
            <Spin spinning={false}>  <InfiniteScroll
              style={{
                display: "grid",
                gap: 20,
                gridTemplateColumns: "repeat(5,1fr)"
              }}
              pageStart={0}
              initialLoad={false}
              loadMore={() => {
                this.currentOffset += this.OFFSET
                this.getStudentList(true)
              }}
              hasMore={true}
              loader={<div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}
                           className="loader" key={0}>< Spin spinning={this.isLoading}/></div>}
            >

              {this.originalStudentList && this.originalStudentList!.map((student: any) => {
                return <div style={{
                  width: "100%",
                  padding: 10,
                  boxShadow: "0 3px 24px rgb(0 0 0 / 10%)",
                  borderRadius: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}>
                  <img
                    style={{height: 200, width: 200, borderRadius: '50%', marginBottom: 10}}
                    src={student && student.imageUrl! !== 'no' ? student && student.imageUrl! : require('../../../resources/img/default-avatar.svg')}/>
                  <h3 style={{marginBottom: 5, textAlign: "center"}}> {student && student.full_name!}</h3>
                  <p style={{marginBottom: 5, textAlign: "center"}}>{student && student.pos_name!}</p>
                  <p style={{marginBottom: 5, textAlign: "center"}}>{student && student.hours!} ч.</p>
                </div>
              })}

            </InfiniteScroll>  </Spin> : <Empty description={false} />
          }


        </Page>
      </div>
    )
  }


}

export default injectIntl(StudentsRatingList)