import './style.css'
import { ComponentProps, createElement, default as React } from "react";
import {
  collection,
  getCubaREST,
  injectMainStore,
  instance,
  MainStoreInjected,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";
import Page from "../../hoc/PageContentHoc";
import { RootStoreProp } from "../../store";
import { inject, observer } from "mobx-react";
import { Card, DatePicker, Form, Icon, Input, Modal, Row, Select, Tooltip } from "antd";
import { RouteComponentProps } from "react-router-dom";
import Button, { ButtonType } from "../../components/Button/Button";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { withRouter } from "react-router";
import { FormComponentProps } from "antd/lib/form";
import Notification from "../../util/Notification/Notification";
import { LearningBudgetRequest } from "../../../cuba/entities/base/tsadv_LearningBudgetRequest";
import { LearningBudget } from "../../../cuba/entities/base/tsadv_LearningBudget";
import { parseToJsonFromFieldValue } from "../../components/MultiFileUpload";
import { OrganizationGroupExt } from "../../../cuba/entities/base/base$OrganizationGroupExt";
import { PersonGroupExt } from "../../../cuba/entities/base/base$PersonGroupExt";
import { action, IReactionDisposer, observable, observe, reaction } from "mobx";
import { Course } from "../../../cuba/entities/base/tsadv$Course";
import TextArea from "antd/lib/input/TextArea";
import { DicCostCenter } from "../../../cuba/entities/base/tsadv$DicCostCenter";
import { DicLearningType } from "../../../cuba/entities/base/tsadv$DicLearningType";
import { LearningBudgetRequestCost } from "../../../cuba/entities/base/tsadv_LearningBudgetRequestCost";
import LearningBudgetRequestManagement from "./LearningBudgetRequestManagement";
import { restServices } from "../../../cuba/services";
import EmployeePickerTable, { SelectRowType } from "./EmployeePickerTable";
import { SerializedEntity } from "@cuba-platform/rest";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import moment from "moment";
import CoursePickerTable, { CoursePickerTableData } from "./CoursePickerTable";
import { PartyExt } from "../../../cuba/entities/base/base$PartyExt";
import { DicCategory } from "../../../cuba/entities/base/tsadv$DicCategory";
import { PerdiemsSetting } from "../../../cuba/entities/base/tsadv_PerdiemsSetting";
import { DicRegion } from "../../../cuba/entities/base/tsadv_DicRegion";
import { PositionGroupExt } from "../../../cuba/entities/base/base$PositionGroupExt";
import { LearningBudgetRequestHeader } from "../../../cuba/entities/base/tsadv_LearningBudgetRequestHeader";
import { CostsComponent } from "./CostsComponent";
import { getValidGreaterZero, parseStringToFloat, updateCost } from "./utils";
import { ObservableArray } from "mobx/lib/types/observablearray";
import { goBackOrHomePage } from "../../util/util";
import LabelTooltip from "./LabelTooltip/LabelTooltip";
import { ReportGroup } from "../../../cuba/entities/base/report$ReportGroup";
import { OrganizationExt } from '../../../cuba/entities/base/base$OrganizationExt';
import {ValidationRule} from "antd/es/form";

type EditorProps = {
  entityId: string;
  budgetId: string;
  readOnly?: string;
};

@inject("rootStore")
@injectMainStore
@observer
class LearningBudgetRequestEdit extends React.Component<EditorProps & WrappedComponentProps & FormComponentProps & MainStoreInjected & RootStoreProp & RouteComponentProps> {

  @observable learningBudget: LearningBudget;
  @observable selectedEmployee: AssignmentExt;
  @observable selectedEmployees: AssignmentExt[] = [];
  @observable selectedCourse?: CoursePickerTableData;
  @observable selectedLearningType?: SerializedEntity<DicLearningType>;
  @observable selectedTypeOfTraining?: SerializedEntity<DicCategory>;
  @observable assignmentsList: SerializedEntity<AssignmentExt>[];
  @observable currentUser: SerializedEntity<PersonGroupExt>;
  @observable pickerUsersDataItems: Array<AssignmentExt>;
  @observable pickerCoursesDataItems: Array<CoursePickerTableData>;
  @observable isCourseOnline: boolean;
  @observable organizationGroupList: SerializedEntity<OrganizationGroupExt>[];
  @observable selectedOrganization: SerializedEntity<OrganizationGroupExt>;
  @observable selectedBaseOrganization: SerializedEntity<OrganizationExt>;
  @observable isEmployeeModalVisible: boolean;
  @observable isCourseModalVisible: boolean;
  @observable perdiemsSettings?: PerdiemsSetting;
  @observable reportGroup?: SerializedEntity<OrganizationGroupExt>
  @observable isCourseRequred: boolean = true
  @observable isCourseNameRequred: boolean = true

  learningBudgetRequestHeader: LearningBudgetRequestHeader;
  @observable daysCount = {
    value: 1
  }
  @observable employeeCount = {
    value: 1
  }

  reactionDisposers: IReactionDisposer[] = [];

  dataInstance = instance<LearningBudgetRequest>(LearningBudgetRequest.NAME, {
    view: "learningBudgetRequest-front-view",
    loadImmediately: false
  });

  costDataInstance = instance<LearningBudgetRequestCost>(LearningBudgetRequestCost.NAME, {
    view: "_local",
    loadImmediately: false
  });

  positionGroupsDc = collection<PositionGroupExt>(PositionGroupExt.NAME, {
    view: "_minimal",
    filter: {
      conditions: [{
        property: "company.id",
        operator: "=",
        value: this.props.rootStore!.learningBudgetInfo.selectedBudget ? this.props.rootStore!.learningBudgetInfo.selectedBudget!.company!.id : this.props.history.goBack()
      }]
    },
  });


  learningTypesDc = collection<DicLearningType>(DicLearningType.NAME, {
    sort: "-updateTs",
  });

  typeOfTrainingDc = collection<DicCategory>(DicCategory.NAME, {
    sort: "-updateTs",
  });

  coursesDc = collection<Course>(Course.NAME, {
    view: "course-front-view",
    sort: "-updateTs",
  });

  costCentersDc = collection<DicCostCenter>(DicCostCenter.NAME, {
    sort: "-updateTs",
  });

  regionsDc = collection<DicRegion>(DicRegion.NAME, {
    sort: "-updateTs",
  });

  reportGroupDc = collection<OrganizationGroupExt>(OrganizationGroupExt.NAME, {
    sort: "-updateTs",
  })


  fields = [
    "organizationGroup",

    "employee",

    "costCenter",

    "positionName",

    "responsiblePerson",

    "employeeCount",

    "course",

    "typeOfTraining",

    "courseName",

    "learningType",

    "reportGroup",

    "city",

    "month",

    "region",

    "dayCount",

    "reason",

  ];

  costFields = [
    "trainingCost",

    "travel",

  ];

  constructor(props: EditorProps & WrappedComponentProps & FormComponentProps & MainStoreInjected & RootStoreProp & RouteComponentProps) {
    super(props);
    observe(this.dataInstance, change => {
      if (change.object.status === 'DONE' && change.object.item.course) {
        this.isCourseOnline = change.object.item.course.isOnline || false
      }
    })
  }

  isNewEntity = () => {
    return this.props.entityId === LearningBudgetRequestManagement.NEW_SUBPATH;
  }


  getUpdateEntityData = (fields: string[]): any => {
    const obj = {
      ...this.props.form.getFieldsValue(fields),
    };

    const metaClass = this.props.mainStore!.metadata!.find(mci => mci.entityName === this.dataInstance.entityName);
    if (metaClass) {
      metaClass.properties
        .filter(value => value.cardinality === "ONE_TO_MANY" || value.cardinality === "MANY_TO_MANY")
        .filter(value => fields.find(field => field === value.name))
        .forEach(value => {
          const files = obj[value.name];
          if (files)
            obj[value.name] = parseToJsonFromFieldValue(files);
        })
    }
    return obj;
  };

  updateCost = () => {
    const obj = this.getUpdateEntityData(this.costFields);
    updateCost(obj, this.props.form)
    return this.costDataInstance.update(obj);
  }

  update = () => {
    let obj = this.getUpdateEntityData(this.fields);
    return this.configureFieldsAndUpdate(obj);
  }

  configureFieldsAndUpdate = (obj: any) => {
    const courseData = obj['course'] as Course;
    const employeeData = obj['employee'] as PersonGroupExt;
    if (courseData) {
      const course = this.coursesDc.items.find(item => item.id === courseData.id);
      if (course) {
        obj['course'] = course;
      }
    }
    if (employeeData) {
      obj['employee'] = employeeData;
    }
    obj['costByPerson'] = parseStringToFloat(this.props.form.getFieldValue('totalByOne'));
    obj['costSum'] = parseStringToFloat(this.props.form.getFieldValue('totalWhole'));
    obj['learningBudgetRequestHeader'] = this.learningBudgetRequestHeader;
    return this.dataInstance.update(obj);
  }

  close = () => {
    this.props.history!.goBack();
  }

  updateAndClose = () => {
    // @ts-ignore
    const r = this.props.form.validateFields() as Promise<any>
    r.then(value => {
      this.updateCost()
        .then(cost => {
          this.update()
            .then(value => this.close())
            .catch((a: any) =>
              Notification.error({
                message: this.props.intl.formatMessage({
                  id: "management.editor.error"
                })
              })
            )
        })
        .catch((a: any) =>
          Notification.error({
            message: this.props.intl.formatMessage({
              id: "management.editor.error"
            })
          })
        )
    }).catch(reason => {
      const errors = reason.errors
      for (let field in errors) {
        errors[field].errors.forEach((val: {
          message: string
          field: string
        }) => {
          Notification.error({
            message: val.message
          })
        })
      }
    })
  }

  onEmployeeChange = (employeeId: string) => {
    const employee = this.assignmentsList.find(item => item.personGroup!.id === employeeId);
    this.selectedEmployee = employee!;
    this.selectedEmployees.splice(0, 1)
    this.selectedEmployees.push(this.selectedEmployee)
    this.props.form.setFieldsValue({
      employee: this.selectedEmployee.personGroup,
      employeeCount: 1
    });
    if (this.selectedEmployee.costCenter && !this.props.form.getFieldValue('costCenter')) {
      this.props.form.setFieldsValue({
        costCenter: this.selectedEmployee.costCenter.id
      })
    }
    restServices.employeeService.personProfile(employeeId)
      .then(value => {
        this.dataInstance.item!.positionName = value.positionName;
        this.props.form.setFieldsValue({
          positionName: this.dataInstance.item!.positionName
        });
      })
      .catch(() => {
        Notification.error({
          message: this.props.intl.formatMessage({ id: "management.editor.error" })
        });
      }
      )
  }

  onCourseChange = (courseId: string) => {
    this.isCourseNameRequred = false
    this.selectedCourse = this.pickerCoursesDataItems.find(item => item.id === courseId)!;
    const course = this.coursesDc.items.find(item => item.id === courseId);
    this.selectedLearningType = course!.learningType as SerializedEntity<DicLearningType>;
    this.selectedTypeOfTraining = course!.category as SerializedEntity<DicCategory>;
    this.props.form.setFieldsValue({
      course: this.selectedCourse,
      learningType: this.selectedLearningType.id,
      typeOfTraining: this.selectedTypeOfTraining.id
    });

    getCubaREST()!.loadEntity<Course>(Course.NAME, courseId, { view: '_local' })
      .then(value => {
        this.isCourseOnline = value.isOnline!;
        this.props.form.setFieldsValue({
          courseName: value._instanceName,
          courseNameEng: value.nameLang3
        });
        this.dataInstance.item!.courseNameEng = value.nameLang3
        if (this.isCourseOnline) {
          this.props.form.setFieldsValue({
            city: "ONLINE"
          });
        }
      })
      .catch(() => {
        Notification.error({
          message: this.props.intl.formatMessage({ id: "management.editor.error" })
        });
      }
      )
  }

  onRegionChange = (regionId: string) => {
    this.findPerdiemSetting(regionId);
  }

  findPerdiemSetting = (regionId: string) => {
    getCubaREST()!.searchEntities<PerdiemsSetting>(PerdiemsSetting.NAME, {
      conditions: [{
        property: 'companyCode.id',
        operator: '=',
        value: this.selectedOrganization!.company!.id
      },
      {
        property: 'region.id',
        operator: '=',
        value: regionId
      }]
    }, {
      sort: '-createTs',
      view: 'perdiems-front-view',
      limit: 1
    }).then(perdiemsResult => {
      if (perdiemsResult.length) {
        this.perdiemsSettings = perdiemsResult[0] as PerdiemsSetting;
      } else {
        this.perdiemsSettings = undefined
        Notification.info({
          message: this.props.intl.messages['dailyAllowance.empty']
        })
      }
    });
  }

  parseToCourseData = (course: SerializedEntity<Course>): CoursePickerTableData => {
    return {
      id: course.id,
      name: course._instanceName,
      shortDescription: course.shortDescription,
      party: course.party ? ((course.party) as SerializedEntity<PartyExt>)._instanceName : "",
      category: course.category ? ((course.category) as SerializedEntity<DicCategory>)._instanceName : "",
      targetAudience: course.targetAudience,
      learningType: course.learningType ? ((course.learningType) as SerializedEntity<DicLearningType>)._instanceName : "",
      activeFlag: course.activeFlag ? this.props.intl.formatMessage({ id: "cubaReact.dataTable.yes" }) : this.props.intl.formatMessage({ id: "cubaReact.dataTable.no" }),
      selfEnrollment: course.selfEnrollment ? this.props.intl.formatMessage({ id: "cubaReact.dataTable.yes" }) : this.props.intl.formatMessage({ id: "cubaReact.dataTable.no" }),
      isOnline: course.isOnline ? this.props.intl.formatMessage({ id: "cubaReact.dataTable.yes" }) : this.props.intl.formatMessage({ id: "cubaReact.dataTable.no" }),
      sorterColumns: []
    } as CoursePickerTableData
  }


  onEmployeeModalOkClick = (employee: AssignmentExt[]) => {
    this.onEmployeeChange(employee[0].personGroup!.id);
    this.isEmployeeModalVisible = false;
  }

  onEmployeeModalCancelClick = () => {
    this.isEmployeeModalVisible = false;
  }

  onCourseModalOkClick = (course: CoursePickerTableData[]) => {
    this.selectedCourse = course[0];
    this.onCourseChange(this.selectedCourse.id);
    this.isCourseModalVisible = false;
  }

  onCourseModalCancelClick = () => {
    this.isCourseModalVisible = false;
  }

  showEmployeeModal = () => {
    this.isEmployeeModalVisible = true;
  };

  showCourseModal = () => {
    this.isCourseModalVisible = true;
  };

  clearCourseFields = () => {
    this.isCourseNameRequred = true
    this.selectedCourse = undefined;
    this.dataInstance.item!.course = undefined;
    this.selectedLearningType = undefined;
    this.selectedTypeOfTraining = undefined;
    this.isCourseOnline = false
    this.props.form.setFieldsValue({
      course: this.selectedCourse,
      learningType: this.selectedLearningType,
      typeOfTraining: this.selectedTypeOfTraining,
      courseName: "",
      courseNameEng: "",
      city: ""
    });
  };


  render() {
    if (!this.assignmentsList || this.coursesDc.status === 'LOADING' || this.positionGroupsDc.status === 'LOADING') return 'Loading...';

    if (!this.pickerCoursesDataItems) {
      this.pickerCoursesDataItems = this.coursesDc.items.sort((a, b) => {
        if (a._instanceName < b._instanceName) {
          return -1;
        }
        if (a._instanceName > b._instanceName) {
          return 1;
        }
        return 0;
      }).map(item => this.parseToCourseData(item));
    }

    const positions = this.positionGroupsDc && this.positionGroupsDc.items
      ? this.positionGroupsDc.items.filter(item => item._instanceName && item._instanceName.length > 0)
        .sort((a, b) => {
          if (a._instanceName < b._instanceName) {
            return -1;
          }
          if (a._instanceName > b._instanceName) {
            return 1;
          }
          return 0;
        })
        .map(item => item._instanceName)
      : []
    const uniquePositions: string[] = [];

    for (const p in positions) {
      if (!uniquePositions.includes(positions[p])) {
        uniquePositions.push(positions[p])
      }
    }

    const actions = [];
    const messages = this.props.mainStore!.messages!;
    const responsiblePersonOption = this.currentUser ? {
      value: this.currentUser.id,
      label: this.currentUser._instanceName
    } : null;
    const organizationOption = this.selectedOrganization ? {
      value: this.selectedOrganization.id,
      label: this.selectedOrganization._instanceName
    } : null;
    const costCenterOption = this.dataInstance.item!.costCenter ?
      {
        value: this.dataInstance.item!.costCenter.id,
        label: (this.dataInstance.item!.costCenter as SerializedEntity<DicCostCenter>)._instanceName
      }
      : this.selectedBaseOrganization && this.selectedBaseOrganization.costCenter ? {
        value: this.selectedBaseOrganization.costCenter.id,
        label: (this.selectedBaseOrganization.costCenter as SerializedEntity<DicCostCenter>)._instanceName
      }
        : this.selectedOrganization && this.selectedOrganization.costCenter ?
          {
            value: this.selectedOrganization.costCenter.id,
            label: (this.selectedOrganization.costCenter as SerializedEntity<DicCostCenter>)._instanceName
          }
          : undefined;


    const regionOption = this.dataInstance.item!.region ?
      {
        value: this.dataInstance.item!.region.id,
        label: (this.dataInstance.item!.region as SerializedEntity<DicRegion>)._instanceName
      }
      : null;


    let employeeOption;
    let courseOption;
    let typeOFTrainingOption;
    let learningTypeOption;

    if (this.selectedTypeOfTraining) {
      typeOFTrainingOption = { value: this.selectedTypeOfTraining.id, label: this.selectedTypeOfTraining._instanceName };
    } else if (this.dataInstance.item!.typeOfTraining) {
      typeOFTrainingOption = {
        value: this.dataInstance.item!.typeOfTraining.id,
        label: (this.dataInstance.item!.typeOfTraining as SerializedEntity<DicCategory>)._instanceName
      };
    }

    if (this.selectedLearningType) {
      learningTypeOption = { value: this.selectedLearningType.id, label: this.selectedLearningType._instanceName };
    } else if (this.dataInstance.item!.learningType) {
      learningTypeOption = {
        value: this.dataInstance.item!.learningType.id,
        label: (this.dataInstance.item!.learningType as SerializedEntity<DicLearningType>)._instanceName
      };
    }

    if (this.selectedEmployee) {
      employeeOption = {
        value: this.selectedEmployee.personGroup!.id,
        label: (this.selectedEmployee.personGroup as SerializedEntity<PersonGroupExt>)._instanceName
      };
    } else if (this.dataInstance.item!.employee) {
      employeeOption = {
        value: this.dataInstance.item!.employee.id,
        label: (this.dataInstance.item!.employee as SerializedEntity<PersonGroupExt>)._instanceName
      };
    }

    if (this.selectedCourse) {
      courseOption = { value: this.selectedCourse.id, label: this.selectedCourse.name };
    } else if (this.dataInstance.item!.course) {
      courseOption = {
        value: this.dataInstance.item!.course.id,
        label: (this.dataInstance.item!.course as SerializedEntity<Course>)._instanceName
      };
    }

    if (!this.props.readOnly) {
      actions.push(<Button
        onClick={this.updateAndClose}
        buttonType={ButtonType.PRIMARY}
        style={{ marginLeft: "8px" }}>
        <FormattedMessage id="management.editor.submit" />
      </Button>);
    }
    actions.push(<Button htmlType="button"
      buttonType={ButtonType.FOLLOW}
      onClick={() => this.props.history!.goBack()}>
      <FormattedMessage id="management.editor.cancel" />
    </Button>);

    return (
      <Page pageName={this.props.intl.formatMessage({ id: "budgetRequestEdit" })}>
        <Card className="narrow-layout card-actions-container"
          bordered={false} actions={actions}>
          <div className={"ant-row ant-form-item"}
            style={{ marginBottom: "12px", padding: "0 30px" }}>
            <fieldset style={{ border: '1px silver solid', borderRadius: '1rem' }}>
              <legend style={{
                width: 'fit-content',
                margin: '0 1%'
              }}>{this.props.intl.formatMessage({ id: "employeeInfo" })}</legend>
              <Form className='form-grid'>
                <Row style={{ gridColumn: 1 }}>
                  <Form.Item>
                    <div className="budget-request-form">
                      <label>{this.props.intl.formatMessage({ id: "organizationGroupLabel" })}</label>
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("organizationGroup",
                          {
                            initialValue: organizationOption ? organizationOption!.value : null
                          })(
                            <Select showSearch
                              disabled={true}>
                              {organizationOption ?
                                <Select.Option key={organizationOption.value}>
                                  {organizationOption.label}
                                </Select.Option> : null}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <label>{messages[LearningBudgetRequest.NAME + '.' + 'costCenter']}</label>
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("costCenter",
                          {
                            initialValue: costCenterOption ? costCenterOption.value : undefined
                          })(
                            <Select showSearch>
                              {this.costCentersDc && this.costCentersDc.items.map(value =>
                                <Select.Option key={value.id}>
                                  {value._instanceName}
                                </Select.Option>)}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <label>{messages[LearningBudgetRequest.NAME + '.' + 'responsiblePerson']}</label>
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("responsiblePerson",
                          {
                            initialValue: responsiblePersonOption ? responsiblePersonOption.value : null
                          })(
                            <Select disabled={true}
                              style={{ width: '100%' }}>
                              {responsiblePersonOption ?
                                <Select.Option key={responsiblePersonOption.value}>
                                  {responsiblePersonOption.label}
                                </Select.Option> : null}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                </Row>
                <Row style={{ gridColumn: 2 }}>
                  <Form.Item>
                    <div className="budget-request-form">
                      <label>{messages[LearningBudgetRequest.NAME + '.' + 'employee']}</label>
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("employee",
                          {
                            valuePropName: 'personId',
                            initialValue: employeeOption ? employeeOption.value : undefined
                          })(
                            <Select
                              showSearch
                              filterOption={(input, option) => {
                                return option.props.children!.toLocaleString().toLowerCase().includes(input.toLowerCase());
                              }
                              }
                              value={employeeOption ? employeeOption.value : undefined}
                              onChange={this.onEmployeeChange}>
                              {this.assignmentsList && this.assignmentsList.map(value =>
                                <Select.Option key={value.personGroup!.id}>
                                  {(value.personGroup as SerializedEntity<PersonGroupExt>)._instanceName}</Select.Option>)}
                            </Select>
                          )}
                        <Button
                          className="picker-btn"
                          icon="ellipsis"
                          onClick={() => this.showEmployeeModal()}
                          key="modal">

                        </Button>
                        <Modal
                          title={this.props.intl.formatMessage({ id: "persons" })}
                          width={"1200px"}
                          style={{ height: "770px" }}
                          footer={null}
                          closable={false}
                          visible={this.isEmployeeModalVisible}>
                          <div>
                            <EmployeePickerTable
                              items={this.assignmentsList}
                              intl={this.props.intl}
                              onOkClick={this.onEmployeeModalOkClick}
                              onCancelClick={this.onEmployeeModalCancelClick}
                              paginationPosition={"bottom"}
                              tableProps={{ rowKey: "id" }}
                              selectRowType={SelectRowType.SINGLE} />
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <label>{this.props.intl.formatMessage({ id: "positionNameLabel" })}</label>
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("positionName",
                          {
                            initialValue: this.dataInstance.item ? this.dataInstance.item!.positionName : undefined
                          })(
                            <Select showSearch
                              filterOption={(input, option) => {
                                return option.props.children!.toLocaleString().toLowerCase().includes(input.toLowerCase());
                              }
                              }>
                              {uniquePositions && uniquePositions.map(value =>
                                <Select.Option key={value}>
                                  {value}</Select.Option>)}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <label
                        style={{ display: "inline-block" }}>{this.props.intl.formatMessage({ id: "numberOfEmployees" })}</label>
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("employeeCount",
                          {
                            initialValue: this.dataInstance.item!.employeeCount ? this.dataInstance.item!.employeeCount || this.employeeCount.value : 1,
                            rules: [
                              {
                                transform: (value: string) => parseInt(value),
                                min: 1,
                                type: 'integer',
                                message: this.props.intl.messages['employeeCount.invalid']
                              },
                              {
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.intl.formatMessage({ id: "numberOfEmployees" }) })
                              }
                            ]
                          })(
                            <Input
                              onChange={(ev) => this.employeeCount.value = parseInt(ev.target.value) || 1}
                              disabled={(this.dataInstance.item && this.dataInstance.item.employee === 'undefined') || this.props.form.getFieldValue('employee')} />
                          )}
                      </div>
                    </div>
                  </Form.Item>
                </Row>
              </Form>
            </fieldset>
            <fieldset style={{
              border: "1px solid silver",
              borderRadius: "1rem",
              marginTop: "5%"
            }}>
              <legend style={{
                width: "fit-content",
                margin: '0 1%'
              }}>{this.props.intl.formatMessage({ id: "course.information" })}</legend>
              <Form style={{ padding: "10px" }}>
                <Row>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[`${LearningBudgetRequest.NAME}.course`]} trigger={'click'}
                        title={this.props.intl.formatMessage({ id: "courseHint" })} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("course",
                          {
                            rules: [{
                              required: this.isCourseRequred,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[`${LearningBudgetRequest.NAME}.course`] })
                            }],
                            valuePropName: 'id',
                            initialValue: courseOption ? courseOption.value : undefined
                          })(
                            <Select showSearch
                              style={{ width: "96%" }}
                              filterOption={(input, option) => {
                                return option.props.children!.toLocaleString().toLowerCase().includes(input.toLowerCase());
                              }
                              }
                              value={courseOption ? courseOption.value : undefined}
                              onChange={this.onCourseChange}>
                              {this.pickerCoursesDataItems && this.pickerCoursesDataItems.map(value =>
                                <Select.Option key={value.id}>
                                  {value.name}</Select.Option>)}
                            </Select>
                          )}
                        <Button
                          className="picker-btn"
                          icon="close"
                          onClick={() => this.clearCourseFields()}
                          key="clear">

                        </Button>
                        <Button
                          className="picker-btn"
                          icon="ellipsis"
                          onClick={() => this.showCourseModal()}
                          key="modal">

                        </Button>

                        <Modal
                          title={this.props.intl.formatMessage({ id: "courses" })}
                          width={'50rem'}
                          footer={null}
                          closable={false}
                          visible={this.isCourseModalVisible}>
                          <div>
                            <CoursePickerTable
                              items={this.pickerCoursesDataItems}
                              intl={this.props.intl}
                              onOkClick={this.onCourseModalOkClick}
                              onCancelClick={this.onCourseModalCancelClick}
                              paginationPosition={"bottom"}
                              tableProps={{ rowKey: "id" }}
                              selectRowType={SelectRowType.SINGLE} />
                          </div>
                        </Modal>

                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'courseName']}
                        trigger={'click'}
                        title={this.props.intl.formatMessage({ id: "courseTextHint" })} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("courseName",
                          {
                            rules: [{
                              required: this.isCourseNameRequred,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'courseName'] })
                            }],
                            initialValue: this.dataInstance.item ? this.dataInstance.item.courseName : undefined
                          })(
                            <Input onChange={value => {
                              this.isCourseRequred = !(value.target.value && value.target.value !== '');
                            }} disabled={(this.dataInstance.item && this.dataInstance.item.course === 'undefined') || this.props.form.getFieldValue('course')} />
                          )}
                      </div>
                    </div>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'learningType']}
                        trigger={'click'}
                        title={messages[LearningBudgetRequest.NAME + '.' + 'learningType']} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("learningType",
                          {
                            rules: [{
                              required: true,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'learningType'] })
                            }],
                            initialValue: learningTypeOption ? learningTypeOption.value : undefined
                          })(
                            <Select showSearch
                              disabled={(this.dataInstance.item && this.dataInstance.item.course === 'undefined') || this.props.form.getFieldValue('course')}
                              value={learningTypeOption ? learningTypeOption.value : undefined}>
                              {this.learningTypesDc && this.learningTypesDc.items.map(value =>
                                <Select.Option key={value.id}>
                                  {value._instanceName}
                                </Select.Option>)}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'typeOfTraining']}
                        trigger={'click'}
                        title={messages[LearningBudgetRequest.NAME + '.' + 'typeOfTraining']} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("typeOfTraining",
                          {
                            rules: [{
                              required: true,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'typeOfTraining'] })
                            }],
                            initialValue: typeOFTrainingOption ? typeOFTrainingOption.value : undefined
                          })(
                            <Select showSearch
                              disabled={(this.dataInstance.item && this.dataInstance.item.course === 'undefined') || this.props.form.getFieldValue('course')}
                              value={typeOFTrainingOption ? typeOFTrainingOption.value : undefined}>
                              {this.typeOfTrainingDc && this.typeOfTrainingDc.items.map(value =>
                                <Select.Option key={value.id}>
                                  {value._instanceName}
                                </Select.Option>)}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'reportGroup']}
                        trigger={'click'}
                        title={messages[LearningBudgetRequest.NAME + '.' + 'reportGroup']} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("reportGroup",
                          {
                            rules: [
                              {
                                required: true,
                                message: this.props.intl.messages['reportGroup.required']
                              }
                            ],
                            initialValue: this.reportGroup ? this.reportGroup.id : undefined
                          })(
                            <Select showSearch
                              value={this.reportGroup ? this.reportGroup.id : undefined}>
                              {this.reportGroupDc && this.reportGroupDc.items.map(value =>
                                <Select.Option key={value.id}>
                                  {value._instanceName}
                                </Select.Option>)}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'city']} trigger={'click'}
                        title={messages[LearningBudgetRequest.NAME + '.' + 'city']} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("city",
                          {
                            rules: [{
                              required: true,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'city'] })
                            }],
                            initialValue: this.isCourseOnline ? 'ONLINE' : this.dataInstance.item ? this.dataInstance.item.city : undefined
                          })(
                            <Input disabled={this.isCourseOnline} />
                          )}
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'region']} trigger={'click'}
                        title={messages[LearningBudgetRequest.NAME + '.' + 'region']} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("region",
                          {
                            rules: [{
                              required: true,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'region'] })
                            }],
                            initialValue: regionOption ? regionOption.value : undefined
                          })(
                            <Select showSearch
                              onChange={this.onRegionChange}>
                              {this.regionsDc && this.regionsDc.items.map(value =>
                                <Select.Option key={value.id}>
                                  {value._instanceName}</Select.Option>)}
                            </Select>
                          )}
                      </div>
                    </div>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'month']} trigger={'click'}
                        title={messages[LearningBudgetRequest.NAME + '.' + 'month']} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("month",
                          {
                            rules: [{
                              required: true,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.mainStore!.messages![LearningBudgetRequest.NAME + '.month'] })
                            }],
                            initialValue: this.dataInstance.item ? moment(this.dataInstance.item.month) : undefined
                          })(
                            <DatePicker.MonthPicker style={{ width: '100%' }} />
                          )}
                      </div>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={messages[LearningBudgetRequest.NAME + '.' + 'dayCount']}
                        trigger={'click'}
                        title={messages[LearningBudgetRequest.NAME + '.' + 'dayCount']} />
                      <div className={'budget-request-form-input'}>
                        {this.props.form.getFieldDecorator("dayCount",
                          {
                            initialValue: this.dataInstance.item ? this.dataInstance.item.dayCount || this.daysCount.value : 1,
                            rules: [
                              {
                                transform: (value: string) => parseInt(value),
                                min: 1,
                                type: 'integer',
                                message: this.props.intl.messages['daysCount.invalid']
                              },
                              {
                                required: true,
                                message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: messages[LearningBudgetRequest.NAME + '.' + 'dayCount'] })
                              }
                            ]
                          })(
                            <Input onChange={(ev) => this.daysCount.value = parseInt(ev.target.value) || 1} />
                          )}
                      </div>
                    </div>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item>
                    <div className="budget-request-form">
                      <LabelTooltip labelText={this.props.intl.formatMessage({ id: "reasonCaption" })} trigger={'click'}
                        title={this.props.intl.formatMessage({ id: "reasonCaption" })}
                        containerStyle={{ flex: 2 }} />
                      <div className={'budget-request-form-input'} style={{ flex: 5 }}>
                        {this.props.form.getFieldDecorator("reason",
                          {
                            rules: [{
                              required: true,
                              message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.intl.formatMessage({ id: "reasonCaption" }) })
                            }],
                            initialValue: this.dataInstance.item ? this.dataInstance.item.reason : undefined
                          })(
                            <TextArea rows={5} style={{ width: "100%" }} />
                          )}
                      </div>
                    </div>
                  </Form.Item>
                </Row>
              </Form>
            </fieldset>
            <CostsComponent assignmentsList={this.selectedEmployees}
              learningBudget={this.learningBudget} employeeCount={this.employeeCount}
              form={this.props.form} perdiemsSettings={this.perdiemsSettings} intl={this.props.intl}
              daysCount={this.daysCount} costs={this.costDataInstance.item} />
          </div>
        </Card>
      </Page>
    );
  }

  componentDidMount() {
    getCubaREST()!.loadEntity(LearningBudget.NAME, this.props.budgetId, { view: 'learningBudget-front-view' })
      .then(value => {
        this.learningBudget = value as LearningBudget;
        if (!this.isNewEntity()) {
          this.dataInstance.load(this.props.entityId);
        } else {
          const learningBudgetRequest = new LearningBudgetRequest();
          learningBudgetRequest.budget = this.learningBudget;
          this.dataInstance.setItem(learningBudgetRequest);
        }

        this.reactionDisposers.push(
          reaction(
            () => this.dataInstance.status === 'DONE',
            (isDataLoaded, permsReaction) => {
              if (isDataLoaded === true) {
                getCubaREST()!.loadEntity(PersonGroupExt.NAME, this.props.rootStore!.userInfo!.personGroupId!, { view: '_minimal' })
                  .then(value => {
                    this.currentUser = value;
                    if (this.dataInstance.item) {
                      this.daysCount.value = this.dataInstance.item.dayCount ? this.dataInstance.item.dayCount : 1;
                      this.dataInstance.item.responsiblePerson = this.currentUser;
                      this.selectedOrganization = this.props.rootStore!.learningBudgetInfo.selectedOrganization;
                      if (this.selectedOrganization) {
                        getCubaREST()!.loadEntity(OrganizationExt.NAME, this.selectedOrganization.organization!.id, { view: 'organization-for-budget-request-view' })
                          .then(val => {
                            this.selectedBaseOrganization = val;
                          })
                      }
                      if (!this.dataInstance.item.reportGroup && this.selectedOrganization) {
                        restServices.learningBudgetService.findReportGroupByOrganizationGroupId({ organizationGroupId: this.selectedOrganization.id })
                          .then(val => {
                            getCubaREST()!.loadEntity(OrganizationGroupExt.NAME, val, { view: '_minimal' })
                              .then(reportGroupRes => {
                                this.reportGroup = reportGroupRes;
                              })

                          })
                      } else if (this.dataInstance.item.reportGroup) {
                        this.reportGroup = this.dataInstance.item.reportGroup as SerializedEntity<OrganizationGroupExt>;
                      }
                      this.learningBudgetRequestHeader = this.props.rootStore!.learningBudgetInfo.currentLearningBudgetRequestHeader;
                      this.organizationGroupList = Array.of(this.selectedOrganization);
                      this.dataInstance.item.organizationGroup = this.selectedOrganization;
                      if (this.dataInstance.item.cost) {
                        this.costDataInstance.load(this.dataInstance.item.cost.id);
                      } else {
                        const learningBudgetRequestCost = new LearningBudgetRequestCost();
                        this.dataInstance.item.cost = learningBudgetRequestCost;
                        this.costDataInstance.setItem(learningBudgetRequestCost);
                      }
                      this.props.form.setFieldsValue({
                        responsiblePerson: this.currentUser,
                        organizationGroup: this.selectedOrganization
                      });

                      restServices.assignmentService.getAssignmentsByListOfOrganization({ organizationGroupExtList: this.organizationGroupList })
                        .then(values => {
                          this.assignmentsList = values.sort((a, b) => {
                            if (!a.personGroup)
                              return -1;
                            else if (!b.personGroup)
                              return 1;
                            else
                              return (a.personGroup as SerializedEntity<PersonGroupExt>)._instanceName.localeCompare((b.personGroup as SerializedEntity<PersonGroupExt>)._instanceName)
                          })
                        });
                    }
                  });
                permsReaction.dispose();
              }
            },
            { fireImmediately: true }
          )
        );
      });

  }

  componentWillUnmount() {
    this.reactionDisposers.forEach(dispose => dispose());
  }


}

export default injectIntl(
  withLocalizedForm
    <EditorProps>({
      onValuesChange: (props: any, changedValues: any) => {
        // Reset server-side errors when field is edited
        Object.keys(changedValues).forEach((fieldName: string) => {
          props.form.setFields({
            [fieldName]: {
              value: changedValues[fieldName]
            }
          });
        });
      }
    })(withRouter(LearningBudgetRequestEdit)));

