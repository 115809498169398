import React from "react";
import {collection, getEnumCaption, getPropertyInfoNN, injectMainStore, MainStoreInjected,} from "@cuba-platform/react";
import {CourseScheduleEnrollment} from "../../../cuba/entities/base/tsadv_CourseScheduleEnrollment";
import {inject, observer} from "mobx-react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Button, Spin, Table, Typography} from "antd";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {RouteComponentProps} from "react-router";
import {formatDate, JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import {LearningRequest} from "../../../cuba/entities/base/tsadv_LearningRequest";
import {Link, withRouter} from "react-router-dom";
import DataTableFormat from "../../components/DataTable/intex";
import {LearningRequestManagement} from "../LearningRequest/LearningRequestManagement";
import moment from "moment";

type Props = RouteComponentProps<{ entityId?: string }>;

@injectMainStore
@inject("rootStore")
@observer
class CourseSchedueListComponent extends React.Component<Props & MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  dataCollection = collection<CourseScheduleEnrollment>(CourseScheduleEnrollment.NAME, {
    view: "CourseScheduleEnrollment-view",
    filter: {
      conditions: [
        {
          property: "personGroup.id",
          operator: "=",
          value: this.props.rootStore!.userInfo.personGroupId!
        },
        {
          property: "courseSchedule.endDate",
          operator: ">=",
          value: moment().format(JSON_DATE_TIME_FORMAT)
        }
      ]
    }
  });

  learningRequestCollection = collection<LearningRequest>(LearningRequest.NAME, {
    view: "portal.learningRequest-edit",
    sort: "-requestNumber",
    filter: {
      conditions: [{
        property: "personGroup.id",
        operator: "=",
        value: this.props.rootStore!.userInfo.personGroupId!
      }
      ]
    }
  });

  render() {

    let Text = Typography.Text
    return (

      <Page pageName={this.props.intl.formatMessage({id: "menu.trainingPlan"})}>
        <Section size="large">
          <Spin key={'internalTraining'} spinning={this.dataCollection.status === 'LOADING'}>
            {/*<h2><Text style={{color: "LightSkyBlue"}}>{this.props.intl.formatMessage({id: "internalTraining"})}</Text>*/}
            {/*</h2>*/}
            <Table
              key={'dataCollection'}
              columns={[
                {
                  title: this.props.intl.formatMessage({id: "courseScheduleEnrollment.course"}),
                  key: "CourseScheduleEnrollmentName",
                  render: (text, record: CourseScheduleEnrollment) =>
                    record.courseSchedule!.course!.name
                },
                {
                  title: this.props.intl.formatMessage({id: "courseScheduleEnrollment.isOnline"}),
                  key: "CourseScheduleEnrollmentFormat",
                  render: ((text, record: CourseScheduleEnrollment) => record.courseSchedule && record.courseSchedule.course!.isOnline ? "Онлайн" : "Очно")
                },
                {
                  title: this.props.intl.formatMessage({id: "courseScheduleEnrollment.address"}),
                  key: "CourseScheduleEnrollmentAddress",
                  render: (text, record: CourseScheduleEnrollment) => record.courseSchedule!.address
                },
                {
                  title: this.props.intl.formatMessage({id: "courseScheduleEnrollment.trainer"}),
                  render: (text, record: CourseScheduleEnrollment) => record.courseSchedule&&record.courseSchedule.trainer&&record.courseSchedule.trainer['_instanceName']
                },
                {
                  title: this.props.intl.formatMessage({id: "courseScheduleEnrollment.startDate"}),
                  render: (text, record: CourseScheduleEnrollment) => formatDate(record.courseSchedule!.startDate)
                },
                {
                  title: this.props.intl.formatMessage({id: "courseScheduleEnrollment.endDate"}),
                  render: (text, record: CourseScheduleEnrollment) => formatDate(record.courseSchedule!.endDate)
                },
                {
                  title: this.props.intl.formatMessage({id: "courseScheduleEnrollment.status"}),
                  dataIndex: "CourseScheduleEnrollmentStatus",
                  key: "CourseScheduleEnrollmentStatus",
                  render: (text, record: CourseScheduleEnrollment) => getEnumCaption(record.status, getPropertyInfoNN("status", CourseScheduleEnrollment.NAME, this.props.mainStore!.metadata!), this.props.mainStore!.enums!)
                },
              ]}

              dataSource={this.dataCollection.items.slice()}
              rowKey={record => record.id}

            />
          </Spin>
        </Section>

        {/*<Section size="large">*/}
        {/*  <Spin key={'externalTraining'} spinning={this.learningRequestCollection.status === 'LOADING'}>*/}
        {/*    <h2><Text style={{color: "LightSkyBlue"}}>{this.props.intl.formatMessage({id: "externalTraining"})}</Text>*/}
        {/*    </h2>*/}
        {/*    <DataTableFormat dataCollection={this.learningRequestCollection}*/}
        {/*                     enableFiltersOnColumns={[]}*/}
        {/*                     hideSelectionColumn*/}
        {/*                     key={'learningRequestCollection'}*/}
        {/*                     buttons={[<Link key={'create.learningRequest.link'}*/}
        {/*                                     to={`${LearningRequestManagement.PATH}/new`}>*/}
        {/*                       <Button*/}
        {/*                         id={'create.learningRequest.btn'}>{this.props.intl.formatMessage({id: "create.learningRequest.btn"})}*/}
        {/*                       </Button>*/}
        {/*                     </Link>]}*/}
        {/*                     fields={['requestNumber', 'courseName', 'typeOfTraining','instruction','dateFrom' ,'dateTo','status']}*/}
        {/*                     render={[{*/}
        {/*                       column: 'requestNumber',*/}
        {/*                       render: (text, record) => <Link*/}
        {/*                         key={record.id + 'link'}*/}
        {/*                         to={LearningRequestManagement.PATH + '/' + record.id}>{text}</Link>*/}
        {/*                     }]}*/}
        {/*    />*/}
        {/*  </Spin>*/}
        {/*</Section>*/}
      </Page>
    );
  }
}

export const CourseScheduleEnrollment_PATH = "/trainingPlan"

const CourseScheduleListComponent = withRouter(injectIntl(CourseSchedueListComponent));

export default CourseScheduleListComponent;