import {Col, Form, Row, Select} from "antd";
import {SearchSelect} from "../../../components/SearchSelect";
import React from "react";
import {action, observable, runInAction} from "mobx";
import {restServices} from "../../../../cuba/services";
import {PersonGroupExt} from "../../../../cuba/entities/base/base$PersonGroupExt";
import {observer} from "mobx-react";
import {Input} from "antd/es";
import {injectIntl, WrappedComponentProps} from "react-intl";

type PersonFinderProps = {
  onChange?: (value: string) => void;
}


@observer
class PersonFinder extends React.Component<PersonFinderProps & WrappedComponentProps> {

  @observable isLoading: boolean = false;
  @observable personSearchResult: PersonGroupExt[] = [];
  @observable selectedPerson: PersonGroupExt | null = null;
  @observable selectedDepartment: string | null = null;
  @observable selectedPosition: string | null = null;

  @action onSearch = async (value: string) => {
    if (value.length < 3) return;

    try {
      this.isLoading = true;
      this.personSearchResult = await restServices.recognitionService.findPersonGroupByName(value);
    } finally {
      this.isLoading = false;
    }
  }

  async onSelectPerson(value: string) {
    runInAction((() => this.selectedPerson = this.personSearchResult.find((person) => person.id === value) || null));
    const user = await restServices.employeeService.getUserExtByPersonGroupId(this.selectedPerson!.id);

    this.props.onChange!(this.selectedPerson!.id)

    const department = await restServices.positionService.getPosition(user.id, null);

    const orgGroup = await restServices.employeeService.getOrganizationGroupByPersonGroupId(this.selectedPerson!.id, "_local");

    runInAction(() => {
      this.selectedPosition = department.positionFullName || null;
      this.selectedDepartment = orgGroup.organizationName || null;
    })
  }

  render() {
    return <>
      <SearchSelect showSearch onSearch={this.onSearch} loading={this.isLoading}
                     onSelect={(value: string) => this.onSelectPerson(value as string)}
                    options={this.personSearchResult.map((person) => (
                      <Select.Option key={person.id} value={person.id}>{person.fullName}</Select.Option>))}
      />
      <Row gutter={50} hidden={this.selectedDepartment === null && this.selectedPosition === null}>
        <Col span={12}>
          <Form.Item label={this.props.intl.messages["recognition.personFinder.department"]}>
            <Input readOnly value={this.selectedDepartment!}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={this.props.intl.messages["recognition.personFinder.position"]}>
            <Input readOnly value={this.selectedPosition!}/>
          </Form.Item>
        </Col>
      </Row>
    </>
  }

}

export default injectIntl(PersonFinder);