import * as React from "react";
import { FormEvent } from "react";
import { Card, message } from "antd";
import { inject, observer } from "mobx-react";
import { DocumentsFamiliarizationManagement } from "./DocumentsFamiliarizationManagement";
import { FormComponentProps } from "antd/lib/form";
import { Redirect } from "react-router-dom";
import { action, observable } from "mobx";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

import { getCubaREST, injectMainStore, MainStoreInjected, withLocalizedForm } from "@cuba-platform/react";

import "../../../app/App.css";
import { DocumentsFamiliarization } from "../../../cuba/entities/base/DocumentsFamiliarization";
import Button, { ButtonType } from "../../components/Button/Button";
import { downloadFile, getLocaleName } from "../../util/util";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import { FileDescriptor } from "../../../cuba/entities/base/sys$FileDescriptor";
import { ListOfAcknowledgement } from "../../../cuba/entities/base/ListOfAcknowledgement";
import { RootStoreProp } from "../../store";
import { instanceStore } from "../../util/InstanceStore";
import Notification from "../../util/Notification/Notification";


type Props = FormComponentProps & EditorProps;

type EditorProps = {
  entityId: string;
};

@injectMainStore
@inject("rootStore")
@observer
class DocumentsFamiliarizationEditComponent extends React.Component<Props & WrappedComponentProps & RootStoreProp & MainStoreInjected> {

  dataInstance = instanceStore<DocumentsFamiliarization>(
    DocumentsFamiliarization.NAME,
    {view: "documentsFamiliarization.edit", loadImmediately: false}
  );

  @observable
  performingFinishRequest = false;

  @observable
  updated = false;

  @observable
  showSignButton = false;

  @observable
  globalErrors: string[] = [];

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        return;
      }
    });
  };

  render() {
    if (this.updated) {
      return <Redirect to={DocumentsFamiliarizationManagement.PATH}/>;
    }

    const actions = [];
    if (this.showSignButton) {
      actions.push(<Button buttonType={ButtonType.FOLLOW} onClick={this.signDocument}
                           loading={this.performingFinishRequest}>
        <FormattedMessage id="confirmFamiliarization"/>
      </Button>)
    }

    const {status} = this.dataInstance;

    return (
      <Page pageName={this.dataInstance.item ? this.dataInstance.item.name : ""}>
        <Card className="narrow-layout card-actions-container"
              bordered={false}
              actions={actions}>
          <Section visible={false} size="large">
            {this.dataInstance.item
              ? <>
                <div>{this.dataInstance.item['description' + getLocaleName(this.props.mainStore!.locale!)]}</div>
                <div style={{
                  fontSize: '18px',
                  fontWeight: 600,
                  marginTop: '20px'
                }}>
                  <a onClick={() => {
                    downloadFile((this.dataInstance.item!.file as FileDescriptor).id,
                      (this.dataInstance.item!.file as FileDescriptor).name as string,
                      (this.dataInstance.item!.file as FileDescriptor).extension as string,
                      this.props.intl.formatMessage({id: "file.download.error"}));
                  }}>
                    {`${(this.dataInstance.item!.file as FileDescriptor).name}`}
                  </a>
                </div>
              </>
              : null}
          </Section>
        </Card>
      </Page>
    );
  }

  signDocument = () => {
    getCubaREST()!.commitEntity(ListOfAcknowledgement.NAME, ({
      user: {
        id: this.props.rootStore!.userInfo.id
      },
      documentFamiliarization: {
        id: this.props.entityId
      }, acknowledgement: true
    } as ListOfAcknowledgement)).then(() => {
      Notification.success({
        message: this.props.intl.formatMessage({id: "documentsFamiliarization.successSign"})
      })
      this.showSignButton = false;
    });
  };

  componentDidMount() {
    this.searchSignedDocument();
    this.dataInstance.load(this.props.entityId);
  }

  searchSignedDocument = () => {
    getCubaREST()!.searchEntities<ListOfAcknowledgement>(ListOfAcknowledgement.NAME, {
      conditions: [
        {
          property: "user.id",
          operator: "=",
          value: this.props.rootStore!.userInfo.id!
        }, {
          property: "documentFamiliarization.id",
          operator: "=",
          value: this.props.entityId
        }, {
          property: "acknowledgement",
          operator: "=",
          value: "true"
        }]
    }).then(response => {
      if (response.length == 0) {
        this.showSignButton = true;
      }
    })
  }

  @action setPerformingFinishRequest = (value: boolean) => {
    this.performingFinishRequest = value;
  };
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(DocumentsFamiliarizationEditComponent)
);
