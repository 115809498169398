import React, { Component } from 'react';
import TimelineItem, { FeedItemModel } from "./TimelineItem";
import { restServices } from "../../../cuba/services";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { getCubaREST, injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import { RootStoreProp } from "../../store";
import { Timeline } from "../../../cuba/entities/base/Timeline";
import { EntitiesWithCount, SerializedEntity } from "@cuba-platform/rest";
import { getBlobUrl } from "../../util/util";
import { Button } from "antd";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS, format } from "../../util/Date/Date";

@inject("rootStore")
@injectMainStore
@observer
class TimelineList extends Component<RootStoreProp & MainStoreInjected & WrappedComponentProps> {
  pageSize: number = 2;

  @observable offset: number = 0;
  @observable maxPage = 0;

  @observable feeds: FeedItemModel[] = [];

  render() {
    return (
      <div>
        <div className="news-block">
          {this.feeds.map(feed => <TimelineItem id={feed.id} date={feed.date} img={feed.img} text={feed.text}
                                                url={feed.url}
                                                title={feed.title}/>)}
        </div>
        <div className="centered-flex-container">
          {this.offset < (this.maxPage - this.pageSize)
            ? <Button type={"link"} onClick={this.incrementPage.bind(null)}>
            <span
              style={{textDecoration: "underline"}}> {this.props.intl.formatMessage({id: "homePage.showMore"})}</span>
            </Button>
            : null}
        </div>
        <div>
        </div>
      </div>

    );
  }

  fetchNews = () => {
    restServices.portalHelperService.companiesForLoadDictionary({personGroupId: this.props.rootStore!.userInfo!.personGroupId!})
      .then(companies => {
        getCubaREST()!.searchEntitiesWithCount(Timeline.NAME, {
          conditions: [
            {
              property: "company.id",
              operator: "in",
              value: companies
            }, {
              property: "isPublished",
              operator: "=",
              value: "true"
            }
          ]
        }, {
          view: "timeline.edit",
          limit: this.pageSize,
          offset: this.offset,
          sort:"-createTs"
        }).then((timelinesWithCount: EntitiesWithCount<SerializedEntity<Timeline>>) => {
          this.maxPage = timelinesWithCount.count;
          const feeds = timelinesWithCount.result.map(tl => {
            const language = this.props.mainStore!.locale!.charAt(0).toUpperCase() + this.props.mainStore!.locale!.substr(1);
            return {
              id: tl.id,
              title: tl["newsTopic" + language],
              text: tl["newsText" + language],
              date: format(tl.dateTo, DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS),
              img: tl["image" + language] ? getBlobUrl(tl["image" + language].id) : undefined,
              url: tl["url" + language]
            }
          });
          this.setFeeds([...this.feeds, ...feeds])
        });
      });
  }

  @action
  setFeeds = (feeds: FeedItemModel[]) => {
    this.feeds = feeds;
  }

  @action
  incrementPage = () => {
    this.offset += this.pageSize;
    this.fetchNews()
  }

  componentDidMount(): void {
    this.fetchNews();
  }
}

export default  injectIntl(TimelineList);