import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import {Route, Switch} from "react-router-dom";
import CartPage from "./CartPage";
import ItemsPage from "./ItemsPage";
import RecognitionTopBar from "../Components/RecognitionTopBar";
import OrdersPage from "./OrdersPage";

@inject("rootStore")
@injectMainStore
@observer
class ShopPage extends React.Component<RootStoreProp & WrappedComponentProps> {

  render() {
    const href = window.location.href;

    return <>
      <RecognitionTopBar href={href}/>

      <Switch>
        <Route exact={true} path="/recognition/store/items" component={ItemsPage} />
        <Route exact={true} path="/recognition/store/orders" component={OrdersPage} />
        <Route exact={true} path="/recognition/store/cart" component={CartPage} />
      </Switch>
    </>
  }
}

export default injectIntl(ShopPage);