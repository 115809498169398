import * as React from "react"
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import DataTableFormat from "../../components/DataTable/intex";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {Link} from "react-router-dom";
import {BaseUuidEntity} from "../../../cuba/entities/base/sys$BaseUuidEntity";
import {Button, Table} from "antd";
import {rootStore} from "../../store";
import {inject, observer} from "mobx-react";
import {IReactionDisposer, observable} from "mobx";
import {restServices} from "../../../cuba/services";
import {PersonMedalRequest} from "../../../cuba/entities/base/tsadv_PersonMedalRequest";
import {SerializedEntity} from "@cuba-platform/rest";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import PersonMedalRequestManagement from "../Recognition/PersonMedal/PersonMedalRequestManagement";
import {formatDate} from "../../util/Date/Date";
import {ColumnProps} from "antd/es/table";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";

type Props = {
  data?: PersonMedalRequest[],
  personGroupId: string
} & WrappedComponentProps & MainStoreInjected & RootStoreProp

type TableData = {
  id: string
  order: number
  date: number
  employeeName: string
  category: string,
  status: string
  link: string
}
@inject("rootStore")
@injectMainStore
@observer
class EmployeeRecognitionList extends React.Component<WrappedComponentProps&Props& MainStoreInjected& RootStoreProp> {
  dataWatcher: IReactionDisposer
  messages = this.props.intl.messages;
  @observable data?: TableData[] = []
  @observable selectedRow: TableData | undefined;

  constructor(props: Props) {
    super(props);
    restServices.recognitionPortalService.loadUserNominations(this.props.rootStore!.userInfo.personGroupId!).then(value => {
      this.data = this.mapData(value).sort((a, b) => {
        if (a.order > b.order) {
          return -1;
        }
        if (a.order < b.order) {
          return 1;
        }
        return 0;
      })
    })
  }

  mapData = (data: PersonMedalRequest[]): TableData[] => {
    return data.map(value => ({
        id: value.id,
        order: value.requestNumber,
        date: value.requestDate,
        employeeName: value.personGroup ? value.personGroup.map(it => (it as SerializedEntity<PersonGroupExt>)._instanceName).join(', ') : '',
        category: value.awardType ? value.awardType.langValue : '',
        status: value.status ? value.status.langValue : '',
        link: PersonMedalRequestManagement.PATH + "/" + value.id,
      } as TableData)
    )
  }

  columns = [
    {
      key: 'order',
      dataIndex: 'order',
      title: this.messages['medalTab.tabs.nominations.table.order'],
      render: (text: any, record: TableData) => {
        const link = record.link
        return (
          <Link
            to={
              link
            }
            key="edit">
            <a
              key={"editPersonMedalRequest"}>
              {record.order}
            </a>
          </Link>
        )
      }
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: this.messages['medalTab.tabs.nominations.table.date'],
      render: (text, record) => {
        const date = record.date
        return (
          <label>
            {formatDate(date, 'DD.MM.YYYY')}
          </label>
        )
      }

    } as ColumnProps<TableData>,
    {
      key: 'employeeName',
      dataIndex: 'employeeName',
      title: this.messages['medalTab.tabs.nominations.table.employeeName'],

    },
    {
      key: 'category',
      dataIndex: 'category',
      title: this.messages['medalTab.tabs.nominations.table.category'],

    },
    {
      key: 'status',
      dataIndex: 'status',
      title: this.messages['medalTab.tabs.nominations.table.status'],

    },
  ]

  onRowSelect = (record: TableData) => {
    this.selectedRow = record;
  }

  render() {
    return (
      <Page>
        <Section size={"large"}>
          <div style={{ margin: '2%' }}>
            <div className={'nominations-layout'}>
              <Link
                to={
                  PersonMedalRequestManagement.PATH + "/" + PersonMedalRequestManagement.NEW_SUBPATH
                }
                key="create">
                <Button type={'primary'} style={{ width: '20%' }}>
                  {this.messages['medalTab.tabs.nominations.nominate']}
                </Button>
              </Link>
              <Table dataSource={this.data} columns={this.columns} rowKey={record => record.id} onRowClick={this.onRowSelect}>

              </Table>
            </div>
          </div>
        </Section>
      </Page>
    )
  }

}
export  default injectIntl(EmployeeRecognitionList);
