import * as React from "react";
import {observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";
import {observable} from "mobx";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {AddressRequestManagement} from "../AddressRequest/AddressRequestManagement";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import DataTableFormat from "../../components/DataTable/intex";
import {withRouter} from "react-router";
import Button, {ButtonType} from "../../components/Button/Button";
import {PersonAwardsDegrees} from "../../../cuba/entities/base/tsadv_PersonAwardsDegrees";
import {PersonAwardsDegreesRequestManagement} from "../PersonAwardsDegreesRequest/PersonAwardsDegreesRequestManagement";
import {PersonAwardsDegreesRequest} from "../../../cuba/entities/base/tsadv_PersonAwardsDegreesRequest";

export type PersonAwardsDegreesListProps = {
  personGroupId: string
}

@injectMainStore
@observer
class PersonAwardsDegreesListComponent extends React.Component<PersonAwardsDegreesListProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps> {
  dataCollection = collection<PersonAwardsDegrees>(PersonAwardsDegrees.NAME, {
    view: "personAwardsDegrees.edit",
    sort: "-updateTs",
    filter: {
      conditions: [{
        property: 'employee.id',
        operator: '=',
        value: this.props.personGroupId
      }]
    }
  });

  fields = [
    "type",

    "kind",

    "startDate",

    "endDate",

    "description"
  ];

  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
      <Link
        to={
          PersonAwardsDegreesRequestManagement.PATH +
          "/" +
          PersonAwardsDegreesRequestManagement.NEW_SUBPATH
        }
        key="create"
      >
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}
                key="createBtn"
        >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>

    ];

    return (
      <DataTableFormat
        dataCollection={this.dataCollection}
        enableFiltersOnColumns={[]}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  openRequest = () => {
    this.getRequestId()
      .then(value => this.props.history!.push(PersonAwardsDegreesRequestManagement.PATH + '/' + value));
  }

  getRequestId = (): Promise<string> => {
    if (!this.selectedRowKey) return new Promise<string>(resolve => resolve(PersonAwardsDegreesRequestManagement.NEW_SUBPATH));
    return getCubaREST()!.searchEntities<PersonAwardsDegreesRequest>(PersonAwardsDegreesRequest.NAME, {
      conditions: [{
        property: 'baseAddress.id',
        operator: '=',
        value: this.selectedRowKey!
      }, {
        property: 'status.code',
        operator: 'in',
        value: ['DRAFT', 'TO_BE_REVISED', 'APPROVING']
      }]
    }, {
      view: 'personAwardsDegreesRequest.edit'
    }).then(values => {
      if (!values || values.length === 0) {
        return AddressRequestManagement.NEW_SUBPATH + '/' + this.selectedRowKey!;
      } else {
        const approvingRequest = values.find(value => value!.status!.code === 'APPROVING');
        return approvingRequest ? approvingRequest.id : values[0].id;
      }
    });
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const PersonAwardsDegreesList = injectIntl(withRouter(PersonAwardsDegreesListComponent));

export default PersonAwardsDegreesList;
