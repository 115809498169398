import {DatePicker, Divider, Input, Select, Button as AntdButton} from "antd";
import Button, {ButtonType} from "../../components/Button/Button";
import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {observer} from "mobx-react";

interface IProps {
  confirm: any,
  selectedKeys: any,
  setSelectedKeys: any,
  clearFilters: any,
  text: string,
  type: string,
  date: boolean,
  selectItems?: any,
  emptyFilters:any,
  code?:true
}

@injectMainStore
@observer
class SearchFilterDropdown extends React.Component<MainStoreInjected & WrappedComponentProps & IProps> {
  render() {
    let {confirm, selectedKeys, setSelectedKeys, emptyFilters, clearFilters, text, type, code} = this.props;
    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: 12}}>
                      <span style={{
                        marginRight: 16,
                        minInlineSize: "fit-content"
                      }}>{this.props.intl.formatMessage({id: text})}
                      </span>{type === "has" ? <span
            style={{marginRight: 16}}>{this.props.intl.formatMessage({id: "cubaReact.dataTable.operator.contains"})}</span> :
          <span style={{marginRight: 16}}>=</span>}
          {this.props.date ? <DatePicker onChange={(e, value) => {
            setSelectedKeys!(value ? [value] : [])
          }}/> : !this.props.selectItems ?
            <Input type={text==="cba.FormTemplateName"||text==="employee"||text === "export.person" || text === "export.comment" || text==="historyProcess"? "text" : "number"}
                   style={{marginRight: 16}}
                   value={selectedKeys && selectedKeys[0]}
                   onChange={(e) => {
                     setSelectedKeys!(e.target.value ? [e.target.value] : []);
                   }
                   }
                   onBlur={() => {
                     confirm!();
                   }
                   }
                   autoFocus
                   onPressEnter={confirm!}
            /> :
            <Select dropdownStyle={{minWidth: "20%", overflowX: "hidden"}} onChange={(value) => {
              setSelectedKeys!(value ? [value] : [])
            }}>
              {this.props.selectItems && this.props.selectItems.map((item: any) => {
                return <Select.Option value={code?item.code:item.id}>{item._instanceName}</Select.Option>
              })}
            </Select>
          }</div>
        <Divider style={{margin: 0}}></Divider>
        <div style={{display: "flex", justifyContent: "flex-start"}}>
          <AntdButton type={"link"}
                      onClick={() => {
                        confirm!();
                      }}
          >
            {this.props.intl.formatMessage({id: "search"})}
          </AntdButton>
          <AntdButton type={"link"}
                      onClick={() => {
                        console.log(clearFilters)
                        clearFilters();
                        emptyFilters(text.match(/^(?!.*(red|green|blue))/)![0]);
                      }}
          >
            {this.props.intl.formatMessage({id: "cubaReact.dataTable.reset"})}
          </AntdButton>
        </div>
      </div>
    );
  }
}

export default injectIntl(SearchFilterDropdown)
