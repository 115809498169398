import React, { Component } from 'react';
import Img from "../../components/Img";
import { Row } from "antd";
import {RouteComponentProps, withRouter} from "react-router";

export type FeedItemModel = {
  id: string,
  url: string,
  img?: Promise<string>,
  date: string
  title: string,
  text: string
}

export class TimeLineItemFull extends Component<RouteComponentProps&FeedItemModel> {
 static PATH="/news"
  render() {
    const {id, title, text, img, url} = this.props.history.location.state as any;
    return (
      <>
      <Row key={id}>
          <Img className="news-image" type="promise" style={{margin:25}} src={img}/>
      </Row>
      <Row>
          <span>
            <h2 style={{marginLeft:25}}><a className="underline" href={url}>{title}</a></h2>
        </span>
      </Row>
      <Row>
      <div style={{marginLeft:25}} dangerouslySetInnerHTML={{__html:text}}/>
    </Row>
    </>);
  }
}

export default withRouter(TimeLineItemFull);