import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import AddressRequestEdit from "./MilitaryFormRequestEdit";

type Props = RouteComponentProps<{ entityId: string, militaryFormId?: string }>;

@observer
export class MilitaryFormRequestManagement extends React.Component<Props> {
  static PATH = "/militaryFormRequest";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId, militaryFormId} = this.props.match.params;
    return (
      <>
        <AddressRequestEdit entityId={entityId} militaryFormId={militaryFormId}/>
      </>
    );
  }
}
