import * as React from "react";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {Button, Col, Input, Modal, Popover, Row, Select} from "antd";
import "./style.css"
import {ReactComponent as ArrowIcon} from "../../../resources/icons/arrow.svg";
import {action, observable} from "mobx";
import {collection, injectMainStore, MainStoreInjected, withLocalizedForm} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {OrganizationGroupExt} from "../../../cuba/entities/base/base$OrganizationGroupExt";
import {restServices} from "../../../cuba/services";
import {RootStoreProp} from "../../store";
import {AssessmentTemplate} from "../../../cuba/entities/base/tsadv$AssessmentTemplate";
import {AssessmentParticipant} from "../../../cuba/entities/base/tsadv$AssessmentParticipant";
import LoadingPage from "../LoadingPage";
import {runReport} from "../../util/reportUtil";
import {getPersonByPersonGroup} from "../../util/personUtils";
import NineBoxItem from "./NineBoxItem";
import {PerformancePlan} from "../../../cuba/entities/base/tsadv$PerformancePlan";
import Notification from "../../util/Notification/Notification";
import {DicCompany} from "../../../cuba/entities/base/base_DicCompany";
import {AssignmentExt} from "../../../cuba/entities/base/base$AssignmentExt";
import {getOrganizationCondition} from "../Recognition/PersonMedal/utils";
import {SerializedEntity} from "@cuba-platform/rest";
import PickerTable from "../Recognition/PickerComponent/PickerTable";
import {FormComponentProps} from "antd/es/form";
type Props={
  menu:boolean
}
@injectMainStore
@inject("rootStore")
@observer
class NineBox extends React.Component<MainStoreInjected & FormComponentProps&RootStoreProp&Props & WrappedComponentProps> {
  @observable visible = false;
  show = () => {
    this.visible = true;
  }
  @observable isOrganizationModalVisible: boolean;
  @observable selectedOrganizationId: string;
  @observable selectedOrganizationName: string;
  organizationnameFilterValue?: string = '';
  organizationCompanyFilterValue?: DicCompany;
  modalSelectedTemp: AssignmentExt[] = [];
  modalSelectedOrgTemp: OrganizationGroupExt[] = [];
  companiesDc = collection<DicCompany>(DicCompany.NAME, {
    sort: "-updateTs",
  });organizationDc = collection<OrganizationGroupExt>(OrganizationGroupExt.NAME, {
    view: "organizationGroup.browse"
  });
  organizationTableColumns = [
    {
      caption: this.props.intl!.formatMessage({ id: "organization" }),
      field: '_instanceName'
    },
    {
      caption: this.props.intl!.formatMessage({ id: "company" }),
      field: 'company._instanceName'
    },
  ]
  onOrganizationNameFilterChange = (value: string) => {
    this.organizationnameFilterValue = value.trim();
    this.organizationDc.filter = {
      conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
    }

    this.organizationDc.load();
  }
  onCompanyFilterChange = (value: DicCompany) => {
    this.organizationCompanyFilterValue = value;
    this.organizationDc.filter = {
      conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
    }

    this.organizationDc.load();
  }
  onOrganizationChange = (organizationId: string) => {
    this.selectedGroup = organizationId;}
  handleOrganizationModalOk = () => {
    if (this.modalSelectedOrgTemp.length > 0) {
      this.selectedOrganizationName = (this.modalSelectedOrgTemp[0] as SerializedEntity<OrganizationGroupExt>)._instanceName
      this.onOrganizationChange(this.modalSelectedOrgTemp[0].id);
    }
    this.organizationnameFilterValue = ''
    this.organizationCompanyFilterValue = undefined;
    this.modalSelectedOrgTemp = []
    this.organizationDc.filter = {
      conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
    }
    this.organizationDc.load();
    this.isOrganizationModalVisible = false
  }

  handleOrganizationModalCancel = () => {
    this.organizationnameFilterValue = ''
    this.organizationCompanyFilterValue = undefined;
    this.organizationDc.filter = {
      conditions: getOrganizationCondition(this.organizationnameFilterValue, this.organizationCompanyFilterValue)
    }
    this.organizationDc.load();
    this.isOrganizationModalVisible = false
  }
  showOrganizationModal = () => {
    this.isOrganizationModalVisible = true;
  };
  getOrganizationModal = () => {
    return <Modal
      title={this.props.intl.formatMessage({id: "organizations"})}
      width={"1200px"}
      style={{height: "770px"}}
      footer={null}
      closable={false}
      visible={this.isOrganizationModalVisible}>
      <div>
        <PickerTable intl={this.props.intl} tableData={this.organizationDc} columns={this.organizationTableColumns}
                     selectionMode={'single'}
                     showPositionFilter={false}
                     onSelect={(rows: OrganizationGroupExt[]) => this.modalSelectedOrgTemp = rows}
                     onNameFilterChange={this.onOrganizationNameFilterChange}
                     onCompanyFilterChange={this.onCompanyFilterChange} companiesDc={this.companiesDc}/>
        <Button onClick={this.handleOrganizationModalOk} type={'primary'}>
          {this.props.intl!.formatMessage({id: "cubaReact.dataTable.ok"})}
        </Button>
        <Button onClick={this.handleOrganizationModalCancel} className="cancel-btn" style={{marginLeft: '1%'}}>
          {this.props.intl!.formatMessage({id: "management.browser.exclude.cancel"})}
        </Button>
      </div>
    </Modal>
  }
  componentDidMount() {
    restServices.assignmentSalaryRequestService.getMyOrgAndChilds().then(res => {
      console.log(res);
      this.orgDc = res
    })
  }

  params = {}
  loadPersonInfo = (person: string) => {
    let data = {}
    let locale:string = this.props.rootStore!.userInfo!.locale === "en"?"PROFILE_TD_EN":"PROFILE_TD_RU"
    getPersonByPersonGroup(person).then((res: any) => data = {
      parameters: [{
        name: "person",
        value: res.id
      }]
    }).then(() =>
      runReport(locale, data, this.props.intl))
  }
  @observable departmentDc: any;
  @observable dataObj = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
  }
  @observable popoverData = [];
  @action showInfo = (arr: any) => {
    this.popoverData = arr;
    this.visible = true
  }
  @observable numberOfEmployees = 0;
  @observable
  selectedGroup = "";
  @observable
  checked = false;
  @observable selectedCycle = ""
  @observable
  selectedAppraisal = "";
  cycleDc = collection(PerformancePlan.NAME, {
    view: "performancePlan.browse",
    sort: "-endDate"
  })
  appraisalDc = collection(AssessmentTemplate.NAME, {
    view: "assessmentTemplate.browse",
    sort: "-dateTo"
  })
  employeeDc = collection(AssessmentParticipant.NAME, {
    view: "assessmentParticipant.browse"
  })
  @action
  get9Box = () => {
    this.dataObj = {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
      9: [],
    };
    if (this.selectedAppraisal && this.selectedGroup&&this.selectedCycle) {
      restServices.learningService.getNewNineBox({
        organizationGroupId: this.selectedGroup,
        lang: this.props.rootStore!.userInfo.locale!,
        appraisalProcessId: this.selectedAppraisal,
        performancePlanId: this.selectedCycle,
        idp: false
      }).then((res: any) => {
        if(!res){
          Notification.error({message:this.props.intl.formatMessage({id:"management.browser.noItems"})})
          return
        }
        console.log(res);
        this.numberOfEmployees = res && res.length;
        res && res.forEach((item: any) => {
          this.dataObj[item.boxLevel]&&this.dataObj[item.boxLevel].push(item)
        })
      })
    }
  }
  @observable
  orgDc
    :
    any = []
  @observable properties = {
    loadPersonInfo: this.loadPersonInfo,
    showInfo: this.showInfo,
  }

  render() {
    if (this.orgDc)
      return (
        <Page pageName={"9Box"}>
          <Section size={"large"} className={"body-section-9box"}>
            <Row>
              <Col offset={2}>
              <div style={{
                display: "flex",
                marginBottom: 30,
                alignItems: "center",
                width:"100%",
                gap:10,
              }}>
                <span className="department-9box">{this.props.intl.formatMessage({id: "export.organisation"})}:</span>
                {this.props.menu?<div style={{width:200}} className="form-item-box">
                  <div style={{display:'flex', flexDirection:'row'}}>
                    {this.props.form.getFieldDecorator("organizationGroup",
                      {
                        rules: [{
                          required: true,
                          message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.props.intl.formatMessage({ id: "organization" }) })
                        }],
                        initialValue: this.selectedOrganizationName
                      })(
                      <Input  readOnly={true}
                              style={{ display: 'inline-block' }}
                              value={this.selectedOrganizationName}/>
                    )}
                    <Button
                      className="picker-btn"
                      icon="ellipsis"
                      onClick={() => this.showOrganizationModal()}
                      key="modal">

                    </Button>
                  </div>

                  {this.isOrganizationModalVisible ? this.getOrganizationModal() : null}
                </div>:<Select dropdownStyle={{minWidth:"max-content", overflowX:"hidden"}} className={"selectTest"} onChange={(value: any) => this.selectedGroup = value}>
                  {this.orgDc && this.orgDc.map((item: any) => {
                    return <Select.Option title={item._instanceName} value={item.id}>
                      {item["_instanceName"]}
                    </Select.Option>
                  })}
                </Select>}
                <span
                  className="appraisal-process-9box">{this.props.intl.formatMessage({id: "performancePlan"})}:</span>
                <Select  dropdownStyle={{ overflowX:"hidden", minWidth:"max-content"}} className={"selectTest"}  onChange={(value: any) => this.selectedCycle = value} >
                  {this.cycleDc && this.cycleDc.items.map((item: any) => {
                    return <Select.Option title={item._instanceName}
                                          value={item.id}>{item._instanceName}</Select.Option>
                  })}
                </Select>
                <span
                  className="appraisal-process-9box">{this.props.intl.formatMessage({id: "myCbaForm"})}:</span>
                <Select dropdownStyle={{minWidth:"max-content", overflowX:"hidden"}} className={"selectTest"}  onChange={(value: any) => this.selectedAppraisal = value} >
                  {this.appraisalDc && this.appraisalDc.items.map((item: any) => {
                    return <Select.Option title={item._instanceName}
                                          value={item.id}>{item._instanceName}</Select.Option>
                  })}
                </Select>
                <Button onClick={() => {
                  this.get9Box()
                }}
                        type={"primary"}>{this.props.intl.formatMessage({id: "search"})}</Button>
              </div>
              </Col>
            </Row>
            <Row style={{display: "flex"}}>

              <Col
                md={2} style={{display: "flex", justifyContent: "flex-end"}}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 15,
                  alignSelf: "flex-end"
                }}>
                  <ArrowIcon style={{width: 50, height: 50, transform: "rotate(270deg)"}}/>
                  <span style={{
                    writingMode: "vertical-lr",
                    transform: "rotate(180deg)",
                    fontSize: "115%"
                  }}>{"CBA"}</span></div>
                <ul style={{
                  margin: 0,
                  listStyle: "none",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  padding: 0,
                  display: "flex",
                  gap: 5
                }}>
                  <li className={"li-element"}>{this.props.intl.formatMessage({id: "high"})}</li>
                  <li className={"li-element"}>{this.props.intl.formatMessage({id: "medium"})}</li>
                  <li className={"li-element"}>{this.props.intl.formatMessage({id: "low"})}</li>
                </ul>
              </Col>
              <Popover placement={"rightTop"} trigger={"hover"}
                       visible={this.visible}
                       content={<div style={{
                         display: "flex",
                         flexDirection: "column",
                         padding: 10
                       }}>
                         {this.popoverData.map((item: any) => {
                           return <a className={"ninebox-span"} onClick={(e) => {
                             e.preventDefault();
                             this.loadPersonInfo(item.personGroupId)
                           }}>{item.employeeFullName}</a>
                         })}
                         <div style={{display: "flex", justifyContent: "flex-end"}}><Button type={"primary"}
                                                                                            style={{marginTop: 5}}
                                                                                            onClick={() => this.visible = false}>{this.props.intl.formatMessage({id: "close"})}</Button>
                         </div>
                       </div>}>
                <Col className={"ninebox-container"} sm={20} md={20} style={{
                  alignSelf: "flex-end",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: 5,
                  minHeight: 650,
                }}>
                  <><NineBoxItem numberOfEmployees={this.numberOfEmployees}
                                 dataObj={this.dataObj[7]} {...this.properties}  />
                    <NineBoxItem numberOfEmployees={this.numberOfEmployees}
                                 dataObj={this.dataObj[8]} {...this.properties} />
                    <NineBoxItem numberOfEmployees={this.numberOfEmployees}
                                 dataObj={this.dataObj[9]} {...this.properties}/>
                    <NineBoxItem numberOfEmployees={this.numberOfEmployees}
                                 dataObj={this.dataObj[4]} {...this.properties}/>
                    <NineBoxItem dataObj={this.dataObj[5]}
                                 numberOfEmployees={this.numberOfEmployees} {...this.properties}/>
                    <NineBoxItem dataObj={this.dataObj[6]}
                                 numberOfEmployees={this.numberOfEmployees} {...this.properties}/>
                    <NineBoxItem dataObj={this.dataObj[1]}
                                 numberOfEmployees={this.numberOfEmployees} {...this.properties}/>
                    <NineBoxItem dataObj={this.dataObj[2]}
                                 numberOfEmployees={this.numberOfEmployees} {...this.properties}/>
                    <NineBoxItem dataObj={this.dataObj[3]}
                                 numberOfEmployees={this.numberOfEmployees} {...this.properties}/></>
                </Col>
              </Popover>
            </Row>
            <Row>
              <Col sm={20} md={20} offset={2}>
                <ul style={{
                  margin: 0,
                  listStyle: "none",
                  justifyContent: "space-around",
                  padding: 0,
                  display: "flex",
                  gap: 5
                }}>
                  <li>{this.props.intl.formatMessage({id: "low"})}</li>
                  <li>{this.props.intl.formatMessage({id: "medium"})}</li>
                  <li>{this.props.intl.formatMessage({id: "high"})}</li>
                </ul>
                <div style={{display: "flex", alignItems: "center", gap: 15}}>
                  <span style={{fontSize: "115%"}}>KPI</span>
                  <ArrowIcon style={{alignSelf: "flex-end", width: 50, height: 50}}/></div>
              </Col>
            </Row>

          </Section>
        </Page>
      );
    else return <LoadingPage/>
  }

}

export default (injectIntl(
  withLocalizedForm
  ({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
          props.form.setFields({
            [fieldName]: {
              value: changedValues[fieldName]
            }
          });
        }
      );
    }
  })
  (NineBox)
));