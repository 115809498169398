import { AbstractDictionary } from "./AbstractDictionary";
export class DicNineBoxCategory extends AbstractDictionary {
  static NAME = "tsadv_DicNineBoxCategory";
  totalScoreKPImin?: number | null;
  totalScoreKPImax?: number | null;
  totalScoreCBAmin?: number | null;
  totalScoreCBAmax?: number | null;
  category?: any | null;
}
export type DicNineBoxCategoryViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "dicNineBoxCategory-browse"
  | "dicNineBoxCategory-edit";
export type DicNineBoxCategoryView<
  V extends DicNineBoxCategoryViewName
> = V extends "_base"
  ? Pick<
      DicNineBoxCategory,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "startDate"
      | "code"
      | "endDate"
      | "totalScoreKPImin"
      | "totalScoreKPImax"
      | "totalScoreCBAmin"
      | "totalScoreCBAmax"
      | "category"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "description1"
      | "description2"
      | "description3"
      | "description4"
      | "description5"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_local"
  ? Pick<
      DicNineBoxCategory,
      | "id"
      | "totalScoreKPImin"
      | "totalScoreKPImax"
      | "totalScoreCBAmin"
      | "totalScoreCBAmax"
      | "category"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "_minimal"
  ? Pick<
      DicNineBoxCategory,
      | "id"
      | "langValue"
      | "langValue1"
      | "langValue2"
      | "langValue3"
      | "langValue4"
      | "langValue5"
      | "startDate"
      | "code"
      | "endDate"
    >
  : V extends "dicNineBoxCategory-browse"
  ? Pick<
      DicNineBoxCategory,
      | "id"
      | "totalScoreKPImin"
      | "totalScoreKPImax"
      | "totalScoreCBAmin"
      | "totalScoreCBAmax"
      | "category"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : V extends "dicNineBoxCategory-edit"
  ? Pick<
      DicNineBoxCategory,
      | "id"
      | "totalScoreKPImin"
      | "totalScoreKPImax"
      | "totalScoreCBAmin"
      | "totalScoreCBAmax"
      | "category"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "langValue1"
      | "description1"
      | "langValue2"
      | "description2"
      | "langValue3"
      | "description3"
      | "langValue4"
      | "description4"
      | "langValue5"
      | "description5"
      | "startDate"
      | "endDate"
      | "code"
      | "isSystemRecord"
      | "active"
      | "isDefault"
      | "order"
    >
  : never;
