import RootStore from "./RootStore";
import {IdpDetail} from "../../cuba/entities/base/tsadv$IdpDetail";
import {IndividualDevelopmentPlan} from "../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";
import {SerializedEntity} from "@cuba-platform/rest";
import {action, observable, runInAction, when} from "mobx";
import {Competence} from "../../cuba/entities/base/tsadv$Competence";
import {IdpMeeting} from "../../cuba/entities/base/tsadv_IdpMeeting";
import {CompetenceGroup} from "../../cuba/entities/base/tsadv$CompetenceGroup";
import {PersonAssessmentRes} from "../pages/IndividualDevolopmentPlan/IndividualDevolopmentPlanEdit";
import {DicReadinessLevel} from "../../cuba/entities/base/tsadv$DicReadinessLevel";

export default class IdpStore {
  root: RootStore;

  @observable selectedIdp: IndividualDevelopmentPlan;

  @observable selectedIdpDetails: SerializedEntity<IdpDetail>[];

  @observable planPercent: number

  @observable competencies:Competence;

  @observable showTaskDetails:boolean = false;

  @observable currentTaskDetail?:IdpDetail;

  @observable validateFields:()=>boolean;

  @observable createTaskVisible:boolean = false;

  @observable saveIdpDetails:()=>Promise<boolean>;

  @observable saveRequest:()=> Promise<undefined | false>;

  @observable idpMeetings:IdpMeeting[];

  @observable positionCompetencies:CompetenceGroup[];

  @observable validationStarted:boolean;

  @observable deletedTasks:IdpMeeting[];

  @observable doneDetails: IdpMeeting[];

  @observable approvingIdpMeetingId:string;

  @observable actualAssessment:PersonAssessmentRes|undefined;

  @observable readinessLevelFromCard:DicReadinessLevel|undefined|null;

  @observable isReservist:boolean = false;

  @observable selfAssessmentValidation = false;

  @observable managerValidation = false;

  @observable assessmentResult:number;

  @observable validateIdp:()=>Promise<boolean>;

  constructor(root: RootStore) {
    this.deletedTasks = [];
    this.doneDetails = [];
  when(()=> !!this.selectedIdp,()=>{
    this.planPercent = this.selectedIdp.planPercent
    this.idpMeetings = this.selectedIdp.idpMeeting||[]
  })
    runInAction(() => this.root = root)
  }

  @action setSelectedIdp = (value: IndividualDevelopmentPlan) => {
    this.selectedIdp = value;
  };

  @action setSelectedIdpDetails = (value: SerializedEntity<IdpDetail>[]) => {
    this.selectedIdpDetails = value;
  };
  @action setIdpDetail = (value: SerializedEntity<IdpDetail>) => {
    this.selectedIdpDetails = [...this.selectedIdpDetails,value]
  }
  @action getIdpDetailById = (id: string): SerializedEntity<IdpDetail> | undefined => {
    return this.selectedIdpDetails.find(idp => idp.id === id) ? this.selectedIdpDetails.find(idp => idp.id === id) : undefined
  }
  @action setCurrentTaskDetail = (value:IdpDetail) =>{
    this.currentTaskDetail = value
  }
  @action pushIdpMeeting = (value:IdpMeeting) => {
    if(this.idpMeetings){
     const result = this.getIdpMeetingByCode(value.id)
      if(result) {
        for (const [key, value] of Object.entries(result))
          result[key] = value[key];
        this.selectedIdp.idpMeeting = this.idpMeetings
        return
      }
      this.idpMeetings.push(value)
      return;
    }
    this.idpMeetings = [];
    this.idpMeetings.push(value)
  }

  @action getIdpMeetingByCode = (code:string):IdpMeeting|undefined =>{
    if(this.idpMeetings){
     return this.idpMeetings.find(item=>(item.meetingType&&item.meetingType.code) === code);
    }
   return undefined
  }

  @action changeValidationStarted = (value:boolean) => {
    this.validationStarted = value
  }
  @action calculatePercent = () => {
      if (this.selectedIdpDetails) {
        this.doneDetails = this.selectedIdpDetails.filter(d=>d.done).filter(d=>!d.deleteTs)
        this.deletedTasks = this.selectedIdpDetails.filter(d=>d.deleteTs)
        this.planPercent = (this.doneDetails.length / (this.selectedIdpDetails.length - this.deletedTasks.length)) * 100
        if(this.selectedIdp){
          this.selectedIdp.planPercent = this.planPercent
          this.idpMeetings = this.selectedIdp.idpMeeting||[]
        }
        if(this.planPercent===100){
          this.selectedIdp.complete = true
        }
      }
  }
}
