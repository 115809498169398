import * as React from "react";
import {inject, observer} from "mobx-react";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import { injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Table} from "antd";
import {action, observable} from "mobx";
import {PersonLearningContract} from "../../../cuba/entities/base/tsadv$PersonLearningContract";
import {SerializedEntity} from "@cuba-platform/rest";
import {ColumnProps} from "antd/es/table";
import moment from "moment";
import MsgEntity from "../../components/MsgEntity";

@injectMainStore
@inject("rootStore")
@observer
class PersonLearningContractListComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  @observable dataCollection: SerializedEntity<PersonLearningContract>[]
  @action changeDataCollection(value:SerializedEntity<PersonLearningContract>[]){
    this.dataCollection = value
  }
  @observable loading:boolean = true
  @action changeLoading(value:boolean){
    this.loading = value
  }
  render(){
    const columns:ColumnProps<PersonLearningContract>[] = [
      {
        title:this.props.intl.formatMessage({id:"course"}),
        render:(text, record)=>record.courseScheduleEnrollment&&record.courseScheduleEnrollment.courseSchedule&&
        record.courseScheduleEnrollment.courseSchedule?record.courseScheduleEnrollment.courseSchedule['_instanceName']:""
      },
      {
        title:<MsgEntity entityName={PersonLearningContract.NAME} propertyName={"contractNumber"} mainStore={this.props.mainStore}/>,
        render:(text, record) => record.contractNumber||""
      },
      {
        title:<MsgEntity entityName={PersonLearningContract.NAME} propertyName={"termOfService"} mainStore={this.props.mainStore}/>,
        render:(text, record) => record.termOfService?moment(record.termOfService).format('L'):""
      },
      {
        title:this.props.intl.formatMessage({id:"balanceKZT"}),
        render:(text, record) => {
          const result = (record.courseScheduleEnrollment&&record.courseScheduleEnrollment.totalCost)/
            (Number(moment(record.termOfService).diff(moment(record.contractDate),"days"))+1)*(Number(moment(record.termOfService).diff(moment(),"days"))+1);
          if(result<0) return 0;
                                                           return isNaN(result)?"":result.toLocaleString('ru',{maximumFractionDigits:2})
        }
      }
    ]
    return(
      <Table
        dataSource={this.dataCollection}
        columns={columns}
        loading={this.loading}
      />
    )
  }
  componentDidMount() {
    getCubaREST()!.searchEntities<PersonLearningContract>(PersonLearningContract.NAME,{conditions:[{
      property:"personGroup.id",
      operator:"=",
      value:this.props.rootStore!.userInfo.personGroupId!
      }]},{view:"personLearningContract.edit"})
      .then(value => {
        this.changeDataCollection(value);
        this.changeLoading(false)})
      .catch(reason => console.log(reason))
  }
}

const PersonLearningContractList = injectIntl(PersonLearningContractListComponent);

export default PersonLearningContractList;