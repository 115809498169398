import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Button, Card, Col, Divider, Row, Tabs} from "antd";
import CreateRecognition from "../Components/CreateRecognition";
import {restServices} from "../../../../cuba/services";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import RecognitionList from "../Components/RecognitionList";
import {action, computed, observable, reaction, runInAction} from "mobx";
import Page from "../../../hoc/PageContentHoc";
import Img from "../../../components/Img";
import {RouteComponentProps} from "react-router";
import LoadingPage from "../../LoadingPage";
import {ProfilePojo} from "../../../../cuba/entities/base/tsadv$ProfilePojo";
import {recognitionPersonWallTypeStore} from "../store/RecognitionsStore";
import {PersonPreferences} from "../Components/PersonPreferences";
import {RecognitionProfileTypes} from "../Components/RecognitionProfileTypes";
import PersonAwardCreateModal from "../Components/PersonAwardCreateModal";
import CoinsHistoryComponent from "../Components/CoinsHistoryComponent";
import {NotFoundImage} from "../../../components/NoImage";
import RecognitionProfilePointsAndCoins from "../Components/RecognitionProfilePointsAndCoins";
import PersonMedals from "../Components/PersonMedals";
import Analytics from "../Components/Chart/Analytics";
import PersonFeed from "../Components/PersonFeed";


type PersonPageProps = RouteComponentProps<{ groupId: string }>;

@inject("rootStore")
@injectMainStore
@observer
class PersonPage extends React.Component<RootStoreProp & WrappedComponentProps & PersonPageProps> {

  @observable profile?: ProfilePojo;
  @observable isShowRecognitionCreateModal: boolean = false;
  @observable isShowPersonAwardCreateModal: boolean = false;
  @observable personImage?: Promise<string>

  componentDidMount() {
    reaction(
      () => this.props.match.params.groupId,
      () => this.updatePersonGroup(),
      {fireImmediately: true}
    );
  }

  @action updatePersonGroup = () => {
    const {groupId} = this.props.match.params;

    restServices.recognitionService.loadProfile(groupId)
      .then(profilePojo => runInAction(() => this.profile = profilePojo))

    this.personImage = restServices.employeeService.getPersonImage(groupId);
  }

  @computed get isMyProfile() {
    return this.props.rootStore!.userInfo!.personGroupId === this.props.match.params.groupId;
  }

  renderRecognitionButtons = () => {
    return <>
      <Divider/>
      <Button block
              type={"primary"}
              style={{marginBottom: "15px"}}
              onClick={() => runInAction(() => this.isShowRecognitionCreateModal = true)}>
        <FormattedMessage id={"recognition.thanks"}/>
      </Button>
      <Button block
              type={"primary"}
              onClick={() => runInAction(() => this.isShowPersonAwardCreateModal = true)}>
        <FormattedMessage id={"recognition.nominate"}/>
      </Button>
    </>
  }

  personCard = (profile: ProfilePojo) => {
    return <>
      <Row type={"flex"} justify={"center"}>
        <Img type={"base64Promise"} src={this.personImage} style={{borderRadius: "100%"}}
             image={NotFoundImage.EMPLOYEE}/>
      </Row>

      <h1 style={{textAlign: "center"}}>{`${profile.firstName} ${profile.lastName}`}</h1>
      <h1 style={{textAlign: "center"}}>{profile.position}</h1>
      <h1 style={{textAlign: "center"}}>{profile.organization}</h1>

      {!this.isMyProfile && this.renderRecognitionButtons()}

      <Divider/>
      <RecognitionProfileTypes personGroupId={this.props.match.params.groupId}/>
      <Divider/>
      {this.isMyProfile &&
        <div style={{width: '100%'}}>
          <RecognitionProfilePointsAndCoins personGroupId={this.props.match.params.groupId}/>
          <Divider/>
        </div>
      }
      <PersonPreferences personGroupId={this.props.match.params.groupId}/>
    </>
  }

  onCreated = () => {
    recognitionPersonWallTypeStore.reloadRecognitions();
  }

  @action onCloseRecognition = () => {
    this.isShowRecognitionCreateModal = false;
  }

  @action onCloseAward = () => {
    this.isShowPersonAwardCreateModal = false;
  }

  render() {
    if (!this.profile) {
      return <LoadingPage/>
    }

    const {groupId} = this.props.match.params;

    return <>
      {this.isShowRecognitionCreateModal &&
        <CreateRecognition visible={this.isShowRecognitionCreateModal}
                           personGroup={groupId}
                           onCreated={this.onCreated}
                           onClose={this.onCloseRecognition}
        />
      }
      {this.isShowPersonAwardCreateModal &&
        <PersonAwardCreateModal personGroupId={groupId}
                                onClose={this.onCloseAward}
                                visible={this.isShowPersonAwardCreateModal}
        />
      }
      <Page>
        <Row>
          <Col xs={8}>
            <Card className="narrow-layout large-section section-container">
              {this.personCard(this.profile)}
            </Card>
          </Col>
          <Col xs={16}>
            <Card className={"narrow-layout large-section section-container"}>
              <PersonFeed groupId={groupId} isMyProfile={this.isMyProfile}/>
            </Card>
          </Col>
        </Row>
      </Page>
    </>
  }
}

export default injectIntl(PersonPage);