import { AbstractParentEntity } from "./AbstractParentEntity";
import { PersonCompetence } from "./tsadv_PersonCompetence";
import { CompetenceGroup } from "./tsadv$CompetenceGroup";
import { ScaleLevel } from "./tsadv$ScaleLevel";
import { DicMatchResult } from "./tsadv_DicMatchResult";
export class PersonCompetenceHistory extends AbstractParentEntity {
  static NAME = "tsadv_PersonCompetenceHistory";
  personCompetence?: PersonCompetence | null;
  personCompetenceType?: any | null;
  competence?: CompetenceGroup | null;
  scaleLevel?: ScaleLevel | null;
  requiredDevelopment?: boolean | null;
  matchResult?: DicMatchResult | null;
}
export type PersonCompetenceHistoryViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personCompetenceHistory-browse";
export type PersonCompetenceHistoryView<
  V extends PersonCompetenceHistoryViewName
> = V extends "_base"
  ? Pick<
      PersonCompetenceHistory,
      | "id"
      | "personCompetenceType"
      | "requiredDevelopment"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      PersonCompetenceHistory,
      | "id"
      | "personCompetenceType"
      | "requiredDevelopment"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "personCompetenceHistory-browse"
  ? Pick<
      PersonCompetenceHistory,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "personCompetenceType"
      | "requiredDevelopment"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "competence"
      | "scaleLevel"
      | "matchResult"
      | "personCompetence"
    >
  : never;
