import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Collapse, Row, Spin} from "antd";
import {RootStoreProp} from "../../../store";
import {inject, observer} from "mobx-react";
import {injectMainStore} from "@cuba-platform/react";
import {observable, runInAction} from "mobx";
import Page from "../../../hoc/PageContentHoc";
import {RcgFaq} from "../../../../cuba/entities/base/tsadv$RcgFaq";
import {restServices} from "../../../../cuba/services";


@inject("rootStore")
@injectMainStore
@observer
class FAQRecognitionPage extends React.Component<RootStoreProp & WrappedComponentProps> {
  @observable faqs?: RcgFaq[];

  componentDidMount() {
    restServices.recognitionService.loadRcgFaqs()
      .then((faqs) => runInAction(() => this.faqs = faqs));
  }

  renderFAQ = (faq: RcgFaq) => {
    return (
      <Collapse.Panel header={faq.title} key={faq.id}>
        <p>{faq.content}</p>
      </Collapse.Panel>
    )
  }

  renderSpin = () => {
    return <Row type={"flex"} justify={"center"}>
      <Spin size={"large"}/>
    </Row>
  }

  render() {

    return <>
      <Page pageName={"Recognition FAQ"}>
        {
          this.faqs !== undefined
            ? <Collapse>
                {this.faqs.map(this.renderFAQ)}
              </Collapse>
            : this.renderSpin()
        }
      </Page>
    </>
  }
}

export default injectIntl(FAQRecognitionPage);