import { AbstractParentEntity } from "./AbstractParentEntity";
import { LearningBudget } from "./tsadv_LearningBudget";
export class BudgetForecast extends AbstractParentEntity {
  static NAME = "tsadv_BudgetForecast";
  quarter?: string | null;
  budget?: LearningBudget | null;
  startDate?: any | null;
  endDate?: any | null;
}
export type BudgetForecastViewName = "_base" | "_local" | "_minimal";
export type BudgetForecastView<
  V extends BudgetForecastViewName
> = V extends "_base"
  ? Pick<
      BudgetForecast,
      | "id"
      | "quarter"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      BudgetForecast,
      | "id"
      | "quarter"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_minimal"
  ? Pick<BudgetForecast, "id" | "quarter">
  : never;
