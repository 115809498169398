import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { DicPersonQualificationType } from "./tsadv$DicPersonQualificationType";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { FileDescriptor } from "./sys$FileDescriptor";
import { PersonQualification } from "./tsadv$PersonQualification";
import { DicEducationDocumentType } from "./tsadv_DicEducationDocumentType";
export class PersonQualificationRequest extends AbstractBprocRequest {
  static NAME = "tsadv_PersonQualificationRequest";
  type?: DicPersonQualificationType | null;
  personGroup?: PersonGroupExt | null;
  startDate?: any | null;
  endDate?: any | null;
  assignValidationDate?: any | null;
  attachment?: FileDescriptor | null;
  note?: string | null;
  educationalInstitutionName?: string | null;
  diploma?: string | null;
  typeName?: string | null;
  issuedDate?: any | null;
  file?: FileDescriptor | null;
  attachments?: FileDescriptor[] | null;
  personQualification?: PersonQualification | null;
  qualification?: string | null;
  profession?: string | null;
  educationDocumentType?: DicEducationDocumentType | null;
  courseName?: string | null;
  expiryDate?: any | null;
}
export type PersonQualificationRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personQualificationRequest.edit"
  | "personQualificationRequest.integration";
export type PersonQualificationRequestView<
  V extends PersonQualificationRequestViewName
> = V extends "_base"
  ? Pick<
      PersonQualificationRequest,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "startDate"
      | "endDate"
      | "assignValidationDate"
      | "note"
      | "educationalInstitutionName"
      | "diploma"
      | "typeName"
      | "issuedDate"
      | "qualification"
      | "profession"
      | "courseName"
      | "expiryDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      PersonQualificationRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "assignValidationDate"
      | "note"
      | "educationalInstitutionName"
      | "diploma"
      | "typeName"
      | "issuedDate"
      | "qualification"
      | "profession"
      | "courseName"
      | "expiryDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<PersonQualificationRequest, "id" | "requestNumber" | "requestDate">
  : V extends "personQualificationRequest.edit"
  ? Pick<
      PersonQualificationRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "assignValidationDate"
      | "note"
      | "educationalInstitutionName"
      | "diploma"
      | "typeName"
      | "issuedDate"
      | "qualification"
      | "profession"
      | "courseName"
      | "expiryDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "type"
      | "personGroup"
      | "attachment"
      | "file"
      | "attachments"
      | "personQualification"
      | "educationDocumentType"
      | "status"
    >
  : V extends "personQualificationRequest.integration"
  ? Pick<
      PersonQualificationRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "assignValidationDate"
      | "note"
      | "educationalInstitutionName"
      | "diploma"
      | "typeName"
      | "issuedDate"
      | "qualification"
      | "profession"
      | "courseName"
      | "expiryDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "personQualification"
      | "personGroup"
      | "startDate"
      | "endDate"
      | "educationDocumentType"
      | "issuedDate"
      | "expiryDate"
      | "status"
    >
  : never;
