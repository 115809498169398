import React, { Component } from 'react'
import { collection, injectMainStore, MainStoreInjected, Msg } from '@cuba-platform/react';
import { AdaptationTask } from '../../../../cuba/entities/base/tsadv_AdaptationTask';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { inject, observer } from 'mobx-react';
import { RootStoreProp } from '../../../store';
import AdaptationTaskEdit from '../AdaptationTaskManagment/AdaptationTaskEdit';
import { Modal, Table, Button as AntdButton, Typography } from 'antd';
import { observable } from 'mobx';
import { SerializedEntity } from '@cuba-platform/rest';

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp & {
  adaptationPlanId: string;
  readonly?: boolean;
};

@injectMainStore
@inject("rootStore")
@observer
class AdaptationTasksTableComponent extends Component<Props> {

  dataCollection = collection<AdaptationTask>(AdaptationTask.NAME, {
    view: "adaptationTask",
    sort: "-updateTs",
    loadImmediately: true,
    filter: {
      conditions: [
        {
          property: "adaptationPlan.id",
          operator: "=",
          value: this.props.adaptationPlanId!,
        },
      ]
    }
  })
  
  @observable
  selectedRowKeys: string[] = [];
  
  @observable
  isModalOpen: boolean = false;

  @observable
  editTaskId: string = "new";
  
  @observable
  isLocaleRu = this.props.rootStore!.userInfo.locale === 'ru';

  @observable
  isLocaleKz = this.props.rootStore!.userInfo.locale === 'kz';

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  showDeletionDialog = (e: SerializedEntity<AdaptationTask>) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "management.browser.delete.areYouSure" },
        { instanceName: e._instanceName }
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        this.selectedRowKeys = [];
        return this.dataCollection.delete(e);
      }
    });
  }

  getRecordById(id: string): SerializedEntity<AdaptationTask> {
    const record:
      | SerializedEntity<AdaptationTask>
      | undefined = this.dataCollection.items.find(record => record.id === id);

    if (!record) {
      throw new Error("Cannot find entity with id " + id);
    }

    return record;
  }
  
  toggleModal = ()=>{
    this.isModalOpen = !this.isModalOpen;
    this.selectedRowKeys = [];
  }

  handleSelectedRowsChange = (selectedRowKeys: string[])=>{
    this.selectedRowKeys = selectedRowKeys;
    if(selectedRowKeys.length === 0) this.editTaskId = "new";
  }

  handleSelect = (record: AdaptationTask)=>{
    this.selectedRowKeys=[record.id];
  }
  
  handleOpenCreate = ()=>{
    this.editTaskId = "new";
    this.selectedRowKeys = [];
    this.isModalOpen = true;
  }

  handleAddTask = async (task: AdaptationTask) => {
    this.dataCollection.load();
    this.toggleModal();
  }
  handleRemoveTask = async () => {
    this.showDeletionDialog(this.getRecordById(this.selectedRowKeys[0]));
    this.editTaskId = "new";
    this.selectedRowKeys = [];
  }
  handleOpenEdit = () => {
    this.editTaskId = this.selectedRowKeys[0];
    this.isModalOpen = true;
  }
  handleEditTask = () => {
    this.dataCollection.load();
    this.toggleModal();
  }

  renderTaskField = (
    fieldName: string, 
    ruSuffix:string = 'Lang1', 
    kzSuffix:string = 'Lang2', 
    enSuffix:string = 'Lang3'
  ) => (text: string, record: AdaptationTask) => {
    const value = text 
    || (this.isLocaleRu && record[fieldName + ruSuffix])
    || (this.isLocaleKz && record[fieldName + kzSuffix])
    || record[fieldName+enSuffix] || record[fieldName+ruSuffix] || record[fieldName+kzSuffix];
    
    return (
      <Typography.Text style={{ whiteSpace: 'break-spaces' }}>
        {value}
      </Typography.Text>
    )
  }

  render() {
    return (
      <div>
        {!this.props.readonly &&
          <div>
          <AntdButton
            htmlType="button"
            style={{ margin: "0 12px 12px 0", paddingLeft: "5px" }}
            type="primary"
            icon="plus"
            onClick={this.handleOpenCreate}
          >
          </AntdButton>
          <AntdButton
            htmlType="button"
            style={{ margin: "0 12px 12px 0", paddingLeft: "5px" }}
            type="primary"
            icon="edit"
            onClick={this.handleOpenEdit}
            disabled={this.selectedRowKeys.length !== 1}
          >
          </AntdButton>
          <AntdButton
            htmlType="button"
            style={{ margin: "0 12px 12px 0", paddingLeft: "5px" }}
            type="danger"
            icon="minus"
            onClick={this.handleRemoveTask}
            disabled={this.selectedRowKeys.length === 0}
          >
          </AntdButton>
          <Modal
            title={this.props.intl.formatMessage({id:"adaptationPlan.modal.title"})}
            visible={this.isModalOpen}
            onOk={this.toggleModal}
            onCancel={this.toggleModal}
            footer={[]}
          >
            <AdaptationTaskEdit
              onCancel={this.toggleModal}
              onSave={this.handleAddTask}
              entityId={this.editTaskId}
              adaptationPlanId={this.props.adaptationPlanId}
            />
          </Modal>
          </div>
        }
        <Table
          style={{ tableLayout: 'fixed' }}
          dataSource={this.dataCollection.items}
          rowKey={'id'}
          rowSelection={this.props.readonly ? undefined : {
            selectedRowKeys: this.selectedRowKeys,
            selectWay: 'onSelect',
            onChange: this.handleSelectedRowsChange,
            onSelect: this.handleSelect,
          }}
          columns={[
            {
              key: 'assignment',
              title: <Msg entityName={AdaptationTask.NAME} propertyName={"assignment"} mainStore={this.props.mainStore} />,
              dataIndex: 'assignment',
              render: this.renderTaskField('assignment')
            }, {
              key: 'expectedResults',
              title: <Msg entityName={AdaptationTask.NAME} propertyName={"expectedResults"} mainStore={this.props.mainStore} />,
              dataIndex: 'expectedResults',
              render: this.renderTaskField('expectedResults')
            }, {
              key: 'achievedResults',
              title: <Msg entityName={AdaptationTask.NAME} propertyName={"achievedResults"} mainStore={this.props.mainStore} />,
              dataIndex: 'achievedResults',
              render: this.renderTaskField('achievedResults')
            },
          ]}
        />
      </div>
    )
  }
}

const AdaptationTasksTable = injectIntl(AdaptationTasksTableComponent)

export default AdaptationTasksTable
