import * as React from "react";
import {ChangeEvent, createElement} from "react";
import {Card, Checkbox, Col, Form, Input, message, Modal, Row, Select, Spin, Table, Button as Btn} from "antd";
import Popover from '../../components/Popover'
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

import {
  collection,
  getCubaREST,
  injectMainStore,
  instance,
  Msg,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../App.css";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import LoadingPage from "../LoadingPage";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import { goBackOrHomePage} from "../../util/util";
import {SuccessorRequest} from "../../../cuba/entities/base/tsadv_SuccessorRequest";
import {restServices} from "../../../cuba/services";
import moment from "moment";
import {action, observable, reaction, runInAction, when} from "mobx";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import Section from "../../hoc/Section";
import {SuccessionPlanning} from "../../../cuba/entities/base/tsadv$SuccessionPlanning";
import {TsadvUser} from "../../../cuba/entities/base/tsadv$UserExt";
import {DicReadinessLevel} from "../../../cuba/entities/base/tsadv$DicReadinessLevel";
import {SuccessorStatus} from "../../../cuba/entities/base/tsadv_SuccessorStatus";
import SearchPerson from "../../components/SearchPerson";
import {runReport} from "../../util/reportUtil";
import Notification from "../../util/Notification/Notification";
import EditableAssessmentForm
  from "../../components/EditableAssessmentForm/EditableAssessmentForm";
import {CompetenceTemplate} from "../../../cuba/entities/base/tsadv$CompetenceTemplate";
import {DicAssessmentStatus} from "../../../cuba/entities/base/tsadv$DicAssessmentStatus";
import {AssessmentTemplate} from "../../../cuba/entities/base/tsadv$AssessmentTemplate";
import {CompetenceTemplateParticipant} from "../../../cuba/entities/base/tsadv_CompetenceTemplateParticipant";
import {PersonAssessment} from "../../../cuba/entities/base/tsadv_PersonAssessment";
import {CompetenceElement} from "../../../cuba/entities/base/tsadv$CompetenceElement";
import {SerializedEntity} from "@cuba-platform/rest";

export interface PersonAssessmentRes {
  person_assessment_id: string;
  person_group_id: string;
  date_from: string;
  date_to: string;
  status_code: string;
  session_name: string;
  employeeFullName: string;
  participant_person_group_id: string;
  participant_type_code: string;
  total_result: number;
  instruction: string;
  assessment_type: string;
  participant_status_id: string;
  partcicipant_result: number;
  participant_status_code: string;
  is_edit: boolean;
  position_group_id:string;
}

export type PositionInfo = {
  positionId: string;
  positionGroupId: string;
  organizationGroupId: string;
  positionName: string;
  organizationName: string;
  company: string;
  departmentName?: string;
  managementName?: string;
  place?: string;
  fte?: number;
  parentPositionName?: string;
  parentPositionGroupId?: string;
}

type EditorProps = {
  entityId:string;
  initiatorId?: string;
  reservistId?:string;
  positionGroupId?:string;
}

type DetailData = {
  person_assessment_id: string | null,
  competence_source: string | null,
  competence_type_id: string | null,
  competence_type: string | null,
  competence_group_id: string | null,
  competence_name: string | null,
  required_scale_level_id: string | null,
  required_scale_level: string | null,
  result_percent: string | null,
  result_id: string | null,
  result: string | null,
  required_to_train: boolean,
  comments: string | null,
  children: any,
  participant1_scale_level: string | null,
  participant2_scale_level: string | null,
  participant3_scale_level: string | null,
}
const formStyle = { marginBottom: "12px", padding: "0 30px" }

@inject("rootStore")
@injectMainStore
@observer
class SuccessorRequestEditComponent extends AbstractBprocEdit<SuccessorRequest, EditorProps> {

  processDefinitionKey = "successorRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<SuccessorRequest>(SuccessorRequest.NAME, {
    view: "successorRequest-edit-with-assessment",
    loadImmediately: false,
  });


  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  @observable positionGroupId:string

  @observable
  editAddress: PersonGroupExt;

  instanceEditAddress = instance<PersonGroupExt>(PersonGroupExt.NAME, {
    view: "personEducation.full",
    loadImmediately: false
  })

  keyPositionInstance = instance<SuccessionPlanning>(SuccessionPlanning.NAME,{
    view:"successionPlanning-SuccessorRequest"
  });
  keyPositionCollection = collection<SuccessionPlanning>(SuccessionPlanning.NAME,{
    view:"successionPlanning-talentPool",
    filter:{
      conditions:[{
        property:"positionType",
        operator:"=",
        value:"INCLUDE"
      }]
    }
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;
  @observable
  initiator : PersonExt;
  @observable splitSearch:string[];

  @observable initiatorId: string;
  @observable reservistId: string;
  @action changeReservistId (value:string){
    this.reservistId = value
    this.loadReservist()
  }
  @action loadReservist (){
    if ( this.reservistId){
      getCubaREST()!.loadEntity<PersonGroupExt>(PersonGroupExt.NAME, this.reservistId, {view: "personGroupExt-successorRequest"})
        .then(value => {
          this.reservist = value
          const startDate = value.person&&value.person.hireDate
          if(moment().diff(moment(startDate),"months")<6){
            message.warning(this.props.intl.formatMessage({id:"successorRequestWarningMessage"}))
          }
          this.findManagers(value.id)
        });
      if(this.props.entityId === 'new'){
        restServices.learningService.getPersonAssessments({personGroupId:  this.reservistId, lang: this.props.rootStore!.userInfo.locale!})
          .then(res=>{
            if(res[0]&&res[0].value) {
              const data: PersonAssessmentRes[] = JSON.parse(res[0].value);
              this.allAssessment = data.filter(i=>i.person_group_id === this.reservistId)
              if(this.allAssessment){
                const pgAssessment = this.allAssessment.filter(a=>a.position_group_id === (this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup!.id));
                if(pgAssessment.length>0) this.actualAssessment = pgAssessment.reduce((prev, curr) => moment(prev.date_from).isAfter(moment(curr.date_from)) ? prev : curr)
                else this.actualAssessment = undefined
              }}})
      }
    }
  }
  @observable allDone:boolean = false;
  @observable keyPosVal:string;
  @observable keyPosManagers?:TsadvUser[] = []
  @observable keyPosPositionGroupId:string;
  @observable comment:string;
  @observable loadingSuccessors:boolean=false;
  @observable timer:NodeJS.Timeout;
  @action changeLoadingSuccessors (value:boolean){
    this.loadingSuccessors = value
  }
  @action changeComment = (e:ChangeEvent<HTMLTextAreaElement>) => {
    this.comment = e.target.value
  }
  @action changeKeyPos = async (value:string)=>{
    this.keyPosVal = value
    this.keyPositionInstance.load(value)
    this.alreadyCreated = false;
    this.newPersonAssessmentId = undefined;
    this.personAssessment = undefined;
    when(()=>this.keyPositionInstance.status ==="DONE",()=>{
      getCubaREST()!.searchEntities<CompetenceElement>(CompetenceElement.NAME,{
        conditions:[{
          property:"positionGroup.id",
          operator:"=",
          value:this.keyPositionInstance.item!.positionGroup!.id
        }]
      },{view:"_local"}).then(ce=>{
        this.competenceLen = ce.length;
        if(this.competenceLen===0){
          this.competenceNullMessage(this.keyPositionInstance&&this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup['_instanceName'])
        }
      })
      if(this.props.entityId==='new'){
        this.loadAssessments(this.reservistId,this.props.rootStore!.userInfo.locale!)
          .then((res)=>{
            if(res !== null&&res[0]) {
              const data: PersonAssessmentRes[] = JSON.parse(res[0].value);
              this.allAssessment = data.filter(i=>i.person_group_id === this.reservistId)
              if(this.allAssessment){
                const pgAssessment = this.allAssessment.filter(a=>a.position_group_id === (this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup!.id));
                if(pgAssessment.length>0) this.actualAssessment = pgAssessment.reduce((prev, curr) => moment(prev.date_from).isAfter(moment(curr.date_from)) ? prev : curr)
                else this.actualAssessment = undefined
              }
            }
          })}
    })
    restServices.successionPlanningService.getSuccessionPlannings({successionPlanningList: [value], view:"keyPosition-short",successor:"",position:"",company:"",organization:""}).then(res=>{
      runInAction(()=>this.keyPosManagers=res[0].managers)
    })
    return this.keyPosVal
  }

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';
  @observable typing=false;
  @observable typingTimeout=0;

  isUpdateBeforeOutcome = true;
  @observable
  reservist?:PersonGroupExt

  fields= [

    "requestNumber",

    "requestDate",

    "status",

    "successorStatus",

    "readinessLevel",

    "comment"
  ];

  getDate = () => moment().format(JSON_DATE_TIME_FORMAT);

  positionInfo:PositionInfo

  loadPositionInfo = (positionGroupId:string) => {
    restServices.positionService.getPositionInfo(positionGroupId, this.getDate()).then(value => {
      this.positionInfo = value;
    })
  }
  validate = (): Promise<boolean> => {
    const assignments = this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup.assignments
    const actualAssignments = assignments&& assignments.filter(item => item.startDate<this.getDate() && item.endDate>this.getDate());
    const isCurrentEmployee = (id:string):boolean =>{
      if(actualAssignments){
        return actualAssignments.map(aa=>aa.personGroup!.id).includes(id)
      }
      return false
    }
    let isValidatedSuccess = true;
    if(!this.actualAssessment&&this.competenceLen===0){
      isValidatedSuccess = false;
      this.noAssessmentMessageWithoutComp(this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup['_instanceName'])
      return new Promise(resolve => resolve(isValidatedSuccess));
    }
    if(!this.actualAssessment&&this.competenceLen>0){
      isValidatedSuccess = false;
      this.noAssessmentMessage()
      return new Promise(resolve => resolve(isValidatedSuccess));
    }
    this.props.form.validateFields(this.fields, {force: true}, (err, values) => {
      isValidatedSuccess = !err;
      if (err) {
        Notification.error({
          message: this.props.intl.formatMessage({id: "management.editor.validationError"})
        });
      }
    });
    if(!this.managerValidation && this.props.rootStore!.userInfo.personGroupId! !== this.initiatorId && this.props.rootStore!.userInfo.personGroupId !== this.reservistId &&isCurrentEmployee(this.props.rootStore!.userInfo.personGroupId!)){
      Notification.error({message:this.props.intl.formatMessage({id:"createNewAssessment.error"}),duration:3})
      runInAction(()=>this.assessmentFormVisible = true)
      return new Promise(resolve => resolve(false));
    }
    return new Promise(resolve => resolve(isValidatedSuccess));
  };

  getUpdateEntityData(): SuccessorRequest {
    if (this.isNotDraft()){
      console.log("isnotDraft")
      return {...super.getUpdateEntityData(),personGroup:this.reservistId,comment:this.comment,description:this.comment,assessment:this.actualAssessment?{id:this.actualAssessment.person_assessment_id}:undefined, readinessLevel: this.dataInstance.item && this.dataInstance.item.readinessLevel,
        successorStatus: this.dataInstance.item && this.dataInstance.item.successorStatus};
    }
    return {
      ...super.getUpdateEntityData(),
      personGroup: this.reservistId,
      initiator: this.props.initiatorId,
      positionGroup: this.keyPositionInstance && this.keyPositionInstance.item && this.keyPositionInstance.item.positionGroup && this.keyPositionInstance.item.positionGroup,
      comment: this.comment,
      description:this.comment,
      assessment: this.assessment
    };
  }

  @observable managers:PersonGroupExt[]
  findManagers =  (personGroupId:string) =>{
    restServices.employeeService.getManagersByPersonGroup(personGroupId).then(value =>this.managers=value)
  }
  @action goBack = () =>{
    this.props.history.goBack()
  }
  @observable readinessLevelDc = collection<DicReadinessLevel>(DicReadinessLevel.NAME,{
    view:"_local"
  })
  @observable successorStatusDc = collection<SuccessorStatus>(SuccessorStatus.NAME,{
    view:"_local"
  })
  @observable readinessLevel:DicReadinessLevel;


  @observable detailTableData: any

  @action openCommentModal = (record: any) => {
  }

  @action loadAssessments = async (personGroupId:string,lang:string) => {
    return await restServices.learningService.getPersonAssessments({personGroupId:personGroupId,lang:lang})
  }
  @observable assessment?:PersonAssessment|null
  reportEmployeeProfile = async () => {
    try{
      const personProfile = await restServices.employeeService.personProfile(
        this.dataInstance.item&&this.dataInstance.item.personGroup&&this.dataInstance.item.personGroup.id||
        this.reservistId||
        this.props.rootStore!.userInfo.personGroupId );
      const data = {
        parameters: [{
          name: "person",
          value: personProfile.id
        }]
      };
      runReport('employee_profile_'+this.props.rootStore!.userInfo.locale, data, this.props.intl);
    }catch(e){
      Notification.error({
        message: this.props.intl.formatMessage({
          id: 'adaptationPlan.employee_profile.NotFound',
          defaultMessage: 'Employee Profile Not Found!',
        }),
      });
    }
  }

  @observable actualAssessment:PersonAssessmentRes|undefined;

  @observable assessmentFormVisible:boolean = false;

  @observable personAssessment:PersonAssessment|undefined;

  @observable alreadyCreated : boolean = false;

  @action createNewSessionAlert = () =>{
    Modal.info({title:this.props.intl.formatMessage({id:"fillEmployeeCardMsg"}),centered:true})
    this.assessmentFormVisible = false;
  }

  @action createNewSession = () =>{
    const pg = this.keyPositionInstance.item && this.keyPositionInstance.item.positionGroup

    if(!pg){
      Notification.error({message:this.props.intl.formatMessage({id:"notification.positionGroup"})})
      return
    }
    const personGr = this.reservist
    getCubaREST()!.searchEntities<CompetenceTemplate>(CompetenceTemplate.NAME, {
      conditions: [{
        property: "code",
        operator: "=",
        value: "LDP"
      }]
    }).then((ct) => {
      Promise.all([getCubaREST()!.searchEntities<DicAssessmentStatus>(DicAssessmentStatus.NAME, {
        conditions: [{
          property: "code",
          operator: "=",
          value: "DRAFT"
        }]
      }).then(as => as[0]),
        getCubaREST()!.searchEntities<AssessmentTemplate>(AssessmentTemplate.NAME, {
          conditions: [{
            property: "competenceTemplate.id",
            operator: "=",
            value: ct[0].id
          }]
        }).then(at => at[0]),
        getCubaREST()!.searchEntities<CompetenceTemplateParticipant>(CompetenceTemplateParticipant.NAME,{
          conditions:[{
            property:"competenceTemplate.id",
            operator:"=",
            value:ct[0].id
          }]
        },{view:"competenceTemplateParticipant-for-assessmentTemplate-edit"}).then(res=>res),
      ]).then(data => {
        const personAssessment: PersonAssessment = {
          assessmentTemplate: data[1],
          personGroup: personGr,
          assessmentStatus: data[0],
          positionGroup: {id:pg.id},
        }
        getCubaREST()!.commitEntity<PersonAssessment>(PersonAssessment.NAME, {
          ...personAssessment,
          id: ""
        }).then(pa => {
          this.newPersonAssessmentId = pa.id
          this.personAssessment = {id:this.newPersonAssessmentId};
          this.dataInstance.item!.assessment = {id:this.newPersonAssessmentId};
          restServices.learningService.createAssessment({personAssessmentId:pa.id}).then(()=>{
            const participant = {...(data[2][0] as CompetenceTemplateParticipant),personGroupId:personGr!.id};
            restServices.learningService.createAssessmentParticipant({personAssessmentId:pa.id,personGroupId:participant.personGroupId,code:"EMPLOYEE"})
              .then(()=>{
                this.editableFormVisible = true;
                this.alreadyCreated = true;
              })
          })
        })
      })
    })
  }

  @observable allAssessment:PersonAssessmentRes[]

  @observable assessmentForm:Array<any>;

  @observable tableKey=0;

  @observable participantsCount = 0;

  @observable newPersonAssessmentId:string|undefined;

  @observable selfAssessmentValidation:boolean;

  @observable managerValidation:boolean;

  @action checkForSelfAssessmentValidation = () => {
    const levels = this.assessmentForm.filter(a=>(a.participants[0]&&a.participants[0].scale_level&&a.participants[0].scale_level!==""))
    this.selfAssessmentValidation = levels.length === this.assessmentForm.length;
  }
  @action checkForManagerAssessmentValidation = () => {
    const levels = this.assessmentForm.filter(a=>(a.participants[1]&&a.participants[1].scale_level&&a.participants[1].scale_level!==""))
    this.managerValidation = levels.length === this.assessmentForm.length;
  }

  @action getParticipantsCount = (id:string,onFirstLoad?:boolean) => {
    restServices.learningService.getPersonAssessmentForm({personGroupId:id,lang:this.props.rootStore!.userInfo.locale!}).then(res=> {
      if(res !== null&&res[0]){
        this.assessmentForm = JSON.parse(res[0]['value'])
        this.participantsCount = JSON.parse(res[0]['value'])[0].participants.length;
        this.checkForSelfAssessmentValidation()
        this.checkForManagerAssessmentValidation()
      }
    })
    if(onFirstLoad) return;
    restServices.learningService.getPersonAssessments({personGroupId:this.reservistId,lang:this.props.rootStore!.userInfo.language!})
      .then(res=>{
        if(res[0]&&res[0].value) {
          const data: PersonAssessmentRes[] = JSON.parse(res[0].value);
          if(this.newPersonAssessmentId) {
            this.actualAssessment = data.filter((d: PersonAssessmentRes) => d.person_assessment_id === this.newPersonAssessmentId)[0];
          }
          this.allAssessment = data
          const actualAssessment = this.allAssessment.find(i=>i.person_assessment_id === (this.actualAssessment && this.actualAssessment.person_assessment_id))
          if(actualAssessment) this.actualAssessment = actualAssessment;
        }
        const assignments = this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup.assignments
        const actualAssignments = assignments&& assignments.filter(item => item.startDate<this.getDate() && item.endDate>this.getDate());
        const isCurrentEmployee = (id:string):boolean =>{
          if(actualAssignments){
            return actualAssignments.map(aa=>aa.personGroup!.id).includes(id)
          }
          return false
        }
        if(this.dataInstance.item&&isCurrentEmployee(this.props.rootStore!.userInfo.personGroupId!)) {
          if(this.actualAssessment){
            this.dataInstance.item.readinessLevel = this.dicReadinessLevel.find(rl => (this.actualAssessment!.total_result >= rl['min'] && this.actualAssessment!.total_result <= rl['max']))
            if(!this.dataInstance.item.readinessLevel) {
              this.dataInstance.item.readinessLevel = this.dicReadinessLevel.find(rl => rl.code === "THREE_YEAR")
            }}
          this.props.form.setFieldsValue({'readinessLevel': this.dataInstance.item.readinessLevel&&this.dataInstance.item.readinessLevel['_instanceName']})
        }
      })
  }
  @observable addMeButtonLoading:boolean = false;

  @action addMeToSession = ():void => {
    this.addMeButtonLoading = true;
    const item = this.dataInstance.item
    if(item) {
      restServices.learningService.createAssessmentParticipant({
        personAssessmentId: (item.assessment && item.assessment.id),
        personGroupId:this.props.rootStore!.userInfo.personGroupId!,
        code: "LDP"
      })
        .then(() => {
          this.addMeButtonLoading = false;
          this.tableKey = this.tableKey + 1;
          if(this.actualAssessment) {
            this.getParticipantsCount(this.actualAssessment!.person_assessment_id)
          }
        });
    }
  }

  @observable editableFormVisible:boolean=false;

  @observable dicReadinessLevel: SerializedEntity<DicReadinessLevel>[];

  @action setNewAssessment = () => {
    this.editableFormVisible=false
    if(this.newPersonAssessmentId) {
      restServices.learningService.getPersonAssessments({
        personGroupId: this.reservistId,
        lang: this.props.rootStore!.userInfo!.locale!
      }).then((res) => {
        if(res !== null&&res[0]) {
          const data = JSON.parse(res[0]['value']);
          this.actualAssessment = data.filter((d: PersonAssessmentRes) => d.person_assessment_id === this.newPersonAssessmentId)[0];
          if(this.props.form.getFieldsValue(["readinessLevel"])[0]===undefined && this.actualAssessment)
            this.props.form.getFieldDecorator("readinessLevel", {initialValue:this.dicReadinessLevel.find(rl => (this.actualAssessment!.total_result >= rl['min'] && this.actualAssessment!.total_result <= rl['max']))!.id!})
          this.newPersonAssessmentId = undefined;
        }
      })
    }
  }

  @observable competenceLen:number;

  competenceNullMessage = (positionGroupName:string) => {
    Notification.error({message:this.props.intl.formatMessage({id:"noCompetenceMsg"},{positionGroupName:positionGroupName}),duration:6});
  }

  noAssessmentMessageWithoutComp = (positionGroupName:string) => {
    Notification.error({message:this.props.intl.formatMessage({id:"createNewAssessmentWithCompetence.error"},{positionGroupName:positionGroupName}),duration:6});
  }

  noAssessmentMessage = () => {
    Notification.error({message:this.props.intl.formatMessage({id:"fillNewAssessment.error"}),duration:6});
  }

  findActualAssessment = (personGroupId:string) =>{
    this.loadAssessments(personGroupId,this.props.rootStore!.userInfo.locale!)
      .then((res)=>{
        if(res !== null&&res[0]) {
          const data: PersonAssessmentRes[] = JSON.parse(res[0]['value']);
          this.allAssessment = data;
          const assessment = data.find(r => r.person_assessment_id === (this.dataInstance.item && this.dataInstance.item.assessment && this.dataInstance.item.assessment.id))
          if (assessment) this.actualAssessment = assessment
        }
      })
  }

  loadPersonInfo = async (localeProp:string) => {
      try{
        const personProfile = await restServices.employeeService.personProfile(
          this.dataInstance.item&&this.dataInstance.item.personGroup&&this.dataInstance.item.personGroup.id||
          this.reservistId||
          this.props.rootStore!.userInfo.personGroupId );
        const data = {
          parameters: [{
            name: "person",
            value: personProfile.id
          }]
        };
        let locale:string = localeProp === "en"?"PROFILE_TD_EN":"PROFILE_TD_RU"
        runReport(locale, data, this.props.intl);
      }catch(e){
        Notification.error({
          message: this.props.intl.formatMessage({
            id: 'adaptationPlan.employee_profile.NotFound',
            defaultMessage: 'Employee Profile Not Found!',
          }),
        });
      }
  }

  @action showConfirm = () => {
    Modal.info({
      title: this.props.intl.formatMessage({id:"myTeam-personData.choosen"}),
      centered:true,
      content: (
        <div>
          <Btn style={{marginRight:"14px"}} shape="round" icon="download" onClick={()=>this.loadPersonInfo("ru")}>
            Ru
          </Btn>
          <Btn  shape="round" icon="download" onClick={()=>this.loadPersonInfo("en")}>
            En
          </Btn>
        </div>
      ),
      onOk() {},
    });
  }

  render() {
    if (!this.mainStore) return <LoadingPage/>
    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>
    const entityName = this.dataInstance.entityName;
    const {TextArea} = Input
    return (
      <Page pageName={<FormattedMessage id={"successorRequest"}/>}>
        <div style={{textAlign:"right"}}>
          <Button style={{backgroundColor:"green",marginRight:50,height:40,textAlign:"center",paddingRight:40,paddingLeft:40}} onClick={()=>this.showConfirm()}><FormattedMessage id={"employee.profile"}/></Button>
        </div>
        <Section size={"large"}>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}><FormattedMessage id={"close"}/></Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="horizontal">
              <div className={"ant-row ant-form-item"}
                   style={formStyle}>
                <fieldset style={{
                  border: "1px solid #d8d8d8",
                  padding: "5px",
                  borderRadius: "4px",
                  width: "100%",
                  height: "auto",
                }}>
                  <legend style={{
                    width: "auto",
                    padding:"2px",
                    borderBottom:"none",
                    color:"inherit",
                    fontSize:"1.5em",
                    marginBottom:0
                  }}><FormattedMessage id={"vacancyRequest.requestInfo"}/>
                  </legend>
                  <Row>
                    <Col md={22} lg={10} offset={1}>
                      <ReadonlyField
                        disabled
                        entityName={entityName}
                        propertyName="requestNumber"
                        form={this.props.form}
                        formItemOpts={{style:{marginBottom:"5px"}}}
                      />
                    </Col>
                    <Col md={22} offset={1} lg={10}>
                      <Form.Item
                        label={createElement(Msg, {entityName: entityName, propertyName: "initiator"})}
                        style={{marginBottom:"5px" }}>
                        <Input
                          value={this.initiator ? this.initiator['_instanceName'] || '' : ''}
                          disabled/>
                      </Form.Item>
                    </Col>

                  </Row>
                  <Row>
                    <Col md={22} lg={10} offset={1}>
                      <ReadonlyField
                        disabled
                        entityName={entityName}
                        propertyName="requestDate"
                        formItemOpts={{style:{marginBottom:"5px"}}}
                        form={this.props.form}
                      />
                    </Col>
                    <Col md={22} offset={1} lg={10}>
                      <ReadonlyField
                        disabled
                        entityName={entityName}
                        propertyName="status"
                        form={this.props.form}
                        formItemOpts={{style:{marginBottom:"5px"}}}
                        optionsContainer={this.statussDc}
                      />
                    </Col>
                  </Row>
                </fieldset>
              </div>
              <>{this.renderReservistInfo()} {this.renderKeyPositionInfo()} </>
              <div className={"ant-row ant-form-item"}
                   style={formStyle}>
                <fieldset style={{
                  border: "1px solid #d8d8d8",
                  padding: "5px",
                  borderRadius: "4px",
                  width: "100%",
                  height: "auto",
                }}>
                  <legend style={{
                    width: "auto",
                    padding:"2px",
                    borderBottom:"none",
                    color:"inherit",
                    fontSize:"1.5em",
                    marginBottom:0
                  }}><FormattedMessage id={"vacancyRequest.justification"}/>
                  </legend>
                  {this.props.entityId==="new" || !this.isNotDraft() ?
                    <Form.Item style={{marginBottom:"5px",width:"100%"}}>
                      {this.props.form.getFieldDecorator("comment",{
                        rules:[{
                          required:true,
                          message:this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.comment']})
                        }]
                      })(
                        <TextArea required style={{width:"92%", resize:"none",display:"block",marginLeft:"auto",marginRight:"auto"}} value={this.comment} autoSize={{ minRows: 5, maxRows: 15 }} onChange={this.changeComment} />)}
                    </Form.Item>:
                    <Form.Item style={{marginBottom:"5px", textAlign:"center" }}>
                      <TextArea disabled style={{width:"92%", resize:"none"}} autoSize={{ minRows: 5, maxRows: 15 }} value={this.comment} onChange={this.changeComment} />
                    </Form.Item>
                  }
                </fieldset>
              </div>

              {this.takCard()}
            </Form>
          </Card>
        </Section>
      </Page>
    );
  }

  renderReservistInfo = () => {
    return (
      <div>
        <div className={"ant-row ant-form-item"}
             style={formStyle}>
          <fieldset style={{
            border: "1px solid #d8d8d8",
            padding: "5px",
            borderRadius: "4px",
            width: "100%",
            height: "auto",
          }}>
            <legend style={{
              width: "auto",
              padding:"2px",
              borderBottom:"none",
              color:"inherit",
              fontSize:"1.5em",
              marginBottom:0
            }}><FormattedMessage id={"vacancyRequest.potentialReservistInfo"}/>
            </legend>
            <div>
              <Row>
                <Col md={22} lg={10} offset={1}>
                  <Form.Item
                    label={<FormattedMessage id={"vacancyRequest.successor"}/>}
                    style={{marginBottom:"5px" }}
                    required
                  >
                    {
                      this.props.reservistId === "new"?
                        <SearchPerson selectValue={this.reservist&&this.reservist.id} changeValue={(val)=>{this.changeReservistId(val)}} companyCode={this.props.rootStore!.userInfo.companyCode!} />
                        :
                        <Input
                          value={this.reservist ? this.reservist['_instanceName'] || '' : ''}
                          disabled/>
                    }

                  </Form.Item>
                </Col>
                <Col md={22} offset={1} lg={10}>
                  <Form.Item
                    style={{marginBottom:"5px" }}
                    label={<FormattedMessage id={"vacancyRequest.grade"}/>}>
                    <Input
                      value={this.reservist&&this.reservist.currentAssignment&&this.reservist.currentAssignment.gradeGroup? this.reservist.currentAssignment.gradeGroup['_instanceName']:""}
                      disabled/>
                  </Form.Item>
                </Col>

              </Row>
              <Row>
                <Col md={22} lg={10} offset={1}>
                  <Form.Item
                    style={{marginBottom:"5px" }}
                    label={<FormattedMessage id={"vacancyRequest.currentPosition"}/>}>
                    <Input
                      value={(this.reservist&&this.reservist.currentAssignment&&this.reservist.currentAssignment.positionGroup) ? this.reservist.currentAssignment.positionGroup['_instanceName']: ''}
                      disabled/>
                  </Form.Item>
                </Col>
                <Col md={22} offset={1} lg={10}>
                  <Form.Item
                    style={{marginBottom:"5px" }}
                    label={<FormattedMessage id={"vacancyRequest.startDate"}/>}>
                    <Input
                      value={this.reservist&&this.reservist.person&&this.reservist.person.hireDate ? moment(this.reservist.person.hireDate).locale('ru').format('L'): ''}
                      disabled/>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={22} lg={10} offset={1}>
                  <Form.Item
                    style={{marginBottom:"5px" }}
                    label={<FormattedMessage id={"vacancyRequest.organization"}/>}>
                    <Input
                      value={(this.reservist&&this.reservist.currentAssignment&&this.reservist.currentAssignment.organizationGroup) ? this.reservist.currentAssignment.organizationGroup['_instanceName']: ''}
                      disabled/>
                  </Form.Item>
                </Col>
                <Col md={22} offset={1} lg={10}>
                  <Form.Item
                    style={{marginBottom:"5px" }}
                    label={<FormattedMessage id={"vacancyRequest.directSupervisor"}/>}>
                    {
                      this.managers&&this.managers.length>1?
                        <Popover popoverTitle={<FormattedMessage id={"keyPosition.modalTable.ModalDirector"}/>} popoverData={this.managers}>
                          <Input  style={{color:"#005781"}} className={"individual-input"} disabled value={this.managers && this.managers[0]!.fullName! + "+ " +(this.managers.length-1)}/>
                        </Popover>
                        : this.managers&&this.managers.length===1? <Input disabled value={this.managers && this.managers[0]!.fullName!}/>
                          :<Input disabled value={""}/>
                    }
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </fieldset>
        </div>
      </div>
    );
  }

  renderKeyPositionInfo = () => {
    const {Option} = Select;
    const assignments = this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup.assignments
    const actualAssignments = assignments&& assignments.filter(item => item.startDate<this.getDate() && item.endDate>this.getDate());
    const isCurrentEmployee = (id:string):boolean =>{
      if(actualAssignments){
        return actualAssignments.map(aa=>aa.personGroup!.id).includes(id)
      }
      return false
    }
    const actualAssignmentsPG = actualAssignments&&actualAssignments[0]&&actualAssignments[0].personGroup&&actualAssignments[0].personGroup['_instanceName']
    const entityName = this.dataInstance.entityName;
    const readinessLevel = this.dataInstance.item&&this.dataInstance.item.readinessLevel?this.dataInstance.item.readinessLevel:"";
    const successorStatus = this.dataInstance.item&&this.dataInstance.item.successorStatus?this.dataInstance.item.successorStatus:"";
    const messages = this.mainStore.messages;
    const item = this.dataInstance.item;
    if (!messages) return <LoadingPage/>
    return (
      <>
        {this.actualAssessment?
          <Modal onCancel={(e)=>runInAction(()=>{
            e.preventDefault();
            this.assessmentFormVisible=false;
            if(item){
              this.getParticipantsCount(this.actualAssessment!.person_assessment_id!);
            }})}
                 visible={this.assessmentFormVisible} centered width={"80%"}
                 footer={[
                   <Button style={((isCurrentEmployee(this.props.rootStore!.userInfo.personGroupId!)) && this.participantsCount < 2) ?{backgroundColor:"green",color:"white"}:{display:"none"}}
                           onClick={()=>{
                             this.addMeToSession();
                           }}>
                     <FormattedMessage id="addMeToSessionSuccessor"/></Button>,
                   <Button type={"primary"} style={{width:150}} onClick={(e)=>{
                     e.preventDefault();
                     runInAction(()=>{
                       this.assessmentFormVisible=false;
                       this.getParticipantsCount(this.actualAssessment!.person_assessment_id!);
                     });
                   }}>OK</Button>]}>
            <EditableAssessmentForm
              key={this.tableKey}
              personGroupId={item&&item.personGroup&&item.personGroup.id}
              personAssessmentId={this.actualAssessment!.person_assessment_id}
              positionGroupName={this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup['_instanceName']}
              keyPosHoldersPersonGroups={actualAssignments&&actualAssignments.map(aa=>aa.personGroup)}
            />
          </Modal>
          :<></>
        }
        {this.personAssessment?
          <Modal  visible={this.editableFormVisible} width={"80%"} centered onCancel={(e)=>{
            e.preventDefault();
            this.newPersonAssessmentId = undefined;
            this.alreadyCreated = false;
            this.editableFormVisible = false;
            getCubaREST()!.deleteEntity(PersonAssessment.NAME,this.personAssessment&&this.personAssessment.id).then(()=>"Deleted")
          }}
                  footer={[
                    <Button type={"primary"} style={{width:150}} onClick={(e)=>{
                      e.preventDefault();
                      this.setNewAssessment();
                    }}>OK</Button>]}
          >
            <EditableAssessmentForm
              personGroupId={this.reservistId}
              personAssessmentId={this.personAssessment.id}
              positionGroupName={this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup['_instanceName']}
            />
          </Modal>:
          <></>
        }
        <div className={"ant-row ant-form-item"}
             style={formStyle}>
          <fieldset style={{
            border: "1px solid #d8d8d8",
            padding: "5px",
            borderRadius: "4px",
            width: "100%",
            height: "auto",
          }}>
            <legend style={{
              width: "auto",
              padding:"2px",
              borderBottom:"none",
              color:"inherit",
              fontSize:"1.5em",
              marginBottom:0
            }}><FormattedMessage id={"vacancyRequest.keyPositionInfo"}/>
            </legend>
            <Row>
              <Col md={22} lg={10} offset={1}>
                <Form.Item
                  style={{marginBottom:"5px" }}
                  label={<FormattedMessage id={"vacancyRequest.keyPosition"}/>}
                  required>
                  {this.props.positionGroupId||this.positionGroupId?
                    <Input
                      value={this.keyPositionInstance&&this.keyPositionInstance.item? this.keyPositionInstance.item['_instanceName']: ''}
                      disabled
                    />:
                    this.props.form.getFieldDecorator('positionGroup',{
                      rules:[{
                        required:true,
                        message:this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.intl.formatMessage({id:"positionGroup"})})
                      }]
                    })(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      onChange={this.changeKeyPos}
                      loading={this.keyPositionCollection.status==="LOADING"}
                    >
                      {this.keyPositionCollection&&this.keyPositionCollection.status==="DONE"&&this.keyPositionCollection.items.map(item=><Option key={item.id}>{item['_instanceName'] + ` (${item.positionGroup&&item.positionGroup.company&&item.positionGroup.company['_instanceName']})`}</Option>)}
                    </Select>)}
                </Form.Item>
              </Col>
              <Col md={22} offset={1} lg={10}>
                <Form.Item
                  style={{marginBottom:"5px" }}
                  label={<FormattedMessage id={"vacancyRequest.programStartDate"}/>}>
                  <Input
                    value={this.keyPositionInstance&&this.keyPositionInstance.item&&this.keyPositionInstance.item.currentSuccessionPlanningHistory?moment(this.keyPositionInstance.item.currentSuccessionPlanningHistory.date).locale('ru').format('L'):""}
                    disabled/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={22} lg={10} offset={1}>
                <Form.Item
                  style={{marginBottom:"5px" }}
                  label={<FormattedMessage id={"vacancyRequest.organization"}/>}>
                  <Input
                    value={this.keyPositionInstance&&this.keyPositionInstance.item&&this.keyPositionInstance.item.positionGroup&&this.keyPositionInstance.item.positionGroup.organizationGroup?this.keyPositionInstance.item.positionGroup.organizationGroup['_instanceName']:""}
                    disabled/>
                </Form.Item>
              </Col>
              <Col md={22} offset={1} lg={10}>
                <Form.Item
                  style={{marginBottom:"5px" }}
                  label={<FormattedMessage id={"vacancyRequest.currentEmployee"}/>}>
                  {
                    actualAssignments&& actualAssignments.length>1?
                      <Popover popoverTitle={<FormattedMessage id={"vacancyRequest.currentEmployee"}/>} popoverData={actualAssignments.map(item=>item.personGroup)}>
                        <Input  style={{color:"#005781"}} className={"individual-input"} disabled value={actualAssignmentsPG +" + " + (actualAssignments.length-1)}/>
                      </Popover>
                      : actualAssignments&&actualAssignments.length===1? <Input disabled value={actualAssignmentsPG}/>
                        :<Input disabled value={""}/>
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={22} lg={10} offset={1}>
                <Form.Item
                  style={{marginBottom:"5px" }}
                  label={<FormattedMessage id={"vacancyRequest.supervisor"}/>}>
                  {
                    this.keyPosManagers&&this.keyPosManagers.length>1?
                      <Popover popoverTitle={<FormattedMessage id={"vacancyRequest.supervisor"}/>} popoverData={this.keyPosManagers}>
                        <Input  style={{color:"#005781"}} className={"individual-input"} disabled value={this.keyPosManagers && this.keyPosManagers[0]!.fullName! + "+ " +(this.keyPosManagers.length-1)}/>
                      </Popover>
                      : this.keyPosManagers&&this.keyPosManagers.length===1? <Input disabled value={this.keyPosManagers && this.keyPosManagers[0]!.fullName!}/>
                        :<Input disabled value={""}/>
                  }
                </Form.Item>
                {this.props.entityId==="new" || !this.isNotDraft() ?
                  <ReadonlyField
                    form={this.props.form}
                    entityName={entityName}
                    disabled
                    propertyName={"successorStatus"}
                    optionsContainer={this.successorStatusDc}
                    formItemOpts={{label:this.props.intl.formatMessage({id:"reservistStatus"})}}
                    getFieldDecoratorOpts={{rules:[{
                        required:true,
                        message:this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.intl.formatMessage({id:"reservistStatus"})})
                      }]}}
                  />
                  :
                  <Form.Item label={this.props.intl.formatMessage({id:"reservistStatus"})} style={{marginBottom:"5px"}}>
                    {this.props.form.getFieldDecorator("successorStatus")}
                    <Input disabled value={successorStatus['_instanceName']}/>
                  </Form.Item>
                }
              </Col>
              <Col>
                <Col md={22} lg={10} offset={1}>
                  <Form.Item label={this.props.intl.formatMessage({id:"keyPosPercent"})} style={{marginBottom:5}} labelCol={{style:{marginBottom:0}}}>
                    <a style={{marginRight:10}} onClick={()=>runInAction(()=>this.actualAssessment?this.assessmentFormVisible = true:this.createNewSessionAlert())} >
                      {this.actualAssessment&&this.actualAssessment.total_result||0}%
                    </a>
                    {this.alreadyCreated||(this.reservistId&&this.reservistId !== this.props.rootStore!.userInfo.personGroupId)?
                      <></>:
                      <a style={this.competenceLen>0?{textDecoration:"underline"}:{pointerEvents:"none",cursor:"not-allowed"}} onClick={()=>this.createNewSession()}><FormattedMessage id="createNewEstimation"/></a>
                    }
                  </Form.Item>
                  {this.props.entityId==="new" || !this.isNotDraft() ?
                    <ReadonlyField
                      form={this.props.form}
                      entityName={entityName}
                      disabled={true}
                      propertyName={"readinessLevel"}
                      optionsContainer={this.readinessLevelDc}
                      formItemOpts={{style:{marginBottom:"5px"}}}
                      getFieldDecoratorOpts={{rules:[{
                          message:this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.readinessLevel']})
                        }]}}
                    />
                    :
                    <Form.Item label={<Msg entityName={this.dataInstance.entityName} propertyName={"readinessLevel"}/>} style={{marginBottom:"5px"}}>
                      {this.props.form.getFieldDecorator("readinessLevel")}
                      <Input disabled value={readinessLevel['_instanceName']}/>
                    </Form.Item>
                  }
                </Col>
              </Col>
            </Row>
          </fieldset>
        </div>
      </>
    );
  }

  componentDidMount() {
    super.componentDidMount();
    getCubaREST()!.loadEntities<DicReadinessLevel>(DicReadinessLevel.NAME, {view: "dicReadinessLevel-edit"}).then(levels => {
      runInAction(() =>
        this.dicReadinessLevel = levels
      )})
    this.loadPositionInfo(this.props.positionGroupId?this.props.positionGroupId:this.positionGroupId);
    this.findManagers(this.props.reservistId?this.props.reservistId:this.reservistId);
    this.keyPositionInstance.load(this.props.positionGroupId?this.props.positionGroupId:this.positionGroupId);
  }

  initItem = (request:SuccessorRequest) => {
    this.dataInstance.setItem(request);

    const fieldValues = this.dataInstance.getFieldValues(this.fields);
    this.props.form.setFieldsValue(fieldValues);

    when(()=>this.successorStatusDc.items&&this.successorStatusDc.items.length>0&&this.props.entityId==='new',() => {
      this.props.form.setFieldsValue({'successorStatus': this.successorStatusDc.items.find(s => s.code === "IN_PROCESS")!.id})
    })
  }

  setReactionDisposer = () => {
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      (item:SuccessorRequest) => {
        this.onReactionDisposerEffect(item);

        this.props.form.setFieldsValue(this.onReactionFieldsValue(item));
      }
    );
  };

  onReactionDisposerEffect = (item: SuccessorRequest) => {
    this.initiatorId = item && item.initiator ? item.initiator.id! : this.props.initiatorId;
    this.reservistId = item && item.personGroup ? item.personGroup.id! : this.props.reservistId;
    this.positionGroupId = item && item.positionGroup ? item.positionGroup.id : this.props.positionGroupId;
    this.comment = item && item.comment?item.comment:"";
    this.readinessLevel = item && item.readinessLevel!;
    this.assessment = item && item.assessment;
    if(this.props.entityId!=='new'){
     this.findActualAssessment(this.dataInstance.item&&this.dataInstance.item.personGroup&&this.dataInstance.item.personGroup.id)
      this.getParticipantsCount(this.assessment&&this.assessment.id,true)
    }
    getCubaREST()!.searchEntities<CompetenceElement>(CompetenceElement.NAME,{
      conditions:[{
        property:"positionGroup.id",
        operator:"=",
        value:this.positionGroupId
      }]
    },{view:"_local"}).then(ce=>{
      this.competenceLen = ce.length;
    })
    getCubaREST()!.searchEntities<SuccessionPlanning>(SuccessionPlanning.NAME,{
      conditions:[{
        property:"positionGroup.id",
        operator:"=",
        value:this.positionGroupId
      }]
    }).then(value => this.changeKeyPos(value[0].id) )
    this.loadReservist()
    this.keyPositionInstance.load(item&&item.positionGroup && item.positionGroup.id)

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();

    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.initiatorId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: '_minimal'
    }).then(value => value[0])
      .then(value => this.initiator = value)
    this.reservistId = item && item.personGroup ? item.personGroup.id! : this.props.reservistId;
  }
}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(SuccessorRequestEditComponent))
);
