import React, {ChangeEvent} from "react";
import {restServices} from "../../../../cuba/services";
import {action, observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {PreferencePojo} from "../../../../cuba/entities/base/tsadv$PreferencePojo";
import {Modal, Row, Spin} from "antd";
import TextArea from "antd/es/input/TextArea";
import {PersonGroupExt} from "../../../../cuba/entities/base/base$PersonGroupExt";
import {injectIntl, WrappedComponentProps} from "react-intl";

type PersonPreferencesEditModalProps = {
  personGroupId: string;
  visible: boolean;
  onClose: () => void;
  onSaved: () => void;
}

@observer
class PersonPreferencesEditModal extends React.Component<PersonPreferencesEditModalProps & WrappedComponentProps> {

  @observable wasChanged = false;
  @observable personPreferences?: PreferencePojo[] = [];
  @observable isSaving = false;

  componentDidMount() {
    restServices.recognitionService.loadPersonPreferences(this.props.personGroupId)
      .then(preferences => runInAction(() => this.personPreferences = preferences));
  }

  renderPreference = (personPreference: PreferencePojo) => {
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      personPreference.description = e.target.value;
      runInAction(() => this.wasChanged = true);
    }

    return <>
      <Row type={"flex"} justify={"space-between"}>
        <p>{personPreference.typeName}</p>
        <p>{`+${personPreference.coins} coins`}</p>
      </Row>
      <TextArea value={personPreference.description!} onChange={onChange} rows={4}/>
    </>
  }

  @action save = () => {
    const personGroup = {id: this.props.personGroupId} as PersonGroupExt;
    this.isSaving = true;
    restServices.recognitionService.savePersonPreference(personGroup, this.personPreferences!)
      .then(this.props.onSaved)
      .finally(() => this.isSaving = false)
      .then(this.props.onClose);
  }

  render() {
    return (
      <Modal visible={this.props.visible}
             title={this.props.intl.messages["recognition.personPreference.modal.title"]}
             onOk={this.save}
             onCancel={this.props.onClose}
             okButtonProps={{disabled: !this.wasChanged}}>
        {this.personPreferences !== undefined
          ? this.personPreferences.map(this.renderPreference)
          : <Row type={"flex"} justify={"center"}>
            <Spin size={"large"}/>
          </Row>
        }
      </Modal>
    )
  }
}

export default injectIntl(PersonPreferencesEditModal);