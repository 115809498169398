import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicPhoneType } from "./tsadv$DicPhoneType";
import { PersonContact } from "./tsadv$PersonContact";
export class PersonContactRequest extends AbstractBprocRequest {
  static NAME = "tsadv_PersonContactRequest";
  personGroup?: PersonGroupExt | null;
  type?: DicPhoneType | null;
  contactValue?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  personContact?: PersonContact | null;
}
export type PersonContactRequestViewName = "_base" | "_local" | "_minimal";
export type PersonContactRequestView<
  V extends PersonContactRequestViewName
> = V extends "_base"
  ? Pick<
      PersonContactRequest,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "contactValue"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      PersonContactRequest,
      | "id"
      | "contactValue"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<PersonContactRequest, "id" | "requestNumber" | "requestDate">
  : never;
