import { AbstractParentEntity } from "./AbstractParentEntity";
import { AssessmentParticipant } from "./tsadv$AssessmentParticipant";
import { CompetenceGroup } from "./tsadv$CompetenceGroup";
import { DicOverallRating } from "./tsadv$DicOverallRating";
import { PersonAssessment } from "./tsadv_PersonAssessment";
import { ScaleLevel } from "./tsadv$ScaleLevel";
import { DicMatchResult } from "./tsadv_DicMatchResult";
export class AssessmentCompetence extends AbstractParentEntity {
  static NAME = "tsadv$AssessmentCompetence";
  participantAssessment?: AssessmentParticipant | null;
  competenceGroup?: CompetenceGroup | null;
  overallRating?: DicOverallRating | null;
  comment?: string | null;
  weight?: any | null;
  competenceSource?: any | null;
  personAssessment?: PersonAssessment | null;
  requiredScaleLevel?: ScaleLevel | null;
  resultPercent?: any | null;
  result?: DicMatchResult | null;
  requiredDevelopmet?: boolean | null;
}
export type AssessmentCompetenceViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "assessmentCompetence-for-apab";
export type AssessmentCompetenceView<
  V extends AssessmentCompetenceViewName
> = V extends "_base"
  ? Pick<
      AssessmentCompetence,
      | "id"
      | "comment"
      | "weight"
      | "competenceSource"
      | "resultPercent"
      | "requiredDevelopmet"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      AssessmentCompetence,
      | "id"
      | "comment"
      | "weight"
      | "competenceSource"
      | "resultPercent"
      | "requiredDevelopmet"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "assessmentCompetence-for-apab"
  ? Pick<
      AssessmentCompetence,
      | "id"
      | "participantAssessment"
      | "competenceGroup"
      | "personAssessment"
      | "requiredDevelopmet"
    >
  : never;
