import {Button, DatePicker, Form, Input, Modal, Select} from "antd";
import {FormComponentProps} from "antd/es/form";
import {getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../store";
import {Competence} from "../../../../cuba/entities/base/tsadv$Competence";
import {SerializedEntity} from "@cuba-platform/rest";
import {DicEducationType} from "../../../../cuba/entities/base/tsadv$DicEducationType";
import {DicIdpActionType} from "../../../../cuba/entities/base/tsadv_DicIdpActionType";
import {Course} from "../../../../cuba/entities/base/tsadv$Course";
import {inject, observer} from "mobx-react";
import * as React from "react";
import {action, observable, runInAction} from "mobx";
import {CourseSchedule} from "../../../../cuba/entities/base/tsadv_CourseSchedule";
import moment, {Moment} from "moment";
import {IdpDetail} from "../../../../cuba/entities/base/tsadv$IdpDetail";
import {IndividualDevelopmentPlan} from "../../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";
import {IdpDetailsActionTypeCodes} from "../../../../cuba/enums/enums";
import SearchCourse from "../../../components/SearchCourse";

type AddTaskProps = {
  competence:Competence,
  educationTypes:SerializedEntity<DicEducationType>[],
  actionTypes:SerializedEntity<DicIdpActionType>[],
  competenceCourses:Course[],
  taskCourses:Course[]
}

@injectMainStore
@inject("rootStore")
@observer
class AddTaskModalComponent extends React.Component<AddTaskProps & FormComponentProps &  MainStoreInjected & WrappedComponentProps & RootStoreProp>{

  @action changeCreateTaskVisible(value: boolean) {
    if(this.props.rootStore!.idpStore) this.props.rootStore!.idpStore.createTaskVisible = value
  }

  uuidv4 = () => {
    return 'new' + 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  @observable taskName: string;

  @action changeTaskName(value: string) {
    this.taskName = value
  }

  @observable taskDescription: string;

  @action changeTaskDescription(value: string) {
    this.taskDescription = value
  }

  @observable taskEducationType:DicEducationType;

  @observable taskCourse:Course;

  @observable taskCourseId:string;

  @action getCourseData(value:string){
    getCubaREST()!.loadEntity<CourseSchedule>(CourseSchedule.NAME,value,{view:"_local"}).then(course=>{
      runInAction(()=>this.taskCourse = course)
    })
  }

  @observable taskActionType: DicIdpActionType;

  @observable taskPlanDate: Moment = moment().endOf('year');

  @action.bound changeTaskPlanDate(date: Moment | null) {
    if (date) {
      this.taskPlanDate = date
    }
  }

  @action.bound handleOk() {
    const store = this.props.rootStore!.idpStore;
    this.props.form.validateFields((err)=>{
      if(!err){
        const task:IdpDetail ={
          id:this.uuidv4(),
          goalName:this.taskName,
          description:this.taskDescription,
          educationType:this.taskEducationType,
          actionType:this.taskActionType,
          done:false,
          competence:this.props.competence,
          targetDate:this.taskPlanDate.toDate(),
          course:this.taskCourse
        }
        if(store){
          store.setIdpDetail(task as SerializedEntity<IdpDetail>)
          store.calculatePercent()
        }
        this.changeCreateTaskVisible(false)
        this.props.form.resetFields()
        this.taskCourseId = ""
        this.taskName = ""
        this.taskActionType = {}
    }})
  }

  @action.bound handleCancel() {
    this.changeCreateTaskVisible(false)
  }

  render() {
    const {Option} = Select
    const messages = this.props.mainStore!.messages!;
    const store = this.props.rootStore!.idpStore &&this.props.rootStore!.idpStore

    return (<Modal
      visible={store&&store.createTaskVisible}
      title={<FormattedMessage id={"addCompetenceTask"}/>}
      onCancel={this.handleCancel}
      key={"taskForCompetence"}
      bodyStyle={{padding:5}}
      centered
      footer={[
        <Button key="back" onClick={this.handleCancel}>
        <FormattedMessage id={"management.browser.delete.cancel"}/>
        </Button>,
        <Button key="submit" type="primary" onClick={this.handleOk}>
        <FormattedMessage id={"save"}/>
        </Button>,
      ]}
    >
      <Form>
        <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"planDate"}/>}>
          {this.props.form.getFieldDecorator("targetDate",{
            rules:[{
              required:true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.targetDate']}),
            }]
          })(
            <DatePicker locale={"ru"} format={'L'} onChange={this.changeTaskPlanDate}/>)}
        </Form.Item>
        <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"task"}/>}>
          {this.props.form.getFieldDecorator("goalName",{
            rules:[{
              required:true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.goalName']}),
            }]
          })(
            <Input onChange={(event) => this.changeTaskName(event.target.value)}/>)}
        </Form.Item>
        <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"educationType"}/>}>
          {this.props.form.getFieldDecorator("educationType",{
            rules:[{
              required:true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.educationType']}),
            }]
          })(
            <Select onChange={((value:string) => runInAction(()=>{
              const educationType = this.props.educationTypes.find(type => type.id === value)
              if(educationType) this.taskEducationType = educationType
            }))}>
              {this.props.educationTypes && this.props.educationTypes.map(value => <Option key={value.id}
                                                                                           value={value.id}>{value._instanceName}</Option>)}
            </Select>)}
        </Form.Item>
        <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"actionType"}/>}>
          {this.props.form.getFieldDecorator("actionType",{
            rules:[{
              required:true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.actionType']}),
            }]
          })(
            <Select onChange={((value:string) => runInAction(()=>{
              const actionType = this.props.actionTypes.find(type => type.id === value)
              if(actionType) this.taskActionType = actionType
            }))}>
              {this.props.actionTypes && this.props.actionTypes.map(value => <Option key={value.id}
                                                                                     value={value.id}>{value._instanceName}</Option>)}
            </Select>)}

        </Form.Item>
        {this.taskActionType && this.taskActionType.code === IdpDetailsActionTypeCodes.TRAINING ?
          <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"course"}/>}>
            {this.props.form.getFieldDecorator("course",{
              rules:[{
                required:true,
                message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.course']}),
                validator: (() => this.taskCourseId ? Promise.resolve() : Promise.reject(new Error())),
              }]
            })(
              this.props.competenceCourses&&this.props.competenceCourses.length===0?
                <SearchCourse changeValue={(value: string) => runInAction(() => {
                  this.taskCourseId = value
                  getCubaREST()!.loadEntity<Course>(Course.NAME,value,{view:"_local"}).then(course=>this.taskCourse = course)
                  this.getCourseData(value)
                })} selectValue={this.taskCourseId} entityValue={(course:Course)=>runInAction(()=>this.taskCourse = course)}/>
                :
                <Select onChange={(value:string)=>runInAction(()=>{
                  const course = this.props.taskCourses&&this.props.taskCourses.find(c=>c.id === value)
                  this.taskCourseId = value
                  if(course) this.taskCourse = course
                })}>
                  {this.props.taskCourses?this.props.taskCourses.map(c => <Option key={c.id} value={c.id}>{c.name}</Option>):undefined}
                </Select>)}
          </Form.Item>:
          <></>
        }
        <Form.Item className="taskDetailModalItem" label={<FormattedMessage id={"description"}/>}>
          {this.props.form.getFieldDecorator("description",{
            rules:[{
              required:true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[IndividualDevelopmentPlan.NAME + '.' + '.course']}),
            }]
          })(
            <Input onChange={(event) => this.changeTaskDescription(event.target.value)}/>)}
        </Form.Item>
      </Form>
    </Modal>)
  }
}

export const AddTaskModal =  Form.create<AddTaskProps & FormComponentProps &  MainStoreInjected & WrappedComponentProps & RootStoreProp>()(injectIntl(AddTaskModalComponent))
