import * as React from "react";
import {injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {observable} from "mobx";
import {observer} from "mobx-react";
import {getBlobUrl} from "../../../util/util";
import moment from "moment";
import Img from "../../../components/Img";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {Button, Form, Input, List, Modal} from "antd";
import {PersonGroup} from "../../../../cuba/entities/base/base$PersonGroup";
import {Successor} from "../../../../cuba/entities/base/tsadv$Successor";

interface Props {
  isVisible: boolean,
  personData: Successor | undefined,
  toggleVisibility: () => void,
  organizationGroup: string
}

class SuccessorProfileModal extends React.Component<MainStoreInjected & WrappedComponentProps & Props> {

  render() {
    const {personData, isVisible, toggleVisibility, organizationGroup} = this.props;
    if (isVisible) {
      return (

        <Modal centered={true} bodyStyle={{display: "flex", justifyContent: "center"}} width={1000} closable={false}
               style={{width: "auto", height: "auto"}}
               title={this.props.intl.formatMessage({id: "reservist.profileModalName"})}
               footer={[<Button style={{width: 150}} type={"primary"}
                                onClick={toggleVisibility}>{this.props.intl.formatMessage({id: "reservistOk"})}</Button>]}
               visible={isVisible}>
          <Form layout={"horizontal"}
                style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <Form.Item style={{marginBottom: 0, paddingBottom: 0}}>
                {<div style={{height: "100%", width: "300px"}}><Img style={{height: "100%", width: "300px"}}
                                                                    type={"promise"}
                                                                    src={getBlobUrl(personData!.personGroup && personData!.personGroup.list && personData!.personGroup.list[0].image && personData!.personGroup.list[0].image.id)}/>
                </div>}
              </Form.Item></div>
            <div style={{width: "65%"}}>
              <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr", alignItems: "center", marginBottom: 24
              }}>
                {this.props.intl.formatMessage({id: "reservist.fullName"})} <Input style={{marginLeft: 10}}
                                                                                   disabled={true}
                                                                                   value={personData!.personGroup ? personData!.personGroup["_instanceName"] : ""}/>
              </div>
              <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr", alignItems: "center", marginBottom: 24
              }}>

                {this.props.intl.formatMessage({id: "reservist.keyPosition"})} <Input style={{marginLeft: 10}}
                                                                                      disabled={true}
                                                                                      value={personData!.succession! ? personData!.succession!["_instanceName"] : ""}/>
              </div>
              <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr", alignItems: "center", marginBottom: 24
              }}>
                {this.props.intl.formatMessage({id: "reservistOrganizationGroup"})} <Input style={{marginLeft: 10}}
                                                                                           disabled={true}
                                                                                           value={organizationGroup ? organizationGroup : ""}/>
              </div>
              <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr", alignItems: "center", marginBottom: 24
              }}>
                {this.props.intl.formatMessage({id: "employee.hireDate"})} <Input style={{marginLeft: 10}}
                                                                                  disabled={true}
                                                                                  value={personData!.personGroup && personData!.personGroup!.list! && personData!.personGroup!.list![0] && moment(personData!.personGroup!.list![0].hireDate).format("DD.MM.YYYY") || ""}/>
              </div>
              <div style={{
                display: "grid",
                gridTemplateColumns: "1fr 4fr", alignItems: "center", marginBottom: 0
              }}>
                {this.props.intl.formatMessage({id: "reservistUpdateDate"})} <Input style={{marginLeft: 10}}
                                                                                     disabled={true}
                                                                                     value={personData ? moment(personData.startDate).format("DD.MM.YYYY") : ""}/>
              </div>
            </div>
          </Form>
        </Modal>)
    } else {
      return null;
    }

  }
}

export default injectIntl(SuccessorProfileModal)