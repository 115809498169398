import * as React from "react";
import {observer} from "mobx-react";
import DictionaryEmployee from "./DictionaryEmployee";

@observer
export class DictionaryEmployeeManagement extends React.Component {
  static PATH = "/dictionaries/employees";

  render() {
    return (
      <>
        <DictionaryEmployee/>
      </>
    );
  }
}
