import React, { Component } from 'react';
import Page from "../../hoc/PageContentHoc";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, Col, Modal, Row, Table } from "antd";
import { SplitPane } from "react-multi-split-pane/dist";
import { restServices } from "../../../cuba/services";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { RootStoreProp } from "../../store";
import TreeOrganization, { OrganizationNode } from "./Tree/TreeOrganization";
import Column from "antd/es/table/Column";
import Img from "../../components/Img";
import { PersonProfile } from "../MyTeam/MyTeamCard";
import { getFileUrl} from "../../util/util";
import Search from "antd/es/input/Search";
import { getCubaREST, injectMainStore } from "@cuba-platform/react";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import moment from "moment";
import Notification from "../../util/Notification/Notification";
import { SerializedEntity } from "@cuba-platform/rest";
import { PositionGroup } from "../../../cuba/entities/base/base$PositionGroup";
import { DicCompany } from "../../../cuba/entities/base/tsadv$DicCompany";
import { PersonContact } from "../../../cuba/entities/base/tsadv$PersonContact";
import { string } from "@amcharts/amcharts4/core";
import { TsadvUser } from '../../../cuba/entities/base/tsadv$UserExt';

export const NAME = "/dictionaries/employees";

type UserTableRow = {
  id: string,
  name: string,
  positionGroup: string,
  organization: string,
  data?: PersonProfile,
  lineManager?: string
}

type OrganInfo = {
  employeeFullName: string,
  managerFullName: string,
  positionGroup: string,
  organization: string,
  personGroupId: string
}

@injectMainStore
@inject("rootStore")
@observer
class DictionaryEmployee extends Component<WrappedComponentProps & RootStoreProp> {

  @observable
  mainSplitPaneDefaultSizes: number[] = [5, 10];

  @observable
  users: UserTableRow[]

  @observable
  loadingTable = false;

  @observable
  personGroupId: string = '';

  @observable
  userData: any;

  @observable
  selectedEmployee: any | null;

  @observable
  visibleModal: boolean = false;

  selectedOrganizationGroupId: string | null = null;

  showModal = () => {
    this.visibleModal = true
  };

  handleCancel = (e: any) => {
    this.visibleModal = false
  };

  @action
  setLoadingTable = (bool: boolean) => {
    this.loadingTable = bool;
  }

  componentDidMount() {
    if (this.props.rootStore &&
        this.props.rootStore.userInfo &&
        this.props.rootStore.userInfo.organizationGroupId) {
      let organiztionGroupId = this.props.rootStore!.userInfo.organizationGroupId;
      this.loadEmployees(organiztionGroupId);
    }
  }

  render() {
    return (
      <Page pageName={this.props.intl.formatMessage({ id: "menu.dictionaryEmployees" })}
        pageBodyClasses="total-body-height">
        <div style={{ height: "100%", position: 'relative' }}>
          <SplitPane key={'mainSplitPane'}
            split="vertical"
            defaultSizes={this.mainSplitPaneDefaultSizes}
            onDragFinished={sizes => this.saveDefaultSettings(sizes)}>
            <TreeOrganization onSelectNode={this.selectTreeNode} />
            <div style={{ height: '100%', width: '100%', overflow: 'auto' }}>
              <Search style={{ padding: '10px 5px 10px 10px' }}
                placeholder={this.props.intl.formatMessage({ id: 'search' })}
                onSearch={this.onSearchEmployees} />
              <Table dataSource={this.users} pagination={{
                defaultPageSize: 10
              }} loading={this.loadingTable}
                size="default" bordered={false} rowKey="id">
                <Column title={this.props.intl.formatMessage({ id: "employee" })}
                  dataIndex={"name"}
                  key={"name"} render={(text, record: UserTableRow) => {
                    return <Button
                      style={{ border: "none", background: "transparent", color: "#005781" }}
                      onClick={() => {
                        this.setSelectedEmployee(record.data).then(this.showModal)
                      }
                      }>  {record.name} </Button>


                  }} />
                <Column title={<FormattedMessage id="positionGroup" />}
                  dataIndex={"positionGroup"}
                  key={"positionGroup"} render={(text, record: UserTableRow, index) => {
                    return record.positionGroup;
                  }} />
                <Column title={<FormattedMessage id="organization" />}
                  dataIndex={"organization"}
                  key={"organization"} render={(text, record: UserTableRow, index) => {
                    return record.organization;
                  }} />
              </Table>
            </div>
          </SplitPane>
        </div>
        <Modal
          visible={this.visibleModal}
          footer={null}
          onCancel={this.handleCancel}
          destroyOnClose={true}
          width={725}
        >

          <div className="course-trainer-modal">
            <Row style={{ marginTop: '20px' }} type={"flex"} justify="center">
              <Col sm={7} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                <div className="course-trainer-modal-image">
                  {this.getImageComponent()}
                </div>
              </Col>
              <Col sm={1} xs={0}>
              </Col>
              <Col sm={16} xs={24}>
                <div className="course-trainer-modal-content">
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="employee" /> </span>: {this.selectedEmployee ? this.selectedEmployee!.fullName : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="positionGroup" /> </span> : {this.selectedEmployee ? this.selectedEmployee!.positionName : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="organization" /> </span>: {this.selectedEmployee ? this.selectedEmployee!.organizationName : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="company" /> </span>: {this.selectedEmployee ? this.selectedEmployee!.companyCode : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="manager" /> </span>: {this.selectedEmployee && this.selectedEmployee.managerList
                          ? this.selectedEmployee.managerList.map((m: any) => m).join(", ")
                          : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="phoneNumber" /> </span>: {this.selectedEmployee ? this.selectedEmployee!.phone : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="email" /> </span>: {this.selectedEmployee ? this.selectedEmployee!.login : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>  <FormattedMessage
                        id="Telegram" /> </span>: <a href={`https://t.me/${this.selectedEmployee ? this.selectedEmployee!.telegram : ""}`} target="_blank"> {this.selectedEmployee ? this.selectedEmployee!.telegram : null} </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"form-item-container"} style={{ marginTop: '32px' }}>
                      <span style={{ fontWeight: "bolder", color: "#005781" }}>WhatsApp</span>: <a href={`https://wa.me/${this.selectedEmployee ? this.selectedEmployee!.whatsApp : ""}`} target="_blank"> {this.selectedEmployee ? this.selectedEmployee!.whatsApp : null} </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </Page>
    );
  }

  getImageComponent = () => {
    if (!this.selectedEmployee) {
      return null;
    }
    
    if (this.selectedEmployee.imageId) {
      return <Img type="src" src={getFileUrl(this.selectedEmployee!.imageId)}/>
    }

    return <Img type="src" src={require("../../../resources/img/default-user.svg")}/>
  }

  saveDefaultSettings = (sizes: number[]) => {
    restServices.userSettingService.saveSetting('MyTeamComponent.mainSplitPane', '[' + sizes + ']');
  }

  loadDefaultSettings = () => {
    restServices.userSettingService.loadSetting<number[]>('DictionaryEmployees.mainSplitPane')
      .then(value => {
        if (value) this.mainSplitPaneDefaultSizes = value;
      });
  };

  @action
  selectTreeNode = (selectedRow: OrganizationNode) => {
    this.loadEmployees(selectedRow.data.organizationGroupId);
  };

  loadEmployees = async (selectedOrganizationGroupId: string) => {
    this.selectedOrganizationGroupId = selectedOrganizationGroupId;
    this.setUsers([]);
    this.loadEmployeesData()
      .catch(e => {
        this.setLoadingTable(false);
        Notification.error({
          description: e.message,
          message: this.props.intl.formatMessage({ id: "management.editor.error" })
        });
      })
  }

  onSearchEmployees = (searchText: string) => {
    this.setUsers([]);
    (async () => {
      await this.loadEmployeesData(searchText);
    })().catch((error: any) => {
      this.setLoadingTable(false);
      Notification.error({
        description: error.message,
        message: this.props.intl.formatMessage({ id: "management.editor.error" })
      });
    })
  };

  loadEmployeesData = async (searchText: string | undefined = "") => {
    this.setLoadingTable(true);

    const userData = await restServices.employeeService.getEmployeeWithOrganizationAndSearch({
      organization: this.selectedOrganizationGroupId,
      lang: this.props.rootStore!.userInfo!.locale!,
      search: searchText
    });

    if (userData) {
      const userPersonGroups: UserTableRow[] = [];
      for (let i = 0; i < userData.length; i++) {
        const assignment = userData[i];
        userPersonGroups.push({
          id: assignment.personGroupId,
          name: assignment.employeeFullName,
          positionGroup: assignment.position,
          organization: assignment.organization,
          data: {
            id: assignment.personGroupId,
          },
        } as UserTableRow);
      }
      this.setLoadingTable(false);
      userPersonGroups.sort(function (a, b) {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      this.setUsers(userPersonGroups);
    } else {
      this.setLoadingTable(false);
    }
  }

  loadPersonContactInfo = (personGroupId: string): Promise<PersonContact[]> => {
    return getCubaREST()!.searchEntities<PersonContact>(PersonContact.NAME, {
      conditions: [{
        property: "personGroup.id",
        operator: "=",
        value: personGroupId
      }]
    }, {
      view: "person.contact"
    })
  }

  @action
  setSelectedEmployee = async (value: any) => {

    if (value) {
      await getCubaREST()!.searchEntities<AssignmentExt>(AssignmentExt.NAME, {
        conditions: [{
          property: "personGroup.id",
          operator: "=",
          value: value.id
        }, {
          property: "startDate",
          operator: "<=",
          value: moment().format('YYYY-MM-DD')
        }, {
          property: "endDate",
          operator: ">=",
          value: moment().format('YYYY-MM-DD')
        }]
      }, { view: "assignment.position.persongroup" }).then((item) => {
        let employeeData = item.map(item => ({
          birthDate: item.personGroup!.person!.dateOfBirth!,
          fullName: item.personGroup! && item.personGroup!['_instanceName']!,
          positionName: item.positionGroup!['_instanceName']!,
          organizationName: item.organizationGroup!['_instanceName']!,
          companyCode: item.organizationGroup!.company!.code!,
          imageId: this.getImageId(item)
        }))
        this.selectedEmployee = employeeData[0]
      })

      await getCubaREST()!.searchEntities<TsadvUser>(TsadvUser.NAME, {
        conditions: [{
          property: "personGroup.id",
          operator: "=",
          value: value.id
        }]
      }, {
        view: "_base"
      }).then(item => {
        if (this.selectedEmployee) {
          this.selectedEmployee.telegram = item[0] ? item[0].telegramUserName : "";
          this.selectedEmployee.whatsApp = item[0] ? item[0].whatsappNumber : "";
          this.selectedEmployee.login = item[0] ? item[0].login : "";
        }
      })

      await restServices.organizationHrUserService.getHrUsersForPerson({
        personGroupId: value.id,
        roleCode: "MANAGER"
      }).then(item => {
        const selectE = this.selectedEmployee
        this.selectedEmployee = { ...selectE, managerList: item.map(a => a && a.fullName) }
      })

      await getCubaREST()!.searchEntities<PersonContact>(PersonContact.NAME, {
        conditions: [{
          property: "personGroup.id",
          operator: "=",
          value: value.id
        }]
      }, {
        view: "personContact.full"
      }).then(item => {
        const selectE = this.selectedEmployee
        item.forEach(c => {
          if (c.type!.code === 'mobile') {
            this.selectedEmployee = { ...selectE, phone: c.contactValue! }
          }
          return this.selectedEmployee
        }
        )

      })
    }
  };

  getImageId = (item: AssignmentExt) => {
    if (item.personGroup
      && item.personGroup.person
      && item.personGroup.person.image
    ) {
      return item.personGroup.person.image.id
    }

    if (item.personGroup && item.personGroup.image) {
      return item.personGroup.image.id
    }

    return undefined
  }

  @action
  setUsers = (value: UserTableRow[]) => {
    this.users = value;
  };
}

export default injectIntl(DictionaryEmployee);
