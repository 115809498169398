import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Button, Col, Row, Select} from "antd";
import {observable} from "mobx";
import {observer} from "mobx-react";
import ShopElement from "./ShopElement";
import {GoodsPageInfo} from "../../../../cuba/entities/base/tsadv$GoodsPageInfo";
import {GoodsPojo} from "../../../../cuba/entities/base/tsadv$GoodsPojo";
import {shopStore} from "../store/ShopStore";
import {Spin} from "antd/es";

type Filter = {
  key: string,
  caption: string
}

@observer
class RecognitionElementList extends React.Component<WrappedComponentProps> {

  messages = this.props.intl.messages as Record<string, string>;

  sortVariants: Filter[] = [
    {key: "0", caption: this.messages["recognition.recognitionList.filter.default"]},
    {key: "1", caption: this.messages["recognition.recognitionList.filter.cost"]},
    {key: "2", caption: this.messages["recognition.recognitionList.filter.name"]},
    {key: "3", caption: this.messages["recognition.recognitionList.filter.availability"]}
  ]

  @observable goodsPageInfo?: GoodsPageInfo;

  componentDidMount() {
    shopStore.reloadGoodsPage();
  }

  renderItem = (item: GoodsPojo) => {
    return <ShopElement item={item}/>
  }

  renderList = () => {
    if (shopStore.isLoading && shopStore.goods.length === 0) {
      return <Row type={"flex"} justify={"center"}>
        <Spin size={"large"}/>
      </Row>
    }

    return <>
      {shopStore.goods.map(this.renderItem)}
      <Row type={"flex"} justify={"center"} style={{height: "40px"}}>
        <Button type={"primary"}
                hidden={shopStore.isLastPage || shopStore.goods.length === 0}
                loading={shopStore.isLoading && shopStore.goods.length !== 0}
                onClick={() => shopStore.loadNextGoodsPage()}
        >
          <FormattedMessage id={"recognition.recognitionList.loadMore"}/>
        </Button>
      </Row>
    </>
  }

  renderOption = (variant: Filter, index: number) => {
    return <Select.Option key={index} value={variant.key}>
      {variant.caption}
    </Select.Option>
  }

  render() {
    return <>
      <Row type={"flex"} style={{alignItems: "center"}} gutter={30}>
        <Col>
          <span>
            <FormattedMessage id={"recognition.recognitionList.sortBy"}/>
          </span>
          <Select style={{width: 200}} defaultValue={shopStore.sort} onChange={shopStore.setSort}>
            {this.sortVariants.map(this.renderOption)}
          </Select>
        </Col>
      </Row>
      {this.renderList()}
    </>
  }
}

export default injectIntl(RecognitionElementList);