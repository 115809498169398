import * as React from "react";
import MenuCbaList from "./MenuCbaList";
import {RouteComponentProps} from "react-router";
import MenuCbaEdit from "./MenuCbaEdit";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {injectIntl, WrappedComponentProps} from "react-intl";

type MenuCbaManagementProps = RouteComponentProps<{ personAssesmentId?: string; }>;

export class MenuCbaManagementComponent extends React.Component<MenuCbaManagementProps & WrappedComponentProps> {
  static PATH = "/menuCba";
  static NEW_PATH = "/new"

  render() {
    const {personAssesmentId} = this.props.match.params;

    return (
      <>
        <Page pageName={this.props.intl.formatMessage({id: "menu.menuCba"})}>
          <Section size={"large"}>
            {personAssesmentId ? (
              <MenuCbaEdit personAssesmentId={personAssesmentId}/>
            ) : (
              <MenuCbaList/>
            )}
          </Section>
        </Page>
      </>
    );
  }
}

export default injectIntl(MenuCbaManagementComponent);
