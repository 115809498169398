import { StandardEntity } from "./sys$StandardEntity";
import { CompetenceTemplate } from "./tsadv$CompetenceTemplate";
import { DicParticipantType } from "./tsadv$DicParticipantType";
export class CompetenceTemplateParticipant extends StandardEntity {
  static NAME = "tsadv_CompetenceTemplateParticipant";
  competenceTemplate?: CompetenceTemplate | null;
  role?: DicParticipantType | null;
  selfAssessment?: boolean | null;
  otherAssessment?: boolean | null;
  totalScore?: boolean | null;
  instructionLangValue1?: string | null;
  instructionLangValue2?: string | null;
  instructionLangValue3?: string | null;
  number?: number | null;
}
export type CompetenceTemplateParticipantViewName =
  | "_base"
  | "_local"
  | "_minimal";
export type CompetenceTemplateParticipantView<
  V extends CompetenceTemplateParticipantViewName
> = V extends "_base"
  ? Pick<
      CompetenceTemplateParticipant,
      | "id"
      | "selfAssessment"
      | "otherAssessment"
      | "totalScore"
      | "instructionLangValue1"
      | "instructionLangValue2"
      | "instructionLangValue3"
      | "number"
    >
  : V extends "_local"
  ? Pick<
      CompetenceTemplateParticipant,
      | "id"
      | "selfAssessment"
      | "otherAssessment"
      | "totalScore"
      | "instructionLangValue1"
      | "instructionLangValue2"
      | "instructionLangValue3"
      | "number"
    >
  : never;
