import React from "react";
import {Radio, Row} from "antd";
import {observable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {GoodsCategoryPojo} from "../../../../cuba/entities/base/tsadv$GoodsCategoryPojo";
import {restServices} from "../../../../cuba/services";
import {shopStore} from "../store/ShopStore";

@observer
class ShopCategories extends React.Component {
  readonly DEFAULT_CATEGORY_ID = "-1";

  @observable categories: GoodsCategoryPojo[] = [];

  componentDidMount() {
    restServices.recognitionService.loadCategories()
      .then(categories => runInAction(() => this.categories = categories));
  }

  renderRadio = (categoryPojo: GoodsCategoryPojo) => {
    return (
      <Radio value={categoryPojo.categoryId}>
        <div>
          <Row type={"flex"} justify={"space-between"} style={{width: "100%", alignItems: "baseline"}}>
            <h1>{categoryPojo.name}</h1>
            <h1>{categoryPojo.goodsCount}</h1>
          </Row>
        </div>
      </Radio>
    )
  }

  render() {
    return <>
      <Radio.Group className={"category-group"} onChange={e => shopStore.setCategoryId(e.target.value || this.DEFAULT_CATEGORY_ID)}>
        {this.categories.map(this.renderRadio)}
      </Radio.Group>
    </>
  }
}

export default ShopCategories;