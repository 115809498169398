import {observer} from "mobx-react";
import * as React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {observable, runInAction} from "mobx";
import {restServices, TelegramDto} from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";
import {ChangeEvent} from "react";
import {Button, Col, Input, Modal, Row} from "antd";
import Img from "../../components/Img";

type AddTelegramModalProps = {
  onAdd?: () => void;
}

@observer
class AddTelegramModal extends React.Component<AddTelegramModalProps & WrappedComponentProps> {
  telegramDto: TelegramDto = {}
  @observable isSHowModal: boolean = false;
  code: string = "";

  open() {
    restServices.telegramService.getTelegramLinkForCurrentUser().then((resp) => {
      runInAction(() => {
        this.telegramDto = resp;
        this.isSHowModal = true;
      })
    })
      .catch(e => Notification.error({message: this.props.intl.formatMessage({id: 'myProfile.telegram.getQrError'})}))
  }

  sendCode = () => {
    restServices.telegramService.registerUserByTelegramCode(this.code)
      .then(e => {
        Notification.success({message: this.props.intl.formatMessage({id: 'myProfile.telegram.success'})});
        runInAction(() => this.isSHowModal = false)
        if (this.props.onAdd) {
          this.props.onAdd();
        }
      })
      .catch(e => Notification.error({message: this.props.intl.formatMessage({id: 'myProfile.telegram.sendCodeError'})}))
  };

  onChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    this.code = e.target.value;
  };

  render() {
    return <>
      <Button type="link" onClick={() => this.open()}>
        <FormattedMessage id="myProfile.telegram.add"/>
      </Button>
      <Modal centered visible={this.isSHowModal} onCancel={() => runInAction(() =>this.isSHowModal = false)} width="800px" footer={null}>
        <Row justify="center" type="flex" align="middle">
          <Col xs={14}>
            <Row>
              <h3 style={{textAlign: "center"}}>
                <FormattedMessage id="myProfile.telegram.description"/>
              </h3>
            </Row>
            <Row justify="center" type="flex" style={{margin: "2%"}}>
              <Button type="primary" onClick={() => window.open(this.telegramDto.link, '_blank')}>
                <FormattedMessage id="myProfile.telegram.toTelegram"/>
              </Button>
            </Row>
          </Col>
          <Col xs={10}>
            <Img type="base64" src={this.telegramDto.qrCode}/>
          </Col>
        </Row>
        <Row justify="center" type="flex">
          <Col xs={18}>
            <Input.Group>
              <Input style={{width: 'calc(100% - 200px)'}} onChange={this.onChangeCode}/>
              <Button type="primary" onClick={this.sendCode}>
                <FormattedMessage id="myProfile.telegram.sedCode"/>
              </Button>
            </Input.Group>
          </Col>
        </Row>
      </Modal>
    </>
  }
}

export default injectIntl(AddTelegramModal);