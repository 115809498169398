import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import PersonAwardCard from "./PersonAwardCard";
import {observer} from "mobx-react";
import {action, computed, observable} from "mobx";
import {NomineePojo} from "../../../../cuba/entities/base/tsadv$NomineePojo";
import {restServices} from "../../../../cuba/services";
import {Icon, Row, Select} from "antd";

@observer
class NomineeTop extends React.Component<WrappedComponentProps> {

  @observable nominees: NomineePojo[] = [];
  @observable years?: number[];
  @observable selectedYear: number;
  @observable isExpanded = false;

  async componentDidMount() {
    this.years = await restServices.recognitionService.loadNomineeYearsNominations(1);
    this.selectedYear = this.years[0];

    this.loadNominees();
  }

  @computed get currentShowed() {
    if (!this.nominees) {
      return undefined;
    }

    if (this.isExpanded) {
      return this.nominees;
    }

    return this.nominees.slice(0, 4);
  }

  loadNominees = async () =>  {
    this.nominees = await restServices.recognitionService.loadTopNomineeWithDefault(this.selectedYear, "-1", this.selectedYear, true);
  }

  onChangeYear = (year: number) => {
    this.selectedYear  = year;
    this.loadNominees();
  }

  @action showMore = () => {
    this.isExpanded = true;
  }

  render() {
    const {messages} = this.props.intl;

    return <>
      <Row type={"flex"} justify={"space-between"} style={{padding: "0 20px", alignItems: "center"}}>
        <h2 style={{margin: 0}}>{messages["recognition.recognitionProgram.winners.NomineeTop"]}</h2>
        <Select style={{width: "300px"}} value={this.selectedYear} onChange={this.onChangeYear}>
          {this.years && this.years.map(year => <Select.Option value={year}>{year}</Select.Option>)}
        </Select>
      </Row>
      <div style={{display: "flex", flexWrap: "wrap", textAlign: "center", justifyContent: "center"}}>
        {this.currentShowed && this.currentShowed.map((nominee) => <PersonAwardCard nomineePojo={nominee}/>)}
      </div>
      {!this.isExpanded &&
        <div style={{display: "flex", justifyContent: "center"}}>
          <div style={{cursor: "pointer"}} onClick={this.showMore}>
            <span>{messages["recognition.recognitionList.loadMore"]}</span> <Icon type="caret-down" theme="filled"/>
          </div>
        </div>
      }
    </>
  }
}

export default injectIntl(NomineeTop);