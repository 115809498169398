import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import VacancyRequestEdit from "./VacancyRequestEdit";
import VacancyRequestList from "./VacancyRequestList";

type Props = RouteComponentProps<{ entityId?: string }>;

@observer
export class VacancyRequestManagement extends React.Component<Props> {
  static PATH = "/vacancyRequest";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId} = this.props.match.params;
    return (
      <>
        {entityId ? (
          <VacancyRequestEdit entityId={entityId}/>
        ) : (
          <VacancyRequestList/>
        )}
      </>
    );
  }
}
