import * as React from "react";
import {collection, DataTable, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {AbsenceRvdRequest} from "../../../cuba/entities/base/tsadv_AbsenceRvdRequest";
import DataTableFormat from "../../components/DataTable/intex";
import {Button} from "antd";
import LoadingPage from "../LoadingPage";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";
import {AbsenceRvdRequestManagement} from "../MyTeam/timeManagement/rvd/MyTeamPersonRvdRequest/AbsenceRvdRequestManagement";
import {DEFAULT_DATE_TIME_PATTERN, DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS, formatDate} from "../../util/Date/Date";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {RootStoreProp} from "../../store";
@injectMainStore
@observer
@inject("rootStore")
class RvdList extends React.Component<MainStoreInjected&WrappedComponentProps&RootStoreProp>{
  static PATH="/rvdList"
  dataCollection = collection<AbsenceRvdRequest>(AbsenceRvdRequest.NAME, {
    view: "absenceRvdRequest.edit",
    sort: "-timeOfStarting",

    filter:{

      conditions:[
        {
          property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!
        },
        {
        group:"OR",
        conditions: [
          {
            property: 'type.overtimeWork',
            value: 'true',
            operator: '=',
          },
          {
            property: 'type.workOnWeekend',
            value: "true",
            operator: '=',
          },
          {
            property: 'type.temporaryTransfer',
            value: "true",
            operator: '=',
          },
          {
            property: 'type.availableToManager',
            operator: '=',
            value: 'true'
          }
        ]}]},
    loadImmediately: true,
  });
  fields = [

    "requestNumber",

    "personGroup",

    "status",

    "type",

    "timeOfStarting",

    "timeOfFinishing",


  ];
  render(){
    console.log(this.dataCollection)
    return (
      <Page>
        <Section size={"large"}>
          {
            <Link to={AbsenceRvdRequestManagement.PATH+"/new"}><Button type={"primary"}>
            {this.props.intl.formatMessage({id:"addRequest"})}
          </Button></Link>}


          <DataTableFormat render={[{
            column: 'requestNumber',
            render: (text, record) => <Link to={AbsenceRvdRequestManagement.PATH + '/' + record.id}>{text}</Link>
          }, {
            column: 'timeOfStarting',
            render: (text, record) => formatDate(record.timeOfStarting, DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS)
          }, {
            column: 'timeOfFinishing',
            render: (text, record) => formatDate(record.timeOfFinishing, DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS)
          }]} hideSelectionColumn={true} fields={this.fields} dataCollection={this.dataCollection}/></Section>
      </Page>
    )
  }

}
export default injectIntl(RvdList)