import React, { HTMLAttributes } from 'react';

export enum NotFoundImage {
  DEFAULT = "no-image.svg",
  EMPLOYEE = "default-user.svg"
}

export function getNotFoundImage(image: NotFoundImage): NotFoundImage {
  return require("../../../resources/img/" + image);
}

export type NoImageProps = {
  image?: NotFoundImage
}

class NoImage extends React.Component<HTMLAttributes<HTMLImageElement> & NoImageProps> {
  render() {
    const {style} = this.props;

    return (
      <img className={"no-image" + (this.props.className ? " " + this.props.className : "")} alt={"no image"}
           src={require("../../../resources/img/" + (this.props.image ? this.props.image : NotFoundImage.DEFAULT))}
           style={Object.assign({maxWidth: "250px", maxHeight: "250px"}, style)}/>
    );
  }
}

export default NoImage;