import { StandardEntity } from "./sys$StandardEntity";
import { UcoSignRegistration } from "./ucosign$Registration";
import { FileDescriptor } from "./sys$FileDescriptor";
import { UcoSignSignature } from "./ucosign$Signature";
export class UcoSignDocument extends StandardEntity {
  static NAME = "ucosign$Document";
  ucosignRegistration?: UcoSignRegistration | null;
  egovSignOrderId?: number | null;
  sigexDocumentId?: string | null;
  fileTitle?: string | null;
  fileDescription?: string | null;
  originalFile?: FileDescriptor | null;
  finalFile?: FileDescriptor | null;
  ucosignSignatures?: UcoSignSignature[] | null;
}
export type UcoSignDocumentViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "ucoSignDocument-buildDdc"
  | "ucoSignDocument-documentsToSigning"
  | "ucoSignDocument.edit";
export type UcoSignDocumentView<
  V extends UcoSignDocumentViewName
> = V extends "_base"
  ? Pick<
      UcoSignDocument,
      | "id"
      | "fileTitle"
      | "egovSignOrderId"
      | "sigexDocumentId"
      | "fileDescription"
    >
  : V extends "_local"
  ? Pick<
      UcoSignDocument,
      | "id"
      | "egovSignOrderId"
      | "sigexDocumentId"
      | "fileTitle"
      | "fileDescription"
    >
  : V extends "_minimal"
  ? Pick<UcoSignDocument, "id" | "fileTitle">
  : V extends "ucoSignDocument-buildDdc"
  ? Pick<
      UcoSignDocument,
      | "id"
      | "fileTitle"
      | "sigexDocumentId"
      | "fileTitle"
      | "originalFile"
      | "finalFile"
    >
  : V extends "ucoSignDocument-documentsToSigning"
  ? Pick<
      UcoSignDocument,
      | "id"
      | "egovSignOrderId"
      | "sigexDocumentId"
      | "fileTitle"
      | "fileDescription"
      | "ucosignRegistration"
      | "originalFile"
    >
  : V extends "ucoSignDocument.edit"
  ? Pick<
      UcoSignDocument,
      | "id"
      | "egovSignOrderId"
      | "sigexDocumentId"
      | "fileTitle"
      | "fileDescription"
      | "originalFile"
      | "finalFile"
      | "ucosignSignatures"
    >
  : never;
