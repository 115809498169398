import * as React from "react";
import {observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";
import {observable} from "mobx";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import DataTableFormat from "../../components/DataTable/intex";
import {withRouter} from "react-router";
import Button, {ButtonType} from "../../components/Button/Button";
import {PersonQualification} from "../../../cuba/entities/base/tsadv$PersonQualification";
import {PersonQualificationRequestManagement} from "../PersonQualificationRequest/PersonQualificationRequestManagement";
import {PersonQualificationRequest} from "../../../cuba/entities/base/tsadv_PersonQualificationRequest";

export type AddressListProps = {
  personGroupId: string
}

@injectMainStore
@observer
class PersonQualificationListComponent extends React.Component<AddressListProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps> {
  dataCollection = collection<PersonQualification>(PersonQualification.NAME, {
    view: "personQualification-view",
    sort: "-updateTs",
    filter: {
      conditions: [{
        property: 'personGroup.id',
        operator: '=',
        value: this.props.personGroupId
      }]
    }
  });

  fields = [
    "educationalInstitutionName",

    "startDate",

    "endDate",
    "diploma",

    "issuedDate",
    "qualification",
    "profession",
    "educationDocumentType",
    "courseName",
    "expiryDate"
  ];

  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
      <Link
        to={
          PersonQualificationRequestManagement.PATH +
          "/" +
          PersonQualificationRequestManagement.NEW_SUBPATH
        }
        key="create"
      >
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}
                key="createBtn"
        >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>,
      <Button
        buttonType={ButtonType.FOLLOW}
        style={{margin: "0 12px 12px 0"}}
        disabled={!this.selectedRowKey}
        onClick={this.openRequest}
        key="editBtn"
      >
        <FormattedMessage id="management.browser.edit"/>
      </Button>
    ];

    return (
      <DataTableFormat
        dataCollection={this.dataCollection}
        enableFiltersOnColumns={[]}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  openRequest = () => {
    this.getRequestId()
      .then(value => this.props.history!.push(PersonQualificationRequestManagement.PATH + '/' + value));
  }

  getRequestId = (): Promise<string> => {
    if (!this.selectedRowKey) return new Promise<string>(resolve => resolve(PersonQualificationRequestManagement.NEW_SUBPATH));
    return getCubaREST()!.searchEntities<PersonQualificationRequest>(PersonQualificationRequest.NAME, {
      conditions: [{
        property: 'personQualification.id',
        operator: '=',
        value: this.selectedRowKey!
      }, {
        property: 'status.code',
        operator: 'in',
        value: ['DRAFT', 'TO_BE_REVISED', 'APPROVING']
      }]
    }, {
      view: 'personQualificationRequest.edit'
    }).then(values => {
      if (!values || values.length === 0) {
        return PersonQualificationRequestManagement.NEW_SUBPATH + '/' + this.selectedRowKey!;
      } else {
        const approvingRequest = values.find(value => value!.status!.code === 'APPROVING');
        return approvingRequest ? approvingRequest.id : values[0].id;
      }
    });
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const PersonQualificationList = injectIntl(withRouter(PersonQualificationListComponent));

export default PersonQualificationList;
