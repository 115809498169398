import * as React from "react";
import {observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";

import {observable} from "mobx";

import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import DataTableFormat from "../../components/DataTable/intex";
import {withRouter} from "react-router";
import {PersonBankDetails} from "../../../cuba/entities/base/tsadv_PersonBankDetails";
import {PersonBankdetailsRequest} from "../../../cuba/entities/base/tsadv_PersonBankdetailsRequest";
import Button, {ButtonType} from "../../components/Button/Button";
import {BankDetailsRequestManagement} from "../BankDetailsRequest/BankDetailsRequestManagement";
import moment from "moment";
import {Modal} from "antd";

export type AddressListProps = {
  personGroupId: string
}

@injectMainStore
@observer
class BankDetailsListComponent extends React.Component<AddressListProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps> {
  dataCollection = collection<PersonBankDetails>(PersonBankDetails.NAME, {
    view: "personBankDetails.edit",
    sort: "-updateTs",
    filter: {
      conditions: [{
        property: 'personGroup.id',
        operator: '=',
        value: this.props.personGroupId
      }]
    }
  });

  fields = [
    "bank",

    "fullNameBankCard",

    "iban",

    "startDateHistory",

    "endDateHistory"
  ];

  @observable selectedRowKey: string | undefined;

  warning=()=>{
    Modal.warning({
      title: this.props.intl.formatMessage({id: "myProfile.bankDetails.notification"})
    });
  }

  render() {
    const currentDay = parseInt(moment().format('D'))
    const buttons = [
      (!(currentDay>10 && currentDay<21))?
      <Link
        to={
          BankDetailsRequestManagement.PATH +
          "/" +
          BankDetailsRequestManagement.NEW_SUBPATH
        }
        key="create"
      >
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}
                key="createBtn"
        >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>:
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}
                key="createBtn"
                onClick={this.warning}
                      >
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button> ,
      !(currentDay>10 && currentDay<21) ?
        <Button
          buttonType={ButtonType.FOLLOW}
          style={{margin: "0 12px 12px 0"}}
          disabled={!this.selectedRowKey}
          onClick={this.openRequest}
          key="editBtn"
        >
          <FormattedMessage id="management.browser.edit"/>
        </Button>:
        <Button
          buttonType={ButtonType.FOLLOW}
          style={{margin: "0 12px 12px 0"}}
          disabled={!this.selectedRowKey}
          onClick={this.warning}
          key="editBtn"
        >
          <FormattedMessage id="management.browser.edit"/>
        </Button>
    ];

    return (
      <DataTableFormat
        dataCollection={this.dataCollection}
        enableFiltersOnColumns={[]}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }


  openRequest = () => {
    this.getRequestId()
      .then(value =>this.props.history!.push(BankDetailsRequestManagement.PATH + '/' + value));
  }

  getRequestId = (): Promise<string> => {
    if (!this.selectedRowKey) return new Promise<string>(resolve => resolve(BankDetailsRequestManagement.NEW_SUBPATH));
    return getCubaREST()!.searchEntities<PersonBankdetailsRequest>(PersonBankdetailsRequest.NAME, {
      conditions: [{
        property: 'bank.id',
        operator: '=',
        value: this.selectedRowKey!
      }, {
        property: 'status.code',
        operator: 'in',
        value: ['DRAFT', 'TO_BE_REVISED', 'APPROVING']
      }]
    }, {
      view: 'personBankDetailsRequest.full'
    }).then(values => {
      if (!values || values.length === 0) {
        return BankDetailsRequestManagement.NEW_SUBPATH + '/' + this.selectedRowKey!;
      }
      else {
        const approvingRequest = values.find(value => value!.status!.code === 'APPROVING');
        return approvingRequest ? approvingRequest.id : values[0].id;
      }
    });
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}
const BankDetailsList = injectIntl(withRouter(BankDetailsListComponent));

export default BankDetailsList;
