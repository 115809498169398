import { StandardEntity } from "./sys$StandardEntity";
import { LearningBudgetRequestHeader } from "./tsadv_LearningBudgetRequestHeader";
import { PersonGroupExt } from "./base$PersonGroupExt";
export class BudgetHistoryApprovement extends StandardEntity {
  static NAME = "tsadv_BudgetHistoryApprovement";
  learningBudgetHeader?: LearningBudgetRequestHeader | null;
  approver?: PersonGroupExt | null;
  role?: string | null;
  action?: string | null;
  comment?: string | null;
  date?: any | null;
}
export type BudgetHistoryApprovementViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "budgetHistoryApprovement-front-view";
export type BudgetHistoryApprovementView<
  V extends BudgetHistoryApprovementViewName
> = V extends "_base"
  ? Pick<
      BudgetHistoryApprovement,
      "id" | "role" | "action" | "comment" | "date"
    >
  : V extends "_local"
  ? Pick<
      BudgetHistoryApprovement,
      "id" | "role" | "action" | "comment" | "date"
    >
  : V extends "budgetHistoryApprovement-front-view"
  ? Pick<
      BudgetHistoryApprovement,
      | "id"
      | "learningBudgetHeader"
      | "approver"
      | "role"
      | "action"
      | "comment"
      | "date"
    >
  : never;
