import * as React from "react";
import { inject, observer } from "mobx-react";

import { observable } from "mobx";

import { Button, Table } from "antd";

import {
  collection,
  injectMainStore,
  MainStoreInjected,
  Msg
} from "@cuba-platform/react";

import { AdaptationPlan } from "../../../../cuba/entities/base/tsadv_AdaptationPlan";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import { RootStoreProp } from "../../../store";

type Props = MainStoreInjected & WrappedComponentProps & RootStoreProp & {
  personGroupId?: string,
  setEntityId?: (entityId: string | null) => void
};

@injectMainStore
@inject("rootStore")
@observer
class AdaptationPlanListComponent extends React.Component<Props> {
  dataCollection = collection<AdaptationPlan>(AdaptationPlan.NAME, {
    view: "adaptationPlan",
    sort: "-updateTs",
    filter: {
      conditions: [
        {
          property: "personGroup.id",
          operator: "=",
          value: this.props.personGroupId!,
        },
        {
          property: "needless",
          operator: "=",
          value: "false",
        }
      ]
    },
  });

  
  @observable
  isLocaleRu = this.props.rootStore!.userInfo.locale === 'ru';

  @observable
  isLocaleKz = this.props.rootStore!.userInfo.locale === 'kz';

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  renderPlanName = (text: string, record: AdaptationPlan)=>{
    const planName = this.isLocaleRu ? record.planNameLangRu : this.isLocaleKz ? record.planNameLangKz : record.planNameLangEn;
    return (
      <Button
        onClick={()=>this.props.setEntityId && this.props.setEntityId(record.id)}
        type="link"
      >
        {planName || "(name is empty)"}
      </Button>
    )
  };

  renderStatus = (text: string, record: AdaptationPlan) => {
    const statusCode = record.adaptationStatus && (record.adaptationStatus ==="FULL_APPROVED" || (record.status && record.status.code === "DRAFT")) ? 
      this.props.intl.formatMessage({ id: `statusCode.${record.adaptationStatus}` })
      : this.isLocaleEn ?
        record.status!.langValue3
        : record.status!.langValue1;
    return statusCode;
  }

  render() {
    return (
      <Table
        scroll={{x:true}}
        dataSource={[...this.dataCollection.items]}
        rowKey={'id'}
        columns={[
          {
            key: 'planName',
            title: <Msg entityName={AdaptationPlan.NAME} propertyName={"planName"} mainStore={this.props.mainStore} />,
            dataIndex: 'planName',
            render: this.renderPlanName
          }, {
            key: 'dateFrom',
            title: <Msg entityName={AdaptationPlan.NAME} propertyName={"dateFrom"} mainStore={this.props.mainStore} />,
            dataIndex: 'dateFrom',
          }, {
            key: 'dateTo',
            title: <Msg entityName={AdaptationPlan.NAME} propertyName={"dateTo"} mainStore={this.props.mainStore} />,
            dataIndex: 'dateTo',
          }, {
            key: 'status',
            title: <Msg entityName={AdaptationPlan.NAME} propertyName={"status"} mainStore={this.props.mainStore} />,
            dataIndex: 'status',
            render: this.renderStatus
          },
        ]}
      />
    );
  }
}

const AdaptationPlanList = injectIntl(AdaptationPlanListComponent);

export default AdaptationPlanList;
