import { BaseUserExt } from "./base$UserExt";
import { PersonGroupExt } from "./base$PersonGroupExt";
export class TsadvUser extends BaseUserExt {
  static NAME = "tsadv$UserExt";
  personGroup?: PersonGroupExt | null;
  fullNameWithLogin?: string | null;
}
export type TsadvUserViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "portal-bproc-users"
  | "tsadvUserExt-view"
  | "user-fioWithLogin"
  | "user.browse"
  | "user.edit"
  | "user.roles"
  | "userExt.bproc"
  | "userExt.edit";
export type TsadvUserView<V extends TsadvUserViewName> = V extends "_base"
  ? Pick<
      TsadvUser,
      | "id"
      | "shortName"
      | "login"
      | "fullNameWithLogin"
      | "loginLowerCase"
      | "password"
      | "passwordEncryption"
      | "name"
      | "firstName"
      | "lastName"
      | "middleName"
      | "position"
      | "email"
      | "language"
      | "timeZone"
      | "timeZoneAuto"
      | "active"
      | "changePasswordAtNextLogon"
      | "groupNames"
      | "ipMask"
      | "sysTenantId"
      | "atsCode"
      | "innerNumber"
      | "availability"
      | "mobilePhone"
      | "telegramCode"
      | "telegramChatId"
      | "passwordChangeDate"
      | "fullName"
    >
  : V extends "_local"
  ? Pick<
      TsadvUser,
      | "id"
      | "fullNameWithLogin"
      | "login"
      | "loginLowerCase"
      | "password"
      | "passwordEncryption"
      | "name"
      | "firstName"
      | "lastName"
      | "middleName"
      | "position"
      | "email"
      | "language"
      | "timeZone"
      | "timeZoneAuto"
      | "active"
      | "changePasswordAtNextLogon"
      | "groupNames"
      | "ipMask"
      | "sysTenantId"
      | "atsCode"
      | "innerNumber"
      | "availability"
      | "mobilePhone"
      | "telegramCode"
      | "telegramChatId"
      | "passwordChangeDate"
      | "shortName"
      | "fullName"
    >
  : V extends "_minimal"
  ? Pick<TsadvUser, "id" | "shortName" | "login">
  : V extends "portal-bproc-users"
  ? Pick<TsadvUser, "id" | "shortName" | "login" | "fullNameWithLogin" | "personGroup">
  : V extends "tsadvUserExt-view"
  ? Pick<
      TsadvUser,
      | "id"
      | "login"
      | "loginLowerCase"
      | "password"
      | "passwordEncryption"
      | "name"
      | "firstName"
      | "lastName"
      | "middleName"
      | "position"
      | "email"
      | "language"
      | "timeZone"
      | "timeZoneAuto"
      | "active"
      | "changePasswordAtNextLogon"
      | "groupNames"
      | "ipMask"
      | "sysTenantId"
      | "group"
      | "userRoles"
      | "substitutions"
      | "image"
      | "addressBookEntries"
      | "availability"
      | "innerNumber"
      | "atsCode"
      | "image"
      | "addressBookEntries"
      | "availability"
      | "innerNumber"
      | "atsCode"
      | "personGroup"
      | "personGroup"
    >
  : V extends "user-fioWithLogin"
  ? Pick<
      TsadvUser,
      | "id"
      | "fullNameWithLogin"
      | "login"
      | "loginLowerCase"
      | "password"
      | "passwordEncryption"
      | "name"
      | "firstName"
      | "lastName"
      | "middleName"
      | "position"
      | "email"
      | "language"
      | "timeZone"
      | "timeZoneAuto"
      | "active"
      | "changePasswordAtNextLogon"
      | "groupNames"
      | "ipMask"
      | "sysTenantId"
      | "atsCode"
      | "innerNumber"
      | "availability"
      | "mobilePhone"
      | "telegramCode"
      | "telegramChatId"
      | "passwordChangeDate"
      | "shortName"
      | "fullName"
      | "personGroup"
    >
  : V extends "user.browse"
  ? Pick<
      TsadvUser,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "login"
      | "loginLowerCase"
      | "password"
      | "passwordEncryption"
      | "name"
      | "firstName"
      | "lastName"
      | "middleName"
      | "position"
      | "email"
      | "language"
      | "timeZone"
      | "timeZoneAuto"
      | "active"
      | "changePasswordAtNextLogon"
      | "groupNames"
      | "ipMask"
      | "sysTenantId"
      | "group"
      | "image"
      | "image"
    >
  : V extends "user.edit"
  ? Pick<
      TsadvUser,
      | "id"
      | "login"
      | "loginLowerCase"
      | "password"
      | "passwordEncryption"
      | "name"
      | "firstName"
      | "lastName"
      | "middleName"
      | "position"
      | "email"
      | "language"
      | "timeZone"
      | "timeZoneAuto"
      | "active"
      | "changePasswordAtNextLogon"
      | "groupNames"
      | "ipMask"
      | "sysTenantId"
      | "group"
      | "userRoles"
      | "substitutions"
      | "image"
      | "addressBookEntries"
      | "availability"
      | "innerNumber"
      | "atsCode"
      | "image"
      | "addressBookEntries"
      | "availability"
      | "innerNumber"
      | "atsCode"
      | "personGroup"
    >
  : V extends "user.roles"
  ? Pick<TsadvUser, "id" | "shortName" | "login" | "userRoles">
  : V extends "userExt.bproc"
  ? Pick<
      TsadvUser,
      | "id"
      | "fullNameWithLogin"
      | "login"
      | "loginLowerCase"
      | "password"
      | "passwordEncryption"
      | "name"
      | "firstName"
      | "lastName"
      | "middleName"
      | "position"
      | "email"
      | "language"
      | "timeZone"
      | "timeZoneAuto"
      | "active"
      | "changePasswordAtNextLogon"
      | "groupNames"
      | "ipMask"
      | "sysTenantId"
      | "atsCode"
      | "innerNumber"
      | "availability"
      | "mobilePhone"
      | "telegramCode"
      | "telegramChatId"
      | "passwordChangeDate"
      | "shortName"
      | "fullName"
      | "personGroup"
    >
  : V extends "userExt.edit"
  ? Pick<
      TsadvUser,
      | "id"
      | "shortName"
      | "login"
      | "personGroup"
      | "firstName"
      | "lastName"
      | "middleName"
      | "email"
      | "language"
      | "image"
      | "mobilePhone"
      | "telegramChatId"
      | "fullName"
    >
  : never;
