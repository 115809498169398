import React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {observer} from "mobx-react";
import NomineeTop from "./NomineeTop";
import AllNominees from "./AllNominees";

@observer
class RecognitionWinners extends React.Component<WrappedComponentProps> {

  render() {
    return <>
      <NomineeTop/>
      <AllNominees/>
    </>
  }

}

export default injectIntl(RecognitionWinners);