import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import WccfEdit from "./WccfEdit";

type Props = RouteComponentProps<{ entityId: string }>;

@observer
export class WccfManagement extends React.Component<Props> {
  static PATH = "/wccf";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId} = this.props.match.params;
    return (
      <WccfEdit entityId={entityId}/>
    );
  }
}
