import * as React from "react";
import { ChangeEvent } from "react";
import TextArea from "antd/lib/input/TextArea";
import { Form, Input } from "antd";
import { LearningBudgetRequestCost } from "../../../cuba/entities/base/tsadv_LearningBudgetRequestCost";
import { inject, observer } from "mobx-react";
import { injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import { WrappedComponentProps } from "react-intl";
import { FormComponentProps } from "antd/lib/form";
import { RootStoreProp } from "../../store";
import { IReactionDisposer, observable, observe, reaction } from "mobx";
import { LearningBudget } from "../../../cuba/entities/base/tsadv_LearningBudget";
import { PerdiemsSetting } from "../../../cuba/entities/base/tsadv_PerdiemsSetting";
import { fillData, formatAmount, parseStringToFloat } from "./utils";
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import Notification from "../../util/Notification/Notification";

export type CostsComponentProps = WrappedComponentProps & FormComponentProps & MainStoreInjected & RootStoreProp & {
  assignmentsList: AssignmentExt[]
  learningBudget: LearningBudget
  perdiemsSettings?: PerdiemsSetting,
  costs?: LearningBudgetRequestCost,
  employeeCount?: {
    value: number
  },
  daysCount: {
    value: number
  }
}

@inject("rootStore")
@injectMainStore
@observer
export class CostsComponent extends React.Component<CostsComponentProps> {
  @observable assignmentsList = this.props.assignmentsList
  @observable employeeCount = this.props.employeeCount || {
    value: 1
  }
  @observable learningBudget: LearningBudget = this.props.learningBudget
  @observable daysCount = this.props.daysCount || {
    value: 1
  }

  messages = this.props.mainStore!.messages!;
  perdiemsReaction: IReactionDisposer

  @observable values: {
    trainingCost: string
    travel: string
    residence: string
    dailyAllowance: string
    residenceWhole: string
    dailyAllowanceWhole: string
    trainingCostWhole: string
    travelWhole: string
    totalByOne: string
    totalWhole: string
  } = {
      trainingCost: '0',
      travel: '0',
      residence: '0',
      dailyAllowance: '0',
      residenceWhole: '0',
      dailyAllowanceWhole: '0',
      trainingCostWhole: '0',
      travelWhole: '0',
      totalByOne: '0',
      totalWhole: '0'
    }

  @observable usdValues: {
    trainingCostUSD: string
    travelUSD: string
    residenceUSD: string
    dailyAllowanceUSD: string
    totalByOneUSD: string
    totalWholeUSD: string
    trainingCostUSDWhole: string
    travelUSDWhole: string
    residenceUSDWhole: string
    dailyAllowanceUSDWhole: string
  }


  constructor(props: CostsComponentProps) {
    super(props);
    this.perdiemsReaction = reaction(r => this.props.perdiemsSettings, (arg, r) => {
      if (this.props.perdiemsSettings) {
        this.values.travel = this.props.perdiemsSettings.travelExpenses.toString()
        this.values.dailyAllowance = formatAmount((this.props.perdiemsSettings.amount * this.daysCount.value).toString())
        this.values.residence = formatAmount((this.props.perdiemsSettings.livingExpenses * this.daysCount.value).toString())
      } else {
        this.values.travel = '0'
        this.values.dailyAllowance = '0'
        this.values.residence = '0'
      }
      this.calculateValues()
      this.calculateUSDValues()
    })
    observe(this.assignmentsList, change => {
      this.employeeCount.value = change.object['length'] || 0
      this.calcResidenceAndDailyAllowance();
      this.calculateValues()
      this.calculateUSDValues()
      return true
    })
    observe(this.daysCount, change => {
      this.calcResidenceAndDailyAllowance();
      this.calculateValues()
      this.calculateUSDValues()
      return true
    })
    if (this.employeeCount)
      observe(this.employeeCount, change => {
        this.calcResidenceAndDailyAllowance();
        this.calculateValues()
        this.calculateUSDValues()
        return true
      })
    if (this.props.costs) {
      const employeeCount = this.props.employeeCount ? this.props.employeeCount.value : 0
      let totalByOne = 0
      let totalWhole = 0
      for (let field in this.props.costs) {
        const val = this.props.costs[field]
        if (typeof val === 'number') {
          totalByOne += val
          totalWhole += val * employeeCount
        }
      }
      this.values = {
        trainingCost: this.props.costs.trainingCost ? this.props.costs.trainingCost.toString() : '0',
        travel: this.props.costs.travel ? this.props.costs.travel.toString() : '0',
        residence: this.props.costs.residence ? this.props.costs.residence.toString() : '0',
        dailyAllowance: this.props.costs.dailyAllowance ? this.props.costs.dailyAllowance.toString() : '0',
        residenceWhole: this.props.costs.residence ? (this.props.costs.residence * employeeCount).toString() : '0',
        dailyAllowanceWhole: this.props.costs.dailyAllowance ? (this.props.costs.dailyAllowance * employeeCount).toString() : '0',
        trainingCostWhole: this.props.costs.trainingCost ? (this.props.costs.trainingCost * employeeCount).toString() : '0',
        travelWhole: this.props.costs.travel ? (this.props.costs.travel * employeeCount).toString() : '0',
        totalByOne: totalByOne.toString(),
        totalWhole: totalWhole.toString()
      }
    }
    this.calculateUSDValues()
  }

  calculateUSDValues = () => {
    if (!isNaN(parseFloat(this.learningBudget.currencyExchangeRate))) {
      if (isNaN(this.daysCount.value)) {
        this.daysCount.value = 0;
      }
      const trainingCost = parseFloat(this.values.trainingCost.replace(" ", ""))
      const travel = parseFloat(this.values.travel.replace(" ", ""))
      const residence = parseFloat(this.values.residence.replace(" ", ""))
      const dailyAllowance = parseFloat(this.values.dailyAllowance.replace(" ", ""))
      const employeeCount = this.employeeCount.value

      const exchangeRate = parseFloat(this.learningBudget.currencyExchangeRate);
      const trainingCostUSD = trainingCost / exchangeRate;
      const travelUSD = travel / exchangeRate;
      const residenceUSD = residence / exchangeRate;
      const dailyAllowanceUSD = dailyAllowance / exchangeRate;
      const trainingCostUSDWhole = trainingCostUSD * employeeCount;
      const travelUSDWhole = travelUSD * employeeCount;
      const residenceUSDWhole = residenceUSD * employeeCount;
      const dailyAllowanceUSDWhole = dailyAllowanceUSD * employeeCount;
      const totalByOneUSD = trainingCostUSD + travelUSD + residenceUSD + dailyAllowanceUSD;
      const totalWholeUSD = (trainingCostUSD + travelUSD + residenceUSD + dailyAllowanceUSD) * employeeCount;

      this.usdValues = {
        trainingCostUSD: isNaN(trainingCostUSD) ? '0' : formatAmount(trainingCostUSD.toString()),
        travelUSD: isNaN(travelUSD) ? '0' : formatAmount(travelUSD.toString()),
        residenceUSD: isNaN(residenceUSD) ? '0' : formatAmount(residenceUSD.toString()),
        dailyAllowanceUSD: isNaN(dailyAllowanceUSD) ? '0' : formatAmount(dailyAllowanceUSD.toString()),
        totalByOneUSD: isNaN(totalByOneUSD) ? '0' : formatAmount(totalByOneUSD.toString()),
        totalWholeUSD: isNaN(totalWholeUSD) ? '0' : formatAmount(totalWholeUSD.toString()),
        trainingCostUSDWhole: isNaN(trainingCostUSDWhole) ? '0' : formatAmount(trainingCostUSDWhole.toString()),
        travelUSDWhole: isNaN(travelUSDWhole) ? '0' : formatAmount(travelUSDWhole.toString()),
        residenceUSDWhole: isNaN(residenceUSDWhole) ? '0' : formatAmount(residenceUSDWhole.toString()),
        dailyAllowanceUSDWhole: isNaN(dailyAllowanceUSDWhole) ? '0' : formatAmount(dailyAllowanceUSDWhole.toString())
      };
    }
  }

  calculateValues = () => {
    const employeeCount = this.employeeCount ? this.employeeCount.value : 1
    const {
      trainingCost,
      travel,
      residence,
      dailyAllowance,
      residenceWhole,
      dailyAllowanceWhole
    } = fillData({
      dayCount: this.daysCount.value,
      travelCostString: this.values.travel,
      trainingCostString: this.values.trainingCost,
      dailyAllowanceString: this.values.dailyAllowance,
      residenceString: this.values.residence,
      employeeCount: employeeCount
    })
    this.props.costs!.dailyAllowance = dailyAllowance;
    this.props.costs!.residence = residence;
    const trainingCostWhole = trainingCost * (employeeCount || 1)
    const travelWhole = travel * (employeeCount || 1)
    const totalWhole = trainingCostWhole + travelWhole + residenceWhole + dailyAllowanceWhole
    const totalByOne = trainingCost + travel + residence + dailyAllowance

    this.values = {
      trainingCostWhole: formatAmount(trainingCostWhole.toString()),
      totalByOne: formatAmount(totalByOne.toString()),
      totalWhole: formatAmount(totalWhole.toString()),
      trainingCost: formatAmount((trainingCost).toString()),
      travelWhole: formatAmount(travelWhole.toString()),
      residence: formatAmount(residence.toString()),
      travel: formatAmount(travel.toString()),
      dailyAllowanceWhole: formatAmount(dailyAllowanceWhole.toString()),
      dailyAllowance: formatAmount(dailyAllowance.toString()),
      residenceWhole: formatAmount(residenceWhole.toString())
    }

  }

  calcResidenceAndDailyAllowance = () => {
    if (this.props.perdiemsSettings) {
      this.values.dailyAllowance = formatAmount((this.props.perdiemsSettings.amount * this.daysCount.value).toString())
      this.values.residence = formatAmount((this.props.perdiemsSettings.livingExpenses * this.daysCount.value).toString())
    } else if(this.props.form.getFieldValue('region')) {  
      Notification.error({
        message: this.props.intl.formatMessage({ id: "noPerdiemSetting" })
      });
    }
  }

  onTrainingCostChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.values.trainingCost = e.target.value
    this.calculateValues()
    this.calculateUSDValues()
  };

  onResidenceChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.values.residence = e.target.value
    this.calculateValues()
    this.calculateUSDValues()
  };

  onDailyAllowanceChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.values.dailyAllowance = e.target.value
    this.calculateValues()
    this.calculateUSDValues()
  };

  onTravelChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.values.travel = e.target.value
    this.calculateValues()
    this.calculateUSDValues()
  };

  render() {
    return <fieldset key={'field-set-221'} style={{
      border: "1px solid silver",
      borderRadius: "1rem",
      marginTop: "5%"
    }}>
      <legend
        style={{ margin: '0 1%', width: 'fit-content' }}>{this.props.intl.formatMessage({ id: "plannedCosts" })}</legend>
      <div style={{ margin: '1%' }}>
        <div style={{ display: "flex", flexDirection: 'row', width: "100%", marginBottom: "30px", justifyContent: 'space-between' }}>
          <TextArea rows={5} disabled={true}
            value={this.props.intl.formatMessage({ id: "budgetRequestNote" })} style={{ flex: 2 }} />
          <div style={{ width: '100%', margin: '5% 10px', flex: 1 }}>
            <div
              style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <label>{this.props.intl.formatMessage({ id: "exchangeRateToUSD" })}</label>
              <Input style={{ width: "5rem" }} disabled
                value={this.learningBudget.currencyExchangeRate} />
            </div>
            <div
              style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <label>{this.props.intl.formatMessage({ id: "nationalCurrency" })}</label>
              <Input style={{ width: "5rem" }} disabled
                value={this.learningBudget.currency!.code!.toString()} />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: "100%", display: 'flex', flexDirection: 'column' }}>
            {/*headers*/}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <label style={{ width: '100%' }} />
              <label style={{ width: '100%' }}>{this.props.intl.formatMessage({ id: "costByOne" })}</label>
              <label style={{ width: '100%' }}>{this.props.intl.formatMessage({ id: "costByWhole" })}</label>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label
                  style={{ width: '100%' }}>{this.messages[`${LearningBudgetRequestCost.NAME}.trainingCost`]}</label>
                <Form.Item style={{ width: '100%' }}>
                  {this.props.form.getFieldDecorator("trainingCost",
                    {
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.messages[`${LearningBudgetRequestCost.NAME}.trainingCost`] })
                      }],
                      initialValue: this.values.trainingCost
                    })(
                      <Input
                        style={{ width: '100%' }}
                        onChange={this.onTrainingCostChange}
                        onBlur={(e) => this.props.form.setFieldsValue({
                          trainingCost: formatAmount(e.target.value)
                        })} />
                    )}
                </Form.Item>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("trainingCostWhole",
                  {
                    initialValue: this.values.trainingCostWhole
                  })(
                    <Input key={'two'} style={{ marginTop: '2%' }}
                      disabled={true} />
                  )}
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label style={{ width: '100%' }}>{this.messages[`${LearningBudgetRequestCost.NAME}.travel`]}</label>
                <Form.Item style={{ width: "100%" }}>
                  {this.props.form.getFieldDecorator("travel",
                    {
                      rules: [{
                        required: true,
                        message: this.props.intl.formatMessage({ id: "form.validation.required" }, { fieldName: this.messages[`${LearningBudgetRequestCost.NAME}.travel`] })
                      }],
                      initialValue: this.values.travel
                    })(
                      <Input key={'three'} style={{ width: "100%" }}
                        onChange={this.onTravelChange}
                        onBlur={(e) => this.props.form.setFieldsValue({
                          travel: formatAmount(e.target.value)
                        })} />
                    )}
                </Form.Item>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("travelWhole",
                  {
                    initialValue: this.values.travelWhole
                  })(
                    <Input
                      disabled={true} />
                  )}
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label style={{ width: "100%" }}>{this.messages[`${LearningBudgetRequestCost.NAME}.residence`]}</label>
                <Form.Item style={{ width: "100%" }}>
                  <Input style={{ width: "100%" }}
                         required={true}
                         value={this.values.residence}
                         onChange={this.onResidenceChange}
                         onBlur={(e) => this.props.form.setFieldsValue({
                      residence: formatAmount(e.target.value)
                    })} />
                </Form.Item>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("residenceWhole",
                  {
                    initialValue: this.values.residenceWhole
                  })(
                    <Input style={{ marginTop: '2%' }}
                      disabled={true} />
                  )}
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label
                  style={{ width: "100%" }}>{this.messages[`${LearningBudgetRequestCost.NAME}.dailyAllowance`]}</label>
                <Form.Item style={{ width: "100%" }}>
                  <Input style={{ width: "100%" }}
                    required={true}
                    value={this.values.dailyAllowance}
                    onChange={this.onDailyAllowanceChange}
                    onBlur={(e) => this.props.form.setFieldsValue({
                      dailyAllowance: formatAmount(e.target.value)
                    })} />
                </Form.Item>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("dailyAllowanceWhole",
                  {
                    initialValue: this.values.dailyAllowanceWhole
                  })(
                    <Input style={{ marginTop: '2%' }}
                      disabled={true} />
                  )}
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label style={{ width: "100%" }}>{this.props.intl.formatMessage({ id: "total" })}</label>
                {this.props.form.getFieldDecorator("totalByOne",
                  {
                    initialValue: this.values.totalByOne
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("totalWhole",
                  {
                    initialValue: this.values.totalWhole
                  })(
                    <Input
                      disabled={true} />
                  )}
              </div>
            </div>
          </div>
          <div style={{ width: "100%", display: 'flex', flexDirection: 'column', marginTop: '5%' }}>
            {/*headers*/}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
              <label style={{ width: '100%' }} />
              <label style={{ width: '100%' }}>{this.props.intl.formatMessage({ id: "costByOneUSD" })}</label>
              <label style={{ width: '100%' }}>{this.props.intl.formatMessage({ id: "costByWholeUSD" })}</label>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '3%'
            }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label style={{ width: "100%" }}>{this.props.intl.formatMessage({ id: "trainingCostUSD" })}</label>
                {this.props.form.getFieldDecorator("trainingCostUSD",
                  {
                    initialValue: this.usdValues.trainingCostUSD
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("trainingCostUSDWhole",
                  {
                    initialValue: this.usdValues.trainingCostUSDWhole
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '3%'
            }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label style={{ width: "100%" }}>{this.props.intl.formatMessage({ id: "travelUSD" })}</label>
                {this.props.form.getFieldDecorator("travelUSD",
                  {
                    initialValue: this.usdValues.travelUSD
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("travelUSDWhole",
                  {
                    initialValue: this.usdValues.travelUSDWhole
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '3%'
            }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label style={{ width: "100%" }}>{this.props.intl.formatMessage({ id: "residenceUSD" })}</label>
                {this.props.form.getFieldDecorator("residenceUSD",
                  {
                    initialValue: this.usdValues.residenceUSD
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("residenceUSDWhole",
                  {
                    initialValue: this.usdValues.residenceUSDWhole
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '3%'
            }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label
                  style={{ width: "100%" }}>{this.props.intl.formatMessage({ id: "dailyAllowanceUSD" })}</label>
                {this.props.form.getFieldDecorator("dailyAllowanceUSD",
                  {
                    initialValue: this.usdValues.dailyAllowanceUSD
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("dailyAllowanceUSDWhole",
                  {
                    initialValue: this.usdValues.dailyAllowanceUSDWhole
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '3%'
            }}>
              <div style={{ marginRight: '1%', display: 'flex', flexDirection: 'row', width: '100%', flex: '2' }}>
                <label style={{ width: "100%" }}>{this.props.intl.formatMessage({ id: "total" })}</label>
                {this.props.form.getFieldDecorator("totalByOneUSD",
                  {
                    initialValue: this.usdValues.totalByOneUSD
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: '1' }}>
                {this.props.form.getFieldDecorator("totalWholeUSD",
                  {
                    initialValue: this.usdValues.totalWholeUSD
                  })(
                    <Input style={{ width: "100%" }}
                      disabled={true} />
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  }

  componentWillUnmount() {
    this.perdiemsReaction.$mobx.dispose()
  }
}
