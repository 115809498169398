import React, { MouseEventHandler } from 'react';
import { ColumnProps, TableProps } from "antd/es/table";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { Button, Input, Table } from "antd";
import {
    SorterResult,
    TableEventListeners,
    TableRowSelection
} from "antd/lib/table/interface";
import { IntlShape } from 'react-intl';
import { SerializedEntity } from '@cuba-platform/rest';
import { PersonGroupExt } from '../../../cuba/entities/base/base$PersonGroupExt';
import { AssignmentExt } from "../../../cuba/entities/base/base$AssignmentExt";
import Column from "antd/es/table/Column";
import { PositionGroupExt } from '../../../cuba/entities/base/base$PositionGroupExt';
import { OrganizationGroupExt } from '../../../cuba/entities/base/base$OrganizationGroupExt';
import { Person } from '../../../cuba/entities/base/base$Person';

export enum SelectRowType {
    NONE = "none",
    SINGLE = "single",
    MULTI = "multi"
}


type PaginationPosition = 'top' | 'bottom' | 'both';


type PickerTableProps = {
    selectRowType?: SelectRowType,
    columns?: ColumnProps<AssignmentExt>[],
    items: Array<AssignmentExt>,
    paginationPosition?: PaginationPosition,
    onRowClick?: (record: AssignmentExt, index: number, event?: Event) => void,
    onOkClick: (record: AssignmentExt[]) => void,
    onCancelClick: () => void,
    tableProps: TableProps<AssignmentExt>,
    intl?: IntlShape
}

type TableState<AssignmentExt> = {
    loading: boolean,
    pagination?: PageConfig | false,
    selectedRowKeys: number[],
    items: Array<AssignmentExt>,
    sorterColumns: SorterResult<AssignmentExt>[],
}

type PageConfig = {
    position?: PaginationPosition,
    length?: number
}


@observer
export default class EmployeePickerTable extends React.Component<PickerTableProps> {

    @observable filteredItems: Array<AssignmentExt>;
    @observable allItems: Array<AssignmentExt>;
    @observable selectedRows: AssignmentExt[] = [];

    @observable tableState: TableState<AssignmentExt> = {
        loading: false,
        selectedRowKeys: [],
        items: [],
        pagination: false,
        sorterColumns: []
    };

    constructor(props: PickerTableProps, context: any) {
        super(props, context);

        if (this.props.paginationPosition) {
            this.tableState.pagination = {
                position: this.props.paginationPosition
            }
        }
    }

    componentDidMount() {
        this.allItems = this.props.items;
        this.filteredItems = this.allItems;
    }

    @action
    setItems = (items: Array<AssignmentExt>) => {
        this.tableState.items = items;
    }

    @action
    setPageCount = (pageCount: number | undefined) => {
        if (this.tableState.pagination) {
            this.tableState.pagination.length = pageCount;
        }
    }

    @action
    setLoading = (loading: boolean) => {
        this.tableState.loading = loading;
    }

    @action
    setSelectedRowKeys = (value: number[]) => {
        this.tableState.selectedRowKeys = value;
    }

    @action
    addSelectedRowKey = (value: any) => {
        this.tableState.selectedRowKeys.push(value);
    }

    @action
    addSortColumn = (value: SorterResult<AssignmentExt>) => {
        this.tableState.sorterColumns.push(value);
    }

    @action
    clearSelectedRowKeys = () => {
        this.tableState.selectedRowKeys = [];
    }


    onSelectedRowKeysChange = (selectedRowKeys: any, selectedRows: AssignmentExt[]) => {
        this.clearSelectedRowKeys();
        if (this.props.selectRowType === SelectRowType.MULTI) {
            this.selectedRows = selectedRows;
            this.setSelectedRowKeys(selectedRowKeys);
        } else {
            this.addSelectedRowKey(selectedRowKeys[0]);
            this.selectedRows[0] = selectedRows[0];
        }

    }

    updateMultiSelectedRows = (record: AssignmentExt, index: number) => {
        if (this.selectedRows.includes(record)) {
            this.selectedRows.splice(this.selectedRows.indexOf(record), 1)
        } else {
            this.selectedRows.push(record);
        }

        const selectedKeys = this.tableState.selectedRowKeys;

        if (selectedKeys.includes(index)) {
            selectedKeys.splice(selectedKeys.indexOf(index), 1)
        } else {
            selectedKeys.push(index);
        }
        this.clearSelectedRowKeys();
        this.setSelectedRowKeys(selectedKeys);
    }

    render() {
        let rowSelection: TableRowSelection<any> | undefined;
        let onRowClick: ((record: AssignmentExt, index: number, event: Event) => void) | undefined;

        if (this.props.selectRowType) {
            switch (this.props.selectRowType) {
                case SelectRowType.MULTI: {
                    rowSelection = {
                        type: 'checkbox',
                        onChange: this.onSelectedRowKeysChange,
                        selectedRowKeys: this.tableState.selectedRowKeys
                    };
                    onRowClick = (record: AssignmentExt, index: number, event: Event) => {
                        this.updateMultiSelectedRows(record, index);

                    }
                    break;
                }
                case SelectRowType.SINGLE: {
                    rowSelection = {
                        type: 'radio',
                        onChange: this.onSelectedRowKeysChange,
                        selectedRowKeys: this.tableState.selectedRowKeys
                    }
                    onRowClick = (record: AssignmentExt, index: number, event: Event) => {
                        this.clearSelectedRowKeys();
                        this.selectedRows[0] = record;
                        this.addSelectedRowKey(index);

                    }
                    break;
                }
                default: {
                    onRowClick = this.props.onRowClick;
                    break;
                }
            }
        } else {
            onRowClick = this.props.onRowClick;
        }

        const tableEventListeners = (record: AssignmentExt, index: number) => {
            return {
                onClick: (arg: React.MouseEvent) => {
                    if (this.props.selectRowType === SelectRowType.MULTI) {
                        this.updateMultiSelectedRows(record, index);

                    } else {
                        this.clearSelectedRowKeys();
                        this.selectedRows[0] = record;
                        this.addSelectedRowKey(index);
                    }

                }

            } as TableEventListeners
        };
        const className = (this.props.selectRowType ? this.props.selectRowType + "-table" : "")
            + (onRowClick ? " actionable" : "");
        return (
            <div className={"table-container"}>
                <div style={{ marginBottom: "5px" }}>
                    <Input onChange={value => {
                        this.filteredItems = this.allItems
                            .filter(item =>
                                ((item as SerializedEntity<AssignmentExt>).personGroup as SerializedEntity<PersonGroupExt>)._instanceName.toLowerCase()
                                    .includes(value.target.value.toLowerCase()))
                    }} />
                </div>
                <Table className={className}
                    pagination={this.tableState.pagination}
                    dataSource={this.filteredItems}
                    style={{ height: "700px" }}
                    onRow={tableEventListeners}
                    rowSelection={rowSelection}
                    showHeader={true}
                    scroll={{ x: 1000, y: 500 }}>

                    <Column key='personGroup' title={this.props.intl!.formatMessage({ id: "personName" })}
                        render={(text, record: AssignmentExt) => (record.personGroup as SerializedEntity<PersonGroupExt>)._instanceName} />
                    <Column key='employeeNumber' title={this.props.intl!.formatMessage({ id: "employeeNumber" })}
                        render={(text, record: AssignmentExt) => (record.personGroup!.person as SerializedEntity<Person>).employeeNumber} />
                    <Column key='organizationGroup' title={this.props.intl!.formatMessage({ id: "organizationGroup" })}
                        render={(text, record: AssignmentExt) => (record.organizationGroup as SerializedEntity<OrganizationGroupExt>)._instanceName} />
                    <Column key='positionGroup' title={this.props.intl!.formatMessage({ id: "postionGroup" })}
                        render={(text, record: AssignmentExt) => (record.positionGroup as SerializedEntity<PositionGroupExt>)._instanceName} />
                </Table>
                <div style={{ width: "100%", height: "35px" }}>
                    <div style={{ float: "right" }}>
                        <Button style={{ display: "inline-block", marginRight: "5px" }}
                            onClick={() => {
                                this.props.onOkClick(this.selectedRows);
                                this.clearSelectedRowKeys();
                                this.selectedRows = [];
                            }}>{this.props.intl!.formatMessage({ id: "cubaReact.dataTable.ok" })}
                        </Button>
                        <Button style={{ display: "inline-block" }}
                            onClick={() => {
                                this.props.onCancelClick();
                                this.clearSelectedRowKeys();
                                this.selectedRows = [];
                            }}>{this.props.intl!.formatMessage({ id: "management.browser.exclude.cancel" })}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}