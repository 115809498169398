import * as React from "react";
import {inject, observer} from "mobx-react";
import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {Button, Col, Row, Input, Typography, Tabs, Select, Upload, message, Form, Modal} from 'antd';
import {RefferalCandidate} from "../../../cuba/entities/base/tsadv_RefferalCandidate";
import {Link} from "react-router-dom";
import {DicExperience} from "../../../cuba/entities/base/tsadv_DicExperience";
import {DicRelationshipToReferrer} from "../../../cuba/entities/base/tsadv_DicRelationshipToReferrer";
import {DicEducation} from "../../../cuba/entities/base/tsadv_DicEducation";
import {DicPersonalEvaluation} from "../../../cuba/entities/base/tsadv_DicPersonalEvaluation";
import {action, observable, runInAction} from "mobx";
import {restServices} from "../../../cuba/services";
import {Requisition} from "../../../cuba/entities/base/tsadv$Requisition";
import {RouteComponentProps} from "react-router";
import {DicSource} from "../../../cuba/entities/base/tsadv$DicSource";
import {JobRequest} from "../../../cuba/entities/base/tsadv$JobRequest";
import moment from "moment";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import {organizationVacancyListPATH} from "./OgranizationVacancyList";
import {FormComponentProps, FormItemProps} from "antd/es/form";
import {FormEvent} from "react";
import {PersonGroupExt} from "../../../cuba/entities/base/base$PersonGroupExt";
import {PersonAttachment} from "../../../cuba/entities/base/tsadv$PersonAttachment";
import {DicAttachmentCategory} from "../../../cuba/entities/base/tsadv$DicAttachmentCategory";
import {SerializedEntity} from "@cuba-platform/rest";
import Notification from "../../util/Notification/Notification";

export const organizationVacancyRecommendPATH = "/OrganizationVacancyRecommend";

type Props = RouteComponentProps<{ entityId: string }>;


@injectMainStore
@inject("rootStore")
@observer

class organizationVacancyRecommendClass extends React.Component<FormComponentProps & Props & MainStoreInjected & WrappedComponentProps & RootStoreProp> {
  DicExperience = collection<DicExperience>(DicExperience.NAME, {
    view: "_local",
  });
  DicRelationshipToReferrer = collection<DicRelationshipToReferrer>(DicRelationshipToReferrer.NAME, {
    view: "_local",
  });
  DicEducation = collection<DicEducation>(DicEducation.NAME, {
    view: "_local",
  });
  DicPersonalEvaluation = collection<DicPersonalEvaluation>(DicPersonalEvaluation.NAME, {
    view: "_local",
  });
  DicSource = collection<DicSource>(DicSource.NAME, {
    view: "_minimal",
    filter: {
      conditions: [{
        property: "code",
        operator: "=",
        value: "REF_PROGRAM"
      }]
    }
  })
  @observable firstName: string;
  @observable lastName: string;
  @observable vacancyName: string;
  @observable workExperienceId: string;
  @observable relationshipToReferrerId: string;
  @observable fileId: string;
  @observable fileName: string;
  @observable educationId: string;
  @observable personGroupId: string;
  @observable personalEvaluationId: string;
  @observable cityResidenceName: string;
  @observable saving: boolean = false;
  @observable resume: Array<SerializedEntity<DicAttachmentCategory>>;
  @observable candidate:SerializedEntity<PersonExt>;
  @action saveRecommendNewCandidate = () => {
    this.saving = true;
    message.loading({
      content: this.props.intl.formatMessage({id: "message.saving"}),
      key: "updatatable",
      duration: 0
    })
    restServices.learningService.createCandidateForReferral({
      firstName: this.firstName,
      lastName: this.lastName,
      requisitionId: this.props.match.params.entityId
    })
      .then(value => {
        const candidate:RefferalCandidate = {
          vacancy: {id: this.props.match.params.entityId},
          referrer: {id: this.props.rootStore!.userInfo.personGroupId!},
          file: {id: this.fileId},
          relationshipToReferrer: {id: this.relationshipToReferrerId},
          workExperience: {id: this.workExperienceId},
          education: {id: this.educationId},
          personGroup: {id: value.id},
          personalEvaluation: {id: this.personalEvaluationId},
          cityResidence: this.cityResidenceName
        }
        const attachment:PersonAttachment = {
          personGroup: {id: value.id},
          attachment: {id: this.fileId},
          category: this.resume[0],
          filename: this.fileName
        }
        this.saveCandidateRequest(candidate,attachment,value.id)
      })
  }
  @action saveCandidateRequest = async (refferalCandidateData:RefferalCandidate,personAttachmentData:PersonAttachment,personGroupId:string) => {
    const refferalCandidate = getCubaREST()!.commitEntity<RefferalCandidate>(RefferalCandidate.NAME,refferalCandidateData )
    const personAttachment = getCubaREST()!.commitEntity<PersonAttachment>(PersonAttachment.NAME, personAttachmentData)
    const personGroupExt = getCubaREST()!.loadEntity<PersonGroupExt>(PersonGroupExt.NAME, personGroupId, {
      view: "person-group-ext-intern"
    }).then(personGroup => {
      getCubaREST()!.commitEntity<PersonExt>(PersonExt.NAME, {
        id: (personGroup.person && personGroup.person.id),
        source: {id: this.DicSource.items[0].id},
        city: this.cityResidenceName
      })
    })
    const jobRequest = restServices.learningService.createJobRequestForRecommendation({requisition:this.props.match.params.entityId,requestDate:moment().toISOString(),candidatePersonGroup:personGroupId})
    await Promise.all([refferalCandidate, personGroupExt, jobRequest, personAttachment])
      .then(() => {
        message.success({
          content: this.props.intl.formatMessage({id: "organizationVacancy.recommend.message.success"}),
          key: "updatatable"
        })
        this.props.history.push(organizationVacancyListPATH)
      })
      .catch(() => message.error({
        content: this.props.intl.formatMessage({id: "management.editor.error"}),
        key: "updatatable",
        duration: 2
      })).finally(() => this.saving = false)
  }
  @action recommend = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Modal.confirm({
      title: this.props.intl.formatMessage({id: "organizationVacancy.modal.recommend.title"}),
      okText: this.props.intl.formatMessage({id: "cubaReact.dataTable.yes"}),
      cancelText: this.props.intl.formatMessage({id: "cubaReact.dataTable.no"}),
      onOk: () => {
        this.props.form.validateFieldsAndScroll((error) => {
          if (!error) {
            getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME,{conditions:[{
                property:"firstName",
                operator:"=",
                value:this.firstName
              },{
                property:"lastName",
                operator:"=",
                value:this.lastName
              },{
                property:"type.code",
                operator:"=",
                value:"CANDIDATE"
              }]},{view:"person-for-search"}).then(res=>{if(res.length>0){
              this.candidate = res[0];
              getCubaREST()!.searchEntities<JobRequest>(JobRequest.NAME,{conditions:[{
                  group:"AND",
                  conditions:[
                    {
                      property:"requisition.id",
                      operator:"=",
                      value:this.props.match.params.entityId
                    },{
                      property:"candidatePersonGroup.id",
                      operator:"=",
                      value:res[0].group&&res[0].group.id
                    }]}]},{view:"jobRequest.full"}).then(res=>runInAction(()=>{
                if(res.length===0){
                  this.saving = true;
                  getCubaREST()!.searchEntities<RefferalCandidate>(RefferalCandidate.NAME,{conditions:[{
                      property:"personGroup.id",
                      operator:"=",
                      value:this.candidate.group&&this.candidate.group.id
                    }]}).then(async (candidates)=>{
                    if(candidates.length>0) {
                      const candidate: RefferalCandidate = {
                        id: candidates[0].id,
                        vacancy: {id: this.props.match.params.entityId},
                        referrer: {id: this.props.rootStore!.userInfo.personGroupId!},
                        file: {id: this.fileId},
                        relationshipToReferrer: {id: this.relationshipToReferrerId},
                        workExperience: {id: this.workExperienceId},
                        education: {id: this.educationId},
                        personGroup: {id: this.candidate.group && this.candidate.group.id},
                        personalEvaluation: {id: this.personalEvaluationId},
                        cityResidence: this.cityResidenceName
                      }
                      const attachment: PersonAttachment = {
                        personGroup: {id: this.candidate.group && this.candidate.group.id},
                        attachment: {id: this.fileId},
                        category: this.resume[0],
                        filename: this.fileName
                      }
                      await this.saveCandidateRequest(candidate, attachment, this.candidate.group && this.candidate.group.id);
                    }
                  })
                  return
                }
                Notification.error({message:this.props.intl.formatMessage({id:"candidateAlreadyRecommended.error"})})
              }))
            }
            else{
              this.saveRecommendNewCandidate()
            }
            })}
        });
      }
    })
  }

  @observable isHaveReq:boolean;
  render() {
    const {Option} = Select
    const {TabPane} = Tabs;
    const {Text} = Typography;
    const formItemProps: FormItemProps = {style: {marginBottom: 0}}
    const sortedDicExp = this.DicExperience && this.DicExperience.items.sort((a, b) =>
      a._instanceName.localeCompare(b._instanceName, undefined, {numeric: true}));
    return (
      <Page pageName={<FormattedMessage id={"organization.vacancies"}/>}>
        <Section size="large">
          <Tabs size={"large"} style={{width: "100%"}}>
            <TabPane tab={<FormattedMessage id={"candidateRecommendationForm"}/>} key={"1"}>
              <Form onSubmit={(e) => this.recommend(e)}>
                <Section sectionName={this.props.intl.formatMessage({id: "recommenderData"})} size={"large"}
                         style={{width: "95%"}}>
                  <Row>
                    <Col span={10}><Text strong>{this.props.intl.formatMessage({id: "enterYourName"})}</Text></Col>
                    <Col span={14}>
                      <Input readOnly type={'text'} value={this.props.rootStore!.userInfo!.firstLastName}/>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text
                      strong>{this.props.intl.formatMessage({id: "enterYourCorporateEmail"})}</Text></Col>
                    <Col span={14}><Input readOnly type={'text'}
                                          value={this.props.rootStore!.userInfo!.email}/></Col>
                  </Row>
                </Section>
                <Section sectionName={this.props.intl.formatMessage({id: "recommendedData"})} size={"large"}
                         style={{width: "95%"}}>
                  <Row>
                    <Col span={10}><Text
                      strong>{this.props.intl.formatMessage({id: "enterCandidateName"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('candidateFirstName', {
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![PersonExt.NAME + '.firstName']})
                          }]
                        })
                        (<Input onChange={(event) => runInAction(() => this.firstName = event.target.value)}/>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text
                      strong>{this.props.intl.formatMessage({id: "enterCandidateLastName"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('candidateLastName', {
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![PersonExt.NAME + '.lastName']})
                          }]
                        })
                        (<Input onChange={(event) => runInAction(() => this.lastName = event.target.value)}/>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text strong>{this.props.intl.formatMessage({id: "workExperience"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('workExperience', {
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![RefferalCandidate.NAME + '.workExperience']})
                          }]
                        })
                        (<Select style={{width: "100%"}} onChange={(value: string) => runInAction(
                          () => this.workExperienceId = value
                        )}
                                 placeholder={this.props.intl.formatMessage({id: "workExperience"})}>
                          {sortedDicExp.map(value =>
                            <Option key={value.id} value={value.id}>{value["_instanceName"]}</Option>
                          )}
                        </Select>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text
                      strong>{this.props.intl.formatMessage({id: "candidateCityOfResidence"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('cityResidence', {
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![RefferalCandidate.NAME + '.cityResidence']})
                          }]
                        })
                        (<Input
                          onChange={(event) => runInAction(() => this.cityResidenceName = event.target.value)}/>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text strong>{this.props.intl.formatMessage({id: "recommendedCandidate"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('relationshipToReferrer', {
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![RefferalCandidate.NAME + '.relationshipToReferrer']})
                          }]
                        })
                        (<Select style={{width: "100%"}}
                                 placeholder={this.props.intl.formatMessage({id: "recommendedCandidate"})}
                                 onChange={(value: string) => runInAction(
                                   () => this.relationshipToReferrerId = value
                                 )}>
                          {this.DicRelationshipToReferrer.items.map(value => <Option key={value.id}
                                                                                     value={value.id}>{value["_instanceName"]}</Option>)}
                        </Select>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text
                      strong>{this.props.intl.formatMessage({id: "candidateEducation"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('education', {
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![RefferalCandidate.NAME + '.education']})
                          }]
                        })
                        (<Select style={{width: "100%"}}
                                 placeholder={this.props.intl.formatMessage({id: "candidateEducation"})}
                                 onChange={(value: string) => runInAction(
                                   () => this.educationId = value
                                 )}>
                          {this.DicEducation.items.map(value => <Option key={value.id}
                                                                        value={value.id}>{value["_instanceName"]}</Option>)}
                        </Select>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text
                      strong>{this.props.intl.formatMessage({id: "candidatePersonalEvaluation"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('personalEvaluation', {
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![RefferalCandidate.NAME + '.personalEvaluation']})
                          }]
                        })
                        (<Select style={{width: "100%"}}
                                 placeholder={this.props.intl.formatMessage({id: "candidatePersonalEvaluation"})}
                                 onChange={(value: string) => runInAction(
                                   () => this.personalEvaluationId = value
                                 )}>
                          {this.DicPersonalEvaluation.items.map(value => <Option key={value.id}
                                                                                 value={value.id}>{value["_instanceName"]}</Option>)}
                        </Select>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col span={10}><Text
                      strong>{this.props.intl.formatMessage({id: "attachCandidateResume"})}</Text></Col>
                    <Col span={14}>
                      <Form.Item {...formItemProps}>
                        {this.props.form.getFieldDecorator('file', {
                          valuePropName: 'fileList',
                          getValueFromEvent: e => {
                            if (Array.isArray(e)) {
                              return e;
                            }
                            return e && e.fileList;
                          },
                          rules: [{
                            required: true,
                            message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.props.mainStore!.messages![RefferalCandidate.NAME + '.file']})
                          }]
                        })
                        (<Upload
                          style={{display: "inline-block"}} action={getCubaREST()!.getFileUploadURL()}
                          headers={{'Authorization': 'Bearer ' + getCubaREST()!.restApiToken}}
                          onChange={(info) => runInAction(() => {
                            if (info.file && info.file.response) {
                              this.fileId = info.file.response.id
                              this.fileName = info.file.response.name!;
                            }
                          })}>
                          <Button icon={"upload"} type={"default"}>
                            <FormattedMessage id="file.Upload"/>
                          </Button>
                        </Upload>)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <br/>
                  <Row style={{marginTop: "20px"}}>
                    <Col span={3}><Button htmlType={"submit"} type={"primary"}
                                          style={{marginLeft: "20px"}}><FormattedMessage id={"recommend"}/></Button></Col>
                    <Col span={3}><Link to={organizationVacancyListPATH}><Button
                      style={{marginLeft: "20px"}}><FormattedMessage id={"form.cancel"}/></Button></Link></Col>
                  </Row>
                </Section>
              </Form>
            </TabPane>
          </Tabs>
        </Section>
      </Page>

    );
  }

  componentDidMount() {
    getCubaREST()!.loadEntity<Requisition>(Requisition.NAME, this.props.match.params.entityId, {view: "jobOfferRequisition"})
      .then(value => runInAction(() => this.vacancyName = (value.positionGroup && value.positionGroup['_instanceName'])))
    getCubaREST()!.searchEntities<DicAttachmentCategory>(DicAttachmentCategory.NAME, {
      conditions: [{
        property: "code",
        operator: "=",
        value: "RESUME"
      }]
    }, {
      view: "_minimal"
    }).then(value => runInAction(() => this.resume = value))
  }
}

const organizationVacancyRecommend = Form.create()(injectIntl(organizationVacancyRecommendClass));
export default organizationVacancyRecommend;

