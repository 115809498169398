import * as React from "react";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import DataTableFormat from "../../components/DataTable/intex";
import {ScheduleOffsetsRequest} from "../../../cuba/entities/base/tsadv_ScheduleOffsetsRequest";
import {Button} from "antd";
import {Link} from "react-router-dom";
import {ScheduleOffsetsRequestManagement} from "../ScheduleOffsetsRequest/ScheduleOffsetsRequestManagement";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {RootStoreProp} from "../../store";
@injectMainStore
@observer
@inject("rootStore")
class ScheduleOffsetList extends React.Component<MainStoreInjected&WrappedComponentProps&RootStoreProp> {
  @observable dataCollection = collection<ScheduleOffsetsRequest>(
    ScheduleOffsetsRequest.NAME,
    {filter:{
        conditions:[{
          property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!
        }]
      }, view: "scheduleOffsetsRequest-for-my-team", sort: "-updateTs" }
  );

  fields = [
    "requestNumber",

    "status",

    "personGroup",

    "currentSchedule",

    "newSchedule",

    "dateOfStartNewSchedule",
  ];
  render() {


    return (
      <Page>
          <Section size={"large"}>

          <Link to={ScheduleOffsetsRequestManagement.PATH+"/"+ScheduleOffsetsRequestManagement.NEW_SUBPATH}>
          <Button type={"primary"} style={{width:125}}>
            {this.props.intl.formatMessage({id:"createRequest"})}
          </Button>
          </Link>
        <DataTableFormat render={[{column:"requestNumber", render:((text, record) => <Link to={ScheduleOffsetsRequestManagement.PATH+"/"+record.id}>{text}</Link>)}]}
          hideSelectionColumn={true} canSelectRowByClick={false} dataCollection={this.dataCollection} fields={this.fields}/>
          </Section>
          </Page>
    );
  }
}
export default injectIntl(ScheduleOffsetList);
