import { ILearningBudgetRequest } from "./tsadv_ILearningBudgetRequest";
import { LearningBudgetRequestCost } from "./tsadv_LearningBudgetRequestCost";
import { LearningBudgetRequestPrev } from "./tsadv_LearningBudgetRequestPrev";
import { LearningBudgetRequestHeader } from "./tsadv_LearningBudgetRequestHeader";
export class LearningBudgetRequest extends ILearningBudgetRequest {
  static NAME = "tsadv_LearningBudgetRequest";
  cost?: LearningBudgetRequestCost | null;
  learningBudgetRequestPrev?: LearningBudgetRequestPrev | null;
  learningBudgetRequestHeader?: LearningBudgetRequestHeader | null;
}
export type LearningBudgetRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "learningBudgetRequest-front-view"
  | "learningBudgetRequest-full-view";
export type LearningBudgetRequestView<
  V extends LearningBudgetRequestViewName
> = V extends "_base"
  ? Pick<
      LearningBudgetRequest,
      | "id"
      | "courseName"
      | "courseNameEng"
      | "city"
      | "month"
      | "positionName"
      | "employeeCount"
      | "reason"
      | "costByPerson"
      | "costSum"
      | "dayCount"
    >
  : V extends "_local"
  ? Pick<
      LearningBudgetRequest,
      | "id"
      | "courseName"
      | "courseNameEng"
      | "city"
      | "month"
      | "positionName"
      | "employeeCount"
      | "reason"
      | "costByPerson"
      | "costSum"
      | "dayCount"
    >
  : V extends "learningBudgetRequest-front-view"
  ? Pick<
      LearningBudgetRequest,
      | "id"
      | "cost"
      | "employee"
      | "employeeCount"
      | "course"
      | "typeOfTraining"
      | "learningType"
      | "courseName"
      | "courseNameEng"
      | "reason"
      | "positionName"
      | "dayCount"
      | "organizationGroup"
      | "costCenter"
      | "region"
      | "month"
      | "city"
      | "costByPerson"
      | "costSum"
      | "reportGroup"
      | "learningBudgetRequestHeader"
    >
  : V extends "learningBudgetRequest-full-view"
  ? Pick<
      LearningBudgetRequest,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "courseName"
      | "courseNameEng"
      | "city"
      | "month"
      | "positionName"
      | "employeeCount"
      | "reason"
      | "costByPerson"
      | "costSum"
      | "dayCount"
      | "learningBudgetRequestPrev"
      | "budget"
      | "cost"
      | "course"
      | "learningType"
      | "typeOfTraining"
      | "region"
      | "organizationGroup"
      | "costCenter"
      | "responsiblePerson"
      | "employee"
      | "reportGroup"
    >
  : never;
