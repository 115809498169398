class NcaLayer {
  public webSocket: WebSocket | null = null;
  private callback: ((result: any) => void) | null = null;
  private static statesEnum = {
    0: "CONNECTING",
    1: "OPEN",
    2: "CLOSING",
    3: "CLOSED"
  };


  constructor() {
    this.webSocket = null;
    this.callback = null;
  }


  public connect(): void {
    this.callback = null;
    this.webSocket = new WebSocket("wss://127.0.0.1:13579/");

    this.webSocket.addEventListener("open", (event) => {
      console.log("Connection opened");
      this.connectionStateChanged();
    });

    this.webSocket.addEventListener("close", (event) => {
      if (event.wasClean) {
        console.log("Connection has been closed");
      } else {
        console.log("Connection error");
        this.openDialog();
      }
      console.log("Code: " + event.code + " Reason: " + event.reason);
      this.connectionStateChanged();
    });

    this.webSocket.onmessage = (event) => {
      var result = JSON.parse(event.data);

      if (result != null) {
        var rw = {
          code: result["code"],
          result: result["result"],
          message: result["message"],
          responseObject: result["responseObject"],
          getResult: function () {
            return this.result;
          },
          getMessage: function () {
            return this.message;
          },
          getResponseObject: function () {
            return this.responseObject;
          },
          getCode: function () {
            return this.code;
          },
        };
        if (this.callback != null) {
          this.callback(rw);
        }
      }
    };
  }

  public getConnectionState(): string {
    if (this.webSocket) {
      switch (this.webSocket.readyState) {
        case WebSocket.CONNECTING:
          return "CONNECTING";
        case WebSocket.OPEN:
          return "OPEN";
        case WebSocket.CLOSING:
          return "CLOSING";
        case WebSocket.CLOSED:
          return "CLOSED";
        default:
          return "UNKNOWN";
      }
    }
    return "DISCONNECTED";
  }

  public openDialog(): void {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Ошибка при подключении к NCALayer. Запустите NCALayer и нажмите ОК"
      ) === true
    ) {
      this.connect();
    } else {
      this.webSocket!.close();
    }
  }

  public getActiveTokens(callBack: (result: any) => void): void {
    var getActiveTokens = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "getActiveTokens"
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(getActiveTokens));
  }

  public getKeyInfo(storageName: string, callBack: (result: any) => void): void {
    var getKeyInfo = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "getKeyInfo",
      args: [storageName]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(getKeyInfo));
  }

  public signXml(storageName: string, keyType: string, xmlToSign: string, callBack: (result: any) => void): void {
    var signXml = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "signXml",
      args: [storageName, keyType, xmlToSign, "", ""]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(signXml));
  }

  public signXmls(storageName: string, keyType: string, xmlsToSign: string, callBack: (result: any) => void): void {
    var signXmls = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "signXmls",
      args: [storageName, keyType, xmlsToSign, "", ""]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(signXmls));
  }

  public createCAdESFromFile(storageName: string, keyType: string, filePath: string, flag: string, callBack: (result: any) => void): void {
    var createCAdESFromFile = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "createCAdESFromFile",
      args: [storageName, keyType, filePath, flag]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(createCAdESFromFile));
  }

  public createCAdESFromBase64(storageName: string, keyType: string, base64ToSign: string, flag: string, callBack: (result: any) => void): void {
    var createCAdESFromBase64 = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "createCAdESFromBase64",
      args: [storageName, keyType, base64ToSign, flag]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(createCAdESFromBase64));
  }

  public createCAdESFromBase64Hash(storageName: string, keyType: string, base64ToSign: string, callBack: (result: any) => void): void {
    var createCAdESFromBase64Hash = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "createCAdESFromBase64Hash",
      args: [storageName, keyType, base64ToSign]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(createCAdESFromBase64Hash));
  }

  public applyCAdEST(storageName: string, keyType: string, cmsForTS: string, callBack: (result: any) => void): void {
    var applyCAdEST = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "applyCAdEST",
      args: [storageName, keyType, cmsForTS]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(applyCAdEST));
  }

  public showFileChooser(fileExtension: string, currentDirectory: string, callBack: (result: any) => void): void {
    var showFileChooser = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "showFileChooser",
      args: [fileExtension, currentDirectory]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(showFileChooser));
  }

  public changeLocale(language: string): void {
    var changeLocale = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "changeLocale",
      args: [language]
    };
    this.callback = null;
    this.webSocket!.send(JSON.stringify(changeLocale));
  }

  public createCMSSignatureFromFile(storageName: string, keyType: string, filePath: string, flag: string, callBack: (result: any) => void): void {
    var createCMSSignatureFromFile = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "createCMSSignatureFromFile",
      args: [storageName, keyType, filePath, flag]
    };
    this.callback = callBack;
    this.webSocket!.send(JSON.stringify(createCMSSignatureFromFile));
  }

  public createCMSSignatureFromBase64(storageName: string, keyType: string, base64ToSign: string, flag: boolean, callBack: (result: any) => void): void {
    var createCMSSignatureFromBase64 = {
      module: "kz.gov.pki.knca.commonUtils",
      method: "createCMSSignatureFromBase64",
      args: [storageName, keyType, base64ToSign, flag]
    };
    this.callback = callBack;
    if(this.webSocket)
      this.webSocket.send(JSON.stringify(createCMSSignatureFromBase64));
  }
  private connectionStateChanged(): void {
    if (!this.webSocket) return;
  
    let connectionStateNumber = this.webSocket.readyState;
    var rw = {
      code: "CONNECTION",
      state: NcaLayer.statesEnum[connectionStateNumber],
      getState: function() {
        return this.state;
      },
      getCode: function() {
        return this.code;
      },
    };
  }
  
  
}

export default NcaLayer;

