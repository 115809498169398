import * as React from "react";
import {Tabs} from "antd";
import {injectIntl, WrappedComponentProps} from "react-intl";
import CourseScheduleEnrollmentTest from "../CourseScheduleEnrollment/CourseScheduleEnrollmentTest";
import IndividualDevolopmentPlan from "../IndividualDevolopmentPlan/IndividualDevolopmentPlan";

const {TabPane} = Tabs;

type Props ={
  entityId: string;
}

class TabTrainingPlanTabs extends React.Component<WrappedComponentProps & Props > {

  render(): React.ReactNode {
    return   <div className="card-container"> <Tabs className={"tabSheet"} defaultActiveKey="1" type="card">
      <TabPane
        tab={this.props.intl.formatMessage({id:"trainingPlan.tabs.title.edu"})}
        key="1">
        <CourseScheduleEnrollmentTest/>
      </TabPane>
      <TabPane  tab={this.props.intl.formatMessage({id:"trainingPlan.tabs.title.indu"}) }
               key="2">
        <IndividualDevolopmentPlan entityId={this.props.entityId} />
      </TabPane>
    </Tabs>
    </div>
  }
}

export default injectIntl(TabTrainingPlanTabs);