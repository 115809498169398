import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import DataTableFormat from "../../components/DataTable/intex";
import {ProcessInstanceData} from "../../../cuba/entities/base/bproc_ProcessInstanceData";
import {ProcInstanceV} from "../../../cuba/entities/base/tsadv$ProcInstanceV";
import {RootStoreProp} from "../../store";
import {Link} from "react-router-dom";
import {AnnualSalaryRevisionManagement} from "../AnnualSalaryRevision/AnnualSalaryRevisionManagement";
import {Button as AntdButton, Table} from "antd";
import SearchFilterDropdown from "../AnnualSalaryRevision/SearchFilterDropdown";
import {action, observable} from "mobx";
import moment from "moment";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {RouteComponentProps} from "react-router";
import {ProcInstanceMyTeamV} from "../../../cuba/entities/base/tsadv$ProcInstanceMyTeamV";

type Props = { personId?: string}

@injectMainStore
@inject("rootStore")
@observer
class RequestHistoryPage extends React.Component <WrappedComponentProps & MainStoreInjected & RootStoreProp & Props> {
  fields = [
    "process",
    "requestNumber",
    "startUser",
    "startTime",
    "endTime",
    "status",

  ];
  @observable dataCollection = this.props.personId! ? collection<ProcInstanceMyTeamV>(ProcInstanceMyTeamV.NAME, {
    view: "procInstanceMyTeamV-view",
    sort: "-startTime",
    filter: {conditions: [{property: "personGroup.id", operator: "=", value: this.props.personId! }]},
  }): collection<ProcInstanceV>(ProcInstanceV.NAME, {
    view: "procInstanceV-view",
    sort: "-startTime",
    filter: {conditions: [{property: "startUser.id", operator: "=", value: this.props.rootStore!.userInfo!.id! }]},
  })

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_local"
  });
  @observable filteredData: any = null;
  @observable isLoading: boolean = false;
  @observable obj = {}
  @action handleChange = (pagination: any, filters: any, sorters: any) => {
    this.filteredData = filters
  }
  @action emptyFilters = (name?: string) => {
    if (name) {
      this.filteredData[name] = null
    } else {
      this.filteredData = null
    }
  }

  renderTable = (filteredData:any) =>{

    const userLocale = this.props.rootStore!.userInfo.locale
    return(
      <Table
        loading={this.dataCollection.status === "LOADING"}
        className={"requestHistory"}
        onChange={this.handleChange}
        dataSource={this.dataCollection.items}
      >
        <Table.Column
          dataIndex={userLocale === "en" ? "processEn" : "processRu"}
          filteredValue={(userLocale === "en" ? this.filteredData && this.filteredData.processEn : this.filteredData && this.filteredData.processRu) || null}
          onFilter={(value, record) => {
            return (userLocale === "en" ? record.processEn : record.processRu).toLowerCase().includes(value.toLowerCase())
          }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
          <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"has"}
                                text={"historyProcess"} clearFilters={clearFilters}
                                setSelectedKeys={setSelectedKeys} confirm={confirm}
                                selectedKeys={selectedKeys}/>
        } sorter={(a: any, b: any) => {
          return (userLocale === "en" ? a.processEn.localeCompare(b.processEn) : a.processRu.localeCompare(b.processRu))
        }} title={this.props.intl.formatMessage({id: "historyProcess"})}/>
        <Table.Column filteredValue={filteredData.requestNumber || null}
                      onFilter={(value, record) => {
                        return parseInt(value) === record.requestNumber
                      }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
          <SearchFilterDropdown emptyFilters={this.emptyFilters} date={false} type={"equals"}
                                text={"requestNumber"} clearFilters={clearFilters}
                                setSelectedKeys={setSelectedKeys} confirm={confirm}
                                selectedKeys={selectedKeys}/>
        } sorter={(a: any, b: any) => {
          return a.requestNumber - b.requestNumber
        }} render={((text, record) =>
          <Link
            to={"/" + (record.entityName!.match("AssignedPerformancePlan") ? "kpi": record.entityName!.match("AssignmentSalaryRequest") ? "wccf" : record.entityName!.split("tsadv_")[1]
              ? record.entityName!.split("tsadv_")[1]:record.entityName!.split("kzm$")[1]?record.entityName!.split("kzm$")[1]
              : record.entityName!.split("tsadv$")[1]) + "/" + record.entityId}>
            <span>{text}</span></Link>)} title={this.props.intl.formatMessage({id: "requestNumber"})}
                      dataIndex={"requestNumber"}/>
        <Table.Column
          title={this.props.intl.formatMessage({id: "historyStartUser"})}
          dataIndex={"startUser._instanceName"}/>
        <Table.Column filteredValue={this.filteredData && this.filteredData.startTime}
                      render={(text) => {
                        return moment(text).format("DD.MM.YYYY")
                      }}
                      onFilter={(value, record) => {
                        return moment(value).format("L") === moment(record.startTime).format("L")
                      }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
          <SearchFilterDropdown emptyFilters={this.emptyFilters} date={true} type={"equals"}
                                text={"historyStartTime"} clearFilters={clearFilters}
                                setSelectedKeys={setSelectedKeys} confirm={confirm}
                                selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
          return moment(a.startTime).valueOf() - moment(b.startTime).valueOf()
        }} dataIndex={"startTime"}
                      title={this.props.intl.formatMessage({id: "historyStartTime"})}/>
        <Table.Column render={(text) => {
          return moment(text).format("DD.MM.YYYY")
        }} filteredValue={this.filteredData && this.filteredData.endTime}
                      onFilter={(value, record) => {
                        return moment(value).format("L") === moment(record.endTime).format("L")
                      }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
          <SearchFilterDropdown emptyFilters={this.emptyFilters} date={true} type={"equals"}
                                text={"historyEndTime"} clearFilters={clearFilters}
                                setSelectedKeys={setSelectedKeys} confirm={confirm}
                                selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
          return moment(a.endTime).valueOf() - moment(b.endTime).valueOf()
        }} dataIndex={"endTime"}
                      title={this.props.intl.formatMessage({id: "historyEndTime"})}/>
        <Table.Column
          filteredValue={this.filteredData && this.filteredData.status && this.filteredData.status._instanceName}
          onFilter={(value, record) => {
            return value === record.status.id
          }} filterDropdown={({confirm, selectedKeys, setSelectedKeys, clearFilters}) =>
          <SearchFilterDropdown emptyFilters={this.emptyFilters} selectItems={this.statusesDc.items}
                                date={false} type={"equals"}
                                text={"status"} clearFilters={clearFilters}
                                setSelectedKeys={setSelectedKeys} confirm={confirm}
                                selectedKeys={selectedKeys}/>} sorter={(a: any, b: any) => {
          return a.status._instanceName.localeCompare(b.status._instanceName)
        }} title={this.props.intl.formatMessage({id: "status"})}
          dataIndex={"status._instanceName"}/>
      </Table>
    )
  }

  render() {

    let filteredData = this.filteredData || {};

    const isNull = Object.values(filteredData).every(value => {
      if (value === null || !value[0]) {
        return true;
      }
      return false;
    })



    return (
      <>
        { this.props.personId ?
            this.renderTable(filteredData) :
            <Page pageName={this.props.intl.formatMessage({id: "requestHistory"})}>
              <Section size={"large"}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  {!isNull ? <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <AntdButton type={'link'} onClick={() => this.emptyFilters()}
                                icon={"delete"}>{this.props.intl.formatMessage({id: "cubaReact.dataTable.clearAllFilters"})}</AntdButton>
                  </div> : null}
                  {this.renderTable(filteredData)}
                </div>
              </Section>
            </Page>
        }
      </>
        );
  }
}

export default injectIntl(RequestHistoryPage);
