import * as React from "react";
import {Table} from "antd";
import {observer} from "mobx-react";
import {IReactionDisposer, observable, reaction} from "mobx";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {formatAmount} from "../utils";
import {ColumnProps} from "antd/es/table";

export type TableDataParent = TableDataBase & {
  reportGroup: string
  children?: TableDataChild[]
}

export type TableDataChild = TableDataBase & {
  expenditureHeading: string
}

type TableDataBase = {
  tuitionFees: number
  tripExpenses: number
  totalInvestment: number
  employeeCount: number
}

type Props = WrappedComponentProps & {
  items?: TableDataParent[]
}

@observer
class BudgetTableComponent extends React.Component<Props> {
  @observable data: TableDataParent[]


  messages = this.props.intl.messages
  total: TableDataParent

  constructor(props: Props) {
    super(props);
    if (this.props.items) {
      this.data = this.props.items
      this.total = this.calcTotal()
      this.data.push(this.total)
    } else
      reaction(() => this.props.items, (arg, r) => {
        if (arg) {
          this.data = arg
          this.total = this.calcTotal()
          this.data.push(this.total)
          r.dispose()
        }
      })
  }

  calcTotal = () => (
    {
      tuitionFees: this.data.map(value => value.tuitionFees).reduce((p, c) => p + c, 0),
      reportGroup: 'Total',
      employeeCount: this.data.map(value => value.employeeCount).reduce((p, c) => p + c, 0),
      totalInvestment: this.data.map(value => value.totalInvestment).reduce((p, c) => p + c, 0),
      tripExpenses: this.data.map(value => value.tripExpenses).reduce((p, c) => p + c, 0),
    }
  )

  renderReportGroupColumn = (text: any, row: TableDataParent, index: number) => {
    return (
      <label style={{width: '100%'}}>{row.reportGroup}</label>
    )
  }

  columns = [
    {
      title: this.messages['reportGroup'],
      dataIndex: 'reportGroup',
      key: 'reportGroup',
      render: this.renderReportGroupColumn
    }, {
      title: this.messages['heading'],
      dataIndex: 'expenditureHeading',
      key: 'expenditureHeading',
    }, {
      title: this.messages['total'],
      children: [
        {
          title: this.messages['tutionFees'],
          dataIndex: 'tuitionFees',
          key: 'tuitionFees',
          render: (text, record, index) =>
            <label>{formatAmount(record.tuitionFees.toString())}</label>
        }, {
          title: this.messages['tripExpenses'],
          dataIndex: 'tripExpenses',
          key: 'tripExpenses',
          render: (text, record, index) =>
            <label>{formatAmount(record.tripExpenses.toString())}</label>
        }, {
          title: this.messages['totalInvestment'],
          dataIndex: 'totalInvestment',
          key: 'totalInvestment',
          render: (text, record, index) =>
            <label>{formatAmount(record.totalInvestment.toString())}</label>
        }, {
          title: this.messages['employeeCount'],
          dataIndex: 'employeeCount',
          key: 'employeeCount',
        },
      ]
    }
  ] as ColumnProps<TableDataParent>[]

  render() {
    return (
      <div style={{
        width: '100%',
        height: '100%',
      }}>
        <Table bordered dataSource={[...this.data]} columns={this.columns} rowKey={record => record.reportGroup}/>
      </div>
    )
  }
}

export default injectIntl(BudgetTableComponent)
