import * as React from "react";
import { observer } from "mobx-react";
import AdaptationPlanEdit from "./AdaptationPlanEdit";
import { RouteComponentProps } from "react-router-dom";
import Page from "../../../hoc/PageContentHoc";
import { AdaptationPlan } from "../../../../cuba/entities/base/tsadv_AdaptationPlan";
import { FormattedMessage } from "react-intl";

type Props = RouteComponentProps<{ entityId: string }>

@observer
export class AdaptationPlanEditPage extends React.Component<Props> {
  static PATH = "/adaptationPlan";
  static NEW_SUBPATH = "new";
  
  render() {
    return (
      <Page pageName={<FormattedMessage id={AdaptationPlan.NAME} defaultMessage="Adaptation Plan"/>}>
        <AdaptationPlanEdit showCompetentionTable entityId={this.props.match.params.entityId} />
      </Page>
    )
  }
}