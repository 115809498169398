import * as React from "react";
import { inject, observer } from "mobx-react";
import RecognitionComponent from "./RecognitionComponent";
import { RootStoreProp } from "../../store";

@inject("rootStore")
@observer
class RecognitionStructure extends React.Component<RootStoreProp> {

    render() {
        return (
            <RecognitionComponent
                personGroupId={this.props.rootStore!.userInfo!.personGroupId!}
            />
        )
    }

    componentDidMount() {
        this.props.rootStore!.assistantTeamInfo.active = false;
    }
}

const recognitionStructure = RecognitionStructure;
export default recognitionStructure;