import * as React from "react";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import {
  injectMainStore,
  MainStoreInjected,
} from "@cuba-platform/react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { RootStoreProp } from "../../store";
import { withRouter } from "react-router";
import Page from "../../hoc/PageContentHoc";
import { RouteComponentProps } from "react-router-dom";
import Section from "../../hoc/Section";
import { Link } from "react-router-dom";
import {
  Table
} from "antd";
import { UcoSignDocument } from "../../../cuba/entities/base/ucosign$Document";
import { DocumentSignManagement } from "./DocumentSignManagement";
import { UcoSignSignature } from "../../../cuba/entities/base/ucosign$Signature";
import {restServices, ucoSignRegistrationWithName} from "../../../cuba/services";
import moment from "moment";
import {debounce} from "lodash";
import SearchInput from "../../components/SearchInput";

type Prop = { type: "documentsSign" };
type State = {
  searchField: string
  loading: boolean
  abortController: AbortController
}

@injectMainStore
@inject("rootStore")
@observer
class DocumentSignActivityList extends React.Component<
  Prop &
  WrappedComponentProps &
  RootStoreProp &
  RouteComponentProps &
  MainStoreInjected
  ,
  State
> {
  @observable
  dataCollection: ucoSignRegistrationWithName[];

  @observable
  documentsList: Array<UcoSignDocument>;

  @observable
  signature: UcoSignSignature[];

  lang = this.props.rootStore!.userInfo.locale!;
  columns = [
    {
      title: this.props.intl.formatMessage({
        id: "documentsForSignature.documentType"
      }),
      sorter: (a:any, b:any) => {
        if(this.lang == "en") {
          return a.documentType.nameLang3.localeCompare(b.documentType.nameLang3)
        }
        else {
          return a.documentType.nameLang1.localeCompare(b.documentType.nameLang1)
        }
      },
      dataIndex: "ucoSignRegistration.documentType",
      key: "ucoSignRegistration.documentType",
      render: (text: any, record: ucoSignRegistrationWithName) => {
        if (!record.ucoSignRegistration || !record.ucoSignRegistration.documentType) return ""
        if(this.lang == "en") return record.ucoSignRegistration.documentType.nameLang3
        return record.ucoSignRegistration.documentType.nameLang1
      }
    },
    {
      title: this.props.intl.formatMessage({ id: "description" }),
      sorter: (a:any, b:any) => {
        return a.description.localeCompare(b.description)},
      dataIndex: "ucoSignRegistration.description",
      key: "description",
      render: (text: any, records: any) => {
        const record = records.ucoSignRegistration
        return (
          <div>
            <span>{record.description + ' ' + (record.metaData3?record.metaData3:record.metaData1)}</span>
            <p>{records.fullName}</p>
          </div>
        )
      }
    },
    {
      title: this.props.intl.formatMessage({ id: "date" }),
      sorter:(a:any, b:any) => {
        const aDate = moment(a.createTs!)
        const bDate = moment(b.createTs!)
        return bDate.diff(aDate)
      },
      dataIndex: "ucoSignRegistration.createTs",
      key: "createTs",
      render: (text: any, records: any) => {
        const record = records.ucoSignRegistration
        const hour = moment(record.createTs).format("HH:mm")
        const date = moment(record.createTs).format("DD.MM.YYYY")
        return <>{date+" "+ hour}</>
      }
    },
    {
      title: this.props.intl.formatMessage({ id: "action" }),
      dataIndex: "ucoSignRegistration.egovSignOrderId",
      key: "egovSignOrderId",
      sorter: (a: any, b: any) => {
        const aDocument = this.filterById(a.ucoSignRegistration)
        const bDocument = this.filterById(b.ucoSignRegistration)
        if (aDocument && !bDocument) {
          return -1;
        } else if (!aDocument && bDocument) {
          return 1;
        } else {
          return 0;
        }
      },
      render: (text: any, records: any) => {
        const record = records.ucoSignRegistration
        let filteredDocuments: boolean = this.filterById(record)
        if (filteredDocuments)
          return (
            <Link
              style={{ color: "green" }}
              to={DocumentSignManagement.PATH + "/" + record.id}
            >
              {this.lang === "en" ? "Sign" : "Подписать"}
            </Link>
          );
        else
          return (
            <Link to={DocumentSignManagement.PATH + "/" + record.id}>
              {this.lang === "en" ? "Show" : "Посмотреть"}
            </Link>
          );
      }
    }
  ];
  state = {
    searchField: '',
    loading: false,
    abortController: new AbortController()
  };

  filterById = (record: any) => {
    let filteredDocuments: boolean = false;
    record.ucosignDocuments.filter((value: UcoSignDocument) => {
      value.ucosignSignatures!.filter(
        val => {
          if(
            val.signUser!.id == this.props.rootStore!.userInfo!.id! &&
            val.signatureData == null) {
            filteredDocuments = true
          }
        }
      );
    });
    return filteredDocuments
  }

  render() {
    const type = this.props.type;
    const pageName = this.props.intl.formatMessage({ id: type });

    const getDocumentsSignal = async (params: { userId: string, filter?: string, signal?: AbortSignal }): Promise<ucoSignRegistrationWithName[]> => {
      const {
        filter = '',
        signal = new AbortController().signal,
        userId
      } = params
      try {
        return await restServices.digitalSignatureSignService.getAllDocumentsWithSignal({
          filter,
          userId,
          servicePath: 'hruco_DigitalSignatureHrucoService/getAllDocuments',
          signal
        })
      } catch (e) {
        return []
      }
    };
    const search = async (searchValue: string) => {
      const {abortController} = this.state
      this.setState({searchField: searchValue, loading: true})
      abortController.abort()
      this.setState({abortController: new AbortController()})
      try {
        const signal = this.state.abortController.signal
        const res = await getDocumentsSignal({filter: searchValue, signal, userId: this.props.rootStore!.userInfo.id!})
        this.dataCollection = res
      } finally {
        this.setState({
          loading: false
        })
      }
    }
    const debouncedSearch = debounce(search, 800);

    return (
      <Page pageName={pageName}>
        <Section size="large">
          <div style={{marginBottom: "10px"}}>
            <SearchInput placeholder={this.props.intl.formatMessage({id: "placeholder.employee"})} onSearch={debouncedSearch} />
          </div>
          <Table
            dataSource={this.dataCollection ? this.dataCollection : []}
            key={"documentType"}
            rowKey={"documentType"}
            loading={
              false
            }
            pagination={false}
            columns={this.columns}
          />
        </Section>
      </Page>
    );
  }
  componentDidMount() {
    restServices.digitalSignatureSignService.
    getAllDocuments({userId: this.props.rootStore!.userInfo.id!, filter: ''}).then(value=>{
      this.dataCollection = value
    })
  }
}
export default withRouter(injectIntl(DocumentSignActivityList));
