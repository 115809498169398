import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {Checkbox, Col, Form, Modal, Row} from "antd";
import {action, computed, observable, runInAction} from "mobx";
import {inject, observer} from "mobx-react";
import {restServices} from "../../../../cuba/services";
import {PersonGroupExt} from "../../../../cuba/entities/base/base$PersonGroupExt";
import TextArea from "antd/es/input/TextArea";
import {DicQuality} from "../../../../cuba/entities/base/tsadv$DicQuality";
import {getCubaREST, injectMainStore} from "@cuba-platform/react";
import moment from "moment/moment";
import {JSON_DATE_TIME_FORMAT} from "../../../util/Date/Date";
import {DicRecognitionType} from "../../../../cuba/entities/base/tsadv$DicRecognitionType";
import {Recognition} from "../../../../cuba/entities/base/tsadv$Recognition";
import {RootStoreProp} from "../../../store";
import {FormComponentProps} from "antd/es/form";
import "../style.less";
import {RecognitionQuality} from "../../../../cuba/entities/base/tsadv$RecognitionQuality";
import Notification from "../../../util/Notification/Notification";
import {getPersonByPersonGroup} from "../../../util/personUtils";
import QuantitySelector from "./QuantitySelector";
import {GetFieldDecoratorOptions} from "antd/es/form/Form";
import RecognitionTypeSelector from "./RecognitionTypeSelector";
import PersonFinder from "./PersonFinder";

type CreateRecognitionProps = {
  visible: boolean,
  onClose: () => void,
  onCreated: () => void,
  personGroup?: string
}

@inject("rootStore")
@injectMainStore
@observer
class CreateRecognition extends React.Component<RootStoreProp & WrappedComponentProps & CreateRecognitionProps & FormComponentProps> {

  message = this.props.intl.messages;

  readonly COMMENT_VALID_PROPS: GetFieldDecoratorOptions = {
    validateTrigger: 'onBlur',
    rules: [{
      required: true,
      message: this.message["recognition.create.commentLen"],
      min: 100
    }]
  }

  @observable personSearchName: string;
  @observable isLoading: boolean = false;
  @observable notifyManager: boolean = false;
  @observable personSearchResult: PersonGroupExt[] = [];
  @observable recognitionTypes: DicRecognitionType[] = [];
  @observable selectedPerson: PersonGroupExt | null = null;
  @observable comment: string = "";

  @observable selectedDepartment: string | null = null;
  @observable selectedPosition: string | null = null;

  @observable isSending: boolean = false;
  @observable title: string = this.message["recognition.create.description"] as string;

  selectedQuality: DicQuality[] = [];
  selectedRecognitionType: DicRecognitionType| null = null;


  componentDidMount() {
    this.transformIfPersonGroupPassed();
  }

  transformIfPersonGroupPassed = async () => {
    const personGroup = this.props.personGroup;

    if (!personGroup) {
      return;
    }

    const now = moment().format("YYYY-MM-DD");
    const personGroupEntity = await getPersonByPersonGroup(personGroup, now, "_local");

    this.title = personGroupEntity["_instanceName"];
    this.selectedPerson = {id: personGroup} as PersonGroupExt;
  }


  renderRecognitionType = () => {
    const {getFieldDecorator} = this.props.form;

    return <>
      <Row>
        <Col span={12}>
          <FormattedMessage id="recognition.create.selectRecognitionType"/>
        </Col>
        <Col span={12}>
          <FormattedMessage id="recognition.create.coast"/>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item>
            {getFieldDecorator('recognitionType', {rules: [{required: true, message: this.message["recognition.create.selectRecognitionType"]}]})
            (<RecognitionTypeSelector onChange={(selected) => this.selectedRecognitionType = selected}/>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          {this.selectedRecognitionType && this.selectedRecognitionType.coins}
        </Col>
      </Row>
    </>
  }

  @action validateAndSave = () => {
    this.props.form.validateFields(async err => {
      if (err) {
        return;
      }

      this.isSending = true;

      await this.save()
        .finally(() => this.isSending = false);
    });
  }

  async save() {
    const author = {
      id: this.props.rootStore!.userInfo!.personGroupId
    }

    const recognition: Recognition = {
      recognitionType: this.selectedRecognitionType,
      notifyManager: this.notifyManager,
      comment: this.comment,
      coins: this.selectedRecognitionType!.coins,
      author,
      receiver: this.selectedPerson,
      commentRu: "commentRu",
      commentEn: "commentEn",
      recognitionDate: moment().format(JSON_DATE_TIME_FORMAT),
    }

    const newRecognition = await getCubaREST()!.commitEntity(Recognition.NAME, recognition);

    const createRecognitionQualityPromises = this.selectedQuality.map(async (quantity) => {
      await getCubaREST()!.commitEntity(RecognitionQuality.NAME, {
        recognition: newRecognition,
        quality: quantity
      } as RecognitionQuality)
    })

    await Promise.all(createRecognitionQualityPromises);

    this.props.onCreated();
    this.props.onClose();
  }

  @action onSearch = async (value: string) => {
    if (value.length < 3) return;

    try {
      this.isLoading = true;
      this.personSearchResult = await restServices.recognitionService.findPersonGroupByName(value);
    } finally {
      this.isLoading = false;
    }
  }

  @computed get commentTitle(): string {
    return this.comment.length === 0
      ? this.message["recognition.create.comment.min"] as string
      : this.comment.length + " " + this.message["recognition.create.comment.current"] as string;
  }

  @action onChangePerson = (value: string) => {
    this.selectedPerson = {id: value} as PersonGroupExt;
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const personGroup = this.props.personGroup;

    return <>
      <Modal centered
             visible={this.props.visible}
             title={this.title}
             width={800}
             onOk={this.validateAndSave}
             okButtonProps={{loading: this.isSending}}
             onCancel={this.props.onClose}
      >
        <Form>
          {!personGroup &&
            <>
              <Form.Item label={this.message["recognition.create.selectRecipient"] as string}>
                {getFieldDecorator('receiver', {rules: [{required: true, message: ''}]})
                (<PersonFinder onChange={this.onChangePerson}/>)}
              </Form.Item>
            </>
          }

          {this.renderRecognitionType()}

          <Row type={"flex"} justify={"space-between"}>
            <div>
              <FormattedMessage id={"recognition.create.yourComment"}/>
            </div>
            <div>{this.commentTitle}</div>
          </Row>
          <Form.Item>
            {getFieldDecorator('comment', this.COMMENT_VALID_PROPS)
            (<TextArea onChange={e => this.comment = e.target.value as string} rows={10}/>)}
          </Form.Item>

          <Form.Item label={this.message["recognition.create.selectQuality"]}>
            <QuantitySelector onChange={(selectedQuality) => runInAction(() => this.selectedQuality = selectedQuality)}/>
          </Form.Item>

          <Checkbox checked={this.notifyManager}
                    onChange={(e) => runInAction(() => this.notifyManager = e.target.checked)}
          >
            <FormattedMessage id="recognition.create.notifyManager"/>
          </Checkbox>
        </Form>
      </Modal>
    </>
  }
}


export default injectIntl(Form.create<RootStoreProp & WrappedComponentProps & CreateRecognitionProps & FormComponentProps>()(CreateRecognition));