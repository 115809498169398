import React from 'react';
import {Input, Tree} from 'antd';
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import LoadingPage from "../LoadingPage";
import {restServices} from "../../../cuba/services";
import {PositionHierarchy} from "../../../cuba/entities/base/tsadv_PositionHierarchy";
import {RootStoreProp} from "../../store";
import Page from "../../hoc/PageContentHoc";
import {injectIntl, WrappedComponentProps} from "react-intl";

interface DataNode {
  title: string;
  key: string;
  isLeaf?: boolean;
  children?: DataNode[];
}


const {TreeNode} = Tree;

export type PositionHierarchyTreeProps = {
  changeSelectedPosition: (positionId?: string) => void;
  getDefaultId?:(defaultId?: string) => void|undefined;
}
const { Search } = Input;

@inject("rootStore")
@observer
class PositionHierarchyTreeNew extends React.Component<PositionHierarchyTreeProps & RootStoreProp & WrappedComponentProps> {

  @observable treeData: DataNode[]

  @observable dataList: DataNode[] = []

  @observable searchValue:string = ""

  @observable expandedKeys:any =[]

  @observable gData:any;

  @observable defaultExpandedKey:any;

  @observable autoExpandParent:boolean = true

  @observable isUpper:boolean = false

  @observable onChanged:boolean = false;

  componentDidMount(): void {
    new Promise(resolve => {
      restServices.positionStructureService.getStartData({})
        .then(value => value.map(value1 => this.parseToDataNode(value1)))
        .then(value2 => {
          this.treeData = [...value2]
          if(this.treeData.length>0) {
            this.props.getDefaultId && this.props.getDefaultId(this.treeData[0]!.key!)
            this.defaultExpandedKey = this.treeData[0]!.key
          }
          this.treeData.map(item=>{
              this.updateDataNode([...value2], item.key)
          })
          resolve()
        });
    })

  }

  getParentKey = (key:any, tree:DataNode[]):any => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some( (item:any) => item.key === key)) {
          parentKey = node.key;
        } else if (this.getParentKey(key, node.children)) {
          parentKey = this.getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };
  generateList = (data:any) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      if(node.title && node.key) {
        const {key} = node;
        const {title} = node;
        this.dataList.push({key, title: title.toLowerCase()});
        if (node.children) {
          this.generateList(node.children);

        }
      }
    }
  };
  isUpperCase = (str:string) => {
    return str[0] == str.toUpperCase() && str[0] != str[0].toLowerCase();
  }
  ucFirst = (str:string) =>{
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  }
  loop = (data:any):any => {
    if(data.length>0 && data[0].title) {
      return data.map((item: any) => {
        const index = item!.title!.toLowerCase()!.indexOf(this.searchValue)
        const beforeStr = item.title.substr(0, index);
        const afterStr = item.title.substr(index + this.searchValue!.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span style={{color: "orangered"}}>
                {(beforeStr.length>0)? this.searchValue : this.ucFirst(this.searchValue)}
              </span>
              {afterStr}
            </span>
          ) : (
            <span>{item.title}</span>
          );
        if (item.children) {
          return {title, key: item.key, children: this.loop(item.children)};
        }
        return {
          title,
          key: item.key,
        };

      })
    }
  }


  onExpand = (expandedKeys:any) => {
    this.expandedKeys = expandedKeys;
    this.autoExpandParent = false;
  };

  onChange = (e:any)=> {
    this.onChanged = true
    this.autoExpandParent = true
    this.searchValue = e.target.value;
    this.expandedKeys = this.dataList
      .map((item) => {
        if (item.title.indexOf(this.searchValue ) > -1) {
          return this.getParentKey(item.key, this.treeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
  };
  renderTreeNodes = (data: DataNode[]): any => {
    return data.map(item => {
      return <TreeNode title={item.title}
                       key={item.key}
                       dataRef={item}
                       isLeaf={item.isLeaf}>
        {item.children ? this.renderTreeNodes(item.children) : null}
      </TreeNode>
    });
  }
  render() {
    if (!this.treeData || this.treeData.length < 1) {
      return <LoadingPage/>
    }else if(this.treeData.length < 1){
      return  <Page pageName={this.props.intl.formatMessage({id: 'page.noData'})}/>
    }

    const expandKey:string[] = this.treeData.map(value=>{
      return value.key
    })
    return <div>
      <Search style={{padding: '10px 5px 10px 10px'}}  placeholder="Search" onChange={this.onChange} />
      { this.onChanged?
        <Tree
          onSelect={selectedKeys => this.selectPosition(selectedKeys)}
          expandedKeys={this.expandedKeys}
          onExpand={(expandedKeys)=>{this.onExpand(expandedKeys)}}
          autoExpandParent={this.autoExpandParent}
        >
          {this.renderTreeNodes(this.loop(this.treeData))}
        </Tree>:
        <Tree
          defaultExpandedKeys={expandKey}
          onSelect={selectedKeys => this.selectPosition(selectedKeys)}
          onExpand={(expandedKeys)=>{this.onExpand(expandedKeys)}}

        >
          {this.renderTreeNodes(this.loop(this.treeData))}
        </Tree>

      }

    </div>



  }
  ;

  // onExpand(expandedKeys: string[]) {
  //   return new Promise<void>(resolve => {
  //     if (expandedKeys.length > 0) {
  //       const lastKey = expandedKeys[expandedKeys.length - 1];
  //       if (!this.isLoaded(lastKey, this.treeData)) {
  //         this.updateDataNode(this.treeData, lastKey)
  //       }
  //     }
  //     resolve()
  //   })
  // }

  isLoaded(lastKey: string, treeData?: DataNode[]): boolean {
    if (treeData && treeData.length > 0) {
      for (let i = 0; i < treeData.length; i++) {
        const children = treeData[i].children;
        if (treeData[i].key === lastKey) {
          if (children) {
            let loaded = false
            for (let j = 0; j < children.length; j++) {
              if (!children[j].isLeaf && children[j].children) {
                loaded = true
              }
            }
            if (loaded) {
              return true
            }
          }
        } else {
          if (this.isLoaded(lastKey, children)) {
            return true
          }
        }
      }
    }
    return false
  }

  updateDataNode(treeData: DataNode[], lastKey: string): void {
    restServices.positionStructureService.getChildren({parentId: lastKey})
      .then(value => value.map(value1 => {
        return this.parseToDataNode(value1)
      }))
      .then(value2 => {
        this.generateList([...value2])
        this.treeData = this.insertData(this.treeData, lastKey, value2)
      })
  }

  insertData(treeData: DataNode[], lastKey: string, children: DataNode[]): DataNode[] {
    return treeData.map(value => {
      if (lastKey === value.key) {
        return {
          ...value, children: children
        }
      } else {
        return {
          ...value, children: this.insertData(value.children ? value.children : [], lastKey, children)
        }
      }
    });
  }

  parseToDataNode(positionHierarchy: PositionHierarchy): DataNode {
    return {
      key: positionHierarchy.id,
      title: positionHierarchy.positionName,
      isLeaf: !positionHierarchy.haveChildren,
      children: positionHierarchy.children ? positionHierarchy.children.map(value => this.parseToDataNode(value)) : []
    } as DataNode
  }

  selectPosition(selectedKeys: string[]) {
    if (selectedKeys && selectedKeys.length > 0) {
      const lastSelected = selectedKeys[selectedKeys.length - 1];
      if (lastSelected) {
        this.props.changeSelectedPosition(lastSelected)
      } else {
        this.props.changeSelectedPosition('')
      }
    } else {
      this.props.changeSelectedPosition('')
    }
  }
}

export default injectIntl(PositionHierarchyTreeNew);