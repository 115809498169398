import * as React from "react";
import {Card, Col, Form, Icon, Input, Button as Btn, Row, Upload} from "antd";
import {inject, observer} from "mobx-react";
import {observable, runInAction} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";
import {collection, getCubaREST, injectMainStore, instance, withLocalizedForm} from "@cuba-platform/react";
import "../../../app/App.css";
import {AddressRequest} from "../../../cuba/entities/base/tsadv$AddressRequest";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import moment from "moment";
import {downloadFile, goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import {SuccessionPlanningRequestManagement} from "./SuccessionPlanningRequestManagement";
import {PersonExperience} from "../../../cuba/entities/base/tsadv$PersonExperience";
import {ReadonlyField} from "../../components/ReadonlyField";
import {SuccessionPlanningRequest} from "../../../cuba/entities/base/tsadv_SuccessionPlanningRequest";
import TextArea from "antd/es/input/TextArea";
import {PositionGroupExt} from "../../../cuba/entities/base/base$PositionGroupExt";
import {SerializedEntity} from "@cuba-platform/rest";
import {Msg} from "@cuba-platform/react";
import {FileDescriptor} from "../../../cuba/entities/base/sys$FileDescriptor";

type EditorProps = {
  entityId: string;
  addressId?: string;
  isKeyPosition?:boolean;
  positionGroupId?:string;
}

@inject("rootStore")
@injectMainStore
@observer
class SuccessionPlanningRequestEditComponent extends AbstractBprocEdit<AddressRequest, EditorProps> {
  processDefinitionKey = "successionPlanningRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<SuccessionPlanningRequest>(SuccessionPlanningRequest.NAME, {
    view: "successionPlanningRequest-edit",
    loadImmediately: false
  });


  statusDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  @observable status: undefined | DicRequestStatus | null;

  @observable
  positionInstance : SerializedEntity<PositionGroupExt>;

  @observable
  editAddress: PersonExperience;

  instanceEditAddress = instance<PersonExperience>(PersonExperience.NAME, {
    view: "portal.my-profile",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  approverHrRoleCode: string;

  @observable fileId:null | undefined | any[];
  @observable file:undefined | null | FileDescriptor[];

  isUpdateBeforeOutcome = true;

  fields = [

    "requestNumber",

    "requestDate",

    "status",

    'comment',

    "personGroup",

    "positionGroup",

    "file",

    "action",

    "description"

  ];
  @observable positionGroupId:string;
  @observable action:string;
  @observable description:string;
  @observable requestNumber:string;

  getUpdateEntityData(): SuccessionPlanningRequest {

    const fields: SuccessionPlanningRequest = this.props.form.getFieldsValue(this.fields)

      const data: SuccessionPlanningRequest = {
        ...fields,
        personGroup: {id: this.personGroupId},
        positionGroup: {id: this.positionGroupId},
        action: this.action,
        description: this.description,
        file:this.fileId&&this.fileId.length>0?[...this.fileId]:null
      }
    if(this.props.entityId !=="new") {
      data.status = this.status
    }
    return data
  }


  render() {

    if (!this.mainStore) return <LoadingPage/>
    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>
    const entityName = this.dataInstance.entityName;
    const formStyle = { marginBottom: "12px", padding: "0 30px" }
    const personGroup = this.person&&this.person.group&&this.person.group['_instanceName']
    const action = this.props.entityId==="new"?this.props.isKeyPosition?this.props.intl.formatMessage({id:"successionPlanning.action.exclude"}):this.props.intl.formatMessage({id:"successionPlanning.action.include"}):
                        this.action==="EXCLUDE"?this.props.intl.formatMessage({id:"successionPlanning.action.exclude"}):this.props.intl.formatMessage({id:"successionPlanning.action.include"})
    const positionGroup = this.props.entityId==="new"?this.positionInstance&&this.positionInstance._instanceName:this.dataInstance.item&&this.dataInstance.item.positionGroup&&this.dataInstance.item.positionGroup['_instanceName']
    const organizationGroup = this.props.entityId==="new"?this.positionInstance&&this.positionInstance.position&&this.positionInstance.position.organizationGroupExt&&this.positionInstance.position.organizationGroupExt['_instanceName']:
                              this.dataInstance.item&&this.dataInstance.item.positionGroup&&this.dataInstance.item.positionGroup.organizationGroup&&this.dataInstance.item.positionGroup.organizationGroup['_instanceName']
    const file = this.props.entityId==="new"?this.positionInstance&&this.positionInstance.jobDescription&&this.positionInstance.jobDescription.file:
                 this.dataInstance.item&&this.dataInstance.item.positionGroup&&this.dataInstance.item.positionGroup.jobDescription&&this.dataInstance.item.positionGroup.jobDescription.file&&this.dataInstance.item.positionGroup.jobDescription.file
    const fileId = file&&file.id
    const fileExt =file&&file.extension
    const fileName =file&&file.name
    const isNotDraft = this.isNotDraft();

    const HandleDownloadFile = () => {
      downloadFile(fileId, fileName?fileName:"No name",fileExt?fileExt:"No extension", "Файл не найден");
    }
    return (
      <Page pageName={<FormattedMessage id={this.props.entityId==="new"?this.props.isKeyPosition?"successionPlanning.pageNameExclude":"successionPlanning.pageNameInclude":this.action==="EXCLUDE"?"successionPlanning.pageNameExclude":"successionPlanning.pageNameInclude"}/>}>
        <Section size={"large"}>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <div>
              <div className={"ant-row ant-form-item"}
                   style={formStyle}>
                <fieldset style={{
                  border: "1px solid #d8d8d8",
                  padding: "5px",
                  borderRadius: "4px",
                  width: "100%",
                  height: "auto",
                }}>
                  <legend style={{
                    width: "auto",
                    padding:"2px",
                    borderBottom:"none",
                    color:"inherit",
                    fontSize:"1.5em"
                  }}><FormattedMessage id={"positionInfo"}/>
                  </legend>
                  <div>
                    <Row>
                      <Col md={22} lg={10} offset={1}>
                        <ReadonlyField
                          disabled
                          style={{width:"100%"}}
                          entityName={entityName}
                          propertyName="requestNumber"
                          formItemOpts={{style:{marginBottom:5}}}
                          form={this.props.form}
                          />
                      </Col>
                      {this.props.entityId==="new"}
                      <Col md={22} offset={1} lg={10}>
                        <Form.Item style={{marginBottom:5}}
                          label={<FormattedMessage id={"successionPlanning.action"}/>} >
                          <Input disabled value={action}/>
                        </Form.Item>
                      </Col>

                    </Row>
                    <Row>
                      <Col md={22} lg={10} offset={1}>
                        <ReadonlyField
                          disabled
                          style={{width:"100%"}}
                          form={this.props.form}
                          entityName={entityName}
                          formItemOpts={{style:{marginBottom:5}}}
                          propertyName={"requestDate"}/>
                      </Col>
                      <Col md={22} offset={1} lg={10}>
                        <Form.Item style={{marginBottom:5}}
                                   label={<Msg entityName={PositionGroupExt.NAME} propertyName={"organizationGroup"}/>}>
                          <Input disabled value={organizationGroup}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={22} lg={10} offset={1}>
                        {this.props.entityId === "new" ?
                          <ReadonlyField
                            disabled
                            style={{width: "100%"}}
                            form={this.props.form}
                            entityName={entityName}
                            propertyName={"status"}
                            formItemOpts={{style:{marginBottom:5}}}
                            optionsContainer={this.statusDc}/>
                          :
                          <Form.Item style={{marginBottom:5}} label={<Msg entityName={entityName} propertyName={"status"} />}>
                          <Input disabled value={this.status?this.status['_instanceName']:""}/>
                          </Form.Item>
                        }
                      </Col>
                      <Col md={22} offset={1} lg={10}>
                        <Form.Item style={{marginBottom:5}}
                          label={<Msg entityName={entityName} propertyName={"positionGroup"}/>}>
                          <Input disabled value={positionGroup}/>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={22} lg={10} offset={1}>
                        <Form.Item style={{marginBottom:5}}
                          label={<Msg entityName={entityName} propertyName={"personGroup"}/>}>
                          <Input disabled value={personGroup}/>
                        </Form.Item>
                      </Col>
                      <Col md={22} offset={1} lg={10}>
                        <Form.Item style={{marginBottom:5}}
                                   label={<Msg entityName={PositionGroupExt.NAME} propertyName={"jobDescription"}/>}>
                          {file ?
                            <a style={{textDecoration:"underline"}} onClick={HandleDownloadFile}>{fileName}</a>
                            :
                            <FormattedMessage id={"diNotFound"}/>
                          }
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className={"ant-row ant-form-item"}
                 style={formStyle}>
              <fieldset style={{
                border: "1px solid #d8d8d8",
                padding: "5px",
                borderRadius: "4px",
                width: "100%",
                height: "auto",
              }}>
                <legend style={{
                  width: "auto",
                  padding:"2px",
                  borderBottom:"none",
                  color:"inherit",
                  fontSize:"1.5em"
                }}><FormattedMessage id={"asr.Justification"}/>
                </legend>
                <div>
                  <Row>
                    <Col offset={1}>
                      <Form.Item style={{marginBottom:5}}>
                      <div style={{display:"block"}}>
                        <span style={{marginRight:10}}><FormattedMessage id={"justificationFile"}/>:</span>
                        {this.props.entityId==="new"?
                          <Upload
                            style={{display:"inline-block"}} action={getCubaREST()!.getFileUploadURL()}
                            headers={{'Authorization': 'Bearer ' + getCubaREST()!.restApiToken}}
                            multiple
                            onChange={({file,fileList})=>runInAction(()=> {
                                if(fileList.length>0){
                                  this.fileId = fileList.map(f=>f.response&&f.response.id)
                                }})}>
                            <Btn type={"primary"}>
                              <Icon type={this.fileId&&this.fileId.length>0?"plus":"upload"} style={{width:30}}/>
                            </Btn>
                          </Upload>
                          :
                          this.file&&this.file.map(f=><a style={{textDecoration:"underline",display:"block"}} onClick={()=>downloadFile(f!.id,f.name!,f.extension!,"Cannot find file")}>{f&&f.name}</a>)
                        }

                      </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                        <Form.Item label={<FormattedMessage id={"comment"}/>} style={{marginBottom:"5px", width:"92%", marginLeft:"auto",marginRight:"auto"}}>
                          {this.props.form.getFieldDecorator("description",{
                            rules:[{
                              required:true,
                              message:this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.description']})
                            }]
                          })
                          (<TextArea disabled={isNotDraft} onChange={(e)=> runInAction(()=>this.description = e.target.value)}  value={this.description} style={{width:"100%", resize:"none"}} autoSize={{ minRows: 10, maxRows: 20}} />)}
                  </Form.Item>
                    </Row>
                </div>
              </fieldset>
            </div>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
    );
  }
  componentDidMount() {
    super.componentDidMount();
    if(this.props.entityId==="new"){
      getCubaREST()!.loadEntity<PositionGroupExt>(PositionGroupExt.NAME,this.props.positionGroupId,{
        view:"positionGroup.list"
      }).then(value => {runInAction(()=>{
        this.positionInstance = value
      })})
    }

  }


  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: SuccessionPlanningRequest) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    this.status = item && item.status

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();

    this.positionGroupId = item&&item.positionGroup? item.positionGroup.id: this.props.positionGroupId

    this.action = item&& item.action? item.action:(this.props.isKeyPosition?"EXCLUDE":"INCLUDE")

    this.description = item&& item.description? item.description: ""

    this.file = item && item!.file

    this.fileId = item.file && item.file.map(f=>f.id)



    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const addressId = this.props.entityId
    if (addressId)
      getCubaREST()!.loadEntity(PersonExperience.NAME, addressId, {view: 'personExperience.full'})
        .then(value => this.editAddress = value as PersonExperience)
        .then(value => {
          this.instanceEditAddress.setItem(value);
          const properties = [

            "startMonth",

            "endMonth",

            "job",

            "location",

            "company"

          ];
          if (this.props.entityId === SuccessionPlanningRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditAddress.getFieldValues(properties));
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(SuccessionPlanningRequestEditComponent))
);
