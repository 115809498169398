import { AbstractParentEntity } from "./AbstractParentEntity";
import { AssessmentTemplate } from "./tsadv$AssessmentTemplate";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { DicAssessmentStatus } from "./tsadv$DicAssessmentStatus";
import { AssessmentParticipant } from "./tsadv$AssessmentParticipant";
import { AssessmentCompetence } from "./tsadv$AssessmentCompetence";
import { CompetenceGroup } from "./tsadv$CompetenceGroup";
import { ScaleLevel } from "./tsadv$ScaleLevel";
export class Assessment extends AbstractParentEntity {
  static NAME = "tsadv$Assessment";
  template?: AssessmentTemplate | null;
  startDate?: any | null;
  endDate?: any | null;
  employeePersonGroup?: PersonGroupExt | null;
  status?: DicAssessmentStatus | null;
  goalRating?: any | null;
  competenceRating?: any | null;
  overalRating?: any | null;
  performance?: any | null;
  potential?: any | null;
  riskOfLoss?: any | null;
  impactOfLoss?: any | null;
  assessmentName?: string | null;
  competenceSource?: any | null;
  participant?: AssessmentParticipant | null;
  assessmentCompetence?: AssessmentCompetence | null;
  competenceGroup?: CompetenceGroup | null;
  requiredScaleLevel?: ScaleLevel | null;
  scaleLevel?: ScaleLevel | null;
  resultPercent?: any | null;
  result?: any | null;
  requiredDevelopmet?: boolean | null;
  comment?: string | null;
  selfAssessment?: number | null;
  otherAssessment?: number | null;
  assessmentDifference?: number | null;
  formattedOveralRating?: string | null;
  formattedCompetenceRating?: string | null;
  formattedGoalRating?: string | null;
}
export type AssessmentViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "assessment-for-apab"
  | "assessment.create"
  | "assessment.matrix"
  | "assessment.scrum.competence"
  | "assessment.view"
  | "assessmentForCard";
export type AssessmentView<V extends AssessmentViewName> = V extends "_base"
  ? Pick<
      Assessment,
      | "id"
      | "startDate"
      | "endDate"
      | "goalRating"
      | "competenceRating"
      | "overalRating"
      | "performance"
      | "potential"
      | "riskOfLoss"
      | "impactOfLoss"
      | "assessmentName"
      | "competenceSource"
      | "resultPercent"
      | "result"
      | "requiredDevelopmet"
      | "comment"
      | "selfAssessment"
      | "otherAssessment"
      | "formattedOveralRating"
      | "formattedCompetenceRating"
      | "formattedGoalRating"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      Assessment,
      | "id"
      | "startDate"
      | "endDate"
      | "goalRating"
      | "competenceRating"
      | "overalRating"
      | "performance"
      | "potential"
      | "riskOfLoss"
      | "impactOfLoss"
      | "assessmentName"
      | "competenceSource"
      | "resultPercent"
      | "result"
      | "requiredDevelopmet"
      | "comment"
      | "selfAssessment"
      | "otherAssessment"
      | "formattedOveralRating"
      | "formattedCompetenceRating"
      | "formattedGoalRating"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "assessment-for-apab"
  ? Pick<
      Assessment,
      | "id"
      | "requiredScaleLevel"
      | "comment"
      | "employeePersonGroup"
      | "participant"
      | "assessmentCompetence"
    >
  : V extends "assessment.create"
  ? Pick<
      Assessment,
      | "id"
      | "assessmentName"
      | "template"
      | "startDate"
      | "endDate"
      | "status"
      | "employeePersonGroup"
    >
  : V extends "assessment.matrix"
  ? Pick<
      Assessment,
      | "id"
      | "employeePersonGroup"
      | "performance"
      | "potential"
      | "riskOfLoss"
      | "impactOfLoss"
      | "template"
      | "goalRating"
      | "competenceRating"
      | "overalRating"
    >
  : V extends "assessment.scrum.competence"
  ? Pick<
      Assessment,
      "id" | "employeePersonGroup" | "competenceRating" | "goalRating"
    >
  : V extends "assessment.view"
  ? Pick<
      Assessment,
      | "id"
      | "startDate"
      | "endDate"
      | "goalRating"
      | "competenceRating"
      | "overalRating"
      | "performance"
      | "potential"
      | "riskOfLoss"
      | "impactOfLoss"
      | "assessmentName"
      | "competenceSource"
      | "resultPercent"
      | "result"
      | "requiredDevelopmet"
      | "comment"
      | "selfAssessment"
      | "otherAssessment"
      | "formattedOveralRating"
      | "formattedCompetenceRating"
      | "formattedGoalRating"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "template"
      | "employeePersonGroup"
    >
  : V extends "assessmentForCard"
  ? Pick<
      Assessment,
      | "id"
      | "startDate"
      | "endDate"
      | "goalRating"
      | "competenceRating"
      | "overalRating"
      | "performance"
      | "potential"
      | "riskOfLoss"
      | "impactOfLoss"
      | "assessmentName"
      | "competenceSource"
      | "resultPercent"
      | "result"
      | "requiredDevelopmet"
      | "comment"
      | "selfAssessment"
      | "otherAssessment"
      | "formattedOveralRating"
      | "formattedCompetenceRating"
      | "formattedGoalRating"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "template"
      | "status"
      | "employeePersonGroup"
    >
  : never;
