import Notification from "../util/Notification/Notification";
import {Modal} from "antd";

type ExceptionType = "SYNC"| "ASYNC";

type LocalizedException = {
  type: ExceptionType,
  message: string
};

const handleLocalisedException = (e: LocalizedException) => {
  if (e.type === "ASYNC") {
    Notification.error({
      message: e.message
    });

    return;
  }

  if (e.type === "SYNC") {
    Modal.error({
      content: e.message
    });
    return;
  }

}

export function makeFetchWrapper() {
  const originalFetch = window.fetch;
  window.fetch = async function (url, options) {
    const resp = await originalFetch(url, options);

    if (resp.headers.get("Content-Type") !== "application/json") {
      return resp;
    }

    if (!resp.ok) {
      const error = await resp.json();
      if (error._isLocalized) {
        handleLocalisedException(error);
      }
    }

    return resp;
  }
}
