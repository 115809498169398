import {observer} from "mobx-react";
import React, {ReactNode} from "react";
import {DataCollectionStore} from "@cuba-platform/react/dist/data/Collection";
import {DataTable} from "@cuba-platform/react";
import {BaseUuidEntity} from "../../../cuba/entities/base/sys$BaseUuidEntity";
import {getFieldValue} from "../../pages/LearningBudget/utils";
import {Input} from "antd";

export type ColumnSettings = {
  caption: string
  field: string
}

type PickerTableProps<E> = {
  tableData: DataCollectionStore<E & BaseUuidEntity>
  columns: ColumnSettings[]
  selectionMode: 'single' | 'multi' | 'none'
  onSelect: (rows: E[]) => void
  onNameFilterChange?: (value: string) => void;
  filter?: () => ReactNode;
}

@observer
export default class PickerTable<E> extends React.Component<PickerTableProps<E>> {

  nameFilterValue = ''

  columns = this.props.columns.map(col => ({
    title: col.caption,
    render: (text: any, record: (E & BaseUuidEntity)) => (
      <label>
        {getFieldValue(col.field.split('.'), record)}
      </label>
    )
  }))

  handleSelectionChange = (selectedRowKeys: string[]) => {
    if (selectedRowKeys.length) {
      const rows = this.props.tableData.items.filter(value => selectedRowKeys.includes(value.id))
      this.props.onSelect(rows)
    }
  }

  render() {
    return (

      <div>
        {
          this.props.filter
            ? this.props.filter()
            : (<div style={{marginBottom: "5px"}}>
              <Input onChange={(event) => this.nameFilterValue = event.target.value}
                     onPressEnter={(event) => this.props.onNameFilterChange!(this.nameFilterValue)}/>
            </div>)
        }
        <DataTable fields={[]} dataCollection={this.props.tableData}
                   rowSelectionMode={this.props.selectionMode} onRowSelectionChange={this.handleSelectionChange}
                   tableProps={{
                     columns: this.columns
                   }}
        />
      </div>
    )
  }
}