import {action, observable, runInAction} from "mobx";
import RootStore from "./RootStore";


export default class CBAStore{
  root: RootStore;
  @observable participantTotalResult:number

  @observable renderParticipantTotalResult:number

  @observable renderEmployeeParticipantTotalResult:number

  @observable weight:number

  @observable participantStatusCode:string

  @observable activeKey:string

  @observable isHasCategoryCodeDelta:boolean

  constructor(rootStore:RootStore) {
    runInAction(()=>{
      this.root = rootStore;
    })
    this.isHasCategoryCodeDelta = false
    this.activeKey = "1"
    this.renderEmployeeParticipantTotalResult = 0
    this.participantStatusCode = "DRAFT"
  }

  @action
  setCategoryCodeDelta = (value:boolean) =>{
    this.isHasCategoryCodeDelta = value
  }

  @action
  setParticipantStatusCode = (statusCode:string)=>{
    this.participantStatusCode = statusCode
  }

  @action
  setActiveKey = (key:string)=>{
    this.activeKey = key
  }

  @action
  setParticipantTotalResult = (totalResult:number)=>{
    this.participantTotalResult = totalResult
  }

  @action
  setRenderParticipantTotalResult = (renderTotalResult:number)=>{
    this.renderParticipantTotalResult = renderTotalResult
  }
  @action
  setEmployeeRenderParticipantTotalResult = (renderTotalResult:number)=>{
    debugger
    this.renderEmployeeParticipantTotalResult = renderTotalResult
  }
  @action
  setCBAWeight = (weight:number)=>{
    this.weight = weight
  }
}