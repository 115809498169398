import RootStore from "./RootStore";
import {action} from "mobx";
import { HierarchyData } from "../pages/LearningBudget/BudgetHierarchyComponent";
import { LearningBudget } from "../../cuba/entities/base/tsadv_LearningBudget";
import { SerializedEntity } from "@cuba-platform/rest";
import { OrganizationGroupExt } from "../../cuba/entities/base/base$OrganizationGroupExt";
import { AssignmentExt } from "../../cuba/entities/base/base$AssignmentExt";
import {PaginationConfig} from "antd/es/pagination";
import { LearningBudgetRequestHeader } from "../../cuba/entities/base/tsadv_LearningBudgetRequestHeader";

export default class {
  rootStore: RootStore;

  selectedHierarchyData?: HierarchyData;
  selectedBudget: SerializedEntity<LearningBudget> | undefined;
  selectedOrganization: SerializedEntity<OrganizationGroupExt>;
  expandedKeys: string[] = [];
  allAssignments: Array<SerializedEntity<AssignmentExt>>;
  budgetRequestPagingConfig: PaginationConfig;
  currentLearningBudgetRequestHeader: LearningBudgetRequestHeader;
  isForecast: boolean = false;

  selectedTab?: string;
  selectedMenu?: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  clearBudgetInfo = () => {
    this.selectedHierarchyData = undefined;
    this.selectedTab = undefined;
    this.selectedMenu = undefined;
  };

  @action
  setBudgetInfo = (hierarchyData: HierarchyData, budget: SerializedEntity<LearningBudget>, selectedTab: string, selectedMenu: string) => {
    this.selectedHierarchyData = hierarchyData;
    this.selectedBudget = budget
    if (selectedTab || !hierarchyData)
      this.selectedTab = selectedTab;
    if (selectedMenu || !hierarchyData)
      this.selectedMenu = selectedMenu;
  };

  @action
  setBudget = (budget: SerializedEntity<LearningBudget>) => {
    this.selectedBudget = budget
  }

  @action
  setHierarchyData = (hierarchyData: HierarchyData) => {
    this.selectedHierarchyData = hierarchyData;
  }

  @action
  setOrganization = (organization: SerializedEntity<OrganizationGroupExt>) => {
    this.selectedOrganization = organization;
  }

  @action
  setExpandedKeys = (keys: string[]) => {
    this.expandedKeys = keys;
  }

  @action
  setAssignments = (assignments: Array<SerializedEntity<AssignmentExt>>) => {
    this.allAssignments = assignments;
  }

  @action
  setBudgetRequestConfig = (budgetRequestPagingConfig: PaginationConfig) => {
    this.budgetRequestPagingConfig = budgetRequestPagingConfig;
  }

  @action
  setLearningBudgetRequestHeader = (learningBudgetRequestHeader: LearningBudgetRequestHeader) => {
    this.currentLearningBudgetRequestHeader = learningBudgetRequestHeader;
  }

  @action
  setIsForecast = (isForecast: boolean) => {
    this.isForecast = isForecast;
  }


}