import { StandardEntity } from "./sys$StandardEntity";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { Medal } from "./tsadv$Medal";
import { PersonMedalRequest } from "./tsadv_PersonMedalRequest";
import { DicCompany } from "./base_DicCompany";
import { DicAwardType } from "./tsadv$DicAwardType";
export class PersonMedal extends StandardEntity {
  static NAME = "tsadv$PersonMedal";
  personGroup?: PersonGroupExt | null;
  medal?: Medal | null;
  request?: PersonMedalRequest | null;
  company?: DicCompany | null;
  awardType?: DicAwardType | null;
  medalCount?: number | null;
  startDate?: any | null;
  endDate?: any | null;
  isActive?: boolean | null;
  awardedBy?: PersonGroupExt | null;
  deliveryDate?: any | null;
  delivered?: boolean | null;
  hasUpgraded?: boolean | null;
  upgradedMedal?: Medal | null;
  upgradedDate?: any | null;
}
export type PersonMedalViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "pcfMedal-view"
  | "personMedal.edit"
  | "personMedal.exchange";
export type PersonMedalView<V extends PersonMedalViewName> = V extends "_base"
  ? Pick<
      PersonMedal,
      | "id"
      | "medalCount"
      | "startDate"
      | "endDate"
      | "isActive"
      | "deliveryDate"
      | "delivered"
      | "hasUpgraded"
      | "upgradedDate"
    >
  : V extends "_local"
  ? Pick<
      PersonMedal,
      | "id"
      | "medalCount"
      | "startDate"
      | "endDate"
      | "isActive"
      | "deliveryDate"
      | "delivered"
      | "hasUpgraded"
      | "upgradedDate"
    >
  : V extends "pcfMedal-view"
  ? Pick<
      PersonMedal,
      | "id"
      | "medalCount"
      | "startDate"
      | "endDate"
      | "isActive"
      | "deliveryDate"
      | "delivered"
      | "hasUpgraded"
      | "upgradedDate"
      | "medal"
      | "awardType"
    >
  : V extends "personMedal.edit"
  ? Pick<
      PersonMedal,
      | "id"
      | "medalCount"
      | "startDate"
      | "endDate"
      | "isActive"
      | "deliveryDate"
      | "delivered"
      | "hasUpgraded"
      | "upgradedDate"
      | "personGroup"
      | "medal"
      | "request"
      | "company"
      | "awardType"
      | "awardedBy"
      | "upgradedMedal"
    >
  : V extends "personMedal.exchange"
  ? Pick<
      PersonMedal,
      | "id"
      | "medalCount"
      | "startDate"
      | "endDate"
      | "isActive"
      | "deliveryDate"
      | "delivered"
      | "hasUpgraded"
      | "upgradedDate"
      | "personGroup"
      | "medal"
      | "request"
      | "company"
      | "awardType"
      | "awardedBy"
      | "upgradedMedal"
      | "awardType"
      | "medal"
      | "request"
    >
  : never;
