import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import PersonContactRequestEdit from "./PersonContactRequestEdit";
import {observable} from "mobx";

type Props = RouteComponentProps<{ entityId: string, addressId?: string }>;
interface defaultProps{
  isRequest?:boolean
}

@observer
export class PersonContactRequestManagement extends React.Component<Props,defaultProps> {
  static PATH = "/personContactRequest";
  static NEW_SUBPATH = "new";
  @observable defaultProps:defaultProps
  constructor(props:Props,defaultProps:defaultProps) {
    super(props,defaultProps);
    this.defaultProps = defaultProps
  }

  render() {

    const {entityId,addressId} = this.props.match.params;
    console.log(this.props.match.params)
    return (
      <>
        <PersonContactRequestEdit entityId={entityId} addressId={addressId} isRequest={this.defaultProps.isRequest}/>
      </>
    );
  }
}
