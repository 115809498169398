import React from 'react';
import {Tabs} from "antd";
import {injectIntl, WrappedComponentProps} from "react-intl";
import AssignedGoalList from "../../AssignedGoals/IndividualGoal/AssignedGoalList";
import CBAGoal from "../../AssignedGoals/CBAGoal/CBAGoal";
import {action, observable, runInAction} from "mobx";
import {RouteComponentProps} from "react-router-dom";
import {RootStoreProp} from "../../../store";
import {inject} from "mobx-react";
import { EmployeeColumns } from '../AssignedPerformancePlanEdit';

type Props = {
  isUsedCBAForm:boolean
  match:any
  historya:any
  assignedPerformancePlanId: string;
  setTotalWeight?: (totalWeight: number) => void
  setTotalResult?: (totalResult: number) => void
  assessmentStatus:string
  stepIndex:any,
  readonly: boolean;
  approverHrRoleCode?: string;
  cbaWeight?: number;
  KPIfinalScore: number;
  RenderCompetencetotalResult: number;
  RendertotalResult: number;
  personInitiatorId?: string;
  parentForm: any;
  initiatorId:string|undefined;
  setAssignedPerformanceState?: (state: {
    update: () => void;
    validate: () => boolean;
  }) => void;
  setEmployeeColumns: (map:Map<string, EmployeeColumns>) => void;
}

@inject("rootStore")
class GoalForm extends React.Component<Props & WrappedComponentProps & RootStoreProp > {

  @observable activeKey:string
  @observable activeKeyAfterClick:boolean = false
  @observable totalResult:number
  @observable cbaWeight:number

  @action
  setTotalResult=(v:number)=>{
    this.totalResult = v
  }


  render() {
    const {TabPane} = Tabs


    return (

      <Tabs
        activeKey={this.props.rootStore!.cbaStore!  && this.props.rootStore!.cbaStore!.activeKey!}
        defaultActiveKey={"1"}
        onTabClick={(key:string)=>runInAction(()=>this.props.rootStore!.cbaStore! && this.props.rootStore!.cbaStore!.setActiveKey && this.props.rootStore!.cbaStore!.setActiveKey(key))}
        key={'tabs'}
        onChange={(activeKey:string)=>{
        runInAction(()=>{
          this.activeKey = activeKey
        })
          this.props.rootStore!.cbaStore! && this.props.rootStore!.cbaStore!.setActiveKey && this.props.rootStore!.cbaStore!.setActiveKey(this.activeKey)
      }}>

        <TabPane  tab={this.props.intl.formatMessage({id: "kpi.edit.tabs.ratingForm"})} key="1">
          <div>
            <AssignedGoalList
              RendertotalResult={this.props.RendertotalResult}
              RenderCompetencetotalResult={this.props.RenderCompetencetotalResult}
              assessmentStatus={this.props.assessmentStatus}
              KPIfinalScore={this.props.KPIfinalScore}
              key='AssignedGoalList'
              setAssignedPerformanceState={this.props.setAssignedPerformanceState}
              assignedPerformancePlanId={this.props.assignedPerformancePlanId}
              setTotalWeight={this.props.setTotalWeight}
              setTotalResult={this.props.setTotalResult}
              parentForm={this.props.parentForm}
              approverHrRoleCode={this.props.approverHrRoleCode}
              readonly={this.props.readonly}
              setEmployeeColumns={this.props.setEmployeeColumns}
              />
          </div>
        </TabPane>


        {/*<TabPane tab={this.props.intl.formatMessage({id: "kpi.edit.tabs.goalTreeForm"})} key="2">*/}
        {/*  <GoalTree*/}
        {/*    assignedPerformancePlanId={this.props.assignedPerformancePlanId}/>*/}
        {/*</TabPane>*/}
        {this.props.isUsedCBAForm && this.props.stepIndex === 2?
          <TabPane tab={"CBA"} key="cbaForm">
            <CBAGoal
              approverHrRoleCode={this.props.approverHrRoleCode}
              personInitiatorId={this.props.personInitiatorId}
              initiatorId={this.props.initiatorId}
              assignedPerformancePlanId={this.props.assignedPerformancePlanId}
            />
          </TabPane>:<></>
        }

      </Tabs>

    );
  }
}

export default injectIntl(GoalForm);
