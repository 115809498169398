import {Button, Icon, Popover} from "antd";
import React from "react";
import {PopoverProps} from "antd/es/popover";


export type PopoverComponentProps =
  PopoverProps
  & {
    content?:any,
    title?:string,
    popoverTitle: JSX.Element,
    popoverData:any,
    useInKeyPosition?:boolean
}

export default class extends React.Component<PopoverComponentProps>{


  render() {
    const content = (
      <div style={{padding:"14px",maxHeight:"350px",overflowY:"scroll"}}>
        <div className={"hover-list"} style={{display:"flex", justifyContent:"flex-start"}}>
          <Icon  type="user" />
          <div style={{marginLeft:"10px", marginBottom:"20px"}}> {this.props.popoverTitle} </div>
        </div>
        {
          this.props.useInKeyPosition
            ? this.props.popoverData && this.props.popoverData!.map((item:any,index:any)=><div key={index} style={{padding:"12px 5px", background:"#F3F3F3", borderRadius:"3px", margin:"8px 0"}}>{index+1}) {item.full_name}</div>)
              : this.props.popoverData && this.props.popoverData!.map((item:any,index:any)=><div key={item.id} style={{padding:"12px 5px", background:"#F3F3F3", borderRadius:"3px", margin:"8px 0"}}>{index+1}) {item.fullName}</div>)
        }
      </div>
    );

    return (
      <Popover className={"keyPosition-pop"} placement="bottom" content={content} children={
        <Button style={{background:"transparent", border:"none", width:"100%", padding:"0", cursor:"pointer"}}>
          {this.props.children}
        </Button>
      }/>

    );
  }
}