import {AbstractBprocRequest} from "./AbstractBprocRequest";
import {FileDescriptor} from "./sys$FileDescriptor";
import {PersonGroupExt} from "./base$PersonGroupExt";
import {SurChargeType} from "../../enums/enums";

export class SurChargeRequest extends AbstractBprocRequest {
  static NAME = "tsadv_SurChargeRequest";
  employeeName?: PersonGroupExt | null;
  aidSum?: any | null;
  aidType?: SurChargeType | null;
  justification?: string | null;
  file?: FileDescriptor[] | null;
}

export type SurChargeApplicationViewName = "_base" | "_local" | "_minimal" | "surchargerequest.view" ;