import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../../store";
import { getCubaREST, MainStoreInjected} from "@cuba-platform/react";
import {Button, Input, Tabs} from "antd";
import ScoreCard from "./ScoreCard";
import {MyTeamCardProps} from "../../MyTeamCard";
import ScoreCardHistory from "./ScoreCardHistory";
import {restServices} from "../../../../../cuba/services";
import {observable, runInAction} from "mobx";
import {inject, observer} from "mobx-react";
import {PersonGroupExt} from "../../../../../cuba/entities/base/base$PersonGroupExt";
import {runReport} from "../../../../util/reportUtil";
import {DicLanguage} from "../../../../../cuba/entities/base/base$DicLanguage";
import {PersonExt} from "../../../../../cuba/entities/base/base$PersonExt";

@observer
@inject("rootStore")
class ScoreCardTabs extends React.Component<WrappedComponentProps & RootStoreProp & MainStoreInjected  & MyTeamCardProps>{

  @observable
  calcResult:string=""

  @observable
  collectionLangDc:any

  @observable
  personExtId:string


  @observable
  currentAssignmentId:string
  report = async () => {
    const data = {
      template: "DEFAULT",
      parameters: [
        {
          name: "pid",
          value: this.personExtId
        }
      ]
    };

    await runReport((this.props.rootStore!.userInfo!.locale === "en"?"SCORECARD_EN":"SCORECARD_RU"), data, this.props.intl);
  }
  render() {
    const {TabPane} = Tabs;
    return(
      <>
        <div>
          <label style={{
            fontWeight:500
          }}>
            <FormattedMessage id={"cba.scoreCardMain.result"} />
          </label>
          <Input value={`${this.calcResult} %`}
                 style={{
                   width:"200px",
                   marginLeft: "12px"
                 }}/>
        </div>
        <Button
          style={{background:"green",color:"#fff",margin:"20px 0"}}
          onClick={this.report}
        >
          <FormattedMessage id={"cba.report"} />
        </Button>
        <Tabs defaultActiveKey={"1"}>
          <TabPane tab={this.props.intl.formatMessage({
            id: "cba.scoreCardMain.Scorecard"
          })} key={"1"}>
            <ScoreCard currentAssignmentId={this.currentAssignmentId} personGroupId={this.props.personGroupId}/>
          </TabPane>
          <TabPane tab={this.props.intl.formatMessage({
            id: "cba.scoreCardMain.Other"
          })} key={"2"}>
            <ScoreCardHistory currentAssignmentId={this.currentAssignmentId} personGroupId={this.props.personGroupId}/>
          </TabPane>
        </Tabs>
      </>
    )
  }
  componentDidMount() {
    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions:[{
        property: "group",
        operator:"=",
        value:this.props.personGroupId
      }
      ]
    },{
      view: 'person-for-search'
    }).then(value => {
      this.personExtId = value[0].id
    });
    getCubaREST()!.loadEntities<DicLanguage>("tsadv$DicLanguage",{
      view:"_local"
    }).then((res)=>{
      this.collectionLangDc = res.filter(lang=>lang.code === this.props.rootStore!.userInfo!.locale!.toUpperCase())
    })
    getCubaREST()!.searchEntities<PersonGroupExt>(PersonGroupExt.NAME,{
      conditions:[{
        property:"id",
        operator:"=",
        value:this.props!.personGroupId!
      }]
    },{
      view:"personGroup-with-position",
      sort:"updateTs"
    }).then(v=>{
      runInAction(()=>{
        this.currentAssignmentId = v && v[0].currentAssignment!.id
      })
      restServices.scoredCompetencyService.calcResult({assignmentId:v && v[0].currentAssignment!.id}).then(value=>{
        if(value!==undefined){
          this.calcResult = value
        }
      })
    })

  }
}
export default injectIntl(ScoreCardTabs)