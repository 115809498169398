import * as React from "react";
import {createElement} from "react";
import {Card, Col, Form, Input, Row, Spin} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";
import {collection, getCubaREST, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";
import "../../../app/App.css";
import {AddressRequest} from "../../../cuba/entities/base/tsadv$AddressRequest";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {DicKato} from "../../../cuba/entities/base/tsadv_DicKato";
import {DicStreetType} from "../../../cuba/entities/base/tsadv_DicStreetType";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import moment from "moment";
import {goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import MsgEntity from "../../components/MsgEntity";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import {PersonEducationRequest} from "../../../cuba/entities/base/tsadv_PersonEducationRequest";
import {PersonEducationRequestManagement} from "./PersonEducationRequestManagement";
import {PersonEducation} from "../../../cuba/entities/base/tsadv$PersonEducation";
import {DicFormStudy} from "../../../cuba/entities/base/tsadv_DicFormStudy";
import { DicEducationType } from "../../../cuba/entities/base/base$DicEducationType";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount"
import {DicEducationDocumentType} from "../../../cuba/entities/base/tsadv_DicEducationDocumentType";

type EditorProps = {
  entityId: string;
  addressId?: string;
  isRequest?:boolean;
}


@inject("rootStore")
@injectMainStore
@observer
class PersonEducationRequestEditComponent extends AbstractBprocEdit<AddressRequest, EditorProps> {
  processDefinitionKey = "personEducationRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<PersonEducationRequest>(PersonEducationRequest.NAME, {
    view: "portal.my-profile",
    loadImmediately: false
  });

  dicFormStudy = (this.isUserInitiator || this.props.entityId === 'new') ? collection<DicFormStudy>(DicFormStudy.NAME,{
    view: "dicFormStudy-view",
      filter:{
        conditions:[{
          group:"OR",
          conditions:[{
            property:'company.code',
            operator:"=",
            value:this.props.rootStore!.userInfo!.companyCode!
          },{
            property:'company.code',
            operator:"=",
            value: "empty"
          }]
        }]
      }
  }) : collection<DicFormStudy>(DicFormStudy.NAME, {
    view: "_minimal"
  });

  reqCollection = collection(PersonEducationRequest.NAME, {
    view:"_base"
  })

  dicEducationType = (this.isUserInitiator || this.props.entityId === 'new') ? collection<DicEducationType>(DicEducationType.NAME,{
    view: "dicEducationType-browse",
    filter:{
      conditions:[{
        group:"OR",
        conditions:[{
          property:'company.code',
          operator:"=",
          value:this.props.rootStore!.userInfo!.companyCode!
        },{
          property:'company.code',
          operator:"=",
          value: "empty"
        }]
      }]
    }
  }):collection<DicEducationType>(DicEducationType.NAME, {
    view: "_minimal"
  });

  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  katosDc = collection<DicKato>(DicKato.NAME,
    {view: "_minimal", loadImmediately: false, sort: this.dicLangValue});

  streetTypesDc = collection<DicStreetType>(DicStreetType.NAME, {
    view: "_minimal",
    sort: this.dicLangValue
  });

  @observable
  editAddress: PersonEducation;

  instanceEditAddress = instance<PersonEducation>(PersonEducation.NAME, {
    view: "personEducation.full",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  isKatoRequired = false;

  @observable
  approverHrRoleCode: string;

  isUpdateBeforeOutcome = true;

  fields= [

    "requestNumber",

    "requestDate",

    "status",

    "startDate",

    "endDate",

    "school",

    "educationType",

    "specialization",

    "diplomaNumber",

    "faculty",

    "startYear",

    "endYear",

    "qualification",

    "formStudy",

    "attachments"
  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return {personEducation:this.props.addressId, ...super.getUpdateEntityData()};
    return {
      personGroup: this.personGroupId,
      personEducation:this.props.addressId,
      baseAddress: this.editAddress ? this.editAddress.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {

    if (!this.mainStore) return <LoadingPage/>
    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>
    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();
    return (
      <div>
        <ScrollToTopOnMount />
        <Page pageName={this.props.isRequest?this.props.intl.formatMessage({id:"personEducationRequest"}):<MsgEntity entityName={entityName}/>}>
        <Section size={"large"}>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}
              />

              <Form.Item
                label={createElement(Msg, {entityName: entityName, propertyName: "personGroup"})}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>

              {
                this.editAddress ?
                  <Row type={"flex"} className={"data-form"}>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderAddress()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderAddressRequest()}
                    </Col>
                  </Row>
                  : this.renderAddressRequest()
              }

              <ReadonlyField
                entityName={entityName}
                propertyName="attachments"
                form={this.props.form}
                disabled={isNotDraft}
                formItemOpts={{style: {marginBottom: "12px"}}}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.attachments']})
                  }]
                }}
              />

            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
      </div>
    );
  }

  renderAddress = () => {

    const entityName = this.dataInstance.entityName;

    return (
      <Spin spinning={!this.editAddress}>
      <div>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "school"})}>
          <Input
            value={this.editAddress && this.editAddress.school ? this.editAddress.school || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "educationType"})}>
          <Input
            value={this.editAddress && this.editAddress!.educationType ? this.editAddress!.educationType!['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "specialization"})}>
          <Input
            value={this.editAddress && this.editAddress.specialization ? this.editAddress.specialization || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "diplomaNumber"})}>
          <Input
            value={this.editAddress && this.editAddress.diplomaNumber ? this.editAddress.diplomaNumber || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "faculty"})}>
          <Input
            value={this.editAddress && this.editAddress.faculty ? this.editAddress.faculty || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "startYear"})}>
          <Input
            value={this.editAddress && this.editAddress!.startYear? this.editAddress!.startYear : ""}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "endYear"})}>
          <Input
            value={this.editAddress && this.editAddress!.endYear? this.editAddress!.endYear : ""}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "qualification"})}>
          <Input
            value={this.editAddress && this.editAddress.qualification ? this.editAddress.qualification || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "formStudy"})}>
          <Input
            value={this.editAddress && this.editAddress.formStudy ? this.editAddress.formStudy['_instanceName'] || '' : ''}
            disabled/>
        </Form.Item>



      </div>
      </Spin>
    );
  }

  renderAddressRequest = () => {

    const messages = this.mainStore.messages!;

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    return (
      <div>
        <ReadonlyField
          entityName={entityName}
          propertyName='school'
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('school', value !== this.editAddress.school);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('school'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="educationType"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={this.dicEducationType}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.educationType']})
            }],
            getValueFromEvent: args => {
              if (this.editAddress)
                this.changedMap.set('educationType', args !== (this.editAddress.educationType ? this.editAddress.educationType : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('educationType'),
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="specialization"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.educationType']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('specialization', value !== this.editAddress!.specialization);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('specialization'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="diplomaNumber"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('diplomaNumber', value !== this.editAddress!.diplomaNumber);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('diplomaNumber'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="faculty"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('faculty', value !== this.editAddress!.diplomaNumber);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('faculty'),
            validateStatus: "success",
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName="startYear"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.startYear']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('startYear', value !== this.editAddress.startYear);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('startYear'),
          }}
        />


        <ReadonlyField
          entityName={entityName}
          propertyName="endYear"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: false,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.endYear']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('endYear', value !== this.editAddress.endYear);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('endYear'),
          }}
        />
        <ReadonlyField
          entityName={entityName}
          propertyName='qualification'
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('qualification', value !== this.editAddress.qualification);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('qualification'),
            validateStatus: "success",
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="formStudy"
          disabled={isNotDraft}
          form={this.props.form}
          optionsContainer={this.dicFormStudy}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.formStudy']})
            }],
            getValueFromEvent: args => {
              if (this.editAddress)
                this.changedMap.set('formStudy', args !== (this.editAddress.formStudy ? this.editAddress.formStudy : undefined));
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('formStudy'),
          }}
        />
      </div>
    );
  }


  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: AddressRequest | undefined) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();

    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => this.person = value)

    const addressId = this.props.addressId || (item && item.baseAddress ? item.baseAddress.id : undefined)

    if (addressId)
      getCubaREST()!.loadEntity(PersonEducation.NAME, addressId, {view: 'personEducation.full'})
        .then(value => this.editAddress = value as PersonEducation)
        .then(value => {
          this.instanceEditAddress.setItem(value);
          const properties = [

            "school",

            "educationType",

            "specialization",

            "diplomaNumber",

            "faculty",

            "startYear",

            "endYear",

            "qualification",

            "formStudy",

          ];
          if (this.props.entityId === PersonEducationRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditAddress.getFieldValues(properties));
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });

  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(PersonEducationRequestEditComponent))
);
