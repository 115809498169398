import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import DocumentsFamiliarizationEdit from "./DocumentsFamiliarizationEdit";
import DocumentsFamiliarizationList from "./DocumentsFamiliarizationList";
import { injectMainStore, MainStoreInjected } from "@cuba-platform/react";

type Props = RouteComponentProps<{ entityId?: string }>;

@injectMainStore
@observer
export class DocumentsFamiliarizationManagement extends React.Component<Props & MainStoreInjected> {
  static PATH = "/documents-familiarization";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId } = this.props.match.params;
    return (
      <>
        {entityId ? (
          <DocumentsFamiliarizationEdit entityId={entityId} />
        ) : (
          <DocumentsFamiliarizationList />
        )}
      </>
    );
  }

  static getLocaleName = (localName: string) => {
    switch (localName) {
      case "en" : {
        return "En";
      }
      case "kz" : {
        return "Kz";
      }
      default: {
        return "";
      }
    }
  };
}
