import * as React from "react";
import {createElement} from "react";
import {Alert, Card, Form, Input, Select} from "antd";
import {inject, observer} from "mobx-react";
import {PunishmentRemovalRequestManagement} from "./punishmentRemovalRequestManagement";
import {FormComponentProps} from "antd/lib/form";
import {Redirect} from "react-router-dom";
import {action, observable, toJS} from "mobx";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";

import {
  collection,
  getCubaREST,
  injectMainStore,
  instance,
  MainStoreInjected,
  Msg,
  MultilineText,
  withLocalizedForm
} from "@cuba-platform/react";

import "../../../../App.css";

import {DicRequestStatus} from "../../../../../cuba/entities/base/tsadv$DicRequestStatus";
import AbstractBprocEdit from "../../../Bproc/abstract/AbstractBprocEdit";
import Section from "../../../../hoc/Section";
import Page from "../../../../hoc/PageContentHoc";
import {PersonExt} from "../../../../../cuba/entities/base/base$PersonExt";
import Button, {ButtonType} from "../../../../components/Button/Button";
import {goBackOrHomePage} from "../../../../util/util";
import {runReport} from "../../../../util/reportUtil";
import moment from "moment";
import {ExecutiveAssistantsManagement} from "../../../ExecutiveAssistants/ExecutiveAssistantsManagement";
import {MyTeamStructureManagement} from "../../MyTeamStructureManagement";
import Notification from "../../../../util/Notification/Notification";
import {withRouter} from "react-router";
import {ReadonlyField} from "../../../../components/ReadonlyField";

import DefaultDatePicker from "../../../../components/Datepicker";
import {restServices} from "../../../../../cuba/services";
import {PunishmentRequestType} from "../../../../../cuba/enums/enums";
import {PunishmentRemovalRequest} from "../../../../../cuba/entities/base/tsadv$PunishmentRemovalRequest";
import {serviceCollection} from "../../../../util/ServiceDataCollectionStore";
import TextArea from "antd/es/input/TextArea";
import {dictionaryCollection} from "../../../../util/DictionaryDataCollectionStore";
import {DicOffenceType} from "../../../../../cuba/entities/base/tsadv$DicOffenceType";
import {DicPunishmentTypes} from "../../../../../cuba/entities/base/tsadv$DicPunishmentTypes";
import {MyTeamNew} from "../../../../../cuba/entities/base/tsadv$MyTeamNew";
import {ExtTaskData} from "../../../../../cuba/entities/base/tsadv_ExtTaskData";

type Props = FormComponentProps & EditorProps;

type EditorProps = {
  entityId: string | undefined;
  personGroupId: string;
};

type PersonProfile = {
  id: string,
  groupId: string,
  positionGroupId: string,
  assignmentGroupId: string,
  positionId: string,
  fullName: string,
  firstLastName: string,
  hireDate?: any,
  birthDate?: any,
  sex?: string,
  cityOfResidence?: string,
  citizenship?: string,
  nationality?: string,
  imageId?: string,
  organizationName?: string,
  positionName?: string,
  email?: string,
  phone?: string,
  companyCode?: string,
}

@inject("rootStore")
@injectMainStore
@observer
class PunishmentRemovalRequestEditComponent extends AbstractBprocEdit<PunishmentRemovalRequest, EditorProps & Props & WrappedComponentProps & MainStoreInjected> {
  dataInstance = instance<PunishmentRemovalRequest>(PunishmentRemovalRequest.NAME, {
    view: "punishmentRemovalRequest-view",
  });

  statusesDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_local"
  });


  @observable
  punishmentsDc = serviceCollection(
    (pagination) => restServices.punishmentService.getPunishmentByPersonGroup({
      personGroupId: this.personGroupId
    }));


  @observable
  updated = false;

  fields = [
    "requestNumber",
    "requestDate",
    "status",
    "earlyTerminationReason",
    "removingOrderPunishment",
    "removingOrderDate",
    "removingFile",
  ];

  @observable
  approverHrRoleCode: string;
  @observable selectedPerson:string;
  personGroupId: string;

  processDefinitionKey = "punishmentRemovalRequest";

  path = PunishmentRemovalRequestManagement.PATH;

  isUpdateBeforeOutcome = true;

  @observable
  reportCode?: string = undefined;

  @observable
  person: PersonExt;
  @observable visible:boolean=false;
  @observable
  personProfile?: PersonProfile;
  @observable teamMembers:MyTeamNew[];
  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }
  @action setSelectedMember=(value:string)=>{
    this.selectedPerson=value;
    this.personGroupId=this.selectedPerson;
    this.loadPerson(this.personGroupId)
      .then(value => this.person = value);
    this.setEmployee(this.personGroupId);
    this.loadPersonProfile();
    this.punishmentsDc.load();
    this.visible=true;
  }
  componentDidMount() {
    super.componentDidMount();
    if(this.props.entityId==="new"&&!this.props.personGroupId){
      restServices.myTeamService.getChildren({parentPositionGroupId: this.props.rootStore!.userInfo!.positionGroupId!}).then((res: MyTeamNew[]) => {
        this.teamMembers = res;
        this.selectedPerson=this.teamMembers&&this.teamMembers[0].personGroupId;});}
    if(this.props.entityId!=="new"){
      this.dataInstance.load(this.props.entityId)
    }
  }

  onReactionDisposerEffect = (item: PunishmentRemovalRequest | undefined) => {
    this.personGroupId = (item && item.personGroup ? (item.personGroup.id) : this.props.personGroupId?this.props.personGroupId:this.selectedPerson) as string;
    if(this.personGroupId){
      this.loadPerson(this.personGroupId)
        .then(value => this.person = value);
      this.setEmployee(this.personGroupId);
      this.loadPersonProfile();
      this.punishmentsDc.load()}
  }

  getUpdateEntityData = (): any => {
    if (this.isNotDraft()) return {
      ...this.props.form.getFieldsValue(this.fields),
    };
    return {
      personGroup: {
        id: this.personGroupId
      },
      ...this.props.form.getFieldsValue(this.fields),
    }
  };


  report = () => {
    const data = {
      parameters: [{
        name: "req",
        value: this.props.entityId
      }]
    };

    runReport(this.reportCode!, data, this.props.intl);
  }

  actions = () => {
    const {status} = this.dataInstance;

    const actions = [];

    actions.push(<Button buttonType={ButtonType.FOLLOW}
                         disabled={status !== "DONE" && status !== "ERROR"}
                         loading={status === "LOADING"}
                         onClick={this.saveRequest}>
      <FormattedMessage id="management.editor.submit"/>
    </Button>);

    actions.push(<Button buttonType={ButtonType.FOLLOW}
                         onClick={event => goBackOrHomePage(this.props.history)}>{this.props.intl.formatMessage({id: "close"})}</Button>);

    if (!this.isNewEntity()) {
      if (this.reportCode)
        actions.push(<Button buttonType={ButtonType.FOLLOW}
                             onClick={this.report}>{this.props.intl.formatMessage({id: "report"})}</Button>);

      actions.push(this.getOutcomeBtns());
    }
    return actions;
  }

  loadPerson = (personGroupId: string): Promise<PersonExt> => {
    this.dataInstance.update({requestType: PunishmentRequestType.REMOVAL});
    return getCubaREST()!.searchEntities(PersonExt.NAME, {
      conditions: [{
        property: 'group.id',
        operator: '=',
        value: personGroupId,
      }, {
        property: 'startDate',
        operator: '<',
        value: moment().format('YYYY-MM-DD'),
      }, {
        property: 'endDate',
        operator: '>',
        value: moment().format('YYYY-MM-DD'),

      }]
    }, {
      view: '_minimal'
    }).then(value => value[0] as PersonExt);
  }


  loadBpmProcessData = () => {
    const processDefinitionKey = this.processDefinitionKey;
    if (this.props.entityId !== "new") {

      restServices.bprocService.processInstanceData({
        processInstanceBusinessKey: this.processInstanceBusinessKey(),
        processDefinitionKey: processDefinitionKey
      }).then(value => {
        this.isCalledProcessInstanceData = true;
        this.processInstanceData = value;
        if (value) {
          this.isUserInitiator = value.startUserId === this.props.rootStore!.userInfo!.id;
          restServices.bprocService.tasks({processInstanceData: value})
            .then(tasks => {
              this.tasks = tasks;
              this.activeTask = tasks.find(task => !task.endTime
              ) as ExtTaskData;

              this.activeUserTask = this.activeTask
              && this.activeTask.assigneeOrCandidates
              && this.activeTask.assigneeOrCandidates.some(user => user.id === this.props.rootStore!.userInfo.id)
                ? this.activeTask : null;

              if (this.activeUserTask)
                restServices.bprocFormService.getTaskFormData({taskId: this.activeUserTask.id!})
                  .then(formData => {
                    this.formData = formData;
                    this.isStartForm = false;
                    this.initVariablesByBproc();
                  });
              else {
                if (this.isUserInitiator && this.activeTask) {
                  this.isStartForm = false;
                }
                this.initVariablesByBproc();
              }
            })
        } else {
          restServices.bprocService.getStartFormData({processDefinitionKey: processDefinitionKey})
            .then(formData => {
              this.formData = formData;
              this.isStartForm = true;
              this.initVariablesByBproc();
            });
        }
      })
    } else {
      const processDefinitionKey = this.processDefinitionKey;

      restServices.bprocService.getStartFormData({processDefinitionKey: processDefinitionKey})
        .then(formData => {
          this.formData = formData;
          this.isStartForm = true;
          this.initVariablesByBproc();
        });
    }
  }


  render() {
    if (this.updated) {
      return <Redirect to={PunishmentRemovalRequestManagement.PATH}/>;
    }
    const messages = this.mainStore.messages!;
    const {getFieldDecorator} = this.props.form;
    return (
      <Page pageName={this.props.intl.formatMessage({id: "PunishmentRemovalRequest"})}>
        <Section size="large">
          <Card className="narrow-layout card-actions-container"
                actions={this.actions()}
                bordered={false}>
            <Form layout="vertical">

              {getFieldDecorator('requestType',)(<Input type="hidden"/>)}

              <ReadonlyField
                entityName={PunishmentRemovalRequest.NAME}
                propertyName="requestNumber"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                disabled
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.requestNumber']}),
                  }]
                }}
              />

              <ReadonlyField
                entityName={PunishmentRemovalRequest.NAME}
                propertyName="requestDate"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                disabled
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.requestDate']}),
                  }]
                }}
              />

              <ReadonlyField
                entityName={PunishmentRemovalRequest.NAME}
                propertyName="status"
                form={this.props.form}
                formItemOpts={{style: {marginBottom: "12px"}}}
                optionsContainer={this.statusesDc}
                disabled
                getFieldDecoratorOpts={{
                  rules: [{required: true}],
                }}
              />

              <div className={"ant-row ant-form-item"} style={{marginBottom: "12px"}}>
                <FormattedMessage id="employee.personName"/>
                {this.props.personGroupId||this.props.entityId!=="new"?
                  <Input disabled
                         value={this.personProfile ? this.personProfile.fullName || '' : ''}
                  />: <Select placeholder={this.props.intl.formatMessage({id:"chooseEmployee"})} disabled={!this.isDraft() && this.isRevise()} value={this.visible?this.selectedPerson:undefined} onChange={(value => this.setSelectedMember(value.toString()))}>
                    {this.teamMembers&&this.teamMembers.map((item:MyTeamNew)=><Select.Option value={item.personGroupId}>{item&&item.fullName}</Select.Option>)}
                  </Select>}
              </div>

              <div className={"ant-row ant-form-item"} style={{marginBottom: "12px"}}>
                <FormattedMessage id="employee.positionName"/>
                <Input disabled
                       value={this.personProfile ? this.personProfile.positionName || '' : ''}
                />
              </div>

              <div className={"ant-row ant-form-item"} style={{marginBottom: "12px"}}>
                <FormattedMessage id="employee.organizationName"/>
                <Input disabled
                       value={this.personProfile ? this.personProfile.organizationName || '' : ''}
                />
              </div>

              <FormattedMessage id="employee.hireDate"/>
              <div className={"ant-row ant-form-item"} style={{marginBottom: "12px"}}>
                <DefaultDatePicker disabled
                                   value={this.personProfile ? moment(this.personProfile.hireDate) || '' : null}
                />
              </div>

              <ReadonlyField
                entityName={this.dataInstance.entityName}
                propertyName="removingOrderPunishment"
                form={this.props.form}
                optionsContainer={this.punishmentsDc}
                formItemOpts={{style: {marginBottom: "12px"}, label: this.props.intl.formatMessage({id: "PunishmentRemovalRequest.removingOrderPunishment"})}}
                disabled={!this.isDraft() && !this.isRevise()}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.removingOrderPunishment']}),
                  }]
                }}
              />
              <Form.Item
                style={{width: '100%'}}
                label={this.props.intl.formatMessage({id: "PunishmentRemovalRequest.earlyTerminationReason"})}>
                {getFieldDecorator("earlyTerminationReason", {
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.accident']})
                  }]
                })(
                  <TextArea
                    disabled={!this.isDraft() && !this.isRevise()}
                    rows={4}/>
                )}
              </Form.Item>


              <ReadonlyField
                entityName={PunishmentRemovalRequest.NAME}
                propertyName="removingFile"
                form={this.props.form}
                disabled={!this.isDraft() && !this.isRevise()}
                formItemOpts={{style: {marginBottom: "12px"}, label: this.props.intl.formatMessage({id: "PunishmentRemovalRequest.removingFile"})}}
                getFieldDecoratorOpts={{
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[this.dataInstance.entityName + '.removingFile']}),
                  }]
                }}
              />

              {this.globalErrors.length > 0 && (
                <Alert
                  message={<MultilineText lines={toJS(this.globalErrors)}/>}
                  type="error"
                  style={{marginBottom: "24px"}}
                />
              )}

              {this.takCard()}

            </Form>
          </Card>
        </Section>
      </Page>

    );
  }

  isDraft() {
    const completeStatus = this.statusesDc.items.find(value => value.code === 'DRAFT')
    if (completeStatus) {
      return this.props.form.getFieldValue('status') === completeStatus.id
    }
    return false
  }

  isRevise() {
    const completeStatus = this.statusesDc.items.find(value => value.code === 'TO_BE_REVISED')
    if (completeStatus) {
      return this.props.form.getFieldValue('status') === completeStatus.id
    }
    return false
  }

  afterSendOnApprove = () => {
    const statusId = this.dataInstance.item && this.dataInstance.item.status && this.dataInstance.item.status.id;
    if (this.statusesDc.items.find(value => value.id === statusId && value.code === 'DRAFT'))
      this.props.history!.push(
        this.props.rootStore!.assistantTeamInfo.active
          ? ExecutiveAssistantsManagement.PATH
          : MyTeamStructureManagement.PATH);
    else this.props.history!.goBack();
  };

  loadPersonProfile() {
    restServices.employeeService.personProfile(this.personGroupId)
      .then(value => {
        this.personProfile = value;
      })
      .catch(() => {
          Notification.error({
            message: this.props.intl.formatMessage({id: "management.editor.error"})
          });
        }
      )
  }

}

const component = injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(PunishmentRemovalRequestEditComponent))
);

export default (component);
