import React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {observer} from "mobx-react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import {restServices} from "../../../../../cuba/services";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {getNotFoundImage, NotFoundImage} from "../../../../components/NoImage";
import {generateDivId} from "../../chartUtils";
import {Select} from "antd";
import {getPersonPageLink} from "../../recognitionUtils";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {observable, runInAction} from "mobx";

type ChartPageProps = {
  personGroupId: string
}

type ChartData = {
  receiver: string;
  count: number;
  authorId: string;
  image: string;
  receiverId: string;
}

enum Period {
  DEFAULT = 0,
  WEEK = 4,
  MONTH = 3,
  QUARTER = 2,
  YEAR = 1
}

@observer
class RecognitionCountChart extends React.Component<ChartPageProps & WrappedComponentProps & RouteComponentProps> {
  static readonly PER_PERSON_HEIGHT = 100;

  @observable height = RecognitionCountChart.PER_PERSON_HEIGHT;

  chartDivId = generateDivId();
  period: Period = Period.DEFAULT;
  chart: am4charts.XYChart;

  loadData = async () => {
    const data = await restServices.recognitionChartService.loadRecognitionsCount(
      this.props.personGroupId,
      this.period
    );

    return await Promise.all(
      data.map(async (item: ChartData): Promise<ChartData> => {
        return {...item, image: await this.getPersonImageOrDefault(item.receiverId)}
      })
    );
  }

  componentDidMount() {
    this.init();
  }


  init = async () => {
    this.chart && this.chart.dispose();

    const data = await this.loadData();

    runInAction(() => this.height = RecognitionCountChart.PER_PERSON_HEIGHT * data.length);

    this.chart = this.makeChart();
    this.chart.data = data;
  }

  onChartClick = (event: any) => {
    const groupId = event.target.dataItem!.dataContext.receiverId;
    const link = getPersonPageLink(groupId);

    this.props.history.push({
      pathname: link,
      state: {personGroup: groupId}
    })
  }

  makeChart = () => {
    am4core.useTheme(am4themes_animated);

    const chart = am4core.create(this.chartDivId, am4charts.XYChart);

    chart.hiddenState.properties.opacity = 0;
    chart.paddingRight = 40;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "receiver";
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.marginRight = 40;
    categoryAxis.renderer.tooltip!.dx = -40;

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.fillOpacity = 0.3;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.baseGrid.strokeOpacity = 0;
    valueAxis.renderer.labels.template.dy = 20;

    const series = chart.series.push(new am4charts.ColumnSeries);
    series.dataFields.valueX = "count";
    series.dataFields.categoryY = "receiver";
    series.tooltipText = "{valueX.value}";
    series.tooltip!.pointerOrientation = "vertical";
    series.tooltip!.dy = -30;
    series.columnsContainer.zIndex = 100;

    series.columns.template.events.on("hit", this.onChartClick);

    const columnTemplate = series.columns.template;
    columnTemplate.height = am4core.percent(50);
    columnTemplate.maxHeight = 50;
    columnTemplate.column.cornerRadius(60, 10, 60, 10);
    columnTemplate.strokeOpacity = 0;

    series.heatRules.push({
      target: columnTemplate,
      property: "fill",
      dataField: "valueX",
      min: am4core.color("#e5dc36"),
      max: am4core.color("#5faa46")
    });
    series.mainContainer.mask = undefined;

    const cursor = new am4charts.XYCursor();
    chart.cursor = cursor;
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;
    cursor.behavior = "none";

    const bullet = columnTemplate.createChild(am4charts.CircleBullet);
    bullet.circle.radius = 30;
    bullet.valign = "middle";
    bullet.align = "left";
    bullet.isMeasured = true;
    bullet.interactionsEnabled = false;
    bullet.horizontalCenter = "right";
    bullet.interactionsEnabled = false;

    const outlineCircle = bullet.createChild(am4core.Circle);
    outlineCircle.adapter.add("radius", function (radius, target) {
      const circleBullet = target.parent as any;
      return circleBullet!.circle!.pixelRadius + 10;
    })

    const image = bullet.createChild(am4core.Image);
    image.width = 60;
    image.height = 60;
    image.horizontalCenter = "middle";
    image.verticalCenter = "middle";
    image.propertyFields.href = "image";

    image.adapter.add("mask", function (mask, target) {
      const circleBullet = target.parent as any;
      return circleBullet!.circle!;
    })

    return chart;
  }

  async getPersonImageOrDefault(personGroupId: string) {
    return await restServices.employeeService.getPersonImage(personGroupId)
      || getNotFoundImage(NotFoundImage.EMPLOYEE);
  }

  onPeriodChange = (value: Period) => {
    this.period = value;
    this.init();
  }

  componentWillUnmount() {
    this.chart && this.chart.dispose();
  }

  render() {
    const enumOptions = Object.keys(Period).filter(key => isNaN(Number(key)));

    return <>
      <Select style={{width: "300px"}}
              defaultValue={Period.DEFAULT}
              onChange={this.onPeriodChange}
      >
        {enumOptions.map((option, index) => <Select.Option value={index}>
            <FormattedMessage id={`recognition.recognitionCountChart.period.${option}`}/>
          </Select.Option>
        )}
      </Select>
      <div id={this.chartDivId} style={{height: this.height}}/>
    </>
  }
}

export default withRouter(injectIntl(RecognitionCountChart));