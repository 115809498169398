import React, {createElement} from "react";
import {inject, observer} from "mobx-react";
import {collection, injectMainStore, MainStoreInjected, Msg} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {PersonTimeExperience} from "../../../cuba/entities/base/tsadv_PersonTimeExperience";
import DataTableFormat from "../../components/DataTable/intex";
import moment from "moment";
import {restServices} from "../../../cuba/services";
import {serviceCollection} from "../../util/ServiceDataCollectionStore";
import {Spin, Table} from "antd";



@inject("rootStore")
@injectMainStore
@observer
class PersonAssignmentList extends  React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp >{

  dataCollectionDc = serviceCollection(
    Pagination => restServices.employeeService.getPersonExperienceJson({
      pPersonGroupId:this.props!.rootStore!.userInfo!.personGroupId!, pLang: this.props.rootStore!.userInfo!.locale!, pDate: moment().format("YYYY-MM-DD")})
  )


  render(){

    const columns = [
      {
        title:this.props.intl.formatMessage({id:"personTimeExperience.experienceType"}),
        key:"experienceType",
        dataIndex:"experienceType",
        render:(text:any,record:any)=>{
          return record.experience_type!
        },
        sorter:(a : any, b:any) =>a.experience_type && a.experience_type!.localeCompare(b.experience_type!)
      },

      {
        title:this.props.intl.formatMessage({id:"personTimeExperience.years"}),
        key:"years",
        dataIndex:"years",
        render:(text:any,record:PersonTimeExperience)=>{
          return record.years!
        },
        sorter:(a:PersonTimeExperience, b:PersonTimeExperience) => a.years! - b.years!
      },
      {
        title:this.props.intl.formatMessage({id:"personTimeExperience.months"}),
        key:"months",
        dataIndex:"months",
        render:(text:any,record:PersonTimeExperience)=>{
          return record.months!
        },
        sorter:(a:PersonTimeExperience, b:PersonTimeExperience) => a.months! - b.months!
      },
      {
        title:this.props.intl.formatMessage({id:"personTimeExperience.days"}),
        key:"days",
        dataIndex:"days",
        render:(text:any,record:PersonTimeExperience)=>{
          return record.days!
        },
        sorter:(a:PersonTimeExperience, b:PersonTimeExperience) => a.days! - b.days!
      },
    ]

    return(
      <div>
        <Table
          dataSource={this.dataCollectionDc.items}
          columns={columns}
        />
      </div>
    )
  }

  componentDidMount() {
    this.dataCollectionDc.load()
  }

}

export default injectIntl(PersonAssignmentList)