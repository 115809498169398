import * as React from "react";
import {observer} from "mobx-react";
import HrRequestManagerPage from "./HrRequestManagerPage";

@observer
export class HrRequestManagerManagement extends React.Component {
  static PATH = "/hr-request-manager";

  render() {
    return (
      <>
        <HrRequestManagerPage/>
      </>
    );
  }
}
