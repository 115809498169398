import { StandardEntity } from "./sys$StandardEntity";
import { EmployeeQuestionnaireQuestions } from "./tsadv_EmployeeQuestionnaireQuestions";
import { EmployeeQuestionnaireParticipants } from "./tsadv_EmployeeQuestionnaireParticipants";
export class EmployeeQuestionnaireResults extends StandardEntity {
  static NAME = "tsadv_EmployeeQuestionnaireResults";
  question?: EmployeeQuestionnaireQuestions | null;
  answerText?: string | null;
  employeeQuestionnaireParticipants?: EmployeeQuestionnaireParticipants | null;
}
export type EmployeeQuestionnaireResultsViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "employeeQuestionnaireResults-browseView";
export type EmployeeQuestionnaireResultsView<
  V extends EmployeeQuestionnaireResultsViewName
> = V extends "_base"
  ? Pick<EmployeeQuestionnaireResults, "id" | "answerText">
  : V extends "_local"
  ? Pick<EmployeeQuestionnaireResults, "id" | "answerText">
  : V extends "employeeQuestionnaireResults-browseView"
  ? Pick<
      EmployeeQuestionnaireResults,
      "id" | "answerText" | "question" | "employeeQuestionnaireParticipants"
    >
  : never;
