import {observer} from "mobx-react";
import * as React from "react";
import {FormEvent} from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {observable, runInAction} from "mobx";
import {restServices} from "../../../cuba/services";
import {Button, Col, Form, Modal, Row} from "antd";
import {FormComponentProps} from "antd/lib/form";
import MaskedInput from "antd-mask-input";
import Notification from "../../util/Notification/Notification";
import {withLocalizedForm} from "@cuba-platform/react";

type AddWhatsAppModalProps = {
  onAdd: () => void;
}

@observer
class AddTeWhatsAppModal extends React.Component<AddWhatsAppModalProps & WrappedComponentProps & FormComponentProps> {

  @observable isSHowModal: boolean = false;
  number: string = "";

  open() {
    this.isSHowModal = true;
  }

  clean(number: string): string {
    return number.replace(/[^\d;+]/g, '');
  }


  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields();
    restServices.userService.setWhatsAppForCurrentUser(this.number)
      .then(() => runInAction(() => {
        if (this.props.onAdd) {
          this.props.onAdd();
        }
        this.isSHowModal = false;
      }))
      .catch(() => Notification.error({
        message:
          this.props.intl.formatMessage({
            id: "whatsApp.error"
          })
      }))
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    return <>
      <Button type="link" onClick={() => this.open()}>
        <FormattedMessage id="myProfile.telegram.add"/>
      </Button>
      <Modal centered visible={this.isSHowModal} onCancel={() => runInAction(() => this.isSHowModal = false)}
             width="800px" footer={null}>
        <h1><FormattedMessage id="whatsApp.adding"/></h1>
        <Form onSubmit={this.handleSubmit}>
          <Row gutter={15}>
            <Col xs={20}>
              <Form.Item>
                {getFieldDecorator('number', {
                  rules: [{
                    required: true,
                    message: this.props.intl.formatMessage({id: "whatsApp.validation"}),
                    validator: (rule, value, callback) => {
                      if (this.clean(value).length !== 12) {
                        return callback('Wrong number format')
                      }
                    }
                  }]
                })(
                  <MaskedInput
                    placeholder={"WhatsApp"}
                    mask={"+1 (111) 111 11 11"}
                    onChange={(e) => this.number = this.clean(e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Form.Item>
                <Button type={"primary"} htmlType="submit">
                  <FormattedMessage id={"whatsApp.add"}/>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  }
}

export default injectIntl(
  withLocalizedForm<AddWhatsAppModalProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(AddTeWhatsAppModal)
);