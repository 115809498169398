import { StandardEntity } from "./sys$StandardEntity";
import { FileDescriptor } from "./sys$FileDescriptor";
import { PersonMedalRequest } from "./tsadv_PersonMedalRequest";
export class PersonMedalRequestAttachment extends StandardEntity {
  static NAME = "tsadv_PersonMedalRequestAttachment";
  file?: FileDescriptor | null;
  comment?: string | null;
  date?: any | null;
  medalRequest?: PersonMedalRequest | null;
}
export type PersonMedalRequestAttachmentViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "person-medal-request-attachment-front-edit";
export type PersonMedalRequestAttachmentView<
  V extends PersonMedalRequestAttachmentViewName
> = V extends "_base"
  ? Pick<PersonMedalRequestAttachment, "id" | "comment" | "date">
  : V extends "_local"
  ? Pick<PersonMedalRequestAttachment, "id" | "comment" | "date">
  : V extends "person-medal-request-attachment-front-edit"
  ? Pick<
      PersonMedalRequestAttachment,
      | "id"
      | "version"
      | "createTs"
      | "createdBy"
      | "updateTs"
      | "updatedBy"
      | "deleteTs"
      | "deletedBy"
      | "comment"
      | "date"
      | "file"
      | "medalRequest"
    >
  : never;
