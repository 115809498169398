import * as React from "react";
import {inject, observer} from "mobx-react";
import {
  collection,
  getEnumCaption,
  getPropertyInfoNN,
  injectMainStore,
  MainStoreInjected
} from "@cuba-platform/react";
import {Activity} from "../../../cuba/entities/base/uactivity$Activity";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import {action, observable, reaction, runInAction} from "mobx";
import {NavLink, RouteComponentProps} from "react-router-dom";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {withRouter} from "react-router";
import {DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS, format, JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";
import {ActivityLinkMap} from "../../util/ActivityLinkMap";
import {link} from "../../util/util";
import {Checkbox, Col, DatePicker, Icon, Row, Table, Button, Modal} from "antd";
import Search from "antd/es/input/Search";
import {Condition, ConditionsGroup} from "@cuba-platform/rest/dist-node/filter";
import {Moment} from "moment";
import activityState from "./ActivityState";
import {SorterResult} from "antd/es/table";
import {restServices} from "../../../cuba/services";
import Notification from "../../util/Notification/Notification";

type Prop = { type: "tasks" | "notifications" };

@injectMainStore
@inject("rootStore")
@observer
class ActivityCards extends React.Component<Prop & WrappedComponentProps & RootStoreProp & RouteComponentProps & MainStoreInjected> {
  readonly DEFAULT_PAGE_SIZE = 50;

  @observable sortBy?: string = activityState.sortBy;
  @observable searchByName: string = "" || activityState.searchByName;
  @observable dateFrom: Moment | null = activityState.dateFrom;
  @observable dateTo: Moment | null = activityState.dateTo
  @observable isStatusInProgress: boolean = activityState.isStatusInProgress;
  @observable isStatusCompleted: boolean = activityState.isStatusCompleted;
  @observable pageIndex: number = activityState.pageIndex;

  defaultFilters: Array<Condition | ConditionsGroup> = [
    {
      property: "assignedUser.id",
      operator: "=",
      value: this.props.rootStore!.userInfo.id!
    },
    {
      property: "type.code",
      operator: (this.props.type === "tasks" ? "<>" : "="),
      value: "NOTIFICATION"
    }
  ]

  dataCollection = collection<Activity>(Activity.NAME, {
    view: "portal-activity",
    sort: "-updateTs",
    offset: activityState.pageIndex,
    limit: 50,
    filter: {
      conditions: this.defaultFilters
    },
    loadImmediately: false
  });

  language = this.props.rootStore!.userInfo.language as String;

  notificationHeaderDataIndex = "notificationHeader" + this.props.rootStore!.userInfo.locale!.charAt(0).toUpperCase() + this.props.rootStore!.userInfo.locale!.slice(1);

  selectedRecordsIds: string[] = [];

  showError = () => {
    Notification.error({
      message: this.props.intl.formatMessage({id: "activityCard.error"})
    })
  }

  @observable
  selectedRowKey: string | undefined;


  componentDidMount() {
    reaction(
      () => [this.searchByName, this.dateFrom, this.dateTo, this.isStatusCompleted, this.isStatusInProgress, this.sortBy, this.pageIndex],
      () => this.handleStateChangeChange(),
      {fireImmediately: true}
    )
  }

  componentWillUnmount() {
    this.saveState();
  }

  saveState() {
    activityState.sortBy = this.sortBy;
    activityState.searchByName = this.searchByName;
    activityState.dateFrom = this.dateFrom;
    activityState.dateTo = this.dateTo;
    activityState.isStatusCompleted = this.isStatusCompleted;
    activityState.isStatusInProgress = this.isStatusInProgress;
    activityState.pageIndex = this.pageIndex;
  }

  handleStateChangeChange() {
    let newFilters = [...this.defaultFilters];

    if (this.searchByName) {
      newFilters.push({property: this.notificationHeaderDataIndex, operator: "contains", value: this.searchByName})
    }

    if (this.dateFrom) {
      newFilters.push({property: "createTs", operator: ">=", value: this.dateFrom.format(JSON_DATE_TIME_FORMAT)})
    }

    if (this.dateTo) {
      newFilters.push({property: "createTs", operator: "<=", value: this.dateTo.format(JSON_DATE_TIME_FORMAT)})
    }

    let inStatus = []

    if (this.isStatusCompleted) {
      inStatus.push("done")
    }

    if (this.isStatusInProgress) {
      inStatus.push("active")
    }

    if (inStatus.length > 0) {
      newFilters.push({property: "status", operator: "in", value: inStatus})
    }

    this.dataCollection.offset = (this.pageIndex - 1) * this.DEFAULT_PAGE_SIZE;
    this.dataCollection.filter!.conditions = newFilters;
    this.dataCollection.sort = this.sortBy;

    this.dataCollection.load();
  }

  @observable
  modalIsVisible: boolean = false;

  @action
  setModalIsVisible = (bool: boolean) => {
    this.modalIsVisible = bool;
  }

  render() {
    const type = this.props.type;
    const pageName = this.props.intl.formatMessage({id: type});
    const notifications = this.props.intl.formatMessage({id: "notifications"});

    return (
      <Page pageName={pageName}>
        <Section size="large" visible={true}>
          <Modal centered
                 visible={this.modalIsVisible}
                 onCancel={() => {
                   this.setModalIsVisible(false);
                 }
                 }
                 onOk={() => {
                   restServices.notificationsService.closeAllNotificationsForCurrentUser()
                     .then(
                       () => this.dataCollection.load(),
                       () => this.showError()
                     );
                   this.setModalIsVisible(false);
                 }
                 }
          >
            {this.props.intl.formatMessage({id: "activityCard.selectAllNotifications"})}
          </Modal>
          {pageName === notifications &&
            <div style={{display: "flex"}}>
              <Button type="primary"
                      style={{margin: "10px"}}
                      onClick={() => restServices.notificationsService.closeNotificationsForCurrentUserById({
                        notificationsDto: {
                          notificationIds: this.selectedRecordsIds
                        }
                      }).then(
                        () => this.dataCollection.load(),
                        () => this.showError()
                      )
                      }
              >
                {this.props.intl.formatMessage({id: "activityCard.readSelectedItems"})}
              </Button>
              <Button type="primary"
                      style={{margin: "10px"}}
                      onClick={() => this.setModalIsVisible(true)}
              >
                {this.props.intl.formatMessage({id: "activityCard.readAllItems"})}
              </Button>
            </div>
          }
          <Table
            rowSelection={pageName === notifications ?
              {
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows) => {
                  this.selectedRecordsIds = selectedRows.map((row: any) => row.id);
                }
              } : undefined
            }
            loading={this.dataCollection.status === "LOADING"}
            dataSource={this.dataCollection.items}
            rowKey={'id'}
            onChange={(pagination, filters, sorter) => {
              this.handleSortChange(sorter);
            }}
            pagination={{
              current: this.pageIndex,
              total: this.dataCollection.count,
              defaultPageSize: this.DEFAULT_PAGE_SIZE,
              onChange: (page) => {
                this.handlePageChange(page);
              }
            }}
            columns={[
              {
                dataIndex: "createTs",
                key: "createTs",
                title: this.props.intl.messages["activityCard.createTs"],
                sorter: true,
                sortOrder: this.sortBy === "createTs" ? "ascend" : this.sortBy === "-createTs" ? "descend" : undefined,
                filterIcon: () => (
                  <Icon type="filter" theme="filled"
                        style={{color: this.dateTo || this.dateFrom ? '#1890ff' : undefined}}/>
                ),
                filterDropdown: () => {
                  return <>
                    <div style={{padding: 8}}>
                      <Row>
                        <Col>
                          <Row align={"middle"} type={"flex"}>
                            <Col xs={6}>{this.props.intl.messages["activityCard.date.from"]}</Col>
                            <Col xs={18}>
                              <DatePicker onChange={value => runInAction(() => this.dateFrom = value)} value={this.dateFrom}/>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row align={"middle"} type={"flex"}>
                            <Col xs={6}>{this.props.intl.messages["activityCard.date.to"]}</Col>
                            <Col xs={18}>
                              <DatePicker onChange={value => runInAction(() => this.dateTo = value)} value={this.dateTo}/>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </>
                },
                render: (text, record) => {
                  return format(new Date(text), DEFAULT_DATE_TIME_PATTERN_WITHOUT_SECONDS)
                }
              },
              {
                dataIndex: this.notificationHeaderDataIndex,
                key: this.notificationHeaderDataIndex,
                sortOrder: this.sortBy === this.notificationHeaderDataIndex ? "ascend" : this.sortBy === "-" + this.notificationHeaderDataIndex ? "descend" : undefined,
                filterDropdown: () => {
                  return <>
                    <div style={{padding: 8}}>
                      <Search allowClear defaultValue={this.searchByName}
                              onSearch={value => runInAction(() => this.searchByName = value)}/>
                    </div>
                  </>
                },
                filterIcon: () => (
                  <Icon type="filter" theme="filled"
                        style={{color: this.searchByName && this.searchByName.length !== 0 ? '#1890ff' : undefined}}/>
                ),
                title: this.props.intl.messages["activityCard.notificationHeader"],
                sorter: true,
                render: (text, record) => {
                  if (record.type!.code !== 'NOTIFICATION') {
                    const entityName = record!.type!.windowProperty!.entityName!;
                    const activityLink = ActivityLinkMap[entityName] ? "/" + ActivityLinkMap[entityName] : undefined;
                    return <NavLink
                      to={`..${(link(entityName) === "/assignmentSalaryRequest") ? link("wccf") :
                        (activityLink || link(entityName))}/${record!.referenceId}`}>{text}</NavLink>;

                  } else {
                    return <NavLink
                      to={`${record!.id}`}>{text}</NavLink>;
                  }
                }
              },
              {
                dataIndex: "status",
                key: "status",
                sortOrder: this.sortBy === "status" ? "ascend" : this.sortBy === "-status" ? "descend" : undefined,
                title: this.props.intl.messages["activityCard.status"],
                sorter: true,
                filterIcon: () => (
                  <Icon type="filter" theme="filled"
                        style={{color: this.isStatusCompleted || this.isStatusInProgress ? '#1890ff' : undefined}}/>
                ),
                filterDropdown: () => {
                  return <>
                    <div style={{padding: 8}}>
                      <Row>
                        <Col>
                          <Checkbox defaultChecked={this.isStatusInProgress}
                                    onChange={e => runInAction(() => this.isStatusInProgress = e.target.checked)}>{this.props.intl.messages["activityCard.status.active"]}</Checkbox>
                        </Col>
                        <Col>
                          <Checkbox defaultChecked={this.isStatusCompleted}
                                    onChange={e => runInAction(() => this.isStatusCompleted = e.target.checked)}>{this.props.intl.messages["activityCard.status.done"]}</Checkbox>
                        </Col>
                      </Row>
                    </div>
                  </>
                },
                render: (text, record) => getEnumCaption(record.status, getPropertyInfoNN("status", Activity.NAME,
                  this.props.mainStore!.metadata!), this.props.mainStore!.enums!)
              }
            ]}
          >
          </Table>
        </Section>
      </Page>
    );
  }

  @action handlePageChange(page: number) {
    this.pageIndex = page;
  }

  @action handleSortChange<T>(sorter: SorterResult<T>) {
    if (sorter.field) {
      if (!sorter.order) {
        this.sortBy = undefined;
        return;
      }
      let prefix = sorter.order === "ascend" ? "" : "-";
      this.sortBy = prefix + sorter.field;
    }
  }
}

export default withRouter(injectIntl(ActivityCards));