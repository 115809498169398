import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link, RouteComponentProps} from "react-router-dom";
import {collection, getCubaREST, injectMainStore, MainStoreInjected, Msg} from "@cuba-platform/react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {withRouter} from "react-router";
import {SuccessionPlanningRequestManagement} from "./SuccessionPlanningRequestManagement";
import {observable} from "mobx";
import {PersonExperience} from "../../../cuba/entities/base/tsadv$PersonExperience";
import {SuccessionPlanningRequest} from "../../../cuba/entities/base/tsadv_SuccessionPlanningRequest";
import {RootStoreProp} from "../../store";
import moment from "moment";
import {Button as AntBtn, Icon, Table} from "antd";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";


export type RequiredPersonGroupProps = {
  positionGroupId: string,
  isKeyPosition:boolean,
}

@injectMainStore
@inject("rootStore")
@observer
class SuccessionPlanningRequestListComponent extends React.Component<RootStoreProp&RequiredPersonGroupProps & MainStoreInjected & WrappedComponentProps & RouteComponentProps> {
  dataCollection = collection<SuccessionPlanningRequest>(SuccessionPlanningRequest.NAME, {
    view: "successionPlanningRequest-edit",
    sort: "requestNumber",
    filter: {
      conditions: [{
        property: 'personGroup.id',
        operator: '=',
        value: this.props.rootStore!.userInfo!.personGroupId!
      },
        {
          property: 'positionGroup.id',
          operator: '=',
          value: this.props.positionGroupId
        }]
    }
  });
   fields = [
    "requestNumber",

    "requestDate",

    "status",

    "positionGroup"
  ];

  @observable selectedRowKey: string | undefined;

  render() {
    const buttons = [
        <Button buttonType={ButtonType.PRIMARY}
                onClick={()=>{this.props.history.push({
                  pathname: SuccessionPlanningRequestManagement.PATH+"/" + SuccessionPlanningRequestManagement.NEW_SUBPATH,
                  state: {isKeyPosition:this.props.isKeyPosition, positionGroupId:this.props.positionGroupId}
                })}}
                style={{margin: "0 12px 12px 0"}}>
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
    ];
    return (
      <Page pageName={<FormattedMessage id={"allKeyPositionRequests"}/>}>
        <Section size="large">
          <div>
            <div style={{marginBottom: 16}}>
              {buttons}
            </div>
      <Table dataSource={this.dataCollection.items}
             loading={this.dataCollection.status==="LOADING"}
             pagination={{pageSize:30}}
             columns={[{
               title:this.props.intl.formatMessage({id:"requestNumber"}),
               dataIndex:"requestNumber",
               render: (text, record: SuccessionPlanningRequest) => <Link
                 to={SuccessionPlanningRequestManagement.PATH + "/" + record.id}
               >{text}</Link>,
               sorter:(a, b) => a.requestNumber - b.requestNumber
             },
               {
                 title:this.props.intl.formatMessage({id:"requestDate"}),
                 dataIndex:"requestDate",
                 render:(text,record) => moment(record.requestDate!).format('L'),
                 sorter:(a, b) => {
                   const aDate = moment(a.requestDate!)
                   const bDate = moment(b.requestDate!)
                   return bDate.diff(aDate)
                 }
               },{
                 title:<Msg entityName={this.dataCollection.entityName} propertyName={"personGroup"}/>,
                 dataIndex:"personGroup",
                 render:(text, record) => record.personGroup?record.personGroup!['_instanceName']:""
               },{
                 title:this.props.intl.formatMessage({id:"key.position"}),
                 dataIndex:"positionGroup",
                 render:(text, record) => record.positionGroup?record.positionGroup!['_instanceName']:""
               },
               {
                 title:this.props.intl.formatMessage({id:"reservistOrganizationGroup"}),
                 dataIndex:"positionGroup.organizationGroup",
                 render:(text, record) => record.positionGroup&&record.positionGroup.organizationGroup?record.positionGroup.organizationGroup!['_instanceName']:""
               },
               {
                 title:this.props.intl.formatMessage({id:"successionPlanning.action"}),
                 dataIndex:"action",
                 render:(text, record) => record.action&&record.action==="INCLUDE"?this.props.intl.formatMessage({id:"successionPlanning.action.include"}):record.action&&record.action==="EXCLUDE"?this.props.intl.formatMessage({id:"successionPlanning.action.exclude"}):""
               },
               {
                 title:this.props.intl.formatMessage({id:"status"}),
                 dataIndex:"status",
                 render:(text, record) => record.status?record.status!['_instanceName']:"",
                 filters:[{text:<FormattedMessage id={"status.Draft"} />,value:this.props.intl.formatMessage({id:"status.Draft"})},
                   {text:<FormattedMessage id={"status.OnApproval"} />,value:this.props.intl.formatMessage({id:"status.OnApproval"})},
                   {text:<FormattedMessage id={"status.Approved"} />,value:this.props.intl.formatMessage({id:"status.Approved"})},
                   {text:<FormattedMessage id={"status.ToBeRevised"} />,value:this.props.intl.formatMessage({id:"status.ToBeRevised"})},
                   {text:<FormattedMessage id={"status.Rejected"} />,value:this.props.intl.formatMessage({id:"status.Rejected"})}],

                 onFilter:(value, record) => record.status!["_instanceName"].startsWith(value)
               },{
                 title:"",
                 render:(text, record) => {
                   if (record.status!.code === "DRAFT") {
                     return <AntBtn onClick={() => {
                       this.dataCollection.delete({id:record.id})
                     }
                     }><Icon type='delete'/></AntBtn>
                   }
                   return <AntBtn disabled={true}><Icon type='delete'/></AntBtn>
                 }
               }]}
      />
          </div>
        </Section>
      </Page>
    );
  }

  openEditRequest = () => {
    this.getRequestId()
      .then(value =>this.props.history!.push(SuccessionPlanningRequestManagement.PATH + '/' + value));
  }
  getRequestId = ()=> {
    return getCubaREST()!.searchEntities<PersonExperience>(PersonExperience.NAME, {
      conditions: [{
        property: 'id',
        operator: '=',
        value: this.selectedRowKey!
      }]
    }, {
      view: 'portal.my-profile'
    }).then(value => value[0].id)
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

}

const PersonExperienceRequestList  = injectIntl(withRouter(SuccessionPlanningRequestListComponent));

export default PersonExperienceRequestList;