import * as React from "react";
import './style.css'
import { inject, observer } from "mobx-react";
import { collection, getCubaREST, injectMainStore, withLocalizedForm } from "@cuba-platform/react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import Page from "../../../hoc/PageContentHoc";
import { Card, Form, Select, Tabs } from "antd";
import BudgetTableComponent, { TableDataParent } from "./BudgetTableComponent";
import AttachmentsComponent, { AttachmentFileData } from "./AttachmentsComponent";
import { restServices } from "../../../../cuba/services";
import ButtonGroup from "antd/es/button/button-group";
import { DicCurrency } from "../../../../cuba/entities/base/base$DicCurrency";
import { action, observable, reaction } from "mobx";
import { ConditionsGroup, SerializedEntity } from "@cuba-platform/rest";
import { LearningBudget } from "../../../../cuba/entities/base/tsadv_LearningBudget";
import { FileDescriptor } from "../../../../cuba/entities/base/sys$FileDescriptor";
import AbstractAgreedBprocEdit from "../../Bproc/abstract/AbstractAgreedBprocEdit";
import { instanceStore } from "../../../util/InstanceStore";
import LoadingPage from "../../LoadingPage";
import { wrapToSerialized } from "../utils";
import { formatDate } from "../../../util/Date/Date";
import ExportBudgetDetailReport from "../ExportBudgetDetailReport";

type ApprovmentScreenProps = {
  entityId: string
  currency?: DicCurrency
}

@inject("rootStore")
@injectMainStore
@observer

class ApprovmentScreen extends AbstractAgreedBprocEdit<LearningBudget, ApprovmentScreenProps> {
  dataInstance = instanceStore<LearningBudget>(LearningBudget.NAME, {
    view: 'learningBudget-approvment-front-view',
    loadImmediately: false
  })

  currencyStore = collection<DicCurrency>(DicCurrency.NAME, {
    loadImmediately: false,
    view: '_local',
  })
  messages = this.props.intl.messages
  processDefinitionKey = 'learningBudgetApprovalRequest'

  userLocale = this.props.mainStore ? this.props.mainStore.locale : 'en'
  @observable currentCurrency?: DicCurrency | null
  @observable budgetTableData?: TableDataParent[]
  @observable attachmentData?: AttachmentFileData[]

  bprocComment: string;

  fields: string[] = [
    'comment'
  ]

  @action
  setCurrency = (currency?: DicCurrency) => {
    if (!this.currentCurrency || !currency || this.currentCurrency.id !== currency.id) {
      this.currentCurrency = currency
      this.recalcCurrency()
    }
  }

  @action
  recalcCurrency = () => {
    if (!this.budgetTableData) return;

    this.budgetTableData.forEach(budgetData => {
      if (this.currentCurrency && this.dataInstance.item) {
        for (const budgetDataFieldName in budgetData) {
          const budgetDataFieldVal = budgetData[budgetDataFieldName]
          this.recalcFields(budgetDataFieldVal, budgetDataFieldName, budgetData)
        }
        if (budgetData.children && budgetData.children.length > 0) {
          budgetData.children.forEach(child => {
            for (const childFieldname in child) {
              const childFieldVal = child[childFieldname]
              this.recalcFields(childFieldVal, childFieldname, child); 
            }
          })
            
        }
      }
    })
    this.budgetTableData = [...this.budgetTableData]
  }

  updateAndCommit = (): Promise<void> => {
    this.bprocComment = this.props.form.getFieldValue("bproc-comment")
    this.updateItemValue();
    return new Promise(resolve => {
      this.dataInstance.commit().then(val => {
        this.props.form.setFieldsValue({
          'bproc-comment': this.bprocComment
        })
        resolve()
      })
    })
  };

  recalcFields = (fieldValue: any, fieldName: string, dataArr: any) => {
    if (typeof fieldValue === 'number' && fieldName !== 'employeeCount') {
      if (this.currentCurrency && this.dataInstance.item){
        if (this.currentCurrency.code === 'USD') {
          dataArr[fieldName] = fieldValue / (this.dataInstance.item.currencyExchangeRate || 1)
        } else {
          dataArr[fieldName] = fieldValue * (this.dataInstance.item.currencyExchangeRate || 1)
        }
      }
    }
  }

  parseAttachmentsData = async (budget?: LearningBudget): Promise<AttachmentFileData[]> => {
    if (!budget || !budget.attachments) {
      return []
    }
    const result: AttachmentFileData[] = []

    for (const attachment of budget.attachments) {
      const createdBy = wrapToSerialized(attachment.file).createdBy
      const author = createdBy ? (await restServices.userHelperService.getUsersFullName([createdBy]))[0] : ''
      result.push({
        file: attachment.file!,
        comment: attachment.comment || '',
        author: author,
        date: formatDate(wrapToSerialized(attachment.file).createDate, 'DD-MM-YYYY')
      })
    }
    return result
  }

  getLocalizedBudgetNameField = () => {
    switch (this.userLocale) {
      case 'ru':
        return 'nameRus'
      case 'kz':
        return 'nameKz'
      default:
        return 'nameEng'
    }
  }

  render() {
    if (this.dataInstance.status !== 'DONE' || this.currencyStore.status !== 'DONE' || !this.budgetTableData) {
      return <LoadingPage />
    }

    return (
      <Page pageName={this.props.intl.formatMessage({ id: "approvmentScreen" })}>
        <div style={{ margin: '1%' }}>
          <Tabs defaultActiveKey={'1'} style={{
            width: '100%',
            height: '100%'
          }}>
            <Tabs.TabPane tab={this.dataInstance.item ? this.dataInstance.item[this.getLocalizedBudgetNameField()] : ''}
              key="1">
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                rowGap: '1rem'
              }}>
                <h3>
                  {this.messages['learningBudgetApprovmentDescription']}
                </h3>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'end'
                }}>
                  <label htmlFor={'select-currency'} style={{ margin: '1%' }}>{this.messages['currency']}</label>
                  <Select
                    value={this.currentCurrency ? (this.currentCurrency as SerializedEntity<DicCurrency>)._instanceName : undefined}
                    style={{
                      width: '10rem'
                    }}
                    onSelect={(value: any) => this.setCurrency(this.currencyStore.items.find(cu => cu.id === value))}>
                    {
                      this.currencyStore.items.map(currency =>
                        <Select.Option key={currency.id}>
                          {currency._instanceName}
                        </Select.Option>)
                    }
                  </Select>
                </div>
                <BudgetTableComponent items={this.budgetTableData} />
                <AttachmentsComponent items={this.attachmentData} />
                {this.takCard()}
              </div>
            </Tabs.TabPane>
          </Tabs>
          <ButtonGroup
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'end'
            }}>
            <ExportBudgetDetailReport budgetId={this.dataInstance.item!.id} />
            <div id="outComeButtonsContainer">
              {this.getOutcomeBtns()}
            </div>
          </ButtonGroup>
        </div>
      </Page>
    )
  }

  componentDidMount() {
    reaction(() => this.dataInstance.status, (arg, r) => {
      if (arg === 'DONE') {
        this.currencyStore.filter = {
          conditions: [{
            group: 'OR',
            conditions: [
              {
                property: 'code',
                value: 'USD',
                operator: 'contains',
              },
              {
                property: 'code',
                value: this.dataInstance.item ? this.dataInstance.item.currency!.code || 'USD' : 'USD',
                operator: 'contains',
              }
            ]
          }]
        }
        this.currencyStore.load()
        this.currentCurrency = this.dataInstance.item!.currency
        r.dispose()
      }
    })
    super.componentDidMount();
    (async () => {
      this.setReactionDisposer();
      await this.loadData();
      await this.loadBpmProcessData();
      this.budgetTableData = await restServices.learningBudgetPortalService.loadBudgetApprovmentData(this.props.entityId)
      this.attachmentData = await this.parseAttachmentsData(this.dataInstance.item)
    })()
  }
}

export default injectIntl(
  withLocalizedForm<ApprovmentScreenProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(ApprovmentScreen))
);
