import {collection, getCubaREST, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {inject, observer} from "mobx-react";
import * as React from "react";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../../store";
import {Link} from "react-router-dom";
import {TalentPoolProgramComponent} from "../TalentPoolProgram";
import {action, observable} from "mobx";
import {SuccessionPlanning} from "../../../../cuba/entities/base/tsadv$SuccessionPlanning";
import Section from "../../../hoc/Section";
import {Table, Breadcrumb, Layout, Modal, Button, Form, Input, Row, Col, Icon, Popover, Spin} from "antd";
import {SerializedEntity} from "@cuba-platform/rest";
import {FC} from "react";
import './KeyPosition.css'
import {restServices} from "../../../../cuba/services";
import PopoverS from "../../../components/Popover"
import {downloadFile} from "../../../util/util";
import {withRouter} from 'react-router-dom';
import {RouteComponentProps} from "react-router";
import {SuccessorRequestManagement} from "../../SuccessorRequest/SuccessorRequestManagement";
import Highlighter from 'react-highlight-words';
import {FilterDropdownProps} from "antd/es/table/interface";
import {DicCompany} from "../../../../cuba/entities/base/base_DicCompany";

const {Content} = Layout
export type RequiredPersonGroupProps = {
  personGroupId?: string
}
type Employee = {
  person_group_id: string,
  full_name: string
}
type KeyPositionList = {
  company_code: string,
  company_id: string,
  derjateli: Employee[],
  manager: Employee[],
  organization_name: string,
  position_group_id: string,
  position_name: string,
  reservisty: Employee[],
  holdersPOPOVER: string,
  successorsPOPOVER: string,
  managersPOPOVER: string
}

type Detail = {
  competencies: Competence[],
  current_employee: Employee[],
  job_description: File[],
  manager: Employee[],
  organization_name: string,
  position_group_id: string,
  position_name: string
}

type Competence = {
  competence_name: string,
  competence_type: string,
  required_level: string,
  scale_name: string,
}

type File = {
  file_id: string,
  file_name: string
}
interface RootObject {
  competence_name: string,
  competence_type: string,
  required_level: string,
  scale_name: string,
}
interface RootObjectWithKey extends RootObject {
  key: string;
}
type CompetenceTableRow = {
  key: string,
  competence_type: string,
  competence_type_id: string,
  children: (RootObjectWithKey & { key: string })[]
};

@injectMainStore
@inject("rootStore")
@observer
export class KeyPositionListComponent extends React.Component<RouteComponentProps & RequiredPersonGroupProps & MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  companyDC = collection<DicCompany>(DicCompany.NAME,{
    filter:{
      conditions:[{
        property:"code",
        operator:"<>",
        value:"empty"
      }]
    },
      view:"_minimal"
  })

  @observable keyPositionList: Array<KeyPositionList>

  @observable loadingData: boolean = true

  @observable loadingModalData: boolean = true

  @observable visible: boolean = false

  @observable loading: boolean = false

  @observable dataList: Array<SerializedEntity<any>> = []

  @observable searchText: string | RegExp = ''

  @observable searchTextHolder: string | RegExp = ''

  @observable searchTextManager: string | RegExp = ''

  @observable searchTextSuccessor: string | RegExp = ''

  @observable modalData: Detail

  @observable modalDataTable: CompetenceTableRow[] = []


  @observable searchedColumn: string = ''

  RESERVIST: string = "RESERVIST"

  MANAGER: string = "MANAGER"

  HOLDER: string = "HOLDER"

  private searchInput: Input | null


  @action
  showModal = (positionGroupId: any) => {
    this.visible = true;
    restServices.learningService.getSuccessionKeyPositionInfo({
      lang: this.props.rootStore!.userInfo!.locale!,
      positionGroupId: positionGroupId,
    }).then(r => JSON.parse(r[0] && r[0]!.value)).then(detail => {
      this.loadingModalData = false
      this.modalData =  detail[0]
      if(detail[0] && detail[0]!.competencies! && detail[0]!.competencies!.length>0){
        this.modalDataTable = this.groupDataByCompetenceType(detail[0] && detail[0]!.competencies!)
      }
    })
  };

  content: FC<Array<string>> = (props, value: string) => {
    return (
      <div style={{padding: "14px"}}>
        <div className={"hover-list"} style={{display: "flex", justifyContent: "flex-start"}}>
          <Icon type="user"/>
          <div style={{marginLeft: "10px", marginBottom: "20px"}}> {
            (value === this.RESERVIST ? <FormattedMessage id={"keyPosition.table.popover.reservists"}/>
              : value === this.MANAGER ? <FormattedMessage id={"keyPosition.table.popover.director"}/>
                : value === this.HOLDER ? <FormattedMessage id={"keyPosition.table.popover.holder"}/>
                  : "")
          } </div>
        </div>
        {
          props
            ? props.map((item, index) => <div
              style={{padding: "12px 5px", background: "#F3F3F3", borderRadius: "3px", margin: "8px 0"}}>{index + 1}) {
              item
            }
            </div>)
            : ""
        }
      </div>
    )
  }

  componentDidMount() {
    restServices.learningService.getSuccessionKeyPosition({
      lang: this.props.rootStore!.userInfo!.locale!,
      company: "",
      position: "",
      organization: "",
      holder: "",
      manager: "",
      reservist: ""
    }).then(r => JSON.parse(r[0] && r[0]!.value!)).then(list => {
      this.loadingData = false
      this.keyPositionList = list.map((item: KeyPositionList) => (
        {
          ...item,
          key: this.uuid(),
          holdersPOPOVER: item.derjateli !== null ? Object.values(item.derjateli.map(fil => fil!.full_name!)) : "",
          successorsPOPOVER: item.reservisty !== null ? Object.values(item.reservisty.map(fil => fil!.full_name!)) : "",
          managersPOPOVER: item.manager !== null ? Object.values(item.manager.map(fil => fil!.full_name!)) : "",
        }))
    })


    getCubaREST()!.searchEntities<SuccessionPlanning>(SuccessionPlanning.NAME, {
      conditions: [{
        property: "positionType",
        operator: "=",
        value: "INCLUDE"
      }]
    }, {
      view: "_minimal",
    }).then(value => {
      this.dataList = value.map(i => i.id)
    })
  }

  uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  @action
  handleCancel = () => {
    this.visible = false
    for (let member in this.modalData) delete this.modalData[member];
    this.loadingModalData = true
    this.modalDataTable = []
  }


  groupDataByCompetenceType = (arr: RootObject[]): CompetenceTableRow[] => {
    const map = arr.reduce((acc, cur, index) => {
      if (cur.competence_type && cur.competence_type) {
        if (!acc.has(cur.competence_type)) {
          acc.set(cur.competence_type, {
            competence_type_id: cur.competence_type,
            competence_type: cur.competence_type,
            key: this.uuid(),
            children: []
          });
        }
        acc.get(cur.competence_type)!.children.push({
          ...cur,
          competence_type: "",
          key: `${this.uuid() || index}-${this.uuid() || index}`
        });
      }
      return acc
    }, new Map() as Map<string, CompetenceTableRow>);

    return Array.from(map.values())
  };

  getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: (props: FilterDropdownProps) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={this.props.intl.formatMessage({id:"keyPosition.filterTable.search"})}
          value={props.selectedKeys![0]!}
          onChange={(e) => props.setSelectedKeys!(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(props.selectedKeys![0]!, props.confirm, dataIndex)}
          style={{width: 188, marginBottom: 8, display: 'block'}}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(props.selectedKeys![0]!, props.confirm, dataIndex)}
          icon="search"
          size="small"
          style={{width: 90, marginRight: 8}}
        >
          <FormattedMessage id={"keyPosition.filterTable.search"} />
        </Button>
        <Button onClick={() => this.handleReset(props.clearFilters, dataIndex)} size="small" style={{width: 90}}>
          <FormattedMessage id={"keyPosition.filterTable.reset"} />
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
    ),

    onFilter: (value: string, record: KeyPositionList) =>
      record && record[dataIndex] && record![dataIndex]!
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())


    ,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput!.select());
      }
    },
    render: (text: any, record: KeyPositionList) => {
      let holdersArr: any
      let successorsArr: any
      let managersArr: any
      if (dataIndex === "holdersPOPOVER" && Object.prototype.toString.call(text) === '[object Array]') {
        holdersArr = text
        text = text.filter((str: string) => str.toLowerCase().includes(typeof this.searchTextHolder === "string" ? this.searchTextHolder : ""))[0]
      } else if (dataIndex === "successorsPOPOVER" && Object.prototype.toString.call(text) === '[object Array]') {
        successorsArr = text
        text = text.filter((str: string) => str && str.toLowerCase().includes(typeof this.searchTextSuccessor === "string" ? this.searchTextSuccessor : ""))[0]
      } else if (dataIndex === "managersPOPOVER" && Object.prototype.toString.call(text) === '[object Array]') {
        managersArr = text
        text = text.filter((str: string) => str.toLowerCase().includes(typeof this.searchTextManager === "string" ? this.searchTextManager : ""))[0]
      } else {
        text = text.toString()
      }
      const popList = (list: Array<string>, title: string) => {
        return list && list.length > 0 ?
          list.length === 1 ?
            <span style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textDecoration: "underline",
              color: "#005487",
              fontSize: "12px",
              wordBreak: "break-word"
            }}>
              {text}
            </span> :
            <Popover placement={"bottomLeft"} content={this.content(list, title)}>
              <Button
                style={{background: "transparent", border: "none", width: "100%", padding: "0", textAlign: "start"}}>
                              <span style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                textDecoration: "underline",
                                color: "#005487",
                                fontSize: "12px",
                                wordBreak: "break-word"
                              }}>
                               {text + '+' + (list.length - 1)}
                              </span>
              </Button>
            </Popover> : <></>
      }
      const popListHighliter = (list: Array<string>, title: string) => {
        return list && list.length > 0 ?
          list.length === 1 ?
            <span style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              textDecoration: "underline",
              color: "#005487",
              fontSize: "12px",
              wordBreak: "break-word"
            }}>
              {text}
            </span> :
            <Popover placement={"bottomLeft"} content={this.content(list, title)}>
              <Button
                style={{background: "transparent", border: "none", width: "100%", padding: "0", textAlign: "start"}}>
                              <span style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                textDecoration: "underline",
                                color: "#005487",
                                fontSize: "12px",
                                wordBreak: "break-word"
                              }}>
                                 <Highlighter
                                   highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                                   searchWords={[this.searchText]}
                                   autoEscape
                                   textToHighlight={text}
                                 />
                              </span>
              </Button>
            </Popover> : <></>
      }
      const positionModal = (hasHighliter: boolean = false) => {
        return <div style={{width: "300px"}}>
          {
            <Button style={{background: "transparent", border: "none"}} onClick={() => {
              this.showModal(record && record.position_group_id)
            }}>
                            <span style={{
                              whiteSpace: "normal",
                              wordWrap: "break-word", textDecoration: "underline", color: "#005487"
                            }}>
                                {hasHighliter ? <Highlighter
                                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                                    searchWords={[this.searchText]}
                                    autoEscape
                                    textToHighlight={text}
                                  /> :
                                  text}
                            </span>
            </Button>
          }
        </div>
      }
      return <>
        {this.searchedColumn === dataIndex ? (
          dataIndex === "holdersPOPOVER" ? popListHighliter(holdersArr, this.HOLDER)
            : dataIndex === "successorsPOPOVER" ? popListHighliter(successorsArr, this.RESERVIST)
              : dataIndex === "managersPOPOVER" ? popListHighliter(managersArr, this.MANAGER)
                : dataIndex === "position_name" ? positionModal(true) :
                  <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.searchText]}
                    autoEscape
                    textToHighlight={text}
                  />
        ) : dataIndex === "holdersPOPOVER"
          ? popList(holdersArr, this.HOLDER)
          : dataIndex === "successorsPOPOVER"
            ? popList(successorsArr, this.RESERVIST)
            : dataIndex === "managersPOPOVER"
              ? popList(managersArr, this.MANAGER)
              : dataIndex === "position_name"
                ? positionModal()
                : text}
      </>
    }

  });
  handleSearch = (selectedKeys: any, confirm: any, dataIndex: string) => {
    confirm();
    if (dataIndex === "holdersPOPOVER") {
      this.searchTextHolder = selectedKeys
    } else if (dataIndex === "managersPOPOVER") {
      this.searchTextManager = selectedKeys
    } else if (dataIndex === "successorsPOPOVER") {
      this.searchTextSuccessor = selectedKeys
    }
    this.searchText = selectedKeys
    this.searchedColumn = dataIndex

  };

  @action
  handleReset = (clearFilters: any, dataIndex: string) => {
    clearFilters();
    if (dataIndex === "holdersPOPOVER") {
      this.searchTextHolder = ''
    } else if (dataIndex === "managersPOPOVER") {
      this.searchTextManager = ''
    } else if (dataIndex === "successorsPOPOVER") {
      this.searchTextSuccessor = ''
    }
    this.searchText = ''
  };


  findManager = (Persons:Employee[]):string =>{
    let personName:string = ''
    if(Persons){
       personName = (Persons.length === 1 ? Persons[0]!.full_name!
        : (Persons[0]! ? `${Persons[0]!.full_name!}+${Persons.length - 1}` : ""))
    }
    return personName
  }

  render() {
    const columns = [
      {
        title: this.props.intl.formatMessage({id: "keyPosition.table.company.code"}),
        dataIndex: "company_code",
        key: "company_code",
        filters:this.companyDC && this.companyDC.items && this.companyDC.items.map(code=>({text:code._instanceName, value:code._instanceName})),
        onFilter:(value:string, record:KeyPositionList) =>  record.company_code!.startsWith(value),
        sorter: (a: KeyPositionList, b: KeyPositionList) => a.company_code.localeCompare(b.company_code),
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.table.keyPosition"}),
        dataIndex: "position_name",
        key: "position_name",
        sorter: (a: KeyPositionList, b: KeyPositionList) => a.position_name.localeCompare(b.position_name),
        ...this.getColumnSearchProps('position_name')
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.table.organization"}),
        dataIndex: "organization_name",
        key: "organization_name",
        sorter: (a: KeyPositionList, b: KeyPositionList) => a.organization_name.localeCompare(b.organization_name),
        ...this.getColumnSearchProps('organization_name')
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.table.holder"}),
        dataIndex: "holdersPOPOVER",
        key: "holders",
        ...this.getColumnSearchProps('holdersPOPOVER')
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.table.director"}),
        key: "director",
        dataIndex: "managersPOPOVER",
        ...this.getColumnSearchProps('managersPOPOVER')
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.table.reservists"}),
        key: "reservists",
        dataIndex: "successorsPOPOVER",
        ...this.getColumnSearchProps('successorsPOPOVER')
      }
    ]
    return (
      <Layout>
        <Section>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={TalentPoolProgramComponent.PATH}>
                <div style={{color: "#005487", textDecoration: "underline", display: "inline-block"}}>
                  <FormattedMessage id={"keyPosition.title.path.parent"}/>
                </div>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <div style={{color: "#000", fontWeight:"bold",display: "inline-block"}}>
                <FormattedMessage id={"keyPosition.title.path"}/>
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Content style={{marginTop:"30px"}}>
            <div style={{marginBottom: "12px"}}>
              <FormattedMessage id={"keyPosition.table.title"}/>
            </div>
            <Table dataSource={this.keyPositionList}
                   size="small"
                   bordered={true}
                   rowKey={this.uuid}
                   loading={this.loadingData}
                   className={"keyPosition-table"}
                   columns={columns}
                   pagination={{
                     defaultPageSize:20
                   }}
            />
            {this.renderModalPage()}
          </Content>
        </Section>
      </Layout>
    );
  }

  renderModalPage = () =>{
    const modalColumns = [
      {
        title: this.props.intl.formatMessage({id: "keyPosition.modalTable.table.type"}),
        dataIndex: "competence_type",
        key: "competence_type",
        render:(text:any,record:any)=>{
          return  record.hasOwnProperty("competence_type")? record!.competence_type!:""
        }
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.modalTable.table.nameing"}),
        dataIndex: "competence_name",
        key: "nameing"
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.modalTable.table.scale"}),
        dataIndex: "scale_name",
        key: "scale"
      },
      {
        title: this.props.intl.formatMessage({id: "keyPosition.modalTable.table.level"}),
        dataIndex: "required_level",
        key: "level"
      }
    ]
    return <Modal
      className={"keyPosition-modal--reservist"}
      visible={this.visible}
      title={this.props.intl.formatMessage({id: "keyPosition.modalTable.title"})}
      onCancel={this.handleCancel}
      footer={[
        <Button key="back" onClick={this.handleCancel}>
          <FormattedMessage id={"keyPosition.modalTable.cancel"}/>
        </Button>,

        <Button key="submit" type="primary" loading={this.loading} onClick={() => {
          this.props.history.push({
            pathname: SuccessorRequestManagement.PATH + "/" + SuccessorRequestManagement.NEW_SUBPATH,
            state: {positionGroupId: this.modalData.position_group_id}
          })
        }}>
          <FormattedMessage id={"keyPosition.modalTable.submit"}/>
        </Button>,
      ]}
    >
      <Spin spinning={this.loadingModalData}>
        <div>
          <Form layout="vertical">
            <Row>
              <Col md={24} sm={24} lg={12} style={{padding: "0 2.5% 0 0"}}>
                <Form.Item
                  label={<FormattedMessage id={"keyPosition.table.keyPosition"}/>}>
                  <Input
                    value={this.modalData && this.modalData!.position_name!}
                    disabled/>
                </Form.Item>
              </Col>
              <Col md={24} sm={24} lg={12} style={{padding: "0 0 0 2.5%"}}>
                <Form.Item
                  label={<FormattedMessage id={"keyPosition.modalTable.structure"}/>}>
                  <Input
                    value={this.modalData && this.modalData!.organization_name!}
                    disabled/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={24} sm={24} lg={12} style={{padding: "0 2.5% 0 0"}}>
                <Form.Item
                  label={<FormattedMessage id={"keyPosition.modalTable.currentWorker"}/>}>
                  {this.modalData && this.modalData!.current_employee && this.modalData!.current_employee!.length > 1 ?
                    <PopoverS
                      popoverTitle={<FormattedMessage id={"keyPosition.modalTable.currentEmployee"}/>}
                      popoverData={this.modalData && this.modalData!.current_employee!}
                      useInKeyPosition={true}>
                      <Input
                        className={"individual-input"}
                        value={this.findManager(this.modalData && this.modalData!.current_employee!)}
                        disabled/>
                    </PopoverS>
                    :
                    <Input
                      className={"individual-input"}
                      value={this.modalData && this.modalData!.current_employee! && this.modalData!.current_employee![0]!.full_name!.toString()!}
                      disabled/>
                  }

                </Form.Item>
              </Col>
              <Col md={24} sm={24} lg={12} style={{padding: "0 0 0 2.5%"}}>
                <Form.Item
                  label={<FormattedMessage id={"keyPosition.modalTable.ModalDirector"}/>}>
                  {this.modalData && this.modalData!.manager && this.modalData!.manager!.length > 1 ?
                    <PopoverS popoverTitle={<FormattedMessage id={"keyPosition.modalTable.ModalDirector"}/>}
                              popoverData={this.modalData && this.modalData!.manager!}
                              useInKeyPosition={true}>
                      <Input
                        className={"individual-input"}
                        value={this.findManager(this.modalData && this.modalData!.manager!)}
                        disabled/>
                    </PopoverS> : <Input
                      className={"individual-input"}
                      value={this.modalData && this.modalData!.manager! && this.modalData!.manager![0]!.full_name!.toString()!}
                      disabled/>}
                </Form.Item>
              </Col>
            </Row>

            <div>
              <div style={{display: "flex", alignItems: "center"}}><FormattedMessage
                id={"keyPosition.modalTable.manual"}/></div>
              <div>
                {this.modalData && this.modalData.job_description ?
                  <div style={{display: "flex", alignItems: "center", marginBottom: "8px"}}>
                                <span style={{padding: "0 10px 0 0 ", color: "#005487"}}>
                                  {this.modalData && this.modalData.job_description[0].file_name}
                                </span>
                    <Button
                      onClick={() => {
                        const extension = this.modalData && this.modalData.job_description[0].file_name.split('.').slice(-1)[0].toString()
                        return downloadFile(this.modalData && this.modalData.job_description[0].file_id, this.modalData && this.modalData.job_description[0].file_name,
                          extension
                          , "not found")
                      }}
                      type="primary">
                      <Icon type="download"/>
                    </Button>
                  </div>
                  : <div/>}
              </div>
            </div>

            <div style={{marginBottom: "12px"}}>
              <FormattedMessage id={"keyPosition.modalTable.tableTitle"}/>
            </div>
            <Table dataSource={this.modalDataTable}
                   size="default"
                   bordered={true}
                   rowKey={() => this.uuid()}
                   columns={modalColumns}
            />

          </Form>
        </div>
      </Spin>
    </Modal>
  }

}


const KeyPositionList = injectIntl(KeyPositionListComponent);

export default withRouter(KeyPositionList);