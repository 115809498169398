import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { FileDescriptor } from "./sys$FileDescriptor";
export class PersonOtherInfoRequest extends AbstractBprocRequest {
  static NAME = "tsadv_PersonOtherInfoRequest";
  personGroup?: PersonGroupExt | null;
  childUnder18WithoutFatherOrMother?: any | null;
  childUnder14WithoutFatherOrMother?: any | null;
  attachments?: FileDescriptor[] | null;
}
export type PersonOtherInfoRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personOtherInfoRequest.edit";
export type PersonOtherInfoRequestView<
  V extends PersonOtherInfoRequestViewName
> = V extends "_base"
  ? Pick<
      PersonOtherInfoRequest,
      | "id"
      | "requestNumber"
      | "requestDate"
      | "childUnder18WithoutFatherOrMother"
      | "childUnder14WithoutFatherOrMother"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      PersonOtherInfoRequest,
      | "id"
      | "childUnder18WithoutFatherOrMother"
      | "childUnder14WithoutFatherOrMother"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<PersonOtherInfoRequest, "id" | "requestNumber" | "requestDate">
  : V extends "personOtherInfoRequest.edit"
  ? Pick<
      PersonOtherInfoRequest,
      | "id"
      | "childUnder18WithoutFatherOrMother"
      | "childUnder14WithoutFatherOrMother"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "status"
      | "requestDate"
      | "requestNumber"
      | "personGroup"
      | "attachments"
      | "childUnder18WithoutFatherOrMother"
      | "childUnder14WithoutFatherOrMother"
    >
  : never;
