import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { DicPromotionType } from "./tsadv$DicPromotionType";
import { DicAward } from "./tsadv_DicAward";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { FileDescriptor } from "./sys$FileDescriptor";
export class PersonAwardsDegreesRequest extends AbstractBprocRequest {
  static NAME = "tsadv_PersonAwardsDegreesRequest";
  type?: DicPromotionType | null;
  kind?: DicAward | null;
  startDate?: any | null;
  endDate?: any | null;
  description?: string | null;
  employee?: PersonGroupExt | null;
  file?: FileDescriptor[] | null;
}
export type PersonAwardsDegreesRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "personAwardsDegreesRequest.edit";
export type PersonAwardsDegreesRequestView<
  V extends PersonAwardsDegreesRequestViewName
> = V extends "_base"
  ? Pick<
      PersonAwardsDegreesRequest,
      | "id"
      | "description"
      | "startDate"
      | "endDate"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      PersonAwardsDegreesRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<PersonAwardsDegreesRequest, "id" | "description">
  : V extends "personAwardsDegreesRequest.edit"
  ? Pick<
      PersonAwardsDegreesRequest,
      | "id"
      | "startDate"
      | "endDate"
      | "description"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "type"
      | "kind"
      | "employee"
      | "file"
      | "status"
    >
  : never;
