import * as React from "react";
import {Tabs} from "antd";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {PositionDetailsProps} from "./PositionDetails";
import JobDescriptionForm from "./JobDescriptionForm";
import JobDescriptionRequestTables from "./JobDescriptionRequestTable";
import "./TabSheets.css";
import MainData from "./MainData";
import DestinationsForm from "./DestinationsForm";
import CompetenceTable from "./CompetenceTable";


import ReservistsTable from "./ReservistsList";
const {TabPane} = Tabs;

class TabSheets extends React.Component<WrappedComponentProps & PositionDetailsProps> {

  render(): React.ReactNode {
    return <div style={{height:"100%",overflowY:"auto"}}>
      <Tabs style={{height:"auto"}} className={"tabSheet"} key={this.props.selectedPosition} defaultActiveKey="1" type="card">
        <TabPane tab={this.props.intl.formatMessage({id:"tree.positionHieararchy.mainData"})} key="1">
          <MainData selectedPosition={this.props.selectedPosition} />
        </TabPane>
        <TabPane tab={this.props.intl.formatMessage({
          id: "jobDescriptionRequests"
        })} key="5">
          <JobDescriptionRequestTables selectedPosition={this.props.selectedPosition}/>
        </TabPane>
        <TabPane tab={this.props.intl.formatMessage({
          id: "jobDescription"
        })} key="2">
          <JobDescriptionForm selectedPosition={this.props.selectedPosition}/>
        </TabPane>
        <TabPane tab={this.props.intl.formatMessage({
          id: "assignments"
        })} key="3">
          <DestinationsForm selectedPosition={this.props.selectedPosition}/>
        </TabPane>
        <TabPane key="4" tab={this.props.intl.formatMessage({id:"reservistTabText"})}>
          <ReservistsTable selectedPosition={this.props.selectedPosition}/>
        </TabPane>
        <TabPane key="9" tab={this.props.intl.formatMessage({id: "competenceTab"})}>
          <CompetenceTable selectedPosition={this.props.selectedPosition}/>
        </TabPane>
      </Tabs>
    </div>
  }
}

export default injectIntl(TabSheets);