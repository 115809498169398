import { AbsenceRvdRequest } from "./base/tsadv_AbsenceRvdRequest";
export class AbsenceRvdRequestKzm extends AbsenceRvdRequest {
  static NAME = "kzm_AbsenceRvdRequestKzm";
  remote?: boolean | null;
}
export type AbsenceRvdRequestKzmViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "absenceRvdRequestKzm.edit";
export type AbsenceRvdRequestKzmView<
  V extends AbsenceRvdRequestKzmViewName
> = V extends "_base"
  ? Pick<
      AbsenceRvdRequestKzm,
      | "id"
      | "requestDate"
      | "remote"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "comment"
      | "purposeText"
      | "timeOfStarting"
      | "timeOfFinishing"
      | "totalHours"
      | "compensation"
      | "vacationDay"
      | "acquainted"
      | "agree"
      | "shiftCode"
      | "overrideAllHoursByDay"
    >
  : V extends "_local"
  ? Pick<
      AbsenceRvdRequestKzm,
      | "id"
      | "remote"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "purposeText"
      | "timeOfStarting"
      | "timeOfFinishing"
      | "totalHours"
      | "compensation"
      | "vacationDay"
      | "acquainted"
      | "agree"
      | "shiftCode"
      | "overrideAllHoursByDay"
    >
  : V extends "_minimal"
  ? Pick<AbsenceRvdRequestKzm, "id" | "requestDate">
  : V extends "absenceRvdRequestKzm.edit"
  ? Pick<
      AbsenceRvdRequestKzm,
      | "id"
      | "remote"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "purposeText"
      | "timeOfStarting"
      | "timeOfFinishing"
      | "totalHours"
      | "compensation"
      | "vacationDay"
      | "acquainted"
      | "agree"
      | "shiftCode"
      | "overrideAllHoursByDay"
      | "personGroup"
      | "type"
      | "purpose"
      | "status"
      | "shift"
      | "files"
    >
  : never;
