import React from "react";
import {Button, Col, Modal, Row, Tooltip} from "antd";
import {observer} from "mobx-react";
import CoinsComponent, {CoinsComponentWithoutInjectIntl} from "./CoinsComponent";
import {cartStore} from "../store/CartStore";
import {restServices} from "../../../../cuba/services";
import Notification from "../../../util/Notification/Notification";
import {computed} from "mobx";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";


type CartTotalElementProps = {
  total: number;
}

@observer
class CartTotalElement extends React.Component<CartTotalElementProps & WrappedComponentProps> {

  componentDidMount() {
    cartStore.loadTotalSum();
  }

  onOk = async () => {
    await restServices.recognitionService.checkoutCartList(null);
    Notification.success({message: this.props.intl.messages["recognition.cart.buySuccess"]});
    await cartStore.reloadCartItems();
  }

  onBuy = () => {
    const content = <Row type={"flex"} justify={"space-between"} style={{alignItems: "center"}}>
      <div>
        {this.props.intl.messages["recognition.cart.coinsInfo"]}
      </div>
      <CoinsComponentWithoutInjectIntl intl={this.props.intl} value={cartStore.totalSum}/>
    </Row>

    Modal.confirm({
      title: this.props.intl.messages["recognition.cart.confirm"],
      content: content,
      okText: this.props.intl.messages["recognition.cart.buy"],
      onOk: this.onOk
    })
  }

  @computed get isEnoughCoins(): boolean {
    return cartStore.totalSum <= cartStore.balance;
  }

  render() {
    return <div style={{padding: "20px", border: "1px solid", borderRadius: "6px"}}>
      <Row type={"flex"} justify={"space-between"} style={{alignItems: "center", marginBottom: "20px"}}>
        <Col>
          <div>
            <FormattedMessage id={"recognition.cart.total"}/>
          </div>
        </Col>
        <Col>
          <CoinsComponent value={cartStore.totalSum} />
        </Col>
      </Row>
      <Tooltip title={this.isEnoughCoins ? undefined : ""}>
        <Button block type={"primary"} disabled={!this.isEnoughCoins || cartStore.cardItems.length === 0} onClick={this.onBuy}>
          <FormattedMessage id={"recognition.cart.buy"}/>
        </Button>
      </Tooltip>
    </div>
  }
}

export default injectIntl(CartTotalElement);