import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import DisabilityRequestEdit from "./DisabilityRequestEdit";

type Props = RouteComponentProps<{ entityId: string, disabilityId?: string }>;

@observer
export class DisabilityRequestManagement extends React.Component<Props> {
  static PATH = "/disabilityRequest";
  static NEW_SUBPATH = "new";

  render() {
    const {entityId, disabilityId} = this.props.match.params;
    return (
      <>
        <DisabilityRequestEdit entityId={entityId} disabilityId={disabilityId}/>
      </>
    );
  }
}
