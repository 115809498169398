import React from "react";
import {restServices} from "../../../../cuba/services";
import {observable, reaction, runInAction} from "mobx";
import {observer} from "mobx-react";
import {Divider} from "antd";
import {injectIntl, WrappedComponentProps} from "react-intl";

type RecognitionProfilePointsAndCoinsProps = {
  personGroupId: string;
}

@observer
class RecognitionProfilePointsAndCoins extends React.Component<RecognitionProfilePointsAndCoinsProps & WrappedComponentProps> {

  @observable points?: number;
  @observable coins?: number;

  componentDidMount() {
    reaction(
      () => this.props.personGroupId,
      () => this.reload(),
      {fireImmediately: true}
    );
  }

  reload = () => {
    restServices.recognitionService.loadPersonCoins(this.props.personGroupId)
      .then(coins => runInAction(() => this.coins = coins.coins));

    restServices.recognitionService.loadPersonPoints(this.props.personGroupId)
      .then(points => runInAction(() => this.points = points.points));
  }

  render() {
    const points = this.points !== undefined &&
      <div className={"points-coins-container"}>
        <img src={require("../../../../resources/icons/recognition/points.png")} style={{width: '20px'}}/>
        <h1>{`${this.points} ${this.props.intl.messages["points"]}`}</h1>
      </div>

    const coins = this.coins !== undefined &&
      <div className={"points-coins-container"}>
        <img src={require("../../../../resources/icons/recognition/coins.png")} style={{width: '20px'}}/>
        <h1>{`${this.coins} ${this.props.intl.messages["coins"]}`}</h1>
      </div>

    return <div style={{width: '100%', display: "flex", justifyContent: "center", alignItems: "center"}}>
      {points}
      <Divider orientation="center" type="vertical"/>
      {coins}
    </div>
  }
}

export default injectIntl(RecognitionProfilePointsAndCoins);