import * as React from "react";
import { observer } from "mobx-react";
import AdaptationPlanEdit from "./AdaptationPlanEdit";
import AdaptationPlanList from "./AdaptationPlanList";
import { Tabs } from "antd";
import MyCompetentionTable from "../MyCompetentionTable";
import { FormattedMessage } from "react-intl";

type Props = {
  personGroupId?: string;
}

@observer
export class AdaptationPlanManagement extends React.Component<Props> {
  static PATH = "/adaptationPlanManagement";
  static NEW_SUBPATH = "new";

  state={
    entityId: null as string | null,
  }

  setEntityId = (entityId: string | null)=>{
    this.setState({
      entityId,
    });
  }

  render() {
    return (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={<FormattedMessage id="tsadv_AdaptationPlan"/>} key="1">
          {this.state.entityId 
            ? <AdaptationPlanEdit setEntityId={this.setEntityId} entityId={this.state.entityId} />
            : <AdaptationPlanList setEntityId={this.setEntityId} personGroupId={this.props.personGroupId}/>}
        </Tabs.TabPane>
        <Tabs.TabPane tab={<FormattedMessage id="myСompetention.competence-assessment"/>} key="2">
          {this.props.personGroupId && <MyCompetentionTable personGroupId={this.props.personGroupId}/>}
        </Tabs.TabPane>
      </Tabs>
    );
  }
}
 