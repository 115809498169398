import { AbstractParentEntity } from "./AbstractParentEntity";
import { GradeDetail } from "./tsadv_GradeDetail";
import { Indicator } from "./tsadv_Indicator";
export class GradeDetailAnswer extends AbstractParentEntity {
  static NAME = "tsadv_GradeDetailAnswer";
  gradeDetail?: GradeDetail | null;
  indicator?: Indicator | null;
}
export type GradeDetailAnswerViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "gradeDetailAnswer-all";
export type GradeDetailAnswerView<
  V extends GradeDetailAnswerViewName
> = V extends "_base"
  ? Pick<
      GradeDetailAnswer,
      "id" | "legacyId" | "organizationBin" | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      GradeDetailAnswer,
      "id" | "legacyId" | "organizationBin" | "integrationUserLogin"
    >
  : V extends "gradeDetailAnswer-all"
  ? Pick<
      GradeDetailAnswer,
      | "id"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "gradeDetail"
      | "indicator"
    >
  : never;
