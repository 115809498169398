import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";

import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {VacancyRequestAssistantManagement} from "./VacancyRequestAssistantManagement";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {RootStoreProp} from "../../store";
import Button, {ButtonType} from "../../components/Button/Button";
import {Button as AntBtn, Icon, Table} from "antd";
import Page from "../../hoc/PageContentHoc";
import Section from "../../hoc/Section";
import {VacancyRequest} from "../../../cuba/entities/base/tsadv_VacancyRequest";
import moment from "moment";

@injectMainStore
@inject("rootStore")
@observer
class VacancyRequestListComponent extends React.Component<MainStoreInjected & WrappedComponentProps & RootStoreProp> {

  dataCollection = collection<VacancyRequest>(VacancyRequest.NAME, {
    view: "portal.vacancyRequest-assistantList",
    sort: "-requestNumber",
    filter: {
      conditions: [{property: "createdBy", operator: "=", value: this.props.rootStore!.userInfo.login!},
        {property: "assistantManager", operator: "<>", value: ""}]
    }
  });

  fields = [
    "requestNumber",

    "requestDate",

    "status",

    "positionGroup"
  ];

  render() {
    if (!this.dataCollection.items)
      return <Icon type="spin"/>;

    const buttons = [
      <Link
        to={
          VacancyRequestAssistantManagement.PATH + "/" + VacancyRequestAssistantManagement.NEW_SUBPATH
        }
        key="create">
        <Button buttonType={ButtonType.PRIMARY}
                style={{margin: "0 12px 12px 0"}}>
          <span>
            <FormattedMessage id="management.browser.create"/>
          </span>
        </Button>
      </Link>
    ];

    return (
      <Page pageName={this.props.intl.formatMessage({id: "vacancyRequest.createRequest"})}>
        <Section size="large">
          <div>
            <div style={{marginBottom: 16}}>
              {buttons}
            </div>
            <Table dataSource={this.dataCollection.items}
                   loading={this.dataCollection.status==="LOADING"}
                   columns={[{
                     title:this.props.intl.formatMessage({id:"requestNumber"}),
                     dataIndex:"requestNumber",
                     render: (text, record: VacancyRequest) => <Link
                       to={VacancyRequestAssistantManagement.PATH + "/" + record.id}
                     >{text}</Link>,
                     sorter:(a, b) => a.requestNumber - b.requestNumber
                   }
                     ,{
                       title:this.props.intl.formatMessage({id:"requestDate"}),
                       dataIndex:"requestDate",
                       render:(text,record) => moment(record.requestDate!).format('L'),
                       sorter:(a, b) => {
                         const aDate = moment(a.requestDate!)
                         const bDate = moment(b.requestDate!)
                         return bDate.diff(aDate)
                       }
                     },{
                       title:this.props.intl.formatMessage({id:"status"}),
                       dataIndex:"status",
                       render:(text, record) => record.status?record.status!['_instanceName']:"",
                       filters:[{text:<FormattedMessage id={"status.Draft"} />,value:this.props.intl.formatMessage({id:"status.Draft"})},
                         {text:<FormattedMessage id={"status.OnApproval"} />,value:this.props.intl.formatMessage({id:"status.OnApproval"})},
                         {text:<FormattedMessage id={"status.Approved"} />,value:this.props.intl.formatMessage({id:"status.Approved"})},
                         {text:<FormattedMessage id={"status.ToBeRevised"} />,value:this.props.intl.formatMessage({id:"status.ToBeRevised"})},
                         {text:<FormattedMessage id={"status.Rejected"} />,value:this.props.intl.formatMessage({id:"status.Rejected"})}],

                       onFilter:(value, record) => record.status!["_instanceName"].startsWith(value)
                     },{
                       title:this.props.intl.formatMessage({id:"positionGroup"}),
                       dataIndex:"positionGroup",
                       render:(text, record) => record.positionGroup?record.positionGroup!['_instanceName']:""
                     },{
                       title:"",
                       render:(text, record) => {
                         if (record.status!.code === "DRAFT") {
                           return <AntBtn onClick={() => {
                             this.dataCollection.delete({id:record.id})
                           }
                           }><Icon type='delete'/></AntBtn>
                         }
                         return <AntBtn disabled={true}><Icon type='delete'/></AntBtn>
                       }
                     }]}
            />
          </div>
        </Section>
      </Page>
    );
  }
}

const VacancyRequestAssistantList = injectIntl(VacancyRequestListComponent);

export default VacancyRequestAssistantList;
