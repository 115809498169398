import * as React from "react";
import {inject, observer} from "mobx-react";
import {Link, withRouter,RouteComponentProps} from "react-router-dom";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {observable} from "mobx";
import {collection, injectMainStore, MainStoreInjected} from "@cuba-platform/react";
import {IndividualDevelopmentPlan} from "../../../cuba/entities/base/tsadv$IndividualDevelopmentPlan";
import {IndividualDevolopmentPlanManagement} from "./IndividualDevolopmentPlanManagement";
import IndividualDevolopmentPlanList from "./IndividualDevolopmentPlanList";
import {RootStoreProp} from "../../store";
import {Button, Spin} from "antd";
import moment from "moment";
import {JSON_DATE_TIME_FORMAT} from "../../util/Date/Date";

type Props = { entityId?: string|undefined };

@inject("rootStore")
@injectMainStore
@observer
 class IndividualDevolopmentPlan extends React.Component <WrappedComponentProps& MainStoreInjected & Props &  RootStoreProp & RouteComponentProps> {

  dc = collection<IndividualDevelopmentPlan>(IndividualDevelopmentPlan.NAME, {view:"_minimal",filter:{
    conditions:[{
      property:"personGroup.id",
      operator:"=",
      value:this.props!.rootStore!.userInfo!.personGroupId!
    },{
      property:"endDate",
      operator:">=",
      value:moment().format(JSON_DATE_TIME_FORMAT)
    },
      {
        property:"startDate",
        operator:"<=",
        value:moment().format(JSON_DATE_TIME_FORMAT)
      }
    ]
    }})

  @observable visible:boolean = false

  onVisible=(bool:boolean)=>{
    this.visible = bool
  }
  isActiveIPR = ():any => {
    return this.dc.items.length > 0;
  }

  render() {

    const {status} = this.dc
    if(status === "LOADING"){
      return <Spin style={{position:"absolute", top:"-50%", left:"-50%", translate:"50% 50%"}} />
    }
    return (
        <div style={{padding:"10px"}}>
          { !this.visible ?
            <div className={"mainPage"} style={{width:"100%"}}>
              <div style={{marginBottom:"20px"}}>
                <img style={{width:"90%"}} src={require('../../../resources/img/IPRMainPage.PNG')} />
              </div>
              <div style={{display: "flex", justifyContent: "flex-start"}}>
                <Link to={
                  IndividualDevolopmentPlanManagement.PATH + "/" +
                  IndividualDevolopmentPlanManagement.NEW_SUBPATH
                }
                      key="create"
                > <Button style={{color:"#fff", background:"#005487"}}>
                  <span>
                    <FormattedMessage id="individualPlan.button.create"/>
                  </span>
                  </Button>
                </Link>
                <Button onClick={() => {
                  this.visible = true
                }} style={{
                  marginLeft: "12px",
                  background: "transparent",
                  textDecoration: "underline",
                  color: "blue",
                  border: "none"
                }}>
                  <FormattedMessage id="individualPlan.button.list"/>
                </Button>

              </div>


            </div>:
            <IndividualDevolopmentPlanList  collection={this.dc} onVisible={this.onVisible}/>
          }
        </div>
    );
  }


}
export  default injectIntl(withRouter(IndividualDevolopmentPlan))