import {action, observable, runInAction} from "mobx";
import RootStore from "./RootStore";
import {DataCollectionStore} from "@cuba-platform/react/dist/data/Collection";
import {AssignmentSchedule} from "../../cuba/entities/base/tsadv$AssignmentSchedule";

export  default class ScheduleStandart {
  root: RootStore
  @observable currentSchedule:DataCollectionStore<AssignmentSchedule>

  constructor(rootStore:RootStore) {
    runInAction(()=>{
      this.root = rootStore
    })
  }

  @action
  setCurrentSchedule = (item:DataCollectionStore<AssignmentSchedule>) =>{
    this.currentSchedule = item
  }

}