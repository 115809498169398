import { AbstractBprocRequest } from "./AbstractBprocRequest";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { PositionGroupExt } from "./base$PositionGroupExt";
import { FileDescriptor } from "./sys$FileDescriptor";
export class SuccessionPlanningRequest extends AbstractBprocRequest {
  static NAME = "tsadv_SuccessionPlanningRequest";
  personGroup?: PersonGroupExt | null;
  positionGroup?: PositionGroupExt | null;
  file?: FileDescriptor[] | null;
  description?: string | null;
  action?: any | null;
}
export type SuccessionPlanningRequestViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "successionPlanningRequest-browse"
  | "successionPlanningRequest-edit";
export type SuccessionPlanningRequestView<
  V extends SuccessionPlanningRequestViewName
> = V extends "_base"
  ? Pick<
      SuccessionPlanningRequest,
      | "id"
      | "requestNumber"
      | "description"
      | "action"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestDate"
      | "comment"
    >
  : V extends "_local"
  ? Pick<
      SuccessionPlanningRequest,
      | "id"
      | "description"
      | "action"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
    >
  : V extends "_minimal"
  ? Pick<SuccessionPlanningRequest, "id" | "requestNumber">
  : V extends "successionPlanningRequest-browse"
  ? Pick<
      SuccessionPlanningRequest,
      | "id"
      | "description"
      | "action"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "personGroup"
      | "positionGroup"
      | "file"
      | "status"
    >
  : V extends "successionPlanningRequest-edit"
  ? Pick<
      SuccessionPlanningRequest,
      | "id"
      | "description"
      | "action"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
      | "requestNumber"
      | "requestDate"
      | "comment"
      | "personGroup"
      | "positionGroup"
      | "file"
      | "status"
    >
  : never;
