import RootStore from "./RootStore";
import { action, observable } from "mobx";
import { restServices } from "../../cuba/services";
import { BellNotification } from "./BellNotificationStore";
import { getCubaREST } from "@cuba-platform/react";
import { LearningFeedbackTemplate } from "../../cuba/entities/base/tsadv$LearningFeedbackTemplate";
import { LearningFeedbackUsageType } from "../../cuba/enums/enums";
import { SerializedEntity } from "@cuba-platform/rest";


class GlobalStore {
  rootStore: RootStore;

  @observable questionnaireId: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action
  setQuestionnaireId = (value: string) => {
    this.questionnaireId = value;
  };

  loadDailyQuestionnaireId = () => {
    restServices.portalHelperService.companiesForLoadDictionary({
      personGroupId: this.rootStore.userInfo.personGroupId!
    }).then(companies => {
      getCubaREST()!.searchEntities(LearningFeedbackTemplate.NAME, {
        conditions: [{
          property: "usageType",
          operator: "=",
          value: LearningFeedbackUsageType.DAILY_SURVEY
        }, {
          property: "company.id",
          operator: "in",
          value: companies
        }]
      }).then((questionnaires: SerializedEntity<LearningFeedbackTemplate>[]) => {
        if (questionnaires.length > 0) {
          this.setQuestionnaireId(questionnaires[0].id);
        }
      });
    });
  };
}

export default GlobalStore;