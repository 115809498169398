import * as React from "react";
import {RouteComponentProps} from "react-router";
import {observer} from "mobx-react";
import MyCalendar from "./MyCalendar";

type Props = RouteComponentProps<{ entityId?: string }>;

@observer
export class CalendarManagement extends React.Component<Props> {
  static PATH = " MyCalendar";
  static NEW_SUBPATH = "new";

  render() {
    return (
      <>
        <MyCalendar/>
      </>
    );
  }
}
