import { StandardEntity } from "./sys$StandardEntity";
export class ILearningBudgetRequestCost extends StandardEntity {
  static NAME = "tsadv_ILearningBudgetRequestCost";
  trainingCost?: any | null;
  travel?: any | null;
  residence?: any | null;
  dailyAllowance?: any | null;
}
export type ILearningBudgetRequestCostViewName =
  | "_base"
  | "_local"
  | "_minimal";
export type ILearningBudgetRequestCostView<
  V extends ILearningBudgetRequestCostViewName
> = V extends "_base"
  ? Pick<
      ILearningBudgetRequestCost,
      "id" | "trainingCost" | "travel" | "residence" | "dailyAllowance"
    >
  : V extends "_local"
  ? Pick<
      ILearningBudgetRequestCost,
      "id" | "trainingCost" | "travel" | "residence" | "dailyAllowance"
    >
  : never;
