import * as React from "react";
import {createElement} from "react";
import {Card, Col, Form, Input, Row} from "antd";
import {inject, observer} from "mobx-react";
import {observable} from "mobx";
import {FormattedMessage, injectIntl} from "react-intl";
import {collection, getCubaREST, injectMainStore, instance, Msg, withLocalizedForm} from "@cuba-platform/react";
import "../../../app/App.css";
import {AddressRequest} from "../../../cuba/entities/base/tsadv$AddressRequest";
import {DicRequestStatus} from "../../../cuba/entities/base/tsadv$DicRequestStatus";
import {ReadonlyField} from "../../components/ReadonlyField";
import AbstractBprocEdit from "../Bproc/abstract/AbstractBprocEdit";
import {PersonExt} from "../../../cuba/entities/base/base$PersonExt";
import moment from "moment";
import {goBackOrHomePage, isEquals, langValue} from "../../util/util";
import LoadingPage from "../LoadingPage";
import Section from "../../hoc/Section";
import Button, {ButtonType} from "../../components/Button/Button";
import Page from "../../hoc/PageContentHoc";
import {withRouter} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import {formatDate} from "../../util/Date/Date";
import {PersonExperienceRequestManagement} from "./PersonExperienceRequestManagement";
import {PersonExperience} from "../../../cuba/entities/base/tsadv$PersonExperience";
import {PersonExperienceRequest} from "../../../cuba/entities/base/tsadv_PersonExperienceRequest";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

type EditorProps = {
  entityId: string;
  addressId?: string;
  isRequest?:boolean;
}

@inject("rootStore")
@injectMainStore
@observer
class PersonExpirienceRequestEditComponent extends AbstractBprocEdit<AddressRequest, EditorProps> {
  processDefinitionKey = "personExperienceRequest";

  dicLangValue = 'langValue' + this.props.rootStore!.userInfo!.localeIndex;

  dataInstance = instance<PersonExperienceRequest>(PersonExperienceRequest.NAME, {
    view: "personExperienceRequest-Edit",
    loadImmediately: false
  });


  statussDc = collection<DicRequestStatus>(DicRequestStatus.NAME, {
    view: "_minimal"
  });

  @observable
  hireDate:string

  @observable
  startDate:any

  @observable
  endDate:any



  @observable
  editAddress: PersonExperience;

  instanceEditAddress = instance<PersonExperience>(PersonExperience.NAME, {
    view: "portal.my-profile",
    loadImmediately: false
  });

  @observable
  changedMap = new Map<string, boolean>();

  @observable
  person: PersonExt;

  personGroupId: string;

  @observable
  isLocaleEn = this.props.rootStore!.userInfo.locale === 'en';

  @observable
  approverHrRoleCode: string;

  isUpdateBeforeOutcome = true;

  fields = [

    "requestNumber",

    "requestDate",

    "status",

    'comment',

    "startMonth",

    "endMonth",

    "job",

    "location",

    "company",

    "attachments"

  ];

  getUpdateEntityData(): any {
    if (this.isNotDraft())
      return super.getUpdateEntityData();
    return {
      personGroup: this.personGroupId,
      personExperience: this.editAddress ? this.editAddress.id : undefined,
      ...super.getUpdateEntityData()
    };
  }

  render() {

    if (!this.mainStore) return <LoadingPage/>

    const messages = this.mainStore.messages;
    if (!messages) return <LoadingPage/>

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();

    return (
      <div>
        <ScrollToTopOnMount />
        <Page pageName={this.props.isRequest?this.props.intl.formatMessage({id:"personExperienceRequestEdit"}):<FormattedMessage id={"personExperience"}/>}>
        <Section size={"large"}>
          <Card className="narrow-layout card-actions-container"
                bordered={false}
                actions={[
                  <Button buttonType={ButtonType.FOLLOW}
                          onClick={() => goBackOrHomePage(this.props.history!)}>{this.props.intl.formatMessage({id: "close"})}</Button>,
                  this.getOutcomeBtns()]}>
            <Form layout="vertical">

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestNumber"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="requestDate"
                form={this.props.form}
              />

              <ReadonlyField
                disabled
                entityName={entityName}
                propertyName="status"
                form={this.props.form}
                optionsContainer={this.statussDc}
              />

              <Form.Item
                label={<FormattedMessage id={"personGroup"}/>}>
                <Input
                  value={this.person ? this.person['_instanceName'] || '' : ''}
                  disabled/>
              </Form.Item>

              {
                this.editAddress ?
                  <Row type={"flex"} className={"data-form"}>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'currentValue'}/></div>
                      {this.renderAddress()}
                    </Col>
                    <Col md={24} sm={24} lg={12}>
                      <div className={"section-header-container"}><FormattedMessage id={'newValue'}/></div>
                      {this.renderAddressRequest()}
                    </Col>
                  </Row>
                  : this.renderAddressRequest()
              }

              <Form.Item
                label={createElement(Msg, {entityName: entityName, propertyName: "comment"})}>
                {this.props.form.getFieldDecorator("comment")(
                  <TextArea
                    rows={5}
                    disabled={isNotDraft}/>
                )}
              </Form.Item>

            </Form>

            {this.takCard()}

          </Card>
        </Section>
      </Page>
      </div>
        );
  }

  renderAddress = () => {

    const entityName = this.dataInstance.entityName;


    return (
      <div>
        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "company"})}>
          <Input
            value={this.editAddress ? this.editAddress.company || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "startMonth"})}>
          <Input
            value={formatDate(this.editAddress.startMonth)}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "endMonth"})}>
          <Input
            value={formatDate(this.editAddress.endMonth)}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "job"})}>
          <Input
            value={this.editAddress ? this.editAddress.job || '' : ''}
            disabled/>
        </Form.Item>

        <Form.Item
          label={createElement(Msg, {entityName: entityName, propertyName: "location"})}>
          <Input
            value={this.editAddress ? this.editAddress.location || '' : ''}
            disabled/>
        </Form.Item>

      </div>
    );
  }

  checkHireDate = (rule: any, value: any, callback: any,message:any,property:string) =>{
    const messages = this.mainStore.messages!;
    const entityName = this.dataInstance.entityName;
    if (!value) {
      return callback(this.props.intl.formatMessage({id: "form.validation.required"},{fieldName:messages[entityName + `.${property}`]}))
    }
    const date = moment(value).format('YYYY-MM-DD')
    if(new Date(date)>=new Date(this.hireDate)){
      return callback(this.props.intl.formatMessage({id: `${message}`},))
    }else if(new Date(this.endDate) < new Date(this.startDate)){
      return callback(this.props.intl.formatMessage({id: "PersonExpirence.validate.equal"},))
    }
    else return callback();
  }

  renderAddressRequest = () => {

    const messages = this.mainStore.messages!;

    const entityName = this.dataInstance.entityName;
    const isNotDraft = this.isNotDraft();


    return (
      <div>

        <ReadonlyField
          entityName={entityName}
          propertyName="company"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.company']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('company', value !== this.editAddress.company);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('company'),
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="job"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.job']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('job', value !== this.editAddress.job);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('job'),
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="startMonth"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required:!isNotDraft,
              validator: (rule,value, callback)=>{
                this.checkHireDate(rule,value ,callback ,"PersonExpirence.validate.startDate",'startMonth')
              }
            }],
            getValueFromEvent: args => {
              this.startDate = moment(args).format('YYYY-MM-DD')
              if (this.editAddress)
                this.changedMap.set('startMonth', this.editAddress.startMonth ? !moment(this.editAddress.startMonth).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('startMonth'),
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="endMonth"
          disabled={isNotDraft}
          form={this.props.form}
          getFieldDecoratorOpts={{
            rules: [{
              required:!isNotDraft,
              validator: (rule,value, callback)=>{
            this.checkHireDate(rule,value ,callback ,"PersonExpirence.validate.endDate",'endMonth')
          }
            }],
            getValueFromEvent: args => {
              this.endDate = moment(args).format('YYYY-MM-DD')
              if (this.editAddress)
                this.changedMap.set('endMonth', this.editAddress.endMonth ? !moment(this.editAddress.endMonth).isSame(args, 'day') : !!args);
              return args;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('endMonth'),
          }}
        />

        <ReadonlyField
          entityName={entityName}
          propertyName="location"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: !isNotDraft,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: messages[entityName + '.location']})
            }],
            getValueFromEvent: args => {
              const value = args.currentTarget.value;
              if (this.editAddress)
                this.changedMap.set('location', value !== this.editAddress.location);
              return value;
            }
          }}
          formItemOpts={{
            hasFeedback: this.changedMap.get('location'),
          }}
        />
        <ReadonlyField
          entityName={this.dataInstance.entityName}
          propertyName="attachments"
          form={this.props.form}
          disabled={isNotDraft}
          getFieldDecoratorOpts={{
            rules: [{
              required: true,
              message: this.props.intl.formatMessage({id: "form.validation.required"}, {fieldName: this.mainStore.messages![this.dataInstance.entityName + '.attachments']})
            }],
          }}
        />

      </div>
    );
  }


  initVariablesByBproc = () => {
    if (this.activeUserTask && this.activeUserTask.hrRole && this.activeUserTask.hrRole.code) {
      this.approverHrRoleCode = this.activeUserTask.hrRole.code;
    }
  }

  onReactionDisposerEffect = (item: AddressRequest | undefined) => {
    this.personGroupId = item && item.personGroup ? item.personGroup.id! : this.props.rootStore!.userInfo!.personGroupId!;

    const requestDate = item && item.requestDate ? item.requestDate : moment().toISOString();

    getCubaREST()!.searchEntities<PersonExt>(PersonExt.NAME, {
      conditions: [{
        property: "group.id",
        operator: '=',
        value: this.personGroupId
      }, {
        property: 'startDate',
        operator: '<=',
        value: requestDate,
      }, {
        property: 'endDate',
        operator: '>=',
        value: requestDate,
      }]
    }, {
      view: 'person-edit'
    }).then(value => value[0])
      .then(value => {
        this.person = value
        this.hireDate = value && value.hireDate!
      })

    const addressId = this.props.addressId || (item && item.baseAddress ? item.baseAddress.id : undefined)
    if (addressId)
      getCubaREST()!.loadEntity(PersonExperience.NAME, addressId, {view: 'personExperience.full'})
        .then(value => this.editAddress = value as PersonExperience)
        .then(value => {
          this.instanceEditAddress.setItem(value);
          const properties = [

            "startMonth",

            "endMonth",

            "job",

            "location",

            "company"

          ];
          if (this.props.entityId === PersonExperienceRequestManagement.NEW_SUBPATH) {
            this.props.form.setFieldsValue(this.instanceEditAddress.getFieldValues(properties));
          } else if (item) {
            properties.forEach(field => {
              this.changedMap.set(field, !isEquals(value[field], item[field]));
            });
          }
        });
  }

}

export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(withRouter(PersonExpirienceRequestEditComponent))
);
