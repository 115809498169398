import { StandardEntity } from "./sys$StandardEntity";
import { PersonGroupExt } from "./base$PersonGroupExt";
import { OrganizationGroupExt } from "./base$OrganizationGroupExt";
import { LearningBudget } from "./tsadv_LearningBudget";
export class BudgetDelegation extends StandardEntity {
  static NAME = "tsadv_BudgetDelegation";
  fromPerson?: PersonGroupExt | null;
  personTo?: PersonGroupExt | null;
  organization?: OrganizationGroupExt | null;
  budget?: LearningBudget | null;
}
export type BudgetDelegationViewName =
  | "_base"
  | "_local"
  | "_minimal"
  | "budget-delegation-view";
export type BudgetDelegationView<
  V extends BudgetDelegationViewName
> = V extends "_base"
  ? Pick<BudgetDelegation, "id" | "personTo" | "organization">
  : V extends "_minimal"
  ? Pick<BudgetDelegation, "id" | "personTo" | "organization">
  : V extends "budget-delegation-view"
  ? Pick<
      BudgetDelegation,
      "id" | "personTo" | "organization" | "fromPerson" | "organization"
    >
  : never;
