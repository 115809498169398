import { AbstractParentEntity } from "./AbstractParentEntity";
import { Successor } from "./tsadv$Successor";
import { SuccessionPlanning } from "./tsadv$SuccessionPlanning";
import { DicReadinessLevel } from "./tsadv$DicReadinessLevel";
import { SuccessorStatus } from "./tsadv_SuccessorStatus";
export class SuccessorHistory extends AbstractParentEntity {
  static NAME = "tsadv_SuccessorHistory";
  date?: any | null;
  successor?: Successor | null;
  succession?: SuccessionPlanning | null;
  readinessLevel?: DicReadinessLevel | null;
  readinessLevelCulcDate?: any | null;
  status?: SuccessorStatus | null;
  comment?: string | null;
}
export type SuccessorHistoryViewName = "_base" | "_local" | "_minimal";
export type SuccessorHistoryView<
  V extends SuccessorHistoryViewName
> = V extends "_base"
  ? Pick<
      SuccessorHistory,
      | "id"
      | "date"
      | "readinessLevelCulcDate"
      | "comment"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : V extends "_local"
  ? Pick<
      SuccessorHistory,
      | "id"
      | "date"
      | "readinessLevelCulcDate"
      | "comment"
      | "legacyId"
      | "organizationBin"
      | "integrationUserLogin"
    >
  : never;
