import * as React from "react";
import NineBox from "./NineBox";
import {RouteComponentProps} from "react-router";
type Props = RouteComponentProps<{ menu?: string }>;
class NineBoxManagement extends React.Component<Props> {
  static PATH = "/nineBox";
  static NEW_SUBPATH = "new";
  render() {
    const {menu} = this.props.match.params
    return (
      menu?
      <><NineBox menu/></>
        :<><NineBox/></>
    );
  }
}
export default NineBoxManagement
